import { FC, useEffect, useState } from "react";
import TitleBox from "../SubComponents/TitleBox";
import { ComponentProps } from "../SelfAwarenessComponentMapping";
import { useSelfAwarenessContext } from "hooks/useSelfAwarenessContext";
import BackAndNextFlat from "components/utility/BackAndNextFlat";
import { colours } from "./styles/colourMap";
import InfoModal from "../SubComponents/InfoModal";
import { FaCheckCircle } from "react-icons/fa";
import TextArea from "../SubComponents/TextArea";
import ConfirmModal from "../SubComponents/ConfirmModal";

const CauseReflection: FC<ComponentProps> = ({
  backAndNextHandler,
  setHasModal,
}) => {
  const [show, setShow] = useState<boolean>(false);
  const [showConfirm, setShowConfirm] = useState<boolean>(false);
  const { journal } = useSelfAwarenessContext();
  const [reflection, setReflection] = useState<string>("");
  const handler = () => {
    setShow(!show);
    setHasModal(!show);
  };

  const [selectedItems, setSelectedItems] = useState<string[]>([]);

  // Load data from local storage on component mount
  useEffect(() => {
    const storedJournal = localStorage.getItem("SelfAwarenessJournal");
    const existingJournal = storedJournal ? JSON.parse(storedJournal) : {};
    if (existingJournal && existingJournal.selectedCause) {
      setSelectedItems(existingJournal.selectedCause);
    }
    if (existingJournal && existingJournal.reflection) {
      setReflection(existingJournal.reflection);
    }
    // eslint-disable-next-line
  }, []);

  const answerHandler = (value: string) => {
    setReflection(value);
    const storedJournal = localStorage.getItem("SelfAwarenessJournal");
    const existingJournal = storedJournal ? JSON.parse(storedJournal) : {};

    // Update the existing journal with the new answer
    const updatedJournal = {
      ...existingJournal,
      reflection: value,
    };

    // Store the updated journal in local storage
    localStorage.setItem(
      "SelfAwarenessJournal",
      JSON.stringify(updatedJournal)
    );
  };

  const confirmHandler = () => {
    setShowConfirm(!showConfirm);
    setHasModal(!showConfirm);
  };

  useEffect(() => {
    if (!journal) return;
  }, [journal]);

  return (
    <div className="py-8 px-4 w-full md:w-1/4">
      <ConfirmModal
        backAndNextHandler={backAndNextHandler}
        setHasModal={setHasModal}
        handler={confirmHandler}
        showConfirm={showConfirm}
      />
      {journal && <InfoModal handler={handler} show={show} journal={journal} />}
      {journal && (
        <>
          <TitleBox
            journal={journal}
            handler={handler}
            showInfo={true}
            title={`What action do you plan to take to address the following factors?`}
          />

          <div className="p-5 my-4 font-poppins">
            <div className="max-h-[300px] overflow-y-auto">
              {selectedItems.map((item, index) => (
                <div key={index} className="px-5 select-none">
                  <div
                    style={{
                      borderColor: colours[journal.pageType]["border"],
                      color: "var(--text-colour)",
                    }}
                    className={`border-2 p-2 flex w-full gap-2 items-center  rounded-full my-2 bg-white px-4`}
                  >
                    <FaCheckCircle
                      className="flex-shrink-0"
                      size={20}
                      style={{ color: "var(--icon-colour-0)" }}
                    />
                    {item}
                  </div>
                </div>
              ))}
            </div>
          </div>

          <TextArea
            maxLength={500}
            answer={reflection}
            journal={journal}
            answerHandler={answerHandler}
          />
        </>
      )}
      <div className="my-4">
        <BackAndNextFlat
          {...backAndNextHandler}
          nextAllowed={reflection.length > 0}
          nextCallback={() => {
            setShowConfirm(true);
            setHasModal(true);
          }}
        />
      </div>
    </div>
  );
};

export default CauseReflection;
