import { useGCSession } from "hooks/useGCSession";
import useGetParticipants from "hooks/useGetParticipants";
import { useEffect, useState } from "react";
import classes from "./EndRatingAverage.module.css";
import { DRAG_RATING_KEYS } from "hooks/useDragRating";
import { CheckInCheckOut } from "interface/ParticipantInterface";
import { useCollection } from "hooks/useCollection";
import { useAuthContext } from "hooks/useAuthContext";
import UserBarHandle from "components/RatingAverage/UserBarHandle";
import Slider from "../../../components/Slider/Slider";
import { FeedbackForm } from "interface/FeedbackFormInterface";
import BackAndNext from "components/utility/BackAndNext";
import { useGrowthCircleContext } from "hooks/useGrowthCircleContext";
import { useNavigate } from "react-router-dom";
import MainSessionTemplate from "components/utility/SessionTemplate/MainSessionTemplate";
import NoGCSessionWrapper from "pages/NoGCSession/NoGCSessionWrapper";
import { Toaster, toast } from "react-hot-toast";
import LoadingWidget from "components/utility/LoadingWidget";
import ConfirmModal from "components/utility/ConfirmModal";
import { REFLECTION_RATING_ROUTE } from "strings/Routes";
import { useFirestore } from "hooks/useFirestore";
import useParticipants from "hooks/useParticipants";
import { checkCanAdvanceNextActivity } from "models/organisationRole";
import useOrganisationContext from "hooks/organisation/useOrganisationContext";

/**
 * Page that shows the average ratings of all participants after user has submitted the end ratings
 */
const ReflectionRatingAverage = () => {
  const { profile } = useAuthContext();
  const { growthCircleSession } = useGrowthCircleContext();
  const { selectedOrganisation } = useOrganisationContext();
  const { getParticipant, participants, getGroupedParticipantsByCheckInKey } =
    useGetParticipants();
  const { documents } = useCollection("FeedBackForm", [
    "growthCircle",
    "==",
    profile!.growthCircle,
  ]);
  const { updateDocument } = useFirestore("users");
  const {
    calculateAverageCheckIn,
    facilAllowNextPage,
    checkAllRatingAveragesNotNan,
    calculateReflectionRatingAverages,
    getGroupedFeedbackFormsByCheckInKey,
  } = useGCSession();
  // const { updateFeedbackFormTransaction } = useFeedbackForm();
  // const { updateRoleProgress } = useUsers();
  const { sessionRole } = useParticipants();

  const [checkInAverages, setCheckInAverages] =
    useState<CheckInCheckOut | null>(null);
  const [reflectionAverages, setReflectionAverages] =
    useState<CheckInCheckOut | null>(null);
  const [showContent, setShowContent] = useState<boolean>(false);
  const [currentCheckInType, setCurrentCheckInType] = useState<string | null>(
    ""
  );
  const [currentReflectionCheckInType, setCurrentReflectionCheckInType] =
    useState<string | null>("");
  const [feedBackForms, setFeedBackForms] = useState<FeedbackForm[]>([]);
  const [allFormsFilled, setAllFormsFilled] = useState<boolean>(false);
  const [showConfirmModal, setShowConfirmModal] = useState<boolean>(false);
  let navigate = useNavigate();

  const canAdvance = checkCanAdvanceNextActivity(sessionRole);

  useEffect(() => {
    if (!profile) return;
    const data = { pageStep: window.location.pathname };
    updateDocument(profile.uid, data);
    if (!!participants && participants.length > 0) {
      setCheckInAverages(calculateAverageCheckIn(participants)!);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [participants]);

  useEffect(() => {
    if (documents.length > 0) {
      setFeedBackForms(documents);
    }
  }, [documents]);

  useEffect(() => {
    // console.log("This is the boolean of all forms filled: " + allFormsFilled + " and feedackForm length " + feedBackForms.length);
    if (allFormsFilled) {
      setTimeout(
        () =>
          toast.success(
            "All participants have successfully submitted their feedback!"
          ),
        1000
      );
    }
  }, [allFormsFilled]);

  useEffect(() => {
    if (!!feedBackForms && feedBackForms.length > 0) {
      setReflectionAverages(calculateReflectionRatingAverages(feedBackForms));
    }
    const formsSubmitted: number = feedBackForms.filter(
      (feedbackForm) => feedbackForm.refPersonalDragValues !== undefined
    ).length;
    if (
      formsSubmitted !== 0 &&
      formsSubmitted === participants.length &&
      !allFormsFilled
    ) {
      setAllFormsFilled(true);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [feedBackForms]);

  useEffect(() => {
    //Check if all checkIn keys are true
    if (!!checkInAverages && !!reflectionAverages) {
      setShowContent(checkAllRatingAveragesNotNan(checkInAverages));
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [checkInAverages, reflectionAverages]);

  const nextHandler = () => {
    if (!allFormsFilled) {
      setShowConfirmModal(true);
    } else {
      submitHandler();
    }
  };

  const submitHandler = () => {
    if (canAdvance) {
      facilAllowNextPage("end-of-session");
    }

    // const userFeedbackForm: FeedbackForm | undefined = feedBackForms.find(
    //   (feedback) => feedback.userId === profile?.id && !feedback.savedToProgress
    // );
    // if (!!userFeedbackForm && !!userFeedbackForm.id) {
    //   updateRoleProgress(userFeedbackForm, participants, feedBackForms.length);
    //   //Set feedbackForm to be updated
    //   updateFeedbackFormTransaction(userFeedbackForm.id, {
    //     savedToProgress: true,
    //   });
    // }

    // navigate(END_OF_SESSION);
    navigate(REFLECTION_RATING_ROUTE);
  };

  const getOtherValues = (key) => {
    const groupedParticipants = getGroupedParticipantsByCheckInKey(
      key,
      participants
    );

    if (!!groupedParticipants) {
      return groupedParticipants.map((participants, index) => {
        //Map into a component depending on the length
        return (
          <UserBarHandle
            max={selectedOrganisation?.maxRating ?? 10}
            key={index}
            checkInType={DRAG_RATING_KEYS[key].KEY + index}
            currentCheckInType={currentCheckInType}
            participants={participants}
            values={[participants[0].checkIn[DRAG_RATING_KEYS[key].KEY]]}
            setCurrentCheckInType={setCurrentCheckInType}
          />
        );
      });
    }
  };

  const getReflectionOtherValues = (key) => {
    // console.log(feedBackForms);
    const groupedFeedbackForms: FeedbackForm[][] | undefined =
      getGroupedFeedbackFormsByCheckInKey(key, feedBackForms);

    if (!!groupedFeedbackForms) {
      return groupedFeedbackForms.map((feedbackForms, index) => {
        return (
          <UserBarHandle
            max={selectedOrganisation?.maxRating ?? 10}
            key={index}
            checkInType={DRAG_RATING_KEYS[key].KEY + index}
            currentCheckInType={currentReflectionCheckInType}
            participants={feedbackForms.map((feedbackForm) => {
              return getParticipant(feedbackForm);
            })}
            values={[
              feedbackForms[0].refPersonalDragValues![
                DRAG_RATING_KEYS[key].KEY
              ],
            ]}
            setCurrentCheckInType={setCurrentReflectionCheckInType}
          />
        );
      });
    }
  };

  const REF_RATING_AVG_CONSTANTS = {
    PAGE_TITLE: "Check out how everyone's feeling!",
    NEXT_MODAL_ID: "show-next-confirm-modal",
  };
  return (
    <NoGCSessionWrapper>
      <MainSessionTemplate currentStep={5}>
        <Toaster
          position="top-center"
          containerStyle={{
            top: "15%",
          }}
          toastOptions={{
            style: {
              backgroundColor: "var(--main-colour-2)",
              color: "var(--text-colour)",
              textAlign: "center",
            },
          }}
        />
        <div className={classes["reflection-rating-average-container"]}>
          {/* title */}
          <div className={classes["title"]}>
            <h1>{REF_RATING_AVG_CONSTANTS.PAGE_TITLE}</h1>
            <hr />
          </div>
          {/* completed participants */}

          {/* sections */}
          {(showContent &&
            Object.keys(DRAG_RATING_KEYS).map((key, index) => {
              return (
                <div key={key} className={classes["rating-row"]}>
                  {/* textual content */}
                  <div className={classes["textual-content"]}>
                    <h2>{DRAG_RATING_KEYS[key].TITLE}</h2>
                    <h3>{DRAG_RATING_KEYS[key].SUB_TITLE}</h3>
                  </div>
                  {/* before */}
                  <div className={classes["textual-content"]}>
                    <h3>Before:</h3>
                  </div>
                  <Slider
                    values={[
                      checkInAverages![DRAG_RATING_KEYS[key].KEY].toFixed(1),
                    ]}
                    setValues={[0]}
                    icon={false}
                    isDisabled={true}
                    // eslint-disable-next-line react/style-prop-object
                    style={"average"}
                    otherValues={getOtherValues(key)}
                  />
                  {/* after */}
                  <div
                    className={classes["textual-content"]}
                    style={{
                      marginTop: "calc(1.5rem + 0.5vh)",
                    }}
                  >
                    <h3>After:</h3>
                  </div>
                  <Slider
                    values={[
                      reflectionAverages![DRAG_RATING_KEYS[key].KEY].toFixed(1),
                    ]}
                    setValues={[0]}
                    icon={false}
                    isDisabled={true}
                    // eslint-disable-next-line react/style-prop-object
                    style={"average"}
                    otherValues={getReflectionOtherValues(key)}
                  />
                </div>
              );
            })) || <LoadingWidget />}
          {/* Next Button */}
          <BackAndNext
            backAllowed={false}
            nextAllowed={canAdvance || growthCircleSession?.allowEndOfSession}
            nextCallback={nextHandler}
            backHidden={true}
          />
        </div>
        {showConfirmModal && (
          <ConfirmModal
            hasTitle={true}
            hasMessage={true}
            hasBackDrop={true}
            modalId={REF_RATING_AVG_CONSTANTS.NEXT_MODAL_ID}
            title={"Are you sure?"}
            message={
              "Not everyone has submitted their feedback yet. This will affect everyone's role progress"
            }
            onClick={() => setShowConfirmModal(false)}
            onAccept={() => submitHandler()}
          />
        )}
        <div id={REF_RATING_AVG_CONSTANTS.NEXT_MODAL_ID}></div>
      </MainSessionTemplate>
    </NoGCSessionWrapper>
  );
};

export default ReflectionRatingAverage;
