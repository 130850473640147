import SimpleLoader from "components/Loaders/SimpleLoader";
import AutoResizeTextArea from "components/utility/Inputs/AutoResizeTextArea";
import PageWrapper from "components/utility/PageWrapper";
import { Modal, Tooltip } from "flowbite-react";
import Organisation from "interface/OrganisationInterface";
import classes from "pages/AllCircles/Admin/Admin.module.css";

import { useState } from "react";
import { AiFillCloseCircle, AiFillQuestionCircle } from "react-icons/ai";
import { GiHamburgerMenu } from "react-icons/gi";

interface HeaderTextProps {
  updating: boolean;
  isLoading: boolean;
  setOrganitionHeaderText: (value: React.SetStateAction<string>) => void;
  handleCheckboxChange: (checkboxName: string) => Promise<void>;
  selectedOrganisation: Organisation;
  OrganisationHeaderText: string;
  handleSubmitHeaderText: () => void;
}

const GrowthCircleHeaderText = ({
  updating,
  OrganisationHeaderText,
  handleCheckboxChange,
  setOrganitionHeaderText,
  handleSubmitHeaderText,
  selectedOrganisation,
  isLoading,
}: HeaderTextProps) => {
  const [isVisible, setIsVisible] = useState(false);
  return (
    <>
      <Modal show={isVisible} size={"lg"}>
        <div className="min-h-[60vh] relative">
          <PageWrapper pageType="groundRules">
            <AiFillCloseCircle
              onClick={() => setIsVisible(!isVisible)}
              size={30}
              style={{ color: "var(--icon-colour-0)" }}
              className="cursor-pointer absolute top-2 right-2"
            />
            <div
              className={`w-full bg-white min-h-[20px] flex justify-center items-center p-5`}
            >
              <GiHamburgerMenu
                style={{ color: "var(--main-colour)" }}
                size={30}
                className="absolute left-5"
              />
              <p
                className="font-semibold text-xl"
                style={{ color: "var(--main-colour)" }}
              >
                {OrganisationHeaderText}
              </p>
            </div>
          </PageWrapper>
        </div>
      </Modal>{" "}
      <div general-settings-tour="header-text" className="border-2 p-5">
        <p className="font-semibold py-4 text-xl">GrowthCircles Header Text</p>
        <div className="mb-4 flex gap-2 items-center">
          <p> This will appear on top of each session steps/pages</p>
          <Tooltip content="Click to preview" placement="top">
            <AiFillQuestionCircle
              onClick={() => setIsVisible(!isVisible)}
              size={25}
              style={{ color: "var(--icon-colour-0)" }}
              className="cursor-pointer"
            />
          </Tooltip>
        </div>
        {!updating ? (
          <label className="cursor-pointer">
            <input
              type="checkbox"
              checked={selectedOrganisation?.hasCustomHeaderText ?? false}
              onChange={() => handleCheckboxChange("hasCustomHeaderText")}
              className="my-4 mr-2"
              style={{
                height: "20px",
                width: "20px",
                borderRadius: "5px",
                backgroundColor: selectedOrganisation?.hasCustomHeaderText
                  ? "var(--icon-colour-0)"
                  : "",
              }}
            />
            Show this header (Replacing GrowthCircles default header text)
          </label>
        ) : (
          <SimpleLoader />
        )}

        <div className="w-full md:w-1/2 my-4  py-4 bg-white  p-2 rounded-md border border-gray-300">
          <AutoResizeTextArea
            maxLength={50}
            className="w-full border-none text-lg "
            value={OrganisationHeaderText}
            onChange={(value) => setOrganitionHeaderText(value)}
          />
        </div>

        <button
          disabled={isLoading}
          onClick={handleSubmitHeaderText}
          className={`${classes["button"]} my-4`}
        >
          {isLoading ? "Saving..." : "Save"}
        </button>
      </div>
    </>
  );
};

export default GrowthCircleHeaderText;
