import classes from './ProgressBarCard.module.css';

const ProgressBarCard = ({progress}) => {

   
    //Inline for the progress
    const progressStyle = {
    width: `${progress}%`,
    };
    

  return (
    <div className={classes['progress-bar-container']}>
        <div style={progressStyle} className={classes['progress-bar']}>
           
        </div>
        <span>{progress}%</span>
    </div>
  )
}

export default ProgressBarCard
