import { Dispatch, FC, ReactNode, SetStateAction, useState } from "react";
import BackAndNext from "../BackAndNext";
import Modal from "../Modal";

interface Props {
  isModalOpen: boolean;
  setIsModalOpen: Dispatch<SetStateAction<boolean>>;
  confirmHandler?: () => Promise<any>;
  children: ReactNode;
}

const defaultConfirmHandler = () => new Promise(() => {});

const ConfirmationModal: FC<Props> = ({
  isModalOpen,
  setIsModalOpen,
  confirmHandler = defaultConfirmHandler,
  children
}) => {
  const [disabled, setDisabled] = useState(false);

  const onClick = () => {    
    setDisabled(true);
    confirmHandler()
      .then(() => setDisabled(false));
  }

  return (
    <Modal
      isModalOpen={isModalOpen}
      setIsModalOpen={setIsModalOpen}
    >
      <>
        {children}
        <BackAndNext
          backCallback={() => setIsModalOpen(false)}
          nextCallback={onClick}
          backAllowed={!disabled}
          nextAllowed={!disabled}
          customNextText='Yes'
          useAnimation={false}
        />
      </>
    </Modal>
  );
}

export default ConfirmationModal;