import classes from "pages/AllCircles/Admin/Admin.module.css";
import { Participant } from "interface/ParticipantInterface";
import { ChangeEvent, useEffect, useState } from "react";
import { useAuthContext } from "hooks/useAuthContext";
import toast from "react-hot-toast";
import { useSpecialThanks } from "hooks/useSpecialThanks";
import usePromise from "hooks/utility/usePromise";
import { useGrowthCircleContext } from "hooks/useGrowthCircleContext";
import { SpecialThanksInterface } from "interface/SpecialThanksInterface";
import SpecialThanksItem from "./SpecialThanksItem";
import AutoResizeTextArea from "components/utility/Inputs/AutoResizeTextArea";
import { FaTrashAlt } from "react-icons/fa";
import { Modal } from "flowbite-react";
import { AiFillCloseCircle } from "react-icons/ai";

interface SpecialThanksProps {
  participants: Participant[];
  setHasModal?: (b: boolean) => any;
  isDisabled?: boolean | undefined;
}

const SpecialThanks = ({
  participants,
  setHasModal,
  isDisabled,
}: SpecialThanksProps) => {
  const [selectedParticipantIndex, setSelectedParticipantIndex] = useState(0);
  const [selectedParticipant, setSelectedParticipant] = useState<Participant>(
    participants[0]
  );
  const { growthCircleSession } = useGrowthCircleContext();
  const [message, setMessage] = useState<string>("");
  const [wasSent, setWasSent] = useState(true);
  const { profile } = useAuthContext();
  const sortedParticipants = participants.filter(
    (participant) => participant.userId !== profile?.uid
  );
  const you = participants.filter(
    (participant) => participant.userId === profile?.uid
  );
  const [messages, setMessages] = useState<SpecialThanksInterface[]>([]);
  const { addSpecialThanks, getCurrentSpecialThanks, deleteSpecialThanks } =
    useSpecialThanks();
  const [activeData, setActiveData] = useState<SpecialThanksInterface>();
  const { isLoading, resolve } = usePromise();
  const [show, setShow] = useState<boolean>(false);

  useEffect(() => {
    // setMessage("");
    setSelectedParticipant(sortedParticipants[selectedParticipantIndex]);
    if (selectedParticipant) setExistingMessage();

    // eslint-disable-next-line
  }, [participants, selectedParticipant, messages]);

  useEffect(() => {
    if (!profile || !growthCircleSession) return;
    getCurrentSpecialThanks(growthCircleSession.sessionInstance, setMessages);
    // eslint-disable-next-line
  }, [profile, growthCircleSession]);

  const setExistingMessage = () => {
    if (profile && messages) {
      const _message = messages.filter(
        (message) =>
          message.fromId === profile.id &&
          message.toId === selectedParticipant.userId
      );

      if (_message.length > 0) {
        setActiveData(_message[0]);
        return setMessage(_message[0].message);
      } else {
        if (message === "") {
          return setMessage("");
        }
      }
    }
  };

  const deleteSpecialThanksHandler = async () => {
    if (activeData) {
      resolve(() => deleteSpecialThanks(activeData?.id)).then(() => {
        toast.success("Data was successfully deleted.");
        setMessage("");
        setShow(!show);
      });
    }
  };

  const submitHandler = async () => {
    if (
      message !== "" &&
      profile &&
      growthCircleSession &&
      growthCircleSession?.sessionInstance
    ) {
      resolve(() =>
        addSpecialThanks(
          message,
          selectedParticipant.userId,
          profile.uid,
          growthCircleSession?.sessionInstance
        )
      ).then(() => {
        toast.success(
          `Special thanks was sent to ${selectedParticipant.userName}`
        );
        setWasSent(true);
      });
    } else {
      toast.error("Please specify your message.");
    }
  };

  const participantChangeHandler = (
    index: string,
    e: ChangeEvent<HTMLSelectElement>
  ) => {
    e.preventDefault();
    const newIndex = parseInt(index);
    const previousIndex = selectedParticipantIndex; // Store the previous index

    if (wasSent === false) {
      toast(
        (t) => (
          <div className="flex flex-col w-full">
            <p className="text-center my-4">
              Switch to a different participant without sending the message?
              this will delete unsent messages.
            </p>
            <div className={`${classes["container"]} flex gap-2 mx-auto`}>
              <button
                className={`${classes["button"]}`}
                onClick={async () => {
                  setWasSent(true);
                  setSelectedParticipantIndex(newIndex);
                  setSelectedParticipant(sortedParticipants[newIndex]);
                  if (
                    messages.find(
                      (message) =>
                        message.toId === sortedParticipants[newIndex].userId
                    ) === undefined
                  ) {
                    setMessage("");
                  }
                  toast.dismiss(t.id);
                }}
              >
                Ok
              </button>
              <button
                className={`${classes["button"]}`}
                onClick={() => {
                  toast.dismiss(t.id);
                  e.preventDefault(); // Prevent default behavior of select element
                  setSelectedParticipantIndex(previousIndex);
                  setSelectedParticipant(sortedParticipants[previousIndex]);
                }}
              >
                Cancel
              </button>
            </div>
          </div>
        ),
        {
          duration: 10000,
          style: { width: "300px" },
        }
      );
    } else {
      setSelectedParticipantIndex(newIndex);
      setSelectedParticipant(sortedParticipants[newIndex]);
      if (
        messages.find(
          (message) => message.toId === sortedParticipants[newIndex].userId
        ) === undefined
      ) {
        setMessage("");
      } else {
        setMessage("");
      }
    }
  };

  return (
    <>
      <Modal show={show} size={"md"}>
        <div
          className="min-h-[20vh] relative border-2 rounded-lg"
          style={{ borderColor: "var(--icon-colour-0)" }}
        >
          <AiFillCloseCircle
            onClick={() => {
              setShow(!show);
              if (setHasModal) setHasModal(!show);
            }}
            className="absolute top-2 right-2 cursor-pointer"
            size={30}
            style={{ color: "var(--icon-colour-0)" }}
          />
          <div className="p-5 flex justify-center items-center flex-col mt-6 gap-4">
            <p
              className="font-poppins text-center text-xl"
              style={{ color: "var(--text-colour)" }}
            >
              Are you sure you want to delete this message?
            </p>
            <div
              className={`${classes["container"]} flex gap-4 mt-5 justify-center items-center`}
            >
              <button
                disabled={isLoading}
                className={`${classes["button"]}`}
                onClick={deleteSpecialThanksHandler}
              >
                {isLoading ? "Please wait..." : "Yes"}
              </button>
              {!isLoading && (
                <button
                  disabled={isLoading}
                  className={`${classes["button"]}`}
                  onClick={() => {
                    setShow(!show);
                    if (setHasModal) setHasModal(!show);
                  }}
                >
                  No
                </button>
              )}
            </div>
          </div>
        </div>
      </Modal>
      {sortedParticipants.length > 0 && (
        <div
          className={`${classes["container"]} flex flex-col mx-auto mb-[100px] w-3/4 p-8 rounded-2xl my-4 bg-default border-2 shadow-md`}
          style={{ borderColor: "var(--main-colour)" }}
        >
          <p
            className="font-bold text-xl"
            style={{ color: "var(--main-colour)" }}
          >
            Leave a word of thanks
          </p>
          <p style={{ color: "var(--main-colour)" }} className="text-sm mt-4">
            Select who you wish to thank here :
          </p>
          <select
            value={selectedParticipantIndex}
            className="my-4 border-[var(--main-colour)] focus:border-[var(--main-colour)] focus:ring-0"
            onChange={(e: ChangeEvent<HTMLSelectElement>) =>
              participantChangeHandler(e.target.value, e)
            }
          >
            {sortedParticipants.map((participant, index) => (
              <option key={index} value={index}>
                {participant.userName}
              </option>
            ))}
          </select>

          <div
            className="relative border rounded-lg  bg-white"
            style={{ borderColor: "var(--main-colour)" }}
          >
            <AutoResizeTextArea
              onChange={(value) => {
                setWasSent(false);
                setMessage(value);
              }}
              placeholder="Your message here..."
              value={message}
              className="border-none w-full rounded-lg placeholder:text-gray-200 "
            />
            {message !== "" && (
              <FaTrashAlt
                onClick={() => {
                  setShow(!show);
                  if (setHasModal) setHasModal(!show);
                }}
                className="absolute -top-2 -right-2 cursor-pointer"
                size={20}
                style={{ color: "var(--icon-colour-0)" }}
              />
            )}
          </div>
          <button
            disabled={isLoading || isDisabled}
            onClick={submitHandler}
            className={`${classes["button"]} my-8 shadow-md   mx-auto`}
          >
            {isLoading ? "Sending..." : "Send Message"}
          </button>
          <div>
            {messages.filter((message) => message.toId === you[0].userId)
              .length > 0 && (
              <p className="text-sm font-bold font-poppins">
                Messages for you :
              </p>
            )}

            {messages &&
              messages
                .filter((message) => message.toId === you[0].userId)
                .map((message, index) => {
                  const participant = participants.filter(
                    (participant) => participant.userId === message.fromId
                  );

                  return (
                    <SpecialThanksItem
                      message={message}
                      participant={participant[0]}
                      key={index}
                    />
                  );
                })}
          </div>
        </div>
      )}
      {sortedParticipants.length === 0 && (
        <p>Special thanks will appear if you have 2 or more participants.</p>
      )}
    </>
  );
};

export default SpecialThanks;
