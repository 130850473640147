import React, { useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
import PageWrapper from "components/utility/PageWrapper";
import { useAuthContext } from "hooks/useAuthContext";
// import { useDocument } from "../../../hooks/useDocument";
import classes from "./EnterCode.module.css";
import useJoinParticipant from "hooks/useJoinParticipant";
import useGetParticipants from "hooks/useGetParticipants";
import { useGrowthCircleContext } from "hooks/useGrowthCircleContext";
import usePromise from "hooks/utility/usePromise";
import { WAITING_AREA_ROUTE } from "strings/Routes";
import OrgBubble from "components/Bubble/OrgBubble";

const EnterCode = () => {
  const { profile } = useAuthContext();
  // const { document } = useDocument("GrowthCircles", profile?.growthCircle);
  const { validateInviteCode, isPending, error, errorMessage } =
    useJoinParticipant();
  const { participants } = useGetParticipants();
  const { growthCircleSession } = useGrowthCircleContext();
  const navigate = useNavigate();
  const [userKey, setUserKey] = useState<string>("");
  const { isLoading, resolve } = usePromise();

  useEffect(() => {
    //auto accept user if already entered a correct code
    if (!profile || !participants || !growthCircleSession) return;

    let obj = participants.find((o) => o.userId === profile.id);

    if (obj) {
      navigate(WAITING_AREA_ROUTE);
    }

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [profile, participants, growthCircleSession]);

  useEffect(() => {
    if (!growthCircleSession) return;
    // auto populate userKey if user is the host
    if (profile?.uid === growthCircleSession.facilOwner) {
      const stringValue = growthCircleSession.invite_code.toString();
      setUserKey(stringValue);
      const input = document.querySelector("input[type='tel']");
      const event = new Event("input", { bubbles: true });
      if (input) input.dispatchEvent(event);
    }
  }, [growthCircleSession, profile]);

  const buttonStyle = {
    color: "var(--main-colour-2)",
    backgroundColor: "var(--main-colour)",
  };

  const handleSubmit = (e: React.FormEvent<HTMLFormElement>) => {
    e.preventDefault();
    resolve(() => validateInviteCode(userKey));
  };

  const setUserKeyHandler = (value: string) => {
    const stringValue = value.toString();
    setUserKey(stringValue);
  };
  return (
    <>
      <PageWrapper pageType={"waiting-area"}>
        <div className={classes["wrapper"]}>
          <div className={classes["container"]}>
            <OrgBubble />

            <p className={classes["prompt"]}>GrowthCircle’s Code</p>
            <form onSubmit={handleSubmit}>
              <div
                className={classes["center"] + " " + classes["input-container"]}
              >
                <input
                  className="outline-none focus:border-none focus:ring-0"
                  type="tel"
                  onChange={(e) => setUserKeyHandler(e.target.value)}
                  autoFocus={true}
                  required
                  defaultValue={userKey}
                />
                {error && <p className={classes["error"]}>{errorMessage}</p>}
              </div>

              {/* Submit */}
              <button
                disabled={isLoading}
                className={classes["submitButton"]}
                type="submit"
                style={buttonStyle}
              >
                {isPending || isLoading ? "Verifying..." : "Next"}
              </button>
            </form>
          </div>
        </div>
      </PageWrapper>
    </>
  );
};

export default EnterCode;
