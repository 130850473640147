import { useAuthContext } from "hooks/useAuthContext";
import { useEmailAPI } from "hooks/useEmailAPI";
import { useEffect } from "react";

const SendEmailTest = () => {
  const { profile } = useAuthContext();
  const {
    sendEmail,
    sendMailWantToBeFacil,
    sendEMailVerification,
    generateResetLink,
  } = useEmailAPI();
  useEffect(() => {
    if (profile) return;
  }, [profile]);
  const handleClick = () => {
    sendEmail(profile!.email, profile!.displayName, profile!.growthCircle);
  };

  const handleClick2 = () => {
    sendMailWantToBeFacil(profile!.email, profile!.displayName);
  };

  const handleClick3 = () => {
    sendEMailVerification(profile!.email, profile!.displayName, profile!.uid);
  };

  const handleClick4 = () => {
    generateResetLink(profile!.email);
  };

  return (
    <div className="h-screen flex justify-center items-center">
      <div className="flex gap-4">
        <button
          onClick={handleClick}
          className="bg-green-500 px-4 py-2 text-white rounded-md"
        >
          Send Email
        </button>
        <button
          onClick={handleClick2}
          className="bg-green-500 px-4 py-2 text-white rounded-md"
        >
          Send Want to be a facil
        </button>
        <button
          onClick={handleClick3}
          className="bg-green-500 px-4 py-2 text-white rounded-md"
        >
          Email Verification
        </button>

        <button
          onClick={handleClick4}
          className="bg-green-500 px-4 py-2 text-white rounded-md"
        >
          Generate Link
        </button>
      </div>
    </div>
  );
};

export default SendEmailTest;
