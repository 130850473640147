import { ReactNode } from "react";
import classes from "./Button.module.css";

export interface ButtonProps {
  isDisabled?: boolean;
  text?: string;
  style?: { [key: string]: string | undefined };
  className?: string;
  disabledClassName?: string;
  type?: "button" | "submit" | "reset" | undefined;
  onClick?: any;
  children?: ReactNode
}

const Button: React.FC<ButtonProps> = ({
  isDisabled,
  text,
  style,
  className,
  disabledClassName,
  type,
  onClick,
  children
}) => {
  let finalClassName = classes.button + " "
  + (className ? className : '');

  if (isDisabled) {
    finalClassName += ' ' + classes.disabled + ' '
      + (disabledClassName ? disabledClassName : '');
  }

  return (
    <button
      className={finalClassName}
      style={style}
      type={type}
      onClick={onClick}
    >
      {text}
      { children }
    </button>
  );
};

export default Button;
