import { SelfAwarenessToolInterface } from "interface/SelfAwarenessToolInterface";
import { projectFirestore, timestamp } from "../firebase/config";

import { useEffect, useState } from "react";
import { currentMonthName, currentYearFormat } from "utility/monthName";
import { DocumentData } from "firebase/firestore";
import { Profile } from "interface/ProfileInterface";
import { LearningStoryLineInterface } from "interface/LearningStoryLineInterface";
import { SelfAwarenessJournalInterface } from "interface/SelfAwarenessJournalInterface";

export const useSelfAwareness = () => {
  const [tools, setTools] = useState<SelfAwarenessToolInterface[]>([]);
  const addNewTool = async (data: SelfAwarenessToolInterface) => {
    try {
      const createdAt = timestamp.fromDate(new Date());

      const ref = await projectFirestore
        .collection("SelfAwarenessTools")
        .add({ ...data, createdAt });

      await ref.update({ id: ref.id });
      return ref.id;
    } catch (error) {
      console.error(error);
    }
  };

  const getAllSelfAwarenessTools = () => {
    const unsubscribe = projectFirestore
      .collection("SelfAwarenessTools")

      .onSnapshot(async (snapshot) => {
        const toolMap: SelfAwarenessToolInterface[] = [];

        snapshot.forEach((doc) => {
          const data = doc.data() as SelfAwarenessToolInterface;
          toolMap.push(data);
        });

        // Now 'topicsMap' contains the desired structure with questions grouped by topics

        setTools(toolMap);
        // If you need to set the grouped data in state, you can do so here.
        // For example, if you have a state variable 'groupedQuestions':
        // setGroupedQuestions(topicsMap);
      });

    return () => {
      unsubscribe();
    };
  };

  const getSelfAwarenessTool = async (id: string) => {
    try {
      const docSnapshot = await projectFirestore
        .collection("SelfAwarenessTools")
        .doc(id)
        .get();

      // Check if the document exists
      if (docSnapshot.exists) {
        // Access the document data
        const selfAwarenessToolData =
          docSnapshot.data() as SelfAwarenessToolInterface;
        return selfAwarenessToolData;
      } else {
        // Document with the specified ID does not exist
        console.log("Document not found");
        return null; // or throw an error if you prefer
      }
    } catch (error) {
      console.error("Error getting document:", error);
      throw error; // Rethrow the error for handling in the calling code
    }
  };

  const saveJournalEntry = async (pageType: string, journalTitle: string) => {
    const storedJournal = localStorage.getItem("SelfAwarenessJournal");
    const dataJournal = storedJournal ? JSON.parse(storedJournal) : {};

    try {
      const createdAt = timestamp.fromDate(new Date());
      if (dataJournal.journalId) {
        const ref = await projectFirestore
          .collection("SelfAwarenessTools")
          .doc(dataJournal.journalId)
          .collection("JournalEntries")
          .add({
            ...dataJournal,
            createdAt,
            month: currentMonthName,
            year: currentYearFormat,
            pageType,
            journalTitle,
          });

        await ref.update({ id: ref.id });
        localStorage.removeItem("SelfAwarenessJournal");
        return ref.id;
      } else {
        return false;
      }
    } catch (error) {
      console.error(error);
    }
  };

  const saveJournalEntryFeedback = async (
    selfAwarenessToolsId: string,
    journalId: string,
    feedback: string,
    profile: Profile
  ) => {
    try {
      const createdAt = timestamp.fromDate(new Date());

      // Reference to the specific journal entry document within the SelfAwarenessTools collection
      const journalEntryRef = projectFirestore
        .collection("SelfAwarenessTools")
        .doc(selfAwarenessToolsId)
        .collection("JournalEntries")
        .doc(journalId);

      // Retrieve the current document data
      const doc = await journalEntryRef.get();

      if (!doc.exists) {
        console.error("No such journal entry!");
        return false;
      }

      // Create the new feedback object
      const newFeedback = {
        feedback: feedback,
        feedbackBy: profile.displayName,
        feedBackAt: createdAt,
      };

      // Update the journal entry with the new feedback
      await journalEntryRef.update(newFeedback);

      return journalEntryRef.id;
    } catch (error) {
      console.error(error);
    }
  };

  const saveJournalEntryFeedbackReply = async (
    selfAwarenessToolsId: string,
    journalId: string,
    feedback: string,
    profile: Profile
  ) => {
    try {
      const createdAt = timestamp.fromDate(new Date());

      // Reference to the specific journal entry document within the SelfAwarenessTools collection
      const journalEntryRef = projectFirestore
        .collection("SelfAwarenessTools")
        .doc(selfAwarenessToolsId)
        .collection("JournalEntries")
        .doc(journalId);

      // Retrieve the current document data
      const doc = await journalEntryRef.get();

      if (!doc.exists) {
        console.error("No such journal entry!");
        return false;
      }

      // Create the new feedback object
      const newFeedback = {
        reply: feedback,
        repliedBy: profile.displayName,
        repliedAt: createdAt,
      };

      // Update the journal entry with the new feedback
      await journalEntryRef.update(newFeedback);

      return journalEntryRef.id;
    } catch (error) {
      console.error(error);
    }
  };

  const getSelfAwarenessJournalEntryById = async (
    selfAwarenessToolsId: string,
    journalId: string
  ) => {
    try {
      const journalEntryRef = projectFirestore
        .collection("SelfAwarenessTools")
        .doc(selfAwarenessToolsId)
        .collection("JournalEntries")
        .doc(journalId);

      const doc = await journalEntryRef.get();

      if (!doc.exists) {
        console.error("No such journal entry!");
        return false;
      }

      return doc.data() as SelfAwarenessJournalInterface;
    } catch (error) {
      console.error(error);
    }
  };

  const getQuestionsComp = async (id: string) => {
    try {
      const querySnapshot = await projectFirestore
        .collection("SelfAwarenessTools")
        .doc(id)
        .collection("questions")
        .get();

      // Check if any documents exist
      if (querySnapshot.size > 0) {
        // Access the document data for all documents
        const selfAwarenessToolData = querySnapshot.docs.map((doc) =>
          doc.data()
        );

        return selfAwarenessToolData;
      } else {
        // No documents found
        console.log("No documents found");
        return []; // Return an empty array or throw an error if you prefer
      }
    } catch (error) {
      console.error("Error getting documents:", error);
      throw error; // Rethrow the error for handling in the calling code
    }
  };

  const getPathQuestions = async (id: string) => {
    try {
      const querySnapshot = await projectFirestore
        .collection("SelfAwarenessTools")
        .doc(id)
        .collection("pathQuestions")
        .orderBy("order", "asc")
        .get();

      // Check if any documents exist
      if (querySnapshot.size > 0) {
        // Access the document data for all documents
        const pathQuestions = querySnapshot.docs.map((doc) => doc.data());

        return pathQuestions;
      } else {
        // No documents found
        console.log("No documents found");
        return []; // Return an empty array or throw an error if you prefer
      }
    } catch (error) {
      console.error("Error getting documents:", error);
      throw error; // Rethrow the error for handling in the calling code
    }
  };

  const getGradingQuestions = async (id: string) => {
    try {
      const querySnapshot = await projectFirestore
        .collection("SelfAwarenessTools")
        .doc(id)
        .collection("gradingQuestions")
        .orderBy("order", "asc")
        .get();

      // Check if any documents exist
      if (querySnapshot.size > 0) {
        // Access the document data for all documents
        const pathQuestions = querySnapshot.docs.map((doc) => doc.data());

        return pathQuestions;
      } else {
        // No documents found
        console.log("No documents found");
        return []; // Return an empty array or throw an error if you prefer
      }
    } catch (error) {
      console.error("Error getting documents:", error);
      throw error; // Rethrow the error for handling in the calling code
    }
  };

  const getAllMySelfAwarenessJournal = async (profileId: string) => {
    try {
      // Query SelfAwarenessTools collection
      const toolsSnapshot = await projectFirestore
        .collection("SelfAwarenessTools")
        .get();

      // Array to store results
      let allJournalEntries: DocumentData[] = [];

      // Loop through each SelfAwarenessTools document
      for (const toolsDoc of toolsSnapshot.docs) {
        // Get the reference to the JournalEntries collection inside each SelfAwarenessTools document
        const journalEntriesRef = toolsDoc.ref.collection("JournalEntries");

        // Query JournalEntries collection where profileId is equal to userId
        const querySnapshot = await journalEntriesRef
          .where("userId", "==", profileId)
          .get();

        // Check if any documents exist
        if (querySnapshot.size > 0) {
          // Access the document data for all documents
          const journalEntries = querySnapshot.docs.map((doc) => doc.data());
          allJournalEntries = allJournalEntries.concat(journalEntries);
        }
      }
      allJournalEntries.sort((a, b) => (a.createdAt > b.createdAt ? -1 : 1));

      return allJournalEntries;
    } catch (error) {
      console.error(error);
      throw error; // Rethrow the error if you prefer
    }
  };

  const getAllMySelfAwarenessByUserId = async (userId: string) => {
    try {
      // Query SelfAwarenessTools collection
      const toolsSnapshot = await projectFirestore
        .collection("SelfAwarenessTools")
        .get();

      // Array to store results
      let allJournalEntries: DocumentData[] = [];

      // Loop through each SelfAwarenessTools document
      for (const toolsDoc of toolsSnapshot.docs) {
        // Get the reference to the JournalEntries collection inside each SelfAwarenessTools document
        const journalEntriesRef = toolsDoc.ref.collection("JournalEntries");

        // Query JournalEntries collection where userId is equal to userId
        const querySnapshot = await journalEntriesRef
          .where("userId", "==", userId)
          .get();

        // Check if any documents exist
        if (querySnapshot.size > 0) {
          // Access the document data for all documents
          const journalEntries = querySnapshot.docs.map((doc) => doc.data());
          allJournalEntries = allJournalEntries.concat(journalEntries);
        }
      }
      allJournalEntries.sort((a, b) => (a.createdAt > b.createdAt ? -1 : 1));

      return allJournalEntries;
    } catch (error) {
      console.error(error);
      throw error; // Rethrow the error if you prefer
    }
  };

  //Journey With Sage Functions
  const saveProgress = async (
    profile: Profile,
    progress: string,
    storyLine: string,
    status: string
  ) => {
    try {
      const querySnapshot = await projectFirestore
        .collection("LearningStoryLine")
        .where("user_id", "==", profile.uid)
        .where("storyLine", "==", storyLine)
        .get();

      if (!querySnapshot.empty) {
        // Document with the user_id exists, update it
        querySnapshot.forEach(async (doc) => {
          await doc.ref.update({
            progress,
            status,
            updatedAt: timestamp.fromDate(new Date()),
          });
        });
      } else {
        // Document with the user_id does not exist, add new data
        const ref = await projectFirestore.collection("LearningStoryLine").add({
          createdAt: timestamp.fromDate(new Date()),
          user_id: profile.uid,
          name: profile.displayName,
          email: profile.email,
          progress,
          status,
          storyLine,
        });

        await ref.update({ id: ref.id });
      }
    } catch (error) {
      console.error(error);
    }
  };

  const getProgress = async (userId: string, storyLine: string) => {
    try {
      const querySnapshot = await projectFirestore
        .collection("LearningStoryLine")
        .where("user_id", "==", userId)
        .where("storyLine", "==", storyLine)
        .get();

      if (!querySnapshot.empty) {
        const doc = querySnapshot.docs[0];
        return {
          ...(doc.data() as LearningStoryLineInterface),
        };
      } else {
        console.log("No progress found for the given user_id and storyLine.");
        return null;
      }
    } catch (error) {
      console.error("Error fetching progress:", error);
      return null;
    }
  };

  useEffect(() => {
    if (!tools) return;
    getAllSelfAwarenessTools();
    // eslint-disable-next-line
  }, []);

  return {
    addNewTool,
    tools,
    getSelfAwarenessTool,
    saveJournalEntry,
    getQuestionsComp,
    getPathQuestions,
    getGradingQuestions,
    getAllMySelfAwarenessJournal,
    saveProgress,
    getProgress,
    getAllMySelfAwarenessByUserId,
    saveJournalEntryFeedback,
    getSelfAwarenessJournalEntryById,
    saveJournalEntryFeedbackReply,
  };
};
