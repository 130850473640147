import React, { createContext } from "react";
import { Participant } from "interface/ParticipantInterface";

export interface ShareContextState {
  messageSent: boolean;
  setMessageSent: React.Dispatch<React.SetStateAction<boolean>>;
  currSelectedParticipant: Participant | null;
}

const defaultShareContext = {
  messageSent: false,
  setMessageSent: () => {},
  currSelectedParticipant: null
}

const ShareContext = createContext<ShareContextState>(defaultShareContext);

export default ShareContext;
