import { useContext } from "react"
import { GrowthCircleContext } from "context/GrowthCircleContext";

export const useGrowthCircleContext = () => {
  const context = useContext(GrowthCircleContext);

  if(!context) {
    throw Error('useGrowthCircleContext must be used inside an GrowthCircleContextProvider')
  }

  return context;
}