// general
export const REFLECT_TITLE = "Reflect";
export const SHARE_AND_SUPPORT_TITLE = "Path, Share & Support";
export const SKILL_TITLE = "Skill Description";
export const PAGE_TITLE =
  "Click onto the different coloured tabs at the top to navigate through the tabs";

// reflection
export const REFLECTION_QUESTION_TITLE = "Reflection Questions";
export const REFLECTION_BOX_TITLE = "Reflection";
export const REFLECTION_BOX_PLACEHOLDER = "Type your reflections here";
export const NOTES_BOX_TITLE = "Notes";
export const NOTES_BOX_PLACEHOLDER = "Type your notes here";
export const REFLECTION_COMPULSORY_QUESTIONS = [
  "What makes you say so?",
  "Where are you now?",
  "What challenges are you facing?",
  "What have you tried?",
];
export const INSTRUCTIONS_REFLECT = 'Reflect on your topic by answering the questions in the reflection space below. Capture any interesting insights in the notes section during the sharing.'

// share and support
export const SHARE_QUESTION_CHAT_TITLE = "Other's Question";
export const SHARE_QUESTION_SUBMISSION_TITLE = "What would you like to ask?";
export const SHARE_QUESTION_SUBMISSION_PLACEHOLDER = "Type a question...";
export const INSTRUCTIONS_SHARE_QUESTION = 'Scroll through to view the topics, pathway and role that your peers have selected. You may choose to type your questions to each individual here.'

// skill
export const INSTRUCTIONS_ROLE = 'Here is the role you picked.';
