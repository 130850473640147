import { useState } from "react";

export const useCarousel = (length: number) => {
  const [currentIndex, setCurrentIndex] = useState<number>(0); //Index of carousel

  const next = (): void => {
    if (currentIndex < length - 1) {
      setCurrentIndex((prevState) => prevState + 1);
    }
  };

  const prev = (): void => {
    if (currentIndex > 0) {
      setCurrentIndex((prevState) => prevState - 1);
    }
  };

  const changeIndex = (index: number): void => {
    setCurrentIndex(index);
  };

  return {
    currentIndex,
    next,
    prev,
    changeIndex,
  };
};
