import { useAuthContext } from "hooks/useAuthContext";
import Header from "../../components/Header/Header";
import Navigation from "../../components/Home/Navigation/Navigation";
import PageWrapper from "../../components/utility/PageWrapper";
import { useEffect } from "react";
import { useNavigate } from "react-router-dom";

const Home = () => {
  const { profile } = useAuthContext();
  const navigate = useNavigate();
  useEffect(() => {
    if (!profile) return;
    if (profile.changePassword && profile.changePassword === true) {
      navigate("/account/change-password");
    }
    // eslint-disable-next-line
  }, [profile]);
  return (
    <>
      <PageWrapper pageType="groundRules">
        <Header />
        <Navigation />
      </PageWrapper>
    </>
  );
};

export default Home;
