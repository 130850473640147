import { Tooltip } from "flowbite-react";
import { FaQuestionCircle } from "react-icons/fa";

interface TextInputHelpersProps {
  text: string;
  helpText?: string;
}

const TextInputHelpers = ({ text, helpText }: TextInputHelpersProps) => {
  return (
    <p className="text-md my-2 font-semibold flex gap-2 items-center">
      {text}{" "}
      {helpText && (
        <Tooltip content={helpText} placement="top">
          <FaQuestionCircle
            style={{ color: "var(--icon-colour-5)" }}
            size={18}
          />
        </Tooltip>
      )}
    </p>
  );
};

export default TextInputHelpers;
