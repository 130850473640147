import NavAdmin from "components/Admin/NavAdmin";
import SidebarAdmin from "components/Admin/SidebarAdmin";
import DashboardCard from "./Dashboard/DashboardCard";
import FilterHeader from "./Dashboard/FilterHeader";

import { Suspense, lazy, useCallback, useEffect, useState } from "react";

import { HierarchicalNames, useDashboard } from "hooks/useDashboard";
//import { getGroupByGroupName } from "models/organisationGroup";
import classes from "./Admin.module.css";

import DashboardSettings from "components/Organisation/DashboardSettings/DashboardSettings";
import useOrganisationContext from "hooks/organisation/useOrganisationContext";
import usePermissionContext from "hooks/permissions/usePermissionContext";
import { useAuthContext } from "hooks/useAuthContext";
import usePromise from "hooks/utility/usePromise";
import { Profile } from "interface/ProfileInterface";
import {
  D_INTENTIONS_AND_TAKEWAYS,
  D_LEVEL_OF_CLARITY,
  D_LEVEL_OF_CLARITY_CUSTOM,
  D_NUMBER_OF_ACCOUNTS,
  D_NUMBER_OF_TIMES_FACILITATORS_MET_PARTICIPANTS,
  D_STEP_5_REFLECTION_QUALITATIVE,
  D_STEP_5_REFLECTION_QUANTITATIVE,
  D_TOP_5_TOPICS,
  Statistics,
  checkHasStatistics,
  dashboardStatsCustomDescriptionGenerator,
  dashboardStatsCustomNameGenerator,
  defaultDashboardStat,
  defaultDashboardStats,
  getDashboardStatsByOrganisationId,
  getStatisticTitleSplit,
} from "models/dashboardStats";
import { getOrgsWithPermission } from "models/organisation";
import { P_CAN_EDIT_DASHBOARD, P_CAN_VIEW_DASHBOARD } from "models/permission";
import QuantitativeFeedback from "./Dashboard/QuantitativeFeedback";
import IntentionsAndTakeways from "./Dashboard/IntentionsAndTakeways"; 

const QualitativeFeedback = lazy(
  () => import("./Dashboard/QualitativeFeedback")
);
const OrganisationStackBarComponent = lazy(
  () => import("./Dashboard/OrganisationStackBarComponent")
);
const OrganisationOtherStackBarComponent = lazy(
  () => import("./Dashboard/OrganisationOtherStackBarComponent")
);
const AccountCount = lazy(() => import("./Dashboard/AccountCount"));
const TopicsBoard = lazy(() => import("./Dashboard/TopicsBoard"));
const FacilMeetingNumbers = lazy(
  () => import("./Dashboard/FacilMeetingNumbers")
);

export default function Admin() {
  const {
    startDate,
    setStartDate,
    endDate,
    setEndDate,
    profiles,
    setProfiles,
    allProfiles,
    groups,
    setGroups,
    allGroups,
    allGroupsData,
    filteredParticipantHistory,
    beforeAverages,
    afterAverages,
    averagesCount,
    beforeCustomAverages,
    afterCustomAverages,
    customAveragesCount,
    rankedTopics,
    userCounts,
    averageFeedback,
    qualitativeFeedback,
    intentions,
    takeawaysEntries,
    facilNumbersMeet,
    actionStepsEntries,
  } = useDashboard();
 
  const elementsAfterQuantitative = 2;
  const { selectedOrganisation, selectOrganisationByName } =
    useOrganisationContext();
  const { checkHasPermission } = usePermissionContext();
  const [options, setOptions] = useState<string[]>([]);
  const [statistics, setStatistics] = useState<Statistics>({});
  const { profile } = useAuthContext();
  const { isLoading, resolve } = usePromise();
  const [displayDashboardSettings, setDisplayDashboardSettings] =
    useState(false);
  const canEditDashboard =
    selectedOrganisation &&
    checkHasPermission(selectedOrganisation.id, P_CAN_EDIT_DASHBOARD);

  const getOrgsWithDashboardPermission = useCallback((profile: Profile) => {
    return getOrgsWithPermission(profile, P_CAN_VIEW_DASHBOARD);
  }, []);

  const handleDisplayDashboardSettings = () => {
    setDisplayDashboardSettings(true);
  };

  useEffect(() => {
    if (!selectedOrganisation) return;
    getDashboardStatsByOrganisationId(selectedOrganisation.id).then(
      (dashboardStats) => {
        if (!dashboardStats) {
          return;
        }
        var stats: Statistics = {};

        if (
          !dashboardStats ||
          dashboardStats.statistics[D_LEVEL_OF_CLARITY] === undefined
        ) {
          stats = defaultDashboardStats;
        } else {
          stats = dashboardStats.statistics;
        }
        const newStats = {};
        var orderCount = Object.keys(stats).length - elementsAfterQuantitative + 1;
        for (const [key] of averageFeedback) {
          const id = D_STEP_5_REFLECTION_QUANTITATIVE.concat(
            key.replace(
              new RegExp(" ".replace(/[.*+?^${}()|[\]\\]/g, "\\$&"), "g"),
              ""
            )
          );

          if (stats[id] === undefined) {
            newStats[id] = defaultDashboardStat(
              id,
              dashboardStatsCustomNameGenerator(
                D_STEP_5_REFLECTION_QUANTITATIVE,
                key
              ),
              dashboardStatsCustomDescriptionGenerator(
                D_STEP_5_REFLECTION_QUANTITATIVE,
                key
              ),
              true,
              orderCount
            );
            orderCount += 1;
          }
        }
        if (Object.keys(newStats).length > 0) {
          var length = Object.keys(newStats).length;
          var prevLength = Object.keys(stats).length;
          for (const [key, value] of Object.entries(stats)) {
            if (
              (value["order"] as number) >=
              prevLength - elementsAfterQuantitative + 1
            ) {
              // before everything except qualitative feedback
              newStats[key] = {
                ...value,
                order: (value["order"] as number) + length,
              };
            }
          }
        }
        setStatistics({ ...stats, ...newStats });
      }
    );
    // eslint-disable-next-line
  }, [selectedOrganisation, averageFeedback]);

  useEffect(() => {
    if (!profile) {
      return;
    }

    const fetchData = async () => {
      try {
        const orgs = await getOrgsWithDashboardPermission(profile);
        const org_names = orgs
          .filter((org) => !org.isAType)
          .map((org) => org.name)
          .sort();
        selectOrganisationByName(org_names[0]);
        return org_names;
      } catch (error) {
        // Handle errors here
        console.error(error);
        throw error; // Rethrow the error to be caught later if needed
      }
    };

    resolve(() => fetchData())
      .then((org_names) => {
        if (org_names) setOptions(org_names);
      })
      .catch((error) => {
        // Handle errors from fetchData or setOptions here
        console.error(error);
      });

    // eslint-disable-next-line
  }, [profile]);

  return (
    <div className="min-h-screen h-full ">
      <NavAdmin />
      <div className={`${classes.container} min-h-screen`}>
        <SidebarAdmin />
        <div
          className={`${classes.main} overflow-y-auto max-h-[95vh] mb-[100px]`}
        >
          {displayDashboardSettings && (
            <DashboardSettings
              setDisplayDashboardSettings={setDisplayDashboardSettings}
              setStatistics={setStatistics}
              statistics={statistics}
            />
          )}
          {!displayDashboardSettings && (
            <>
              <div className="flex flex-col md:flex-row items-start gap-6">
                <FilterHeader
                  options={options}
                  startDate={startDate}
                  setStartDate={setStartDate}
                  endDate={endDate}
                  setEndDate={setEndDate}
                  profiles={profiles}
                  setProfiles={setProfiles}
                  allProfiles={allProfiles.map((profile) => profile + " (" + userCounts[profile] + ")")}
                  groups={groups}
                  setGroups={setGroups}
                  allGroups={allGroups.map(group => {
                    const userCount = allGroupsData[group.name]?.users.length || 0;

                    // For calculating users in main group and subgroup(s)
                    const totalUserCount = new Set(Object.values(allGroupsData[group.name]?.subGroupUsers || {}).flat()).size;
                  
                    const updatedGroup: HierarchicalNames = {
                      name: group.name.includes('& Subgroups') 
                      ? `${group.name} (${totalUserCount})` 
                      : `${group.name} (${userCount})`,
                      subGroupNames: group.subGroupNames.map(subGroupName => {
                        const subGroupUserCount = allGroupsData[group.name]?.subGroupUsers[subGroupName]?.length || 0;
                        return `${subGroupName} (${subGroupUserCount})`;
                      })
                    };
                  
                    return updatedGroup;
                  })}
                />
                {canEditDashboard && (
                  <button
                    className={`${classes["button"]} p-7`}
                    onClick={handleDisplayDashboardSettings}
                  >
                    Edit Dashboard Settings
                  </button>
                )}
              </div>
              <div
                className="grid gap-6"
                style={{
                  gridTemplateColumns: "repeat(auto-fit, minmax(500px, 1fr))",
                }}
              >
                {checkHasStatistics(statistics, D_LEVEL_OF_CLARITY) && (
                  <DashboardCard
                    titles={getStatisticTitleSplit(
                      statistics,
                      D_LEVEL_OF_CLARITY
                    )}
                    key={D_LEVEL_OF_CLARITY}
                  >
                    <Suspense fallback="Loading...">
                      <OrganisationStackBarComponent
                        isLoading={isLoading}
                        beforeAverages={beforeAverages}
                        afterAverages={afterAverages}
                        count={averagesCount}
                      />
                    </Suspense>
                  </DashboardCard>
                )}

                {checkHasStatistics(statistics, D_LEVEL_OF_CLARITY_CUSTOM) &&
                  (Object.entries(beforeCustomAverages).length !== 0 ||
                    Object.entries(afterCustomAverages).length !== 0) && (
                    <DashboardCard
                      titles={getStatisticTitleSplit(
                        statistics,
                        D_LEVEL_OF_CLARITY_CUSTOM
                      )}
                      key={D_LEVEL_OF_CLARITY_CUSTOM}
                    >
                      <Suspense fallback="Loading...">
                        <OrganisationOtherStackBarComponent
                          isLoading={isLoading}
                          beforeAverages={beforeCustomAverages}
                          afterAverages={afterCustomAverages}
                          counts={customAveragesCount}
                        />
                      </Suspense>
                    </DashboardCard>
                  )}

                {checkHasStatistics(statistics, D_TOP_5_TOPICS) && (
                  <DashboardCard
                    titles={getStatisticTitleSplit(statistics, D_TOP_5_TOPICS)}
                    key={D_TOP_5_TOPICS}
                  >
                    <Suspense fallback="Loading...">
                      <TopicsBoard
                        topics={rankedTopics}
                        isLoading={isLoading}
                      />
                    </Suspense>
                  </DashboardCard>
                )}

                {checkHasStatistics(statistics, D_NUMBER_OF_ACCOUNTS) && (
                  <DashboardCard
                    titles={getStatisticTitleSplit(
                      statistics,
                      D_NUMBER_OF_ACCOUNTS
                    )}
                    className="items-center"
                    key={D_NUMBER_OF_ACCOUNTS}
                  >
                    <Suspense fallback="Loading...">
                      <AccountCount
                        isLoading={isLoading}
                        organisationName={selectedOrganisation?.name}
                        userCounts={userCounts}
                      />
                    </Suspense>
                  </DashboardCard>
                )}
                {selectedOrganisation &&
                  checkHasStatistics(
                    statistics,
                    D_NUMBER_OF_TIMES_FACILITATORS_MET_PARTICIPANTS
                  ) && (
                    <DashboardCard
                      titles={getStatisticTitleSplit(
                        statistics,
                        D_NUMBER_OF_TIMES_FACILITATORS_MET_PARTICIPANTS
                      )}
                      key={D_NUMBER_OF_TIMES_FACILITATORS_MET_PARTICIPANTS}
                    >
                      {" "}
                      {facilNumbersMeet && selectedOrganisation && (
                        <Suspense fallback={"loading..."}>
                          <FacilMeetingNumbers
                            isLoading={isLoading}
                            organisationName={selectedOrganisation?.name}
                            data={facilNumbersMeet}
                          />
                        </Suspense>
                      )}
                    </DashboardCard>
                  )}

                <QuantitativeFeedback
                  isLoading={isLoading}
                  averages={averageFeedback}
                  statistics={statistics}
                />
              </div>
              <div className="flex flex-col">
                {checkHasStatistics(
                  statistics,
                  D_STEP_5_REFLECTION_QUALITATIVE
                ) && (
                  <DashboardCard
                    titles={getStatisticTitleSplit(
                      statistics,
                      D_STEP_5_REFLECTION_QUALITATIVE
                    )}
                    className="md:!min-h-[200px]"
                  >
                    <Suspense fallback="Loading...">
                      <QualitativeFeedback
                        qualitativeFeedback={qualitativeFeedback}
                        isLoading={isLoading}
                      />
                    </Suspense>
                  </DashboardCard>
                )}

                {checkHasStatistics(statistics, D_INTENTIONS_AND_TAKEWAYS) && (
                  <DashboardCard
                    titles={getStatisticTitleSplit(
                      statistics,
                      D_INTENTIONS_AND_TAKEWAYS
                    )}
                    className="md:!min-h-[200px]">
                    <Suspense fallback="Loading...">
                      <IntentionsAndTakeways
                        intentions={intentions}
                        takeawaysEntries={takeawaysEntries}
                        participantHistory={filteredParticipantHistory ?? []}
                        actionStepsEntries={actionStepsEntries}
                        isLoading={isLoading}
                      />
                    </Suspense>
                  </DashboardCard>
                )}
              </div>
            </>
          )}
        </div>
      </div>
    </div>
  );
}
