import Connection from "assets/SelfAwareness/icons/Connection.png";
import Wellbeing from "assets/SelfAwareness/icons/Wellbeing.png";
import Honesty from "assets/SelfAwareness/icons/Honesty.png";
import Play from "assets/SelfAwareness/icons/Play.png";
import Peace from "assets/SelfAwareness/icons/Peace.png";
import Autonomy from "assets/SelfAwareness/icons/Autonomy.png";
import Meaning from "assets/SelfAwareness/icons/Meaning.png";
import Love from "assets/SelfAwareness/icons/Love.png";
import Joy from "assets/SelfAwareness/icons/Joy.png";
import Surprise from "assets/SelfAwareness/icons/Surprise.png";
import Sadness from "assets/SelfAwareness/icons/Sadness.png";
import Anger from "assets/SelfAwareness/icons/Anger.png";
import Fear from "assets/SelfAwareness/icons/Fear.png";

export const SelfAwarenessIconMapping = {
  Connection,
  "Physical Well-being": Wellbeing,
  Honesty,
  Play,
  Peace,
  Autonomy,
  Meaning,
  Love,
  Joy,
  Surprise,
  Sadness,
  Anger,
  Fear,
};
