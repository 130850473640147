import { Modal } from "flowbite-react";
import usePromise from "hooks/utility/usePromise";
import { FormEvent, useEffect, useState } from "react";
import { AiFillCloseCircle } from "react-icons/ai";
import { FaEdit, FaTimes } from "react-icons/fa";
import classes from "pages/AllCircles/Admin/Admin.module.css";
import { HiOutlineExclamationCircle } from "react-icons/hi";
import SimpleLoader from "components/Loaders/SimpleLoader";
import { useOrgFeedbackQuestions } from "hooks/organisation/useOrgFeedbackQuestions";
import toast from "react-hot-toast";
import TextAreaMax from "components/Organisation/WelcomeSettings/components/TextAreaMax";

type Props = {
  id: string | undefined;
  uid: string | undefined;
  choices: string[];
  choice: string;
  onChange: (uid: string, checked: boolean) => void;
  values: string; //this is a single string need to convert it into an array and delaminate ","
  editable: boolean | undefined;
  isDisabled?: boolean;
};

const ChoiceItem = ({
  id,
  uid,
  choice,
  onChange,
  values,
  editable,
  choices,
  isDisabled,
}: Props) => {
  const [checked, setChecked] = useState(false); // Initialize state with false
  const [deleteShow, setDeleteShow] = useState(false);
  const [editShow, setEditShow] = useState(false);
  const { isLoading, resolve } = usePromise();
  const [valuesArray, setValuesArray] = useState<string[]>([]);
  const [others, setOthers] = useState("");
  const [otherIsSaving, setOthersIsSaving] = useState(false);
  const [choiceUpdate, setChoice] = useState(choice ?? "");
  const toggleCheckBox = () => {
    if (choice.toLocaleLowerCase() !== "others") {
      onChange(choice, !checked);
    }
    setChecked(!checked);
  };
  const { RemoveChoice, UpdateChoice } = useOrgFeedbackQuestions();

  const deleteQuestionHandler = async () => {
    resolve(() => RemoveChoice(id, uid, choice)).then(() => {
      toast.success("Item was deleted successfully");
      setDeleteShow(!deleteShow);
    });
  };

  const setChoiceHandler = (value: string) => {
    setChoice(value);
  };

  const submitHandler = async (e: FormEvent) => {
    e.preventDefault();
    resolve(() => UpdateChoice(id, uid, choice, choiceUpdate)).then(() => {
      toast.success("Item was updated successfully");
      setEditShow(!editShow);
    });
  };

  useEffect(() => {
    if (!values || typeof values !== "string") return; // Check if values is not a string
    if (values !== "") {
      const checkedChoicesArray = values.split("|");
      setValuesArray(checkedChoicesArray);
    }
  }, [values]);

  const valueHandler = (value: string) => {
    setOthers(value);
  };

  const otherSaveHandler = async (e: FormEvent) => {
    e.preventDefault();
    setOthersIsSaving(true);
    onChange(others, true);
    const countdown = setTimeout(() => {
      setOthersIsSaving(false);
    }, 1200);
    toast.success("Data was saved successfully!");
    return () => clearTimeout(countdown);
  };

  useEffect(() => {
    // eslint-disable-next-line
    valuesArray.map((value) => {
      if (!choices.includes(value)) {
        if (choice.toLocaleLowerCase() === "others") {
          setChecked(true);
          setOthers(value);
        }
      }
      if (value === choice) {
        setChecked(true);
      }
    });

    // eslint-disable-next-line
  }, [valuesArray]);

  return (
    <>
      <Modal
        show={deleteShow}
        size="xl"
        popup
        onClose={() => setDeleteShow(!deleteShow)}
      >
        <Modal.Body className="relative py-10 rounded-md">
          <>
            {!isLoading ? (
              <>
                {" "}
                <AiFillCloseCircle
                  size={35}
                  color="var(--icon-colour-0)"
                  onClick={() => setDeleteShow(!deleteShow)}
                  className="absolute top-2 right-2 cursor-pointer"
                />
                <div
                  className={`${classes["container"]} text-center flex justify-center flex-col items-center`}
                >
                  <HiOutlineExclamationCircle
                    size={82}
                    className="mx-auto mb-4  text-gray-400 dark:text-gray-200"
                  />
                  <h3 className="mb-5 text-lg font-normal text-gray-500 dark:text-gray-400">
                    Are you certain about your decision to delete the{" "}
                    <strong>{choice}</strong> item? This action is Irreversible
                  </h3>

                  <div className="flex justify-center gap-4">
                    <button
                      className={`${classes["button"]}`}
                      color="failure"
                      onClick={deleteQuestionHandler}
                    >
                      Yes, I'm sure
                    </button>
                    <button
                      className={`${classes["button"]}`}
                      color="gray"
                      onClick={() => setDeleteShow(!deleteShow)}
                    >
                      No, cancel
                    </button>
                  </div>
                </div>
              </>
            ) : (
              <SimpleLoader center={true} />
            )}
          </>
        </Modal.Body>
      </Modal>
      <Modal
        show={editShow}
        size="xl"
        popup
        onClose={() => setEditShow(!editShow)}
      >
        <Modal.Body className="relative py-10 rounded-md">
          <>
            {!isLoading ? (
              <>
                {" "}
                <AiFillCloseCircle
                  size={35}
                  color="var(--icon-colour-0)"
                  onClick={() => setEditShow(!editShow)}
                  className="absolute top-2 right-2 cursor-pointer"
                />
                <div
                  className={`${classes["container"]} flex flex-col   w-full`}
                >
                  <p className="font-semibold text-standard">Edit Choice</p>
                  <form onSubmit={submitHandler}>
                    <div className="w-full">
                      <TextAreaMax
                        value={choiceUpdate}
                        valueHandler={setChoiceHandler}
                        maxLength={150}
                        row={3}
                        required={true}
                      />
                    </div>
                    <button
                      type="submit"
                      className={`${classes["button"]} float-right`}
                    >
                      Save
                    </button>
                  </form>
                </div>
              </>
            ) : (
              <SimpleLoader center={true} />
            )}
          </>
        </Modal.Body>
      </Modal>
      <div className="flex justify-between w-full">
        <label className="cursor-pointer flex text-standard justify-start items-start text-left">
          <input
            disabled={isDisabled}
            type="checkbox"
            checked={checked} // Use checked state directly
            onChange={toggleCheckBox} // Call toggleCheckBox function on change
            className="mx-4"
            style={{
              height: "20px",
              width: "20px",
              marginTop: "2px",
              borderRadius: "5px",
              backgroundColor: checked ? "var(--main-colour)" : "", // Adjust styling based on checked state
            }}
          />
          {choice}
        </label>

        {editable && (
          <div className="flex justify-center items-center gap-2">
            <FaTimes
              onClick={() => setDeleteShow(!deleteShow)}
              size={20}
              className="text-red-700 cursor-pointer"
            />
            <FaEdit
              onClick={() => setEditShow(!editShow)}
              size={20}
              className="text-sky-600 cursor-pointer"
            />
          </div>
        )}
      </div>
      <div className={`${classes["container"]} w-full block`}>
        {choice.toLocaleLowerCase() === "others" && checked && (
          <form onSubmit={otherSaveHandler}>
            <TextAreaMax
              readonly={isDisabled}
              maxLength={200}
              row={5}
              value={others}
              valueHandler={valueHandler}
            />

            {!isDisabled && (
              <button
                disabled={otherIsSaving}
                className={`${classes["button"]}`}
                type="submit"
              >
                {otherIsSaving ? "Saving..." : "Save"}
              </button>
            )}
          </form>
        )}
      </div>
    </>
  );
};

export default ChoiceItem;
