import { FC, useState } from "react";
import classes from "./TotalScoreRow.module.css";
import FirstNameAvatar from "components/utility/FirstNameAvatar";
import useGetParticipants from "hooks/useGetParticipants";
import { Participant } from "interface/ParticipantInterface";
import React from "react";
import { Modal } from "flowbite-react";
import { MdCancel } from "react-icons/md";
import TotalScoreModal from "./TotalScoreModal";
import Organisation from "interface/OrganisationInterface";

interface Props {
  beforeTotal?: number;
  afterTotal?: number;
  name: string;
  participant: Participant;
  setIsModal: (b) => any;
  organisation?: Organisation;
}

const TotalScoreRow: FC<Props> = ({
  beforeTotal,
  afterTotal,
  name,
  participant,
  setIsModal,
  organisation,
}) => {
  const { getParticipantColourIndex } = useGetParticipants();
  const [openModal, setOpenModal] = useState(false);

  const maxRating = organisation?.maxRating ? organisation.maxRating * 4 : 40;
  const flagRatingPercentage = organisation?.flagRatingPercentage ? organisation.flagRatingPercentage : 60;
  const flagValue = (flagRatingPercentage / 100) * maxRating;

  const isBelow = () => {
    
    if (beforeTotal && beforeTotal < flagValue) {
      return `text-red-500`;
    }
    if (afterTotal && afterTotal < flagValue && Math.sign(afterTotal) !== -1) {
      return `text-red-500`;
    }
    return ``;
  };

  return (
    <React.Fragment>
      <Modal
        dismissible
        show={openModal}
        size="md"
        className="min-h-[100vh]"
        onClose={() => {
          setOpenModal(false);
          setIsModal(false);
        }}
      >
        <Modal.Body>
          <div className="absolute right-0 top-0 p-2">
            <MdCancel
              size={30}
              className="cursor-pointer"
              style={{
                color: "var(--main-colour)",
              }}
              onClick={() => {
                setOpenModal(false);
                setIsModal(false);
              }}
            />
          </div>
          <div className="flex justify-center items-center">
            <TotalScoreModal
              organisation={organisation}
              participant={participant}
            />
          </div>
        </Modal.Body>
      </Modal>
      <div
        className={`${classes["container"]} shadow-md cursor-pointer`}
        onClick={() => {
          setOpenModal(true);
          setIsModal(true);
        }}
      >
        <div className={classes["picture"]}>
          <FirstNameAvatar
            name={participant.userName}
            participant={participant}
            styleTriggered={true}
            participantNumber={getParticipantColourIndex(participant.userId)}
          />
        </div>
        <div className={`${isBelow()} w-28 overflow-hidden text-ellipsis`}>
          {name}
        </div>
        <div
          className={`justify-self-center ${
            beforeTotal && beforeTotal < flagValue ? `text-red-500` : ``
          }`}
        >
          {beforeTotal ? `${beforeTotal}/40` : ""}
        </div>
        <div
          className={`justify-self-center ${
            afterTotal && afterTotal < flagValue && Math.sign(afterTotal) !== -1
              ? `text-red-500`
              : ``
          }`}
        >
          {afterTotal && Math.sign(afterTotal) === 1 ? `${afterTotal}/40` : ""}
        </div>
      </div>
    </React.Fragment>
  );
};

export default TotalScoreRow;
