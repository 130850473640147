import { useEffect, useState } from "react";
import classes from "./IntroRating.module.css";
import { useAuthContext } from "hooks/useAuthContext";
// import useParticipants from "hooks/useParticipants";
import EmptySpace from "components/utility/EmptySpace";
// import { DRAG_RATING_KEYS, useDragRating } from "../../../hooks/useDragRating";
// import { LocalStorageKeys } from "App";
// import { useComponentDidUpdate } from "hooks/useComponentDidUpdate";
// import { CheckInCheckOut } from "interface/ParticipantInterface";
import SliderCustom from "components/SliderCustom/SliderCustom";
import BackAndNext from "components/utility/BackAndNext";
import { ComponentProps } from "../GenericSessionPage/ComponentMapping";
import useOrganisationContext from "hooks/organisation/useOrganisationContext";
import { useOrgORS } from "hooks/organisation/useOrgORS";
import toast from "react-hot-toast";
import useParticipants from "hooks/useParticipants";
import SimpleLoader from "components/Loaders/SimpleLoader";

/**
 * Page that includes 4 drag sliders to rate their checkIns
 */
const IntroOrgRatingComponent = ({ backAndNextHandler }: ComponentProps) => {
  const { profile } = useAuthContext();
  // const userStorageKey = profile!.id + "_";
  const { updateSessionORScheckInValues } = useParticipants();

  const { selectedOrganisation } = useOrganisationContext();
  const [isAnyZero, setIsAnyZero] = useState(false); // Initialize as false
  const { ors } = useOrgORS();
  const initialQuestionValues = {};

  // Function to initialize questionValues
  const initializeQuestionValues = () => {
    ors.forEach((question) => {
      initialQuestionValues[question.title.replace(/ /g, "_")] = 1;
    });

    // Set questionValues to the initialized values
    setQuestionValues(initialQuestionValues);
  };

  useEffect(() => {
    if (!ors) return;
    initializeQuestionValues();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [ors, profile]);

  const [questionValues, setQuestionValues] = useState(initialQuestionValues);

  useEffect(() => {
    if (!selectedOrganisation) return;
  }, [selectedOrganisation]);

  const isAnyValueZero = () => {
    // Check for zero values in questionValues
    for (const questionId in questionValues) {
      if (questionValues[questionId] === 0) {
        return true; // Found a value equal to 0
      }
    }
    return false; // No value is equal to 0
  };

  useEffect(() => {
    // Function to check if any value is equal to 0
    if (!questionValues) return;

    // Update local storage key "ORSquestionValues" with the new questionValues
    localStorage.setItem("ORSquestionValues", JSON.stringify(questionValues));

    const hasZeroValue = isAnyValueZero();
    setIsAnyZero(hasZeroValue);

    // eslint-disable-next-line
  }, [questionValues]);

  const removeDragRatingsFromStorage = () => {
    localStorage.removeItem("ORSquestionValues");
  };

  const submitHandler = async () => {
    if (isAnyZero) {
      toast.error("Please complete the ORS before proceeding");
    } else {
      await updateSessionORScheckInValues(questionValues);
      removeDragRatingsFromStorage();
    }
  };

  return (
    <>
      <EmptySpace height={"1rem"} />
      <div className={classes.description}>
        {selectedOrganisation?.customORSTitle !== ""
          ? selectedOrganisation?.customORSTitle
          : "How have you been feeling the past week in the following areas?"}
      </div>
      {ors && localStorage.getItem("ORSquestionValues") ? (
        ors.map((question) => (
          <div
            key={question.id}
            className="w-[320px] md:w-[400px] mx-auto mt-5"
          >
            <div className="w-full mx-auto">
              <div className={`${classes.box} shadow-md`}>
                <div className={`${classes.header}`}>{question.title}</div>
                <div className={classes["header-description"]}>
                  {question.sub_title}
                </div>
                <div className="flex justify-center px-2">
                  {/* Step 2: Create state variables for each question */}
                  <SliderCustom
                    maxValue={selectedOrganisation?.maxRating ?? 10}
                    withBackground={true}
                    value={
                      questionValues[question.title.replace(/ /g, "_")] ||
                      question.min
                    }
                    setValue={(value) => {
                      // Step 3: Update the state variable when the slider changes
                      setQuestionValues((prevValues) => ({
                        ...prevValues,
                        [question.title.replace(/ /g, "_")]: value,
                      }));

                      // Check for zero values when a slider value changes
                      const hasZeroValue = isAnyValueZero();
                      setIsAnyZero(hasZeroValue);
                    }}
                  />
                </div>
              </div>
            </div>
          </div>
        ))
      ) : (
        <SimpleLoader />
      )}

      <EmptySpace height={"40px"} />
      <BackAndNext
        {...backAndNextHandler}
        nextCallback={(e) => {
          submitHandler();
          backAndNextHandler.nextCallback(e);
        }}
        nextAllowed={isAnyZero === false && backAndNextHandler.nextAllowed}
      />
    </>
  );
};

export default IntroOrgRatingComponent;
