import { useCallback } from "react";
import {
  getRoleById,
  updateRolePermissions as _updateRolePermissions,
  FIRESTORE_SUBPATH_ROLES,
} from "models/organisationRole";
import { Role } from "models/organisationRole";
import { Permissions } from "models/permission";
import { useListSelect } from "hooks/utility/useListSelect";
import { useAuthContext } from "hooks/useAuthContext";
import { collection, onSnapshot } from "firebase/firestore";
import { FIRESTORE_PATH_ORGANISATIONS } from "models/organisation";
import { projectFirestore } from "../../firebase/config";
import { WithId } from "utility/model";

export interface OrganisationRoleHook {
  roles: Role[];
  selectedRole: Role | null;
  selectFirstRole: () => void;
  selectRoleByName: (roleName: string) => void;
  updateRolePermissions: (
    permissions: Permissions,
    updatePermissionContext: (
      organisationId: string,
      roleName: string,
      permission: Permissions
    ) => void
  ) => Promise<void>;
  setRoles: (newRoles: WithId<Role>[]) => void; // Method to set or update roles
  updateRoleSettings: (
    updatedRoles: {
      name: string;
      isEnable: boolean;
    }[]
  ) => Promise<void>;
  updateAverageRoleCalc: (
    updatedRoles: {
      name: string;
    }[],
    toggledRoles: Role[]
  ) => Promise<void>;
}

/**
 * Keeps the state of a list of an organisation's roles and the currently selected role.
 *
 * @param organisationId Id of the organisation to manage.
 * @returns OrganisationRoleHook.
 */
export default function useOrganisationRole(
  organisationId: string
): OrganisationRoleHook {
  const { profile } = useAuthContext();

  /*
  const fetchRoles = useCallback(async () => {
    if (!organisationId) {
      return [];
    }

    return getRoles(organisationId).then((roles) => {
      return roles.sort((r1, r2) => r1.name.localeCompare(r2.name));
    });
  }, [organisationId]);
  */
  //const [roles, setRoles] = useState<Role[]>([]);

  const fetchRoles = useCallback((): Promise<WithId<Role>[]> => {
    return new Promise((resolve, reject) => {
      if (!organisationId) {
        resolve([]);
        return;
      }

      const collectionRef = collection(
        projectFirestore,
        `${FIRESTORE_PATH_ORGANISATIONS}/${organisationId}/${FIRESTORE_SUBPATH_ROLES}`
      );

      const unsub = onSnapshot(
        collectionRef,
        (snapshot) => {
          const fetchedRoles: WithId<Role>[] = [];
          snapshot.forEach((doc) => {
            fetchedRoles.push({
              id: doc.id,
              ...doc.data(),
            } as WithId<Role>);
          });
          setRoles(fetchedRoles); // Update roles state with fetched roles
          resolve(fetchedRoles);
        },
        (error) => {
          console.error("Error fetching roles:", error);
          reject(error);
        }
      );

      // Clean up the listener
      return unsub;
    });
    //eslint-disable-next-line
  }, [organisationId]);

  const {
    items: roles,
    selectedItem: selectedRole,
    selectItem,
    updateItem,
    setList,
  } = useListSelect(
    fetchRoles,
    (role1: WithId<Role>, role2: WithId<Role>) => role1.name === role2.name
  );

  const selectFirstRole = useCallback(
    () => selectItem(() => true),
    [selectItem]
  );

  const selectRoleByName = useCallback(
    (name: string) => selectItem((role) => role.name === name),
    [selectItem]
  );

  const updateRolePermissions = useCallback(
    async (
      permissions: Permissions,
      updatePermissionContext?: (
        organisationId: string,
        roleName: string,
        permission: Permissions
      ) => void
    ) => {
      if (!organisationId || !selectedRole || !profile) {
        return;
      }

      await _updateRolePermissions(
        organisationId,
        selectedRole?.name,
        permissions
      );

      if (updatePermissionContext) {
        updatePermissionContext(organisationId, selectedRole.name, permissions);
      }

      return updateItem(await getRoleById(organisationId, selectedRole.id));
    },
    [organisationId, selectedRole, updateItem, profile]
  );

  const updateRoleSettings = async (
    updatedRoles: {
      name: string;
      isEnable: boolean;
    }[]
  ) => {
    try {
      updatedRoles.forEach((role) => {
        projectFirestore
          .collection("organisations")
          .doc(organisationId)
          .collection("roles")
          .doc(role.name)
          .update({ isEnable: role.isEnable });
      });

      console.debug("Roles updated successfully");
    } catch (error) {
      console.error("Error updating roles:", error);
    }
  };

  const updateAverageRoleCalc = async (
    updatedRoles: {
      name: string;
    }[],
    toggledRoles: Role[]
  ) => {
    try {
      updatedRoles.forEach((role) => {
        // Check if the role is in toggledRoles
        const isIncluded = toggledRoles.some(
          (toggledRole) => toggledRole.name === role.name
        );

        // Update Firestore for each role
        projectFirestore
          .collection("organisations")
          .doc(organisationId)
          .collection("roles")
          .doc(role.name)
          .update({
            exCludedToCalc: !isIncluded, // Set exCludedToCalc to true if the role is not included
          });
      });
    } catch (error) {
      console.error("Error updating roles:", error);
    }
  };

  // Method to set or update roles
  const setRoles = useCallback(
    (newRoles: WithId<Role>[]) => {
      return setList(newRoles);
    },
    [setList]
  );

  return {
    roles,
    selectedRole,
    selectFirstRole,
    selectRoleByName,
    updateRolePermissions,
    setRoles,
    updateRoleSettings,
    updateAverageRoleCalc,
  };
}
