import Header from "components/Header/Header";
import PageWrapper from "components/utility/PageWrapper";
import classes from "./Community.module.css";
// import QRCode from "react-qr-code";
import { ReactComponent as GreenPlusIcon } from "../../assets/GreenPlusIcon.svg";
import { useEffect } from "react";
import { useAuthContext } from "hooks/useAuthContext";

const Community = () => {
  const { profile } = useAuthContext();

  let data = [
    // {
    //   title: "GC 1",
    //   date: new Date().toLocaleString(),
    //   paticipants: 3,
    //   link: "http://growthbeans.com/1",
    // },
    // {
    //   title: "GC 2",
    //   date: new Date().toLocaleString(),
    //   paticipants: 2,
    //   link: "http://growthbeans.com/12",
    // },
    // {
    //   title: "GC 3",
    //   date: new Date().toLocaleString(),
    //   paticipants: 4,
    //   link: "http://growthbeans.com/3",
    // },
    // {
    //   title: "GC 4",
    //   date: new Date().toLocaleString(),
    //   paticipants: 1,
    //   link: "http://growthbeans.com/4",
    // },
    // {
    //   title: "GC 5",
    //   date: new Date().toLocaleString(),
    //   paticipants: 3,
    //   link: "http://growthbeans.com/43333",
    // },
  ];
  useEffect(() => {
    console.log(data);
  });
  return (
    <PageWrapper pageType={"groundRules"}>
      <Header />
      <div className={classes["community-container"]}>
        <div className={classes["nav-title"]}>
          <h3 className={classes["title"]}>Available GrowthCircles</h3>
          {profile?.isFacil && (
            <button>
              <GreenPlusIcon />
            </button>
          )}
        </div>
        {/* {data &&
            data.map((gc) => {
              return (
                <div key={gc.title} className={classes["gc-cards"]}>
                  <QRCode size={100} value={gc.link} />
                  <div className={classes["gc-info"]}>
                    <h5>{gc.title}</h5>
                    <p>{gc.date.toString()}</p>
                    <small>Total Participants : {gc.paticipants} / 6</small>
                  </div>
                </div>
              );
            })} */}
      </div>
    </PageWrapper>
  );
};

export default Community;
