import { createAvatar } from "@dicebear/core";
import { initials } from "@dicebear/collection";
import ProfilePicture from "components/ProfilePicture/ProfilePicture";
import { Profile } from "interface/ProfileInterface";
import usePromise from "hooks/utility/usePromise";
import useGroup from "hooks/organisation/useGroup";
import Organisation from "interface/OrganisationInterface";
import { useState } from "react";
import EmptySpace from "components/utility/EmptySpace";
import { Group, SubGroup } from "interface/GroupInterface";
import { AiFillCloseCircle } from "react-icons/ai";
import { Modal } from "flowbite-react";
import classes from "pages/AllCircles/Admin/Admin.module.css";
import SimpleLoader from "components/Loaders/SimpleLoader";

interface GroupUserProps {
  user: Profile;
  selectedOrganisation: Organisation;
  group?: Group;
  subgroup?: SubGroup;
  isSelected?: boolean;
  onToggleSelect?: () => void;
  readOnly?: boolean;
  leaders?: string[];
}

const GroupUser = ({
  user,
  selectedOrganisation,
  group,
  subgroup,
  isSelected,
  onToggleSelect,
  readOnly,
  leaders,
}: GroupUserProps) => {
  const { isLoading, resolve } = usePromise();
  const { removeUserFromGroup, getGroups } = useGroup();
  const [show, setShow] = useState(false);
  const removeUserHandler = async (uid: string) => {
    try {
      resolve(() =>
        removeUserFromGroup(group?.id ?? "", subgroup?.id ?? "", uid)
      ).then(() => {
        getGroups();
        setShow(!show);
      });
    } catch (error) {
      console.error(error);
    }
  };
  const getSVG = (user: Profile) => {
    const avatar = createAvatar(initials, {
      seed: user.displayName || user.email,
      backgroundColor: ["EB8181"],
    });

    const svg = avatar.toString();
    return `data:image/svg+xml;utf8,${encodeURIComponent(svg)}`;
  };

  return (
    <>
      <Modal show={show} popup={true} size={"md"}>
        <Modal.Body className="relative p-5">
          {isLoading ? (
            <div className="flex justify-center items-center min-h-[20vh]">
              <SimpleLoader />
            </div>
          ) : (
            <>
              <AiFillCloseCircle
                size={25}
                style={{ color: "var(--icon-colour-0)" }}
                className="absolute top-2 right-2 cursor-pointer"
                onClick={() => setShow(!show)}
              />
              <div className="text-center flex justify-center items-center flex-col">
                <p className="my-4">
                  Are you sure you want to remove user from this group?
                </p>
                <div className={`${classes["container"]} my-4 flex gap-2`}>
                  <button
                    className={`${classes["button"]}`}
                    onClick={() => removeUserHandler(user.uid)}
                  >
                    Yes
                  </button>
                  <button
                    className={`${classes["button"]}`}
                    onClick={() => setShow(!show)}
                  >
                    Cancel
                  </button>
                </div>
              </div>
            </>
          )}
        </Modal.Body>
      </Modal>
      <div
        className={`relative flex rounded-lg shadow-md max-w-[250px]  min-w-[250px] min-h-[250px] flex-col items-center p-5 bg-white cursor-pointer  ${
          isSelected
            ? "border-4 border-[var(--icon-colour-0)] "
            : " border-gray-300"
        }  `}
        style={{
          backgroundColor:
            leaders && leaders.length > 0 && leaders?.includes(user.uid)
              ? "rgba(235, 129, 129, 0.5)"
              : "",
        }}
        onClick={onToggleSelect}
      >
        {!readOnly && (
          <AiFillCloseCircle
            size={25}
            style={{ color: "var(--icon-colour-0)" }}
            className="absolute top-2 right-2 cursor-pointer"
            onClick={() => setShow(!show)}
          />
        )}

        {user.photoURL ? (
          <div className="mb-3">
            <ProfilePicture size={96} profile={user} onClick={(e) => e} />
          </div>
        ) : (
          <img
            className="w-24 h-24 mb-3 rounded-full shadow-lg"
            src={getSVG(user)}
            alt={user.displayName || user.email}
          />
        )}
        <h5 className="w-36 text-center overflow-hidden text-ellipsis mb-1 text-xl font-medium text-gray-900 dark:text-white ">
          {user.displayName || user.email || user.firstname}
        </h5>
        <span className="text-sm text-gray-500 dark:text-gray-400">
          {user.email}
        </span>
        {leaders && leaders.length > 0 && leaders?.includes(user.uid) ? (
          <p className="mt-2 font-semibold">Leader</p>
        ) : (
          ""
        )}
        <span className="mt-2 font-semibold text-sm text-standard">
          {selectedOrganisation.users[user.uid]}
        </span>

        <EmptySpace height="5px" />
      </div>
    </>
  );
};

export default GroupUser;
