import { FC, useEffect, useState } from "react";
import { ComponentProps } from "../SelfAwarenessComponentMapping";
import { IoMdInformationCircle } from "react-icons/io";
import DropDownSelector from "./DropDownSelector";
import BackAndNextFlat from "components/utility/BackAndNextFlat";
import { useSelfAwarenessContext } from "hooks/useSelfAwarenessContext";
import { MaterialMapping } from "./MaterialMapping";
import Lightbox, { ImagesListType } from "react-spring-lightbox";

import InfoModal from "../SubComponents/InfoModal";

const DropDownSelections: FC<ComponentProps> = ({
  backAndNextHandler,
  setHasModal,
}) => {
  const handler = () => {
    setShow(!show);
    setHasModal(!show);
  };

  const [show, setShow] = useState(false);
  const [selectedItems, setSelectedItem] = useState<string[]>([]);
  const { journal } = useSelfAwarenessContext();
  const [showLightBox, setShowLightBox] = useState(false);
  const [activeIndex, setActiveIndex] = useState(0);

  const images: ImagesListType = journal
    ? [
        {
          src: MaterialMapping[journal.selectionModalImageSource],
          loading: "lazy",
          alt: "Uploaded image",
        },
      ]
    : [];

  const gotoPrevious = () => {
    activeIndex > 0 && setActiveIndex(activeIndex - 1);
  };

  const gotoNext = () => {
    activeIndex + 1 < images.length && setActiveIndex(activeIndex + 1);
  };

  useEffect(() => {
    if (!journal) return;
    // Get the existing SelfAwarenessJournal from local storage
    const storedJournal = localStorage.getItem("SelfAwarenessJournal");

    const existingJournal = storedJournal ? JSON.parse(storedJournal) : {};

    // Update SelfAwarenessJournal with the latest selectedItems
    const updatedJournal = {
      ...existingJournal,
      selectedItems: selectedItems, // Use the latest state here
      // other properties of SelfAwarenessJournal...
    };

    // Save the updated SelfAwarenessJournal to local storage
    localStorage.setItem(
      "SelfAwarenessJournal",
      JSON.stringify(updatedJournal)
    );
  }, [selectedItems, journal]);

  return (
    <>
      {journal && <InfoModal handler={handler} journal={journal} show={show} />}

      {journal && (
        <div className="py-8 px-4 w-full md:w-1/4">
          <div
            className="relative p-5 border-2 rounded-lg font-poppins font-semibold"
            style={{
              backgroundColor: "#FFF4B9",
              borderColor: "#FFE871",
              color: "var(--text-colour)",
            }}
          >
            {journal.hasSelectionInfo && (
              <IoMdInformationCircle
                style={{ color: "var(--icon-colour-0)" }}
                className="absolute top-0 right-0 cursor-pointer "
                size={30}
                onClick={handler}
              />
            )}
            <p>{journal.selectionTitle}</p>
          </div>
          <DropDownSelector
            selectedItems={selectedItems}
            setSelectedItem={setSelectedItem}
          />
          {selectedItems && (
            <div className="mt-5">
              <BackAndNextFlat
                {...backAndNextHandler}
                nextAllowed={selectedItems.length > 0 ? true : false}
              />
            </div>
          )}
        </div>
      )}

      <Lightbox
        isOpen={showLightBox}
        onPrev={gotoPrevious}
        onNext={gotoNext}
        images={images}
        currentIndex={activeIndex}
        style={{ background: "grey" }}
        /* Handle closing */
        onClose={() => setShowLightBox(!showLightBox)}
        /* Use single or double click to zoom */
        // singleClickToZoom

        /* react-spring config for open/close animation */
        pageTransitionConfig={{
          from: { transform: "scale(0.75)", opacity: 0 },
          enter: { transform: "scale(1)", opacity: 1 },
          leave: { transform: "scale(0.75)", opacity: 0 },
          config: { mass: 1, tension: 320, friction: 32 },
        }}
      />
    </>
  );
};

export default DropDownSelections;
