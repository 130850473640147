import { SetStateAction, useCallback, useEffect, useState } from "react";
import classes from "pages/AllCircles/Admin/Admin.module.css";

import Organisation from "interface/OrganisationInterface";
import { Modal, Tooltip } from "flowbite-react";
import { AiFillCloseCircle, AiFillQuestionCircle } from "react-icons/ai";
import PageWrapper from "components/utility/PageWrapper";
import Bubble from "components/InfoPanel/CircleBubble";

import TextAreaMax from "components/Organisation/WelcomeSettings/components/TextAreaMax";
import { projectStorage } from "../../../firebase/config";
import { getDownloadURL, listAll, ref } from "firebase/storage";
import useOrganisationContext from "hooks/organisation/useOrganisationContext";
import usePromise from "hooks/utility/usePromise";
import toast from "react-hot-toast";

interface TaglineProps {
  OrganisationTagLine: string;
  setOrganisationTagLine: (value: SetStateAction<string>) => void;
  isLoading: boolean;
  handleSubmitTagLine: () => void;
  updating: boolean;
  selectedOrganisation: Organisation;
  handleCheckboxChange: (checkboxName: string) => Promise<void>;
  withInfo?: boolean;
}
const GrowthCircleTagLine = ({
  OrganisationTagLine,
  setOrganisationTagLine,
  isLoading,
  updating,
  handleSubmitTagLine,
  selectedOrganisation,
  handleCheckboxChange,
  withInfo,
}: TaglineProps) => {
  const [isVisible, setIsVisible] = useState(false);
  const { updateSelectedOrganisation } = useOrganisationContext();
  const [files, setFiles] = useState<string[]>([]);
  const { isLoading: isClearing, resolve } = usePromise();
  const fetchImages = useCallback(async () => {
    try {
      setFiles([]);
      const storageRef = ref(
        projectStorage,
        `organisations/logo/${selectedOrganisation?.id}`
      );
      const result = await listAll(storageRef);

      const urlPromises = result.items.map((imageRef) =>
        getDownloadURL(imageRef)
      );

      const urls = await Promise.all(urlPromises);

      setFiles(urls); // Update the state with the new URLs
    } catch (error) {
      console.error(error);
    }

    // eslint-disable-next-line
  }, [selectedOrganisation, files]);

  const clearTagLineHandler = async () => {
    resolve(() => updateSelectedOrganisation({ tagLine: "" })).then(() => {
      setOrganisationTagLine("");
      toast.success("Tag line cleared.");
    });
  };

  useEffect(() => {
    setFiles([]);
    fetchImages();

    // eslint-disable-next-line
  }, [selectedOrganisation]);
  return (
    <>
      <Modal show={isVisible} size={"lg"}>
        <div className="min-h-[60vh] relative">
          <PageWrapper pageType="welcome">
            <AiFillCloseCircle
              onClick={() => setIsVisible(!isVisible)}
              size={30}
              style={{ color: "var(--icon-colour-0)" }}
              className="cursor-pointer absolute top-2 right-2"
            />
            <Bubble>
              {files[0] && selectedOrganisation?.showTagLineLogo && (
                <div className="w-44">
                  <img
                    src={files[0]}
                    alt={selectedOrganisation?.name}
                    className="h-full w-full object-contain"
                  />
                </div>
              )}

              {selectedOrganisation?.tagLine &&
              selectedOrganisation?.tagLine !== "" ? (
                <h3
                  style={{ color: "var(--main-colour)" }}
                  className="text-3xl text-center break-words w-[300px]"
                >
                  {selectedOrganisation?.tagLine}
                </h3>
              ) : (
                <h3
                  style={{ color: "var(--main-colour)" }}
                  className="text-5xl text-center break-words w-[300px]"
                >
                  Growth <br /> <em>Circles</em>
                </h3>
              )}
            </Bubble>
          </PageWrapper>
        </div>
      </Modal>

      <div className="flex border-2 border-gray-200 p-5 flex-col items-start gap-2 my-4">
        <p className="font-semibold mt-4 text-xl">Tag line & Logo Options</p>
        <span className="mb-4 flex gap-2 items-center">
          Select the checkbox below and substitute the text "GrowthCircle" with
          your tagline. If an organization logo is uploaded, it will be
          displayed beneath the logo.
          {withInfo && withInfo === true && (
            <Tooltip content="Click to preview" placement="top">
              <AiFillQuestionCircle
                onClick={() => setIsVisible(!isVisible)}
                size={25}
                style={{ color: "var(--icon-colour-0)" }}
                className="cursor-pointer"
              />
            </Tooltip>
          )}
        </span>

        <div className={`${updating ? "opacity-[0.3]" : ""}`}>
          {selectedOrganisation.logo && selectedOrganisation.logo !== "" ? (
            <div className="text-2xl flex flex-col">
              <label className="cursor-pointer">
                <input
                  disabled={updating}
                  type="checkbox"
                  checked={selectedOrganisation?.showTagLineLogo ?? false}
                  onChange={() => handleCheckboxChange("showTagLineLogo")}
                  className="mx-4"
                  style={{
                    height: "20px",
                    width: "20px",
                    borderRadius: "5px",
                    backgroundColor: selectedOrganisation?.showTagLineLogo
                      ? "var(--icon-colour-0)"
                      : "",
                  }}
                />
                Show tag line with logo (Replacing Bubble)
              </label>
            </div>
          ) : (
            <p className="font-semibold">
              {!updating && "No organisation logo available."}
            </p>
          )}
        </div>
      </div>

      <div
        general-settings-tour="organisation-tag-line"
        className="border-2 my-5 border-gray-200 p-5"
      >
        <p className="font-semibold  py-4 text-xl">Organisation Tag line</p>
        <div className="mb-4 flex gap-2 items-center">
          <p>
            {" "}
            The tagline you provide will replace the text "GrowthCircles" within
            the GrowthCircle bubbles. If you check the checkbox under{" "}
            <strong>"Tag Line & Logo Options"</strong>, the tagline will be
            displayed beneath the organization logo.
          </p>

          {withInfo && withInfo === true && (
            <Tooltip content="Click to preview" placement="top">
              <AiFillQuestionCircle
                onClick={() => setIsVisible(!isVisible)}
                size={25}
                style={{ color: "var(--icon-colour-0)" }}
                className="cursor-pointer"
              />
            </Tooltip>
          )}
        </div>
        <p>
          If no logo is uploaded, the tagline will replace "GrowthCircles" and
          will be displayed underneath the "Welcome to" message. If cleared it
          will return the "Welcome To GrowthCircles" message.
        </p>
        <div
          className={`w-full ${
            withInfo && withInfo === true ? "md:w-1/2" : ""
          } my-4 `}
        >
          <TextAreaMax
            value={OrganisationTagLine}
            row={5}
            maxLength={50}
            valueHandler={setOrganisationTagLine}
          />
        </div>
        <button
          disabled={isLoading}
          onClick={handleSubmitTagLine}
          className={`${classes["button"]} my-2`}
        >
          {isLoading ? "Saving..." : "Save"}
        </button>
        <button
          disabled={isClearing}
          onClick={clearTagLineHandler}
          className={`${classes["button"]} my-2 mx-2`}
        >
          {isClearing ? "Clearing..." : "Clear"}
        </button>
      </div>
    </>
  );
};

export default GrowthCircleTagLine;
