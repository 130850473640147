import { createPortal } from "react-dom";
import classes from "./Modal.module.css";
import { ReactComponent as Xbutton } from "assets/Xbutton.svg";
import { useScrollBlock } from "hooks/useScrollBlock";
import { ReactNode, useCallback, useEffect, useRef } from "react";

interface Props {
  isModalOpen: boolean;
  setIsModalOpen: React.Dispatch<React.SetStateAction<boolean>>;
  onClose?: () => void;
  isScrollBlocked?: boolean;
  clickOutsideToClose?: boolean;
  closeButtonTopOffset?: string;
  closeButtonRightOffset?: string;
  padding?: string;
  borderRadius?: string;
  minWidth?: string;
  maxWidth?: string;
  minHeight?: string;
  maxHeight?: string;
  backgroundColor?: string;
  boxShadow?: string;
  top?: string;
  left?: string;
  overflowY?: string;
  children: ReactNode;
  customModalContainerStyle?: object;
  offOverlay?: boolean;
  hasFadeIn?: boolean;
  modalId?: string;
  closeButtonColor?: string;
}

const Modal: React.FC<Props> = ({
  isModalOpen,
  setIsModalOpen,
  onClose = () => {},
  isScrollBlocked, // boolean to block scroll on open
  clickOutsideToClose, // boolean to close modal on outside click
  closeButtonTopOffset,
  closeButtonRightOffset,
  padding,
  borderRadius,
  minWidth,
  maxWidth,
  minHeight,
  maxHeight,
  backgroundColor,
  boxShadow,
  top,
  left,
  overflowY,
  children,
  customModalContainerStyle, //ADD: Object of CSS fields passed by parent
  offOverlay, //ADD: option to remove overlay
  hasFadeIn, //ADD: fades in modal
  modalId, //Use CreatePortal on another id
  closeButtonColor, //ADD: color to change cross button to
}) => {
  //Do a deep merge of buttonStyle object and buttonStyles
  const buttonStyle = {
    top: closeButtonTopOffset,
    right: closeButtonRightOffset,
  };

  const modalContainerStyle = {
    padding,
    borderRadius,
    minWidth,
    maxWidth,
    minHeight,
    maxHeight,
    backgroundColor,
    overflowY,
    boxShadow,
    top,
    left,
  };

  const mergedContainerStyle: object = {
    ...modalContainerStyle,
    ...customModalContainerStyle,
  };

  const closeModal = useCallback((e: React.MouseEvent<HTMLButtonElement> | MouseEvent) => {
    e.stopPropagation();
    setIsModalOpen(false);
    onClose();
  }, [onClose, setIsModalOpen]);

  const ref = useRef<HTMLDivElement>(null);

  const [blockScroll, allowScroll] = useScrollBlock();

  // block scrolls on modal open
  // allow scrolls on modal close
  useEffect(() => {
    if (isModalOpen && isScrollBlocked) {
      blockScroll();
    } else {
      allowScroll();
    }
  }, [allowScroll, blockScroll, isModalOpen, isScrollBlocked]);

  useEffect(() => {
    if (!clickOutsideToClose) return;

    const handleClickOutside = (event: MouseEvent): any => {
      if (ref.current && !ref.current.contains(event.target as Node)) {
        closeModal(event);
      }
    };
    // Bind the event listener
    document.addEventListener("mousedown", handleClickOutside);
    return () => {
      // Unbind the event listener on clean up
      document.removeEventListener("mousedown", handleClickOutside);
    };
  }, [clickOutsideToClose, closeModal, ref]);

  if (!isModalOpen) return null;

  return createPortal(
    <>
      {
        <div
          className={`${classes["overlay-styles"]} ${
            !offOverlay && classes["overlay-colour"]
          }`}
        />
      }
      <div
        ref={ref}
        className={`${classes["modal-container"]} ${
          hasFadeIn ? classes["fadeIn"] : ""
        }`}
        style={mergedContainerStyle}
      >
        <button
          className={classes["close-modal-button"]}
          onClick={(e: React.MouseEvent<HTMLButtonElement>) => closeModal(e)}
          style={buttonStyle}
        >
          <Xbutton
            fill={closeButtonColor ? closeButtonColor : "var(--main-colour)"}
          />
        </button>
        {children}
      </div>
    </>,
    document.getElementById(modalId ? modalId : "portal") as HTMLElement
  );
};

Modal.defaultProps = {
  isScrollBlocked: false,
  clickOutsideToClose: false,
  closeButtonTopOffset: "10px",
  closeButtonRightOffset: "10px",
  padding: "40px",
  borderRadius: "15px",
  minWidth: "400px",
  maxWidth: "600px",
  minHeight: "100px",
  maxHeight: "500px",
  backgroundColor: "var(--secondary-colour-2-60)",
  boxShadow: "0px 4px 10px var(--text-colour)",
  top: "50%",
  left: "50%",
  overflowY: "scroll",
  offOverlay: false, //ADD: option to remove overlay
  hasFadeIn: false, //ADD: fades in modal
  modalId: "portal", //Use CreatePortal on another id
};

export default Modal;
