import AddNewType from "./AddNewType";
import { useState } from "react";
import classes from "../../../pages/AllCircles/Admin/Admin.module.css";
import GrowthCirclesTypeList from "./GrowthCirclesTypeList";
import NavAdmin from "components/Admin/NavAdmin";
import { Toaster } from "react-hot-toast";
import SidebarAdmin from "components/Admin/SidebarAdmin";

const GrowthCirclesTypeSettings = () => {
  const [showForm, setShowForm] = useState(false);

  return (
    <div>
      <NavAdmin />
      <Toaster
        position="bottom-center"
        containerStyle={{
          bottom: "5%",
        }}
        toastOptions={{ duration: 4000 }}
      />
      <div className={`${classes.container} min-h-screen`}>
        <SidebarAdmin />
        <div className={classes.main}>
          <div className={classes.title}>
            <h4>Growth Circles Type </h4>
          </div>
          <div className="my-4">
            <button
              className={`${classes["button"]}`}
              onClick={() => setShowForm(!showForm)}
            >
              Add New Type
            </button>
          </div>
          <>{showForm && <AddNewType />}</>
          <GrowthCirclesTypeList />
        </div>
      </div>
    </div>
  );
};

export default GrowthCirclesTypeSettings;
