import classes from "./ShareMessage.module.css";
import EmptySpace from "components/utility/EmptySpace";
import FirstNameAvatar from "../../utility/FirstNameAvatar";
import RolesSmallBubbleMap from "assets/RolesSmallBubble/RolesSmallBubbleMap";
import RolesIconMap from "assets/Roles/RolesIconMap";
import Modal from "components/utility/Modal";
import { useState } from "react";
/* Importing the `useGCSession` hook from the `useGCSession.tsx` file in the `hooks` folder. */
// import { useGCSession } from "hooks/useGCSession";
import firebase from "firebase/compat/app";
import {
  PartialParticipant,
  Participant,
} from "interface/ParticipantInterface";

interface ModalProps {
  initials: string;
  coreSkill: string;
  role: string;
}

const ModalContent: React.FC<ModalProps> = ({ initials, coreSkill, role }) => {
  const RoleIcon = RolesIconMap[coreSkill];

  return (
    <div className={classes["modal-content"]}>
      <div className={classes["modal-title"]}>
        <RoleIcon fill={"var(--main-colour)"} />
        <EmptySpace width={"20px"} />
        <strong>{initials}</strong>
      </div>
      <EmptySpace height={"20px"} />
      <p className={classes["core-skill-and-role"]}>
        {coreSkill} - {role}
      </p>
    </div>
  );
};

interface Props {
  name: string;
  createdAt: firebase.firestore.Timestamp;
  isSelf: boolean;
  text: string;
  participantNumber: number;
  participant: Participant | PartialParticipant;
  coreSkill: string;
  role?: string;
}

const ShareMessage: React.FC<Props> = ({
  name,
  createdAt,
  isSelf,
  text,
  participantNumber,
  participant,
  coreSkill,
  role,
}) => {
  const sentDate = new Date(createdAt.seconds * 1000).toLocaleTimeString([], {
    hour: "2-digit",
    minute: "2-digit",
    hour12: true,
  });

  // svg
  const RoleBubbleIcon = RolesSmallBubbleMap[coreSkill];

  const [isModalOpen, setIsModalOpen] = useState<boolean>(false);

  const toggleModal = () => {
    setIsModalOpen(!isModalOpen);
  };

  // const { getFirstLetters } = useGCSession();

  return (
    <li
      className={`${classes["message-entry"]} 
      ${!isSelf && classes["chat-bubble-left"]} 
      ${isSelf && classes["chat-bubble-right"]} 
      ${isSelf && classes["user-message-entry"]} 
      ${!isSelf && classes["other-message-entry"]}`}
    >
      {!isSelf && (
        <>
          <FirstNameAvatar
            name={name}
            participant={participant}
            participantNumber={participantNumber}
          />
          <EmptySpace width={"8px"} />
        </>
      )}
      <div className={`${classes["chat-bubble"]} `}>
        <div
          className={`${classes["message-container"]}
          `}
        >
          {!isSelf && (
            <p
              className={`${classes["name"]} w-40 overflow-hidden text-ellipsis py-2`}
            >
              {name}
            </p>
          )}
          <p className={`${classes.text}`}>{text}</p>
          <EmptySpace height={"4px"} />
          <div className={classes["edit-and-time"]}>
            <p className={classes["text-time"]}>{sentDate}</p>
          </div>
        </div>
        {role !== undefined && role !== "" && (
          <>
            <div className={classes["role-bubble"]}>
              <RoleBubbleIcon onClick={toggleModal} />
            </div>
            <Modal
              isModalOpen={isModalOpen}
              setIsModalOpen={setIsModalOpen}
              isScrollBlocked={true}
              clickOutsideToClose={true}
              padding={"30px"}
              minWidth={"80%"}
            >
              <ModalContent
                coreSkill={coreSkill}
                role={role}
                initials={isSelf ? "My Role" : `${name}'s Role`}
              />
            </Modal>
          </>
        )}
      </div>
      {isSelf && (
        <>
          <EmptySpace width={"8px"} />
          <FirstNameAvatar
            name={name}
            participantNumber={participantNumber}
            participant={participant}
          />
        </>
      )}
    </li>
  );
};

export default ShareMessage;
