import React from "react";
import classes from "./ProgressBarContent.module.css";
import ProgressBar from "./ProgressBar";
import EmptySpace from "components/utility/EmptySpace";

interface Props {

}

const ProgressBarContent: React.FC<Props> = () => {
  return (
    <div className={classes["content-container"]}>
      <strong>What does the percentage mean?</strong>
      <EmptySpace height={"20px"} />
      <ProgressBar progress={0} />
      <EmptySpace height={"20px"} />
      <div style={{ borderTop: "2px solid var(--main-colour)" }} />
      <EmptySpace height={"20px"} />
      <p>Each time you choose any role, it's percentage will increase by 10%</p>
      <EmptySpace height={"20px"} />
      <ProgressBar progress={10} />
      <EmptySpace height={"20px"} />
      <strong>Is there any way to increase it further each time? </strong>
      <EmptySpace height={"20px"} />
      <p>
        Yes! At the end of every session, you can request for feedback on how
        well you did played your role.
      </p>
      <EmptySpace height={"20px"} />
      <ProgressBar progress={40} />
      <EmptySpace height={"20px"} />
      <p>
        Average rating of 4 and above ={" "}
        <span className={classes["red"]}>increase by 30%</span>
      </p>
      <p>
        Average rating of {">"}= -3 and 4 ={" "}
        <span className={classes["red"]}>increase by 10%</span>
      </p>
      <p>
        Average rating of 3 and below ={" "}
        <span className={classes["red"]}>no increase</span>
      </p>
    </div>
  );
};

export default ProgressBarContent;
