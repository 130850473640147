interface FeedbackProps {
  feedbackData: any;
  feedbackLayout: any;
}

function ExportColumnDisplay({ feedbackData, feedbackLayout }: FeedbackProps) {
  return (
    <>
      {feedbackLayout &&
        feedbackLayout.map((layoutItem, index) => {
          return layoutItem.subCategories.map((subCategory, subIndex) => {
            return (
              <td key={subIndex} className="px-6 py-2 border border-gray-500">
                {subCategory.type !== "multipleChoice" &&
                  feedbackData &&
                  feedbackData[layoutItem.title] &&
                  feedbackData[layoutItem.title][subCategory.questionName]}

                {subCategory.type === "multipleChoice" &&
                  feedbackData &&
                  feedbackData[layoutItem.title] &&
                  feedbackData[layoutItem.title][subCategory.questionName]
                    .split("|")
                    .map((choice, choiceIndex) => (
                      <li key={choiceIndex}>{choice}</li>
                    ))}
              </td>
            );
          });
        })}
    </>
  );
}

export default ExportColumnDisplay;
