import { useRef, useEffect } from 'react';

// use 
// const isMount = useMount();

const useMount = () => {
  const isMountRef = useRef(true);
  useEffect(() => {
    isMountRef.current = false;
  }, []);
  return isMountRef.current;
};

export default useMount;