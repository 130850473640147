import { LocalStorageKeys } from "App";
import {
  FeedbackForm,
  OpenEndedFields,
  SmileyRatings,
} from "interface/FeedbackFormInterface";
import { useAuthContext } from "./useAuthContext";
import useFeedbackForm from "./useFeedbackForm";

/**
 * Retrieves current GC smileyRatings in localStorage
 * @param userStorageKey: string key to use to retrieve from localStorage
 */
const getGCSmileyRatings = (
  userStorageKey: string
): SmileyRatings | undefined => {
  const jsonGcSmileyRatings = localStorage.getItem(
    userStorageKey + LocalStorageKeys.REFLECTION.GC_SMILEY_RATINGS
  );
  if (!!jsonGcSmileyRatings) {
    return JSON.parse(jsonGcSmileyRatings);
  }
  // convertNestedValueToInt(gcSmileyRatings, "answer");
};

/**
 * Retrieves current USER smileyRatings in localStorage
 * @param userStorageKey: string key to use to retrieve from localStorage
 */
const getPersonalSmileyRatings = (
  userStorageKey: string
): SmileyRatings | undefined => {
  const jsonPersonalSmileyRatings = localStorage.getItem(
    userStorageKey + LocalStorageKeys.REFLECTION.PERSONAL_SMILEY_RATINGS
  );
  if (!!jsonPersonalSmileyRatings) {
    return JSON.parse(jsonPersonalSmileyRatings);
  }
};

/**
 * Retrieves current open-ended fields in localStorage
 * @param userStorageKey: string key to use to retrieve from localStorage
 */
const getOpenEndedFields = (
  userStorageKey: string
): OpenEndedFields | undefined => {
  const jsonOpenEndedFields = localStorage.getItem(
    userStorageKey + LocalStorageKeys.REFLECTION.OPEN_ENDED_FIELDS
  );
  if (jsonOpenEndedFields) {
    return JSON.parse(jsonOpenEndedFields);
  }
};

/**
 * Retrieves current badge-ratings in localStorage
 * @param userStorageKey: string key to use to retrieve from localStorage
 */
const getOthersBadgeRatings = (userStorageKey: string) => {
  const jsonBadgeRatings = localStorage.getItem(
    userStorageKey + LocalStorageKeys.REFLECTION.OTHER_BADGE_RATINGS
  );
  if (jsonBadgeRatings) {
    return JSON.parse(jsonBadgeRatings);
  }
};

/**
 * Hook that includes methods to deal with all sections of the reflection rating
 * @param userStorageKey: string key to use to retrieve from localStorage
 */
const useReflectionRating = () => {
  const { profile } = useAuthContext();
  const { setFeedbackFormTransaction } = useFeedbackForm();
  // const { setDocumentTransaction, updateDocumentTransaction } = useFirestore("FeedBackForm");
  const userStorageKey: string = profile?.id + "_";

  // TODO: check if storage is cleared
  /**
   * Clear all ReflectionRating data from localStorage
   */
  const clearReflectionFields = () => {
    Object.keys(LocalStorageKeys.REFLECTION).forEach((key) => {
      localStorage.removeItem(
        userStorageKey + LocalStorageKeys.REFLECTION[key]
      );
    });
  };

  /**
   * Async method to submit the feedback form based on data from localStorage
   */
  const handleSubmitFeedbackForm = async () => {
    const newUserFeedback: Partial<FeedbackForm> = {
      growthCircle: profile?.growthCircle,
      userId: profile?.id,
      userName: profile?.displayName,
    };

    //1. Submit all form details related to user
    // const dragRatings = getDragRatings(userStorageKey);
    // if (!!dragRatings) {
    //   newUserFeedback[LocalStorageKeys.REFLECTION.DRAG_RATINGS] = dragRatings;
    // }

    const gcSmileyRatings = getGCSmileyRatings(userStorageKey);
    //console.log(gcSmileyRatings);
    if (!!gcSmileyRatings) {
      newUserFeedback[LocalStorageKeys.REFLECTION.GC_SMILEY_RATINGS] =
        gcSmileyRatings;
    }

    const personalSmileyRatings = getPersonalSmileyRatings(userStorageKey);
    //console.log(personalSmileyRatings);
    if (!!personalSmileyRatings) {
      newUserFeedback[LocalStorageKeys.REFLECTION.PERSONAL_SMILEY_RATINGS] =
        personalSmileyRatings;
    }

    const openEndedFields = getOpenEndedFields(userStorageKey);
    if (!!openEndedFields) {
      newUserFeedback[LocalStorageKeys.REFLECTION.OPEN_ENDED_FIELDS] =
        openEndedFields;
    }

    // console.log(newUserFeedback);
    //1. Submit ratings for others
    //TODO: Feedbackform submit ratings for others
    const othersBadgeRatings = getOthersBadgeRatings(userStorageKey);
    if (!!othersBadgeRatings) {
      await Promise.all(
        Object.keys(othersBadgeRatings).map(async (userId) => {
          const uniqueId: string = userId + profile?.growthCircle;
          const badgeRatings: Partial<FeedbackForm> = {
            userId: userId,
            growthCircle: profile?.growthCircle,
            refBadgeRatings: othersBadgeRatings[userId],
          };
          await setFeedbackFormTransaction(uniqueId, badgeRatings);
        })
      );
    }

    //2. Set document for user
    const id = profile?.id + profile!.growthCircle;
    await setFeedbackFormTransaction(id, newUserFeedback);

    //3. Clear localStorage of fields
    // clearReflectionFields();
  };
  return {
    handleSubmitFeedbackForm,
    clearReflectionFields,
  };
};

export default useReflectionRating;
