import { projectFirestore } from "../../../../firebase/config";
import { useEffect, useRef, useState } from "react";
import { DownloadTableExcel } from "react-export-table-to-excel";

import { Profile } from "interface/ProfileInterface";
interface dateDisplay {
  seconds: number;
  nanoseconds: number;
}

const ExportUsers = () => {
  const tableRef = useRef(null);
  const [datas, setDatas] = useState<Profile[]>([]);
  const [attendedData, setAttendedData] = useState<{
    [uid: string]: number | undefined;
  }>({});
  const [loading, setLoading] = useState(true);

  const fetchDataAndSortByCreatedAt = async () => {
    const ref = projectFirestore
      .collection("users")

      .where("last_active", "!=", null)
      .orderBy("last_active", "desc")
      .get();

    ref.then((snapshots) => {
      let result: Profile[] = [];
      snapshots.docs.map((doc) => {
        console.log(doc.data());
        return result.push(doc.data() as Profile);
      });
      setDatas(result);
    });
  };

  useEffect(() => {
    fetchDataAndSortByCreatedAt();
    // eslint-disable-next-line
  }, []);

  const displayRegisteredDate = (registeredDate: dateDisplay) => {
    // Convert Firestore Timestamp to JavaScript Date
    const date = new Date(
      registeredDate.seconds * 1000 + registeredDate.nanoseconds / 1000000
    );

    // Format the date to a string
    const formattedDate: string = date.toLocaleDateString("en-US", {
      timeZone: "Asia/Manila",
      month: "long",
      day: "numeric",
      year: "numeric",
    });

    return formattedDate;
  };

  const fetchDataFromCollection = async (
    uid: string,
    collectionName: string
  ) => {
    try {
      const ref = projectFirestore
        .collectionGroup(collectionName)
        .where("userId", "==", uid);

      // Use await to get the query snapshot
      const snapshot = await ref.get();

      // Get the number of documents in the snapshot
      const numberOfDocuments = snapshot.size;

      // Log or return the result as needed
      if (numberOfDocuments === 0) {
        console.log(
          `User with UID ${uid} has 0 records in ${collectionName}. Snapshot:`,
          snapshot.docs
        );
      } else {
        console.log(
          `User with UID ${uid} has ${numberOfDocuments} records in ${collectionName}.`
        );
      }

      // Return the value if you want to use it elsewhere
      return numberOfDocuments;
    } catch (error) {
      console.error(error);
      // Handle the error as needed
      throw error; // Rethrow the error to indicate that it was not handled
    }
  };

  useEffect(() => {
    let isMounted = true;

    const fetchData = async () => {
      try {
        setLoading(true);

        const promises = datas.map(async (data) => {
          const numberOfAttended = await fetchDataFromCollection(
            data.uid,
            "participantHistory"
          );
          const numberOfFeedbackForms = await fetchDataFromCollection(
            data.uid,
            "FeedBackForm"
          );

          return { uid: data.uid, numberOfAttended, numberOfFeedbackForms };
        });

        const results = await Promise.all(promises);

        if (isMounted) {
          const newData = results.reduce(
            (acc, { uid, numberOfAttended, numberOfFeedbackForms }) => {
              acc[uid] = { numberOfAttended, numberOfFeedbackForms };
              return acc;
            },
            {}
          );

          setAttendedData(newData);
          setLoading(false);
        }
      } catch (error) {
        if (isMounted) {
          console.error(error);
          setLoading(false);
        }
      }
    };

    fetchData();

    return () => {
      // Cleanup to prevent state update on unmounted component
      isMounted = false;
    };
  }, [datas]);

  const displayNumberOfAttended = (uid: string) => {
    const numberOfAttended = attendedData[uid];
    return numberOfAttended !== undefined ? numberOfAttended : "Loading...";
  };

  return (
    <div>
      <div className="px-4 my-2">
        <DownloadTableExcel
          filename={`GrowthCircles-users-data-${new Date()}`}
          sheet="users"
          currentTableRef={tableRef.current}
        >
          <button className="bg-green-500 hover:bg-green-600 px-4 py-2 rounded-md text-white">
            Export Excel
          </button>
        </DownloadTableExcel>
      </div>
      <p className="my-2">Totals of {datas.length} users</p>
      <table border={1} ref={tableRef}>
        <thead>
          <tr>
            <th scope="col" className="px-6 py-3 border border-gray-500">
              Email
            </th>

            <th scope="col" className="px-6 py-3 border border-gray-500">
              Name
            </th>
            <th scope="col" className="px-6 py-3 border border-gray-500">
              Username
            </th>
            <th scope="col" className="px-6 py-3 border border-gray-500">
              Date Registered
            </th>
            <th scope="col" className="px-6 py-3 border border-gray-500">
              isVerified
            </th>
            <th scope="col" className="px-6 py-3 border border-gray-500">
              Sessions attended
            </th>
          </tr>
        </thead>
        <tbody>
          {datas &&
            datas.map((data, index) => (
              <tr key={index}>
                <td className="px-6 py-2 border border-gray-500">
                  {data.email}
                </td>
                <td className="px-6 py-2 border border-gray-500">
                  {data.firstname} {data.lastname}
                </td>
                <td className="px-6 py-2 border border-gray-500">
                  {data.displayName}
                </td>
                <td className="px-6 py-2 border border-gray-500">
                  {data.last_active &&
                    displayRegisteredDate(
                      data.last_active as unknown as dateDisplay
                    )}
                </td>

                <td className="px-6 py-2 border border-gray-500">
                  {data.isVerified ? "Verified" : ""}
                </td>
                <td className="px-6 py-2 border border-gray-500">
                  {!loading ? displayNumberOfAttended(data.uid) : "Loading..."}
                </td>
              </tr>
            ))}
        </tbody>
      </table>
    </div>
  );
};

export default ExportUsers;
