import { Participant } from "interface/ParticipantInterface";
import classes from "./ReflectionQuestions.module.css";
// import { GCJournal } from "interface/GCJournal";

interface DisplayQuestionsProps {
  Participant?: Participant[];
  Journal?: Participant;
  topic: string;
  questionType: "general" | "personal" | "compulsory";
}

const DisplayQuestions = ({
  Participant,
  topic,
  questionType,
  Journal,
}: DisplayQuestionsProps) => {
  return (
    <>
      {questionType === "general" && (
        <ol className={`${classes["list-marker"]} px-4 list-decimal`}>
          {Participant &&
            Participant[0] &&
            Participant[0].reflectionQuestion.length > 0 &&
            Participant[0].reflectionQuestion.map((line) => {
              if (line) {
                const replacedLine = line.replace(
                  /<topic>/g,
                  `<strong>${topic}</strong>`
                );
                return (
                  <li
                    key={line}
                    className={classes["question"]}
                    dangerouslySetInnerHTML={{ __html: replacedLine }}
                  ></li>
                );
              } else {
                return null;
              }
            })}
        </ol>
      )}

      {questionType === "personal" && (
        <ol className={`${classes["list-marker"]} px-4 list-decimal`}>
          {Participant &&
            Participant[0] &&
            Participant[0].personalReflectionQuestion.length > 0 &&
            Participant[0].personalReflectionQuestion.map((line) => {
              if (line) {
                const replacedLine = line.replace(
                  /<topic>/g,
                  `<strong>${topic}</strong>`
                );
                return (
                  <li
                    key={line}
                    className={classes["question"]}
                    dangerouslySetInnerHTML={{ __html: replacedLine }}
                  ></li>
                );
              } else {
                return null;
              }
            })}
        </ol>
      )}

      {questionType === "compulsory" && (
        <ol className={`${classes["list-marker"]} px-4 list-decimal`}>
          {Participant &&
            Participant[0] &&
            Participant[0].compulsoryReflectionQuestion.length > 0 &&
            Participant[0].compulsoryReflectionQuestion.map((line) => {
              if (line) {
                const replacedLine = line.replace(
                  /<topic>/g,
                  `<strong>${topic}</strong>`
                );
                return (
                  <li
                    key={line}
                    className={classes["question"]}
                    dangerouslySetInnerHTML={{ __html: replacedLine }}
                  ></li>
                );
              } else {
                return null;
              }
            })}
        </ol>
      )}

      {questionType === "general" && (
        <ol className={`${classes["list-marker"]} px-4 list-decimal`}>
          {Journal &&
            Journal &&
            Array.isArray(Journal.reflectionQuestion) &&
            Journal.reflectionQuestion.length > 0 &&
            Journal.reflectionQuestion.map((line) => {
              if (line) {
                const replacedLine = line.replace(
                  /<topic>/g,
                  `<strong>${topic}</strong>`
                );
                return (
                  <li
                    key={line}
                    className={classes["question"]}
                    dangerouslySetInnerHTML={{ __html: replacedLine }}
                  ></li>
                );
              } else {
                return null;
              }
            })}
        </ol>
      )}

      {questionType === "personal" && (
        <ol className={`${classes["list-marker"]} px-4 list-decimal`}>
          {Journal &&
            Array.isArray(Journal.personalReflectionQuestion) &&
            Journal.personalReflectionQuestion.length > 0 &&
            Journal.personalReflectionQuestion.map((line) => {
              if (line) {
                const replacedLine = line.replace(
                  /<topic>/g,
                  `<strong>${topic}</strong>`
                );
                return (
                  <li
                    key={line}
                    className={classes["question"]}
                    dangerouslySetInnerHTML={{ __html: replacedLine }}
                  ></li>
                );
              } else {
                return null;
              }
            })}
        </ol>
      )}

      {questionType === "compulsory" && (
        <ol className={`${classes["list-marker"]} px-4 list-decimal`}>
          {Journal &&
            Array.isArray(Journal.compulsoryReflectionQuestion) &&
            Journal.compulsoryReflectionQuestion.length > 0 &&
            Journal.compulsoryReflectionQuestion.map((line) => {
              if (line) {
                const replacedLine = line.replace(
                  /<topic>/g,
                  `<strong>${topic}</strong>`
                );
                return (
                  <li
                    key={line}
                    className={classes["question"]}
                    dangerouslySetInnerHTML={{ __html: replacedLine }}
                  ></li>
                );
              } else {
                return null;
              }
            })}
        </ol>
      )}
    </>
  );
};

export default DisplayQuestions;
