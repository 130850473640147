import { useFirestore } from "hooks/useFirestore";
import { Profile } from "interface/ProfileInterface";
import { useEffect, useRef, useState } from "react";
import { DownloadTableExcel } from "react-export-table-to-excel";

const AllFacil = () => {
  const tableRef = useRef(null);
  const { getDocumentsByQuery } = useFirestore("users");
  const facilitators = getDocumentsByQuery(["isFacil", "==", true]);
  const [facils, setFacils] = useState(Array<Profile>);

  useEffect(() => {
    if (!facilitators) return;
    facilitators.then((data) => {
      setFacils(data as unknown as Profile[]);
    });
  }, [facilitators]);
  return (
    <div>
      <div className="px-4">
        <DownloadTableExcel
          filename={`Facilitators-data-${new Date()}`}
          sheet="users"
          currentTableRef={tableRef.current}
        >
          <button className="bg-green-500 hover:bg-green-600 px-4 py-2 rounded-md text-white">
            Export Excel
          </button>
        </DownloadTableExcel>
      </div>
      <p>Total {facils && <span>{facils.length}</span>}</p>
      <table ref={tableRef}>
        <thead>
          <tr>
            <th>Email</th>
            <th>isFacil</th>
          </tr>
        </thead>
        <tbody>
          {facils &&
            facils.map((user: Profile, index: number) => (
              <tr key={index}>
                <td>{user.email}</td>
                <td>{user.isFacil ? "True" : "False"}</td>
              </tr>
            ))}
        </tbody>
      </table>
    </div>
  );
};

export default AllFacil;
