import { useCallback, useEffect, useState } from "react";
import { projectFirestore, timestamp } from "../firebase/config";
import useOrganisationContext from "./organisation/useOrganisationContext";
import { Intention } from "interface/IntentionInterface";

export const useOrganisationIntentions = () => {
  const { selectedOrganisation } = useOrganisationContext();
  const [intentions, setIntentions] = useState<Intention[]>([]);

  const updateOrganisationIntentionSettings = useCallback(
    async (settingCheck: Object) => {
      try {
        projectFirestore
          .collection("organisations")
          .doc(selectedOrganisation?.id)
          .update(settingCheck);
      } catch (error) {
        console.error(error);
      }
    },
    [selectedOrganisation]
  );

  const AddNewIntentionQuestion = useCallback(
    async (question: string) => {
      try {
        const trimmedQuestion = question.trim();

        const data = {
          createdAt: timestamp.fromDate(new Date()),
          question: trimmedQuestion,
          organisationId: selectedOrganisation?.id,
        };
        const ref = await projectFirestore
          .collection("organisations")
          .doc(selectedOrganisation?.id)
          .collection("intentions")
          .add(data);
        await ref.update({ id: ref.id });
      } catch (error) {
        console.error(error);
      }
    },
    [selectedOrganisation]
  );

  const DeleteIntention = useCallback(
    async (questionId: string) => {
      try {
        await projectFirestore
          .collection("organisations")
          .doc(selectedOrganisation?.id)
          .collection("intentions")
          .doc(questionId)
          .delete();
      } catch (error) {
        console.error(error);
      }
    },
    [selectedOrganisation]
  );

  const updateIntention = useCallback(
    async (questionId: string, question: string) => {
      try {
        projectFirestore
          .collection("organisations")
          .doc(selectedOrganisation?.id)
          .collection("intentions")
          .doc(questionId)
          .update({ question: question });
      } catch (error) {
        console.error(error);
      }
    },
    [selectedOrganisation]
  );

  const organisationQuestionsMemo = () => {
    const unsubscribe = projectFirestore
      .collection("organisations")
      .doc(selectedOrganisation?.id)
      .collection("intentions")

      .onSnapshot(async (snapshot) => {
        const intentionsMap: Intention[] = [];

        snapshot.forEach((doc) => {
          const data = doc.data() as Intention;
          intentionsMap.push(data);
        });

        setIntentions(intentionsMap);
      });

    return () => {
      unsubscribe();
    };
  };

  useEffect(() => {
    organisationQuestionsMemo();
    // eslint-disable-next-line
  }, [selectedOrganisation]);

  return {
    updateOrganisationIntentionSettings,
    AddNewIntentionQuestion,
    updateIntention,
    DeleteIntention,
    intentions,
  };
};
