import { FC, useEffect, useState } from "react";
import { ComponentProps } from "../GenericSessionPage/ComponentMapping";
import classes from "./EnterCode.module.css";
import OrgBubble from "components/Bubble/OrgBubble";
import usePromise from "hooks/utility/usePromise";
import useJoinParticipant from "hooks/useJoinParticipant";
import useGetParticipants from "hooks/useGetParticipants";
import { useGrowthCircleContext } from "hooks/useGrowthCircleContext";
import { useAuthContext } from "hooks/useAuthContext";

const EnterCodeComponent: FC<ComponentProps> = ({ backAndNextHandler }) => {
  const { profile } = useAuthContext();
  const [userKey, setUserKey] = useState<string>("");
  const { isLoading, resolve } = usePromise();
  const { validateInviteCode, isPending, error, errorMessage } =
    useJoinParticipant();
  const { participants } = useGetParticipants();
  const { growthCircleSession } = useGrowthCircleContext();
  const buttonStyle = {
    color: "var(--main-colour-2)",
    backgroundColor: "var(--main-colour)",
  };

  const handleSubmit = async (e: React.FormEvent<HTMLFormElement>) => {
    e.preventDefault();
    await resolve(() => validateInviteCode(userKey));
  };
  const setUserKeyHandler = (value: string) => {
    const stringValue = value.toString();
    setUserKey(stringValue);
  };

  const nextCall = (e: any) => {
    backAndNextHandler.nextCallback(e);
  };

  useEffect(() => {
    if (!profile || !participants) return;
    let obj = participants.find((o) => o.userId === profile.id);

    if (profile.uid === growthCircleSession?.facilOwner) {
      setUserKey(growthCircleSession.invite_code);
    }

    if (obj) {
      nextCall("");
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [profile, participants, growthCircleSession]);

  return (
    <div className={classes["wrapper"]}>
      <div className={classes["container"]}>
        <OrgBubble />
        {growthCircleSession && (
          <p className={`${classes["prompt"]} mt-12 text-center`}>
            You're about to join a <u>{growthCircleSession.name}</u> session.
          </p>
        )}
        <p className={`${classes["prompt"]} mt-2`}>Enter GrowthCircle’s Code</p>
        <form onSubmit={handleSubmit}>
          <div className={classes["center"] + " " + classes["input-container"]}>
            <input
              className="outline-none focus:border-none focus:ring-0"
              type="tel"
              onChange={(e) => setUserKeyHandler(e.target.value)}
              autoFocus={true}
              required
              defaultValue={userKey}
            />
            {error && <p className={classes["error"]}>{errorMessage}</p>}
          </div>

          {/* Submit */}
          <button
            disabled={isLoading}
            className={classes["submitButton"]}
            type="submit"
            style={buttonStyle}
          >
            {isPending || isLoading ? "Verifying..." : "Next"}
          </button>
        </form>
      </div>
    </div>
  );
};

export default EnterCodeComponent;
