import { useState, useEffect } from "react";
import { projectAuth, projectFirestore, timestamp } from "../firebase/config";
import { useAuthContext } from "./useAuthContext";
import { useEmailAPI } from "./useEmailAPI";
import { Profile } from "interface/ProfileInterface";
import { validatePassword, validateUserName } from "utility/inputValidation";
import useUser, {
  EMAIL_AUTH_ERRORS,
  PASSWORD_AUTH_ERRORS,
  USER_NAME_AUTH_ERRORS,
} from "./useUser";
import { hasKey } from "utility/typePredicates";
import { createUserWithEmailAndPassword, updateProfile } from "firebase/auth";
import { acceptInvitations } from "models/organisation";
import { UpdateData } from "firebase/firestore";

export const useSignup = () => {
  const { getAuthErrorCategory, formatAuthError } = useUser();
  const [isCancelled, setIsCancelled] = useState<boolean>(false);
  const [error, setError] = useState<string | null>(null);
  const [isPending, setIsPending] = useState<boolean>(false);
  const [usernameError, setUsernameError] = useState<string | null>(null);
  const [emailError, setEmailError] = useState<string | null>(null);
  const [passwordError, setPasswordError] = useState<string | null>(null);
  const { dispatch } = useAuthContext();
  const { sendEMailVerification } = useEmailAPI();

  const signup = async (
    email: string,
    password: string,
    displayName: string,
    userDetails?: UpdateData<Profile>,
    OrganisationFields?: any
  ) => {
    setError(null);
    setUsernameError(null);
    setEmailError(null);
    setPasswordError(null);
    setIsPending(true);

    try {
      validateUserName(displayName);
      validatePassword(password);
      // signup
      const res = await createUserWithEmailAndPassword(
        projectAuth,
        email,
        password
      );

      if (!res || !res.user) {
        throw new Error("Could not complete signup");
      }

      // add display name to user
      await updateProfile(res.user, { displayName });

      //create a user document
      const newUser = {
        online: true,
        email: email,
        displayName: displayName,
        displayNameLowerCase: displayName.toLowerCase(),
        photoURL: "",
        firstname: "",
        lastname: "",
        gender: "",
        birthday: "",
        accountType: "email_and_password",
        friendRequests: [],
        referral: [],
        grow: [],
        last_active: timestamp.fromDate(new Date()),
        createdAt: timestamp.fromDate(new Date()),
        uid: res.user.uid,
        id: res.user.uid,
        progress: {},
        isFacil: false,
        age: "",
        growthCircle: "",
        hasStrongPassword: true,
        OrganisationFields: OrganisationFields ?? [],
        ...userDetails,
      } as unknown as Profile;
      await projectFirestore.collection("users").doc(res.user.uid).set(newUser);
      acceptInvitations(newUser);
      sendEMailVerification(email, displayName, res.user.uid);
      dispatch({ type: "LOGIN", payload: res.user, profile: newUser });

      setIsPending(false);
      return {
        res,
        error: {
          usernameError: null,
          emailError: null,
          passwordError: null,
        },
      };
    } catch (error: any) {
      console.error(error);
      const paddedError: { message: string; code: string } = {
        message: "",
        code: "",
      };
      if (hasKey(error, "message") && typeof error.message === "string") {
        paddedError.message = error.message;
      }
      if (hasKey(error, "code") && typeof error.code === "string") {
        paddedError.code = error?.code;
      }
      const errorMessage = formatAuthError(paddedError);
      switch (getAuthErrorCategory(paddedError)) {
        case USER_NAME_AUTH_ERRORS:
          setUsernameError(errorMessage);
          break;
        case EMAIL_AUTH_ERRORS:
          setEmailError(errorMessage);
          break;
        case PASSWORD_AUTH_ERRORS:
          setPasswordError(errorMessage);
          break;
        default:
          setError(errorMessage);
      }

      setIsPending(false); // Make sure to set isPending to false even in the catch block
      return {
        res: null,
        error: {
          usernameError,
          emailError,
          passwordError,
        },
      };
    }
  };

  const createUser = async (
    uid: string,
    email: string,
    displayName: string,
    userDetails?: UpdateData<Profile>,
    OrganisationFields?: any
  ) => {
    try {
      const newUser = {
        online: true,
        email: email,
        displayName: displayName,
        displayNameLowerCase: displayName.toLowerCase(),
        photoURL: "",
        firstname: "",
        lastname: "",
        gender: "",
        birthday: "",
        accountType: "email_and_password",
        friendRequests: [],
        referral: [],
        grow: [],
        last_active: timestamp.fromDate(new Date()),
        createdAt: timestamp.fromDate(new Date()),
        uid: uid,
        id: uid,
        progress: {},
        isFacil: false,
        changePassword: true,
        age: "",
        growthCircle: "",
        hasStrongPassword: true,
        OrganisationFields: OrganisationFields ?? [],
        ...userDetails,
      } as unknown as Profile;

      await projectFirestore.collection("users").doc(uid).set(newUser);

      return true;
    } catch (error: any) {
      return false;
    }
  };

  useEffect(() => {
    return () => setIsCancelled(true);
  }, []);

  return {
    usernameError,
    emailError,
    passwordError,
    signup,
    createUser,
    error,
    isPending,
    isCancelled,
  };
};
