import { ComponentProps } from "../SelfAwarenessComponentMapping";
import { FC, useEffect, useState } from "react";
import { FaCheckCircle } from "react-icons/fa";
import BackAndNextFlat from "components/utility/BackAndNextFlat";

const TopSelection: FC<ComponentProps> = ({ backAndNextHandler }) => {
  const [selectedItem, setSelectedItem] = useState<string[]>([]);
  const [TopSelectionItems, setTopSelectionItems] = useState<string[]>([]);

  // Load data from local storage on component mount
  useEffect(() => {
    const storedSelectedItems = localStorage.getItem("SelfAwarenessJournal");
    const existingJournal = storedSelectedItems
      ? JSON.parse(storedSelectedItems)
      : {};

    // Update SelfAwarenessJournal with the latest selectedItems
    const updatedJournal = {
      ...existingJournal,
      topSelectionGrading: {}, // Use the latest state here
      // other properties of SelfAwarenessJournal...
    };

    // Save the updated SelfAwarenessJournal to local storage
    localStorage.setItem(
      "SelfAwarenessJournal",
      JSON.stringify(updatedJournal)
    );
    if (storedSelectedItems) {
      const parsedJournal = JSON.parse(storedSelectedItems);
      setSelectedItem(parsedJournal.selectedItems || []);
    }
  }, []);

  const handleSubItemClick = (item: string) => {
    // Use the callback form to ensure the latest state
    setTopSelectionItems((prevSelected) => {
      // Check if the item is already selected
      if (prevSelected.includes(item)) {
        // If selected, remove it
        return prevSelected.filter((i) => i !== item);
      } else {
        // If not selected, add it (but limit to 3 items)
        return prevSelected.length < 3 ? [...prevSelected, item] : prevSelected;
      }
    });
  };

  useEffect(() => {
    // Get the existing SelfAwarenessJournal from local storage
    const storedJournal = localStorage.getItem("SelfAwarenessJournal");
    const existingJournal = storedJournal ? JSON.parse(storedJournal) : {};

    // Update SelfAwarenessJournal with the latest selectedItems
    const updatedJournal = {
      ...existingJournal,
      topSelectionItems: TopSelectionItems, // Use the latest state here
      // other properties of SelfAwarenessJournal...
    };

    // Save the updated SelfAwarenessJournal to local storage
    localStorage.setItem(
      "SelfAwarenessJournal",
      JSON.stringify(updatedJournal)
    );
  }, [TopSelectionItems]);

  return (
    <div className="py-8 px-4 w-full md:w-1/4">
      <div
        className="relative p-5 border-2 rounded-lg font-poppins font-semibold"
        style={{
          backgroundColor: "#FFF4B9",
          borderColor: "#FFE871",
          color: "var(--text-colour)",
        }}
      >
        {selectedItem.length > 2 ? (
          <p>
            These are the emotions you have selected. Select your top 3
            emotions.
          </p>
        ) : (
          <p className="text-center">
            You selected {selectedItem.join(" , ")}. Select them all by clicking
            the items.
          </p>
        )}
      </div>

      <div
        className="border-2 p-5 my-8 bg-white rounded-lg font-poppins  "
        style={{
          borderColor: "#FFE871",
          color: "var(--text-colour)",
        }}
      >
        {selectedItem &&
          selectedItem.map((item, index) => (
            <div
              key={index}
              style={{ borderBottomColor: "var(--icon-colour-0)" }}
              className={`border-b-2 p-2 flex gap-2 items-center cursor-pointer select-none`}
              onClick={() => handleSubItemClick(item)}
            >
              {TopSelectionItems.includes(item) && (
                <FaCheckCircle
                  size={20}
                  style={{ color: "var(--icon-colour-0)" }}
                />
              )}
              {item}
            </div>
          ))}
      </div>

      <div className="mt-5">
        <BackAndNextFlat
          {...backAndNextHandler}
          nextAllowed={
            TopSelectionItems.length === 3 ||
            TopSelectionItems.length === selectedItem.length
              ? true
              : false
          }
        />
      </div>
    </div>
  );
};

export default TopSelection;
