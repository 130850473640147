import { FC, useEffect, useState } from "react";
import { ComponentProps } from "../SelfAwarenessComponentMapping";
import BackAndNextFlat from "components/utility/BackAndNextFlat";
import { useSelfAwarenessContext } from "hooks/useSelfAwarenessContext";

import TitleBox from "../SubComponents/TitleBox";
import InfoModal from "../SubComponents/InfoModal";

import TextAreaFocus from "../SubComponents/TextAreaFocus";
import toast from "react-hot-toast";

const FocusValueQuestions: FC<ComponentProps> = ({
  backAndNextHandler,
  setHasModal,
}) => {
  const { journal } = useSelfAwarenessContext();

  const [show, setShow] = useState(false);

  const handler = () => {
    setShow(!show);
    setHasModal(!show);
  };

  useEffect(() => {
    if (!journal) return;
  }, [journal]);

  const nextHandler = (e: React.MouseEvent<HTMLButtonElement, MouseEvent>) => {
    const storedJournal = localStorage.getItem("SelfAwarenessJournal");
    const existingJournal = storedJournal ? JSON.parse(storedJournal) : {};
    const gradingItemsKeys = Object.keys(
      existingJournal.focusValueQuestions || {}
    );

    // Check if all grading items are filled
    const allGradingItemsFilled = gradingItemsKeys.every((key) => {
      const item = existingJournal.focusValueQuestions[key];
      return item.trim() !== "";
    });

    if (
      existingJournal &&
      existingJournal?.focusValueQuestions &&
      allGradingItemsFilled
    ) {
      backAndNextHandler.nextCallback(e);
    } else {
      toast.error("Please answer all questions");
    }
  };

  return (
    <div className="py-8 px-4 w-full md:w-1/4">
      {journal && <InfoModal handler={handler} show={show} journal={journal} />}
      {journal && (
        <>
          <TitleBox
            title={"What is the value that you both bring?"}
            showInfo={true}
            journal={journal}
            handler={handler}
          />
          <div className="my-4">
            <p
              style={{ color: "var(--text-colour)" }}
              className="font-poppins font-semibold"
            >
              The value of your approach is...
            </p>
            <TextAreaFocus
              storage="focusValueQuestions"
              question={`How does your approach address the concerns? What is the benefit of adopting your approach?`}
              row={3}
              journal={journal}
              maxLength={500}
            />
          </div>
          <div className="my-4">
            <p
              style={{ color: "var(--text-colour)" }}
              className="font-poppins font-semibold"
            >
              The value of their approach is...
            </p>
            <TextAreaFocus
              storage="focusValueQuestions"
              question={`How does their approach address the concerns? What is the benefit of adopting their approach?`}
              row={3}
              journal={journal}
              maxLength={500}
            />
          </div>

          <div className="mt-5">
            <BackAndNextFlat
              {...backAndNextHandler}
              nextCallback={(e) => nextHandler(e)}
            />
          </div>
        </>
      )}
    </div>
  );
};

export default FocusValueQuestions;
