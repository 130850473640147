import { Modal } from "flowbite-react";
import { useAuthContext } from "hooks/useAuthContext";
import { useGrowthCircleContext } from "hooks/useGrowthCircleContext";
import { acknowledgeReflectionCopyToInstructor } from "models/profile";
import { useState } from "react";

/**
 * A modal for users to acknowlegde that a copy of their reflections
 * will be sent to their instructors.
 *
 * @returns Acknowledgements component.
 */
export default function Acknowledgements() {
  // TODO: Refactor into a Organisation component for SUSS.
  const { profile } = useAuthContext();
  const { growthCircleSession } = useGrowthCircleContext();
  const [accept, setAccept] = useState(false);

  const acceptHandler = async () => {
    if (!profile) {
      return;
    }
    await acknowledgeReflectionCopyToInstructor(profile.uid);
    window.location.reload();
  };

  const showModal = profile?.personalID !== undefined
    && growthCircleSession?.organisation === "SUSS"
    && profile?.acceptCopy !== true;
  

  return (
    <Modal
      show={showModal}
      size="md"
      popup={true}
      className="min-h-full"
    >
      <Modal.Body className="relative px-5">
        <div className="text-center py-5">
          <h3 className="mb-5 py-4 text-lg font-normal text-gray-500 dark:text-gray-400">
            <p>As this forms part of your course assessment as part of PSY213, 
              the National University of Singapore and Growthbeans requires your 
              consent to disclose the personal information you provide in the reflection 
              to your course instructors.</p>
              <br />
            <p>In participating in this reflection exercise, 
              you consent to Growthbean’s collection, use, and disclosure of the reflection 
              and your Personal Data to the National University of Singapore for the purposes 
              of assessment of your reflection as part of your coursework for PSY213.</p>
          </h3>

          <div className="flex justify-center items-start py-4">
            <label className="text-gray-500 dark:text-gray-400">
              <input
                onChange={() => setAccept(!accept)}
                type="checkbox"
                className="mx-2 text-main-colour"
              />
              I acknowledge, please do{" "}
              <span className="block">not show me again.</span>
            </label>
          </div>

          <div className="flex justify-center gap-4">
            <button
              onClick={acceptHandler}
              className={` ${
                !accept ? "bg-slate-300" : "bg-main-colour"
              } text-default shadow-md rounded-full px-12 py-2`}
            >
              OK
            </button>
          </div>
        </div>
      </Modal.Body>
    </Modal>
  );
}
