import React from "react";
import classes from "./Skill.module.css";
import Role from "../../ChooseRoles/Role/Role";
import EmptySpace from "../../utility/EmptySpace";
import RoleInterface from "interface/RoleInterface";
import { INSTRUCTIONS_ROLE, SKILL_TITLE } from "strings/SharingPanel";
import Instruction from "components/utility/Instruction";

interface Props {
  role: RoleInterface;
}

const Skill: React.FC<Props> = ({ role }) => {
  return (
    <div className={classes["skill-container"]}>
      {role !== undefined && role !== null ? (
        <>
          <strong className={classes["title"]}>{SKILL_TITLE}</strong>
          <Instruction
            text={INSTRUCTIONS_ROLE}
            className={classes["instructions"]}
          />
          <EmptySpace height={"30px"} />
          {role && <Role key={role.role} object={role} />}
        </>
      ) : (
        <>
          <strong className={`${classes["title"]} text-center`}>
            Roles is not available for this GrowthCircle
          </strong>

          <EmptySpace height={"30px"} />
        </>
      )}
    </div>
  );
};

export default Skill;
