import {
  updatePassword,
  EmailAuthProvider,
  reauthenticateWithCredential,
  updateEmail,
  updateProfile,
} from "firebase/auth";
import { Profile } from "interface/ProfileInterface";
import { useAuthContext } from "./useAuthContext";
import {
  validateName,
  validateUserName,
  validateBirthday,
  validatePassword,
  USER_NAME_INPUT_ERRORS,
  NAME_INPUT_ERRORS,
  BIRTHDATE_INPUT_ERRORS,
  PASSWORD_INPUT_ERRORS,
  ERROR_MESSAGES,
} from "utility/inputValidation";
import { projectFirestore } from "../firebase/config";
import { updateUser } from "models/profile";
import { UpdateData } from "firebase/firestore";
import Organisation from "interface/OrganisationInterface";

export const USER_NAME_AUTH_ERRORS = {
  ...USER_NAME_INPUT_ERRORS,
} as const;

export const NAME_AUTH_ERRORS = {
  ...NAME_INPUT_ERRORS,
} as const;

export const EMAIL_AUTH_ERRORS = {
  EMAIL_INVALID: "auth/invalid-email",
  EMAIL_IN_USE: "auth/email-already-in-use",
} as const;

export const BIRTHDATE_AUTH_ERRORS = {
  ...BIRTHDATE_INPUT_ERRORS,
} as const;

export const PASSWORD_AUTH_ERRORS = {
  ...PASSWORD_INPUT_ERRORS,
  PASSWORD_WEAK: "auth/weak-password",
  PASSWORD_WRONG: "auth/wrong-password",
} as const;

export const OTHER_AUTH_ERRORS = {
  REQUIRES_REAUTH: "auth/requires-recent-login",
} as const;

const AUTH_ERROR_MESSAGES = {
  ...ERROR_MESSAGES,
  "auth/invalid-email": "Invalid email",
  "auth/email-already-in-use": "Email already in use",
  "auth/requires-recent-login": "Please re-login and try again",
  "auth/weak-password": "Please use a stronger password",
  "auth/wrong-password": "Incorrect password, please try again",
};

const useUser = () => {
  const { dispatch, user, profile } = useAuthContext();

  /**
   * Reauthenticates users that log in via email and password.
   *
   * @param currentPassword Current password input from user.
   * @returns Promise of updated user credentials.
   */
  const reauthenticate = async (currentPassword: string) => {
    if (user && user.email) {
      const credential = EmailAuthProvider.credential(
        user.email,
        currentPassword
      );
      return reauthenticateWithCredential(user, credential);
    }
  };

  /**
   * Updates the current user's email.
   *
   * @param newEmail New email of the user.
   * @returns A promise that resolves when the update completes.
   */
  const updateUserEmail = async (newProfile: Profile) => {
    if (user && profile && user.email !== newProfile.email) {
      return updateEmail(user, newProfile.email).then(() => {
        newProfile.isVerified = false;
      });
    }
  };

  /**
   * Updates the curreny user's display name.
   *
   * @param newDisplayName New display name of the user.
   * @returns A promise that resolves when the update completes.
   */
  const updateUserDisplayName = async (newDisplayName: string) => {
    if (user && profile) {
      const newProfile: {
        displayName?: string;
        displayNameLowerCase?: string;
        photoURL?: string;
      } = {};
      if (user.displayName !== newDisplayName) {
        newProfile.displayName = newDisplayName;
        newProfile.displayNameLowerCase = newDisplayName.toLowerCase();
      }
      if (newProfile) {
        return updateProfile(user, newProfile);
      }
    }
  };

  /**
   * Updates the profile of the current user.
   * Updates the user object in firebase auth (email and profile picture link) as well as the user document in firestore.
   *
   * @param newProfile updated Profile object.
   * @returns A Promise that resolves when all updates complete.
   */
  const setProfile = async (newProfile: Profile) => {
    if (user && profile) {
      validateUserName(newProfile.displayName);
      validateName(newProfile.firstname);
      validateName(newProfile.lastname);
      validateBirthday(newProfile.birthday);
      return Promise.all([
        updateUserEmail(newProfile),
        updateUserDisplayName(newProfile.displayName),
      ])
        .then(() => updateUser(user.uid, newProfile as UpdateData<Profile>))
        .then(() =>
          dispatch({
            type: "AUTH_IS_READY",
            payload: user,
            profile: newProfile,
          })
        );
    }
  };

  /**
   * Updates the password of the current user.
   *
   * @param currentPassword Current password of the user.
   * @param newPassword New password of the user.
   * @returns A Promise that resolves when all updates complete.
   */
  const setPassword = async (currentPassword: string, newPassword: string) => {
    if (user && profile) {
      validatePassword(newPassword);
      return reauthenticate(currentPassword)
        .then(() => updatePassword(user, newPassword))
        .then(() =>
          setProfile({
            ...profile,
            hasStrongPassword: true,
            changePassword: false,
          })
        );
    }
  };

  /**
   * Checks the category of Auth Error.
   * Auth errors include input errors and errors thrown by firebase.
   *
   * @param errorCode Auth Error.
   * @returns The category of errors if the code belongs to any, and null otherwise.
   */
  const getAuthErrorCategory = (error: { code: string; message: string }) => {
    for (let category of [
      USER_NAME_AUTH_ERRORS,
      NAME_AUTH_ERRORS,
      BIRTHDATE_AUTH_ERRORS,
      EMAIL_AUTH_ERRORS,
      PASSWORD_AUTH_ERRORS,
      OTHER_AUTH_ERRORS,
    ]) {
      let errors = Object.values<string>(category);
      if (errors.includes(error.code) || errors.includes(error.message)) {
        return category;
      }
    }
    return null;
  };

  /**
   * Formats authentication errors.
   *
   * @param error Authentication error.
   * @returns User friendly message describing the error.
   */
  const formatAuthError = (error: { code: string; message: string }) => {
    if (getAuthErrorCategory(error)) {
      if (error.code) {
        return AUTH_ERROR_MESSAGES[error.code];
      }
      return AUTH_ERROR_MESSAGES[error.message];
    }
    if (error.message) {
      return error.message;
    }
    return "An unknown error occured. Please refresh the page and try again";
  };

  const updateProfileInfo = async (uid: string, collectedDataObject: any) => {
    const data: UpdateData<Profile> = {
      OrganisationFields: collectedDataObject,
    };
    return updateUser(uid, data);
  };

  const synOldProfileToNew = async (
    current_uid: string,
    new_uid: string,
    displayName: string
  ) => {
    let userRef = projectFirestore.collection("users");
    const queryRef = userRef.where("uid", "==", current_uid);

    await queryRef
      .get()
      .then(async function (doc) {
        if (doc.docs.length > 0) {
          doc.docs.map((_user) => {
            const data: UpdateData<Profile> = {
              age: "",
              gender: "",
              online: false,
              displayName: displayName,
              displayNameLowerCase: displayName.toLowerCase(),
              isEmailSent: false,
              isFacil: _user.data().isFacil,
              id: new_uid,
              progress: _user.data().progress,
            };

            //update to profile
            updateUser(new_uid, data);

            return new_uid;
          });
        } else {
          console.log("no record");
        }
      })
      .catch(function (err) {
        console.log("Error getting documents: ", err);
      });
  };

  const getUserById = async (id: string): Promise<Profile | null> => {
    try {
      const user = await projectFirestore
        .collection("users")
        .where("uid", "==", id)
        .get();

      if (user.docs.length > 0) {
        const userData = user.docs.map((_user) => _user.data() as Profile);
        return userData[0]; // Assuming you want to return the first user found
      } else {
        return null;
      }
    } catch (error) {
      console.error("Error getting documents: ", error);
      return null;
    }
  };

  const getAllOrganisationByUser = async (
    uid: string
  ): Promise<Organisation[]> => {
    try {
      const orgsSnapshot = await projectFirestore
        .collection("organisations")
        .get();
      const organisations: Organisation[] = [];

      orgsSnapshot.forEach((doc) => {
        const data = doc.data() as Organisation;
        if (data.users && data.users.hasOwnProperty(uid)) {
          organisations.push({ ...data });
        }
      });

      return organisations;
    } catch (error) {
      console.error("Error fetching organisations: ", error);
      return [];
    }
  };

  return {
    user,
    profile,
    setProfile,
    setPassword,
    getAuthErrorCategory,
    formatAuthError,
    synOldProfileToNew,
    updateProfileInfo,
    getUserById,
    getAllOrganisationByUser,
  };
};

export default useUser;
