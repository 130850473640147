import { useEffect, useState } from "react";
import SliderGrading from "./SliderGrading";
import { GradingQuestionInterface } from "interface/GradingQuestionInterface";
import { colours } from "./styles/colourMap";
import { SelfAwarenessToolInterface } from "interface/SelfAwarenessToolInterface";

const GradingItemQuestion = ({
  question,
  journal,
}: {
  question: GradingQuestionInterface;
  journal: SelfAwarenessToolInterface;
}) => {
  const [value, setValue] = useState(5);

  useEffect(() => {
    const storedJournal = localStorage.getItem("SelfAwarenessJournal");
    const existingJournal = storedJournal ? JSON.parse(storedJournal) : {};

    // Update or add the grade for the current item
    const updatedTopSelectionItem = {
      ...existingJournal.gradingItems,
      [question.category]: value,
    };

    // Update existingJournal with the latest topSelectionItem
    const updatedJournal = {
      ...existingJournal,
      gradingItems: updatedTopSelectionItem,
      // other properties of SelfAwarenessJournal...
    };

    // Save the updated SelfAwarenessJournal to local storage
    localStorage.setItem(
      "SelfAwarenessJournal",
      JSON.stringify(updatedJournal)
    );
  }, [value, question]);

  return (
    <div
      className="p-5 w-full bg-white my-4 rounded-xl border-2"
      style={{
        color: "var(--text-colour)",
        borderColor: colours[journal.pageType]["border"],
      }}
    >
      <p className="font-semibold">{question.label}</p>
      <div className="p-2">
        <SliderGrading
          withBackground={true}
          value={value}
          setValue={setValue}
        />
      </div>
    </div>
  );
};

export default GradingItemQuestion;
