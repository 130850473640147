import React from "react";
import classes from "./RoleBack.module.css";
import { ReactComponent as Xbutton } from "assets/Xbutton.svg";
import RoleInterface from "../../../../interface/RoleInterface";
import { WHY_IS, IMPORTANT } from "strings/ChooseRoles";

interface Props {
  setIsFlipped: React.Dispatch<React.SetStateAction<boolean>>;
  role: string;
  importance: string;
  currSelected?: RoleInterface | null;
}

const RoleBack: React.FC<Props> = ({
  setIsFlipped,
  role,
  importance,
  currSelected,
}) => {
  const onClickFlipBack = (e: React.MouseEvent<HTMLButtonElement>) => {
    e.stopPropagation();
    setIsFlipped(false);
  };

  return (
    <div
      className={`${classes["role-back-container"]} ${
        currSelected && currSelected.role === role && classes["role-selected"]
      }`}
    >
      <p>
        {WHY_IS}
        <strong>{role}</strong> {IMPORTANT}
      </p>
      <div className={classes["line"]}></div>
      <p>{importance}</p>
      <button className={classes["flip-button"]} onClick={onClickFlipBack}>
        <Xbutton fill={"var(--main-colour)"} />
      </button>
    </div>
  );
};

export default RoleBack;
