import React from "react";

interface Props {
  fill: string;
  height?: string;
  width?: string;
}

const ReflectiveThinking: React.FC<Props> = ({ fill, height, width }) => {
  return (
    <svg
      width={width ? width : "47"}
      height={height ? height : "54"}
      viewBox="0 0 47 54"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M42.3939 22.197L45 31.9697L41.7424 36.5303L40.4394 43.697H35.8788L31.9697 42.3939L30.6667 51.5152H11.1212L8.51515 37.9884C4.50182 34.1888 2 28.8112 2 22.8485C2 11.3336 11.3336 2 22.8485 2C34.3634 2 42.3939 10.6821 42.3939 22.197Z"
        stroke={fill}
        strokeWidth="4"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M16.333 20.2437C16.333 18.9551 16.7151 17.6955 17.431 16.624C18.1469 15.5526 19.1644 14.7176 20.3549 14.2245C21.5454 13.7313 22.8554 13.6023 24.1192 13.8537C25.383 14.1051 26.5439 14.7256 27.4551 15.6368C28.3662 16.5479 28.9867 17.7088 29.2381 18.9726C29.4895 20.2364 29.3605 21.5464 28.8674 22.7369C28.3743 23.9274 27.5392 24.9449 26.4678 25.6608C25.3964 26.3767 24.1367 26.7588 22.8482 26.7588V30.6679M22.8482 38.4861V39.7891"
        stroke={fill}
        strokeWidth="4"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
    </svg>
  );
};

export default ReflectiveThinking;
