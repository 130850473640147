import { useContext } from "react";
import { OrganisationGroupContext } from "context/OrganisationGroupContext";

const ERR_NO_PROVIDER = "useOrganisationGroupContext must be used inside an OrganisationGroupContextProvider";

/**
 * Provides the context for the groups of the organisation and the currently selected group.
 *
 * @returns OrganisationGroupContext.
 */
export default function useOrganisationGroupContext() {

  const context = useContext(OrganisationGroupContext);

  if (!context) {
    throw Error(ERR_NO_PROVIDER);
  }

  return context;
}
