import { SelfAwarenessContext } from "../context/SelfAwarenessContext";
import { useContext } from "react";

export const useSelfAwarenessContext = () => {
  const context = useContext(SelfAwarenessContext);

  if (!context) {
    throw Error(
      "useSelfAwarenessContext must be used inside an AuthContextProvider"
    );
  }

  return context;
};
