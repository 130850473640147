import PublicQuestion from "interface/PublicQuestionInterface";
import {
  addPublicQuestion,
  deletePublicQuestion,
  getPublicQuestions,
} from "models/publicQuestion";
import { useCallback, useEffect, useState } from "react";

/**
 * Keeps the state of a list of public questions.
 *
 * @returns PublicQuestionHook.
 */
const usePublicQuestion = () => {
  const [publicQuestions, setPublicQuestions] = useState<PublicQuestion[]>([]);

  const fetchPublicQuestions = useCallback(async () => {
    const publicQuestions = await getPublicQuestions();

    setPublicQuestions(publicQuestions);
  }, []);

  /**
   * Adds new public questions to the existing list of public questions.
   *
   * @param newPublicQuestions - The array of new public questions to be added.
   * @returns A promise that resolves when all the new public questions have been added.
   */
  const addPublicQuestions = useCallback(
    async (newPublicQuestions: PublicQuestion[]) => {
      const promises = newPublicQuestions.map(async (newPublicQuestion) => {
        try {
          await addPublicQuestion(newPublicQuestion);
        } catch (error) {
          console.error(error);
        }
      });

      await Promise.all(promises);
      setPublicQuestions([...publicQuestions, ...newPublicQuestions]);
    },
    [publicQuestions]
  );

  /**
   * Deletes multiple public questions.
   *
   * @param publicQuestionIds - An array of public question IDs to be deleted.
   */
  const deletePublicQuestions = useCallback(
    async (publicQuestionIds: string[]) => {
      const promises = publicQuestionIds.map(async (publicQuestionId) => {
        try {
          await deletePublicQuestion(publicQuestionId);
        } catch (error) {
          console.error(error);
        }
      });

      await Promise.all(promises);
      setPublicQuestions(
        publicQuestions.filter(
          (publicQuestion) => !publicQuestionIds.includes(publicQuestion.id)
        )
      );
    },
    [publicQuestions]
  );

  useEffect(() => {
    fetchPublicQuestions();
  }, [fetchPublicQuestions]);

  return {
    publicQuestions,
    addPublicQuestions,
    deletePublicQuestions,
  };
};

export default usePublicQuestion;
