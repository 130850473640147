import { FC, useEffect, useState } from "react";
import { ComponentProps } from "../SelfAwarenessComponentMapping";
import BackAndNextFlat from "components/utility/BackAndNextFlat";
import { useSelfAwarenessContext } from "hooks/useSelfAwarenessContext";
import TextArea from "../SubComponents/TextArea";
import TitleBox from "../SubComponents/TitleBox";
import InfoModal from "../SubComponents/InfoModal";
import ConfirmModal from "../SubComponents/ConfirmModal";

const EndQuestion: FC<ComponentProps> = ({
  backAndNextHandler,
  setHasModal,
}) => {
  const [answer, setAnswer] = useState("");
  const { journal } = useSelfAwarenessContext();
  const [showConfirm, setShowConfirm] = useState<boolean>(false);
  const [show, setShow] = useState(false);

  const handler = () => {
    setShow(!show);
    setHasModal(!show);
  };

  // Load data from local storage on component mount
  useEffect(() => {
    const storedJournal = localStorage.getItem("SelfAwarenessJournal");
    if (storedJournal) {
      const parsedJournal = JSON.parse(storedJournal);
      setAnswer(parsedJournal.endAnswer || "");
    }
    // eslint-disable-next-line
  }, []); // Empty dependency array means this effect runs once on mount

  const answerHandler = (value: string) => {
    setAnswer(value);
    const storedJournal = localStorage.getItem("SelfAwarenessJournal");
    const existingJournal = storedJournal ? JSON.parse(storedJournal) : {};

    // Update the existing journal with the new answer
    const updatedJournal = {
      ...existingJournal,

      endAnswer: value,
    };

    // Store the updated journal in local storage
    localStorage.setItem(
      "SelfAwarenessJournal",
      JSON.stringify(updatedJournal)
    );
  };

  useEffect(() => {
    if (!journal) return;
  }, [journal]);

  const confirmHandler = () => {
    setShowConfirm(!showConfirm);
    setHasModal(!showConfirm);
  };

  return (
    <div className="py-8 px-4 w-full md:w-1/4">
      <ConfirmModal
        backAndNextHandler={backAndNextHandler}
        setHasModal={setHasModal}
        handler={confirmHandler}
        showConfirm={showConfirm}
      />
      {journal && <InfoModal handler={handler} show={show} journal={journal} />}
      {journal && (
        <>
          <TitleBox
            title={journal.endQuestionTitle || ""}
            showInfo={true}
            journal={journal}
            handler={handler}
          />
          <TextArea
            maxLength={500}
            answer={answer}
            journal={journal}
            answerHandler={answerHandler}
          />

          <div className="mt-5">
            <BackAndNextFlat
              {...backAndNextHandler}
              nextAllowed={answer.length > 0 ? true : false}
              nextCallback={confirmHandler}
            />
          </div>
        </>
      )}
    </div>
  );
};

export default EndQuestion;
