import { Modal } from "flowbite-react";
import Organisation from "interface/OrganisationInterface";
import { useState } from "react";
import { AiFillCloseCircle, AiFillInfoCircle } from "react-icons/ai";
import TemplateOverview from "./TemplateOverview";

interface TemplateCardProps {
  data: Organisation;
  isSelected: boolean;
  onSelect: () => void;
}

const TemplateCard = ({ data, isSelected, onSelect }: TemplateCardProps) => {
  const [isVisible, setIsVisible] = useState(false);
  const hoverHandler = () => {
    if (!isSelected) {
      onSelect();
      setIsVisible(!isVisible);
    } else {
      setIsVisible(!isVisible);
    }
  };

  return (
    <>
      <Modal size={"7xl"} show={isVisible}>
        <Modal.Body className="relative min-h-[80vh] bg-default">
          {" "}
          <div>
            <AiFillCloseCircle
              onClick={() => setIsVisible(!isVisible)}
              className="absolute top-5 right-5 cursor-pointer"
              size={30}
              style={{ color: "var(--icon-colour-0)" }}
            />
            <TemplateOverview onSelect={hoverHandler} data={data} />
          </div>
        </Modal.Body>
      </Modal>
      <div
        onClick={onSelect}
        style={{
          borderColor: isSelected ? "#F9BB62" : "",
        }}
        className={`relative cursor-pointer text-center w-[150px] shrink-0 h-[150px] bg-red-400 flex justify-center items-center rounded-lg ${
          isSelected ? "border-[10px]" : ""
        }`}
      >
        <AiFillInfoCircle
          onClick={hoverHandler}
          size={25}
          className="absolute top-2 right-2 z-10"
        />

        {data.name}
      </div>
    </>
  );
};

export default TemplateCard;
