import { useState } from "react";
import { useLogin } from "hooks/useLogin";
import ResetEmail from "./ResetEmail";
import InputField from "components/utility/Forms/InputField";
import Button from "components/utility/Button";

const buttonStyle = {
  fontSize: "1em",
  fontWeight: "500",
};

interface Props {
  instructions: JSX.Element;
  cssClasses: { readonly [key: string]: string }
}

/**
 * Password reset form using email with success message.
 *
 * @param instructions Instructions to the end user in the form of a JSX element.
 * @param cssClasses Object containing class names to style form elements.
 * @returns PasswordReset component.
 */
const PasswordReset: React.FC<Props> = ({ instructions, cssClasses }) => {
  const { forgotPassword, isPending, error, resetSent } = useLogin()
  const [email, setEmail] = useState('')

  const submitHandler = (e: React.FormEvent<HTMLFormElement>) => {
    e.preventDefault();
    forgotPassword(email);
  };

  return (
    resetSent
    ? <ResetEmail email={email} />
    : (
      <>
        {instructions}
        <form onSubmit={submitHandler} className={cssClasses.form}>
          <div className={cssClasses.inputs}>
            <InputField
              text="Email"
              Invalid={error}
              onChange={(e: React.ChangeEvent<HTMLInputElement>) => setEmail(e.target.value)}
              value={email}
            />
          </div>
          <span />
          <div className={cssClasses.button}>
            <Button
              text={isPending ? "Loading..." : "Next"}
              style={buttonStyle}
              type="submit"
            />
          </div>
        </form>
      </>
    )
  );
}

export default PasswordReset;