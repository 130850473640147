import { useReducer, useCallback, useMemo } from "react";
import { projectFirestore, timestamp } from "../firebase/config";
import firebase from "firebase/compat/app";
import Query = firebase.firestore.Query;
import WhereFilterOp = firebase.firestore.WhereFilterOp;
import FieldValue = firebase.firestore.FieldValue;
import {
  DocumentData,
  DocumentSnapshot,
  SetOptions,
  doc,
  getDoc,
  getDocs,
} from "firebase/firestore";

export type QueryCondition =
  | {
      field: string;
      operator: WhereFilterOp;
      value: string | boolean;
    }
  | undefined;

export interface initialStateStructure {
  document: any;
  isPending: boolean;
  error: string | null;
  success: boolean;
}

export interface actionStructure {
  type:
    | "RETRIEVED_DOCUMENT"
    | "IS_PENDING"
    | "ADDED_DOCUMENT"
    | "DELETED_DOCUMENT"
    | "UPDATED_DOCUMENT"
    | "SUCCESS"
    | "ERROR";
  payload?: any;
}

let initialState = {
  document: null,
  isPending: false,
  error: null,
  success: false,
};

/**
  TODO: Firebase has recent breaking changes, `ref.set` and `ref.update` will
  no longer return the new document. This causes `action.payload` in
  'ADDED_DOCUMENT' and 'UPDATED_DOCUMENT' to be undefined. 
  
  Let's refactor the reducer to reflect these changes.
*/
export const firestoreReducer = (
  state: initialStateStructure,
  action: actionStructure
) => {
  switch (action.type) {
    case "IS_PENDING":
      return {
        ...state,
        isPending: true,
      };
    case "ADDED_DOCUMENT":
      return {
        isPending: false,
        document: action.payload,
        success: true,
        error: null,
      };
    case "RETRIEVED_DOCUMENT":
      return {
        isPending: false,
        document: action.payload,
        success: true,
        error: null,
      };
    case "DELETED_DOCUMENT":
      return {
        isPending: false,
        document: null,
        success: true,
        error: null,
      };
    case "UPDATED_DOCUMENT":
      return {
        isPending: false,
        document: action.payload,
        success: true,
        error: null,
      };
    case "SUCCESS":
      return {
        ...state,
        isPending: false,
        success: true,
        error: null,
      };
    case "ERROR":
      return {
        isPending: false,
        document: null,
        success: false,
        error: action.payload,
      };
    default:
      return state;
  }
};

/**
 * Hook to get functions that can modify the give collection.
 * If collections and docs are used, docs must be 1 length shorter than collections
 *
 * @param collection - the name of the collection to retrieve
 * @param collections - an array of nested collections to retrieve, in order
 * @param docs - an array of doc id to traverse the nested collections, in order
 */
export const useFirestore = (
  collection: string | null,
  collections?: string[],
  docs?: string[]
) => {
  const [response, dispatch] = useReducer(firestoreReducer, initialState);

  const ref = useMemo(() => {
    if (collection) {
      // if only 1 layer
      return projectFirestore.collection(collection);
    } else if (collections && docs) {
      // if nested collection
      let colRef = projectFirestore.collection(collections[0]);
      for (let i = 0; i < docs.length; i++) {
        if (!docs[i] || !collections[i + 1]) break;
        const docRef = colRef!.doc(docs[i]);
        colRef = docRef.collection(collections[i + 1]);
      }
      return colRef;
    }
    throw new Error(
      "useFirestore: Either specify collection" +
        " or specify both collections and docs"
    );
  }, [collection, collections, docs]);

  // only dispatch is not cancelled
  const dispatchIfNotCancelled = useCallback((action: actionStructure) => {
    // if (!isCancelled) {
    dispatch(action);
    // }
  }, []);

  // add a document
  const addDocument = useCallback(
    async <T extends object>(doc: T) => {
      dispatch({ type: "IS_PENDING" });
      try {
        const createdAt = firebase.firestore.Timestamp.now();
        const addedDocument = await ref.add({ ...doc, createdAt });
        dispatchIfNotCancelled({
          type: "ADDED_DOCUMENT",
          payload: addedDocument,
        });
        console.info("Document added");
        return {
          ...doc,
          id: addedDocument.id,
        };
      } catch (err) {
        console.error("Error found" + err);
        let msg = "";
        if (err instanceof Error) {
          msg = err.message;
        }
        dispatchIfNotCancelled({ type: "ERROR", payload: msg });
      }
    },
    [ref, dispatchIfNotCancelled]
  );

  /**
   * Set a document using an id.
   *
   * @param id Id of the document.
   * @param data Data to upload as document.
   */
  const setDocument = useCallback(
    async (id, data, options?: SetOptions) => {
      try {
        dispatchIfNotCancelled({ type: "IS_PENDING", payload: null });
        console.log("changine", id, data, options);
        options
          ? await ref.doc(id).set(data, options)
          : await ref.doc(id).set(data);
        dispatchIfNotCancelled({ type: "SUCCESS", payload: null });
      } catch (err) {
        let msg = "";
        if (err instanceof Error) {
          msg = err.message;
        }
        dispatchIfNotCancelled({ type: "ERROR", payload: msg });
        return;
      }
    },
    [dispatchIfNotCancelled, ref]
  );

  /**Set a document using a transaction
   * @param id - id to be set
   * @param doc - document to be set
   */
  const setDocumentTransaction = useCallback(
    async (id: string, doc: { [key: string]: any }) => {
      dispatch({ type: "IS_PENDING" });
      try {
        const docRef = ref.doc(id ? id : "");
        const createdAt = firebase.firestore.FieldValue.serverTimestamp();
        projectFirestore.runTransaction(async (transaction) => {
          await transaction.get(docRef).then((document) => {
            //Set if document does not already exist
            if (!document.exists) {
              console.info("Setting document");
              transaction.set(docRef, { ...doc, createdAt: createdAt });
            } else {
              //Update the document
              //TODO: Perform deep merge with otherRatings
              const keys = Object.keys(doc);
              const data = document.data() as firebase.firestore.DocumentData;
              keys.forEach((key, index) => {
                //1. If there are same keys, deep merge
                if (
                  data[key] &&
                  typeof data[key] === "object" &&
                  !Array.isArray(data[key])
                ) {
                  doc = {
                    ...doc,
                    [key]: {
                      ...data[key],
                      ...doc[key],
                    },
                  };
                }
              });
              console.info("Updating document");
              transaction.update(docRef, doc);
            }
            console.info("Successful doc set");
            dispatchIfNotCancelled({
              type: "ADDED_DOCUMENT",
              payload: doc,
            });
          });
        });
      } catch (err) {
        let msg = "";
        if (err instanceof Error) msg = err.message;
        dispatchIfNotCancelled({ type: "ERROR", payload: msg });
        console.error("this was called error :", err);
        return null;
      }
    },
    [dispatchIfNotCancelled, ref]
  );

  // delete a document
  const deleteDocument = useCallback(
    async (id: string | undefined) => {
      if (!id) return;

      dispatch({ type: "IS_PENDING" });

      try {
        await ref.doc(id).delete();
        dispatchIfNotCancelled({ type: "DELETED_DOCUMENT" });
      } catch (err) {
        dispatchIfNotCancelled({ type: "ERROR", payload: "could not delete" });
      }
    },
    [dispatchIfNotCancelled, ref]
  );

  /**
   * Deletes fields from a document
   * @param id - query to get single document {field: , operator: , value: }
   * @param fields - array of field names to be deleted []
   */
  const deleteDocumentFieldByQuery = useCallback(
    async (id: string, fields: string[]) => {
      dispatch({ type: "IS_PENDING" });

      try {
        //1. Get the doc ref
        const docRef = ref.doc(id);

        //2. Get the fieldMapping
        const fieldMapping: { [key: string]: FieldValue } = {};
        fields.forEach((field) => {
          fieldMapping[field] = firebase.firestore.FieldValue.delete();
        });

        const updatedDocument = await docRef.update(fieldMapping);
        dispatchIfNotCancelled({
          type: "UPDATED_DOCUMENT",
          payload: updatedDocument,
        });
      } catch (err) {
        dispatchIfNotCancelled({ type: "ERROR", payload: "could not delete" });
      }
    },
    [dispatchIfNotCancelled, ref]
  );

  //update documents
  const updateDocument = useCallback(
    async (id: string | undefined, updates: object) => {
      if (!id) return;
      dispatch({ type: "IS_PENDING" });
      try {
        const updatedDocument = await ref.doc(id).update(updates);
        dispatchIfNotCancelled({
          type: "UPDATED_DOCUMENT",
          payload: updatedDocument,
        });
        return updatedDocument;
      } catch (err) {
        let msg = "";
        if (err instanceof Error) {
          msg = err.message;
        }
        dispatchIfNotCancelled({ type: "ERROR", payload: msg });
        return;
      }
    },
    [dispatchIfNotCancelled, ref]
  );

  /**
   * Retrieves the document data and pads it with document id.
   *
   * @param documentSnapshot Document snapshot.
   * @returns Document data with its id.
   */
  const _padWithId = useCallback((documentSnapshot: DocumentSnapshot) => {
    if (!documentSnapshot.exists()) {
      throw new Error("Document does not exist");
    }
    return {
      ...documentSnapshot.data(),
      id: documentSnapshot.id,
    };
  }, []);

  /**
   * Retrives a document based on a relative path.
   *
   * @param docPath Relative path to document.
   * @returns Retrived document.
   */
  const getDocumentById = useCallback(
    (docPath: string) => {
      dispatch({ type: "IS_PENDING" });
      const docRef = doc(ref, docPath);
      return getDoc(docRef).then((snapshot) => {
        dispatch({ type: "SUCCESS" });
        return _padWithId(snapshot);
      });
    },
    [ref, _padWithId]
  );

  /**
   * @param queries - an array of query objects [{field1: , operator1: , value1: }, {field2: ,...}]
   */
  const getDocumentByQuery = useCallback(
    (queries: QueryCondition[]) => {
      dispatch({ type: "IS_PENDING" });
      let newRef: Query = ref;
      queries.forEach((query) => {
        if (query) {
          newRef = newRef.where(query.field, query.operator, query.value);
        }
      });

      return newRef.get().then((snap) => {
        dispatch({ type: "SUCCESS" });
        if (snap.empty) {
          throw new Error("Document does not exist");
        }
        const data = snap.docs[0].data();
        const uid = snap.docs[0].id;
        return { id: uid, ...data };
      });
    },
    [ref]
  );

  /**
   * Updates a document using a transaction so that previous data in the field will not be lost, applicable to arrays
   * @param id - documentId
   * @param updates - object of fields to be updated
   * @param type - name of field to be updated (should be an array)
   *
   */
  const updateDocumentTransaction = useCallback(
    async (id: string, updates: { [key: string]: any }) => {
      dispatch({ type: "IS_PENDING" });

      try {
        //Get docRef
        const docRef = ref.doc(id);

        projectFirestore.runTransaction(async (transaction) => {
          //Code will get re-run multiple times if there are conflicts
          await transaction.get(docRef).then((doc) => {
            if (!doc.exists) {
              throw new Error("Document does not exist!");
            }

            //Do a manual deep merge with updates and docRef (to not overwrite arrays)
            //1. Loop through the keys of updates
            const storedData = doc.data() as firebase.firestore.DocumentData;
            const keys = Object.keys(updates);
            keys.forEach((key, index) => {
              //1.1 if updates.key is an Array, make new value of updates new Set [...docRef.key, ...updates.key]
              const updateValue = updates[key];
              if (Array.isArray(updateValue) && !!storedData[key]) {
                const docData = doc.data() as firebase.firestore.DocumentData;
                //Merges all unique ids
                updates[key] = Array.from(
                  new Set([...docData[key], ...updateValue])
                );
                // console.log(updates[key]);
              }
            });

            console.info(updates);
            transaction.update(docRef, updates);
            console.info("Successful update transaction");
            dispatchIfNotCancelled({
              type: "UPDATED_DOCUMENT",
              payload: updates,
            });
          });
        });
      } catch (err) {
        let msg = "";
        if (err instanceof Error) msg = err.message;
        dispatchIfNotCancelled({ type: "ERROR", payload: msg });
        console.error("this was called error :", err);
        return null;
      }
    },
    [dispatchIfNotCancelled, ref]
  );

  /**
   * Gets a list of document(s) based on query
   * @param query?:  [string, firebase.firestore.WhereFilterOp, string]
   */
  const getDocumentsByQuery = useCallback(
    (
      query?: [string, WhereFilterOp, string | boolean],
      limit?: number
    ): Promise<DocumentData[]> => {
      dispatchIfNotCancelled({ type: "IS_PENDING" });
      const queriedDocuments = query ? ref.where(...query) : ref;
      if (limit) {
        ref.limit(limit);
      }
      return getDocs(queriedDocuments)
        .then((querySnapShot) => {
          dispatchIfNotCancelled({ type: "SUCCESS" });
          const result = querySnapShot.docs.map((doc) => {
            return { ...doc.data(), id: doc.id };
          });
          //console.log(result)
          return result;
        })
        .catch((err) => {
          dispatchIfNotCancelled({ type: "ERROR", payload: err.message });
          return [];
        });
    },
    [ref, dispatchIfNotCancelled]
  );

  return {
    addDocument,
    getDocumentById,
    getDocumentByQuery,
    getDocumentsByQuery,
    deleteDocument,
    deleteDocumentFieldByQuery,
    updateDocument,
    updateDocumentTransaction,
    setDocument,
    setDocumentTransaction,
    response,
    timestamp,
    isCancelled: true,
  };
};
