import { Modal } from "flowbite-react";
import classes from "pages/AllCircles/Admin/Admin.module.css";
import InputField from "components/utility/Forms/InputField";
import { ChangeEvent, FormEvent, useEffect, useState } from "react";
import { Group } from "interface/GroupInterface";
import toast from "react-hot-toast";
import { Resolver } from "hooks/utility/usePromise";
import { FaTimesCircle } from "react-icons/fa";
import useGroup from "hooks/organisation/useGroup";

interface UpdateGroupModalProps {
  show: boolean;
  setShow: (value: React.SetStateAction<boolean>) => void;
  editingGroup?: Group[];
  isLoading: boolean;
  handler: (
    groupName: string,
    subscriptionType: string,
    dateExpire: string | undefined,
    groupID: string
  ) => Promise<void>;
  groupName: string;
  resolve: Resolver;
  setFocusGroup: React.Dispatch<React.SetStateAction<Group | null>>;
  setGroupName: (value: React.SetStateAction<string>) => void;
}

const UpdateGroupModal = ({
  show,
  setShow,
  editingGroup,
  isLoading,
  handler,
  groupName,
  resolve,
  setFocusGroup,
  setGroupName,
}: UpdateGroupModalProps) => {
  const [updateGroupName, setGroupNameLocal] = useState<string | undefined>("");
  const [subscriptionType, setSubscriptionType] = useState<string | undefined>(
    ""
  );
  const [dateExpire, setDateExpire] = useState<string | undefined>("");
  const [selectedGroup, setSelectedGroup] = useState<Group>();
  const { groups } = useGroup();
  const updateHandler = (e: FormEvent) => {
    e.preventDefault();

    // Check if the new group name already exists, excluding the current group
    const duplicateGroup = groups.find(
      (g) =>
        g.groupName.toLowerCase() === updateGroupName?.toLowerCase() &&
        g.id !== selectedGroup?.id // Exclude the current group
    );

    if (duplicateGroup) {
      toast.error("Group name already exists");
    } else {
      if (editingGroup && updateGroupName && subscriptionType) {
        if (selectedGroup && selectedGroup?.id) {
          resolve(() =>
            handler(
              updateGroupName,
              subscriptionType,
              dateExpire,
              selectedGroup?.id ?? ""
            )
          ).then(() => {
            setTimeout(() => {
              setShow(!show);
              setGroupName(updateGroupName);
            }, 1000);
          });
        } else {
          toast.error("Problem updating data.");
        }
      }
    }
  };

  useEffect(() => {
    if (editingGroup) {
      const selectedGroup = editingGroup.find(
        (group) => group.groupName === groupName
      );

      if (selectedGroup) {
        setSelectedGroup(selectedGroup);
        setGroupNameLocal(selectedGroup?.groupName);
        setSubscriptionType(selectedGroup?.subscriptionType);
        setDateExpire(selectedGroup?.dateExpire);
      }
    }
  }, [editingGroup, groupName]);

  useEffect(() => {
    if (selectedGroup) {
      const _group = groups.filter((g) => g.id === selectedGroup.id);

      if (_group) setFocusGroup(_group[0]);
    }
    // eslint-disable-next-line
  }, [groups, selectedGroup]);

  return (
    <Modal show={show} size={"xl"} popup={true}>
      <Modal.Body className="bg-default py-8 flex flex-col justify-center items-center relative">
        {editingGroup && (
          <div className="text-center">
            {!isLoading && (
              <FaTimesCircle
                className={"absolute top-2 right-2 cursor-pointer"}
                onClick={() => setShow(!show)}
                size={25}
                style={{ color: "var(--icon-colour-0)" }}
              />
            )}
            <form
              onSubmit={updateHandler}
              className="flex flex-col justify-center items-center gap-2"
            >
              <InputField
                type="text"
                isRequired={true}
                onChange={(e: ChangeEvent<HTMLInputElement>) =>
                  setGroupNameLocal(e.target.value)
                }
                htmlFor={"group-name"}
                value={updateGroupName}
                label={"Group name"}
              />

              <InputField
                type="select"
                label={"Group Type"}
                htmlFor={"group type"}
                value={subscriptionType}
                onChange={(e: ChangeEvent<HTMLSelectElement>) =>
                  setSubscriptionType(e.target.value)
                }
                options={["Permanent", "Subscription"]}
              />
              {subscriptionType === "Subscription" && (
                <div className="mt-4 px-2">
                  <label
                    style={{ color: "var(--text-colour)" }}
                    htmlFor="expirationDate"
                  >
                    Expiration date
                  </label>
                  <input
                    required
                    type="datetime-local"
                    className="border border-slate-200"
                    id="expirationDate"
                    value={dateExpire}
                    onChange={(e) => setDateExpire(e.target.value)}
                  />
                </div>
              )}

              <div
                className={`${classes["container"]} flex gap-4 justify-center items-center my-4`}
              >
                <button
                  disabled={isLoading}
                  type="submit"
                  className={`${classes["button"]}`}
                >
                  {isLoading ? "Updating..." : "Update"}
                </button>
              </div>
            </form>
          </div>
        )}
      </Modal.Body>
    </Modal>
  );
};

export default UpdateGroupModal;
