import useUsers from "hooks/useUsers";
import Organisation from "interface/OrganisationInterface";
import { Profile } from "interface/ProfileInterface";
import { useEffect, useState } from "react";
import { Group, SubGroup } from "interface/GroupInterface";
import GroupUser from "./GroupUser";
import useGroup from "hooks/organisation/useGroup";
import { inputClass } from "strings/InputClassStrings";
import usePromise from "hooks/utility/usePromise";
import SimpleLoader from "components/Loaders/SimpleLoader";

interface GroupUserListProps {
  users: string[];
  selectedOrganisation: Organisation;
  group: Group;
  subgroup?: SubGroup;
}
const SubGroupUserList = ({
  users,
  selectedOrganisation,
  group,
  subgroup,
}: GroupUserListProps) => {
  const [orgUsers, setOrgUsers] = useState<Profile[]>([]);

  const { getAllUsersByIds } = useUsers();
  const { subGroups } = useGroup();
  const [leaders, setLeaders] = useState<string[]>([]);
  const [localUsers, setLocalUsers] = useState<Profile[]>([]);
  const [filteredUsers, setFilteredUsers] = useState<Profile[]>([]);
  const [currentPage, setCurrentPage] = useState(1);
  const [itemsPerPage] = useState(10);
  const [searchQuery, setSearchQuery] = useState<string>("");
  const { isLoading, resolve } = usePromise();
  const paginate = (pageNumber: number) => setCurrentPage(pageNumber);
  const totalPages = Math.ceil(filteredUsers.length / itemsPerPage);

  const renderPaginationButtons = () => {
    const paginationItems: JSX.Element[] = [];

    const createPaginationItem = (i: number) => (
      <button
        key={i}
        onClick={() => paginate(i)}
        style={{
          backgroundColor:
            currentPage === i ? "var(--icon-colour-2)" : "#EB8181",
        }}
        className={`my-5 mx-1 px-3 py-1 rounded-md `}
      >
        {i}
      </button>
    );

    if (totalPages <= 7) {
      for (let i = 1; i <= totalPages; i++) {
        paginationItems.push(createPaginationItem(i));
      }
    } else {
      paginationItems.push(createPaginationItem(1));

      if (currentPage > 3) {
        paginationItems.push(
          <span key="ellipsis1" className="my-5 mx-1 px-3 py-1">
            ...
          </span>
        );
      }

      const startPage = Math.max(2, currentPage - 1);
      const endPage = Math.min(totalPages - 1, currentPage + 1);

      for (let i = startPage; i <= endPage; i++) {
        paginationItems.push(createPaginationItem(i));
      }

      if (currentPage < totalPages - 2) {
        paginationItems.push(
          <span key="ellipsis2" className="my-5 mx-1 px-3 py-1">
            ...
          </span>
        );
      }

      paginationItems.push(createPaginationItem(totalPages));
    }

    return (
      <div className="flex justify-center mt-4">
        <button
          onClick={() => paginate(currentPage - 1)}
          className="my-5 mx-1 px-3 py-1 rounded-md bg-gray-200"
          disabled={currentPage === 1}
        >
          &lt;
        </button>
        {paginationItems}
        <button
          onClick={() => paginate(currentPage + 1)}
          className="my-5 mx-1 px-3 py-1 rounded-md bg-gray-200"
          disabled={currentPage === totalPages}
        >
          &gt;
        </button>
      </div>
    );
  };

  const indexOfLastItem = currentPage * itemsPerPage;
  const indexOfFirstItem = indexOfLastItem - itemsPerPage;
  const currentItems = filteredUsers.slice(indexOfFirstItem, indexOfLastItem);

  // Handle search input change
  const handleSearchChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    const query = event.target.value.toLowerCase();
    setSearchQuery(query);
    const filtered = localUsers.filter((user) => {
      const matchesSearchQuery =
        user.displayNameLowerCase.toLowerCase().includes(query) ||
        user.email.toLowerCase().includes(query);

      return matchesSearchQuery;
    });
    setFilteredUsers(filtered);
    setCurrentPage(1); // Reset to first page when search query or filter changes
  };

  const fetchOrgUsers = async () => {
    if (subgroup && subGroups.length > 0) {
      const _subGroup = subGroups.filter(
        (_sG) => _sG.id === subgroup.id && _sG.groupID === group.id
      );
      if (_subGroup.length > 0) {
        const _data = await getAllUsersByIds(_subGroup[0].users);
        if (_data) {
          const leadersList = _subGroup[0].groupLeaders || []; // Fallback to an empty array if undefined
          setLeaders(leadersList);

          // Sort users: leaders first
          const sortedUsers = _data.sort((a, b) => {
            const isALeader =
              _subGroup[0].groupLeaders &&
              _subGroup[0].groupLeaders.includes(a.uid)
                ? 1
                : 0;
            const isBLeader =
              _subGroup[0].groupLeaders &&
              _subGroup[0].groupLeaders.includes(b.uid)
                ? 1
                : 0;
            return isBLeader - isALeader;
          });

          setOrgUsers(sortedUsers);
          setLocalUsers(sortedUsers);
        } else {
          setOrgUsers([]);
          setLocalUsers([]);
        }
      } else {
        setOrgUsers([]);
        setLocalUsers([]);
        setLeaders([]);
      }
    } else {
      setOrgUsers([]);
      setLocalUsers([]);
      setLeaders([]);
    }
  };

  useEffect(() => {
    resolve(() => fetchOrgUsers());
    // eslint-disable-next-line
  }, [users, subgroup, subGroups]);

  useEffect(() => {
    const filtered = localUsers.filter((user) => {
      const matchesSearchQuery =
        user.displayNameLowerCase.toLowerCase().includes(searchQuery) ||
        user.email.toLowerCase().includes(searchQuery);

      return matchesSearchQuery;
    });

    // Sort filtered users: leaders first
    const sortedFilteredUsers = filtered.sort((a, b) => {
      const isALeader = leaders.includes(a.uid) ? 1 : 0;
      const isBLeader = leaders.includes(b.uid) ? 1 : 0;
      return isBLeader - isALeader;
    });

    setFilteredUsers(sortedFilteredUsers);
    setCurrentPage(1); // Reset to first page when filter changes
    // eslint-disable-next-line
  }, [searchQuery, localUsers, leaders, selectedOrganisation]);

  return (
    <>
      {orgUsers && orgUsers.length > 0 && (
        <>
          <p className="mt-2">
            {" "}
            <strong> {filteredUsers.length}</strong> Total Users in this Sub
            Group <strong>{subgroup?.groupName}</strong>
          </p>
          <div className="relative flex items-center justify-end w-1/4 mt-2">
            <input
              type="text"
              placeholder="Search..."
              value={searchQuery}
              onChange={handleSearchChange}
              className={inputClass}
              style={{ minWidth: "200px" }}
            />
          </div>
          <div className="flex gap-2 flex-wrap my-4">
            {currentItems.map((user, index) => (
              <GroupUser
                leaders={leaders}
                user={user}
                key={index}
                selectedOrganisation={selectedOrganisation}
                group={group}
                subgroup={subgroup}
              />
            ))}
          </div>
        </>
      )}
      {filteredUsers.length > 0 ? (
        <>
          {" "}
          <div>{renderPaginationButtons()}</div>
        </>
      ) : (
        <>
          {!isLoading ? (
            <div className="min-h-[10vh] flex justify-center items-center">
              <p>No records available.</p>
            </div>
          ) : (
            <div className="w-full mt-2 min-[30vh] flex justify-center items-center">
              {" "}
              <SimpleLoader />
            </div>
          )}
        </>
      )}
    </>
  );
};

export default SubGroupUserList;
