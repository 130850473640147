import Dice from "assets/Components/dice.png";
import IntroRating from "assets/Components/intro-rating.png";
import AverageRating from "assets/Components/average-rating.png";
import SettingIntension from "assets/Components/setting-intentions.png";
import SettingIntensionRandom from "assets/Components/setting-intentions-random.png";
import ChooseRoles from "assets/Components/choose-roles.png";
import ViewChooseRoles from "assets/Components/view-choose-roles.png";
import ChooseTopic from "assets/Components/choose-topic.png";
import ViewChooseTopic from "assets/Components/view-choose-topic.png";
import ChoosePathway from "assets/Components/choose-pathway.png";
import SharingPanel from "assets/Components/sharing-panel.png";
import EndRating from "assets/Components/end-rating.png";
import IntroEndAverage from "assets/Components/intro-end-average.png";
import FeedbackAfter from "assets/Components/feedback-after.png";
import ChooseActivity from "assets/Components/choose-activity.png";
import ActivityNg from "assets/Components/ng-activity.png";

interface ComponentDetailsProps {
  info: string;
}
const ComponentDetails = ({ info }: ComponentDetailsProps) => {
  return (
    <div className="py-8 text-center flex justify-center">
      {info === "dice" && (
        <div>
          <h3 className="text-xl font-bold">Dice Page</h3>

          <img className="my-4" src={Dice} alt="dice" />
        </div>
      )}
      {info === "intro-rating" && (
        <div>
          <h3 className="text-xl font-bold">Intro Rating Page</h3>

          <img className="my-4" src={IntroRating} alt="intro-rating" />
        </div>
      )}
      {info === "intro-org-rating" && (
        <div>
          <h3 className="text-xl font-bold">Intro Rating Page</h3>

          <img className="my-4" src={IntroRating} alt="intro-rating" />
        </div>
      )}
      {info === "rating-org-average" && (
        <div>
          <h3 className="text-xl font-bold">Average Rating Page</h3>

          <img className="my-4" src={AverageRating} alt="average-rating" />
        </div>
      )}
      {info === "rating-average" && (
        <div>
          <h3 className="text-xl font-bold">Average Rating Page</h3>

          <img className="my-4" src={AverageRating} alt="average-rating" />
        </div>
      )}{" "}
      {info === "setting-intentions" && (
        <div>
          <h3 className="text-xl font-bold">Setting Intentions</h3>

          <img
            className="my-4"
            src={SettingIntension}
            alt="setting-intention"
          />
        </div>
      )}
      {info === "setting-intentions-randomize" && (
        <div>
          <h3 className="text-xl font-bold">
            Setting Intentions Randomize Questions
          </h3>

          <img
            className="my-4"
            src={SettingIntensionRandom}
            alt="setting-intention"
          />
        </div>
      )}
      {info === "choose-roles" && (
        <div>
          <h3 className="text-xl font-bold">Choose Roles</h3>

          <img className="my-4" src={ChooseRoles} alt="setting-intention" />
        </div>
      )}
      {info === "view-choose-roles" && (
        <div>
          <h3 className="text-xl font-bold">View Choose Roles</h3>

          <img className="my-4" src={ViewChooseRoles} alt="setting-intention" />
        </div>
      )}
      {info === "choose-activity" && (
        <div>
          <h3 className="text-xl font-bold">Choose Activity</h3>

          <img className="my-4" src={ChooseActivity} alt="choose-activity" />
        </div>
      )}
      {info === "activity-NG" && (
        <div>
          <h3 className="text-xl font-bold">Activity</h3>

          <img className="my-4" src={ActivityNg} alt="activity-NG" />
        </div>
      )}
      {info === "choose-topic" && (
        <div>
          <h3 className="text-xl font-bold">Choose Topics</h3>

          <img className="my-4" src={ChooseTopic} alt="setting-intention" />
        </div>
      )}
      {info === "view-choose-topic" && (
        <div>
          <h3 className="text-xl font-bold">View Choose Topics</h3>

          <img className="my-4" src={ViewChooseTopic} alt="setting-intention" />
        </div>
      )}
      {info === "choose-pathway" && (
        <div>
          <h3 className="text-xl font-bold">Choose Pathway</h3>

          <img className="my-4" src={ChoosePathway} alt="setting-intention" />
        </div>
      )}
      {info === "sharing-panel" && (
        <div>
          <h3 className="text-xl font-bold">Sharing Panel</h3>

          <img className="my-4" src={SharingPanel} alt="setting-intention" />
        </div>
      )}
      {info === "NG-activity-reflection" && (
        <div>
          <h3 className="text-xl font-bold">Activity Reflection</h3>

          <img
            className="my-4"
            src={SharingPanel}
            alt="NG-activity-reflection"
          />
        </div>
      )}
      {info === "end-rating" && (
        <div>
          <h3 className="text-xl font-bold">End Rating</h3>

          <img className="my-4" src={EndRating} alt="setting-intention" />
        </div>
      )}
      {info === "end-org-rating" && (
        <div>
          <h3 className="text-xl font-bold">End Rating</h3>

          <img className="my-4" src={EndRating} alt="setting-intention" />
        </div>
      )}
      {info === "intro-end-average" && (
        <div>
          <h3 className="text-xl font-bold">CheckIn and CheckOut Average</h3>

          <img className="my-4" src={IntroEndAverage} alt="setting-intention" />
        </div>
      )}
      {info === "intro-end-org-average" && (
        <div>
          <h3 className="text-xl font-bold">CheckIn and CheckOut Average</h3>

          <img className="my-4" src={IntroEndAverage} alt="setting-intention" />
        </div>
      )}
      {info === "feedback-after" && (
        <div>
          <h3 className="text-xl font-bold">Feedback After Session</h3>

          <img className="my-4" src={FeedbackAfter} alt="setting-intention" />
        </div>
      )}
      {info === "feedback-org-after" && (
        <div>
          <h3 className="text-xl font-bold">Feedback After Session</h3>

          <img className="my-4" src={FeedbackAfter} alt="setting-intention" />
        </div>
      )}
    </div>
  );
};

export default ComponentDetails;
