import firebase from "firebase/compat/app";

export interface OrgUser {
    user_id:string;
    org_id:string;
    role:string;
    joinedOn:firebase.firestore.Timestamp;
}

export const defaultOrgUser: Omit<OrgUser, "joinedOn"> = {
    user_id: "",
    org_id: "",
    role: "",
};