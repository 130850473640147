import { useEffect, useState } from "react";
import FiveStepHeader from "../../../components/FiveStepHeader/FiveStepHeader";
import Header from "../../../components/Header/Header";
import EmptySpace from "../../../components/utility/EmptySpace";
import Steps from "../../../components/utility/Steps";
import classes from "./ChoosePathway.module.css";
import Pathways from "../../../components/ChoosePathway/Pathways";
import BackAndNext from "../../../components/utility/BackAndNext";
import useParticipants from "../../../hooks/useParticipants";
import { useNavigate } from "react-router-dom";
import { useAuthContext } from "hooks/useAuthContext";
import { useGCSession } from "hooks/useGCSession";
import { useGrowthCircleContext } from "hooks/useGrowthCircleContext";
import Center from "components/utility/Center/Center";
import NoGCSessionWrapper from "pages/NoGCSession/NoGCSessionWrapper";
import ContentWrapper from "components/utility/ContentWrapper";
import { CHOOSE_PATHWAY_TITLE } from "strings/ChoosePathway";
import { VIEW_CHOOSE_TOPIC_ROUTE, SHARING_PANEL_ROUTE } from "strings/Routes";
import NGFooter from "components/Footers/NGFooter";
import { checkCanAdvanceNextActivity } from "models/organisationRole";
import Instruction from "components/utility/Instruction";
import { updateWindowLocationPath } from "models/profile";

const INSTRUCTIONS_CHOOSE_PATHWAY = 'Which of the following is what you need?'

const ChoosePathway = () => {
  const { profile } = useAuthContext();
  const { growthCircleSession } = useGrowthCircleContext();
  const { sessionRole, oldPath, updateSelectedPath } = useParticipants();

  const canAdvance = checkCanAdvanceNextActivity(sessionRole);
  const stepsCount = 5;
  const currentStep = 4;

  const [currSelected, setCurrSelected] = useState<string>("");
  const [hasTrigger, setHasTrigger] = useState(false);

  const _setTrigger = () => {
    setHasTrigger(!hasTrigger);
  };

  useEffect(() => {
    if (!profile?.uid) {
      return;
    }
    updateWindowLocationPath(profile.uid);
    setCurrSelected(oldPath);
  }, [oldPath, profile?.uid]);

  // navigation
  const navigate = useNavigate();

  const { facilAllowNextPage } = useGCSession();
  const nextHandler = () => {
    updateSelectedPath(currSelected);
    if (canAdvance) {
      facilAllowNextPage("sharing-panel");
    }
    navigate(SHARING_PANEL_ROUTE);
  };

  const backHandler = () => {
    navigate(VIEW_CHOOSE_TOPIC_ROUTE);
  };

  return (
    <NoGCSessionWrapper>
      <div className="relative">
        <Header hasModal={hasTrigger} />
        <FiveStepHeader
          hasLightning={true}
          hasGuide={true}
          currentStep={currentStep}
          hasModal={hasTrigger}
          setIsTriggerVisible={_setTrigger}
        />
        <ContentWrapper hasFiveStepper={true}>
          <Center>
            <EmptySpace height={"1rem"} />
            <Steps count={stepsCount} currentStep={currentStep} />
            <EmptySpace height={"30px"} />
            <strong className={classes["title"]}>{CHOOSE_PATHWAY_TITLE}</strong>
            <Instruction
              text={INSTRUCTIONS_CHOOSE_PATHWAY}
            />
            <EmptySpace height={"30px"} />
            <Pathways
              currSelected={currSelected}
              setCurrSelected={setCurrSelected}
            />
            <EmptySpace height={"30px"} />
            <BackAndNext
              nextAllowed={
                !!currSelected &&
                (canAdvance || growthCircleSession?.allowSharingPanel)
              }
              nextCallback={nextHandler}
              backAllowed={true}
              backCallback={backHandler}
              backHidden={undefined}
            />
            <EmptySpace height={"50px"} />
          </Center>
        </ContentWrapper>
        <EmptySpace height={"200px"} />
        <NGFooter type={growthCircleSession?.type} />
      </div>
    </NoGCSessionWrapper>
  );
};

export default ChoosePathway;
