import { useEffect, useState } from "react";
import GcCard from "../GcCard";
// import classes from "./GrowthCirclesList.module.css";
import { projectFirestore } from "../../../firebase/config";

const GrowthCirclesList = ({ limit, isOngoing }) => {
  const ref = projectFirestore.collection("GrowthCircles");
  const [documents, setDocuments] = useState(Array);

  const queryRef = ref
    .where("isOngoing", "==", isOngoing)
    .orderBy("createdAt", "desc")
    .limit(limit);

  //TODO: improve this line of code to use Hook instead.
  useEffect(() => {
    queryRef
      .get()
      .then(function (doc) {
        if (doc.docs.length > 0) {
          //get document from users
          let documents: Object[] = [];
          doc.docs.map((_gc) => {
            documents.push({ ..._gc.data(), id: _gc.id });
            //TODO: delete all growthCircles
            // const ref = projectFirestore
            //   .collection("GrowthCircles")
            //   .doc(_gc.id);
            // console.log(ref);
            // ref.delete().then(() => {});

            return setDocuments(documents);
          });
        } else {
          try {
            // add display name to user
          } catch (err) {
            console.log("Error getting documents: ", err);
          }
        }
      })
      .catch(function (err) {
        console.log("Error getting documents: ", err);
      });

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [limit, isOngoing]);

  return (
    <div className="grid grid-1 md:grid-cols-4 gap-4 mt-2">
      {!documents && (
        <>
          <p>Loading...</p>
        </>
      )}
      {documents && documents.map((gc: any) => <GcCard key={gc.id} gc={gc} />)}
    </div>
  );
};

export default GrowthCirclesList;
