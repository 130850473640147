interface GradingCardProps {
  title: string;
  selectedItemGrade: Record<string, number>;
}
const GradingCard = ({ title, selectedItemGrade }: GradingCardProps) => {
  return (
    <div
      className="py-4 px-2 text-white min-w-[150px] font-poppins text-center font-semibold rounded-lg"
      style={{ backgroundColor: "#EB8181" }}
    >
      <p>{title}</p>
      <p className="my-2 text-2xl">{selectedItemGrade[title]}</p>
    </div>
  );
};

export default GradingCard;
