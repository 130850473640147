import { IceBreakers, ImageAltProps } from "interface/IceBreakerInterface";
import { projectFirestore, timestamp } from "../firebase/config";
import useOrganisationContext from "./organisation/useOrganisationContext";
import { useEffect, useState } from "react";

export const useIceBreaker = () => {
  const { selectedOrganisation } = useOrganisationContext();
  const [iceBreakers, setIceBreakers] = useState<IceBreakers[]>([]);

  const saveIceBeakerSettings = async (
    row: number,
    cols: number,
    type: string,
    behavior: string
  ) => {
    try {
      const ref = await projectFirestore
        .collection("organisations")
        .doc(selectedOrganisation?.id)
        .collection("IceBreakers")
        .add({
          row,
          cols,
          type,
          behavior,
          createdAt: timestamp.fromDate(new Date()),
        });
      await ref.update({ id: ref.id });
    } catch (error) {
      console.error(error);
    }
  };

  const getIceBreakersByOrgId = async (orgId: string) => {
    try {
      const iceBreakerData: IceBreakers[] = [];
      const snapshot = await projectFirestore
        .collection("organisations")
        .doc(orgId)
        .collection("IceBreakers")
        .get();

      snapshot.forEach((doc) => {
        const data = doc.data() as IceBreakers;
        iceBreakerData.push(data);
      });

      return iceBreakerData;
    } catch (error) {
      console.error(error);
      throw error; // Rethrow the error for handling in higher level
    }
  };

  const updateIceBeakerSettings = async (
    row: number,
    cols: number,
    behavior: string,
    id: string
  ) => {
    try {
      await projectFirestore
        .collection("organisations")
        .doc(selectedOrganisation?.id)
        .collection("IceBreakers")
        .doc(id)
        .update({
          row,
          cols,
          behavior,
        });
    } catch (error) {
      console.error(error);
    }
  };

  const updateIceBreakerImages = async (
    newImages: (string | undefined)[],
    id: string
  ) => {
    try {
      const iceBreakerRef = projectFirestore
        .collection("organisations")
        .doc(selectedOrganisation?.id)
        .collection("IceBreakers")
        .doc(id);

      // Get the existing images
      const doc = await iceBreakerRef.get();
      let existingImages: string[] = [];

      if (doc.exists) {
        const data = doc.data();
        if (data && data.images) {
          existingImages = data.images;
        }
      }

      // Merge new images with existing ones (remove duplicates)
      const mergedImages = Array.from(
        new Set([...existingImages, ...newImages])
      );

      // Update the ice breaker document with the merged images
      await iceBreakerRef.update({
        images: mergedImages,
      });
    } catch (error) {
      console.error(error);
    }
  };

  const removeIceBreakerImage = async (imageToRemove: string, id: string) => {
    try {
      const iceBreakerRef = projectFirestore
        .collection("organisations")
        .doc(selectedOrganisation?.id)
        .collection("IceBreakers")
        .doc(id);

      // Get the existing images
      const doc = await iceBreakerRef.get();
      let existingImages: string[] = [];

      if (doc.exists) {
        const data = doc.data();
        if (data && data.images) {
          existingImages = data.images;
        }
      }

      // Remove the image to remove from the existing images array
      const updatedImages = existingImages.filter(
        (image) => image !== imageToRemove
      );

      // Update the ice breaker document with the updated images array
      await iceBreakerRef.update({
        images: updatedImages,
      });
    } catch (error) {
      console.error(error);
    }
  };

  const updateAltText = async (path: string, altText: string, id) => {
    try {
      const iceBreakerRef = projectFirestore
        .collection("organisations")
        .doc(selectedOrganisation?.id)
        .collection("IceBreakers")
        .doc(id);

      // Get the existing images and altTexts
      const doc = await iceBreakerRef.get();
      let existingImages: ImageAltProps[] = [];

      if (doc.exists) {
        const data = doc.data();
        if (data && data.imageAlts) {
          existingImages = data.imageAlts;
        }
      }

      // Check if the path already exists in the array
      const existingImageIndex = existingImages.findIndex(
        (image) => image.path === path
      );

      if (existingImageIndex !== -1) {
        // If the path exists, update the altText
        existingImages[existingImageIndex].altText = altText;
      } else {
        // If the path does not exist, add the new image
        existingImages.push({ path, altText });
      }

      // Update the ice breaker document with the modified imageAlts
      await iceBreakerRef.update({
        imageAlts: existingImages,
      });
    } catch (error) {
      console.error(error);
    }
  };

  useEffect(() => {
    const unsubscribe = projectFirestore
      .collection("organisations")
      .doc(selectedOrganisation?.id)
      .collection("IceBreakers")
      .onSnapshot((snapshot) => {
        const iceBreakerData: IceBreakers[] = [];
        snapshot.forEach((doc) => {
          const data = doc.data() as IceBreakers;
          iceBreakerData.push(data);
        });
        setIceBreakers(iceBreakerData);
      });

    // Cleanup function
    return () => unsubscribe();
  }, [selectedOrganisation]); // Re-run effect when selectedOrganisation changes

  return {
    iceBreakers,
    saveIceBeakerSettings,
    updateIceBeakerSettings,
    updateIceBreakerImages,
    removeIceBreakerImage,
    updateAltText,
    getIceBreakersByOrgId,
  };
};
