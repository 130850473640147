import { AiFillCloseCircle } from "react-icons/ai";

const HeaderInstructions = (props: { onClick: () => void }) => {
  const { onClick } = props;
  return (
    <div
      className="text-white p-2 text-center"
      style={{
        padding: "10px 20px",
        position: "absolute",
        top: 2,
        right: 0,
        zIndex: 1000,
        cursor: "pointer",
      }}
    >
      <AiFillCloseCircle size={40} onClick={onClick} color={"white"} />
    </div>
  );
};

export default HeaderInstructions;
