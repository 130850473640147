import AutoResizeTextArea from "components/utility/Inputs/AutoResizeTextArea";
import useOrganisation from "hooks/organisation/useOrganisation";
import useOrganisationContext from "hooks/organisation/useOrganisationContext";
import useOrganisationRoleContext from "hooks/organisation/useOrganisationRoleContext";
import { useFirestore } from "hooks/useFirestore";
import usePromise from "hooks/utility/usePromise";
import { VideoInterface } from "interface/VideoInterface";
import { Role } from "models/organisationRole";

import { FormEvent, useEffect, useState } from "react";
import { Toaster, toast } from "react-hot-toast";
import { FaArrowAltCircleLeft } from "react-icons/fa";
import { Link } from "react-router-dom";
import { useNavigate } from "react-router-dom";
import Select from "react-select";
import makeAnimated from "react-select/animated";
import classes from "../../../pages/AllCircles/Admin/Admin.module.css";

const AddNewVideo = () => {
  const { organisations } = useOrganisation();
  const [url, setURL] = useState("");
  const [title, setTitle] = useState("");
  const [description, setDescription] = useState("");
  const [selectedRoles, setSelectedRoles] = useState<string[]>([]);
  const { roles } = useOrganisationRoleContext();
  const { selectedOrganisation, selectOrganisationByName } =
    useOrganisationContext();
  const { addDocument } = useFirestore("Videos");
  const { isLoading, resolve } = usePromise();
  const navigate = useNavigate();
  const reset = () => {
    setURL("");
    setTitle("");
    setDescription("");
  };

  const animatedComponents = makeAnimated();

  const getRoles = (roles: Role[]) => {
    return roles.map((role) => ({ value: role.name, label: role.name }));
  };

  const getFormattedRoles = (roles: Array<Object>) => {
    return roles.map((role) => ({ value: role, label: role }));
  };

  const handleChange = (selectedOptions) => {
    setSelectedRoles(selectedOptions);
  };

  const formattedSelectedRoles = getFormattedRoles(selectedRoles);

  const AddVideoHandler = async (e: FormEvent) => {
    e.preventDefault();
    // Regular expression to extract the video ID from the URL
    const regExp = /youtu\.be\/([\w-]+)/;

    // Executing the regular expression on the URL
    const match = url.match(regExp);

    // Check if a match is found and extract the video ID
    const videoID = match && match[1];
    const selectedRoleValues = selectedRoles.map((role: any) => role.value);
    try {
      const data: VideoInterface = {
        title: title,
        videoURL: url,
        embedId: videoID ? videoID : "",
        description: description,
        organisationID: selectedOrganisation?.id,
        organisationName: selectedOrganisation?.name,
        selectedRoles: selectedRoleValues,
      };
      resolve(() =>
        addDocument(data).then(() => {
          reset();
          navigate("/all-circles/admin/manage-videos");
        })
      );
    } catch (err) {
      toast.error("Error adding new video");
    }
  };

  useEffect(() => {
    if (!selectedOrganisation) return;
    selectOrganisationByName(selectedOrganisation.name);
  }, [selectedOrganisation, selectOrganisationByName]);

  return (
    <div className="w-full md:w-1/2">
      <Toaster toastOptions={{ duration: 5000 }} />
      <form
        style={{ color: "var(--text-colour)" }}
        onSubmit={AddVideoHandler}
        className="py-4"
      >
        <div>
          <p className="font-semibold">Title</p>
          <input
            type="text"
            value={title}
            required
            placeholder="Specify title.."
            className="w-full !text-lg focus:border-gray-400 !px-8 !py-6 rounded-full placeholder:text-gray-300"
            onChange={(e) => setTitle(e.target.value)}
          />
        </div>
        <div className="py-4">
          <p className="font-semibold">Video URL </p>
          <input
            className="w-full !text-lg focus:border-gray-400 !px-8 !py-6 rounded-full placeholder:text-gray-300"
            placeholder="https://youtu.be/exampleCode"
            type="text"
            value={url}
            required
            onChange={(e) => setURL(e.target.value)}
          />{" "}
          <small>
            (Please note that the video URL should be the embed link obtained
            from YouTube's share options.)
          </small>
        </div>
        <div>
          <p className="font-semibold">Description</p>
          <div className="p-2 bg-white border border-gray-300  rounded-lg w-full mt-2">
            <AutoResizeTextArea
              defaultValue={description}
              placeholder="Description"
              className="border-none w-full placeholder:text-gray-300"
              onChange={(value) => setDescription(value)}
            />
          </div>
        </div>
        <div className="flex flex-col">
          <label className="text-3xl font-semibold">Organisation</label>
          <select
            required
            className="border border-gray-300"
            defaultValue={selectedOrganisation?.name}
            onChange={(e) => selectOrganisationByName(e.target.value)}
          >
            {organisations &&
              organisations.map((org) => (
                <option key={org.id}>{org.name}</option>
              ))}
          </select>
        </div>

        <div className="flex flex-col">
          <label className="text-3xl font-semibold">Role Visibility</label>
          <Select
            components={animatedComponents}
            closeMenuOnSelect={false}
            isMulti
            options={getRoles(roles)}
            defaultValue={formattedSelectedRoles}
            onChange={handleChange}
          />
        </div>

        <div className="flex gap-4 py-4 items-center justify-start">
          <Link
            to={`/all-circles/admin/manage-videos`}
            className={`${classes["button"]} `}
          >
            <FaArrowAltCircleLeft size={28} />
          </Link>
          <button
            disabled={isLoading}
            type="submit"
            className={`${classes["button"]}`}
          >
            {isLoading ? "Submitting..." : "Submit"}
          </button>
        </div>
      </form>
    </div>
  );
};

export default AddNewVideo;
