import MainSessionTemplate from "components/utility/SessionTemplate/MainSessionTemplate";
import { useEffect, useState } from "react";
import classes from "./EndRating.module.css";
import SliderContainer from "components/Slider/SliderContainer";
import { useDragRating, DRAG_RATING_KEYS } from "hooks/useDragRating";
import { useAuthContext } from "hooks/useAuthContext";
import { LocalStorageKeys } from "App";
import { useComponentDidUpdate } from "hooks/useComponentDidUpdate";
import BackAndNext from "components/utility/BackAndNext";
import { useGrowthCircleContext } from "hooks/useGrowthCircleContext";
import { useNavigate } from "react-router-dom";
import { END_RATING_AVERAGE_ROUTE, SHARING_PANEL_ROUTE } from "strings/Routes";
import { useGCSession } from "hooks/useGCSession";
import { useFirestore } from "hooks/useFirestore";
import useParticipants from "hooks/useParticipants";
import { checkCanAdvanceNextActivity } from "models/organisationRole";

/**
 * Rating with four drag sliders after user is finished with GC Session
 */
const EndRating = () => {
  const { profile } = useAuthContext();
  const { growthCircleSession } = useGrowthCircleContext();
  const { sessionRole } = useParticipants();
  const { updateDocument } = useFirestore("users");
  const {
    dragValues: gcDragValues,
    setDragValues: setGcDragValues,
    anyUntouched,
    dragError: gcDragError,
    setDragError: setGcDragError,
    updatesFeedbackFormWithDragRating,
  } = useDragRating(LocalStorageKeys.REFLECTION.DRAG_RATINGS); //For section 1 of Feedback
  const { facilAllowNextPage } = useGCSession();
  let navigate = useNavigate();

  const changeGcValuesHandler = (e) => {
    setGcDragValues(e);
  };

  const canAdvance = checkCanAdvanceNextActivity(sessionRole);

  //TODO : End Rating Bug
  const nextHandler = () => {
    if (anyUntouched()) {
      setGcDragError(true);
    } else {
      //Submits the end-rating
      if (canAdvance) {
        facilAllowNextPage("end-rating-average");
      }
      updatesFeedbackFormWithDragRating();
      navigate(END_RATING_AVERAGE_ROUTE);
    }
  };

  const [individually, setIndividually] = useState(
    gcDragValues[DRAG_RATING_KEYS.INDIVIDUALLY.KEY].value
  );
  const [interpersonally, setInterpersonally] = useState(
    gcDragValues[DRAG_RATING_KEYS.INTERPERSONALLY.KEY].value
  );
  const [socially, setSocially] = useState(
    gcDragValues[DRAG_RATING_KEYS.SOCIALLY.KEY].value
  );
  const [overall, setOverall] = useState(
    gcDragValues[DRAG_RATING_KEYS.OVERALL.KEY].value
  );

  useComponentDidUpdate(() => {
    gcDragValues[DRAG_RATING_KEYS.INDIVIDUALLY.KEY].isTouched = true;
  }, [individually]);

  useComponentDidUpdate(() => {
    gcDragValues[DRAG_RATING_KEYS.INTERPERSONALLY.KEY].isTouched = true;
  }, [interpersonally]);

  useComponentDidUpdate(() => {
    gcDragValues[DRAG_RATING_KEYS.SOCIALLY.KEY].isTouched = true;
  }, [socially]);

  useComponentDidUpdate(() => {
    gcDragValues[DRAG_RATING_KEYS.OVERALL.KEY].isTouched = true;
  }, [overall]);

  useEffect(() => {
    if (!profile) return;
    const data = { pageStep: window.location.pathname };
    updateDocument(profile.uid, data);

    const newDragValues = { ...gcDragValues };
    newDragValues[DRAG_RATING_KEYS.INDIVIDUALLY.KEY].value = individually;
    newDragValues[DRAG_RATING_KEYS.INTERPERSONALLY.KEY].value = interpersonally;
    newDragValues[DRAG_RATING_KEYS.SOCIALLY.KEY].value = socially;
    newDragValues[DRAG_RATING_KEYS.OVERALL.KEY].value = overall;
    changeGcValuesHandler(newDragValues);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [individually, interpersonally, socially, overall]);

  const backHandler = () => {
    navigate(SHARING_PANEL_ROUTE);
  };

  return (
    <MainSessionTemplate currentStep={5}>
      <div className={classes["reflection-drag-container"]}>
        <span className={classes["description"]}>
          How do you feel now about the following areas?
        </span>
        <SliderContainer
          name="Individually"
          description="Personal well-being"
          values={individually}
          setValues={setIndividually}
          icon={false}
          showError={
            !gcDragValues[DRAG_RATING_KEYS.INDIVIDUALLY.KEY].isTouched &&
            gcDragError
          }
        />
        <SliderContainer
          name="Interpersonally"
          description="Family, Close relationships"
          values={interpersonally}
          setValues={setInterpersonally}
          icon={false}
          showError={
            !gcDragValues[DRAG_RATING_KEYS.INTERPERSONALLY.KEY].isTouched &&
            gcDragError
          }
        />
        <SliderContainer
          name="Socially"
          description="Work, school, friendships"
          values={socially}
          setValues={setSocially}
          icon={false}
          showError={
            !gcDragValues[DRAG_RATING_KEYS.SOCIALLY.KEY].isTouched &&
            gcDragError
          }
        />
        <SliderContainer
          name="Overall"
          description="General sense of well-being"
          values={overall}
          setValues={setOverall}
          icon={false}
          showError={
            !gcDragValues[DRAG_RATING_KEYS.OVERALL.KEY].isTouched && gcDragError
          }
        />
      </div>
      <div className={classes["bottom-nav"]}>
        <BackAndNext
          backAllowed={true}
          nextAllowed={
            canAdvance || growthCircleSession?.allowEndRatingAverage
          }
          nextCallback={nextHandler}
          backCallback={backHandler}
        />
      </div>
    </MainSessionTemplate>
  );
};

export default EndRating;
