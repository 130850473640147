import { FC } from "react";
import { useAuthContext } from "hooks/useAuthContext";
import ProfilePicture from "components/ProfilePicture/ProfilePicture";
import classes from "./ProfileSettings.module.css";
import { Profile } from "interface/ProfileInterface";

/**
 * Counts the number of circles the user has joined.
 *
 * @param profile Profile of the user.
 * @returns Number of circles joined.
 */
function getCirclesJoined(profile: Profile | null) {
  let count = 0;
  if (profile) {
    const progress = profile.progress;
    for (let info of Object.values(progress)) {
      count += info.numOfTimesPlayed;
    }
  }
  return count;
}

const ProfileHeader: FC<{}> = () => {
  const { profile } = useAuthContext();

  return (
    <div className={classes["header-container"]}>
      <div className={classes["profile-main-container"]}>
        <div className={classes["profile-name-image"]}>
          <ProfilePicture
            profile={profile}
          />
          <div>
            <h2 className="text-md font-semibold">{profile?.displayName} </h2>
            <div className={"text-sm " + classes["circles-joined"]}>
              {"Circles joined: " + getCirclesJoined(profile)}
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}

export default ProfileHeader;