import React from "react";
import SingleDice from "./SingleDice";
import classes from "./ParticipantDiceSelection.module.css";
import EmptySpace from "components/utility/EmptySpace";
import { Participant } from "interface/ParticipantInterface";
import { PARTICIPANT_DICE_PICKS_TITLE } from "strings/Dice";

interface Props {
  participants: Participant[];
  dices: number[];
}

const ParticipantDiceSelection: React.FC<Props> = ({ participants, dices }) => {
  if (!participants || !dices) return null;
  return (
    <>
      <strong className={classes["title"]}>
        {PARTICIPANT_DICE_PICKS_TITLE}
      </strong>
      <EmptySpace height={"30px"} />
      <div className={classes["participant-choices-container"]}>
        {participants.map((participant: Participant, index: number) => {
          const participantDiceIndex = participant["diceIndex"];
          // eslint-disable-next-line array-callback-return
          if (participantDiceIndex === -1) return;
          return (
            <div
              className={classes["participant-choice"]}
              key={participant.userId}
            >
              <SingleDice
                clickable={false}
                diceId={dices[participantDiceIndex]}
              />
              <p className="text-center w-24 overflow-hidden text-ellipsis">
                {participant.userName.split(" ")[0]}
              </p>
            </div>
          );
        })}
      </div>
    </>
  );
};

export default ParticipantDiceSelection;
