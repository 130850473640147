import { useTour } from "@reactour/tour";
import SimpleLoader from "components/Loaders/SimpleLoader";
import AddQuestionToDomainModal from "components/Organisation/QuestionSettings/AddQuestionToDomainModal";
import CopyQuestionModal from "components/Organisation/QuestionSettings/CopyQuestionModal";
import DomainQuestionsModal from "components/Organisation/QuestionSettings/DomainQuestionsModal";
import EmptySpace from "components/utility/EmptySpace";
import InputField from "components/utility/Forms/InputField";
import AutoResizeTextArea from "components/utility/Inputs/AutoResizeTextArea";
import { DocumentData } from "firebase/firestore";
import useOrganisationContext from "hooks/organisation/useOrganisationContext";
import usePublicQuestion from "hooks/question/usePublicQuestion";
import useSuperAdminQuestion from "hooks/question/useSuperAdminQuestion";
import { useAuthContext } from "hooks/useAuthContext";
import { useOrganisationQuestion } from "hooks/useOrganisationQuestion";
import usePromise from "hooks/utility/usePromise";
import OrganisationTopics from "interface/OrganisationTopicsInterface";
import { QuestionInterface } from "interface/QuestionsInterface";
import classes from "pages/AllCircles/Admin/Admin.module.css";
import {
  ChangeEvent,
  FormEvent,
  Fragment,
  useCallback,
  useEffect,
  useState,
} from "react";
import { toast } from "react-hot-toast";
import { capitalizeFirstLetter } from "utility/stringHandling";
import {
  randomReflectionQuestionsTourSteps,
  specificReflectionQuestionsTourSteps,
  standardReflectionQuestionsTourSteps,
} from "utility/tours/organisationSettings/reflectionQuestionsTourSteps";
import QuestionCard from "./QuestionCard";

interface QuestionSettingsProps {
  topics: DocumentData[];
  questions: Record<string, QuestionInterface[]> | undefined;
  categoryQuestions: Record<string, QuestionInterface[]> | undefined;
}

// Defines the type of reflection questions the organisation uses
export enum QuestionType {
  STANDARD = "", // currently unused
  SPECIFIC = "specific", // uses custom categories and topics
  RANDOM = "random", // uses the default categories
}

// for random question type
export const DEFAULT_CATEGORIES = ["personal", "general", "compulsory"];

/**
 * Reflection questions settings component
 *
 * @param props - the component props
 * @param props.topics - the topics available for the organisation
 * @param props.questions - the questions available for the organisation
 * @param props.categoryQuestions - the category questions available for the organisation
 * @returns the QuestionSettings component
 */
const QuestionSettings = ({
  topics,
  questions,
  categoryQuestions,
}: QuestionSettingsProps) => {
  const {
    setIsOpen: setIsTourOpen,
    setSteps,
    setCurrentStep,
    currentStep,
  } = useTour();
  const { selectedOrganisation, updateSelectedOrganisation } =
    useOrganisationContext();
  const { AddNewQuestion, updateOrganisationQuestionSettings } =
    useOrganisationQuestion();
  const { publicQuestions, addPublicQuestions, deletePublicQuestions } =
    usePublicQuestion();
  const {
    superAdminQuestions,
    addSuperAdminQuestions,
    deleteSuperAdminQuestions,
  } = useSuperAdminQuestion();
  const [topic, setTopic] = useState<string | null>("");
  const [options, setOptions] = useState<string[]>([]);
  const [question, setQuestion] = useState<string>("");
  const [topicCategoryID, setTopicCategoryID] = useState("");
  const { isLoading, resolve } = usePromise();
  const { isLoading: isUpdating, resolve: update } = usePromise();
  const [selectedValue, setSelectedValue] = useState(
    selectedOrganisation?.questionsType ?? QuestionType.SPECIFIC
  );
  const [category, setCategory] = useState(DEFAULT_CATEGORIES[0]);
  const { profile } = useAuthContext();
  const [checkboxState, setCheckboxState] = useState({
    noPersonalQuestion: false,
    noFollowUpQuestion: false,
  });
  const [updating, setIsUpdating] = useState(false);
  const [isCategoryQuestion, setIsCategoryQuestion] = useState(false);

  const categoryOptions = topics
    .map((category) => {
      return category.category;
    })
    .sort((a, b) => a.localeCompare(b));

  const isSpecific = selectedValue === QuestionType.SPECIFIC;
  const totalTopics = topics
    ? topics.map((topic) => topic.general).flat().length
    : 0;
  const totalQuestions =
    questions &&
    categoryQuestions &&
    (isSpecific
      ? Object.values(questions).flat().length +
        Object.values(categoryQuestions).flat().length
      : Object.values(questions).reduce(
          (total, questionsArray) => total + questionsArray.length,
          0
        ));

  const optionsNotEmpty =
    (selectedValue === QuestionType.SPECIFIC &&
      (isCategoryQuestion ? categoryOptions.length > 0 : options.length > 0)) ||
    selectedValue === QuestionType.RANDOM;

  // All topic and category questions (just the question string)
  const allQuestions = Object.values(questions ? questions : {})
    .flat()
    .concat(Object.values(categoryQuestions ? categoryQuestions : {}).flat());

  // Count the number of questions per category in an object
  const questionsPerCategoryId =
    questions && categoryQuestions
      ? Object.entries(categoryQuestions).reduce(
          (acc, pair) => {
            const [categoryId, questions] = pair;
            if (acc[categoryId]) {
              acc[categoryId] += questions.length;
            } else {
              acc[categoryId] = questions.length;
            }
            return acc;
          },
          Object.values(questions)
            .flatMap((q) => q)
            .reduce((acc, question) => {
              if (acc[question.topicCategoryId]) {
                acc[question.topicCategoryId] += 1;
              } else {
                acc[question.topicCategoryId] = 1;
              }

              return acc;
            }, {} as Record<string, number>)
        )
      : {};

  const totalCategories =
    selectedValue === QuestionType.SPECIFIC
      ? topics
        ? topics.length
        : 0
      : DEFAULT_CATEGORIES.length;

  const setTopicHandler = useCallback(
    (topicName: string) => {
      if (!selectedOrganisation) {
        return;
      }

      if (selectedValue === QuestionType.RANDOM) {
        setTopicCategoryID("");
        return;
      }

      // Find the topic that contains the desired topicName
      const selectedTopic = topics.find((topic) =>
        topic.general.includes(topicName)
      );

      // Check if the topic was found
      if (selectedTopic) {
        // Now you can use 'selectedTopic' and 'topicName' as needed
        setTopicCategoryID(selectedTopic.id);
        setCategory(selectedTopic.category);
        setTopic(topicName);
      }
    },
    [selectedOrganisation, selectedValue, topics]
  );

  const getTopicsOptions = useCallback(() => {
    let combined: string[] = [];
    topics.map((category) => {
      const _cat = category as OrganisationTopics;
      return _cat.general.map((d) => {
        return combined.push(d);
      });
    });

    // Sort the combined array alphabetically
    combined.sort((a, b) => a.localeCompare(b));

    setOptions(combined);
    setTopicHandler(combined[0]);
  }, [setTopicHandler, topics]);

  const AddNewQuestionHandler = useCallback(
    async (e: FormEvent) => {
      e.preventDefault();
      if (!selectedOrganisation) {
        return;
      }

      const _topic =
        selectedOrganisation?.questionsType === QuestionType.RANDOM
          ? category
          : isCategoryQuestion
          ? null
          : topic;

      resolve(() => AddNewQuestion(question, _topic, topicCategoryID, category))
        .then(() => {
          setQuestion("");
          toast.success("New question was added successfully");
        })
        .catch((error) => {
          toast.error(error);
        });
    },
    [
      isCategoryQuestion,
      AddNewQuestion,
      category,
      resolve,
      question,
      topic,
      topicCategoryID,
      selectedOrganisation,
    ]
  );

  const handleRadioChange = useCallback(
    (event) => {
      setSelectedValue(event.target.value);
      setIsCategoryQuestion(false);

      if (event.target.value === QuestionType.RANDOM) {
        setCategory(DEFAULT_CATEGORIES[0]);
        setTopic(DEFAULT_CATEGORIES[0]);
      }

      update(() =>
        updateSelectedOrganisation({ questionsType: event.target.value })
      ).then(() => {
        toast.success("Question type updated");
      });
    },
    [update, updateSelectedOrganisation]
  );

  const handleCheckboxChange = useCallback(
    async (checkboxName: string) => {
      setIsUpdating(true);
      const updatedCheckboxState = {
        ...checkboxState,
        [checkboxName]: !checkboxState[checkboxName],
      };

      setCheckboxState(updatedCheckboxState);
      updateOrganisationQuestionSettings(updatedCheckboxState).then(() => {
        updateSelectedOrganisation({ name: selectedOrganisation?.name }).then(
          () => {
            setIsUpdating(false);
            toast.success("Settings was updated successfully.");
          }
        );
      });
    },
    [
      checkboxState,
      updateOrganisationQuestionSettings,
      updateSelectedOrganisation,
      selectedOrganisation,
    ]
  );

  const handleToggleCategoryQuestion = useCallback(() => {
    setIsCategoryQuestion((prev) => {
      if (prev) {
        getTopicsOptions();
      } else {
        setCategory(categoryOptions[0]);
        setTopicCategoryID(
          topics.find((topic) => topic.category === categoryOptions[0])?.id ||
            ""
        );
        setTopic(null);
      }
      return !prev;
    });
  }, [categoryOptions, getTopicsOptions, topics]);

  useEffect(() => {
    if (!selectedOrganisation) return;
    getTopicsOptions();
  }, [getTopicsOptions, selectedOrganisation]);

  useEffect(() => {
    if (!selectedOrganisation || !setSteps) {
      return;
    }

    if (selectedValue === QuestionType.STANDARD) {
      setCurrentStep(0);
      setSteps(standardReflectionQuestionsTourSteps);
    } else if (selectedValue === QuestionType.SPECIFIC) {
      setCurrentStep(0);
      setSteps(specificReflectionQuestionsTourSteps);
    } else {
      setCurrentStep(0);
      setSteps(randomReflectionQuestionsTourSteps);
    }
  }, [selectedOrganisation, selectedValue, setSteps, setCurrentStep]);

  // Get reactour to scroll properly.
  useEffect(() => {
    const scrollOptions = {
      top: currentStep === 0 ? window.scrollY : 85 + currentStep / 100,
      behavior: "smooth" as ScrollBehavior,
    };
    window.scrollTo(scrollOptions);
  }, [currentStep]);

  return (
    <>
      <div className="mb-[100px]">
        {selectedOrganisation && (
          <div style={{ color: "var(--text-colour)" }}>
            <EmptySpace />
            <button
              className={`${classes["button"]}`}
              onClick={() => setIsTourOpen(true)}
            >
              Show Tutorial
            </button>
            <EmptySpace />
            <div className="flex items-center gap-4">
              <p className="text-xl my-4 ">
                Questions for <strong>{selectedOrganisation.name}</strong>
              </p>
            </div>
            <p className=" mb-8 justify-start items-center gap-2  text-lg">
              Choose whether the question type should be tailored to specific
              topics or a mixture of personal and general questions across all
              topics. From the dropdown menu, pick a topic and input your
              questions into the textarea. Once you're done, simply click the
              "ADD QUESTION" button. Your questions will be sorted by their
              corresponding topics. If a topic isn't listed, please make sure to
              add it in the "Topics" tab before adding a question.{" "}
              {`If your question sentence contains a fill-in-the-blank, you can insert <topic> within the sentence, and it will automatically be substituted with the chosen topic.`}{" "}
              {profile?.access && profile.access === "admin" && (
                <span>
                  {" "}
                  The copy button is to copy the topics or questions from
                  another organisation or growthcircle type
                </span>
              )}
            </p>
            {profile?.access && profile.access === "admin" && (
              <CopyQuestionModal topics={topics as OrganisationTopics[]} />
            )}

            <hr className="my-4" />
            <div reflection-questions-settings-tour="question-type">
              <p className="my-2 font-semibold">Question Type</p>
              {!isUpdating ? (
                <div className="flex flex-col">
                  <label className="cursor-pointer">
                    <input
                      type="radio"
                      value=""
                      checked={selectedOrganisation.questionsType === ""}
                      onChange={handleRadioChange}
                      className="mx-4"
                      style={{
                        height: "20px",
                        width: "20px",
                        backgroundColor:
                          selectedOrganisation.questionsType === ""
                            ? "var(--icon-colour-0)"
                            : "",
                      }}
                    />
                    Use the standard Questions (Growthbeans default questions ){" "}
                    <span className="text-red-500">
                      Note : Ensure that pathways is set on Organisation Pages
                    </span>
                  </label>
                  <label className="cursor-pointer">
                    <input
                      type="radio"
                      value={QuestionType.SPECIFIC}
                      checked={
                        selectedOrganisation.questionsType ===
                        QuestionType.SPECIFIC
                      }
                      onChange={handleRadioChange}
                      className="mx-4"
                      style={{
                        height: "20px",
                        width: "20px",
                        backgroundColor:
                          selectedOrganisation.questionsType ===
                          QuestionType.SPECIFIC
                            ? "var(--icon-colour-0)"
                            : "",
                      }}
                    />
                    Specific Questions per Topic (One topic multiple questions
                    randomized )
                  </label>

                  <label className="cursor-pointer">
                    <input
                      type="radio"
                      value={QuestionType.RANDOM}
                      checked={
                        selectedOrganisation.questionsType ===
                        QuestionType.RANDOM
                      }
                      onChange={handleRadioChange}
                      className="mx-4"
                      style={{
                        height: "20px",
                        width: "20px",
                        backgroundColor:
                          selectedOrganisation.questionsType ===
                          QuestionType.RANDOM
                            ? "var(--icon-colour-0)"
                            : "",
                      }}
                    />
                    Randomize Personal and General questions for any topic
                  </label>
                </div>
              ) : (
                <SimpleLoader />
              )}
            </div>
            <EmptySpace />

            {!updating ? (
              <div
                reflection-questions-settings-tour="advance-options"
                className="flex flex-col"
              >
                <hr />
                <EmptySpace />{" "}
                <p className="my-2 font-semibold">Advance Options</p>
                {selectedOrganisation.questionsType ===
                  QuestionType.SPECIFIC && (
                  <label className="cursor-pointer">
                    <input
                      type="checkbox"
                      checked={selectedOrganisation.noPersonalQuestion ?? false}
                      onChange={() =>
                        handleCheckboxChange("noPersonalQuestion")
                      }
                      className="mx-4"
                      style={{
                        height: "20px",
                        width: "20px",
                        borderRadius: "5px",
                        backgroundColor: selectedOrganisation.noPersonalQuestion
                          ? "var(--icon-colour-0)"
                          : "",
                      }}
                    />
                    No personal question ( No random personal question will
                    appear after specific questions )
                  </label>
                )}
                <label className="cursor-pointer">
                  <input
                    type="checkbox"
                    checked={selectedOrganisation.noFollowUpQuestion ?? false}
                    onChange={() => handleCheckboxChange("noFollowUpQuestion")}
                    className="mx-4"
                    style={{
                      height: "20px",
                      width: "20px",
                      borderRadius: "5px",
                      backgroundColor: selectedOrganisation.noFollowUpQuestion
                        ? "var(--icon-colour-0)"
                        : "",
                    }}
                  />
                  No follow-up question ( No follow-up questions will appear )
                </label>
                <EmptySpace />
              </div>
            ) : (
              <>
                {" "}
                <SimpleLoader /> <EmptySpace />
              </>
            )}

            {selectedOrganisation.questionsType !== QuestionType.STANDARD && (
              <>
                <hr />
                <p className="my-4 text-lg font-semibold">Add questions</p>
              </>
            )}
            <form
              reflection-questions-settings-tour="add-question"
              onSubmit={AddNewQuestionHandler}
            >
              {selectedOrganisation.questionsType === QuestionType.SPECIFIC && (
                <div className="pb-2">
                  <label
                    reflection-questions-settings-tour="category-question-checkbox"
                    className="cursor-pointer ml-2"
                  >
                    <input
                      type="checkbox"
                      checked={isCategoryQuestion}
                      onChange={handleToggleCategoryQuestion}
                      className="mr-4"
                      style={{
                        height: "20px",
                        width: "20px",
                        borderRadius: "5px",
                        backgroundColor: isCategoryQuestion
                          ? "var(--icon-colour-0)"
                          : "",
                      }}
                    />
                    Add as Category Question
                  </label>
                  {isCategoryQuestion ? (
                    <InputField
                      required={true}
                      label="Choose Category"
                      name={"selected category"}
                      type="select"
                      htmlFor={"category"}
                      onChange={(e: ChangeEvent<HTMLSelectElement>) => {
                        const selectedCategory = topics.find(
                          (category) => category.category === e.target.value
                        );
                        setCategory(e.target.value);
                        setTopicCategoryID(selectedCategory?.id);
                      }}
                      options={categoryOptions}
                      value={category}
                      disabled={isLoading}
                    />
                  ) : (
                    <InputField
                      required={true}
                      label="Choose Topic"
                      name={"selected topics"}
                      type="select"
                      htmlFor={"topics"}
                      onChange={(e: ChangeEvent<HTMLSelectElement>) =>
                        setTopicHandler(e.target.value)
                      }
                      options={options}
                      value={topic}
                      disabled={isLoading}
                    />
                  )}
                </div>
              )}

              {selectedOrganisation.questionsType === QuestionType.RANDOM && (
                <div className="mb-4">
                  <InputField
                    required={true}
                    label="Question Category"
                    name={"selected category"}
                    type="select"
                    htmlFor={"category"}
                    onChange={(e: ChangeEvent<HTMLSelectElement>) => {
                      setCategory(e.target.value);
                      setTopic(e.target.value);
                    }}
                    options={DEFAULT_CATEGORIES}
                    value={category}
                    disabled={isLoading}
                  />
                  {category === "compulsory" && (
                    <p className="pt-4 pl-4 text-sky-600">
                      Compulsory questions are not randomized and will appear
                      underneath personal and general questions.
                    </p>
                  )}
                </div>
              )}

              {selectedOrganisation.questionsType !== QuestionType.STANDARD && (
                <>
                  <div className="py-4 bg-white shadow-md w-1/2 p-2 rounded-md mt-5">
                    <AutoResizeTextArea
                      isRequired={true}
                      placeholder="Specify question here"
                      className="border-none w-full text-slate-500 placeholder:text-gray-300"
                      value={question}
                      onChange={(value) => setQuestion(value)}
                    />
                  </div>{" "}
                  {optionsNotEmpty ? (
                    <button disabled={isLoading} type="submit" className="my-4">
                      {isLoading ? "Adding Question..." : "Add Question"}
                    </button>
                  ) : (
                    <p className="text-red-600 mt-4">
                      You must have at least 1{" "}
                      {isCategoryQuestion ? "category" : "topic"} to add a
                      question to
                    </p>
                  )}
                </>
              )}
            </form>
            {selectedOrganisation.questionsType !== QuestionType.STANDARD && (
              <EmptySpace />
            )}
            <hr />
            {selectedOrganisation.questionsType &&
              selectedOrganisation.questionsType !== QuestionType.STANDARD && (
                <div reflection-questions-settings-tour="domain-questions">
                  <p className="mt-4 text-lg font-semibold">Domain questions</p>
                  <p className="my-2">
                    You can add or copy questions from the different domains
                    that are available.
                  </p>
                  <div className="flex gap-2">
                    <DomainQuestionsModal
                      questions={allQuestions}
                      publicQuestions={publicQuestions}
                      superAdminQuestions={superAdminQuestions}
                      topics={topics}
                      questionType={
                        selectedOrganisation.questionsType as QuestionType
                      }
                      deletePublicQuestions={deletePublicQuestions}
                      deleteSuperAdminQuestions={deleteSuperAdminQuestions}
                    />
                    <AddQuestionToDomainModal
                      questions={allQuestions}
                      publicQuestions={publicQuestions}
                      superAdminQuestions={superAdminQuestions}
                      addPublicQuestions={addPublicQuestions}
                      addSuperAdminQuestions={addSuperAdminQuestions}
                    />
                  </div>
                  <EmptySpace />
                  <hr />
                </div>
              )}
            {questions && (
              <p className="my-4" style={{ color: "var(--text-colour)" }}>
                Total of <span className="font-bold">{totalCategories}</span>{" "}
                categories
                {isSpecific && ","}{" "}
                {isSpecific && <span className="font-bold">{totalTopics}</span>}{" "}
                {isSpecific && "topics"} and a total of{" "}
                <span className="font-bold">{totalQuestions} </span> questions.
                (Categories and topics with no questions are not displayed)
              </p>
            )}
            <div className="flex flex-wrap gap-4 mb-[100px] ">
              {questions && categoryQuestions ? (
                isSpecific ? (
                  topics
                    .sort((a, b) => a.category.localeCompare(b.category))
                    .map(
                      (category) =>
                        questionsPerCategoryId[category.id] && (
                          <Fragment key={category.id}>
                            <div className="w-full">
                              <p className="text-lg font-semibold w-full">
                                Category:{" "}
                                {capitalizeFirstLetter(category.category)}
                              </p>
                              {categoryQuestions[category.id] && (
                                <div key="categoryQuestion" className="w-full">
                                  <p className="py-4">
                                    Total of{" "}
                                    {categoryQuestions[
                                      category.id
                                    ].length.toLocaleString()}{" "}
                                    category questions
                                  </p>
                                  <ul className="bg-white shadow-md ">
                                    {categoryQuestions[category.id].map(
                                      (question) => (
                                        <li key={question.id}>
                                          <QuestionCard question={question} />
                                        </li>
                                      )
                                    )}
                                  </ul>
                                </div>
                              )}
                              {category.general
                                .sort((a, b) => a.localeCompare(b))
                                .map(
                                  (topic) =>
                                    questions[topic] && (
                                      <div key={topic} className="w-full">
                                        <p className="py-4">
                                          Total of{" "}
                                          {questions[
                                            topic
                                          ].length.toLocaleString() ?? "0"}{" "}
                                          questions from{" "}
                                          <strong>{topic}</strong>
                                        </p>
                                        <ul className="bg-white shadow-md ">
                                          {questions[topic]?.map((question) => (
                                            <li key={question.id}>
                                              <QuestionCard
                                                question={question}
                                              />
                                            </li>
                                          ))}
                                        </ul>
                                      </div>
                                    )
                                )}
                            </div>
                          </Fragment>
                        )
                    )
                ) : (
                  Object.entries(questions).map(([topic, questionsArray]) => (
                    <div key={topic} className="w-full">
                      <p className="text-lg font-semibold w-full">
                        {capitalizeFirstLetter(topic)}
                      </p>
                      <p className="py-4">
                        Total of {questionsArray.length} questions
                      </p>
                      <ul className="bg-white shadow-md ">
                        {questionsArray.map((question) => (
                          <li key={question.id}>
                            <QuestionCard question={question} />
                          </li>
                        ))}
                      </ul>
                    </div>
                  ))
                )
              ) : (
                <p>No questions available.</p>
              )}
            </div>
          </div>
        )}
      </div>
    </>
  );
};

export default QuestionSettings;
