import { useCallback, useEffect, useState } from "react";
import useOrganisationContext from "./useOrganisationContext";

import { projectFirestore, timestamp } from "../../firebase/config";

import { DocumentData } from "firebase/firestore";

export const useOrgORS = () => {
  const { selectedOrganisation } = useOrganisationContext();
  const [ors, setORS] = useState<DocumentData[]>([]);

  const AddNewORS = useCallback(
    async (title: string, sub_title: string) => {
      try {
        const trimmedORS = title.trim();

        const data = {
          createdAt: timestamp.fromDate(new Date()),
          title: trimmedORS,
          sub_title,
          max: 10,
          min: 0,
          order: (ors?.length ?? 0) + 1,
        };
        const ref = await projectFirestore
          .collection("organisations")
          .doc(selectedOrganisation?.id)
          .collection("ORS")
          .add(data);
        await ref.update({ id: ref.id });
      } catch (error) {
        console.error(error);
      }
    },
    // eslint-disable-next-line
    [selectedOrganisation]
  );

  const UpdateORS = useCallback(
    async (id: string, title: string, sub_title: string) => {
      try {
        const ref = projectFirestore
          .collection("organisations")
          .doc(selectedOrganisation?.id)
          .collection("ORS")
          .doc(id);

        // Check if the document already exists
        const docSnapshot = await ref.get();

        const updatedData = {
          title,
          sub_title,
        };

        if (docSnapshot.exists) {
          // Document exists, update it with the new data
          await ref.update(updatedData);
        } else {
          console.error(`Document with ID ${id} does not exist.`);
        }
      } catch (error) {
        console.error(error);
      }
    },
    // eslint-disable-next-line
    [selectedOrganisation]
  );

  const saveOrder = useCallback(
    async (selectedIds: string[]) => {
      try {
        const batch = projectFirestore.batch();

        selectedIds.forEach((sectionId, index) => {
          const sectionRef = projectFirestore
            .collection("organisations")
            .doc(selectedOrganisation?.id)
            .collection("ORS")
            .doc(sectionId);

          batch.update(sectionRef, { order: index + 1 });
        });

        await batch.commit();
      } catch (error) {
        console.error(error);
      }
    },
    // eslint-disable-next-line
    [selectedOrganisation]
  );

  const DeleteORS = useCallback(
    async (Id: string) => {
      try {
        await projectFirestore
          .collection("organisations")
          .doc(selectedOrganisation?.id)
          .collection("ORS")
          .doc(Id)
          .delete();
      } catch (error) {
        console.error(error);
      }
    },
    // eslint-disable-next-line
    [selectedOrganisation]
  );

  const ORSMemo = () => {
    const unsubscribe = projectFirestore
      .collection("organisations")
      .doc(selectedOrganisation?.id)
      .collection("ORS")
      .orderBy("order", "asc")
      .onSnapshot((snapshot) => {
        const updatedORS: DocumentData[] = [];
        snapshot.forEach((doc) => {
          updatedORS.push({
            id: doc.id,
            ...doc.data(),
          });
        });
        setORS(updatedORS);
      });

    return () => {
      unsubscribe();
    };
  };

  const getORSByOrgID = async (
    organisationID: string
  ): Promise<DocumentData[]> => {
    return new Promise((resolve, reject) => {
      try {
        const unsubscribe = projectFirestore
          .collection("organisations")
          .doc(organisationID)
          .collection("ORS")
          .orderBy("order", "asc")
          .onSnapshot(
            (snapshot) => {
              const updatedORS: DocumentData[] = [];

              snapshot.forEach((doc) => {
                updatedORS.push({
                  id: doc.id,
                  ...doc.data(),
                });
              });

              resolve(updatedORS.length > 0 ? updatedORS : []);
              unsubscribe(); // Unsubscribe after the first snapshot to avoid memory leaks
            },
            (error) => {
              reject(error);
            }
          );
      } catch (error) {
        reject(error);
      }
    });
  };

  const updateORSTitle = async (customORSTitle: string) => {
    try {
      await projectFirestore
        .collection("organisations")
        .doc(selectedOrganisation?.id)
        .update({ customORSTitle });
    } catch (error) {
      console.error(error);
    }
  };

  useEffect(() => {
    return ORSMemo();
    // eslint-disable-next-line
  }, [selectedOrganisation]);

  return {
    ors,
    AddNewORS,
    DeleteORS,
    UpdateORS,
    saveOrder,
    updateORSTitle,
    getORSByOrgID,
  };
};
