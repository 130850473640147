import Header from "components/Header/Header";
import PageWrapper from "components/utility/PageWrapper";

import { useEffect, useState } from "react";
import JoinGrowthCirclesLists from "./JoinGrowthCirclesLists";
import { getOrgsWithPermission } from "models/organisation";
import { P_CAN_OBSERVE } from "models/permission";
import { useAuthContext } from "hooks/useAuthContext";
import { Select } from "flowbite-react";
import { Link } from "react-router-dom";
import EmptySpace from "components/utility/EmptySpace";
import Organisation from "interface/OrganisationInterface";

const JoinSessions = () => {
  const { profile } = useAuthContext();
  const [selectedOrganisation, setSelectedOrganisation] =
    useState<Organisation>();
  const [organisations, setOrganisations] = useState<Organisation[]>([]);

  useEffect(() => {
    if (!profile) {
      return;
    }
    setHasModal(false);
    getOrgsWithPermission(profile, P_CAN_OBSERVE).then(async (orgs) => {
      setOrganisations(orgs);
      const sortedOrg = orgs.sort((a, b) => a.name.localeCompare(b.name));
      setSelectedOrganisation(sortedOrg[0]);
    });
  }, [profile]);

  const [hasModal, setHasModal] = useState(false);

  return (
    <PageWrapper pageType={"groundRules"}>
      <Header hasModal={hasModal} />

      <div className="w-full mt-20 py-5 mb-[200px]">
        <EmptySpace height="50px" />
        <div className="w-full mt-5 flex justify-around items-end">
          <Link
            to={"/"}
            className="text-white px-8 py-2 rounded-lg shadow-md"
            style={{ backgroundColor: `var(--main-colour)` }}
          >
            Main Page
          </Link>
          <div>
            <label className="text-standard">
              Select Organisation Sessions
            </label>

            <Select
              onChange={(e) => {
                const selectedOrg = organisations.find(
                  (org) => org.name === e.target.value
                );
                setSelectedOrganisation(selectedOrg);
              }}
            >
              {organisations
                .sort((a, b) => a.name.localeCompare(b.name))
                .map((org) => (
                  <option key={org.id} value={org.name}>
                    {org.name}
                  </option>
                ))}
            </Select>
          </div>
        </div>
        {!selectedOrganisation && (
          <div className="mx-auto max-w-7xl h-[100px] flex justify-center items-center">
            Loading...
          </div>
        )}
        <div className="mx-auto max-w-7xl">
          {selectedOrganisation && (
            <h1 className="text-3xl font-semibold py-5 text-standard">
              Join the <u>{selectedOrganisation.name}</u> Session
            </h1>
          )}

          {selectedOrganisation && (
            <JoinGrowthCirclesLists
              setHasModal={setHasModal}
              organisation={selectedOrganisation}
            />
          )}
        </div>
      </div>
    </PageWrapper>
  );
};

export default JoinSessions;
