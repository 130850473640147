import FirstNameAvatar from "components/utility/FirstNameAvatar";
import { projectFirestore } from "../../../firebase/config";
import useGetParticipants from "hooks/useGetParticipants";
import { GrowthCircleSession } from "interface/GrowthCircleSessionInterface";
import { useEffect, useMemo, useRef, useState } from "react";
import { Participant } from "interface/ParticipantInterface";
import useGroup from "hooks/organisation/useGroup";
import Organisation from "interface/OrganisationInterface";
import { Group, SubGroup } from "interface/GroupInterface";
import usePromise from "hooks/utility/usePromise";
import SimpleLoader from "components/Loaders/SimpleLoader";

interface GrowthCircle {
  gc: GrowthCircleSession;
  join: Function;
  organisation: Organisation;
}

const SessionCards = ({ gc, join, organisation }: GrowthCircle) => {
  const [isVisible, setIsVisible] = useState(false);

  const { getParticipantColourIndex } = useGetParticipants();
  const { getUsersGroups, getUsersSubGroups } = useGroup();
  const [participants, setParticipants] = useState<Array<Participant>>([]);
  const [groups, setGroups] = useState<Group[]>([]);
  const [subGroups, setSubGroups] = useState<SubGroup[]>([]);
  const { isLoading, resolve } = usePromise();
  const lastFetchedUid = useRef<string | null>(null);

  const getGroup = async () => {
    try {
      if (organisation) {
        const _group = await resolve(() =>
          getUsersGroups(organisation.id, gc.facilOwner)
        );
        if (_group) {
          setGroups(_group);
        } else {
          setGroups([]);
        }
      } else {
        setGroups([]);
      }
    } catch (error) {
      console.error(error);
      setGroups([]);
    }
  };

  const getSubGroup = async () => {
    try {
      if (organisation) {
        const _group = await getUsersSubGroups(organisation.id, gc.facilOwner);
        if (_group) {
          setSubGroups(_group);
        } else {
          setSubGroups([]);
        }
      } else {
        setSubGroups([]);
      }
    } catch (error) {
      console.error(error);
      setSubGroups([]);
    }
  };

  const fetchParticipants = useMemo(
    () => async () => {
      try {
        if (!gc.uid || lastFetchedUid.current === gc.uid) {
          return;
        }

        lastFetchedUid.current = gc.uid;

        const querySnapshot = await projectFirestore
          .collection("GrowthCircles")
          .doc(gc.uid)
          .collection("Participants")
          .get();

        const documents = querySnapshot.docs.map(
          (doc) => doc.data() as Participant
        );

        if (documents.length === 0) {
          setParticipants([]);
        } else {
          setParticipants(documents);
        }
      } catch (error) {
        console.error("Error fetching participants:", error);
        // Handle the error appropriately
      }
    },
    [gc]
  );

  useEffect(() => {
    Promise.all([fetchParticipants(), getGroup(), getSubGroup()]);

    // eslint-disable-next-line
  }, [gc]); // Only run the effect when `gc` or `searching` changes

  const modalHandler = () => {
    setIsVisible(!isVisible);
    join(!isVisible);
  };

  return (
    <>
      <div className="max-w-7xl relative  bg-white rounded-lg h-auto  min-h-[400px] drop-shadow-md">
        <div
          className="min-h-[50px] flex justify-center items-center rounded-tr-lg rounded-tl-lg"
          style={{ backgroundColor: "var(--main-colour)" }}
        >
          {" "}
          <h5 className="mb-2 text-lg font-bold tracking-tight text-default text-center">
            {gc.name}
          </h5>
        </div>

        <div className="px-5 mt-4 mb-[50px]">
          {groups.length > 0 && (
            <p className="text-center text-slate-500">
              <strong>Group: </strong>
              {groups.map((g, index) => (
                <span key={index}>
                  {g.groupName}
                  {index < groups.length - 1 ? ", " : ""}
                </span>
              ))}
            </p>
          )}

          {subGroups.length > 0 && (
            <p className="text-center text-slate-500">
              <strong>Sub-Group: </strong>
              {subGroups.map((g, index) => (
                <span key={index}>
                  {g.groupName}
                  {index < subGroups.length - 1 ? ", " : ""}
                </span>
              ))}
            </p>
          )}
          {participants.length === 0 && (
            <p className="text-center py-4 font-semibold text-slate-400">
              No Participants.
            </p>
          )}

          {!isLoading ? (
            <div className="flex flex-col gap-1 mt-5 items-start justify-center">
              {participants && participants.length > 0 && (
                <>
                  <h3 className="font-semibold text-slate-700 mb-4">
                    Participants
                  </h3>
                  {participants.map((doc, index) => (
                    <div
                      key={index}
                      className="flex justify-center items-center gap-2"
                    >
                      <FirstNameAvatar
                        name={doc.userName}
                        participant={doc}
                        styleTriggered={true}
                        participantNumber={getParticipantColourIndex(
                          doc.userId
                        )}
                      />
                      <p className="text-slate-600 capitalize">
                        {doc.userName}
                      </p>
                    </div>
                  ))}
                </>
              )}
            </div>
          ) : (
            <SimpleLoader />
          )}
        </div>

        <a
          onClick={modalHandler}
          target="_new"
          href={`${window.location.origin}/all-circles/join-session-observer/${gc.uid}`}
          className="inline-flex absolute bottom-2 right-2 items-center px-3 py-2 text-sm font-medium text-center text-white bg-main-colour rounded-lg shadow-md  focus:ring-4 focus:outline-none focus:ring-green-300"
          style={{ backgroundColor: "var(--main-colour)", color: "#fff" }}
        >
          <span>Join as Observer</span>
        </a>
      </div>
    </>
  );
};

export default SessionCards;
