import classes from "./Admin.module.css";

import SidebarAdmin from "components/Admin/SidebarAdmin";
import NavAdmin from "components/Admin/NavAdmin";
import { lazy, Suspense } from "react";
const AdminAllPathways = lazy(
  () => import("components/Admin/AdminPathways/AdminAllPathways")
);
const AdminPathways = () => {
  return (
    <div>
      <NavAdmin />
      <div className={classes.container}>
        <SidebarAdmin />
        <div className={classes.main}>
          <div className={classes.title}>
            <h4>Pathways</h4>
          </div>
          <Suspense fallback={<div>Loading...</div>}>
            <AdminAllPathways />
          </Suspense>
        </div>
      </div>
    </div>
  );
};

export default AdminPathways;
