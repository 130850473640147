import LarefSessionTemplate from "components/utility/SessionTemplate/LarefSessionTemplate";
import { useEffect, useState } from "react";
import KeepInTouch from "components/EndOfSession/KeepInTouch";
import Completed from "components/EndOfSession/Completed";
import FeedbackWaitingArea from "components/EndOfSession/FeedbackWaitingArea/MainWaitingArea";
import { useAuthContext } from "hooks/useAuthContext";
import { useCollection } from "hooks/useCollection";
import useUsers from "hooks/useUsers";
import { useNavigate } from "react-router-dom";
import ConfirmModal from "components/utility/ConfirmModal";
import NoGCSessionWrapper from "pages/NoGCSession/NoGCSessionWrapper";

// import useReflectionRatings from "hooks/useReflectionRating.ts";

const EndOfSession = () => {
  //Firebase/"Backend" hooks
  const { profile } = useAuthContext();
  const sessionId = profile!.growthCircle;
  // const { growthCircleSession } = useGrowthCircleContext();
  const COLLECTIONS = {
    RETRIEVING_COL: "GrowthCircles/" + sessionId + "/Participants",
    UPDATING_COL: "users",
  };
  const { documents: participants } = useCollection(COLLECTIONS.RETRIEVING_COL);
  // const { updateDocumentTransaction } = useFirestore(COLLECTIONS.UPDATING_COL);
  // const { endGCSession } = useGCSession();
  const { endGCSessionForUser } = useUsers();
  // const { handleSubmitFeedbackForm, clearReflectionFields } =
  //   useReflectionRatings();

  //States
  const [journalSubmitted, setJournalSubmitted] = useState(false);
  const confirmHomeModalId = "confirm-home";
  const [showHomeModal, setShowHomeModal] = useState(false);
  const [requestsSent, setRequestsSent] = useState<string[]>([]); //Array to track friend requests sent
  const [showContent, setShowContent] = useState(false); //Condition to show content after documents are loaded
  const [contentType, setContentType] = useState("WaitingArea"); //Render content based on state it is in

  const navigate = useNavigate();

  /**
   * Stores the ids of participants that the user wishes to add
   * @param userId - the uuid of a user
   */
  const handleSendRequest = (userId: string) => {
    if (requestsSent.includes(userId)) {
      //Remove
      const newRequestSent = [
        ...requestsSent.filter((requestUserId) => requestUserId !== userId),
      ];
      setRequestsSent(newRequestSent);
    } else {
      setRequestsSent([...requestsSent, userId]);
    }
  };

  /**
   * Re-render sub components based on state of contentType
   */
  const handleNext = () => {
    switch (contentType) {
      // case "KeepInTouch":
      //   //Call method to store requestsSent to user
      //   storeFriendRequests();
      //   setContentType("Completed");
      //   break;
      case "WaitingArea":
        setContentType("Completed");
        break;
      case "Completed":
        //Set growthCircle to null
        if (!journalSubmitted) {
          setShowHomeModal(true);
        } else {
          handleConfirmHome();
        }
        break;
    }
  };

  const handleConfirmHome = () => {
    endGCSessionForUser().then((res) => navigate("/"));
  };

  //useEffects
  useEffect(() => {
    if (participants) {
      setShowContent(true);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [participants, profile]);

  //To track requests sent
  useEffect(() => {}, [requestsSent]);

  //Render bubble content based on state of contentType
  const getBubbleContent = () => {
    switch (contentType) {
      case "WaitingArea":
        return <FeedbackWaitingArea parentNextHandler={handleNext} />;
      case "KeepInTouch":
        return (
          <KeepInTouch
            participants={participants.filter(
              (participant) => participant.userId !== profile!.id
            )}
            // participants={dummyparticipants}
            requestsSent={requestsSent}
            handleSendRequest={handleSendRequest}
          />
        );
      case "Completed":
        return (
          <Completed
            setJournalSubmitted={() => setJournalSubmitted(true)}
            participant={
              participants.filter(
                (participant) => participant.userId === profile!.id
              )[0]
            }
          />
        );
    }
  };

  return (
    <NoGCSessionWrapper>
      <LarefSessionTemplate
        stepperContent={"Completed"}
        hasMascot={false}
      >
        {showContent && getBubbleContent()}
        {showHomeModal && (
          <ConfirmModal
            hasMessage={true}
            hasTitle={true}
            hasBackDrop={true}
            onClick={() => setShowHomeModal(false)}
            onAccept={() => handleConfirmHome()}
            modalId={confirmHomeModalId}
            message={"Are you sure? Your journal will not be saved."}
          />
        )}
        <div id={confirmHomeModalId} />
      </LarefSessionTemplate>
    </NoGCSessionWrapper>
  );
};

export default EndOfSession;
