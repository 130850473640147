import { useEffect, useState } from "react";
import GrowthCirclesTypeAdminCard from "./GrowthCirclesTypeAdminCard";
import CreatableSelect from "react-select/creatable";
import makeAnimated from "react-select/animated";
import { useGrowthCirclesType } from "hooks/useGrowthCirlesType";
import Organisation from "interface/OrganisationInterface";

const GrowthCirclesTypeList = () => {
  const animatedComponents = makeAnimated();
  const [tags, setTags] = useState(null);
  const { growthcircletypes } = useGrowthCirclesType();

  const [data, setData] = useState<Organisation[]>([]);

  const tagData = [
    { value: "Wellbeing", label: "Wellbeing" },
    { value: "Career & Purpose", label: "Career & Purpose" },
    { value: "People & Teams", label: "People & Teams" },
    { value: "Personal Development", label: "Personal Development" },
  ];

  const handleTagChange = (selectedOptions) => {
    setTags(selectedOptions);

    if (!selectedOptions || selectedOptions.length === 0) {
      // If no tags are selected, show all data
      setData(growthcircletypes);
    } else {
      // Filter data based on selected tags
      const filteredData = growthcircletypes.filter((circle) =>
        selectedOptions.some(
          (tag) =>
            circle.tags?.includes(tag.value) ||
            (circle.name &&
              circle.name.toLowerCase().includes(tag.value.toLowerCase()))
        )
      );

      setData(filteredData);
    }
  };

  const StoredData = () => {
    setData(growthcircletypes);
  };

  useEffect(() => {
    if (!growthcircletypes) return;
    StoredData();
    // eslint-disable-next-line
  }, [growthcircletypes]);

  return (
    <div className="mb-[100px]">
      <div className="flex flex-col w-full md:w-1/2 xl:w-2/4 mb-5">
        <CreatableSelect
          placeholder="Tag and name filter"
          isMulti
          components={animatedComponents}
          defaultValue={tags}
          options={tagData}
          onChange={handleTagChange}
        />
      </div>
      <div className="grid grid-cols-1 md:grid-cols-2 xl:grid-cols-4 gap-2">
        {data.map((circle, index) => (
          <GrowthCirclesTypeAdminCard key={index} data={circle} />
        ))}
        {data.length === 0 && (
          <p style={{ color: "var(--text-colour)" }}>No Data found.</p>
        )}
      </div>
    </div>
  );
};

export default GrowthCirclesTypeList;
