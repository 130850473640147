import { useState, useEffect } from "react";

const FooterInstructions = () => {
  const [isVisible, setIsVisible] = useState(true);

  useEffect(() => {
    const timeout = setTimeout(() => {
      setIsVisible(false);
    }, 5000); // 5000 milliseconds (5 seconds)

    return () => {
      clearTimeout(timeout);
    };
  }, []);

  return isVisible ? (
    <div
      className="text-white p-2 text-center"
      style={{
        backgroundColor: "rgba(0, 0, 0, 0.5)",
        padding: "10px 20px",
        position: "absolute",
        bottom: "20%",
        left: 0,
        right: 0,
        zIndex: 1000,
      }}
    >
      <p>
        Swipe to switch between images, double-tap to enable zoom functionality,
        tap anywhere outside the image or press the close button to exit the
        lightbox.
      </p>
    </div>
  ) : null;
};

export default FooterInstructions;
