import React from "react";

import classes from "./OtherRoles.module.css";

import RolesIconMap from "assets/Roles/RolesIconMap";
import Box from "components/utility/Box/Box";
import EmptySpace from "components/utility/EmptySpace";

import useGetParticipants from "hooks/useGetParticipants";
import { useAuthContext } from "hooks/useAuthContext";

interface IconProps {
  coreSkill: string;
}

const Icon: React.FC<IconProps> = ({ coreSkill }) => {
  const CoreSkillIcon = RolesIconMap[coreSkill];
  return (
    <Box height={"75px"} width={"75px"} border={"2px solid var(--main-colour)"}>
      <CoreSkillIcon fill={"var(--main-colour)"} />
    </Box>
  );
};

interface OtherRoleProps {
  userName: string;
  role: string;
  coreSkill: string;
}

const OtherRole: React.FC<OtherRoleProps> = ({ userName, role, coreSkill }) => {
  if (!role || !coreSkill) return null;
  return (
    <div className={classes["other-role-container"]}>
      <Icon coreSkill={coreSkill} />
      <EmptySpace width={"20px"} />
      <div>
        <p className="w-28 font-semibold overflow-hidden text-ellipsis">
          {userName}
        </p>
        <p className={`${classes["other-role"]} `}>{role}</p>
      </div>
    </div>
  );
};

const OtherRoles = () => {
  const { participants } = useGetParticipants();
  const { profile } = useAuthContext();

  if (!participants || !profile) return null;
  return (
    <div className={classes["other-roles-container"]}>
      {participants.map((p) => {
        const participantId = p.userId;
        if (participantId === profile.uid || !p?.role) {
          return null;
        }
        const userName = p.userName;
        const role = p.role.role;
        const coreSkill = p.role.core_skills;
        return (
          <div key={participantId}>
            <OtherRole userName={userName} role={role} coreSkill={coreSkill} />
            <EmptySpace />
          </div>
        );
      })}
    </div>
  );
};

export default OtherRoles;
