import { useNavigate } from "react-router-dom";
import Button from "components/utility/Button";
import classes from "./ResetEmail.module.css";

const ResetEmail = (props) => {
  const navigate = useNavigate();

  // const resendButtonStyle = {
  //   fontSize: "0.8em",
  //   fontWeight: "500",
  //   width: "91px",
  //   height: "27px",
  //   backgroundColor: "#EB8181",
  //   color: "#FDF5E6",
  // };

  const returnButtonStyle = {
    fontSize: "1em",
    fontWeight: "500",
    width: "191px",
    height: "45px",
    backgroundColor: "#EB8181",
    color: "#FDF5E6",
  };

  // const alertText = "Invalid username. Please enter correct username.";

  const submitHandler = (e) => {
    e.preventDefault();
  };

  const clickHandler = (e) => {
    navigate("/login");
  };

  return (
    <>
      <form onSubmit={submitHandler} className={classes.form}>
        <div className={classes.inputs}>
          <span>A reset link has been sent to the following email:</span>
          {"\n"}
          <span className={classes.email}>{props.email}</span>
          {/* <Button text="Re-send" style={resendButtonStyle} type="submit" /> */}
        </div>
        <span />
        <div className={classes.button}>
          <Button
            text="Return to Login"
            style={returnButtonStyle}
            onClick={clickHandler}
          />
        </div>
      </form>
      <span />
    </>
  );
};

export default ResetEmail;
