import NavAdmin from "components/Admin/NavAdmin";
import SidebarAdmin from "components/Admin/SidebarAdmin";
import classes from "./Admin.module.css";
import { useParams } from "react-router-dom";
import EditVideo from "components/Admin/AdminVideo/EditVideo";
import { useDocument } from "hooks/useDocument";
import { useEffect } from "react";

const AdminEditVideo = () => {
  const { id } = useParams();
  const { document } = useDocument("Videos", id);

  useEffect(() => {
    if (!document) return;
  }, [id, document]);

  return (
    <div>
      <NavAdmin />
      <div className={`${classes.container} min-h-screen`}>
        <SidebarAdmin />
        <div className={classes.main}>
          <div className={classes.title}>
            <h4>Edit Video</h4>
          </div>
          <div>{document && <EditVideo video={document} />}</div>
        </div>
      </div>
    </div>
  );
};

export default AdminEditVideo;
