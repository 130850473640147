import { useNavigate } from "react-router-dom";

const BackButton = (props) => {
  const navigate = useNavigate();

  const backButtonStyle = {
    display: "flex",
    justifyContent: "flex-start",
    marginLeft: "15px",
    marginBottom: "15px",
    cursor: "pointer",
  };

  return (
    <svg
      width="24"
      height="24"
      viewBox="0 0 36 36"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      onClick={() => navigate(-1)}
      style={backButtonStyle}
    >
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M18 2.25C13.8228 2.25 9.81677 3.90937 6.86307 6.86307C3.90937 9.81677 2.25 13.8228 2.25 18C2.25 22.1772 3.90937 26.1832 6.86307 29.1369C9.81677 32.0906 13.8228 33.75 18 33.75C22.1772 33.75 26.1832 32.0906 29.1369 29.1369C32.0906 26.1832 33.75 22.1772 33.75 18C33.75 13.8228 32.0906 9.81677 29.1369 6.86307C26.1832 3.90937 22.1772 2.25 18 2.25V2.25ZM18 36C13.2261 36 8.64773 34.1036 5.27208 30.7279C1.89642 27.3523 0 22.7739 0 18C0 13.2261 1.89642 8.64773 5.27208 5.27208C8.64773 1.89642 13.2261 0 18 0C22.7739 0 27.3523 1.89642 30.7279 5.27208C34.1036 8.64773 36 13.2261 36 18C36 22.7739 34.1036 27.3523 30.7279 30.7279C27.3523 34.1036 22.7739 36 18 36V36ZM25.875 19.125C26.1734 19.125 26.4595 19.0065 26.6705 18.7955C26.8815 18.5845 27 18.2984 27 18C27 17.7016 26.8815 17.4155 26.6705 17.2045C26.4595 16.9935 26.1734 16.875 25.875 16.875H12.8407L17.6715 12.0465C17.8827 11.8353 18.0014 11.5487 18.0014 11.25C18.0014 10.9513 17.8827 10.6647 17.6715 10.4535C17.4603 10.2423 17.1737 10.1236 16.875 10.1236C16.5763 10.1236 16.2897 10.2423 16.0785 10.4535L9.3285 17.2035C9.22373 17.308 9.14061 17.4321 9.0839 17.5688C9.02718 17.7055 8.99799 17.852 8.99799 18C8.99799 18.148 9.02718 18.2945 9.0839 18.4312C9.14061 18.5679 9.22373 18.692 9.3285 18.7965L16.0785 25.5465C16.2897 25.7577 16.5763 25.8764 16.875 25.8764C17.1737 25.8764 17.4603 25.7577 17.6715 25.5465C17.8827 25.3353 18.0014 25.0487 18.0014 24.75C18.0014 24.4513 17.8827 24.1647 17.6715 23.9535L12.8407 19.125H25.875Z"
        fill="#EB8181"
      />
    </svg>
  );
};

export default BackButton;
