import { Link } from "react-router-dom";

import Button from "components/utility/Button";
import InputField from "components/utility/Forms/InputField";
import WelcomeMessage from "components/Login/WelcomeMessage";
import PageWrapper from "components/utility/PageWrapper";
import ContentWrapper from "components/utility/ContentWrapper";
import BackButton from "assets/BackButton";

import classes from "./Login.module.css";

//import hook
import { useLogin } from "hooks/useLogin";
import { useState } from "react";

const Login = () => {
  //declare states
  const [email, setEmail] = useState("");
  const [password, setPassword] = useState("");
  //deconstruct functions
  const { isPending, error, login } = useLogin();

  const buttonStyle = {
    fontSize: "1em",
    fontWeight: "500",
  };

  const submitHandler = (e) => {
    e.preventDefault();
    //console.log(email, password);
    login(email, password);
  };

  return (
    <PageWrapper>
      <ContentWrapper>
        <div className={classes.header}>
          <BackButton />
          <WelcomeMessage />
        </div>
        <form onSubmit={submitHandler} className={classes.form}>
          <div className={classes.inputs}>
            <InputField
              text="Email"
              type={"email"}
              onChange={(e) => setEmail(e.target.value)}
              value={email}
            />
            <InputField
              text="Password"
              type={"password"}
              password={true}
              Invalid={error}
              onChange={(e) => setPassword(e.target.value)}
              value={password}
            />
          </div>
          <span />
          <div className={classes.button}>
            <Button
              text={isPending ? "Loading..." : "Log In"}
              style={buttonStyle}
              type="submit"
              isDisabled={isPending}
            />
          </div>
        </form>
        <Link to="/forget-password" className={classes.link}>
          Forget password?
        </Link>
      </ContentWrapper>
    </PageWrapper>
  );
};

export default Login;
