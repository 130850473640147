import SimpleLoader from "components/Loaders/SimpleLoader";
import { Modal } from "flowbite-react";
import { useSelfAwareness } from "hooks/useSelfAwareness";
import usePromise from "hooks/utility/usePromise";
import classes from "pages/AllCircles/Admin/Admin.module.css";
import { AiFillCloseCircle } from "react-icons/ai";
import { BackAndNextProps } from "../SelfAwarenessComponentMapping";
import { useSelfAwarenessContext } from "hooks/useSelfAwarenessContext";
import { useEffect } from "react";

const ConfirmModal = ({
  backAndNextHandler,
  setHasModal,
  handler,
  showConfirm,
}: {
  backAndNextHandler: BackAndNextProps;
  setHasModal: (b: boolean) => any;
  handler: () => void;
  showConfirm: boolean;
}) => {
  const { isLoading, resolve } = usePromise();
  const { saveJournalEntry } = useSelfAwareness();
  const { journal } = useSelfAwarenessContext();

  const confirmNext = (e: React.MouseEvent<HTMLButtonElement, MouseEvent>) => {
    if (journal) {
      resolve(() => saveJournalEntry(journal.pageType, journal.title)).then(
        () => {
          setHasModal(false);
          backAndNextHandler.nextCallback(e);
        }
      );
    }
  };

  useEffect(() => {
    if (!journal) return;
    // eslint-disable-next-line
  }, [journal]);

  return (
    <Modal show={showConfirm} popup={true} size={"sm"}>
      <div
        className={`${classes["container"]} p-5 relative flex flex-col`}
        style={{ color: "var(--text-colour)" }}
      >
        {!isLoading ? (
          <>
            <AiFillCloseCircle
              style={{ color: "var(--icon-colour-0)" }}
              size={30}
              className="absolute top-2 right-2 cursor-pointer"
              onClick={handler}
            />
            <p className="my-8 text-center">
              Have you completed your journal? Pressing the "OK" button will
              submit and save the journal.
            </p>
            <div className="flex  gap-4 justify-center items-center">
              <button
                onClick={confirmNext}
                className={`${classes["button"]}  `}
              >
                Ok
              </button>
              <button onClick={handler} className={`${classes["button"]} `}>
                Cancel
              </button>
            </div>
          </>
        ) : (
          <div className="min-h-[100px] flex justify-center items-center">
            <SimpleLoader />
          </div>
        )}
      </div>
    </Modal>
  );
};

export default ConfirmModal;
