const TickedCheckbox = (props) => {
  const style = {};

  return (
    <svg
      width="27"
      height="23"
      viewBox="0 0 23 23"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      onClick={props.onClick}
      style={style}
    >
      <g filter="url(#filter0_d_3342_9778)">
        <rect
          x="5"
          y="3"
          width="13"
          height="13"
          stroke="#828080"
          strokeWidth="2"
          shapeRendering="crispEdges"
        />
      </g>
      <path
        d="M7 9.73333L10 13L16 6"
        stroke="#828080"
        strokeWidth="2"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <defs>
        <filter
          id="filter0_d_3342_9778"
          x="0"
          y="0"
          width="23"
          height="23"
          filterUnits="userSpaceOnUse"
          colorInterpolationFilters="sRGB"
        >
          <feFlood floodOpacity="0" result="BackgroundImageFix" />
          <feColorMatrix
            in="SourceAlpha"
            type="matrix"
            values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0"
            result="hardAlpha"
          />
          <feOffset dy="2" />
          <feGaussianBlur stdDeviation="2" />
          <feComposite in2="hardAlpha" operator="out" />
          <feColorMatrix
            type="matrix"
            values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0.25 0"
          />
          <feBlend
            mode="normal"
            in2="BackgroundImageFix"
            result="effect1_dropShadow_3342_9778"
          />
          <feBlend
            mode="normal"
            in="SourceGraphic"
            in2="effect1_dropShadow_3342_9778"
            result="shape"
          />
        </filter>
      </defs>
    </svg>
  );
};

export default TickedCheckbox;
