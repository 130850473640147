import { useEffect, useState } from "react";
import classes from "./FeedBackStepper.module.css";

type FeedBackStepperProps = {
  currentStep: number;
  numberOfPage: number;
};

export default function FeedBackStepper({
  currentStep,
  numberOfPage,
}: FeedBackStepperProps) {
  const [steps, setSteps] = useState<JSX.Element[]>([]);
  useEffect(() => {
    createPage();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [currentStep, numberOfPage]);

  const createPage = () => {
    var p: JSX.Element[] = [];
    for (var i = 1; i <= numberOfPage; i++) {
      p.push(
        <div className={currentStep === i ? classes.active : ""} key={i}>
          {i}
        </div>
      );
    }
    return setSteps(p);
  };

  return <div className={classes["stepper-container"]}>{steps}</div>;
}
