import FeedbackForm from "components/FeedBackForm/FeedbackForm";
import FeedbackFormTitle from "components/FeedBackForm/FeedbackFormTitle";
import QuestionGroup from "components/FeedBackForm/QuestionGroup";
import BackAndNext from "components/utility/BackAndNext";
import EmptySpace from "components/utility/EmptySpace";
import { useAuthContext } from "hooks/useAuthContext";
import { useGrowthCircleContext } from "hooks/useGrowthCircleContext";
import useParticipantFeedback from "hooks/useParticipantFeedback";
import useParticipants from "hooks/useParticipants";
import { getFacilFeedbackQuestions } from "models/componentSettings/feedback/facilFeedback";
import { R_HOST } from "models/organisationRole";
import React from "react";
import { useEffect } from "react";
import { useNavigate } from "react-router-dom";
import { COMPLETED_ROUTE } from "strings/Routes";
import { FeedbackProps } from "./FeedbackAfterComponent";

const FeedbackFacilComponent = ({
  backAndNextHandler,
  setTabNumber,
}: FeedbackProps) => {
  const { profile } = useAuthContext();
  const { growthCircleSession } = useGrowthCircleContext();
  const navigate = useNavigate();
  const { ParticipantRecord, sessionRole } = useParticipants();

  const facilFeedbackQuestions = getFacilFeedbackQuestions(
    growthCircleSession?.organisation ?? ""
  );
  const questionCategory = facilFeedbackQuestions.category;

  const category1Questions = facilFeedbackQuestions.subCategories[0].questions;

  const mainQuestions = facilFeedbackQuestions.subCategories[1].questions;

  const questionPrefixes =
    facilFeedbackQuestions.subCategories[1].questionPrefix ?? [];
  const questionPrefix =
    questionPrefixes[sessionRole?.name ?? ""] ?? questionPrefixes["default"];

  const otherFeedbackQuestion =
    facilFeedbackQuestions.subCategories[2].questions[0];

  const [otherFeedback, setOtherFeedback] = useParticipantFeedback(
    otherFeedbackQuestion.defaultResponse + "",
    questionCategory,
    otherFeedbackQuestion.questionName
  );

  useEffect(() => {
    if (!profile) return;
    if (!ParticipantRecord) return;
    if (ParticipantRecord[0]?.feedbackReflectionStatus === "submitted") {
      navigate(COMPLETED_ROUTE);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [profile, ParticipantRecord]);

  const nextHandler = () => {
    setTabNumber(3);
  };

  const backHandler = () => {
    setTabNumber(1);
  };

  return (
    <React.Fragment>
      <EmptySpace height={"15px"} />
      <FeedbackForm activeTab={2} handleSwitchTabs={(n) => setTabNumber(n)}>
        <FeedbackFormTitle>{facilFeedbackQuestions.title}</FeedbackFormTitle>
        <EmptySpace height={"10px"} />
        {sessionRole?.name && sessionRole.name !== R_HOST && (
          <QuestionGroup
            questionCategory={questionCategory}
            questions={category1Questions}
            sessionRole={sessionRole}
          />
        )}
        <QuestionGroup
          label={questionPrefix}
          questionCategory={questionCategory}
          questions={mainQuestions}
          sessionRole={sessionRole}
        />
        <QuestionGroup>
          <p className="text-slate-600 font-[700] px-4 text-left select-none">
            {otherFeedbackQuestion.question[sessionRole?.name ?? ""] ??
              otherFeedbackQuestion.question.default}
          </p>
          <textarea
            defaultValue={otherFeedback}
            onChange={(event) => setOtherFeedback(event.target.value)}
            style={{ borderColor: "var(--main-colour)" }}
            className={`w-full border-2 rounded-lg bg-default shadow-lg  px-4 py-2 focus:ring-main-colour focus:border-main-colour `}
            rows={5}
          />
        </QuestionGroup>
      </FeedbackForm>
      <EmptySpace height={"50px"} />
      <BackAndNext
        {...backAndNextHandler}
        nextCallback={nextHandler}
        backCallback={backHandler}
      />
    </React.Fragment>
  );
};

export default FeedbackFacilComponent;
