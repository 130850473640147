import ExpectRow from "./ExpectRow";

/**
 * Markup for GroundRule sections
 */

//Stepper: 'Being Mindful'
const MindfulContent = () => {

  return (
    <div className="text-left">
      {/* TextContent */}
      <p>
      This session should not be taken as a replacement for medical, clinical, or professional advice. 
      Please seek the services of a trained therapist, doctor, or trained professional should you require such assistance.
      </p>
    </div>
  );
};

//Stepper: 'Our Invitation'
const InvitationContent = () => {
  return (
    <div className="text-left">
      {/* <h1>Before We Begin...</h1> */}

      <ol>
        <p>
          <strong>To build a safe space together: </strong>
        </p>
        <li>Reserve judgment</li>
        <li>Be open to listen to others perspectives</li>
        <li>Ask “What” and “How”, avoid “Why”</li>
        <li>Adhere to the timings</li>
        <li>One person to speak at any given time</li>
        <li>Anything mentioned within the group stays within the group</li>
      </ol>
      <p>
        Individuals who affect the safe space may be invited to step aside for a
        chat.
      </p>
    </div>
  );
};

export type ExpectContents = {
  imageName: string;
  text: string;
};

//Stepper: 'What to Expect' Content
const ExpectContent = () => {
  const rows = [
    {
      title: "1: Let's Connect",
      contents: [
        {
          imageName: "Dice",
          text: "What connects to us",
        },
      ],
    },
    {
      title: "2: Check-In",
      contents: [
        {
          imageName: "Heart",
          text: "How are you feeling?",
        },
        {
          imageName: "Heart",
          text: "Setting Intentions",
        },
      ],
    },
    {
      title: "3: Build A Skill",
      contents: [
        {
          imageName: "Muscle",
          text: "Choosing Roles",
        },
      ],
    },
    {
      title: "4: Choose And Share",
      contents: [
        {
          imageName: "Folder",
          text: "Choosing Topic",
        },
        {
          imageName: "Journey",
          text: "Choosing Journey",
        },
        {
          imageName: "ChatBubble",
          text: "Sharing",
        },
      ],
    },
    {
      title: "5:  Reflection",
      contents: [
        {
          imageName: "LightBulb",
          text: "Reflection",
        },
      ],
    },
  ];

  return (
    <>
      {rows.map((row, index) => {
        return (
          <ExpectRow key={index} title={row.title} content={row.contents} />
        );
      })}
    </>
  );
};

const ContentTypes = {
  Mindful: {
    id: 1,
    title: "Before We Begin...",
    subtitle: "Take Note",
    content: MindfulContent(),
    buttonText: "Okay",
    stepperContent: "Being Mindful",
    larefState: "shocked",
  },
  Invitation: {
    id: 2,
    title: "Before We Begin...",
    subtitle: "An Invitation to All",
    content: InvitationContent(),
    buttonText: "I Agree",
    stepperContent: "An Invitation to All",
    larefState: "",
  },
  Expect: {
    id: 3,
    title: "Here's what to expect",
    content: ExpectContent(),
    buttonText: "Let's Go!",
    stepperContent: "What To Expect",
    larefState: "",
  },
};

export default ContentTypes;
