import EmptySpace from "components/utility/EmptySpace";
import Modal from "components/utility/Modal";
import { Dispatch, SetStateAction } from "react";

const INSTRUCTIONS_CHOOSE_ROLE = {
  title: 'Support the growth and wellbeing of others by playing a role',
  pointers: {
    title:'How to choose a role:\n',
    points: [
      'Pick a role that you are comfortable in',
      'Pick a role that you would like to practice and develop further in.',
      'Stretch yourself further. Pick a role that you have not done before.'
    ]
  },
  note: 'Be intentional in practicing this role throughout the session. However, picking a role here does not limit you from playing other roles during the session.'
}

interface Props {
  isModalOpen: boolean
  setIsModalOpen: Dispatch<SetStateAction<boolean>>
}

export function InstructionModal({
  isModalOpen,
  setIsModalOpen
}: Props) {
  return (
    <Modal
      isModalOpen={isModalOpen}
      setIsModalOpen={setIsModalOpen}
    >
      <>
        <div className="text-center">
          <b>
            { INSTRUCTIONS_CHOOSE_ROLE.title }
          </b>
        </div>
        <EmptySpace />
        <b>
          {INSTRUCTIONS_CHOOSE_ROLE.pointers.title}
        </b>
        <ul>
          {INSTRUCTIONS_CHOOSE_ROLE.pointers.points.map(point => {
            return (
              <li className="pl-4"
                key={point}
              >
                { ` - ${point}` }
              </li>
            );
          })}
        </ul>

        <EmptySpace/>
        <span>
          <b>{ 'Note: ' }</b>{ INSTRUCTIONS_CHOOSE_ROLE.note }
        </span>
      </>
    </Modal>
  );
}
