import { QueryConstraint, UpdateData, where } from "firebase/firestore";
import { timestamp } from "../firebase/config";
import getModelOperations from "utility/model";
import {
  FIRESTORE_PATH_ORGANISATIONS,
  getAllOrganisations,
} from "./organisation";
import {
  defaultPermissions,
  Permissions,
  P_ADVANCE_NEXT_ACTIVITY,
  P_ASSIGN_CO_HOST,
  P_BECOME_CO_HOST,
  P_GENERATE_QR_CODE,
  P_REMOVE_PROFILE_PICTURE,
  P_SEE_AVERAGE_RATING,
  P_USE_TIMER,
  P_CAN_OBSERVE,
  P_GRADE_JOURNALS,
  P_SKIP_SAVE_JOURNAL,
  P_CAN_VIEW_FEEDBACKS,
  P_CAN_VIEW_DASHBOARD,
  P_CAN_ACCESS_SELFAWARENESS,
  P_CAN_VIEW_OVERALL_SELF_DISCOVERY,
  P_VIEW_JOURNALS,
  P_CAN_VIEW_SELFAWARENESS_JOURNAL,
  P_CAN_VIEW_ASSIGNED_GROUP_SELFAWARENESS_JOURNAL,
  P_CAN_GRADE_ASSIGNED_GROUP_SELFAWARENESS_JOURNAL,
  P_CAN_EDIT_DASHBOARD,
  P_CAN_SEE_OTHER_RATINGS,
} from "./permission";
import Organisation from "interface/OrganisationInterface";
import { Profile } from "interface/ProfileInterface";
import { updateOrgUserByIds } from "utility/orgUsersHelpers";
import { setUserRole } from "models/organisation";
import { addInfoLog, addErrorLog } from "models/organisationLog";
import { getUsersByIds } from "models/profile";
import toast from "react-hot-toast";
import { FireStoreTimeStamp } from "react-admin-firebase/dist/misc/firebase-models";

export const FIRESTORE_SUBPATH_ROLES = "roles";

export const R_CO_HOST = "co-host";
export const R_HOST = "host";

/**
 * Organisation roles with different permissions such as facilitator, trainee, etc.
 * Types of roles:
 *    organisation: role in the organisation, such as facilitator, member etc.
 *    session: role in a particular growthcircles session. May change each session, such as host, co-host etc.
 */
export interface Role {
  name: string;
  type: "organisation" | "session";
  description: string;
  permissions: Permissions;
  createdAt: FireStoreTimeStamp;
  isEnable?: boolean;
  exCludedToCalc: boolean;
}

export interface UserMap {
  [id: string]: string;
}

export const defaultRole: Role = {
  name: "",
  type: "organisation",
  description: "",
  permissions: {
    ...defaultPermissions,
  },
  createdAt: timestamp.fromDate(new Date()),
  exCludedToCalc: false,
};

// TODO: Check for `see average rating` before showing average ratings in growth circles.
export const participantRole: Role = {
  name: "participant",
  type: "organisation",
  description: "",
  permissions: {
    ...defaultRole.permissions,
  },
  createdAt: timestamp.fromDate(new Date()),
  exCludedToCalc: false,
};

export const traineeRole: Role = {
  name: "trainee",
  type: "organisation",
  description: "",
  permissions: {
    ...defaultRole.permissions,
    [P_ADVANCE_NEXT_ACTIVITY]: true,
    [P_BECOME_CO_HOST]: true,
    [P_REMOVE_PROFILE_PICTURE]: true,
  },
  createdAt: timestamp.fromDate(new Date()),
  exCludedToCalc: false,
};

export const facilitatorRole: Role = {
  name: "facilitator",
  type: "organisation",
  description: "",
  permissions: {
    ...defaultRole.permissions,
    [P_GENERATE_QR_CODE]: true,
    [P_GRADE_JOURNALS]: true,
    [P_ADVANCE_NEXT_ACTIVITY]: true,
    [P_BECOME_CO_HOST]: true,
    [P_REMOVE_PROFILE_PICTURE]: true,
    [P_SEE_AVERAGE_RATING]: true,
    [P_USE_TIMER]: true,
    [P_CAN_SEE_OTHER_RATINGS]: true,
  },
  createdAt: timestamp.fromDate(new Date()),
  exCludedToCalc: false,
};

export const coHostRole: Role = {
  name: R_CO_HOST,
  type: "session",
  description: "",
  permissions: {
    ...defaultRole.permissions,
    [P_ADVANCE_NEXT_ACTIVITY]: true,
    [P_REMOVE_PROFILE_PICTURE]: true,
    [P_USE_TIMER]: true,
  },
  createdAt: timestamp.fromDate(new Date()),
  exCludedToCalc: false,
};

export const hostRole: Role = {
  name: R_HOST,
  type: "session",
  description: "",
  permissions: {
    ...defaultRole.permissions,
    [P_ADVANCE_NEXT_ACTIVITY]: true,
    [P_ASSIGN_CO_HOST]: true,
    [P_REMOVE_PROFILE_PICTURE]: true,
    [P_SEE_AVERAGE_RATING]: true,
    [P_USE_TIMER]: true,
    [P_CAN_SEE_OTHER_RATINGS]: true,
  },
  createdAt: timestamp.fromDate(new Date()),
  exCludedToCalc: false,
};

export const instructorRole: Role = {
  name: "instructor",
  type: "organisation",
  description: "",
  permissions: {
    ...defaultRole.permissions,
  },
  createdAt: timestamp.fromDate(new Date()),
  exCludedToCalc: false,
};

export const adminRole: Role = {
  name: "administrator",
  type: "organisation",
  description: "",
  permissions: {
    ...defaultRole.permissions,
    [P_GENERATE_QR_CODE]: true,
    [P_GRADE_JOURNALS]: true,
    [P_ADVANCE_NEXT_ACTIVITY]: true,
    [P_BECOME_CO_HOST]: true,
    [P_REMOVE_PROFILE_PICTURE]: true,
    [P_SEE_AVERAGE_RATING]: true,
    [P_USE_TIMER]: true,
    [P_CAN_OBSERVE]: true,
    [P_CAN_VIEW_FEEDBACKS]: true,
    [P_CAN_VIEW_DASHBOARD]: true,
    [P_CAN_EDIT_DASHBOARD]: true,
    [P_CAN_ACCESS_SELFAWARENESS]: true,
  },
  createdAt: timestamp.fromDate(new Date()),
  exCludedToCalc: false,
};

export const superAdminRole: Role = {
  name: "super administrator",
  type: "organisation",
  description: "",
  permissions: {
    ...adminRole.permissions,
    [P_VIEW_JOURNALS]: true,
    [P_ASSIGN_CO_HOST]: true,
    [P_CAN_VIEW_SELFAWARENESS_JOURNAL]: true,
    [P_CAN_VIEW_ASSIGNED_GROUP_SELFAWARENESS_JOURNAL]: true,
    [P_CAN_GRADE_ASSIGNED_GROUP_SELFAWARENESS_JOURNAL]: true,
    [P_CAN_VIEW_OVERALL_SELF_DISCOVERY]: true,
  },
  createdAt: timestamp.fromDate(new Date()),
  exCludedToCalc: false,
};

export function getDefaultRoles() {
  return [
    adminRole,
    participantRole,
    traineeRole,
    facilitatorRole,
    coHostRole,
    hostRole,
    instructorRole,
  ];
}

// --- Helper Functions ---

const ops = getModelOperations(defaultRole);

function _setRole(organisationId: string, updatedRole: Role) {
  const path = `${FIRESTORE_PATH_ORGANISATIONS}/${organisationId}/${FIRESTORE_SUBPATH_ROLES}/${updatedRole.name}`;
  return ops.setModel(path, updatedRole);
}

function _removeRole(organisationId: string, removeRole: Role) {
  const path = `${FIRESTORE_PATH_ORGANISATIONS}/${organisationId}/${FIRESTORE_SUBPATH_ROLES}/${removeRole.name}`;
  return ops.deleteModel(path);
}

function _getRole(organisationId: string, roleId: string) {
  const path = `${FIRESTORE_PATH_ORGANISATIONS}/${organisationId}/${FIRESTORE_SUBPATH_ROLES}/${roleId}`;
  return ops.getModel(path);
}

async function _getRoles(
  organisationId: string,
  ...queryConstraints: QueryConstraint[]
) {
  const path = `${FIRESTORE_PATH_ORGANISATIONS}/${organisationId}/${FIRESTORE_SUBPATH_ROLES}`;
  const result = await ops.getModels(path, ...queryConstraints);
  return result;
}

function _updateRole(
  organisationId: string,
  roleName: string,
  roleUpdates: UpdateData<Role>
) {
  const path = `${FIRESTORE_PATH_ORGANISATIONS}/${organisationId}/${FIRESTORE_SUBPATH_ROLES}/${roleName}`;
  return ops.updateModel(path, roleUpdates);
}

// --- End Helper functions ---

export const getRoleById = _getRole;

export function getRoleByUserID(
  userId: string,
  organisation: Organisation,
  roles: Role[]
): Role | null;
export function getRoleByUserID(
  userId: string,
  organisation: Organisation
): Promise<Role | null>;
export function getRoleByUserID(
  userId: string,
  organisation: Organisation,
  roles?: Role[]
) {
  if (roles) {
    return getRoleByName(roles, organisation.users[userId]);
  }
  return _getRoles(organisation.id).then((roles) =>
    getRoleByName(roles, organisation.users[userId])
  );
}

export function getRoleByName(roles: Role[], roleName: string) {
  const result = roles.find(
    (role) =>
      roleName &&
      role.name.toLowerCase().replace(" ", "") ===
        roleName.toLowerCase().replace(" ", "")
  );
  return result ? result : null;
}

export async function getUsersByRole(map: UserMap, roleName: string) {
  const result = Object.keys(map).filter((id) => map[id] === roleName);
  return result.length > 0 ? result : null;
}

export async function getAllUserPermissions(
  profile: Profile,
  organisations: Organisation[]
) {
  if (profile.access === "admin") {
    return organisations.map((organisation) => ({
      organisationId: organisation.id,
      name: superAdminRole.name,
      permissions: superAdminRole.permissions,
    }));
  }

  const promises = organisations.map(async (organisation) => {
    const role = await getRoleByUserID(profile.id, organisation);
    if (!role) {
      return {
        organisationId: organisation.id,
        name: "participant",
        permissions: defaultPermissions,
      };
    }

    return {
      organisationId: organisation.id,
      name: role.name,
      permissions: role.permissions,
    };
  });

  return await Promise.all(promises);
}

export async function addRole(
  organisationId: string,
  role: Role,
  oldRole?: Role
) {
  if (oldRole) {
    // For edit actions, we can allow retying in different casings and adding of spaces
    const existingRoles = await _getRoles(
      organisationId,
      where("name", "!=", oldRole.name)
    );
    const existingRole = getRoleByName(existingRoles, role.name);
    if (existingRole !== null) {
      return false;
    }
  } else {
    const existingRoles = await _getRoles(organisationId);
    const existingRole = getRoleByName(existingRoles, role.name);
    if (existingRole !== null) {
      return false;
    }
  }
  return setRole(organisationId, role);
}

export async function removeRole(organisationId: string, role: Role) {
  if (role === null) {
    return false;
  }
  return _removeRole(organisationId, role);
}

export async function reassignRole(
  profile: Profile,
  organisation: Organisation,
  previousRole: Role,
  newRole: Role,
  logDescription: string
) {
  try {
    new Promise((resolve) => {
      getUsersByRole(organisation.users, previousRole.name).then(
        async (users) => {
          if (users) {
            // Update role of identified users (from getUsersByRole) to newRole
            users.forEach((user) => {
              updateOrgUserByIds(user, organisation.id, { role: newRole.name });
              setUserRole(organisation, user, newRole.name);
              resolve(users);
            });

            // Map User ID to display name to be printed in text file
            const usernameList = getUsersByIds(users);
            exportUsersToTextFile(
              profile.id,
              organisation.id,
              (await usernameList).map((user) => user.email),
              newRole.name,
              logDescription
            );
            toast.success(
              `Users with this role have been assigned the ${newRole.name} role.`
            );
          } else {
            // Create log
            const text = logDescription + `\n\nNo users affected.`;
            await addInfoLog(profile.id, organisation.id, "ROLES", text);
            return false;
          }
        }
      );
    });
    return true;
  } catch {
    const errorLogDescription = `Error reassining users to the new role "${newRole.name}".`;
    await addErrorLog(
      profile.id,
      organisation.id,
      "ROLES",
      errorLogDescription
    );
    return false;
  }
}

/**
 * Export list of affected users (whose roles have been deleted) into a text file
 */
async function exportUsersToTextFile(
  profileId: string,
  organisationId: string,
  users: string[],
  newRoleName: string,
  logDescription: string
) {
  // Convert the users list to a formatted string
  const text =
    logDescription +
    `\n\nThe following list of users have been assigned to ${newRoleName} role: \n\n` +
    users.map((user) => `${user}`).join("\n");

  // Create a Blob object from the text
  const blob = new Blob([text], { type: "text/plain" });

  // Create a link element to download the text file
  const url = URL.createObjectURL(blob);
  const link = document.createElement("a");
  link.href = url;
  link.download = "users.txt";

  // Append the link to the document body and trigger the download
  document.body.appendChild(link);
  link.click();

  // Create log
  await addInfoLog(profileId, organisationId, "ROLES", text);

  // Clean up
  URL.revokeObjectURL(url);
  document.body.removeChild(link);
}

export function addRoles(organisationId: string, roles: Role[]) {
  roles.forEach((role) => addRole(organisationId, role));
}

export const setRole = _setRole;

export function setRoles(organisationId: string, updatedRoles: Role[]) {
  return Promise.all(
    updatedRoles.map((role) => {
      role.name = role.name.toLowerCase();
      return setRole(organisationId, role);
    })
  );
}

export async function setDefaultRoles(organisationId: string) {
  const existingRoles = await _getRoles(organisationId);

  // Create a map of existing roles for quick lookup by name
  const existingRolesMap = new Map(
    existingRoles.map((role) => [role.name, role])
  );

  // Set to keep track of roles we process from getDefaultRoles
  const processedRoleNames = new Set<string>();

  // Iterate over default roles and update or create them
  getDefaultRoles().forEach((defaultRole) => {
    const existingRole = existingRolesMap.get(defaultRole.name);

    // Mark this role as processed
    processedRoleNames.add(defaultRole.name);

    if (existingRole) {
      console.debug("Updating Role: ", defaultRole.name);
    } else {
      console.debug("Creating New Role: ", defaultRole.name);
    }

    // Merge existing permissions with defaultPermissions, adding missing ones
    const updatedPermissions = {
      ...existingRole?.permissions, // Keep existing permissions
      ...defaultPermissions, // Add default permissions if missing
    };

    const updatedRole = {
      ...defaultRole,
      ...existingRole, // Retain existing role properties, if any
      permissions: updatedPermissions, // Merge permissions
    };

    // Save the updated or newly created role
    setRole(organisationId, updatedRole);
  });

  // Process existingRoles that weren't part of getDefaultRoles
  existingRoles.forEach((existingRole) => {
    if (!processedRoleNames.has(existingRole.name)) {
      // Merge the existingRole.permissions with defaultPermissions
      const updatedPermissions = {
        ...existingRole.permissions, // Keep existing permissions
        ...defaultPermissions, // Add default permissions if missing
      };

      // Update the existing role with merged permissions
      const updatedRole = {
        ...existingRole,
        permissions: updatedPermissions, // Merge existing permissions with defaultPermissions
      };

      console.debug(
        "Updating Non-Default Role with Default Permissions: ",
        existingRole.name
      );
      setRole(organisationId, updatedRole); // Save the updated role with merged permissions
    }
  });
}

export async function updateRolePermissions(
  organisationId: string,
  roleName: string,
  permissions: Permissions
) {
  return _updateRole(organisationId, roleName, { permissions });
}

export async function updateRoleDescription(
  organisationId: string,
  roleName: string,
  description: string
) {
  return _updateRole(organisationId, roleName, { description });
}

export async function updateDefaultPermissionsForAll() {
  const defaultRoles = getDefaultRoles();
  const organisations = await getAllOrganisations();

  for (const org of organisations) {
    const roles = await _getRoles(org.id);

    for (const role of roles) {
      for (const defaultRole of defaultRoles) {
        if (role.id === defaultRole.name) {
          try {
            updateRolePermissions(org.id, role.id, defaultRole.permissions);
          } catch (e) {
            console.log(e);
          }
        }
      }
    }
  }
  console.log("update done");
}

export const getRoles = _getRoles;

export function checkHasPermission(role: Role | null, permission: string) {
  const permissions = role?.permissions;
  return permissions ? permissions[permission] === true : false;
}

export function checkCanAdvanceNextActivity(role: Role | null) {
  return checkHasPermission(role, P_ADVANCE_NEXT_ACTIVITY);
}

export function checkCanUseTimer(role: Role | null) {
  return checkHasPermission(role, P_USE_TIMER);
}

export function checkCanSkipSaveJournal(role: Role | null) {
  return checkHasPermission(role, P_SKIP_SAVE_JOURNAL);
}

export function checkIfCanObserve(role: Role | null) {
  return checkHasPermission(role, P_CAN_OBSERVE);
}

export function checkCanGradeJournals(role: Role | null) {
  return checkHasPermission(role, P_GRADE_JOURNALS);
}

export function checkIfCanGenerateQRcode(role: Role | null) {
  return checkHasPermission(role, P_GENERATE_QR_CODE);
}

export function checkIfCanViewJournalFeedback(role: Role | null) {
  return checkHasPermission(role, P_CAN_VIEW_FEEDBACKS);
}

export function checkIfCanViewSelfAwarenessJournal(role: Role | null) {
  return checkHasPermission(role, P_CAN_ACCESS_SELFAWARENESS);
}

export function checkIfCanViewSelfDiscoveryDashboard(role: Role | null) {
  return checkHasPermission(role, P_CAN_VIEW_OVERALL_SELF_DISCOVERY);
}

export function checkIfCanEditDashboard(role: Role | null) {
  return checkHasPermission(role, P_CAN_EDIT_DASHBOARD);
}
