import InputField from "components/utility/Forms/InputField";
import { Modal } from "flowbite-react";
import useOrganisationContext from "hooks/organisation/useOrganisationContext";
import { useAuthContext } from "hooks/useAuthContext";
import usePromise from "hooks/utility/usePromise";
import PublicQuestion from "interface/PublicQuestionInterface";
import { QuestionInterface } from "interface/QuestionsInterface";
import SuperAdminQuestion from "interface/SuperAdminQuestionInterface";
import { ChangeEvent, useCallback, useMemo, useState } from "react";
import toast from "react-hot-toast";
import { AiFillCloseCircle } from "react-icons/ai";
import { timestamp } from "../../../firebase/config";

interface Props {
  questions: QuestionInterface[];
  publicQuestions: PublicQuestion[];
  superAdminQuestions: SuperAdminQuestion[];
  addPublicQuestions: (newPublicQuestions: PublicQuestion[]) => Promise<void>;
  addSuperAdminQuestions: (
    newSuperAdminQuestions: SuperAdminQuestion[]
  ) => Promise<void>;
}

export enum QuestionDomain {
  PUBLIC = "public",
  SUPER_ADMIN = "super admin",
  ORGANISATION = "organisation",
}

/**
 * A modal that allows the user to add questions to a domain.
 *
 * @param Props - The props for the component.
 * @param Props.questions - The list of all questions.
 * @param Props.publicQuestions - The list of public questions.
 * @param Props.superAdminQuestions - The list of super admin questions.
 * @param Props.addPublicQuestions - A function that adds new public questions.
 * @param Props.addSuperAdminQuestions - A function that adds new super admin questions.
 * @returns The AddQuestionToDomainModal component.
 */
const AddQuestionToDomainModal = ({
  questions,
  publicQuestions,
  superAdminQuestions,
  addPublicQuestions,
  addSuperAdminQuestions,
}: Props) => {
  const [visible, setVisible] = useState(false);
  const [domain, setDomain] = useState(QuestionDomain.PUBLIC);
  const [selectedQuestions, setSelectedQuestions] = useState<{
    [key: string]: boolean;
  }>({});
  const [selectAll, setSelectAll] = useState(false);
  const [confirmation, setConfirmation] = useState(false);
  const { isLoading, resolve } = usePromise();
  const { profile } = useAuthContext();
  const { selectedOrganisation } = useOrganisationContext();

  const isSuperAdmin = profile?.access && profile.access === "admin";

  const domainOptions = isSuperAdmin
    ? [QuestionDomain.PUBLIC, QuestionDomain.SUPER_ADMIN]
    : [QuestionDomain.PUBLIC];

  const uniqueQuestions = useMemo(
    () => [
      ...new Set(
        questions
          .map((question) => question.question)
          .filter((question) => {
            const publicQuestionsLower = publicQuestions.map((question) =>
              question.question.toLocaleLowerCase()
            );
            const superAdminQuestionsLower = superAdminQuestions.map(
              (question) => question.question.toLocaleLowerCase()
            );

            if (domain === QuestionDomain.PUBLIC) {
              return !publicQuestionsLower.includes(question);
            }

            if (domain === QuestionDomain.SUPER_ADMIN) {
              return !superAdminQuestionsLower.includes(question);
            }

            // should never reach this line
            console.error("Question domain error");
            return false;
          })
      ),
    ],
    [questions, publicQuestions, superAdminQuestions, domain]
  );

  const hasSelectedQuestions = Object.values(selectedQuestions).includes(true);

  const resetSelectedQuestionsState = useCallback(() => {
    setSelectedQuestions({});
    setSelectAll(false);
    setConfirmation(false);
  }, []);

  const handleSelectQuestion = useCallback(
    (e: ChangeEvent<HTMLInputElement>) => {
      setConfirmation(false);
      // uncheck select all if any question is unchecked
      if (selectAll && !e.target.checked) {
        setSelectAll(false);
      }

      setSelectedQuestions((prev) => {
        const newSelectedQuestions = {
          ...prev,
          [e.target.value]: e.target.checked,
        };

        return newSelectedQuestions;
      });
    },
    [selectAll]
  );

  const addQuestionToDomain = useCallback(
    async (questionToAdd: string[]) => {
      if (!selectedOrganisation) {
        return;
      }

      try {
        if (domain === QuestionDomain.PUBLIC) {
          await addPublicQuestions(
            questionToAdd.map((question) => ({
              id:
                questions.find((q) => q.question === question)?.id || question,
              question,
              createdAt: timestamp.fromDate(new Date()),
              organisationFromId: selectedOrganisation.id,
            }))
          );
        }

        if (domain === QuestionDomain.SUPER_ADMIN) {
          await addSuperAdminQuestions(
            questionToAdd.map((question) => ({
              id:
                questions.find((q) => q.question === question)?.id || question,
              question,
              createdAt: timestamp.fromDate(new Date()),
            }))
          );
        }

        toast.success("Questions added to domain");
        setVisible(false);
        resetSelectedQuestionsState();
        setDomain(QuestionDomain.PUBLIC);
      } catch (error) {
        toast.error("An error occurred while adding questions to domain.");
        console.error(error);
      }
    },
    [
      addPublicQuestions,
      addSuperAdminQuestions,
      domain,
      questions,
      resetSelectedQuestionsState,
      selectedOrganisation,
    ]
  );

  const handleToggleSelectAll = useCallback(() => {
    setSelectAll((prev) => {
      const newValue = !prev;
      setSelectedQuestions(
        uniqueQuestions.reduce((acc, question) => {
          acc[question] = newValue;
          return acc;
        }, {})
      );

      return newValue;
    });
  }, [uniqueQuestions]);

  const handleAddSelectedQuestionsToDomain = useCallback(() => {
    const questionToAdd = Object.keys(selectedQuestions).filter(
      (question) => selectedQuestions[question]
    );

    resolve(async () => {
      await addQuestionToDomain(questionToAdd);
    });
  }, [addQuestionToDomain, resolve, selectedQuestions]);

  return (
    <>
      <Modal show={visible} size="4xl">
        <Modal.Body
          className="relative min-h-[80vh] border-2 rounded-lg bg-default"
          style={{ borderColor: "var(--icon-colour-0)" }}
        >
          <AiFillCloseCircle
            size={30}
            style={{ color: "var(--icon-colour-0)" }}
            onClick={() => {
              setVisible(false);
              resetSelectedQuestionsState();
              setDomain(QuestionDomain.PUBLIC);
            }}
            className="absolute top-2 right-2 cursor-pointer"
          />
          <div className="flex flex-col gap-2 justify-center">
            <p className="text-2xl">
              <strong>{`Add questions to domain`}</strong>
            </p>
            <p>
              You can select questions to add to a domain. Questions that are
              similar to existing questions in the domains will not be appear
              here.
              <br />
              <strong>public</strong> domain questions are visible to all
              organisations.
              {profile?.access && profile.access === "admin" && (
                <>
                  <br />
                  <strong>super admin</strong> domain questions are visible to
                  super admins only. (other users cannot see this domain)
                </>
              )}
            </p>
            <div>
              <p>Choose a domain to add the questions to.</p>
              <InputField
                htmlFor="domain"
                type="select"
                onChange={(e: ChangeEvent<HTMLSelectElement>) => {
                  setConfirmation(false);
                  setDomain(e.target.value as QuestionDomain);
                }}
                options={domainOptions}
                value={domain}
                isDisabled={isLoading}
              />
            </div>
            <div className="max-h-80 overflow-auto mt-2">
              {uniqueQuestions.length > 0 ? (
                <table className="w-full text-sm text-left rtl:text-right">
                  <thead className="text-xs border uppercase text-standard">
                    <tr className="bg-white">
                      <th key="role" scope="col" className="py-3 border">
                        <div className="flex justify-center items-center">
                          <input
                            type="checkbox"
                            checked={selectAll}
                            onChange={handleToggleSelectAll}
                            className="mr-2"
                            style={{
                              height: "20px",
                              width: "20px",
                              borderRadius: "5px",
                              backgroundColor: selectAll
                                ? "var(--icon-colour-0)"
                                : "",
                            }}
                          />
                          select
                        </div>
                      </th>
                      <th key="action" scope="col" className="px-6 py-3 border">
                        question
                      </th>
                    </tr>
                  </thead>
                  <tbody>
                    {uniqueQuestions.map((question, index) => (
                      <tr key={index} className="bg-white">
                        <td className="px-2 py-2 border flex justify-center">
                          <input
                            type="checkbox"
                            checked={selectedQuestions[question] || false}
                            onChange={handleSelectQuestion}
                            value={question}
                            style={{
                              height: "20px",
                              width: "20px",
                              borderRadius: "5px",
                              backgroundColor:
                                selectedQuestions[question] || false
                                  ? "var(--icon-colour-0)"
                                  : "",
                            }}
                          />
                        </td>
                        <td className="px-6 py-2 border">{question}</td>
                      </tr>
                    ))}
                  </tbody>
                </table>
              ) : (
                <p>No questions available to add to domain.</p>
              )}
            </div>
            <div className="flex flex-col items-center">
              {!(profile?.access && profile.access === "admin") && (
                <p className="text-center text-red-500 font-bold">
                  Warning: You cannot delete questions from the domain once
                  added.
                </p>
              )}
              {hasSelectedQuestions && (
                <>
                  {confirmation ? (
                    <div className="flex row items-center my-6">
                      Confirm add questions?
                      <button
                        className="ml-2 bg-red-400 text-white p-1 rounded"
                        onClick={() => setConfirmation(false)}
                      >
                        No
                      </button>
                      <button
                        className="ml-2 bg-red-400 text-white p-1 rounded"
                        onClick={() => {
                          setConfirmation(false);
                          handleAddSelectedQuestionsToDomain();
                        }}
                      >
                        Yes
                      </button>
                    </div>
                  ) : (
                    <button
                      disabled={isLoading}
                      onClick={() => setConfirmation(true)}
                      className="bg-red-400 text-white p-2 text-lg uppercase rounded-full w-1/3 my-4"
                    >
                      {isLoading ? "Adding questions..." : "Add questions"}
                    </button>
                  )}
                </>
              )}
            </div>
          </div>
        </Modal.Body>
      </Modal>

      <button
        className="bg-red-400 text-white p-2 text-lg uppercase rounded-full w-auto my-2"
        onClick={() => setVisible(true)}
      >
        Add questions to domain
      </button>
    </>
  );
};

export default AddQuestionToDomainModal;
