import SmileyRatingWithMax from "components/utility/smileyRating/SmileyRatingWithMax";
import { ChangeEventHandler } from "react";

export type SmileyRatingQuestionProps = {
  label: string;
  name: string;
  value: number;
  onChange: ChangeEventHandler<HTMLSelectElement>;
  maxRating: number | undefined;
};

export default function SmileyRatingQuestionWithMax({
  label,
  name,
  value,
  onChange,
  maxRating,
}: SmileyRatingQuestionProps) {
  return (
    <div className="border-slate-400/[.4] pb-2 pt-4">
      <div className="w-full">
        <p className="text-slate-600 font-[700] px-4 text-left select-none">
          {label}
        </p>
        <SmileyRatingWithMax
          otherClass={"bg-white"}
          value={value}
          name={name}
          onSelect={onChange}
          showError={false}
          maxRating={maxRating}
        />
      </div>
    </div>
  );
}
