import BackAndNext from "components/utility/BackAndNext";
import EmptySpace from "components/utility/EmptySpace";
import useParticipants from "hooks/useParticipants";
import { useEffect, useState } from "react";
import { checkHasPermission } from "models/organisationRole";
import { P_SEE_AVERAGE_RATING } from "models/permission";
import { Participant } from "interface/ParticipantInterface";
import { ComponentProps } from "../GenericSessionPage/ComponentMapping";
import React from "react";
import useOrganisationContext from "hooks/organisation/useOrganisationContext";
import OrgTotalScore from "../RatingAverage/OrgTotalScore";
import CheckOutOrgFacilScreen from "components/CheckOutFacilScreen/CheckOutOrgFacilScreen";

import { useAuthContext } from "hooks/useAuthContext";
import useOrganisationRoleContext from "hooks/organisation/useOrganisationRoleContext";

const CheckInCheckOutOrgAverageComponent = ({
  backAndNextHandler,
  setHasModal,
}: ComponentProps) => {
  const { ParticipantRecords, ParticipantRecord } = useParticipants();
  const { profile } = useAuthContext();
  const [countAllSubmitted, setCountAllSubmitted] = useState(0);
  const [isParticipantsModal, setIsParticipantsModal] = useState(false);
  const [isParticipantModal, setIsParticipantModal] = useState(false);
  const [self, setSelf] = useState<Participant | undefined>(undefined);
  const [hasPermission, setHasPermission] = useState(false);
  const { selectedOrganisation } = useOrganisationContext();
  const { roles } = useOrganisationRoleContext();
  useEffect(() => {
    if (isParticipantModal || isParticipantsModal) {
      setHasModal(isParticipantModal || isParticipantsModal);
      document.body.style.overflowY = "hidden";
    } else {
      setHasModal(false);
      document.body.style.overflowY = "unset";
    }
  }, [isParticipantModal, isParticipantsModal, setHasModal]);

  // Function to count participants with orgCheckOut records
  const countParticipantsCheckedOut = (
    participantRecords: Participant[]
  ): number => {
    return participantRecords.filter((record) => {
      if (record.orgCheckOut) {
        for (const key in record.orgCheckOut) {
          if (Object.prototype.hasOwnProperty.call(record.orgCheckOut, key)) {
            const value = record.orgCheckOut[key];
            if (typeof value === "number" && value >= 0) {
              return true; // At least one key has a value >= 0
            }
          }
        }
      }
      return false;
    }).length;
  };

  useEffect(() => {
    if (ParticipantRecord == null) return;
    setSelf(ParticipantRecord[0]);
  }, [ParticipantRecord]);

  useEffect(() => {
    if (ParticipantRecords.length === 0) return;
    const numberOfCheckedOutParticipants =
      countParticipantsCheckedOut(ParticipantRecords);
    setCountAllSubmitted(numberOfCheckedOutParticipants);
  }, [ParticipantRecords]);

  useEffect(() => {
    if (!selectedOrganisation) return;
  }, [selectedOrganisation]);

  useEffect(() => {
    if (!profile) return;

    const _role = roles.filter(
      (role) => role.name === selectedOrganisation?.users[profile.uid]
    );

    setHasPermission(checkHasPermission(_role[0], P_SEE_AVERAGE_RATING));
    // eslint-disable-next-line
  }, [profile, roles]);

  useEffect(() => {
    // eslint-disable-next-line
  }, [hasPermission]);

  return (
    <React.Fragment>
      <EmptySpace height="5px" />
      {ParticipantRecords.length > 0 && (
        <div className="py-4 border-b border-main-colour w-2/3 mx-auto">
          <p className="text-sm font-semibold text-center">
            {selectedOrganisation?.name === "NTU Career & Attachment Office"
              ? "Check out how everyone’s progress!"
              : "Check out how everyone’s feeling!"}
          </p>
          <p className="text-center py-2 font-semibold   text-slate-600">
            {countAllSubmitted} out of {ParticipantRecords.length} submitted
          </p>
        </div>
      )}

      <CheckOutOrgFacilScreen
        organisation={selectedOrganisation || undefined}
        participants={ParticipantRecords}
        self={self}
        hasPermission={hasPermission}
        setModalState={setIsParticipantsModal}
      />

      {hasPermission ? (
        <OrgTotalScore
          organisation={selectedOrganisation || undefined}
          participants={ParticipantRecords}
          setIsModal={setIsParticipantModal}
        />
      ) : (
        ""
      )}
      <EmptySpace height={"40px"} />
      <BackAndNext {...backAndNextHandler} />
    </React.Fragment>
  );
};

export default CheckInCheckOutOrgAverageComponent;
