import { useContext, useEffect, useState } from "react";

import classes from "./Reflect.module.css";
import ViewReflection from "./ViewReflection";
import ViewJournalContext from "../ViewJournalContext";
import { Carousel } from "react-responsive-carousel";
import "react-responsive-carousel/lib/styles/carousel.min.css";
import { ReactComponent as ExpandIcon } from "assets/NG/Activity/expand.svg";
import Lightbox, { ImagesListType } from "react-spring-lightbox";
import HeaderInstructions from "components/UploadImage/HeaderInstructions";
import FooterInstructions from "components/UploadImage/FooterInstructions";
import { Participant } from "interface/ParticipantInterface";

interface ViewNGReflectProps {
  journal: Participant;
  hasLightBox: Function;
}

const ViewNGReflect = ({ journal, hasLightBox }: ViewNGReflectProps) => {
  const { userJournal } = useContext(ViewJournalContext);
  const [activeIndex, setActiveIndex] = useState<number>(0);
  const [lightboxToggler, setLightboxToggler] = useState(false);

  const gotoPrevious = () => {
    activeIndex > 0 && setActiveIndex(activeIndex - 1);
  };

  const gotoNext = () => {
    activeIndex + 1 < images.length && setActiveIndex(activeIndex + 1);
  };

  const images: ImagesListType = (journal?.imageUrls || []).map((url) => ({
    src: url,
    loading: "lazy",
    alt: "Uploaded image",
  }));

  const getImageUrl = (index: number) => {
    if (journal.imageUrls && journal.imageUrls[index]) {
      return journal.imageUrls[index];
    }
  };

  const openLightbox = () => {
    setLightboxToggler(!lightboxToggler);
    hasLightBox(!lightboxToggler);
  };

  useEffect(() => {
    //console.log(journal);
  }, [journal]);
  return (
    <div className={classes["reflect-container"]}>
      <strong className={classes["title"]}>My Reflection</strong>
      <div className="bg-default w-full border-8 border-sky-200 px-2 py-4 rounded-tr-3xl rounded-tl-3xl">
        <div className="relative flex flex-col justify-center items-center w-full  bg-default rounded-lg  shadow-md">
          {journal &&
            journal &&
            journal.imageUrls &&
            journal.imageUrls.length > 0 && (
              <>
                <Carousel
                  className="relative"
                  showStatus={false}
                  showThumbs={false}
                  onChange={setActiveIndex}
                  swipeable={true}
                  showArrows={true}
                >
                  {Array.from(
                    { length: journal.imageUrls.length },
                    (_, index) => {
                      const imageUrlBase = getImageUrl(index);

                      return (
                        <div
                          key={index}
                          className="relative flex justify-center items-center h-full max-h-96"
                        >
                          <div className="relative">
                            <img
                              src={imageUrlBase}
                              alt={`Selected artwork ${index + 1}`}
                              className="rounded-lg w-full h-full object-cover"
                            />
                          </div>
                        </div>
                      );
                    }
                  )}
                </Carousel>
                <>
                  {getImageUrl(activeIndex) && (
                    <>
                      <ExpandIcon
                        width={32}
                        onClick={openLightbox}
                        className="absolute bottom-2 right-2 cursor-pointer z-10"
                      />
                    </>
                  )}
                </>
              </>
            )}
        </div>
        <div className="py-5">
          <ol className="list-decimal list-outside pl-5 space-y-2">
            {journal.reflectionQuestion1 && (
              <li className="text-gray-700 break-words">
                {journal.reflectionQuestion1}
              </li>
            )}
            {journal.reflectionQuestion2 && (
              <li className="text-gray-700 break-words">
                {journal.reflectionQuestion2}
              </li>
            )}

            {!Array.isArray(journal.personalReflectionQuestion) &&
              journal.personalReflectionQuestion[0] !== "" && (
                <li className="text-gray-700 break-words">
                  {Array.isArray(journal.personalReflectionQuestion) && (
                    <p
                      dangerouslySetInnerHTML={{
                        __html: journal.personalReflectionQuestion[0] || "",
                      }}
                    ></p>
                  )}
                  {!Array.isArray(journal.personalReflectionQuestion) && (
                    <p
                      dangerouslySetInnerHTML={{
                        __html: journal.personalReflectionQuestion || "",
                      }}
                    ></p>
                  )}
                </li>
              )}
            {journal.reflectionQuestion &&
              journal.reflectionQuestion.length > 0 && (
                <li className="text-gray-700">{journal.reflectionQuestion} </li>
              )}
          </ol>
        </div>
      </div>
      {(userJournal.reflection || userJournal.notes) && <ViewReflection />}
      <Lightbox
        isOpen={lightboxToggler}
        onPrev={gotoPrevious}
        onNext={gotoNext}
        images={images}
        currentIndex={activeIndex}
        /* Add your own UI */
        renderHeader={() => <HeaderInstructions onClick={openLightbox} />}
        renderFooter={() => <FooterInstructions />}
        // renderPrevButton={() => (<CustomLeftArrowButton />)}
        // renderNextButton={() => (<CustomRightArrowButton />)}
        // renderImageOverlay={() => (<ImageOverlayComponent >)}

        /* Add styling */
        // className="cool-class"
        style={{ background: "grey" }}
        /* Handle closing */
        onClose={() => openLightbox}
        /* Use single or double click to zoom */
        // singleClickToZoom

        /* react-spring config for open/close animation */
        pageTransitionConfig={{
          from: { transform: "scale(0.75)", opacity: 0 },
          enter: { transform: "scale(1)", opacity: 1 },
          leave: { transform: "scale(0.75)", opacity: 0 },
          config: { mass: 1, tension: 320, friction: 32 },
        }}
      />
    </div>
  );
};

export default ViewNGReflect;
