import Timer from "components/SharingPanel/Timer/Timer";
import BackAndNext from "components/utility/BackAndNext";
import EmptySpace from "components/utility/EmptySpace";
import { Modal } from "flowbite-react";
import { useAuthContext } from "hooks/useAuthContext";
import { useGrowthCircleContext } from "hooks/useGrowthCircleContext";
import React, { useEffect, useState, lazy, Suspense } from "react";
import { ReactComponent as CloseIcon } from "../../../assets/Xbutton.svg";
import { ComponentProps } from "../GenericSessionPage/ComponentMapping";

const NGReflectShareSkills = lazy(
  () => import("components/NG/NGReflectShareSkills")
);

const ActivityShareReflectionComponent = ({
  setHasModal,
  backAndNextHandler,
}: ComponentProps) => {
  const [hasLightBox, setHasLightBox] = useState(false);
  const [isVisible, setIsVisible] = useState(false);

  const setLightBoxHandler = () => {
    setHasModal(!hasLightBox);
    setHasLightBox(!hasLightBox);
  };

  const { profile } = useAuthContext();
  const { growthCircleSession } = useGrowthCircleContext();

  useEffect(() => {
    if (!profile) return;
    if (!growthCircleSession) return;

    if (isVisible) {
      document.body.style.overflow = "hidden";
    } else {
      document.body.style.overflow = "unset";
    }
  }, [growthCircleSession, hasLightBox, profile, isVisible]);

  const nextHandler = () => {
    setIsVisible(true);
    setHasModal(true);
    window.document.body.style.overflow = "hidden !important";
  };

  const confirmNext = (e: React.MouseEvent<HTMLButtonElement, MouseEvent>) => {
    setIsVisible(false);
    setHasModal(false);
    window.document.body.style.overflow = "unset !important";
    backAndNextHandler.nextCallback(e);
  };

  const modalHandler = () => {
    setHasModal(!isVisible);
    setIsVisible(!isVisible);
  };

  return (
    <React.Fragment>
      <React.Fragment>
        <Modal show={isVisible} size="md" className="min-h-screen" popup={true}>
          <Modal.Body className="p-5 relative mt-[25%] md:mt-[5%]">
            <div className="absolute right-0 top-0 p-2">
              <CloseIcon className="cursor-pointer" onClick={modalHandler} />
            </div>
            <div className="text-center py-5 px-2">
              <h3 className="font-semibold mt-5 text-lg text-slate-700">
                Before Proceeding
              </h3>
              <EmptySpace height={"20px"} />
              <div className="text-slate-700 text-center">
                {profile?.facilGC && (
                  <p className="text-sm">
                    Have all the participants finished sharing?
                  </p>
                )}
                {!profile?.facilGC && (
                  <p className="text-sm">Are you finished sharing?</p>
                )}
                <EmptySpace height={"20px"} />
                <div className="flex justify-center gap-4 mt-5">
                  <button
                    type="button"
                    className={`${
                      profile?.isFacil ? "bg-facil-colour" : "bg-main-colour"
                    } shadow-md  px-12 rounded-3xl text-white py-3 hover:bg-green-700 transition duration-1000`}
                    onClick={confirmNext}
                  >
                    <span>{"Yes"}</span>
                  </button>
                  <button
                    type="button"
                    className={`${
                      profile?.isFacil ? "bg-facil-colour" : "bg-main-colour"
                    } shadow-md px-12 rounded-3xl text-white py-3 hover:bg-green-700 transition duration-1000`}
                    onClick={modalHandler}
                  >
                    No
                  </button>
                </div>
              </div>
            </div>
          </Modal.Body>
        </Modal>
      </React.Fragment>
      <EmptySpace height={"30px"} />
      <Suspense fallback={<div>Loading...</div>}>
        <NGReflectShareSkills hasLightBox={setLightBoxHandler} />
      </Suspense>
      <EmptySpace height={"40px"} />
      <BackAndNext {...backAndNextHandler} nextCallback={nextHandler} />
      <EmptySpace height={"40px"} />
      {profile?.isFacil && (
        <>
          <Timer />
          <EmptySpace height={"50px"} />
        </>
      )}{" "}
      <EmptySpace height={"20px"} />
    </React.Fragment>
  );
};

export default ActivityShareReflectionComponent;
