import { useFirestore } from "./useFirestore";

export const useFacilCheck = () => {
  const { updateDocument } = useFirestore("users");

  //TODO: transfer to firebase
  const facilsEmails = [
    "kristine@empatho.sg",
    "faithisz.ah@gmail.com",
    "greg.tan@sgassist.com",
    "adrian.tan@sgassist.com",
    "wk.chan0711@gmail.com",
    "dylanliau@savoir-asia.com",
    "shamanthayan@savoir-asia.com",
    "shane@savoir-asia.com",
    "jonathan_poh@sacs.org.sg",
    "susanong.mh@gmail.com",
    "veena.nanthakumar@gmail.com",
    "admin@growthcollective.sg",
    "support@growthcollective.sg",
    "training@growthcollective.sg",
    "kaori03252014@gmail.com",
    "facil01@growthbeans.com",
    "facil02@growthbeans.com",
    "facil03@growthbeans.com",
    "facil04@growthbeans.com",
    "facil05@growthbeans.com",
    "facil06@growthbeans.com",
    "facil07@growthbeans.com",
    "facil08@growthbeans.com",
    "facil09@growthbeans.com",
    "facil10@growthbeans.com",
    "facil11@growthbeans.com",
    "facil12@growthbeans.com",
    "facil13@growthbeans.com",
    "facil14@growthbeans.com",
    "facil15@growthbeans.com",
    "facil16@growthbeans.com",
    "facil17@growthbeans.com",
    "facil18@growthbeans.com",
    "facil19@growthbeans.com",
    "facil20@growthbeans.com",
    "chanyunxin@gmail.com",
    "ryap.js@gmail.com",
    "boonpin.lim@gmail.com",
    "hiral_ds@yahoo.com",
    "tiongjieting@gmail.com",
    "nicolexchin@hotmail.com",
    "syukrie564@gmail.com",
    "hafizah.ayni@gmail.com",
  ];

  /**
   * It updates the document with the id passed in to have the isFacil property set to true.
   * @param {string} email - the email of the user you want to update
   * @param {boolean} isFacil - boolean
   * @param {string} id - the id of the document you want to update
   */
  const updateToFacil = (isFacil: boolean, id: string) => {
    // console.log(email , isFacil)
    if (isFacil === false) {
      return updateDocument(id, { isFacil: true });
    } else {
      //console.log("already a facil");
    }
  };

  /**
   * It takes in an email, a boolean, and an id, and if the email is in the facilsEmails array, it
   * calls the updateToFacil function with the email, boolean, and id
   * @param {string} email - the email of the user
   * @param {boolean} isFacil - boolean
   * @param {string} id - the id of the user
   */
  const checkEmail = (email: string, isFacil: boolean, id: string) => {
    facilsEmails.some((facilEmail) => {
      if (email === facilEmail) {
        updateToFacil(isFacil, id);
        return true;
      } else {
        return false;
      }
    });
  };

  const checkIfFacil = (email: string) => {
    facilsEmails.some((facilEmail) => {
      if (email === facilEmail) {
        return facilEmail;
      } else {
        return false;
      }
    });
  };

  return {
    checkEmail,
    checkIfFacil,
    facilsEmails,
  };
};
