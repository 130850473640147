import useUsers from "hooks/useUsers";
import { Profile } from "interface/ProfileInterface";
import { useEffect, useState } from "react";
import GroupUser from "./GroupUser";
import Organisation from "interface/OrganisationInterface";
import { Group, SubGroup } from "interface/GroupInterface";
import classes from "pages/AllCircles/Admin/Admin.module.css";

import useGroup from "hooks/organisation/useGroup";
import toast from "react-hot-toast";
import { inputClass } from "strings/InputClassStrings";
import usePromise from "hooks/utility/usePromise";
import SimpleLoader from "components/Loaders/SimpleLoader";

interface SubGroupLeaderListProps {
  users: string[];
  selectedOrganisation: Organisation;
  group?: Group;
  subgroup?: SubGroup;
  canAssignGroup: boolean;
}

const SubGroupLeaderList = ({
  users,
  group,
  subgroup,
  selectedOrganisation,
  canAssignGroup,
}: SubGroupLeaderListProps) => {
  const [orgUsers, setOrgUsers] = useState<Profile[]>([]);
  const [localUsers, setLocalUsers] = useState<Profile[]>([]);
  const [filteredUsers, setFilteredUsers] = useState<Profile[]>([]);
  const [selectedUsers, setSelectedUsers] = useState<Set<string>>(new Set());
  const [isLoading, setIsLoading] = useState(false);
  const { getAllUsersByIds } = useUsers();
  const { assignSubGroupLeader, subGroups } = useGroup();
  const [currentPage, setCurrentPage] = useState(1);
  const [itemsPerPage] = useState(10);
  const [searchQuery, setSearchQuery] = useState<string>("");
  const { isLoading: loading, resolve } = usePromise();

  const paginate = (pageNumber: number) => setCurrentPage(pageNumber);
  const totalPages = Math.ceil(filteredUsers.length / itemsPerPage);

  const renderPaginationButtons = () => {
    const paginationItems: JSX.Element[] = [];

    const createPaginationItem = (i: number) => (
      <button
        key={i}
        onClick={() => paginate(i)}
        style={{
          backgroundColor:
            currentPage === i ? "var(--icon-colour-2)" : "#EB8181",
        }}
        className={`my-5 mx-1 px-3 py-1 rounded-md `}
      >
        {i}
      </button>
    );

    if (totalPages <= 7) {
      for (let i = 1; i <= totalPages; i++) {
        paginationItems.push(createPaginationItem(i));
      }
    } else {
      paginationItems.push(createPaginationItem(1));

      if (currentPage > 3) {
        paginationItems.push(
          <span key="ellipsis1" className="my-5 mx-1 px-3 py-1">
            ...
          </span>
        );
      }

      const startPage = Math.max(2, currentPage - 1);
      const endPage = Math.min(totalPages - 1, currentPage + 1);

      for (let i = startPage; i <= endPage; i++) {
        paginationItems.push(createPaginationItem(i));
      }

      if (currentPage < totalPages - 2) {
        paginationItems.push(
          <span key="ellipsis2" className="my-5 mx-1 px-3 py-1">
            ...
          </span>
        );
      }

      paginationItems.push(createPaginationItem(totalPages));
    }

    return (
      <div className="flex justify-center mt-4">
        <button
          onClick={() => paginate(currentPage - 1)}
          className="my-5 mx-1 px-3 py-1 rounded-md bg-gray-200"
          disabled={currentPage === 1}
        >
          &lt;
        </button>
        {paginationItems}
        <button
          onClick={() => paginate(currentPage + 1)}
          className="my-5 mx-1 px-3 py-1 rounded-md bg-gray-200"
          disabled={currentPage === totalPages}
        >
          &gt;
        </button>
      </div>
    );
  };

  const indexOfLastItem = currentPage * itemsPerPage;
  const indexOfFirstItem = indexOfLastItem - itemsPerPage;
  const currentItems = filteredUsers.slice(indexOfFirstItem, indexOfLastItem);

  useEffect(() => {
    setCurrentPage(1);
  }, [orgUsers]);

  // Handle search input change
  const handleSearchChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    const query = event.target.value.toLowerCase();
    setSearchQuery(query);
    const filtered = localUsers.filter((user) => {
      const matchesSearchQuery =
        user.displayNameLowerCase.toLowerCase().includes(query) ||
        user.email.toLowerCase().includes(query);

      return matchesSearchQuery;
    });
    setFilteredUsers(filtered);
    setCurrentPage(1); // Reset to first page when search query or filter changes
  };

  const fetchOrgUsers = async () => {
    if (subgroup && subgroup.users && subGroups.length > 0) {
      const realtimeUsers = subGroups.filter(
        (_group) => _group.id === subgroup.id && _group.groupID === group?.id
      );
      if (realtimeUsers.length > 0) {
        const _data = await getAllUsersByIds(realtimeUsers[0].users);

        if (_data) {
          const sortedUsers = _data.sort((a, b) => {
            const isASelected = selectedUsers.has(a.uid) ? 1 : 0;
            const isBSelected = selectedUsers.has(b.uid) ? 1 : 0;
            return isBSelected - isASelected;
          });

          setLocalUsers(sortedUsers);
          setOrgUsers(sortedUsers);
        } else {
          setOrgUsers([]);
          setLocalUsers([]);
        }
      } else {
        setOrgUsers([]);
        setLocalUsers([]);
      }
    } else {
      setOrgUsers([]);
      setLocalUsers([]);
    }
  };

  const toggleUserSelection = (userId: string) => {
    setSelectedUsers((prevSelectedUsers) => {
      const newSelectedUsers = new Set(prevSelectedUsers);

      if (newSelectedUsers.has(userId)) {
        newSelectedUsers.delete(userId);
      } else {
        newSelectedUsers.add(userId);
      }
      return newSelectedUsers;
    });
  };

  const assignGroupLeaderHandler = async () => {
    try {
      setIsLoading(true);
      const selectedUsersArray = [...selectedUsers];
      await assignSubGroupLeader(
        group?.id,
        subgroup?.id,
        selectedUsersArray
      ).then(() => {
        setTimeout(function () {
          setIsLoading(false);
          toast.success("Leader(s) was updated successfully!");
        }, 2000);
      });
    } catch (error) {
      console.error(error);
    }
  };

  useEffect(() => {
    resolve(() => fetchOrgUsers());
    if (subgroup && subgroup.groupLeaders) {
      const realtimeUsers = subGroups.filter(
        (_group) => _group.id === subgroup.id && _group.groupID === group?.id
      );
      if (realtimeUsers.length > 0) {
        setSelectedUsers(new Set(realtimeUsers[0].groupLeaders));
      } else {
        setSelectedUsers(new Set());
      }
    } else {
      setSelectedUsers(new Set());
    }

    // eslint-disable-next-line
  }, [users, group, subgroup, subGroups]);

  useEffect(() => {}, [orgUsers]);

  useEffect(() => {
    const filtered = localUsers.filter((user) => {
      const matchesSearchQuery =
        user.displayNameLowerCase.toLowerCase().includes(searchQuery) ||
        user.email.toLowerCase().includes(searchQuery);

      return matchesSearchQuery;
    });
    setFilteredUsers(filtered);
    setCurrentPage(1); // Reset to first page when filter changes
    // eslint-disable-next-line
  }, [searchQuery, localUsers, canAssignGroup, selectedOrganisation]);

  return (
    <>
      {canAssignGroup && (
        <>
          <b>
            Select sub-group leader(s) for :{" "}
            {subGroups &&
              subGroups
                .filter((g) => g.id === subgroup?.id)
                .map((_g) => _g.groupName)}
          </b>
          <div className="relative flex items-center justify-end w-1/4 mt-2">
            <input
              disabled={isLoading}
              type="text"
              placeholder="Search..."
              value={searchQuery}
              onChange={handleSearchChange}
              className={inputClass}
              style={{ minWidth: "200px" }}
            />
          </div>
          {orgUsers && orgUsers.length > 0 && (
            <div className="flex gap-2 flex-wrap my-4">
              {currentItems.map((user, index) => (
                <GroupUser
                  readOnly={true}
                  user={user}
                  key={index}
                  selectedOrganisation={selectedOrganisation}
                  group={group}
                  subgroup={subgroup}
                  isSelected={selectedUsers.has(user.id)}
                  onToggleSelect={() => toggleUserSelection(user.id)}
                />
              ))}
            </div>
          )}
          {filteredUsers.length > 0 ? (
            <>
              {" "}
              <div>{renderPaginationButtons()}</div>
              <div className="my-8">
                <button
                  disabled={isLoading}
                  onClick={assignGroupLeaderHandler}
                  className={`${classes["button"]}`}
                >
                  {isLoading ? "Assigning..." : "Assign Leader"}
                </button>
              </div>
            </>
          ) : (
            <>
              {!loading ? (
                <div className="min-h-[10vh] flex justify-center items-center">
                  <p>No records available.</p>
                </div>
              ) : (
                <div className="w-full mt-2 min-[30vh] flex justify-center items-center">
                  {" "}
                  <SimpleLoader />
                </div>
              )}
            </>
          )}
          <hr />
        </>
      )}
    </>
  );
};

export default SubGroupLeaderList;
