import { QueryConstraint, UpdateData } from "firebase/firestore";
import firebase from 'firebase/compat/app';
import getModelOperations from "utility/model";
import {
  FIRESTORE_PATH_ORGANISATIONS,
} from "./organisation";

const FIRESTORE_SUBPATH_LOGS = "logs";
type LOG_TYPES = "INFO" | "WARN" | "ERROR";
type LOG_CATEGORIES = "USERS" | "ROLES" | "GENERAL" | "OTHERS";

/**
 * Organisation logs with different types
 * id: Date object
 * type:
 *    INFO: General useful information 
 *    WARN: Changes to security (Eg. permission changes for a role)
 *    ERROR: Failed to complete action
 * category:
 *    USERS: log about organisation users
 *    ROLES: log about organisation roles
 *    OTHERS: default
 * actionBy: userId
 * description: Description about the log
 */
export interface Log {
  timestamp: Date;
  type: LOG_TYPES;
  category: LOG_CATEGORIES;
  actionBy: string;
  description: string;
}


export const defaultLog: Log = {
  timestamp: new Date(),
  type: "INFO",
  category: "OTHERS",
  actionBy: "",
  description: "",
};

// --- Helper Functions ---

const ops = getModelOperations(defaultLog);

async function _addLog(organisationId: string, log: Log) {
  const path = `${FIRESTORE_PATH_ORGANISATIONS}/${organisationId}/${FIRESTORE_SUBPATH_LOGS}`;
  
  try {
    const docRef = await firebase.firestore().collection(path).add(log);
    
    const addedLogSnapshot = await docRef.get();
    const addedLog = { id: addedLogSnapshot.id, ...addedLogSnapshot.data() } as Log & { id: string };
    
    return addedLog;
  } catch (error) {
    console.error('Error adding log:', error);
    throw error;
  }
}

/*
function _removeLog(organisationId: string, removeLog: Log) {
  const path = `${FIRESTORE_PATH_ORGANISATIONS}/${organisationId}/${FIRESTORE_SUBPATH_LOGS}/${removeLog.id}`;
  return ops.deleteModel(path);
}
*/

function _getLog(organisationId: string, logId: string) {
  const path = `${FIRESTORE_PATH_ORGANISATIONS}/${organisationId}/${FIRESTORE_SUBPATH_LOGS}/${logId}`;
  return ops.getModel(path);
}

async function _getLogs(
  organisationId: string,
  ...queryConstraints: QueryConstraint[]
) {
  const path = `${FIRESTORE_PATH_ORGANISATIONS}/${organisationId}/${FIRESTORE_SUBPATH_LOGS}`;
  const result = await ops.getModels(path, ...queryConstraints);
  return result;
}

function _updateLog(
  organisationId: string,
  logId: string,
  logUpdates: UpdateData<Log>,
) {
  // Ensure logUpdates does not include the 'id' field
  const { ...updates } = logUpdates; // Destructure id from updates

  const path = `${FIRESTORE_PATH_ORGANISATIONS}/${organisationId}/${FIRESTORE_SUBPATH_LOGS}/${logId}`;
  return ops.updateModel(path, updates);
}

export const getLogById = _getLog;

export function getLogsByType(logs: Log[], logType: string) {  
  const result = logs.find((log) => logType && log.type === logType);
  return result ? result : null;
}

export async function getLogsByDescription(logs: Log[], logDescription: string) {
  const result = logs.find((log) => logDescription && log.description.includes(logDescription));
  return result ? result : null;
}

async function addLog(organisationId: string, log: Log) { 
  return _addLog(organisationId, log);
}

export async function addInfoLog(profileId: string, organisationId: string, category: LOG_CATEGORIES, description: string) { 
  const newLog: Log = {
    timestamp: new Date(),
    type: "INFO",
    category: category,
    actionBy: profileId,
    description: description,
  };
  
  await addLog(organisationId, newLog);
}

export async function addWarnLog(profileId: string, organisationId: string, category: LOG_CATEGORIES, description: string) { 
  const newLog: Log = {
    timestamp: new Date(),
    type: "WARN",
    category: category,
    actionBy: profileId,
    description: description,
  };

  await addLog(organisationId, newLog);
}

export async function addErrorLog(profileId: string, organisationId: string, category: LOG_CATEGORIES, description: string) { 
  const newLog: Log = {
    timestamp: new Date(),
    type: "ERROR",
    category: category,
    actionBy: profileId,
    description: description,
  };
  
  await addLog(organisationId, newLog);
}

/*
export async function removeLog(organisationId: string, log: Log) {
  if (log === null) {
    return false;
  }
  return _removeLog(organisationId, log)
}
*/

export function addLogs(organisationId: string, logs: Log[]) {
  logs.forEach((log) => addLog(organisationId, log));
}

export function setLogs(organisationId: string, updatedLogs: Log[]) {
  return Promise.all(
    updatedLogs.map((log) => {
      return addLog(organisationId, log);
    })
  );
}

export async function updateLogType(
  organisationId: string,
  logId: string,
  type: LOG_TYPES,
) {
  return _updateLog(organisationId, logId, { type });
}

export const getLogs = _getLogs;