import BackAndNextFlat from "components/utility/BackAndNextFlat";
import { useEffect, useState } from "react";
import { BackAndNextProps } from "../SelfAwarenessComponentMapping";
import { useSelfAwarenessContext } from "hooks/useSelfAwarenessContext";
import { colours } from "../components/styles/colourMap";
import CriterialItem from "../components/CriteriaItem";

interface CriteriaItemsProps {
  options: string[];
  backAndNextHandler: BackAndNextProps;
  backHandler: () => void;
  nextAllowedHandler: (
    e: React.MouseEvent<HTMLButtonElement, MouseEvent>
  ) => void;
}

interface Criteria {
  criteriaText: string;
  weight: number;
}

const CriteriaItems = ({
  options,
  backAndNextHandler,
  nextAllowedHandler,
  backHandler,
}: CriteriaItemsProps) => {
  const [activeIndex, setActiveIndex] = useState(0);
  const [criteria, setCriteria] = useState<Criteria[]>([]);
  const { journal } = useSelfAwarenessContext();
  // Load data from local storage on component mount
  useEffect(() => {
    const storedSelectedItems = localStorage.getItem("SelfAwarenessJournal");

    if (storedSelectedItems) {
      const parsedJournal = JSON.parse(storedSelectedItems);
      setCriteria(parsedJournal.criteria || []);
    }
  }, []);

  const nextIndexHandler = (
    e: React.MouseEvent<HTMLButtonElement, MouseEvent>
  ) => {
    if (activeIndex < options.length - 1) {
      setActiveIndex((prevIndex) => prevIndex + 1);
    } else {
      nextAllowedHandler(e);
    }
  };

  const backIndexHandler = () => {
    if (activeIndex > 0) {
      setActiveIndex((prevIndex) => prevIndex - 1);
    } else {
      backHandler();
    }
  };

  return (
    <div>
      <p
        className="px-2 font-poppins font-semibold"
        style={{ color: "var(--text-colour)" }}
      >
        Option {activeIndex + 1} : {options[activeIndex]}
      </p>

      {journal && (
        <div className="mb-8">
          {criteria &&
            criteria.map((item, index) => (
              <div
                key={index}
                className="bg-white px-2 border-2 rounded-lg my-4"
                style={{ borderColor: colours[journal.pageType]["border"] }}
              >
                <CriterialItem option={options[activeIndex]} item={item} />
              </div>
            ))}
        </div>
      )}

      <div className="my-4">
        <BackAndNextFlat
          {...backAndNextHandler}
          nextAllowed={true}
          backCallback={backIndexHandler}
          nextCallback={(e) => nextIndexHandler(e)}
        />
      </div>
    </div>
  );
};

export default CriteriaItems;
