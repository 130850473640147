import EmptySpace from "components/utility/EmptySpace";
import FileField from "components/utility/Forms/FileField";
import { ChangeEvent, ChangeEventHandler, useState } from "react";
import { SUPPORTED_IMAGE_EXTENSIONS, isValidImageFile } from "utility/imageValidation";

interface Props {
  onChange: ChangeEventHandler<HTMLInputElement>;
}

/**
 * Image file input that displays the selected image.
 *
 * @param onChange additional event handler when user selects an image.
 * @returns ImagePicker component.
 */
const ImagePicker = ({ onChange }: Props) => {
  const [imageURI, setImageURI] = useState<string | undefined>();
  const [errorMessage, setErrorMessage] = useState('');

  /**
   * Sets the imageURI based on uploaded file.
   *
   * @param e File select event.
   * @returns True if successful, false otherwise.
   */
  const readURI = (e: ChangeEvent<HTMLInputElement>) => {
    if (!(e.target.files && e.target.files[0])) {
      return false;
    }
    const file = e.target.files[0];
    if (!isValidImageFile(file.name)) {
      setErrorMessage('Please use one of the following image types: '
        + SUPPORTED_IMAGE_EXTENSIONS.join(', '));
      return false;
    }
    let reader = new FileReader();
    reader.onload = (ev) => {
      if (ev.target) {
        setImageURI(ev.target.result as string)
      }
    };
    reader.readAsDataURL(file);
    return true
  }

  const changeHandler = (e) => {
    setErrorMessage('');
    if (readURI(e)) {
      onChange(e);
    }
  }

  const imageTag = 
    imageURI
      ? (
        <img
          max-height={96}
          height={96}
          width={96}
          src={imageURI}
          alt={'selected'}
        />
      ) 
      : undefined;
 
  return (
    <>
      {errorMessage}
      <EmptySpace/>
      <FileField
        customIcon={imageTag}
        onChange={changeHandler}
        accept={SUPPORTED_IMAGE_EXTENSIONS
          .map(ext => 'image/' + ext)
          .join(', ')
        }
      />
      
    </>
  );
}

export default ImagePicker;
 