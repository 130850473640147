import { createContext, useEffect, useReducer } from "react";

import React from "react";

import { useAuthContext } from "hooks/useAuthContext";
import useOrganisation from "hooks/organisation/useOrganisation";
import useOrganisationContext from "hooks/organisation/useOrganisationContext";
import useFacilTheme from "hooks/useFacilTheme";

export const ThemeContext = createContext<ThemeContextStructure | null>(null);

interface ThemeContextStructure {
  isFacil: boolean;
  dispatch: React.Dispatch<actionStructure>;
}

export const authReducer = (state: stateStructure, action: actionStructure) => {
  switch (action.type) {
    case "facilitator":
      return { ...state, type: action.type, isFacil: true };
    case "participant":
      return { ...state, type: action.type, isFacil: false };

    default:
      return state;
  }
};

interface stateStructure {
  isFacil: true | false;
  type: string;
}

interface actionStructure {
  type: "facilitator" | "participant";

  isFacil: true | false;
}

interface Props {
  children: React.ReactNode;
}

export const ThemeContextProvider: React.FC<Props> = ({ children }) => {
  const [state, dispatch] = useReducer(authReducer, {
    type: "participant",
    isFacil: false,
  });
  const { changeFacilTheme } = useFacilTheme();
  const { profile } = useAuthContext();
  const { organisations } = useOrganisation();
  const { selectedOrganisation } = useOrganisationContext();

  useEffect(() => {
    if (!organisations || !profile) return;

    const selectedOrgName = selectedOrganisation
      ? selectedOrganisation.name
      : "Growthbeans";
    const isFacilitatorInSelectedOrg = organisations.some(
      (org) =>
        org.name === selectedOrgName && org.users[profile.id] === "facilitator"
    );

    dispatch({
      type: isFacilitatorInSelectedOrg ? "facilitator" : "participant",
      isFacil: isFacilitatorInSelectedOrg,
    });

    localStorage.setItem(
      "GrowthCirclesThemeIsFacil",
      isFacilitatorInSelectedOrg ? "true" : "false"
    );

    changeFacilTheme();

    // eslint-disable-next-line
  }, [organisations, profile, selectedOrganisation]);

  /* Logging the state of the AuthContext. */
  // console.log(state);

  return (
    <ThemeContext.Provider value={{ ...state, dispatch: dispatch }}>
      {children}
    </ThemeContext.Provider>
  );
};
