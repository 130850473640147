import { FC, useEffect, useState } from "react";
import { ComponentProps } from "../SelfAwarenessComponentMapping";

import BackAndNextFlat from "components/utility/BackAndNextFlat";
import { useSelfAwarenessContext } from "hooks/useSelfAwarenessContext";
import TitleBox from "../SubComponents/TitleBox";
import { colours } from "./styles/colourMap";
import WorstItem from "./WorstItem";

const WorstGrading: FC<ComponentProps> = ({ backAndNextHandler }) => {
  const [TopSelectionItems, setTopSelectionItems] = useState<string[]>([]);
  const { journal } = useSelfAwarenessContext();
  // Load data from local storage on component mount
  useEffect(() => {
    const storedSelectedItems = localStorage.getItem("SelfAwarenessJournal");

    if (storedSelectedItems) {
      const parsedJournal = JSON.parse(storedSelectedItems);
      setTopSelectionItems(parsedJournal.worsts || []);
    }
  }, []);

  useEffect(() => {
    if (!journal) return;
  }, [journal]);
  return (
    <div className="py-8 px-4 w-full md:w-1/4">
      {journal && (
        <TitleBox
          title={"What is the probability of it happening?"}
          journal={journal}
          showInfo={false}
          handler={() => {}}
        />
      )}
      {journal && (
        <div className="my-8">
          {TopSelectionItems &&
            TopSelectionItems.map((item, index) => (
              <div
                key={index}
                className="bg-white px-2 border-2 rounded-lg my-4"
                style={{ borderColor: colours[journal.pageType]["border"] }}
              >
                <WorstItem item={item} />
              </div>
            ))}
        </div>
      )}
      <div className="my-4">
        <BackAndNextFlat {...backAndNextHandler} />
      </div>
    </div>
  );
};

export default WorstGrading;
