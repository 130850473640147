import { FC, useEffect } from "react";
import { ComponentProps } from "../GenericSessionPage/ComponentMapping";
import classes from "./WaitingArea.module.css";
import OrgBubble from "components/Bubble/OrgBubble";
import EmptySpace from "components/utility/EmptySpace";
import ParticipantWaitingArea from "components/WaitingArea/ParticipantWaitingArea";
import NGFooter from "components/Footers/NGFooter";
import { useAuthContext } from "hooks/useAuthContext";
import useGetParticipants from "hooks/useGetParticipants";
import { useGCSession } from "hooks/useGCSession";
import { useGrowthCircleContext } from "hooks/useGrowthCircleContext";
import useOrganisationContext from "hooks/organisation/useOrganisationContext";
import { useFirestore } from "hooks/useFirestore";
import useParticipants from "hooks/useParticipants";
import { checkCanAdvanceNextActivity } from "models/organisationRole";
import WaitingAreaNextComponent from "components/WaitingArea/WaitingAreaNextComponent";

const WaitingAreaComponent: FC<ComponentProps> = ({ backAndNextHandler }) => {
  const { profile } = useAuthContext();
  const { participants } = useGetParticipants();
  const { createDices } = useGCSession();
  const { growthCircleSession } = useGrowthCircleContext();
  const { selectedOrganisation } = useOrganisationContext();
  const { updateDocument } = useFirestore("users");
  const { sessionRole } = useParticipants();

  const canAdvance = checkCanAdvanceNextActivity(sessionRole);

  useEffect(() => {
    if (
      !profile ||
      !canAdvance ||
      !growthCircleSession ||
      !selectedOrganisation
    )
      return;
    createDices(growthCircleSession);
    const data = { pageStep: window.location.pathname };
    updateDocument(profile.uid, data);

    if (profile.isFacil === true && profile.facilGC === "") {
      const data2 = { facilGC: growthCircleSession.id };
      updateDocument(profile.uid, data2);
    }

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [profile, growthCircleSession, canAdvance, selectedOrganisation]);

  if (!profile || !growthCircleSession) return null;

  return (
    <div className={`${classes["wrapper"]} min-h-screen`}>
      <div
        className={`${classes["container"]} flex justify-center flex-col min-h-[90vh] mb-[30vh]`}
      >
        <OrgBubble />
        <EmptySpace height={"30px"} />
        <ParticipantWaitingArea participants={participants} />
        <EmptySpace height={"30px"} />
        <WaitingAreaNextComponent
          backAndNextHandler={backAndNextHandler}
          participants={participants}
        />
      </div>

      <NGFooter type={growthCircleSession?.type} />
    </div>
  );
};

export default WaitingAreaComponent;
