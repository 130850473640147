import React from "react";
import classes from "./NumberChosenIcon.module.css";

interface Props {
  numOfPeopleChosen: number | undefined,
}

/**
 * A small icon that displays the number of participants who chosen this element
 * 
 * @param numOfPeopleChosen number of participants who chosen this element
 */
const NumberChosenIcon: React.FC<Props> = ({ numOfPeopleChosen }) => {
  if (!numOfPeopleChosen) return null;
  return (
    <div className={classes["display-users"]}>
      <p>{numOfPeopleChosen}</p>
    </div>
  );
};

export default NumberChosenIcon;
