import { useCallback } from "react";
import { useStorage } from "./useStorage";

/**
 * Uploads an image to firebase storage and retrieve its download link.
 *
 * @param uploadFilePath Firebase upload path.
 */
export const useUploadImage = (uploadFilePath: string) => {
  const { upload, download, deleteObject } = useStorage();

  /**
   * Uploads an image to firebase storage.
   *
   * @param file Upload file path.
   */
  const uploadImage = (file: File) => {
    const metadata = {
      contentType: file.type,
      contentDisposition: "inline",
    };

    upload(uploadFilePath, file, metadata)
  }

  /**
   * Retrieves the download link for the uploaded image.
   * Note that the image link does not update imediately after updateImage() completes.
   *
   * @returns The download URL.
   */
  const getImageLink = () => {
    const downloadFilePath = uploadFilePath + '_500x500';
    return download(downloadFilePath)
  }

  const deleteImage = useCallback(() => {
    return deleteObject(uploadFilePath);
  }, [deleteObject, uploadFilePath])

  return { uploadImage, getImageLink, deleteImage };
}

