import React from "react";
import { ReactComponent as Beach } from "../../assets/DiceIcons/Beach.svg";
import { ReactComponent as Bowl } from "../../assets/DiceIcons/Bowl.svg";
import { ReactComponent as Champion } from "../../assets/DiceIcons/Champion.svg";
import { ReactComponent as Climbing } from "../../assets/DiceIcons/Climbing.svg";
import { ReactComponent as CoconutTree } from "../../assets/DiceIcons/CoconutTree.svg";
import { ReactComponent as Coffin } from "../../assets/DiceIcons/Coffin.svg";
import { ReactComponent as CrossRoad } from "../../assets/DiceIcons/CrossRoad.svg";
import { ReactComponent as Cup } from "../../assets/DiceIcons/Cup.svg";
import { ReactComponent as Cycling } from "../../assets/DiceIcons/Cycling.svg";
import { ReactComponent as DeadTree } from "../../assets/DiceIcons/DeadTree.svg";
import { ReactComponent as Dolphin } from "../../assets/DiceIcons/Dolphin.svg";
import { ReactComponent as DoubleSidedArrow } from "../../assets/DiceIcons/DoubleSidedArrow.svg";
import { ReactComponent as Dragonfly } from "../../assets/DiceIcons/Dragonfly.svg";
import { ReactComponent as FerrisWheel } from "../../assets/DiceIcons/FerrisWheel.svg";
import { ReactComponent as Fishing } from "../../assets/DiceIcons/Fishing.svg";
import { ReactComponent as Flower } from "../../assets/DiceIcons/Flower.svg";
import { ReactComponent as GivingLove } from "../../assets/DiceIcons/GivingLove.svg";
import { ReactComponent as HorseRiding } from "../../assets/DiceIcons/HorseRiding.svg";
import { ReactComponent as IcyMountain } from "../../assets/DiceIcons/IcyMountain.svg";
import { ReactComponent as Key } from "../../assets/DiceIcons/Key.svg";
import { ReactComponent as Lizard } from "../../assets/DiceIcons/Lizard.svg";
import { ReactComponent as MagicWand } from "../../assets/DiceIcons/MagicWand.svg";
import { ReactComponent as Male } from "../../assets/DiceIcons/Male.svg";
import { ReactComponent as Mountain } from "../../assets/DiceIcons/Mountain.svg";
import { ReactComponent as Needle } from "../../assets/DiceIcons/Needle.svg";
import { ReactComponent as Pirate } from "../../assets/DiceIcons/Pirate.svg";
import { ReactComponent as Pumpkin } from "../../assets/DiceIcons/Pumpkin.svg";
import { ReactComponent as Rapport } from "../../assets/DiceIcons/Rapport.svg";
import { ReactComponent as Refresh } from "../../assets/DiceIcons/Refresh.svg";
import { ReactComponent as RopeKnot } from "../../assets/DiceIcons/RopeKnot.svg";
import { ReactComponent as ServingFood } from "../../assets/DiceIcons/ServingFood.svg";
import { ReactComponent as ShootingStar } from "../../assets/DiceIcons/ShootingStar.svg";
import { ReactComponent as Snake } from "../../assets/DiceIcons/Snake.svg";
import { ReactComponent as Snapchat } from "../../assets/DiceIcons/Snapchat.svg";
import { ReactComponent as Snowman } from "../../assets/DiceIcons/Snowman.svg";
import { ReactComponent as Sun } from "../../assets/DiceIcons/Sun.svg";
import { ReactComponent as Talking } from "../../assets/DiceIcons/Talking.svg";
import { ReactComponent as TigerFace } from "../../assets/DiceIcons/TigerFace.svg";
import { ReactComponent as Tired } from "../../assets/DiceIcons/Tired.svg";
import { ReactComponent as Tulip } from "../../assets/DiceIcons/Tulip.svg";
import { ReactComponent as TV } from "../../assets/DiceIcons/TV.svg";
import { ReactComponent as Uneasy } from "../../assets/DiceIcons/Uneasy.svg";
import { ReactComponent as Web } from "../../assets/DiceIcons/Web.svg";

const DiceImages: React.ElementType[] = [
  Beach,
  Bowl,
  Champion,
  Climbing,
  CoconutTree,
  Coffin,
  CrossRoad,
  Cup,
  Cycling,
  DeadTree,
  Dolphin,
  DoubleSidedArrow,
  Dragonfly,
  FerrisWheel,
  Fishing,
  Flower,
  GivingLove,
  HorseRiding,
  IcyMountain,
  Key,
  Lizard,
  MagicWand,
  Male,
  Mountain,
  Needle,
  Pirate,
  Pumpkin,
  Rapport,
  Refresh,
  RopeKnot,
  ServingFood,
  ShootingStar,
  Snake,
  Snapchat,
  Snowman,
  Sun,
  Talking,
  TigerFace,
  Tired,
  Tulip,
  TV,
  Uneasy,
  Web,
];

export default DiceImages;
