import { getDownloadURL, ref, uploadBytesResumable } from "firebase/storage";
import { projectStorage } from "../firebase/config";
import { isValidImageFile } from "./imageValidation";

export const uploadSingImage = async (
  image: File,
  directory: string
): Promise<string | undefined> => {
  return new Promise<string | undefined>((resolve, reject) => {
    const storageRef = ref(
      projectStorage,
      `/${directory}/${Date.now()}-${image.name}`
    );
    const metadata = {
      contentType: image.type,
      contentDisposition: "inline",
    };

    const uploadTask = uploadBytesResumable(storageRef, image, metadata);

    uploadTask.on(
      "state_changed",
      (snapshot) => {
        const percent = Math.round(
          (snapshot.bytesTransferred / snapshot.totalBytes) * 100
        );
        console.debug(percent);
      },
      (err) => {
        console.error(err);
        reject(err);
      },
      async () => {
        try {
          // Get the download URL of the resized image
          const url = await getDownloadURL(uploadTask.snapshot.ref);
          if (url) {
            resolve(url);
          } else {
            console.error("Invalid url format");
            reject(new Error("Invalid url format"));
          }
        } catch (error) {
          console.error(error);
          reject(error);
        }
      }
    );
  });
};

const uploadImage = (
  image: File,
  directory: string
): Promise<string | undefined> => {
  return new Promise<string | undefined>((resolve, reject) => {
    const storageRef = ref(
      projectStorage,
      `/${directory}/${Date.now()}-${image.name}`
    );
    const metadata = {
      contentType: image.type,
      contentDisposition: "inline",
    };

    const uploadTask = uploadBytesResumable(storageRef, image, metadata);

    uploadTask.on(
      "state_changed",
      (snapshot) => {
        const percent = Math.round(
          (snapshot.bytesTransferred / snapshot.totalBytes) * 100
        );
        console.debug(percent);
      },
      (err) => {
        console.error(err);
        reject(err);
      },
      async () => {
        try {
          // Get the download URL of the resized image
          const url = await getDownloadURL(uploadTask.snapshot.ref);

          if (isValidImageFile(url)) {
            let newStr = url.replace(
              /\.(jpe?g|png|gif|bmp|jpg)/i,
              "_500x500.webp"
            );
            resolve(newStr);
          } else {
            console.error("Invalid file format");
            reject(new Error("Invalid file format"));
          }
        } catch (error) {
          console.error(error);
          reject(error);
        }
      }
    );
  });
};

async function uploadImages(
  images: File[],
  directory: string
): Promise<string[]> {
  const imagePromises = images.map(
    (image) =>
      new Promise<string | undefined>((resolve, reject) => {
        uploadImage(image, directory)
          .then((url) => resolve(url))
          .catch((error) => reject(error));
      })
  );

  const imageRes = await Promise.all(imagePromises);

  // Filter out undefined values
  const filteredUrls = imageRes.filter((url) => url !== undefined) as string[];

  return filteredUrls; // list of URLs
}

export const handleMultipleFileUpload = async (
  event: React.ChangeEvent<HTMLInputElement>,
  directory: string
) => {
  const files = event.target.files;
  if (!files) {
    console.error("No files selected.");
    return undefined;
  }

  const fileList = Array.from(files);

  const urls = await uploadImages(fileList, directory);

  return urls; // Do something with the URLs
};
