import FiveStepHeader from "components/FiveStepHeader/FiveStepHeader";
import Header from "components/Header/Header";
import BackAndNext from "components/utility/BackAndNext";
import Center from "components/utility/Center/Center";
import ContentWrapper from "components/utility/ContentWrapper";
import EmptySpace from "components/utility/EmptySpace";
import PageWrapper from "components/utility/PageWrapper";
import Steps from "components/utility/Steps";
import useParticipants from "hooks/useParticipants";
import NoGCSessionWrapper from "pages/NoGCSession/NoGCSessionWrapper";
import { useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
import { CHECK_IN_AFTER_ROUTE, FEEDBACK_ROUTE_SELF } from "strings/Routes";
import NGFooter from "components/Footers/NGFooter";
import { useGrowthCircleContext } from "hooks/useGrowthCircleContext";
import TotalScore from "../RatingAverage/TotalScore";
import { checkHasPermission } from "models/organisationRole";
import { P_SEE_AVERAGE_RATING } from "models/permission";
import CheckOutFacilScreen from "components/CheckOutFacilScreen/CheckOutFacilScreen";
import { Participant } from "interface/ParticipantInterface";

const CheckInCheckOutAverage = () => {
  const stepsCount = 5;
  const currentStep = 5;

  const { ParticipantRecords, ParticipantRecord, sessionRole } =
    useParticipants();
  const navigate = useNavigate();
  const [countAllSubmitted, setCountAllSubmitted] = useState(0);
  const [hasTrigger, setHasTrigger] = useState(false);
  const { growthCircleSession } = useGrowthCircleContext();
  const [isParticipantsModal, setIsParticipantsModal] = useState(false);
  const [isParticipantModal, setIsParticipantModal] = useState(false);
  const [self, setSelf] = useState<Participant | undefined>(undefined);

  useEffect(() => {
    if (hasTrigger || isParticipantModal || isParticipantsModal) {
      document.body.style.overflowY = "hidden";
    } else {
      document.body.style.overflowY = "unset";
    }
  }, [hasTrigger, isParticipantModal, isParticipantsModal]);

  const _setTrigger = () => {
    setHasTrigger(!hasTrigger);
  };

  // Function to count participants with checkOut records
  const countParticipantsCheckedOut = (
    participantRecords: Participant[]
  ): number => {
    return participantRecords.filter(
      (record) => record.checkOut !== null && record.checkOut !== undefined
    ).length;
  };

  useEffect(() => {
    if (ParticipantRecord == null) return;
    setSelf(ParticipantRecord[0]);
  }, [ParticipantRecord]);

  useEffect(() => {
    if (ParticipantRecords.length === 0) return;
    const numberOfCheckedOutParticipants =
      countParticipantsCheckedOut(ParticipantRecords);
    setCountAllSubmitted(numberOfCheckedOutParticipants);
  }, [ParticipantRecords]);

  const backHandler = () => {
    navigate(CHECK_IN_AFTER_ROUTE);
  };

  const nextHandler = () => {
    navigate(FEEDBACK_ROUTE_SELF);
  };

  return (
    <NoGCSessionWrapper>
      <PageWrapper>
        <Header
          hasModal={hasTrigger || isParticipantModal || isParticipantsModal}
        />
        <FiveStepHeader
          hasModal={hasTrigger || isParticipantModal || isParticipantsModal}
          hasLightning={true}
          hasGuide={false}
          currentStep={currentStep}
          text="Reflection on Session"
          setIsTriggerVisible={_setTrigger}
        />

        <ContentWrapper hasFiveStepper={true}>
          <Center>
            <EmptySpace height="10px" />

            <Steps count={stepsCount} currentStep={currentStep} />
            <EmptySpace height="5px" />
            {ParticipantRecords.length > 0 && (
              <div className="py-4 border-b border-main-colour w-2/3 mx-auto">
                <p className="text-sm font-semibold text-center">
                  Check out how everyone’s feeling!
                </p>
                <p className="text-center py-2 font-semibold   text-slate-600">
                  {countAllSubmitted} out of {ParticipantRecords.length}{" "}
                  submitted
                </p>
              </div>
            )}

            <CheckOutFacilScreen
              participants={ParticipantRecords}
              self={self}
              hasPermission={checkHasPermission(
                sessionRole,
                P_SEE_AVERAGE_RATING
              )}
              setModalState={setIsParticipantsModal}
            />

            {checkHasPermission(sessionRole, P_SEE_AVERAGE_RATING) ? (
              <TotalScore
                participants={ParticipantRecords}
                setIsModal={setIsParticipantModal}
              />
            ) : (
              ""
            )}

            <EmptySpace height={"40px"} />
            <BackAndNext
              nextAllowed={true}
              nextCallback={nextHandler}
              backAllowed={true}
              backCallback={backHandler}
              backHidden={undefined}
            />
            <NGFooter type={growthCircleSession?.type} />
          </Center>
        </ContentWrapper>
      </PageWrapper>
    </NoGCSessionWrapper>
  );
};

export default CheckInCheckOutAverage;
