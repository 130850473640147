import { Modal } from "flowbite-react";
import { useGrowthCirclesType } from "hooks/useGrowthCirlesType";
import usePromise from "hooks/utility/usePromise";
import Organisation from "interface/OrganisationInterface";
import classes from "pages/AllCircles/Admin/Admin.module.css";
import { ChangeEvent, useState } from "react";
import toast from "react-hot-toast";

import { AiFillCloseCircle } from "react-icons/ai";
import { BsExclamationTriangleFill } from "react-icons/bs";
import { useNavigate } from "react-router-dom";
import { inputClass } from "strings/InputClassStrings";
const DeleteGcComponent = ({
  organisation,
}: {
  organisation: Organisation;
}) => {
  const [isVisible, setIsVisible] = useState(false);
  const { deleteGCType } = useGrowthCirclesType();
  const deleteHandler = () => {
    setIsVisible(!isVisible);
  };
  const [name, setName] = useState("");
  const { isLoading, resolve } = usePromise();
  const navigate = useNavigate();
  const confirmDeleteHandler = () => {
    if (name === organisation.name) {
      resolve(() => deleteGCType(organisation.id)).then(() => {
        setIsVisible(!isVisible);
        toast.success("Growthcircle type was successfully deleted");
        navigate(`/all-circles/admin/growthcircles-type`);
      });
    } else {
      toast.error("Please enter the correct Growthcircle title.");
    }
  };

  return (
    <>
      <Modal size={"3xl"} show={isVisible}>
        <div className="bg-red-300 relative p-5">
          <p className="text-red-800 font-semibold text-2xl flex gap-2  items-center">
            <BsExclamationTriangleFill size={30} /> Delete this Growthcircle?{" "}
          </p>
          <p className="text-red-800 my-4">
            Once deleted, the information will be permanently removed from the
            Growthcircles database.{" "}
          </p>
          {!isLoading && (
            <AiFillCloseCircle
              size={30}
              className="text-default absolute top-2 right-2 z-10 cursor-pointer"
              onClick={deleteHandler}
            />
          )}
        </div>
        <Modal.Body
          style={{ color: "var(--text-colour)" }}
          className="min-h-[20vh]"
        >
          <div className={`${classes["container"]} flex-col gap-4`}>
            <p>
              Confirm you want to delete this collection by typing its name :{" "}
              <strong>{organisation.name}</strong>
            </p>
            <input
              onChange={(e: ChangeEvent<HTMLInputElement>) =>
                setName(e.target.value)
              }
              type="text"
              placeholder="Growthcircle name..."
              className={inputClass}
            />
            <div className=" self-end">
              <button
                disabled={isLoading}
                onClick={confirmDeleteHandler}
                className={`${classes["button"]} my-8 disabled:bg-gray-400 disabled:hover:bg-gray-400`}
              >
                {isLoading ? "Deleting..." : "Confirm Delete"}
              </button>
            </div>
          </div>
        </Modal.Body>
      </Modal>
      <div className="py-4 p-4 flex-1 border-2 border-gray-200  my-4">
        <p className="font-semibold py-4 text-xl">Administrator</p>
        <p>
          Exercise caution in this area, as it is restricted to administrators.
          Once deleted, the information will be permanently removed from the
          Growthcircles database.
        </p>
        <button onClick={deleteHandler} className={`${classes["button"]} my-8`}>
          Delete
        </button>
      </div>
    </>
  );
};

export default DeleteGcComponent;
