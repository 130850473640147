import { FC, useEffect, useState } from "react";
import TitleBox from "../SubComponents/TitleBox";
import { ComponentProps } from "../SelfAwarenessComponentMapping";
import { useSelfAwarenessContext } from "hooks/useSelfAwarenessContext";
import BackAndNextFlat from "components/utility/BackAndNextFlat";
import { FaPlusCircle } from "react-icons/fa";
import classes from "pages/AllCircles/Admin/Admin.module.css";
import ChallengeInput from "../SubComponents/ChallengeInput";
import SimpleLoader from "components/Loaders/SimpleLoader";
import ConfirmModal from "../SubComponents/ConfirmModal";

const ChallengeTasks: FC<ComponentProps> = ({
  backAndNextHandler,
  setHasModal,
}) => {
  const [show, setShow] = useState<boolean>(false);
  const { journal } = useSelfAwarenessContext();
  const [showConfirm, setShowConfirm] = useState<boolean>(false);

  const handler = () => {
    setShow(!show);
    setHasModal(!show);
  };

  const [challenges, setChallenges] = useState<string[]>([]);
  const [isLoading, setLoading] = useState<boolean>(false);

  // Load data from local storage on component mount
  useEffect(() => {
    const storedJournal = localStorage.getItem("SelfAwarenessJournal");
    const existingJournal = storedJournal ? JSON.parse(storedJournal) : {};
    if (existingJournal && existingJournal.challenges) {
      setChallenges(existingJournal.challenges);
    }
    // eslint-disable-next-line
  }, []);

  const addChallengeHandler = () => {
    // Assuming challenges is an array in your state
    const newChallenge = ""; // Replace with your actual default data
    setChallenges((prevChallenges) => [...prevChallenges, newChallenge]);

    const storedJournal = localStorage.getItem("SelfAwarenessJournal");
    const existingJournal = storedJournal ? JSON.parse(storedJournal) : {};
    const existingChallenges = existingJournal.challenges || [];

    // Update the local storage with the modified challenges array
    const updatedJournal = {
      ...existingJournal,
      challenges: [...existingChallenges, newChallenge], // Add the new challenge
    };
    localStorage.setItem(
      "SelfAwarenessJournal",
      JSON.stringify(updatedJournal)
    );
  };

  const removeHandler = (index: number) => {
    const storedJournal = localStorage.getItem("SelfAwarenessJournal");
    const existingJournal = storedJournal ? JSON.parse(storedJournal) : {};
    const existingChallenges = existingJournal.challenges || [];
    const updatedChallenges = existingChallenges.filter((_, i) => i !== index); // Remove the challenge with the specified value

    // Update the local storage with the modified challenges array directly
    existingJournal.challenges = updatedChallenges;
    localStorage.setItem(
      "SelfAwarenessJournal",
      JSON.stringify(existingJournal)
    );
    setChallenges(updatedChallenges); // Update the state
    refreshValues();
  };

  const refreshValues = () => {
    setLoading(true);
    const storedJournal = localStorage.getItem("SelfAwarenessJournal");
    const existingJournal = storedJournal ? JSON.parse(storedJournal) : {};
    if (existingJournal && existingJournal.challenges) {
      setChallenges(existingJournal.challenges);
    }
    setTimeout(() => {
      setLoading(false);
    }, 1000);
  };

  const nextHandler = () => {
    setShowConfirm(!showConfirm);
    setHasModal(!showConfirm);
  };

  useEffect(() => {}, [isLoading]);

  useEffect(() => {
    if (!journal) return;
  }, [journal]);

  return (
    <>
      <ConfirmModal
        showConfirm={showConfirm}
        setHasModal={setHasModal}
        backAndNextHandler={backAndNextHandler}
        handler={nextHandler}
      />
      <div className="py-8 px-4 w-full md:w-1/4">
        {journal && (
          <>
            <TitleBox
              journal={journal}
              handler={handler}
              showInfo={false}
              title={`Contribute Challenge Task(s)`}
            />

            <div
              className={`p-5 flex flex-col gap-2 mt-8 font-poppins ${classes["container"]}`}
            >
              {!isLoading &&
                challenges.map((challenge, index) => (
                  <ChallengeInput
                    key={index}
                    removeHandler={removeHandler}
                    index={index}
                    pageType={journal.pageType}
                    challenge={challenge}
                  />
                ))}

              {isLoading && (
                <div className="flex justify-center items-center">
                  <SimpleLoader />
                </div>
              )}
            </div>
            <div className="flex justify-center items-center mb-8">
              <FaPlusCircle
                className="cursor-pointer"
                onClick={addChallengeHandler}
                size={30}
                style={{ color: "var(--icon-colour-0)" }}
              />
            </div>
          </>
        )}
        <div className="my-4">
          <BackAndNextFlat
            {...backAndNextHandler}
            nextAllowed={challenges.length > 0 ? true : false}
            nextCallback={nextHandler}
          />
        </div>
      </div>
    </>
  );
};

export default ChallengeTasks;
