import { FC, Suspense, lazy, useEffect, useState } from "react";
import { ComponentProps } from "../GenericSessionPage/ComponentMapping";
import classes from "../Welcome.module.css";
import { Modal, Button, Select } from "flowbite-react";
import { FiMapPin } from "react-icons/fi";
import { LocationSelectionData } from "utility/locationSelectionData";
import { useGCSession } from "hooks/useGCSession";
import { useGrowthCircleContext } from "hooks/useGrowthCircleContext";
import { useAuthContext } from "hooks/useAuthContext";
import useOrganisationContext from "hooks/organisation/useOrganisationContext";
import InfoPanelComponent from "../InfoPanel/InfoPanelComponent";
import { useWelcome } from "hooks/useWelcome";
import defaultUpload from "assets/GrowthCirclesType/pink.svg";

const WelcomeCenterDisplaySession = lazy(
  () =>
    import(
      "components/Organisation/WelcomeSettings/components/WelcomeCenterDisplaySession"
    )
);

const OrgBubble = lazy(() => import("components/Bubble/OrgBubble"));

const WelcomeComponent: FC<ComponentProps> = ({
  backAndNextHandler,
  setHasModal,
}) => {
  const { profile } = useAuthContext();
  const [isVisible, setVisible] = useState(false);
  const [location, setLocation] = useState("Online");
  const [locationDetails, setLocationDetails] = useState("");
  const [isSaving, setIsSaving] = useState(false);
  const { updateGCLocation } = useGCSession();
  const { growthCircleSession } = useGrowthCircleContext();
  const { selectedOrganisation } = useOrganisationContext();
  const [isReady, setIsReady] = useState(false);
  const { welcomeData } = useWelcome();
  const [focusComponent, setFocusComponent] = useState("Welcome");

  const modalHandler = async () => {
    setIsSaving(true);

    await updateGCLocation(location, locationDetails);
    setVisible(!isVisible);
    setIsSaving(false);
  };

  useEffect(() => {
    if (!profile) return;

    if (
      growthCircleSession?.facilOwner === profile.uid &&
      growthCircleSession?.type === "Growthbeans"
    ) {
      setVisible(true);
    }

    if (
      growthCircleSession?.facilOwner === profile.uid &&
      growthCircleSession?.type !== "Growthbeans"
    ) {
      if (
        growthCircleSession?.type &&
        selectedOrganisation &&
        selectedOrganisation?.name
      ) {
        updateGCLocation(selectedOrganisation?.name, locationDetails);
        setLocation(selectedOrganisation?.name);
      }
    }

    // eslint-disable-next-line
  }, [profile, document, growthCircleSession, selectedOrganisation]);

  useEffect(() => {
    SynReady();
    // eslint-disable-next-line
  }, [welcomeData]);

  const SynReady = async () => {
    const countdown = setTimeout(() => {
      setIsReady(true);
    }, 1200);
    return () => clearTimeout(countdown);
  };

  const getObjectiveValue = (
    label: string,
    objective: string
  ): string | undefined => {
    if (welcomeData && welcomeData.objectives) {
      const objective = welcomeData.objectives.find(
        (obj) => obj.label === label
      );

      return objective ? `${objective.value}` : `${objective}`;
    } else {
      return `${objective}`;
    }
  };

  const nextHandler = async (e: any) => {
    if (selectedOrganisation?.welcomeCustomType) {
      if (selectedOrganisation?.welcomeCustomType === "no") {
        backAndNextHandler.nextCallback(e);
      }
      if (selectedOrganisation?.welcomeCustomType === "single-logo") {
        setFocusComponent("SingleLogo");
      }
      if (selectedOrganisation?.welcomeCustomType === "multiple-logo") {
        setFocusComponent("MultipleLogo");
      }
    } else {
      backAndNextHandler.nextCallback(e);
    }
  };

  if (!profile) {
    return (
      <InfoPanelComponent
        setHasModal={setHasModal}
        backAndNextHandler={backAndNextHandler}
      />
    );
  }

  return (
    <div id="welcome-page">
      <Modal show={isVisible} size="md" popup={true} className="min-h-screen">
        <Modal.Body>
          <div className="text-center pt-5">
            <FiMapPin className="mx-auto mb-2 h-24 w-24 text-gray-400 dark:text-gray-200" />
            <h3 className="mb-5 py-4 text-lg font-normal text-gray-500 dark:text-gray-400">
              Where is the location for your GrowthCircles session?
              <small className="block text-sm mt-2">
                Please select from the options and then press save
              </small>
            </h3>

            <div className="flex  flex-col justify-center gap-4">
              <Select
                defaultValue={location}
                onChange={(e) => setLocation(e.target.value)}
              >
                {["SUSS", ...LocationSelectionData]
                  .sort((a, b) => a.localeCompare(b))
                  .map((location, index) => (
                    <option value={location} key={index}>
                      {location}
                    </option>
                  ))}
              </Select>
              <textarea
                cols={30}
                rows={10}
                className={`${
                  location === "My Organization" || location === "Others"
                    ? ""
                    : "hidden"
                } w-full
                  text-sm
                  text-gray-900
                  bg-gray-50
                  rounded-lg
                  border-gray-300`}
                placeholder="Specify location"
                onChange={(e) => setLocationDetails(e.target.value)}
                defaultValue={locationDetails}
              ></textarea>
              <Button
                disabled={isSaving}
                className="shadow-md"
                color={isSaving ? "gray" : "success"}
                onClick={modalHandler}
              >
                {isSaving ? "Saving..." : "Save"}
              </Button>
            </div>
          </div>
        </Modal.Body>
      </Modal>

      {!welcomeData ? (
        <>
          {isReady && (
            <div
              className="cursor-pointer"
              onClick={(e: any) => backAndNextHandler.nextCallback(e)}
            >
              <div className={`${classes["wrapper"]} relative`}>
                <div className={`${classes["container"]}`}>
                  <>
                    <Suspense fallback={<div>Loading...</div>}>
                      <OrgBubble />
                    </Suspense>
                    <p className={classes["left"]}>Gain new perspectives</p>
                    <p className={classes["right"]}>Enhance your positivity</p>
                    <p className={classes["center"]}>
                      Develop your empathy skills
                    </p>
                    <p className={classes["centerFill"]}>
                      Enhance your listening and questioning skills
                    </p>
                    <p className={`${classes["prompt"]} pb-20`}>
                      Tap anywhere to start
                    </p>
                  </>
                </div>
              </div>
            </div>
          )}
        </>
      ) : (
        <div className="cursor-pointer" onClick={(e: any) => nextHandler(e)}>
          {isReady && (
            <>
              {focusComponent === "Welcome" && (
                <div className={`${classes["wrapper"]} relative`}>
                  <div className={`${classes["container"]}`}>
                    <>
                      <Suspense fallback="Loading...">
                        <WelcomeCenterDisplaySession
                          selectedOrganisation={selectedOrganisation}
                          welcomeData={welcomeData}
                        />
                      </Suspense>
                      {welcomeData.objectiveSettings && (
                        <>
                          {welcomeData.objectiveSettings.summary && (
                            <div className="w-full my-2">
                              <p
                                className={`${classes["welcome-big-text"]} text-ellipsis overflow-hidden break-words`}
                              >
                                {getObjectiveValue(
                                  "description",
                                  "Description of Growth Circles (200 words)"
                                )}
                              </p>
                            </div>
                          )}

                          {welcomeData.objectiveSettings.objective1 && (
                            <p className={classes["left"]}>
                              {getObjectiveValue(
                                "Objective 1",
                                "Gain new perspectives"
                              )}
                            </p>
                          )}
                          {welcomeData.objectiveSettings.objective2 && (
                            <p className={classes["right"]}>
                              {getObjectiveValue(
                                "Objective 2",
                                "Enhance your positivity"
                              )}
                            </p>
                          )}
                          {welcomeData.objectiveSettings.objective3 && (
                            <p className={classes["center"]}>
                              {getObjectiveValue(
                                "Objective 3",
                                "Develop your empathy skills"
                              )}
                            </p>
                          )}
                          {welcomeData.objectiveSettings.objective4 && (
                            <p className={classes["centerFill"]}>
                              {getObjectiveValue(
                                "Objective 4",
                                "Enhance your listening and questioning skills"
                              )}
                            </p>
                          )}
                        </>
                      )}

                      <p className={`${classes["prompt"]} pb-20`}>
                        {selectedOrganisation?.welcomeCustomType === "no"
                          ? "Tap anywhere to start"
                          : "Next Page"}
                      </p>
                    </>
                  </div>
                </div>
              )}

              {focusComponent === "SingleLogo" && (
                <div
                  className={`${classes["wrapper"]} relative cursor-pointer`}
                  onClick={(e: any) => backAndNextHandler.nextCallback(e)}
                >
                  <div className={`${classes["container"]}`}>
                    <>
                      {" "}
                      <h5 className="text-default font-semibold my-2 text-2xl text-center flex justify-center items-center w-[360px] mx-auto text-ellipsis overflow-hidden break-words">
                        {welcomeData && welcomeData.title
                          ? welcomeData.title
                          : "Name XXX"}{" "}
                        Growth Circles
                      </h5>
                      <div className="max-w-[280px] max-h-[280px]  flex justify-center items-center my-12">
                        <img
                          src={welcomeData.thumbnailAfter || defaultUpload}
                          alt="thumb_after"
                        />
                      </div>
                    </>
                    <div className="w-full my-2">
                      <p
                        className={`${classes["welcome-big-text"]} text-ellipsis overflow-hidden break-words`}
                      >
                        {welcomeData.descriptionAfter}
                      </p>
                    </div>

                    <p className={`${classes["prompt"]} pb-20 my-8`}>
                      Tap anywhere to start
                    </p>
                  </div>
                </div>
              )}

              {focusComponent === "MultipleLogo" && (
                <div
                  className={`${classes["wrapper"]} relative cursor-pointer`}
                  onClick={(e: any) => backAndNextHandler.nextCallback(e)}
                >
                  <div className={`${classes["container"]}`}>
                    <>
                      {" "}
                      <h5 className="text-default font-semibold my-2 text-2xl text-center flex justify-center items-center w-[360px] mx-auto text-ellipsis overflow-hidden break-words">
                        {welcomeData && welcomeData.title
                          ? welcomeData.title
                          : "Name XXX"}{" "}
                        Growth Circles
                      </h5>
                      <div className="my-8 w-full ">
                        {welcomeData &&
                          welcomeData.logos &&
                          welcomeData.logos.length > 0 &&
                          welcomeData.logos.map((logo, index) => (
                            <div
                              key={index}
                              className="my-2 flex flex-col gap-4 max-h-[280px] max-w-[280px] justify-center items-center mx-auto"
                            >
                              {logo.imageUrl !== "" ? (
                                <img
                                  width={200}
                                  src={logo.imageUrl}
                                  alt="upload_Logo"
                                />
                              ) : (
                                <img
                                  width={200}
                                  src={defaultUpload}
                                  alt="upload_Logo"
                                />
                              )}

                              <p className={`w-full`}>
                                {logo.description !== ""
                                  ? logo.description
                                  : "Description of organisation"}
                              </p>
                              <div className="h-10 border-t w-full"></div>
                            </div>
                          ))}
                      </div>
                    </>

                    <p className={`${classes["prompt"]} pb-20 my-8`}>
                      Tap anywhere to start
                    </p>
                  </div>
                </div>
              )}
            </>
          )}
        </div>
      )}
    </div>
  );
};

export default WelcomeComponent;
