import { Routes, Route, Navigate } from "react-router-dom";

import Home from "./pages/Home/Home";
import LoginLanding from "./pages/Login/LoginLanding";
import Login from "./pages/Login/Login";
import ForgetPassword from "./pages/Login/ForgetPassword";
import Signup from "./pages/Login/Signup";
import LoadingWidget from "./components/utility/LoadingWidget";
import SendEmailTest from "./pages/AllCircles/Admin/SendEmailTest";
import ProfileSettings from "pages/ProfileSettings/ProfileSettings";
import UpdatePassword from "pages/ProfileSettings/UpdatePassword";

import "./App.css";
import { useAuthContext } from "./hooks/useAuthContext";
import Dice from "./pages/AllCircles/Dice/Dice";
import Welcome from "./pages/AllCircles/Welcome";
import ChooseRoles from "./pages/AllCircles/ChooseRoles/ChooseRoles";
import ViewChooseRoles from "pages/AllCircles/ViewChooseRoles/ViewChooseRoles";
import InfoPanel from "./pages/AllCircles/InfoPanel/InfoPanel";
import GroundRules from "pages/AllCircles/GroundRules/GroundRules";
import EnterCode from "./pages/AllCircles/EnterCode/EnterCode";
import WaitingArea from "./pages/AllCircles/WaitingArea/WaitingArea";
import ErrorPage from "pages/Error/ErrorPage";
import IntroRating from "./pages/AllCircles/IntroRating/IntroRating";
import SharingPanel from "./pages/AllCircles/SharingPanel/SharingPanel";
import ChooseTopic from "./pages/AllCircles/ChooseTopic/ChooseTopic";
import ViewChooseTopic from "./pages/AllCircles/ViewChooseTopic/ViewChooseTopic";
import SettingIntentions from "pages/AllCircles/SettingIntentions/SettingIntentions";
import EndOfSession from "pages/AllCircles/EndOfSession/EndOfSession";
import Trigger from "pages/AllCircles/Trigger/Trigger";
import ReflectionMainRating from "pages/AllCircles/ReflectionRating/ReflectionRatingMain";
import ChoosePathway from "pages/AllCircles/ChoosePathway/ChoosePathway";
import QuestionForTopics from "pages/AllCircles/QuestionForTopics/QuestionForTopics";
import { useEffect, useState } from "react";
import NoGCSession from "pages/NoGCSession/NoGCSession";
import Users from "./pages/AllCircles/Admin/Users";
import AdminGrowthCircles from "./pages/AllCircles/Admin/AdminGrowthCircles";
import ShowSingleGC from "./pages/AllCircles/Admin/ShowSingleGC";
import AdminOrganisations from "./pages/AllCircles/Admin/AdminOrganisations";
import AdminTopics from "./pages/AllCircles/Admin/AdminTopics";
import AdminPathways from "./pages/AllCircles/Admin/AdminPathways";
import AdminSkills from "./pages/AllCircles/Admin/AdminSkills";
import RatingAverage from "./pages/AllCircles/RatingAverage/RatingAverage";
import EndRatingAverage from "./pages/AllCircles/EndRatingAverage/EndRatingAverage";
import EndRating from "./pages/AllCircles/EndRating/EndRating";
import PageWrapper from "./components/utility/PageWrapper";
import ResourceRoot from "./pages/Resources/ResourceRoot";
import ViewJournal from "./pages/AllCircles/ViewJournal/ViewJournal";
import Generator from "./pages/AllCircles/Admin/Generator";
import ResourcesLandingPage from "./pages/Resources/ResourceLandingPage";
import Community from "./pages/Community/Community";
import Chats from "pages/Chats/Chats";
import Journal from "pages/Journal/Journal";
import MyCircles from "pages/MyCircles/MyCircles";
import MyRoles from "pages/MyRoles/MyRoles";
import NewShowAllGC from "pages/AllCircles/Admin/NewShowAllGC";
import ChooseActivityPage from "pages/AllCircles/ChooseActivity/ChooseActivityPage";
import ActivityPage from "pages/AllCircles/NationalGalleryPages/ActivityPage";
import ActivityShareReflection from "pages/AllCircles/NationalGalleryPages/ActivityShareReflection";
import CheckOutFeedbackSelf from "pages/AllCircles/CheckOutFeedback/CheckOutFeedbackSelf";
import CheckOutFeedbackFacil from "pages/AllCircles/CheckOutFeedback/CheckOutFeedbackFacil";
import CheckOutFeedbackGroup from "pages/AllCircles/CheckOutFeedback/CheckOutFeedbackGroup";
import WaitingAreaFinal from "pages/AllCircles/WaitingArea/WaitingAreaFinal";
import CompletedFinal from "pages/AllCircles/Completed/CompletedFinal";
import Finished from "pages/AllCircles/Finished/Finished";
import CheckOutAfter from "pages/AllCircles/CheckOutFeedback/CheckOutAfter";
import CheckInCheckOutAverage from "pages/AllCircles/EndRatingAverage/CheckInCheckOutAverage";
import DataDeletion from "pages/DataDeletion/DataDeletion";
import VerifiedPage from "pages/Verification/Verified";
import VerificationForm from "pages/Verification/VerificationForm";
import AllFacil from "pages/AllCircles/Admin/AllFacil";
import JoinSessions from "pages/AllCircles/JoinSessions/JoinSessions";
import ObserverPage from "pages/AllCircles/Observer/ObserverPage";
import CopyUser from "pages/Remedy/CopyUser";
import LoginAs from "pages/Remedy/LoginAs";
import UpdateDisplayNameLowerCase from "pages/UpdateDisplayNameLowerCase";
import GenericSessionPage from "pages/AllCircles/GenericSessionPage/GenericSessionPage";
import useOrganisationContext from "hooks/organisation/useOrganisationContext";
import AdminVideos from "pages/AllCircles/Admin/AdminVideos";
import AdminAddNewVideo from "pages/AllCircles/Admin/AdminAddNewVideo";
import AdminEditVideo from "pages/AllCircles/Admin/AdminEditVideo";
import SignupInvite from "pages/Login/SignupInvite";
import ExportData from "pages/AllCircles/Admin/organisations/ExportData";
import ExportDataAll from "pages/AllCircles/Admin/organisations/ExportDataAll";
import ChangePassword from "pages/ChangePassword/ChangePassword";
import ExportDataCustomFeedback from "pages/AllCircles/Admin/organisations/ExportDataCustomFeedback";
import ExportDataCustomORS from "pages/AllCircles/Admin/organisations/ExportDataCustomORS";
import ExportUsers from "pages/AllCircles/Admin/Export/ExportUsers";
import Dashboard from "pages/AllCircles/Admin/Dashboard";
import MainDashboard from "pages/AllCircles/Admin/MainDashboard";
import GrowthCirclesTypeSettings from "components/Organisation/GrowthCirclesTypeSettings/GrowthCirclesTypeSettings";
import AdminGrowthCircleType from "pages/AllCircles/Admin/AdminGrowthCircleType";
import MySubscriptions from "pages/MySubscriptions/MySubscriptions";
import Staging from "pages/MySubscriptions/Staging";
import SelfDiscovery from "pages/SelfDiscovery/SelfDiscovery";
import SelfDiscoverySinglePage from "pages/SelfDiscovery/SelfDiscoverySinglePage";
import MyGrowth from "pages/MyGrowth/MyGrowth";
import FacilitatorDetails from "pages/AllCircles/Admin/Dashboard/FacilitatorDetails/FacilitatorDetails";
import ErrorOrganisation from "pages/Error/ErrorOrganisation";
import JourneyWithSage from "pages/JourneyWithSage/JourneyWithSage";
import TestPage from "pages/testpage/TestPage";
import SelfDiscoverySettings from "pages/AllCircles/Admin/SelfDiscovery/SelfDiscoverySettings";

export const LocalStorageKeys = {
  //Keys for feedbackform
  REFLECTION: {
    DRAG_RATINGS: "refPersonalDragValues",
    GC_SMILEY_RATINGS: "refGcSmileyRatings",
    OTHER_SMILEY_RATINGS: "refOtherSmileyRatings",
    PERSONAL_SMILEY_RATINGS: "refPersonalSmileyRatings",
    OPEN_ENDED_FIELDS: "refOpenEndedFields",
    OTHER_BADGE_RATINGS: "refBadgeRatings",
  },
  //Keys for IntroRating
  INTRO_RATING: "introRatings",
};

const App = () => {
  const { user, profile, authIsReady } = useAuthContext();
  const [profileFetched, setProfileFetched] = useState(false);
  const { selectedOrganisation } = useOrganisationContext();

  useEffect(() => {
    if (!profile || profileFetched) return;

    setProfileFetched(false);
  }, [profile, profileFetched, selectedOrganisation]);

  return (
    <>
      {!authIsReady && (
        <PageWrapper>
          <LoadingWidget />
        </PageWrapper>
      )}
      {authIsReady && (
        <>
          <Routes>
            <Route
              path="/"
              element={!user ? <Navigate replace to="/login" /> : <Home />}
            />
            <Route
              path="/my-circles"
              element={!user ? <Navigate replace to="/login" /> : <MyCircles />}
            />
            <Route
              path="/chats"
              element={!user ? <Navigate replace to="/login" /> : <Chats />}
            />
            <Route
              path="/community-page"
              element={!user ? <Navigate replace to="/login" /> : <Community />}
            />
            <Route
              path="/my-subscriptions"
              element={
                !user ? <Navigate replace to="/login" /> : <MySubscriptions />
              }
            />
            <Route
              path="/self-awareness"
              element={
                !user ? <Navigate replace to="/login" /> : <SelfDiscovery />
              }
            />
            <Route
              path="/journey-with-sage"
              element={
                !user ? <Navigate replace to="/login" /> : <JourneyWithSage />
              }
            />

            <Route
              path="/self-discovery/:id"
              element={
                !user ? (
                  <Navigate replace to="/login" />
                ) : (
                  <SelfDiscoverySinglePage />
                )
              }
            />

            <Route
              path="/login"
              element={user ? <Navigate replace to="/" /> : <LoginLanding />}
            />
            <Route
              path="/login/*"
              element={user ? <Navigate replace to="/" /> : <Login />}
            />
            <Route
              path="/signup"
              element={user ? <Navigate replace to="/" /> : <Signup />}
            />
            <Route
              path="/resources"
              element={
                !user ? (
                  <Navigate replace to="/login" />
                ) : (
                  <ResourcesLandingPage />
                )
              }
            />

            <Route path="/forget-password" element={<ForgetPassword />} />

            <Route
              path="/journal"
              element={!user ? <Navigate replace to="/login" /> : <Journal />}
            />
            <Route
              path="/my-account"
              element={
                !user ? <Navigate replace to="/login" /> : <ProfileSettings />
              }
            />
            <Route
              path="/my-growth"
              element={!user ? <Navigate replace to="/login" /> : <MyGrowth />}
            />
            <Route
              path="/my-account/update-password"
              element={
                !user ? <Navigate replace to="/login" /> : <UpdatePassword />
              }
            />
            <Route path="/resources" element={<ResourceRoot />} />
            {/* admin dashboard */}

            <Route
              path="/all-circles/admin/dashboard"
              element={
                !user ? <Navigate replace to="/login" /> : <MainDashboard />
              }
            />

            <Route
              path="/all-circles/admin/self-discovery"
              element={
                !user ? (
                  <Navigate replace to="/login" />
                ) : (
                  <SelfDiscoverySettings />
                )
              }
            />

            <Route
              path="/all-circles/admin/dashboard/facilitators"
              element={
                !user ? (
                  <Navigate replace to="/login" />
                ) : (
                  <FacilitatorDetails />
                )
              }
            />

            <Route
              path="/all-circles/admin/dashboard2"
              element={!user ? <Navigate replace to="/login" /> : <Dashboard />}
            />

            <Route
              path="/all-circles/admin/users"
              element={
                !profile?.access ? <Navigate replace to="/login" /> : <Users />
              }
            />
            <Route
              path="/all-circles/admin/growth-circles"
              element={
                !profile?.access ? (
                  <Navigate replace to="/login" />
                ) : (
                  <AdminGrowthCircles />
                )
              }
            />
            <Route
              path="/all-circles/admin/qr-generator"
              element={
                !profile?.access ? (
                  <Navigate replace to="/login" />
                ) : (
                  <Generator />
                )
              }
            />
            <Route
              path="/all-circles/admin/growth-circles/:id"
              element={
                !profile?.access ? (
                  <Navigate replace to="/login" />
                ) : (
                  <ShowSingleGC />
                )
              }
            />

            {/* export data */}
            <Route
              path="/all-circles/admin/all-gc"
              element={
                !profile?.access ? (
                  <Navigate replace to="/login" />
                ) : (
                  <NewShowAllGC />
                )
              }
            />

            <Route
              path="/all-circles/admin/org-export"
              element={
                !profile?.access ? (
                  <Navigate replace to="/login" />
                ) : (
                  <ExportData />
                )
              }
            />

            <Route
              path="/all-circles/admin/export-users"
              element={
                !profile?.access ? (
                  <Navigate replace to="/login" />
                ) : (
                  <ExportUsers />
                )
              }
            />

            <Route
              path="/all-circles/admin/org-export-ors"
              element={
                !profile?.access ? (
                  <Navigate replace to="/login" />
                ) : (
                  <ExportDataCustomORS />
                )
              }
            />

            <Route
              path="/all-circles/admin/org-custom-export"
              element={
                !profile?.access ? (
                  <Navigate replace to="/login" />
                ) : (
                  <ExportDataCustomFeedback />
                )
              }
            />

            <Route
              path="/all-circles/admin/org-export-all"
              element={
                !profile?.access ? (
                  <Navigate replace to="/login" />
                ) : (
                  <ExportDataAll />
                )
              }
            />

            <Route
              path="/all-circles/admin/all-facilitators"
              element={
                !profile?.access ? (
                  <Navigate replace to="/login" />
                ) : (
                  <AllFacil />
                )
              }
            />

            <Route
              path="/all-circles/admin/manage-videos"
              element={
                !profile?.access ? (
                  <Navigate replace to="/login" />
                ) : (
                  <AdminVideos />
                )
              }
            />

            <Route
              path="/all-circles/admin/growthcircles-type"
              element={
                !profile?.access ? (
                  <Navigate replace to="/login" />
                ) : (
                  <GrowthCirclesTypeSettings />
                )
              }
            />

            <Route
              path="/all-circles/admin/growthcircles-type/:id"
              element={
                !profile?.access ? (
                  <Navigate replace to="/login" />
                ) : (
                  <AdminGrowthCircleType />
                )
              }
            />

            <Route
              path="/all-circles/admin/add-new-video"
              element={
                !profile?.access ? (
                  <Navigate replace to="/login" />
                ) : (
                  <AdminAddNewVideo />
                )
              }
            />

            <Route
              path="/all-circles/admin/edit-video/:id"
              element={
                !profile?.access ? (
                  <Navigate replace to="/login" />
                ) : (
                  <AdminEditVideo />
                )
              }
            />

            {/* end of export data */}

            <Route
              path="/all-circles/admin/manage-organisations"
              element={<AdminOrganisations />}
            />
            <Route
              path="/all-circles/admin/manage-topics"
              element={
                !profile?.access ? (
                  <Navigate replace to="/login" />
                ) : (
                  <AdminTopics />
                )
              }
            />
            <Route
              path="/all-circles/admin/manage-pathways"
              element={
                !profile?.access ? (
                  <Navigate replace to="/login" />
                ) : (
                  <AdminPathways />
                )
              }
            />
            <Route
              path="/all-circles/admin/manage-skills"
              element={
                !profile?.access ? (
                  <Navigate replace to="/login" />
                ) : (
                  <AdminSkills />
                )
              }
            />
            <Route
              path="/all-circles/admin/skills"
              element={
                !profile?.access ? (
                  <Navigate replace to="/login" />
                ) : (
                  <AdminSkills />
                )
              }
            />

            <Route
              path="/organisation/invite/:id"
              element={!user ? <SignupInvite /> : <Navigate replace to="/" />}
            />

            <Route
              path="/organisation/invite/:group/:id"
              element={!user ? <SignupInvite /> : <Navigate replace to="/" />}
            />
            {/* end of dashboard */}

            <Route path="/all-circles/welcome/:id" element={<Welcome />} />

            <Route path="/all-circles/staging/:id" element={<Staging />} />
            <Route
              path="/all-circles/info-panel/:id"
              element={
                !user ? <InfoPanel /> : <Navigate replace to="/growthcircle" />
              }
            />
            <Route path="/all-circles/ground-rules" element={<GroundRules />} />
            <Route path="/all-circles/enter-code" element={<EnterCode />} />
            <Route path="/all-circles/waiting-area" element={<WaitingArea />} />
            <Route
              path="/all-circles/dice"
              element={!user ? <Navigate replace to="/" /> : <Dice />}
            />
            <Route path="/all-circles/intro-rating" element={<IntroRating />} />
            <Route
              path="/all-circles/setting-intentions"
              element={<SettingIntentions />}
            />
            <Route path="/all-circles/choose-roles" element={<ChooseRoles />} />
            <Route
              path="/all-circles/view-choose-roles"
              element={<ViewChooseRoles />}
            />
            <Route path="/all-circles/choose-topic" element={<ChooseTopic />} />
            <Route
              path="/all-circles/view-choose-topic"
              element={<ViewChooseTopic />}
            />
            <Route
              path="/all-circles/choose-pathway"
              element={<ChoosePathway />}
            />
            <Route
              path="/all-circles/sharing-panel"
              element={<SharingPanel />}
            />
            <Route
              path="/all-circles/questions-for-topics"
              element={<QuestionForTopics />}
            />
            <Route
              path="/all-circles/reflection-rating"
              element={user ? <ReflectionMainRating /> : <Login />}
            />
            <Route
              path="/all-circles/end-rating-average"
              element={user ? <EndRatingAverage /> : <Login />}
            />
            {/* <Route path="/all-circles/feedback-form" element={user ? <FeedBackForm /> : <Login/>} /> */}

            <Route
              path="/all-circles/trigger"
              element={user ? <Trigger /> : <Login />}
            />

            <Route path="session-does-not-exist" element={<NoGCSession />} />
            <Route
              path="/all-circles/end-of-session"
              element={<EndOfSession />}
            />
            <Route
              path="/all-circles/rating-average"
              element={<RatingAverage />}
            />
            <Route path="/all-circles/end-rating" element={<EndRating />} />
            <Route
              path="/all-circles/view-journal/:participantId"
              element={<ViewJournal />}
            />
            <Route
              path="/all-circles/activity-share-reflection"
              element={<ActivityShareReflection />}
            />

            {/* NG Routes */}

            <Route
              path="/all-circles/choose-activity"
              element={<ChooseActivityPage type="NG" />}
            />

            <Route path="/all-circles/activity" element={<ActivityPage />} />

            <Route
              path="/all-circles/check-out-feedback-for-self"
              element={<CheckOutFeedbackSelf />}
            />

            <Route
              path="/all-circles/check-out-feedback-for-facil"
              element={<CheckOutFeedbackFacil />}
            />

            <Route
              path="/all-circles/check-out-feedback-for-group"
              element={<CheckOutFeedbackGroup />}
            />

            <Route
              path="/all-circles/waiting-area-final"
              element={<WaitingAreaFinal />}
            />

            <Route path="/all-circles/completed" element={<CompletedFinal />} />

            <Route path="/all-circles/finished" element={<Finished />} />

            <Route
              path="/all-circles/check-in-after"
              element={<CheckOutAfter />}
            />

            <Route
              path="/all-circles/check-in-check-out-average"
              element={<CheckInCheckOutAverage />}
            />

            {/* END of NG Routes */}

            {/* SUSS Routes */}

            <Route
              path="/all-circles/join-session"
              element={<JoinSessions />}
            />
            <Route
              path="/all-circles/join-session-observer/:id"
              element={<ObserverPage />}
            />
            <Route
              path="/all-circles/choose-activity-suss"
              element={<ChooseActivityPage type="SUSS" />}
            />

            {/* END of SUSS Routes */}

            {/* temp */}
            <Route path="/admin/copy-user" element={<CopyUser />} />
            <Route path="/admin/login-as" element={<LoginAs />} />
            {/* end of temp */}

            <Route path="/my-roles" element={user ? <MyRoles /> : <Login />} />
            <Route
              path="/account/email-verification-form"
              element={user ? <VerificationForm /> : <Login />}
            />

            <Route
              path="/account/change-password"
              element={user ? <ChangePassword /> : <Login />}
            />

            <Route
              path="/account/email-verification/:id"
              element={<VerifiedPage />}
            />
            <Route
              path="/organisation-invite-error"
              element={<ErrorOrganisation />}
            />

            <Route path="/all-circles/email-test" element={<SendEmailTest />} />
            <Route path="/data-deletion/:id" element={<DataDeletion />} />
            <Route path="/all-circles/email-test" element={<SendEmailTest />} />
            <Route path="/test-page" element={<TestPage />} />
            <Route path="/*" element={<ErrorPage />} />

            <Route path="/growthcircle" element={<GenericSessionPage />} />

            {/* TODO Delete after using, page to add displayNameLowerCase to all users */}
            <Route
              path="/update-display-name-lower-case"
              element={<UpdateDisplayNameLowerCase />}
            />
          </Routes>
        </>
      )}
    </>
  );
};

export default App;
