import { createPortal } from "react-dom";

import classes from "./Backdrop.module.css";

interface Props {
  onClick: () => any,
}

const BackdropOverlay: React.FC<Props> = ({onClick}) => {
  return <div className={classes.backdrop} onClick={onClick} />;
};

const Backdrop: React.FC<Props> = ({onClick}) => {
  //Modified to allow backdrop div to be of any id

  return (
    <>
      {createPortal(
        <BackdropOverlay onClick={onClick} />,
        document.getElementById("backdrop-root") as HTMLElement
      )}
    </>
  );

};
export default Backdrop;
