import Header from "components/Header/Header";
import PageWrapper from "components/utility/PageWrapper";
import { useAuthContext } from "hooks/useAuthContext";
import { useEmailAPI } from "hooks/useEmailAPI";
import { useEffect, useState } from "react";
import { Toaster, toast } from "react-hot-toast";
import { Link } from "react-router-dom";

const VerificationForm = () => {
  const { sendEMailVerification } = useEmailAPI();
  const { profile } = useAuthContext();
  const [isLoading, setLoading] = useState(false);

  useEffect(() => {
    if (!profile) return;
  }, [profile]);

  const verifyHandler = async () => {
    setLoading(true);
    if (profile) {
      const response = await sendEMailVerification(
        profile?.email,
        profile?.displayName,
        profile?.uid
      );

      if (response.message === "Email Sent") {
        setLoading(false);
        toast.success("Email was sent successfully!");
      } else {
        setLoading(false);
        toast.error("Failed sending email.");
      }
    }
  };

  return (
    <PageWrapper pageType={"groundRules"}>
      <Header />
      <Toaster
        position="top-center"
        containerStyle={{
          top: "15%",
        }}
        toastOptions={{ duration: 4000 }}
      />
      <div className="bg-white shadow-md mt-20 md:mt-44 w-full rounded-md max-w-3xl p-10 mx-auto px-2 sm:px-6 lg:px-8 mb-10 flex justify-center items-center flex-col gap-4">
        <p className="text-slate-500 text-center">
          Before submitting, please ensure you have reviewed your email. Once
          submitted, check your inbox for an email containing account
          verification instructions. If you do not receive an email, be sure to
          examine your spam folder.
        </p>
        <input
          type="email"
          defaultValue={profile?.email}
          placeholder="your email here.."
          className="border border-slate-500/[0.2] text-slate-400 rounded-full  "
        />

        <button
          disabled={isLoading}
          className="bg-green-500 text-green-200 rounded-3xl px-8 py-2 shadow-lg border-4 border-green-800 min-w-[200px] text-center"
          onClick={verifyHandler}
        >
          {isLoading ? "Please wait.." : "Verify"}
        </button>
        <Link
          to={"/"}
          className="bg-red-500 text-red-200 rounded-3xl px-8 py-2 shadow-lg border-4 border-red-800 min-w-[200px] text-center"
        >
          Cancel
        </Link>
      </div>
    </PageWrapper>
  );
};

export default VerificationForm;
