import Im_Feeling from "assets/SelfAwareness/Im_Feeling.png";
import I_Can_Do_It from "assets/SelfAwareness/I_Can_Do_It.png";
import Help_I_Need from "assets/SelfAwareness/Help_I_Need.png";
import Whats_The_Issue from "assets/SelfAwareness/Whats_The_Issue.png";
import Take_Action from "assets/SelfAwareness/Take_Action.png";
import Now_Thats_New from "assets/SelfAwareness/Now_Thats_New.png";
import Self_Appraisal from "assets/SelfAwareness/Self_Appraisal.png";
import Lets_Appreciate from "assets/SelfAwareness/Lets_Appreciate.png";
import Challenge_Me from "assets/SelfAwareness/Challenge_Me.png";
import Embrace_Differences from "assets/SelfAwareness/Embrace_Differences.png";
import How_To_Choose from "assets/SelfAwareness/How_To_Choose.png";

export const SelfAwarenessImageMap = {
  Im_Feeling,
  I_Can_Do_It,
  Help_I_Need,
  Whats_The_Issue,
  Take_Action,
  Now_Thats_New,
  Self_Appraisal,
  Lets_Appreciate,
  Challenge_Me,
  Embrace_Differences,
  How_To_Choose,
};
