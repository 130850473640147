import Header from "components/Header/Header";
import ContentWrapper from "components/utility/ContentWrapper";
import PageWrapper from "components/utility/PageWrapper";
import { ReactComponent as BottomRightRainCloud } from "assets/RainClouds/BottomRightRainCloud.svg";
import { ReactComponent as BottomLeftRainCloud } from "assets/RainClouds/BottomLeftRainCloud.svg";
import { ReactComponent as MiddleRainCloud } from "assets/RainClouds/MiddleRainCloud.svg";
import { ReactComponent as TopLeftRainCloud } from "assets/RainClouds/TopLeftRainCloud.svg";
import { ReactComponent as TopRightRainCloud } from "assets/RainClouds/TopRightRainCloud.svg";
import classes from "./ErrorPage.module.css";

const ErrorPage = () => {
  const wrapperStyle = {
    paddingTop: "10vh",
    // maxWidth: "450px",
    // height: "auto",
    // minHeight: "auto",
    // maxHeight: "none",
    overflow: "initial",
    display: "flex",
    flexDirection: "column",
    alignItems: "center",
    position: "relative",
  };

  return (
    <PageWrapper>
      <Header />
      <ContentWrapper style={wrapperStyle}>
        <div className={classes["clouds"]}>
          <BottomRightRainCloud className={classes["brcloud"]} />
          <BottomLeftRainCloud className={classes["blcloud"]} />
          <MiddleRainCloud className={classes["mcloud"]} />
          <TopLeftRainCloud className={classes["tlcloud"]} />
          <TopRightRainCloud className={classes["trcloud"]} />
        </div>
        <div className={classes["main"]}>
          <div className={classes["title"]}>404</div>
          <div className={classes["subtitle"]}>Page not found</div>

          <div className={classes["description"]}>
            The page you are looking for does not exist.
          </div>
        </div>
      </ContentWrapper>
    </PageWrapper>
  );
};

export default ErrorPage;
