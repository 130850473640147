import {
  arrayRemove,
  arrayUnion,
  FieldValue,
  QueryConstraint,
  UpdateData,
  where,
  WithFieldValue,
} from "firebase/firestore";
import { Profile } from "interface/ProfileInterface";
import getModelOperations, { WithId } from "utility/model";
import { FIRESTORE_PATH_ORGANISATIONS } from "./organisation";
import { getUserByEmail } from "./profile";
import { Group } from "interface/GroupInterface";

const FIRESTORE_SUBPATH_GROUPS = "groups";

const ERR_GROUP_ALREADY_EXISTS =
  "A group with the same group name already exists";

export const defaultGroupModel: Group = {
  groupName: "Main Group",
  subGroupName: "Main Sub-group",
  organisationId: "",
  invites: [],
  users: [],
  groupLeaders: [],
};

// --- Helper Functions ---

const ops = getModelOperations(defaultGroupModel);

async function _addGroup(
  organisationId: string,
  newGroup: WithFieldValue<Group>
) {
  const existingGroup = await getGroupByGroupName(
    organisationId,
    newGroup.groupName,
    newGroup.subGroupName
  );
  if (existingGroup) {
    throw new Error(ERR_GROUP_ALREADY_EXISTS);
  }
  const path = `${FIRESTORE_PATH_ORGANISATIONS}/${organisationId}/${FIRESTORE_SUBPATH_GROUPS}`;
  return ops.addModel(path, newGroup);
}

/*
function _setGroup(
  organisationId: string,
  groupId: string,
  newGroup: WithFieldValue<Group>,
  setOptions?: SetOptions
) {
  const path = `${FIRESTORE_PATH_ORGANISATIONS}/${organisationId}/${FIRESTORE_SUBPATH_GROUPS}/${groupId}`;
  return ops.setModel(path, newGroup, setOptions);
}
*/

function _getGroup(organisationId: string, groupId: string) {
  const path = `${FIRESTORE_PATH_ORGANISATIONS}/${organisationId}/${FIRESTORE_SUBPATH_GROUPS}/${groupId}`;
  return ops.getModel(path);
}

function _getGroupWhere(
  organisationId: string,
  ...queryConstraints: QueryConstraint[]
) {
  const path = `${FIRESTORE_PATH_ORGANISATIONS}/${organisationId}/${FIRESTORE_SUBPATH_GROUPS}`;
  return ops.getModelWhere(path, ...queryConstraints);
}

function _getGroups(
  organisationId: string,
  ...queryConstraints: QueryConstraint[]
) {
  const path = `${FIRESTORE_PATH_ORGANISATIONS}/${organisationId}/${FIRESTORE_SUBPATH_GROUPS}`;
  return ops.getModels(path, ...queryConstraints);
}

function _updateGroup(
  organisationId: string,
  groupId: string,
  groupUpdates: UpdateData<Group>
) {
  const path = `${FIRESTORE_PATH_ORGANISATIONS}/${organisationId}/${FIRESTORE_SUBPATH_GROUPS}/${groupId}`;
  return ops.updateModel(path, groupUpdates);
}

function _deleteGroup(organisationId: string, groupId: string) {
  const path = `${FIRESTORE_PATH_ORGANISATIONS}/${organisationId}/${FIRESTORE_SUBPATH_GROUPS}/${groupId}`;
  return ops.deleteModel(path);
}

/*
  subscribeModel: (docId: string, onStoreChange: OnStoreChange<WithId<T> | undefined>) => Unsubscribe,
  subscribeModels: (
    onStoreChange: OnStoreChange<WithId<T>[]>,
    ...queryConstraints: QueryConstraint[]
  ) => Unsubscribe
*/

// --- End Helper functions ---

export const addGroup = _addGroup;

export const getGroupByGroupId = _getGroup;

export function getGroupByGroupName(
  organisationId: string,
  groupName: string | FieldValue,
  subGroupName: string | FieldValue
) {
  return _getGroupWhere(
    organisationId,
    where("groupName", "==", groupName),
    where("subGroupName", "==", subGroupName)
  );
}

export function getGroups(organisationId: string) {
  return _getGroups(
    organisationId,
    where("organisationId", "==", organisationId)
  );
}

export async function RemoveUserFromGroup(
  organisationId: string,
  group: WithId<Group>,
  userId: string
) {
  if (!group.users.includes(userId)) {
    return;
  }

  const updatedUsers = group.users.filter((user_id) => user_id !== userId);

  return _updateGroup(organisationId, group.id, {
    users: updatedUsers,
  });
}

export async function joinGroup(
  organisationId: string,
  group: WithId<Group>,
  user: Profile
) {
  if (group.users.includes(user.id)) {
    return;
  }
  return _updateGroup(organisationId, group.id, {
    users: arrayUnion(user.id),
  });
}

export function inviteUser(
  organisationId: string,
  group: WithId<Group>,
  email: string
) {
  return getUserByEmail(email).then((profile) => {
    if (profile?.uid) {
      return joinGroup(organisationId, group, profile);
    } else {
      return _updateGroup(organisationId, group.id, {
        invites: arrayUnion(email),
      });
    }
  });
}

export function unInviteUser(
  organisationId: string,
  groupId: string,
  email: string
) {
  return _updateGroup(organisationId, groupId, { invites: arrayRemove(email) });
}

export async function acceptInvitation(user: Profile, organisationId: string) {
  const email = user.email;
  if (!email) {
    return;
  }
  const groups = await _getGroups(
    organisationId,
    where("invites", "array-contains", email)
  );
  return groups.forEach((group) => {
    return Promise.all([
      joinGroup(organisationId, group, user),
      unInviteUser(organisationId, group.id, email),
    ]);
  });
}

export function updateGroupName(
  organisationId: string,
  groupId: string,
  newGroupName: string,
  newSubGroupName: string
) {
  return _updateGroup(organisationId, groupId, {
    groupName: newGroupName,
    subGroupName: newSubGroupName,
  });
}

export const deleteGroup = _deleteGroup;
