import classes from "./OthersIntention.module.css";
import EntryCards from "components/SettingIntentions/EntryCards";
import EmptySpace from "components/utility/EmptySpace";
import useGetParticipants from "hooks/useGetParticipants";
import { OTHERS_INTENTIONS_TITLE } from "strings/SettingIntentions";

interface OthersIntentionProps {
  randomize?: boolean;
}

const OthersIntention = ({ randomize }: OthersIntentionProps) => {
  const { participants } = useGetParticipants();

  return (
    <div className={classes["other-intention-section"]}>
      {!randomize && (
        <div className={classes["other-intention-title"]}>
          {OTHERS_INTENTIONS_TITLE}
        </div>
      )}
      {randomize && (
        <div className={`${classes["other-intention-title"]} text-lg`}>
          Other's Responses
        </div>
      )}
      <EmptySpace />
      {participants.map((p) => {
        return (
          <div key={p.userId} className={classes["other-intention-entry"]}>
            <EntryCards
              randomize={randomize}
              userName={p.userName}
              intention={p.intentions}
              intentionQuestion={p.intentionQuestion}
            />
          </div>
        );
      })}
    </div>
  );
};

export default OthersIntention;
