import { QuestionDomain } from "components/Organisation/QuestionSettings/AddQuestionToDomainModal";
import {
  DEFAULT_CATEGORIES,
  QuestionType,
} from "components/Organisation/QuestionSettings/QuestionSettings";
import InputField from "components/utility/Forms/InputField";
import { DocumentData } from "firebase/firestore";
import { Modal } from "flowbite-react";
import useOrganisationContext from "hooks/organisation/useOrganisationContext";
import { useAuthContext } from "hooks/useAuthContext";
import { useOrganisationQuestion } from "hooks/useOrganisationQuestion";
import usePromise from "hooks/utility/usePromise";
import PublicQuestion from "interface/PublicQuestionInterface";
import { QuestionInterface } from "interface/QuestionsInterface";
import SuperAdminQuestion from "interface/SuperAdminQuestionInterface";
import { ChangeEvent, useCallback, useEffect, useState } from "react";
import toast from "react-hot-toast";
import { AiFillCloseCircle } from "react-icons/ai";

interface Props {
  questions: QuestionInterface[];
  publicQuestions: PublicQuestion[];
  superAdminQuestions: SuperAdminQuestion[];
  topics: DocumentData[];
  questionType: QuestionType;
  deletePublicQuestions: (publicQuestionIds: string[]) => Promise<void>;
  deleteSuperAdminQuestions: (superAdminQuestionIds: string[]) => Promise<void>;
}

enum DomainQuestionAction {
  COPY = "copy",
  DELETE = "delete",
}

/**
 * Modal to copy or delete questions from the public or super admin domain.
 *
 * @param Props - The props of the component.
 * @param Props.topics - The topics available in the database.
 * @param Props.questions - The questions available in the database.
 * @param Props.questionType - The type of question to be added.
 * @param Props.publicQuestions - The public questions available in the database.
 * @param Props.superAdminQuestions - The super admin questions available in the database.
 * @param Props.deletePublicQuestions - The function to delete public questions.
 * @param Props.deleteSuperAdminQuestions - The function to delete super admin questions.
 * @returns The DomainQuestionsModal component.
 */
const DomainQuestionsModal = ({
  topics,
  questions,
  questionType,
  publicQuestions,
  superAdminQuestions,
  deletePublicQuestions,
  deleteSuperAdminQuestions,
}: Props) => {
  const { isLoading, resolve } = usePromise();
  const { profile } = useAuthContext();
  const { selectedOrganisation, organisations } = useOrganisationContext();
  const { copyNewQuestions } = useOrganisationQuestion();

  const isSuperAdmin = profile?.access && profile.access === "admin";
  const isSpecificQuestionType = questionType === QuestionType.SPECIFIC;

  const topicOptions = topics
    .flatMap((topic) => topic.general)
    .sort((a, b) => a.localeCompare(b));
  const categoryOptions = topics
    .map((topic) => topic.category)
    .sort((a, b) => a.localeCompare(b));
  const domainOptions = isSuperAdmin
    ? [QuestionDomain.PUBLIC, QuestionDomain.SUPER_ADMIN]
    : [QuestionDomain.PUBLIC];
  const actionOptions = isSuperAdmin
    ? [DomainQuestionAction.COPY, DomainQuestionAction.DELETE]
    : [DomainQuestionAction.COPY];

  const [visible, setVisible] = useState(false);
  const [domain, setDomain] = useState(QuestionDomain.PUBLIC);
  const [topic, setTopic] = useState(topicOptions[0] || "");
  const [category, setCategory] = useState(
    isSpecificQuestionType ? categoryOptions[0] : DEFAULT_CATEGORIES[0]
  );
  const [topicCategoryID, setTopicCategoryID] = useState(
    topics.find((topic) => topic.general.includes(topic))?.id || ""
  );
  const [selectedQuestions, setSelectedQuestions] = useState<{
    [key: string]: boolean;
  }>({});
  const [selectAll, setSelectAll] = useState(false);
  const [action, setAction] = useState(DomainQuestionAction.COPY);
  const [isCategoryQuestion, setIsCategoryQuestion] = useState(false);
  const [confirmation, setConfirmation] = useState(false);

  const getDomainQuestions = useCallback((): PublicQuestion[] => {
    if (action === DomainQuestionAction.DELETE) {
      return domain === QuestionDomain.PUBLIC
        ? publicQuestions
        : superAdminQuestions.map((question) => ({
            ...question,
            organisationFromId: "",
          }));
    }

    const questionsLower = questions.map((question) =>
      question.question.toLocaleLowerCase()
    );

    if (domain === QuestionDomain.PUBLIC) {
      return publicQuestions.filter(
        (question) =>
          !questionsLower.includes(question.question.toLocaleLowerCase())
      );
    }

    if (domain === QuestionDomain.SUPER_ADMIN) {
      return superAdminQuestions
        .filter(
          (question) =>
            !questionsLower.includes(question.question.toLocaleLowerCase())
        )
        .map((question) => ({
          ...question,
          organisationFromId: "",
        }));
    }

    // currently organisation domain will always be empty because all questions are duplicates
    return [];
  }, [action, domain, publicQuestions, superAdminQuestions, questions]);

  const hasSelectedQuestions = Object.values(selectedQuestions).includes(true);

  const resetSelectedQuestionsState = useCallback(() => {
    setSelectedQuestions({});
    setSelectAll(false);
    setConfirmation(false);
  }, []);

  const handleSelectQuestion = useCallback(
    (e: ChangeEvent<HTMLInputElement>) => {
      setConfirmation(false);
      // uncheck select all if any question is unchecked
      if (selectAll && !e.target.checked) {
        setSelectAll(false);
      }

      setSelectedQuestions((prev) => {
        const newSelectedQuestions = {
          ...prev,
          [e.target.value]: e.target.checked,
        };

        return newSelectedQuestions;
      });
    },
    [selectAll]
  );

  const copyQuestionsFromDomain = useCallback(
    async (questionsToCopy: string[]) => {
      if (!selectedOrganisation) {
        return;
      }

      try {
        // use category state if it is a category question or if the question type is random
        // else search for the actual category with the topic name
        const questionCategory =
          isSpecificQuestionType && !isCategoryQuestion
            ? topics.find((topic) => topic.general.includes(topic))?.category ||
              ""
            : category;

        // The topic is the category for random question type and null for category questions
        const questionTopic = isSpecificQuestionType
          ? isCategoryQuestion
            ? null
            : topic
          : category;

        await copyNewQuestions(
          questionsToCopy,
          questionTopic,
          topicCategoryID,
          questionCategory,
          selectedOrganisation.id
        );

        toast.success("Questions copied");
        setVisible(false);
        resetSelectedQuestionsState();
        setIsCategoryQuestion(false);
        setDomain(QuestionDomain.PUBLIC);
      } catch (error) {
        toast.error("An error occurred while copying questions");
        console.error(error);
      }
    },
    [
      isCategoryQuestion,
      category,
      isSpecificQuestionType,
      resetSelectedQuestionsState,
      selectedOrganisation,
      topic,
      topicCategoryID,
      topics,
      copyNewQuestions,
    ]
  );

  const deleteQuestionsFromDomain = useCallback(
    async (questionsToDelete: string[]) => {
      try {
        if (domain === QuestionDomain.PUBLIC) {
          await deletePublicQuestions(
            questionsToDelete.map(
              (question) =>
                publicQuestions.find((q) => q.question === question)?.id || ""
            )
          );
        }

        if (domain === QuestionDomain.SUPER_ADMIN) {
          await deleteSuperAdminQuestions(
            questionsToDelete.map(
              (question) =>
                superAdminQuestions.find((q) => q.question === question)?.id ||
                ""
            )
          );
        }

        toast.success("Questions deleted");
        setVisible(false);
        resetSelectedQuestionsState();
        setDomain(QuestionDomain.PUBLIC);
      } catch (error) {
        toast.error("An error occurred while deleting questions");
        console.error(error);
      }
    },
    [
      domain,
      publicQuestions,
      superAdminQuestions,
      deletePublicQuestions,
      deleteSuperAdminQuestions,
      resetSelectedQuestionsState,
    ]
  );

  const handleCloseModal = useCallback(() => {
    setVisible(false);
    resetSelectedQuestionsState();
    setDomain(QuestionDomain.PUBLIC);
    setIsCategoryQuestion(false);
    setAction(DomainQuestionAction.COPY);
  }, [resetSelectedQuestionsState]);

  const handleSelectDomain = useCallback(
    (e: ChangeEvent<HTMLSelectElement>) => {
      setDomain(e.target.value as QuestionDomain);
      setIsCategoryQuestion(false);
      resetSelectedQuestionsState();
    },
    [resetSelectedQuestionsState]
  );

  const handleSelectAction = useCallback(
    (e: ChangeEvent<HTMLSelectElement>) => {
      setAction(e.target.value as DomainQuestionAction);
      setIsCategoryQuestion(false);
      resetSelectedQuestionsState();
    },
    [resetSelectedQuestionsState]
  );

  const handleToggleCategoryQuestion = useCallback(() => {
    setIsCategoryQuestion((prev) => {
      if (prev) {
        setTopic(topicOptions[0]);
      } else {
        setCategory(categoryOptions[0]);
        setTopicCategoryID(
          topics.find((topic) => topic.category === categoryOptions[0])?.id ||
            ""
        );
      }
      return !prev;
    });
  }, [categoryOptions, topicOptions, topics]);

  const handleTopicOrCategoryChange = useCallback(
    // topic name can refer to a category name
    (topicName: string) => {
      if (!selectedOrganisation) {
        return;
      }

      setConfirmation(false);

      // random  question type
      if (!isSpecificQuestionType) {
        setTopicCategoryID("");
        setCategory(topicName);
        return;
      }

      // category question
      if (isCategoryQuestion) {
        const selectedCategory = topics.find(
          (topic) => topic.category === topicName
        );

        if (selectedCategory) {
          setTopicCategoryID(selectedCategory.id);
          setCategory(topicName);
        }

        return;
      }

      // Find the topic category that contains the desired topicName
      const selectedTopic = topics.find((topic) =>
        topic.general.includes(topicName)
      );

      // Check if the topic was found
      if (selectedTopic) {
        // Now you can use 'selectedTopic' and 'topicName' as needed
        setTopicCategoryID(selectedTopic.id);
        setTopic(topicName);
      }
    },
    [selectedOrganisation, topics, isCategoryQuestion, isSpecificQuestionType]
  );

  const handleToggleSelectAll = () => {
    setSelectAll((prev) => {
      const newValue = !prev;
      setSelectedQuestions(
        getDomainQuestions().reduce((acc, question) => {
          acc[question.question] = newValue;
          return acc;
        }, {})
      );

      return newValue;
    });
  };

  const handlePerformAction = useCallback(() => {
    const questionsToActOn = Object.keys(selectedQuestions).filter(
      (question) => selectedQuestions[question]
    );

    resolve(async () => {
      if (action === DomainQuestionAction.COPY) {
        await copyQuestionsFromDomain(questionsToActOn);
      }

      if (action === DomainQuestionAction.DELETE) {
        await deleteQuestionsFromDomain(questionsToActOn);
      }
    });
  }, [
    action,
    copyQuestionsFromDomain,
    deleteQuestionsFromDomain,
    resolve,
    selectedQuestions,
  ]);

  const domainQuestions = getDomainQuestions();

  useEffect(() => {
    setTopic(topicOptions[0] || "");
  }, [topicOptions]);

  return (
    <>
      <Modal show={visible} size="4xl">
        <Modal.Body
          className="relative min-h-[80vh] border-2 rounded-lg bg-default"
          style={{ borderColor: "var(--icon-colour-0)" }}
        >
          <AiFillCloseCircle
            size={30}
            style={{ color: "var(--icon-colour-0)" }}
            onClick={handleCloseModal}
            className="absolute top-2 right-2 cursor-pointer"
          />
          <div className="flex flex-col gap-2 justify-center">
            <p className="text-2xl">
              <strong>{`Domain questions`}</strong>
            </p>
            <p>
              Select a question domain and the action to perform.
              <br />
              <strong>copy</strong> action will copy the selected questions to
              the selected topic or category. Only non duplicate questions will
              be available for copy.
              {profile?.access && profile.access === "admin" && (
                <>
                  <br />
                  <strong>delete</strong> action will delete the selected
                  questions from the domain.
                </>
              )}
            </p>
            <div>
              <p>Select a question domain.</p>
              <InputField
                htmlFor="domain"
                type="select"
                onChange={handleSelectDomain}
                options={domainOptions}
                value={domain}
                isDisabled={isLoading}
              />
            </div>
            <div>
              <p>Select the action to perform.</p>
              <InputField
                htmlFor="action"
                type="select"
                onChange={handleSelectAction}
                options={actionOptions}
                value={action}
                isDisabled={isLoading}
              />
            </div>
            {action === DomainQuestionAction.COPY && (
              <div>
                {isSpecificQuestionType && (
                  <div className="pb-2 mt-2">
                    <label className="cursor-pointer my-2">
                      <input
                        type="checkbox"
                        checked={isCategoryQuestion}
                        onChange={handleToggleCategoryQuestion}
                        className="mr-4"
                        style={{
                          height: "20px",
                          width: "20px",
                          borderRadius: "5px",
                          backgroundColor: isCategoryQuestion
                            ? "var(--icon-colour-0)"
                            : "",
                        }}
                      />
                      Copy as category questions
                    </label>
                    <p className="mt-2">
                      Choose a {isCategoryQuestion ? "category" : "topic"} to
                      copy the questions to.
                    </p>
                    {isCategoryQuestion ? (
                      <InputField
                        required={true}
                        name={"selected category"}
                        type="select"
                        htmlFor={"category"}
                        onChange={(e: ChangeEvent<HTMLSelectElement>) => {
                          handleTopicOrCategoryChange(e.target.value);
                        }}
                        options={categoryOptions}
                        value={category}
                        disabled={isLoading}
                      />
                    ) : (
                      <InputField
                        required={true}
                        name={"selected topics"}
                        type="select"
                        htmlFor={"topics"}
                        onChange={(e: ChangeEvent<HTMLSelectElement>) =>
                          handleTopicOrCategoryChange(e.target.value)
                        }
                        options={topicOptions}
                        value={topic}
                        disabled={isLoading}
                      />
                    )}
                  </div>
                )}

                {!isSpecificQuestionType && (
                  <div className="py-2 mb-4">
                    <p>Choose a category to copy the questions to.</p>
                    <InputField
                      required={true}
                      name={"selected category"}
                      type="select"
                      htmlFor={"category"}
                      onChange={(e: ChangeEvent<HTMLSelectElement>) => {
                        handleTopicOrCategoryChange(e.target.value);
                      }}
                      options={DEFAULT_CATEGORIES}
                      value={category}
                      disabled={isLoading}
                    />
                    {category === "compulsory" && (
                      <p className="pt-4 pl-4 text-sky-600">
                        Compulsory questions are not randomized and will appear
                        underneath personal and general questions.
                      </p>
                    )}
                  </div>
                )}
              </div>
            )}
            <div className="max-h-80 overflow-auto mt-2">
              {domainQuestions.length > 0 ? (
                <table className="w-full text-sm text-left rtl:text-right">
                  <thead className="text-xs border uppercase text-standard">
                    <tr className="bg-white">
                      <th key="role" scope="col" className="py-3 border">
                        <div className="flex justify-center items-center">
                          <input
                            type="checkbox"
                            checked={selectAll}
                            onChange={handleToggleSelectAll}
                            className="mr-2"
                            style={{
                              height: "20px",
                              width: "20px",
                              borderRadius: "5px",
                              backgroundColor: selectAll
                                ? "var(--icon-colour-0)"
                                : "",
                            }}
                          />
                          select
                        </div>
                      </th>
                      <th key="action" scope="col" className="px-6 py-3 border">
                        question
                      </th>
                      {domain === QuestionDomain.PUBLIC && (
                        <th
                          key="organisation"
                          scope="col"
                          className="pl-6 py-3 border"
                        >
                          organisation
                        </th>
                      )}
                    </tr>
                  </thead>
                  <tbody>
                    {domainQuestions.map((question, index) => (
                      <tr key={index} className="bg-white">
                        <td className="px-2 py-2 border flex justify-center">
                          <input
                            type="checkbox"
                            checked={
                              selectedQuestions[question.question] || false
                            }
                            onChange={handleSelectQuestion}
                            value={question.question}
                            style={{
                              height: "20px",
                              width: "20px",
                              borderRadius: "5px",
                              backgroundColor:
                                selectedQuestions[question.question] || false
                                  ? "var(--icon-colour-0)"
                                  : "",
                            }}
                          />
                        </td>
                        <td className="px-6 py-2 border">
                          {question.question}
                        </td>
                        {domain === QuestionDomain.PUBLIC && (
                          <td className="px-6 py-2 border">
                            {organisations.find(
                              (organisation) =>
                                organisation.id === question.organisationFromId
                            )?.name || "Deleted organisation"}
                          </td>
                        )}
                      </tr>
                    ))}
                  </tbody>
                </table>
              ) : (
                <p className="text-center">No questions available</p>
              )}
            </div>

            <div className="flex justify-center">
              {hasSelectedQuestions && topic !== "" && (
                <>
                  {confirmation ? (
                    <div className="flex row items-center my-6">
                      Confirm{" "}
                      {action === DomainQuestionAction.COPY ? "copy" : "delete"}{" "}
                      questions?{" "}
                      <button
                        className="ml-2 bg-red-400 text-white p-1 rounded"
                        onClick={() => setConfirmation(false)}
                      >
                        No
                      </button>
                      <button
                        className="ml-2 bg-red-400 text-white p-1 rounded"
                        onClick={() => {
                          setConfirmation(false);
                          handlePerformAction();
                        }}
                      >
                        Yes
                      </button>
                    </div>
                  ) : (
                    <button
                      disabled={isLoading}
                      onClick={() => setConfirmation(true)}
                      className="bg-red-400 text-white p-2 text-lg uppercase rounded-full w-1/3 my-4"
                    >
                      {isLoading
                        ? action === DomainQuestionAction.COPY
                          ? "Copying questions..."
                          : "Deleting questions..."
                        : action === DomainQuestionAction.COPY
                        ? "Copy questions"
                        : "Delete questions"}
                    </button>
                  )}
                </>
              )}
            </div>
          </div>
        </Modal.Body>
      </Modal>

      <button
        className="bg-red-400 text-white p-2 text-lg uppercase rounded-full w-auto my-2"
        onClick={() => setVisible(true)}
      >
        Domain questions
      </button>
    </>
  );
};

export default DomainQuestionsModal;
