import { useRanger } from "./SliderFunction";
import HappyFace from "assets/Slider/HappyFace";
import SadFace from "assets/Slider/SadFace";
import classes from "./Slider.module.css";

interface SliderProps {
  values: (string | number)[];
  setValues: number[];
  icon: boolean;
  isDisabled?: boolean;
  style?: string; // - currently has default (uses --main-colour-2) and average (uses --main-colour and changes in other styles)
  otherValues?: JSX.Element[]; // an array of bar components to be rendered
  max?: number;
}

const Slider = ({
  values,
  setValues,
  icon,
  isDisabled,
  style, // - currently has default (uses --main-colour-2) and average (uses --main-colour and changes in other styles)
  otherValues, // an array of bar components to be rendered
  max = 10,
}: SliderProps) => {
  const numericValues = values.map((value) =>
    typeof value === "number" ? value : parseFloat(value)
  );

  const { getTrackProps, ticks, segments, handles } = useRanger({
    min: 1,
    max: max,
    stepSize: 1,
    values: numericValues,
    onChange: setValues,
  });

  const faceStrokeColor =
    style === "average" ? "var(--main-colour)" : "var(--main-colour-2)";

  return (
    <div>
      <br />
      <br />
      <div
        className={`${classes.track} ${
          style ? classes["track--" + style] : ""
        }`}
        {...getTrackProps()}
      >
        {ticks.map(({ value, getTickProps }) => (
          <div className={classes.tick} {...getTickProps()}>
            {icon && (
              <div
                className={`${classes["tick-label"]} ${
                  style ? classes["tick-label--" + style] : ""
                } `}
              >
                {value}
              </div>
            )}
            {!icon && (
              <div
                className={`${classes.face} ${
                  style ? classes["face--" + style] : ""
                }`}
              >
                {value === 1 && <SadFace strokeColor={faceStrokeColor} />}
                {value === max && <HappyFace strokeColor={faceStrokeColor} />}
              </div>
            )}
          </div>
        ))}
        {segments.map(({ getSegmentProps }, i: number) => (
          <div
            className={`${classes.segment} ${
              style ? classes["segment--" + style] : ""
            }`}
            {...getSegmentProps()}
            index={i}
          />
        ))}
        {handles.map(({ value, active, getHandleProps }) =>
          !isDisabled ? (
            <button
              disabled={isDisabled ? isDisabled : false}
              type="button"
              key={value}
              {...getHandleProps({
                style: {
                  appearance: "none",
                  border: "none",
                  background: "transparent",
                  outline: "none",
                  position: "absolute",
                  left: `${(value / max) * 100}%`,
                  transform: "translateX(-50%)",
                },
              })}
            >
              {style === "average" && (
                <div
                  className={`${classes.handle} ${
                    style ? classes["dummy-handle--" + style] : ""
                  }`}
                  data-active={active.toString()}
                >
                  {value}
                </div>
              )}

              <div
                className={`${classes.bar} ${
                  style ? classes["bar--" + style] : ""
                }`}
                data-active={active.toString()}
              ></div>
              <div
                className={`${classes.handle} ${
                  style ? classes["handle--" + style] : ""
                }`}
                data-active={active.toString()}
              >
                {value}
              </div>
            </button>
          ) : (
            <button
              key={value}
              disabled={true}
              style={{
                outline: "none",
                border: "none",
                backgroundColor: "transparent",
                position: "absolute",
                left: `${((value - 1) / (max - 1)) * 100}%`,
                zIndex: 1,
                transform: "translate(-50%, -50%)",
              }}
            >
              {style === "average" && (
                <div
                  className={`${classes.handle} ${
                    style ? classes["dummy-handle--" + style] : ""
                  }`}
                >
                  {value}
                </div>
              )}
              <div
                className={`${classes.bar} ${
                  style ? classes["bar--" + style] : ""
                }`}
              ></div>
              <div
                className={`${classes.handle} ${
                  style ? classes["handle--" + style] : ""
                }`}
              >
                {value}
              </div>
            </button>
          )
        )}
        {/* Other Rating Values if provided */}
        {otherValues}
      </div>
    </div>
  );
};

export default Slider;
