import FiveStepHeader from "components/FiveStepHeader/FiveStepHeader";
import Header from "components/Header/Header";
import ContentWrapper from "components/utility/ContentWrapper";
import EmptySpace from "components/utility/EmptySpace";
import { useAuthContext } from "hooks/useAuthContext";
import useParticipants from "hooks/useParticipants";
import NoGCSessionWrapper from "pages/NoGCSession/NoGCSessionWrapper";
import React, { useEffect, useState } from "react";
import classes from "./CompletedFinal.module.css";

import Center from "components/utility/Center/Center";
import PageWrapper from "components/utility/PageWrapper";
import { useGrowthCircleContext } from "hooks/useGrowthCircleContext";
import { useGCSession } from "hooks/useGCSession";
import { useNavigate } from "react-router-dom";
import { FINISHED_ROUTE } from "strings/Routes";
import { useEmailAPI } from "hooks/useEmailAPI";
import { useFirestore } from "hooks/useFirestore";
import NGFooter from "components/Footers/NGFooter";
import { Button, Modal } from "flowbite-react";
import { MdCancel } from "react-icons/md";
import { saveJournal } from "models/journal";
import usePromise from "hooks/utility/usePromise";
import { checkCanAdvanceNextActivity } from "models/organisationRole";

const CompletedFinal = () => {
  const currentStep = 5;
  const { ParticipantRecords, ParticipantRecord, updateLevelProgress } =
    useParticipants();
  const { facilAllowNextPage } = useGCSession();
  const { profile } = useAuthContext();
  const [reflect, setReflect] = useState(false);
  const [reflection, setReflection] = useState(false);
  const [notes, setNotes] = useState(false);
  const [shareAndSupport, setShareAndSupport] = useState(false);
  const [myQuestions, setMyQuestions] = useState(false);
  const [othersQuestions, setOthersQuestions] = useState(false);
  const [saveIntention, setSaveIntention] = useState(false);
  const [savePicture, setSavePicture] = useState(false);
  const { growthCircleSession } = useGrowthCircleContext();
  const { sessionRole } = useParticipants();
  const { updateDocument } = useFirestore("users");
  const { sendEmail } = useEmailAPI();
  const [isAtLeastOneTrue, setIsAtLeastOneTrue] = useState(false);
  const navigate = useNavigate();
  const [hasTrigger, setHasTrigger] = useState(false);
  const [isVisible, setIsVisible] = useState(false);
  const { isLoading, resolve } = usePromise();

  const _setTrigger = () => {
    setHasTrigger(!hasTrigger);
  };
  const handleShareAndSupport = () => {
    const newShareAndSupport = !shareAndSupport;
    setShareAndSupport(newShareAndSupport);
    setMyQuestions(newShareAndSupport);
    setOthersQuestions(newShareAndSupport);
  };

  const handleSubShareCheckbox = (setSubCheckbox, value) => {
    setSubCheckbox(!value);
    if (!shareAndSupport && !value) {
      setShareAndSupport(true);
    } else if (shareAndSupport && value) {
      setShareAndSupport(false);
    }
  };

  function atLeastOneTrue() {
    return (
      reflect ||
      reflection ||
      notes ||
      shareAndSupport ||
      myQuestions ||
      othersQuestions ||
      saveIntention ||
      savePicture
    );
  }

  const handleReflect = () => {
    const newReflect = !reflect;
    setReflect(newReflect);
    setReflection(newReflect);
    setNotes(newReflect);
  };

  const handleSubCheckbox = (setSubCheckbox, value) => {
    setSubCheckbox(!value);
    if (!reflect && !value) {
      setReflect(true);
    } else if (reflect && value) {
      setReflect(false);
    }
  };
  useEffect(() => {
    if (!profile) return;
    if (ParticipantRecords.length === 0) return;
    if (!ParticipantRecord[0] === undefined) return;
    if (!growthCircleSession) return;
    if (ParticipantRecord[0] !== undefined) {
      setTimeout(() => {
        updateLevelProgress(ParticipantRecord[0]);
        console.debug("updateLevel called");
      }, 1000);
    }

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [ParticipantRecords, ParticipantRecord, profile, growthCircleSession]);

  useEffect(() => {
    setIsAtLeastOneTrue(atLeastOneTrue());
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [
    reflect,
    reflection,
    notes,
    shareAndSupport,
    myQuestions,
    othersQuestions,
    saveIntention,
    savePicture,
  ]);

  // create data for GCJournal Submit
  const processJournal = async () => {
    if (growthCircleSession) {
      saveJournal(growthCircleSession, ParticipantRecord[0], {
        saveIntention,
        reflection,
        notes,
        myQuestions,
        othersQuestions,
        savePicture,
      });
    }
  };

  const nextHandler = () => {
    return resolve(async () => {
      if (checkCanAdvanceNextActivity(sessionRole)) {
        facilAllowNextPage("end-of-session");
      }
      if (!profile?.isEmailSent) {
        sendEmail(
          profile!.email,
          profile!.displayName,
          ParticipantRecord[0].id
        );
        await updateDocument(profile?.uid, { isEmailSent: true });
      }
      await processJournal();
      navigate(FINISHED_ROUTE);
    });
  };

  const skipSaveHandler = async () => {
    navigate(FINISHED_ROUTE);
  };

  const modalHandler = () => {
    setIsVisible(!isVisible);
  };

  return (
    <NoGCSessionWrapper>
      <PageWrapper pageType={"groundRules"}>
        <React.Fragment>
          <Modal
            show={isVisible}
            size="md"
            className="min-h-screen"
            popup={true}
          >
            <Modal.Body className="p-5 relative mt-[10%] md:mt-[5%]">
              <div className="absolute right-0 top-0 p-2">
                <MdCancel
                  size={30}
                  className="cursor-pointer"
                  onClick={modalHandler}
                  style={{ color: "var(--main-colour)" }}
                />
              </div>
              <div className="text-center py-5 px-2">
                <h3 className="font-semibold mt-5 text-lg text-slate-700">
                  Before Skipping
                </h3>
                <EmptySpace height={"20px"} />
                <div className="flex flex-col justify-center gap-4">
                  <p>
                    Please be aware that skipping will not save your progress to
                    your journal.
                  </p>

                  <div className="flex gap-4 justify-center items-center m-5">
                    <Button
                      className="shadow-md"
                      color="gray"
                      onClick={modalHandler}
                    >
                      Cancel
                    </Button>
                    <button
                      type="button"
                      className="shadow-md bg-green-500 text-green-300 px-6 py-2 rounded-md"
                      onClick={skipSaveHandler}
                    >
                      <span>Proceed</span>
                    </button>
                  </div>
                </div>
              </div>
            </Modal.Body>
          </Modal>
        </React.Fragment>
        <Header hasModal={hasTrigger || isVisible} />
        <FiveStepHeader
          hasModal={hasTrigger || isVisible}
          setIsTriggerVisible={_setTrigger}
          hasLightning={true}
          hasGuide={false}
          currentStep={currentStep}
          text="Completed"
        />
        <div className="relative w-full">
          <ContentWrapper hasFiveStepper={true}>
            <Center>
              <div
                className={`bg-white flex flex-col justify-start items-center w-full h-auto rounded-xl shadow-lg] py-4  px-4 md:px-14 shadow-lg relative`}
              >
                <div className="w-full px-2 md:px-6">
                  <h2
                    style={{ color: "var(--main-colour)" }}
                    className={` font-semibold text-center text-xl`}
                  >
                    Well Done!
                  </h2>
                  <p className="text-center mt-2">
                    Like a <strong className="italic">Circle</strong>, you’re
                    welcome for another round!
                  </p>
                  <div
                    style={{
                      backgroundColor: "var(--main-colour)",
                      borderColor: "var(--main-colour-20)",
                    }}
                    className={`mt-4 border  rounded-3xl h-auto pb-4 shadow-lg`}
                  >
                    <div
                      className={`overflow-y-scroll h-[300px] ${classes["custom-scrollbar"]}`}
                    >
                      <div
                        style={{ borderColor: "var(--main-colour)" }}
                        className={`$flex justify-center border-4  rounded-3xl  bg-default py-3  `}
                      >
                        <h3
                          style={{ color: "var(--main-colour)" }}
                          className={`text-center font-semibold text-lg`}
                        >
                          Save to your Journal?{" "}
                        </h3>
                      </div>
                      <div className="mx-4">
                        <div className="flex py-4 justify-between items-center px-4 mt-2 text-default font-semibold border-b border-default pr-8">
                          <p>Save Intention</p>
                          <div className="block">
                            <label className="inline-flex items-center">
                              <input
                                checked={saveIntention}
                                onChange={() =>
                                  setSaveIntention(!saveIntention)
                                }
                                type="checkbox"
                                style={{ color: "var(--main-colour-80)" }}
                                className={`w-6 h-6 rounded-full  checked:border-default`}
                              />
                            </label>
                          </div>
                        </div>
                        <div className="px-4 mt-2 text-default font-semibold">
                          <p>Save elements in “Choose and Share”</p>
                        </div>
                        <div className="px-4 py-4 text-default font-semibold border-b border-default">
                          <div className="flex py-2 justify-between items-center pl-5 pr-[12px]">
                            <p>○ Reflect</p>
                            <div className="block">
                              <label className="inline-flex items-center">
                                <input
                                  type="checkbox"
                                  checked={reflect}
                                  onChange={handleReflect}
                                  style={{ color: "var(--main-colour-80)" }}
                                  className={`w-6 h-6 rounded-full checked:border-default`}
                                />
                              </label>
                            </div>
                          </div>
                          <div className="flex py-1 justify-between items-center pl-10 pr-[13px]">
                            <p>○ Reflection</p>
                            <div className="block">
                              <label className="inline-flex items-center">
                                <input
                                  type="checkbox"
                                  checked={reflection}
                                  onChange={() =>
                                    handleSubCheckbox(setReflection, reflection)
                                  }
                                  style={{ color: "var(--main-colour-80)" }}
                                  className={`w-5 h-5 rounded-full  checked:border-default`}
                                />
                              </label>
                            </div>
                          </div>
                          <div className="flex py-2 justify-between items-center pl-10 pr-[13px]">
                            <p>○ Notes</p>
                            <div className="block">
                              <label className="inline-flex items-center">
                                <input
                                  type="checkbox"
                                  checked={notes}
                                  onChange={() =>
                                    handleSubCheckbox(setNotes, notes)
                                  }
                                  style={{ color: "var(--main-colour-80)" }}
                                  className={`w-5 h-5 rounded-full  checked:border-default`}
                                />
                              </label>
                            </div>
                          </div>
                        </div>
                        <div className="px-4 py-4 text-default font-semibold border-b border-default">
                          <div className="flex py-2 justify-between items-center pl-5 pr-[12px]">
                            <p>○ Share and Support</p>
                            <div className="block">
                              <label className="inline-flex items-center">
                                <input
                                  type="checkbox"
                                  checked={shareAndSupport}
                                  onChange={handleShareAndSupport}
                                  style={{ color: "var(--main-colour-80)" }}
                                  className={`w-6 h-6 rounded-full  checked:border-default`}
                                />
                              </label>
                            </div>
                          </div>
                          <div className="flex py-1 justify-between items-center pl-10 pr-[13.5px]">
                            <p>○ My Questions</p>
                            <div className="block">
                              <label className="inline-flex items-center">
                                <input
                                  type="checkbox"
                                  checked={myQuestions}
                                  onChange={() =>
                                    handleSubShareCheckbox(
                                      setMyQuestions,
                                      myQuestions
                                    )
                                  }
                                  style={{ color: "var(--main-colour-80)" }}
                                  className={`w-5 h-5 rounded-full  checked:border-default`}
                                />
                              </label>
                            </div>
                          </div>
                          <div className="flex py-1 justify-between items-center pl-10 pr-[13.5px]">
                            <p>○ Other's Questions</p>
                            <div className="block">
                              <label className="inline-flex items-center">
                                <input
                                  type="checkbox"
                                  checked={othersQuestions}
                                  onChange={() =>
                                    handleSubShareCheckbox(
                                      setOthersQuestions,
                                      othersQuestions
                                    )
                                  }
                                  style={{ color: "var(--main-colour-80)" }}
                                  className={`w-5 h-5 rounded-full  checked:border-default`}
                                />
                              </label>
                            </div>
                          </div>
                        </div>
                        {growthCircleSession &&
                          growthCircleSession.type === "NG" && (
                            <div className="flex font-bold text-default py-4 justify-between items-center px-4 pr-8">
                              <p>○ Save Pictures</p>
                              <div className="block">
                                <label className="inline-flex items-center">
                                  <input
                                    checked={savePicture}
                                    onChange={() =>
                                      setSavePicture(!savePicture)
                                    }
                                    type="checkbox"
                                    style={{ color: "var(--main-colour-80)" }}
                                    className={`w-6 h-6 rounded-full checked:border-default`}
                                  />
                                </label>
                              </div>
                            </div>
                          )}
                      </div>
                    </div>
                  </div>
                </div>

                <button
                  onClick={nextHandler}
                  disabled={isLoading || !isAtLeastOneTrue}
                  style={
                    isLoading || !isAtLeastOneTrue
                      ? {
                          color: "gray",
                          borderColor: "gray",
                          backgroundColor: "lightgray",
                        }
                      : {
                          color: "var(--main-colour)",
                          borderColor: "var(--main-colour)",
                        }
                  }
                  className={`px-4 py-2 text-xl font-bold border-4 w-3/4 shadow-lg mx-auto mt-5 rounded-full duration-1000 transition hover:text-white`}
                >
                  {isLoading ? "Saving..." : "Save"}
                </button>
                <EmptySpace height="50px" />
                {!isLoading && (
                  <button
                    onClick={modalHandler}
                    className={`absolute bottom-0 right-0 px-4 py-2  text-xl font-bold  mx-auto mt-5 rounded-full  hover:text-white duration-1000 transition`}
                    style={{ color: "var(--main-colour)" }}
                  >
                    SKIP
                  </button>
                )}
              </div>
            </Center>
            <EmptySpace height="5px" />
            <NGFooter type={growthCircleSession?.type} />
          </ContentWrapper>
        </div>
      </PageWrapper>
    </NoGCSessionWrapper>
  );
};

export default CompletedFinal;
