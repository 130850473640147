import { StorageError, UploadMetadata, UploadTask, UploadTaskSnapshot, deleteObject, getDownloadURL, ref, uploadBytesResumable } from "firebase/storage";
import { projectStorage } from "../firebase/config";
import { useCallback } from "react";

/**
 * Use firebase upload features that updates in real time and retrieve its download URL.
 * Uses firebase storage's uploadBytesResumable and getDownload URL function.
 *
 * @returns Related functions and state variables.
 */
export const useStorage = () => {

  /**
   * Upoads an image to firebase storage.
   *
   * @param path File path to upload in firebase storage.
   * @param data Data file to upload.
   * @param metadata Upload metadata.
   * @param onNext Handler whenever there is an update on upload progress.
   * @param onError Error handler for the upload.
   * @param onComplete Function to run when upload completes.
   */
  const upload = (
    path: string,
    data: Blob | Uint8Array | ArrayBuffer,
    metadata?: UploadMetadata,
    onNext?: ((snapshot: UploadTaskSnapshot) => unknown),
    onError?: ((e: StorageError) => unknown),
    onComplete?: (uploadTask: UploadTask) => void,
  ) => {
    const storageRef = ref(projectStorage, path);
    const uploadTask = uploadBytesResumable(storageRef, data, metadata);

    const wrappedOnComplete = () => {
      if (onComplete) {
        onComplete(uploadTask);
      }
    };
    
    uploadTask.on(
      'state_changed',
      onNext,
      onError,
      wrappedOnComplete
    )
  }

  /**
   * Retrives the download URL of an object from firebase storage.
   *
   * @param path Download path from firebase storage.
   * @returns Download URL of the object.
   */
  const download = (path: string) => {
    const storageRef = ref(projectStorage, path);
    return getDownloadURL(storageRef);
  }

  /**
   * Deletes an object from firebase storage at a specified path.
   * 
   * @param path Path of object in firebase storage to be deleted.
   * @return A promise that resolves when completed.
   */
  const deleteObjectWrapped = useCallback((path: string) => {
    const storageRef = ref(projectStorage, path);
    return deleteObject(storageRef).catch(() => {
      console.log('Error deleting object');
    });
  }, [])

  return { upload, download, deleteObject: deleteObjectWrapped }
}
