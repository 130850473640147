import Logo from "./Logo";
import { ReactComponent as Leaf } from "../../assets/Leaf.svg";

import classes from "./LoadingWidget.module.css";
import { useLogout } from "hooks/useLogout";
import { useEffect } from "react";

interface WidgetProps {
  text?: string;
  title?: string;
  doTimeOut?: boolean;
}

/**
 * @param text - string to be displayed within the circle
 * @param title - string to be displayed outside the circle
 */
const LoadingWidget: React.FC<WidgetProps> = ({ text, title, doTimeOut }) => {
  const logoStyle = {
    marginTop: "20px",
    color: "#FDF5E6",
    fontSize: "1.5em",
  };

  const { logout } = useLogout();

  useEffect(() => {
    if (doTimeOut) {
      const countdown = setTimeout(() => {
        logout();
      }, 15000);
      return () => clearTimeout(countdown);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return (
    <div className={classes["widget-container"]}>
      {title && <h1>{title}</h1>}
      <div className={classes.placeholder}>
        <div className={classes.c1} />
        <div className={classes.c2} />
        <div className={classes.c3} />
        <div className={classes["c4-container"]}>
          <div className={classes.c4}>
            <div className={classes["loading-text"]}>
              <Logo text={text} style={logoStyle} />
              <span className={`${classes.progress} py-4`}>
                <Leaf className={classes["left-leaf"]} />
                <Leaf className={classes["center-leaf"]} />
                <Leaf className={classes["right-leaf"]} />
              </span>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default LoadingWidget;
