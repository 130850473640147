import { BadgeStates, BadgeType } from "interface/FeedbackFormInterface";
import { Participant } from "interface/ParticipantInterface";
import { useEffect, useReducer } from "react";

export enum RateActionEnum {
  TOGGLE = "TOGGLE",
  ADD_USER = "ADD_USER",
  DELETE_USER = "DELETE_USER",
}

export type RateState = {
  [participantId: string]: BadgeStates;
};

export type TogglePayload = {
  id: string;
  badge: BadgeType;
};

//Actions
export type ToggleRateAction = {
  type: RateActionEnum.TOGGLE;
  payload: TogglePayload;
};

export type AddNewUserAction = {
  type: RateActionEnum.ADD_USER;
  payload: Participant;
};

export type DeleteNewUserAction = {
  type: RateActionEnum.DELETE_USER;
  payload: string;
};

export type RateAction =
  | ToggleRateAction
  | AddNewUserAction
  | DeleteNewUserAction;

/**
 * Dynamically gets the initial badge states
 */
const getInitialBadgeStates = (): BadgeStates => {
  return Object.keys(BadgeType).reduce((prev, curr, index) => {
    const state: BadgeStates = {
      ...prev,
      [BadgeType[curr]]: 0,
    };
    return state;
  }, {} as BadgeStates);
};

interface RateUsersProps {
  participants: Participant[];
  localStorageKey: string;
}

/**
 * Dynamically gets the initial RateState for each participant
 * @param participants: Participant[]
 * @param localStorageKey: string
 */
const getInitialRateState = ({
  participants,
  localStorageKey,
}: RateUsersProps): RateState => {
  // localStorage.removeItem(localStorageKey);
  const jsonRateState: string | null = localStorage.getItem(localStorageKey);
  if (!!jsonRateState) {
    const storedRateState: RateState = JSON.parse(jsonRateState);
    // const storedRateStateKeys: string[] = Object.keys(storedRateState);
    return storedRateState;
  } else {
    //return default by reducing participant
    const newRateState: RateState = participants.reduce(
      (prev, curr, currIndex) => {
        return {
          ...prev,
          [curr.userId]: getInitialBadgeStates(),
        };
      },
      {} as RateState
    );
    // console.log(newRateState);
    return newRateState;
  }
};

/**
 * Reducer method for useRateOtherUsers hook
 * Includes the following RateAction methods (so far):
 * TOGGLE: Toggle one badge of a specific participant
 * ADD_USER: Adds user into the list of users to rate
 * DELETE_USER: Removes a user from the list of users to rate
 * @param state: RateState - current state
 * @param action: RateAction - Methods above
 */
const rateOtherUsersReducer = (state: RateState, action: RateAction) => {
  switch (action.type) {
    case RateActionEnum.TOGGLE:
      //1. Gets the participant by id and toggles the selection
      //Updated Nested Object pattern (https://redux.js.org/usage/structuring-reducers/immutable-update-patterns#updating-nested-objects) must be adhered to when updating nested objects
      const toggleValue: number =
        1 - state[action.payload.id][action.payload.badge];
      return {
        ...state,
        [action.payload.id]: {
          ...state[action.payload.id],
          [action.payload.badge]: toggleValue,
        },
      };
    case RateActionEnum.ADD_USER:
      //1. Get the user that is not assigned yet
      console.log("Adding new user" + action.payload.userName);
      return {
        ...state,
        [action.payload.userId]: getInitialBadgeStates(),
      };
    case RateActionEnum.DELETE_USER:
      //1. Get the user that is not assigned yet
      console.log("Deleting new user" + action.payload);
      const newState: RateState = {
        ...state,
      };
      delete newState[action.payload];
      return newState;
    default:
      return state;
  }
};

/**
 * Hook to rate other users
 * @param participants: Participant[] - list of participants to rate
 * @param localStorageKey: key to use for localStorage
 */
const useRateOtherUsers = (
  participants: Participant[],
  localStorageKey: string
) => {
  const [userRatings, dispatch] = useReducer(
    rateOtherUsersReducer,
    { participants: participants, localStorageKey: localStorageKey },
    getInitialRateState
  );

  /**
   * Stores updated badge rating into localStorage
   */
  useEffect(() => {
    //Store in localStorage
    localStorage.setItem(localStorageKey, JSON.stringify(userRatings));
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [userRatings]);

  useEffect(() => {
    const userRatingsKeys: string[] = Object.keys(userRatings);
    if (userRatingsKeys.length < participants.length) {
      participants.forEach((participant) => {
        if (typeof userRatings[participant.userId] === "undefined") {
          dispatch({ type: RateActionEnum.ADD_USER, payload: participant });
        }
      });
    }

    if (userRatingsKeys.length > participants.length) {
      userRatingsKeys.forEach((userId) => {
        if (
          !participants.find((participant) => participant.userId === userId)
        ) {
          dispatch({ type: RateActionEnum.DELETE_USER, payload: userId });
        }
      });
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [participants]);

  return {
    userRatings,
    dispatch,
  };
};

export default useRateOtherUsers;
