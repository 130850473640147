import { FaTrashAlt } from "react-icons/fa";
import { inputClass } from "strings/InputClassStrings";
import { colours } from "../components/styles/colourMap";
import { ChangeEvent, useEffect, useState } from "react";

const WorstInput = ({
  index,
  pageType,
  removeHandler,
  worst,
}: {
  index: number;
  pageType: string;
  worst: string;
  removeHandler: (index: number) => void;
}) => {
  const [answer, setAnswer] = useState<string>(worst);
  const answerHandler = (value: string) => {
    setAnswer(value);
    const storedJournal = localStorage.getItem("SelfAwarenessJournal");
    const existingJournal = storedJournal ? JSON.parse(storedJournal) : {};
    const existingWorsts = existingJournal.worsts || [];

    // Check if the index is within bounds
    if (index >= 0 && index < existingWorsts.length) {
      const updatedWorsts = existingWorsts.map((worst, i) =>
        i === index ? value : worst
      );

      // Update the existing journal with the updated challenges array
      const updatedJournal = {
        ...existingJournal,
        worsts: updatedWorsts,
      };

      // Store the updated journal in local storage
      localStorage.setItem(
        "SelfAwarenessJournal",
        JSON.stringify(updatedJournal)
      );
    } else {
      // Add a new value if the index is out of bounds
      const updatedJournal = {
        ...existingJournal,
        worsts: [...existingWorsts, value],
      };

      // Store the updated journal in local storage
      localStorage.setItem(
        "SelfAwarenessJournal",
        JSON.stringify(updatedJournal)
      );
    }
  };

  useEffect(() => {}, [worst]);

  return (
    <div className="flex gap-2 items-center">
      <input
        onChange={(e: ChangeEvent<HTMLInputElement>) =>
          answerHandler(e.target.value)
        }
        value={answer}
        placeholder={`Worst thing that can happen ${index + 1}`}
        className={`${inputClass} border-2`}
        style={{ borderColor: colours[pageType]["border"] }}
      />
      <FaTrashAlt
        className="cursor-pointer"
        onClick={() => removeHandler(index)}
        size={20}
        style={{ color: "var(--icon-colour-0)" }}
      />
    </div>
  );
};

export default WorstInput;
