import { Participant } from "interface/ParticipantInterface";
import ParticipantSummaryCard from "./ParticipantSummaryCard";
import classes from "./ParticipantSummary.module.css";

interface ParticipantSummaryProps {
  participants: Participant[];
}

const ParticipantSummary = ({ participants }: ParticipantSummaryProps) => {
  const host = participants.find(
    (participant) => participant.sessionRole === "host"
  );
  const otherParticipants = participants.filter(
    (participant) => participant.sessionRole !== "host"
  );
  const sortedParticipants = [host, ...otherParticipants];

  return (
    <div className="w-full my-6">
      {/* TODO: convert into a card */}
      <div
        className={`${classes.container} max-w-md md:max-w-md mx-auto  w-full gap-4 flex flex-col justify-center items-center px-4 py-6 rounded-lg shadow-md`}
      >
        {" "}
        <h1
          className={`text-default sticky font-bold text-xl justify-self-center  `}
        >
          Participants Summary
        </h1>
        {sortedParticipants.map(
          (participant) =>
            participant && (
              <ParticipantSummaryCard
                key={participant.userId}
                participant={participant}
              />
            )
        )}
        {sortedParticipants.includes(undefined) && (
          <p className="text-default">No Participants</p>
        )}
      </div>
    </div>
  );
};

export default ParticipantSummary;
