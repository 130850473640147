import classes from "./Admin.module.css";
import SidebarAdmin from "components/Admin/SidebarAdmin";
import NavAdmin from "components/Admin/NavAdmin";
import { lazy, Suspense } from "react";

const AdminAllTopicsList = lazy(
  () => import("components/Admin/AdminTopics/AdminAllTopicsList")
);

const AdminTopics = () => {
  return (
    <div>
      <NavAdmin />
      <div className={classes.container}>
        <SidebarAdmin />
        <div className={classes.main}>
          <div className={classes.title}>
            <h4>Topics</h4>
          </div>
          <Suspense fallback={<div>Loading...</div>}>
            <AdminAllTopicsList />
          </Suspense>
        </div>
      </div>
    </div>
  );
};

export default AdminTopics;
