import EmbeddedVideo from "components/utility/EmbeddedVideo";
import { Button, Modal } from "flowbite-react";
import { useFirestore } from "hooks/useFirestore";
import usePromise from "hooks/utility/usePromise";

import { VideoInterface } from "interface/VideoInterface";
import { useState } from "react";
import { FaEdit, FaTrash } from "react-icons/fa";
import { HiOutlineExclamationCircle } from "react-icons/hi";
import { Link } from "react-router-dom";

interface VideoProps {
  video: VideoInterface;
}

const VideoCard = ({ video }: VideoProps) => {
  const [visible, setVisible] = useState(false);
  const { isLoading, resolve } = usePromise();

  const { deleteDocument } = useFirestore("Videos");
  const modalHandler = () => {
    setVisible(!visible);
  };

  const deleteHandler = async () => {
    resolve(() => deleteDocument(video.id));
    setVisible(!visible);
  };

  return (
    <>
      <Modal show={visible} size="md" popup onClose={modalHandler}>
        <Modal.Header />
        <Modal.Body>
          <div className="text-center">
            <HiOutlineExclamationCircle className="mx-auto mb-4 h-14 w-14 text-gray-400 dark:text-gray-200" />
            <h3 className="mb-5 text-lg font-normal text-gray-500 dark:text-gray-400">
              Are you sure you want to delete this video titled :{" "}
              <strong>{video.title}</strong> <br />
            </h3>
            <div className="flex justify-center gap-4">
              <Button
                disabled={isLoading}
                color="failure"
                onClick={deleteHandler}
              >
                {isLoading ? "Deleting..." : "Yes, I'm sure"}
              </Button>
              <Button color="gray" onClick={modalHandler}>
                No, cancel
              </Button>
            </div>
          </div>
        </Modal.Body>
      </Modal>
      <div
        style={{ color: "var(--text-colour)" }}
        className={"bg-white shadow-lg py-4 relative"}
        key={video.embedId}
      >
        <EmbeddedVideo
          embedId={video.embedId}
          style={{ width: "auto", height: "300px" }}
        />
        <div className="px-4">
          <h5 className="text-2xl font-bold tracking-tight py-4">
            {video.title}
          </h5>
          <p className="font-normal  ">{video.description}</p>
        </div>
        <div className="px-4 py-4">
          <ul>
            <li>
              Organisation :{" "}
              <strong>{video.organisationName?.toUpperCase()}</strong>
            </li>
            <li className="py-4">
              Access Role :{" "}
              <strong>
                {video.selectedRoles.map((role, index) => (
                  <p key={index}>{role?.toUpperCase()}</p>
                ))}
              </strong>
            </li>
          </ul>
        </div>
        <div className="absolute bottom-2 right-2 flex gap-2 justify-center items-center">
          <button
            style={{
              backgroundColor: "inherit",
              boxShadow: "none",
              color: "black",
            }}
            onClick={() => setVisible(!visible)}
          >
            <FaTrash size={25} style={{ color: "var(--icon-colour-0)" }} />
          </button>
          <Link to={`/all-circles/admin/edit-video/${video.id}`}>
            <FaEdit size={30} style={{ color: "var(--icon-colour-0)" }} />
          </Link>
        </div>
      </div>
    </>
  );
};

export default VideoCard;
