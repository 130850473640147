import { FC } from "react";
import classes from "./FilterDropDown.module.css";
import { FilterEvent } from "./FilterDropDownMulti";

interface Props {
  option: string;
  // Currently selected options in parent dropdown
  currentlySelected: string[];
  // Sets currently selected item in parent dropdown
  setCurr: (evt:FilterEvent) => any;
  // onSelect is passed down from above FilterDropDown to do something with selected item
  onSelect: (evt: FilterEvent) => any;
}

const FilterDropDownOptionMulti: FC<Props> = ({ option, onSelect, currentlySelected, setCurr }) => {
  const isSelected = currentlySelected.includes(option);
  return (
    <div className={classes["option-container"]}>
      <input
        id={option}
        type="checkbox"
        value=""
        checked={isSelected}
        onChange={(e) => {
          // if clicking again on currently selected then clear the selection
          if (isSelected) {
            const newOptions = currentlySelected.filter(opt => opt !== option);
            setCurr({
              value: newOptions
            });
            return;
          // Add option to array
          } else {
            const newOptions = [...currentlySelected, option]
            setCurr({
              value: newOptions
            });
          }
        }}
      />
      <label id="option-label" htmlFor={option}>
        {option}
      </label>
    </div>
  );
};

export default FilterDropDownOptionMulti;
