import React from "react";
import classes from "./Checkbox.module.css";

type CheckboxProps = {
  id: string;
  name: string;
  handleClick: (e: React.FormEvent<HTMLInputElement>) => any;
  isChecked: boolean;
  text: string;
  styleType: string;
};

/**
 * @param {string} id - key for the checkbox
 * @param {string} name - name of the checkbox
 * @param {(e: React.FormEvent<HTMLInputElement>) => any} handleClick - method handler when user clicks on the checkbox
 * @param {boolean} isChecked - boolean flag for whether or not the checkbox is checked
 * @param {string} text - label for the checkbox
 * @param {styleType} styleType - modifier for the css based on the type: bold, semibold, medium
 */
const Checkbox = ({
  id,
  name,
  handleClick,
  isChecked,
  text,
  styleType,
}: CheckboxProps) => {
  // const {} = props;

  const labelClasses = `${classes["label--" + styleType]}`;

  return (
    <div className={classes["checkboxContainer"]}>
      <label className={labelClasses} htmlFor={id}>
        {text}
      </label>
      <input
        className="outline-none border-none focus:border-none focus:ring-0"
        id={id}
        name={name}
        type="checkbox"
        onChange={handleClick}
        checked={isChecked}
      />
    </div>
  );
};

export default Checkbox;
