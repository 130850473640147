import { FC, useEffect, useState } from "react";
import { ComponentProps } from "../SelfAwarenessComponentMapping";
import { QuotationList } from "./Quotation";
import BackAndNextFlat from "components/utility/BackAndNextFlat";
import { Modal } from "flowbite-react";
import classes from "pages/AllCircles/Admin/Admin.module.css";
import { useNavigate } from "react-router-dom";

const RandomQuotation: FC<ComponentProps> = ({
  backAndNextHandler,
  setHasModal,
}) => {
  const [randomQuotation, setRandomQuotation] = useState<string>("");
  const [isVisible, setIsVisible] = useState<boolean>(true);

  const navigate = useNavigate();
  useEffect(() => {
    const getRandomQuotation = () => {
      const randomIndex = Math.floor(Math.random() * QuotationList.length);
      return QuotationList[randomIndex];
    };

    // Set a random quotation on component mount
    setRandomQuotation(getRandomQuotation());
    setHasModal(true);
    // eslint-disable-next-line
  }, []);

  const nextHandler = () => {
    navigate(`/self-awareness`);
  };

  const handler = () => {
    setIsVisible(!isVisible);
    setHasModal(!isVisible);
  };

  return (
    <>
      {" "}
      <Modal show={isVisible} popup={true} size={"sm"}>
        <div
          className={`${classes["container"]} p-5 relative flex flex-col`}
          style={{ color: "var(--text-colour)" }}
        >
          <p className="my-8 text-center">Your journal entry is saved.</p>
          <button
            onClick={handler}
            className={`${classes["button"]} w-1/2 self-center`}
          >
            Close
          </button>
        </div>
      </Modal>
      <div className="py-8 px-4 w-full md:w-1/4">
        <div
          className="my-8 font-poppins text-2xl h-[250px]"
          style={{ color: "var(--text-colour)" }}
        >
          <p className="w-full text-center">{randomQuotation}</p>
        </div>{" "}
        <div className="mt-5">
          <BackAndNextFlat
            {...backAndNextHandler}
            nextAllowed={true}
            backAllowed={false}
            nextCallback={nextHandler}
          />
        </div>
      </div>
    </>
  );
};

export default RandomQuotation;
