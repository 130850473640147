import React, { useEffect } from "react";
import useParticipants from "hooks/useParticipants";
import DiceImages from "./DiceImages";
import Box from "../utility/Box/Box";
import NumberChosenIcon from "./NumberChosenIcon";

interface Props {
  clickable: boolean;
  index?: number;
  diceId: number;
  numOfPeopleChosen?: number;
  currSelected?: number | null;
  setCurrSelected?: React.Dispatch<React.SetStateAction<number | null>>;
}

/**
 * Component that renders a single box with 1 dice icon in it
 *
 * @param clickable click to select, set to false if only to display
 * @param index refers to the order of the dices shown, ranges 0 - 8
 * @param diceId each dice image has a specified Id to identify them
 * @param numOfPeopleChosen  number of people who clicked on this dice
 * @param currSelected state to show if this dice is selected
 * @param setCurrSelected state setter to change what dice is selected
 * @returns
 */
const SingleDice: React.FC<Props> = ({
  clickable,
  index,
  diceId,
  numOfPeopleChosen,
  currSelected,
  setCurrSelected,
}) => {
  const { updateSelectedDice, ParticipantRecord } = useParticipants();

  const selectDice = () => {
    if (!clickable || index === undefined || !setCurrSelected) return;
    updateSelectedDice(index);
    setCurrSelected(index);
  };

  useEffect(() => {
    if (!ParticipantRecord) return;
  }, [currSelected, index, ParticipantRecord]);

  const DiceIcon = DiceImages[diceId];

  return (
    <>
      {ParticipantRecord.length > 0 && (
        <Box
          height={"100px"}
          width={"100px"}
          margin={"10px"}
          borderRadius={"10px"}
          backgroundColor={
            clickable && ParticipantRecord[0].diceIndex === index
              ? "var(--secondary-colour)"
              : "var(--secondary-colour-2)"
          }
          onClick={() => selectDice()}
          cursor={clickable ? "pointer" : ""}
        >
          <NumberChosenIcon numOfPeopleChosen={numOfPeopleChosen} />
          <DiceIcon />
        </Box>
      )}
    </>
  );
};

SingleDice.defaultProps = {
  numOfPeopleChosen: 0,
  clickable: false,
};

export default SingleDice;
