import { SelfAwarenessToolInterface } from "interface/SelfAwarenessToolInterface";
import TitleBox from "./TitleBox";

import BackAndNextFlat from "components/utility/BackAndNextFlat";
import { BackAndNextProps } from "../SelfAwarenessComponentMapping";
import { useEffect } from "react";
import { colours } from "../components/styles/colourMap";
import ValueRiskItem from "../components/ValueRiskItem";

interface QuestionCompItemProps {
  journal: SelfAwarenessToolInterface;
  backAndNextHandler: BackAndNextProps;
  handler: () => void;
  nextHandler: (e: React.MouseEvent<HTMLButtonElement, MouseEvent>) => void;
  question: string;
  backOptionHandler: () => void;
  option: string;
}
const ValueRiskGrading = ({
  backAndNextHandler,
  journal,
  handler,
  question,
  nextHandler,
  backOptionHandler,
  option,
}: QuestionCompItemProps) => {
  useEffect(() => {
    // eslint-disable-next-line
  }, [question]);

  return (
    <>
      {journal && question && (
        <>
          <TitleBox
            title={`Value-Risk-Resources-Need (VRRN)`}
            handler={handler}
            showInfo={true}
            journal={journal}
            size="small"
          />
          <p
            className="font-poppins p-2 my-4 font-semibold"
            style={{ color: "var(--text-colour)" }}
          >
            {question}
          </p>
          <div
            className="bg-white px-2 border-2 rounded-lg my-4"
            style={{ borderColor: colours[journal.pageType]["border"] }}
          >
            <ValueRiskItem option={option} question={question} />
          </div>

          <div className="mt-5">
            <BackAndNextFlat
              {...backAndNextHandler}
              backCallback={backOptionHandler}
              nextCallback={(e) => nextHandler(e)}
            />
          </div>
        </>
      )}
    </>
  );
};

export default ValueRiskGrading;
