import { useEffect, useState } from "react";
import Header from "components/Header/Header";
import FiveStepHeader from "components/FiveStepHeader/FiveStepHeader";
import classes from "./IntroRating.module.css";
import Steps from "components/utility/Steps";
import { useAuthContext } from "hooks/useAuthContext";
import { useGCSession } from "hooks/useGCSession";
// import AverageRatingModal from "./AverageRatingModal";
import { useNavigate } from "react-router-dom";
import useParticipants from "hooks/useParticipants";
import useGetParticipants from "hooks/useGetParticipants";
import BackAndNext from "components/utility/BackAndNext";
import EmptySpace from "components/utility/EmptySpace";
import Center from "components/utility/Center/Center";
// import { useGrowthCircleContext } from "hooks/useGrowthCircleContext";
import NoGCSessionWrapper from "pages/NoGCSession/NoGCSessionWrapper";
import ContentWrapper from "components/utility/ContentWrapper";
import { DRAG_RATING_KEYS, useDragRating } from "../../../hooks/useDragRating";
import { LocalStorageKeys } from "App";
import { useComponentDidUpdate } from "hooks/useComponentDidUpdate";
import { DICE_ROUTE, RATING_AVERAGE_ROUTE } from "../../../strings/Routes";
import { CheckInCheckOut } from "interface/ParticipantInterface";
import { useFirestore } from "hooks/useFirestore";
import SliderCustom from "components/SliderCustom/SliderCustom";
import NGFooter from "components/Footers/NGFooter";
import { useGrowthCircleContext } from "hooks/useGrowthCircleContext";
import { checkCanAdvanceNextActivity } from "models/organisationRole";

/**
 * Page that includes 4 drag sliders to rate their checkIns
 */
const IntroRating = () => {
  const { profile } = useAuthContext();
  const userStorageKey = profile!.id + "_";
  const { growthCircleSession } = useGrowthCircleContext();
  const { sessionRole, updateGCSessionCheckIn } = useParticipants();
  const { participants } = useGetParticipants();
  const { facilAllowNextPage } = useGCSession(); // removed calculateAverageCheckIn
  const { updateDocument } = useFirestore("users");
  const [hasTrigger, setHasTrigger] = useState(false);

  const dragRatingKey = userStorageKey + LocalStorageKeys.INTRO_RATING;
  const {
    dragValues: introDragValues,
    setDragValues: setIntroDragValues,
    anyUntouched: anyIntroUntouched,
    dragError: showIntroDragError,
    setDragError: setShowIntroDragError,
    setKeyToTouched,
    removeDragRatingsFromStorage,
  } = useDragRating(dragRatingKey);
  const [individually, setIndividually] = useState(
    introDragValues[DRAG_RATING_KEYS.INDIVIDUALLY.KEY].value
  );
  const [interpersonally, setInterpersonally] = useState(
    introDragValues[DRAG_RATING_KEYS.INTERPERSONALLY.KEY].value
  );
  const [socially, setSocially] = useState(
    introDragValues[DRAG_RATING_KEYS.SOCIALLY.KEY].value
  );
  const [overall, setOverall] = useState(
    introDragValues[DRAG_RATING_KEYS.OVERALL.KEY].value
  );

  // const [averages, setAverages] = useState<CheckInCheckOut | null | undefined>(
  //   null
  // );
  // const [showAverage, setShowAverage] = useState(false);
  let navigate = useNavigate();

  // !!! this does not update when users reupdates their checkin ratings,
  // but then again, they wouldn't update it, so should be fine
  useEffect(() => {
    //setAverages(calculateAverageCheckIn(participants));
    //console.debug(averages);
    if (!profile) return;
    const data = { pageStep: window.location.pathname };
    updateDocument(profile.uid, data);
    // console.log(calculateAverageCheckIn(participants));
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [participants]);

  const currentStep = 2;
  const stepsCount = 5;

  const _setTrigger = () => {
    setHasTrigger(!hasTrigger);
  };

  const submitHandler = () => {
    if (anyIntroUntouched()) {
      setShowIntroDragError(true);
      console.log(showIntroDragError);
    } else {
      // const data = {
      //   individually: individually[0],
      //   interpersonally: interpersonally[0],
      //   socially: socially[0],
      //   overall: overall[0],
      // };
      const data = {} as CheckInCheckOut;
      data[DRAG_RATING_KEYS.INDIVIDUALLY.KEY] = individually;
      data[DRAG_RATING_KEYS.INTERPERSONALLY.KEY] = interpersonally;
      data[DRAG_RATING_KEYS.SOCIALLY.KEY] = socially;
      data[DRAG_RATING_KEYS.OVERALL.KEY] = overall;

      updateGCSessionCheckIn(data);
      // setShowAverage(true);
      // console.log("submitting", data);
      //Navigate to rating-average
      removeDragRatingsFromStorage();
      navigate(RATING_AVERAGE_ROUTE);

      const canAdvance = checkCanAdvanceNextActivity(sessionRole);
      if (canAdvance) {
        facilAllowNextPage("end-rating-average");
      }
    }
  };

  // const hideTermsHandler = () => {
  //   // setShowAverage(false);
  //   navigate("/all-circles/setting-intentions");
  // };

  const backHandler = () => {
    navigate(DICE_ROUTE);
  };

  //Set touch to true for drag ratings
  useComponentDidUpdate(() => {
    setKeyToTouched(DRAG_RATING_KEYS.INDIVIDUALLY.KEY);
  }, [individually]);

  useComponentDidUpdate(() => {
    setKeyToTouched(DRAG_RATING_KEYS.INTERPERSONALLY.KEY);
  }, [interpersonally]);

  useComponentDidUpdate(() => {
    setKeyToTouched(DRAG_RATING_KEYS.SOCIALLY.KEY);
  }, [socially]);

  useComponentDidUpdate(() => {
    setKeyToTouched(DRAG_RATING_KEYS.OVERALL.KEY);
  }, [overall]);

  useEffect(() => {
    const newIntroDragValues = { ...introDragValues };
    newIntroDragValues[DRAG_RATING_KEYS.INDIVIDUALLY.KEY].value = individually;
    newIntroDragValues[DRAG_RATING_KEYS.INTERPERSONALLY.KEY].value =
      interpersonally;
    newIntroDragValues[DRAG_RATING_KEYS.SOCIALLY.KEY].value = socially;
    newIntroDragValues[DRAG_RATING_KEYS.OVERALL.KEY].value = overall;
    setIntroDragValues(newIntroDragValues);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [individually, interpersonally, socially, overall]);

  return (
    <NoGCSessionWrapper>
      <Header hasModal={hasTrigger} />
      <FiveStepHeader
        hasLightning={true}
        hasGuide={true}
        currentStep={currentStep}
        setIsTriggerVisible={_setTrigger}
        hasModal={hasTrigger}
      />
      {/* {showAverage && (
          <AverageRatingModal
            document={growthCircleSession}
            averages={averages}
            onClick={hideTermsHandler}
          />
        )}
        <div id="terms-modal" /> */}
      <ContentWrapper hasFiveStepper={true}>
        <div className="relative">
          <Center width="90%" maxWidth="900px">
            <EmptySpace height={"1rem"} />
            <Steps count={stepsCount} currentStep={currentStep} />
            <div className={classes.description}>
              How have you been feeling the past week in the following areas?
            </div>
            <div className="w-[320px] md:w-[400px] mx-auto mt-5 ">
              <div className="w-full mx-auto">
                <div className={`${classes.box} shadow-md`}>
                  <div className={classes.header}>Individually</div>
                  <div className={classes["header-description"]}>
                    Personal well-being
                  </div>
                  <div className="flex justify-center px-2">
                    <SliderCustom
                      withBackground={true}
                      value={individually}
                      setValue={setIndividually}
                    />
                  </div>
                </div>
              </div>
            </div>
            <div className="w-[320px] md:w-[400px] mx-auto mt-5 ">
              <div className="w-full mx-auto">
                <div className={`${classes.box} shadow-md`}>
                  <div className={classes.header}>Interpersonally</div>
                  <div className={classes["header-description"]}>
                    Family, Close relationships
                  </div>
                  <div className="flex justify-center px-2">
                    <SliderCustom
                      withBackground={true}
                      value={interpersonally}
                      setValue={setInterpersonally}
                    />
                  </div>
                </div>
              </div>
            </div>

            <div className="w-[320px] md:w-[400px] mx-auto mt-5 ">
              <div className="w-full mx-auto">
                <div className={`${classes.box} shadow-md`}>
                  <div className={classes.header}>Socially</div>
                  <div className={classes["header-description"]}>
                    Work, school, friendships
                  </div>
                  <div className="flex justify-center px-2">
                    <SliderCustom
                      withBackground={true}
                      value={socially}
                      setValue={setSocially}
                    />
                  </div>
                </div>
              </div>
            </div>

            <div className="w-[320px] md:w-[400px] mx-auto mt-5 ">
              <div className="w-full mx-auto">
                <div className={`${classes.box} shadow-md`}>
                  <div className={classes.header}>Overall</div>
                  <div className={classes["header-description"]}>
                    General sense of well-being
                  </div>
                  <div className="flex justify-center px-2">
                    <SliderCustom
                      withBackground={true}
                      value={overall}
                      setValue={setOverall}
                    />
                  </div>
                </div>
              </div>
            </div>

            <EmptySpace height={"40px"} />
            <BackAndNext
              nextAllowed={!anyIntroUntouched()}
              nextCallback={submitHandler}
              nextFailCallback={submitHandler}
              backAllowed={true}
              backCallback={backHandler}
            />

            <EmptySpace height={"40px"} />
            <NGFooter type={growthCircleSession?.type} />
          </Center>
        </div>
      </ContentWrapper>
    </NoGCSessionWrapper>
  );
};

export default IntroRating;
