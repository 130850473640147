import { Modal } from "flowbite-react";
import { useState } from "react";
import { AiFillCloseCircle, AiFillInfoCircle } from "react-icons/ai";
import classes from "pages/AllCircles/Admin/Admin.module.css";
import { Link } from "react-router-dom";
import { SelfAwarenessToolInterface } from "interface/SelfAwarenessToolInterface";
import { SelfAwarenessImageMap } from "components/SelfAwareness/SelfAwarenessImageMap";

interface SelfDiscoveryProps {
  tool: SelfAwarenessToolInterface;
  hasModal?: boolean;
  setModal?: Function;
}

const SelfDiscoveryCard = ({ tool, setModal }: SelfDiscoveryProps) => {
  const [show, setShow] = useState(false);
  return (
    <>
      <Modal show={show} size={"lg"}>
        <div className="relative min-h-[40vh]">
          <AiFillCloseCircle
            size={30}
            style={{ color: "var(--icon-colour-0)" }}
            className="absolute top-2 right-2 cursor-pointer"
            onClick={() => {
              if (setModal) {
                setModal();
              }
              setShow(!show);
            }}
          />

          <div
            style={{ color: "var(--text-colour)" }}
            className={`${classes["container"]} p-5 flex flex-col justify-center items-center`}
          >
            <p className="my-5 text-3xl">{tool.title}</p>
            <img
              width={"50%"}
              height={"50%"}
              src={SelfAwarenessImageMap[tool.source]}
              alt={tool.alt}
              className="rounded-lg"
            />
            <p className="my-4 font-semibold">{tool.description}</p>

            <Link
              to={`/self-discovery/${tool.id}`}
              className={`${classes["button"]}`}
            >
              Start Journal
            </Link>
          </div>
        </div>
      </Modal>
      <div
        className="animate-fade relative p-5 shadow-lg border-2 rounded-lg flex flex-col justify-center items-center"
        style={{
          borderColor: "#EB8181",
          backgroundColor: "#FEFBF5",
          color: "var(--text-colour)",
          fontWeight: "500",
        }}
      >
        {tool && tool.components && tool.components.length > 0 && (
          <AiFillInfoCircle
            onClick={() => {
              if (setModal) {
                setModal();
              }
              setShow(!show);
            }}
            style={{ color: "#EB8181" }}
            size={25}
            className="absolute top-1 right-1 cursor-pointer"
          />
        )}

        <img
          width={104}
          height={104}
          src={SelfAwarenessImageMap[tool.source]}
          alt={tool.alt}
          className="rounded-lg"
          style={{
            filter:
              tool && tool.components && tool.components.length > 0
                ? "none"
                : "grayscale(100%)",
          }}
        />

        <p className="my-1 text-center">{tool.title}</p>
      </div>
    </>
  );
};

export default SelfDiscoveryCard;
