import { currentMonthName } from "utility/monthName";
import { projectFirestore, timestamp } from "../firebase/config";
import { SpecialThanksInterface } from "interface/SpecialThanksInterface";
import { useEffect, useState } from "react";
import { useAuthContext } from "./useAuthContext";

export const useSpecialThanks = () => {
  const { profile } = useAuthContext();
  const [mySpecialThanks, setMySpecialThanks] = useState<
    SpecialThanksInterface[]
  >([]);
  const addSpecialThanks = async (
    message: string,
    toId: string,
    fromId: string,
    growthCircleInstance: string
  ) => {
    try {
      // Check if a message with the same criteria already exists
      const existingMessage = await projectFirestore
        .collection("SpecialThanks")
        .where("toId", "==", toId)
        .where("fromId", "==", fromId)
        .where("growthCircleInstance", "==", growthCircleInstance)
        .get();

      if (existingMessage.docs.length > 0) {
        // Update the existing message
        const existingDocId = existingMessage.docs[0].id;
        await projectFirestore
          .collection("SpecialThanks")
          .doc(existingDocId)
          .update({
            message,
          });

        return existingDocId;
      } else {
        // Add a new message
        const data: SpecialThanksInterface = {
          message,
          toId,
          fromId,
          createdAt: timestamp.fromDate(new Date()),
          growthCircleInstance,
          month: currentMonthName,
        };

        const ref = await projectFirestore
          .collection("SpecialThanks")
          .add(data);
        await ref.update({ id: ref.id });

        return ref.id;
      }
    } catch (error) {
      console.error(error);
    }
  };

  const deleteSpecialThanks = async (id: string | undefined) => {
    try {
      projectFirestore.collection("SpecialThanks").doc(id).delete();
    } catch (error) {
      console.error(error);
    }
  };

  const getCurrentSpecialThanks = async (
    growthCircleInstance: string,
    setMessages: React.Dispatch<React.SetStateAction<SpecialThanksInterface[]>>
  ) => {
    const unsubscribe = projectFirestore
      .collection("SpecialThanks")

      .where("growthCircleInstance", "==", growthCircleInstance)

      .onSnapshot(async (snapshot) => {
        const messageMap: SpecialThanksInterface[] = [];

        snapshot.forEach((doc) => {
          const data = doc.data() as SpecialThanksInterface;
          messageMap.push(data);
        });

        setMessages(messageMap);
      });

    return () => {
      unsubscribe();
    };
  };

  const getMySpecialThanks = () => {
    try {
      const unsubscribe = projectFirestore
        .collection("SpecialThanks")
        .where("toId", "==", profile?.uid)
        .orderBy("createdAt", "desc")
        .onSnapshot(async (snapshot) => {
          const messageMap: SpecialThanksInterface[] = [];

          snapshot.forEach((doc) => {
            const data = doc.data() as SpecialThanksInterface;
            messageMap.push(data);
          });

          setMySpecialThanks(messageMap);
        });

      return () => {
        unsubscribe();
      };
    } catch (error) {
      console.error(error);
    }
  };

  useEffect(() => {
    if (!profile) return;
    getMySpecialThanks();
    // eslint-disable-next-line
  }, [profile]);

  return {
    addSpecialThanks,
    getCurrentSpecialThanks,
    deleteSpecialThanks,
    mySpecialThanks,
  };
};
