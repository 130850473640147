import { useEffect } from "react";
import useOrganisationContext from "hooks/organisation/useOrganisationContext";

export type FeedbackFormTabsProps = {
  activeTab: number;
  organisation?: string;
  viewOnly?: boolean;
  handleSwitchTabs: (tab: number) => void;
  numTabs: number; // Add a prop for the number of tabs
};

const generateTabContent = (
  activeTab: number,
  handleSwitchTabs: (tab: number) => void,
  numTabs: number
) => (
  <div className="text-white text-center text-[11px] font-semibold py-3 flex justify-center items-center gap-px">
    {numTabs > 1 &&
      Array.from({ length: numTabs }).map((_, index) => (
        <span
          key={index}
          onClick={() => handleSwitchTabs(index + 1)}
          style={
            activeTab === index + 1
              ? {
                  color: "var(--main-colour)",
                  backgroundColor: "var(--main-colour-2)",
                }
              : {}
          }
          className={`h-[17px] w-[64px] md:h-[18px] md:w-[82px] flex justify-center items-center border border-default cursor-pointer ${
            index === 0 ? "rounded-tl-2xl rounded-bl-2xl" : ""
          } ${index === numTabs - 1 ? "rounded-tr-2xl rounded-br-2xl" : ""}`}
        >
          {index + 1}
        </span>
      ))}
  </div>
);

const FeedBackOrgFormTabs = ({
  activeTab,
  handleSwitchTabs,
  organisation,
  viewOnly,
  numTabs,
}: FeedbackFormTabsProps) => {
  const { selectedOrganisation } = useOrganisationContext();

  useEffect(() => {
    if (!selectedOrganisation) return;
  }, [selectedOrganisation]);

  useEffect(() => {
    if (!organisation) return;
  }, [organisation]);

  return (
    <div
      style={{ backgroundColor: "var(--main-colour)" }}
      className={`rounded-tl-3xl rounded-tr-3xl py-3 `}
    >
      {generateTabContent(activeTab, handleSwitchTabs, numTabs)}
    </div>
  );
};

export default FeedBackOrgFormTabs;
