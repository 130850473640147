import { ComponentProps } from "../SelfAwarenessComponentMapping";
import { FC, useEffect, useState } from "react";
import BackAndNextFlat from "components/utility/BackAndNextFlat";
import TextArea from "../SubComponents/TextArea";
import { useSelfAwarenessContext } from "hooks/useSelfAwarenessContext";

const ReflectionSubmit: FC<ComponentProps> = ({ backAndNextHandler }) => {
  const [reflection, setReflection] = useState<string>("");
  const [priority, setPriority] = useState<string>("");
  const { journal } = useSelfAwarenessContext();
  useEffect(() => {
    // Get the existing SelfAwarenessJournal from local storage
    const storedJournal = localStorage.getItem("SelfAwarenessJournal");
    const existingJournal = storedJournal ? JSON.parse(storedJournal) : {};

    setPriority(existingJournal.priority);
  }, []);

  // Load data from local storage on component mount
  useEffect(() => {
    const storedJournal = localStorage.getItem("SelfAwarenessJournal");
    if (storedJournal) {
      const parsedJournal = JSON.parse(storedJournal);
      setReflection(parsedJournal.reflection || "");
    }
    // eslint-disable-next-line
  }, []); // Empty dependency array means this effect runs once on mount

  const answerHandler = (value: string) => {
    setReflection(value);
    const storedJournal = localStorage.getItem("SelfAwarenessJournal");
    const existingJournal = storedJournal ? JSON.parse(storedJournal) : {};

    // Update the existing journal with the new answer
    const updatedJournal = {
      ...existingJournal,
      reflection: value,
    };

    // Store the updated journal in local storage
    localStorage.setItem(
      "SelfAwarenessJournal",
      JSON.stringify(updatedJournal)
    );
  };

  useEffect(() => {
    if (!journal) return;
  }, [journal]);

  return (
    <div className="py-8 px-4 w-full md:w-1/4">
      <div
        className="relative p-5 border-2 rounded-lg font-poppins font-semibold"
        style={{
          backgroundColor: "#FFF4B9",
          borderColor: "#FFE871",
          color: "var(--text-colour)",
        }}
      >
        <p>
          How can you do this for yourself? If you were your biggest supporter,
          what would you say to yourself?
        </p>
      </div>
      <div
        style={{
          color: "var(--text-colour)",
        }}
        className="my-4 flex justify-center items-center"
      >
        <p className="text-2xl font-semibold underline">{priority}</p>
      </div>
      {journal && (
        <TextArea
          maxLength={500}
          journal={journal}
          answer={reflection}
          answerHandler={answerHandler}
        />
      )}

      <div className="mt-5">
        <BackAndNextFlat {...backAndNextHandler} />
      </div>
    </div>
  );
};

export default ReflectionSubmit;
