import { useFirestore } from "hooks/useFirestore";
import { projectFirestore } from "../../firebase/config";
import { useState } from "react";

const CopyUser = () => {
  const [from, setFrom] = useState("");
  const [to, setTo] = useState("");
  const { updateDocument } = useFirestore("users");

  const handleSubmit = async () => {
    console.log(from, to);

    let ref = projectFirestore.collection("users");
    const queryRef = ref.where("uid", "==", from);

    await queryRef
      .get()
      .then(async function (doc) {
        if (doc.docs.length > 0) {
          doc.docs.map((_user) => {
            console.log(_user.data());
            var data = {
              age: "",
              gender: "",
              online: false,
              dislayName: _user.data().dislayName, // there are times that displayName is returning undefined
              isEmailSent: false,
              id: to,
              progress: _user.data().progress,
            };
            console.log(data);
            console.log(to);
            updateDocument(to, data);

            return _user.id;
          });
        } else {
          console.log("no record");
        }
      })
      .catch(function (err) {
        console.log("Error getting documents: ", err);
      });
  };
  return (
    <div>
      <div>
        <label>Copy from : </label>
        <input type="text" onChange={(e) => setFrom(e.target.value)} />
      </div>
      <div>
        <label>Copy to : </label>
        <input type="text" onChange={(e) => setTo(e.target.value)} />
      </div>
      <button onClick={handleSubmit}>Submit</button>
    </div>
  );
};

export default CopyUser;
