import { useTour } from "@reactour/tour";
import SimpleLoader from "components/Loaders/SimpleLoader";
import EmptySpace from "components/utility/EmptySpace";
import useOrganisationContext from "hooks/organisation/useOrganisationContext";
import { useResourcesOrganisation } from "hooks/useResourcesOrganisation";
import usePromise from "hooks/utility/usePromise";
import { ResourceInterface } from "interface/ResourceInterface";
import classes from "pages/AllCircles/Admin/Admin.module.css";
import { FormEvent, useState } from "react";
import { toast } from "react-hot-toast";
import { inputClass } from "strings/InputClassStrings";
import ResourcesList from "./ResourcesList";

interface ResourceSettingsProps {
  resources: ResourceInterface[];
}

const ResourcesSettings = ({ resources }: ResourceSettingsProps) => {
  const { setIsOpen: setIsTourOpen } = useTour();
  const { selectedOrganisation } = useOrganisationContext();
  const [newResource, setNewResource] = useState("");
  const [url, setURL] = useState("");
  const { AddResources, GetOrganisationResources } = useResourcesOrganisation();
  const { isLoading, resolve } = usePromise();
  const [error, setError] = useState("");

  const submitHandler = async (e: FormEvent) => {
    e.preventDefault();
    const data = { title: newResource, url };
    resolve(() => AddResources(data)).then(() => {
      setNewResource("");
      setURL("");
      toast.success("New resources was successfully added.");
      GetOrganisationResources();
    });
  };

  const urlHandler = (url: string) => {
    // Validate URL
    const isUrlValid = /^https:\/\//.test(url);

    if (url.length > 0 && !isUrlValid) {
      setError("Invalid URL. Please make sure it starts with 'https://'.");
      setURL(url);
      return;
    } else {
      setError("");
      setURL(url);
    }
  };

  return (
    <>
      <EmptySpace />
      <button
        className={`${classes["button"]}`}
        onClick={() => setIsTourOpen(true)}
      >
        Show Tutorial
      </button>
      <EmptySpace />
      <div className={`${classes["container"]} mb-[100px] flex flex-col`}>
        <div className="mb-4">
          <p className="text-xl my-4 text-slate-600">
            {" "}
            Resources for{" "}
            <strong>{selectedOrganisation && selectedOrganisation.name}</strong>
          </p>
        </div>
        <form
          onSubmit={submitHandler}
          className="my-4 flex flex-col justify-start items-start gap-2 "
        >
          <div
            resources-settings-tour="resource-name-input"
            style={{ color: "var(--text-colour)" }}
          >
            <p className="font-semibold mt-4">Resource Name</p>
            <input
              className={inputClass}
              value={newResource}
              placeholder="Resource Name"
              onChange={(e: React.ChangeEvent<HTMLInputElement>) =>
                setNewResource(e.target.value)
              }
              required
            />
          </div>
          <div
            resources-settings-tour="resource-link-input"
            style={{ color: "var(--text-colour)" }}
          >
            <p className="font-semibold mt-4">Resource URL/Link</p>
            <input
              className={inputClass}
              value={url}
              placeholder="https://"
              onChange={(e: React.ChangeEvent<HTMLInputElement>) =>
                urlHandler(e.target.value)
              }
              required
            />
            <small className="text-red-400">{error}</small>
          </div>

          <button
            resources-settings-tour="add-resource-button"
            className={`${classes["button"]} my-4`}
            disabled={isLoading || error.length > 0}
            type="submit"
          >
            {isLoading ? "Submitting..." : "Add Resource"}
          </button>
        </form>

        {!isLoading ? (
          <ResourcesList resources={resources} />
        ) : (
          <SimpleLoader />
        )}
      </div>
    </>
  );
};

export default ResourcesSettings;
