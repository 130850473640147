import React, { ReactNode, useEffect, useState } from "react";
import classes from "./ReflectionRateOtherUsers.module.css";
import reflection from "./Reflection.module.css";
import stringReplace from "react-string-replace";
import { useAuthContext } from "hooks/useAuthContext";
import { Participant } from "interface/ParticipantInterface";
import { BadgeType } from "interface/FeedbackFormInterface";
import { renderBadgeIcon } from "components/ReflectionRating/Badges";
import OtherUsersRow from "components/ReflectionRating/OtherUsersRow";
import useRateOtherUsers from "hooks/useRateOtherUsers";
import { LocalStorageKeys } from "App";

interface RateOtherUsersProp {
  participants: Participant[];
}

interface OtherUsersConstants {
  TITLE: string;
  OTHER_USERS_TITLE: string;
}

/**
 * Page that displays rows of other users for the current user to rate
 */
const ReflectionRateOtherUsers: React.FC<RateOtherUsersProp> = ({
  participants,
}) => {
  const { profile } = useAuthContext();
  const userStorageKey = profile?.id + "_";
  const [showContent, setShowContent] = useState<boolean>(false);
  const { userRatings, dispatch: userRatingsDispatch } = useRateOtherUsers(
    participants.filter((participant) => participant.userId !== profile?.id),
    userStorageKey + LocalStorageKeys.REFLECTION.OTHER_BADGE_RATINGS
  );

  useEffect(() => {
    if (!!participants && participants.length > 0) {
      setShowContent(true);
    }
  }, [participants]);

  // useEffect(() => {
  //   console.log(userRatings);
  // }, [userRatings])

  const OTHER_USERS_CONSTANTS: OtherUsersConstants = {
    TITLE:
      "Now that we have come to the end of this session, let’s appreciate each other before we leave.",
    OTHER_USERS_TITLE:
      "Let’s put a check on the emojis that you think they deserve it",
  };

  const getBadgeDescription = (badge: BadgeType): Iterable<ReactNode> => {
    switch (badge) {
      case BadgeType.EFFORT:
        return stringReplace(
          "Appreciate their Efforts",
          /(Efforts)/g,
          (match, i) => (
            <span key={i} className={classes["switch-colour"]}>
              {match}
            </span>
          )
        );
      case BadgeType.CARE_AND_SUPPORT:
        return stringReplace(
          "Appreciate their Care and Support",
          /(Care and Support)/g,
          (match, i) => (
            <span key={i} className={classes["switch-colour"]}>
              {match}
            </span>
          )
        );
      case BadgeType.ROLE:
        return stringReplace(
          "Appreciate the Role they played",
          /(Role they played)/g,
          (match, i) => (
            <span key={i} className={classes["switch-colour"]}>
              {match}
            </span>
          )
        );
      default:
        return "Badge does not exist!";
    }
  };

  return (
    <div className={classes["other-users-container"]}>
      {/* Title */}
      <h1>{OTHER_USERS_CONSTANTS.TITLE}</h1>
      {/* Badge Description */}
      <div className={classes["badges"]}>
        {Object.keys(BadgeType).map((badge, index) => {
          return (
            <div key={index} className={classes["badge-row"]}>
              {renderBadgeIcon("white", BadgeType[badge])}
              &nbsp; &nbsp;
              <p>{getBadgeDescription(BadgeType[badge])}</p>
            </div>
          );
        })}
      </div>

      {/* Other Users List */}
      {showContent && (
        <div
          className={`${reflection["reflection-sub-container"]} ${reflection["white"]}`}
        >
          {/* title */}
          <div className={classes["other-users-title"]}>
            <h1>{OTHER_USERS_CONSTANTS.OTHER_USERS_TITLE}</h1>
            <hr />
          </div>
          <div className={classes["badge-title"]}>
            <p>Efforts</p>
            <p>
              Care & <br />
              Support
            </p>
            <p>Role</p>
          </div>
          {/* participants */}
          {
            //!! Change to userRatings mapping instead
            // participants
            // .filter((participant) => participant.userId !== profile.id)
            // .map((participant, index) =>
            //   <OtherUsersRow
            //     key={index}
            //     participant={participant}
            //     userRatings={userRatings}
            //     handleBadgeClick={userRatingsDispatch}
            //   />
            // )
            Object.keys(userRatings).map((userId, index) => (
              <OtherUsersRow
                key={index}
                participant={
                  participants.find(
                    (participant) => participant.userId === userId
                  )!
                }
                userRatings={userRatings}
                handleBadgeClick={userRatingsDispatch}
              />
            ))
          }
        </div>
      )}
    </div>
  );
};

export default ReflectionRateOtherUsers;
