export const TOPIC_INFOS = [
  {
    id: "1",
    key: "Types of Grievance Issues",
    learningItems: [
      `<p class="py-4">In the workplace, there can be General Grievances and Individual Grievances:</p>
        <p class="py-4"><strong>• General Grievance</strong> affect a group of employees. Possible examples include a wage cut or a retrenchment exercise that could involve several employees or the entire workforce.</p>
        <p class="py-4"><strong>• Individual Grievance</strong> affect one employee and requires a one-to-one approach. Possible examples of Individual Grievances include an employee who feels discriminated against in a promotion exercise or a case of sexual harassment.</p>
        <p class="py-4">When handling these grievances, unionised companies must communicate with and involve the Trade Union Representative and members of the Branch Committee as well.</p>
        `,
    ],
  },
  {
    id: "2",
    key: "Grievance Handling Procedures & Challenges",
    learningItems: [
      `<p class="py-4">For unionised companies, the grievance procedure is a requirement in all collective agreements. The Singapore Industrial Arbitration Court website provides sample clauses for grievance procedures to which unionised companies can take reference from.</p>

      <p class="py-4">The only difference between a unionised company and a non-unionised company is the presence of the Trade Union Representative (TUR) in the grievance handling process. An employee who is a Trade Union member has the right to seek assistance from the TUR and have the respective Trade Union’s involvement in resolving his grievances.</p>
      
      <p class="py-4">An illustration of a grievance process is provided below. <strong>Refer to page 18 of the Handbook</strong></p>
      
       <img src="https://firebasestorage.googleapis.com/v0/b/gc-testing-ebf45.appspot.com/o/files%2FTAFEP_handbook_500x500.png?alt=media&token=350f921d-2baa-440d-8d0d-4ce424012d7b" />
      `,
    ],
  },
  {
    id: "3",
    key: "Job Search",
    learningItems: [
      `<p class="py-4">Pick a topic to explore and discuss further. This leads to randomly generated questions for discussion
      </p> 
      `,
    ],
  },
  {
    id: "4",
    key: "Resume & Interview Preparation",
    learningItems: [
      `<p class="py-4">Pick a focus area to practice in. This leads to the generation of random interview questions in this area.</p> 
      `,
    ],
  },
  {
    id: "5",
    key: "Internship/Career preparation",
    learningItems: [
      `<p class="py-4">Pick a topic to explore and discuss further. This leads to randomly generated questions for discussion
      </p> 
      `,
    ],
  },
  {
    id: "6",
    key: "Academics and Co-curricular support",
    learningItems: [
      `<p class="py-4">Pick a topic to explore and discuss further. This leads to randomly generated questions for discussion</p> 
      `,
    ],
  },
  {
    id: "7",
    key: "Interview Preparation",
    learningItems: [
      `<p class="py-4">Pick a focus area to practice in. This leads to the generation of random interview questions in this area.</p> 
      `,
    ],
  },
  {
    id: "8",
    key: "Job Search & Resume Review",
    learningItems: [
      `<p class="py-4">Pick a focus area to practice in. This leads to the generation of random interview questions in this area.</p> 
      `,
    ],
  },
];
