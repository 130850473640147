import { ComponentProps } from "../SelfAwarenessComponentMapping";
import { FC, useEffect, useState } from "react";
import { AiFillCloseCircle, AiFillInfoCircle } from "react-icons/ai";
import { Modal } from "flowbite-react";
import classes from "pages/AllCircles/Admin/Admin.module.css";
import BackAndNextFlat from "components/utility/BackAndNextFlat";
import ConfirmModal from "../SubComponents/ConfirmModal";
import TextArea from "../SubComponents/TextArea";
import { useSelfAwarenessContext } from "hooks/useSelfAwarenessContext";

const SupportNeed: FC<ComponentProps> = ({
  backAndNextHandler,
  setHasModal,
}) => {
  const [supportNeeded, setSupportNeed] = useState<string>("");
  const [priority, setPriority] = useState<string>("");
  const [shareNeeds, setShareNeeds] = useState<boolean>(false);
  const [show, setShow] = useState<boolean>(false);
  const [showConfirm, setShowConfirm] = useState<boolean>(false);
  const { journal } = useSelfAwarenessContext();

  useEffect(() => {
    // Get the existing SelfAwarenessJournal from local storage
    const storedJournal = localStorage.getItem("SelfAwarenessJournal");
    const existingJournal = storedJournal ? JSON.parse(storedJournal) : {};

    setPriority(existingJournal.priority);
  }, []);

  // Load data from local storage on component mount
  useEffect(() => {
    const storedJournal = localStorage.getItem("SelfAwarenessJournal");
    if (storedJournal) {
      const parsedJournal = JSON.parse(storedJournal);
      setSupportNeed(parsedJournal.supportNeeded || "");
    }
    // eslint-disable-next-line
  }, []); // Empty dependency array means this effect runs once on mount

  const answerHandler = (value: string) => {
    setSupportNeed(value);
    const storedJournal = localStorage.getItem("SelfAwarenessJournal");
    const existingJournal = storedJournal ? JSON.parse(storedJournal) : {};

    // Update the existing journal with the new answer
    const updatedJournal = {
      ...existingJournal,
      supportNeeded: value,
    };

    // Store the updated journal in local storage
    localStorage.setItem(
      "SelfAwarenessJournal",
      JSON.stringify(updatedJournal)
    );
  };
  const handler = () => {
    setShow(!show);
    setHasModal(!show);
  };

  const setShareNeedsHandler = (value: boolean) => {
    setShareNeeds(value);
    const storedJournal = localStorage.getItem("SelfAwarenessJournal");
    const existingJournal = storedJournal ? JSON.parse(storedJournal) : {};

    // Update the existing journal with the new answer
    const updatedJournal = {
      ...existingJournal,
      shareInGrowthHub: value ? "Yes" : "No",
    };

    // Store the updated journal in local storage
    localStorage.setItem(
      "SelfAwarenessJournal",
      JSON.stringify(updatedJournal)
    );
  };

  const nextHandler = () => {
    setShowConfirm(!showConfirm);
    setHasModal(!showConfirm);
  };

  useEffect(() => {
    if (!journal) return;
  }, [journal]);

  return (
    <>
      {" "}
      <ConfirmModal
        showConfirm={showConfirm}
        handler={nextHandler}
        backAndNextHandler={backAndNextHandler}
        setHasModal={setHasModal}
      />
      <Modal show={show} popup={true} size={"sm"}>
        <div
          className={`${classes["container"]} p-5 relative flex flex-col`}
          style={{ color: "var(--text-colour)" }}
        >
          <AiFillCloseCircle
            style={{ color: "var(--icon-colour-0)" }}
            size={30}
            className="absolute top-2 right-2 cursor-pointer"
            onClick={handler}
          />
          <p className="my-8">
            Information from this journal entry will be shared on “Needs and
            Wants” in GrowthHub.
          </p>
          <button
            onClick={handler}
            className={`${classes["button"]} w-1/2 self-center`}
          >
            Okay
          </button>
        </div>
      </Modal>
      <div className="py-8 px-4 w-full md:w-1/4">
        <div
          className="relative p-5 border-2 rounded-lg font-poppins font-semibold"
          style={{
            backgroundColor: "#FFF4B9",
            borderColor: "#FFE871",
            color: "var(--text-colour)",
          }}
        >
          <p>How can someone support you with this need?</p>
        </div>
        <div
          style={{
            color: "var(--text-colour)",
          }}
          className="my-4 flex justify-center items-center"
        >
          <p className="text-2xl font-semibold underline">{priority}</p>
        </div>
        {journal && (
          <TextArea
            maxLength={500}
            journal={journal}
            answer={supportNeeded}
            answerHandler={answerHandler}
          />
        )}

        <div
          className="relative p-5 border-2 rounded-lg font-poppins bg-yellow-300/10"
          style={{
            borderColor: "#FFE871",
            color: "var(--text-colour)",
          }}
        >
          <AiFillInfoCircle
            size={25}
            style={{ color: "var(--icon-colour-0)" }}
            className="absolute top-2 right-2 cursor-pointer"
            onClick={handler}
          />
          <div className=" cursor-pointer flex items-center gap-4 select-none">
            <input
              type="checkbox"
              checked={shareNeeds}
              onChange={() => setShareNeedsHandler(!shareNeeds)}
              className="my-4 mr-2"
              style={{
                height: "20px",
                width: "20px",
                borderRadius: "5px",
                backgroundColor: shareNeeds ? "var(--icon-colour-0)" : "",
              }}
            />
            <p onClick={() => setShareNeedsHandler(!shareNeeds)}>
              {" "}
              Share this to “Needs and Wants” in GrowthHub.
            </p>
          </div>
        </div>

        <div className="mt-5">
          <BackAndNextFlat
            {...backAndNextHandler}
            nextCallback={nextHandler}
            nextAllowed={supportNeeded !== "" ? true : false}
          />
        </div>
      </div>
    </>
  );
};

export default SupportNeed;
