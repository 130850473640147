import { useEffect, useRef } from "react";

import { Modal } from "flowbite-react";
import classes from "../../pages/AllCircles/Admin/Admin.module.css";
import PrivacyPolicyContent from "./PrivacyPolicyContent";
import { AiFillCloseCircle } from "react-icons/ai";

interface PrivacyProps {
  show: boolean;
  onClick: () => void;
  canClose?: boolean;
  section: 'Privacy';
}

const ReadPrivacyModal = ({ show, onClick, canClose, section }: PrivacyProps) => {
  const validationMessageRef = useRef<HTMLDivElement | null>(null);

  const setAddModalErrorMsg = () => {
    // scrolls the validation message into view, and the block: 'nearest' ensures it scrolls the modal and not the window
    validationMessageRef.current?.scrollIntoView({ block: "nearest" });
  };

  const closeHandler = () => {
    onClick();
  };

  useEffect(() => {
    setAddModalErrorMsg();
  }, []);

  // The empty dependency array ensures this effect runs only once, when the component mounts
  return (
    <>
      <Modal show={show} size={"7xl"} className="z-[7000] md:mt-0">
        <Modal.Body className="relative">
          <div className="sticky right-0 top-0 p-2">
          {canClose && (
            <AiFillCloseCircle
              className="absolute border-none right-5 top-5 p-2 cursor-pointer"
              style={{ color: "var(--icon-colour-0)" }}
              onClick={closeHandler}
              size={50}
            />
          )}
          </div>
          <div
            className={`${classes["container"]} text-center flex flex-col items-center min-h-[600px]`}
          >
            <div ref={validationMessageRef}>
              <input
                type="text"
                className="h-1 focus:border-none border-none"
                autoFocus={true}
                readOnly={true}
              />
              <PrivacyPolicyContent startIndex={0}/>
            </div>
          </div>
        </Modal.Body>
      </Modal>
    </>
  );
};

export default ReadPrivacyModal;
