import { FeedbackQuestions } from "models/componentSettings/feedback/utility/feedback";
import { useState } from "react";

interface FeedbackSortCardProps {
  question: FeedbackQuestions;
  setOrder: Function;
  order: string[];
}

const FeedbackSortCard = ({
  question,
  setOrder,
  order,
}: FeedbackSortCardProps) => {
  const [selected, setIsSelected] = useState(true);

  const selectedHandler = () => {
    setIsSelected(!selected);
    setOrder((prevSelectedIds) => {
      if (!selected) {
        return [...prevSelectedIds, question.id];
      } else {
        return prevSelectedIds.filter((id: string) => id !== question.id);
      }
    });
  };

  return (
    <div
      onClick={selectedHandler}
      className={`relative bg-white flex justify-center items-center min-w-[150px]  min-h-[150px] shadow-md text-slate-700  cursor-pointer ${
        selected
          ? "border-4 border-[var(--icon-colour-0)] "
          : "border border-slate-400/70"
      }`}
    >
      {question.title}

      {question.id && selected && (
        <p className="absolute bottom-2 right-2 text-md font-semibold rounded-full text-default bg-[var(--icon-colour-0)] w-10 h-10 flex items-center justify-center text-center">
          {selected ? order.indexOf(question.id) + 1 : ""}
        </p>
      )}
    </div>
  );
};

export default FeedbackSortCard;
