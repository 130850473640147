export const LEARNING_OUTCOMES = [
  {
    id: "1",
    key: "Growth Circle 1 (GC1)",

    learningItems: [
      ` <h3 class="font-semibold mt-5 text-lg text-slate-700 my-4">
          Learning Outcomes
        </h3>
      <ul class="px-4 text-sm list-decimal">
        <li>Discuss the mental health and well-being landscape in Singapore.</li>
        <li>Explain the mental health continuum.</li>
        <li>Comment on the psychiatric classification systems.</li>
        <li>Describe lay person's perspectives of mental health.</li>
        <li>Identify the components in the Biopsychosocial model of mental health.</li>
        <li>Describe the biological aspects of mental health and well-being.</li>
        <li>Describe the social factors that influence mental health and wellbeing.</li>
        <li>Explain the associations between biological, social and psychological factors on mental health and illness.</li>
        <li>Discuss strategies to improve mental health and well-being.</li>
        <li>Explain how physical health, pleasure and spirituality are important components in strategies that improve mental health and well-being.</li>
      
       </ul>
    `,
    ],
  },
  {
    id: "2",
    key: "Growth Circle 2 (GC2)",

    learningItems: [
      ` <h3 class="font-semibold mt-5 text-lg text-slate-700 my-4">
          Learning Outcomes
        </h3>
    <ul class="px-4 text-sm list-decimal">
            <li>Appreciate how hyperactivity and challenging conduct are mental health issues that lie on a spectrum.</li>
            <li>Explain how excessive hyperactivity and challenging conduct may lead to mental disorders like ADHD, personality disorders in adults and conduct and oppositional defiant disorders in children.</li>
            <li>Describe mental health issues relating to self-harm, suicide and substance misuse.</li>
            <li>Explain how social media is associated with mental health and wellbeing.</li>
            <li>Describe the relationship between mass media and mental health and wellbeing.</li>
        </ul>
      `,
    ],
  },
  {
    id: "3",
    key: "Growth Circle 3 (GC3)",
    learningItems: [
      `
      <h3 class="font-semibold mt-5 text-lg text-slate-700 my-4">
        Learning Outcomes
      </h3>
      <ul class="px-4 text-sm list-decimal">
            <li>Identify the human experiences of sadness, fear, madness, trauma and adversity.</li>
            <li>Appreciate how sadness, fear, madness, and trauma and adversity are mental health issues that lie on a spectrum.</li>
            <li>Explain how excessive sadness, fear, madness, trauma and adversity may lead to mental disorders like depression, anxiety, psychoses and post-traumatic stress disorder.</li>
            <li>Appraise social stigma and promotion of mental health.</li>
        </ul>
        `,
    ],
  },

  {
    id: "4",
    key: "Types of Grievance Issues",
    learningItems: [
      `
      <h3 class="font-semibold mt-5 text-lg text-slate-700 my-4">
        What is a Grievance?
      </h3>
      <p>A grievance refers to a formal complaint based on feelings of dissatisfaction or perceptions of unfair treatment pertaining to an employee’s work or workplace; for example, when a company policy or code of ethics on anti-discrimination has been violated. </p>
      <h3 class="font-semibold mt-5 text-lg text-slate-700 my-4">
        Having a proper Grievance Procedure  
      </h3>
      <p>- allows for prompt response and quick resolution of a complaint or grievance, </p>
      <p class="py-4">- can boost employee morale and productivity</p>
      <p class="py-4">- avoid legal action</p>
        `,
    ],
  },
  {
    id: "5",
    key: "Grievance Handling Procedures & Challenges",
    learningItems: [
      `
      <h3 class="font-semibold mt-5 text-lg text-slate-700 my-4">
        What is a Grievance?
      </h3>
      <p>A grievance refers to a formal complaint based on feelings of dissatisfaction or perceptions of unfair treatment pertaining to an employee’s work or workplace; for example, when a company policy or code of ethics on anti-discrimination has been violated. </p>
      <h3 class="font-semibold mt-5 text-lg text-slate-700 my-4">
        Having a proper Grievance Procedure  
      </h3>
      <p>- allows for prompt response and quick resolution of a complaint or grievance, </p>
      <p class="py-4">- can boost employee morale and productivity</p>
      <p class="py-4">- avoid legal action</p>
        `,
    ],
  },

  {
    id: "6",
    key: "Flexible Work Arrangement",
    learningItems: [
      `<p class="py-8">Flexible work arrangements can be broadly classified under:</p>

      <p>Flexi-time</p>
      <p>Flexi-load</p>
      <p>Flexi-place</p>
      
      <p class="text-lg font-semibold py-8"> Why It Matters</p>
      
      <p class="py-1">1. Attract and retain talent competitively</p>
      <p class="py-1">2. Deploy manpower more nimbly.</p> 
      <p class="py-1">3. Helps your employees to become more productive, as they can better manage their work and personal responsibilities.</p>
      `,
    ],
  },

  {
    id: "7",
    key: "Growth Circle 4 (GC4)",
    learningItems: [
      `
      <h3 class="font-semibold mt-5 text-lg text-slate-700 my-4">
        Learning Outcomes
      </h3>
      <ul class="px-4 text-sm list-decimal">
            <li>Identify the human experiences of sadness, fear, madness, trauma and adversity.</li>
            <li>Appreciate how sadness, fear, madness, and trauma and adversity are mental health issues that lie on a spectrum.</li>
            <li>Explain how excessive sadness, fear, madness, trauma and adversity may lead to mental disorders like depression, anxiety, psychoses and post-traumatic stress disorder.</li>
           
        </ul>
        `,
    ],
  },
  {
    id: "8",
    key: "Growth Circle 4 Topics",

    learningItems: [
      `<ul class="px-4 text-sm list-decimal">
          <li>Appraise social stigma and promotion of mental health.</li>
            
        </ul>
        `,
    ],
  },
  {
    id: "9",
    key: "Academics and Co-curricular support",

    learningItems: [
      `<ul class="px-4 text-sm list-decimal">
          <li>Pick a topic to explore and discuss further. This leads to randomly generated questions for discussion
          </li>
            
        </ul>
        `,
    ],
  },
  {
    id: "10",
    key: "Job Search & Resume Review",

    learningItems: [
      `<ul class="px-4 text-sm list-decimal">
          <li>Pick a topic to explore and discuss further. This leads to randomly generated questions for discussion
          </li>
            
        </ul>
        `,
    ],
  },
  {
    id: "11",
    key: "Interview Preparation",

    learningItems: [
      `<ul class="px-4 text-sm list-decimal">
          <li>Pick a focus area to practice in. This leads to the generation of random interview questions in this area.
          </li>
            
        </ul>
        `,
    ],
  },
  {
    id: "12",
    key: "Internship/Career preparation",

    learningItems: [
      `<ul class="px-4 text-sm list-decimal">
          <li>Pick a focus area to practice in. This leads to the generation of random interview questions in this area.
          </li>
            
        </ul>
        `,
    ],
  },

  // Pick a focus area to practice in. This leads to the generation of random interview questions in this area.
];
