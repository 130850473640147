import { useDashboard } from "hooks/useDashboard";
import { useEffect, useState } from "react";
import classes from "./FacilitatorDetails.module.css";

interface DataStructure {
  count: number;
  username: string;
}

const FacilitatorDetails = () => {
  const [count, setCount] = useState<DataStructure[]>([]);
  const {
    facilNumbersMeet,
    getAllParticipants,
    getAllFacilitators,
    allParticipantHistory,
    allFacilitators,
    getUserRecordsCount,
  } = useDashboard();

  useEffect(() => {
    console.log(allParticipantHistory);
    console.log(allFacilitators);
    getAllParticipants();
    getAllFacilitators();
    const _count = getUserRecordsCount(allFacilitators, allParticipantHistory);
    console.log(_count);
    setCount(_count);
    // eslint-disable-next-line
  }, [facilNumbersMeet]);

  const totalCount = count.reduce((total, record) => total + record.count, 0);

  return (
    <div className="w-full flex justify-center items-center my-8">
      <table className={classes["table"]}>
        <thead>
          <tr>
            {" "}
            <th>Facilitator</th>
            <th># GC Sessions</th>
          </tr>
        </thead>
        <tbody>
          {count &&
            count.map((record, index) => (
              <tr key={index}>
                <td>{record.username}</td>
                <td>{record.count}</td>
              </tr>
            ))}{" "}
          <tr>
            <td>Total</td>
            <td>{totalCount}</td>
          </tr>
        </tbody>
      </table>
    </div>
  );
};

export default FacilitatorDetails;
