import { useState } from "react";

const useTabs = () => {
  const [activated, setActivated] = useState<boolean[]>([true, false, false]);

  const setFirstActivated = () => {
    setActivated([true, false, false]);
  };

  const setSecondActivated = () => {
    setActivated([false, true, false]);
  };

  const setThirdActivated = () => {
    setActivated([false, false, true]);
  };

  const isTabOpen = (tabIndex: number) => {
    return activated[tabIndex];
  };

  return {
    activated,
    setFirstActivated,
    setSecondActivated,
    setThirdActivated,
    isTabOpen,
  };
};

export default useTabs;
