import classes from "./TopicSelection.module.css";
import Carousel from "../ChooseRoles/Carousel";
import { useCarousel } from "hooks/useCarousel";
import React, { useState, useEffect } from "react";
import Topics from "./Topics";
import EmptySpace from "../utility/EmptySpace";
import useParticipants from "hooks/useParticipants";
import BackAndNext from "../utility/BackAndNext";
import { useNavigate } from "react-router-dom";
import useTopics from "hooks/useTopics";
import Center from "components/utility/Center/Center";
import {
  SUSS_CHOOSE_ACTIVITY,
  VIEW_CHOOSE_ROLES_ROUTE,
  VIEW_CHOOSE_TOPIC_ROUTE,
} from "strings/Routes";
import { GrowthCircleSession } from "interface/GrowthCircleSessionInterface";
import { AiFillInfoCircle } from "react-icons/ai";
import { Modal } from "flowbite-react";
import { MdCancel } from "react-icons/md";
import { LEARNING_OUTCOMES } from "strings/LearningOutcomes";

const isTopicCreated = (
  oldTopic: string,
  topics: { [key: string]: string[] }[]
) => {
  let isCreated = true;
  topics.forEach((generalTopic) => {
    const arrayOfTopics = generalTopic["topics"];
    arrayOfTopics.forEach((topic) => {
      if (topic === oldTopic) {
        isCreated = false;
      }
    });
  });
  return isCreated;
};

interface TopicSelectionProps {
  growthCircleSession: GrowthCircleSession | undefined;
  setHasModal?: Function;
}

const TopicSelection = ({
  growthCircleSession,
  setHasModal,
}: TopicSelectionProps) => {
  const { oldTopic, updateSelectedTopic } = useParticipants();

  // retrieve topics from database
  const { topics } = useTopics(growthCircleSession);

  const [isVisible, setIsVisible] = useState(false);

  // carousel
  const { currentIndex, next, prev } = useCarousel(topics.length);

  // currently selected topic
  const [currSelected, setCurrSelected] = useState("");

  // created topic
  const [createdTopic, setCreatedTopic] = useState("");

  const topic = topics[currentIndex];
  const topicContent = topic ? topic["topics"] : [];

  // fetch last chosen topic
  useEffect(() => {
    setCurrSelected(oldTopic);
    if (isTopicCreated(oldTopic, topics)) {
      setCreatedTopic(oldTopic);
    }
  }, [oldTopic, topics]);

  // navigate
  const navigate = useNavigate();

  // handle next
  const handleNext = () => {
    updateSelectedTopic(currSelected);
    navigate(VIEW_CHOOSE_TOPIC_ROUTE);
  };

  // handle back
  const handleBack = () => {
    if (growthCircleSession?.type === "SUSS") {
      navigate(SUSS_CHOOSE_ACTIVITY);
    } else {
      navigate(VIEW_CHOOSE_ROLES_ROUTE);
    }
  };

  //SUSS
  const getSUSStopicDisplay = (text: string) => {
    var subText = text;
    switch (text) {
      case "Growth Circle 1 Topics":
        subText = "GC1";
        break;
      case "Growth Circle 2 Topics":
        subText = "GC2";
        break;
      case "Growth Circle 3 Topics":
        subText = "GC3";
        break;
      case "Growth Circle 4 Topics":
        subText = "GC4";
        break;
      default:
        subText = text;
        break;
    }
    return subText;
  };

  const modalHandler = () => {
    setIsVisible(!isVisible);
    if (setHasModal) setHasModal(!isVisible);
  };

  return (
    <Center>
      <React.Fragment>
        <Modal show={isVisible} size="md" className="min-h-screen" popup={true}>
          <Modal.Body className="p-5 relative mt-[10%] md:mt-[5%]">
            <div className="absolute right-0 top-0 p-2">
              <MdCancel
                size={30}
                className="cursor-pointer"
                onClick={modalHandler}
                style={{ color: "var(--main-colour)" }}
              />
            </div>
            <div className="text-center py-5 px-2">
              <h3 className="font-semibold mt-5 text-lg text-slate-700">
                Learning Outcomes
              </h3>
              <EmptySpace height={"20px"} />
              <div className="text-slate-700 text-left">
                {topics[currentIndex] !== undefined && (
                  <ul>
                    {LEARNING_OUTCOMES.filter(
                      (learning) =>
                        learning.key === topics[currentIndex].general
                    ).map((learning) => (
                      <li
                        key={learning.id}
                        dangerouslySetInnerHTML={{
                          __html: `${learning.learningItems}`,
                        }}
                      ></li>
                    ))}
                  </ul>
                )}
              </div>
            </div>
          </Modal.Body>
        </Modal>
      </React.Fragment>
      {growthCircleSession?.type === "SUSS" && (
        <>
          <div className="relative w-full">
            <p className="text-md mx-auto w-[250px] text-center mt-5">
              Click on the “i” button to view the learning objectives.
            </p>
            <AiFillInfoCircle
              onClick={modalHandler}
              className="absolute top-2 right-12 cursor-pointer"
              size={30}
              style={{ color: "var(--main-colour)" }}
            />
          </div>
          <EmptySpace height={"10px"} />
        </>
      )}
      <form className={`${classes["form"]} mt-5`}>
        <input
          className={classes["input"]}
          placeholder={"Create your own topic..."}
          defaultValue={createdTopic}
          onChange={(e) => {
            setCreatedTopic(e.target.value);
            setCurrSelected(e.target.value);
          }}
        ></input>
      </form>
      <EmptySpace height={"30px"} />
      <div className={classes["carousel-container"]}>
        <Carousel
          children={topics.map((topic) => {
            const topicName = topic["general"];
            return (
              <div key={topicName} className={classes["topic-card"]}>
                <strong>{getSUSStopicDisplay(topicName)}</strong>
              </div>
            );
          })}
          next={next}
          prev={prev}
          currentIndex={currentIndex}
          displayCount={"1"}
          arrowOffset={"2%"}
          width={"50%"}
          arrowColor={"var(--main-colour-2)"}
        />
      </div>
      <EmptySpace height={"30px"} />
      <Topics
        content={topicContent}
        currSelected={currSelected}
        setCurrSelected={setCurrSelected}
        height={"300px"}
      />
      <EmptySpace height={"30px"} />

      {createdTopic && (
        <Topics
          content={[createdTopic]}
          currSelected={currSelected}
          setCurrSelected={setCurrSelected}
        />
      )}
      <EmptySpace height={"30px"} />

      <BackAndNext
        nextAllowed={!!currSelected}
        nextCallback={handleNext}
        backAllowed={true}
        backCallback={handleBack}
        backHidden={undefined}
      />
      <EmptySpace height={"30px"} />
    </Center>
  );
};

export default TopicSelection;
