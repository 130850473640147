import { useCallback, useMemo } from "react";
import { fetchSignInMethodsForEmail } from "firebase/auth";
import { updateDoc } from "firebase/firestore";
import { useAuthContext } from "./useAuthContext";
import {
  projectAuth,
  projectFirestore,
  serverTimestamp,
} from "../firebase/config";
import { Profile } from "interface/ProfileInterface";
import { FeedbackForm } from "interface/FeedbackFormInterface";
import { Participant } from "interface/ParticipantInterface";
import { BadgeStates } from "interface/FeedbackFormInterface";
import { useStorage } from "./useStorage";
import { getUserByEmail } from "models/profile";

/**
 * Methods involving database operations with the user
 * .get() counts as a read
 * or realtime listener gets a read/update to database
 */
const useUsers = () => {
  const { profile } = useAuthContext();
  const { deleteObject } = useStorage();

  const ref = useMemo(() => {
    return projectFirestore.collection("users");
  }, []);

  /**
   * Sets growthCircle field in user document to null to end the session
   */
  //TODO : this section is where the users gc id is being cleared after finishing the GC
  const endGCSessionForUser = async () => {
    const userDocRef = ref.doc(profile?.id);
    const updatedAt = serverTimestamp;

    await projectFirestore.runTransaction(async (transaction) => {
      await transaction.get(userDocRef).then((userDoc) => {
        if (!userDoc.exists) {
          throw new Error("Document does not exist!");
        }

        //Set growthCircles to null
        // const userDocData = userDoc.data();
        //console.log("ended called");
        transaction.update(userDocRef, {
          growthCircle: null,
          updatedAt: updatedAt,
        });
      });
    });
  };

  /**
   * Helper method to calculate progress for each user
   * @param badgeStates: BadgeStates
   * @param  totalSubmissions: number
   */
  const calculateRoleProgress = (
    badgeStates: BadgeStates | undefined,
    totalSubmissions: number
  ) => {
    if (!badgeStates) {
      return 10;
    }

    return Object.keys(badgeStates).reduce((prev, curr, index) => {
      //console.log(Math.floor(totalSubmissions / 2));
      if (badgeStates[curr] >= Math.floor(totalSubmissions / 2)) prev += 10;
      return prev;
    }, 10);
  };

  /**
   * Updates the user's progress based on badge ratings
   * @param - feedbackForm: FeedbackForm
   * @param - participants: Participant[]
   */
  // TODO: progress updates here
  const updateRoleProgress = async (
    feedbackForm: FeedbackForm,
    participants: Participant[],
    totalSubmissions: number
  ) => {
    const userDocRef = ref.doc(feedbackForm.userId);
    const updatedAt = serverTimestamp;

    await projectFirestore.runTransaction(async (transaction) => {
      await transaction.get(userDocRef).then((userDoc) => {
        if (!userDoc.exists) {
          throw new Error("Document does not exist!");
        }
        const userDocData = userDoc.data()!;

        //1. Get length of feedback forms submitted

        //2. Get role of participant
        const role: string | undefined = participants.find(
          (participant) => participant.userId === feedbackForm.userId
        )?.role?.role;

        if (!role) return;

        //Calculate progress
        const progress = calculateRoleProgress(
          feedbackForm.refBadgeRatings,
          totalSubmissions
        );

        //Increment number of times played
        const newNumOfTimesPlayed: number =
          userDocData.progress[role].numOfTimesPlayed + 1;
        //Get current progress of role
        let newProgress: number =
          userDocData.progress[role].progressPercentage + progress;
        let level: number = userDocData.progress[role].level
          ? userDocData.progress[role].level
          : 0;
        if (newProgress >= 100) {
          newProgress = newProgress % 100;
          level = level + 1;
        }
        // console.log(feedbackForm.userId);
        // console.log(newProgress);
        // console.log(level);
        // console.log(newNumOfTimesPlayed);

        const roleToUpdate: string = "progress." + role;

        transaction.update(userDocRef, {
          [roleToUpdate + ".numOfTimesPlayed"]: newNumOfTimesPlayed,
          [roleToUpdate + ".progressPercentage"]: newProgress,
          [roleToUpdate + ".level"]: level,
          updatedAt: updatedAt,
        });
      });
    });
  };

  /**
   * Fetches the sign in methods for a particular email address.
   * @param email Email to fetch sign in methods for.
   * @returns an array of possible sign in methods for that email.
   */
  const fetchSignInMethods = useCallback(async (email: string) => {
    return fetchSignInMethodsForEmail(projectAuth, email);
  }, []);

  const updateUserProfile = useCallback(
    async (userId: string, newProfile: Partial<Profile>) => {
      const userRef = ref.doc(userId);
      return updateDoc(userRef, newProfile).then(() => {
        if (newProfile.photoURL === null || newProfile.photoURL === "") {
          return deleteObject(`/profile_pictures/${userId}_500x500`);
        }
      });
    },
    [ref, deleteObject]
  );

  const checkUserFunction = getUserByEmail;

  const getAllUsersByIds = async (users: string[]) => {
    const userObjects: Profile[] = [];

    // Split the users array into chunks if needed
    // const chunkedUsers = chunkArray(users, 10);

    // Use Promise.all to handle parallel requests
    await Promise.all(
      users.map(async (userId) => {
        const res = await projectFirestore
          .collection("users")
          .where("uid", "==", userId)
          .get();

        const chunkUserObjects: Profile[] = res.docs.map(
          (doc) => doc.data() as Profile
        );
        userObjects.push(...chunkUserObjects);
      })
    );

    return userObjects;
  };

  const getAllUsers = useCallback(async () => {
    try {
      const snapshot = await projectFirestore.collection("users").get();
      const users = snapshot.docs.map((doc) => ({
        id: doc.id,
        ...doc.data(),
      })) as Profile[];
      return users;
    } catch (error) {
      console.error("Error fetching users:", error);
      return [];
    }
  }, []);

  return {
    fetchSignInMethods,
    endGCSessionForUser,
    updateRoleProgress,
    checkUserFunction,
    updateUserProfile,
    getAllUsersByIds,
    getAllUsers,
  };
};

export default useUsers;
