import { useGrowthCircleContext } from "hooks/useGrowthCircleContext";
import useParticipants from "hooks/useParticipants";
import { useEffect, useState } from "react";
import classes from "./ActivityCard.module.css";
import { ReactComponent as CloseIcon } from "../../assets/Xbutton.svg";
import { ReactComponent as InfoIcon } from "../../assets/NG/Activity/info-icon.svg";
import { Activity } from "interface/ActivityInterface";
import NGActivityIcons from "pages/AllCircles/NationalGalleryPages/NGActivityIcons";

const ActivityCard = (props: {
  activity: Activity;
  text: string;
  width: string;
  height: string;
  description?: string;
  hasImage?: boolean;
  viewMode?: boolean;
}) => {
  const {
    text,
    description,
    hasImage = true,
    activity,
    viewMode,
    height,
    width,
  } = props;
  const [active, setActive] = useState(false);
  const [selected, setSelected] = useState(false);
  const { updateSelectedActivity, ParticipantRecord, ParticipantRecords } =
    useParticipants();
  const [participantsIsLoaded, setParticipantsIsLoaded] = useState(false);
  const [count, setCount] = useState(0);
  const { growthCircleSession } = useGrowthCircleContext();

  useEffect(() => {
    if (!ParticipantRecord) return;
    if (!ParticipantRecords) return;
    setParticipantsIsLoaded(true);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [ParticipantRecord, ParticipantRecords]);

  useEffect(() => {
    if (!participantsIsLoaded) return;
    if (!growthCircleSession) return;
    const count = ParticipantRecords.filter(
      (record) => record.activity === text
    ).length;
    setCount(count);

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [participantsIsLoaded, text, ParticipantRecords, count]);

  const handleSelect = async () => {
    setSelected(!selected);
    if (selected === false) {
      updateSelectedActivity(text);
    } else {
      updateSelectedActivity("");
    }
  };

  return (
    <div
      className={`${classes.card} ${active ? classes.active : ""} `}
      onClick={handleSelect}
      style={{ width, minHeight: `calc(${height} * 1.1)`, height }}
    >
      {count > 0 && (
        <div className="absolute -bottom-2 -right-2 z-1000 bg-white rounded-full w-6 h-6 text-center border-2  border-main-colour text-[14px] text-main-colour">
          {count}
        </div>
      )}
      {ParticipantRecord.length > 0 && (
        <div
          style={{
            borderColor: viewMode
              ? "var(--icon-colour-0)"
              : "var(--main-colour)",
            width,
            minHeight: `calc(${height} * 1.1)`,
            height,
          }}
          className={`${classes["card-inner"]} ${
            ParticipantRecord[0].activity === text ? `border-8` : "border-2"
          }   `}
        >
          <div className={`${classes["card-front"]} py-2`}>
            <InfoIcon
              className="absolute top-1 right-1 cursor-pointer"
              onClick={() => setActive(!active)}
            />

            {hasImage &&
            activity.selectedImageURL !== "" &&
            activity.selectedImageIndex === -1 ? (
              <div
                className="w-full h-full p-2 max-h-[150px]"
                style={{
                  backgroundImage: `url(${activity.selectedImageURL})`,
                  backgroundPosition: "center",
                  backgroundSize: "cover",
                }}
              ></div>
            ) : (
              <div
                className={`flex flex-col justify-center items-center ${
                  hasImage ? "w-full h-full" : "h-2/5"
                }`}
              >
                {hasImage && (
                  <NGActivityIcons id={activity.selectedImageIndex ?? 0} />
                )}
              </div>
            )}

            <p
              style={
                ParticipantRecord[0].activity === text
                  ? {
                      color: viewMode
                        ? "var(--icon-colour-0)"
                        : "var(--main-colour)",
                    }
                  : { color: "var(--text-colour)" }
              }
              className={`p-2 text-center w-full ${
                hasImage ? "flex justify-center items-center h-auto" : ""
              } text-sm ${
                ParticipantRecord[0].activity === text ? `font-semibold` : ""
              } font-poppins `}
            >
              {text}
            </p>
          </div>
          <div className={`${classes["card-back"]} `}>
            <CloseIcon
              className="absolute right-1 top-1 cursor-pointer"
              onClick={() => setActive(!active)}
            />

            <p className={`${hasImage ? "" : "text-sm"}`}>{description}</p>
          </div>
        </div>
      )}
    </div>
  );
};

export default ActivityCard;
