import { QueryConstraint } from "firebase/firestore";
import { timestamp } from "../firebase/config";
import getModelOperations from "utility/model";
import SuperAdminQuestion from "interface/SuperAdminQuestionInterface";

const FIRESTORE_PATH_SUPER_ADMIN_QUESTIONS = "SuperAdminQuestions";

export const defaultSuperAdminQuestion: SuperAdminQuestion = {
  id: "",
  createdAt: timestamp.fromDate(new Date()),
  question: "",
};

// --- DB Helper Functions ---

const ops = getModelOperations(defaultSuperAdminQuestion);

function _setSuperAdminQuestion(
  superAdminQuestionId: string,
  superAdminQuestion: SuperAdminQuestion
) {
  const path = `${FIRESTORE_PATH_SUPER_ADMIN_QUESTIONS}/${superAdminQuestionId}`;
  return ops.setModel(path, superAdminQuestion);
}

function _deleteSuperAdminQuestion(superAdminQuestionId: string) {
  const path = `${FIRESTORE_PATH_SUPER_ADMIN_QUESTIONS}/${superAdminQuestionId}`;
  return ops.deleteModel(path);
}

async function _getSuperAdminQuestions(...queryConstraints: QueryConstraint[]) {
  const path = `${FIRESTORE_PATH_SUPER_ADMIN_QUESTIONS}`;
  const result = await ops.getModels(path, ...queryConstraints);
  return result;
}

// --- End DB Helper Functions ---

export function addSuperAdminQuestion(
  newSuperAdminQuestion: SuperAdminQuestion
) {
  return _setSuperAdminQuestion(
    newSuperAdminQuestion.id,
    newSuperAdminQuestion
  );
}

export const getSuperAdminQuestions = _getSuperAdminQuestions;

export const deleteSuperAdminQuestion = _deleteSuperAdminQuestion;
