import { useAuthContext } from "hooks/useAuthContext";
import SuperAdminQuestion from "interface/SuperAdminQuestionInterface";
import {
  addSuperAdminQuestion,
  deleteSuperAdminQuestion,
  getSuperAdminQuestions,
} from "models/superAdminQuestion";
import { useCallback, useEffect, useState } from "react";

/**
 * Keeps the state of a list of super admin questions.
 *
 * @returns SuperAdminQuestionHook.
 */
const useSuperAdminQuestion = () => {
  const { profile } = useAuthContext();
  const [superAdminQuestions, setSuperAdminQuestions] = useState<
    SuperAdminQuestion[]
  >([]);

  /**
   * Fetches super admin questions and sets the state of super admin questions.
   *
   * @returns A promise that resolves when the super admin questions are fetched.
   */
  const fetchSuperAdminQuestions = useCallback(async () => {
    if (!profile || !profile.access || !(profile.access === "admin")) {
      return;
    }

    const superAdminQuestions = await getSuperAdminQuestions();

    setSuperAdminQuestions(superAdminQuestions);
  }, [profile]);

  /**
   * Adds new super admin questions to the existing list of super admin questions.
   *
   * @param newSuperAdminQuestions - The array of new super admin questions to be added.
   * @returns A promise that resolves when all the new super admin questions have been added.
   */
  const addSuperAdminQuestions = useCallback(
    async (newSuperAdminQuestions: SuperAdminQuestion[]) => {
      const promises = newSuperAdminQuestions.map(
        async (newSuperAdminQuestion) => {
          try {
            await addSuperAdminQuestion(newSuperAdminQuestion);
          } catch (error) {
            console.error(error);
          }
        }
      );

      await Promise.all(promises);
      setSuperAdminQuestions([
        ...superAdminQuestions,
        ...newSuperAdminQuestions,
      ]);
    },
    [superAdminQuestions]
  );

  /**
   * Deletes multiple super admin questions.
   *
   * @param superAdminQuestionIds - An array of super admin question IDs to be deleted.
   */
  const deleteSuperAdminQuestions = useCallback(
    async (superAdminQuestionIds: string[]) => {
      const promises = superAdminQuestionIds.map(
        async (superAdminQuestionId) => {
          try {
            await deleteSuperAdminQuestion(superAdminQuestionId);
          } catch (error) {
            console.error(error);
          }
        }
      );

      await Promise.all(promises);
      setSuperAdminQuestions(
        superAdminQuestions.filter(
          (superAdminQuestion) =>
            !superAdminQuestionIds.includes(superAdminQuestion.id)
        )
      );
    },
    [superAdminQuestions]
  );

  useEffect(() => {
    fetchSuperAdminQuestions();
  }, [fetchSuperAdminQuestions]);

  return {
    superAdminQuestions,
    addSuperAdminQuestions,
    deleteSuperAdminQuestions,
  };
};

export default useSuperAdminQuestion;
