import { SelfAwarenessToolInterface } from "interface/SelfAwarenessToolInterface";
import { useEffect, useState } from "react";
import { BackAndNextProps } from "../SelfAwarenessComponentMapping";

import TextArea from "./TextArea";
import BackAndNextFlat from "components/utility/BackAndNextFlat";
import ValueFinalCard from "./ValueFinalCard";

const ValueRiskFinalGrade = ({
  journal,
  backAndNextHandler,
  nextHandler,
  backHandler,
  handler,
}: {
  journal: SelfAwarenessToolInterface;
  backAndNextHandler: BackAndNextProps;
  nextHandler: (e: React.MouseEvent<HTMLButtonElement, MouseEvent>) => void;
  backHandler: () => void;
  handler: () => void;
}) => {
  const [options, setOptions] = useState<{ [option: string]: number }[]>([]);

  const [gradingReflection, setGradingReflection] = useState("");

  // Load data from local storage on component mount
  useEffect(() => {
    const storedJournal = localStorage.getItem("SelfAwarenessJournal");

    if (storedJournal) {
      const parsedJournal = JSON.parse(storedJournal);

      if (parsedJournal && parsedJournal.valueGrading) {
        // Set the Options from parsedJournal if it exists
        setOptions(parsedJournal.valueGrading);
      }
      if (parsedJournal && parsedJournal.gradingReflection) {
        setGradingReflection(parsedJournal.gradingReflection);
      }
    }

    // eslint-disable-next-line
  }, []); // Empty dependency array means this effect runs once on mount

  const nextAllowedHandler = (
    e: React.MouseEvent<HTMLButtonElement, MouseEvent>
  ) => {
    handler();
  };

  const answerHandler = (value: string) => {
    setGradingReflection(value);
    const storedJournal = localStorage.getItem("SelfAwarenessJournal");
    const existingJournal = storedJournal ? JSON.parse(storedJournal) : {};

    // Update the existing journal with the new answer
    const updatedJournal = {
      ...existingJournal,

      gradingReflection: value,
    };

    // Store the updated journal in local storage
    localStorage.setItem(
      "SelfAwarenessJournal",
      JSON.stringify(updatedJournal)
    );
  };

  return (
    <div className={`flex flex-col`}>
      <div className="flex flex-col lg:flex-row gap-4 my-4 justify-center items-center">
        {options &&
          Object.entries(options)
            .sort(([, a], [, b]) => b[Object.keys(b)[0]] - a[Object.keys(a)[0]])
            .map(([key, value], index) => (
              <ValueFinalCard
                key={index}
                index={index}
                title={key} // Pass the title to your ValueFinalCard component
                value={value[Object.keys(value)[0]]} // Pass the value to your ValueFinalCard component
              />
            ))}
      </div>
      <p
        className="mt-4 font-poppins font-semibold"
        style={{ color: "var(--text-colour)" }}
      >
        How are you feeling now? Which are you inclined to choose?
      </p>
      {journal && (
        <TextArea
          maxLength={500}
          journal={journal}
          answer={gradingReflection}
          answerHandler={answerHandler}
        />
      )}

      <div className="my-4">
        <BackAndNextFlat
          {...backAndNextHandler}
          nextAllowed={gradingReflection.length > 0}
          backCallback={backHandler}
          nextCallback={(e) => nextAllowedHandler(e)}
        />
      </div>
    </div>
  );
};

export default ValueRiskFinalGrade;
