import FiveStepHeader from "components/FiveStepHeader/FiveStepHeader";
import Header from "components/Header/Header";
import Center from "components/utility/Center/Center";
import ContentWrapper from "components/utility/ContentWrapper";
import PageWrapper from "components/utility/PageWrapper";
import { Editor } from "react-draft-wysiwyg";
import { EditorState, convertFromRaw } from "draft-js";
import { useGrowthCircleContext } from "hooks/useGrowthCircleContext";
import NoGCSessionWrapper from "pages/NoGCSession/NoGCSessionWrapper";
import { useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
import { HOME_PAGE } from "strings/Routes";
import NGFooter from "components/Footers/NGFooter";
import { useAuthContext } from "hooks/useAuthContext";
import { getOrgByName } from "models/organisation";
import Organisation, {
  CustomTextEditorBlock,
} from "interface/OrganisationInterface";
import { WithId } from "utility/model";

const Finished = () => {
  const [editorState, setEditorState] = useState(EditorState.createEmpty());
  const navigate = useNavigate();
  const { growthCircleSession } = useGrowthCircleContext();
  const [hasTrigger, setHasTrigger] = useState(false);
  const { profile } = useAuthContext();

  const [initialContent, setInitialContent] =
    useState<CustomTextEditorBlock | null>(null);
  const [currentOrg, setCurrentOrg] = useState<WithId<Organisation> | null>(
    null
  );

  useEffect(() => {
    if (!growthCircleSession) return;
    const fetchData = async () => {
      const existingOrg = await getOrgByName(growthCircleSession?.type);
      if (!existingOrg) return;
      setCurrentOrg(existingOrg);
      setInitialContent(
        existingOrg.customEndGrowthcircles
          ? existingOrg.customEndGrowthcircles
          : null
      );
    };

    fetchData();

    // eslint-disable-next-line
  }, [growthCircleSession]);

  useEffect(() => {
    const contentState = convertFromRaw(
      initialContent || {
        blocks: [],
        entityMap: {},
      }
    );
    setEditorState(EditorState.createWithContent(contentState));
  }, [initialContent]);

  const _setTrigger = () => {
    setHasTrigger(!hasTrigger);
  };

  const currentStep = 5;

  const nextHandler = () => {
    navigate(HOME_PAGE);
  };

  useEffect(() => {
    if (!profile) return;

    // eslint-disable-next-line
  }, [profile]);

  useEffect(() => {
    //TODO: remove facil activity reset on end
    // if (profile?.uid === growthCircleSession?.facilOwner) {
    //   updateDocument(growthCircleSession?.uid, { Activity: [] });
    // }
    // eslint-disable-next-line
  }, []);

  return (
    <NoGCSessionWrapper>
      <PageWrapper pageType={"groundRules"}>
        <Header hasModal={hasTrigger} />
        <FiveStepHeader
          hasModal={hasTrigger}
          hasLightning={true}
          hasGuide={false}
          currentStep={currentStep}
          text="Completed"
          setIsTriggerVisible={_setTrigger}
        />
        <div className="relative w-full">
          <ContentWrapper hasFiveStepper={true}>
            {growthCircleSession ? (
              currentOrg &&
              currentOrg.enableCustomEndGrowthCircles &&
              initialContent ? (
                <Center>
                  <div className="bg-white flex flex-col justify-start items-center w-full h-auto rounded-xl shadow-lg py-4 px-4 md:px-14">
                    <div className="w-full flex flex-col justify-start">
                      <Editor
                        toolbar={{ options: [] }}
                        readOnly={true}
                        editorState={editorState}
                        toolbarClassName="border-none"
                        wrapperClassName="border-none"
                        editorClassName="p-2 select-none"
                        onEditorStateChange={() => {}}
                      />
                    </div>
                    <button
                      onClick={nextHandler}
                      style={{
                        color: "var(--main-colour)",
                        borderColor: "var(--main-colour)",
                      }}
                      className={`px-4 py-2 text-xl font-bold border-4 w-3/4 shadow-lg mx-auto mt-5 rounded-full  hover:text-white duration-1000 transition `}
                    >
                      Return To Home
                    </button>
                  </div>
                  <NGFooter type={growthCircleSession?.type} />
                </Center>
              ) : (
                <Center>
                  <div
                    className={`bg-white flex flex-col justify-start items-center w-full h-auto rounded-xl shadow-lg] py-4  px-4 md:px-14 shadow-lg`}
                  >
                    <div className="w-3/4 flex flex-col justify-start items-center">
                      <h1
                        style={{ color: "var(--main-colour)" }}
                        className={` font-semibold text-xl py-3`}
                      >
                        Thank you for joining{" "}
                        <span className="italic">Growth Circles</span>.
                      </h1>

                      <p className="mt-2 text-center   text-slate-800">
                        We hope you enjoyed your experience at the Growth
                        Circles.
                      </p>

                      <button
                        onClick={nextHandler}
                        style={{
                          color: "var(--main-colour)",
                          borderColor: "var(--main-colour)",
                        }}
                        className={`px-4 py-2  text-xl font-bold border-4 w-3/4 shadow-lg mx-auto mt-5 rounded-full  hover:text-white duration-1000 transition `}
                      >
                        Return To Home
                      </button>
                    </div>
                  </div>
                  <NGFooter type={growthCircleSession?.type} />
                </Center>
              )
            ) : (
              ""
            )}
          </ContentWrapper>
        </div>
      </PageWrapper>
    </NoGCSessionWrapper>
  );
};

export default Finished;
