import MainSessionTemplate from "components/utility/SessionTemplate/MainSessionTemplate";
import { useAuthContext } from "hooks/useAuthContext";
import { useCallback, useEffect, useState } from "react";
import { useParams } from "react-router-dom";
import ViewJournalSingleContent from "components/SharingPanel/ViewJournalSingleContent";
import "firebase/firestore";
import { ShareMessage } from "interface/MessageInterface";
import RequestVerifyEmailModal from "components/Journal/RequestVerifyEmailModal";
// import { getJournalByParticipantId } from "models/journal";
import { getShareMessagesByRecipientPID } from "models/shareMessage";
// import { Participant } from "interface/ParticipantInterface";
import { useParticipantHistoryJournals } from "hooks/useParticipantHistoryJournals";

/**
 * Page that opens when users want to view their journal
 */
const ViewJournal = () => {
  const { profile } = useAuthContext();
  const { participantId } = useParams();

  const [hasLightBox, setHasLightBox] = useState(false);
  const [messages, setMessages] = useState<ShareMessage[]>([]);
  const { selectedJournal } = useParticipantHistoryJournals();

  const fetchMessages = useCallback(async () => {
    if (!participantId || !profile || !selectedJournal) {
      return;
    }
    const shareMessages = await getShareMessagesByRecipientPID(
      participantId,
      profile.uid,
      true,
      true
    );
    setMessages(shareMessages);
  }, [participantId, profile, selectedJournal]);

  const fetchJournal = useCallback(async () => {
    if (!participantId) {
      return;
    }
  }, [participantId]);

  useEffect(() => {
    if (!profile) return;
    fetchJournal();
    fetchMessages();

    // eslint-disable-next-line
  }, [profile]);

  return (
    <MainSessionTemplate
      hasGuide={false}
      hasLightning={false}
      currentStep={7}
      removeNoGCWrapper={true}
      hasLightBox={hasLightBox || !profile?.isVerified}
    >
      <RequestVerifyEmailModal show={!profile?.isVerified} />
      {selectedJournal && (
        <ViewJournalSingleContent
          hasLightBox={setHasLightBox}
          userJournal={selectedJournal}
          participants={[]}
          messages={messages}
        />
      )}
    </MainSessionTemplate>
  );
};

export default ViewJournal;
