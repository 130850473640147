import { ReactNode } from "react";

type Props = {
  children: ReactNode;
};

export default function FeedbackFormTitle({ children }: Props) {
  return (
    <h2
      style={{ color: "var(--main-colour)" }}
      className={`text-xl font-bold py-4 select-none`}
    >
      {children}
    </h2>
  );
}
