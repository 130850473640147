import { useEffect, useState } from "react";
import { useFirestore } from "hooks/useFirestore";
import Role from "interface/RoleInterface";
import { Profile } from "interface/ProfileInterface";
import { useAuthContext } from "./useAuthContext";

interface Skill {
  [key: string]: Role[];
}

const getRoleNames = (skills: Skill[], generalSkillArray: string[]) => {
  const allRoleArray: string[] = [];
  for (let i = 0; i < generalSkillArray.length; i++) {
    const currGeneralSkill = generalSkillArray[i];
    const content = skills[i][currGeneralSkill];
    for (let x = 0; x < content.length; x++) {
      const roleObject = content[x];
      const role = roleObject.role;
      allRoleArray.push(role);
    }
  }
  return allRoleArray;
};

// skills is in the format of [{activeListening: {}} ...]
// this method gets all the field names
const getGeneralSkillArray = (skills: Skill[]) => {
  return skills.map((skill) => {
    delete skill.createdAt; // need to remove all fields until only left with skill object
    delete skill.id;
    return Object.keys(skill)[0];
  });
};

// This hook will update the user's database profile.progress object to contain
// any new skills that are in skills database but not in profile.progress object
const useUpdateProgress = (skills: Skill[], profile: Profile | null) => {
  const { updateDocument } = useFirestore("users");
  const [updatedProgressObject, setUpdatedProgressObject] = useState(false);
  const { dispatch, user } = useAuthContext();

  useEffect(() => {
    if (!skills || skills.length === 0 || !profile) return;
    const generalSkillArray = getGeneralSkillArray(skills);
    const allRoleArray = getRoleNames(skills, generalSkillArray);
    const newProgressObject = { ...profile.progress };
    for (let i = 0; i < allRoleArray.length; i++) {
      const currSkill = allRoleArray[i];
      if (profile.progress && profile.progress[currSkill]) continue;
      newProgressObject[currSkill] = {
        level: 0,
        progressPercentage: 0,
        numOfTimesPlayed: 0,
      };
    }

    //update Profile Context
    dispatch({
      type: "AUTH_IS_READY",
      payload: user,
      profile: { ...profile, progress: newProgressObject },
    });

    updateDocument(profile.uid, { progress: newProgressObject });
    setUpdatedProgressObject(true);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [skills]);

  return { updatedProgressObject };
};

export default useUpdateProgress;
