import { createPortal } from "react-dom";
import Button from "./Button";
import { ReactComponent as CrossCircle } from "../../assets/CrossCircle.svg";
import Backdrop from "./Backdrop";
import classes from "./ConfirmModal.module.css";

interface OverlayProps {
  title?: string;
  message: string;
  hasTitle?: boolean;
  hasMessage?: boolean;
  buttonFlexCol?: boolean;
  onAccept: () => any;
  onCancel: () => any;
  leftButtonText?: string;
  rightButtonText?: string;
  customModalContainerStyle?: { [key: string]: string };
}

const ConfirmOverlay: React.FC<OverlayProps> = ({
  title,
  hasTitle,
  hasMessage,
  buttonFlexCol,
  message,
  onAccept,
  onCancel,
  leftButtonText,
  rightButtonText,
  customModalContainerStyle, //Custom CSS fields for the container itself
}) => {
  const handleAccept = () => {
    onAccept();
  };

  const handleCancel = () => {
    onCancel();
  };

  const buttonStyle = {
    backgroundColor: "var(--main-colour)",
    color: "#FFFFFF",
    // border: "5px solid #EB8181",
    fontWeight: "700",
    fontSize: "1.1em",
    minHeight: "40px",
    // width: "203px",
    width: "40%",
    margin: "0",
  };

  return (
    <div className={`${classes.modal}`} style={customModalContainerStyle}>
      <div className={classes.icon}>
        <CrossCircle onClick={handleCancel} className={classes.close} />
      </div>

      {hasTitle && title && <span>{title}</span>}
      {hasMessage && (
        <div className={`${classes.terms} ${classes.paragraph}`}>
          {/* message here */}
          <p>{message}</p>
        </div>
      )}

      <div className={classes.buttons + " " + (hasMessage ? "" : classes.cols)}>
        <Button
          text={leftButtonText ? leftButtonText : "Yes"}
          style={buttonStyle}
          onClick={handleAccept}
        />
        <Button
          text={rightButtonText ? rightButtonText : "No"}
          style={buttonStyle}
          onClick={handleCancel}
        />
      </div>
    </div>
  );
};

interface Props {
  hasBackDrop: boolean;
  modalId: string;
  title?: string;
  hasTitle: boolean;
  hasMessage: boolean;
  buttonFlexCol?: boolean;
  message: string;
  onAccept: () => any;
  onClick: () => any;
  leftButtonText?: string;
  rightButtonText?: string;
  customModalContainerStyle?: { [key: string]: string };
}

//Template to open modals
const ConfirmModal: React.FC<Props> = ({
  hasBackDrop,
  modalId,
  onClick,
  onAccept,
  message,
  title,
  hasTitle,
  hasMessage,
  customModalContainerStyle,
  leftButtonText,
  rightButtonText,
}) => {
  return (
    <>
      {hasBackDrop && <Backdrop onClick={onClick} />}
      {createPortal(
        //Modal of choice
        <ConfirmOverlay
          hasMessage={hasMessage}
          hasTitle={hasTitle}
          title={title}
          message={message}
          onCancel={onClick}
          onAccept={onAccept}
          customModalContainerStyle={customModalContainerStyle}
          leftButtonText={leftButtonText}
          rightButtonText={rightButtonText}
        />,
        document.getElementById(modalId) as HTMLElement
      )}
    </>
  );
};

export default ConfirmModal;
