import EmptySpace from "components/utility/EmptySpace";

import { Suspense, lazy } from "react";
import SimpleLoader from "components/Loaders/SimpleLoader";

const SelfDiscoveryUserSettingList = lazy(
  () => import("./SelfDiscoveryUserSettingsList")
);

/**
 * Component to update an organisation's users settings.
 * @returns UsersSettings component.
 */
export default function SelfDiscoveryOrgUsers() {
  return (
    <div className="mb-[100px] text-standard">
      <EmptySpace />

      <b>List of users :</b>

      <Suspense fallback={<SimpleLoader />}>
        <SelfDiscoveryUserSettingList />
      </Suspense>
      <hr />
    </div>
  );
}
