import { getModelOperationsWithPath, WithId } from "../utility/model";
import { Profile } from "interface/ProfileInterface";
import { timestamp } from "../firebase/config";
import { UserCredential } from "firebase/auth";
import {
  SetOptions,
  UpdateData,
  where,
  WithFieldValue,
} from "firebase/firestore";
import toast from "react-hot-toast";

const FIRESTORE_PATH_USERS = "users";

const defaultProfileModel: Profile = {
  accountType: "",
  age: "",
  displayName: "",
  displayNameLowerCase: "",
  email: "",
  photoURL: "",
  firstname: "",
  lastname: "",
  gender: "",
  birthday: "",
  grow: [],
  growthCircle: "",
  id: "",
  isFacil: false,
  last_active: timestamp.fromDate(new Date()),
  createdAt: timestamp.fromDate(new Date()),
  online: true,
  progress: {},
  referral: [],
  uid: "",
  pageNumber: 1,
  region: "SINGAPORE, SINGAPORE",
};

// --- Helper Functions ---

const ops = getModelOperationsWithPath(
  FIRESTORE_PATH_USERS,
  defaultProfileModel
);

const _setUser: (
  userId: string,
  newUser: WithFieldValue<Profile>,
  setOptions?: SetOptions | undefined
) => Promise<void> = ops.setModel;

const _getUser: (userId: string) => Promise<WithId<Profile> | undefined> =
  ops.getModel;

const _getUsers = ops.getModels;

const _getUserWhere = ops.getModelWhere;

const _updateUser: (
  userId: string,
  userUpdates: UpdateData<Profile>
) => Promise<void> = ops.updateModel;

// --- End Helper functions ---

export const getUser = _getUser;

export const getUsers = _getUsers;

export const updateUser = _updateUser;

export function processUserSignUp(userCredential: UserCredential) {
  const user = userCredential.user;
  if (!user) {
    return Promise.resolve();
  }
  const userId = user.uid;
  const createUserDocument = _setUser(userId, defaultProfileModel);
  return Promise.all([createUserDocument]);
}

export async function processUserSignIn(
  userCredential: UserCredential,
  newProfile: Partial<Profile>
) {
  const user = userCredential.user;
  if (!user) {
    return Promise.resolve();
  }
  const userId = user.uid;
  const checkUserDocument = getUser(userId).then((user) => {
    if (!user) {
      const profile: Profile = {
        ...defaultProfileModel,
        ...(newProfile as Profile),
      };
      _setUser(userId, profile);
    }
  });
  await Promise.all([checkUserDocument]);
}

/**
 * Gets first user profile witht he given displayName.
 *
 * @returns The user profile if user exists, null otherwise.
 */
export function getUserById(userId: string): Promise<Profile | undefined>;
export function getUserById(
  userId: string,
  users: Profile[]
): Profile | undefined;
export function getUserById(userId: string, users?: Profile[]) {
  return users
    ? users.find((u) => u.uid === userId)
    : _getUserWhere(where("id", "==", userId));
}

export function getUserByEmail(email: string): Promise<Profile | undefined>;
export function getUserByEmail(
  email: string,
  users: Profile[]
): Profile | undefined;
export function getUserByEmail(email: string, users?: Profile[]) {
  return users
    ? users.find((u) => u.email === email)
    : _getUserWhere(where("email", "==", email));
}

export function getUserBydisplayNameLowerCase(
  displayNameLowerCase: string
): Promise<Profile | undefined>;
export function getUserBydisplayNameLowerCase(
  email: string,
  users: Profile[]
): Profile | undefined;
export function getUserBydisplayNameLowerCase(
  displayNameLowerCase: string,
  users?: Profile[]
) {
  return users
    ? users.find((u) => u.displayNameLowerCase === displayNameLowerCase)
    : _getUserWhere(where("displayNameLowerCase", "==", displayNameLowerCase));
}

export function getUsersByIds(
  userIds: string[] | undefined | null
): Promise<Profile[]> {
  if (!userIds) {
    // Return an empty array if userIds is undefined or null
    return Promise.resolve([]);
  }

  // Otherwise, proceed with fetching user profiles
  return Promise.all(userIds.map((userId) => getUserById(userId))).then(
    (profiles) => profiles.filter((p) => !!p) as Profile[]
  );
}

/**
 * Gets users based on their region.
 *
 * @param region The region to filter users by.
 * @returns Promise<Profile[]> A promise that resolves to an array of profiles matching the region.
 */
export async function getUsersByRegion(region: string): Promise<Profile[]> {
  try {
    const users = await getUsers(); // Fetch all users

    const filteredUsers = users.filter((user) => user.region === region);

    return filteredUsers;
  } catch (error) {
    console.error("Error fetching users by region:", error);
    throw error;
  }
}

/**
 * Exits a growthcircle session of a given sessionId.
 * Does nothing if the user is not in the specified session.
 *
 * @param userId Id of the user.
 * @param sessionId Id of the session.
 */
export async function exitGCSession(userId: string, sessionId: string) {
  const user = await getUserById(userId);
  if (!user || user.growthCircle !== sessionId) {
    return;
  }
  return updateUser(userId, {
    pageStep: "",
    growthCircle: "",
    pageNumber: 1,
  });
}

/**
 * Updates the progress of the user based on the current window path.
 *
 * @param userId Id of the user.
 */
export async function updateWindowLocationPath(userId: string) {
  return updateUser(userId, {
    pageStep: window.location.pathname,
  });
}

/**
 * Saves to the user profile indicating that user has acknowledged that a copy
 * of their reflections will be sent to their instructors.
 * For SUSS organisation.
 *
 * @param userId Id of the user.
 * @returns
 */
export async function acknowledgeReflectionCopyToInstructor(userId: string) {
  // TODO: move the acceptCopy field into organisation user object instead of general user object
  return updateUser(userId, {
    acceptCopy: true,
  });
}

/**
 * Updates the email sent status of the user.
 *
 * @param userId Id of the user.
 */
export async function resetEmailSentStatus(userId: string) {
  return updateUser(userId, {
    isEmailSent: false,
  });
}

/**
 * Updates all users to have the "Region" field set to "SINGAPORE, SINGAPORE".
 */
export async function updateAllUsersWithRegion() {
  try {
    const users = await getUsers(); // Fetch all users

    const updatePromises = users.map(async (user) => {
      await updateUser(user.id, { region: "SINGAPORE, SINGAPORE" });
    });

    await Promise.all(updatePromises);
    toast.success("Successfully updated all users.");
  } catch (error) {
    toast.error("Error updating users");
  }
}

/**
 * Updates the region of specified users.
 *
 * @param userIds Array of user IDs whose region needs to be updated.
 */
export async function updateUsersRegion(userIds: string[], newRegion: string) {
  try {
    const updatePromises = userIds.map(async (userId) => {
      await updateUser(userId, { region: newRegion });
    });

    await Promise.all(updatePromises);
    toast.success("Successfully updated users' regions.");
  } catch (error) {
    toast.error("Error updating users' regions.");
  }
}
