import { ChangeEventHandler, FC, useEffect, useState } from "react";
import PasswordVisibilityToggle from "./PasswordVisibilityToggle";
import AlertModal from "assets/AlertModal";
import { ReactComponent as ExclamationMark } from "assets/ExclamationMark.svg";
import classes from "./InputField.module.css";

interface Props {
  onChange: ChangeEventHandler<HTMLInputElement>;
  errorMessage?: string;
  placeholder?: string;
  isRequired?: boolean;
  id?: string;
}

/**
 * Input field for password.
 * Contains a eye icon to toggle visibility and an alert modal for error messages.
 *
 * @param onChange Change handler for the input field.
 * @param errorMessage Error message.
 * @param placeholder Placeholder text for the input.
 * @param isRequired Whether this input field is compulsory.
 * @param id Id of the input component.
 * @returns The PasswordField component
 */
const PasswordField: FC<Props> = ({
  errorMessage,
  placeholder,
  isRequired,
  onChange,
  id,
}) => {
  const [showPassword, setShowPassword] = useState(false);
  const [showAlertModal, setShowAlertModal] = useState(true);

  const visibilityToggleHandler = () => {
    setShowPassword(!showPassword);
  };

  const toggleAlertModal = () => {
    setShowAlertModal(!showAlertModal);
  };

  useEffect(() => {
    setShowAlertModal(true);
  }, [setShowAlertModal, errorMessage]);

  return (
    <div
      className={`${classes["input-placeholder"]} ${
        errorMessage && classes.error
      }`}
      style={{ margin: "10px" }}
    >
      <input
        placeholder={placeholder}
        className={classes.input}
        required={isRequired}
        onChange={onChange}
        type={showPassword ? "text" : "password"}
        autoComplete="on"
        id={id}
        autoFocus={true}
      />
      <PasswordVisibilityToggle
        showPassword={showPassword}
        visibilityToggleHandler={visibilityToggleHandler}
      />
      {errorMessage && (
        <div className={classes["alert-placeholder"]}>
          <ExclamationMark onClick={toggleAlertModal} />
          {showAlertModal && (
            <AlertModal text={errorMessage} className="cursor-pointer" />
          )}
        </div>
      )}
    </div>
  );
};

export default PasswordField;
