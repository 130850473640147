/* eslint-disable jsx-a11y/anchor-is-valid */
import { useLogout } from "hooks/useLogout";
import { Link } from "react-router-dom";
import { NavLink } from "react-router-dom";
import { FaHome, FaSignOutAlt } from "react-icons/fa";
import { useEffect, useState } from "react";
import Organisation from "interface/OrganisationInterface";
import { useAuthContext } from "hooks/useAuthContext";
import { getOrgsWithPermission } from "models/organisation";
import { P_CAN_VIEW_DASHBOARD } from "models/permission";

export default function NavAdmin() {
  const { logout } = useLogout();
  const { profile } = useAuthContext();

  const [administrator, setAdministrator] = useState<Organisation[]>([]);

  useEffect(() => {
    if (!profile) return;

    getOrgsWithPermission(profile, P_CAN_VIEW_DASHBOARD).then(async (orgs) => {
      setAdministrator(orgs);
    });
  }, [profile]);

  return (
    <nav className="bg-red-400 border-gray-200 shadow-md px-2 sm:px-4 py-4 dark:bg-gray-900">
      <div className="px-10 flex flex-wrap items-center justify-between mx-auto">
        <Link to="/all-circles/admin/dashboard" className="flex items-center">
          <span className="self-center w-full md:ml-0 ml-[120px] text-xl font-semibold whitespace-nowrap text-white">
            {administrator.length > 0 &&
            administrator[0].name &&
            !profile?.access
              ? administrator[0].name
              : "GrowthCircles"}
          </span>
        </Link>

        <div className="hidden w-full md:block md:w-auto" id="navbar-default">
          <ul className="flex flex-col p-4 mt-4 border border-gray-100 rounded-lg bg-gray-50 md:flex-row md:space-x-8 md:mt-0 md:text-sm md:font-medium md:border-0 md:bg-red-400 dark:bg-gray-800 md:dark:bg-gray-900 dark:border-gray-700">
            <li>
              <NavLink
                to="/"
                className="py-2 pl-3 pr-4 text-white bg-red-600 rounded md:bg-transparent md:text-white md:p-0 dark:text-white flex gap-2"
                aria-current="page"
              >
                {" "}
                <FaHome size={20} />
                Home
              </NavLink>
            </li>
            <li>
              <a
                onClick={logout}
                className="cursor-pointer  py-2 pl-3 pr-4 text-white flex gap-2 rounded  md:p-0"
              >
                <FaSignOutAlt size={20} /> Logout
              </a>
            </li>
          </ul>
        </div>
      </div>
    </nav>
  );
}
