import { SelfAwarenessToolInterface } from "interface/SelfAwarenessToolInterface";

import { BackAndNextProps } from "../SelfAwarenessComponentMapping";
import { useEffect, useState } from "react";
import ValueRiskComps from "./ValueRiskComps";
import ConfirmModal from "../SubComponents/ConfirmModal";
import ValueRiskFinalGrade from "../SubComponents/ValueRiskFinalGrade";

interface ValueRiskProps {
  journal: SelfAwarenessToolInterface;
  backAndNextHandler: BackAndNextProps;
  setLocalComponent: React.Dispatch<React.SetStateAction<string>>;
  setHasModal: (b: boolean) => any;
}
const ValueRisk = ({
  journal,
  backAndNextHandler,
  setLocalComponent,
  setHasModal,
}: ValueRiskProps) => {
  const [compIndex, setCompIndex] = useState<number>(0);
  const [options, setOptions] = useState<string[]>([]);
  const [showConfirm, setShowConfirm] = useState<boolean>(false);
  const [focusComponent, setFocusComponent] = useState<string>(
    "RiskComp" || "FinalValueComp"
  );
  // Load data from local storage on component mount
  useEffect(() => {
    const storedJournal = localStorage.getItem("SelfAwarenessJournal");

    if (storedJournal) {
      const parsedJournal = JSON.parse(storedJournal);

      if (parsedJournal && parsedJournal.Options) {
        setOptions(parsedJournal.Options);
      }
    }
    // eslint-disable-next-line
  }, []); // Empty dependency array means this effect runs once on mount

  const backHandler = () => {
    if (compIndex > 0) {
      setCompIndex((prevIndex) => prevIndex - 1);
    } else {
      setLocalComponent("DecisionSelection");
    }
  };

  const localBackHandler = () => {
    setFocusComponent("RiskComp");
  };

  const nextHandler = (e: React.MouseEvent<HTMLButtonElement, MouseEvent>) => {
    if (compIndex < options.length - 1) {
      setCompIndex((prevIndex) => prevIndex + 1);
    } else {
      setFocusComponent("FinalValueComp");
    }
  };
  const handler = () => {
    setShowConfirm(!showConfirm);
    setHasModal(!showConfirm);
  };

  return (
    <>
      <ConfirmModal
        setHasModal={setHasModal}
        backAndNextHandler={backAndNextHandler}
        handler={handler}
        showConfirm={showConfirm}
      />

      {options && focusComponent === "RiskComp" && (
        <ValueRiskComps
          journal={journal}
          setHasModal={setHasModal}
          backAndNextHandler={backAndNextHandler}
          backHandler={backHandler}
          nexHandler={nextHandler}
          option={options[compIndex]}
        />
      )}

      {options && focusComponent === "FinalValueComp" && (
        <ValueRiskFinalGrade
          handler={handler}
          journal={journal}
          backAndNextHandler={backAndNextHandler}
          backHandler={localBackHandler}
          nextHandler={nextHandler}
        />
      )}
    </>
  );
};

export default ValueRisk;
