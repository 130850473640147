import { ReactComponent as UserAvatar } from "assets/EndOfSession/UserAvatar.svg";
import { ReactComponent as PlusIcon } from "assets/EndOfSession/PlusIcon.svg";
import { ReactComponent as SentIcon } from "assets/EndOfSession/SentIcon.svg";
import classes from "components/EndOfSession/KeepInTouch.module.css";
import { Participant } from "interface/ParticipantInterface";

type KeepInTouchProps = {
  participants: Participant[];
  requestsSent: string[];
  handleSendRequest: (userId: any) => void;
};

/**
 * Component to add other participants as friends
 * @param {Participant[]} participants - all participants (including user)
 * @param {string[]} requestsSent - string array that stores the userIds of those the user wishes to add as friends
 * @param {(userId: any) => void} handleSendRequest - method to send friend requests to those users indicated
 */
const KeepInTouch = ({
  participants,
  requestsSent,
  handleSendRequest,
}: KeepInTouchProps) => {
  //Method to change participants name to initials
  const formatName = (participant: Participant): string => {
    const userName = participant.userName;

    const names = userName.trim().split(" ");
    //Currently
    const initials = names.reduce((total, currValue, currIdx) => {
      //Only pad the first and last word as initials
      if (currIdx === 0 || currIdx === names.length - 1) {
        total = `${total} ${currValue.charAt(0).toUpperCase()}`;
      }
      return total;
    }, "");

    // const formattedName = `${initials} #${participant.userId}`;
    const formattedName: string = initials;
    return formattedName;
  };

  return (
    <div className={classes["participantsContainer"]}>
      {/* Participants */}
      {participants.map((participant, index) => {
        return (
          <div
            key={participant.userId}
            className={classes["individualContainer"]}
          >
            <div className={classes["avatar"]}>
              <UserAvatar />
              <div
                onClick={() => handleSendRequest(participant.userId)}
                className={classes["action"]}
              >
                {requestsSent.includes(participant.userId) ? (
                  <SentIcon />
                ) : (
                  <PlusIcon fill="var(--main-colour)" />
                )}
              </div>
            </div>
            <p>
              {formatName(participant)}
              <br />
              {/* {`#${participant.userId}`} */}
            </p>
          </div>
        );
      })}
    </div>
  );
};

export default KeepInTouch;
