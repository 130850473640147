import React, { useContext, useEffect } from "react";
import classes from "./ReflectionQuestions.module.css";
import InnerContainer from "../Container/InnerContainer";
// import usePath from "../../../hooks/usePath";
import EmptySpace from "components/utility/EmptySpace";
import ViewJournalContext from "../ViewJournalContext";
import { useGrowthCircleContext } from "hooks/useGrowthCircleContext";

import { REFLECTION_COMPULSORY_QUESTIONS } from "strings/SharingPanel";
import Organisation from "interface/OrganisationInterface";
import DisplayQuestions from "./DisplayQuestions";

interface Props {
  path: string;
  generalReflectionQuestion: string[];
  personalReflectionQuestion: string[];
  compulsoryReflectionQuestions?: string[] | null;
  updatePersonalReflectionQuestion?: (question: string[]) => Promise<void>;
  updateGeneralReflectionQuestion?: (question: string[]) => Promise<void>;
  updateCompulsoryReflectionQuestion?: (question: string[]) => Promise<void>;
  topic: string;
  generalTopic?: string;
  parentTopicCategory?: string;
  isCustom: boolean;
  organisation?: Organisation;
}

const OrgViewReflectionQuestions: React.FC<Props> = ({
  path,
  generalReflectionQuestion,
  personalReflectionQuestion,
  compulsoryReflectionQuestions,
  updatePersonalReflectionQuestion,
  updateGeneralReflectionQuestion,
  updateCompulsoryReflectionQuestion,
  topic,
  generalTopic,
  parentTopicCategory,
  isCustom,
  organisation,
}) => {
  const { userJournal } = useContext(ViewJournalContext);
  // const { personalQuestion, generalQuestion } = usePath(path, generalTopic!);

  const tabNumber = "first";
  const place =
    userJournal && !userJournal.reflection && !userJournal.notes
      ? "top-and-bottom"
      : "top";
  const { growthCircleSession } = useGrowthCircleContext();
  useEffect(() => {
    if (!personalReflectionQuestion || !generalReflectionQuestion) return;

    // eslint-disable-next-line
  }, [personalReflectionQuestion, generalReflectionQuestion, isCustom]);

  useEffect(() => {}, [topic, generalTopic, growthCircleSession]);

  useEffect(() => {
    if (!compulsoryReflectionQuestions) return;

    // eslint-disable-next-line
  }, [compulsoryReflectionQuestions]);

  return (
    <InnerContainer tab={tabNumber} place={place}>
      {organisation?.components.includes("choose-activity") && (
        <p className="text-xl font-semibold text-center break-words w-full">
          {parentTopicCategory ?? path}
        </p>
      )}
      <EmptySpace />
      {organisation?.questionsType === "specific" && (
        <strong className={`my-4  text-center break-words w-full text-xl`}>
          On topic of {topic}
        </strong>
      )}
      {organisation?.questionsType === "specific" && (
        <>
          <DisplayQuestions
            Journal={userJournal}
            questionType="general"
            topic={topic}
          />
          <EmptySpace />
          <>
            {personalReflectionQuestion.length > 0 && (
              <strong className={classes["title"]}>
                Personal Questions for {topic}
              </strong>
            )}
            <DisplayQuestions
              Journal={userJournal}
              questionType="personal"
              topic={topic}
            />
          </>{" "}
        </>
      )}
      {organisation?.questionsType === "random" && (
        <>
          <strong className={classes["title"]}>
            General Questions for {topic}
          </strong>
          <DisplayQuestions
            questionType="general"
            Journal={userJournal}
            topic={topic}
          />
          <EmptySpace />
          <>
            {personalReflectionQuestion.length > 0 && (
              <strong className={classes["title"]}>
                Personal Questions for {topic}
              </strong>
            )}
            <DisplayQuestions
              Journal={userJournal}
              questionType="personal"
              topic={topic}
            />
          </>{" "}
        </>
      )}
      <EmptySpace />
      <>
        {compulsoryReflectionQuestions &&
          compulsoryReflectionQuestions.length > 0 && (
            <strong className={classes["title"]}>
              Compulsory Questions for {topic}
            </strong>
          )}
        <DisplayQuestions
          Journal={userJournal}
          questionType="compulsory"
          topic={topic}
        />
      </>{" "}
      <EmptySpace />
      <strong className={classes["title"]}>Follow-up Questions</strong>
      {REFLECTION_COMPULSORY_QUESTIONS.map((line) => {
        return (
          <p key={line} className={classes["question"]}>
            {line}
          </p>
        );
      })}
    </InnerContainer>
  );
};

export default OrgViewReflectionQuestions;
