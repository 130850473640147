import EmptySpace from "../../../components/utility/EmptySpace";
import BackAndNext from "../../../components/utility/BackAndNext";
import TopicResponse from "components/ViewChooseTopic/TopicResponse";
import React from "react";
import { ComponentProps } from "../GenericSessionPage/ComponentMapping";

const ViewChooseTopicComponent = ({ backAndNextHandler }: ComponentProps) => {
  return (
    <React.Fragment>
      <EmptySpace height={"30px"} />
      <TopicResponse />
      <EmptySpace height={"30px"} />
      <BackAndNext {...backAndNextHandler} />
      <EmptySpace height={"40px"} />
    </React.Fragment>
  );
};

export default ViewChooseTopicComponent;
