import { timestamp } from "../firebase/config";
import {
  QueryConstraint,
  UpdateData,
  WithFieldValue,
} from "firebase/firestore";
import {
  OnStoreChange,
  Unsubscribe,
} from "hooks/utility/useClientSyncExternalStore";
import { CheckInCheckOut, Participant } from "interface/ParticipantInterface";
import getModelOperations, { WithId } from "utility/model";
import { FIRESTORE_PATH_GC_SESSIONS } from "./growthCircleSession";

const FIRESTORE_SUBPATH_PARTICIPANTS = "Participants";

export const defaultCheckInCheckOut: CheckInCheckOut = {
  individually: -1,
  interpersonally: -1,
  overall: -1,
  socially: -1,
};

export const defaultParticipantModel: Participant = {
  userId: "",
  sessionInstance: "",
  email: "",
  userName: "",
  triggered: false,
  checkIn: defaultCheckInCheckOut,
  checkOut: defaultCheckInCheckOut,
  createdAt: timestamp.fromDate(new Date()),
  diceIndex: -1,
  id: "",
  topic: "",
  intentions: "",
  notes: "",
  path: "",
  reflection: "",
  compulsoryReflectionQuestion: [],
  personalReflectionQuestion: [],
  reflectionQuestion: [],
  organisationFields: [],
  isTrainee: false,
};

export const defaultNewParticipantModel = {
  id: "",
  origin: "",
  userId: "",
  email: "",
  userName: "",
  activity: "",
  triggered: false,
  growthCircleId: "",
  sessionInstance: "",
  isFacil: "",
  location: "",
  locationDetails: "",
  gender: "",
  birthday: "",
  photoURL: "",
  personalId: "",
  tutorialGroup: "", //group
  sessionRole: "",
  isTrainee: false,
  organisationFields: [],
};

// --- Helper Functions ---

const ops = getModelOperations(defaultParticipantModel);

function _addParticipant(
  sessionId: string,
  newParticipant: WithFieldValue<Participant>
) {
  const path = `${FIRESTORE_PATH_GC_SESSIONS}/${sessionId}/${FIRESTORE_SUBPATH_PARTICIPANTS}`;
  return ops.addModel(path, newParticipant);
}

function _getParticipant(sessionId: string, participantId: string) {
  const path = `${FIRESTORE_PATH_GC_SESSIONS}/${sessionId}/${FIRESTORE_SUBPATH_PARTICIPANTS}/${participantId}`;
  return ops.getModel(path);
}

function _getParticipants(
  sessionId: string,
  ...queryConstraints: QueryConstraint[]
) {
  const path = `${FIRESTORE_PATH_GC_SESSIONS}/${sessionId}/${FIRESTORE_SUBPATH_PARTICIPANTS}`;
  return ops.getModels(path, ...queryConstraints);
}

function _updateParticipant(
  sessionId: string,
  participantId: string,
  participantUpdates: UpdateData<Participant>
) {
  const path = `${FIRESTORE_PATH_GC_SESSIONS}/${sessionId}/${FIRESTORE_SUBPATH_PARTICIPANTS}/${participantId}`;
  return ops.updateModel(path, participantUpdates);
}

function _deleteParticipant(sessionId: string, participantId: string) {
  const path = `${FIRESTORE_PATH_GC_SESSIONS}/${sessionId}/${FIRESTORE_SUBPATH_PARTICIPANTS}/${participantId}`;
  return ops.deleteModel(path);
}

const _subscribeParticipants: (
  path: string,
  onStoreChange: OnStoreChange<WithId<Participant>[]>,
  ...queryConstraints: QueryConstraint[]
) => Unsubscribe = ops.subscribeModels;

// --- End Helper functions ---

export function addParticipant(
  sessionId: string,
  newParticipant: Partial<WithFieldValue<Participant>>
) {
  return _addParticipant(sessionId, {
    ...defaultParticipantModel,
    ...(newParticipant as any),
  });
}

export const getParticipantById = _getParticipant;

export const getParticipants = _getParticipants;

export const deleteParticipant = _deleteParticipant;

export function subscribeParticipants(
  sessionId: string,
  onStoreChange: OnStoreChange<WithId<Participant>[]>
) {
  return _subscribeParticipants(
    `${FIRESTORE_PATH_GC_SESSIONS}/${sessionId}/${FIRESTORE_SUBPATH_PARTICIPANTS}`,
    onStoreChange
  );
}

export function saveParticipantFeedback(
  sessionId: string,
  participantId: string,
  feedback: {}
) {
  return _updateParticipant(sessionId, participantId, {
    feedbackReflectionStatus: "submitted",
    feedbackReflection: feedback,
  });
}
