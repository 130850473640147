import classes from "./ChatSearch.module.css";
import { ReactComponent as SearchIcon } from "../../assets/Search.svg";
const ChatSearch = () => {
  return (
    <div className={classes["container"]}>
      <div className={classes["input-container"]}>
        <div className={classes["svg-container"]}>
          <SearchIcon />
        </div>

        <input type="text" />
      </div>

      <button type="button">Cancel</button>
    </div>
  );
};

export default ChatSearch;
