import { FC, useEffect, useState } from "react";
import { ComponentProps } from "../SelfAwarenessComponentMapping";
import { useSelfAwarenessContext } from "hooks/useSelfAwarenessContext";
import InfoModal from "../SubComponents/InfoModal";
import TitleBox from "../SubComponents/TitleBox";
import BackAndNextFlat from "components/utility/BackAndNextFlat";
import { useSelfAwareness } from "hooks/useSelfAwareness";
import { GradingQuestionInterface } from "interface/GradingQuestionInterface";
import GradingItemQuestion from "./GradingItemQuestion";
import SimpleLoader from "components/Loaders/SimpleLoader";

const GradingQuestions: FC<ComponentProps> = ({
  backAndNextHandler,
  setHasModal,
}) => {
  const { journal } = useSelfAwarenessContext();
  const [show, setShow] = useState<boolean>(false);
  const { getGradingQuestions } = useSelfAwareness();
  const [questions, setQuestions] = useState<GradingQuestionInterface[]>([]);

  const handler = () => {
    setShow(!show);
    setHasModal(!show);
  };

  const setGradingQuestion = async () => {
    if (journal) {
      const _questions = (await getGradingQuestions(
        journal.id || ""
      )) as GradingQuestionInterface[];
      setQuestions(_questions);
    }
  };

  useEffect(() => {
    if (!journal) return;
    setGradingQuestion();

    // eslint-disable-next-line
  }, [journal]);
  return (
    <div className="py-8 px-4 w-full md:w-1/4">
      {journal && <InfoModal handler={handler} show={show} journal={journal} />}
      {journal && (
        <>
          <TitleBox
            title={journal.gradingQuestionTitle || ""}
            showInfo={true}
            journal={journal}
            handler={handler}
          />

          {questions &&
            questions.length > 0 &&
            questions.map((question, index) => (
              <GradingItemQuestion
                journal={journal}
                key={index}
                question={question}
              />
            ))}

          {questions && questions.length === 0 && (
            <div className="min-h-[200px] flex justify-center items-center mx-auto">
              <SimpleLoader />
            </div>
          )}

          <div className="mt-5">
            <BackAndNextFlat {...backAndNextHandler} />
          </div>
        </>
      )}
    </div>
  );
};

export default GradingQuestions;
