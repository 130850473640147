import { useState } from "react";
import classes from "./ResourcePlate.module.css";
import DownArrow from "assets/DownArrow";
import EmptySpace from "components/utility/EmptySpace";

const ResourcePlate = ({ title }) => {
  const [isOpen, setIsOpen] = useState(false);

  return (
    <div className={classes["container"]}>
      <div
        className={`${classes["resource-plate-container"]} ${
          isOpen && classes["is-open"]
        }`}
        onClick={() => setIsOpen(!isOpen)}
      >
        <strong className={classes["title"]}>{title}</strong>
        <DownArrow
          fill={isOpen ? "var(--secondary-colour-2)" : "var(--main-colour)"}
        />
      </div>
      <EmptySpace />
      {isOpen && <div className={classes["resource-drop-down"]}></div>}
    </div>
  );
};

export default ResourcePlate;
