import classes from "./ChooseTopic.module.css";
import Header from "../../../components/Header/Header";
import FiveStepHeader from "../../../components/FiveStepHeader/FiveStepHeader";
import Steps from "../../../components/utility/Steps";
import EmptySpace from "../../../components/utility/EmptySpace";
import TopicSelection from "../../../components/ChooseTopics/TopicSelection";
import Center from "components/utility/Center/Center";
import NoGCSessionWrapper from "pages/NoGCSession/NoGCSessionWrapper";
import ContentWrapper from "components/utility/ContentWrapper";
import { CHOOSE_TOPIC_TITLE } from "strings/ChooseTopic";
import { useFirestore } from "hooks/useFirestore";
import { useAuthContext } from "hooks/useAuthContext";
import { useEffect, useState } from "react";
import { useGrowthCircleContext } from "hooks/useGrowthCircleContext";
import NGFooter from "components/Footers/NGFooter";

const ChooseTopic = () => {
  const stepsCount = 5;
  const currentStep = 4;
  const { profile } = useAuthContext();
  const { growthCircleSession } = useGrowthCircleContext();
  const { updateDocument } = useFirestore("users");
  const [hasTrigger, setHasTrigger] = useState(false);
  const [hasModal, setHasModal] = useState(false);

  const _setTrigger = () => {
    setHasTrigger(!hasTrigger);
  };

  useEffect(() => {
    if (!profile) return;
    if (!growthCircleSession) return;
    const data = { pageStep: window.location.pathname };
    updateDocument(profile.uid, data);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [profile]);

  return (
    <NoGCSessionWrapper>
      <div className="relative">
        <Header hasModal={hasTrigger || hasModal} />
        <FiveStepHeader
          hasLightning={true}
          hasGuide={true}
          currentStep={currentStep}
          hasModal={hasTrigger || hasModal}
          setIsTriggerVisible={_setTrigger}
        />
        <ContentWrapper hasFiveStepper={true}>
          <Center>
            <EmptySpace height={"1rem"} />
            <Steps count={stepsCount} currentStep={currentStep} />
            <EmptySpace height={"2rem"} />
            <strong className={classes["title"]}>{CHOOSE_TOPIC_TITLE}</strong>
            <TopicSelection
              setHasModal={setHasModal}
              growthCircleSession={growthCircleSession}
            />
          </Center>
        </ContentWrapper>
        <EmptySpace height={"10px"} />
        <NGFooter type={growthCircleSession?.type} />
      </div>
    </NoGCSessionWrapper>
  );
};

export default ChooseTopic;
