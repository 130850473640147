import { FC, useEffect, useState } from "react";
import TitleBox from "../SubComponents/TitleBox";
import { ComponentProps } from "../SelfAwarenessComponentMapping";
import { useSelfAwarenessContext } from "hooks/useSelfAwarenessContext";
import BackAndNextFlat from "components/utility/BackAndNextFlat";
import { colours } from "./styles/colourMap";
import TextArea from "../SubComponents/TextArea";

const TopListWithReflection: FC<ComponentProps> = ({
  backAndNextHandler,
  setHasModal,
}) => {
  const [show, setShow] = useState<boolean>(false);
  const { journal } = useSelfAwarenessContext();
  const handler = () => {
    setShow(!show);
    setHasModal(!show);
  };

  const [selectedItems, setSelectedItems] = useState<string[]>([]);
  const [answer, setAnswer] = useState("");
  // Load data from local storage on component mount
  useEffect(() => {
    const storedJournal = localStorage.getItem("SelfAwarenessJournal");
    const existingJournal = storedJournal ? JSON.parse(storedJournal) : {};
    if (existingJournal && existingJournal.listSelected) {
      setSelectedItems(existingJournal.listSelected);
    }
    // eslint-disable-next-line
  }, []);

  const answerHandler = (value: string) => {
    setAnswer(value);
    const storedJournal = localStorage.getItem("SelfAwarenessJournal");
    const existingJournal = storedJournal ? JSON.parse(storedJournal) : {};

    // Update the existing journal with the new answer
    const updatedJournal = {
      ...existingJournal,
      answer: value,
    };

    // Store the updated journal in local storage
    localStorage.setItem(
      "SelfAwarenessJournal",
      JSON.stringify(updatedJournal)
    );
  };

  useEffect(() => {
    if (!journal) return;
  }, [journal]);

  return (
    <div className="py-8 px-4 w-full md:w-1/4 ">
      {journal && (
        <>
          <TitleBox
            journal={journal}
            handler={handler}
            showInfo={false}
            title={journal.topListReflectionTitle || ""}
          />

          <div
            className="border-2 p-5 my-8 bg-white rounded-lg font-poppins  "
            style={{
              borderColor: colours[journal.pageType]["border"],
              color: "var(--text-colour)",
            }}
          >
            <div className="max-h-[300px] overflow-y-auto">
              {selectedItems.map((item, index) => (
                <div key={index} className="px-5 select-none">
                  <div
                    style={{ borderBottomColor: "var(--icon-colour-0)" }}
                    className={`border-b-2 p-2 flex gap-2 items-center  `}
                  >
                    {item}
                  </div>
                </div>
              ))}
            </div>
          </div>

          <TextArea
            maxLength={500}
            journal={journal}
            answer={answer}
            answerHandler={answerHandler}
          />
        </>
      )}
      <div className="my-4">
        <BackAndNextFlat {...backAndNextHandler} />
      </div>
    </div>
  );
};

export default TopListWithReflection;
