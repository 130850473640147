interface QuestionDataCardProps {
  entries: String[];
  title: string;
}

const QuestionDataCard = ({ entries, title }: QuestionDataCardProps) => {
  // Filter the entries to exclude strings with length less than 6
  const filteredEntries = entries.filter((entry) => entry.length >= 6);

  return (
    <>
      {filteredEntries && (
        <div className="border border-slate-400 flex-grow">
          <div className="bg-[#FADCAF] px-5 py-3">{title}</div>
          <div className="max-h-64 overflow-y-auto">
            {filteredEntries &&
              filteredEntries.map((entry, index) => (
                <div
                  key={index}
                  className="px-5 py-3 border border-slate-400/75"
                >
                  {entry}
                </div>
              ))}
          </div>
        </div>
      )}
    </>
  );
};

export default QuestionDataCard;
