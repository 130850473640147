import { useContext, useState } from "react";
import classes from "./Share.module.css";
import shareChatStyles from "./ShareChat.module.css";
import {
  SHARE_AND_SUPPORT_TITLE,
  SHARE_QUESTION_CHAT_TITLE,
} from "strings/SharingPanel";
import InnerContainer from "../Container/InnerContainer";
import ViewJournalContext from "../ViewJournalContext";
import ShareContext from "./ShareContext";
import { useAuthContext } from "hooks/useAuthContext";
import ShareMessagesSingleView from "./ShareMessagesSingleView";
import { ShareMessage } from "interface/MessageInterface";
import { Participant } from "interface/ParticipantInterface";

interface Props {
  messages: ShareMessage[];
  userJournal: Participant;
}

const ViewNGShare = ({ messages, userJournal }: Props) => {
  const tab: string = "second";
  const place: string = "top-and-bottom";
  const { participants } = useContext(ViewJournalContext);
  const { profile } = useAuthContext();
  const [messageSent, setMessageSent] = useState(false);

  const participant = participants.find((p) => p.userId === profile?.uid);
  const state = {
    messageSent,
    setMessageSent,
    currSelectedParticipant: participant ? participant : null,
    messages,
  };

  return (
    <ShareContext.Provider value={state}>
      <div className={classes["share-container"]}>
        <strong className={`${classes["title"]} text-center py-2`}>
          {SHARE_AND_SUPPORT_TITLE}
        </strong>
        <div className="w-full h-28 border-[7px] rounded-tl-3xl rounded-tr-3xl border-purple-300 bg-default p-5 flex justify-center items-center flex-col">
          {userJournal.path !== "" && (
            <p>
              {userJournal.location === "NG" ? "Activity" : "Pathway"} :{" "}
              <span
                className="font-semibold"
                style={{ color: `var(--main-colour)` }}
              >
                {userJournal.path}
              </span>
            </p>
          )}
          {userJournal.topic !== "" && (
            <p>
              Topic :{" "}
              <span
                className="font-semibold"
                style={{ color: `var(--main-colour)` }}
              >
                {userJournal.topic}
              </span>
            </p>
          )}
        </div>
        <InnerContainer place={place} tab={tab}>
          <strong className={shareChatStyles["title"]}>
            {SHARE_QUESTION_CHAT_TITLE}
          </strong>

          <ShareMessagesSingleView viewOnly={true} messages={messages} />
        </InnerContainer>
      </div>
    </ShareContext.Provider>
  );
};

export default ViewNGShare;
