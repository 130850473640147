import React from "react";
import classes from "./QuestionForm.module.css";

type QuestionFormType = {
  title: string;
  name: string;
  value: string;
  onChange: (e: React.FormEvent<HTMLTextAreaElement>) => void;
  isAutoFocus: boolean;
};

export default function QuestionForm({
  title,
  name,
  value,
  onChange,
  isAutoFocus,
}: QuestionFormType) {
  return (
    <div className={classes["question-form-container"]}>
      <h3>{title}</h3>
      <textarea
        id={name}
        required={true}
        // autoFocus={isAutoFocus}
        name={name}
        cols={30}
        rows={5}
        onChange={onChange}
        value={value}
      >
        {value}
      </textarea>
    </div>
  );
}
