import { useEffect, useState } from "react";
import SliderGrading from "./SliderGrading";

interface Criteria {
  criteriaText: string;
  weight: number;
}

interface CriterialItemProps {
  item: Criteria;
  option: string;
}

const CriterialItem = ({ item, option }: CriterialItemProps) => {
  // Retrieve the value from local storage or set it to 5 by default
  const storedJournal = localStorage.getItem("SelfAwarenessJournal");
  const existingJournal = storedJournal ? JSON.parse(storedJournal) : {};
  const initialValue =
    existingJournal.criteriaGrading?.[option]?.[item.criteriaText] !== undefined
      ? existingJournal.criteriaGrading[option][item.criteriaText]
      : 5;

  const [value, setValue] = useState(initialValue);

  useEffect(() => {
    const storedJournal = localStorage.getItem("SelfAwarenessJournal");
    const existingJournal = storedJournal ? JSON.parse(storedJournal) : {};

    // Ensure each option has its own object in criteriaGrading
    const updatedTopSelectionItem = {
      ...existingJournal.criteriaGrading,
      [option]: {
        ...(existingJournal.criteriaGrading?.[option] || {}),
        [item.criteriaText]: value,
      },
    };

    const updatedJournal = {
      ...existingJournal,
      criteriaGrading: updatedTopSelectionItem,
      // other properties of SelfAwarenessJournal...
    };

    localStorage.setItem(
      "SelfAwarenessJournal",
      JSON.stringify(updatedJournal)
    );
    // eslint-disable-next-line
  }, [value, item, option]);

  // Reset the state when the option changes
  useEffect(() => {
    setValue(initialValue);
    // eslint-disable-next-line
  }, [option]);

  return (
    <div
      className="p-5 w-full"
      style={{
        color: "var(--text-colour)",
      }}
    >
      <p className="font-semibold">{item.criteriaText}</p>
      <SliderGrading withBackground={true} value={value} setValue={setValue} />
    </div>
  );
};

export default CriterialItem;
