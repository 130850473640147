import { ChangeEventHandler } from "react";
import classes from "./PanelTutorialSelectField.module.css";

/**
 * Select Field for tutorial select for InfoPanel.
 *
 * @param handleChange Change handler for change in selected tutorial.
 * @param defaultValue Default value
 * @returns
 */

interface PanelFieldProps {
  handleChange: ChangeEventHandler<HTMLSelectElement>;
  label: string;
  options: string[];
  defaultValue?: string;
}

const PanelSelectField = ({
  handleChange,
  label,
  options,
  defaultValue,
}: PanelFieldProps) => {
  return (
    <div className="w-full">
      <label className="text-center py-2 text-default">{label}</label>
      <select
        defaultValue={defaultValue}
        onChange={handleChange}
        className={`${classes["box-select"]} bg-default py-3 px-4 text-center w-full text-slate-500 rounded-full outline-none border-none`}
      >
        {options?.map((option) => (
          <option key={option} value={option}>
            {option}
          </option>
        ))}
      </select>
    </div>
  );
};

export default PanelSelectField;
