import React, { useRef, useLayoutEffect } from "react";

/**
 * Component to mimc componentDidUpdate method in React since useEffect fires on initial render of useState
 * Strict mode applies in both production and development environments. However, double render only occurs in production
 * @param func: React.EffectCallback - the function you want to fire after dependency changes
 * @param dependencies: React.DependencyList - the list of dependencies to keep track
 */
export const useComponentDidUpdate = (
  func: React.EffectCallback,
  dependencies: React.DependencyList | undefined
) => {
  const firstUpdate = useRef(true);
  const secondUpdate = useRef(true);
  let isNotStrict = process.env.NODE_ENV === "production"; //Checks if it is in production

  useLayoutEffect(() => {
    if (firstUpdate.current) {
      // console.log("First render");
      firstUpdate.current = false;
      return;
    }

    if (!isNotStrict && secondUpdate.current) {
      // console.log("Is strict");
      secondUpdate.current = false;
      return;
    }

    func();

    // console.log("componentDidUpdate");
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, dependencies);
};
