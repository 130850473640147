import classes from "./SettingsIntentions.module.css";
import { useAuthContext } from "hooks/useAuthContext";
import EmptySpace from "components/utility/EmptySpace";
import SubmitIntention from "components/SettingIntentions/SubmitIntention";
import OthersIntention from "components/SettingIntentions/OthersIntention";
import Instruction from "components/utility/Instruction";
import BackAndNext from "components/utility/BackAndNext";
import { ComponentProps } from "../GenericSessionPage/ComponentMapping";

const INSTRUCTIONS_SET_INTENTIONS =
  "What would you like to take away from the session?";

export default function SettingIntentionsComponent({
  backAndNextHandler,
}: ComponentProps) {
  const { profile } = useAuthContext();

  return (
    <>
      <EmptySpace height={"2rem"} />

      <div className={classes.container}>
        <div className={classes.description}>
          <p className="text-md">If this session could gift you something,</p>
          <p className="text-md">what would it be?</p>
        </div>
        <Instruction text={INSTRUCTIONS_SET_INTENTIONS} />
      </div>

      <EmptySpace height={"2rem"} />
      <SubmitIntention profile={profile} />
      <EmptySpace height={"5px"} />
      <OthersIntention />
      <EmptySpace height={"30px"} />
      <BackAndNext {...backAndNextHandler} />
    </>
  );
}
