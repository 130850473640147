import classes from "./Admin.module.css";

import SidebarAdmin from "components/Admin/SidebarAdmin";
import NavAdmin from "components/Admin/NavAdmin";
import { lazy, Suspense } from "react";

const AdminOneGeneralSkill = lazy(
  () => import("components/Admin/AdminSkills/AdminOneGeneralSkill")
);

const AdminSkills = () => {
  return (
    <div>
      <NavAdmin />
      <div className={classes.container}>
        <SidebarAdmin />
        <div className={classes.main}>
          <div className={classes.title}>
            <h4>Skills</h4>
          </div>
          <Suspense>
            <AdminOneGeneralSkill />
          </Suspense>
        </div>
      </div>
    </div>
  );
};

export default AdminSkills;
