import firebase from "firebase/compat/app";
import { DocumentData } from "firebase/firestore";
import { QuestionInterface } from "interface/QuestionsInterface";
import { useCallback, useEffect, useMemo, useState } from "react";
import { projectFirestore, timestamp } from "../firebase/config";
import useOrganisationContext from "./organisation/useOrganisationContext";

export const useOrganisationTopics = () => {
  const { selectedOrganisation } = useOrganisationContext();
  const [topics, setTopics] = useState<DocumentData[]>([]);
  const [activityTopics, setActivityTopics] = useState<DocumentData[]>([]);
  const [generalTopics, setGeneralTopics] = useState<DocumentData[]>([]);

  const DeleteCategory = useCallback(
    async (id: string) => {
      try {
        const selectedOrganisationId = selectedOrganisation?.id;

        // Batch all the actions so that either all succeeds or all fails
        const batch = projectFirestore.batch();

        // Step 1: Delete the topic from the main collection
        const categoryRef = await projectFirestore
          .collection("organisations")
          .doc(selectedOrganisationId)
          .collection("topics")
          .doc(id);

        batch.delete(categoryRef);

        // Step 2: Remove the topic ID from assignedTopic array in activities collection
        const activitiesRef = projectFirestore
          .collection("organisations")
          .doc(selectedOrganisationId)
          .collection("activities");

        const activitiesSnapshot = await activitiesRef
          .where("assignedTopics", "array-contains", id)
          .get();

        activitiesSnapshot.forEach((doc) => {
          const docRef = activitiesRef.doc(doc.id);
          batch.update(docRef, {
            assignedTopics: firebase.firestore.FieldValue.arrayRemove(id),
          });
        });

        // Step 3: Delete all questions under the category
        const questionsToDeleteSnapshot = await projectFirestore
          .collection("organisations")
          .doc(selectedOrganisationId)
          .collection("questions")
          .where("topicCategoryId", "==", id)
          .get();

        questionsToDeleteSnapshot.forEach((doc) => {
          batch.delete(doc.ref);
        });

        // Finally commit the entire transaction
        await batch.commit();
      } catch (error) {
        console.error(error);
      }
    },
    [selectedOrganisation]
  );

  const AddNewTopic = useCallback(
    async (topic: string, categoryId: string) => {
      try {
        const categoryRef = projectFirestore
          .collection("organisations")
          .doc(selectedOrganisation?.id)
          .collection("topics")
          .doc(categoryId);

        const categoryDoc = await categoryRef.get();
        if (categoryDoc.exists) {
          const categoryData = categoryDoc.data();

          if (categoryData) {
            const existingGeneral = new Set(categoryData.general || []);
            const newTopics = topic.split(",").map((t) => t.trim());

            newTopics.forEach((newTopic) => {
              existingGeneral.add(newTopic);
            });

            const updatedGeneral = Array.from(existingGeneral);

            await categoryRef.update({
              general: updatedGeneral,
            });
          }
        }
      } catch (error) {
        console.error(error);
      }
    },
    [selectedOrganisation]
  );

  const updateTopicName = useCallback(
    async (categoryId: string, topicName: string) => {
      try {
        projectFirestore
          .collection("organisations")
          .doc(selectedOrganisation?.id)
          .collection("topics")
          .doc(categoryId)
          .update({ category: topicName });
      } catch (error) {
        console.error(error);
      }
    },
    [selectedOrganisation]
  );

  const updateOrganisationTopicSettings = useCallback(
    async (settingCheck: Object) => {
      try {
        projectFirestore
          .collection("organisations")
          .doc(selectedOrganisation?.id)
          .update(settingCheck);
      } catch (error) {
        console.error(error);
      }
    },
    [selectedOrganisation]
  );

  const updateTopicSettings = useCallback(
    async (
      settingCheck: { hasInfo: boolean },
      InfoContent: string,
      topicId: string
    ) => {
      const data = { hasInfo: settingCheck.hasInfo, InfoContent };

      try {
        projectFirestore
          .collection("organisations")
          .doc(selectedOrganisation?.id)
          .collection("topics")
          .doc(topicId)
          .update(data);
      } catch (error) {
        console.error(error);
      }
    },
    [selectedOrganisation]
  );

  const DeleteTopic = useCallback(
    async (topicToDelete: string, categoryId: string) => {
      if (!selectedOrganisation) {
        return;
      }

      try {
        // Batch all the actions so that either all succeeds or all fails
        const batch = projectFirestore.batch();

        // Step 1: Delete the topic from the array of topics in the category doc
        const categoryRef = projectFirestore
          .collection("organisations")
          .doc(selectedOrganisation.id)
          .collection("topics")
          .doc(categoryId);

        const categoryDoc = await categoryRef.get();
        if (categoryDoc.exists) {
          const categoryData = categoryDoc.data();

          if (categoryData) {
            const existingGeneral = categoryData.general || [];

            const updatedGeneral = existingGeneral.filter(
              (topic) => topic !== topicToDelete
            );

            batch.update(categoryRef, {
              general: updatedGeneral,
            });

            // Step 2: Delete all questions under the topic
            const questionsToDeleteSnapshot = await projectFirestore
              .collection("organisations")
              .doc(selectedOrganisation.id)
              .collection("questions")
              .where("topicCategoryId", "==", categoryId)
              .get();

            questionsToDeleteSnapshot.forEach((doc) => {
              const data: QuestionInterface = doc.data() as QuestionInterface;

              if (data.topic === topicToDelete) {
                batch.delete(doc.ref);
              }
            });

            // Commit the transaction
            await batch.commit();
          }
        }
      } catch (error) {
        console.error(error);
      }
    },
    [selectedOrganisation]
  );

  const AddNewCategory = useCallback(
    async (category: string) => {
      try {
        const data = {
          createdAt: timestamp.fromDate(new Date()),
          category: category,
          organisationId: selectedOrganisation?.id,
          general: [],
          hasInfo: false,
        };
        const ref = await projectFirestore
          .collection("organisations")
          .doc(selectedOrganisation?.id)
          .collection("topics")
          .add(data);
        await ref.update({ id: ref.id });
      } catch (error) {
        console.error(error);
      }
    },
    [selectedOrganisation]
  );

  const getActivityTopics = useMemo(
    () => async (activityTitle: string) => {
      let activityUnsubscribe: (() => void) | undefined;
      let topicUnsubscribes: (() => void)[] = [];

      try {
        const activityQuery = projectFirestore
          .collection("organisations")
          .doc(selectedOrganisation?.id)
          .collection("activities")
          .where("title", "==", activityTitle);

        activityUnsubscribe = activityQuery.onSnapshot((activitySnapshot) => {
          const updatedTopics: DocumentData[] = [];

          // Clean up previous topic subscriptions
          topicUnsubscribes.forEach((unsubscribe) => unsubscribe());
          topicUnsubscribes = [];

          activitySnapshot.forEach((doc) => {
            const assignedTopicIds = doc.data().assignedTopics ?? [];

            assignedTopicIds.forEach((topicId: string) => {
              const topicQuery = projectFirestore
                .collection("organisations")
                .doc(selectedOrganisation?.id)
                .collection("topics")
                .where("id", "==", topicId);

              const topicUnsubscribe = topicQuery
                .get()
                .then((topicSnapshot) => {
                  if (!topicSnapshot.empty) {
                    topicSnapshot.forEach((topicDoc) => {
                      updatedTopics.push(topicDoc.data() as DocumentData);
                    });

                    setActivityTopics(updatedTopics);
                  } else {
                    console.warn(
                      `No document found for topic with ID ${topicId}`
                    );
                  }
                });

              topicUnsubscribes.push(() => topicUnsubscribe);
            });
          });
        });
      } catch (error) {
        console.error(error);
      }

      return () => {
        if (activityUnsubscribe) {
          activityUnsubscribe();
        }
        topicUnsubscribes.forEach((unsubscribe) => unsubscribe());
      };
    },
    [selectedOrganisation]
  );

  const getAllTopics = () => {
    const updatedTopics: DocumentData[] = [];
    let topicUnsubscribe: (() => void) | undefined;

    try {
      const topicsQuery = projectFirestore
        .collection("organisations")
        .doc(selectedOrganisation?.id)
        .collection("topics");

      topicUnsubscribe = topicsQuery.onSnapshot((topicsSnapshot) => {
        updatedTopics.length = 0; // Clear the array
        topicsSnapshot.forEach((topicSnapshot) => {
          updatedTopics.push(topicSnapshot.data() as DocumentData);
        });

        setActivityTopics(updatedTopics);

        // Set the state or do whatever you need with the topics
        // setTopicData(updatedTopics);
      });
    } catch (error) {
      console.error(error);
    }

    return () => {
      if (topicUnsubscribe) {
        topicUnsubscribe();
      }
    };
  };

  const getAllTopicsByOrgName = async (name: string) => {
    try {
      // Step 1: Get the organization document
      const orgQuerySnapshot = await projectFirestore
        .collection("organisations")
        .where("name", "==", name)
        .get();

      if (orgQuerySnapshot.empty) {
        // Organization not found
        return [];
      }

      // Assume there's only one document matching the name
      const orgDocument = orgQuerySnapshot.docs[0];

      const topicsQuerySnapshot = await projectFirestore
        .collection("organisations")
        .doc(orgDocument.id)
        .collection("topics")
        .get();

      const topics = topicsQuerySnapshot.docs.map((doc) => doc.data());

      return topics;
    } catch (error) {
      console.error("Error fetching questions:", error);
      throw new Error("Error fetching questions");
    }
  };

  /**
   * Gets the topics with matching ids from a specific organisation.
   *
   * @param orgId The organisation id of the organisation to get the topics from
   * @param ids The mathcing topics ids
   * @returns All the matching topics
   */
  const getTopicsById = async (orgId: string, ids: string[]) => {
    const topicsQuerySnapshot = await projectFirestore
      .collection("organisations")
      .doc(orgId)
      .collection("topics")
      .where("id", "in", ids)
      .get();

    const topics = topicsQuerySnapshot.docs.map((doc) => doc.data());

    return topics;
  };

  const organisationTopicsMemo = () => {
    const unsubscribe = projectFirestore
      .collection("organisations")
      .doc(selectedOrganisation?.id)
      .collection("topics")
      .orderBy("createdAt", "desc")
      .onSnapshot((snapshot) => {
        const updatedTopics: DocumentData[] = [];
        snapshot.forEach((doc) => {
          updatedTopics.push({
            id: doc.id,
            ...doc.data(),
          });
        });
        setTopics(updatedTopics);
      });

    return () => {
      unsubscribe();
    };
  };

  useEffect(() => {
    return organisationTopicsMemo();
    // eslint-disable-next-line
  }, [selectedOrganisation]);

  const generalTopicsMemo = () => {
    const unsubscribe = projectFirestore
      .collection("Topics")
      .where("general", "==", "General Topics")
      .onSnapshot((snapshot) => {
        const generalTopics: DocumentData[] = [];
        snapshot.forEach((doc) => {
          generalTopics.push({
            id: doc.id,
            category: "General Topics",
            general: doc.data().topics,
            hasInfo: false,
            organisationId: selectedOrganisation?.id,
          });
        });
        setGeneralTopics(generalTopics);
      });

    return () => {
      unsubscribe();
    };
  };

  useEffect(() => {
    return generalTopicsMemo();
    // eslint-disable-next-line
  }, [selectedOrganisation]);

  return {
    topics,
    generalTopics,
    activityTopics,
    AddNewCategory,
    DeleteCategory,
    AddNewTopic,
    DeleteTopic,
    updateTopicName,
    updateOrganisationTopicSettings,
    updateTopicSettings,
    getActivityTopics,
    getAllTopics,
    getAllTopicsByOrgName,
    getTopicsById,
  };
};
