import {
  checkHasStatistics,
  D_STEP_5_REFLECTION_QUANTITATIVE,
  getStatisticTitleSplit,
  Statistics,
} from "models/dashboardStats";
import { Suspense, } from "react";
import DashboardCard from "./DashboardCard";
import QuantitativeFeedbackCard from "./QuantitativeFeedbackCard";

interface Props {
  isLoading: boolean;
  averages: [string, Record<number, Record<string, number>>][];
  statistics: Statistics;
}

const QuantitativeFeedback = ({
  isLoading,
  averages,
  statistics,
}: Props) => {

  return (
    <>
      {averages.map(
        ([key, value]) =>
          checkHasStatistics(
            statistics,
            D_STEP_5_REFLECTION_QUANTITATIVE.concat(
              key.replace(
                new RegExp(" ".replace(/[.*+?^${}()|[\]\\]/g, "\\$&"), "g"),
                ""
              )
            )
          ) && (
            <DashboardCard
              titles={getStatisticTitleSplit(
                statistics,
                D_STEP_5_REFLECTION_QUANTITATIVE.concat(
                  key.replace(
                    new RegExp(" ".replace(/[.*+?^${}()|[\]\\]/g, "\\$&"), "g"),
                    ""
                  )
                )
              )}
              key={D_STEP_5_REFLECTION_QUANTITATIVE.concat(
                key.replace(
                  new RegExp(" ".replace(/[.*+?^${}()|[\]\\]/g, "\\$&"), "g"),
                  ""
                )
              )}
            >
              <Suspense fallback={"loading..."}>
                <QuantitativeFeedbackCard
                  isLoading={isLoading}
                  averages={value}
                />
              </Suspense>
            </DashboardCard>
          )
      )}
    </>
  );
};

export default QuantitativeFeedback;
