import { useState, useEffect, useRef } from "react";

export default function useComponentVisible(initialIsVisible: boolean) {
  const [isComponentVisible, setIsComponentVisible] =
    useState(initialIsVisible);
  const ref = useRef<any>(null);

  // const handleClickOutside = (event) => {
  //   if (ref.current && !ref.current.contains(event.target)) {
  //     setIsComponentVisible(false);
  //   }
  // };

  useEffect(() => {
    const handleClick = (event) => {
      if (ref.current && !ref.current.contains(event.target)) {
        setIsComponentVisible((prev) => !prev);
      }
    };

    document.addEventListener("click", handleClick);
    return () => {
      document.removeEventListener("click", handleClick);
    };
  }, []);

  // useEffect(() => {
  //   document.addEventListener("scroll", handleClickOutside, true);
  //   return () => {
  //     document.removeEventListener("scroll", handleClickOutside, true);
  //   };
  // }, []);

  return { ref, isComponentVisible, setIsComponentVisible };
}
