const facilTheme: { [key: string]: string } = {
  "--main-colour": "#F9BB62",
  "--main-colour-80": "#FAC77C",
  "--main-colour-60": "#FAD297",
  "--main-colour-20": "#FCE9CC",
  "--main-colour-40": "#F9BB6266",
  "--main-colour-50": "#F9BB6280",
  "--main-colour-0": "#F9BB6200",
  "--secondary-colour": "#EB8181",
  "--sos-button-colour": "#ffa92e",
  "--darker-main-color": "#f9bb62",
};

const userTheme: { [key: string]: string } = {
  "--main-colour": "#eb8181",
  "--main-colour-80": "#ef9895",
  "--main-colour-60": "#f2afa9",
  "--main-colour-20": "#f9ded2",
  "--main-colour-40": "#eb818166",
  "--main-colour-50": "#eb818180",
  "--main-colour-0": "#eb818100",
  "--secondary-colour": "#f9bb62",
  "--sos-button-colour": "#f9bb62",
  "--darker-main-color": "#e1635e",
};

const useFacilTheme = () => {
  const changeFacilTheme = () => {
    var isFacil = localStorage.getItem("GrowthCirclesThemeIsFacil");

    if (isFacil !== "true") {
      Object.keys(userTheme).map((key) => {
        const value = userTheme[key];
        return document.documentElement.style.setProperty(key, value);
      });
    } else {
      Object.keys(facilTheme).map((key) => {
        const value = facilTheme[key];
        return document.documentElement.style.setProperty(key, value);
      });
    }
  };
  changeFacilTheme();
  return { changeFacilTheme };
};

export default useFacilTheme;
