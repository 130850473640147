import { FeedbackQuestion } from "models/componentSettings/feedback/utility/feedback";
import { useState } from "react";

interface FeedbackSortCardProps {
  question: FeedbackQuestion;
  setOrder: Function;
  order: string[];
}

const FeedbackQuestionSortCard = ({
  question,
  setOrder,
  order,
}: FeedbackSortCardProps) => {
  const [selected, setIsSelected] = useState(true);

  const selectedHandler = () => {
    setIsSelected(!selected);
    setOrder((prevSelectedIds) => {
      if (!selected) {
        return [...prevSelectedIds, question.uid];
      } else {
        return prevSelectedIds.filter((id: string) => id !== question.uid);
      }
    });
  };

  const questionIndex = question.uid ? order.indexOf(question.uid) + 1 : -1;
  return (
    <div
      onClick={selectedHandler}
      className={`relative bg-white flex justify-center items-center min-w-[150px]  min-h-[50px] rounded-full w-full shadow-md text-slate-700 cursor-pointer ${
        selected
          ? "border-4 border-[var(--icon-colour-0)]"
          : "border border-slate-400/70"
      }`}
    >
      <p className="px-2 break-words select-none">
        {question.question.default}
      </p>

      {question.uid && selected && questionIndex !== -1 && (
        <p className="absolute bottom-[1px] right-1 text-md font-semibold rounded-full text-default bg-[var(--icon-colour-0)] w-10 h-10 flex items-center justify-center text-center">
          {questionIndex}
        </p>
      )}
    </div>
  );
};

export default FeedbackQuestionSortCard;
