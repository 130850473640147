import React from "react";

interface Props {
  fill?: string,
}

const DownArrow: React.FC<Props> = ({fill}) => {
  return (
    <svg
      width="12"
      height="12"
      viewBox="0 0 12 12"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M5.58988 9.7845C5.63575 9.85112 5.69713 9.90559 5.76873 9.94322C5.84033 9.98086 5.92 10.0005 6.00088 10.0005C6.08177 10.0005 6.16144 9.98086 6.23304 9.94322C6.30464 9.90559 6.36601 9.85112 6.41188 9.7845L10.9119 3.2845C10.964 3.20953 10.9945 3.12172 11.0002 3.0306C11.0059 2.93949 10.9865 2.84856 10.9441 2.7677C10.9018 2.68683 10.838 2.61912 10.7599 2.57193C10.6818 2.52473 10.5922 2.49986 10.5009 2.5H1.50088C1.40981 2.50038 1.32055 2.52557 1.24272 2.57288C1.1649 2.62019 1.10144 2.68782 1.05917 2.7685C1.0169 2.84917 0.997427 2.93985 1.00284 3.03077C1.00825 3.12168 1.03834 3.20941 1.08988 3.2845L5.58988 9.7845Z"
        fill={fill ? fill : "var(--main-colour)"}
      />
    </svg>
  );
};

export default DownArrow;
