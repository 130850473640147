import { FC, useEffect, useState } from "react";
import { ComponentProps } from "../SelfAwarenessComponentMapping";

import GradingItem from "./GradingItem";
import BackAndNextFlat from "components/utility/BackAndNextFlat";

const SelectionGrading: FC<ComponentProps> = ({ backAndNextHandler }) => {
  const [TopSelectionItems, setTopSelectionItems] = useState<string[]>([]);

  // Load data from local storage on component mount
  useEffect(() => {
    const storedSelectedItems = localStorage.getItem("SelfAwarenessJournal");

    if (storedSelectedItems) {
      const parsedJournal = JSON.parse(storedSelectedItems);
      setTopSelectionItems(parsedJournal.topSelectionItems || []);
    }
  }, []);
  return (
    <div className="py-8 px-4 w-full md:w-1/4">
      <div
        className="relative p-5 border-2 rounded-lg font-poppins font-semibold"
        style={{
          backgroundColor: "#FFF4B9",
          borderColor: "#FFE871",
          color: "var(--text-colour)",
        }}
      >
        <p>On a scale of 1-10, what is the intensity of your emotions?</p>
      </div>
      <div className="my-8">
        {TopSelectionItems &&
          TopSelectionItems.map((item, index) => (
            <div
              key={index}
              className="bg-white px-2 border-2 rounded-lg my-4"
              style={{ borderColor: "#FFE871" }}
            >
              <GradingItem item={item} />
            </div>
          ))}
      </div>
      <div className="my-4">
        <BackAndNextFlat {...backAndNextHandler} />
      </div>
    </div>
  );
};

export default SelectionGrading;
