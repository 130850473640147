import React, { useEffect, useState } from "react";
import classes from "./Role.module.css";
import RoleFront from "./RoleFront/RoleFront";
import RoleBack from "./RoleBack/RoleBack";
import RoleInterface from "../../../interface/RoleInterface";
import Locked from "./Locked/Locked";
import { useAuthContext } from "hooks/useAuthContext";

interface Props {
  object: RoleInterface;
  currSelected?: RoleInterface | null;
  setCurrSelected?: React.Dispatch<React.SetStateAction<RoleInterface | null>>;
}

const Role: React.FC<Props> = ({ object, currSelected, setCurrSelected }) => {
  // card flip state
  const [isFlipped, setIsFlipped] = useState(false);
  // card compressed state
  const [isCompressed, setIsCompressed] = useState(true);

  const selectRole = () => {
    if (!setCurrSelected) return;
    setCurrSelected(object);
    //console.log(object);
  };

  const role: string = object.role;
  const importance: string = object.importance;
  const stepsToTake: string[] = object["step_to_take"];
  const example: string[] = object["example"];
  const description: string = object["description"];
  const sampleQuestion: string[] = object["sample_questions"];
  const coreSkill: string = object["core_skills"];
  const unlockConditions: string[] = object["unlock_condition"];
  const questionPrompts: string = object["question_prompts"];

  // check locked
  const [isLoading, setIsLoading] = useState<boolean>(true);
  const [isLocked, setIsLocked] = useState<boolean>(false);
  const { profile } = useAuthContext();

  useEffect(() => {
    if (!profile || !profile.progress[role]) return;
    // check if all unlock conditions are met
    // as long as 1 condition not met, card is locked
    for (let i = 0; i < unlockConditions.length; i++) {
      const condition = unlockConditions[i];
      const progress = profile.progress;
      if (!progress) return;
      if (object["is_premium"] && !profile.is_premium) {
        setIsLocked(true);
        continue;
      }
      if (progress[condition] && progress[condition].level > 2) {
        continue;
      }
      setIsLocked(true);
    }
    setIsLoading(false);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [profile]);

  useEffect(() => {
    if (isFlipped) {
      setIsCompressed(true);
    }
  }, [isFlipped]);

  if (isLoading) return null;

  return (
    // TODO: Change the appearance of this section.
    <>
      <div className={classes["scene"]} onClick={selectRole}>
        {isLocked ? (
          <Locked role={role} coreSkill={coreSkill} />
        ) : (
          <div
            className={`${isFlipped && classes["is-flipped"]} ${
              classes["role-card"]
            } `}
          >
            <div
              className={`${classes["card-face"]} ${classes["card-front"]}}`}
            >
              <RoleFront
                setIsFlipped={setIsFlipped}
                role={role}
                stepsToTake={stepsToTake}
                example={example}
                description={description}
                sampleQuestion={sampleQuestion}
                questionPrompts={questionPrompts}
                currSelected={currSelected}
                coreSkill={coreSkill}
                isCompressed={isCompressed}
                setIsCompressed={setIsCompressed}
              />
            </div>
            <div
              className={`${classes["card-face-flipped"]} ${classes["card-back"]}`}
            >
              <RoleBack
                setIsFlipped={setIsFlipped}
                role={role}
                importance={importance}
                currSelected={currSelected}
              />
            </div>
          </div>
        )}
      </div>
    </>
  );
};

export default Role;
