interface Props {
  text: string;
  className?: string;
}

export default function Instruction({ text, className }: Props) {
  return (
    <>
      <div className={"m-4 " + className}>
        <b>{text}</b>
      </div>
    </>
  );
}
