import EmptySpace from "components/utility/EmptySpace";
import RoleResponse from "components/ViewChooseRoles/RoleResponse";
import Instruction from "components/utility/Instruction";
import React from "react";
import BackAndNext from "components/utility/BackAndNext";
import { ComponentProps } from "../GenericSessionPage/ComponentMapping";

const INSTRUCTIONS_VIEW_ROLES = "Take note of everyone's selected roles";

const ViewChooseRolesComponent = ({ backAndNextHandler }: ComponentProps) => {
  return (
    <React.Fragment>
      <Instruction text={INSTRUCTIONS_VIEW_ROLES} />
      <EmptySpace height={"30px"} />
      <RoleResponse />
      <EmptySpace height={"20px"} />
      <BackAndNext {...backAndNextHandler} />
    </React.Fragment>
  );
};

export default ViewChooseRolesComponent;
