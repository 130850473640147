import NavAdmin from "components/Admin/NavAdmin";
import { Toaster } from "react-hot-toast";
import classes from "pages/AllCircles/Admin/Admin.module.css";
import SidebarAdmin from "components/Admin/SidebarAdmin";
import SelfDiscoveryAdmin from "./SelfDiscoveryAdmin";
import { OrganisationContextProvider } from "context/OrganisationContext";

const SelfDiscoverySettings = () => {
  return (
    <div>
      <NavAdmin />
      <Toaster
        position="bottom-center"
        containerStyle={{
          bottom: "5%",
        }}
        toastOptions={{ duration: 4000 }}
      />
      <div className={`${classes.container} min-h-screen`}>
        <SidebarAdmin />
        <div className={classes.main}>
          <div className={classes.title}>
            <h4>Self Discovery</h4>
          </div>
          <OrganisationContextProvider>
            <SelfDiscoveryAdmin />
          </OrganisationContextProvider>
        </div>
      </div>
    </div>
  );
};

export default SelfDiscoverySettings;
