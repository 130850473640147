import { Modal } from "flowbite-react";
import { HiOutlineExclamationCircle } from "react-icons/hi";
import classes from "pages/AllCircles/Admin/Admin.module.css";
import SimpleLoader from "components/Loaders/SimpleLoader";

interface DeleteGroupModalProps {
  show: boolean;
  setShow: (value: React.SetStateAction<boolean>) => void;
  groupName: string;
  subGroupName: string;
  isLoading: boolean;
  handler: () => Promise<void>;
}

const DeleteGroupModal = ({
  show,
  setShow,
  groupName,
  subGroupName,
  isLoading,
  handler,
}: DeleteGroupModalProps) => {
  return (
    <Modal show={show} size={"xl"} popup={true}>
      <Modal.Body className="py-8 flex flex-col justify-center items-center">
        <div className="text-center">
          <HiOutlineExclamationCircle
            size={100}
            className="mx-auto mb-4   text-gray-400 dark:text-gray-200"
          />
          <h3 className="mb-5 text-lg font-normal text-gray-500 dark:text-gray-400">
            Are you sure you want to delete <br />
            this group{" "}
            <strong>
              {groupName} {subGroupName ? `/ ${subGroupName}` : ""}
            </strong>
            ?
          </h3>
          {isLoading ? (
            <div className="flex justify-center items-center">
              <SimpleLoader />
            </div>
          ) : (
            <div className={`${classes["container"]} flex gap-4`}>
              <button
                className={`${classes["button"]}`}
                color="failure"
                onClick={handler}
              >
                Yes, I'm sure
              </button>
              <button
                className={`${classes["button"]}`}
                color="gray"
                onClick={() => setShow(!show)}
              >
                No, cancel
              </button>
            </div>
          )}
        </div>
      </Modal.Body>
    </Modal>
  );
};

export default DeleteGroupModal;
