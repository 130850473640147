import classes from "./SidebarAccount.module.css";
import { ReactComponent as AccountCircle } from "../../assets/AccountCircle.svg";
import { Link } from "react-router-dom";

interface Props {
  name: string;
}

const SidebarAccount: React.FC<Props> = ({ name }) => {
  return (
    <div className={classes["sidebar-account"]}>
      <AccountCircle viewBox="-5 -3 50 50" className={classes.accountcircle} />
      <Link to="/my-account">
        <h4>{name}</h4>
      </Link>
    </div>
  );
};

export default SidebarAccount;
