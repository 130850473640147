import { CSSProperties, FC, useState } from "react";
import classes from "./FilterDropDown.module.css";
import useComponentVisible from "hooks/useComponentVisible";
import { useEffect } from "react";
import { Badge } from "flowbite-react";
import FilterDropDownOptionMulti from "./FilterDropDownOptionMulti";

export interface FilterEvent {
  value: string[];
}

interface Props {
  id?: string;
  style?: CSSProperties;
  text?: string;
  // Full options array available (i.e not dependent on selection)
  options: string[];
  // Function to fire when an item is selected
  onSelect: (evt: FilterEvent) => any;
  isAllSelected?: boolean;
  btnClass?: string;
}

const FilterDropDownMulti: FC<Props> = ({
  id,
  style,
  text,
  options,
  onSelect,
  isAllSelected = true,
  btnClass,
}) => {
  const { ref, isComponentVisible, setIsComponentVisible } =
    useComponentVisible(false);
  // const [currSelected, setCurrSelected] = useState("");
  const [selectedOptions, setSelectedOptions] = useState<string[]>([]);

  const setOption = (evt: FilterEvent) => {
    setSelectedOptions(evt.value);
    onSelect(evt);
  };

  const selectAll = () => {
    if (selectedOptions.length === options.length) {
      setSelectedOptions([]);
      onSelect({
        value: [],
      });
    } else {
      setSelectedOptions(options);
      onSelect({
        value: options,
      });
    }
  };

  // Clear curr selected if id changes - id can be anything that when changed indicates that dropdown checkmark should clear
  useEffect(() => {
    if (isAllSelected) {
      setSelectedOptions(options);
    } else {
      setSelectedOptions([]);
    }
    // eslint-disable-next-line
  }, [id, options]);

  return (
    <div style={{ ...style }} className={`${classes["main-container"]}`}>
      <button
        className={btnClass}
        type="button"
        onClick={(event) => {
          event.stopPropagation();
          setIsComponentVisible(!isComponentVisible);
        }}
      >
        {text}
      </button>
      {isComponentVisible && (
        <div ref={ref} id="dropdown" className={`${classes["menu-container"]}`}>
          <Badge
            className="bg-white hover:bg-gray-200 text-md justify-center mb-2"
            onClick={selectAll}
          >
            {selectedOptions.length === options.length
              ? "Clear all"
              : "Select All"}
          </Badge>

          {options.map((o) => (
            <FilterDropDownOptionMulti
              key={o}
              option={o}
              currentlySelected={selectedOptions}
              setCurr={setOption}
              onSelect={onSelect}
            />
          ))}
        </div>
      )}
    </div>
  );
};

export default FilterDropDownMulti;
