import { useState } from "react";
import { useAuthContext } from "./useAuthContext";
import useGetParticipants from "./useGetParticipants";
import { useGrowthCircleContext } from "./useGrowthCircleContext";
import { Profile, Date as ProfileDate } from "interface/ProfileInterface";
import {
  getOrgByName,
  joinOrganisation,
  joinUserFieldOrganisation,
} from "models/organisation";
import { getUserById } from "models/profile";
import { participantRole, R_HOST } from "models/organisationRole";
import {
  addParticipant,
  defaultNewParticipantModel,
  defaultParticipantModel,
} from "models/participant";
import { savePastParticipants } from "models/pastParticipant";
import { useFirestore } from "./useFirestore";
import { timestamp } from "../firebase/config";
import useOrganisationContext from "./organisation/useOrganisationContext";
import { removeFeedback } from "localStorage/feedback";
import { removeBadges } from "localStorage/badge";
import useParticipants from "./useParticipants";
import { useGrowthCircles } from "./useGrowthCircles";
import { GrowthCircleSession } from "interface/GrowthCircleSessionInterface";
import {
  checkIfAlreadyInsideOrg,
  createAndAddOneOrgUser,
} from "utility/orgUsersHelpers";
import Organisation from "interface/OrganisationInterface";

const useJoinParticipant = () => {
  const { user, profile } = useAuthContext();
  const { growthCircleSession } = useGrowthCircleContext();
  const { fetchOrganisations } = useOrganisationContext();
  const { getGCDetailsById } = useGrowthCircles();
  const [isPending, setIsPending] = useState<boolean>(false);
  const [error, setError] = useState<boolean>(false);
  const [errorMessage, setErrorMessage] = useState<string | null>(null);
  const { participants } = useGetParticipants();
  const { updateDocument } = useFirestore("GrowthCircles");
  const { sessionRole } = useParticipants();

  /**
   * If the user is not in the session, add them to the session
   * @param userId - the user's id
   * @param email - the email of the user
   * @param displayName - the name of the collection
   */
  const joinSession = async (
    userId: string,
    email: string,
    displayName: string,
    growthCircle: string,
    sessionInstance: string,
    isFacil: boolean,
    location: string,
    locationDetails: string,
    gender: string,
    birthday: ProfileDate,
    photoURL: string,
    personalID: string,
    tutorialGroup: string,
    organisationFields?: any
  ) => {
    let obj = participants.find((o) => o.userId === userId);

    const _id = localStorage.getItem("preGC");
    let _growthCircleSession: GrowthCircleSession;
    if (growthCircleSession) {
      _growthCircleSession = growthCircleSession;
    } else {
      _growthCircleSession = (await getGCDetailsById(
        _id ?? ""
      )) as GrowthCircleSession;
    }

    if (!profile || obj || !_growthCircleSession) {
      return;
    }

    const orgName = _growthCircleSession.organisation;
    const [organisation, user] = await Promise.all([
      getOrgByName(orgName),
      getUserById(userId),
    ]);
    if (!organisation || !user) {
      return;
    }
    const getRole = await getRoleByUser(user, organisation);
    const role = getRole ? getRole : participantRole.name;
    await joinOrganisation(organisation, user.uid, role).then(
      fetchOrganisations
    );
    const check = await checkIfAlreadyInsideOrg(profile, organisation);
    if (check === false) {
      await createAndAddOneOrgUser(organisation, profile, role);
      //TODO: remove this to omit user field
      await joinUserFieldOrganisation(organisation, profile.uid, role).then(
        fetchOrganisations
      );
    }

    const newParticipant = {
      id: "",
      origin: window.location.origin,
      userId: userId,
      email: email,
      userName: displayName,
      triggered: false,
      growthCircleId: growthCircle,
      activity: "",
      sessionInstance: sessionInstance,
      isFacil: isFacil,
      location: location !== "Growthbeans" ? location : orgName,
      locationDetails: locationDetails,
      gender: gender,
      birthday: birthday,
      photoURL: photoURL,
      personalId: personalID,
      tutorialGroup: tutorialGroup, //group
      sessionRole:
        _growthCircleSession?.facilOwner === profile?.id ? R_HOST : "",
      organisationFields: organisationFields ?? [],
      isTrainee: sessionRole?.name === "trainee" ? true : false,
    };

    localStorage.setItem("lastOrg", organisation.name);

    const participantRef = await addParticipant(
      profile.growthCircle ?? _growthCircleSession.uid,
      {
        ...defaultNewParticipantModel,
        ...newParticipant,
      }
    );

    if (!participantRef) {
      return;
    }
    newParticipant.id = participantRef.id;

    //Update GrowthCircles Tutorial Group lastSession convert tutorialGroup to lowercase for filter index
    let data: Object;
    if (_growthCircleSession.facilOwner === profile?.id) {
      data = {
        tutorialGroup: tutorialGroup.toLocaleLowerCase(),
        lastSession: timestamp.fromDate(new Date()),
      };
    } else {
      data = {
        lastSession: timestamp.fromDate(new Date()),
      };
    }
    await updateDocument(_growthCircleSession.id, data);

    // Clear feedback form from localStorage
    removeBadges();
    removeFeedback();

    await savePastParticipants(organisation.id, [
      {
        ...defaultParticipantModel,
        ...newParticipant,
      },
    ]);
  };

  const getRoleByUser = async (
    profile: Profile,
    organisation: Organisation
  ) => {
    const userId = profile.uid;

    // Check if the user id exists in the organisation's users
    if (organisation.users.hasOwnProperty(userId)) {
      // Return the role associated with this user id
      return organisation.users[userId];
    } else {
      // If the user id is not found, return null or undefined
      return participantRole.name;
    }
  };

  /**
   * It checks if the user's inputted invite code matches the invite code of the growth circle. If it
   * does, it adds the user to the growth circle
   * @param userKey - The invite code that the user entered
   * @param growthCircles - This is the object that contains the data of the session that the user is
   * trying to join.
   * @param profile - This is the user's profile object.
   */
  const validateInviteCode = async (userKey: string) => {
    setError(false);
    setIsPending(true);
    setErrorMessage(null);
    const facils = participants.filter(
      (participant) => participant.isFacil === true
    );

    if (growthCircleSession) {
      if (!user?.email || !profile) return;

      //TODO:: check this update if will cause problem
      if (userKey !== growthCircleSession.invite_code.toString()) {
        setError(true);
        setErrorMessage("This code does not exist");
        setIsPending(false);
      } else if (
        //exclude facil from the count

        participants.length - facils.length >= growthCircleSession.number &&
        !profile.isFacil
      ) {
        setError(true);
        setErrorMessage("Room is full");
        setIsPending(false);
      } else {
        const orgName = growthCircleSession.organisation;
        const [organisation, user] = await Promise.all([
          getOrgByName(orgName),
          getUserById(profile.uid),
        ]);
        // console.log(organisation);
        // console.log(user);

        if (user && organisation) {
          await joinSession(
            profile.uid,
            user.email,
            profile.displayName,
            growthCircleSession.uid,
            growthCircleSession.sessionInstance,
            organisation.users[profile.uid] === "facilitator" ? true : false,
            growthCircleSession.location ?? "",
            growthCircleSession.locationDetails ?? "",
            profile.gender ?? "",
            profile.birthday ?? "",
            profile.photoURL ?? "",
            profile.personalID ?? "",
            profile.tutorialGroup ?? "",
            profile.OrganisationFields
              ? profile.OrganisationFields[organisation.id]
              : []
          );
        }
        setIsPending(false);
        setError(false);
        setErrorMessage(null);
      }
    } else {
      const _id = localStorage.getItem("preGC");
      if (_id && profile) {
        const _growthCircleSession = (await getGCDetailsById(
          _id
        )) as GrowthCircleSession;
        if (userKey !== _growthCircleSession.invite_code.toString()) {
          setError(true);
          setErrorMessage("This code does not exist");
          setIsPending(false);
        } else if (
          //exclude facil from the count
          participants.length - facils.length >= _growthCircleSession.number &&
          !profile.isFacil
        ) {
          setError(true);
          setErrorMessage("Room is full");
          setIsPending(false);
        } else {
          const orgName = _growthCircleSession.organisation;
          const [organisation, user] = await Promise.all([
            getOrgByName(orgName),
            getUserById(profile.uid),
          ]);
          // console.log(organisation);
          // console.log(user);

          if (user && organisation) {
            console.log("this is called");
            await joinSession(
              profile.uid,
              user.email,
              profile.displayName,
              _growthCircleSession.uid,
              _growthCircleSession.sessionInstance,
              organisation.users[profile.uid] === "facilitator" ? true : false,
              _growthCircleSession.location ?? "",
              _growthCircleSession.locationDetails ?? "",
              profile.gender ?? "",
              profile.birthday ?? "",
              profile.photoURL ?? "",
              profile.personalID ?? "",
              profile.tutorialGroup ?? "",
              profile.OrganisationFields
                ? profile.OrganisationFields[organisation.id]
                : []
            );
          }
          setIsPending(false);
          setError(false);
          setErrorMessage(null);
        }
      }
    }
  };

  return { isPending, error, errorMessage, validateInviteCode };
};

export default useJoinParticipant;
