import { createPortal } from "react-dom";
import Backdrop from "../../../components/utility/Backdrop";

import classes from "./QuestionForTopics.module.css";

const ModalOverlay = (props) => {
  // const buttonStyle = {
  //   border: "5px solid #EB8181",
  //   fontWeight: "700",
  //   fontSize: "1.1em",
  //   minHeight: "40px",
  //   width: "203px",
  // };

  return (
    <div className={classes.modal}>
      {/* props.cancelHandler */}

      <div className={classes["message-box"]}>
        <span className={classes["close-button"]} onClick={props.cancelHandler}>
          ✖
        </span>
        <h5>
          You chose <span>{props.title}</span>
        </h5>

        <div className={classes["button-container"]}>
          <button
            className={classes["selection-button"]}
            onClick={props.acceptHandler}
          >
            Yes
          </button>
          <button
            className={classes["selection-button"]}
            onClick={props.cancelHandler}
          >
            No
          </button>
        </div>
      </div>
    </div>
  );
};

const QuestionTopicModal = (props) => {
  return (
    <>
      {/* Modals to be ported over to respective placing in DOM  */}
      <Backdrop onClick={props.onClick} />
      {createPortal(
        <ModalOverlay
          cancelHandler={props.onClick}
          acceptHandler={props.acceptHandler}
          title={props.topic}

          // subtitle={props.title}
          // body={props.body}
        />,
        document.getElementById("topic-modal")
      )}
    </>
  );
};

export default QuestionTopicModal;
