import SidebarAdmin from "components/Admin/SidebarAdmin";
import NavAdmin from "components/Admin/NavAdmin";
import FilterHeader from "./Dashboard/FilterHeader";
import DashboardCard from "./Dashboard/DashboardCard";
import VerticalBarChartComponent from "./Dashboard/VerticalBarChartComponent";
import toast, { Toaster } from "react-hot-toast";
import { useEffect } from "react";

export default function Dashboard() {
  const data2 = [
    {
      name: "Too Long",
      value: 9,

      color1: "#7FD33F",
    },
    {
      name: "Just Right",
      value: 9,

      color1: "#7FD33F",
    },
    {
      name: "Too Short",
      value: 3,

      color1: "#7FD33F",
    },
  ];

  const data3 = [
    {
      name: "Yes",
      value: 9,

      color1: "#7FD33F",
    },
    {
      name: "No",
      value: 5,

      color1: "#7FD33F",
    },
  ];

  const data4 = [
    {
      name: "Very Satisfied",
      value: 9,

      color1: "#7FD33F",
    },
    {
      name: "Satisfied",
      value: 8,

      color1: "#7FD33F",
    },
    {
      name: "Neutral",
      value: 10,

      color1: "#7FD33F",
    },
    {
      name: "Unsatisfactory",
      value: 11,

      color1: "#7FD33F",
    },
    {
      name: "Very Unsatisfactory",
      value: 8,

      color1: "#7FD33F",
    },
  ];

  useEffect(() => {
    toast.dismiss("dashboard");
    toast.success(
      "You are viewing a sample example of a dashboard for a Career Mentoring Growth Circle",
      { id: "dashboard" }
    );
  }, []);

  return (
    <div className="min-h-screen h-full ">
      <NavAdmin />
      <Toaster toastOptions={{ duration: 8000 }} />
      <div className="min-h-screen h-full flex ">
        <SidebarAdmin />
        <div className="p-8 mb-[100px]">
          <FilterHeader options={[""]} />

          <div className="flex">
            <DashboardCard titles={["Usefulness of ", "Programme"]} key="usefulness">
              {" "}
              <VerticalBarChartComponent data={data3} />
            </DashboardCard>

            <DashboardCard
              titles={["Sufficiency of Duration of ", "Programme"]} key="sufficiency"
            >
              {" "}
              <VerticalBarChartComponent data={data2} />
            </DashboardCard>

            <DashboardCard
              titles={["Suitability of Period of", "Programme"]}
              key="suitability"
            >
              {" "}
              <VerticalBarChartComponent data={data3} />
            </DashboardCard>
          </div>

          <div className="flex">
            <DashboardCard
              titles={["Attainability of Duration of at least ", "10 hours of face-time"]}
              key="attainability"
            >
              {" "}
              <VerticalBarChartComponent data={data3} />
            </DashboardCard>

            <DashboardCard
              titles={["Mentors that Would ", "Recommend Others"]}
              key="recommendation"
            >
              {" "}
              <VerticalBarChartComponent data={data3} />
            </DashboardCard>

            <DashboardCard titles={["Satisfaction with The", "Programme"]} key="satisfaction">
              {" "}
              <VerticalBarChartComponent data={data4} />
            </DashboardCard>
          </div>
          <div>
            <div className="text-xl font-semibold px-4 py-5">
              Qualitative Questions
            </div>
            <div className="bg-white w-full p-8">
              <div className="border border-slate-400 my-8">
                <div className="bg-[#FADCAF] px-5 py-3">
                  What is your key learning point from this programme?
                </div>
                <div className=" px-5 py-3 border border-slate-400/75">
                  Lorem ipsum dolor, sit amet consectetur adipisicing elit. Qui
                  provident assumenda culpa cupiditate explicabo autem?
                </div>
                <div className=" px-5 py-3 border border-slate-400/75">
                  Lorem ipsum dolor, sit amet consectetur adipisicing elit. Qui
                  provident assumenda culpa cupiditate explicabo autem?
                </div>
                <div className=" px-5 py-3 border border-slate-400/75">
                  Lorem ipsum dolor, sit amet consectetur adipisicing elit. Qui
                  provident assumenda culpa cupiditate explicabo autem?
                </div>
              </div>

              <div className="border border-slate-400 my-8">
                <div className="bg-[#FADCAF] px-5 py-3">
                  How was the registration process to be a Mentor in this
                  programme?
                </div>
                <div className=" px-5 py-3 border border-slate-400/75">
                  Lorem ipsum dolor, sit amet consectetur adipisicing elit. Qui
                  provident assumenda culpa cupiditate explicabo autem?
                </div>
                <div className=" px-5 py-3 border border-slate-400/75">
                  Lorem ipsum dolor, sit amet consectetur adipisicing elit. Qui
                  provident assumenda culpa cupiditate explicabo autem?
                </div>
                <div className=" px-5 py-3 border border-slate-400/75">
                  Lorem ipsum dolor, sit amet consectetur adipisicing elit. Qui
                  provident assumenda culpa cupiditate explicabo autem?
                </div>
              </div>

              <div className="border border-slate-400 my-8">
                <div className="bg-[#FADCAF] px-5 py-3">
                  How was the matching process to the Mentee(s)?
                </div>
                <div className=" px-5 py-3 border border-slate-400/75">
                  Lorem ipsum dolor, sit amet consectetur adipisicing elit. Qui
                  provident assumenda culpa cupiditate explicabo autem?
                </div>
                <div className=" px-5 py-3 border border-slate-400/75">
                  Lorem ipsum dolor, sit amet consectetur adipisicing elit. Qui
                  provident assumenda culpa cupiditate explicabo autem?
                </div>
                <div className=" px-5 py-3 border border-slate-400/75">
                  Lorem ipsum dolor, sit amet consectetur adipisicing elit. Qui
                  provident assumenda culpa cupiditate explicabo autem?
                </div>
              </div>

              <div className="border border-slate-400 my-8">
                <div className="bg-[#FADCAF] px-5 py-3">
                  Are there areas to improve on for future runs of the
                  programme?
                </div>
                <div className=" px-5 py-3 border border-slate-400/75">
                  Lorem ipsum dolor, sit amet consectetur adipisicing elit. Qui
                  provident assumenda culpa cupiditate explicabo autem?
                </div>
                <div className=" px-5 py-3 border border-slate-400/75">
                  Lorem ipsum dolor, sit amet consectetur adipisicing elit. Qui
                  provident assumenda culpa cupiditate explicabo autem?
                </div>
                <div className=" px-5 py-3 border border-slate-400/75">
                  Lorem ipsum dolor, sit amet consectetur adipisicing elit. Qui
                  provident assumenda culpa cupiditate explicabo autem?
                </div>
              </div>

              <div className="border border-slate-400 my-8">
                <div className="bg-[#FADCAF] px-5 py-3">
                  How has the Membership Programme benefited or impacted you?
                </div>
                <div className=" px-5 py-3 border border-slate-400/75">
                  Lorem ipsum dolor, sit amet consectetur adipisicing elit. Qui
                  provident assumenda culpa cupiditate explicabo autem?
                </div>
                <div className=" px-5 py-3 border border-slate-400/75">
                  Lorem ipsum dolor, sit amet consectetur adipisicing elit. Qui
                  provident assumenda culpa cupiditate explicabo autem?
                </div>
                <div className=" px-5 py-3 border border-slate-400/75">
                  Lorem ipsum dolor, sit amet consectetur adipisicing elit. Qui
                  provident assumenda culpa cupiditate explicabo autem?
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}
