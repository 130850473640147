import WelcomeMessage from "components/Login/WelcomeMessage";
import PageWrapper from "components/utility/PageWrapper";
import ContentWrapper from "components/utility/ContentWrapper";
import EmptySpace from "components/utility/EmptySpace";
import PasswordReset from "./PasswordReset"
import classes from "./ForgetPassword.module.css";
import BackButton from "assets/BackButton";

const instructions = (
  <span className={classes['please-enter-email']}>
    Please enter email:
  </span>
);

const ForgetPassword = () => {
  return (
    <PageWrapper>
      <ContentWrapper>
        <div className={classes.header}>
          <BackButton />
          <WelcomeMessage />
        </div>
          <EmptySpace height='40px' />
          <PasswordReset
            cssClasses={classes}
            instructions={instructions}
          />
      <span className={classes.divider} />
      </ContentWrapper>
    </PageWrapper>
  );
};

export default ForgetPassword;
