import { FC, useEffect, useState } from "react";
import TitleBox from "../SubComponents/TitleBox";

import { useSelfAwarenessContext } from "hooks/useSelfAwarenessContext";
import BackAndNextFlat from "components/utility/BackAndNextFlat";
import { FaPlusCircle } from "react-icons/fa";
import classes from "pages/AllCircles/Admin/Admin.module.css";
import SimpleLoader from "components/Loaders/SimpleLoader";
import { ComponentProps } from "../SelfAwarenessComponentMapping";
import StepInput from "../SubComponents/StepInput";
import InfoModal from "../SubComponents/InfoModal";
import btnClasses from "components/utility/BackAndNext.module.css";
import toast from "react-hot-toast";

const DissectKeySelection: FC<ComponentProps> = ({
  backAndNextHandler,
  setHasModal,
}) => {
  const [show, setShow] = useState<boolean>(false);
  const { journal } = useSelfAwarenessContext();

  const handler = () => {
    setShow(!show);
    setHasModal(!show);
  };

  const [dissectKeys, setDissectKeys] = useState<string[]>([]);
  const [isLoading, setLoading] = useState<boolean>(false);

  // Load data from local storage on component mount
  useEffect(() => {
    const storedJournal = localStorage.getItem("SelfAwarenessJournal");
    const existingJournal = storedJournal ? JSON.parse(storedJournal) : {};
    if (existingJournal && existingJournal.dissectKeys) {
      setDissectKeys(existingJournal.dissectKeys);
    }
    // eslint-disable-next-line
  }, []);

  const addStepsHandler = () => {
    // Assuming challenges is an array in your state
    const newSteps = ""; // Replace with your actual default data
    setDissectKeys((prevSteps) => [...prevSteps, newSteps]);

    const storedJournal = localStorage.getItem("SelfAwarenessJournal");
    const existingJournal = storedJournal ? JSON.parse(storedJournal) : {};
    const existingSteps = existingJournal.dissectKeys || [];

    // Update the local storage with the modified challenges array
    const updatedJournal = {
      ...existingJournal,
      dissectKeys: [...existingSteps, newSteps], // Add the new challenge
    };
    localStorage.setItem(
      "SelfAwarenessJournal",
      JSON.stringify(updatedJournal)
    );
  };

  const removeHandler = (index: number) => {
    const storedJournal = localStorage.getItem("SelfAwarenessJournal");
    const existingJournal = storedJournal ? JSON.parse(storedJournal) : {};
    const existingSteps = existingJournal.dissectKeys || [];
    const updatedSteps = existingSteps.filter((_, i) => i !== index); // Remove the challenge with the specified value

    // Update the local storage with the modified challenges array directly
    existingJournal.dissectKeys = updatedSteps;
    localStorage.setItem(
      "SelfAwarenessJournal",
      JSON.stringify(existingJournal)
    );
    setDissectKeys(updatedSteps); // Update the state
    refreshValues();
  };

  const refreshValues = () => {
    setLoading(true);
    const storedJournal = localStorage.getItem("SelfAwarenessJournal");
    const existingJournal = storedJournal ? JSON.parse(storedJournal) : {};
    if (existingJournal && existingJournal.dissectKeys) {
      setDissectKeys(existingJournal.dissectKeys);
    }
    setTimeout(() => {
      setLoading(false);
    }, 1000);
  };

  const nexAllowHandler = (
    e: React.MouseEvent<HTMLButtonElement, MouseEvent>
  ) => {
    const storedJournal = localStorage.getItem("SelfAwarenessJournal");
    const existingJournal = storedJournal ? JSON.parse(storedJournal) : {};
    if (existingJournal.dissectKeys.every((item) => item.trim() !== "")) {
      backAndNextHandler.nextCallback(e);
    } else {
      toast.error("Please fill-up all fields.");
      return false;
    }
  };

  useEffect(() => {}, [isLoading]);

  useEffect(() => {
    if (!journal) return;
  }, [journal]);

  return (
    <div className="py-8 px-4 w-full md:w-1/4">
      {journal && <InfoModal journal={journal} show={show} handler={handler} />}
      {journal && (
        <>
          <TitleBox
            journal={journal}
            handler={handler}
            showInfo={true}
            title={journal.dissectSelectionTitle || ""}
          />

          <div
            className={`p-5 flex flex-col gap-2 mt-8 font-poppins ${classes["container"]}`}
          >
            {!isLoading &&
              dissectKeys.map((step, index) => (
                <StepInput
                  storage="dissectKeys"
                  placeholder={`eg. People, Process...`}
                  key={index}
                  removeHandler={removeHandler}
                  index={index}
                  pageType={journal.pageType}
                  step={step}
                />
              ))}

            {isLoading && (
              <div className="flex justify-center items-center">
                <SimpleLoader />
              </div>
            )}
          </div>
          <div className="flex justify-center items-center mb-8">
            <FaPlusCircle
              className={`cursor-pointer ${btnClasses["animation"]}`}
              onClick={addStepsHandler}
              size={30}
              style={{ color: "var(--icon-colour-0)" }}
            />
          </div>
        </>
      )}
      <div className="my-4">
        <BackAndNextFlat
          {...backAndNextHandler}
          nextAllowed={dissectKeys.length > 0 ? true : false}
          nextCallback={(e) => nexAllowHandler(e)}
        />
      </div>
    </div>
  );
};

export default DissectKeySelection;
