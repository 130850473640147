import React from "react";
import ActiveListening from "./ActiveListening";
import EmotionalAgility from "./EmotionalAgility";
import EmpoweringOthers from "./EmpoweringOthers";
import PerspectiveTaking from "./PerspectiveTaking";
import ReflectiveThinking from "./ReflectiveThinking";

interface Icon {
  fill: string;
  height?: string;
  width?: string;
}

export interface RoleIconStructure {
  [key: string]: React.FC<Icon>;
}

const RolesIconMap: RoleIconStructure = {
  "Active Listening": ActiveListening,
  "Emotional Agility": EmotionalAgility,
  "Empowering Others": EmpoweringOthers,
  "Perspective-Taking": PerspectiveTaking,
  "Reflective Thinking": ReflectiveThinking,
};

export default RolesIconMap;
