import moment from "moment";
import firebase from "firebase/compat/app";
import { getModelOperationsWithPath, WithId } from "../utility/model";
import { GrowthCircleSession } from "interface/GrowthCircleSessionInterface";
import { Profile } from "interface/ProfileInterface";
import {
  DocumentData,
  DocumentReference,
  Timestamp,
  UpdateData,
  where,
  WithFieldValue,
} from "firebase/firestore";
import { PinGenerator } from "components/utility/UtilityFunctions";
import { exitGCSession } from "./profile";
import DiceImages from "components/Dice/DiceImages";
import {
  OnStoreChange,
  Unsubscribe,
} from "hooks/utility/useClientSyncExternalStore";
import { deleteParticipant, getParticipants } from "./participant";
import { v4 as uuid } from "uuid";
import Organisation, {
  defaultOrganisation,
} from "interface/OrganisationInterface";

export const FIRESTORE_PATH_GC_SESSIONS = "GrowthCircles";

export const defaultGCSessionModel: GrowthCircleSession = {
  sessionId: "",
  sessionInstance: "",
  facilOwner: "",
  name: "",
  date: Timestamp.fromDate(new Date()),
  mentor: "",
  venue: [
    {
      id: "text",
      text: "Event",
      value: "Event",
      year: new Date().getFullYear(),
    },
  ],
  status: "Available",
  channel: [],
  number: 6,
  slots: 5,
  timeStart: "7:30pm",
  timeEnd: "9:30pm",
  invite_code: "",
  isOngoing: true,
  type: "Growthbeans",
  subCategory: "",
  organisation: defaultOrganisation.name,
  id: "",
  uid: "",
  createdAt: Timestamp.fromDate(new Date()),
  day: 1,
  month: 1,
  year: 1,
  person: "",
  dices: [],
  dicesImages: [],
  sessionExpire: 2,
  facilPageNumber: 1,
};

// --- Helper Functions ---

const ops = getModelOperationsWithPath(
  FIRESTORE_PATH_GC_SESSIONS,
  defaultGCSessionModel
);

const _addGCSession: (
  newGCSession: WithFieldValue<GrowthCircleSession>
) => Promise<DocumentReference<DocumentData> | undefined> = ops.addModel;

const _getGCSession: (
  sessionId: string
) => Promise<GrowthCircleSession | undefined> = ops.getModel;

//const getGCSessions = ops.getModels;

const _getGCSessionWhere = ops.getModelWhere;

/*const setGCSession: ((
  sessionId: string,
  newData: WithFieldValue<GrowthCircleSession>,
  setOptions: SetOptions | undefined
) => Promise<void>) = ops.setModel;*/

const _updateGCSession: (
  sessionId: string,
  dataUpdates: UpdateData<GrowthCircleSession>
) => Promise<void> = ops.updateModel;

const _subscribeGCSession: (
  sessionId: string,
  onStoreChange: OnStoreChange<WithId<GrowthCircleSession> | undefined>
) => Unsubscribe = ops.subscribeModel;

// --- End Helper functions ---

// function formatDate(date: Date) {
//   return (
//     date.getFullYear() +
//     "-" +
//     (date.getMonth() + 1) +
//     "-" +
//     date.getDate() +
//     " " +
//     date.getHours() +
//     ":" +
//     date.getMinutes() +
//     ":" +
//     date.getSeconds().toString()
//   );
// }

export function checkExpired(
  item: { createdAt: firebase.firestore.Timestamp },
  maxDuration: number
) {
  const hoursPassed = moment().diff(moment(item.createdAt.toDate()), "hours");
  return hoursPassed > maxDuration;
}

export const getGCSession = _getGCSession;

/**
 * We're going to take an array, and swap the last element with a random element in the array
 * @param array - the array to shuffle
 * @returns The array is being returned.
 */
export function shuffleDice(array: React.ElementType[]): number[] {
  let diceChosenSet = new Set<number>();

  while (diceChosenSet.size < 9) {
    let index = Math.floor(Math.random() * array.length);
    diceChosenSet.add(index);
  }
  return Array.from(diceChosenSet);
}

export async function resetDice(id: string) {
  const updates = {
    allowDice: false,
    allowIntroRating: false,
    allowRatingAverage: false,
    allowSettingIntentions: false,
    allowChooseRoles: false,
    allowChooseTopic: false,
    allowChoosePathway: false,
    allowSharingPanel: false,
    allowEndRating: false,
    allowEndRatingAverage: false,
    allowReflectionRating: false,
    allowEndOfSession: false,
    allowViewChooseRoles: false,
    allowSkipFeedBackForm: false,
    allowAddActivity: false,
    allowChooseActivity: false,
    dices: shuffleDice(DiceImages),
    createdAt: Timestamp.fromDate(new Date()),
    sessionInstance: uuid(),
    facilPageNumber: 1,
  };
  return updateGCSession(id, updates);
}

// export async function createABackup(sessionId: string) {
//   if (!sessionId) {
//     return;
//   }
//   const participants = await getParticipants(sessionId);
//   participants.forEach((participant) => {
//     exitGCSession(participant.userId, sessionId);
//     deleteParticipant(sessionId, participant.id);
//   });
//   const ref = projectFirestore.collection("ParticipantHistory").doc(sessionId);
//   const formattedDate = formatDate(new Date());
//   return Promise.all([
//     ref.set({ [formattedDate]: arrayUnion(...participants) }, { merge: true }),
//   ]);
// }

export const deleteParticipants = async (sessionId: string) => {
  if (!sessionId) {
    return;
  }
  const participants = await getParticipants(sessionId);

  participants.forEach((participant) => {
    exitGCSession(participant.userId, sessionId);
    deleteParticipant(sessionId, participant.id);
  });
};

// export async function createFeedbackBackup(id: string) {
//   let ref = projectFirestore.collection("FeedBackFormHistory").doc(id);
//   const ref2 = projectFirestore
//     .collection("FeedBackForm")
//     .where("growthCircle", "==", id);
//   await ref2.get().then(async function (doc) {
//     const formattedDate = formatDate(new Date());
//     await ref.set(
//       { [formattedDate]: arrayUnion(...doc.docs) },
//       { merge: true }
//     );
//     doc.forEach((d) => d.ref.delete());
//   });
// }

function _containsOrg(organisations: Organisation[], orgName: string) {
  return organisations.map((o) => o.name).includes(orgName);
}

// export const checkExpireGC = async (
//   growthCircleSession: GrowthCircleSession
// ) => {
//   const sessionId = growthCircleSession.id;
//   const sessionExpire = growthCircleSession.sessionExpire;
//   if (checkExpired(growthCircleSession, sessionExpire)) {
//     await createABackup(sessionId);
//     await createFeedbackBackup(sessionId);
//     await resetDice(sessionId);
//   }
// };

export async function prepareGrowthCircleSession(
  profile: Profile,
  organisations: Organisation[]
) {
  if (organisations.length === 0) {
    return;
  }
  const growthCircleSession = await getGCSessionByHost(profile.id);
  if (growthCircleSession) {
    if (!_containsOrg(organisations, growthCircleSession.organisation)) {
      updateGCSession(growthCircleSession.id, {
        type: organisations[0].name,
        organisation: organisations[0].name,
      });
    }
    // const sessionId = growthCircleSession.id;
    // const sessionExpire = growthCircleSession.sessionExpire;
    // if (checkExpired(growthCircleSession, sessionExpire)) {
    //   await createABackup(sessionId);
    //   await createFeedbackBackup(sessionId);
    //   await resetDice(sessionId);
    // }

    return growthCircleSession;
  } else if (organisations.length > 0) {
    const newGCSession: GrowthCircleSession = {
      ...defaultGCSessionModel,
      sessionId: `${profile?.displayName}-GrowthCircle`,
      facilOwner: `${profile?.id}`,
      name: `${profile?.displayName} GrowthCircle`,
      date: Timestamp.fromDate(new Date()),
      mentor: `${profile?.displayName}`,
      venue: [
        {
          id: "text",
          text: "Event",
          value: "Event",
          year: new Date().getFullYear(),
        },
      ],
      status: "Available",
      invite_code: PinGenerator(4),
      sessionExpire: 2,
      organisation: organisations[0].name,
      type: organisations[0].name,
      createdAt: Timestamp.fromDate(new Date()),
      sessionInstance: uuid(),
      location: organisations[0].name,
    };
    const docRef = await _addGCSession(newGCSession);
    if (!docRef) {
      return;
    }
    newGCSession.id = docRef.id;
    return newGCSession;
  }
}

export function getGCInviteCode(session: GrowthCircleSession | undefined) {
  return session ? session["invite_code"] : "";
}

export function getGCSessionByHost(hostId: string) {
  return _getGCSessionWhere(where("facilOwner", "==", hostId));
}

export const updateGCSession = _updateGCSession;

export const subscribeGCSession = _subscribeGCSession;
