import React from "react";
import classes from "./LarefSessionTemplate.module.css";
import PageWrapper from "components/utility/PageWrapper";
import Header from "components/Header/Header";
import FiveStepHeader from "components/FiveStepHeader/FiveStepHeader";
import ContentWrapper from "../ContentWrapper";
import Sage from "assets/BeanSquad/sage.png";

type LarefSessionTemplateProps = {
  stepperContent: string;
  children: React.ReactNode;
  leftMascot?: React.ReactNode;
  hasMascot: boolean;
  hasModal?: boolean;
};

/**
 * Template for Pages with Two Clouds, Laref and Grass backgrounds
 * @param {string} stepperContent - content fo the stepper
 * @param {string} larefState - state of laraef (shocked/default)
 * @param {React.ReactNode} children - content of component
 * @param {React.ReactNode} leftMascot? - adds the leftMascot (if necessary)
 */
const LarefSessionTemplate = ({
  stepperContent,
  children,
  leftMascot,
  hasMascot,
  hasModal,
}: LarefSessionTemplateProps) => {
  return (
    <PageWrapper pageType={"groundRules"}>
      <Header hasModal={hasModal} />
      <FiveStepHeader
        hasLightning={false}
        hasGuide={false}
        text={stepperContent}
        currentStep={6}
        hasModal={hasModal}
      />
      <ContentWrapper hasFiveStepper={true}>
        <div className={classes["container"]}>
          {/* Content which defaults Laref to the bottom of the screen */}
          <div className={classes["information"]}>
            <div className={classes["children"]}>{children}</div>

            <div className={classes["larefContainer"]}>
              {/* Spacer to align Laref */}
              <div className={classes["spacer"]}>
                <div className={classes["leftMascot"]}>{leftMascot}</div>
              </div>

              <div className={`${classes["laref"]} w-fit h-fit`}>
                {/* <Laref height={`100%`} width={`100%`} /> */}
                {hasMascot && (
                  <div className="min-h-[235px] md:min-h-[335px]">
                    <img
                      className="max-w-full max-h-full"
                      src={Sage}
                      alt="sage"
                    />
                  </div>
                )}
              </div>
            </div>
          </div>
        </div>
      </ContentWrapper>
    </PageWrapper>
  );
};

LarefSessionTemplate.defaultProps = {
  hasMascot: true,
};

export default LarefSessionTemplate;
