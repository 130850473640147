import ExpectIcons from "assets/GroundRules/ExpectIcons";
import { ExpectContents } from "./ContentTypes";

type ExpectRowProps = {
  content: ExpectContents[];
  title: string;
};

/**
 * Container that stores the different sections of what to expect in ground-rules
 * @param {ExpectContents[]} content - Markup of contents based on the section title
 * @param {string} title - title of the row
 */
const ExpectRow = ({ content, title }: ExpectRowProps) => {
  return (
    <div className={"bg-default text-standard p-5 rounded-3xl shadow-md"}>
      <p className="font-semibold text-xl">{title}</p>
      {content &&
        content.map((content, index) => {
          return (
            <div
              key={index}
              className={"flex gap-2 justify-start items-center"}
              style={{ margin: 0, marginLeft: "6px" }}
            >
              <ExpectIcons imageName={content.imageName} />
              <p className="pb-3">{content.text}</p>
            </div>
          );
        })}
    </div>
  );
};

export default ExpectRow;
