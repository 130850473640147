import React from "react";
import { ReactComponent as ActiveListening } from "./ActiveListening.svg";
import { ReactComponent as EmotionalAgility } from "./EmotionalAgility.svg";
import { ReactComponent as EmpoweringOthers } from "./EmpoweringOthers.svg";
import { ReactComponent as PerspectiveTaking } from "./PerspectiveTaking.svg";
import { ReactComponent as ReflectiveThinking } from "./ReflectiveThinking.svg";

const RolesSmallBubbleMap: { [key: string]: React.ElementType } = {
  "": ActiveListening,
  "Active Listening": ActiveListening,
  "Emotional Agility": EmotionalAgility,
  "Empowering Others": EmpoweringOthers,
  "Perspective-Taking": PerspectiveTaking,
  "Reflective Thinking": ReflectiveThinking,
};

export default RolesSmallBubbleMap;
