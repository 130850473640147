import React from "react";
import classes from "./CompletedContainer.module.css";

type CompletedContainerProps = {
  children: React.ReactNode[];
  header: string;
  id: string;
};

//Inner container within the bubble when user has completed session
/**
 * Component to generate dynamic content for the completed portion of EndOfSession
 * @param {React.ReactNode[]} children - JSX elements to be placed within this component
 * @param {string} header - Title of the container
 * @param {string} id - key of the container
 */
const CompletedContainer = ({
  children,
  header,
  id,
}: CompletedContainerProps) => {
  // const {} = props;
  return (
    <div className={classes["completedContainer"]} key={id}>
      {header && <h1 className={classes["header"]}>{header}</h1>}
      <div className={classes["completedChildren"]}>{children}</div>
    </div>
  );
};

export default CompletedContainer;
