import classes from "./Tabs.module.css";
import { useContext } from "react";
import ReflectShareSkillsContext from "./ReflectShareSkillsContext";

const tabsInfo = [
  {
    className: 'first-tab',
    label: 'Reflect'
  },
  {
    className: 'second-tab',
    label: 'Share'
  },
  {
    className: 'thire-tab',
    label: 'Role'
  }
]

interface TabsProps {
  context?: any;
}

const Tabs = ({ context }: TabsProps) => {
  //!!! need to fix this unknown type ts(2339)
  const {
    activated,
    setFirstActivated,
    setSecondActivated,
    setThirdActivated,
  } = useContext<any>(context ? context : ReflectShareSkillsContext);

  const ActivateHandlers = [
    setFirstActivated,
    setSecondActivated,
    setThirdActivated
  ]

  return (
    <div className={classes["tab-container"]}>
      {
        tabsInfo.map((tabInfo, index) => {
          return (
            <div
              className={classes["tab-border"]}
              key={tabInfo.label}
            >
              <div
                className={`${classes["tab"]} ${classes[tabInfo.className]} ${
                  activated[index] && classes["activated"]
                }`}
                onClick={ActivateHandlers[index]}
              >
                <b>
                  { tabInfo.label }
                </b>
              </div>
            </div>
          )
        })
      }
    </div>
  );
};

export default Tabs;
