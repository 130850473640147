import { ChangeEventHandler } from "react";

type Props = {
  label: string;
  value: string;
  onChange: ChangeEventHandler<HTMLInputElement>;
  placeholder?: string;
  isDisabled?: boolean;
};

export default function TextQuestion({
  label,
  value,
  onChange,
  placeholder,
  isDisabled,
}: Props) {
  return (
    <div className="py-2">
      <p className="text-slate-600 font-[700] p-4 select-none text-left">
        {label}
      </p>

      <input
        type="text"
        defaultValue={value}
        onChange={onChange}
        placeholder={placeholder}
        style={{ borderColor: "var(--main-colour)" }}
        className={`max-w-[290px] md:max-w-[360px] border-2  w-full focus:ring-0 bg-default rounded-lg 
            focus:border-b-main-colour 
         `}
        disabled={isDisabled}
      />
    </div>
  );
}
