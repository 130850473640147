import { FC, useEffect, useState } from "react";
import { ComponentProps } from "../SelfAwarenessComponentMapping";

import { IoIosRefreshCircle } from "react-icons/io";
import BackAndNextFlat from "components/utility/BackAndNextFlat";
import TextArea from "../SubComponents/TextArea";
import { useSelfAwarenessContext } from "hooks/useSelfAwarenessContext";

const RandomQuestion: FC<ComponentProps> = ({ backAndNextHandler }) => {
  const [index, setIndex] = useState<number>(0);
  const [answer, setAnswer] = useState("");
  const { journal } = useSelfAwarenessContext();
  const questions = [
    "What happened?",
    "What questions are you asking?",
    "What answers are you seeking?",
  ];

  // Load data from local storage on component mount
  useEffect(() => {
    const storedJournal = localStorage.getItem("SelfAwarenessJournal");
    if (storedJournal) {
      const parsedJournal = JSON.parse(storedJournal);
      setAnswer(parsedJournal.answer || "");

      // Find the index of the stored question in the questions array
      const storedQuestionIndex = questions.findIndex(
        (question) => question === parsedJournal.question
      );

      // Set the index from local storage or default to 0 if not found
      setIndex(storedQuestionIndex !== -1 ? storedQuestionIndex : 0);
    }
    // eslint-disable-next-line
  }, []); // Empty dependency array means this effect runs once on mount

  const answerHandler = (value: string) => {
    setAnswer(value);
    const storedJournal = localStorage.getItem("SelfAwarenessJournal");
    const existingJournal = storedJournal ? JSON.parse(storedJournal) : {};

    // Update the existing journal with the new answer
    const updatedJournal = {
      ...existingJournal,
      question: questions[index],
      answer: value,
    };

    // Store the updated journal in local storage
    localStorage.setItem(
      "SelfAwarenessJournal",
      JSON.stringify(updatedJournal)
    );
  };

  const handleRefreshClick = () => {
    // Generate a random index different from the current one
    let randomIndex: number;
    do {
      randomIndex = Math.floor(Math.random() * questions.length);
    } while (randomIndex === index);

    const storedJournal = localStorage.getItem("SelfAwarenessJournal");
    const existingJournal = storedJournal ? JSON.parse(storedJournal) : {};

    // Update the existing journal with the new question
    const updatedJournal = {
      ...existingJournal,
      question: questions[randomIndex],
    };

    // Store the updated journal and set the new index in local storage
    localStorage.setItem(
      "SelfAwarenessJournal",
      JSON.stringify(updatedJournal)
    );
    setIndex(randomIndex);
  };

  useEffect(() => {
    if (!journal) return;
  }, [journal]);

  return (
    <div className="py-8 px-4 w-full md:w-1/4">
      <div
        className="relative p-5 border-2 rounded-lg font-poppins font-semibold"
        style={{
          backgroundColor: "#FFF4B9",
          borderColor: "#FFE871",
          color: "var(--text-colour)",
        }}
      >
        <IoIosRefreshCircle
          style={{ color: "var(--icon-colour-0)" }}
          className="absolute top-2 right-2 cursor-pointer"
          size={30}
          onClick={handleRefreshClick}
        />
        <p>{questions[index]}</p>
      </div>
      {journal && (
        <TextArea
          maxLength={500}
          journal={journal}
          answer={answer}
          answerHandler={answerHandler}
        />
      )}

      <div className="mt-5">
        <BackAndNextFlat
          {...backAndNextHandler}
          nextAllowed={answer.length > 0 ? true : false}
        />
      </div>
    </div>
  );
};

export default RandomQuestion;
