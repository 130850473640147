import { Statistics, StatisticsName } from "models/dashboardStats";
import {
  ChangeEvent,
  Dispatch,
  SetStateAction,
  useCallback,
  useEffect,
  useState,
} from "react";

interface Props {
  statisticsId: string;
  statisticsName: StatisticsName;
  statisticsDescription: StatisticsName;
  currentStatistics: Statistics;
  isChecked: boolean;
  setStatistics: Dispatch<SetStateAction<Statistics>>;
}

/**
 * Singular dashboard statistics list item which includes statistics name,
 * description and a checkbox to toggle.
 *
 * @param {object} props
 * @param {StatisticsName} props.statisticsName name of the statistics.
 * @param {boolean} props.isChecked state variable boolean for controlling checkbox.
 * @param {Dispatch<SetStateAction<Statistics>>} props.setStatistics callback to state function to set statistics.
 * @returns DashboardStatsList component.
 */
export default function DashboardStatsListItem({
  statisticsId,
  statisticsName,
  statisticsDescription,
  currentStatistics,
  isChecked,
  setStatistics,
}: Props) {
  const [editing, setEditing] = useState(false);
  const [name, setName] = useState(
    currentStatistics[statisticsId].name.toString()
  );
  const [description, setDescription] = useState(
    currentStatistics[statisticsId].description.toString()
  );

  const handleRevert = () => {
    setEditing(false);
    setName(currentStatistics[statisticsId].name.toString());
    setDescription(currentStatistics[statisticsId].description.toString());
  };

  const handleChange = useCallback(
    (e: ChangeEvent<HTMLInputElement>) => {
      setStatistics((prevStats) => {
        return {
          ...prevStats,
          [statisticsId]: {
            ...prevStats[statisticsId],
            isShown: e.target.checked,
          },
        };
      });
    },
    // eslint-disable-next-line
    [setStatistics]
  );

  useEffect(() => {
    setStatistics((prevStats) => {
      return {
        ...prevStats,
        [statisticsId]: {
          ...prevStats[statisticsId],
          name: name,
          description: description,
        },
      };
    });
    // eslint-disable-next-line
  }, [name, description]);

  useEffect(() => {
    setEditing(false);
  }, [currentStatistics]);

  return (
    <div className="flex flex-row items-center gap-2">
      <div className="self-start">
        <input
          type="checkbox"
          onChange={handleChange}
          checked={isChecked}
          className="cursor-pointer"
          style={{
            height: "20px",
            width: "20px",
            borderRadius: "5px",
            backgroundColor: isChecked ? "var(--icon-colour-0)" : "",
          }}
        />
      </div>
      <div className="flex flex-col gap-2 flex-grow">
        {editing ? (
          <>
            <div>
              <input
                type="text"
                value={name}
                className="font-semibold !text-base"
                onChange={(e) => setName(e.target.value)}
              />
              <textarea
                className="w-full"
                rows={2}
                value={description}
                onChange={(e) => setDescription(e.target.value)}
              />
            </div>
          </>
        ) : (
          <>
            <div>
              <b>{statisticsName}</b>
            </div>
            <div>{statisticsDescription}</div>
          </>
        )}
      </div>
      <div>
        {editing ? (
          <button onClick={handleRevert}>Revert</button>
        ) : (
          <button onClick={() => setEditing(true)}>Edit</button>
        )}
      </div>
    </div>
  );
}
