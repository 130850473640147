import { useState } from "react";
import Header from "components/Header/Header";
import EmptySpace from "components/utility/EmptySpace";
import Center from "components/utility/Center/Center";
import SearchBar from "components/Resources/SearchBar";
import ResourcePlate from "./ResourcePlate";

const ResourceRoot = () => {
  const [setSearchWord] = useState("");

  return (
    <div>
      <Header />
      <EmptySpace height={"8vh"} />
      <Center>
        <SearchBar setSearchWord={setSearchWord} />
        <EmptySpace />
        <ResourcePlate title={"mental health"} />
      </Center>
    </div>
  );
};

export default ResourceRoot;
