import React from "react";
import classes from "./Locked.module.css";
import { ReactComponent as Lock } from "assets/Lock.svg";
import RolesIconMap from "assets/Roles/RolesIconMap";
import EmptySpace from "components/utility/EmptySpace";
// import { ReactComponent as InfoButton } from "assets/InfoButton.svg";

interface Props {
  role: string;
  coreSkill: string;
}

interface CoreSkillIconProps {
  fill: string;
  width?: string;
  height?: string;
}

const Locked: React.FC<Props> = ({ role, coreSkill }) => {
  const CoreSkillIcon: React.FC<CoreSkillIconProps> =
    RolesIconMap[coreSkill as keyof object];

  const eventStopper = (e: React.MouseEvent<HTMLDivElement>) => {
    e.stopPropagation();
  };
  return (
    <div
      className={classes["lock-card"]}
      onClick={(e: React.MouseEvent<HTMLDivElement>) => eventStopper(e)}
    >
      <div className={classes["lock-container"]}>
        <Lock style={{ width: "20px", height: "20px" }} />
        {/* <button onClick={() => {}}>
          <InfoButton fill={"var(--main-colour)"} />
        </button> */}
      </div>

      <strong className={classes["role"]}>{role.toUpperCase()}</strong>
      <EmptySpace height={"30px"} />
      <CoreSkillIcon
        fill="var(--main-colour-2)"
        height={"100px"}
        width={"100px"}
      />
    </div>
  );
};

export default Locked;
