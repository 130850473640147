import Header from "components/Header/Header";
import PageWrapper from "components/utility/PageWrapper";

import SelfDiscoveryCard from "./SelfDiscoveryCard";
import { MdArrowLeft } from "react-icons/md";
import { Link } from "react-router-dom";
import { useState } from "react";
import { useSelfAwareness } from "hooks/useSelfAwareness";

const SelfDiscovery = () => {
  const [hasModal, setHasModal] = useState(false);
  const { tools } = useSelfAwareness();
  const toggleModal = () => {
    if (!hasModal) {
      setHasModal(true);
    } else {
      setHasModal(false);
    }
  };

  return (
    <PageWrapper>
      <Header title="Self-Discovery" hasModal={hasModal} />

      <div className="mb-[100px]">
        <div className="mt-[25%] md:mt-[16%]">
          <div className="px-5 flex flex-col justify-center items-center">
            <p
              style={{ color: "#EB8181" }}
              className=" relative items-center  text-2xl font-semibold w-full text-center my-4"
            >
              <Link className="-top-5 left-0 absolute" to={`/`}>
                <MdArrowLeft size={60} />
              </Link>
              Self Awareness Tools
            </p>
            <p className="text-center text-standard">
              There are many different ways to self discover. Let’s get started.
            </p>
          </div>

          <div className="gap-4 p-5 grid grid-cols-2 md:grid-cols-2 xl:grid-cols-4 ">
            {tools &&
              tools.map((tool, index) => (
                <SelfDiscoveryCard
                  hasModal={hasModal}
                  setModal={toggleModal}
                  key={index}
                  tool={tool}
                />
              ))}
          </div>
        </div>
      </div>
    </PageWrapper>
  );
};

export default SelfDiscovery;
