import useOrganisationRole, { OrganisationRoleHook } from "hooks/organisation/useOrganisationRole";
import { createContext, ReactNode } from "react";

export const OrganisationRoleContext = createContext<OrganisationRoleHook | null>(null);

interface Props {
  organisationId: string
  children: ReactNode
}

export function OrganisationRoleContextProvider({ organisationId, children }: Props) {
  const organisationRoleHook = useOrganisationRole(organisationId);

  return (
    <OrganisationRoleContext.Provider value={organisationRoleHook}>
      {children}
    </OrganisationRoleContext.Provider>
  );
};
