import QrCodeDisplay from "components/QRcode/QrCodeDisplay";
import { Modal } from "flowbite-react";
import { GrowthCircleSession } from "interface/GrowthCircleSessionInterface";
import { Profile } from "interface/ProfileInterface";
import { useState } from "react";
import toast from "react-hot-toast";
import { AiFillCloseCircle } from "react-icons/ai";
import { ImQrcode } from "react-icons/im";

type SessionOwnerComponentProps = {
  growthCircleSession: GrowthCircleSession;
  profile: Profile;
};

const SessionOwnerComponent = ({
  growthCircleSession,
  profile,
}: SessionOwnerComponentProps) => {
  const [show, setShow] = useState(false);
  const copyClipboard = () => {
    navigator.clipboard.writeText(
      `${window.location.origin}/all-circles/welcome/${growthCircleSession.id}`
    );
    toast.success("successfully copied to clipboard");
  };
  return (
    <>
      <Modal show={show}>
        <Modal.Body className="relative">
          <AiFillCloseCircle
            size={30}
            onClick={() => setShow(!show)}
            color={`var(--main-colour)`}
            className="absolute top-2 right-2 cursor-pointer"
          />
          <div className="min-h-[40vh] text-center flex flex-col justify-center items-center text-standard">
            <h3 className="mb-5 text-lg font-normal">My QR Code</h3>
            <QrCodeDisplay
              size={200}
              value={`${window.location.origin}/all-circles/welcome/${growthCircleSession.id}`}
            />
            <p className="py-8 flex flex-col">
              Scan QR or click to copy
              <span className="block py-4" onClick={copyClipboard}>
                {`${window.location.origin}/all-circles/welcome/${growthCircleSession.id}`}
              </span>
              <span>Invite code :</span>
              <span className="py-2 text-5xl font-semibold">
                {growthCircleSession.invite_code}
              </span>
            </p>
          </div>
        </Modal.Body>
      </Modal>
      <div
        style={{
          color: "var(--main-colour)",
          borderColor: "var(--main-colour)",
        }}
        className=" mx-auto w-10/12 relative top-3 z-40  bg-white text-center font-semibold text-lg py-2 rounded-full border-4  shadow-md  "
      >
        <p
          style={{
            maxWidth: "100%", // Adjust this as needed
            overflow: "hidden",
            textOverflow: "ellipsis",
            whiteSpace: "nowrap",
          }}
        >
          {growthCircleSession?.name}
        </p>
        {profile && profile.uid === growthCircleSession.facilOwner && (
          <ImQrcode
            size={22}
            onClick={() => setShow(!show)}
            className="absolute top-3 right-3 cursor-pointer"
          />
        )}
      </div>
    </>
  );
};

export default SessionOwnerComponent;
