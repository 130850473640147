import React, { useEffect } from "react";
import classes from "./SubmitIntention.module.css";
import useParticipants from "hooks/useParticipants";
import { useState } from "react";
import { SUBMIT_INTENTION_PLACEHOLDER } from "strings/SettingIntentions";
import AutoResizeTextArea from "components/utility/Inputs/AutoResizeTextArea";
import usePromise from "hooks/utility/usePromise";
import { Profile } from "interface/ProfileInterface";

interface SubmitIntentionProps {
  profile: Profile | null;
  question?: string;
}

const SubmitIntention = ({ profile, question }: SubmitIntentionProps) => {
  const { addIntentions, addIntentionsRandomize } = useParticipants();
  const [entry, setEntry] = useState("");
  const { isLoading, resolve } = usePromise();

  const submitHandler = (e: React.FormEvent<HTMLFormElement>) => {
    e.preventDefault();
    if (question) {
      resolve(() => addIntentionsRandomize(entry, question));
    } else {
      resolve(() => addIntentions(entry));
    }
  };

  useEffect(() => {
    if (!profile) return;
  }, [profile]);

  return (
    <form className={classes["intention-form"]} onSubmit={submitHandler}>
      <div className={classes["text-area"]}>
        <AutoResizeTextArea
          isRequired={true}
          className="shadow-lg"
          onChange={(value) => setEntry(value)}
          placeholder={SUBMIT_INTENTION_PLACEHOLDER}
        />
      </div>
      <button
        type="submit"
        style={{
          backgroundColor: "var(--main-colour)",
          borderColor: "var(--main-colour-80)",
        }}
        className={`border-2 ${classes.button}  text-white text-sm font-bold py-2 px-4 rounded-full z-50 shadow-md transition duration-1000  `}
      >
        {isLoading ? "submitting..." : "submit"}
      </button>
    </form>
  );
};

export default SubmitIntention;
