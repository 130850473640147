import { Participant } from "interface/ParticipantInterface";
import { projectFirestore } from "../../../../firebase/config";
import React, { useEffect, useRef, useState } from "react";
import { DownloadTableExcel } from "react-export-table-to-excel";

import AsyncGroup from "./AsyncGroup";

import { DocumentData } from "firebase/firestore";
import ExportColumnDisplay from "./ExportColumnDisplay";
import { TEST_ORGANISATIONS } from "utility/growthCircles";
import ExportORSDisplay from "./ExportORSDisplay";
import { ORSInterface } from "interface/ORSInterface";

const ExportDataCustomFeedback = () => {
  const tableRef = useRef(null);
  const [datas, setDatas] = useState<Participant[]>([]);
  const [FeedBackLayout, setFeedbackLayout] = useState<DocumentData[]>([]);
  const [ORS, setORS] = useState<ORSInterface[]>([]);
  const organisationFeedbackMemo = () => {
    try {
      const unsubscribe = projectFirestore
        .collection("organisations")
        .doc("8WOcTxh8STHUV5wPgqbt")
        .collection("feedbackQuestions")
        .orderBy("order", "asc")
        .onSnapshot((snapshot) => {
          const updatedFeedback: DocumentData[] = [];
          snapshot.forEach((doc) => {
            updatedFeedback.push({
              id: doc.id,
              ...doc.data(),
            });
          });
          setFeedbackLayout(updatedFeedback);
        });

      return () => {
        unsubscribe();
      };
    } catch (error) {
      console.log(error);
    }
  };

  const organisationORSMemo = () => {
    try {
      const unsubscribe = projectFirestore
        .collection("organisations")
        .doc("8WOcTxh8STHUV5wPgqbt")
        .collection("ORS")
        .orderBy("order", "asc")
        .onSnapshot((snapshot) => {
          const updatedORS: ORSInterface[] = [];
          snapshot.forEach((doc) => {
            updatedORS.push({
              ...(doc.data() as ORSInterface),
            });
          });
          setORS(updatedORS);
        });

      return () => {
        unsubscribe();
      };
    } catch (error) {
      console.log(error);
    }
  };

  const fetchDataAndSortByCreatedAt = async () => {
    const ref = projectFirestore
      .collection("organisations")
      .doc("8WOcTxh8STHUV5wPgqbt")
      .collection("participantHistory")
      .where("growthCircleId", "not-in", TEST_ORGANISATIONS)
      .where("feedbackReflectionStatus", "==", "submitted")
      .get();

    ref.then((snapshots) => {
      let result: Participant[] = [];
      snapshots.docs.map((doc) => {
        console.log(doc.data());
        return result.push(doc.data() as Participant);
      });
      setDatas(result);
    });
  };

  useEffect(() => {
    fetchDataAndSortByCreatedAt();
    organisationFeedbackMemo();
    organisationORSMemo();
    // eslint-disable-next-line
  }, []);
  console.log(FeedBackLayout);
  return (
    <div>
      <div className="px-4">
        <DownloadTableExcel
          filename={`GrowthCircles-data-${new Date()}`}
          sheet="users"
          currentTableRef={tableRef.current}
        >
          <button className="bg-green-500 hover:bg-green-600 px-4 py-2 rounded-md text-white">
            Export Excel
          </button>
        </DownloadTableExcel>
      </div>
      <p>Total of {datas.length}</p>
      <table border={1} ref={tableRef}>
        <thead>
          <tr>
            <th scope="col" className="px-6 py-3 border border-gray-500">
              Date
            </th>
            <th scope="col" className="px-6 py-3 border border-gray-500">
              Time
            </th>
            <th scope="col" className="px-6 py-3 border border-gray-500">
              Email
            </th>
            <th scope="col" className="px-6 py-3 border border-gray-500">
              Name
            </th>
            <th scope="col" className="px-6 py-3 border border-gray-500">
              Intentions
            </th>
            <th scope="col" className="px-6 py-3 border border-gray-500">
              Random Intentions
            </th>
            <th scope="col" className="px-6 py-3 border border-gray-500">
              Core Role
            </th>
            <th scope="col" className="px-6 py-3 border border-gray-500">
              Role
            </th>
            <th scope="col" className="px-6 py-3 border border-gray-500">
              Role Description
            </th>
            <th scope="col" className="px-6 py-3 border border-gray-500">
              Topic
            </th>
            <th scope="col" className="px-6 py-3 border border-gray-500">
              Path
            </th>
            <th scope="col" className="px-6 py-3 border border-gray-500">
              Tutorial Group
            </th>
            <th scope="col" className="px-6 py-3">
              CheckIn Individually
            </th>
            <th scope="col" className="px-6 py-3 border border-gray-500">
              CheckIn Interpersonally
            </th>
            <th scope="col" className="px-6 py-3 border border-gray-500">
              CheckIn Social
            </th>
            <th scope="col" className="px-6 py-3 border border-gray-500">
              CheckIn Overall
            </th>
            <th scope="col" className="px-6 py-3 border border-gray-500">
              CheckOut Individually
            </th>
            <th scope="col" className="px-6 py-3 border border-gray-500">
              CheckOut Interpersonally
            </th>
            <th scope="col" className="px-6 py-3 border border-gray-500">
              CheckOut Social
            </th>
            <th scope="col" className="px-6 py-3 border border-gray-500">
              CheckOut Overall
            </th>
            {ORS &&
              ORS.length > 0 &&
              ORS.map((ors, index) => (
                <>
                  <React.Fragment key={index}>
                    <th
                      scope="col"
                      className="px-6 py-3 border border-gray-500"
                    >
                      {ors.title} ( Check-IN)
                    </th>
                  </React.Fragment>
                </>
              ))}
            {ORS &&
              ORS.length > 0 &&
              ORS.map((ors, index) => (
                <>
                  <React.Fragment key={index}>
                    <th
                      scope="col"
                      className="px-6 py-3 border border-gray-500"
                    >
                      {ors.title} ( Check-OUT)
                    </th>
                  </React.Fragment>
                </>
              ))}
            {FeedBackLayout &&
              FeedBackLayout.map((data, index) => (
                <>
                  {data.subCategories.map((category, index) => (
                    <React.Fragment key={index}>
                      <th
                        scope="col"
                        className="px-6 py-3 border border-gray-500"
                      >
                        {category.question.default}
                      </th>
                    </React.Fragment>
                  ))}
                </>
              ))}
            <th scope="col" className="px-6 py-3 border border-gray-500">
              Journal Status
            </th>
          </tr>
        </thead>
        <tbody>
          {datas &&
            datas.map((data) => (
              <tr key={data.id}>
                <td className="px-6 py-2 border border-gray-500">
                  {data.createdAt.toDate().toLocaleDateString("en-US", {
                    timeZone: "Asia/Manila",
                    month: "long",
                    day: "numeric",
                    year: "numeric",
                  })}
                </td>
                <td className="px-6 py-2 border border-gray-500">
                  {data.createdAt.toDate().toLocaleTimeString("en-US", {
                    timeZone: "Asia/Manila",
                  })}
                </td>
                <td className="px-6 py-2 border border-gray-500">
                  {" "}
                  {data.email}
                </td>
                <td className="px-6 py-2 border border-gray-500">
                  {data.userName}
                </td>
                <td className="px-6 py-2 border border-gray-500">
                  {data.intentions}
                </td>
                <td className="px-6 py-2 border border-gray-500">
                  {data.intentionQuestion && data.intentionQuestion !== "" && (
                    <>
                      <p>Q : {data.intentionQuestion}</p>
                      <p>A : {data.intentionsRandom}</p>
                    </>
                  )}
                </td>
                <td className="px-6 py-4 border border-gray-500">
                  {" "}
                  {data.role ? data.role.core_skills : ""}
                </td>
                <td className="px-6 py-4 border border-gray-500">
                  {" "}
                  {data.role ? data.role.role : ""}
                </td>
                <td className="px-6 py-4 border border-gray-500">
                  {" "}
                  {data.role ? data.role.description : ""}
                </td>
                <td className="px-6 py-4 border border-gray-500">
                  {" "}
                  {data.activity !== "" ? data.activity : data.topic}{" "}
                  {data.topic}
                </td>
                <td className="px-6 py-4 border border-gray-500">
                  {" "}
                  {data.activityTitle !== ""
                    ? data.activityTitle
                    : data.path}{" "}
                  {data.path}
                </td>
                <td className="px-6 py-4 border border-gray-500">
                  {" "}
                  {data.userId && <AsyncGroup userId={data.userId} />}
                </td>
                <td className="px-6 py-4 border border-gray-500">
                  {data.checkIn ? data.checkIn.individually : ""}
                </td>
                <td className="px-6 py-4 border border-gray-500">
                  {data.checkIn ? data.checkIn.interpersonally : ""}
                </td>
                <td className="px-6 py-4 border border-gray-500">
                  {data.checkIn ? data.checkIn.socially : ""}
                </td>
                <td className="px-6 py-4 border border-gray-500">
                  {data.checkIn ? data.checkIn.overall : ""}
                </td>
                <td className="px-6 py-4 border border-gray-500">
                  {data.checkOut ? data.checkOut.individually : ""}
                </td>
                <td className="px-6 py-4 border border-gray-500">
                  {data.checkOut ? data.checkOut.interpersonally : ""}
                </td>
                <td className="px-6 py-4 border border-gray-500">
                  {data.checkOut ? data.checkOut.socially : ""}
                </td>
                <td className="px-6 py-4 border border-gray-500">
                  {data.checkOut ? data.checkOut.overall : ""}
                </td>
                <ExportORSDisplay ORSLayout={ORS} ORSData={data} />
                <ExportColumnDisplay
                  feedbackLayout={FeedBackLayout}
                  feedbackData={data.feedbackReflection}
                />
                <td className="px-6 py-4 border border-gray-500">
                  {data.feedbackReflectionStatus
                    ? data.feedbackReflectionStatus
                    : "not submitted"}
                </td>
              </tr>
            ))}
        </tbody>
      </table>
    </div>
  );
};

export default ExportDataCustomFeedback;
