import { useState } from "react";
import AlertModal from "../../assets/AlertModal";
import classes from "./PanelInputField.module.css";
import { ReactComponent as HidePassword } from "../../assets/HidePassword.svg";
import { ReactComponent as ShowPassword } from "../../assets/ShowPassword.svg";

type PanelInputFieldProps = {
  id: string;
  label: string;
  type: string;
  placeholder: string;
  value: any;
  Invalid: string | boolean | null;
};

/**
 * Component for input fields in InfoPanel
 * @param {string} id - key of the input field
 * @param {string} label - label of the input field
 * @param {string} type - type of the input field
 * @param {string} placeholder - placeholder of the input field
 * @param {any} value - event handlers of the input field
 * @param {string | boolean | null} Invalid - invalid message/boolean of the input field
 */
const PanelInputField = ({
  id,
  label,
  type,
  placeholder,
  value,
  Invalid,
}: PanelInputFieldProps) => {
  const [viewStatus, setViewStatus] = useState(false);
  // const  = props;

  const passwordViewHandler = () => {
    setViewStatus((prevState) => !prevState);
  };
  return (
    <div className={`${classes["inputField"]} relative`}>
      <label htmlFor={id}>{label}</label>
      <input
        id={id}
        type={type === "password" ? (viewStatus ? "text" : "password") : type}
        autoComplete={type==='password' ? 'new-password' : ''}
        placeholder={placeholder}
        required
        {...value}
      />

      {type === "password" && (
        <button
          type="button"
          className={`${classes.password} absolute right-5 bottom-6`}
          onClick={passwordViewHandler}
        >
          {viewStatus ? <ShowPassword /> : <HidePassword />}
        </button>
      )}

      {Invalid && (
        <div className={classes["alert-placeholder"]}>
          <AlertModal text={Invalid} />
        </div>
      )}
    </div>
  );
};

export default PanelInputField;
