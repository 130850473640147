type UserListLoaderProps = {
  withProfile: boolean;
  quantity?: number;
};
const UserListLoader = ({ withProfile, quantity = 1 }: UserListLoaderProps) => {
  const loaders = Array.from({ length: quantity }, (_, index) => (
    <div
      key={index}
      className="min-h-[400px] w-full max-w-xs   border border-gray-200 rounded-lg shadow-sm"
    >
      <div className="animate-pulse flex-col space-x-4 flex items-center justify-center px-4 pt-4 relative">
        {withProfile && (
          <div className="rounded-full bg-gray-300 h-24 w-24 mt-8"></div>
        )}

        <div className="flex-1 space-y-6 py-1 w-full mt-8">
          <div className="h-2 w-40 mx-auto self-center bg-gray-300 rounded"></div>
          <div className="space-y-3">
            <div className="h-2 bg-gray-300 rounded"></div>
            <div className="h-2 bg-gray-300 rounded"></div>
            <div className="h-2 bg-gray-300 rounded"></div>
          </div>
          <div className="h-12 w-40 rounded-full mx-auto bg-gray-300"></div>
        </div>
      </div>
    </div>
  ));

  return <div className="flex flex-wrap gap-2 w-full mt-8">{loaders}</div>;
};

export default UserListLoader;
