import useOrganisationContext from "hooks/organisation/useOrganisationContext";
import { useAuthContext } from "hooks/useAuthContext";
import { useListSelect } from "hooks/utility/useListSelect";
import Organisation from "interface/OrganisationInterface";
import { Profile } from "interface/ProfileInterface";
import { getAllUserPermissions } from "models/organisationRole";
import { Permissions } from "models/permission";
import { useCallback, useEffect } from "react";

export interface OrganisationPermission {
  organisationId: string;
  name: string;
  permissions: Permissions;
}

export interface PermissionHook {
  permissions: OrganisationPermission[];
  selectedPermission: OrganisationPermission | null;
  selectPermissionByOrganisation: (organisationId: string) => void;
  getOrgsWithPermission: (
    profile: Profile | null,
    permission: string
  ) => Organisation[];
  checkHasPermission: (organisationId: string, permission: string) => boolean;
  updatePermission: (
    organisationId: string,
    roleName: string,
    permission: Permissions
  ) => void;
}

/**
 * Keeps the state of a list of permissions the user has.
 * The selected permission is of the selected organisation.
 *
 * @returns PermissionHook.
 */
export default function usePermission(): PermissionHook {
  const { profile } = useAuthContext();
  const { organisations, selectedOrganisation } = useOrganisationContext();

  const fetchUserPermissions = useCallback(async () => {
    if (!profile || organisations.length === 0) {
      return [];
    }

    return await getAllUserPermissions(profile, organisations);
  }, [profile, organisations]);

  const {
    items: permissions,
    selectedItem: selectedPermission,
    selectItem,
    updateSpecificItem,
  } = useListSelect(
    fetchUserPermissions,
    (permission1, permission2) =>
      permission1.organisationId === permission2.organisationId
  );

  const selectPermissionByOrganisation = useCallback(
    (organisationId: string) =>
      selectItem((permission) => permission.organisationId === organisationId),
    [selectItem]
  );

  /**
   * Retrieves organisations that a user has a certain permission for.
   *
   * @param profile Profile of the user.
   * @param permission Permission required to filter organisations by.
   * @return All organisations that the user has permissions for.
   */
  const getOrgsWithPermission = useCallback(
    (profile: Profile | null, permission: string) => {
      if (profile === null) {
        return [];
      }

      const filteredOrgs: Organisation[] = [];
      const nonATypeOrganisations = organisations.filter((org) => !org.isAType);

      for (const org of nonATypeOrganisations) {
        const orgPermission = permissions.find(
          (permission) => permission.organisationId === org.id
        );

        if (
          profile.access === "admin" ||
          (orgPermission && orgPermission.permissions[permission] === true)
        ) {
          filteredOrgs.push(org);
        }
      }

      return filteredOrgs;
    },
    [organisations, permissions]
  );

  /**
   * Checks if the user has a certain permission for an organisation.
   *
   * @param organisationId Id of the organisation to check permission for.
   * @param permission Permission to check.
   */
  const checkHasPermission = useCallback(
    (organisationId: string, permission: string) => {
      const orgPermissionToCheck = permissions.find(
        (permission) => permission.organisationId === organisationId
      );
      
      if (!orgPermissionToCheck) {
        return false;
      }

      return orgPermissionToCheck.permissions[permission] === true;
    },
    [permissions]
  );

  const updatePermission = useCallback(
    (organisationId: string, roleName: string, permission: Permissions) => {
      const permissionToUpdate = permissions.find(
        (permission) => permission.organisationId === organisationId
      );
      if (!permissionToUpdate || permissionToUpdate.name !== roleName) {
        return;
      }

      updateSpecificItem(
        (permission) => permission.organisationId === organisationId,
        { organisationId, name: roleName, permissions: permission }
      );
    },
    [updateSpecificItem, permissions]
  );

  useEffect(() => {
    if (!selectedOrganisation) {
      return;
    }

    selectPermissionByOrganisation(selectedOrganisation.id);
  }, [selectPermissionByOrganisation, selectedOrganisation]);

  return {
    permissions,
    selectedPermission,
    selectPermissionByOrganisation,
    getOrgsWithPermission,
    checkHasPermission,
    updatePermission,
  };
}
