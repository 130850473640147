import NavAdmin from "components/Admin/NavAdmin";
import SidebarAdmin from "components/Admin/SidebarAdmin";
import classes from "./Admin.module.css";
import AddNewVideo from "components/Admin/AdminVideo/AddNewVideo";

const AdminAddNewVideo = () => {
  return (
    <div>
      <NavAdmin />
      <div className={`${classes.container} min-h-screen`}>
        <SidebarAdmin />
        <div className={classes.main}>
          <div className={classes.title}>
            <h4>Add New Videos</h4>
          </div>
          <div>
            <AddNewVideo />
          </div>
        </div>
      </div>
    </div>
  );
};

export default AdminAddNewVideo;
