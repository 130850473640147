import { useEffect, useState } from "react";
import { IoMdArrowDropdown, IoMdArrowDropup } from "react-icons/io";
import { SelfAwarenessIconMapping } from "./IconMapping";
import { FaCheckCircle } from "react-icons/fa";

interface DropDownItemProps {
  title: string;
  subItem: string[];
  setSelectedItem: React.Dispatch<React.SetStateAction<string[]>>;
  selectedItems: string[];
}

const DropDownItem = ({
  title,
  subItem,
  setSelectedItem,
  selectedItems,
}: DropDownItemProps) => {
  const [show, setShow] = useState(false);

  useEffect(() => {
    const storedSelectedItems = localStorage.getItem("SelfAwarenessJournal");

    if (storedSelectedItems) {
      try {
        const parsedJournal = JSON.parse(storedSelectedItems);

        setSelectedItem(parsedJournal.selectedItems || []);
      } catch (error) {
        console.error("Error parsing stored data:", error);
      }
    }
    // eslint-disable-next-line
  }, []);

  const handleSubItemClick = (item: string) => {
    // Use the callback form to ensure the latest state
    setSelectedItem((prevSelected) => {
      // Check if the item is already selected
      if (prevSelected.includes(item)) {
        // If selected, remove it
        return prevSelected.filter((i) => i !== item);
      } else {
        // If not selected, add it
        return [...prevSelected, item];
      }
    });
  };

  return (
    <div
      style={{
        backgroundColor: "#FDF5E6",
        borderColor: "var(--icon-colour-0)",
      }}
      className={`${
        show ? "border-2 rounded-3xl h-34" : "rounded-full h-12"
      } relative my-2 font-semibold  w-full`}
    >
      <div
        className={`flex items-center justify-start  p-2 ${
          show ? "border-b-2" : ""
        }`}
        style={{
          borderBottomColor: "var(--icon-colour-0)",
          borderRadius: "2px",
        }}
      >
        {" "}
        <img
          width={"32px"}
          height={"32px"}
          src={SelfAwarenessIconMapping[title]}
          alt={title.toLowerCase()}
        />
        <p className="text-left ml-2">{title}</p>
        {!show ? (
          <IoMdArrowDropdown
            className="absolute right-2 cursor-pointer"
            size={40}
            style={{ color: "var(--icon-colour-0)" }}
            onClick={() => setShow(!show)}
          />
        ) : (
          <IoMdArrowDropup
            className="absolute right-2 cursor-pointer"
            size={40}
            style={{ color: "var(--icon-colour-0)" }}
            onClick={() => setShow(!show)}
          />
        )}
      </div>

      <div
        className={`${
          show ? "max-h-[300px] " : "max-h-0 opacity-0"
        } justify-center flex-col mt-5 gap-4 pb-5 transition-all duration-500 ease-out flex`}
      >
        <div
          className={`${
            show ? "opacity-100" : "opacity-0"
          } overflow-y-auto px-5 select-none `}
        >
          {subItem &&
            Array.isArray(subItem) &&
            subItem.sort().map((item, index) => (
              <div
                key={index}
                style={{ borderBottomColor: "var(--icon-colour-0)" }}
                className={`border-b-2 py-2 flex gap-2 items-center cursor-pointer`}
                onClick={() => handleSubItemClick(item)}
              >
                {selectedItems &&
                  Array.isArray(selectedItems) &&
                  selectedItems.includes(item) && (
                    <FaCheckCircle
                      size={20}
                      style={{ color: "var(--icon-colour-0)" }}
                    />
                  )}
                {item}
              </div>
            ))}
        </div>
      </div>
    </div>
  );
};

export default DropDownItem;
