import NavAdmin from "components/Admin/NavAdmin";
import classes from "./Admin.module.css";
import SidebarAdmin from "components/Admin/SidebarAdmin";

import { OrganisationContextProvider } from "context/OrganisationContext";
import { Toaster } from "react-hot-toast";

import { useParams } from "react-router-dom";

import GrowthCircleTypeDetails from "components/Organisation/GrowthCircleTypeDetails";

/**
 * Page to show all organisations that can be managed by the current user.
 *
 * @returns AdminGrowthCircleType component.
 */
export default function AdminGrowthCircleType() {
  const { id } = useParams();

  return (
    <div>
      <NavAdmin />
      <Toaster
        position="bottom-center"
        containerStyle={{
          bottom: "5%",
        }}
        toastOptions={{ duration: 4000 }}
      />
      <div className={`${classes.container} min-h-screen`}>
        <SidebarAdmin />
        <OrganisationContextProvider>
          <div className={classes.main}>
            <div className={classes.title}>
              <h4>GrowthCircle Type Details</h4>
            </div>
            {id && <GrowthCircleTypeDetails id={id} />}
          </div>{" "}
        </OrganisationContextProvider>
      </div>
    </div>
  );
}
