interface Props {
  arrowColor?: string;
}

const LeftArrow: React.FC<Props> = ({ arrowColor }) => {
  return (
    <svg
      width="20"
      height="34"
      viewBox="0 0 10 14"
      fill="current"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M0.286023 7.21608L8.95386 13.2169C9.05389 13.2861 9.17095 13.3266 9.29236 13.3341C9.41377 13.3416 9.53491 13.3157 9.64266 13.2592C9.75042 13.2028 9.84067 13.1179 9.90366 13.0138C9.96664 12.9098 9.99996 12.7905 10 12.6688V0.667199C10.0001 0.5455 9.96685 0.4261 9.90391 0.32194C9.84098 0.217779 9.75073 0.13283 9.64296 0.0762997C9.53519 0.0197692 9.414 -0.00618697 9.29253 0.00124448C9.17106 0.00867593 9.05394 0.0492115 8.95386 0.118458L0.286023 6.11927C0.197717 6.1809 0.125599 6.26294 0.0758019 6.35842C0.0260045 6.4539 0 6.55999 0 6.66767C0 6.77536 0.0260045 6.88145 0.0758019 6.97693C0.125599 7.07241 0.197717 7.15445 0.286023 7.21608Z"
        fill={arrowColor}
      />
    </svg>
  );
};

export default LeftArrow;
