import { useEffect, useRef } from "react";

interface PrivacyPolicySection {
  title: string;
  content: React.ReactNode;
}

const privacyPolicySections: PrivacyPolicySection[] = [
  {
    title: "Privacy Policy",
    content: (
      <>
        <p className="text-start py-2">
          Savoir Asia Consulting Pte. Ltd. (“<span className="font-bold">Growthbeans</span>”, “<span className="font-bold">us</span>”, 
          or “<span className="font-bold">we</span>”) operates as an online web application. 
          We provide services including but not limited to access to our internet web application (“<span className="font-bold">WebApp</span>”) at 
          https://growthcircles-webapp.com (the “<span className="font-bold">WebApp</span>”), interaction with our employees, interaction with other 
          users of the WebApp, facilitating your participation in a program executed by any organisation, providing 
          your information to event providers, providing you updates, sending you emails when you sign up to be part of 
          our mailing list when you request that we contact you, and/or anything done in connection with the use of 
          the WebApp, and anything in connection with such activities. 
        </p>
        <p className="text-start py-2">
          As part of our effort and obligation to protect your privacy, this privacy statement (“<span className="font-bold">Privacy Policy</span>”) 
          sets out the procedures and practices Growthbeans will undertake as part of providing our Services to you. 
          Unless otherwise defined in this Privacy Policy, all capitalised terms used shall have the meaning ascribed 
          to them in our Terms of Use.
        </p>
        <p className="text-start py-2">
          By using our WebApp, submitting your information to us, signing up to the WebApp, or utilising and/or engaging our 
          Services, you are deemed to have read, understood and agreed to the terms of this Privacy Policy (as may be amended 
          from time to time as the case may be) and thereby consent to our collection, use and disclosure of your information 
          as described in this Privacy Policy.
        </p>
        <p className="text-start py-2">
          If you do not agree or wish to opt out of this Privacy Policy, please refrain from and cease the use of the Services 
          and providing us with any information. You may at any time withdraw your consent for us to use and collect your 
          Personal Data (as defined below). For more information on withdrawing consent, and amending and removing Personal 
          Data and information, please refer to the paragraph entitled “Amendments and Removal of Information/Withdrawal of 
          Consent” below.
        </p>
        <p className="text-start py-2">
          Please note that there may be third-party websites linked to the WebApp. In connection with this, please note that 
          this Privacy Policy does not apply to these third-party websites and such third-party websites are separately governed 
          by their privacy policies. Growthbeans is not responsible for the collection, use, disclosure, and care of Personal 
          Data by any of such third-party websites.
        </p>
        <p className="text-2xl font-bold text-start py-4">
          USE AND COLLECTION OF DATA
        </p>
        <p className="text-start py-2">
          In the course of providing our Services to you, you may be required from time to time to provide your Personal 
          Data (as defined below).
        </p>
        <p className="text-start py-2">
          <span className="font-bold">Personal Data</span> refers to data, whether true or not, about an individual who can be identified from that data; 
          or from that data and other information to which Growthbeans has or is likely to have access. Such information 
          includes (without limitation) to:-
        </p>
        <ul className="text-start py-2 pl-6">
          <li className="py-2 list-inside list-disc">
            Your name;
          </li>
          <li className="py-2 list-inside list-disc">
            Identification numbers (in compliance with relevant regulations);
          </li>
          <li className="py-2 list-inside list-disc">
            Gender;
          </li>
          <li className="py-2 list-inside list-disc">
            Age;
          </li>
          <li className="py-2 list-inside list-disc">
            Your status as a caregiver;
          </li>
          <li className="py-2 list-inside list-disc">
            Job Status;
          </li>
          <li className="py-2 list-inside list-disc">
            Organisation you represent and/or you are a part of;
          </li>
          <li className="py-2 list-inside list-disc">
            Job Title;
          </li>
          <li className="py-2 list-inside list-disc">
            Personal Certifications;
          </li>
          <li className="py-2 list-inside list-disc">
            Email Address;
          </li>
          <li className="py-2 list-inside list-disc">
            Mailing Address;
          </li>
          <li className="py-2 list-inside list-disc">
            Phone Number;
          </li>
          <li className="py-2 list-inside list-disc">
            Date of Birth;
          </li>
          <li className="py-2 list-inside list-disc">
            Your image through pictures or videos of events you may attend through our Services;
          </li>
          <li className="py-2 list-inside list-disc">
            Details of your hobbies and interests;
          </li>
          <li className="py-2 list-inside list-disc">
            Results or outcomes of Self-assessment tests or evaluations of you;
          </li>
          <li className="py-2 list-inside list-disc">
            Bank Account Information; and
          </li>
          <li className="py-2 list-inside list-disc">
            Country of Residence.
          </li>
        </ul>
        <p className="text-start py-2">
          Growthbeans uses such information to (without limitation), provide our Services (as 
          defined in our Terms of Use) to you; verify your identity, personal information that 
          you provide to us; issuing you updates on the Services, or about Growthbeans; conduct 
          analytics analyse transaction behaviours to improve our Services; communicate with you; 
          for data analytics and research; impact reporting; for your personal development; facilitator 
          deployment; for collaboration opportunities between you and other Users; for logistics and 
          operations of events that may be listed on the WebApp; generally, improve our Services; provide 
          you information on our Services we believe may be of interest to you; and for any other purpose 
          that is required or permitted by any law or applicable regulation (together the “<span className="font-bold">Usage Purposes</span>”).
        </p>
        <p className="text-start font-bold italic py-2">
          Technology and cookies
        </p>
        <p className="text-start py-2">
          In addition, we may, in the course of our Services to you, also obtain certain information related to 
          you by means of Cookies.
        </p>
        <p className="text-start py-2">
          <span className="font-bold">Cookies</span> are small files that a website or service provider transfers to computer hard drives 
          through a web browser that enables the website or service provider’s systems to recognise the 
          browser and record certain information. Cookies may record data about the use of our WebApp. 
          Some information we may collect includes (but may not be limited to):-
        </p>
        <ul className="text-start py-2 pl-6">
          <li className="py-2 list-inside list-disc">
            Browser and device data, such as your IP address, device type, operating system and internal 
            browser type, screen resolution, operating system name and version, device manufacturer and model, 
            language used, plug-ins, and add-ons; and
          </li>
          <li className="py-2 list-inside list-disc">
            your interactions with the WebApp and use of our Services, and history.
          </li>
        </ul>
        <p className="text-start py-2">
          You may block the use of Cookies at any time, but doing so may prevent us from delivering certain Services to you.
        </p>
        <p className="text-start py-2">
          As aforementioned, Growthbeans may use information obtained by the use of Cookies for the Usage Purposes. In 
          doing so, Growthbeans may engage third-party service providers to assist us to assess usage patterns and 
          trends to customize and improve our Services to you. We will ensure that such third-party service providers 
          will be under obligation not to disclose your Personal Data which has been obtained during their engagement.
        </p>
        <p className="text-2xl font-bold text-start py-4">
          DISCLOSURE OF INFORMATION
        </p>
        <p className="text-start py-2">
          Growthbeans will not disclose your information beyond what is reasonable for us to provide our Services to 
          you. Subject to any applicable law or regulations, Growthbeans may reasonably disclose your information to 
          the following parties:-
        </p>
        <ul className="text-start py-2 pl-6">
          <li className="py-2 list-inside list-disc">
            as may be required by any government, statutory, or regulatory authority, or to comply with any applicable 
            law, court order, directive, or proceeding of such authority;
          </li>
          <li className="py-2 list-inside list-disc">
            other Members of the WebApp with whom you interact in the course of your use of the WebApp;
          </li>
          <li className="py-2 list-inside list-disc">
            other Users of the WebApp for the purposes of coordination and logistical operations for events 
            that are on the WebApp (including but not limited to including you into relevant off-WebApp chat 
            groups on WhatsApp for the same purpose);
          </li>
          <li className="py-2 list-inside list-disc">
            our clients, partners, or other such organisation of which run programs, these programs of which 
            your use of the WebApp is part of the process and/or facilitation of that program;
          </li>
          <li className="py-2 list-inside list-disc">
            our agents, affiliates, and professional advisors;
          </li>
          <li className="py-2 list-inside list-disc">
            as may be required for Growthbeans to enforce any of its rights or defend any action against Growthbeans;
          </li>
          <li className="py-2 list-inside list-disc">
            companies within the same corporate group as Growthbeans that are involved in operating or providing Services;
          </li>
          <li className="py-2 list-inside list-disc">
            third-party service providers which provide operational services to us for us to provide our Services to you;
          </li>
          <li className="py-2 list-inside list-disc">
            professional service providers which help us analyse our data and effectiveness in delivering our Services to you;
          </li>
          <li className="py-2 list-inside list-disc">
            any of your agents; and
          </li>
          <li className="py-2 list-inside list-disc">
            any other party to whom you authorise us to disclose your Personal Data.
          </li>
        </ul>
        <p className="text-start py-2">
          Growthbeans will under no circumstances sell or rent your information to third parties.
        </p>
        <p className="text-2xl font-bold text-start py-4">
          AMENDMENTS AND REMOVAL OF INFORMATION/WITHDRAWAL OF CONSENT
        </p>
        <p className="text-start py-2">
          You may amend or remove any Personal Data. You may do so by accessing our WebApp and making the 
          relevant changes and removals through the WebApp. Alternatively, you may amend or remove any of your 
          Personal Data by sending us an email 
          at <a href="mailto:growthbeans@savoir-asia.com"><span className="underline font-bold">growthbeans@savoir-asia.com</span></a>.
        </p>
        <p className="text-start py-2">
          If you do not want your Personal Data to be:-
        </p>
        <ul className="text-start py-2 pl-6">
          <li className="py-2 list-inside list-disc">
            used by us or by our affiliates, or companies within the same corporate group or to our service providers; and/or
          </li>
          <li className="py-2 list-inside list-disc">
            shared among our affiliates, business partners, or unrelated third parties,
          </li>
        </ul>
        <p className="text-start py-2">
          you may withdraw your consent to allow us to use your information, or op- out of such information 
          sharing or us. You may do so by sending us an email 
          at <a href="mailto:itsupport@savoir-asia.com"><span className="underline font-bold">itsupport@savoir-asia.com</span></a>.
        </p>
        <p className="text-start py-2">
          Please note should you provide us with a set of incomplete Personal Data, or withdraw your consent 
          to allow us to use your Personal Data or disclose your Personal Data, these will likely result in us 
          being unable to provide you with our Services. In addition, such provision of incomplete data may 
          result in restrictions on your use of the WebApp including but not limited to restricting your access 
          to the WebApp, suspension of your use of the Services, or termination of your Membership.
        </p>
        <p className="text-2xl font-bold text-start py-4">
          NOTIFICATIONS AND UPDATES
        </p>
        <p className="text-start py-2">
          By signing up to our WebApp, unless you indicate otherwise, you are deemed to have consented to us 
          sending you via email, welcome messages, requests for verification of your password and choice of 
          username, administrative emails, and updates on Services which we believe may be of interest to you. 
          We may also communicate with you via email or phone to provide your requested Services and address issues 
          relating to your Account.
        </p>
        <p className="text-start py-2">
          You may choose to opt out of certain classifications of emails by either unsubscribing to such updates or by 
          making your request by sending us an email 
          at <a href="mailto:itsupport@savoir-asia.com"><span className="underline font-bold">itsupport@savoir-asia.com</span></a>. 
            Please note that you will not be 
          able to opt-out of service announcements that contain important information about the service.
        </p>
        <p className="text-start py-2">
          Please note that by choosing not to opt-out, you are deemed to have consented to these communications.
        </p>
        <p className="text-2xl font-bold text-start py-4">
          RIGHT TO AMEND PRIVACY POLICY
        </p>
        <p className="text-start py-2">
          This Privacy Policy is made in line with the requirements under the Personal Data Protection Act 2012 and 
          all associated regulations and guidelines. Growthbeans reserves the right to amend the terms of this Privacy 
          Policy at any time to account for any changes to such regulations, guidelines and laws.  In continuing to use 
          the WebApp and our Services, you are deemed to have agreed and consented to any changes to our Private Policy 
          and the most recent version of the Privacy Policy shall apply unless stated otherwise.
        </p>
        <p className="text-2xl font-bold text-start py-4">
          GOVERNING LAW
        </p>
        <p className="text-start py-2">
          This Privacy Policy and any dispute arising out of or in connection with this Privacy Policy shall be governed 
          and construed in accordance with the laws of Singapore.
        </p>
        <p className="text-2xl font-bold text-start py-4">
          JURISDICTION
        </p>
        <p className="text-start py-2">
          You agree to submit to the non-exclusive jurisdiction of the courts of the Republic of Singapore.
        </p>
        <p className="text-2xl font-bold text-start py-4">
          CONTACT US
        </p>
        <p className="text-start py-2">
          If you have any queries, feedback or wish to discuss anything related to this Privacy Policy, please contact 
          us at <a href="mailto:growthbeans@savoir-asia.com"><span className="underline font-bold">growthbeans@savoir-asia.com</span></a>.
        </p>
      </>
    )
  }
]

interface PrivacyContentProps {
  startIndex: number; 
}


const PrivacyPolicyContent: React.FC<PrivacyContentProps> = ({ startIndex }) => {

  // Curretly, specifying the index is not necessaary since there is only one section. 
  // However, it is still specified in case additional sections are added in future.
  useEffect(() => {
    sectionRefs.current[startIndex]?.scrollIntoView({ behavior: 'smooth' });
  }, [startIndex]);

  const sectionRefs = useRef<(HTMLDivElement | null)[]>([]); // Specify the type for refs

  return (
    <div style={{ color: "var(--text-colour)" }}>
      {privacyPolicySections.map((section, index) => (
          <div key={index} ref={el => sectionRefs.current[index] = el}>
            <br />
            <h1 className="text-3xl font-bold">{section.title}</h1>
            {section.content}
          </div>
        ))}      
    </div>
  );
};

export default PrivacyPolicyContent;
