import React from "react";

interface Props {
  setIsCompressed: (b: boolean) => any;
}

const ContractButton: React.FC<Props> = ({ setIsCompressed }) => {
  const onClickContract = (event: React.MouseEvent<SVGSVGElement>) => {
    event.stopPropagation();
    setIsCompressed(true);
  };

  return (
    <svg
      width="40"
      height="40"
      viewBox="0 0 40 40"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      onClick={onClickContract}
    >
      <rect width="40" height="40" rx="10" fill="#EB8181" />
      <path
        d="M12 12L16.4444 16.4123M12 28L16.4444 23.5877M16.4444 23.5877V27.5989M16.4444 23.5877H12.4444M28 28L23.6 23.5877M27.9556 12L23.5556 16.4123M23.5556 12.4457V16.4568H27.5556M16.4444 12.4457V16.4568H12.4444M23.5556 27.5989V23.5877H27.5111"
        stroke="#FDF5E6"
        strokeWidth="2"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
    </svg>
  );
};

export default ContractButton;
