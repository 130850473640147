export const QuotationList = [
  "You are loved",
  "You are the best thing to ever happen to you.",
  "Live, Laugh, Love, Be HAPPY",
  "You didn't come this far just to stop here",
  "You are the artist of your own life, don't hand the paintbrush to anyone else",
  "The struggle you're in today is developing the strength you need for the future",
  "Be Stronger than your excuses",
  "Every accomplishment starts with the decision to TRY",
  "Fear kills more dreams than failure ever will",
  "It is not the mountain we conquer, it's ourselves - Edward Hillary",
  "Happiness is not the absence of problems, it's the ability to deal with them. - Steve Maraboli",
  "Happiness comes in waves, it'll find you again.",
  "Happiness is a healthy mental attitude, a grateful spirit, a clear conscience, and a heart full of love",
  "Happiness is good health and a bad memory - Ingrid Bergman",
  "Happiness is where you find it and very seldom where you seek it.",
  "Happiness adds and multiples as we divide it with others",
  "For every minute you're angry, you lose sixty seconds of happiness",
  "Our business in life is not to get ahead of others, but to get ahead of ourselves - Josheph Cossman",
  "Attitude is a little thing that makes a BIG difference - Winston Churchill",
  "Do what you feel in your heart to be right, for you'll be criticized anyway. - Eleanor Roosevelt",
  "You'll never change your life until you step out of your comfort zone; change begins at the end of your comfort zone. - Roy T. Bennett",
  "When you stop chasing the wrong things, you give the right things a chance to catch you - Lolly Daskal",
  "Everything is within your power, and your power is within you - Janice Trachtman",
  "Broken crayons still colour",
  "You can totally do this",
  "When nothing goes right, you go left.",
  "A smile is the shortest distance between two people",
  "Success is the sum of small efforts, repeated day-in and day-out - Robert Collier",
  "Don't be pushed around by the fears in your mind. Be led by the dreams in your heart. - Roy T. Bennett",
  "I can and I will",
  "If you want to achieve greatness, stop asking for permission.",
  "The past does not equal the future. - Tony Robbins",
];
