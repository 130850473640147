import { projectFirestore } from "../firebase/config";
import { SelfAwarenessToolInterface } from "interface/SelfAwarenessToolInterface";
import { createContext, useEffect, useReducer } from "react";

export const SelfAwarenessContext =
  createContext<SelfAwarenessContextStructure | null>(null);

interface SelfAwarenessContextStructure {
  journal: SelfAwarenessToolInterface | null;

  dispatch: React.Dispatch<actionStructure>;
}

interface stateStructure {
  journal: SelfAwarenessToolInterface | null;
}

interface actionStructure {
  type: "GET" | "REMOVE";
  payload: SelfAwarenessToolInterface | null;
}

interface Props {
  children: React.ReactNode;
  id: string;
}

export const authReducer = (state: stateStructure, action: actionStructure) => {
  switch (action.type) {
    case "GET":
      return { ...state, journal: action.payload };
    case "REMOVE":
      return { ...state, journal: null };

    default:
      return state;
  }
};

export const SelfAwarenessContextProvider: React.FC<Props> = ({
  children,
  id,
}) => {
  const [state, dispatch] = useReducer(authReducer, {
    journal: null,
  });

  useEffect(() => {
    const ref = projectFirestore.collection("SelfAwarenessTools").doc(id);

    ref.onSnapshot(
      (snapshot) => {
        if (snapshot.data()) {
          dispatch({
            type: "GET",
            payload: snapshot.data() as SelfAwarenessToolInterface,
          });
        } else {
          dispatch({
            type: "REMOVE",
            payload: null,
          });
        }
      },
      (err) => {
        console.log(err.message);
      }
    );
  }, [id]);

  /* Logging the state of the AuthContext. */
  // console.log(state);

  return (
    <SelfAwarenessContext.Provider value={{ ...state, dispatch: dispatch }}>
      {children}
    </SelfAwarenessContext.Provider>
  );
};
