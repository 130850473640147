import { useTour } from "@reactour/tour";
import EmptySpace from "components/utility/EmptySpace";

import useGroup from "hooks/organisation/useGroup";
import useOrganisationContext from "hooks/organisation/useOrganisationContext";
import usePromise from "hooks/utility/usePromise";
import { Group, SubGroup } from "interface/GroupInterface";

import classes from "pages/AllCircles/Admin/Admin.module.css";
import { Suspense, lazy, useEffect, useState } from "react";
import toast from "react-hot-toast";

import DeleteGroupModal from "./DeleteGroupModal";
import UpdateGroupModal from "./UpdateGroupModal";
import UpdateSubGroupModal from "./UpdateSubGroupModal";

import GroupLeaderList from "./GroupLeaderList";
import SubGroupLeaderList from "./SubGroupLeaderList";

import SubGroupUserList from "./SubGroupUserList";
import usePermissionContext from "hooks/permissions/usePermissionContext";
import { useAuthContext } from "hooks/useAuthContext";
import { P_CAN_ASSIGN_GROUP_LEADER } from "models/permission";

const GroupUserList = lazy(() => import("./GroupUserList"));
const GroupSettingsInput = lazy(() => import("./GroupSettingsInput"));
const AddGroupAddSubGroup = lazy(() => import("./AddGroupAddSubGroup"));
const GroupInviteUsersInput = lazy(() => import("./GroupInviteUsersInput"));

/**
 * Component for an organisation to update group related settings.
 *
 * @returns GroupSettings component.
 */
export default function GroupsSettings() {
  const { setIsOpen: setIsTourOpen } = useTour();
  const { profile } = useAuthContext();
  const { selectedOrganisation } = useOrganisationContext();
  const { getOrgsWithPermission } = usePermissionContext();
  const canAssignGroup = getOrgsWithPermission(
    profile,
    P_CAN_ASSIGN_GROUP_LEADER
  );
  const [groupNames, setGroupNames] = useState<string[]>([]);
  const [groupName, setGroupName] = useState<string>("");
  const {
    getSubGroupsById,
    addUsersToGroup,
    deleteGroup,
    updateGroup,
    updateSubGroup,
    groups,
    subGroups: docSubGroup,
  } = useGroup();

  const [show, setShow] = useState(false);
  const [editShow, setEditShow] = useState(false);
  const [editSubShow, setEditSubShow] = useState(false);
  const [subGroups, setSubGroups] = useState<string[]>([]);

  const [subGroupCollections, setSubGroupCollections] = useState<SubGroup[]>(
    []
  );
  const [focusGroup, setFocusGroup] = useState<Group | null>(null);
  const [focusSubGroup, setFocusSubGroup] = useState<SubGroup | null>(null);

  const [selectedUsers, setSelectedUsers] = useState<string[]>([]);
  const [subGroupName, setSubGroupName] = useState("");
  const handleChange = (selectedOptions) => {
    const selectedIds = selectedOptions.map(
      (option: { value: string }) => option.value
    );
    setSelectedUsers(selectedIds);
  };
  const { isLoading, resolve } = usePromise();

  const handleGroupChange = async (value: string) => {
    setGroupName(value);
  };

  const handleSubGroupChange = async (value: string) => {
    const _subGroup = subGroupCollections.find(
      (group) => group.groupName === value && group.groupID === focusGroup?.id
    );
    setSubGroupName(value);
    if (_subGroup) {
      setFocusSubGroup(_subGroup);
    }
  };

  const getSubGroups = async () => {
    if (groupName.length > 0) {
      const selectedGroup = groups.find((group) => group.id === focusGroup?.id);
      if (selectedGroup) {
        const _subGroups = await getSubGroupsById(selectedGroup.id ?? "");
        if (_subGroups) {
          const _groupNames = _subGroups.map((obj) => obj.groupName);
          setFocusSubGroup(null);
          setSubGroups(_groupNames);
        }
      } else {
        setSubGroups([]);
      }
    } else {
      setSubGroups([]);
    }
  };

  const deleteHandler = async () => {
    const selectedGroup = groups.find((group) => group.groupName === groupName);
    const selectedSubGroup = subGroupCollections.find(
      (group) => group.groupName === subGroupName
    );
    if (selectedGroup && selectedGroup?.id) {
      resolve(() =>
        deleteGroup(selectedGroup?.id ?? "", selectedSubGroup?.id ?? "")
      ).then(() => {
        setGroupName("");
        setSubGroupName("");

        setFocusGroup(selectedGroup);
        setShow(!show);
      });
    } else {
      toast.error("problem deleting group data.");
    }
  };

  useEffect(() => {
    setGroupNames([]);
    if (groups) {
      const _groupNames = groups.map((obj) => obj.groupName);
      setGroupNames(_groupNames);
    }
  }, [groups]);

  const handleSubmit = () => {
    const selectedGroup = groups.find((group) => group.groupName === groupName);
    const selectedSubGroup = subGroupCollections.find(
      (group) =>
        group.groupName === subGroupName && group.groupID === focusGroup?.id
    );

    if (selectedGroup && selectedGroup.id) {
      resolve(() =>
        addUsersToGroup(
          selectedGroup.id ?? "",
          selectedSubGroup?.id ?? "",
          selectedUsers
        )
      ).then(() => {
        setFocusGroup(selectedGroup);
        if (selectedSubGroup) {
          setFocusSubGroup(selectedSubGroup);
        }
      });
    } else {
      toast.error("Problem inviting users.");
    }
  };

  useEffect(() => {
    if (groups) {
      getSubGroups();
    }
    // eslint-disable-next-line
  }, [groupName, groups]);

  useEffect(() => {
    if (!profile) return;
    if (!selectedOrganisation) return;
    // eslint-disable-next-line
  }, [selectedOrganisation, profile]);

  useEffect(() => {
    const selectedGroup = groups.find((group) => group.groupName === groupName);
    if (selectedGroup) {
      setFocusGroup(selectedGroup);
    }
    // console.log(focusGroup);
    // eslint-disable-next-line
  }, [groupName, subGroupName, groups, focusGroup]);

  useEffect(() => {
    const selectedSubGroup = docSubGroup.find(
      (group) => group.id === focusSubGroup?.id
    );
    if (selectedSubGroup) {
      setFocusSubGroup(selectedSubGroup);
      setSubGroupCollections(docSubGroup);
    }
    const _groupNames = docSubGroup
      .filter((g) => g.groupID === focusGroup?.id)
      .map((obj) => obj.groupName);

    setSubGroups(_groupNames);
    if (focusSubGroup?.groupName) {
      setSubGroupName(focusSubGroup?.groupName);
    } else {
      setSubGroupName("");
    }
    // eslint-disable-next-line
  }, [docSubGroup, focusSubGroup]);

  const handleActualChange = async () => {
    setFocusSubGroup(null);
    setSubGroupName("");
    if (groupName.length === 0) {
      setSubGroupCollections([]);
      setFocusGroup(null);
      setSubGroups([]);
    } else {
      const selectedGroup = groups.find(
        (group) => group.groupName === groupName
      );

      if (selectedGroup) {
        setFocusGroup(selectedGroup);
      }

      if (groups && groups.length > 0 && selectedGroup) {
        const _subGroups = await getSubGroupsById(selectedGroup.id ?? "");

        if (_subGroups) {
          setSubGroupCollections(_subGroups);
          const _groupNames = _subGroups.map((obj) => obj.groupName);
          setSubGroups(_groupNames);
        } else {
          setSubGroups([]);
        }
      } else {
        setSubGroups([]);
      }
    }
  };

  useEffect(() => {
    handleActualChange();
    // eslint-disable-next-line
  }, [groupName]);

  const showEditHandler = () => {
    setEditShow(!editShow);
  };

  return (
    <div className={`flex flex-col mb-[100px] ${classes["container"]}`}>
      <DeleteGroupModal
        handler={deleteHandler}
        isLoading={isLoading}
        groupName={groupName}
        subGroupName={subGroupName}
        show={show}
        setShow={setShow}
      />
      <UpdateGroupModal
        setFocusGroup={setFocusGroup}
        setGroupName={setGroupName}
        handler={updateGroup}
        editingGroup={groups}
        isLoading={isLoading}
        resolve={resolve}
        show={editShow}
        setShow={setEditShow}
        groupName={groupName}
      />
      <UpdateSubGroupModal
        handler={updateSubGroup}
        setFocusSubGroup={setFocusSubGroup}
        editingGroup={subGroupCollections}
        isLoading={isLoading}
        resolve={resolve}
        show={editSubShow}
        setShow={setEditSubShow}
        groupName={subGroupName}
      />

      <div className="border-b border-gray-200">
        <EmptySpace />
        <button
          className={`${classes["button"]}`}
          onClick={() => setIsTourOpen(true)}
        >
          Show Tutorial
        </button>
        <EmptySpace />
        {selectedOrganisation && (
          <p className="text-xl my-4">
            Groups for <strong>{selectedOrganisation.name}</strong>
          </p>
        )}
        <p className="mb-8 justify-start items-center gap-2  text-lg">
          You can create new groups and subgroups by clicking the corresponding
          buttons. Additionally, users can be assigned to different groups and
          subgroups by choosing the group and subgroup names from the dropdown
          menu below.
        </p>

        <Suspense fallback="Loading...">
          <AddGroupAddSubGroup
            selectedOrganisation={selectedOrganisation}
            groups={groups}
            getSubGroups={getSubGroups}
          />
        </Suspense>

        <EmptySpace />
      </div>

      <Suspense fallback="Loading...">
        <GroupSettingsInput
          handleGroupChange={handleGroupChange}
          handleSubGroupChange={handleSubGroupChange}
          groupNames={groupNames}
          groupName={groupName}
          subGroups={subGroups}
          groups={groups}
          subGroupName={subGroupName}
          isLoading={isLoading}
          setShow={setShow}
          show={show}
          editSubShow={editSubShow}
          setEditSubShow={setEditSubShow}
          showEditHandler={showEditHandler}
        />
      </Suspense>

      <Suspense fallback="Loading...">
        <GroupInviteUsersInput
          groupName={groupName}
          subGroupName={subGroupName}
          focusGroup={focusGroup}
          groups={groups}
          docSubGroup={docSubGroup}
          focusSubGroup={focusSubGroup}
          selectedOrganisation={selectedOrganisation}
          handleChange={handleChange}
          handleSubmit={handleSubmit}
          selectedUsers={selectedUsers}
          isLoading={isLoading}
        />
      </Suspense>

      {groups && (
        <>
          <EmptySpace />

          {groupName !== "" &&
            focusGroup &&
            focusGroup?.users &&
            focusGroup?.users.length > 0 &&
            selectedOrganisation && (
              <div className="py-4">
                <GroupLeaderList
                  canAssignGroup={
                    canAssignGroup.find(
                      (org) => org.id === selectedOrganisation.id
                    )
                      ? true
                      : false
                  }
                  group={focusGroup}
                  selectedOrganisation={selectedOrganisation}
                  users={focusGroup?.users}
                />
                <p className="mt-2">
                  {" "}
                  <strong>{focusGroup?.users.length}</strong> Total Users in
                  this Group <strong>{groupName}</strong>
                </p>
                <Suspense fallback={<p>Loading...</p>}>
                  <GroupUserList
                    group={focusGroup}
                    selectedOrganisation={selectedOrganisation}
                  />
                </Suspense>
              </div>
            )}

          <EmptySpace />
          <hr />
          <EmptySpace />

          {subGroupName !== "" &&
            focusGroup &&
            focusSubGroup &&
            focusSubGroup.users &&
            focusSubGroup.users.length > 0 &&
            selectedOrganisation && (
              <div className="py-4">
                <SubGroupLeaderList
                  canAssignGroup={
                    canAssignGroup.find(
                      (org) => org.id === selectedOrganisation.id
                    )
                      ? true
                      : false
                  }
                  group={focusGroup}
                  subgroup={focusSubGroup}
                  selectedOrganisation={selectedOrganisation}
                  users={focusSubGroup?.users}
                />

                <Suspense fallback={<p>Loading...</p>}>
                  <SubGroupUserList
                    group={focusGroup}
                    subgroup={focusSubGroup}
                    selectedOrganisation={selectedOrganisation}
                    users={focusSubGroup.users}
                  />
                </Suspense>
              </div>
            )}
        </>
      )}
    </div>
  );
}
