import DashboardStatsListItem from "components/Organisation/DashboardSettings/DashboardStatsListItem";
import EmptySpace from "components/utility/EmptySpace";
import usePromise from "hooks/utility/usePromise";
import Organisation from "interface/OrganisationInterface";
import { Statistics, setDashboardStats } from "models/dashboardStats";
import classes from "pages/AllCircles/Admin/Admin.module.css";
import {
  Dispatch,
  FormEvent,
  SetStateAction,
  useCallback,
  useEffect,
  useState,
} from "react";
import toast from "react-hot-toast";

interface Props {
  organisation: Organisation;
  setCurrentStatistics: Dispatch<SetStateAction<Statistics>>;
  currentStatistics: Statistics;
}

/**
 * List of dashboard statistics and their description. Can enable or disable the presence
 * of the dashboard statistics from this list.
 *
 * @param {object} props
 * @param {Organisation} props.organisation selected organisation.
 * @param {Dispatch<SetStateAction<Statistics>>} props.setCurrentStatistics set the current statistics.
 * @return DashboardStatsList component.
 */
export default function DashboardStatsList({
  organisation,
  setCurrentStatistics,
  currentStatistics,
}: Props) {
  const [statistics, setStatistics] = useState<Statistics>(currentStatistics);
  const { resolve, isLoading } = usePromise();
  const organisationId = organisation.id;

  const fetchDashboardStats = useCallback(async (orgId: string) => {
    setStatistics(currentStatistics);
    // eslint-disable-next-line
  }, []);

  const handleSubmit = useCallback(
    async (e: FormEvent<HTMLButtonElement>) => {
      e.preventDefault();

      resolve(() =>
        setDashboardStats(organisationId, { organisationId, statistics })
      )
        .then(() => {
          setCurrentStatistics(statistics);
          toast.success("settings was successfully saved");
        })
        .catch((error) => toast.error(`problem saving error : ${error}`));
    },
    [resolve, organisationId, statistics, setCurrentStatistics]
  );

  useEffect(() => {
    fetchDashboardStats(organisationId);
  }, [fetchDashboardStats, organisationId]);

  return (
    <>
      <div dashboard-settings-tour="stats-selection" className="border p-4">
        {statistics &&
          Object.entries(statistics)
            .sort((a, b) => {
              var first = a[1]["order"];
              var second = b[1]["order"];
              if (typeof first === "number" && typeof second === "number") {
                return first - second; // Sort in ascending order
              }

              if (typeof first !== "number") return 1;
              if (typeof second !== "number") return -1;

              return 0;
            })
            .map(([statisticsName, value], index) => {
              const isChecked = value["isShown"];
              return (
                <div key={statisticsName}>
                  <DashboardStatsListItem
                    statisticsId={statisticsName.toString()}
                    statisticsName={value["name"].toString()}
                    statisticsDescription={value["description"].toString()}
                    currentStatistics={currentStatistics}
                    isChecked={isChecked as boolean}
                    setStatistics={setStatistics}
                  />
                  {index < Object.entries(statistics).length - 1 && (
                    <hr
                      className="my-3 border-t-0 border-gray-200"
                      style={{ borderWidth: "1px 0 0 0" }}
                    />
                  )}
                </div>
              );
            })}
      </div>
      <EmptySpace />
      <button
        dashboard-settings-tour="save-button"
        className={`${classes["button"]}`}
        onClick={handleSubmit}
        disabled={isLoading}
      >
        {isLoading ? "Saving..." : "Save"}
      </button>
    </>
  );
}
