import { useNavigate } from "react-router-dom";

import Header from "components/Header/Header";
import Steps from "components/utility/Steps";
import FiveStepHeader from "components/FiveStepHeader/FiveStepHeader";
import ContentWrapper from "components/utility/ContentWrapper";
import EmptySpace from "components/utility/EmptySpace";
import BackAndNext from "components/utility/BackAndNext";
import Center from "components/utility/Center/Center";
import NoGCSessionWrapper from "pages/NoGCSession/NoGCSessionWrapper";
import RoleResponse from "components/ViewChooseRoles/RoleResponse";

import { useGCSession } from "hooks/useGCSession";
import { useAuthContext } from "hooks/useAuthContext";

import { useGrowthCircleContext } from "hooks/useGrowthCircleContext";

import {
  CHOOSE_TOPIC_ROUTE,
  CHOOSE_ROLES_ROUTE,
  NG_CHOOSE_ACTIVITY,
  SUSS_CHOOSE_ACTIVITY,
} from "strings/Routes";
import { useEffect, useState } from "react";
import NGFooter from "components/Footers/NGFooter";
import useParticipants from "hooks/useParticipants";
import { checkCanAdvanceNextActivity } from "models/organisationRole";
import Instruction from "components/utility/Instruction";
import { updateWindowLocationPath } from "models/profile";

const INSTRUCTIONS_VIEW_ROLES = "Take note of everyone's selected roles";

const ViewChooseRoles = () => {
  const currentStep = 3;
  const stepsCount = 5;

  const { profile } = useAuthContext();
  const { growthCircleSession } = useGrowthCircleContext();
  const { sessionRole } = useParticipants();

  const [hasTrigger, setHasTrigger] = useState(false);
  const canAdvance =
    checkCanAdvanceNextActivity(sessionRole);

  const _setTrigger = () => {
    setHasTrigger(!hasTrigger);
  };
  let navigate = useNavigate();

  const { facilAllowNextPage } = useGCSession();

  useEffect(() => {
    if (!profile) {
      return;
    }
    updateWindowLocationPath(profile.uid)
  }, [profile]);

  const nextHandler = () => {
    if (canAdvance) {
      facilAllowNextPage("choose-topic");
    }

    // TODO : this will decide if normal growthcircles or for SUSS, NG etc
    if (growthCircleSession?.type === "NG") {
      navigate(NG_CHOOSE_ACTIVITY);
    } else if (growthCircleSession?.type === "SUSS") {
      navigate(SUSS_CHOOSE_ACTIVITY);
    } else {
      navigate(CHOOSE_TOPIC_ROUTE);
    }
  };

  // back button
  const backHandler = () => {
    navigate(CHOOSE_ROLES_ROUTE);
  };

  return (
    <NoGCSessionWrapper>
      <div className="relative">
        <Header hasModal={hasTrigger} />
        <FiveStepHeader
          hasLightning={true}
          hasGuide={true}
          currentStep={currentStep}
          hasModal={hasTrigger}
          setIsTriggerVisible={_setTrigger}
        />
        <ContentWrapper hasFiveStepper={true}>
          <Center>
            <EmptySpace height={"20px"} />
            <Steps count={stepsCount} currentStep={currentStep} />
            <Instruction
              text={INSTRUCTIONS_VIEW_ROLES}
            />
            <EmptySpace height={"30px"} />
            <RoleResponse />
            <EmptySpace height={"20px"} />
            <BackAndNext
              nextAllowed={canAdvance || growthCircleSession?.allowChooseTopic}
              nextCallback={nextHandler}
              backAllowed={true}
              backCallback={backHandler}
              backHidden={undefined}
            />

            <NGFooter type={growthCircleSession?.type} />
          </Center>
        </ContentWrapper>
        <EmptySpace height="10px" />
      </div>
    </NoGCSessionWrapper>
  );
};

export default ViewChooseRoles;
