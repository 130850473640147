import { CSSProperties, FC } from "react";
import classes from "./EmbeddedVideo.module.css";

interface Props {
  embedId: string;
  style?: CSSProperties;
}
/**
 * Embeds a youtube video in the webapp.
 *
 * @param embedId The video's id - everything after the "v=" in a youtube video link
 */
const EmbeddedVideo: FC<Props> = ({ embedId, style }) => {
  return (
    <div style={style} className={classes["container"]}>
      <iframe
        src={`https://www.youtube.com/embed/${embedId}`}
        title="Youtube vid"
        allow="accelerometer; encrypted-media; clipboard-write; gyroscope; picture-in-picture"
        allowFullScreen
      ></iframe>
    </div>
  );
};

export default EmbeddedVideo;
