import { Tabs } from "flowbite-react";
import classes from "./Tab.module.css";

export default function Tab({children}) {
  return (
    <Tabs.Group
      className={classes.tab}
    >
      { children }
    </Tabs.Group>
  );
}
