import classes from './InfoModal.module.css'
import { ReactComponent as CloseButton } from "../../../assets/CrossCircle.svg";
import Line from 'components/utility/Line/Line';
import ProgressBar from 'components/utility/ProgressBar/ProgressBar';

export default function InfoModal({dismissHandler}) {
  return (
    <div className={classes['modal-main-container']}>
        <div className={classes['modal-content']}>
            <p className={classes.notice}>Receiving feedback from others can help increase your role’s percentage!</p>
            <div className={classes['progressContainer']}>
                <ProgressBar value={0}/>
            </div>
            <Line fill={'red'}/>
            <p>At the end of every session, you can request for feedback on how well you did played your role.</p>
            <div className={classes['progressContainer']}>
                <ProgressBar value={40}/>
            </div>
            <ul>
                <li>Average rating of 4 and above = <span>increase by 30%</span></li>
                <li>Average rating of {`>=-3`} and 4 = <span>increase by 10%</span></li>
                <li>Average rating of 3 and below = no increase</li>
            </ul>
        </div>
        
       
    
        <CloseButton
            className={classes.closeButton}
            onClick={dismissHandler}
        />
      
       
    </div>
  )
}
