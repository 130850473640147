import { FC, lazy, useEffect, useState } from "react";
import { ComponentProps } from "../SelfAwarenessComponentMapping";
import { useSelfAwarenessContext } from "hooks/useSelfAwarenessContext";
import { useSelfAwareness } from "hooks/useSelfAwareness";
import SimpleLoader from "components/Loaders/SimpleLoader";
import InfoModal from "../SubComponents/InfoModal";
import ConfirmModal from "../SubComponents/ConfirmModal";

const QuestionCompItem = lazy(
  () => import("../SubComponents/QuestionCompItem")
);

interface Question {
  order: number;
  label: string;
  question: string;
}

const BasicQuestion: FC<ComponentProps> = ({
  backAndNextHandler,
  setHasModal,
}) => {
  const { journal } = useSelfAwarenessContext();
  const [show, setShow] = useState<boolean>(false);

  const [questions, setQuestions] = useState<Question[]>([]);
  const { getQuestionsComp } = useSelfAwareness();
  const [questionIndex, setQuestionIndex] = useState<number>(0);
  const [showConfirm, setShowConfirm] = useState<boolean>(false);

  const getQuestion = async () => {
    if (journal) {
      const _questions = (await getQuestionsComp(
        journal.id || ""
      )) as Question[];
      setQuestions(_questions);
    }
  };

  const handler = () => {
    setShow(!show);
    setHasModal(!show);
  };

  const questionIndexHandler = () => {
    if (questions.length === questionIndex + 1) {
      nextHandler();
    } else {
      setQuestionIndex((prevIndex) => prevIndex + 1);
    }
  };

  const questionBackHandler = () => {
    setQuestionIndex((prevIndex) => prevIndex - 1);
  };

  const nextHandler = () => {
    setShowConfirm(!showConfirm);
    setHasModal(!showConfirm);
  };

  useEffect(() => {
    if (!journal) return;
    getQuestion();
    // eslint-disable-next-line
  }, [journal]);

  return (
    <>
      <ConfirmModal
        showConfirm={showConfirm}
        setHasModal={setHasModal}
        backAndNextHandler={backAndNextHandler}
        handler={() => {
          setShowConfirm(!showConfirm);
          setHasModal(!showConfirm);
        }}
      />
      {journal && <InfoModal journal={journal} handler={handler} show={show} />}
      {questions.length === 0 && (
        <div className="flex justify-center items-center min-h-[200px]">
          <SimpleLoader />
        </div>
      )}

      {journal && questions.length > 0 && (
        <QuestionCompItem
          question={questions[questionIndex]}
          journal={journal}
          handler={handler}
          questionIndex={questionIndex}
          backAndNextHandler={backAndNextHandler}
          questionIndexHandler={questionIndexHandler}
          questionBackHandler={questionBackHandler}
        />
      )}
    </>
  );
};

export default BasicQuestion;
