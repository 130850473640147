import { FeedbackQuestion, FeedbackQuestions } from "./utility/feedback";

export const FEEDBACK_CATEGORY_GROUP = 'feedbackForGroup';

const membersAreSupportiveQuestion: FeedbackQuestion = {
  questionName: 'membersAreSupportive',
  type: 'smileyRating',
  question: { default: 'My group members are supportive of one another' },
  defaultResponse: 3
}

const badgeRatingQuestion: FeedbackQuestion = {
  questionName: 'Badges',
  type: 'badgeRating',
  question: {
    default: 'What went well in today\'s session? What else do you appreciate? What do you think contributed to that?'
  },
  defaultResponse: ''
}

const appreciationQuestion: FeedbackQuestion = {
  questionName: 'whatElseToAppreciete',
  type: 'textArea',
  question: {
    default: 'What went well in today\'s session? What else do you appreciate? What do you think contributed to that?'
  },
  defaultResponse: ''
}

const becomeFacilQuestion: FeedbackQuestion = {
  questionName: 'wantToBeAFacil',
  type: 'text',
  question: {
    default: 'Would you like to become a facilitator? (If yes, we will get in touch with you with more information)'
  },
  defaultResponse: 'yes'
}

const defaultGroupFeedbackQuestions: FeedbackQuestions = {
  title: 'For the Group',
  category: FEEDBACK_CATEGORY_GROUP,
  subCategories: [
    {
      questions: [membersAreSupportiveQuestion]
    },
    {
      questions: [badgeRatingQuestion]
    },
    {
      questions: [appreciationQuestion ]
    },
    {
      questions: [becomeFacilQuestion]
    }
  ]
};

// TODO move these to firestore and use modelOperations to add, get, update and delete.
export function getGroupFeedbackQuestions(organisationName: string): FeedbackQuestions {
  return defaultGroupFeedbackQuestions;
}
