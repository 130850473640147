import Header from "components/Header/Header";
import PageWrapper from "components/utility/PageWrapper";
import { useFirestore } from "hooks/useFirestore";
import { useEffect } from "react";
import { Link, useParams } from "react-router-dom";

const VerifiedPage = () => {
  const { id } = useParams();
  const { updateDocument } = useFirestore("users");

  useEffect(() => {
    const data = { isVerified: true };
    updateDocument(id, data);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [id]);

  return (
    <PageWrapper pageType={"groundRules"}>
      <Header />
      <div className="bg-white shadow-md mt-20 md:mt-36 w-full rounded-md max-w-3xl p-10 mx-auto px-2 sm:px-6 lg:px-8 mb-10 flex justify-center items-center flex-col gap-4">
        <h1 className="text-3xl font-bold italic">
          Woohoo! Congratulations 🎉
        </h1>
        <p className="text-center">
          Your account has been successfully verified, granting you complete
          access to the fantastic array of features offered by Growth Circles!
          Get ready to unlock your full potential! 😃
        </p>
        <p className="text-center">
          {" "}
          If you are already verified but unable to navigate to other pages,
          please <strong>close any additional tabs</strong> where GrowthCircle
          is open and refresh the current page.
        </p>
        <Link
          to={"/"}
          className="bg-green-500 text-green-200 rounded-3xl px-8 py-4 shadow-lg border-4 border-green-800"
        >
          Return To HomePage
        </Link>
      </div>
    </PageWrapper>
  );
};

export default VerifiedPage;
