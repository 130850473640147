import classes from "./TriggerHelper.module.css";
import TriggerCard from "assets/TriggerCard.webp";
import TriggerHelperImg from "assets/TriggerHelper.webp";
import { useState } from "react";

interface Props {
  setHasModal: (b: boolean) => any;
}

const TriggerHelper = ({ setHasModal }: Props) => {
  const [isTriggerShow, setTriggerShow] = useState(false);

  const backHandler = () => {
    if (isTriggerShow) {
      setTriggerShow(false);
    } else {
      setHasModal(false);
    }
  };

  const nextHandler = () => {
    if (!isTriggerShow) {
      localStorage.setItem("TriggerRead", "true");
      return setTriggerShow(true);
    } else {
      setHasModal(false);
    }
  };

  return (
    <div className={`${classes.container} w-full`}>
      {!isTriggerShow && (
        <>
          <div className={classes.content}>
            <img src={TriggerHelperImg} alt="trigger helper" />
          </div>
        </>
      )}

      {isTriggerShow && (
        <div className="flex justify-center items-center">
          <img src={TriggerCard} alt="trigger card" width={"250px"} />
        </div>
      )}

      <div className={`${classes["button-container"]} mt-5`}>
        <button onClick={backHandler}>Back</button>
        <button onClick={nextHandler}>Next</button>
      </div>
    </div>
  );
};

export default TriggerHelper;
