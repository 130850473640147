import { OrganisationContextProvider } from "context/OrganisationContext";

import Admin from "./Admin";

export default function MainDashboard() {
  return (
    <OrganisationContextProvider>
      <Admin />
    </OrganisationContextProvider>
  );
}
