import QuestionForm from "components/FeedBackForm/QuestionForm";
import { useEffect, useState } from "react";
import classes from "./Reflection.module.css";
import { LocalStorageKeys } from "App";
import useDebounce from "hooks/useDebounce";
import { useAuthContext } from "../../../hooks/useAuthContext";

const getInitialTextField = (userStorageKey) => {
  // localStorage.removeItem(LocalStorageKeys.REFLECTION.OPEN_ENDED_FIELDS);
  const storedFields = localStorage.getItem(
    userStorageKey + LocalStorageKeys.REFLECTION.OPEN_ENDED_FIELDS
  );

  const textFieldsValues = storedFields
    ? JSON.parse(storedFields)
    : {
        textField1: {
          question: "Do you have more takeaways?",
          answer: "",
        },
        textField2: {
          question: "What did you appreciate about the group?",
          answer: "",
        },
        textField3: {
          question: "Where are you in relation to the intention you set?",
          answer: "",
        },
      };

  return textFieldsValues;
  // return textFieldsValues ? textFieldsValues : {
  //   textField1: {
  //     question: "Do you have more takeaways?",
  //     answer: ''
  //   },
  //   textField2: {
  //     question : "What did you appreciate about the group?",
  //     answer: ''
  //   },
  //   textField3: {
  //     question : "Where are you in relation to the intention you set?",
  //     answer: ''
  //   }
  // }
};

/**
 * Sub-page that displays open fields for users to input their feedback
 * @param feedBackDone - method to fire after user has finished
 */
export default function ReflectionFeedBackForm({ feedBackDone }) {
  const { profile } = useAuthContext();
  const userStorageKey = profile!.id + "_";
  const [allValues, setAllValues] = useState(() =>
    getInitialTextField(userStorageKey)
  );

  const storeFieldsInLocalStorage = () => {
    localStorage.setItem(
      userStorageKey + LocalStorageKeys.REFLECTION.OPEN_ENDED_FIELDS,
      JSON.stringify(allValues)
    );
  };

  useEffect(() => {
    const element = document.getElementById("textField1");
    element?.focus({
      preventScroll: true,
    });
  }, []);

  const [storeFieldsDebouncer] = useDebounce(
    () => storeFieldsInLocalStorage(),
    350
  );

  const changeHandler = (e) => {
    // setAllValues({...allValues, [e.target.name] : e.target.value})
    const prev = { ...allValues };
    prev[e.target.name].answer = e.target.value;
    setAllValues(prev);
    storeFieldsDebouncer();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  };

  // const submitHandler =  () => {
  //   storeFieldsInLocalStorage();
  //   feedBackDone();
  //   setAllValues(getInitialTextField(userStorageKey));
  // }

  return (
    <div className={classes["questions-container"]}>
      {Object.keys(allValues).map((textField, index) => {
        return (
          <QuestionForm
            isAutoFocus={textField === "textField1"}
            key={textField}
            title={allValues[textField].question}
            name={textField}
            value={allValues[textField].answer}
            onChange={changeHandler}
          />
        );
      })}
      {/* <QuestionForm title={allValues.question1} name={'answer1'} value={allValues.answer1} onChange={changeHandler}/>
        <QuestionForm title={allValues.question2} name={'answer2'} value={allValues.answer2} onChange={changeHandler}/>
        <QuestionForm title={allValues.question3} name={'answer3'} value={allValues.answer3} onChange={changeHandler}/> */}
    </div>
  );
}
