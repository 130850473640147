import classes from "./EntryCards.module.css";

interface Props {
  randomize?: boolean;
  userName: string;
  intention: string;
  intentionQuestion?: string;
}

const EntryCards: React.FC<Props> = ({
  randomize,
  userName,
  intention,
  intentionQuestion,
}) => {
  if (!intention) return null;
  return (
    <>
      <div className={classes.content}>
        <div className={`${classes.header} text-xl font-semibold`}>
          <p className="w-36 text-ellipsis overflow-hidden">{userName}</p>
        </div>
        <div className={classes.body}>
          {randomize && intentionQuestion && (
            <>
              <p className="mb-4">
                <span className="font-semibold ">Q : </span>
                {intentionQuestion}
              </p>
              <p>
                <span className="font-semibold ">A : </span> {intention}
              </p>
            </>
          )}
          {!randomize && <p>{intention}</p>}
        </div>
      </div>
    </>
  );
};

export default EntryCards;
