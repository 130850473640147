import { useContext } from "react";
import { OrganisationContext } from "context/OrganisationContext";

const ERR_NO_PROVIDER =
  "useOrganisationContext must be used inside an OrganisationContextProvider";

export default function useOrganisationContext() {
  const context = useContext(OrganisationContext);

  if (!context) {
    throw Error(ERR_NO_PROVIDER);
  }

  return context;
}
