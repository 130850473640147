export const LocationSelectionData = [
  "The Red Box",
  "Online - Growth Collectives",
  "Yuhua CC",
  "Nee Soon South",
  "My Organization",
  "Others",
];

export const sortLocationSelectionData = [
  "The Red Box",
  "Online - Growth Collectives",
  "Yuhua CC",
  "Nee Soon South",
  "My Organization",
  "Others",
  "Online",
];
