import { Participant } from "interface/ParticipantInterface";
import { projectFirestore } from "../../../../firebase/config";
import { useEffect, useRef, useState } from "react";
import { DownloadTableExcel } from "react-export-table-to-excel";
import { TEST_ORGANISATIONS } from "utility/growthCircles";

const ExportDataAll = () => {
  const tableRef = useRef(null);
  const [datas, setDatas] = useState<Participant[]>([]);

  const fetchDataAndSortByCreatedAt = async () => {
    try {
      const orgRefs = await projectFirestore.collection("organisations").get();

      let result: Participant[] = [];

      for (const orgDoc of orgRefs.docs) {
        const participantHistoryRefs = await orgDoc.ref
          .collection("participantHistory")
          .where("growthCircleId", "not-in", TEST_ORGANISATIONS)
          .where("feedbackReflectionStatus", "==", "submitted")

          .get();

        const participants = participantHistoryRefs.docs.map(
          (doc) => doc.data() as Participant
        );

        result = result.concat(participants);
      }

      setDatas(result);
    } catch (error) {
      console.error("Error fetching data:", error);
    }
  };

  useEffect(() => {
    fetchDataAndSortByCreatedAt();
  }, []); // The empty dependency array means this effect runs once on component mount

  const defaultValue = 3;

  return (
    <div>
      <div className="px-4">
        <DownloadTableExcel
          filename={`GrowthCircles-data-${new Date()}`}
          sheet="users"
          currentTableRef={tableRef.current}
        >
          <button className="bg-green-500 hover:bg-green-600 px-4 py-2 rounded-md text-white">
            Export Excel
          </button>
        </DownloadTableExcel>
      </div>
      <table border={1} ref={tableRef}>
        <thead>
          <tr>
            <th scope="col" className="px-6 py-3 border border-gray-500">
              Date
            </th>
            <th scope="col" className="px-6 py-3 border border-gray-500">
              Time
            </th>
            <th scope="col" className="px-6 py-3 border border-gray-500">
              Email
            </th>
            <th scope="col" className="px-6 py-3 border border-gray-500">
              Name
            </th>
            <th scope="col" className="px-6 py-3 border border-gray-500">
              Intentions
            </th>
            <th scope="col" className="px-6 py-3 border border-gray-500">
              Session Role
            </th>
            <th scope="col" className="px-6 py-3 border border-gray-500">
              Session Instance
            </th>
            <th scope="col" className="px-6 py-3 border border-gray-500">
              Core Role
            </th>
            <th scope="col" className="px-6 py-3 border border-gray-500">
              Role
            </th>
            <th scope="col" className="px-6 py-3 border border-gray-500">
              Role Description
            </th>
            <th scope="col" className="px-6 py-3 border border-gray-500">
              Topic
            </th>
            <th scope="col" className="px-6 py-3 border border-gray-500">
              Path
            </th>
            <th scope="col" className="px-6 py-3">
              CheckIn Individually
            </th>
            <th scope="col" className="px-6 py-3 border border-gray-500">
              CheckIn Interpersonally
            </th>
            <th scope="col" className="px-6 py-3 border border-gray-500">
              CheckIn Social
            </th>
            <th scope="col" className="px-6 py-3 border border-gray-500">
              CheckIn Overall
            </th>
            <th scope="col" className="px-6 py-3 border border-gray-500">
              CheckOut Individually
            </th>
            <th scope="col" className="px-6 py-3 border border-gray-500">
              CheckOut Interpersonally
            </th>
            <th scope="col" className="px-6 py-3 border border-gray-500">
              CheckOut Social
            </th>
            <th scope="col" className="px-6 py-3 border border-gray-500">
              CheckOut Overall
            </th>
            <th scope="col" className="px-6 py-3 border border-gray-500">
              For Self - On a scale of 1-10, how satisfied are you with today’s
              Growth Circle?
            </th>
            <th scope="col" className="px-6 py-3 border border-gray-500">
              For Self - What takeaways and insights do you have from today’s
              Growth Circle?
            </th>
            <th scope="col" className="px-6 py-3 border border-gray-500">
              For Self - I felt engaged during today’s session
            </th>
            <th scope="col" className="px-6 py-3 border border-gray-500">
              For Self - I feel heard and understood
            </th>
            <th scope="col" className="px-6 py-3 border border-gray-500">
              For Self - I am inspired and encouraged
            </th>
            <th scope="col" className="px-6 py-3 border border-gray-500">
              For Self - I feel I understand myself better
            </th>
            <th scope="col" className="px-6 py-3 border border-gray-500">
              For Self - I was able to think deeper because of questions asked
            </th>
            <th scope="col" className="px-6 py-3 border border-gray-500">
              For Self - Overall, I have benefitted from the session
            </th>
            <th scope="col" className="px-6 py-3 border border-gray-500">
              For Self - I have learned/practiced skills that is applicable to
              my daily life
            </th>
            <th scope="col" className="px-6 py-3 border border-gray-500">
              For Facil - Please write your Growth Facilitator's Name
            </th>
            <th scope="col" className="px-6 py-3 border border-gray-500">
              For Facil - Create a space that made us feel safe to share
            </th>
            <th scope="col" className="px-6 py-3 border border-gray-500">
              For Facil - Communicate well with the group
            </th>
            <th scope="col" className="px-6 py-3 border border-gray-500">
              For Facil - Engage the group effectively
            </th>
            <th scope="col" className="px-6 py-3 border border-gray-500">
              For Facil - Provide useful insights
            </th>
            <th scope="col" className="px-6 py-3 border border-gray-500">
              For Facil - Facilitate learning through reflections
            </th>
            <th scope="col" className="px-6 py-3 border border-gray-500">
              For Facil - Have the necessary knowledge and skills for the role
            </th>
            <th scope="col" className="px-6 py-3 border border-gray-500">
              For Facil - Empower us to support one another
            </th>
            <th scope="col" className="px-6 py-3 border border-gray-500">
              For Facil - Invite and embrace all perspectives
            </th>
            <th scope="col" className="px-6 py-3 border border-gray-500">
              For Facil - Kindly share with us why you gave the above ratings
            </th>
            <th scope="col" className="px-6 py-3 border border-gray-500">
              For Group - My group members are supportive of one another
            </th>
            <th scope="col" className="px-6 py-3 border border-gray-500">
              For Group - What went well in today's session? What else do you
              appreciate? What do you think contributed to that?
            </th>
            <th scope="col" className="px-6 py-3 border border-gray-500">
              For Group - Would you like to become a facilitator? (If yes, we
              will get in touch with you with more information)
            </th>
          </tr>
        </thead>
        <tbody>
          {datas &&
            datas.map((data, index) => (
              <tr key={index}>
                <td className="px-6 py-2 border border-gray-500">
                  {data.createdAt.toDate().toLocaleDateString("en-US", {
                    timeZone: "Asia/Manila",
                    month: "long",
                    day: "numeric",
                    year: "numeric",
                  })}
                </td>
                <td className="px-6 py-2 border border-gray-500">
                  {data.createdAt.toDate().toLocaleTimeString("en-US", {
                    timeZone: "Asia/Manila",
                  })}
                </td>
                <td className="px-6 py-2 border border-gray-500">
                  {" "}
                  {data.email}
                </td>
                <td className="px-6 py-2 border border-gray-500">
                  {data.userName}
                </td>
                <td className="px-6 py-2 border border-gray-500">
                  {data.intentions}
                </td>
                <td className="px-6 py-2 border border-gray-500">
                  {data.sessionRole === "host" ? "Facilitator" : "Participant"}
                </td>
                <td className="px-6 py-2 border border-gray-500">
                  {data.sessionInstance}
                </td>
                <td className="px-6 py-4 border border-gray-500">
                  {" "}
                  {data.role ? data.role.core_skills : ""}
                </td>
                <td className="px-6 py-4 border border-gray-500">
                  {" "}
                  {data.role ? data.role.role : ""}
                </td>
                <td className="px-6 py-4 border border-gray-500">
                  {" "}
                  {data.role ? data.role.description : ""}
                </td>
                <td className="px-6 py-4 border border-gray-500">
                  {" "}
                  {data.activity !== "" ? data.activity : data.topic}{" "}
                  {data.topic}
                </td>
                <td className="px-6 py-4 border border-gray-500">
                  {" "}
                  {data.activityTitle !== ""
                    ? data.activityTitle
                    : data.path}{" "}
                  {data.path}
                </td>
                <td className="px-6 py-4 border border-gray-500">
                  {data.checkIn ? data.checkIn.individually : ""}
                </td>
                <td className="px-6 py-4 border border-gray-500">
                  {data.checkIn ? data.checkIn.interpersonally : ""}
                </td>
                <td className="px-6 py-4 border border-gray-500">
                  {data.checkIn ? data.checkIn.socially : ""}
                </td>
                <td className="px-6 py-4 border border-gray-500">
                  {data.checkIn ? data.checkIn.overall : ""}
                </td>
                <td className="px-6 py-4 border border-gray-500">
                  {data.checkOut ? data.checkOut.individually : ""}
                </td>
                <td className="px-6 py-4 border border-gray-500">
                  {data.checkOut ? data.checkOut.interpersonally : ""}
                </td>
                <td className="px-6 py-4 border border-gray-500">
                  {data.checkOut ? data.checkOut.socially : ""}
                </td>
                <td className="px-6 py-4 border border-gray-500">
                  {data.checkOut ? data.checkOut.overall : ""}
                </td>
                <td className="px-6 py-4 border border-gray-500">
                  {data.feedbackReflection !== undefined &&
                  data.feedbackReflection.feedbackForSelf
                    ? data.feedbackReflection.feedbackForSelf.satisfactory
                    : 5}
                </td>
                <td className="px-6 py-4 border border-gray-500">
                  {data.feedbackReflection !== undefined &&
                  data.feedbackReflection.feedbackForSelf
                    ? data.feedbackReflection.feedbackForSelf.takeaways
                    : ""}
                </td>
                <td className="px-6 py-4 border border-gray-500">
                  {data.feedbackReflection !== undefined &&
                  data.feedbackReflection.feedbackForSelf
                    ? data.feedbackReflection.feedbackForSelf.feltEngaged
                    : defaultValue}
                </td>
                <td className="px-6 py-4 border border-gray-500">
                  {data.feedbackReflection !== undefined &&
                  data.feedbackReflection.feedbackForSelf
                    ? data.feedbackReflection.feedbackForSelf.feltHeard
                    : defaultValue}
                </td>
                <td className="px-6 py-4 border border-gray-500">
                  {data.feedbackReflection !== undefined &&
                  data.feedbackReflection.feedbackForSelf
                    ? data.feedbackReflection.feedbackForSelf.inspired
                    : defaultValue}
                </td>
                <td className="px-6 py-4 border border-gray-500">
                  {data.feedbackReflection !== undefined &&
                  data.feedbackReflection.feedbackForSelf
                    ? data.feedbackReflection.feedbackForSelf.understandMyself
                    : defaultValue}
                </td>
                <td className="px-6 py-4 border border-gray-500">
                  {data.feedbackReflection !== undefined &&
                  data.feedbackReflection.feedbackForSelf
                    ? data.feedbackReflection.feedbackForSelf.thinkDeeper
                    : defaultValue}
                </td>
                <td className="px-6 py-4 border border-gray-500">
                  {data.feedbackReflection !== undefined &&
                  data.feedbackReflection.feedbackForSelf
                    ? data.feedbackReflection.feedbackForSelf.overallBenefitted
                    : defaultValue}
                </td>
                <td className="px-6 py-4 border border-gray-500">
                  {data.feedbackReflection !== undefined &&
                  data.feedbackReflection.feedbackForSelf
                    ? data.feedbackReflection.feedbackForSelf
                        .learnedAndPracticed
                    : defaultValue}
                </td>
                <td className="px-6 py-4 border border-gray-500">
                  {data.feedbackReflection !== undefined &&
                  data.feedbackReflection.feedbackForFacil
                    ? data.feedbackReflection.feedbackForFacil.facilName
                    : ""}
                </td>
                <td className="px-6 py-4 border border-gray-500">
                  {data.feedbackReflection !== undefined &&
                  data.feedbackReflection.feedbackForFacil
                    ? data.feedbackReflection.feedbackForFacil.createdSafeSpace
                    : defaultValue}
                </td>
                <td className="px-6 py-4 border border-gray-500">
                  {data.feedbackReflection !== undefined &&
                  data.feedbackReflection.feedbackForFacil
                    ? data.feedbackReflection.feedbackForFacil.communicatedWell
                    : defaultValue}
                </td>
                <td className="px-6 py-4 border border-gray-500">
                  {data.feedbackReflection !== undefined &&
                  data.feedbackReflection.feedbackForFacil
                    ? data.feedbackReflection.feedbackForFacil
                        .engagedTheGroupEffectively
                    : defaultValue}
                </td>
                <td className="px-6 py-4 border border-gray-500">
                  {data.feedbackReflection !== undefined &&
                  data.feedbackReflection.feedbackForFacil
                    ? data.feedbackReflection.feedbackForFacil
                        .provideUsefulInsights
                    : defaultValue}
                </td>
                <td className="px-6 py-4 border border-gray-500">
                  {data.feedbackReflection !== undefined &&
                  data.feedbackReflection.feedbackForFacil
                    ? data.feedbackReflection.feedbackForFacil
                        .facilitateLearningThoughtReflection
                    : defaultValue}
                </td>
                <td className="px-6 py-4 border border-gray-500">
                  {data.feedbackReflection !== undefined &&
                  data.feedbackReflection.feedbackForFacil
                    ? data.feedbackReflection.feedbackForFacil
                        .haveNecessaryKnowledgeAndSkillsForTheRole
                    : defaultValue}
                </td>
                <td className="px-6 py-4 border border-gray-500">
                  {data.feedbackReflection !== undefined &&
                  data.feedbackReflection.feedbackForFacil
                    ? data.feedbackReflection.feedbackForFacil
                        .empowerUsToSupportOneAnother
                    : defaultValue}
                </td>
                <td className="px-6 py-4 border border-gray-500">
                  {data.feedbackReflection !== undefined &&
                  data.feedbackReflection.feedbackForFacil
                    ? data.feedbackReflection.feedbackForFacil
                        .inviteAndEmbraceAllPerspectives
                    : defaultValue}
                </td>
                <td className="px-6 py-4 border border-gray-500">
                  {data.feedbackReflection !== undefined &&
                  data.feedbackReflection.feedbackForFacil
                    ? data.feedbackReflection.feedbackForFacil.otherFeedback
                    : ""}
                </td>
                <td className="px-6 py-4 border border-gray-500">
                  {data.feedbackReflection !== undefined &&
                  data.feedbackReflection.feedbackForGroup
                    ? data.feedbackReflection.feedbackForGroup
                        .membersAreSupportive
                    : defaultValue}
                </td>
                <td className="px-6 py-4 border border-gray-500">
                  {data.feedbackReflection !== undefined &&
                  data.feedbackReflection.feedbackForGroup
                    ? data.feedbackReflection.feedbackForGroup
                        .whatElseToAppreciete
                    : ""}
                </td>
                <td className="px-6 py-4 border border-gray-500">
                  {data.feedbackReflection !== undefined &&
                  data.feedbackReflection.feedbackForGroup
                    ? data.feedbackReflection.feedbackForGroup.wantToBeAFacil
                    : "no"}
                </td>
              </tr>
            ))}
        </tbody>
      </table>
    </div>
  );
};

export default ExportDataAll;
