import { SelfAwarenessToolInterface } from "interface/SelfAwarenessToolInterface";
import WeightInput from "./WeightInput";
import { FaPlusCircle } from "react-icons/fa";
import { useEffect, useState } from "react";
import { BackAndNextProps } from "../SelfAwarenessComponentMapping";
import BackAndNextFlat from "components/utility/BackAndNextFlat";
import toast from "react-hot-toast";

interface Criteria {
  criteriaText: string;
  weight: number;
}

const WeightageComp = ({
  journal,
  backAndNextHandler,
  nextHandler,
  backHandler,
}: {
  journal: SelfAwarenessToolInterface;
  backAndNextHandler: BackAndNextProps;
  nextHandler: (e: React.MouseEvent<HTMLButtonElement, MouseEvent>) => void;
  backHandler: () => void;
}) => {
  const [criteria, setCriteria] = useState<Criteria[]>([]);

  //clear gradingCriteria

  useEffect(() => {
    const storedJournal = localStorage.getItem("SelfAwarenessJournal");
    // Update local storage with the default criteria
    if (storedJournal) {
      const parsedJournal = JSON.parse(storedJournal);
      localStorage.setItem(
        "SelfAwarenessJournal",
        JSON.stringify({
          ...parsedJournal,

          criteriaGrading: null,
        })
      );
    }

    // eslint-disable-next-line
  }, []);

  // Load data from local storage on component mount
  useEffect(() => {
    const storedJournal = localStorage.getItem("SelfAwarenessJournal");

    if (storedJournal) {
      const parsedJournal = JSON.parse(storedJournal);

      if (parsedJournal && parsedJournal.criteria) {
        // Set the criteria from parsedJournal if it exists
        setCriteria(parsedJournal.criteria);
      } else {
        // Add three default criteria if criteria is missing
        const defaultCriteria = [
          { criteriaText: "", weight: 100 },
          { criteriaText: "", weight: 100 },
          { criteriaText: "", weight: 100 },
        ];

        setCriteria(defaultCriteria);

        // Update local storage with the default criteria
        localStorage.setItem(
          "SelfAwarenessJournal",
          JSON.stringify({
            ...parsedJournal,
            criteria: defaultCriteria,
            criteriaGrading: null,
          })
        );
      }
    }
    // eslint-disable-next-line
  }, []); // Empty dependency array means this effect runs once on mount

  const addWeightHandler = () => {
    // Assuming challenges is an array in your state
    const newCriteria = { criteriaText: "", weight: 100 }; // Default data

    // Assuming setCriteria is a function to update state
    setCriteria((prevCriteria) => [...prevCriteria, newCriteria]);

    const storedJournal = localStorage.getItem("SelfAwarenessJournal");
    const existingJournal = storedJournal ? JSON.parse(storedJournal) : {};
    const existingCriteria = existingJournal.criteria || [];

    // Update the local storage with the modified challenges array
    const updatedJournal = {
      ...existingJournal,
      criteria: [...existingCriteria, newCriteria], // Add the new challenge
    };

    localStorage.setItem(
      "SelfAwarenessJournal",
      JSON.stringify(updatedJournal)
    );
  };

  const removeHandler = (index: number) => {
    const storedJournal = localStorage.getItem("SelfAwarenessJournal");
    const existingJournal = storedJournal ? JSON.parse(storedJournal) : {};
    const existingSteps = existingJournal.criteria || [];
    const updatedSteps = existingSteps.filter((_, i) => i !== index); // Remove the challenge with the specified value

    // Update the local storage with the modified challenges array directly
    existingJournal.criteria = updatedSteps;
    localStorage.setItem(
      "SelfAwarenessJournal",
      JSON.stringify(existingJournal)
    );
    setCriteria(updatedSteps); // Update the state
  };

  const areAllCriteriaTextNotEmpty = (criteriaArray) => {
    return criteriaArray.every(
      (criteria) => criteria.criteriaText.trim() !== ""
    );
  };

  const nextAllowedHandler = (
    e: React.MouseEvent<HTMLButtonElement, MouseEvent>
  ) => {
    const storedJournal = localStorage.getItem("SelfAwarenessJournal");
    const existingJournal = storedJournal ? JSON.parse(storedJournal) : {};
    const existingSteps = existingJournal.criteria || [];

    const allCriteriaTextNotEmpty = areAllCriteriaTextNotEmpty(existingSteps);

    if (allCriteriaTextNotEmpty) {
      // All criteriaText values are not empty
      nextHandler(e);
    } else {
      // At least one criteriaText is empty
      // Your logic for handling the case where criteriaText is empty
      toast.error("Please fill-up all criteria");
    }
  };

  return (
    <div className={` flex flex-col`}>
      {criteria &&
        criteria.map((item, index) => (
          <WeightInput
            key={index}
            index={index}
            pageType={journal.pageType}
            removeHandler={removeHandler}
          />
        ))}

      <div className="flex justify-center items-center my-8">
        <FaPlusCircle
          className="cursor-pointer"
          onClick={addWeightHandler}
          size={30}
          style={{ color: "var(--icon-colour-0)" }}
        />
      </div>

      <div className="my-4">
        <BackAndNextFlat
          {...backAndNextHandler}
          nextAllowed={criteria.length > 0}
          backCallback={backHandler}
          nextCallback={(e) => nextAllowedHandler(e)}
        />
      </div>
    </div>
  );
};

export default WeightageComp;
