import { useEffect, useState } from "react";
import { useCollection } from "./useCollection";

interface Path {
  path: string;
  question: string[];
  category: string;
}

interface PathCollection {
  documents: Path[];
}

//TODO: pathways randomizer
const usePath = (path: string, generalTopic: string) => {
  const [personalQuestion, setPersonalQuestion] = useState<string[]>([]);
  const [generalQuestion, setGeneralQuestion] = useState<string[]>([]);
  const [firstFetch, setFirstFetch] = useState(true);
  let gen: Array<string> = [];
  switch (generalTopic) {
    case "Growth Circle 1 Topics":
      gen = ["general", "personal"];
      break;
    case "Growth Circle 2 Topics":
      gen = ["general"];
      break;
    case "Growth Circle 3 Topics":
      gen = ["general", "personal"];
      break;
    default:
      gen = ["general", "personal"];
      break;
  }

  const { documents }: PathCollection = useCollection(
    "Pathways",
    undefined,
    null,
    [
      ["path", "==", path],
      ["category", "in", gen],
    ]
  );

  useEffect(() => {
    if (documents.length === 0) return;
    if (!firstFetch) return;
    //TODO: fix this cause for custom topics the question will not show
    //if (!generalTopic) return;

    const personalQuestions = documents.filter(
      (q) => q.category === "personal"
    );

    const generalQuestions = documents.filter((q) => q.category === "general");

    if (
      generalQuestions.length > 0 &&
      generalQuestions[0].question !== undefined
    ) {
      const allQuestions = generalQuestions.flatMap((item) => item.question);

      if (allQuestions.length >= 3) {
        // Fisher-Yates shuffle algorithm
        for (let i = allQuestions.length - 1; i > 0; i--) {
          const j = Math.floor(Math.random() * (i + 1));
          [allQuestions[i], allQuestions[j]] = [
            allQuestions[j],
            allQuestions[i],
          ];
        }

        // Select three random questions
        const selectedGeneralQuestions = allQuestions.slice(0, 3);

        // Update your state with the selected questions
        setGeneralQuestion(selectedGeneralQuestions);
      } else {
        // Handle the case where there are not enough questions
        console.error("Not enough questions available.");
      }
    }

    if (
      personalQuestions.length > 0 &&
      generalTopic !== "Growth Circle 2 Topics"
    ) {
      // Concatenate individual questions into a single array of strings
      const allQuestions = personalQuestions.map((item) =>
        item.question.join(" ")
      );

      // Shuffle the questions using Fisher-Yates shuffle algorithm
      for (let i = allQuestions.length - 1; i > 0; i--) {
        const j = Math.floor(Math.random() * (i + 1));
        [allQuestions[i], allQuestions[j]] = [allQuestions[j], allQuestions[i]];
      }

      // Select three random questions (or fewer if there are less than three)
      const selectedPersonalQuestions = allQuestions.slice(0, 3);

      // Update your state with the selected questions
      setPersonalQuestion(selectedPersonalQuestions);
    } else {
      setPersonalQuestion([]);
    }

    setFirstFetch(false);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [documents, generalTopic, personalQuestion, generalQuestion]);

  return {
    personalQuestion,
    generalQuestion,
  };
};

export default usePath;
