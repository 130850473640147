import { colours } from "../components/styles/colourMap";

const PathCard = ({
  pageType,
  content,
  handleCardSelect,
  selectedItem,
}: {
  pageType: string;
  content: string;
  handleCardSelect: (title: string) => void;
  selectedItem: string | null;
}) => {
  return (
    <div
      onClick={() => handleCardSelect(content)}
      style={{
        borderColor:
          selectedItem === content
            ? colours[pageType]["border"]
            : colours[pageType]["background"],
        color: "var(--text-colour)",
      }}
      className={`${
        selectedItem === content ? "border-4" : "border-2"
      }  cursor-pointer max-w-[160px] min-w-[160px] min-h-[88px] bg-white rounded-xl flex flex-col justify-center items-center font-poppins text-center px-2`}
    >
      <div
        className="select-none break-words"
        dangerouslySetInnerHTML={{ __html: content }}
      ></div>
    </div>
  );
};

export default PathCard;
