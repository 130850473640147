import getLocalStorageOperations from "utility/localStorage";

const LOCALSTORAGE_KEY_FEEDBACK = "FeedBackFormData";

type Feedback = {
  feedbackReflection: {[category: string]: {
    [questionName: string]: string
  }}
}

function padMissingData(rawData: {}): Feedback {
  return {
    feedbackReflection: {},
    ...rawData
  }
}

// --- Helper Functions ---

const ops = getLocalStorageOperations(LOCALSTORAGE_KEY_FEEDBACK, padMissingData)

function _setFeedback(feedback: Feedback) {
  return ops.setItem(feedback);
}

// --- End Helper functions ---

export const getFeedback = ops.getItem;

export function getFeedbackByCategory(category: string) {
  const feedback = ops.getItem();
  if (!feedback) {
    return {};
  }
  return feedback.feedbackReflection[category] ?? {};
}

export const setFeedBack = _setFeedback;

export function updateFeedback(category: string, feedback: {}) {
  const updates = padMissingData(ops.getItem() ?? {});
  updates.feedbackReflection[category] = {
    ...updates.feedbackReflection[category],
    ...feedback
  };
  return _setFeedback(updates);
}

export const removeFeedback = ops.removeItem;
