import React from "react";

interface Props {
  children?: React.ReactNode;
  border?: string;
  backgroundColor?: string;
  borderRadius?: string;
  height?: string;
  width?: string;
  margin?: string;
  padding?: string;
  boxShadow?: string;
  cursor?: string;
  display?: string;
  flexDirection?: string;
  alignItems?: string;
  justifyContent?: string;
  position?: string;
  onClick?: Function;
}

const Box: React.FC<Props> = ({
  children,
  border,
  backgroundColor,
  borderRadius,
  height,
  width,
  margin,
  padding,
  boxShadow,
  cursor,
  display,
  flexDirection,
  alignItems,
  justifyContent,
  position,
  onClick,
}) => {
  const boxStyle: object = {
    border,
    backgroundColor,
    borderRadius,
    height,
    width,
    margin,
    padding,
    boxShadow,
    cursor,
    display,
    flexDirection,
    alignItems,
    justifyContent,
    position,
  };

  const onClickFunction = () => {
    if (!onClick) return;
    onClick();
  };
  return (
    <div onClick={onClickFunction} style={boxStyle}>
      {children}
    </div>
  );
};

Box.defaultProps = {
  border: "2px solid var(--main-colour)",
  backgroundColor: "var(--secondary-colour-2)",
  borderRadius: "10px",
  height: "auto",
  width: "auto",
  margin: "0px",
  padding: "10px",
  display: "flex",
  flexDirection: "column",
  alignItems: "center",
  justifyContent: "center",
  position: "relative",
};

export default Box;
