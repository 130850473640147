import { DocumentData } from "firebase/firestore";
import { FeedbackLayout } from "interface/FeedbackLayoutInterface";
import QuestionViewGroup from "./QuestionViewGroup";
import FeedbackOrgForm from "components/FeedBackForm/FeedbackOrgForm";
import FeedbackFormTitle from "components/FeedBackForm/FeedbackFormTitle";
import EmptySpace from "components/utility/EmptySpace";
import { useEffect, useState } from "react";
import { FaSort, FaTrash } from "react-icons/fa";
import { Button, Modal, Tooltip } from "flowbite-react";
import { HiOutlineExclamationCircle } from "react-icons/hi";
import SimpleLoader from "components/Loaders/SimpleLoader";
import usePromise from "hooks/utility/usePromise";
import { useOrgFeedbackQuestions } from "hooks/organisation/useOrgFeedbackQuestions";
import toast from "react-hot-toast";
import FeedbackSortComponent from "./FeedbackSortComponent";
import classes from "pages/AllCircles/Admin/Admin.module.css";
import { AiFillCloseCircle } from "react-icons/ai";
import FeedbackQuestionSorting from "./FeedbackQuestionSorting";

interface FeedbackQuestionProps {
  questions: DocumentData[];
  activeQuestionId: string;
}

const FeedbackQuestions = ({
  questions,
  activeQuestionId,
}: FeedbackQuestionProps) => {
  // Type assertion/casting: questions as FeedbackLayout[]
  const feedbackLayout: FeedbackLayout[] = questions as FeedbackLayout[];
  const [activeTab, setActiveTab] = useState(1); // Initialize the active tab
  const [deleteShow, setDeleteShow] = useState(false);
  const { isLoading, resolve } = usePromise();
  const [question, setQuestion] = useState<FeedbackLayout>();
  const { DeleteFeedbackSection, saveOrder } = useOrgFeedbackQuestions();
  const [isSorting, setIsSorting] = useState(false);
  const [selectedIds, setSelectedIds] = useState<string[]>([]);

  const setDeleteShowHandler = async (question: FeedbackLayout) => {
    setQuestion(question);
    setDeleteShow(!deleteShow);
  };

  const isSortHandler = () => {
    setSelectedIds([]);
    const initialSelectedIds = questions.map((sections) => sections.id);
    setSelectedIds(initialSelectedIds);

    setIsSorting(!isSorting);
  };

  const saveHandler = async () => {
    resolve(() => saveOrder(selectedIds)).then(() => {
      isSortHandler();
      toast.success("New order saved.");
    });
  };

  const deleteSectionHandler = async () => {
    if (question)
      resolve(() => DeleteFeedbackSection(question?.id)).then(() => {
        toast.success("Section was deleted successfully");
        setDeleteShow(!deleteShow);
      });
  };

  useEffect(() => {
    // Check if activeQuestionId is provided and find the corresponding index in feedbackLayout
    if (activeQuestionId) {
      const activeIndex = feedbackLayout.findIndex(
        (item) => item.id === activeQuestionId
      );

      // Set activeTab to the index + 1 if activeQuestionId is found, or keep it as 1 if not found
      if (activeIndex !== -1) {
        setActiveTab(activeIndex + 1);
      } else {
        setActiveTab(1);
      }
    }
  }, [activeQuestionId, feedbackLayout]);

  return (
    <div className="border-l w-full md:max-w-[460px] border-l-gray-300/75 mx-4 my-2 px-4">
      <>
        <Modal
          show={deleteShow}
          size="xl"
          popup
          onClose={() => setDeleteShow(!deleteShow)}
        >
          <Modal.Header />
          <Modal.Body>
            <div className="text-center flex justify-center flex-col items-center">
              {!isLoading && (
                <HiOutlineExclamationCircle
                  size={82}
                  className="mx-auto mb-4  text-gray-400 dark:text-gray-200"
                />
              )}
              <h3 className="mb-5 text-lg font-normal text-gray-500 dark:text-gray-400">
                Are you certain about your decision to delete the{" "}
                <strong>{question?.title}</strong> section? Please be aware that
                this action could have repercussions make sure that there is no
                ongoing session before proceeding.
              </h3>
              {isLoading ? (
                <SimpleLoader />
              ) : (
                <div className="flex justify-center gap-4">
                  <Button color="failure" onClick={deleteSectionHandler}>
                    Yes, I'm sure
                  </Button>
                  <Button
                    color="gray"
                    onClick={() => setDeleteShow(!deleteShow)}
                  >
                    No, cancel
                  </Button>
                </div>
              )}
            </div>
          </Modal.Body>
        </Modal>

        <Modal show={isSorting} size="3xl" popup onClose={isSortHandler}>
          <Modal.Body
            className={`relative ${classes["container"]} flex justify-center`}
          >
            {!isLoading && (
              <AiFillCloseCircle
                size={30}
                color={`var(--icon-colour-0)`}
                className="absolute top-2 right-2 cursor-pointer"
                onClick={() => setIsSorting(!isSorting)}
              />
            )}
            <div className="text-center flex justify-center flex-col items-center mt-8">
              <FeedbackSortComponent
                order={selectedIds}
                setOrder={setSelectedIds}
                questions={questions}
              />

              <button
                disabled={isLoading}
                className={`${classes["button"]} my-5`}
                onClick={saveHandler}
              >
                {isLoading ? "Saving..." : " Save Order"}
              </button>
            </div>
          </Modal.Body>
        </Modal>

        <EmptySpace height={"25px"} />

        {feedbackLayout &&
          (feedbackLayout as FeedbackLayout[]) &&
          feedbackLayout.map((questions, index) => (
            <div
              key={index}
              className="w-full flex flex-col justify-center items-center"
            >
              {activeTab === index + 1 && (
                <>
                  <div className="relative flex justify-center items-center w-full text-center">
                    <div className="absolute left-0 top-0 flex gap-2">
                      {" "}
                      <Tooltip
                        className="capitalize min-w-[120px]"
                        content={`Delete a Section`}
                      >
                        <FaTrash
                          size={20}
                          onClick={() => setDeleteShowHandler(questions)}
                          className="text-red-700 cursor-pointer"
                        />
                      </Tooltip>
                      <Tooltip
                        className="capitalize min-w-[120px]"
                        content={`Sort tabs order`}
                      >
                        {" "}
                        <FaSort
                          size={20}
                          onClick={isSortHandler}
                          className="text-sky-700 cursor-pointer"
                        />
                      </Tooltip>
                    </div>

                    <div className="my-4">
                      <p className="mb-2 text-slate-600"> (Preview Only) </p>
                      <small>
                        Note : The style appearance may change on actual
                        feedback form
                      </small>
                    </div>
                  </div>
                  <FeedbackOrgForm
                    numTab={feedbackLayout.length}
                    activeTab={activeTab}
                    handleSwitchTabs={(n) => setActiveTab(n)}
                  >
                    {questions.subCategories && (
                      <FeedbackQuestionSorting questions={questions} />
                    )}

                    <FeedbackFormTitle>{questions?.title}</FeedbackFormTitle>
                    <EmptySpace height={"15px"} />
                    <QuestionViewGroup
                      adminEditing={true}
                      sectionId={questions.id}
                      editable={true}
                      questionCategory={questions?.title}
                      questions={questions?.subCategories}
                    />
                  </FeedbackOrgForm>
                  <EmptySpace />
                </>
              )}
            </div>
          ))}
      </>
    </div>
  );
};

export default FeedbackQuestions;
