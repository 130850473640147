import NavAdmin from "components/Admin/NavAdmin";
import classes from "./Admin.module.css";
import SidebarAdmin from "components/Admin/SidebarAdmin";

import { OrganisationContextProvider } from "context/OrganisationContext";
import { Toaster } from "react-hot-toast";
import { lazy, Suspense } from "react";

const OrganisationSettings = lazy(
  () => import("components/Organisation/OrganisationSettings")
);

/**
 * Page to show all organisations that can be managed by the current user.
 *
 * @returns AdminOrganisations component.
 */
export default function AdminOrganisations() {
  return (
    <div>
      <NavAdmin />
      <Toaster
        position="bottom-center"
        containerStyle={{
          bottom: "5%",
        }}
        toastOptions={{
          duration: 4000,
          style: {
            maxHeight: "50%",
            maxWidth: "50%",
            wordWrap: "break-word",
          },
        }}
      />
      <div className={`${classes.container} min-h-screen`}>
        <SidebarAdmin />
        <div className={classes.main}>
          <div className={classes.title}>
            <h4>Organisations</h4>
          </div>
          <OrganisationContextProvider>
            <Suspense fallback={<div>Loading...</div>}>
              {" "}
              <OrganisationSettings />
            </Suspense>
          </OrganisationContextProvider>
        </div>
      </div>
    </div>
  );
}
