import { FeedbackQuestion, FeedbackQuestions } from "./utility/feedback";

const FEEDBACK_CATEGORY_SELF = "feedbackForSelf";

const satisfactoryQuestion: FeedbackQuestion = {
  questionName: "satisfactory",
  type: "slider",
  question: {
    default:
      "On a scale of 1-10, how satisfied are you with today’s Growth Circle?",
  },
  defaultResponse: 1,
};

const satisfactoryQuestionNTU: FeedbackQuestion = {
  questionName: "satisfactory",
  type: "slider",
  question: {
    default:
      "On a scale of 1-10, how satisfied are you with today’s BuddyUP! Growth Mentoring Circle?",
  },
  defaultResponse: 1,
};

const takeawaysQuestion: FeedbackQuestion = {
  questionName: "takeaways",
  type: "textArea",
  question: {
    default:
      "What takeaways and insights do you have from today’s Growth Circle?",
  },
  defaultResponse: "",
};

const takeawaysQuestionNTU: FeedbackQuestion = {
  questionName: "takeaways",
  type: "textArea",
  question: {
    default:
      "What takeaways and insights do you have from today’s BuddyUP! Growth Mentoring Circle?",
  },
  defaultResponse: "",
};

const whatActionStepQuestion: FeedbackQuestion = {
  questionName: "whatActionStepQuestion",
  type: "textArea",
  question: {
    default: "What is one action step you will be taking?",
  },
  defaultResponse: "",
};

const feltEngagedQuestion: FeedbackQuestion = {
  questionName: "feltEngaged",
  type: "smileyRating",
  question: { default: "I felt engaged during today’s session" },
  defaultResponse: 3,
};

const feltHeardQuestion: FeedbackQuestion = {
  questionName: "feltHeard",
  type: "smileyRating",
  question: { default: "I feel heard and understood" },
  defaultResponse: 3,
};

const gainedPerspectiveQuestion: FeedbackQuestion = {
  questionName: "newPerspective",
  type: "smileyRating",
  question: { default: "I have gained new perspectives / ideas" },
  defaultResponse: 3,
};

const inspiredQuestion: FeedbackQuestion = {
  questionName: "inspired",
  type: "smileyRating",
  question: { default: "I am inspired and encouraged" },
  defaultResponse: 3,
};

const gainedUnderstandingQuestion: FeedbackQuestion = {
  questionName: "understandMyself",
  type: "smileyRating",
  question: { default: "I feel I understand myself better" },
  defaultResponse: 3,
};

const thoughtDeeperQuestion: FeedbackQuestion = {
  questionName: "thinkDeeper",
  type: "smileyRating",
  question: {
    default: "I was able to think deeper because of questions asked",
  },
  defaultResponse: 3,
};

const benefittedQuestion: FeedbackQuestion = {
  questionName: "overallBenefitted",
  type: "smileyRating",
  question: { default: "Overall, I have benefitted from the session" },
  defaultResponse: 3,
};

const learnedAndPracticedQuestion: FeedbackQuestion = {
  questionName: "learnedAndPracticed",
  type: "smileyRating",
  question: {
    default:
      "I have learned/practiced skills that is applicable to my daily life",
  },
  defaultResponse: 3,
};

const defaultSelfFeedbackQuestions: FeedbackQuestions = {
  title: "For Self",
  category: FEEDBACK_CATEGORY_SELF,
  subCategories: [
    {
      questions: [satisfactoryQuestion, takeawaysQuestion, feltEngagedQuestion],
    },
    {
      questionPrefix: {
        default: "Through this Growth Circle:",
      },
      questions: [
        feltHeardQuestion,
        gainedPerspectiveQuestion,
        inspiredQuestion,
        gainedUnderstandingQuestion,
        thoughtDeeperQuestion,
        benefittedQuestion,
        learnedAndPracticedQuestion,
      ],
    },
  ],
};

const NTUSelfFeedbackQuestions: FeedbackQuestions = {
  title: "For Self",
  category: FEEDBACK_CATEGORY_SELF,
  subCategories: [
    {
      questions: [
        satisfactoryQuestionNTU,
        takeawaysQuestionNTU,
        whatActionStepQuestion,
      ],
    },
  ],
};

const HitachiSelfFeedbackQuestions: FeedbackQuestions = {
  title: "For Self",
  category: FEEDBACK_CATEGORY_SELF,
  subCategories: [
    {
      questions: [
        satisfactoryQuestionNTU,
        takeawaysQuestionNTU,
        whatActionStepQuestion,
      ],
    },
  ],
};

// TODO move these to firestore and use modelOperations to add, get, update and delete.
export function getSelfFeedbackQuestions(
  organisationName: string
): FeedbackQuestions {
  if (organisationName === "NTU Career & Attachment Office") {
    return NTUSelfFeedbackQuestions;
  } else if (organisationName === "Hitachi") {
    return HitachiSelfFeedbackQuestions;
  } else {
    return defaultSelfFeedbackQuestions;
  }
}
