import ChatSearch from "components/Chats/ChatSearch";
import Header from "components/Header/Header";
import PageWrapper from "components/utility/PageWrapper";
import classes from "./Chats.module.css";
import { ReactComponent as ChatDirectory } from "../../assets/ChatDirectory.svg";
import { ReactComponent as ChatMessages } from "../../assets/ChatMessages.svg";
import { ReactComponent as ChatFriendRequest } from "../../assets/ChatFriendRequest.svg";
import ChatUsers from "components/Chats/ChatUsers";

const Chats = () => {
  return (
    <PageWrapper pageType={"groundRules"}>
      <Header />
      <div className={classes["chats-container"]}>
        <ChatSearch />
        <div className={classes["line-separator"]}></div>
        <ChatUsers />
      </div>
      <div className={classes["menu-container"]}>
        <div className={classes["menu-item"]}>
          <ChatDirectory />
        </div>
        <div className={classes["menu-item"]}>
          <ChatMessages />
        </div>
        <div className={classes["menu-item"]}>
          <ChatFriendRequest />
        </div>
      </div>
    </PageWrapper>
  );
};

export default Chats;
