import AutoResizeTextArea from "components/utility/Inputs/AutoResizeTextArea";

interface TextAreaProps {
  valueHandler: (value: string) => void;
  value: string;
  maxLength: number;
  row?: number;
  placeholder?: string;
  onBlur?: () => void;
  className?: string;
  required?: boolean;
  readonly?: boolean;
}
const TextAreaMax = ({
  valueHandler,
  value,
  maxLength,
  row,
  placeholder,
  onBlur,
  className,
  required,
  readonly,
}: TextAreaProps) => {
  const handleInputChange = (value: string) => {
    // Ensure the input doesn't exceed the maximum length
    if (value.length <= maxLength) {
      valueHandler(value);
    }
  };

  const onBlurHandler = () => {
    if (onBlur) onBlur();
  };

  return (
    <div
      className="border-2 my-2 bg-white rounded-lg font-poppins  "
      style={{
        color: "var(--text-colour)",
      }}
    >
      <AutoResizeTextArea
        readonly={readonly}
        style={{ resize: "none" }}
        row={row || 10}
        className={`${className} border-none w-full rounded-lg placeholder:text-gray-300`}
        onChange={(value) => handleInputChange(value)}
        value={value}
        placeholder={
          placeholder ?? `Write here max character length ${maxLength}...`
        }
        maxLength={maxLength}
        onBlur={onBlurHandler}
        isRequired={required ?? false}
      />
      <div
        className="p-2"
        style={{ textAlign: "right", fontSize: "0.9rem", color: "gray" }}
      >
        {value.length}/{maxLength}
      </div>
    </div>
  );
};

export default TextAreaMax;
