import { Activity } from "interface/ActivityInterface";

export const ACTIVITY_INSTRUCTIONS: Activity[] = [
  {
    id: "1",
    title: "It’s A Real Mood",
    subInstruction: "Upload both the selected art work and your creation",
    instructions: [
      "Pick an art work that best describes your current mood",
      "What title would you give it?",
      "Using your mobile phone, create your own art work based on your suggested title. For iPhone users, use notepad. For android users, use (any drawing app). Take a screenshot of your creation and upload it!",
    ],
    image: "Its_a_real_mood.webp",
    inputTitle: "What is your mood? What is your suggested title?",
    placeholder: "My mood is... My title is...",
    imageRequiredIs: 2,
  },
  {
    id: "2",
    title: "Artwork Mixtape",
    subInstruction: "Upload a picture of the selected sculpture",
    instructions: [
      "As a group, pick a sculpture in assigned gallery that all of you like and find interesting or cool.",
      `Observe the sculpture in complete silence for 3 minutes.
        <div class="px-2 mt-2">
         <p>i. What do you see?</p>
         <p>ii. What impression is the sculpture giving you? </p>
         <p>iii. How does it make you feel? </p>
         <p>iv. What song/sound/rhythm/genre might go best with it? </p>
        </div>`,
    ],
    image: "artwork_mixtape.webp",
    inputTitle:
      "What words/song title came up for you as you looked at the sculpture?",
    placeholder: "The words are...",
    imageRequiredIs: 1,
  },
  {
    id: "3",
    title: "What’s Going On",
    subInstruction: "Upload a picture of the selected art work",
    instructions: [
      "Pick an artwork that is most confusing, disturbing or that you do not understand.",
      "Observe the art work in complete silence.",
      "What is one thing you would change about the artwork?",
    ],
    image: "whats_going_on.webp",
    inputTitle: "The one thing I would change about this artwork is...",
    placeholder: "One thing I would change...",
    imageRequiredIs: 1,
  },
  {
    id: "4",
    title: "On Hope",
    subInstruction: "Upload a picture of the selected art work",
    instructions: [
      "Pick an art work that symbolizes hope or least hopeful for you.",
      "What is it about the art work that makes you feel hopeful or what would you like to see instead? Put a word or phrase to it.",
    ],
    image: "on_hope.webp",
    inputTitle: "What makes me feel hopeful is...The word/phrase is...",
    placeholder: "One word/ phrase is...",
    imageRequiredIs: 1,
  },
];
