import React from "react";

const SadFace = ({ strokeColor }) => {
  return (
    <svg
      width="23"
      height="23"
      viewBox="0 0 23 23"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M11.4167 21.8333C17.1696 21.8333 21.8333 17.1696 21.8333 11.4167C21.8333 5.6637 17.1696 1 11.4167 1C5.6637 1 1 5.6637 1 11.4167C1 17.1696 5.6637 21.8333 11.4167 21.8333Z"
        stroke={strokeColor ? strokeColor : "#FDF5E6"}
        strokeWidth="2"
        strokeLinecap="round"
      />
      <path
        d="M7.25 8.34342V8.23926M15.5833 8.34342V8.23926"
        stroke={strokeColor ? strokeColor : "#FDF5E6"}
        strokeWidth="2"
        strokeLinecap="round"
      />
      <path
        d="M15.5833 15.583C15.0625 14.0205 13.7188 12.458 11.4167 12.458C9.11458 12.458 7.77083 14.0205 7.25 15.583"
        stroke={strokeColor ? strokeColor : "#FDF5E6"}
        strokeWidth="2"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
    </svg>
  );
};

export default SadFace;
