import Organisation from "interface/OrganisationInterface";
import { getProperNameComponent } from "pages/AllCircles/GenericSessionPage/ComponentMapping";
import { useState } from "react";
import ComponentDetails from "../ComponentSettings/ComponentDetails";
import classes from "pages/AllCircles/Admin/Admin.module.css";
const TemplateOverview = ({
  data,
  onSelect,
  showDefault,
}: {
  data: Organisation;
  showDefault?: boolean;
  onSelect?: () => void;
}) => {
  const [info, setInfo] = useState("");
  const handleInfo = (component: string) => {
    setInfo(component);
  };

  const onSelectHandler = () => {
    if (onSelect) onSelect();
  };

  return (
    <div className={`${classes["container"]} flex-col justify-center`}>
      <div
        className="flex-col md:flex-row flex gap-5"
        style={{ color: "var(--text-colour)" }}
      >
        <div className=" p-5 w-full md:w-[400px] min-h-[80vh]">
          <div className="flex w-full gap-5">
            {!showDefault && (
              <div className="w-full">
                {data &&
                  data.components.map((component, index) => (
                    <div
                      key={index}
                      onClick={() => {
                        handleInfo(component);
                      }}
                      style={{ borderColor: "var(--main-colour)" }}
                      className={`border-2 my-4 grid grid-cols-4 relative items-center cursor-pointer w-full ${
                        component === info
                          ? "bg-main-colour text-default"
                          : "bg-white"
                      } p-4 rounded-xl shadow-sm`}
                    >
                      <h1 className="justify-self-start col-span-3 text-lg ">{`${
                        index + 1
                      }. ${getProperNameComponent(component)}`}</h1>
                    </div>
                  ))}
              </div>
            )}
            {showDefault && (
              <div className="w-full">
                {data &&
                  data.defaultComponents &&
                  data.defaultComponents.map((component, index) => (
                    <div
                      key={index}
                      onClick={() => {
                        handleInfo(component);
                      }}
                      style={{ borderColor: "var(--main-colour)" }}
                      className={`border-2 my-4 grid grid-cols-4 relative items-center cursor-pointer w-full ${
                        component === info
                          ? "bg-main-colour text-default"
                          : "bg-white"
                      } p-4 rounded-xl shadow-sm`}
                    >
                      <h1 className="justify-self-start col-span-3 text-lg ">{`${
                        index + 1
                      }. ${getProperNameComponent(component)}`}</h1>
                    </div>
                  ))}
              </div>
            )}
          </div>
        </div>
        <div className="flex-1">
          {info !== "" && <ComponentDetails info={info} />}
          {info === "" && (
            <div className="text-2xl text-center h-[80vh] flex justify-center items-center">
              <p>
                Select the component located on the left side to preview the
                display.
              </p>
            </div>
          )}
        </div>
      </div>
      {!showDefault && (
        <div className="mx-auto">
          <button onClick={onSelectHandler} className={`${classes["button"]}`}>
            Use Template
          </button>
        </div>
      )}
    </div>
  );
};

export default TemplateOverview;
