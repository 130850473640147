import { SelfAwarenessToolInterface } from "interface/SelfAwarenessToolInterface";
import PathCard from "./PathCard";

const DecisionSelector = ({
  journal,
  handleCardSelect,
  selectedItem,
}: {
  journal: SelfAwarenessToolInterface;
  handleCardSelect: (title: string) => void;
  selectedItem: string | null;
}) => {
  return (
    <div className="flex font-semibold gap-4 justify-center items-center my-8 font-poppins">
      <PathCard
        handleCardSelect={handleCardSelect}
        pageType={journal.pageType}
        content="<p>The</p> <p>Decision</p> <p>Criteria</p>"
        selectedItem={selectedItem}
      />
      <PathCard
        handleCardSelect={handleCardSelect}
        pageType={journal.pageType}
        content={`<p>Value - Risk -</p> <p>Resources -</p> <p>Need (VRRN)</p>`}
        selectedItem={selectedItem}
      />
    </div>
  );
};

export default DecisionSelector;
