import ReactDOM from "react-dom/client";
import App from "./App";
import { AuthContextProvider } from "./context/AuthContext";
import { GrowthCircleContextProvider } from "./context/GrowthCircleContext";
import { BrowserRouter } from "react-router-dom";
import "./index.css";
import { ThemeContextProvider } from "context/ThemeContext";

const container = document.getElementById("root");
const root = ReactDOM.createRoot(container as HTMLElement);

root.render(
  <BrowserRouter>
    <AuthContextProvider>
      <GrowthCircleContextProvider>
        <ThemeContextProvider>
          <App />
        </ThemeContextProvider>
      </GrowthCircleContextProvider>
    </AuthContextProvider>
  </BrowserRouter>
);
