import classes from "./ShareMessages.module.css";
import ShareMessage from "./ShareMessage";
import ShareContext from "./ShareContext";
import { useContext, useEffect, useRef, useState } from "react";
import { useAuthContext } from "../../../hooks/useAuthContext";
import useMessageScroll from "hooks/useMessageScroll";
import EmptySpace from "components/utility/EmptySpace";

import useGetParticipants from "hooks/useGetParticipants";
import { ShareMessage as ShareMessagesArray } from "interface/MessageInterface";
import { Profile } from "interface/ProfileInterface";
import { PartialParticipant } from "interface/ParticipantInterface";
import { getUsersByIds } from "models/profile";

function sortByTimestamp(msg1: ShareMessagesArray, msg2: ShareMessagesArray) {
  const t1 = msg1.createdAt.valueOf();
  const t2 = msg2.createdAt.valueOf();
  if (t1 < t2) {
    return -1;
  }
  if (t1 > t2) {
    return 1;
  }
  return 0;
}

function getParticipant(
  users: Profile[],
  message: ShareMessagesArray
): PartialParticipant {
  const user = users.find((u) => u.id === message.userId);
  if (!user) {
    return message;
  }
  return {
    ...user,
    userId: user.id,
    userName: user.displayName,
  };
}

interface ShareMessagesProps {
  viewOnly?: boolean;
  messages: ShareMessagesArray[];
}

//TODO::share chats
const ShareMessagesSingleView = ({
  viewOnly,
  messages,
}: ShareMessagesProps) => {
  const { profile } = useAuthContext();
  const bottomOfChat = useRef<HTMLDivElement>(null);
  const messageBoxRef = useRef<HTMLDivElement>(null);
  const { messageSent, setMessageSent } = useContext(ShareContext);
  const [participants, setParticipants] = useState<Profile[]>([]);

  useEffect(() => {
    getUsersByIds(messages.map((m) => m.userId)).then((profiles) => {
      setParticipants(profiles);
    });
  }, [messages]);

  const { scrollHandler } = useMessageScroll(
    bottomOfChat,
    messageBoxRef,
    viewOnly ? messages : messages,
    messageSent,
    setMessageSent
  );
  const { getParticipantColourIndex } = useGetParticipants();

  if (!profile) return null;

  return (
    <>
      <div
        className={`${classes["chat-container"]} ${classes["custom-scrollbar"]}`}
        ref={messageBoxRef}
        onScroll={scrollHandler}
      >
        <EmptySpace />
        <ul className={classes["messages"]}>
          {(messages &&
            messages.sort(sortByTimestamp).map((message, index: number) => {
              const isSelf = message.userId === profile.uid;
              return (
                <div key={index}>
                  {
                    <ShareMessage
                      name={message.userName}
                      text={message.entry}
                      createdAt={message.createdAt}
                      isSelf={isSelf}
                      participant={getParticipant(participants, message)}
                      participantNumber={getParticipantColourIndex(
                        message.userId
                      )}
                      coreSkill={message.coreSkill}
                      role={isSelf ? message.role : undefined}
                    />
                  }
                  <EmptySpace height={"10px"} />
                </div>
              );
            })) || <div>No messages</div>}
        </ul>

        <div ref={bottomOfChat}></div>
      </div>
    </>
  );
};

export default ShareMessagesSingleView;
