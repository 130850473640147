import { ChangeEvent, useEffect, useState } from "react";

type Props = {
  id: string;
  label: string;
  values: string;
  isDisabled?: boolean;
  setValue: (questionName: string, value: string) => void;
  questionName: string;
};

const YesOrNo = ({
  id,
  label,
  values,
  isDisabled,
  setValue,
  questionName,
}: Props) => {
  const [selectedValue, setSelectedValue] = useState(values ?? "Yes");
  const handleRadioChange = (event: ChangeEvent<HTMLInputElement>) => {
    setSelectedValue(event.target.value);
  };

  useEffect(() => {
    setValue(questionName, selectedValue);
    // eslint-disable-next-line
  }, [selectedValue]);

  useEffect(() => {
    setSelectedValue(values ?? "Yes");
    // eslint-disable-next-line
  }, []);

  return (
    <div className="flex flex-col justify-center items-start">
      <p className="font-poppins text-standard font-[700] p-4 select-none text-left">
        {label}
      </p>
      <div className="flex justify-start items-center gap-4 py-4 px-4">
        <input
          disabled={isDisabled}
          type="radio"
          name={`radioInput-${questionName}-${id}`}
          id={`yes-${id}`}
          value="Yes"
          checked={selectedValue === "Yes"}
          onChange={handleRadioChange}
          style={{
            height: "20px",
            width: "20px",
            marginTop: "2px",
            backgroundColor: selectedValue ? "var(--main-colour)" : "", // Adjust styling based on checked state
          }}
          className={`cursor-pointer appearance-none  ring-transparent bg-transparent h-4 w-4 checked:background-none shadow-md`}
        />
        <label htmlFor={`yes-${id}`} className="cursor-pointer">
          Yes
        </label>
        <input
          disabled={isDisabled}
          type="radio"
          name={`radioInput-${questionName}-${id}`}
          id={`no-${id}`}
          value="No"
          checked={selectedValue === "No"}
          onChange={handleRadioChange}
          style={{
            height: "20px",
            width: "20px",
            marginTop: "2px",
            backgroundColor: selectedValue ? "var(--main-colour)" : "", // Adjust styling based on checked state
          }}
          className={`cursor-pointer appearance-none  ring-transparent bg-transparent h-4 w-4 checked:background-none shadow-md`}
        />

        <label htmlFor={`no-${id}`} className="cursor-pointer">
          No
        </label>
      </div>
    </div>
  );
};

export default YesOrNo;
