import { HelperProps } from "pages/AllCircles/GenericSessionPage/ComponentMapping";
import { useState, lazy, Suspense } from "react";
const SharingTabsGuide1 = lazy(() => import("./SharingTabGuide1"));
const SharingTabsGuide2 = lazy(() => import("./SharingTabGuide2"));
const SharingTabsGuide3 = lazy(() => import("./SharingTabGuide3"));
const SharingTabsGuide4 = lazy(() => import("./SharingTabGuide4"));
const SharingTabsGuide5 = lazy(() => import("./SharingTabGuide5"));
const SharingTabsGuide6 = lazy(() => import("./SharingTabGuide6"));
const SharingTabsGuide7 = lazy(() => import("./SharingTabGuide7"));
const SharingTabsGuide8 = lazy(() => import("./SharingTabGuide8"));
const SharingTabsGuide9 = lazy(() => import("./SharingTabGuide9"));
const SharingTabsGuide10 = lazy(() => import("./SharingTabGuide10"));
const SharingTabsGuide11 = lazy(() => import("./SharingTabGuide11"));

const SharingPanelHelper = ({ setHasModal }: HelperProps) => {
  const [step, setStep] = useState(1);

  const backHandler = () => {
    if (step !== 1) {
      return setStep(step - 1);
    } else if (step === 1) {
      setHasModal(false);
    } else {
      return setStep(1);
    }
  };

  const nextHandler = () => {
    if (step < 11) {
      return setStep(step + 1);
    } else {
      localStorage.setItem("SharingRead", "true");
      setStep(1);
      return setHasModal(false);
    }
  };

  return (
    <div className="flex flex-col justify-center items-center">
      <div className="flex flex-col justify-center items-center py-4">
        <h2 className="font-semibold text-2xl text-slate-800">Sharing</h2>
        <p className="text-sm mb-9">This is where the sharing begins.</p>

        {step === 1 && (
          <Suspense fallback={<div>Loading...</div>}>
            <SharingTabsGuide1 />
          </Suspense>
        )}
        {step === 2 && (
          <Suspense fallback={<div>Loading...</div>}>
            <SharingTabsGuide2 />
          </Suspense>
        )}
        {step === 3 && (
          <Suspense fallback={<div>Loading...</div>}>
            <SharingTabsGuide3 />
          </Suspense>
        )}
        {step === 4 && (
          <Suspense fallback={<div>Loading...</div>}>
            <SharingTabsGuide4 />
          </Suspense>
        )}
        {step === 5 && (
          <Suspense fallback={<div>Loading...</div>}>
            <SharingTabsGuide5 />
          </Suspense>
        )}
        {step === 6 && (
          <Suspense fallback={<div>Loading...</div>}>
            <SharingTabsGuide6 />
          </Suspense>
        )}
        {step === 7 && (
          <Suspense fallback={<div>Loading...</div>}>
            <SharingTabsGuide7 />
          </Suspense>
        )}
        {step === 8 && (
          <Suspense fallback={<div>Loading...</div>}>
            <SharingTabsGuide8 />
          </Suspense>
        )}
        {step === 9 && (
          <Suspense fallback={<div>Loading...</div>}>
            <SharingTabsGuide9 />
          </Suspense>
        )}
        {step === 10 && (
          <Suspense fallback={<div>Loading...</div>}>
            <SharingTabsGuide10 />
          </Suspense>
        )}
        {step === 11 && (
          <Suspense fallback={<div>Loading...</div>}>
            <SharingTabsGuide11 />
          </Suspense>
        )}
      </div>

      <div className="flex justify-between items-center gap-14">
        <button
          type="button"
          onClick={backHandler}
          className="bg-white border border-slate-400/[0.2] shadow-lg font-semibold px-8 py-2 rounded-full"
          style={{ color: "var(--main-colour)" }}
        >
          Back
        </button>
        <button
          type="button"
          onClick={nextHandler}
          className="bg-white border border-slate-400/[0.2] shadow-lg font-semibold px-8 py-2 rounded-full"
          style={{ color: "var(--main-colour)" }}
        >
          Next
        </button>
      </div>
    </div>
  );
};

export default SharingPanelHelper;
