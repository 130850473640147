import React, { useEffect, useState } from "react";
import useMount from "./useMount";
import { Message } from "interface/MessageInterface";

// bottomOfChatRef: ref to empty div at bottom of chat
// messageBoxRef: ref to box containing list of messages
// messages: document for messages
// messageSent: state for whether a message is currently being sent
// setMessageSent: state setter for above
const useMessageScroll = (
  bottomOfChatRef: React.RefObject<HTMLDivElement>,
  messageBoxRef: React.RefObject<HTMLDivElement>,
  messages: Message[],
  messageSent: boolean,
  setMessageSent: React.Dispatch<React.SetStateAction<boolean>>
) => {
  const isMount = useMount();
  const [isChatBottom, setIsChatBottom] = useState(true);

  // whenever a new message arrives,
  // if client is at the bottom of the chat, push chat down showing new message
  // if client sends message, push down chat
  useEffect(() => {
    if (isChatBottom && !isMount && bottomOfChatRef.current) {
      bottomOfChatRef.current.scrollIntoView({
        behavior: "smooth",
        block: "nearest",
      });
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [messages]);

  useEffect(() => {
    if (messages && messageBoxRef.current) {
      messageBoxRef.current.scrollTop = messageBoxRef.current.scrollHeight;
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const scrollHandler = () => {
    if (!messageBoxRef.current) return;
    const { scrollTop, scrollHeight, clientHeight } = messageBoxRef.current;
    if (Math.abs(scrollTop + clientHeight - scrollHeight) <= 1) {
      setIsChatBottom(true);
    } else {
      setIsChatBottom(false);
    }
  };

  // scrolls to bottom automatically if user sends a message
  useEffect(() => {
    if (messageSent && bottomOfChatRef.current) {
      bottomOfChatRef.current.scrollIntoView({
        behavior: "smooth",
        block: "nearest",
      });
      setMessageSent(false);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [messageSent]);

  return { scrollHandler };
};

export default useMessageScroll;
