import { ReactComponent as NoGCSessionPage } from "../../assets/NoGCSessionPage/NoGCSessionPage.svg";
import Header from "components/Header/Header";
import classes from "./NoGCSession.module.css";
import { useEffect } from "react";
import { useFirestore } from "hooks/useFirestore";
import { useAuthContext } from "hooks/useAuthContext";

const NoGCSession = () => {
  const { profile } = useAuthContext();
  const { updateDocument } = useFirestore("users");
  useEffect(() => {
    const data = { pageStep: "" };
    updateDocument(profile?.uid, data);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [profile]);

  return (
    <div className={classes["page-container"]}>
      <Header />
      <div className={classes["content-container"]}>
        <NoGCSessionPage />
      </div>
    </div>
  );
};

export default NoGCSession;
