import NavAdmin from "components/Admin/NavAdmin";
import SidebarAdmin from "components/Admin/SidebarAdmin";
import classes from "./Admin.module.css";
import { OrganisationContextProvider } from "context/OrganisationContext";
import { Link } from "react-router-dom";
import { useCollection } from "hooks/useCollection";
import { useEffect } from "react";

import { VideoInterface } from "interface/VideoInterface";
import VideoCard from "components/Admin/AdminVideo/VideoCard";

const AdminVideos = () => {
  const { documents } = useCollection("Videos", null, ["createdAt", "desc"]);

  useEffect(() => {
    if (!documents) return;
  }, [documents]);

  return (
    <div>
      <NavAdmin />
      <div className={`${classes.container} min-h-screen`}>
        <SidebarAdmin />
        <div className={`${classes.main} mb-20`}>
          <div className={classes.title}>
            <h4>Videos</h4>
          </div>
          <OrganisationContextProvider>
            <div className="flex mt-[2%]">
              <div className="mb-4">
                <Link
                  to="/all-circles/admin/add-new-video"
                  className={`${classes["button"]}`}
                >
                  Add New Video
                </Link>
              </div>
            </div>{" "}
            <div className="grid grid-cols-3 gap-4 py-4">
              {documents &&
                documents.map((video: VideoInterface, index) => (
                  <VideoCard video={video} key={index} />
                ))}
            </div>
          </OrganisationContextProvider>
        </div>
      </div>
    </div>
  );
};

export default AdminVideos;
