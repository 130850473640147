import React, { useEffect, useState, lazy, Suspense } from "react";
import { useNavigate } from "react-router-dom";
import { useAuthContext } from "hooks/useAuthContext";
import { useGCSession } from "hooks/useGCSession";
import useGetParticipants from "hooks/useGetParticipants";

import Header from "components/Header/Header";
import Steps from "components/utility/Steps";

import FiveStepHeader from "components/FiveStepHeader/FiveStepHeader";
import EmptySpace from "components/utility/EmptySpace";
import BackAndNext from "components/utility/BackAndNext";
import ParticipantDiceSelection from "components/Dice/ParticipantDiceSelection";
import { useGrowthCircleContext } from "hooks/useGrowthCircleContext";
import NoGCSessionWrapper from "pages/NoGCSession/NoGCSessionWrapper";
import Center from "components/utility/Center/Center";
import ContentWrapper from "components/utility/ContentWrapper";
import { ErrorBoundary } from "react-error-boundary";
import FallbackComponent from "components/utility/ErrorBoundary/FallbackComponent";
import { INTRO_RATING_ROUTE } from "strings/Routes";
import TriggerHelper from "components/TriggerHelper/TriggerHelper";

import { Modal } from "flowbite-react";
import { MdCancel } from "react-icons/md";
import NGFooter from "components/Footers/NGFooter";
import useParticipants from "hooks/useParticipants";
import { checkCanAdvanceNextActivity } from "models/organisationRole";
import { resetEmailSentStatus, updateWindowLocationPath } from "models/profile";
import Instruction from "components/utility/Instruction";

const INSTRUCTIONS_DICE =
  "Pick a picture that connects with you, and share your choice with your group";

const DiceSelection = lazy(() => import("components/Dice/DiceSelection"));

const Dice = () => {
  const navigate = useNavigate();
  const { profile } = useAuthContext();
  const { growthCircleSession } = useGrowthCircleContext();
  const currentStep = 1;
  const stepsCount = 5;
  const [isVisible, setVisible] = useState(false);
  const { sessionRole } = useParticipants();
  const { participants } = useGetParticipants();
  const { facilAllowNextPage } = useGCSession();
  const [hasTrigger, setHasTrigger] = useState(false);

  const canAdvance = checkCanAdvanceNextActivity(sessionRole);

  useEffect(() => {
    if (!profile?.uid) {
      return;
    }
    updateWindowLocationPath(profile.uid);
    resetEmailSentStatus(profile.uid);
  }, [profile?.uid]);

  const _setVisible = () => {
    setVisible(true);
  };

  const _setTrigger = () => {
    setHasTrigger(!hasTrigger);
  };

  const handleNext = () => {
    if (canAdvance) {
      facilAllowNextPage("intro-rating");
    }
    navigate(INTRO_RATING_ROUTE);
  };

  const modalHandler = () => {
    setVisible(!isVisible);
  };

  return (
    <NoGCSessionWrapper>
      <div className="relative h-screen">
        {growthCircleSession && (
          <>
            <div id="terms-modal" />
            <Header hasModal={isVisible || hasTrigger} />
            <FiveStepHeader
              hasLightning={true}
              hasGuide={false}
              hasHelper={true}
              currentStep={currentStep}
              setIsHelperVisible={_setVisible}
              setIsTriggerVisible={_setTrigger}
              hasModal={isVisible || hasTrigger}
            />

            {/* {isVisible && <TriggerHelper trigger={setVisible} />} */}

            <ContentWrapper hasFiveStepper={true}>
              <React.Fragment>
                <Modal
                  show={isVisible}
                  size="md"
                  className="min-h-screen"
                  popup={true}
                >
                  <Modal.Body className="relative mt-[15%] md:mt-[5%] bg-default rounded-3xl overflow-x-auto">
                    <div className="absolute right-0 top-0 p-2">
                      <MdCancel
                        size={30}
                        className="cursor-pointer"
                        onClick={modalHandler}
                        style={{
                          color: "var(--main-colour )",
                        }}
                      />
                    </div>
                    <div className="text-center w-full py-5">
                      <TriggerHelper setHasModal={setVisible} />
                    </div>
                  </Modal.Body>
                </Modal>
              </React.Fragment>
              <ErrorBoundary FallbackComponent={FallbackComponent}>
                <Center className="min-h-screen text-center text-lg">
                  <EmptySpace />
                  <Steps count={stepsCount} currentStep={currentStep} />
                  <Instruction text={INSTRUCTIONS_DICE} />
                  <Suspense fallback={<div>Loading...</div>}>
                    <DiceSelection growthCircleSession={growthCircleSession} />
                  </Suspense>
                  <EmptySpace height={"40px"} />

                  <ParticipantDiceSelection
                    participants={participants}
                    dices={growthCircleSession!.dices}
                  />
                  <EmptySpace height={"30px"} />
                  <BackAndNext
                    nextAllowed={
                      canAdvance || !!growthCircleSession?.allowIntroRating
                    }
                    nextCallback={handleNext}
                    backAllowed={false}
                    backHidden={undefined}
                  />
                  <EmptySpace height={"80px"} />
                  <NGFooter type={growthCircleSession?.type} />
                </Center>
              </ErrorBoundary>
            </ContentWrapper>
          </>
        )}
      </div>
    </NoGCSessionWrapper>
  );
};

export default Dice;
