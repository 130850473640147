/**
 * Check if date is between start and end dates
 *
 * @param startDate Start date
 * @param endDate End date
 * @param date Date to check
 * @returns True if date is between start and end dates, false otherwise
 */
export const isBetweenStartAndEndDates = (
  startDate: Date,
  endDate: Date,
  date: Date
) => {
  return date >= startDate && date <= endDate;
};

/**
 * Sets the time of a date to 00:00:00. This is timezone sensitive.
 *
 * @param date Date to set time to 00:00:00
 * @returns Date with time set to 00:00:00
 */
export const setToStartOfDay = (date: Date) => {
  return new Date(
    Date.UTC(date.getFullYear(), date.getMonth(), date.getDate())
  );
};

/**
 * Gets the current month's start and end dates
 *
 * @returns Object containing the start and end dates of the current month
 */
export const getCurrentMonthDateRange = () => {
  const today = new Date();
  const monthStartDate = new Date(today.getFullYear(), today.getMonth(), 1);
  // Subtract 82800000 milliseconds (or any large number. in this case it is 23 hours)to get the day before the first date of the next month (Months have different last dates)
  const monthEndDate = new Date((new Date(Date.UTC(new Date().getFullYear(), new Date().getMonth() + 1, 1))).getTime() - 82800000);

  return { monthStartDate, monthEndDate };
};
