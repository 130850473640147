import { FormEvent, useCallback, useEffect, useState } from "react";
import useOrganisationContext from "hooks/organisation/useOrganisationContext";
import classes from "../../../pages/AllCircles/Admin/Admin.module.css";

import usePromise from "hooks/utility/usePromise";

import { toast } from "react-hot-toast";
import useGroupSelectHandler from "hooks/organisation/useGroupSelectHandler";
import useOrganisationGroupContext from "hooks/organisation/useOrganisationGroupContext";

import AutoResizeTextArea from "components/utility/Inputs/AutoResizeTextArea";
import { FaCheckCircle, FaPlusCircle, FaTimesCircle } from "react-icons/fa";
import Select from "react-select";
import makeAnimated from "react-select/animated";
import { useGrowthCirclesType } from "hooks/useGrowthCirlesType";
import DeleteGcComponent from "./DeleteGcComponent";

import GrowthCircleHeaderText from "components/Admin/GeneralSettingsComponent/GrowthCircleHeaderText";
import GrowthCircleTagLine from "components/Admin/GeneralSettingsComponent/GrowthCircleTagLine";
import GrowthCircleInviteLink from "components/Admin/GeneralSettingsComponent/GrowthCircleInviteLink";
import GrowthCircleUploadLogo from "components/Admin/GeneralSettingsComponent/GrowthCircleUploadLogo";

interface DataStructure {
  name: string;
}

/**
 * Component to update an organisation's general settings.
 *
 * @returns GeneralSettings component.
 */
export default function GrowthCircleTypeGeneralSettings() {
  const { selectedOrganisation, updateSelectedOrganisation } =
    useOrganisationContext();
  const { isLoading, resolve } = usePromise();
  const [formOrganisationName, setOrganisationName] = useState("");
  const [OrganisationTagLine, setOrganisationTagLine] = useState(
    selectedOrganisation?.tagLine ?? ""
  );
  const [OrganisationHeaderText, setOrganitionHeaderText] = useState(
    selectedOrganisation?.customHeaderText ?? ""
  );
  const { groups, selectedGroup, selectGroupByGroupName } =
    useOrganisationGroupContext();
  const { groupNames, handleGroupChange } = useGroupSelectHandler(
    groups,
    selectGroupByGroupName
  );

  const [imageURLsData, setImageURLsData] = useState<string[]>([]);

  const [updating, setIsUpdating] = useState(false);
  const [remarks, setRemarks] = useState(selectedOrganisation?.remarks);
  const [shortDescription, setShortDescription] = useState(
    selectedOrganisation?.shortDescription
  );

  const [errorTitle, setErrorTitle] = useState(false);

  const { getDataByName, growthcircletypes } = useGrowthCirclesType();

  const [whoForThis, setWhoForThis] = useState<string[]>(
    selectedOrganisation?.whoForThis || []
  );
  const [tags, setTags] = useState<string>(selectedOrganisation?.tags || "");
  const [subTags, setSubTags] = useState(selectedOrganisation?.subTags || []);
  const [targetAudience, setTargetAudience] = useState<string[]>(
    selectedOrganisation?.targetAudience || []
  );

  const [subTagSelection, setSubTagSelection] = useState<{ name: string }[]>(
    []
  );

  const [checkboxState, setCheckboxState] = useState({
    showTagLineHeader: false,
    showTagLineLogo: false,
  });

  const [objectives, setObjective] = useState<string[]>(
    selectedOrganisation?.objectives || []
  );
  const [tempObject, setTempObject] = useState("");

  const whoIsData = [
    { name: "Individuals" },
    { name: "HR" },
    { name: "Leaders" },
    { name: "Wellness Ambassadors" },
    { name: "Mentors" },
    { name: "Mentees" },
    { name: "Student" },
    { name: "Teacher" },
    { name: "Employee" },
  ];

  const tagData = [
    { name: "Wellbeing" },
    { name: "Career & Purpose" },
    { name: "People & Teams" },
    { name: "Personal Development" },
  ];

  const TargetAudienceData = [
    { name: "Schools" },
    { name: "Corporate" },
    { name: "Community Centre" },
  ];

  const handleSubmit = (e: FormEvent<HTMLFormElement>) => {
    e.preventDefault();
    const _tagData = getDataByName(tags);
    const _subTagData = getDataByName(subTags);
    const _forThis = getDataByName(whoForThis);
    const _targetAudience = getDataByName(targetAudience);

    if (errorTitle) {
      toast.error("Please specify a unique growthcircle name.");
      return false;
    }

    if (objectives.length > 0) {
      const data = {
        name: formOrganisationName,
        remarks,
        shortDescription,
        objectives,
        whoForThis: _forThis,
        tags: _tagData,
        subTags: _subTagData,
        targetAudience: _targetAudience,
      };

      resolve(() => updateSelectedOrganisation(data)).then(() => {
        toast.success("Data was updated successfully");
      });
    } else {
      toast.error("Please add objectives");
    }
  };

  const handleSubmitTagLine = useCallback(() => {
    resolve(() =>
      updateSelectedOrganisation({ tagLine: OrganisationTagLine })
    ).then(() => {
      toast.success("Organisation tagline updated");
    });
  }, [updateSelectedOrganisation, resolve, OrganisationTagLine]);

  const handleSubmitHeaderText = useCallback(() => {
    resolve(() =>
      updateSelectedOrganisation({ customHeaderText: OrganisationHeaderText })
    ).then(() => {
      toast.success("Organisation header updated");
    });
  }, [updateSelectedOrganisation, resolve, OrganisationHeaderText]);

  useEffect(() => {
    if (!selectedOrganisation) return;
    setOrganisationTagLine("");
    setOrganisationName(selectedOrganisation?.name);
    if (selectedOrganisation.tagLine && selectedOrganisation.tagLine !== "")
      setOrganisationTagLine(selectedOrganisation?.tagLine);
    setOrganitionHeaderText(selectedOrganisation.customHeaderText ?? "");
    // console.log(activeIndex, imageURLsData, imageURLs);
    // eslint-disable-next-line
  }, [selectedOrganisation, imageURLsData]);

  const handleCheckboxChange = async (checkboxName: string) => {
    setIsUpdating(true);
    const updatedCheckboxState = {
      ...checkboxState,
      [checkboxName]: !checkboxState[checkboxName],
    };

    setCheckboxState(updatedCheckboxState);

    updateSelectedOrganisation({ ...updatedCheckboxState }).then(() => {
      setIsUpdating(false);
      toast.success("Settings was updated successfully.");
    });
  };

  const addObjective = () => {
    if (tempObject !== "") {
      setObjective([...objectives, tempObject]);
      setTempObject("");
    } else {
      toast.error("Please add an objective first");
    }
  };

  const removeObjective = (indexToRemove) => {
    const updatedObjectives = objectives.filter(
      (_, index) => index !== indexToRemove
    );

    setObjective(updatedObjectives);
  };

  const animatedComponents = makeAnimated();

  const getForThis = (whoOptions: DataStructure[]) => {
    return whoOptions.map((data) => ({ value: data.name, label: data.name }));
  };

  const getFormattedData = (datas: string[]) => {
    return datas.map((data) => ({ value: data, label: data }));
  };

  const getFormattedString = (data: string) => {
    return { value: data, label: data };
  };

  const formattedSelectedRoles = getFormattedData(whoForThis);
  const formattedSelectedTags = getFormattedString(tags);
  const formattedTargetAudience = getFormattedData(targetAudience);

  const handleChange = (selectedOptions) => {
    setWhoForThis(selectedOptions);
  };

  const handleTagChange = (selectedOptions) => {
    const updatedTags = tagData.filter((tag) => {
      // Check if the tag does not match the selected option
      return (
        tag.name !== selectedOptions.value && tag.name !== selectedOptions.label
      );
    });
    setSubTagSelection(updatedTags);
    setTags(selectedOptions);
  };

  const handleSubTagChange = (selectedOptions) => {
    setSubTags(selectedOptions);
  };

  const handleTargetAudience = (selectedOptions) => {
    setTargetAudience(selectedOptions);
  };

  const setTitleHandler = (title: string) => {
    // Convert the title to lowercase for case-insensitive comparison
    const lowercaseTitle = title.toLowerCase();

    // Find the object in growthcircletypes based on the lowercase title
    const foundObject = growthcircletypes.find(
      (obj) => obj.name.toLowerCase() === lowercaseTitle
    );

    // Convert existing title to lowercase for case-insensitive comparison
    const existingTitle =
      selectedOrganisation && selectedOrganisation.name.toLowerCase();

    // Check if the found object exists and is equal to the existing title
    const isSameTitle = foundObject
      ? foundObject.name.toLowerCase() === existingTitle
      : false;

    if (foundObject && isSameTitle) {
      setErrorTitle(false);
      setOrganisationName(title);
    }

    if (foundObject && !isSameTitle) {
      setErrorTitle(true);
      setOrganisationName(title);
    }

    if (!foundObject) {
      setErrorTitle(false);
      setOrganisationName(title);
    }
  };

  return (
    <>
      <div className="mb-[100px]" style={{ color: "var(--text-colour)" }}>
        <form
          onSubmit={handleSubmit}
          className="flex flex-col items-start gap-4 my-4 border-2 p-5"
        >
          <div className="w-full md:w-1/2">
            <p className="font-semibold py-4 text-md">Growthcircle Name</p>
            <div className="flex gap-2 items-start">
              <div className="w-full">
                <input
                  className="w-full !text-lg focus:border-gray-400 !px-8 !py-6 rounded-full placeholder:text-sm placeholder:text-slate-300"
                  type="text"
                  placeholder="Specify Organisation Name"
                  required={true}
                  onChange={(e: React.ChangeEvent<HTMLInputElement>) =>
                    setTitleHandler(e.target.value)
                  }
                  value={formOrganisationName}
                />
                {errorTitle && formOrganisationName !== "" && (
                  <p className="text-red-400">
                    The name already exists please try another unique name
                  </p>
                )}
              </div>
              {!errorTitle && formOrganisationName !== "" && (
                <FaCheckCircle
                  style={{ color: "var(--icon-colour-2)" }}
                  size={35}
                  className="mt-2"
                />
              )}
              {errorTitle && formOrganisationName !== "" && (
                <FaTimesCircle
                  style={{ color: "var(--icon-colour-4)" }}
                  size={35}
                  className="mt-2"
                />
              )}
            </div>
          </div>

          <div className="w-full md:w-1/2">
            {" "}
            <p className="text-md my-2 font-semibold">
              Description of Growthcircle
            </p>{" "}
            <div className="py-4 bg-white border border-gray-300 p-2 rounded-md">
              <AutoResizeTextArea
                isRequired={true}
                placeholder="Specify Short Description / Summary"
                className="border-none w-full text-slate-500 placeholder:text-slate-300"
                value={shortDescription}
                onChange={(value) => setShortDescription(value)}
              />
            </div>
          </div>

          <div className="mt-2 w-full md:w-1/2">
            <p className="text-md my-2 font-semibold">
              Objectives ( fill-up the text input and press the plus icon)
            </p>
            <div className="flex justify-between gap-2 items-center">
              <input
                type="text"
                value={tempObject}
                className={
                  "w-full !text-lg focus:border-gray-400 !px-8 !py-6 rounded-full  placeholder:text-sm placeholder:text-slate-300 focus:border-none  "
                }
                placeholder="Specify objective..."
                onChange={(e) => setTempObject(e.target.value)}
              />
              <FaPlusCircle
                onClick={addObjective}
                style={{ color: "var(--icon-colour-2)" }}
                className="cursor-pointer"
                size={35}
              />
            </div>
            <div className="mt-2 px-5 text-xl">
              {objectives &&
                objectives.map((obj, index) => (
                  <div key={index} className="flex items-center">
                    <p>{obj}</p>{" "}
                    <FaTimesCircle
                      onClick={() => removeObjective(index)}
                      style={{
                        color: "var(--icon-colour-0)",
                        marginLeft: "0.5rem",
                        cursor: "pointer",
                      }}
                      size={20}
                    />
                  </div>
                ))}
            </div>
          </div>

          <div className="flex flex-col w-full md:w-1/2">
            <p className="text-md my-2 font-semibold">Who is this for</p>
            <Select
              components={animatedComponents}
              closeMenuOnSelect={false}
              isMulti
              options={getForThis(whoIsData)}
              defaultValue={formattedSelectedRoles}
              onChange={handleChange}
              required
            />
          </div>
          <div className="flex flex-col w-full md:w-1/2">
            <p className="text-md my-2 font-semibold">Tag</p>
            <Select
              components={animatedComponents}
              defaultValue={formattedSelectedTags}
              options={getForThis(tagData)}
              onChange={handleTagChange}
              required
            />
          </div>

          <div className="flex flex-col w-full md:w-1/2">
            <p className="text-md my-2 font-semibold">Sub Tag</p>
            <Select
              components={animatedComponents}
              defaultValue={getFormattedData(subTags)}
              isMulti
              options={getForThis(subTagSelection)}
              onChange={handleSubTagChange}
              required
            />
          </div>

          <div className="mt-2 w-full md:w-1/2">
            {" "}
            <p className="text-md my-2 font-semibold">
              Side Remarks (internal reference)
            </p>{" "}
            <div className="py-4 bg-white border border-gray-300  p-2 rounded-md ">
              <AutoResizeTextArea
                isRequired={true}
                placeholder="Side Remarks"
                className="border-none w-full text-slate-500 placeholder:text-slate-300"
                value={remarks}
                onChange={(value) => setRemarks(value)}
              />
            </div>
          </div>
          <div className="flex flex-col w-full md:w-1/2">
            <p className="text-md my-2 font-semibold">
              Target Audience(s) (internal reference)
            </p>
            {targetAudience && (
              <Select
                components={animatedComponents}
                closeMenuOnSelect={false}
                isMulti
                defaultValue={formattedTargetAudience}
                options={getForThis(TargetAudienceData)}
                onChange={handleTargetAudience}
                required
              />
            )}
          </div>

          <button
            className={`${classes["button"]}`}
            type="submit"
            disabled={isLoading}
          >
            {isLoading ? "Saving..." : "Save"}
          </button>
        </form>

        {selectedOrganisation && (
          <GrowthCircleHeaderText
            updating={updating}
            isLoading={isLoading}
            selectedOrganisation={selectedOrganisation}
            handleCheckboxChange={() =>
              handleCheckboxChange("hasCustomHeaderText")
            }
            OrganisationHeaderText={OrganisationHeaderText}
            setOrganitionHeaderText={setOrganitionHeaderText}
            handleSubmitHeaderText={handleSubmitHeaderText}
          />
        )}

        {selectedOrganisation && (
          <GrowthCircleUploadLogo
            selectedOrganisation={selectedOrganisation}
            OrganisationTagLine={OrganisationTagLine}
            setImageURLsData={setImageURLsData}
          />
        )}

        {selectedOrganisation && (
          <GrowthCircleTagLine
            OrganisationTagLine={OrganisationTagLine}
            setOrganisationTagLine={setOrganisationTagLine}
            isLoading={isLoading}
            handleSubmitTagLine={handleSubmitTagLine}
            updating={updating}
            handleCheckboxChange={handleCheckboxChange}
            selectedOrganisation={selectedOrganisation}
          />
        )}

        <div className="gap-4 mt-5 ">
          {selectedOrganisation && (
            <GrowthCircleInviteLink
              selectedOrganisation={selectedOrganisation}
              selectedGroup={selectedGroup}
              groupNames={groupNames}
              handleGroupChange={handleGroupChange}
            />
          )}

          {selectedOrganisation && (
            <DeleteGcComponent organisation={selectedOrganisation} />
          )}
        </div>
      </div>
    </>
  );
}
