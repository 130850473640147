import packageJson from "../../../package.json";

const VersionInfo = () => {
  return (
    <div style={{ color: "var(--main-colour)" }} className={`text-sm mt-2`}>
      <p>Version: {packageJson.version}</p>
    </div>
  );
};

export default VersionInfo;
