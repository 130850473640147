import { ChangeEventHandler } from "react";

type Props = {
  label: string;
  value: string;
  onChange: ChangeEventHandler<HTMLTextAreaElement>;
  isDisabled?: boolean;
};

export default function TextAreaQuestion({
  label,
  value,
  onChange,
  isDisabled,
}: Props) {
  return (
    <div className="border-slate-400/[.4] w-full  md:max-w-[360px] py-4">
      <p className="text-slate-600 mb-2 font-[700] px-4 text-left select-none">
        {label}
      </p>

      <textarea
        defaultValue={value}
        onChange={onChange}
        style={{ borderColor: "var(--main-colour)" }}
        className={`w-full max-w-[290px] md:max-w-[360px] border-2 rounded-lg bg-default shadow-lg  px-4 py-2 focus:ring-main-colour focus:border-main-colour`}
        rows={5}
        disabled={isDisabled}
      ></textarea>
    </div>
  );
}
