import classes from "./WaitingAreaNext.module.css";
import { Participant } from "interface/ParticipantInterface";
import { useGCSession } from "../../hooks/useGCSession";
import { useNavigate } from "react-router-dom";
// import { DICE_ROUTE } from "strings/Routes";
import { useGrowthCircleContext } from "hooks/useGrowthCircleContext";
import useParticipants from "hooks/useParticipants";
import { checkCanAdvanceNextActivity } from "models/organisationRole";
import Sage from "assets/BeanSquad/sage.png";

interface Props {
  participants: Participant[];
}

const WaitingAreaNext: React.FC<Props> = ({ participants }) => {
  const { facilAllowNextPage } = useGCSession();
  const { growthCircleSession } = useGrowthCircleContext();
  const { sessionRole } = useParticipants();
  const navigate = useNavigate();

  const canAdvance = checkCanAdvanceNextActivity(sessionRole);
  const clickHandler = () => {
    if (canAdvance) {
      facilAllowNextPage("dice");
    }
    // navigate(DICE_ROUTE);
    navigate("/all-circles/ground-rules");
  };
  // console.log(
  //   sessionRole,
  //   participants.length >= growthCircleSession!.number,
  //   canAdvance,
  //   growthCircleSession!.allowDice
  // );

  return (
    <>
      <p className={classes["prompt"]}>
        {participants.length >= growthCircleSession!.number
          ? ""
          : "Waiting for others..."}
      </p>

      <div className={classes["avatar-helper"]}>
        {/* TODO: exclude facilitator */}
        {participants.length >= growthCircleSession!.number ||
        canAdvance ||
        growthCircleSession!.allowDice ? (
          <button
            className={classes["submitButton"]}
            onClick={clickHandler}
            type="button"
          >
            NEXT
          </button>
        ) : (
          <img src={Sage} alt="Sage" />
        )}
      </div>
    </>
  );
};

export default WaitingAreaNext;
