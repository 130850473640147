import { Modal } from "flowbite-react";

import classes from "pages/AllCircles/Admin/Admin.module.css";
import { AiFillCloseCircle } from "react-icons/ai";
import { useNavigate } from "react-router";

const ConfirmExitModal = ({
  setHasModal,
  handler,
  showExit,
}: {
  setHasModal: (b: boolean) => any;
  handler: () => void;
  showExit: boolean;
}) => {
  const navigate = useNavigate();
  const confirmNext = async () => {
    localStorage.removeItem("SelfAwarenessJournal");
    navigate("/self-awareness");
  };

  return (
    <Modal show={showExit} popup={true} size={"sm"}>
      <div
        className={`${classes["container"]} p-5 relative flex flex-col`}
        style={{ color: "var(--text-colour)" }}
      >
        <>
          <AiFillCloseCircle
            style={{ color: "var(--icon-colour-0)" }}
            size={30}
            className="absolute top-2 right-2 cursor-pointer"
            onClick={handler}
          />
          <p className="my-8 text-center">
            Are you certain you wish to leave? Please be aware that any
            unfinished journal data will be cleared upon exiting.
          </p>
          <div className="flex  gap-4 justify-center items-center">
            <button onClick={confirmNext} className={`${classes["button"]}  `}>
              YES
            </button>
            <button onClick={handler} className={`${classes["button"]} `}>
              NO
            </button>
          </div>
        </>
      </div>
    </Modal>
  );
};

export default ConfirmExitModal;
