import { useState } from "react";
import classes from "./InputField.module.css";
import PasswordField from "./PasswordField";
import AlertModal from "assets/AlertModal";
import { ReactComponent as ExclamationMark } from "assets/ExclamationMark.svg";
import SelectField from "./SelectField";
import DateField from "./DateField";
import FileField from "./FileField";
import CheckboxField from "./CheckboxField";

const InputField = (props) => {
  const [showAlertModal, setShowAlertModal] = useState(true);

  const toggleAlertModal = () => {
    setShowAlertModal((prev) => !prev);
  };

  let inputField: JSX.Element;

  if (props.type === "password") {
    inputField = (
      <PasswordField
        errorMessage={props.Invalid}
        placeholder={props.text}
        isRequired={props.isRequired}
        onChange={props.onChange}
        id={props.htmlFor}
      />
    );
  } else if (props.type === "checkbox") {
    inputField = (
      <CheckboxField
        id={props.htmlFor}
        onChange={props.onChange}
        checked={props.checked}
      />
    );
  } else if (props.type === "date") {
    inputField = (
      <DateField
        id={props.htmlFor}
        onChange={props.onChange}
        value={props.value}
        className={props.className}
      />
    );
  } else if (props.type === "select") {
    inputField = (
      <SelectField
        required={props.isRequired}
        id={props.htmlFor}
        label={props.label}
        placeholder={props.placeholder}
        onChange={props.onChange}
        defaultValue={props.defaultValue}
        value={props.value}
        options={props.options}
        containerClassName={props.containerClassName}
        inputClassName={props.inputClassName}
      />
    );
  } else if (props.type === "file") {
    inputField = (
      <FileField
        onChange={props.onChange}
        accept={props.accept}
        customIcon={props.customIcon}
      />
    );
  } else if (props.type === "form") {
    inputField = (
      <form className="flex items-center m-1" onSubmit={props.onSubmit}>
        <div className={classes["input-placeholder"]}>
          <input
            placeholder={props.text}
            className={`${classes.input} ${props.className}`}
            required={props.isRequired}
            onChange={props.onChange}
            type={props.type}
            id={props.htmlFor}
            value={props.value}
            autoFocus={props.autoFocus}
            autoComplete="on"
          />
        </div>
        <button onSubmit={props.onSubmit}>Submit</button>
      </form>
    );
  } else if (props.type === "year") {
    inputField = (
      <label style={{ marginTop: "10px", color: "var(--text-colour)" }}>
        {props.label}
        <div className={classes["input-placeholder"]}>
          <input
            className="w-full border-none"
            onChange={props.onChange}
            value={props.value}
            min="1900"
            max={props.minYear}
            type="number"
          />
        </div>
      </label>
    );
  } else {
    inputField = (
      <div
        className={`${classes["input-placeholder"]} ${
          props.Invalid && classes.error
        } ${props.className}`}
        style={{ margin: "10px" }}
      >
        <input
          placeholder={props.text}
          className={`${classes.input} placeholder:text-sm`}
          required={props.isRequired}
          onChange={props.onChange}
          type={props.type}
          id={props.htmlFor}
          value={props.value}
          defaultValue={props.defaultValue}
          autoFocus={props.autoFocus}
          autoComplete="on"
        />
        {props.Invalid && (
          <div className={classes["alert-placeholder"]}>
            <ExclamationMark
              onClick={toggleAlertModal}
              className="cursor-pointer"
            />
            {showAlertModal && <AlertModal text={props.Invalid} />}
          </div>
        )}
      </div>
    );
  }

  return (
    <>
      {props.htmlFor && props.label && (
        <label
          htmlFor={props.htmlFor}
          style={{ margin: "10px", color: "var(--text-colour)" }}
        >
          {props.label}
        </label>
      )}
      {inputField}
    </>
  );
};

export default InputField;
