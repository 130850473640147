import SliderCustom from "components/SliderCustom/SliderCustom";
import BackAndNext from "components/utility/BackAndNext";
import EmptySpace from "components/utility/EmptySpace";
import useParticipants from "hooks/useParticipants";
import { useEffect, useState } from "react";
import classes from "./CheckOutAfter.module.css";
import React from "react";
import { ComponentProps } from "../GenericSessionPage/ComponentMapping";
import { DRAG_RATING_KEYS, useDragRating } from "hooks/useDragRating";
import { CheckInCheckOut } from "interface/ParticipantInterface";
import { useComponentDidUpdate } from "hooks/useComponentDidUpdate";
import useOrganisationContext from "hooks/organisation/useOrganisationContext";

const CHECKOUT_STORAGE_KEY = "checkOut";

const CheckOutAfterComponent = ({ backAndNextHandler }: ComponentProps) => {
  const { saveCheckOut } = useParticipants();
  const [isSaving, setIsSaving] = useState(false);
  const { selectedOrganisation } = useOrganisationContext();
  const {
    dragValues: introDragValues,
    setDragValues: setIntroDragValues,
    setKeyToTouched,
    removeDragRatingsFromStorage,
  } = useDragRating(CHECKOUT_STORAGE_KEY);

  const [individually, setIndividually] = useState(
    introDragValues[DRAG_RATING_KEYS.INDIVIDUALLY.KEY].value
  );
  const [interpersonally, setInterpersonally] = useState(
    introDragValues[DRAG_RATING_KEYS.INTERPERSONALLY.KEY].value
  );
  const [socially, setSocially] = useState(
    introDragValues[DRAG_RATING_KEYS.SOCIALLY.KEY].value
  );
  const [overall, setOverall] = useState(
    introDragValues[DRAG_RATING_KEYS.OVERALL.KEY].value
  );

  //Set touch to true for drag ratings
  useComponentDidUpdate(() => {
    setKeyToTouched(DRAG_RATING_KEYS.INDIVIDUALLY.KEY);
  }, [individually]);

  useComponentDidUpdate(() => {
    setKeyToTouched(DRAG_RATING_KEYS.INTERPERSONALLY.KEY);
  }, [interpersonally]);

  useComponentDidUpdate(() => {
    setKeyToTouched(DRAG_RATING_KEYS.SOCIALLY.KEY);
  }, [socially]);

  useComponentDidUpdate(() => {
    setKeyToTouched(DRAG_RATING_KEYS.OVERALL.KEY);
  }, [overall]);

  useEffect(() => {
    const newIntroDragValues = { ...introDragValues };
    newIntroDragValues[DRAG_RATING_KEYS.INDIVIDUALLY.KEY].value = individually;
    newIntroDragValues[DRAG_RATING_KEYS.INTERPERSONALLY.KEY].value =
      interpersonally;
    newIntroDragValues[DRAG_RATING_KEYS.SOCIALLY.KEY].value = socially;
    newIntroDragValues[DRAG_RATING_KEYS.OVERALL.KEY].value = overall;
    setIntroDragValues(newIntroDragValues);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [individually, interpersonally, socially, overall]);

  const nextHandler = async (
    e: React.MouseEvent<HTMLButtonElement, MouseEvent>
  ) => {
    setIsSaving(true);
    const data = {} as CheckInCheckOut;
    data[DRAG_RATING_KEYS.INDIVIDUALLY.KEY] = individually;
    data[DRAG_RATING_KEYS.INTERPERSONALLY.KEY] = interpersonally;
    data[DRAG_RATING_KEYS.SOCIALLY.KEY] = socially;
    data[DRAG_RATING_KEYS.OVERALL.KEY] = overall;
    await saveCheckOut(data);
    removeDragRatingsFromStorage();
    setIsSaving(false);
    backAndNextHandler.nextCallback(e);
  };

  useEffect(() => {
    if (!selectedOrganisation) return;
  }, [selectedOrganisation]);

  return (
    <React.Fragment>
      <EmptySpace height={"10px"} />
      <p className="text-center font-semibold mt-3 text-sm w-3/4">
        {selectedOrganisation?.name === "NTU Career & Attachment Office"
          ? "Answer these questions again. On a scale of 1-10, how much clarity do you have in the following areas."
          : "Answer these questions again. How are you feeling now in the following areas?"}
      </p>
      <div className="w-[320px] md:w-[400px] mx-auto mt-5 ">
        <div className="w-full mx-auto">
          <div className={`${classes.box} shadow-md`}>
            <div className={classes.header}>
              {" "}
              {selectedOrganisation?.name === "NTU Career & Attachment Office"
                ? "Industry insights"
                : "Individually"}
            </div>
            <div className={classes["header-description"]}>
              {selectedOrganisation?.name === "NTU Career & Attachment Office"
                ? ""
                : "Personal well-being"}
            </div>
            <div className="flex justify-center px-2">
              <SliderCustom
                maxValue={selectedOrganisation?.maxRating ?? 10}
                withBackground={true}
                value={individually}
                setValue={setIndividually}
              />
            </div>
          </div>
        </div>
      </div>
      <div className="w-[320px] md:w-[400px] mx-auto mt-5 ">
        <div className="w-full mx-auto">
          <div className={`${classes.box} shadow-md`}>
            <div className={classes.header}>
              {" "}
              {selectedOrganisation?.name === "NTU Career & Attachment Office"
                ? "Job roles"
                : "Interpersonally"}
            </div>
            <div className={classes["header-description"]}>
              {selectedOrganisation?.name === "NTU Career & Attachment Office"
                ? ""
                : "Family, Close relationships"}
            </div>
            <div className="flex justify-center px-2">
              <SliderCustom
                maxValue={selectedOrganisation?.maxRating ?? 10}
                withBackground={true}
                value={interpersonally}
                setValue={setInterpersonally}
              />
            </div>
          </div>
        </div>
      </div>

      <div className="w-[320px] md:w-[400px] mx-auto mt-5 ">
        <div className="w-full mx-auto">
          <div className={`${classes.box} shadow-md`}>
            <div className={classes.header}>
              {" "}
              {selectedOrganisation?.name === "NTU Career & Attachment Office"
                ? "Career Paths & Progression"
                : "Socially"}
            </div>
            <div className={classes["header-description"]}>
              {selectedOrganisation?.name === "NTU Career & Attachment Office"
                ? ""
                : "Work, school, friendships"}
            </div>
            <div className="flex justify-center px-2">
              <SliderCustom
                maxValue={selectedOrganisation?.maxRating ?? 10}
                withBackground={true}
                value={socially}
                setValue={setSocially}
              />
            </div>
          </div>
        </div>
      </div>

      <div className="w-[320px] md:w-[400px] mx-auto mt-5 ">
        <div className="w-full mx-auto">
          <div className={`${classes.box} shadow-md`}>
            <div className={classes.header}>
              {" "}
              {selectedOrganisation?.name === "NTU Career & Attachment Office"
                ? "Skills Relevant To Industry"
                : "Overall"}
            </div>
            <div className={classes["header-description"]}>
              {selectedOrganisation?.name === "NTU Career & Attachment Office"
                ? ""
                : "General sense of well-being"}
            </div>
            <div className="flex justify-center px-2">
              <SliderCustom
                maxValue={selectedOrganisation?.maxRating ?? 10}
                withBackground={true}
                value={overall}
                setValue={setOverall}
              />
            </div>
          </div>
        </div>
      </div>

      <EmptySpace height={"40px"} />

      <BackAndNext
        {...backAndNextHandler}
        customNextText={isSaving ? "Saving..." : "Next"}
        nextCallback={nextHandler}
        nextAllowed={Object.values(introDragValues).every(({ value }) => value > 0) && backAndNextHandler.nextAllowed}
      />

      <EmptySpace height={"60px"} />
    </React.Fragment>
  );
};

export default CheckOutAfterComponent;
