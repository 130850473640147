import SimpleLoader from "components/Loaders/SimpleLoader";
import { Button, Modal } from "flowbite-react";
import { useResourcesOrganisation } from "hooks/useResourcesOrganisation";
import usePromise from "hooks/utility/usePromise";
import { ResourceInterface } from "interface/ResourceInterface";
import { useState } from "react";
import { toast } from "react-hot-toast";
import { FaTrash } from "react-icons/fa";
import { HiOutlineExclamationCircle } from "react-icons/hi";

interface ResourceProps {
  resource: ResourceInterface;
}

const ResourceCard = ({ resource }: ResourceProps) => {
  const { isLoading: isDeleting, resolve } = usePromise();
  const { DeleteResource } = useResourcesOrganisation();
  const [isVisible, setIsVisible] = useState(true);
  const deleteHandler = async (resourceId: string) => {
    resolve(() => DeleteResource(resourceId)).then(() => {
      setShow(!show);
      toast.success("data was deleted successfully");
      setIsVisible(!isVisible);
    });
  };
  const [show, setShow] = useState(false);

  const modalHandler = async () => {
    setShow(!show);
  };
  return (
    <>
      <Modal show={show} size="xl" popup onClose={modalHandler}>
        <Modal.Header />
        <Modal.Body>
          <div className="text-center">
            <HiOutlineExclamationCircle className="mx-auto mb-4 h-14 w-14 text-gray-400 dark:text-gray-200" />
            <h3 className="mb-5 text-lg font-normal text-gray-500 dark:text-gray-400">
              Are you sure you want to delete the "
              <strong>{resource.title}</strong>" resource?
            </h3>
            {!isDeleting ? (
              <div className="flex justify-center gap-4">
                <Button
                  color="failure"
                  onClick={() => deleteHandler(resource.id)}
                >
                  Yes, I'm sure
                </Button>
                <Button color="gray" onClick={modalHandler}>
                  No, cancel
                </Button>
              </div>
            ) : (
              <div className="flex justify-center gap-4">
                <SimpleLoader />
              </div>
            )}
          </div>
        </Modal.Body>
      </Modal>

      <div
        className={`${
          isVisible ? "" : "hidden"
        } bg-white relative rounded-md shadow-sm flex flex-col gap-2 w-full xl:w-1/2 p-4`}
      >
        <FaTrash
          onClick={modalHandler}
          className="absolute top-2 right-2 text-red-400 cursor-pointer"
          size={22}
        />
        <p className="text-2xl font-semibold">{resource.title}</p>
        <p className="text-sky-600">
          <a href={resource.url} target="_new">
            {resource.url}
          </a>
        </p>
      </div>
    </>
  );
};

export default ResourceCard;
