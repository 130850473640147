import Button from "components/utility/Button";
import InputField from "components/utility/Forms/InputField";
import { useLogin } from "hooks/useLogin";
import useUsers from "hooks/useUsers";
import { useState } from "react";
import { useNavigate } from "react-router-dom";

const LoginAs = () => {
  const { signInUsingUID } = useLogin();
  const [email, setEmail] = useState("");
  const { checkUserFunction } = useUsers();
  const [error, setError] = useState<string | null>(null);
  const [isPending, setIsPending] = useState<boolean>(false);
  const navigate = useNavigate();
  const formHandler = async () => {
    setIsPending(true);
    setError(null);
    const data = await checkUserFunction(email);

    var requestOptions: RequestInit = {
      method: "GET",
      mode: "cors",
      cache: "no-cache",
      headers: {
        "Content-Type": "application/x-www-form-urlencoded; charset=UTF-8",
      },
    };

    if (data) {
      fetch(
        `https://us-central1-nobeans-110a4.cloudfunctions.net/createCustomToken?id=${data.id}`,
        requestOptions
      )
        .then((response) => response.json())
        .then((data) => {
          signInUsingUID(data, "");
          setTimeout(() => {
            setIsPending(false);
            setError(null);
            navigate("/login");
          }, 2000);
        })
        .catch((e) => {
          console.log(e);
          setTimeout(() => {
            setIsPending(false);
            setError(null);
          }, 2000);
        });
    }
  };

  return (
    <div>
      {error && <p>{error}</p>}
      <InputField
        type="email"
        value={email}
        onChange={(e) => setEmail(e.target.value)}
      />
      <Button
        isDisabled={isPending}
        text={isPending ? "Loading..." : "Login"}
        onClick={formHandler}
      />
    </div>
  );
};

export default LoginAs;
