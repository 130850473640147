import { Dispatch, FC, SetStateAction, useState } from 'react';
import useUser from 'hooks/useUser';
import ConfirmationModal from "components/utility/Modals/ConfirmationModal";
import Modal from 'components/utility/Modal';
import Button from 'components/utility/Button';
import classes from "./ProfileSettings.module.css";
import EmptySpace from 'components/utility/EmptySpace';
import { checkPasswordError } from '../../utility/inputValidation';

interface Props {
  isModalOpen: boolean;
  setIsModalOpen: Dispatch<SetStateAction<boolean>>;
  currentPassword: string;
  newPassword: string;
}

const PasswordConfirmModal: FC<Props> = ({ isModalOpen, setIsModalOpen, currentPassword, newPassword }) => {
  const { setPassword, formatAuthError } = useUser();
  const [message, setMessage] = useState('');

  const confirmHandler = async () => {
    if (checkPasswordError(newPassword)) {
      setPassword(currentPassword, newPassword)
      .then(() => {
        setMessage('Password successfully changed');
      })
      .catch((e) => {
        setMessage(formatAuthError(e));
      })
    } else {
      setMessage('Password Set is Not Strong');
    }
  }

  const closeHandler = () => {
    setIsModalOpen(false);
    setMessage('');
  }

  return (
      message
      ? (
        <Modal
          isModalOpen={isModalOpen}
          setIsModalOpen={setIsModalOpen}
          onClose={closeHandler}
        >
          <div className={classes.modal}>
            <div>
              <b>
                {message}
              </b>
            </div>
            <EmptySpace />
            <div>
              <Button text='Okay' onClick={closeHandler}/>
            </div>
          </div>
        </Modal>
      )
      : (
        <ConfirmationModal
          isModalOpen={isModalOpen}
          setIsModalOpen={setIsModalOpen}
          confirmHandler={confirmHandler}
        >
          <div className={classes.modal}>
            <div>
              <b>You are about to reset your password.</b>
            </div>
            <EmptySpace />
            <div>
              Do you want to proceed?
            </div>
            <EmptySpace />
          </div>
        </ConfirmationModal>
      )
  );
}

export default PasswordConfirmModal