import { useEffect, useState } from "react";
import TitleBox from "../SubComponents/TitleBox";

import { useSelfAwarenessContext } from "hooks/useSelfAwarenessContext";
import BackAndNextFlat from "components/utility/BackAndNextFlat";
import { FaPlusCircle } from "react-icons/fa";
import classes from "pages/AllCircles/Admin/Admin.module.css";
import SimpleLoader from "components/Loaders/SimpleLoader";
import { BackAndNextProps } from "../SelfAwarenessComponentMapping";
import WorstInput from "../SubComponents/WorstInput";
import toast from "react-hot-toast";

const WorstSelection = ({
  backAndNextHandler,
  setHasModal,
}: {
  backAndNextHandler: BackAndNextProps;
  setHasModal: (show: boolean) => void;
}) => {
  const [show, setShow] = useState<boolean>(false);
  const { journal } = useSelfAwarenessContext();

  const handler = () => {
    setShow(!show);
    setHasModal(!show);
  };

  const [worsts, setWorst] = useState<string[]>([]);
  const [isLoading, setLoading] = useState<boolean>(false);

  // Load data from local storage on component mount
  useEffect(() => {
    const storedJournal = localStorage.getItem("SelfAwarenessJournal");
    const existingJournal = storedJournal ? JSON.parse(storedJournal) : {};
    if (existingJournal && existingJournal.worsts) {
      setWorst(existingJournal.worsts);
    } else {
      const _raw = ["", "", ""];
      setWorst(_raw);

      const storedJournal = localStorage.getItem("SelfAwarenessJournal");
      const existingJournal = storedJournal ? JSON.parse(storedJournal) : {};

      // Update the local storage with the modified challenges array
      const updatedJournal = {
        ...existingJournal,
        worsts: _raw, // Add the new challenge
      };
      localStorage.setItem(
        "SelfAwarenessJournal",
        JSON.stringify(updatedJournal)
      );
    }
    // eslint-disable-next-line
  }, []);

  const addWorstHandler = () => {
    // Assuming challenges is an array in your state
    const newWorst = ""; // Replace with your actual default data
    setWorst((prevWorst) => [...prevWorst, newWorst]);

    const storedJournal = localStorage.getItem("SelfAwarenessJournal");
    const existingJournal = storedJournal ? JSON.parse(storedJournal) : {};
    const existingWorst = existingJournal.worsts || [];

    // Update the local storage with the modified challenges array
    const updatedJournal = {
      ...existingJournal,
      worsts: [...existingWorst, newWorst], // Add the new challenge
    };
    localStorage.setItem(
      "SelfAwarenessJournal",
      JSON.stringify(updatedJournal)
    );
  };

  const removeHandler = (index: number) => {
    const storedJournal = localStorage.getItem("SelfAwarenessJournal");
    const existingJournal = storedJournal ? JSON.parse(storedJournal) : {};
    const existingWorst = existingJournal.worsts || [];
    const updatedWorst = existingWorst.filter((_, i) => i !== index); // Remove the challenge with the specified value

    // Update the local storage with the modified challenges array directly
    existingJournal.worsts = updatedWorst;
    localStorage.setItem(
      "SelfAwarenessJournal",
      JSON.stringify(existingJournal)
    );
    setWorst(updatedWorst); // Update the state
    refreshValues();
  };

  const refreshValues = () => {
    setLoading(true);
    const storedJournal = localStorage.getItem("SelfAwarenessJournal");
    const existingJournal = storedJournal ? JSON.parse(storedJournal) : {};
    if (existingJournal && existingJournal.worsts) {
      setWorst(existingJournal.worsts);
    }
    setTimeout(() => {
      setLoading(false);
    }, 1000);
  };

  const nexAllowHandler = (
    e: React.MouseEvent<HTMLButtonElement, MouseEvent>
  ) => {
    const storedJournal = localStorage.getItem("SelfAwarenessJournal");
    const existingJournal = storedJournal ? JSON.parse(storedJournal) : {};
    if (existingJournal.worsts.every((item) => item.trim() !== "")) {
      backAndNextHandler.nextCallback(e);
    } else {
      toast.error("Please fill-up all fields.");
      return false;
    }
  };

  useEffect(() => {}, [isLoading]);

  // Load data from local storage on component mount
  useEffect(() => {
    const storedJournal = localStorage.getItem("SelfAwarenessJournal");
    if (storedJournal) {
      const existingJournal = storedJournal ? JSON.parse(storedJournal) : {};

      // Update the existing journal with the new answer
      const updatedJournal = {
        ...existingJournal,
        steps: [],
      };

      // Store the updated journal in local storage
      localStorage.setItem(
        "SelfAwarenessJournal",
        JSON.stringify(updatedJournal)
      );
    }
    // eslint-disable-next-line
  }, []); // Empty dependency array means this effect runs once on mount

  useEffect(() => {
    if (!journal) return;
  }, [journal]);

  return (
    <>
      {journal && (
        <>
          <TitleBox
            journal={journal}
            handler={handler}
            showInfo={false}
            title={`What is the worst that can happen? Name up to three things.`}
          />

          <div
            className={`p-5 flex flex-col gap-2 mt-8 font-poppins ${classes["container"]}`}
          >
            {!isLoading &&
              worsts.map((worst, index) => (
                <WorstInput
                  key={index}
                  removeHandler={removeHandler}
                  index={index}
                  pageType={journal.pageType}
                  worst={worst}
                />
              ))}

            {isLoading && (
              <div className="flex justify-center items-center">
                <SimpleLoader />
              </div>
            )}
          </div>
          {worsts.length < 3 && (
            <div className="flex justify-center items-center mb-8">
              <FaPlusCircle
                className="cursor-pointer"
                onClick={addWorstHandler}
                size={30}
                style={{ color: "var(--icon-colour-0)" }}
              />
            </div>
          )}
        </>
      )}
      <div className="my-4">
        <BackAndNextFlat
          {...backAndNextHandler}
          nextAllowed={worsts.length >= 3 ? true : false}
          nextCallback={(e) => nexAllowHandler(e)}
        />
      </div>
    </>
  );
};

export default WorstSelection;
