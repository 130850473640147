import { FC, MouseEvent } from "react";
import classes from "./BackAndNextFlat.module.css";

interface BackAndNextFlatProps {
  backCallback?: (e: MouseEvent<HTMLButtonElement>) => any;
  nextCallback?: (e: MouseEvent<HTMLButtonElement>) => any;
  backFailCallback?: (e: MouseEvent<HTMLButtonElement>) => any;
  nextFailCallback?: (e: MouseEvent<HTMLButtonElement>) => any;
  backAllowed: boolean | null | undefined;
  nextAllowed: boolean | null | undefined;
  nextChangeColour?: boolean;
  backHidden?: boolean | null | undefined;
  isFixed?: boolean | null | undefined;
  customNextText?: string;
  useAnimation?: boolean;
}

const BackAndNextFlat: FC<BackAndNextFlatProps> = ({
  backCallback,
  nextCallback,
  backFailCallback,
  nextFailCallback,
  backAllowed,
  nextAllowed,
  nextChangeColour,
  customNextText,
  backHidden,
  isFixed,
  useAnimation = true,
}) => {
  let nextButtonClassName = `${classes["next"]} `;
  if (useAnimation) {
    nextButtonClassName += `${
      (!nextAllowed && classes["button-greyed"]) ||
      classes["button-allowed"] + " " + classes["animation"]
    }`;
  } else {
    nextButtonClassName += `${
      (!nextAllowed && classes["button-greyed"]) || classes["button-allowed"]
    }`;
  }

  const handleNext = (e: MouseEvent<HTMLButtonElement>) => {
    if (!nextAllowed && nextFailCallback) {
      nextFailCallback(e);
    } else if (nextAllowed && nextCallback) {
      nextCallback(e);
    }
  };

  const handleBack = (e: MouseEvent<HTMLButtonElement>) => {
    if (!backAllowed && backFailCallback) {
      backFailCallback(e);
    } else if (backAllowed && backCallback) {
      backCallback(e);
    }
  };

  return (
    <div
      className={`${classes["back-and-next"]} ${
        isFixed ? classes["back-fixed"] : ""
      }`}
    >
      {!backHidden && (
        <button
          onClick={(e) => handleBack(e)}
          className={`${classes["back"]} ${
            !backAllowed && classes["button-greyed"]
          }`}
        >
          <strong>Back</strong>
        </button>
      )}
      <button
        onClick={(e: MouseEvent<HTMLButtonElement>) => handleNext(e)}
        className={nextButtonClassName}
      >
        <strong>{customNextText}</strong>
      </button>
    </div>
  );
};

BackAndNextFlat.defaultProps = {
  isFixed: false,
  customNextText: "Next",
};

export default BackAndNextFlat;
