import { useState, MouseEvent } from "react";
import Button from "../Button";
import classes from "./DebouncedButton.module.css";

interface Props {
  isDisabled?: boolean;
  text?: string;
  style?: { [key: string]: string | undefined };
  className?: string;
  type?: "button" | "submit" | "reset" | undefined;
  onClick?: (e: MouseEvent<HTMLButtonElement>) => Promise<any>;
}

/**
 * Button that triggers an asynchronous operation
 * and is disabled until the operation completes.
 * @param isDisabled Whether the button should be disabled,
 *    in addition to whether the async operation is in progress.
 * @param text Button text.
 * @param style Custom style of the button.
 * @param className Custom class name for the button.
 * @param type Button type.
 * @param onClick Click handler for the button.
 * @returns Debounced button component.
 */
const DebouncedButton = ({
  isDisabled,
  text,
  style,
  className,
  type,
  onClick = () => Promise.resolve(),
}: Props) => {
  const [loading, setLoading] = useState(false);

  const finalOnClick = async (e: MouseEvent<HTMLButtonElement>) => {
    setLoading(true);
    return onClick(e)
      .then(result => {
        return result;
      })
      .finally(() => {
        setLoading(false);
      });
  }

  const finalIsDisabled = loading || isDisabled;
  const finalClassName = (className ? className : classes.button)

  return (
    <Button 
      isDisabled={finalIsDisabled}
      text={text}
      style={style}
      className={finalClassName}
      disabledClassName={classes['button-disabled'] }
      type={type}
      onClick={finalOnClick}
    />
  );
};

export default DebouncedButton;
