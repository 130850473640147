import SimpleLoader from "components/Loaders/SimpleLoader";
import SelfDiscoveryOrgUsers from "components/Organisation/UsersSettings/SelfDiscoveryOrgUsers";
import InputField from "components/utility/Forms/InputField";
import AdminTab from "components/utility/Tab/AdminTab";
import TabItem from "components/utility/Tab/TabItem";

import useOrganisationContext from "hooks/organisation/useOrganisationContext";
import { useAuthContext } from "hooks/useAuthContext";
import usePromise from "hooks/utility/usePromise";
import { Profile } from "interface/ProfileInterface";
import { getOrgsWithPermission } from "models/organisation";
import { P_CAN_VIEW_OVERALL_SELF_DISCOVERY } from "models/permission";
import {
  ChangeEvent,
  Suspense,
  lazy,
  useCallback,
  useEffect,
  useState,
} from "react";
const SelfDiscoveryTable = lazy(() => import("./SelfDiscoveryTable"));

const SelfDiscoveryAdmin = () => {
  const { selectedOrganisation, selectOrganisationByName } =
    useOrganisationContext();
  const [options, setOptions] = useState<string[]>([]);
  const { profile } = useAuthContext();
  const { isLoading, resolve } = usePromise();

  const getOrgsWithDashboardPermission = useCallback((profile: Profile) => {
    return getOrgsWithPermission(profile, P_CAN_VIEW_OVERALL_SELF_DISCOVERY);
  }, []);

  //temp script
  if ("serviceWorker" in navigator) {
    navigator.serviceWorker.getRegistrations().then(function (registrations) {
      for (let registration of registrations) {
        registration.unregister();
      }
    });
  }

  useEffect(() => {
    if (!selectedOrganisation?.name) {
      selectOrganisationByName(options[1]);
    }
    //eslint-disable-next-line
  }, []);

  useEffect(() => {
    if (!profile) return;
    const fetchData = async () => {
      try {
        const orgs = await getOrgsWithDashboardPermission(profile);
        const org_names = orgs
          .filter((org) => !org.isAType)
          .map((org) => org.name)
          .sort();
        selectOrganisationByName(org_names[0]);
        return org_names;
      } catch (error) {
        // Handle errors here
        console.error(error);
        throw error; // Rethrow the error to be caught later if needed
      }
    };

    resolve(() => fetchData())
      .then((org_names) => {
        if (org_names) setOptions(org_names);
      })
      .catch((error) => {
        // Handle errors from fetchData or setOptions here
        console.error(error);
      });
    //eslint-disable-next-line
  }, [profile]);

  return (
    <div className="my-4">
      <AdminTab>
        <TabItem title="Overall">
          <Suspense fallback="Loading...">
            <SelfDiscoveryTable />
          </Suspense>
        </TabItem>
        <TabItem title="Organisation">
          {!isLoading ? (
            <div className="text-standard">
              <p className="font-semibold">Organisation</p>
              <InputField
                name={"selected organisation"}
                type="select"
                htmlFor={"organisation"}
                onChange={(e: ChangeEvent<HTMLSelectElement>) => {
                  selectOrganisationByName(e.target.value);
                }}
                options={options}
                value={selectedOrganisation?.name ?? ""}
              />
            </div>
          ) : (
            <SimpleLoader />
          )}

          <SelfDiscoveryOrgUsers />
        </TabItem>
      </AdminTab>
    </div>
  );
};

export default SelfDiscoveryAdmin;
