import { customFilter } from "../components/Home/Community/Profanity/ProfanityFilter";

export const MINIMUM_AGE = 13;
const MINIMUM_PASSWORD_LENGTH = 8;

export const USER_NAME_INPUT_ERRORS = {
  USER_NAME_EMPTY: "username-empty",
  USER_NAME_HAS_PROFANITY: "username-profanity-found",
} as const;

export const NAME_INPUT_ERRORS = {
  NAME_HAS_PROFANITY: "name-profanity-found",
  NAME_HAS_NUMBERS_OR_SPECIAL_CHARACTERS: "name-unsupported-characters-found",
} as const;

export const BIRTHDATE_INPUT_ERRORS = {
  BIRTHDATE_TOO_YOUNG: "birthdate-too-young",
  BIRTHDATE_INVALID: "birthdate-invalid",
} as const;

export const PASSWORD_INPUT_ERRORS = {
  PASSWORD_NO_UPPERCASE_CHARACTERS: "password-no-uppercase-characters",
  PASSWORD_NO_LOWERCASE_CHARACTERS: "password-no-lowercase-characters",
  PASSWORD_NO_NUMBERS: "password-no-numbers",
  PASSWORD_NO_SPECIAL_CHARACTERS: "password-no-special-characters",
  PASSWORD_NOT_LONG_ENOUGH: "password-not-long-enough",
} as const;

const INPUT_ERRORS = {
  ...USER_NAME_INPUT_ERRORS,
  ...BIRTHDATE_INPUT_ERRORS,
  ...NAME_INPUT_ERRORS,
  ...PASSWORD_INPUT_ERRORS,
} as const;

export const ALLOWED_SPECIAL_CHARS_PASSWORD = /[_@!#$%^&*+=~]/;
export const FORBIDDEN_SPECIAL_CHARS_PASSWORD = /[(){}[\]:;<>,.?\\/-]/;

type InputError = (typeof INPUT_ERRORS)[keyof typeof INPUT_ERRORS];

export const ERROR_MESSAGES: { [K in InputError]: string } = {
  "username-empty": "Username cannot be empty",
  "username-profanity-found": "Profanities are not allowed in username",
  "name-profanity-found":
    "Profanities are not allowed in first name and last name",
  "name-unsupported-characters-found":
    "Numbers and special characters are not allowed in first name and last name",
  "birthdate-too-young": `Invalid birthdate, you must be at least ${MINIMUM_AGE} years of age`,
  "birthdate-invalid": `Invalid birthdate, please enter a valid date year`,
  "password-no-uppercase-characters":
    "Password must contain an uppercase character",
  "password-no-lowercase-characters":
    "Password must contain a lowercase character",
  "password-no-numbers": "Password must contain a number",
  "password-no-special-characters":
    "Password must contain a special character such as (!, @, #, $)",
  "password-not-long-enough": `Password must be at least ${MINIMUM_PASSWORD_LENGTH} characters long`,
};

/**
 * Checks if a user of a certain birthdate is lower than a certain age.
 *
 * @param birthDate Birthdate of the user.
 * @param minAge Minimum age required.
 * @returns True if the user at least of minimum age, false otherwise.
 */
function isOfAge(birthDate: Date, minAge: number) {
  const today = new Date();
  const monthDiff = today.getMonth() - birthDate.getMonth();
  let age = today.getFullYear() - birthDate.getFullYear();
  if (
    monthDiff < 0 ||
    (monthDiff === 0 && today.getDate() < birthDate.getDate())
  ) {
    age -= 1;
  }
  return age >= minAge;
}

/**
 * Checks if the input string contains profanities.
 */
function hasProfanity(input: string) {
  return customFilter.isProfane(input);
}

/**
 * @param input Checks if input string contain special characters that shouldn't be in names.
 */
function nameHasSpecialCharacters(input: string) {
  const regex = /^[\w'\-,.][^0-9_!¡?÷?¿\\+=@#$%ˆ&*(){}|~<>;:[\]]{0,}$/;
  return input.length > 0 && !regex.test(input);
}

/**
 * @param input Checks if input string contain special characters that should and shouldn't be in passwords.
 */
export function passwordHasSpecialCharacters(input: string) {
  return ALLOWED_SPECIAL_CHARS_PASSWORD.test(input) && !FORBIDDEN_SPECIAL_CHARS_PASSWORD.test(input);
}


/**
 * Validates the userName to ensure it is not empty and does not contain profanities.
 *
 * @param userName Input userName.
 * @throws Error(INPUT_ERRORS.USERNAME_EMPTY) if input is empty.
 * @throws Error(INPUT_ERRORS.USERNAME_HAS_PROFANITY) if input contains profanities.
 */
export function validateUserName(userName: string) {
  if (!userName) {
    throw new Error(INPUT_ERRORS.USER_NAME_EMPTY);
  }
  if (hasProfanity(userName)) {
    throw new Error(INPUT_ERRORS.USER_NAME_HAS_PROFANITY);
  }
}

/**
 * Validates the firstName and lastName to ensure that it does not contain
 * profanities and certain special characters.
 *
 * @param name Input name.
 * @throws Error(INPUT_ERRORS.NAME_HAS_PROFANITY) if input contains profanities.
 * @throws Error(INPUT_ERRORS.NAME_HAS_NUMBERS_OR_SPECIAL_CHARACTERS) if input contains special characters.
 */
export function validateName(name: string) {
  if (hasProfanity(name)) {
    throw new Error(INPUT_ERRORS.NAME_HAS_PROFANITY);
  } else if (nameHasSpecialCharacters(name)) {
    throw new Error(INPUT_ERRORS.NAME_HAS_NUMBERS_OR_SPECIAL_CHARACTERS);
  }
}

/**
 * Validates the birthdate to ensure user is old enough.
 *
 * @param birthday Input birthday string.
 * @throws Error(INPUT_ERRORS.BIRTHDAY_TOO_YOUNG) if input birthdate is too young.
 */
export function validateBirthday(birthday: string) {
  if (birthday && isNaN(Date.parse(birthday))) {
    throw new Error(INPUT_ERRORS.BIRTHDATE_INVALID);
  }
  if (birthday && !isOfAge(new Date(birthday), MINIMUM_AGE)) {
    throw new Error(INPUT_ERRORS.BIRTHDATE_TOO_YOUNG);
  }
}

/**
 * Checks that password is of sufficient complexity.
 * Checks that password contains 1 uppercase and 1 lowercase character, a number and special character.
 * Checks that password is of sufficient length.
 *
 * @param password Password to check
 * @returns The error code if there are any violations, null otherwise.
 */
export function checkPasswordError(password: string) {
  // Check if the password meets the criteria
  // eslint-disable-next-line
  const hasUpperCase = /[A-Z]/.test(password);
  const hasLowerCase = /[a-z]/.test(password);
  // eslint-disable-next-line
  const hasNumber = /\d/.test(password);
  const isLongEnough = password.length >= 8;

  return hasUpperCase && hasLowerCase && isLongEnough && hasNumber && passwordHasSpecialCharacters(password);
}

/**
 * Validates that password is of sufficient complexity.
 *
 * @param password Password to check.
 * @throws Error(INPUT_ERRORS.PASSWORD_NOT_COMPLEX) if password is not complex enough.
 * @see checkPasswordError
 */
export function validatePassword(password: string) {
  if (!checkPasswordError(password)) {
    throw new Error("Password Set is Not Strong");
  }
}

/**
 * Checks the category of input Error.
 *
 * @param errorCode Error Code.
 * @returns The category of errors if the code belongs to any, and null otherwise.
 */
export function getInputErrorCategory(errorCode: string) {
  for (let category of [
    USER_NAME_INPUT_ERRORS,
    NAME_INPUT_ERRORS,
    BIRTHDATE_INPUT_ERRORS,
    PASSWORD_INPUT_ERRORS,
  ]) {
    if (Object.keys(category).includes(errorCode)) {
      return category;
    }
  }
  return null;
}

/**
 * Checks if an error message is an InputError.
 *
 * @param msg Error message.
 * @returns True if error message is an InputError, false otherwise.
 */
export function isInputError(msg: string): msg is InputError {
  return Object.values<string>(INPUT_ERRORS).includes(msg);
}

/**
 * Formats the error message to feedback to the user in a user friendly way.
 *
 * @param errorMessage Error message.
 * @returns Formatted message for the user.
 */
export function formatInputError(errorMessage: InputError) {
  return ERROR_MESSAGES[errorMessage];
}
