import FiveStepHeader from "components/FiveStepHeader/FiveStepHeader";
import Header from "components/Header/Header";
import Topic from "components/QuestionForTopics/Topic";
import ContentWrapper from "components/utility/ContentWrapper";
import PageWrapper from "components/utility/PageWrapper";
import Steps from "components/utility/Steps";
import { useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
import classes from "./QuestionForTopics.module.css";
import QuestionTopicModal from "./QuestionTopicModal";

export default function QuestionForTopics() {
  const [myQuestion, setQuestion] = useState("");
  const currentStep = 4;
  const stepsCount = 5;
  const [showModal, setShowModal] = useState(false);
  let navigate = useNavigate();

  // eslint-disable-next-line react-hooks/exhaustive-deps
  const topics = [
    {
      question: "Clarify what you want",
    },
    {
      question: "Process what is happening",
    },
    {
      question: "Seek Possibilities",
    },
    {
      question: "Action and Accountability",
    },
    {
      question: "Surprise",
    },
  ];

  // const buttonStyle = {
  //   width: "124px",
  //   height: "46px",
  //   border: "5px solid #EB8181",
  //   boxSizing: "border-box",
  //   borderRadius: "40px",
  //   fontWeight: "700",
  //   fontSize: "1.1em",
  //   right: "500px",
  //   color: "var(--main-colour-2)",
  //   backgroundColor: "var(--main-colour)",
  // };

  const hideTermsHandler = () => {
    setShowModal(false);
  };

  const selectorHandler = (e) => {
    setQuestion(e.target.innerText);
    setShowModal(true);
  };

  const acceptHandler = () => {
    console.log(myQuestion);
    setShowModal(false);
    navigate("/all-circles/sharing-panel");
  };

  useEffect(() => {
    // console.log(topics)
  }, [topics]);

  return (
    <>
      <PageWrapper>
        {showModal && (
          <QuestionTopicModal
            topic={myQuestion}
            onClick={hideTermsHandler}
            acceptHandler={acceptHandler}
          />
        )}
        <div id="topic-modal" />
        <Header />
        <FiveStepHeader
          hasLightning={true}
          hasGuide={true}
          currentStep={currentStep}
        />
        <ContentWrapper hasFiveStepper={true}>
          {document && (
            <div className={classes["reflection-container"]}>
              <div className={classes["steps-container"]}>
                <Steps count={stepsCount} currentStep={currentStep} />
              </div>
              <div className={classes.description}>
                Choose what you want to do.
              </div>
              <div className={classes["topic-selection-container"]}>
                {topics.map((topic, indx) => (
                  <Topic
                    key={indx}
                    topic={topic.question}
                    isSelected={myQuestion === topic.question ? true : false}
                    onClick={selectorHandler}
                  />
                ))}
              </div>
            </div>
          )}
        </ContentWrapper>
      </PageWrapper>
    </>
  );
}
