import AutoResizeTextArea from "components/utility/Inputs/AutoResizeTextArea";
import { SelfAwarenessToolInterface } from "interface/SelfAwarenessToolInterface";
import { colours } from "../components/styles/colourMap";

interface TextAreaProps {
  answerHandler: (value: string) => void;
  answer: string;
  journal: SelfAwarenessToolInterface;
  maxLength: number;
  row?: number;
}

const TextArea = ({
  answer,
  answerHandler,
  journal,
  maxLength,
  row,
}: TextAreaProps) => {
  const handleInputChange = (value: string) => {
    // Ensure the input doesn't exceed the maximum length
    if (value.length <= maxLength) {
      answerHandler(value);
    }
  };

  return (
    <>
      {journal && journal.pageType && (
        <div
          className="border-2 my-8 bg-white rounded-lg font-poppins  "
          style={{
            borderColor: colours[journal.pageType]["border"],
            color: "var(--text-colour)",
          }}
        >
          <AutoResizeTextArea
            style={{ resize: "none" }}
            row={row || 10}
            className="border-none w-full rounded-lg placeholder:text-gray-300"
            onChange={(value) => handleInputChange(value)}
            value={answer}
            placeholder="Write here max length 500..."
            maxLength={500}
          />
          <div
            className="p-2"
            style={{ textAlign: "right", fontSize: "0.9rem", color: "gray" }}
          >
            {answer.length}/{maxLength}
          </div>
        </div>
      )}
    </>
  );
};

export default TextArea;
