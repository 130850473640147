import useOrganisationContext from "./organisation/useOrganisationContext";
import { useCallback, useEffect, useState } from "react";
import { projectFirestore, timestamp } from "../firebase/config";
import { QuestionInterface } from "interface/QuestionsInterface";
import { DocumentData, WhereFilterOp } from "firebase/firestore";
import OrganisationTopics from "interface/OrganisationTopicsInterface";
import { QuestionType } from "components/Organisation/QuestionSettings/QuestionSettings";

export const useOrganisationQuestion = () => {
  const { selectedOrganisation } = useOrganisationContext();
  const [questions, setQuestions] = useState<
    Record<string, QuestionInterface[]> | undefined
  >();

  const [categoryQuestions, setCategoryQuestions] = useState<
    Record<string, QuestionInterface[]> | undefined
  >();

  const [personalQuestions, setPersonalQuestions] = useState<
    Record<string, QuestionInterface[]> | undefined
  >();

  const [generalQuestions, setGeneralQuestions] = useState<
    Record<string, QuestionInterface[]> | undefined
  >();

  const [compulsoryQuestions, setCompulsoryQuestions] = useState<
    Record<string, QuestionInterface[]> | undefined
  >();

  const [formattedCompulsoryQuestions, setFormattedCompulsoryQuestions] =
    useState<string[]>([]);

  /**
   * Adds a new question to the specified organisation.
   *
   * @param question - The question to be added.
   * @param topic - The topic of the question.
   * @param topicCategoryId - The ID of the topic category.
   * @param category - The category of the question.
   * @returns A promise that resolves when the question is successfully added.
   */
  const AddNewQuestion = useCallback(
    async (
      question: string,
      topic: string | null,
      topicCategoryId: string,
      category: string
    ) => {
      try {
        const trimmedQuestion = question.trim();

        const data = {
          createdAt: timestamp.fromDate(new Date()),
          question: trimmedQuestion,
          organisationId: selectedOrganisation?.id,
          topic,
          category,
          topicCategoryId,
        };
        const ref = await projectFirestore
          .collection("organisations")
          .doc(selectedOrganisation?.id)
          .collection("questions")
          .add(data);
        await ref.update({ id: ref.id });
      } catch (error) {
        console.error(error);
      }
    },
    [selectedOrganisation]
  );

  /**
   * Copies new questions to the specified organisation.
   *
   * @param questionsToCopy - An array of questions to copy.
   * @param topic - The topic of the questions.
   * @param topicCategoryId - The ID of the topic category.
   * @param category - The category of the questions.
   * @param organisationId - The ID of the organisation.
   */
  const copyNewQuestions = useCallback(
    async (
      questionsToCopy: string[],
      topic: string,
      topicCategoryId: string,
      category: string,
      organisationId: string
    ) => {
      const promises = questionsToCopy.map(async (question) => {
        try {
          const data = {
            createdAt: timestamp.fromDate(new Date()),
            question: question,
            organisationId: organisationId,
            topic,
            category,
            topicCategoryId,
          };
          const ref = await projectFirestore
            .collection("organisations")
            .doc(organisationId)
            .collection("questions")
            .add(data);
          await ref.update({ id: ref.id });
        } catch (error) {
          console.error(error);
        }
      });

      await Promise.all(promises);
    },
    []
  );

  /**
   * Processes the copy of topics and questions to a new organisation.
   *
   * @param topics - The array of topics to be copied.
   * @param currentTopics - The array of current topics in the new organisation.
   * @param questions - The array of questions to be copied.
   * @param newOrganisationId - The ID of the new organisation.
   * @param selectedQuestion - The type of selected question.
   */
  const processCopyTopicsAndQuestions = useCallback(
    async (
      topics,
      currentTopics,
      questions,
      newOrganisationId,
      selectedQuestion
    ) => {
      if (selectedQuestion === QuestionType.SPECIFIC) {
        const topicsMap = {};

        // map category to the current topic
        for (const topic of currentTopics) {
          topicsMap[topic.category.toLowerCase()] = topic;
        }

        topics.map(async (topic) => {
          // If current organisation already has the topic category, then we need to do a union
          // of the general topics
          const general = topicsMap[topic.category.toLowerCase()]
            ? [
                ...new Set([
                  ...topic.general,
                  ...topicsMap[topic.category.toLowerCase()].general,
                ]),
              ]
            : topic.general;

          const updatedObject = {
            ...topic,
            general,
            createdAt: timestamp.fromDate(new Date()),
            organisationId: newOrganisationId,
            hasInfo: false,
          };
          try {
            await projectFirestore
              .collection("organisations")
              .doc(newOrganisationId)
              .collection("topics")
              .doc(topic.id)
              .set(updatedObject);
          } catch (error) {
            console.error(error);
          }
        });

        questions.map(async (question) => {
          const updatedObject = {
            ...question,
            createdAt: timestamp.fromDate(new Date()),
            organisationId: newOrganisationId,
          };

          try {
            const ref = await projectFirestore
              .collection("organisations")
              .doc(newOrganisationId)
              .collection("questions")
              .add(updatedObject);
            ref.update({ id: ref.id });
          } catch (error) {
            console.error(error);
          }
        });
      } else {
        questions.map(async (question) => {
          const updatedObject = {
            ...question,
            createdAt: timestamp.fromDate(new Date()),
            organisationId: newOrganisationId,
          };

          try {
            const ref = await projectFirestore
              .collection("organisations")
              .doc(newOrganisationId)
              .collection("questions")
              .add(updatedObject);
            ref.update({ id: ref.id });
          } catch (error) {
            console.error(error);
          }
        });
      }
    },
    []
  );

  const DeleteQuestion = useCallback(
    async (questionId: string) => {
      try {
        await projectFirestore
          .collection("organisations")
          .doc(selectedOrganisation?.id)
          .collection("questions")
          .doc(questionId)
          .delete();
      } catch (error) {
        console.error(error);
      }
    },
    [selectedOrganisation]
  );

  const updateQuestion = useCallback(
    async (questionId: string, question: string) => {
      try {
        projectFirestore
          .collection("organisations")
          .doc(selectedOrganisation?.id)
          .collection("questions")
          .doc(questionId)
          .update({ question });
      } catch (error) {
        console.error(error);
      }
    },
    [selectedOrganisation]
  );

  const getAllQuestionByTopic = useCallback(
    async (topic: string) => {
      const querySnapshot = await projectFirestore
        .collection("organisations")
        .doc(selectedOrganisation?.id)
        .collection("questions")
        .where("topic", "==", topic)
        .orderBy("createdAt", "desc")
        .get();
      const documents: DocumentData = [];

      if (!querySnapshot.empty) {
        querySnapshot.forEach((doc) => {
          // Convert Firestore document to a plain JavaScript object
          const data = doc.data();

          documents.push(data);
        });
      }

      return documents;
    },
    [selectedOrganisation]
  );

  /**
   * Gets the organisation using the its name.
   *
   * @param name Name of the organisation to retrieve.
   * @returns Organisation data.
   */
  const _getOrgWithOrgName = useCallback(async (name: string) => {
    const orgQuerySnapshot = await projectFirestore
      .collection("organisations")
      .where("name", "==", name)
      .get();

    if (orgQuerySnapshot.empty) {
      // Organization not found
      return null;
    }

    // Assume there's only one document matching the name
    return orgQuerySnapshot.docs[0];
  }, []);

  const getAllQuestionByOrgName = useCallback(
    async (name: string) => {
      try {
        // Step 1: Get the organization document
        const orgDocument = await _getOrgWithOrgName(name);

        if (!orgDocument) {
          return [];
        }

        // Step 2: Get the questions from the subcollection
        const questionsQuerySnapshot = await projectFirestore
          .collection("organisations")
          .doc(orgDocument.id)
          .collection("questions")
          .get();

        const questions = questionsQuerySnapshot.docs.map((doc) => doc.data());

        return questions;
      } catch (error) {
        console.error("Error fetching questions:", error);
        throw new Error("Error fetching questions");
      }
    },
    [_getOrgWithOrgName]
  );

  /**
   * Gets all the organisation questions by organisatino ID.
   *
   * @param organisationId ID of the organisation to get the questions from.
   * @returns All of the organisation questions.
   */
  const getAllQuestionByOrgId = useCallback(async (organisationId: string) => {
    try {
      const questionsQuerySnapshot = await projectFirestore
        .collection("organisations")
        .doc(organisationId)
        .collection("questions")
        .get();

      return questionsQuerySnapshot.docs.map((doc) => doc.data());
    } catch (error) {
      console.error("Error fetching questions:", error);
      throw new Error("Error fetching questions");
    }
  }, []);

  const getPersonalQuestions = useCallback(async () => {
    const querySnapshot2 = await projectFirestore
      .collection("Pathways")
      .where("category", "==", "personal")
      .orderBy("createdAt", "desc")
      .get();

    const documents: DocumentData = [];
    querySnapshot2.forEach((doc) => {
      // Convert Firestore document to a plain JavaScript object

      const data = { question: doc.data().question.join(" ") };
      documents.push(data);
    });
    return documents;
  }, []);

  const getDefaultPersonalQuestions = useCallback(async (path: string) => {
    const querySnapshot2 = await projectFirestore
      .collection("Pathways")
      .where("path", "==", path)
      .where("category", "==", "personal")
      .orderBy("createdAt", "desc")
      .get();

    const documents: DocumentData = [];
    querySnapshot2.forEach((doc) => {
      // Convert Firestore document to a plain JavaScript object

      const data = { question: doc.data().question.join(" ") };
      documents.push(data);
    });
    return documents;
  }, []);

  const getDefaultGeneralQuestions = useCallback(async (path: string) => {
    const querySnapshot2 = await projectFirestore
      .collection("Pathways")
      .where("path", "==", path)
      .where("category", "==", "general")
      .orderBy("createdAt", "desc")
      .get();

    const documents: DocumentData = [];
    querySnapshot2.forEach((doc) => {
      // Convert Firestore document to a plain JavaScript object

      const data = { question: doc.data().question.join(" ") };
      documents.push(data);
    });
    return documents;
  }, []);

  const getParentTopicCategoryByTopic = useCallback(
    async (topic: string) => {
      const querySnapshot = await projectFirestore
        .collection("organisations")
        .doc(selectedOrganisation?.id)
        .collection("questions")
        .where("topic", "==", topic)
        .get();

      let topicCategory: string | undefined = "";
      querySnapshot.forEach((doc) => {
        // Convert Firestore document to a plain JavaScript object
        const data = doc.data() as QuestionInterface;
        topicCategory = data.topicCategoryId;
      });

      if (topicCategory) {
        const ref = await projectFirestore
          .collection("organisations")
          .doc(selectedOrganisation?.id)
          .collection("topics")
          .doc(topicCategory)
          .get();

        if (ref.exists) {
          // Convert Firestore document to a plain JavaScript object
          const data = ref.data() as OrganisationTopics;
          const topicCategoryName = data.category;
          return topicCategoryName;
        } else {
          // Handle the case where the document doesn't exist
          return null;
        }
      } else {
        // Handle the case where topicCategory is not defined
        return null;
      }
    },
    [selectedOrganisation]
  );

  const organisationQuestionsMemo = useCallback(() => {
    var _array: string[] = ["general", "personal", "compulsory"];
    var _condition: WhereFilterOp = "in";

    if (selectedOrganisation?.questionsType === "specific") {
      _condition = "not-in";
    }

    const unsubscribe = projectFirestore
      .collection("organisations")
      .doc(selectedOrganisation?.id)
      .collection("questions")
      .where("topic", _condition, _array)
      .orderBy("topic", "asc")
      .startAfter(null)
      .onSnapshot(async (snapshot) => {
        const topicsMap = {};

        snapshot.forEach((doc) => {
          const data = doc.data();
          const questionId = doc.id;
          const topic = data.topic;

          if (topic) {
            if (!topicsMap[topic]) {
              topicsMap[topic] = [];
            }
            topicsMap[topic].push({
              id: questionId,
              ...data,
            });
          }
        });

        // Now 'topicsMap' contains the desired structure with questions grouped by topics

        setQuestions(topicsMap);
        // If you need to set the grouped data in state, you can do so here.
        // For example, if you have a state variable 'groupedQuestions':
        // setGroupedQuestions(topicsMap);
      });

    return () => {
      unsubscribe();
    };
  }, [selectedOrganisation]);

  const organisationCategoryQuestionsMemo = useCallback(() => {
    const unsubscribe = projectFirestore
      .collection("organisations")
      .doc(selectedOrganisation?.id)
      .collection("questions")
      .where("topic", "==", null)
      .onSnapshot(async (snapshot) => {
        const categoryMap = {};

        snapshot.forEach((doc) => {
          const data = doc.data();
          const questionId = doc.id;
          const categoryId = data.topicCategoryId;

          if (categoryMap[categoryId]) {
            categoryMap[categoryId].push({
              id: questionId,
              ...data,
            });
          } else {
            categoryMap[categoryId] = [
              {
                id: questionId,
                ...data,
              },
            ];
          }
        });

        setCategoryQuestions(categoryMap);
      });

    return () => {
      unsubscribe();
    };
  }, [selectedOrganisation]);

  const organisationPersonalQuestionsMemo = useCallback(() => {
    const unsubscribe = projectFirestore
      .collection("organisations")
      .doc(selectedOrganisation?.id)
      .collection("questions")
      .where("topic", "==", "personal")
      .onSnapshot(async (snapshot) => {
        const topicsMap = {};

        snapshot.forEach((doc) => {
          const data = doc.data();
          const questionId = doc.id;
          const topic = data.topic;

          if (topic) {
            if (!topicsMap[topic]) {
              topicsMap[topic] = [];
            }
            topicsMap[topic].push({
              id: questionId,
              ...data,
            });
          }
        });

        // Now 'topicsMap' contains the desired structure with questions grouped by topics

        setPersonalQuestions(topicsMap);
        // If you need to set the grouped data in state, you can do so here.
        // For example, if you have a state variable 'groupedQuestions':
        // setGroupedQuestions(topicsMap);
      });

    return () => {
      unsubscribe();
    };
  }, [selectedOrganisation]);

  const organisationGeneralQuestionsMemo = useCallback(() => {
    const unsubscribe = projectFirestore
      .collection("organisations")
      .doc(selectedOrganisation?.id)
      .collection("questions")
      .where("topic", "==", "general")
      .onSnapshot(async (snapshot) => {
        const topicsMap = {};

        snapshot.forEach((doc) => {
          const data = doc.data();

          const questionId = doc.id;
          const topic = data.topic;

          if (topic) {
            if (!topicsMap[topic]) {
              topicsMap[topic] = [];
            }
            topicsMap[topic].push({
              id: questionId,
              ...data,
            });
          }
        });

        // Now 'topicsMap' contains the desired structure with questions grouped by topics

        setGeneralQuestions(topicsMap);
        // If you need to set the grouped data in state, you can do so here.
        // For example, if you have a state variable 'groupedQuestions':
        // setGroupedQuestions(topicsMap);
      });

    return () => {
      unsubscribe();
    };
  }, [selectedOrganisation]);

  const organisationCompulsoryQuestionsMemo = useCallback(() => {
    const unsubscribe = projectFirestore
      .collection("organisations")
      .doc(selectedOrganisation?.id)
      .collection("questions")
      .where("topic", "==", "compulsory")
      .onSnapshot(async (snapshot) => {
        const topicsMap = {};

        snapshot.forEach((doc) => {
          const data = doc.data();

          const questionId = doc.id;
          const topic = data.topic;

          if (topic) {
            if (!topicsMap[topic]) {
              topicsMap[topic] = [];
            }
            topicsMap[topic].push({
              id: questionId,
              ...data,
            });
          }
        });

        // Now 'topicsMap' contains the desired structure with questions grouped by topics

        setCompulsoryQuestions(topicsMap);
        // If you need to set the grouped data in state, you can do so here.
        // For example, if you have a state variable 'groupedQuestions':
        // setGroupedQuestions(topicsMap);
      });

    return () => {
      unsubscribe();
    };
  }, [selectedOrganisation]);

  const fetchCompulsoryQuestions = useCallback(async () => {
    if (
      selectedOrganisation?.questionsType === "specific" ||
      selectedOrganisation?.questionsType === "random"
    ) {
      if (compulsoryQuestions) {
        try {
          const data2 = compulsoryQuestions["compulsory"];
          if (data2 && data2.length > 0) {
            // Map the data to an array of strings

            const _compulsoryQuestion = data2.map(
              (item: QuestionInterface) => item.question
            );

            setFormattedCompulsoryQuestions(_compulsoryQuestion);
          }
        } catch (error) {
          console.error("Error fetching questions:", error);
        }
      }
    }
  }, [selectedOrganisation, compulsoryQuestions]);

  const updateOrganisationQuestionSettings = useCallback(
    async (settingCheck: Object) => {
      try {
        projectFirestore
          .collection("organisations")
          .doc(selectedOrganisation?.id)
          .update(settingCheck);
      } catch (error) {
        console.error(error);
      }
    },
    [selectedOrganisation]
  );

  useEffect(() => {
    organisationQuestionsMemo();
    organisationCategoryQuestionsMemo();
    organisationPersonalQuestionsMemo();
    organisationGeneralQuestionsMemo();
    organisationCompulsoryQuestionsMemo();
    fetchCompulsoryQuestions();

    // eslint-disable-next-line
  }, [selectedOrganisation]);

  return {
    personalQuestions,
    categoryQuestions,
    generalQuestions,
    compulsoryQuestions,
    formattedCompulsoryQuestions,
    questions,
    AddNewQuestion,
    copyNewQuestions,
    processCopyTopicsAndQuestions,
    DeleteQuestion,
    updateQuestion,
    getAllQuestionByTopic,
    getParentTopicCategoryByTopic,
    getPersonalQuestions,
    getDefaultGeneralQuestions,
    getDefaultPersonalQuestions,
    getAllQuestionByOrgName,
    getAllQuestionByOrgId,
    updateOrganisationQuestionSettings,
  };
};
