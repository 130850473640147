import { FC, MouseEvent } from "react";
import RandomQuestion from "./components/RandomQuestion";
import PriorityListing from "./components/PriorityListing";
import ReflectionSubmit from "./components/ReflectionSubmit";
import SupportNeed from "./components/SupportNeed";
import RandomQuotation from "./components/RandomQuotation";
import TopSelection from "./components/TopSelection";
import SelectionGrading from "./components/SelectionGrading";
import GradingRanking from "./components/GradingRanking";
import GradingRankingNeeds from "./components/GradingRankingNeeds";
import ListSelector from "./components/ListSelector";
import TopList from "./components/TopList";
import ListSelectorReflection from "./components/ListSelectorReflection";
import BasicQuestion from "./components/BasicQuestion";
import TopListWithReflection from "./components/TopListWithReflection";
import ChallengeTasks from "./components/ChallengeTasks";
import SingleQuestion from "./components/SingleQuestion";
import PathSelector from "./components/PathSelector";
import WorstGrading from "./components/WorstGrading";
import WorstRanking from "./components/WorstRanking";
import MultiplePathSelector from "./SubComponents/MultiplePathSelector";
import QuestionWithSub from "./components/QuestionWithSub";
import DissectKeySelection from "./components/DissectKeySelection";
import TopKeyList from "./components/TopKeyList";
import DissectKeyBreakdown from "./components/DissectKeyBreakdown";
import BreakDownSelection from "./components/BreakDownSelection";
import CauseReflection from "./components/CauseReflection";
import GradingQuestions from "./components/GradingQuestions";
import GradingItemReflection from "./components/GradingItemReflection";
import EndQuestion from "./components/EndQuestion";
import FocusQuestions from "./components/FocusQuestions";
import FocusFrameQuestions from "./components/FocusFrameQuestions";
import FocusValueQuestions from "./components/FocusValueQuestions ";
import FocusBlindSpot from "./components/FocusBlindSpot";
import DecisionComp from "./components/DecisionComp";
import DecisionSelection from "./components/DecisionSelection";
import DropDownSelections from "./components/DropDownSelections";

export interface BackAndNextProps {
  backCallback: (e: MouseEvent<HTMLButtonElement>) => any;
  nextCallback: (e: MouseEvent<HTMLButtonElement>) => any;
  backFailCallback?: (e: MouseEvent<HTMLButtonElement>) => any;
  nextFailCallback?: (e: MouseEvent<HTMLButtonElement>) => any;
  backAllowed: boolean | null | undefined;
  nextAllowed: boolean | null | undefined;
  nextChangeColour?: boolean;
  backHidden?: boolean | null | undefined;
  isFixed?: boolean | null | undefined;
  customNextText?: string;
  useAnimation?: boolean;
}

export interface ComponentProps {
  setHasModal: (b: boolean) => any; // To hide background when component has a modal opened.
  backAndNextHandler: BackAndNextProps;
}

export interface HelperProps {
  setHasModal: (b: boolean) => void;
}

export interface Component {
  Main: FC<ComponentProps>; // The main component to display for that page.
}

export interface ComponentMapping {
  [key: string]: Component;
}

export const SelfAwarenessComponentMapping: ComponentMapping = {
  randomQuestion: {
    Main: RandomQuestion,
  },
  dropDownSelection: {
    Main: DropDownSelections,
  },
  priorityListing: {
    Main: PriorityListing,
  },
  reflectionSubmit: {
    Main: ReflectionSubmit,
  },
  supportNeeded: {
    Main: SupportNeed,
  },
  randomQuotations: {
    Main: RandomQuotation,
  },
  topSelections: {
    Main: TopSelection,
  },
  gradingSelection: {
    Main: SelectionGrading,
  },
  gradingRanking: {
    Main: GradingRanking,
  },
  gradingRankingNeeds: {
    Main: GradingRankingNeeds,
  },
  listSelector: {
    Main: ListSelector,
  },
  topList: {
    Main: TopList,
  },
  listSelectorReflection: {
    Main: ListSelectorReflection,
  },
  basicQuestion: {
    Main: BasicQuestion,
  },
  topListWithReflection: {
    Main: TopListWithReflection,
  },
  challengeTasks: {
    Main: ChallengeTasks,
  },
  singleQuestion: {
    Main: SingleQuestion,
  },
  pathSelector: {
    Main: PathSelector,
  },
  worstGrading: {
    Main: WorstGrading,
  },
  worstRanking: {
    Main: WorstRanking,
  },
  multiplePathSelector: {
    Main: MultiplePathSelector,
  },
  questionWithSub: {
    Main: QuestionWithSub,
  },
  dissectKeySelection: {
    Main: DissectKeySelection,
  },
  topKeyList: {
    Main: TopKeyList,
  },
  dissectKeyBreakdown: {
    Main: DissectKeyBreakdown,
  },
  breakDownSelection: {
    Main: BreakDownSelection,
  },
  causeReflection: {
    Main: CauseReflection,
  },
  gradingQuestions: {
    Main: GradingQuestions,
  },
  gradingItemReflection: {
    Main: GradingItemReflection,
  },
  endQuestion: {
    Main: EndQuestion,
  },
  focusQuestions: {
    Main: FocusQuestions,
  },
  focusFrameQuestions: {
    Main: FocusFrameQuestions,
  },
  focusValueQuestions: {
    Main: FocusValueQuestions,
  },
  focusBlindSpot: {
    Main: FocusBlindSpot,
  },
  decisionComp: {
    Main: DecisionComp,
  },
  decisionSelection: {
    Main: DecisionSelection,
  },
};
