/**
 * Customisable organisations for Growthbeans, SUSS, NG etc.
 */
import firebase from "firebase/compat/app";

export const DEFAULT_ADMINLIMIT = 1;
export const DEFAULT_USERLIMIT = 100;

export interface OrganisationDropdownItem {
  field_type: string;
  label: string;
  options: string[];
}

interface Block {
  key: string;
  text: string;
  type: string;
  depth: number;
  inlineStyleRanges: {
    offset: number;
    length: number;
    style: string;
  }[];
  entityRanges: {
    key: string;
    offset: number;
    length: number;
  }[];
  data: {};
}

export interface CustomTextEditorBlock {
  blocks: Block[]; 
  entityMap: { [key: string]: any }; 
}

export const defaultDataConsentContent = {
  "blocks": [
    {
      "key": "1fcpe",
      "text": "Growthbeans requires your consent to disclose the personal information you provide in the reflection to your course instructors.",
      "type": "unstyled",
      "depth": 0,
      "inlineStyleRanges": [],
      "entityRanges": [],
      "data": {
        "text-align": "left"
      }
    }
  ],
  "entityMap": {
  }
}

interface Block {
  key: string;
  text: string;
  type: string;
  depth: number;
  inlineStyleRanges: {
    offset: number;
    length: number;
    style: string;
  }[];
  entityRanges: {
    key: string;
    offset: number;
    length: number;
  }[];
  data: {};
}

export interface CustomTextEditorBlock {
  blocks: Block[];
  entityMap: { [key: string]: any };
}

export interface ExportConfig {
  date: boolean;
  time: boolean;
  email: boolean;
  name: boolean;
  sessionRole: boolean;
  intentions: boolean;
  randomIntentions: boolean;
  coreRole: boolean;
  role: boolean;
  roleDescription: boolean;
  topic: boolean;
  path: boolean;
  tutorialGroup: boolean;
  checkInIndividually: boolean;
  checkInInterpersonally: boolean;
  checkInSocially: boolean;
  checkInOverall: boolean;
  checkOutIndividually: boolean;
  checkOutInterpersonally: boolean;
  checkOutSocially: boolean;
  checkOutOverall: boolean;
  journalStatus: boolean;
  orsCheckIn: boolean;
  orsCheckOut: boolean;
  feedbackQuestions: boolean;
}

export default interface Organisation {
  name: string;
  id: string;
  users: { [id: string]: string }; // maps user id to their role
  invites: { [email: string]: string };
  components: string[];
  defaultComponents?: string[];
  inheritGeneralTopics?: boolean;
  topicHasInfoIcon?: boolean;
  randomizeIntentions?: boolean;
  tagLine?: string;
  showTagLineHeader?: boolean;
  showTagLineLogo?: boolean;
  hasUniqueFields?: boolean;
  uniqueFields?: OrganisationDropdownItem[];
  questionsType?: string;
  hasCustomHeaderText?: boolean;
  customHeaderText?: string;
  isAType?: boolean;
  remarks?: string;
  shortDescription?: string;
  objectives?: string[];
  whoForThis?: string[];
  tags?: string | null;
  multiTag?: string[] | null;
  subTags?: string[];
  targetAudience?: undefined;
  createdAt?: firebase.firestore.Timestamp;
  savedDefaultAt?: firebase.firestore.Timestamp;
  addedBy?: string;
  questionCopied?: boolean;
  subscriptionType?: string;
  dateExpire?: string;
  adminLimit?: number;
  userLimit?: number;
  currAdminCount?: number;
  activityTitle?: string;
  activityInstruction?: string;
  sessionCustomTopic?: boolean;
  noPersonalQuestion?: boolean;
  noFollowUpQuestion?: boolean;
  iceBreakerType?: string;
  welcomeType?: string;
  welcomeCustomType?: string;
  customORSTitle?: string;
  enableFlagRating: boolean;
  setMaxRating: boolean;
  maxRating: number;
  flagRatingPercentage: number;
  logo?: string | null;
  footerLogo?: string | null;
  footerSettings?: string | null;
  exportConfig?: ExportConfig;
  isFooterMain?: boolean;
  enableCustomDataConsent: boolean;
  customDataConsent: CustomTextEditorBlock;
  dataConsentValidityPeriod: number;
  enableCustomEndGrowthCircles: boolean;
  customEndGrowthcircles?: CustomTextEditorBlock;
}

export const defaultOrganisation: Organisation = {
  name: "Growthbeans",
  id: "",
  users: {},
  invites: {},
  components: [],
  adminLimit: 1,
  userLimit: 100,
  enableFlagRating: true,
  setMaxRating: false,
  maxRating: 10,
  flagRatingPercentage: 60,
  enableCustomEndGrowthCircles: false,
  enableCustomDataConsent: false,
  customDataConsent: defaultDataConsentContent,
  dataConsentValidityPeriod: 1,
};

export const defaultOrganisationModel: Organisation = {
  name: "",
  id: "",
  users: {},
  invites: {},
  components: [],
  adminLimit: 1,
  userLimit: 100,
  enableFlagRating: true,
  setMaxRating: false,
  maxRating: 10,
  flagRatingPercentage: 60,
  enableCustomEndGrowthCircles: false,
  enableCustomDataConsent: false,
  customDataConsent: defaultDataConsentContent,
  dataConsentValidityPeriod: 1
};
