import Header from "components/Header/Header";
import GrowthCirclesTypeAdminCard from "components/Organisation/GrowthCirclesTypeSettings/GrowthCirclesTypeAdminCard";

import PageWrapper from "components/utility/PageWrapper";
import { useGrowthCirclesType } from "hooks/useGrowthCirlesType";
import Organisation from "interface/OrganisationInterface";
import { useEffect, useState } from "react";

const MySubscriptions = () => {
  const { growthcircletypes } = useGrowthCirclesType();

  const [data, setData] = useState<Organisation[]>([]);
  const [hasModal, setHasModal] = useState(false);
  const StoredData = () => {
    setData(growthcircletypes);
  };

  const toggleModal = () => {
    if (!hasModal) {
      setHasModal(true);
    } else {
      setHasModal(false);
    }
  };

  useEffect(() => {
    if (!growthcircletypes) return;
    StoredData();

    // eslint-disable-next-line
  }, [growthcircletypes]);
  return (
    <PageWrapper>
      <Header title="My Subscriptions" hasModal={hasModal} />

      <div className="mb-[100px]">
        <div className="mt-[15%] md:mt-[6%] gap-4 p-5 grid grid-cols-1 md:grid-cols-2 xl:grid-cols-4 ">
          {data.map((circle, index) => (
            <GrowthCirclesTypeAdminCard
              hasModal={hasModal}
              setModal={toggleModal}
              key={index}
              data={circle}
              start={true}
            />
          ))}
          {data.length === 0 && (
            <p style={{ color: "var(--text-colour)" }}>No Data found.</p>
          )}
        </div>
      </div>
    </PageWrapper>
  );
};

export default MySubscriptions;
