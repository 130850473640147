import classes from "./OtherUsersRow.module.css";
import { Participant } from "interface/ParticipantInterface";
import React, { useEffect } from "react";
import { BadgeType } from "interface/FeedbackFormInterface";
import { renderBadgeIcon } from "components/ReflectionRating/Badges";
import {
  RateActionEnum,
  RateState,
  ToggleRateAction,
} from "hooks/useRateOtherUsers";
import RoleBadgeInfo from "./RoleBadgeInfo";

interface OtherUsersRowProps {
  participant: Participant;
  userRatings: RateState;
  handleBadgeClick: React.Dispatch<ToggleRateAction>;
}

/**
 * Component that generates a row at the end of ReflectionRating for the user to choose badges that they want them to have
 * @param {Participant} participant - participant of other user
 * @param {RateState} userRatings - Rate state of that other user (Role: 0/1, CareAndSupport: 0/1, Effort: 0/1)
 * @param {React.Dispatch<ToggleRateAction>} handleBadgeClick - method to toggle badge click
 */
const OtherUsersRow: React.FC<OtherUsersRowProps> = ({
  participant,
  userRatings,
  handleBadgeClick,
}) => {
  const colour = "var(--main-colour)";

  useEffect(() => {}, [userRatings]);

  return (
    <>
      <div className={classes["other-users-row"]}>
        <div className={classes["name-and-badge"]}>
          <p className={classes["name"]}>{participant.userName}</p>
          <div className={classes["badge-and-role"]}>
            {/* TODO: this was is causing a blank screen */}
            {participant.role && (
              <>
                {<RoleBadgeInfo role={participant.role.core_skills} />}{" "}
                <div>
                  <span>{participant.role.core_skills} </span>
                  <p>{participant.role.role} </p>
                </div>
              </>
            )}
          </div>
        </div>

        {/* logos */}
        {Object.keys(BadgeType).map((badge, index) => {
          return (
            <div
              key={index}
              className={`${classes["badge-icon"]} ${
                index !== Object.keys(BadgeType).length - 1
                  ? classes["vertical-line"]
                  : ""
              }`}
            >
              <button
                type="button"
                onClick={() =>
                  handleBadgeClick({
                    type: RateActionEnum.TOGGLE,
                    payload: {
                      id: participant.userId,
                      badge: BadgeType[badge],
                    },
                  })
                }
              >
                <div
                  className={`${classes["circle"]} ${
                    userRatings[participant.userId][BadgeType[badge]]
                      ? ""
                      : classes["no-circle"]
                  }`}
                ></div>
                {renderBadgeIcon(
                  userRatings[participant.userId][BadgeType[badge]]
                    ? "var(--main-colour)"
                    : colour,
                  BadgeType[badge]
                )}
              </button>
            </div>
          );
        })}
      </div>
    </>
  );
};

export default OtherUsersRow;
