import { useTour } from "@reactour/tour";
import EmptySpace from "components/utility/EmptySpace";
import InputField from "components/utility/Forms/InputField";
import AutoResizeTextArea from "components/utility/Inputs/AutoResizeTextArea";
import { DocumentData } from "firebase/firestore";
import { Tooltip } from "flowbite-react";
import { useOrgFeedbackQuestions } from "hooks/organisation/useOrgFeedbackQuestions";
import useOrganisationContext from "hooks/organisation/useOrganisationContext";
import usePromise from "hooks/utility/usePromise";
import { FeedbackLayout } from "interface/FeedbackLayoutInterface";
import classes from "pages/AllCircles/Admin/Admin.module.css";
import { ChangeEvent, FormEvent, useEffect, useState } from "react";
import toast from "react-hot-toast";
import FeedbackQuestions from "./FeedbackQuestions";
import { v4 as uuidv4 } from "uuid";

interface FeedbackSettingsProps {
  feedbackLayout: DocumentData[];
}

const FeedbackSettings = ({ feedbackLayout }: FeedbackSettingsProps) => {
  const { setIsOpen: setIsTourOpen } = useTour();
  const { selectedOrganisation } = useOrganisationContext();
  const [feedbackName, setFeedbackName] = useState<string>("");
  const [maxRating, setMaxRating] = useState<number | undefined>(5);
  const { isLoading, resolve } = usePromise();
  const { AddNewFeedbackSection, AddNewQuestionFeedback } =
    useOrgFeedbackQuestions();
  const [questionSection, setQuestionSection] = useState<string>("");
  const [feedbackKeys, setFeedbackKeys] = useState<string[]>([]);
  const [id, setId] = useState<string>("");
  const [questionName, setQuestionName] = useState<string>("");
  const [questionContent, setQuestionContent] = useState<string>("");
  const [questionType, setQuestionType] = useState<string>("text");
  const feedbackNameSubmitHandler = async (e: FormEvent) => {
    e.preventDefault();
    resolve(() => AddNewFeedbackSection(feedbackName)).then(() => {
      toast.success("New feedback section was successfully added");
      setFeedbackName("");
    });
  };

  const setQuestionSectionHandler = async (title: string) => {
    setQuestionSection(title);
    const _filterId = feedbackLayout.find(
      (doc) => doc.title === title
    ) as FeedbackLayout;

    setId(_filterId.id);
  };

  const AddNewQuestionHandler = async (e: FormEvent) => {
    e.preventDefault();
    const _id = id ?? feedbackLayout[0].id;
    const _type = questionType ?? "text";
    if (questionName !== "") {
      try {
        resolve(() =>
          AddNewQuestionFeedback(
            _id,
            questionName,
            _type,
            questionContent,
            maxRating
          )
        ).then(() => {
          setId(_id);
          setQuestionName(questionName);
          setQuestionType(_type);
          setQuestionContent("");
        });
      } catch (error) {
        toast.error(`An error occurred: ${error}`);
      }
    } else {
      toast.error("Question ID is required.");
    }
  };

  const setMaxRatingHandler = (e: ChangeEvent<HTMLInputElement>) => {
    const value = e.target.value;
    const numericValue = Number(value);

    if (value === "") {
      setMaxRating(undefined); // Or set to a default value if you prefer
      toast.error("Value must be a number.");
    } else if (!isNaN(numericValue) && numericValue > 4 && numericValue < 9) {
      setMaxRating(numericValue);
    } else {
      toast.error("Value must be a number between 5 and 8.");
    }
    const uuid = uuidv4().substring(0, 4);
    setQuestionName(questionType + uuid);
  };

  const setSliderMaxRatingHandler = (e: ChangeEvent<HTMLInputElement>) => {
    const value = e.target.value;
    const numericValue = Number(value);

    if (value === "") {
      setMaxRating(undefined); // Or set to a default value if you prefer
      toast.error("Value must be a number.");
    } else if (!isNaN(numericValue) && numericValue > 0 && numericValue < 11) {
      setMaxRating(numericValue);
    } else {
      toast.error("Value must be a number between 1 and 10.");
    }

    const uuid = uuidv4().substring(0, 4);
    setQuestionName(questionType + uuid);
  };

  const QuestionTypeHandler = async (e: ChangeEvent<HTMLSelectElement>) => {
    try {
      setQuestionType(e.target.value);
      const uuid = uuidv4().substring(0, 4);
      setQuestionName(e.target.value + uuid);
    } catch (error) {
      console.error(error);
    }
  };

  useEffect(() => {
    if (!feedbackLayout) return;
    const titles = feedbackLayout.map((doc) => doc.title); //
    setFeedbackKeys(titles);
    if (feedbackLayout.length > 0) {
      setId(feedbackLayout[0].id);
    }

    // eslint-disable-next-line
  }, [feedbackLayout]);

  useEffect(() => {
    // eslint-disable-next-line
  }, [questionSection, id, questionType]);

  return (
    <div className="mb-[100px]">
      {selectedOrganisation && (
        <>
          <EmptySpace />
          <button
            className={`${classes["button"]}`}
            onClick={() => setIsTourOpen(true)}
          >
            Show Tutorial
          </button>
          <EmptySpace />
          <div className="flex items-center gap-4">
            <p className="text-xl my-4 text-slate-600">
              Feedback Questions for{" "}
              <strong>{selectedOrganisation.name}</strong>
            </p>
          </div>
          <p className="mb-8 justify-start items-center gap-2 text-slate-500 text-lg">
            Specify a feedback section name: You should provide a name for each
            feedback section, and each section you create will result in a new
            tab. It's important to make sure that each feedback section has a
            unique name.
          </p>
          <ul className="text-slate-600 px-4 list-decimal">
            <li>
              <strong>Choose Feedback Category:</strong> When adding a new
              question, you need to select a section where you want to place the
              question. This helps organize your questions into different
              categories or tabs.
            </li>
            <li>
              <strong>Question ID: </strong>This represents the unique
              identifier for your question in the database. It's crucial to
              ensure that each question has a distinct ID because it will be
              used when extracting data as a CSV file or displaying it as a data
              point on your dashboard.
            </li>
            <li>
              <strong>Question Type:</strong> You can choose from a list of
              question types, and each type corresponds to a specific input
              form. Select the one that best fits your needs.
            </li>
            <li>
              <strong>Question Label:</strong> This is the text that will appear
              alongside your input question. It serves as a description or
              prompt for the question to help users understand what to input.
            </li>
          </ul>
          <form
            feedback-questions-settings-tour="add-section"
            onSubmit={feedbackNameSubmitHandler}
            className="my-8 flex items-center gap-2"
          >
            <InputField
              text="Feedback Section Name"
              value={feedbackName}
              className="w-[300px]"
              onChange={(e: React.ChangeEvent<HTMLInputElement>) =>
                setFeedbackName(e.target.value)
              }
              isRequired={true}
            />
            <button disabled={isLoading} type="submit">
              {isLoading ? "Submitting..." : "Add Section"}
            </button>
          </form>
          <hr />
          <div className="flex">
            <form onSubmit={AddNewQuestionHandler}>
              <div
                feedback-questions-settings-tour="select-category"
                className="py-2"
              >
                <InputField
                  required={true}
                  label="Choose Feedback Category"
                  name={"selected feedback"}
                  type="select"
                  htmlFor={"topics"}
                  onChange={(e: ChangeEvent<HTMLSelectElement>) =>
                    setQuestionSectionHandler(e.target.value)
                  }
                  options={feedbackKeys}
                />
              </div>

              <div
                feedback-questions-settings-tour="select-question-type"
                className="py-2"
              >
                <InputField
                  required={true}
                  label="Question Type"
                  name={"selected feedback"}
                  type="select"
                  htmlFor={"topics"}
                  onChange={(e: ChangeEvent<HTMLSelectElement>) =>
                    QuestionTypeHandler(e)
                  }
                  options={[
                    "text",
                    "textArea",
                    "smileyRating",
                    "smileyRatingWithMax",
                    "slider",
                    "sliderWithMax",
                    "badgeRating",
                    "multipleChoice",
                    "yesOrNo",
                    "SpecialThanks",
                  ]}
                />
              </div>
              {questionType === "smileyRatingWithMax" && (
                <div className="py-2">
                  <label className="py-4">
                    Max Rating <small>(Max 8)</small>
                  </label>

                  <InputField
                    required={true}
                    value={maxRating}
                    type="number"
                    max={8}
                    min={5}
                    onChange={(e: ChangeEvent<HTMLInputElement>) =>
                      setMaxRatingHandler(e)
                    }
                    text="Specify Max Rating"
                  />
                </div>
              )}

              {questionType === "sliderWithMax" && (
                <div className="py-2">
                  <label className="py-4">
                    Max Rating <small>(Max 8)</small>
                  </label>

                  <InputField
                    required={true}
                    value={maxRating}
                    type="number"
                    max={10}
                    min={1}
                    onChange={(e: ChangeEvent<HTMLInputElement>) =>
                      setSliderMaxRatingHandler(e)
                    }
                    text="Specify Max Rating"
                  />
                </div>
              )}

              <div
                feedback-questions-settings-tour="question-id-input"
                className="py-4"
              >
                <div className="mx-2">
                  <Tooltip
                    className="capitalize"
                    datatype="html"
                    content={`Each question must have a unique ID`}
                  >
                    <label htmlFor="question_id mx-4">Question ID (?)</label>
                  </Tooltip>
                </div>
                <InputField
                  required={true}
                  value={questionName}
                  onChange={(e: ChangeEvent<HTMLInputElement>) =>
                    setQuestionName(e.target.value)
                  }
                  text="Specify Question Name"
                />
              </div>

              <div
                feedback-questions-settings-tour="question-label"
                className="py-2"
              >
                <label className="py-4">Question Label</label>
                <div className="p-2 bg-white rounded-lg shadow-md">
                  <AutoResizeTextArea
                    isRequired={true}
                    value={questionContent}
                    className="border-none bg-white rounded-sm "
                    onChange={(value) => setQuestionContent(value)}
                  />
                </div>
              </div>
              <button className="my-4" disabled={isLoading} type="submit">
                {isLoading ? "Submitting..." : "Add Question"}
              </button>
            </form>

            <FeedbackQuestions
              activeQuestionId={id}
              questions={feedbackLayout}
            />
          </div>
        </>
      )}
    </div>
  );
};

export default FeedbackSettings;
