import { createAvatar } from "@dicebear/core";
import { initials } from "@dicebear/collection";
import { useEffect, useState } from "react";
import ProfilePicture from "components/ProfilePicture/ProfilePicture";
import { Profile } from "interface/ProfileInterface";
import { setUserRole } from "models/organisation";
import DropDown from "components/utility/DropDown/DropDown";
import EmptySpace from "components/utility/EmptySpace";
import Organisation from "interface/OrganisationInterface";
import { Modal } from "flowbite-react";
import { AiFillCloseCircle } from "react-icons/ai";
import UserModalAdminInfo from "./UserModalAdminInfo";

interface Props {
  user: Profile;
  organisation?: Organisation;
  role?: string;
  roleArr?: string[];
  updateDocument: (id: string | undefined, updates: object) => Promise<void>;
}

interface dateDisplay {
  seconds: number;
  nanoseconds: number;
}

const UserItem = ({
  user,
  organisation,
  role,
  roleArr,
  updateDocument,
}: Props) => {
  const avatar = createAvatar(initials, {
    seed: user.displayName || user.email,
    backgroundColor: ["EB8181"],
  });

  const svg = avatar.toString();

  useEffect(() => {
    setIsPremium(user["is_premium"] ? user["is_premium"] : false);
  }, [user]);

  const [isPremium, setIsPremium] = useState(
    user["is_premium"] ? user["is_premium"] : false
  );
  const [view, setView] = useState(false);
  const [show, setShow] = useState(false);

  const handlePremiumClick = () => {
    setIsPremium(!isPremium);
    updateDocument(user.uid, { isPremium: !isPremium });
  };

  const displayRegisteredDate = (registeredDate: dateDisplay) => {
    if (registeredDate === undefined) {
      return "";
    }

    // Convert Firestore Timestamp to JavaScript Date
    const date = new Date(
      registeredDate.seconds * 1000 + registeredDate.nanoseconds / 1000000
    );

    // Format the date to a string
    const formattedDate: string = date.toLocaleDateString("en-US", {
      timeZone: "Asia/Manila",
      month: "long",
      day: "numeric",
      year: "numeric",
    });

    return formattedDate;
  };

  return (
    <>
      <Modal show={show} size={"7xl"}>
        <Modal.Body className="relative">
          <AiFillCloseCircle
            size={30}
            color="var(--icon-colour-0)"
            onClick={() => setShow(!show)}
            className="absolute top-2 right-2 cursor-pointer"
          />
          <div>
            <UserModalAdminInfo user={user} />
          </div>
        </Modal.Body>
      </Modal>
      <div className="w-full max-w-xs bg-white border border-gray-200 rounded-lg shadow dark:bg-gray-800 dark:border-gray-700">
        <div className="flex justify-end px-4 pt-4 relative">
          <button
            onClick={() => setView(!view)}
            id="dropdownButton"
            data-dropdown-toggle="dropdown"
            className=" text-gray-500 dark:text-gray-400 hover:bg-gray-100 dark:hover:bg-gray-700 focus:ring-4 focus:outline-none focus:ring-gray-200 dark:focus:ring-gray-700 rounded-lg text-sm p-1.5"
            type="button"
          >
            <span className="sr-only">Open dropdown</span>
            <svg
              className="w-6 h-6"
              aria-hidden="true"
              fill="currentColor"
              viewBox="0 0 20 20"
              xmlns="http://www.w3.org/2000/svg"
            >
              <path d="M6 10a2 2 0 11-4 0 2 2 0 014 0zM12 10a2 2 0 11-4 0 2 2 0 014 0zM16 12a2 2 0 100-4 2 2 0 000 4z"></path>
            </svg>
          </button>

          <div
            id="dropdown"
            className={`z-10 ${
              view ? "absolute" : "hidden"
            } top-[54px] right-0 text-base list-none border border-slate-400/40 bg-white divide-y divide-gray-100 rounded-lg w-44 dark:bg-gray-70 shadow-md`}
          >
            <ul className="py-2" aria-labelledby="dropdownButton">
              <li>
                <div
                  onClick={() => {
                    setView(!view);
                    setShow(!show);
                  }}
                  className="block cursor-pointer px-4 py-2 text-sm text-gray-700 hover:bg-gray-100  "
                >
                  View Info
                </div>
              </li>
            </ul>
          </div>
        </div>
        <div className="flex flex-col items-center pb-10">
          {user.photoURL ? (
            <div className="mb-3">
              <ProfilePicture size={96} profile={user} onClick={(e) => e} />
            </div>
          ) : (
            <img
              className="w-24 h-24 mb-3 rounded-full shadow-lg"
              src={`data:image/svg+xml;utf8,${encodeURIComponent(svg)}`}
              alt={user.displayName || user.email}
            />
          )}
          <h5 className="w-36 text-center overflow-hidden text-ellipsis mb-1 text-xl font-medium text-gray-900 dark:text-white ">
            {user.displayName || user.email || user.firstname}
          </h5>
          <span className="text-sm text-gray-500">{user.email}</span>
          <p className="text-sm text-center my-2 text-gray-500">
            Member since
            <span className="block">
              {displayRegisteredDate(
                user.last_active as unknown as dateDisplay
              )}
            </span>
          </p>
          {user.isVerified ? (
            <span className="text-sm uppercase bg-green-800 text-green-100 px-4 py-1 rounded-full my-2">
              {user.isVerified ? "Verified" : ""}
            </span>
          ) : (
            <span className="text-sm uppercase bg-gray-400 text-green-100 px-4 py-1 rounded-full my-2">
              {!user.isVerified ? "Not Verified" : ""}
            </span>
          )}

          <div className="flex my-4 gap-2  w-fit items-center">
            <label
              htmlFor={`${user.id}-isPremium `}
              className="cursor-pointer"
              onClick={handlePremiumClick}
            >
              <input
                type="checkbox"
                checked={isPremium ?? false}
                onChange={handlePremiumClick}
                className="my-4 mr-2"
                id={`${user.id}-isPremium`}
                style={{
                  height: "20px",
                  width: "20px",
                  borderRadius: "5px",
                  backgroundColor: isPremium ? "var(--icon-colour-0)" : "",
                }}
              />
              Premium
            </label>
          </div>
          <EmptySpace height="5px" />
          {organisation && role && roleArr && (
            <DropDown
              currSelected={role}
              setCurrSelected={(role) =>
                setUserRole(organisation, user.uid, role)
              }
              listOfOptions={roleArr}
            />
          )}
        </div>
      </div>
    </>
  );
};

export default UserItem;
