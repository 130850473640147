import classes from "./ProfilePicture.module.css";
import { CSSProperties, MouseEvent, MouseEventHandler, useCallback, useState } from "react";
import { ReactComponent as LerefIcon } from "assets/Leref.svg";
import { Profile } from "interface/ProfileInterface";
import ReportProfilePictureModal from "./ReportProfilePictureModal";

interface Props {
  profile?: Profile | null;
  onClick?: MouseEventHandler<HTMLDivElement>;
  size?: number;
  style? : CSSProperties;
  onReport?: () => void;
}

/**
 * Profile picture icon.
 * Shows users profile picture if exists and Leref icon otherwise.
 *
 * @param profile Profile of the user.
 * @param onClick Custom click handler for the profile picture.
 *    Defaults to showing a report profile picture modal.
 * @param size Width and height of picture.
 * @param style custom css style for the profile picture container.
 * @param onReport Method to run after profile picture is reported.
 * @returns ProfilePicture component.
 */
const ProfilePicture = ({ profile, onClick, size = 96, style, onReport }: Props) => {
  const [isModalOpen, setIsModalOpen] = useState(false);
  
  const src = profile?.photoURL;

  const className='bg-main-colour rounded-full border-4 border-facil-colour overflow-hidden '
    + 'flex justify-center items-center cursor-pointer '
    + classes['profile-picture'];
    
  const imageStyle: CSSProperties = {
    width: `${size}px`,
    height: `${size}px`,
    objectFit: 'cover',
  }

  const handleClick = useCallback((e: MouseEvent<HTMLDivElement>) => {
    if (onClick) {
      onClick(e)
    } else {
      setIsModalOpen(true);
    }
  }, [onClick])

  return (
    <div
      className={className} 
      style={{
        ...style,
        width: size,
        height: size
      }}
      onClick={handleClick}
    >
      <ReportProfilePictureModal
        profile={profile}
        isModalOpen={isModalOpen}
        setIsModalOpen={setIsModalOpen}
        onReport={onReport}
      />
      {
        src
          ? <img
            src={src}
            alt={'profile'}
            style={imageStyle}
          />
          : <LerefIcon
            width={size}
            height={size}
          />
      }
    </div>
  );
}

export default ProfilePicture;
