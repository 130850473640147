import classes from "./Topic.module.css";

export default function Topic({ topic, isSelected, onClick }) {
  return (
    <div
      className={
        isSelected ? classes["topic-item-active"] : classes["topic-item"]
      }
      onClick={onClick}
    >
      {topic}
    </div>
  );
}
