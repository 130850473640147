import { useEffect, useRef, useState } from "react";
import { projectFirestore } from "../../../firebase/config";
import { DownloadTableExcel } from "react-export-table-to-excel";
import { QuerySnapshot, getDocs, doc } from "firebase/firestore";
import { getDoc } from "firebase/firestore";

interface FeedbackItem {
  createdAt: firebase.default.firestore.Timestamp;
  [key: string]: any;
}

const NewShowAllGC = () => {
  const tableRef = useRef(null);
  const [allData, setAllData] = useState<FeedbackItem[]>([]);

  const fetchDataAndSortByCreatedAt = async () => {
    const feedbackFormHistoryRef =
      projectFirestore.collection("ParticipantHistory");
    const querySnapshot: QuerySnapshot = await getDocs(feedbackFormHistoryRef);

    let allData: FeedbackItem[] = [];

    querySnapshot.forEach((doc) => {
      if (
        doc.id !== "wWc0ucXhenUCImrC8Q9Q" &&
        doc.id !== "ZcSElw8rtxojhWuRgrjH" &&
        doc.id !== "cLUY89l5rS80sWQOwXN1" &&
        doc.id !== "lKAnlF4vS5S2h3IzrE37"
      ) {
        const data = doc.data();

        for (const field in data) {
          const fieldValue = data[field];

          if (Array.isArray(fieldValue)) {
            fieldValue.forEach((item: FeedbackItem) => {
              if (item.createdAt) {
                item.gcID = doc.id;
                allData.push(item);
              }
            });
          }
        }
      }
    });

    allData.sort((a, b) => {
      const aTimestamp = a.createdAt.toMillis();
      const bTimestamp = b.createdAt.toMillis();

      return bTimestamp - aTimestamp; // Descending order
    });

    //console.log(allData);
    setAllData(allData);
  };

  useEffect(() => {
    fetchDataAndSortByCreatedAt();
    //console.log(allData);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const fetchDataByUserIdAndGrowthCircles = async (
    userId: string,
    growthCircle: string
  ): Promise<any | null> => {
    try {
      if (!userId || !growthCircle) {
        console.log("User ID or Growth Circle is empty.");
        return null;
      }

      const feedbackFormHistoryRef = projectFirestore.collection(
        "FeedBackFormHistory"
      );
      const growthCircleSnapshot = await getDoc(
        doc(feedbackFormHistoryRef, growthCircle)
      );

      if (growthCircleSnapshot.exists()) {
        const data = growthCircleSnapshot.data();
        let userFeedback: any[] = [];

        // Iterate over the fields in the data
        for (const field in data) {
          const fieldValue = data[field];

          // Check if the field value is an array
          if (Array.isArray(fieldValue)) {
            // Filter the items based on the userId
            const filteredItems = fieldValue.filter(
              (item: any) => item.userId === userId
            );

            // Merge the filteredItems with the userFeedback array
            userFeedback = userFeedback.concat(filteredItems);
          }
        }

        if (userFeedback.length > 0) {
          return userFeedback;
        } else {
          console.log(
            "No feedback found for the user in the given growth circle."
          );
          return null;
        }
      } else {
        console.log("No such growth circle!");
        return null;
      }
    } catch (error) {
      console.error("Error fetching feedback data:", error);
      return null;
    }
  };

  const TableRow = ({ item }) => {
    const [closestData, setClosestData] = useState<FeedbackItem | null>(null);
    useEffect(() => {
      const fetchAndSetUserData = async () => {
        const userData = await fetchDataByUserIdAndGrowthCircles(
          item.userId,
          item.gcID
        );
        if (userData) {
          const closestData = userData.reduce((prev, current) => {
            // Convert the timestamps to milliseconds
            const prevDiff = Math.abs(
              item.createdAt.toMillis() - prev.createdAt.toMillis()
            );
            const currentDiff = Math.abs(
              item.createdAt.toMillis() - current.createdAt.toMillis()
            );

            // Compare the differences and return the closest one
            return prevDiff < currentDiff ? prev : current;
          });

          // Log the converted times for item and closestData
          console.log("Item created at:", item.createdAt.toDate());
          console.log(
            "Closest data created at:",
            closestData.createdAt.toDate()
          );

          console.log(closestData);
          setClosestData(closestData);
        }
      };
      fetchAndSetUserData();
      // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [item.userId]);

    return (
      <tr>
        <td className="px-6 py-4 border border-gray-500">
          {item.createdAt
            ? item.createdAt.toDate().toLocaleDateString("en-US", {
                timeZone: "Asia/Manila",
                month: "long",
                day: "numeric",
                year: "numeric",
              })
            : ""}
        </td>
        <td className="px-6 py-4 border border-gray-500">
          {" "}
          {item.createdAt
            ? item.createdAt.toDate().toLocaleTimeString("en-US", {
                timeZone: "Asia/Manila",
              })
            : ""}
        </td>
        <td className="px-6 py-4 border border-gray-500"> {item.email}</td>
        <td className="px-6 py-4 border border-gray-500"> {item.userName}</td>
        <td className="px-6 py-4 border border-gray-500"> {item.intentions}</td>
        <td className="px-6 py-4 border border-gray-500">
          {" "}
          {item.role ? item.role.core_skills : ""}
        </td>
        <td className="px-6 py-4 border border-gray-500">
          {" "}
          {item.role ? item.role.role : ""}
        </td>
        <td className="px-6 py-4 border border-gray-500">
          {" "}
          {item.role ? item.role.description : ""}
        </td>
        <td className="px-6 py-4 border border-gray-500">
          {" "}
          {item.activity !== "" ? item.activity : item.topic} {item.topic}
        </td>
        <td className="px-6 py-4 border border-gray-500">
          {" "}
          {item.activityTitle !== "" ? item.activityTitle : item.path}{" "}
          {item.path}
        </td>

        <td className="px-6 py-4 border border-gray-500">
          {item.checkIn ? item.checkIn.individually : ""}
        </td>
        <td className="px-6 py-4 border border-gray-500">
          {item.checkIn ? item.checkIn.interpersonally : ""}
        </td>
        <td className="px-6 py-4 border border-gray-500">
          {item.checkIn ? item.checkIn.socially : ""}
        </td>
        <td className="px-6 py-4 border border-gray-500">
          {item.checkIn ? item.checkIn.overall : ""}
        </td>
        <td className="px-6 py-4 border border-gray-500">
          {closestData && closestData.refPersonalDragValues !== undefined
            ? closestData.refPersonalDragValues.individually
            : item.checkOut
            ? item.checkOut.individually
            : ""}
        </td>
        <td className="px-6 py-4 border border-gray-500">
          {closestData && closestData.refPersonalDragValues !== undefined
            ? closestData.refPersonalDragValues.interpersonally
            : item.checkOut
            ? item.checkOut.interpersonally
            : ""}
        </td>
        <td className="px-6 py-4 border border-gray-500">
          {closestData && closestData.refPersonalDragValues !== undefined
            ? closestData.refPersonalDragValues.socially
            : item.checkOut
            ? item.checkOut.socially
            : ""}
        </td>
        <td className="px-6 py-4 border border-gray-500">
          {closestData && closestData.refPersonalDragValues !== undefined
            ? closestData.refPersonalDragValues.overall
            : item.checkOut
            ? item.checkOut.overall
            : ""}
        </td>
        <td className="px-6 py-4 border border-gray-500">
          {item.feedbackReflection !== undefined &&
          item.feedbackReflection.feedbackForSelf
            ? item.feedbackReflection.feedbackForSelf.satisfactory
            : ""}
        </td>
        <td className="px-6 py-4 border border-gray-500">
          {item.feedbackReflection !== undefined &&
          item.feedbackReflection.feedbackForSelf
            ? item.feedbackReflection.feedbackForSelf.takeaways
            : ""}
        </td>
        <td className="px-6 py-4 border border-gray-500">
          {item.feedbackReflection !== undefined &&
          item.feedbackReflection.feedbackForSelf
            ? item.feedbackReflection.feedbackForSelf.feltEngaged
            : ""}
        </td>
        <td className="px-6 py-4 border border-gray-500">
          {item.feedbackReflection !== undefined &&
          item.feedbackReflection.feedbackForSelf
            ? item.feedbackReflection.feedbackForSelf.feltHeard
            : ""}
        </td>
        <td className="px-6 py-4 border border-gray-500">
          {item.feedbackReflection !== undefined &&
          item.feedbackReflection.feedbackForSelf
            ? item.feedbackReflection.feedbackForSelf.inspired
            : ""}
        </td>
        <td className="px-6 py-4 border border-gray-500">
          {item.feedbackReflection !== undefined &&
          item.feedbackReflection.feedbackForSelf
            ? item.feedbackReflection.feedbackForSelf.understandMyself
            : ""}
        </td>
        <td className="px-6 py-4 border border-gray-500">
          {item.feedbackReflection !== undefined &&
          item.feedbackReflection.feedbackForSelf
            ? item.feedbackReflection.feedbackForSelf.thinkDeeper
            : ""}
        </td>
        <td className="px-6 py-4 border border-gray-500">
          {item.feedbackReflection !== undefined &&
          item.feedbackReflection.feedbackForSelf
            ? item.feedbackReflection.feedbackForSelf.overallBenefitted
            : ""}
        </td>
        <td className="px-6 py-4 border border-gray-500">
          {item.feedbackReflection !== undefined &&
          item.feedbackReflection.feedbackForSelf
            ? item.feedbackReflection.feedbackForSelf.learnedAndPracticed
            : ""}
        </td>
        <td className="px-6 py-4 border border-gray-500">
          {item.feedbackReflection !== undefined &&
          item.feedbackReflection.feedbackForFacil
            ? item.feedbackReflection.feedbackForFacil.facilName
            : ""}
        </td>
        <td className="px-6 py-4 border border-gray-500">
          {item.feedbackReflection !== undefined &&
          item.feedbackReflection.feedbackForFacil
            ? item.feedbackReflection.feedbackForFacil.createdSafeSpace
            : ""}
        </td>
        <td className="px-6 py-4 border border-gray-500">
          {item.feedbackReflection !== undefined &&
          item.feedbackReflection.feedbackForFacil
            ? item.feedbackReflection.feedbackForFacil.communicatedWell
            : ""}
        </td>
        <td className="px-6 py-4 border border-gray-500">
          {item.feedbackReflection !== undefined &&
          item.feedbackReflection.feedbackForFacil
            ? item.feedbackReflection.feedbackForFacil
                .engagedTheGroupEffectively
            : ""}
        </td>
        <td className="px-6 py-4 border border-gray-500">
          {item.feedbackReflection !== undefined &&
          item.feedbackReflection.feedbackForFacil
            ? item.feedbackReflection.feedbackForFacil.provideUsefulInsights
            : ""}
        </td>
        <td className="px-6 py-4 border border-gray-500">
          {item.feedbackReflection !== undefined &&
          item.feedbackReflection.feedbackForFacil
            ? item.feedbackReflection.feedbackForFacil
                .facilitateLearningThoughtReflection
            : ""}
        </td>
        <td className="px-6 py-4 border border-gray-500">
          {item.feedbackReflection !== undefined &&
          item.feedbackReflection.feedbackForFacil
            ? item.feedbackReflection.feedbackForFacil
                .haveNecessaryKnowledgeAndSkillsForTheRole
            : ""}
        </td>
        <td className="px-6 py-4 border border-gray-500">
          {item.feedbackReflection !== undefined &&
          item.feedbackReflection.feedbackForFacil
            ? item.feedbackReflection.feedbackForFacil
                .empowerUsToSupportOneAnother
            : ""}
        </td>
        <td className="px-6 py-4 border border-gray-500">
          {item.feedbackReflection !== undefined &&
          item.feedbackReflection.feedbackForFacil
            ? item.feedbackReflection.feedbackForFacil
                .inviteAndEmbraceAllPerspectives
            : ""}
        </td>
        <td className="px-6 py-4 border border-gray-500">
          {item.feedbackReflection !== undefined &&
          item.feedbackReflection.feedbackForFacil
            ? item.feedbackReflection.feedbackForFacil.otherFeedback
            : ""}
        </td>
        <td className="px-6 py-4 border border-gray-500">
          {item.feedbackReflection !== undefined &&
          item.feedbackReflection.feedbackForGroup
            ? item.feedbackReflection.feedbackForGroup.membersAreSupportive
            : ""}
        </td>
        <td className="px-6 py-4 border border-gray-500">
          {item.feedbackReflection !== undefined &&
          item.feedbackReflection.feedbackForGroup
            ? item.feedbackReflection.feedbackForGroup.whatElseToAppreciete
            : ""}
        </td>
        <td className="px-6 py-4 border border-gray-500">
          {item.feedbackReflection !== undefined &&
          item.feedbackReflection.feedbackForGroup
            ? item.feedbackReflection.feedbackForGroup.wantToBeAFacil
            : ""}
        </td>
      </tr>
    );
  };

  return (
    <div>
      <h1 className="text-2xl font-semibold">Data</h1>
      <div className="px-4">
        <DownloadTableExcel
          filename={`GrowthCircles-data-${new Date()}`}
          sheet="users"
          currentTableRef={tableRef.current}
        >
          <button className="bg-green-500 hover:bg-green-600 px-4 py-2 rounded-md text-white">
            Export Excel
          </button>
        </DownloadTableExcel>
      </div>
      <div className="px-4 py-8">
        <table
          ref={tableRef}
          className="w-full text-sm text-left text-gray-500 dark:text-gray-400"
        >
          <thead className="text-xs text-gray-700 uppercase bg-gray-50 dark:bg-gray-700 dark:text-gray-400">
            <tr className="border border-gray-500">
              <th scope="col" className="px-6 py-3 border border-gray-500">
                Date
              </th>
              <th scope="col" className="px-6 py-3 border border-gray-500">
                Time
              </th>
              <th scope="col" className="px-6 py-3 border border-gray-500">
                Email
              </th>
              <th scope="col" className="px-6 py-3 border border-gray-500">
                Name
              </th>
              <th scope="col" className="px-6 py-3 border border-gray-500">
                Intentions
              </th>
              <th scope="col" className="px-6 py-3 border border-gray-500">
                Core Role
              </th>
              <th scope="col" className="px-6 py-3 border border-gray-500">
                Role
              </th>
              <th scope="col" className="px-6 py-3 border border-gray-500">
                Role Description
              </th>
              <th scope="col" className="px-6 py-3 border border-gray-500">
                Topic
              </th>
              <th scope="col" className="px-6 py-3 border border-gray-500">
                Path
              </th>
              <th scope="col" className="px-6 py-3">
                CheckIn Individually
              </th>
              <th scope="col" className="px-6 py-3 border border-gray-500">
                CheckIn Interpersonally
              </th>
              <th scope="col" className="px-6 py-3 border border-gray-500">
                CheckIn Social
              </th>
              <th scope="col" className="px-6 py-3 border border-gray-500">
                CheckIn Overall
              </th>

              <th scope="col" className="px-6 py-3 border border-gray-500">
                CheckOut Individually
              </th>
              <th scope="col" className="px-6 py-3 border border-gray-500">
                CheckOut Interpersonally
              </th>
              <th scope="col" className="px-6 py-3 border border-gray-500">
                CheckOut Social
              </th>
              <th scope="col" className="px-6 py-3 border border-gray-500">
                CheckOut Overall
              </th>
              <th scope="col" className="px-6 py-3 border border-gray-500">
                For Self - On a scale of 1-10, how satisfied are you with
                today’s Growth Circle?
              </th>
              <th scope="col" className="px-6 py-3 border border-gray-500">
                For Self - What takeaways and insights do you have from today’s
                Growth Circle?
              </th>
              <th scope="col" className="px-6 py-3 border border-gray-500">
                For Self - I felt engaged during today’s session
              </th>
              <th scope="col" className="px-6 py-3 border border-gray-500">
                For Self - I feel heard and understood
              </th>
              <th scope="col" className="px-6 py-3 border border-gray-500">
                For Self - I am inspired and encouraged
              </th>
              <th scope="col" className="px-6 py-3 border border-gray-500">
                For Self - I feel I understand myself better
              </th>
              <th scope="col" className="px-6 py-3 border border-gray-500">
                For Self - I was able to think deeper because of questions asked
              </th>
              <th scope="col" className="px-6 py-3 border border-gray-500">
                For Self - Overall, I have benefitted from the session
              </th>
              <th scope="col" className="px-6 py-3 border border-gray-500">
                For Self - I have learned/practiced skills that is applicable to
                my daily life
              </th>
              <th scope="col" className="px-6 py-3 border border-gray-500">
                For Facil - Please write your Growth Facilitator's Name
              </th>
              <th scope="col" className="px-6 py-3 border border-gray-500">
                For Facil - Create a space that made us feel safe to share
              </th>
              <th scope="col" className="px-6 py-3 border border-gray-500">
                For Facil - Communicate well with the group
              </th>
              <th scope="col" className="px-6 py-3 border border-gray-500">
                For Facil - Engage the group effectively
              </th>
              <th scope="col" className="px-6 py-3 border border-gray-500">
                For Facil - Provide useful insights
              </th>
              <th scope="col" className="px-6 py-3 border border-gray-500">
                For Facil - Facilitate learning through reflections
              </th>
              <th scope="col" className="px-6 py-3 border border-gray-500">
                For Facil - Have the necessary knowledge and skills for the role
              </th>
              <th scope="col" className="px-6 py-3 border border-gray-500">
                For Facil - Empower us to support one another
              </th>
              <th scope="col" className="px-6 py-3 border border-gray-500">
                For Facil - Invite and embrace all perspectives
              </th>
              <th scope="col" className="px-6 py-3 border border-gray-500">
                For Facil - Kindly share with us why you gave the above ratings
              </th>
              <th scope="col" className="px-6 py-3 border border-gray-500">
                For Group - My group members are supportive of one another
              </th>
              <th scope="col" className="px-6 py-3 border border-gray-500">
                For Group - What went well in today's session? What else do you
                appreciate? What do you think contributed to that?
              </th>
              <th scope="col" className="px-6 py-3 border border-gray-500">
                For Group - Would you like to become a facilitator? (If yes, we
                will get in touch with you with more information)
              </th>
            </tr>
          </thead>
          <tbody className="bg-white">
            {allData.map((item, index) => (
              <TableRow key={index} item={item} />
            ))}
          </tbody>
        </table>
      </div>
    </div>
  );
};

export default NewShowAllGC;
