import SessionCards from "components/Admin/GrowthCircles/SessionCards";
import SimpleLoader from "components/Loaders/SimpleLoader";
import { useGrowthCircles } from "hooks/useGrowthCircles";
import usePromise from "hooks/utility/usePromise";
import { GrowthCircleSession } from "interface/GrowthCircleSessionInterface";
import Organisation from "interface/OrganisationInterface";
import { useEffect, useState } from "react";
import { inputClass } from "strings/InputClassStrings";
import classes from "pages/AllCircles/Admin/Admin.module.css";
import EmptySpace from "components/utility/EmptySpace";
import { BiXCircle } from "react-icons/bi";
import useGroup from "hooks/organisation/useGroup";
import FilterDropDownMulti, {
  FilterEvent,
} from "components/utility/FilterDropDownMulti/FilterDropDownMulti";

type JoinGrowthCirclesListsProps = {
  organisation: Organisation;
  setHasModal: (value: React.SetStateAction<boolean>) => void;
};

const JoinGrowthCirclesLists = ({
  organisation,
  setHasModal,
}: JoinGrowthCirclesListsProps) => {
  const {
    getGroupOrgByGroupName,
    getSubGroupByName,
    getAllGroupsByOrgId,
    getAllSubGroupsByOrgId,
  } = useGroup();
  const [GrowthCircles, setGrowthCircles] = useState<GrowthCircleSession[]>([]);
  const [localGCs, setLocalGCs] = useState<GrowthCircleSession[]>([]);
  const [filteredGCs, setFilteredGCs] = useState<GrowthCircleSession[]>([]);
  const [currentPage, setCurrentPage] = useState(1);
  const [itemsPerPage] = useState(8);

  const [searchQuery, setSearchQuery] = useState<string>("");
  const { getAllGc } = useGrowthCircles();
  const { isLoading, resolve } = usePromise();
  const paginate = (pageNumber: number) => setCurrentPage(pageNumber);
  const totalPages = Math.ceil(filteredGCs.length / itemsPerPage);

  const [groups, setGroups] = useState<string[]>([]);
  const [filteredGroups, setFilteredGroups] = useState<string[]>([]);

  const [subGroups, setSubGroups] = useState<string[]>([]);
  const [filteredSubGroups, setFilteredSubGroups] = useState<string[]>([]);

  const renderPaginationButtons = () => {
    const paginationItems: JSX.Element[] = [];

    const createPaginationItem = (i: number) => (
      <button
        key={i}
        onClick={() => paginate(i)}
        style={{
          backgroundColor:
            currentPage === i ? "var(--icon-colour-2)" : "var(--main-colour)",
        }}
        className={`my-5 mx-1 px-3 py-1 rounded-md `}
      >
        {i}
      </button>
    );

    if (totalPages <= 7) {
      for (let i = 1; i <= totalPages; i++) {
        paginationItems.push(createPaginationItem(i));
      }
    } else {
      paginationItems.push(createPaginationItem(1));

      if (currentPage > 3) {
        paginationItems.push(
          <span key="ellipsis1" className="my-5 mx-1 px-3 py-1">
            ...
          </span>
        );
      }

      const startPage = Math.max(2, currentPage - 1);
      const endPage = Math.min(totalPages - 1, currentPage + 1);

      for (let i = startPage; i <= endPage; i++) {
        paginationItems.push(createPaginationItem(i));
      }

      if (currentPage < totalPages - 2) {
        paginationItems.push(
          <span key="ellipsis2" className="my-5 mx-1 px-3 py-1">
            ...
          </span>
        );
      }

      paginationItems.push(createPaginationItem(totalPages));
    }

    return (
      <div className="flex justify-center mt-4">
        <button
          onClick={() => paginate(currentPage - 1)}
          className="my-5 mx-1 px-3 py-1 rounded-md bg-gray-200"
          disabled={currentPage === 1}
        >
          &lt;
        </button>
        {paginationItems}
        <button
          onClick={() => paginate(currentPage + 1)}
          className="my-5 mx-1 px-3 py-1 rounded-md bg-gray-200"
          disabled={currentPage === totalPages}
        >
          &gt;
        </button>
      </div>
    );
  };

  // Handle search input change
  const searchChangeHandler = (event: React.ChangeEvent<HTMLInputElement>) => {
    const query = event.target.value.toLowerCase();
    setSearchQuery(query);
  };

  const fetchAllGC = async () => {
    try {
      if (organisation) {
        const _growthCircles = (await getAllGc(
          organisation.name
        )) as GrowthCircleSession[];

        setGrowthCircles(_growthCircles);
        setLocalGCs(_growthCircles);
      } else {
        setGrowthCircles([]);
        setLocalGCs([]);
      }
    } catch (error) {
      console.error(error);
    }
  };

  useEffect(() => {
    resolve(() => fetchAllGC());
    // eslint-disable-next-line
  }, [organisation]);

  const fetchAllGroups = async () => {
    try {
      const _groups = await getAllGroupsByOrgId(organisation.id);

      if (_groups.length > 0) {
        setGroups(_groups.map((g) => g.groupName));
      } else {
        setGroups([]);
      }
    } catch (error) {
      console.error(error);
    }
  };

  const fetchAllSubGroups = async () => {
    try {
      const _subgroups = await getAllSubGroupsByOrgId(organisation.id);

      if (_subgroups.length > 0) {
        const uniqueGroupNames = Array.from(
          new Set(_subgroups.map((g) => g.groupName))
        );

        setSubGroups(uniqueGroupNames);
      } else {
        setSubGroups([]);
      }
    } catch (error) {
      console.error(error);
    }
  };

  const handleGroupFilter = (evt: FilterEvent) => {
    if (organisation) {
      if (evt.value.length === 0) {
        setFilteredGroups([]);
        return;
      }
      setFilteredGroups(evt.value);
      setCurrentPage(1);
    }
  };

  const handleSubGroupFilter = (evt: FilterEvent) => {
    if (organisation) {
      if (evt.value.length === 0) {
        setFilteredSubGroups([]);
        return;
      }
      setFilteredSubGroups(evt.value);
      setCurrentPage(1);
    }
  };

  useEffect(() => {
    const filtered = localGCs.filter(async (gc) => {
      setSearchQuery("");
      const matchesSearchQuery = gc.name
        .toLowerCase()
        .includes(searchQuery.toLowerCase());
      return matchesSearchQuery;
    });

    setFilteredGCs(filtered);
    setCurrentPage(1); // Reset to first page when filter changes

    // eslint-disable-next-line
  }, [localGCs, organisation]);

  const searchSubmitHandler = async () => {
    const promises = localGCs.map(async (gc) => {
      const matchesSearchQuery = gc.name
        .toLowerCase()
        .includes(searchQuery.toLowerCase());
      return matchesSearchQuery;
    });

    const results = await Promise.all(promises);
    const filtered = localGCs.filter((_, index) => results[index]);

    setFilteredGCs(filtered);
    setCurrentPage(1); // Reset to first page when filter changes
  };

  const clearHandler = () => {
    setSearchQuery("");
    const filtered = localGCs.filter((gc) => {
      const matchesSearchQuery = gc.name.toLowerCase().includes("");
      return matchesSearchQuery;
    });

    setFilteredGCs(filtered);
    setCurrentPage(1); // Reset to first page when filter changes
  };

  const indexOfLastItem = currentPage * itemsPerPage;
  const indexOfFirstItem = indexOfLastItem - itemsPerPage;
  const currentItems = filteredGCs.slice(indexOfFirstItem, indexOfLastItem);

  const filterGroupChangeHandler = async () => {
    // Create an array of promises
    const promises = filteredGroups.map(async (groupNames) => {
      // Find matches for each localGC
      const groupPromises = localGCs.map(async (gc) => {
        const groupMatch = await getGroupOrgByGroupName(
          organisation.id,
          groupNames
        );
        const isGroupMember = groupMatch.some((doc) => {
          return (
            Array.isArray(doc.data().users) &&
            doc.data().users.includes(gc.facilOwner)
          );
        });
        return isGroupMember;
      });

      // Await for all the group promises to resolve
      const results = await Promise.all(groupPromises);

      // Return results for this group
      return results;
    });

    // Await the outer promises
    const groupResults = await Promise.all(promises);

    // Flatten the groupResults array if necessary
    const filtered = localGCs.filter((_, index) =>
      groupResults.some((result) => result[index])
    );

    // Update the state with filtered results
    setFilteredGCs(filtered);
    setCurrentPage(1); // Reset to first page when filter changes
  };

  const filterSubGroupChangeHandler = async () => {
    // Create an array of promises
    const promises = filteredSubGroups.map(async (groupNames) => {
      // Find matches for each localGC
      const groupPromises = localGCs.map(async (gc) => {
        const subGroupMatch = await getSubGroupByName(
          organisation.id,
          groupNames
        );

        const isSubGroupMember = subGroupMatch.some((doc) => {
          return (
            Array.isArray(doc.data().users) &&
            doc.data().users.includes(gc.facilOwner)
          );
        });
        return isSubGroupMember;
      });

      // Await for all the group promises to resolve
      const results = await Promise.all(groupPromises);

      // Return results for this group
      return results;
    });

    // Await the outer promises
    const groupResults = await Promise.all(promises);

    // Flatten the groupResults array if necessary
    const filtered = localGCs.filter((_, index) =>
      groupResults.some((result) => result[index])
    );

    // Update the state with filtered results
    setFilteredGCs(filtered);
    setCurrentPage(1); // Reset to first page when filter changes
  };

  useEffect(() => {
    if (filteredGroups.length > 0) {
      filterGroupChangeHandler();
    } else {
      resolve(() => fetchAllGC());
    }

    // eslint-disable-next-line
  }, [filteredGroups]);

  useEffect(() => {
    if (filteredSubGroups.length > 0) {
      filterSubGroupChangeHandler();
    } else {
      resolve(() => fetchAllGC());
    }
    // eslint-disable-next-line
  }, [filteredSubGroups]);

  useEffect(() => {
    fetchAllGroups();
    fetchAllSubGroups();
    // eslint-disable-next-line
  }, [organisation]);

  return (
    <>
      {GrowthCircles.length > 0 && (
        <div
          className={`${classes["container"]} relative flex items-center justify-end w-2/3 mt-2 gap-2`}
        >
          <div className="w-full md:w-2/3 flex justify-items-center items-center gap-2">
            <input
              type="text"
              placeholder="Search session name..."
              value={searchQuery}
              onChange={searchChangeHandler}
              className={`${inputClass} placeholder:text-gray-700`}
              style={{ minWidth: "200px" }}
            />
            <button
              className={`${classes["button"]}`}
              style={{ backgroundColor: "var(--main-colour)" }}
              onClick={searchSubmitHandler}
            >
              Search
            </button>
          </div>

          {searchQuery.length > 0 && (
            <BiXCircle
              onClick={clearHandler}
              className="cursor-pointer"
              size={42}
              color={"var(--main-colour)"}
            />
          )}

          {groups.length > 0 && (
            <FilterDropDownMulti
              text="Filter by Group"
              options={groups.sort((a, b) =>
                a.localeCompare(b, undefined, {
                  numeric: true,
                  sensitivity: "base",
                })
              )}
              onSelect={handleGroupFilter}
              isAllSelected={false}
              btnClass={`${classes.button} !bg-[var(--main-colour)]`}
            />
          )}

          {subGroups.length > 0 && (
            <FilterDropDownMulti
              text="Filter by SubGroup"
              options={subGroups.sort((a, b) =>
                a.localeCompare(b, undefined, {
                  numeric: true,
                  sensitivity: "base",
                })
              )}
              onSelect={handleSubGroupFilter}
              isAllSelected={false}
              btnClass={`${classes.button} !bg-[var(--main-colour)]`}
            />
          )}
        </div>
      )}

      <div className="grid grid-1 md:grid-cols-4 gap-4 mt-2">
        {currentItems.map((gc: GrowthCircleSession, index: number) => (
          <SessionCards
            key={index} // some old GC have no uid
            gc={gc}
            join={setHasModal}
            organisation={organisation}
          />
        ))}

        {/* Load more button */}
      </div>
      {filteredGCs.length > 0 ? (
        <>
          {" "}
          <div>{renderPaginationButtons()}</div>
        </>
      ) : (
        <>
          {!isLoading ? (
            <div className="min-h-[10vh] flex justify-center items-center text-standard">
              <p>No active sessions</p>
            </div>
          ) : (
            <div className="w-full mt-2 min-[30vh] flex justify-center items-center">
              {" "}
              <SimpleLoader />
            </div>
          )}
        </>
      )}
      <EmptySpace />
    </>
  );
};

export default JoinGrowthCirclesLists;
