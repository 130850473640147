import { SelfAwarenessToolInterface } from "interface/SelfAwarenessToolInterface";
import { useEffect, useState } from "react";
import { BackAndNextProps } from "../SelfAwarenessComponentMapping";

import toast from "react-hot-toast";
import TextArea from "./TextArea";
import BackAndNextFlat from "components/utility/BackAndNextFlat";
import CriteriaFinalCard from "./CriteriaFinalCard";

type CriteriaGrading = {
  [option: string]: {
    [criteriaText: string]: number;
  };
};

const CriteriaFinalGrade = ({
  journal,
  backAndNextHandler,
  nextHandler,
  backHandler,
}: {
  journal: SelfAwarenessToolInterface;
  backAndNextHandler: BackAndNextProps;
  nextHandler: (e: React.MouseEvent<HTMLButtonElement, MouseEvent>) => void;
  backHandler: () => void;
}) => {
  const [options, setOptions] = useState<{ [option: string]: number }[]>([]);

  const [gradingReflection, setGradingReflection] = useState("");

  // Load data from local storage on component mount
  useEffect(() => {
    const storedJournal = localStorage.getItem("SelfAwarenessJournal");

    if (storedJournal) {
      const parsedJournal = JSON.parse(storedJournal);

      if (parsedJournal && parsedJournal.Options) {
        // Set the Options from parsedJournal if it exists
        calculateAveragesAndSort(parsedJournal.criteriaGrading);
      }
      if (parsedJournal && parsedJournal.gradingReflection) {
        setGradingReflection(parsedJournal.gradingReflection);
      }
    }
    // eslint-disable-next-line
  }, []); // Empty dependency array means this effect runs once on mount

  const calculateAveragesAndSort = (criteriaGrading: CriteriaGrading) => {
    const averages: { [option: string]: number } = {};

    // Calculate averages for each option
    Object.keys(criteriaGrading).forEach((option) => {
      const criteriaValues = Object.values(criteriaGrading[option]);
      const average =
        (criteriaValues.reduce((sum, value) => sum + value, 0) /
          criteriaValues.length) *
        10; // Convert to a 1-10 range
      averages[option] = average;
    });

    // Sort options based on their averages in descending order
    const sortedOptions = Object.keys(averages).sort(
      (a, b) => averages[b] - averages[a]
    );

    // Create a new object with sorted options and their averages
    const sortedAverages: { [option: string]: number } = {};
    sortedOptions.forEach((option) => {
      sortedAverages[option] = averages[option];
    });

    setOptions([sortedAverages]);
    return sortedAverages;
  };

  const areAllCriteriaTextNotEmpty = (criteriaArray) => {
    return criteriaArray.every(
      (criteria) => criteria.criteriaText.trim() !== ""
    );
  };

  const nextAllowedHandler = (
    e: React.MouseEvent<HTMLButtonElement, MouseEvent>
  ) => {
    const storedJournal = localStorage.getItem("SelfAwarenessJournal");
    const existingJournal = storedJournal ? JSON.parse(storedJournal) : {};
    const existingSteps = existingJournal.criteria || [];

    const allCriteriaTextNotEmpty = areAllCriteriaTextNotEmpty(existingSteps);

    if (allCriteriaTextNotEmpty) {
      nextHandler(e);
    } else {
      toast.error("Please fill-up all criteria");
    }
  };

  const answerHandler = (value: string) => {
    setGradingReflection(value);
    const storedJournal = localStorage.getItem("SelfAwarenessJournal");
    const existingJournal = storedJournal ? JSON.parse(storedJournal) : {};

    // Update the existing journal with the new answer
    const updatedJournal = {
      ...existingJournal,

      gradingReflection: value,
    };

    // Store the updated journal in local storage
    localStorage.setItem(
      "SelfAwarenessJournal",
      JSON.stringify(updatedJournal)
    );
  };

  return (
    <div className={`flex flex-col`}>
      <div className="flex flex-col lg:flex-row gap-4 my-4 justify-center items-center">
        {options &&
          options[0] &&
          Object.entries(options[0]).map(([option, average], index) => (
            <CriteriaFinalCard
              key={index}
              index={index}
              title={option}
              percent={`${average.toFixed(2)}%`}
            />
          ))}
      </div>
      <p
        className="mt-4 font-poppins font-semibold"
        style={{ color: "var(--text-colour)" }}
      >
        Looking at the ratings, which option are you deciding on?
      </p>
      {journal && (
        <TextArea
          maxLength={500}
          journal={journal}
          answer={gradingReflection}
          answerHandler={answerHandler}
        />
      )}

      <div className="my-4">
        <BackAndNextFlat
          {...backAndNextHandler}
          nextAllowed={gradingReflection.length > 0}
          backCallback={backHandler}
          nextCallback={(e) => nextAllowedHandler(e)}
        />
      </div>
    </div>
  );
};

export default CriteriaFinalGrade;
