import { MouseEvent, useState } from 'react';
import ProfilePictureUploadModal from './ProfilePictureUploadModal';
import ProfilePicture from 'components/ProfilePicture/ProfilePicture';

import classes from "./ProfileSettings.module.css";
import { useAuthContext } from 'hooks/useAuthContext';

/**
 * Button to toggle profile picture upload modal.
 *
 * @returns ProfilePictureSelector component.
 */
const ProfilePictureSelector = () => {
  const { profile } = useAuthContext();
  const [isModalOpen, setIsModalOpen] = useState(false);

  const handleClick = (e: MouseEvent<HTMLDivElement>) => {
    e.stopPropagation();
    setIsModalOpen(true);
  }

  return (
    <div
      onClick={handleClick}
      className={classes["profile-picture-select"]}
    >
      <ProfilePictureUploadModal
        isModalOpen={isModalOpen}
        setIsModalOpen={setIsModalOpen}
      />
      <ProfilePicture
        profile={profile}
        onClick={handleClick}
      />
      Change Picture
    </div>
  );
}

export default ProfilePictureSelector;