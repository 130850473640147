import EmptySpace from "components/utility/EmptySpace";
import InputField from "components/utility/Forms/InputField";
import { Group } from "interface/GroupInterface";
import Organisation from "interface/OrganisationInterface";

import toast from "react-hot-toast";
import { WithId } from "utility/model";

interface InviteLinkProps {
  selectedOrganisation: Organisation;
  groupNames: string[];
  selectedGroup: WithId<Group> | null;
  handleGroupChange: (e: React.ChangeEvent<HTMLSelectElement>) => void;
}
const GrowthCircleInviteLink = ({
  selectedGroup,
  selectedOrganisation,
  groupNames,
  handleGroupChange,
}: InviteLinkProps) => {
  const copyClipboardGroup = (id: string, group: string) => {
    navigator.clipboard.writeText(
      `${window.location.origin}/organisation/invite/${group}/${id}`
    );
    toast.success("successfully copied to clipboard");
  };

  const copyClipboard = (id: string) => {
    navigator.clipboard.writeText(
      `${window.location.origin}/organisation/invite/${id}`
    );
    toast.success("successfully copied to clipboard");
  };

  return (
    <div
      general-settings-tour="organisation-invite-link"
      className="py-4 p-4 flex-1 border-2 border-gray-200 my-4"
    >
      <p className="font-semibold  py-4 text-xl">Organisation Invite Link</p>
      {selectedOrganisation && (
        <p>
          Generate an invitation link for{" "}
          <strong>{selectedOrganisation.name}</strong> and then copy the link
          below to send it to the organization head. If you wish to create an
          invitation link for a particular group, simply choose a group from the
          dropdown menu and copy the corresponding link.
        </p>
      )}
      <EmptySpace />
      <div className="p-4">
        <InputField
          type="select"
          htmlFor={"group"}
          label={"Select group"}
          onChange={handleGroupChange}
          options={["", ...groupNames]}
          value={selectedGroup?.groupName}
        />
        <EmptySpace />
        <p style={{ color: "var(--icon-colour-0)" }} className="font-semibold">
          Click to copy link
        </p>
        {selectedOrganisation && !selectedGroup && (
          <p
            onClick={() => copyClipboard(selectedOrganisation.id)}
            className="py-2 text-slate-600"
          >{`${window.location.origin}/organisation/invite/${selectedOrganisation?.id}`}</p>
        )}

        {selectedOrganisation && selectedGroup && (
          <p
            onClick={() =>
              copyClipboardGroup(
                selectedOrganisation.id,
                selectedGroup?.groupName
              )
            }
            className="py-2 text-slate-600"
          >{`${window.location.origin}/organisation/invite/${selectedGroup?.groupName}/${selectedOrganisation?.id}`}</p>
        )}
      </div>
    </div>
  );
};

export default GrowthCircleInviteLink;
