import { createContext } from "react";
import { Participant } from "interface/ParticipantInterface";
import Role from "interface/RoleInterface";
import { ShareMessage } from "interface/MessageInterface";

export interface ReflectShareSkillsState {
  activated: boolean[];
  setFirstActivated: () => void;
  setSecondActivated: () => void;
  setThirdActivated: () => void;
  currReflection: string;
  setCurrReflection: React.Dispatch<React.SetStateAction<string>>;
  currNotes: string;
  setCurrNotes: React.Dispatch<React.SetStateAction<string>>;
  messages: ShareMessage[];
  participants: Participant[];
  role: Role | null;
}

const ReflectShareSkillsContext = createContext<ReflectShareSkillsState>(
  {} as ReflectShareSkillsState
);

export default ReflectShareSkillsContext;
