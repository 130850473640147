import SimpleLoader from "components/Loaders/SimpleLoader";
import classes from "./Topics.module.css";

interface Props {
  content: string[] | Record<string, string[]>;
  currSelected: string;
  setCurrSelected: React.Dispatch<React.SetStateAction<string>>;
  height?: string;
}

const Topics: React.FC<Props> = ({
  content,
  currSelected,
  setCurrSelected,
  height,
}) => {
  const heightStyling = {
    height: height ? height : "",
  };

  const selectTopic = (topic: string) => {
    setCurrSelected(topic);
    localStorage.setItem("selectedTopic", topic);
  };

  return (
    <div className={classes["topics-container"]} style={heightStyling}>
      {content === undefined && (
        <div className="p-8 text-slate-600 text-center w-full flex justify-center items-center h-44">
          <SimpleLoader />
        </div>
      )}
      {Array.isArray(content)
        ? content.map((topic, index) => (
            <div
              key={index}
              className={`${classes["topic"]} ${
                currSelected === topic && classes["topic-selected"]
              }`}
              onClick={() => selectTopic(topic)}
            >
              <strong>{topic}</strong>
            </div>
          ))
        : content?.general?.length > 0 &&
          content.general.map((topic, index) => (
            <div
              key={index}
              className={`${classes["topic"]} ${
                currSelected === topic && classes["topic-selected"]
              }`}
              onClick={() => selectTopic(topic)}
            >
              <strong>{topic}</strong>
            </div>
          ))}

      {Array.isArray(content) && content.length === 0 && (
        <div className="p-8 text-slate-600 text-center w-full flex justify-center items-center h-44">
          <p>No topics are available</p>
        </div>
      )}
    </div>
  );
};

export default Topics;
