// import { projectFirestore } from "../../../firebase/config";
import { useFacilCheck } from "hooks/useFacilEmailCheck";
import { Participant } from "interface/ParticipantInterface";
import { useEffect, useState } from "react";

interface Props {
  Callback: (participant) => any;
  participant: Participant;
  growthCircleID?: String;
}

const ParticipantSingle: React.FC<Props> = ({
  Callback,
  participant,
  growthCircleID,
}) => {
  const { facilsEmails } = useFacilCheck();
  const [userID, setUserID] = useState(String);
  //   const ref = projectFirestore.collection("FeedBackForm");
  //   const queryRef = ref
  //     .where("growthCircle", "==", userID)
  //     .where("userId", "==", growthCircleID);

  const clickHandler = (participant) => {
    // console.log(participant);
    setUserID(participant.userId);
    Callback(participant);
  };

  useEffect(() => {
    // queryRef
    //   .get()
    //   .then(function (doc) {
    //     if (doc.docs.length > 0) {
    //       //get document from users
    //       let documents: Object[] = [];
    //       doc.docs.map((_gc) => {
    //         documents.push({ ..._gc.data(), id: _gc.id });
    //         return setInformation(_gc.data());
    //         // console.log(_gc.data());
    //       });
    //     } else {
    //       try {
    //         // add display name to user
    //       } catch (err) {
    //         console.log("Error getting documents: ", err);
    //       }
    //     }
    //   })
    //   .catch(function (err) {
    //     console.log("Error getting documents: ", err);
    //   });
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [userID]);

  return (
    <tr onClick={() => clickHandler(participant)}>
      <td>{participant.userName}</td>
      <td>{participant.email}</td>
      <td>
        {" "}
        {facilsEmails.find((element) => element === participant.email)
          ? "facilitator"
          : "member"}
      </td>
      <td>{participant.createdAt.toDate().toLocaleString()}</td>
    </tr>
  );
};

export default ParticipantSingle;
