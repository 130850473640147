import { FC, useEffect, useState } from "react";
import { ComponentProps } from "../SelfAwarenessComponentMapping";
import { useSelfAwarenessContext } from "hooks/useSelfAwarenessContext";
import InfoModal from "../SubComponents/InfoModal";
import BackAndNextFlat from "components/utility/BackAndNextFlat";
import TitleBox from "../SubComponents/TitleBox";
import { colours } from "./styles/colourMap";
import BreakDownCard from "../SubComponents/BreakDownCard";

const BreakDownSelection: FC<ComponentProps> = ({
  backAndNextHandler,
  setHasModal,
}) => {
  const { journal } = useSelfAwarenessContext();
  const [show, setShow] = useState<boolean>(false);
  const [keyValues, setKeyValues] = useState<Object>({});
  const [selectedItems, setSelectedItem] = useState<string[]>([]);

  const handler = () => {
    setShow(!show);
    setHasModal(!show);
  };

  useEffect(() => {
    const storedJournal = localStorage.getItem("SelfAwarenessJournal");
    const existingJournal = storedJournal ? JSON.parse(storedJournal) : {};

    if (existingJournal.dissectKeyValues) {
      setKeyValues(existingJournal.dissectKeyValues);
    }

    // eslint-disable-next-line
  }, []);

  useEffect(() => {
    if (journal) return;
  }, [journal]);

  return (
    <div className="py-8 px-4 w-full md:w-1/4">
      {journal && <InfoModal show={show} journal={journal} handler={handler} />}
      {journal && (
        <>
          <TitleBox
            journal={journal}
            handler={handler}
            showInfo={true}
            title={`Looking at the list, which root cause can potentially be addressed and can make a significant impact on the challenge?`}
          />

          <div className="my-8 bg-white p-5 rounded-xl">
            <div className="max-h-[350px] overflow-y-scroll">
              {Object.entries(keyValues).map(([key, values]) => (
                <div key={key}>
                  <div
                    className="font-poppins p-4 rounded-full font-bold"
                    style={{
                      backgroundColor: colours[journal.pageType]["background"],
                      color: "var(--text-colour)",
                    }}
                  >
                    {key}
                  </div>
                  <div className="p-5">
                    {values.map((value, index) => (
                      <BreakDownCard
                        selectedItems={selectedItems}
                        value={value}
                        journal={journal}
                        setSelectedItem={setSelectedItem}
                        key={index}
                      />
                    ))}
                  </div>
                </div>
              ))}
            </div>
          </div>
        </>
      )}
      <div className="my-4">
        <BackAndNextFlat
          {...backAndNextHandler}
          nextAllowed={selectedItems.length > 0}
        />
      </div>
    </div>
  );
};

export default BreakDownSelection;
