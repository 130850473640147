import useOrganisationContext from "hooks/organisation/useOrganisationContext";
import { ReactNode, useEffect } from "react";

export type FeedbackFormTabsProps = {
  activeTab: number;
  organisation?: string;
  viewOnly?: boolean;
  handleSwitchTabs: (tab: number) => void;
};

const NTUFeedbackFormTab: ReactNode = (
  <div className="text-white text-center text-[11px] font-semibold py-3 flex justify-center items-center gap-px">
    <h3 className="text-2xl">Let's Reflect</h3>{" "}
  </div>
);

const FeedBackFormTabs = ({
  activeTab,
  handleSwitchTabs,
  organisation,
  viewOnly,
}: FeedbackFormTabsProps) => {
  const { selectedOrganisation } = useOrganisationContext();

  useEffect(() => {
    if (!selectedOrganisation) return;
  }, [selectedOrganisation]);

  useEffect(() => {
    if (!organisation) return;
  }, [organisation]);

  return (
    <div
      style={{ backgroundColor: "var(--main-colour)" }}
      className={`rounded-tl-3xl rounded-tr-3xl py-3 `}
    >
      {!viewOnly &&
        selectedOrganisation?.name === "NTU Career & Attachment Office" &&
        NTUFeedbackFormTab}

      {viewOnly && organisation === "NTU Career & Attachment Office" && (
        <div className="text-white text-center text-[11px] font-semibold py-3 flex justify-center items-center gap-px">
          <span
            onClick={() => handleSwitchTabs(1)}
            style={
              activeTab === 1
                ? {
                    color: "var(--main-colour)",
                    backgroundColor: "var(--main-colour-2)",
                  }
                : {}
            }
            className={`h-[17px] w-[64px] md:h-[18px] md:w-[82px] flex justify-center items-center border border-default rounded-tl-2xl rounded-bl-2xl cursor-pointer`}
          >
            1
          </span>
          <span
            onClick={() => handleSwitchTabs(3)}
            style={
              activeTab === 3
                ? {
                    color: "var(--main-colour)",
                    backgroundColor: "var(--main-colour-2)",
                  }
                : {}
            }
            className={` h-[17px] w-[64px] md:h-[18px] md:w-[82px] flex justify-center items-center border border-default cursor-pointer  rounded-tr-2xl rounded-br-2xl `}
          >
            2
          </span>
        </div>
      )}

      {viewOnly && organisation !== "NTU Career & Attachment Office" && (
        <div className="text-white text-center text-[11px] font-semibold py-3 flex justify-center items-center gap-px">
          <span
            onClick={() => handleSwitchTabs(1)}
            style={
              activeTab === 1
                ? {
                    color: "var(--main-colour)",
                    backgroundColor: "var(--main-colour-2)",
                  }
                : {}
            }
            className={`h-[17px] w-[64px] md:h-[18px] md:w-[82px] flex justify-center items-center border border-default rounded-tl-2xl rounded-bl-2xl cursor-pointer`}
          >
            1
          </span>
          <span
            onClick={() => handleSwitchTabs(2)}
            style={
              activeTab === 2
                ? {
                    color: "var(--main-colour)",
                    backgroundColor: "var(--main-colour-2)",
                  }
                : {}
            }
            className={` h-[17px] w-[64px] md:h-[18px] md:w-[82px] flex justify-center items-center border border-default cursor-pointer`}
          >
            2
          </span>
          <span
            onClick={() => handleSwitchTabs(3)}
            style={
              activeTab === 3
                ? {
                    color: "var(--main-colour)",
                    backgroundColor: "var(--main-colour-2)",
                  }
                : {}
            }
            className={` h-[17px] w-[64px] md:h-[18px] md:w-[82px] flex justify-center items-center border border-default rounded-tr-2xl rounded-br-2xl cursor-pointer`}
          >
            3
          </span>
        </div>
      )}

      {!viewOnly &&
        !organisation &&
        selectedOrganisation?.name !== "NTU Career & Attachment Office" && (
          <div className="text-white text-center text-[11px] font-semibold py-3 flex justify-center items-center gap-px">
            <span
              onClick={() => handleSwitchTabs(1)}
              style={
                activeTab === 1
                  ? {
                      color: "var(--main-colour)",
                      backgroundColor: "var(--main-colour-2)",
                    }
                  : {}
              }
              className={`h-[17px] w-[64px] md:h-[18px] md:w-[82px] flex justify-center items-center border border-default rounded-tl-2xl rounded-bl-2xl cursor-pointer`}
            >
              1
            </span>
            <span
              onClick={() => handleSwitchTabs(2)}
              style={
                activeTab === 2
                  ? {
                      color: "var(--main-colour)",
                      backgroundColor: "var(--main-colour-2)",
                    }
                  : {}
              }
              className={` h-[17px] w-[64px] md:h-[18px] md:w-[82px] flex justify-center items-center border border-default cursor-pointer`}
            >
              2
            </span>
            <span
              onClick={() => handleSwitchTabs(3)}
              style={
                activeTab === 3
                  ? {
                      color: "var(--main-colour)",
                      backgroundColor: "var(--main-colour-2)",
                    }
                  : {}
              }
              className={` h-[17px] w-[64px] md:h-[18px] md:w-[82px] flex justify-center items-center border border-default rounded-tr-2xl rounded-br-2xl cursor-pointer`}
            >
              3
            </span>
          </div>
        )}
    </div>
  );
};

export default FeedBackFormTabs;
