import React from "react";
import classes from "./Center.module.css";

interface Props {
  children: React.ReactNode;
  width?: string;
  maxWidth?: string;
  fadeIn?: boolean;
  minHeight?: string;
  height?: string;
  className?: string;
}

const Center: React.FC<Props> = ({
  children,
  width,
  maxWidth,
  fadeIn,
  minHeight,
  height,
  className = ''
}) => {
  const style = {
    width,
    maxWidth,
    minHeight,
    height,
  };

  return (
    <div
      className={`${classes["outer-container"]} ${
        fadeIn && classes["fade-in"]
      } ${className}`}
    >
      <div style={style} className={`${classes["inner-container"]} mb-36`}>
        {children}
      </div>
    </div>
  );
};

Center.defaultProps = {
  width: "100%",
  maxWidth: "900px",
  fadeIn: true,
};

export default Center;
