export function isObject(obj: unknown): obj is {} {
  return typeof obj === 'object' &&
    !Array.isArray(obj) &&
    obj !== null
}

export function hasKey<T extends (string | number | symbol)>(
  obj: unknown, key: T
): obj is { [key in T]: unknown } {
  return (!!obj) && typeof obj === 'object' && key in obj;
}

export function hasValue(obj: unknown, key: string | number | symbol, type: string) {
  if (hasKey(obj, key)) {
    return typeof (obj[key]) === type;
  }
  return false;
}

export function isFunction(
  maybeFunc: unknown
): maybeFunc is Function {
  return typeof maybeFunc === 'function';
}
