import AutoResizeTextArea from "components/utility/Inputs/AutoResizeTextArea";
import { SelfAwarenessToolInterface } from "interface/SelfAwarenessToolInterface";
import { colours } from "../components/styles/colourMap";
import { useEffect, useState } from "react";

interface TextAreaProps {
  journal: SelfAwarenessToolInterface;
  maxLength: number;
  row: number;
  question: string;
  storage: string;
}

const TextAreaFocus = ({
  journal,
  maxLength,
  row,
  question,
  storage,
}: TextAreaProps) => {
  const [answer, setAnswer] = useState<string>("");

  useEffect(() => {
    const storedJournal = localStorage.getItem("SelfAwarenessJournal");
    const existingJournal = storedJournal ? JSON.parse(storedJournal) : {};

    if (
      existingJournal &&
      existingJournal[storage] &&
      existingJournal[storage][question]
    ) {
      setAnswer(existingJournal[storage][question]);
      // Update or add the grade for the current item
      const updatedTopSelectionItem = {
        ...existingJournal[storage],
        [question]: existingJournal[storage][question],
      };

      // Update existingJournal with the latest topSelectionItem
      const updatedJournal = {
        ...existingJournal,
        [storage]: updatedTopSelectionItem,
        // other properties of SelfAwarenessJournal...
      };

      // Save the updated SelfAwarenessJournal to local storage
      localStorage.setItem(
        "SelfAwarenessJournal",
        JSON.stringify(updatedJournal)
      );
    } else {
      // Update or add the grade for the current item
      const updatedTopSelectionItem = {
        ...existingJournal[storage],
        [question]: "",
      };

      // Update existingJournal with the latest topSelectionItem
      const updatedJournal = {
        ...existingJournal,
        [storage]: updatedTopSelectionItem,
        // other properties of SelfAwarenessJournal...
      };

      // Save the updated SelfAwarenessJournal to local storage
      localStorage.setItem(
        "SelfAwarenessJournal",
        JSON.stringify(updatedJournal)
      );
    }

    // eslint-disable-next-line
  }, []);

  const handleInputChange = (value: string) => {
    // Ensure the input doesn't exceed the maximum length
    if (value.length <= maxLength) {
      setAnswer(value);
      const storedJournal = localStorage.getItem("SelfAwarenessJournal");
      const existingJournal = storedJournal ? JSON.parse(storedJournal) : {};

      // Update or add the grade for the current item
      const updatedTopSelectionItem = {
        ...existingJournal[storage],
        [question]: value,
      };

      // Update existingJournal with the latest topSelectionItem
      const updatedJournal = {
        ...existingJournal,
        [storage]: updatedTopSelectionItem,
        // other properties of SelfAwarenessJournal...
      };

      // Save the updated SelfAwarenessJournal to local storage
      localStorage.setItem(
        "SelfAwarenessJournal",
        JSON.stringify(updatedJournal)
      );
    }
  };

  return (
    <>
      {journal && journal.pageType && (
        <div
          className="border-2 relative my-4 bg-white rounded-lg font-poppins  "
          style={{
            borderColor: colours[journal.pageType]["border"],
            color: "var(--text-colour)",
          }}
        >
          <p
            className={`  
                text-sm p-2 text-gray-400 cursor-pointer"
            }`}
          >
            {question}
          </p>

          <AutoResizeTextArea
            style={{ resize: "none" }}
            row={row}
            className="border-none w-full rounded-lg placeholder:text-gray-300"
            onChange={(value) => {
              handleInputChange(value);
            }}
            value={answer}
            placeholder={`Write here max length ${500}... `}
            maxLength={500}
          />
          <div
            className="p-2 select-none"
            style={{ textAlign: "right", fontSize: "0.9rem", color: "gray" }}
          >
            {answer.length}/{maxLength}
          </div>
        </div>
      )}
    </>
  );
};

export default TextAreaFocus;
