import { ToggleSwitch } from "flowbite-react";
import useOrganisationRoleContext from "hooks/organisation/useOrganisationRoleContext";

import classes from "./IncludeExcludeSettings.module.css";
import adminClasses from "pages/AllCircles/Admin/Admin.module.css";
import { FormEvent, useEffect, useState } from "react";
import { Role } from "models/organisationRole";

import toast from "react-hot-toast";

const IncludeExcludeSettings = () => {
  const { roles, updateAverageRoleCalc } = useOrganisationRoleContext();
  const [toggledRoles, setToggledRoles] = useState<Role[]>([]);
  const [isLoading, setIsLoading] = useState(false);
  const handleToggle = (role: Role) => {
    setToggledRoles((prevToggledRoles: Role[]) => {
      // Check if the role is already in the toggledRoles array
      const isToggled = prevToggledRoles.some((r) => r.name === role.name);

      if (isToggled) {
        // Remove role if it exists
        return prevToggledRoles.filter((r) => r.name !== role.name);
      } else {
        // Add role if it doesn't exist
        return [...prevToggledRoles, role];
      }
    });
  };

  useEffect(() => {
    if (roles.length > 0) {
      // Filter roles that meet the conditions
      const initialToggledRoles = roles.filter(
        (role) =>
          role.isEnable !== false &&
          (role.exCludedToCalc === undefined || role.exCludedToCalc === false)
      );
      setToggledRoles(initialToggledRoles);
    }

    // eslint-disable-next-line
  }, [roles]);

  const delay = (ms: number) =>
    new Promise((resolve) => setTimeout(resolve, ms));

  const handleSubmit = async (e: FormEvent) => {
    e.preventDefault();
    try {
      setIsLoading(true);

      // Introduce a delay of 1 second (1000 ms)
      await delay(1000);

      await updateAverageRoleCalc(roles, toggledRoles);
      toast.success(
        "Include or Exclude average settings was saved successfully!"
      );
    } catch (error) {
      toast.error("Problem updating settings");
    } finally {
      setIsLoading(false);
    }
  };

  return (
    <div className="my-4">
      <hr />
      <p className="font-semibold mt-2">Include or Exclude average settings.</p>
      <p>
        Select the roles to include or exclude from the session's check-in and
        check-out average calculations. Ensure that at least one role is
        included before saving.
      </p>
      <div
        className={`${classes["option-container"]} mx-4 flex flex-col gap-2 my-8 text-standard`}
      >
        {roles.length > 0 &&
          roles
            .filter((r) => r.isEnable !== false)
            .map((role, index) => (
              <ToggleSwitch
                key={index}
                checked={toggledRoles.some((r) => r.name === role.name)}
                label={role.name}
                style={{ maxWidth: "200px" }}
                onChange={() => handleToggle(role)}
              />
            ))}
      </div>
      <div className={adminClasses.container}></div>
      <form onSubmit={handleSubmit}>
        <button
          type="submit"
          disabled={toggledRoles.length === 0 || isLoading}
          className={adminClasses.button}
        >
          {isLoading ? "Saving..." : "Save"}
        </button>
      </form>
    </div>
  );
};

export default IncludeExcludeSettings;
