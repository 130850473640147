import Header from "components/Header/Header";
import PageWrapper from "components/utility/PageWrapper";

const ErrorOrganisation = () => {
  return (
    <PageWrapper pageType={"groundRules"}>
      <Header />
      <div className="bg-white shadow-md mt-[20vh] md:mt-[20vh] w-full rounded-md max-w-3xl p-10 mx-auto px-2 sm:px-6 lg:px-8 mb-10 flex justify-center items-center flex-col gap-4">
        <h1 className="text-3xl font-bold">Invite Error</h1>
        <p className="text-center">
          Before accessing or participating in a session, it's necessary to be
          invited or subscribed to the corresponding organization or growth
          circle type. Please reach out to your facilitator or administrator to
          have your email added.
        </p>
      </div>
    </PageWrapper>
  );
};

export default ErrorOrganisation;
