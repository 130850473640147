import { ENV } from "../firebase/config";

export const useEmailAPI = () => {
  //TODO: sending email
  const sendEmail = async (email: string, fname: string, gcID: string) => {
    try {
      var requestOptions: RequestInit = {
        method: "GET",
        mode: "cors",
        cache: "no-cache",
        headers: {
          "Content-Type": "application/x-www-form-urlencoded; charset=UTF-8",
        },
      };
      return fetch(
        `https://us-central1-${ENV}.cloudfunctions.net/sendMail?email=${email}&fname=${fname}&gcID=${gcID}`,
        requestOptions
      )
        .then((response) => response.json())
        .then((data) => data)
        .catch((e) => console.info(e));
    } catch (error) {
      console.error(error);
    }
  };

  const sendMailWantToBeFacil = async (email: string, fname: string) => {
    try {
      var requestOptions: RequestInit = {
        method: "GET",
        mode: "cors",
        cache: "no-cache",
        headers: {
          "Content-Type": "application/x-www-form-urlencoded; charset=UTF-8",
        },
      };
      return fetch(
        `https://us-central1-${ENV}.cloudfunctions.net/sendMailWantToBeFacil?email=${email}&fname=${fname}`,
        requestOptions
      )
        .then((response) => {
          //console.log("Raw response:", response);
          return response.json();
        })
        .then((data) => data)
        .catch((e) => console.info(e));
    } catch (error) {
      console.error(error);
    }
  };

  const sendResetEMail = async (email: string, fname: string) => {
    try {
      var requestOptions: RequestInit = {
        method: "GET",
        mode: "cors",
        cache: "no-cache",
        headers: {
          "Content-Type": "application/x-www-form-urlencoded; charset=UTF-8",
        },
      };
      return fetch(
        `https://us-central1-${ENV}.cloudfunctions.net/sendResetEmail?email=${email}&fname=${fname}`,
        requestOptions
      )
        .then((response) => response.json())
        .then((data) => console.log(data))
        .catch((e) => console.log(e));
    } catch (error) {
      console.log(error);
    }
  };

  const sendEMailVerification = async (
    email: string,
    fname: string,
    gcID: string
  ) => {
    try {
      var requestOptions: RequestInit = {
        method: "GET",
        mode: "cors",
        cache: "no-cache",
        headers: {
          "Content-Type": "application/x-www-form-urlencoded; charset=UTF-8",
        },
      };
      return fetch(
        `https://us-central1-${ENV}.cloudfunctions.net/sendEmailVerification?email=${email}&fname=${fname}&gcID=${gcID}`,
        requestOptions
      )
        .then((response) => response.json())
        .then((data) => data)
        .catch((e) => console.log(e));
    } catch (error) {
      return error;
    }
  };

  const generateResetLink = async (email: string) => {
    const apiUrl = `https://us-central1-${ENV}.cloudfunctions.net/generateResetLink`;

    const requestData = {
      email,
    };

    fetch(apiUrl, {
      method: "POST",
      headers: {
        "Content-Type": "application/json",
      },
      body: JSON.stringify(requestData),
    })
      .then((response) => response.json())
      .then((data) => {
        console.log("Reset Link:", data.resetLink);
      })
      .catch((error) => {
        console.error("Error:", error);
      });
  };

  return {
    sendEmail,
    sendResetEMail,
    sendMailWantToBeFacil,
    sendEMailVerification,
    generateResetLink,
  };
};
