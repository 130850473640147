import { useNavigate } from "react-router-dom";
import {
  FEEDBACK_ROUTE_FACIL,
  FEEDBACK_ROUTE_GROUP,
  FEEDBACK_ROUTE_SELF,
} from "strings/Routes";

export default function useNavigateFeedback() {
  const navigate = useNavigate();

  return function (number: number) {
    switch (number) {
      case 1:
        navigate(FEEDBACK_ROUTE_SELF);
        break;
      case 2:
        navigate(FEEDBACK_ROUTE_FACIL);
        break;
      case 3:
        navigate(FEEDBACK_ROUTE_GROUP);
        break;
      default:
        return false;
    }
  };
}
