import useFeedbackForm from "hooks/useFeedbackForm";
import React, { useEffect, useState } from "react";
import { useLocation, useNavigate } from "react-router-dom";
import {
  END_OF_SESSION_ROUTE,
  END_RATING_AVERAGE_ROUTE,
  END_RATING_ROUTE,
  REFLECTION_RATING_ROUTE,
} from "strings/Routes";
import LoadingWidget from "./LoadingWidget";
import classes from "./RedirectWrapper.module.css";

const RedirectRoutes = {
  [END_RATING_ROUTE]: END_RATING_AVERAGE_ROUTE,
  [REFLECTION_RATING_ROUTE]: END_OF_SESSION_ROUTE,
};

//TODO : End Rating Bug
const RedirectMessage = {
  [END_RATING_ROUTE]:
    "Looks like you have already completed your check-in after the session",
  [REFLECTION_RATING_ROUTE]:
    "Looks like you have already completed your feedback",
};

type RedirectWrapperProps = {
  children: React.ReactNode;
};

/**
 * Component to redirect users based on the page they are on
 * @param {React.ReactNode} children - contents of Wrapper (the default content if redirection is not needed)
 */
const RedirectWrapper = ({ children }: RedirectWrapperProps) => {
  const location = useLocation();
  const navigate = useNavigate();
  const { endCheckInSubmitted, feedbackSubmitted } = useFeedbackForm();
  const [isRedirecting, setIsRedirecting] = useState<boolean | null>(null);

  useEffect(() => {
    switch (location.pathname) {
      case END_RATING_ROUTE:
        endCheckInSubmitted().then(
          (result) => setIsRedirecting(result)
          // setIsRedirecting(false)
        );
        break;
      case REFLECTION_RATING_ROUTE:
        feedbackSubmitted().then((result) => setIsRedirecting(result));
        break;
      default:
        setIsRedirecting(false);
        break;
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  useEffect(() => {
    //console.log(isRedirecting);
    // Check for redirection route
    if (isRedirecting)
      setTimeout(() => navigate(RedirectRoutes[location.pathname]), 4500);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [isRedirecting]);

  return (
    <>
      {isRedirecting === null && <LoadingWidget />}
      {(isRedirecting && (
        <div className={classes["redirect-container"]}>
          {/* <h1>{RedirectMessage[location.pathname]}</h1> */}
          <LoadingWidget
            title={RedirectMessage[location.pathname]}
            text={"Redirecting"}
          />
        </div>
      )) ||
        (isRedirecting !== null && children)}
    </>
  );
};

export default RedirectWrapper;
