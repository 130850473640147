import { inputClass, numberClass } from "strings/InputClassStrings";
import { colours } from "../components/styles/colourMap";
import { FaTrashAlt } from "react-icons/fa";
import { ChangeEvent, useEffect, useState } from "react";
import classes from "pages/AllCircles/Admin/Admin.module.css";

const WeightInput = ({
  pageType,
  index,
  removeHandler,
}: {
  pageType: string;
  index: number;
  removeHandler: (index: number) => void;
}) => {
  const [weight, setWeight] = useState<number>(100);
  const [criteria, setCriteria] = useState<string>("");

  useEffect(() => {
    const storedJournal = localStorage.getItem("SelfAwarenessJournal");
    const existingJournal = storedJournal ? JSON.parse(storedJournal) : {};
    const existingCriteria = existingJournal.criteria || [];

    if (existingJournal && existingCriteria[index]) {
      setWeight(existingCriteria[index].weight);
      setCriteria(existingCriteria[index].criteriaText);
    }
    // eslint-disable-next-line
  }, []);

  const weightHandler = (value: number) => {
    // Check if the value is a valid number
    if (!isNaN(value) && value <= 100) {
      setWeight(value);

      const storedJournal = localStorage.getItem("SelfAwarenessJournal");
      const existingJournal = storedJournal ? JSON.parse(storedJournal) : {};
      const existingCriteria = existingJournal.criteria || [];

      // Check if the index is within bounds
      if (index >= 0 && index < existingCriteria.length) {
        const updatedCriteria = existingCriteria.map((item, i) =>
          i === index ? { ...item, weight: value } : item
        );

        // Update the existing journal with the updated challenges array
        const updatedJournal = {
          ...existingJournal,
          criteria: updatedCriteria,
        };

        // Store the updated journal in local storage
        localStorage.setItem(
          "SelfAwarenessJournal",
          JSON.stringify(updatedJournal)
        );
      } else {
        // Add a new value if the index is out of bounds
        const updatedJournal = {
          ...existingJournal,
          criteria: [...existingCriteria, { criteriaText: "", weight: value }],
        };

        // Store the updated journal in local storage
        localStorage.setItem(
          "SelfAwarenessJournal",
          JSON.stringify(updatedJournal)
        );
      }
    } else {
      // Handle the case where the value is not a valid number
      console.warn("Invalid number received for weight:", value);
      // You might want to add additional logic or error handling here
    }
  };

  const criteriaHandler = (value: string) => {
    setCriteria(value);
    const defaultCriteria = { criteriaText: value, weight: 100 };

    const storedJournal = localStorage.getItem("SelfAwarenessJournal");
    const existingJournal = storedJournal ? JSON.parse(storedJournal) : {};
    const existingCriteria = existingJournal.criteria || [];

    // Check if the index is within bounds
    if (index >= 0 && index < existingCriteria.length) {
      const updatedCriteria = existingCriteria.map((item, i) =>
        i === index ? { ...item, ...defaultCriteria } : item
      );

      // Update the existing journal with the updated challenges array
      const updatedJournal = {
        ...existingJournal,
        criteria: updatedCriteria,
      };

      // Store the updated journal in local storage
      localStorage.setItem(
        "SelfAwarenessJournal",
        JSON.stringify(updatedJournal)
      );
    } else {
      // Add a new value with default criteria if the index is out of bounds
      const updatedJournal = {
        ...existingJournal,
        criteria: [...existingCriteria, defaultCriteria],
      };

      // Store the updated journal in local storage
      localStorage.setItem(
        "SelfAwarenessJournal",
        JSON.stringify(updatedJournal)
      );
    }
  };

  return (
    <div className={`${classes["container"]} flex flex-col`}>
      <div
        className={`mt-4 gap-4 md:gap-8 flex mx-auto justify-center  items-center`}
        style={{ color: "var(--text-colour)" }}
      >
        <p className="font-semibold text-lg">Decision criteria {index + 1}</p>
        <p>Weightage (%)</p>
        <div className="w-[100px]">
          <input
            max={100}
            min={1}
            type="number"
            value={weight}
            className={`${numberClass} border-4`}
            style={{
              borderColor: colours[pageType]["border"],
              borderWidth: "2px",
            }}
            onChange={(e: ChangeEvent<HTMLInputElement>) =>
              weightHandler(parseInt(e.target.value))
            }
          />
        </div>
      </div>
      <div className="flex items-center gap-2">
        <input
          type="text"
          className={`${inputClass}`}
          placeholder={`Write your criteria..`}
          style={{
            borderColor: colours[pageType]["border"],
            borderWidth: "2px",
          }}
          value={criteria}
          onChange={(e: ChangeEvent<HTMLInputElement>) =>
            criteriaHandler(e.target.value)
          }
        />
        <FaTrashAlt
          className="cursor-pointer"
          onClick={() => removeHandler(index)}
          size={25}
          style={{ color: "var(--icon-colour-0)" }}
        />
      </div>
    </div>
  );
};

export default WeightInput;
