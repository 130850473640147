import React, { useState } from "react";
import {
  saveIntentionContent,
  reflectContent,
  shareAndSupportContent,
} from "components/EndOfSession/JournalContent";

import { useFirestore } from "./useFirestore";

import { GCJournal } from "interface/GCJournal";
import { Participant } from "interface/ParticipantInterface";
import { projectFirestore } from "../firebase/config";
import firebase from "firebase/compat/app";
import "firebase/firestore";

/**
 * Methods to handle the Journal Checklist portion in EndOfSession
 */
const useJournalChecklist = () => {
  const [isSaveIntention, setIsSaveIntention] = useState<boolean>(false);
  const [isSaveReflectAll, setIsSaveReflectAll] = useState<boolean>(false);
  const [isSaveReflect, setIsSaveReflect] = useState<string[]>([]);
  const [isSaveShareAndSupportAll, setIsSaveShareAndSupportAll] =
    useState(false);
  const [isSaveShareAndSupport, setIsSaveShareAndSupport] = useState<string[]>(
    []
  );

  const { addDocument, updateDocument } = useFirestore("GCJournals");

  /**
   * Toggles the option to save intention
   * @param e: React.FormEvent<HTMLInputElement>
   */
  const handleSaveIntention = (e: React.FormEvent<HTMLInputElement>) => {
    setIsSaveIntention(!isSaveIntention);
  };

  /**
   * Toggles the option to save all reflection options
   * @param e: React.FormEvent<HTMLInputElement>
   */
  const handleSaveReflectAll = (e: React.FormEvent<HTMLInputElement>) => {
    setIsSaveReflectAll(!isSaveReflectAll);
    setIsSaveReflect(reflectContent.subContent!.map((content) => content.id));
    if (isSaveReflectAll) {
      setIsSaveReflect([]);
    }
  };

  /**
   * Toggles the option to save one of the reflect options (Reflection/Notes)
   * @param e: React.FormEvent<HTMLInputElement>
   */
  const handleSaveReflect = (e: React.FormEvent<HTMLInputElement>) => {
    const { id, checked } = e.currentTarget;
    setIsSaveReflect([...isSaveReflect, id]);
    if (isSaveReflect.length === reflectContent.subContent!.length - 1) {
      setIsSaveReflectAll(true);
    }
    if (!checked) {
      setIsSaveReflect(isSaveReflect.filter((rId) => rId !== id));
      if (isSaveReflect.length === 1) {
        setIsSaveReflectAll(false);
      }
    }
  };

  /**
   * Toggles the option to save all share and support options
   * @param e: React.FormEvent<HTMLInputElement>
   */
  const handleSaveShareAndSupportAll = (
    e: React.FormEvent<HTMLInputElement>
  ) => {
    setIsSaveShareAndSupportAll(!isSaveShareAndSupportAll);
    setIsSaveShareAndSupport(
      shareAndSupportContent.subContent!.map((content) => content.id)
    );
    if (isSaveShareAndSupportAll) {
      setIsSaveShareAndSupport([]);
    }
  };

  /**
   * Toggles the option to save one of the share and support options (My Questions/Others Questions)
   * @param e: React.FormEvent<HTMLInputElement>
   */
  const handleSaveShareAndSupport = (e: React.FormEvent<HTMLInputElement>) => {
    const { id, checked } = e.currentTarget;
    setIsSaveShareAndSupport([...isSaveShareAndSupport, id]);
    if (isSaveShareAndSupport.length === 1) {
      setIsSaveShareAndSupportAll(true);
    }
    if (!checked) {
      setIsSaveShareAndSupport(
        isSaveShareAndSupport.filter((rId) => rId !== id)
      );
      if (isSaveShareAndSupport.length === 1) {
        setIsSaveShareAndSupportAll(false);
      }
    }
  };

  const handleJournalSubmit = async (participant: Participant) => {
    //1. Entry should not exist in GCJournals yet
    const newJournal: Partial<GCJournal> = {};
    let existingJournal: {
      id?: string;
      data?: firebase.firestore.DocumentData;
    } = {};
    newJournal["userId"] = participant.userId;
    newJournal["growthCircle"] = participant.id;
    if (isSaveIntention && participant.intentions) {
      newJournal[saveIntentionContent.id] = participant.intentions;
    }

    const ref = projectFirestore
      .collection("GCJournals")
      .where("growthCircle", "==", participant.id)
      .where("userId", "==", participant.userId);

    const querySnapshot = await ref.get();

    if (!querySnapshot.empty) {
      querySnapshot.forEach((doc) => {
        existingJournal = { id: doc.id, data: doc.data() };
      });
    } else {
      console.debug("No existing journal found!");
    }

    isSaveReflect.forEach((reflectId) => {
      if (participant[reflectId]) {
        newJournal[reflectId] = participant[reflectId];
      }
    });

    shareAndSupportContent.subContent!.forEach((subContent) => {
      newJournal[subContent.id] = isSaveShareAndSupport.includes(subContent.id);
    });

    // Set other journal properties
    newJournal.path = participant.path;
    newJournal.role = participant.role;
    newJournal.topic = participant.topic;
    newJournal.reflectionQuestion = participant.reflectionQuestion;
    newJournal.personalReflectionQuestion =
      participant.personalReflectionQuestion;
    newJournal.category = "standard";

    // Update or add the journal
    if (existingJournal.id) {
      await updateDocument(existingJournal.id, newJournal);
    } else {
      console.log("Adding Journal");
      await addDocument(newJournal);
    }
  };

  return {
    isSaveIntention,
    isSaveReflect,
    isSaveReflectAll,
    isSaveShareAndSupport,
    isSaveShareAndSupportAll,
    // isSaveSkillDescription,
    // isSaveSkillsDescriptionAll,
    handleSaveIntention,
    handleSaveReflect,
    handleSaveReflectAll,
    handleSaveShareAndSupport,
    handleSaveShareAndSupportAll,
    // handleSaveSkillDescription,
    // handleSaveSkillDescriptionAll,
    handleJournalSubmit,
    saveIntentionContent,
    reflectContent,
    shareAndSupportContent,
  };
};

export default useJournalChecklist;
