import classes from "./Carousel.module.css";
import LeftArrow from "assets/LeftArrow";
import RightArrow from "assets/RightArrow";
import { useEffect } from "react";

interface Props {
  children: React.ReactNode[];
  next: () => void;
  prev: () => void;
  currentIndex: number;
  width?: string;
  height?: string;
  displayCount: string;
  arrowColor?: string;
  arrowOffset?: string;
  isVertical?: boolean;
  customWrapper?: boolean;
}

const Carousel: React.FC<Props> = ({
  children, //Contents of carousel
  next,
  prev,
  currentIndex,
  width, //Width of carousel relative to container
  height, //Height of carousel
  displayCount, //Number of content to display at a time
  arrowColor, //Colour of arrow
  arrowOffset, //Offset of arrow from sides
  isVertical, //Makes the carousel vertical
  customWrapper,
}) => {
  useEffect(() => {
    if (displayCount) {
      document.documentElement.style.setProperty(
        "--carousel-display",
        displayCount
      );
    }

    if (width) {
      document.documentElement.style.setProperty("--carousel-width", width);
    }

    if (arrowOffset) {
      document.documentElement.style.setProperty(
        "--carousel-arrow-offset",
        arrowOffset
      );
    }

    if (arrowColor) {
      document.documentElement.style.setProperty(
        "--carousel-arrow-color",
        arrowColor
      );
    }

    if (height) {
      document.documentElement.style.setProperty("--carousel-height", height);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return (
    <div className={`${classes["carouselContainer"]} w-full`}>
      <div className={`${classes["carouselWrapper"]}`}>
        {currentIndex > 0 && (
          <button type="button" onClick={prev} className={classes["leftArrow"]}>
            <LeftArrow arrowColor={arrowColor} />
          </button>
        )}

        {customWrapper ? (
          <div className={`w-full`}>
            <div
              className={`${classes["carouselContent"]} ${
                isVertical ? classes["carouselContent--vertical"] : ""
              }`}
              style={{
                transform: `translateX(-${currentIndex * 100}%)`,
                transition: `ease-in-out 0.5s`,
              }}
            >
              {Number(displayCount) % 2 !== 0 && Number(displayCount) > 1 && (
                <div />
              )}
              {children}
              {Number(displayCount) % 2 !== 0 && Number(displayCount) > 1 && (
                <div />
              )}
            </div>
          </div>
        ) : (
          <div className={`${classes["carouselContentWrapper"]}`}>
            <div
              className={`${classes["carouselContent"]} ${
                isVertical ? classes["carouselContent--vertical"] : ""
              }`}
              style={{
                transform: `translateX(-${currentIndex * 100}%)`,
                transition: `ease-in-out 0.5s`,
              }}
            >
              {Number(displayCount) % 2 !== 0 && Number(displayCount) > 1 && (
                <div />
              )}
              {children}
              {Number(displayCount) % 2 !== 0 && Number(displayCount) > 1 && (
                <div />
              )}
            </div>
          </div>
        )}

        {currentIndex < children.length - 1 && (
          <button
            type="button"
            onClick={next}
            className={`${classes["rightArrow"]} flex items-center justify-end `}
          >
            <RightArrow arrowColor={arrowColor} />
          </button>
        )}
      </div>
    </div>
  );
};

export default Carousel;
