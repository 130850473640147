import { ReactComponent as ChooseRoleGuide } from "../../assets/ChooseRoleGuide.svg";
import { ReactComponent as ChooseRoleGuideLock } from "../../assets/ChooseRoleGuideLock.svg";
import { ReactComponent as PercentageGuideOne } from "../../assets/PercentageGuide-1.svg";
import { ReactComponent as PercentageGuideTwo } from "../../assets/PercentageGuide-2.svg";

import { useState } from "react";

interface Props {
  setHasModal: (b: boolean) => any;
}

const ChooseRolesHelper = ({ setHasModal }: Props) => {
  const [step, setStep] = useState(1);

  const backHandler = () => {
    if (step !== 1) {
      return setStep(step - 1);
    } else if (step === 1) {
      setHasModal(false);
    } else {
      return setStep(1);
    }
  };

  const nextHandler = () => {
    if (step < 4) {
      return setStep(step + 1);
    } else {
      localStorage.setItem("ChooseRoleRead", "true");
      setStep(1);
      return setHasModal(false);
    }
  };

  return (
    <div className=" flex flex-col justify-center items-center">
      {step === 1 && (
        <div className="flex flex-col justify-center items-center">
          <h5 className="font-semibold text-lg">Pick a role / Build a skill</h5>
          <p className="text-sm py-2">
            Choose a role from different categories of skills. Scroll left-right
            to navigate the different categories and up-down for the different
            roles.
          </p>
          <p className="text-sm py-2">
            To select a role, click on it. When you see a thicker red border
            indicates you have selected it. Click ‘Next’
          </p>
          <div className="py-2">
            <ChooseRoleGuide />{" "}
          </div>
        </div>
      )}

      {step === 2 && (
        <div className="flex flex-col justify-center items-center">
          <h5 className="font-semibold text-lg">Pick a role / Build a skill</h5>
          <p className="text-sm py-2 px-3">
            As you level up, more skills will be unlocked. For now, they will
            look like this.
          </p>

          <div className="py-2">
            <ChooseRoleGuideLock width={"100%"} />
          </div>
        </div>
      )}

      {step === 3 && (
        <div className="flex flex-col justify-center items-center">
          <PercentageGuideOne />
        </div>
      )}

      {step === 4 && (
        <div className="flex flex-col justify-center items-center">
          <PercentageGuideTwo />
        </div>
      )}

      <div className="py-5 flex justify-between items-center gap-14">
        <button
          type="button"
          className="bg-white border border-slate-400/[0.2] shadow-lg font-semibold px-8 py-2 rounded-full"
          onClick={backHandler}
          style={{ color: "var(--main-colour)" }}
        >
          Back
        </button>
        <button
          type="button"
          className="bg-white border border-slate-400/[0.2] shadow-lg font-semibold px-8 py-2 rounded-full"
          onClick={nextHandler}
          style={{ color: "var(--main-colour)" }}
        >
          Next
        </button>
      </div>
    </div>
  );
};

export default ChooseRolesHelper;
