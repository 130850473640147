// import Skill from "components/SharingPanel/Skill/Skill";
import Tabs from "components/SharingPanel/Tabs";
import useTabs from "hooks/useTabs";
import { ShareMessage } from "interface/MessageInterface";
import { Participant } from "interface/ParticipantInterface";
import ViewNGReflect from "./Reflect/ViewNGReflect";
import ViewReflect from "./Reflect/ViewReflect";
import classes from "./ReflectShareSkills.module.css";
// import ViewNGShare from "./Share/ViewNGShare";
import ViewJournalContext from "./ViewJournalContext";
import ViewNGShare from "./Share/ViewNGShare";
import Skill from "./Skill/Skill";
import { OrgJournalWithImageUpload } from "utility/JournalFiltering";

interface ViewJournalContentProps {
  userJournal: Participant;
  messages: ShareMessage[];
  participants: Participant[];
  hasLightBox: Function;
}

const ViewJournalSingleContent = ({
  userJournal,
  participants,
  messages,
  hasLightBox,
}: ViewJournalContentProps) => {
  const {
    activated,
    setFirstActivated,
    setSecondActivated,
    setThirdActivated,
  } = useTabs();

  const state = {
    activated,
    setFirstActivated,
    setSecondActivated,
    setThirdActivated,
    userJournal,
    participants,
    messages,
  };
  const personalQuestion = userJournal.personalReflectionQuestion;
  const generalReflectionQuestion = userJournal.reflectionQuestion;
  const compulsoryQuestions = userJournal.compulsoryReflectionQuestion;

  return (
    <ViewJournalContext.Provider value={state}>
      <div
        className={`animate-fade ${classes["reflect-share-skills-container"]} max-w-[500px] mx-auto`}
      >
        <Tabs context={ViewJournalContext} />

        <div className={classes["content"]}>
          {activated[0] &&
            userJournal.location &&
            !OrgJournalWithImageUpload.includes(userJournal.location) && (
              <ViewReflect
                compulsoryReflectionQuestions={compulsoryQuestions}
                personalReflectionQuestion={personalQuestion}
                generalReflectionQuestion={generalReflectionQuestion}
                oldPath={userJournal.path}
                topic={userJournal.topic}
                organisation={userJournal.location ?? ""}
                hasLightBox={hasLightBox}
                journal={userJournal}
              />
            )}
          {activated[0] &&
            userJournal.location &&
            OrgJournalWithImageUpload.includes(userJournal.location) && (
              <ViewNGReflect journal={userJournal} hasLightBox={hasLightBox} />
            )}
          {activated[1] && (
            <ViewNGShare messages={messages} userJournal={userJournal} />
          )}
          {activated[2] && userJournal.role && (
            <Skill role={userJournal.role} />
          )}
        </div>
      </div>
    </ViewJournalContext.Provider>
  );
};

export default ViewJournalSingleContent;
