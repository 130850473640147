import { SelfAwarenessToolInterface } from "interface/SelfAwarenessToolInterface";
import { colours } from "../components/styles/colourMap";
import { FaCheckCircle } from "react-icons/fa";
import { useEffect } from "react";

interface BreakDownCardProps {
  setSelectedItem: React.Dispatch<React.SetStateAction<string[]>>;
  journal: SelfAwarenessToolInterface;
  value: string;
  selectedItems: string[];
}
const BreakDownCard = ({
  value,
  setSelectedItem,
  journal,
  selectedItems,
}: BreakDownCardProps) => {
  const handleSubItemClick = (item: string) => {
    // Use the callback form to ensure the latest state
    setSelectedItem((prevSelected) => {
      // Check if the item is already selected
      if (prevSelected.includes(item)) {
        // If selected, remove it
        return prevSelected.filter((i) => i !== item);
      } else {
        // If not selected, add it
        return [...prevSelected, item];
      }
    });
  };

  useEffect(() => {
    const storedJournal = localStorage.getItem("SelfAwarenessJournal");

    const existingJournal = storedJournal ? JSON.parse(storedJournal) : {};

    // Update SelfAwarenessJournal with the latest selectedItems
    const updatedJournal = {
      ...existingJournal,
      selectedCause: selectedItems, // Use the latest state here
      // other properties of SelfAwarenessJournal...
    };

    // Save the updated SelfAwarenessJournal to local storage
    localStorage.setItem(
      "SelfAwarenessJournal",
      JSON.stringify(updatedJournal)
    );
    // eslint-disable-next-line
  }, [selectedItems]);

  return (
    <div
      className="border-4 flex gap-2 px-4 py-2 my-2 rounded-full cursor-pointer"
      style={{
        borderColor: colours[journal.pageType]["border"],
        color: "var(--text-colour)",
      }}
      onClick={() => handleSubItemClick(value)}
    >
      {selectedItems &&
        Array.isArray(selectedItems) &&
        selectedItems.includes(value) && (
          <FaCheckCircle
            className="flex-shrink-0"
            size={20}
            style={{ color: "var(--icon-colour-0)" }}
          />
        )}
      {value}
    </div>
  );
};

export default BreakDownCard;
