import { Group, SubGroup } from "interface/GroupInterface";
import { projectFirestore } from "../../firebase/config";

import { useEffect, useState } from "react";
import toast from "react-hot-toast";
import useOrganisationContext from "./useOrganisationContext";

interface GroupAndSubgroup {
  groupDoc: Group; // Replace with your actual type for a group document
  subgroupDoc: SubGroup | null;
}

const useGroup = () => {
  const [groups, setGroups] = useState<Group[]>([]);
  const [subGroups, setSubGroups] = useState<SubGroup[]>([]);
  const [groupNames, setGroupNames] = useState<string[]>([]);
  const { selectedOrganisation } = useOrganisationContext();

  const getGroups = async () => {
    try {
      if (!selectedOrganisation?.id) {
        console.error("Selected organisation ID is not defined");
        return;
      }
      const unsubscribe = projectFirestore
        .collection("organisations")
        .doc(selectedOrganisation.id)
        .collection("groups")
        .onSnapshot(
          (snapshot) => {
            const data = snapshot.docs.map((doc) => {
              return doc.data() as Group;
            });
            setGroups(data);
            const groupNames = data
              .map((g) => g.groupName)
              .sort((a, b) => a.localeCompare(b));
            setGroupNames(groupNames);
          },
          (error) => {
            console.error("Error with Firestore onSnapshot:", error); // Log errors from Firestore
          }
        );

      // Clean up the listener when the component unmounts
      return () => unsubscribe();
    } catch (error) {
      console.error("Error fetching groups:", error);
      // Handle errors if necessary
    }
  };

  const getAllGroups = async (): Promise<Group[]> => {
    try {
      if (!selectedOrganisation?.id) {
        console.error("Selected organisation ID is not defined");
        return []; // Return an empty array if organisation ID is not defined
      }

      const querySnapshot = await projectFirestore
        .collection("organisations")
        .doc(selectedOrganisation.id)
        .collection("groups")

        .get(); // Use .get() for a one-time fetch

      const data = querySnapshot.docs.map((doc) => {
        return { id: doc.id, ...doc.data() } as Group;
      });

      return data; // Return the fetched data
    } catch (error) {
      console.error("Error fetching sub-groups:", error);
      return []; // Return an empty array in case of error
    }
  };

  const getAllGroupsByOrgId = async (orgId: string): Promise<Group[]> => {
    try {
      if (!orgId) {
        console.error("Selected organisation ID is not defined");
        return []; // Return an empty array if organisation ID is not defined
      }

      const querySnapshot = await projectFirestore
        .collection("organisations")
        .doc(orgId)
        .collection("groups")

        .get(); // Use .get() for a one-time fetch

      const data = querySnapshot.docs.map((doc) => {
        return { id: doc.id, ...doc.data() } as Group;
      });

      return data; // Return the fetched data
    } catch (error) {
      console.error("Error fetching sub-groups:", error);
      return []; // Return an empty array in case of error
    }
  };

  const getAllSubGroups = async () => {
    try {
      if (!selectedOrganisation?.id) {
        console.error("Selected organisation ID is not defined");
        return;
      }
      const unsubscribe = projectFirestore
        .collectionGroup("SubGroups")
        .where("organisationID", "==", selectedOrganisation.id)

        .onSnapshot(
          (snapshot) => {
            const data = snapshot.docs.map((doc) => {
              return doc.data() as SubGroup;
            });
            setSubGroups(data);
          },
          (error) => {
            console.error("Error with Firestore onSnapshot:", error); // Log errors from Firestore
          }
        );

      // Clean up the listener when the component unmounts
      return () => unsubscribe();
    } catch (error) {
      console.error("Error fetching groups:", error);
      // Handle errors if necessary
    }
  };

  const getAllSubGroupsByOrgId = async (orgId: string): Promise<SubGroup[]> => {
    try {
      if (!orgId) {
        console.error("Selected organisation ID is not defined");
        return []; // Return an empty array if organisation ID is not defined
      }

      const querySnapshot = await projectFirestore
        .collectionGroup("SubGroups")
        .where("organisationID", "==", orgId)

        .get(); // Use .get() for a one-time fetch

      const data = querySnapshot.docs.map((doc) => {
        return { id: doc.id, ...doc.data() } as SubGroup;
      });

      return data; // Return the fetched data
    } catch (error) {
      console.error("Error fetching sub-groups:", error);
      return []; // Return an empty array in case of error
    }
  };

  const getAllSubGroupsByGroupId = async (
    groupId: string
  ): Promise<SubGroup[]> => {
    try {
      if (!selectedOrganisation?.id) {
        console.error("Selected organisation ID is not defined");
        return [];
      }

      const snapshot = await projectFirestore
        .collection("organisations")
        .doc(selectedOrganisation.id)
        .collection("groups")
        .doc(groupId)
        .collection("SubGroups")
        .get(); // Use .get() instead of onSnapshot for one-time fetch

      const data = snapshot.docs.map((doc) => {
        const docData = doc.data();
        return { id: doc.id, ...docData } as SubGroup;
      });

      return data;
    } catch (error) {
      console.error("Error fetching sub-groups:", error);
      return []; // Return an empty array in case of error
    }
  };

  const joinOrganitionsGroup = async (userID: string, groupID: string) => {
    try {
      // Get the group document
      const groupRef = projectFirestore
        .collection("organisations")
        .doc(selectedOrganisation?.id)
        .collection("groups")
        .doc(groupID);

      const groupSnapshot = await groupRef.get();

      if (groupSnapshot.exists) {
        // Get the current data from the document
        const groupData = groupSnapshot.data();

        if (groupData && groupData.users && Array.isArray(groupData.users)) {
          // Update the users array with the new userID
          const updatedUsers = [...groupData.users, userID];

          // Update the users field in the document
          await groupRef.update({ users: updatedUsers });
        } else {
          console.debug("Invalid group data or users array.");
        }
      } else {
        console.debug("Group not found.");
      }
      getGroups();
    } catch (error) {
      console.error("Error joining group:", error);
    }
  };

  const joinOrganitionsIdAndGroupId = async (
    userID: string,
    orgId: string,
    groupID: string
  ) => {
    try {
      // Get the group document
      const groupRef = projectFirestore
        .collection("organisations")
        .doc(orgId)
        .collection("groups")
        .doc(groupID);

      const groupSnapshot = await groupRef.get();

      if (groupSnapshot.exists) {
        // Get the current data from the document
        const groupData = groupSnapshot.data();

        if (groupData && groupData.users && Array.isArray(groupData.users)) {
          // Update the users array with the new userID
          const updatedUsers = [...groupData.users, userID];

          // Update the users field in the document
          await groupRef.update({ users: updatedUsers });
        } else {
          console.debug("Invalid group data or users array.");
        }
      } else {
        console.debug("Group not found.");
      }
      getGroups();
    } catch (error) {
      console.error("Error joining group:", error);
    }
  };

  const getGroupOrgIDAndByGroupName = async (
    orgId: string,
    GroupName: string
  ) => {
    try {
      const data = await projectFirestore
        .collection("organisations")
        .doc(orgId)
        .collection("groups")
        .where("groupName", "==", GroupName)
        .get();

      return data.docs.map((doc) => doc.id);
    } catch (error) {
      console.error("Error fetching data:", error);
      return []; // Return an empty array or appropriate error handling in case of an error
    }
  };

  const getGroupOrgByGroupName = async (orgId: string, groupName: string) => {
    try {
      const data = await projectFirestore
        .collection("organisations")
        .doc(orgId)
        .collection("groups")
        .get();

      const filteredGroups = data.docs.filter((doc) =>
        doc.data().groupName.toLowerCase().includes(groupName.toLowerCase())
      );
      return filteredGroups;
    } catch (error) {
      console.error("Error fetching data:", error);
      return [];
    }
  };

  const getGroupIDBySubName = async (subGroupName: string) => {
    try {
      const data = await projectFirestore
        .collection("organisations")
        .doc(selectedOrganisation?.id)
        .collection("groups")
        .where("subGroupName", "==", subGroupName)
        .get();

      return data.docs.map((doc) => doc.id);
    } catch (error) {
      console.error("Error fetching data:", error);
      return []; // Return an empty array or appropriate error handling in case of an error
    }
  };

  const getSubGroupByName = async (orgId: string, subGroupName: string) => {
    try {
      const data = await projectFirestore
        .collectionGroup("SubGroups")
        .where("organisationID", "==", orgId)
        .get();

      const filteredSubGroups = data.docs.filter(
        (doc) =>
          doc.data().groupName &&
          doc
            .data()
            .groupName.toLowerCase()
            .includes(subGroupName.toLowerCase())
      );

      return filteredSubGroups;
    } catch (error) {
      console.error("Error fetching data:", error);
      return [];
    }
  };

  const getSubGroups = async (groupName: string) => {
    const subGroupsArray: Array<Group> = []; // Initialize subGroupsArray

    try {
      const ref = await projectFirestore // Wait for the query to complete
        .collection("organisations")
        .doc(selectedOrganisation?.id)
        .collection("groups")
        .where("groupName", "==", groupName)
        .get();

      if (!ref.empty) {
        ref.forEach((subGroup) => {
          return subGroupsArray.push(subGroup.data() as Group);
        });
      }

      return subGroupsArray; // Return the subGroupsArray
    } catch (error) {
      console.error("Error fetching subgroups:", error);
      return []; // Return an empty array in case of an error or no results
    }
  };

  const getSubGroupsById = async (groupID: string) => {
    const subGroupsArray: Array<SubGroup> = []; // Initialize subGroupsArray
    if (groupID) {
      try {
        const ref = await projectFirestore // Wait for the query to complete
          .collection("organisations")
          .doc(selectedOrganisation?.id)
          .collection("groups")
          .doc(groupID)
          .collection("SubGroups")
          .get();

        if (!ref.empty) {
          ref.forEach((subGroup) => {
            return subGroupsArray.push(subGroup.data() as SubGroup);
          });
        }

        return subGroupsArray; // Return the subGroupsArray
      } catch (error) {
        console.error("Error fetching subgroups:", error);
        return []; // Return an empty array in case of an error or no results
      }
    }
  };

  const addGroup = async (
    groupName: string,
    subscriptionType: string,
    dateExpire: string
  ) => {
    try {
      // Check if the groupName already exists
      const duplicateGroup = await projectFirestore
        .collection("organisations")
        .doc(selectedOrganisation?.id)
        .collection("groups")
        .where("groupName", "==", groupName)
        .get();

      if (!duplicateGroup.empty) {
        // If a group with the same name already exists, handle the duplication accordingly
        toast.error("Group name already exists.");
        // You can return an error message or handle it in any way you prefer.
        return;
      }

      // If the groupName is unique, proceed to add the new group
      const data = {
        groupName,
        organisationId: selectedOrganisation?.id,
        dateExpire,
        subscriptionType,
      };

      const ref = await projectFirestore
        .collection("organisations")
        .doc(selectedOrganisation?.id)
        .collection("groups")
        .add(data);
      toast.success("Group was added successfully!");
      return ref.update({ id: ref.id });
    } catch (error) {
      console.error(error);
      // Handle other errors if necessary
    }
  };

  const updateGroup = async (
    groupName: string,
    subscriptionType: string,
    dateExpire: string | undefined,
    groupID: string
  ) => {
    try {
      // If the groupName is unique, proceed to add the new group
      const data = {
        groupName,
        dateExpire,
        subscriptionType,
      };

      if (groupID !== "") {
        await projectFirestore
          .collection("organisations")
          .doc(selectedOrganisation?.id)
          .collection("groups")
          .doc(groupID)
          .update(data);
        getGroups();
        toast.success("Group was updated successfully!");
      } else {
        toast.error("Error updating data.");
      }

      return;
    } catch (error) {
      console.error(error);
      // Handle other errors if necessary
    }
  };

  const updateSubGroup = async (
    groupName: string,
    subscriptionType: string,
    dateExpire: string | undefined,
    groupID: string,
    subGroupID: string
  ) => {
    try {
      // If the groupName is unique, proceed to add the new group
      const data = {
        groupName,
        dateExpire,
        subscriptionType,
      };

      if (groupID !== "" && subGroupID !== "") {
        await projectFirestore
          .collection("organisations")
          .doc(selectedOrganisation?.id)
          .collection("groups")
          .doc(groupID)
          .collection("SubGroups")
          .doc(subGroupID)
          .update(data);
        getAllSubGroups();
        toast.success("Sub-group was updated successfully!");
      } else {
        toast.error("Error updating data.");
      }

      return;
    } catch (error) {
      console.error(error);
      // Handle other errors if necessary
    }
  };

  const addSubGroup = async (
    groupID: string,
    groupName: string,
    subscriptionType: string,
    dateExpire: string
  ) => {
    try {
      // Check if the groupName already exists
      const duplicateGroup = await projectFirestore
        .collection("organisations")
        .doc(selectedOrganisation?.id)
        .collection("groups")
        .doc(groupID)
        .collection("SubGroups")
        .where("groupName", "==", groupName)
        .get();

      if (!duplicateGroup.empty) {
        // If a group with the same name already exists, handle the duplication accordingly
        toast.error("Sub-Group name already exists.");
        // You can return an error message or handle it in any way you prefer.
        return;
      }

      // If the groupName is unique, proceed to add the new group
      const data = {
        groupName,
        groupID,
        organisationID: selectedOrganisation?.id,
        dateExpire,
        subscriptionType,
        invites: [],
        users: [],
      };

      const ref = await projectFirestore
        .collection("organisations")
        .doc(selectedOrganisation?.id)
        .collection("groups")
        .doc(groupID)
        .collection("SubGroups")
        .add(data);
      toast.success("Sub-Group was added successfully!");
      return ref.update({ id: ref.id });
    } catch (error) {
      console.error(error);
      // Handle other errors if necessary
    }
  };

  const addUsersToGroup = async (
    groupID: string,
    subGroupID: string,
    users: string[]
  ) => {
    try {
      if (selectedOrganisation?.id && groupID !== "" && users.length > 0) {
        const groupRef = projectFirestore
          .collection("organisations")
          .doc(selectedOrganisation?.id)
          .collection("groups")
          .doc(groupID);

        if (subGroupID === "") {
          // If subGroupID is empty, add users to the groups collection
          const existingUsers = (await groupRef.get()).data()?.users || [];

          await groupRef.update({
            users: Array.from(new Set([...existingUsers, ...users])),
          });
        } else {
          // If subGroupID is not empty, add users to the SubGroups collection
          const subGroupRef = groupRef.collection("SubGroups").doc(subGroupID);
          const existingUsers = (await subGroupRef.get()).data()?.users || [];

          await subGroupRef.update({
            users: Array.from(new Set([...existingUsers, ...users])),
          });
        }

        toast.success("Users were added successfully!");
      } else {
        toast.error("Error adding users.");
      }
    } catch (error) {
      console.error(error);
    }
  };

  const removeUserFromGroup = async (
    groupID: string,
    subGroupID: string,
    userId: string
  ) => {
    try {
      if (selectedOrganisation?.id && groupID !== "" && userId !== "") {
        const groupRef = projectFirestore
          .collection("organisations")
          .doc(selectedOrganisation?.id)
          .collection("groups")
          .doc(groupID);

        if (subGroupID === "") {
          // Handle the main group
          const groupSnapshot = await groupRef.get();
          const existingUsers = groupSnapshot.data()?.users || [];
          const existingLeaders = groupSnapshot.data()?.groupLeaders || [];

          const updatedUsers = existingUsers.filter((user) => user !== userId);
          const updatedLeaders = existingLeaders.filter(
            (leader) => leader !== userId
          );

          const updateData: { users?: string[]; groupLeaders?: string[] } = {};
          updateData.users = updatedUsers;
          updateData.groupLeaders = updatedLeaders;

          if (Object.keys(updateData).length > 0) {
            await groupRef.update(updateData);
          }
        } else {
          // Handle the sub-group
          const subGroupRef = groupRef.collection("SubGroups").doc(subGroupID);
          const subGroupSnapshot = await subGroupRef.get();
          const subGroupUsers = subGroupSnapshot.data()?.users || [];
          const subGroupLeaders = subGroupSnapshot.data()?.groupLeaders || [];

          const updatedSubGroupUsers = subGroupUsers.filter(
            (user) => user !== userId
          );
          const updatedSubGroupLeaders = subGroupLeaders.filter(
            (leader) => leader !== userId
          );

          const subGroupUpdateData: {
            users?: string[];
            groupLeaders?: string[];
          } = {};
          subGroupUpdateData.users = updatedSubGroupUsers;
          subGroupUpdateData.groupLeaders = updatedSubGroupLeaders;

          if (Object.keys(subGroupUpdateData).length > 0) {
            await subGroupRef.update(subGroupUpdateData);
          }
        }

        toast.success(`User was removed successfully!`);
      } else {
        toast.error("Error removing user.");
      }
    } catch (error) {
      console.error(error);
      toast.error("Error removing user.");
    }
  };

  const deleteGroup = async (groupID: string, subGroupID: string) => {
    try {
      if (groupID !== "") {
        if (subGroupID !== "") {
          // If both groupID and subGroupID are provided, delete the subGroup
          await projectFirestore
            .collection("organisations")
            .doc(selectedOrganisation?.id)
            .collection("groups")
            .doc(groupID)
            .collection("SubGroups")
            .doc(subGroupID)
            .delete();
          toast.success("SubGroup was deleted successfully!");
        } else {
          // If only groupID is provided, delete the entire group
          await projectFirestore
            .collection("organisations")
            .doc(selectedOrganisation?.id)
            .collection("groups")
            .doc(groupID)
            .delete();
          toast.success("Group was deleted successfully!");
        }
      } else {
        toast.error("Error deleting the group.");
      }
    } catch (error) {
      console.error(error);
      toast.error("Error deleting the group.");
    }
  };

  const getSubUser = async (
    userID: string
  ): Promise<GroupAndSubgroup | null> => {
    try {
      let result: GroupAndSubgroup | null = null;

      const groupsSnapshot = await projectFirestore
        .collection("organisations")
        .doc(selectedOrganisation?.id)
        .collection("groups")
        .get();

      for (const groupDoc of groupsSnapshot.docs) {
        const subgroupsSnapshot = await groupDoc.ref
          .collection("SubGroups")
          .get();

        for (const subgroupDoc of subgroupsSnapshot.docs) {
          const usersArray = subgroupDoc.data().users;

          if (usersArray && usersArray.includes(userID)) {
            result = {
              groupDoc: groupDoc.data() as Group, // Replace with your actual type
              subgroupDoc: subgroupDoc.data() as SubGroup,
            };
            // console.log(`User ${userID} found in subgroup ${subgroupDoc.id}`);
            break; // Exit the inner loop once user is found
          }
        }

        if (result) {
          // Exit the outer loop once user is found
          break;
        }
      }

      return result;
    } catch (error) {
      console.error("Error getting subgroups:", error);
      return null;
    }
  };

  const assignGroupLeader = async (
    groupId: string | undefined,
    userIds: string[]
  ) => {
    try {
      projectFirestore
        .collection("organisations")
        .doc(selectedOrganisation?.id)
        .collection("groups")
        .doc(groupId)
        .update({
          groupLeaders: userIds,
        });
    } catch (error) {
      console.error(error);
    }
  };

  const assignSubGroupLeader = async (
    groupId: string | undefined,
    subGroupID: string | undefined,
    userIds: string[]
  ) => {
    try {
      projectFirestore
        .collection("organisations")
        .doc(selectedOrganisation?.id)
        .collection("groups")
        .doc(groupId)
        .collection("SubGroups")
        .doc(subGroupID)
        .update({
          groupLeaders: userIds,
        });
    } catch (error) {
      console.error(error);
    }
  };

  const getGroupsWhereUserIsGroupLeader = async (
    organisationID: string | undefined,
    userId: string
  ) => {
    try {
      const snapshot = await projectFirestore
        .collection("organisations")
        .doc(organisationID)
        .collection("groups")
        .where("groupLeaders", "array-contains", userId)
        .get();

      const documents = snapshot.docs.map((doc) => ({
        id: doc.id,
        ...doc.data(),
      })) as Group[];

      return documents;
    } catch (error) {
      console.error(
        "Error fetching documents where user is a group leader:",
        error
      );
      return [];
    }
  };

  const getSubGroupsWhereUserIsGroupLeader = async (
    organisationID: string | undefined,
    userId: string
  ) => {
    try {
      const snapshot = await projectFirestore
        .collectionGroup("SubGroups")
        .where("organisationID", "==", organisationID)
        .where("groupLeaders", "array-contains", userId)
        .get();

      const documents = snapshot.docs.map((doc) => ({
        id: doc.id,
        ...doc.data(),
      })) as SubGroup[];

      return documents;
    } catch (error) {
      console.error(
        "Error fetching documents where user is a group leader:",
        error
      );
      return [];
    }
  };

  const getDocumentsWhereUserIsGroupLeader = async (
    organisationID: string,
    userId: string
  ) => {
    try {
      const snapshot = await projectFirestore
        .collectionGroup("SubGroups")
        .where("organisationID", "==", organisationID)
        .where("groupLeaders", "array-contains", userId)
        .get();

      const documents = snapshot.docs.map((doc) => ({
        id: doc.id,
        ...doc.data(),
      })) as SubGroup[];

      return documents;
    } catch (error) {
      console.error(
        "Error fetching documents where user is a group leader:",
        error
      );
      return [];
    }
  };

  const getUsersGroups = async (organisationID: string, userId: string) => {
    try {
      const snapshot = await projectFirestore
        .collectionGroup("groups")
        .where("organisationId", "==", organisationID)
        .where("users", "array-contains", userId)
        .get();

      const documents = snapshot.docs.map((doc) => ({
        id: doc.id,
        ...doc.data(),
      })) as Group[];

      return documents;
    } catch (error) {
      console.error(
        "Error fetching documents where user is a group leader:",
        error
      );
      return [];
    }
  };

  const getUsersSubGroups = async (organisationID: string, userId: string) => {
    try {
      const snapshot = await projectFirestore
        .collectionGroup("SubGroups")
        .where("organisationID", "==", organisationID)
        .where("users", "array-contains", userId)
        .get();

      const documents = snapshot.docs.map((doc) => ({
        id: doc.id,
        ...doc.data(),
      })) as SubGroup[];

      return documents;
    } catch (error) {
      console.error(
        "Error fetching documents where user is a group leader:",
        error
      );
      return [];
    }
  };

  useEffect(() => {
    if (!selectedOrganisation) return;
    if (selectedOrganisation?.id !== "") {
      getGroups();
      getAllSubGroups();
    }

    // eslint-disable-next-line
  }, [selectedOrganisation]);

  return {
    addGroup,
    addSubGroup,
    getGroups,
    getSubGroupsById,
    groups,
    subGroups,
    getSubGroups,
    getGroupIDBySubName,
    getSubGroupByName,
    joinOrganitionsGroup,
    addUsersToGroup,
    deleteGroup,
    updateGroup,
    updateSubGroup,
    getSubUser,
    groupNames,
    removeUserFromGroup,
    setGroupNames,
    assignGroupLeader,
    assignSubGroupLeader,
    getDocumentsWhereUserIsGroupLeader,
    getGroupsWhereUserIsGroupLeader,
    getSubGroupsWhereUserIsGroupLeader,
    getAllSubGroupsByGroupId,
    getAllGroups,
    getUsersSubGroups,
    getUsersGroups,
    getGroupOrgIDAndByGroupName,
    getGroupOrgByGroupName,
    joinOrganitionsIdAndGroupId,
    getAllGroupsByOrgId,
    getAllSubGroupsByOrgId,
  };
};

export default useGroup;
