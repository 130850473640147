import PathCard from "./PathCard";
import TitleBox from "./TitleBox";
import BackAndNextFlat from "components/utility/BackAndNextFlat";
import { ComponentProps } from "../SelfAwarenessComponentMapping";
import { FC, useEffect, useState } from "react";
import { useSelfAwarenessContext } from "hooks/useSelfAwarenessContext";
import InfoModal from "./InfoModal";
import { useSelfAwareness } from "hooks/useSelfAwareness";
import { DocumentData } from "firebase/firestore";
import SimpleLoader from "components/Loaders/SimpleLoader";

const MultiplePathSelector: FC<ComponentProps> = ({
  backAndNextHandler,
  setHasModal,
}) => {
  const { journal } = useSelfAwarenessContext();
  const { getPathQuestions } = useSelfAwareness();
  const [selectedItem, setSelectedItem] = useState<string | null>(null);
  const [show, setShow] = useState(false);
  const [questions, setQuestions] = useState<DocumentData[]>([]);
  const handleCardSelect = (title: string) => {
    setSelectedItem((prevSelectedItem) =>
      prevSelectedItem === title ? null : title
    );

    const storedJournal = localStorage.getItem("SelfAwarenessJournal");
    const existingJournal = storedJournal ? JSON.parse(storedJournal) : {};

    const selectedQuestion = questions.find(
      (question) => question.title.trim() === title.trim()
    );

    const parser = new DOMParser();
    const parsedDocument = parser.parseFromString(title, "text/html");

    // Extract the text content from the parsed document
    const parsedTitle = parsedDocument.body.textContent || "";

    // Update the existing journal with the new answer
    const updatedJournal = {
      ...existingJournal,
      path: parsedTitle,
      question: (selectedQuestion && selectedQuestion.question) || "",
    };

    // Store the updated journal in local storage
    localStorage.setItem(
      "SelfAwarenessJournal",
      JSON.stringify(updatedJournal)
    );
  };

  const getSetPathQuestions = async () => {
    if (journal && journal.id) {
      const _questions = await getPathQuestions(journal?.id);

      setQuestions(_questions);
    }
  };

  const handler = () => {
    setShow(!show);
    setHasModal(!show);
  };

  useEffect(() => {
    if (!journal) return;
    getSetPathQuestions();
    // eslint-disable-next-line
  }, [journal]);

  return (
    <>
      {journal && <InfoModal handler={handler} show={show} journal={journal} />}
      {journal && (
        <div className="flex flex-col gap-4 my-5">
          <TitleBox
            title={journal.pathSelectorTitle || ""}
            journal={journal}
            showInfo={true}
            handler={handler}
          />
          <div className="grid grid-cols-2 gap-2 justify-center items-center my-8 mx-auto">
            {questions &&
              questions.length > 0 &&
              questions.map((question, index) => (
                <PathCard
                  key={index}
                  handleCardSelect={handleCardSelect}
                  pageType={journal.pageType}
                  content={question.title}
                  selectedItem={selectedItem}
                />
              ))}
          </div>

          {questions.length === 0 && (
            <div className="mb-10 flex justify-center items-center">
              <SimpleLoader />
            </div>
          )}
          <div className="my-8">
            <BackAndNextFlat
              {...backAndNextHandler}
              nextAllowed={selectedItem !== null ? true : false}
            />
          </div>
        </div>
      )}
    </>
  );
};

export default MultiplePathSelector;
