import { useEffect, useState } from "react";
import EmptySpace from "../../../components/utility/EmptySpace";
import classes from "./ChoosePathway.module.css";
import Pathways from "../../../components/ChoosePathway/Pathways";
import BackAndNext from "../../../components/utility/BackAndNext";
import useParticipants from "../../../hooks/useParticipants";
import { useAuthContext } from "hooks/useAuthContext";
import { CHOOSE_PATHWAY_TITLE } from "strings/ChoosePathway";
import Instruction from "components/utility/Instruction";
import React from "react";
import { ComponentProps } from "../GenericSessionPage/ComponentMapping";

const INSTRUCTIONS_CHOOSE_PATHWAY = "Which of the following is what you need?";

const ChoosePathwayComponent = ({ backAndNextHandler }: ComponentProps) => {
  const { profile } = useAuthContext();
  const { oldPath, updateSelectedPath } = useParticipants();

  const [currSelected, setCurrSelected] = useState<string>("");

  useEffect(() => {
    if (!profile?.uid) {
      return;
    }
    setCurrSelected(oldPath);
  }, [oldPath, profile?.uid]);

  const nextHandler = (e: React.MouseEvent<HTMLButtonElement, MouseEvent>) => {
    updateSelectedPath(currSelected);
    backAndNextHandler.nextCallback(e);
  };

  return (
    <React.Fragment>
      <EmptySpace height={"30px"} />
      <strong className={classes["title"]}>{CHOOSE_PATHWAY_TITLE}</strong>
      <Instruction text={INSTRUCTIONS_CHOOSE_PATHWAY} />
      <Pathways currSelected={currSelected} setCurrSelected={setCurrSelected} />
      <EmptySpace height={"30px"} />
      <BackAndNext
        {...backAndNextHandler}
        nextAllowed={!!currSelected && backAndNextHandler.nextAllowed}
        nextCallback={nextHandler}
      />
      <EmptySpace height={"50px"} />
      <EmptySpace height={"200px"} />
    </React.Fragment>
  );
};

export default ChoosePathwayComponent;
