import FiveStepHeader from "components/FiveStepHeader/FiveStepHeader";
import Header from "components/Header/Header";
import NoGCSessionWrapper from "pages/NoGCSession/NoGCSessionWrapper";
import React from "react";
import ContentWrapper from "../ContentWrapper";
import PageWrapper from "../PageWrapper";
import RedirectWrapper from "../RedirectWrapper";
import Steps from "../Steps";

interface SessionTemplateProps {
  currentStep: number;
  children: React.ReactNode;
  hasGuide?: boolean;
  hasLightning?: boolean;
  showCurrentStep?: boolean;
  removeNoGCWrapper?: boolean;
  hasLightBox?: boolean;
}

/**
 * Template that includes Header, FiveStepHeader, Steps, RedirectWrapper
 * @param {number} currentStep - 1 to 5 (for the Steps component)
 * @param {React.ReactNode} children - contents of the template
 * @param {boolean} hasGuide? - add the question mark symbol if true
 * @param {boolean} hasLightning? - add the lightning symbol if true
 * @param {boolean} showCurrentStep? - show the current step content if true
 * @param {boolean} removeNoGCWrapper? - removes NoGCSessionWrapper if true
 */
const MainSessionTemplate: React.FC<SessionTemplateProps> = ({
  currentStep,
  children,
  hasGuide,
  hasLightning,
  showCurrentStep,
  removeNoGCWrapper,
  hasLightBox,
}) => {
  return (
    <>
      {(removeNoGCWrapper && (
        <PageWrapper forSticky={true}>
          <Header hasModal={hasLightBox} />
          <FiveStepHeader
            hasGuide={hasGuide !== undefined ? hasGuide : true}
            hasLightning={hasLightning !== undefined ? hasLightning : true}
            currentStep={currentStep}
            showCurrentStep={showCurrentStep}
            hasModal={hasLightBox}
          />
          <ContentWrapper hasFiveStepper={true}>
            {showCurrentStep && <Steps count={5} currentStep={currentStep} />}
            <RedirectWrapper>{children}</RedirectWrapper>
          </ContentWrapper>
        </PageWrapper>
      )) || (
        <NoGCSessionWrapper>
          <PageWrapper forSticky={true}>
            <Header hasModal={hasLightBox} />
            <FiveStepHeader
              hasGuide={hasGuide ? hasGuide : true}
              hasLightning={hasLightning ? hasLightning : true}
              currentStep={currentStep}
              showCurrentStep={showCurrentStep}
            />
            <ContentWrapper hasFiveStepper={true}>
              {showCurrentStep && <Steps count={5} currentStep={currentStep} />}
              <RedirectWrapper>{children}</RedirectWrapper>
            </ContentWrapper>
          </PageWrapper>
        </NoGCSessionWrapper>
      )}
    </>
  );
};

export default MainSessionTemplate;
