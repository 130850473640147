import Header from "components/Header/Header";
import Center from "components/utility/Center/Center";
import EmptySpace from "components/utility/EmptySpace";
import PageWrapper from "components/utility/PageWrapper";
import { useAuthContext } from "hooks/useAuthContext";
import RequestVerifyEmailModal from "components/Journal/RequestVerifyEmailModal";
import { OrganisationContextProvider } from "context/OrganisationContext";

import { Suspense, lazy, useCallback, useEffect, useState } from "react";
import { getOrgsWithPermission } from "models/organisation";
import { P_VIEW_JOURNALS } from "models/permission";

const AllJournals = lazy(() => import("components/Journal/AllJournals"));

export default function Journal() {
  const { profile } = useAuthContext();
  const [hasLightBox, setHasLightBox] = useState(false);

  const fetchOrganisations = useCallback(async () => {
    if (!profile) {
      return [];
    }
    return getOrgsWithPermission(profile, P_VIEW_JOURNALS);
  }, [profile]);

  useEffect(() => {
    if (!profile) return;
    // eslint-disable-next-line
  }, [profile]);

  return (
    <PageWrapper>
      <Header hasModal={!profile?.isVerified || hasLightBox} />
      <RequestVerifyEmailModal show={!profile?.isVerified} />
      <EmptySpace height={"8vh"} />
      <Center>
        <OrganisationContextProvider fetchOrganisations={fetchOrganisations}>
          <Suspense fallback={<div>Loading...</div>}>
            <AllJournals setHasLightBox={setHasLightBox} />
          </Suspense>
        </OrganisationContextProvider>
      </Center>
    </PageWrapper>
  );
}
