/* eslint-disable react-hooks/exhaustive-deps */
import React, { useEffect, useState } from "react";
import { useAuthContext } from "hooks/useAuthContext";
import { useLocation, useNavigate } from "react-router-dom";
import { END_OF_SESSION_ROUTE } from "strings/Routes";
import GCNotOngoingWrapper from "./GCNotOngoingWrapper";

interface Props {
  children: React.ReactNode;
}

/**
 * Checks if the user is currently in a GrowthCircles Session and navigates to error page if GC Session is null
 * if not in one session. This is neccesary because some pages will have errors if the
 * GrowthCirclesSession does not exist
 * @param children Other react components
 */
const NoGCSessionWrapper: React.FC<Props> = ({ children }) => {
  const { profile } = useAuthContext();
  const location = useLocation();
  const navigate = useNavigate();
  const [checked, setChecked] = useState(false);
  const [sessionExist, setSessionExist] = useState(false);

  useEffect(() => {
    if (!profile || checked) {
      return;
    }
    if (profile && profile.growthCircle) setSessionExist(true);
    setChecked(true);
  }, [profile]);

  useEffect(() => {
    if (!checked) return;
    if (sessionExist && checked) return;
    navigate("/session-does-not-exist");
  }, [sessionExist, checked]);

  if (!checked) return null;

  if (!sessionExist) {
    return null;
  } else {
    return (
      <>
        {(location.pathname === END_OF_SESSION_ROUTE && children) || (
          <GCNotOngoingWrapper growthCircleId={profile!.growthCircle}>
            {children}
          </GCNotOngoingWrapper>
        )}
      </>
    );
  }
};

export default NoGCSessionWrapper;
