import RatingIconQuestion from "components/FeedBackForm/RatingIconQuestion";
import SliderQuestion from "components/FeedBackForm/SliderQuestion";
import SmileyRatingQuestion from "components/FeedBackForm/SmileyRatingQuestion";
import TextAreaQuestion from "components/FeedBackForm/TextAreaQuestion";
import TextQuestion from "components/FeedBackForm/TextQuestion";
import SimpleLoader from "components/Loaders/SimpleLoader";

import { Modal } from "flowbite-react";
import useBadges from "hooks/feedback/useBadges";
import useFeedback from "hooks/feedback/useFeedback";
import { useOrgFeedbackQuestions } from "hooks/organisation/useOrgFeedbackQuestions";
import usePromise from "hooks/utility/usePromise";

import { FeedbackQuestion } from "models/componentSettings/feedback/utility/feedback";
import { Role } from "models/organisationRole";

import { ChangeEvent, ReactNode, useEffect, useState } from "react";
import toast from "react-hot-toast";
import { FaEdit, FaTimes } from "react-icons/fa";
import { HiOutlineExclamationCircle } from "react-icons/hi";
import useParticipants from "hooks/useParticipants";
import { useAuthContext } from "hooks/useAuthContext";

import InputField from "components/utility/Forms/InputField";
import { Participant } from "interface/ParticipantInterface";
import { FeedbackLayout } from "interface/FeedbackLayoutInterface";
import MultipleChoice from "components/FeedBackForm/MultipleChoice";
import { AiFillCloseCircle } from "react-icons/ai";
import classes from "pages/AllCircles/Admin/Admin.module.css";
import TextAreaMax from "../WelcomeSettings/components/TextAreaMax";
import YesOrNo from "components/FeedBackForm/YesOrNo";
import { v4 as uuidv4 } from "uuid";
import SpecialThanks from "components/SpecialThanks/SpecialThanks";
import SmileyRatingQuestionWithMax from "components/FeedBackForm/SmileyRatingQuestionWithMax";

export type SmileyRatingQuestionProps = {
  viewOnly?: boolean;
  sectionId: string;
  questionCategory?: string;
  questions?: FeedbackQuestion[];
  sessionRole?: Role | null;
  children?: ReactNode;
  label?: string;
  editable?: boolean;
  feedbackResponses?: Object;
  participants?: Participant[];
  FeedbackLayout?: FeedbackLayout[];
  setHasModal?: (b: boolean) => any;
  adminEditing?: boolean;
};

export default function QuestionViewGroup({
  children,
  label = "",
  questionCategory = "",
  questions = [],
  sessionRole,
  editable,
  sectionId,
  viewOnly,
  feedbackResponses,
  participants,
  FeedbackLayout,
  setHasModal,
  adminEditing,
}: SmileyRatingQuestionProps) {
  const defaultResponses = {};
  questions.forEach(
    (question) =>
      (defaultResponses[question.questionName] = question.defaultResponse)
  );
  const { values, setValue } = useFeedback(
    questionCategory,
    defaultResponses ?? 1
  );

  const { ParticipantRecords } = useParticipants();
  const { profile } = useAuthContext();

  const sortedParticipantRecords = [...ParticipantRecords].sort((a, b) => {
    if (a.userId === profile?.uid) return 1;
    if (b.userId === profile?.uid) return -1;
    return 0;
  });

  const [question, setQuestion] = useState<FeedbackQuestion>();
  const [deleteShow, setDeleteShow] = useState(false);
  const { isLoading, resolve } = usePromise();
  const { isLoading: isEditing, resolve: editing } = usePromise();
  const [maxRating, setMaxRating] = useState<number | undefined>(5);
  const { deleteQuestionFeedback, updateQuestionFeedback } =
    useOrgFeedbackQuestions();
  const { badges, toggleBadge } = useBadges();
  const deleteHandler = async (question: FeedbackQuestion) => {
    setQuestion(question);
    setDeleteShow(!deleteShow);
  };

  const [editTitle, setEditTitle] = useState<string>("");
  const [questionType, setQuestionType] = useState<string>("");
  const [questionID, setQuestionID] = useState<string>("");

  const editHandler = async (question: FeedbackQuestion) => {
    setQuestion(question);
    setEditTitle(question.question.default);
    setQuestionType(question.type);
    setQuestionID(question.questionName);
    setShowEdit(!showEdit);
    setMaxRating(question.maxValue ?? 0);
  };

  const [showEdit, setShowEdit] = useState(false);

  const deleteQuestionHandler = () => {
    if (question)
      resolve(() =>
        deleteQuestionFeedback(sectionId, question?.questionName)
      ).then(() => {
        toast.success("Question was deleted successfully");
        setDeleteShow(!deleteShow);
      });
  };

  const editSubmitHandler = async () => {
    if (question) {
      editing(() =>
        updateQuestionFeedback(
          sectionId,
          editTitle,
          questionType,
          questionID,
          question.uid
        )
      ).then(() => {
        toast.success("Question was updated successfully");
        setShowEdit(!showEdit);
      });
    }
  };

  const setMaxRatingHandler = (e: ChangeEvent<HTMLInputElement>) => {
    const value = e.target.value;
    const numericValue = Number(value);

    if (value === "") {
      setMaxRating(undefined); // Or set to a default value if you prefer
      toast.error("Value must be a number.");
    } else if (!isNaN(numericValue) && numericValue > 0) {
      setMaxRating(numericValue);
    } else {
      toast.error("Value must be a number.");
    }
  };

  const setSliderMaxRatingHandler = (e: ChangeEvent<HTMLInputElement>) => {
    const value = e.target.value;
    const numericValue = Number(value);

    if (value === "") {
      setMaxRating(undefined); // Or set to a default value if you prefer
      toast.error("Value must be a number.");
    } else if (!isNaN(numericValue) && numericValue > 0 && numericValue < 11) {
      setMaxRating(numericValue);
    } else {
      toast.error("Value must be a number between 1 and 10.");
    }
  };

  // Function to calculate the average of a specific section and key across participants
  // Function to calculate the average of a specific section and key across participants
  function calculateAverageForSectionAndKey(
    participants: Participant[],
    section: string,
    key: string,
    defaultResponse: string | number | string[]
  ) {
    let sum = 0;
    let count = 0;

    participants.forEach((participant) => {
      if (
        participant.feedbackReflection &&
        participant.feedbackReflection[section] &&
        participant.feedbackReflection[section][key]
      ) {
        const value = participant.feedbackReflection[section][key];
        const numericValue = Number(value);

        if (!isNaN(numericValue)) {
          sum += numericValue;
          count++;
        }
      } else {
        // Use the defaultResponse if the key is missing or the value is not numeric
        const numericDefault = Number(defaultResponse);

        if (!isNaN(numericDefault)) {
          sum += numericDefault;
          count++;
        }
      }
    });

    if (count === 0) {
      return 0; // Avoid division by zero
    }

    return sum / count;
  }

  useEffect(() => {
    if (!ParticipantRecords) return;
    if (!profile) return;

    // eslint-disable-next-line
  }, [profile, ParticipantRecords]);

  const labelChangeHandler = (value: string) => {
    setEditTitle(value);
  };

  return (
    <div className="text-center w-full min-w-[360px] max-w-[290px] md:max-w-[360px] py-8">
      <Modal
        show={deleteShow}
        size="xl"
        popup
        onClose={() => setDeleteShow(!deleteShow)}
      >
        <Modal.Body className="relative py-10 rounded-md">
          <>
            {!isLoading ? (
              <>
                {" "}
                <AiFillCloseCircle
                  size={35}
                  color="var(--icon-colour-0)"
                  onClick={() => setDeleteShow(!deleteShow)}
                  className="absolute top-2 right-2 cursor-pointer"
                />
                <div
                  className={`${classes["container"]} text-center flex justify-center flex-col items-center`}
                >
                  <HiOutlineExclamationCircle
                    size={82}
                    className="mx-auto mb-4  text-gray-400 dark:text-gray-200"
                  />
                  <h3 className="mb-5 text-lg font-normal text-gray-500 dark:text-gray-400">
                    Are you certain about your decision to delete the{" "}
                    <strong> {question?.question.default}</strong> question?
                    Please be aware that this action could have repercussions
                    make sure that there is no ongoing session before
                    proceeding.
                  </h3>

                  <div className="flex justify-center gap-4">
                    <button
                      className={`${classes["button"]}`}
                      color="failure"
                      onClick={deleteQuestionHandler}
                    >
                      Yes, I'm sure
                    </button>
                    <button
                      className={`${classes["button"]}`}
                      color="gray"
                      onClick={() => setDeleteShow(!deleteShow)}
                    >
                      No, cancel
                    </button>
                  </div>
                </div>
              </>
            ) : (
              <SimpleLoader center={true} />
            )}
          </>
        </Modal.Body>
      </Modal>

      <Modal
        show={showEdit}
        size="2xl"
        popup
        onClose={() => setShowEdit(!showEdit)}
      >
        <Modal.Body className="relative py-10 bg-default rounded-lg">
          <>
            {!isEditing ? (
              <>
                {" "}
                <AiFillCloseCircle
                  size={35}
                  color="var(--icon-colour-0)"
                  onClick={() => setShowEdit(!showEdit)}
                  className="absolute top-2 right-2 cursor-pointer"
                />
                <div
                  className={`${classes["container"]} text-center flex flex-col items-start`}
                >
                  <div className="text-left">
                    <label htmlFor="title" className="py-2 font-semibold">
                      Question ID
                    </label>
                    <InputField
                      value={questionID}
                      onChange={(e: React.ChangeEvent<HTMLInputElement>) =>
                        setQuestionID(e.target.value)
                      }
                      isRequired={true}
                    />
                  </div>
                  <label htmlFor="title" className="py-2 font-semibold">
                    Question Label
                  </label>
                  <div className="w-full">
                    <TextAreaMax
                      valueHandler={labelChangeHandler}
                      maxLength={150}
                      value={editTitle}
                      row={5}
                    />
                  </div>
                  <div className="text-left py-4">
                    <label htmlFor="title" className="py-2 font-semibold">
                      Question Type
                    </label>
                    <InputField
                      type={"select"}
                      onChange={(e: ChangeEvent<HTMLSelectElement>) =>
                        setQuestionType(e.target.value)
                      }
                      value={questionType}
                      options={[
                        "text",
                        "textArea",
                        "smileyRating",
                        "smileyRatingWithMax",
                        "slider",
                        "sliderWithMax",
                        "badgeRating",
                        "multipleChoice",
                        "yesOrNo",
                        "SpecialThanks",
                      ]}
                    />
                  </div>
                  {questionType === "smileyRatingWithMax" && (
                    <div className="text-left py-2">
                      <label htmlFor="title" className="py-2 font-semibold">
                        Max Rating
                      </label>
                      <InputField
                        required={true}
                        value={maxRating}
                        onChange={(e: ChangeEvent<HTMLInputElement>) =>
                          setMaxRatingHandler(e)
                        }
                        text="Specify Max Rating"
                      />
                    </div>
                  )}

                  {questionType === "sliderWithMax" && (
                    <div className="py-2">
                      <label className="py-4">
                        Max Rating <small>(Max 8)</small>
                      </label>

                      <InputField
                        required={true}
                        value={maxRating}
                        type="number"
                        max={10}
                        min={1}
                        onChange={(e: ChangeEvent<HTMLInputElement>) =>
                          setSliderMaxRatingHandler(e)
                        }
                        text="Specify Max Rating"
                      />
                    </div>
                  )}

                  <button
                    disabled={isEditing}
                    className={`${classes["button"]}`}
                    onClick={editSubmitHandler}
                  >
                    {isEditing ? "Saving..." : "Update"}
                  </button>
                </div>
              </>
            ) : (
              <SimpleLoader center={true} />
            )}
          </>
        </Modal.Body>
      </Modal>

      {label && (
        <p
          style={{ color: "var(--main-colour)" }}
          className={`text-lg font-semibold text-center`}
        >
          {label}
        </p>
      )}
      {questions.map((questionDetails, index) => {
        const commonProps = {
          label:
            questionDetails.question[sessionRole?.name ?? ""] ??
            questionDetails.question.default,
          key: questionDetails.questionName,
        };

        const average =
          participants &&
          feedbackResponses &&
          calculateAverageForSectionAndKey(
            participants,
            questionCategory,
            questionDetails.questionName,
            questionDetails.defaultResponse
          );

        switch (questionDetails.type) {
          case "text":
            return (
              <div className="relative" key={index}>
                {editable && (
                  <div className="absolute  top-2 -right-8 flex justify-center items-center gap-2">
                    <FaTimes
                      onClick={() => deleteHandler(questionDetails)}
                      size={20}
                      className="text-red-700 cursor-pointer"
                    />
                    <FaEdit
                      onClick={() => editHandler(questionDetails)}
                      size={20}
                      className="text-sky-600 cursor-pointer"
                    />
                  </div>
                )}
                <TextQuestion
                  isDisabled={viewOnly}
                  {...commonProps}
                  value={
                    feedbackResponses &&
                    feedbackResponses[questionCategory] &&
                    feedbackResponses[questionCategory][
                      questionDetails.questionName
                    ] !== undefined
                      ? feedbackResponses[questionCategory][
                          questionDetails.questionName
                        ]
                      : values[questionDetails.questionName]
                  }
                  onChange={(event) =>
                    setValue(questionDetails.questionName, event.target.value)
                  }
                />
              </div>
            );
          case "textArea":
            return (
              <div className="relative" key={index}>
                {editable && (
                  <div className="absolute  top-2 -right-8 flex justify-center items-center gap-2">
                    <FaTimes
                      onClick={() => deleteHandler(questionDetails)}
                      size={20}
                      className="text-red-700 cursor-pointer"
                    />
                    <FaEdit
                      onClick={() => editHandler(questionDetails)}
                      size={20}
                      className="text-sky-600 cursor-pointer"
                    />
                  </div>
                )}
                <TextAreaQuestion
                  isDisabled={viewOnly}
                  {...commonProps}
                  value={
                    feedbackResponses &&
                    feedbackResponses[questionCategory] &&
                    feedbackResponses[questionCategory][
                      questionDetails.questionName
                    ] !== undefined
                      ? feedbackResponses[questionCategory][
                          questionDetails.questionName
                        ]
                      : values[questionDetails.questionName]
                  }
                  onChange={(event) =>
                    setValue(questionDetails.questionName, event.target.value)
                  }
                />
              </div>
            );
          case "slider":
            return (
              <div className="relative" key={index}>
                {editable && (
                  <div className="absolute  top-2 -right-8 flex justify-center items-center gap-2">
                    <FaTimes
                      onClick={() => deleteHandler(questionDetails)}
                      size={20}
                      className="text-red-700 cursor-pointer"
                    />
                    <FaEdit
                      onClick={() => editHandler(questionDetails)}
                      size={20}
                      className="text-sky-600 cursor-pointer"
                    />
                  </div>
                )}
                <SliderQuestion
                  isDisabled={viewOnly}
                  {...commonProps}
                  value={
                    feedbackResponses &&
                    feedbackResponses[questionCategory] &&
                    feedbackResponses[questionCategory][
                      questionDetails.questionName
                    ] !== undefined
                      ? Number(
                          feedbackResponses[questionCategory][
                            questionDetails.questionName
                          ]
                        )
                      : !isNaN(Number(values[questionDetails.questionName]))
                      ? Number(values[questionDetails.questionName])
                      : 0 // Default value for NaN or if feedbackResponses is not available
                  }
                  setValue={(value) =>
                    setValue(questionDetails.questionName, value.toString())
                  }
                />
                {viewOnly && (
                  <div className="pb-6">
                    Average rating: {`${average && average.toFixed(2)}`}
                  </div>
                )}
              </div>
            );
          case "sliderWithMax":
            return (
              <div className="relative" key={index}>
                {editable && (
                  <div className="absolute  top-2 -right-8 flex justify-center items-center gap-2">
                    <FaTimes
                      onClick={() => deleteHandler(questionDetails)}
                      size={20}
                      className="text-red-700 cursor-pointer"
                    />
                    <FaEdit
                      onClick={() => editHandler(questionDetails)}
                      size={20}
                      className="text-sky-600 cursor-pointer"
                    />
                  </div>
                )}
                <SliderQuestion
                  isDisabled={viewOnly}
                  maxRating={questionDetails.maxValue ?? 10}
                  {...commonProps}
                  value={
                    feedbackResponses &&
                    feedbackResponses[questionCategory] &&
                    feedbackResponses[questionCategory][
                      questionDetails.questionName
                    ] !== undefined
                      ? Number(
                          feedbackResponses[questionCategory][
                            questionDetails.questionName
                          ]
                        )
                      : !isNaN(Number(values[questionDetails.questionName]))
                      ? Number(values[questionDetails.questionName])
                      : 1 // Default value for NaN or if feedbackResponses is not available
                  }
                  setValue={(value) =>
                    setValue(questionDetails.questionName, value.toString())
                  }
                />
                {viewOnly && (
                  <div className="pb-6">
                    Average rating: {`${average && average.toFixed(2)}`}
                  </div>
                )}
              </div>
            );
          case "smileyRating":
            return (
              <div className="relative" key={index}>
                {editable && (
                  <div className="absolute  top-2 -right-8 flex justify-center items-center gap-2">
                    <FaTimes
                      onClick={() => deleteHandler(questionDetails)}
                      size={20}
                      className="text-red-700 cursor-pointer"
                    />
                    <FaEdit
                      onClick={() => editHandler(questionDetails)}
                      size={20}
                      className="text-sky-600 cursor-pointer"
                    />
                  </div>
                )}
                <SmileyRatingQuestion
                  {...commonProps}
                  value={
                    feedbackResponses &&
                    feedbackResponses[questionCategory] &&
                    feedbackResponses[questionCategory][
                      questionDetails.questionName
                    ] !== undefined
                      ? Number(
                          feedbackResponses[questionCategory][
                            questionDetails.questionName
                          ]
                        )
                      : Number(values[questionDetails.questionName])
                  }
                  name={questionDetails.questionName}
                  onChange={(event) =>
                    setValue(
                      questionDetails.questionName,
                      event.target.getAttribute("data-set") ?? ""
                    )
                  }
                />
                {viewOnly && (
                  <div className="pb-6">
                    Average rating: {`${average && average.toFixed(2)}`}
                  </div>
                )}
              </div>
            );
          case "smileyRatingWithMax":
            return (
              <div className="relative" key={index}>
                {editable && (
                  <div className="absolute  top-2 -right-8 flex justify-center items-center gap-2">
                    <FaTimes
                      onClick={() => deleteHandler(questionDetails)}
                      size={20}
                      className="text-red-700 cursor-pointer"
                    />
                    <FaEdit
                      onClick={() => editHandler(questionDetails)}
                      size={20}
                      className="text-sky-600 cursor-pointer"
                    />
                  </div>
                )}
                <SmileyRatingQuestionWithMax
                  {...commonProps}
                  value={
                    feedbackResponses &&
                    feedbackResponses[questionCategory] &&
                    feedbackResponses[questionCategory][
                      questionDetails.questionName
                    ] !== undefined
                      ? Number(
                          feedbackResponses[questionCategory][
                            questionDetails.questionName
                          ]
                        )
                      : Number(values[questionDetails.questionName])
                  }
                  name={questionDetails.questionName}
                  onChange={(event) =>
                    setValue(
                      questionDetails.questionName,
                      event.currentTarget.getAttribute("data-set") ?? ""
                    )
                  }
                  maxRating={questionDetails.maxValue}
                />
                {viewOnly && (
                  <div className="pb-6">
                    Average rating: {`${average && average.toFixed(2)}`}
                  </div>
                )}
              </div>
            );
          case "badgeRating":
            return (
              <div className="relative" key={index}>
                {editable && (
                  <div className="absolute  top-2 -right-8 flex justify-center items-center gap-2">
                    <FaTimes
                      onClick={() => deleteHandler(questionDetails)}
                      size={20}
                      className="text-red-700 cursor-pointer"
                    />
                    <FaEdit
                      onClick={() => editHandler(questionDetails)}
                      size={20}
                      className="text-sky-600 cursor-pointer"
                    />
                  </div>
                )}
                <RatingIconQuestion
                  participants={sortedParticipantRecords}
                  badges={
                    feedbackResponses &&
                    feedbackResponses["feedbackForGroup"] &&
                    feedbackResponses["feedbackForGroup"]["Badges"] !==
                      undefined
                      ? feedbackResponses["feedbackForGroup"]["Badges"]
                      : badges
                  }
                  toggleBadge={viewOnly ? () => {} : toggleBadge}
                />
                {feedbackResponses &&
                  feedbackResponses[questionCategory] &&
                  feedbackResponses[questionCategory][
                    questionDetails.questionName
                  ]}
              </div>
            );
          case "multipleChoice":
            return (
              <div className="relative" key={index}>
                {editable && (
                  <div className="absolute  top-2 -right-8 flex justify-center items-center gap-2">
                    <FaTimes
                      onClick={() => deleteHandler(questionDetails)}
                      size={20}
                      className="text-red-700 cursor-pointer"
                    />
                    <FaEdit
                      onClick={() => editHandler(questionDetails)}
                      size={20}
                      className="text-sky-600 cursor-pointer"
                    />
                  </div>
                )}
                <MultipleChoice
                  isDisabled={viewOnly}
                  {...commonProps}
                  editable={editable}
                  id={sectionId}
                  uid={questionDetails.uid}
                  choices={questionDetails.choices}
                  values={
                    feedbackResponses &&
                    feedbackResponses[questionCategory] &&
                    feedbackResponses[questionCategory][
                      questionDetails.questionName
                    ] !== undefined
                      ? feedbackResponses[questionCategory][
                          questionDetails.questionName
                        ]
                      : values[questionDetails.questionName]
                  }
                  questionName={questionDetails.questionName}
                  setValue={setValue}
                />
              </div>
            );
          case "yesOrNo":
            return (
              <div className="relative" key={index}>
                {editable && (
                  <div className="absolute  top-2 -right-8 flex justify-center items-center gap-2">
                    <FaTimes
                      onClick={() => deleteHandler(questionDetails)}
                      size={20}
                      className="text-red-700 cursor-pointer"
                    />
                    <FaEdit
                      onClick={() => editHandler(questionDetails)}
                      size={20}
                      className="text-sky-600 cursor-pointer"
                    />
                  </div>
                )}
                <YesOrNo
                  id={uuidv4()}
                  questionName={questionDetails.questionName}
                  setValue={setValue}
                  {...commonProps}
                  isDisabled={viewOnly}
                  values={
                    feedbackResponses &&
                    feedbackResponses[questionCategory] &&
                    feedbackResponses[questionCategory][
                      questionDetails.questionName
                    ] !== undefined
                      ? feedbackResponses[questionCategory][
                          questionDetails.questionName
                        ]
                      : values[questionDetails.questionName]
                  }
                />
              </div>
            );
          case "SpecialThanks":
            return (
              <div key={index}>
                {!viewOnly && (
                  <div className="relative">
                    {editable && (
                      <div className="absolute  top-2 -right-8 flex justify-center items-center gap-2">
                        <FaTimes
                          onClick={() => deleteHandler(questionDetails)}
                          size={20}
                          className="text-red-700 cursor-pointer"
                        />
                        <FaEdit
                          onClick={() => editHandler(questionDetails)}
                          size={20}
                          className="text-sky-600 cursor-pointer"
                        />
                      </div>
                    )}
                    <SpecialThanks
                      isDisabled={adminEditing}
                      setHasModal={setHasModal}
                      participants={ParticipantRecords}
                    />
                  </div>
                )}
              </div>
            );
          default:
            return <></>;
        }
      })}
      {children ?? ""}
    </div>
  );
}
