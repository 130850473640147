import NGFooter from "components/Footers/NGFooter";
import StandardFooter from "components/Footers/StandardFooter";
import { useGrowthCircleContext } from "hooks/useGrowthCircleContext";

const PoweredByGB = () => {
  const { growthCircleSession } = useGrowthCircleContext();
  return (
    <>
      {growthCircleSession?.type === "NG" && <NGFooter />}
      {growthCircleSession?.type !== "NG" && <StandardFooter />}
    </>
  );
};

export default PoweredByGB;
