import Header from "../../../components/Header/Header";
import Steps from "../../../components/utility/Steps";
import FiveStepHeader from "../../../components/FiveStepHeader/FiveStepHeader";
import { useAuthContext } from "../../../hooks/useAuthContext";
import EmptySpace from "../../../components/utility/EmptySpace";
import BackAndNext from "../../../components/utility/BackAndNext";
import Center from "../../../components/utility/Center/Center";
import { useNavigate } from "react-router-dom";
import { useGCSession } from "../../../hooks/useGCSession";
import { useGrowthCircleContext } from "../../../hooks/useGrowthCircleContext";
import NoGCSessionWrapper from "../../../pages/NoGCSession/NoGCSessionWrapper";
import ContentWrapper from "../../../components/utility/ContentWrapper";
import TopicResponse from "components/ViewChooseTopic/TopicResponse";
import { CHOOSE_TOPIC_ROUTE, CHOOSE_PATHWAY_ROUTE } from "strings/Routes";
import { useFirestore } from "hooks/useFirestore";
import { useEffect, useState } from "react";
import NGFooter from "components/Footers/NGFooter";
import useParticipants from "hooks/useParticipants";
import { checkCanAdvanceNextActivity } from "models/organisationRole";

const ViewChooseTopic = () => {
  const { profile } = useAuthContext();
  const { growthCircleSession } = useGrowthCircleContext();
  const { updateDocument } = useFirestore("users");
  const { sessionRole } = useParticipants();

  const [hasTrigger, setHasTrigger] = useState(false);
  const canAdvance = checkCanAdvanceNextActivity(sessionRole);

  const _setTrigger = () => {
    setHasTrigger(!hasTrigger);
  };
  //Props
  let navigate = useNavigate();

  const currentStep = 4;
  const stepsCount = 5;

  useEffect(() => {
    if (!profile) return;
    const data = { pageStep: window.location.pathname };
    updateDocument(profile.uid, data);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [profile]);

  // submit role next button
  const { facilAllowNextPage } = useGCSession();
  const nextHandler = () => {
    if (canAdvance) {
      facilAllowNextPage("choose-pathway");
    }
    navigate(CHOOSE_PATHWAY_ROUTE);
  };

  // back button
  const backHandler = () => {
    navigate(CHOOSE_TOPIC_ROUTE);
  };

  return (
    <NoGCSessionWrapper>
      <div className="relative">
        <Header hasModal={hasTrigger} />
        <FiveStepHeader
          hasLightning={true}
          hasGuide={true}
          currentStep={currentStep}
          hasModal={hasTrigger}
          setIsTriggerVisible={_setTrigger}
        />
        <ContentWrapper hasFiveStepper={true}>
          <Center>
            <EmptySpace height={"20px"} />
            <Steps count={stepsCount} currentStep={currentStep} />
            <EmptySpace height={"30px"} />
            <TopicResponse />
            <EmptySpace height={"30px"} />
            <BackAndNext
              nextAllowed={
                canAdvance || growthCircleSession?.allowChoosePathway
              }
              nextCallback={nextHandler}
              backAllowed={true}
              backCallback={backHandler}
              backHidden={undefined}
            />
            <EmptySpace height={"40px"} />
          </Center>
        </ContentWrapper>
        <EmptySpace height={"10px"} />
        <NGFooter type={growthCircleSession?.type} />
      </div>
    </NoGCSessionWrapper>
  );
};

export default ViewChooseTopic;
