import { ChangeEvent, FC, useEffect, useState } from "react";
import { ComponentProps } from "../SelfAwarenessComponentMapping";
import { ListSelectionData } from "../Data/ListData";
import BackAndNextFlat from "components/utility/BackAndNextFlat";
import { FaCheckCircle } from "react-icons/fa";
import { inputClass } from "strings/InputClassStrings";
import classes from "pages/AllCircles/Admin/Admin.module.css";
import { useSelfAwarenessContext } from "hooks/useSelfAwarenessContext";
import { Modal } from "flowbite-react";
import { AiFillCloseCircle } from "react-icons/ai";
import TitleBox from "../SubComponents/TitleBox";
import { colours } from "./styles/colourMap";
import SimpleLoader from "components/Loaders/SimpleLoader";

const ListSelector: FC<ComponentProps> = ({
  backAndNextHandler,
  setHasModal,
}) => {
  const [selectedItems, setSelectedItems] = useState<string[]>([]);
  const [other, setOther] = useState<string>("");
  const { journal } = useSelfAwarenessContext();
  const [show, setShow] = useState<boolean>(false);

  const handler = () => {
    setShow(!show);
    setHasModal(!show);
  };

  // Load data from local storage on component mount
  useEffect(() => {
    const storedJournal = localStorage.getItem("SelfAwarenessJournal");
    const existingJournal = storedJournal ? JSON.parse(storedJournal) : {};
    if (existingJournal && existingJournal.listSelected) {
      setSelectedItems(existingJournal.listSelected);
      if (existingJournal.listSelected.includes("Others")) {
        setOther(existingJournal.other);
      }
    }
    // eslint-disable-next-line
  }, []);

  const handleSubItemClick = (item: string) => {
    // Use the callback form to ensure the latest state
    setSelectedItems((prevSelected) => {
      // Check if the item is already selected
      if (prevSelected.includes(item)) {
        // If selected, remove it
        return prevSelected.filter((i) => i !== item);
      } else {
        // If not selected, add it
        return [...prevSelected, item];
      }
    });
  };

  const otherChangeHandler = (other: string) => {
    setOther(other);
    const storedJournal = localStorage.getItem("SelfAwarenessJournal");
    const existingJournal = storedJournal ? JSON.parse(storedJournal) : {};

    // Update the existing journal with the new answer
    const updatedJournal = {
      ...existingJournal,
      other: other,
    };

    // Store the updated journal in local storage
    localStorage.setItem(
      "SelfAwarenessJournal",
      JSON.stringify(updatedJournal)
    );
  };

  useEffect(() => {
    // Get the existing SelfAwarenessJournal from local storage
    const storedJournal = localStorage.getItem("SelfAwarenessJournal");
    const existingJournal = storedJournal ? JSON.parse(storedJournal) : {};

    const updatedJournal = {
      ...existingJournal,
      listSelected: selectedItems, // Use the latest state here
      // other properties of SelfAwarenessJournal...
    };

    // Save the updated SelfAwarenessJournal to local storage
    localStorage.setItem(
      "SelfAwarenessJournal",
      JSON.stringify(updatedJournal)
    );
  }, [selectedItems]);

  useEffect(() => {
    if (!journal) return;
  }, [journal]);

  return (
    <div className="py-8 px-4 w-full md:w-1/4">
      {journal && (
        <Modal show={show} popup={true} size={"sm"}>
          <div
            className={`p-5 relative font-poppins ${classes["container"]} flex flex-col`}
            style={{ color: "var(--text-colour)" }}
          >
            <AiFillCloseCircle
              style={{ color: "var(--icon-colour-0)" }}
              size={30}
              className="absolute top-2 right-2 cursor-pointer"
              onClick={handler}
            />
            {journal.selectionModalContent && (
              <div
                className="my-8"
                dangerouslySetInnerHTML={{
                  __html: journal?.selectionModalContent,
                }}
              ></div>
            )}

            <button
              onClick={handler}
              className={`${classes["button"]} w-1/2 self-center`}
            >
              Okay
            </button>
          </div>
        </Modal>
      )}

      {journal ? (
        <>
          {" "}
          <TitleBox
            journal={journal}
            showInfo={false}
            handler={handler}
            title={journal.listTitle || ""}
          />
          <div
            className="border-2 p-5 my-8 bg-white rounded-lg font-poppins  "
            style={{
              borderColor: colours[journal?.pageType]["border"],
              color: "var(--text-colour)",
            }}
          >
            <div className="max-h-[300px] overflow-y-auto">
              {ListSelectionData[journal.listMapData].map((item, index) => (
                <div key={index} className="px-5 select-none">
                  <div
                    style={{ borderBottomColor: "var(--icon-colour-0)" }}
                    className={`border-b-2 p-2 flex gap-2 items-center cursor-pointer `}
                    onClick={() => handleSubItemClick(item)}
                  >
                    {selectedItems &&
                      Array.isArray(selectedItems) &&
                      selectedItems.includes(item) && (
                        <FaCheckCircle
                          className="flex-shrink-0"
                          size={20}
                          style={{ color: "var(--icon-colour-0)" }}
                        />
                      )}
                    {item}
                  </div>
                </div>
              ))}
              {selectedItems.includes("Others") && (
                <div className={`${classes["container"]} px-5 mt-2`}>
                  <input
                    type="text"
                    className={`${inputClass}`}
                    style={{ borderColor: "#F9BB6280", borderWidth: "2px" }}
                    onChange={(e: ChangeEvent<HTMLInputElement>) =>
                      otherChangeHandler(e.target.value)
                    }
                    value={other}
                  />
                </div>
              )}
            </div>
          </div>
        </>
      ) : (
        <div className="flex justify-center items-center min-h-[200px]">
          <SimpleLoader />
        </div>
      )}

      <div className="my-4">
        <BackAndNextFlat
          {...backAndNextHandler}
          nextAllowed={selectedItems.length > 0 ? true : false}
        />
      </div>
    </div>
  );
};

export default ListSelector;
