import FiveStepHeader from "components/FiveStepHeader/FiveStepHeader";
import Header from "components/Header/Header";
import SliderCustom from "components/SliderCustom/SliderCustom";
import BackAndNext from "components/utility/BackAndNext";
import Center from "components/utility/Center/Center";
import ContentWrapper from "components/utility/ContentWrapper";
import EmptySpace from "components/utility/EmptySpace";
import PageWrapper from "components/utility/PageWrapper";
import Steps from "components/utility/Steps";
import useParticipants from "hooks/useParticipants";
import NoGCSessionWrapper from "pages/NoGCSession/NoGCSessionWrapper";
import { useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
import {
  CHECK_IN_CHECK_OUT_AVERAGE,
  NG_REFLECTION_ACTIVITY_ROUTE,
} from "strings/Routes";
import classes from "./CheckOutAfter.module.css";
import NGFooter from "components/Footers/NGFooter";
import { useGrowthCircleContext } from "hooks/useGrowthCircleContext";

const CHECKOUT_STORAGE_KEY = "checkOut";

const CheckOutAfter = () => {
  const stepsCount = 5;
  const currentStep = 5;
  const navigate = useNavigate();
  const { saveCheckOut } = useParticipants();
  const [isSaving, setIsSaving] = useState(false);
  const [hasTrigger, setHasTrigger] = useState(false);
  const { growthCircleSession } = useGrowthCircleContext();

  const _setTrigger = () => {
    setHasTrigger(!hasTrigger);
  };

  const [checkOut, setCheckOut] = useState(() => {
    const storedCheckout = localStorage.getItem(CHECKOUT_STORAGE_KEY);
    return storedCheckout
      ? JSON.parse(storedCheckout)
      : {
          individually: 0,
          interpersonally: 0,
          overall: 0,
          socially: 0,
        };
  });

  useEffect(() => {
    localStorage.setItem(CHECKOUT_STORAGE_KEY, JSON.stringify(checkOut));
  }, [checkOut]);

  const backHandler = () => {
    navigate(NG_REFLECTION_ACTIVITY_ROUTE);
  };

  const nextHandler = async () => {
    setIsSaving(true);
    await saveCheckOut(checkOut);
    localStorage.removeItem(CHECKOUT_STORAGE_KEY);
    setIsSaving(false);
    navigate(CHECK_IN_CHECK_OUT_AVERAGE);
  };

  return (
    <NoGCSessionWrapper>
      <PageWrapper>
        <Header hasModal={hasTrigger} />
        <FiveStepHeader
          hasModal={hasTrigger}
          setIsTriggerVisible={_setTrigger}
          hasLightning={true}
          hasGuide={false}
          currentStep={currentStep}
          text="Reflection on Session"
        />

        <ContentWrapper hasFiveStepper={true}>
          <Center>
            <EmptySpace height={"5px"} />
            <Steps count={stepsCount} currentStep={currentStep} />
            <EmptySpace height={"10px"} />
            <p className="text-center font-semibold mt-3 text-sm w-3/4">
              Answer these questions again. How are you feeling now in the
              following areas?
            </p>
            <div className="w-[320px] md:w-[400px] mx-auto mt-5 ">
              <div className="w-full mx-auto">
                <div className={`${classes.box} shadow-md`}>
                  <div className={classes.header}>Individually</div>
                  <div className={classes["header-description"]}>
                    Personal well-being
                  </div>
                  <div className="flex justify-center px-2">
                    <SliderCustom
                      withBackground={true}
                      value={checkOut.individually}
                      setValue={(value) =>
                        setCheckOut((prevState) => ({
                          ...prevState,
                          individually: value,
                        }))
                      }
                    />
                  </div>
                </div>
              </div>
            </div>

            <div className="w-[320px] md:w-[400px] mx-auto mt-5 ">
              <div className="w-full mx-auto">
                <div className={`${classes.box} shadow-md`}>
                  <div className={classes.header}>Interpersonally</div>
                  <div className={classes["header-description"]}>
                    Family, Close relationships
                  </div>
                  <div className="flex justify-center px-2">
                    <SliderCustom
                      withBackground={true}
                      value={checkOut.interpersonally}
                      setValue={(value) =>
                        setCheckOut((prevState) => ({
                          ...prevState,
                          interpersonally: value,
                        }))
                      }
                    />
                  </div>
                </div>
              </div>
            </div>

            <div className="w-[320px] md:w-[400px] mx-auto mt-5 ">
              <div className="w-full mx-auto">
                <div className={`${classes.box} shadow-md`}>
                  <div className={classes.header}>Socially</div>
                  <div className={classes["header-description"]}>
                    Work, school, friendships
                  </div>
                  <div className="flex justify-center px-2">
                    <SliderCustom
                      withBackground={true}
                      value={checkOut.socially}
                      setValue={(value) =>
                        setCheckOut((prevState) => ({
                          ...prevState,
                          socially: value,
                        }))
                      }
                    />
                  </div>
                </div>
              </div>
            </div>

            <div className="w-[320px] md:w-[400px] mx-auto mt-5 ">
              <div className="w-full mx-auto">
                <div className={`${classes.box} shadow-md`}>
                  <div className={classes.header}>Overall</div>
                  <div className={classes["header-description"]}>
                    General sense of well-being
                  </div>
                  <div className="flex justify-center px-2">
                    <SliderCustom
                      withBackground={true}
                      value={checkOut.overall}
                      setValue={(value) =>
                        setCheckOut((prevState) => ({
                          ...prevState,
                          overall: value,
                        }))
                      }
                    />
                  </div>
                </div>
              </div>
            </div>

            <EmptySpace height={"40px"} />

            <BackAndNext
              customNextText={isSaving ? "Saving..." : "Next"}
              nextAllowed={true}
              nextCallback={nextHandler}
              backAllowed={false}
              backCallback={backHandler}
              backHidden={undefined}
            />

            <EmptySpace height={"60px"} />
            <NGFooter type={growthCircleSession?.type} />
          </Center>
        </ContentWrapper>
      </PageWrapper>
    </NoGCSessionWrapper>
  );
};

export default CheckOutAfter;
