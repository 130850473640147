import { ComponentProps } from "../SelfAwarenessComponentMapping";
import { FC, useEffect, useState } from "react";
import { FaCheckCircle } from "react-icons/fa";
import BackAndNextFlat from "components/utility/BackAndNextFlat";

const PriorityListing: FC<ComponentProps> = ({ backAndNextHandler }) => {
  const [selectedItem, setSelectedItem] = useState<string[]>([]);
  const [priority, setPriority] = useState<string>("");

  // Load data from local storage on component mount
  useEffect(() => {
    const storedSelectedItems = localStorage.getItem("SelfAwarenessJournal");

    if (storedSelectedItems) {
      const parsedJournal = JSON.parse(storedSelectedItems);
      setSelectedItem(parsedJournal.selectedItems || []);
      setPriority(parsedJournal.selectedItems[0]);
    }
  }, []);

  const handleSubItemClick = (item: string) => {
    // Use the callback form to ensure the latest state
    setPriority(item);
  };

  useEffect(() => {
    // Get the existing SelfAwarenessJournal from local storage
    const storedJournal = localStorage.getItem("SelfAwarenessJournal");
    const existingJournal = storedJournal ? JSON.parse(storedJournal) : {};

    // Update SelfAwarenessJournal with the latest selectedItems
    const updatedJournal = {
      ...existingJournal,
      priority: priority, // Use the latest state here
      // other properties of SelfAwarenessJournal...
    };

    // Save the updated SelfAwarenessJournal to local storage
    localStorage.setItem(
      "SelfAwarenessJournal",
      JSON.stringify(updatedJournal)
    );
  }, [priority]);

  return (
    <div className="py-8 px-4 w-full md:w-1/4">
      <div
        className="relative p-5 border-2 rounded-lg font-poppins font-semibold"
        style={{
          backgroundColor: "#FFF4B9",
          borderColor: "#FFE871",
          color: "var(--text-colour)",
        }}
      >
        {selectedItem.length > 1 ? (
          <p>
            You have selected a few needs. select from the list below, What is
            your top most priority?
          </p>
        ) : (
          <p className="text-center">You selected "{priority}".</p>
        )}
      </div>

      <div
        className="border-2 p-5 my-8 bg-white rounded-lg font-poppins  "
        style={{
          borderColor: "#FFE871",
          color: "var(--text-colour)",
        }}
      >
        {selectedItem &&
          selectedItem.map((item, index) => (
            <div
              key={index}
              style={{ borderBottomColor: "var(--icon-colour-0)" }}
              className={`border-b-2 p-2 flex gap-2 items-center cursor-pointer`}
              onClick={() => handleSubItemClick(item)}
            >
              {priority === item ? (
                <FaCheckCircle
                  size={20}
                  style={{ color: "var(--icon-colour-0)" }}
                />
              ) : (
                ""
              )}{" "}
              {item}
            </div>
          ))}
      </div>

      <div className="mt-5">
        <BackAndNextFlat
          {...backAndNextHandler}
          nextAllowed={priority !== "" ? true : false}
        />
      </div>
    </div>
  );
};

export default PriorityListing;
