import { ReactComponent as HappyFace } from "./happy.svg";
import { ReactComponent as SadFace } from "./sad.svg";
import { FC, useEffect, useRef } from "react";
import classes from "./SliderCustom.module.css";
import { useAuthContext } from "hooks/useAuthContext";

interface Props {
  value: number | undefined;
  withBackground: boolean;
  checkIn?: number;
  checkOut?: number;
  max?: number;
}

const SliderDisplay: FC<Props> = ({
  value,
  withBackground = false,
  checkIn,
  checkOut,
  max = 10,
}) => {
  const outputRef = useRef<HTMLOutputElement>(null);
  const inputRef = useRef(null);

  const { profile } = useAuthContext();

  useEffect(() => {
    if (!profile) return;
  }, [profile]);

  const setBubble = (newVal, min, max) => {
    const percent = ((newVal - min) * 100) / (max - min);
    const bubblePosition = 8 - percent * 0.19;
    return `calc(${percent.toFixed(2)}% + (${bubblePosition}px))`;
  };

  return (
    <div
      className={` ${classes["range-wrap"]} flex justify-center items-center my-4`}
    >
      <SadFace
        fill={withBackground ? "#FDF5E6" : "transparent"}
        className="absolute top-0 left-0 cursor-pointer"
      />
      <input
        ref={inputRef}
        type="range"
        min={1}
        max={max}
        step={1}
        defaultValue={Number.isNaN(value) ? 1 : value}
        className={`${classes.slider} shadow-md mt-8 border-2  `}
        style={{ borderColor: "var(--main-colour)" }}
      />

      {checkIn !== undefined ? (
        <>
          <output
            ref={outputRef}
            style={{
              left: setBubble(checkIn, 1, max),
              background: value ? "" : "lightgray",
              borderColor: "var(--main-colour)",
              color: "var(--main-colour)",
            }}
            className={`${classes.bubble} border-2 text-[12px] bg-default`}
          >
            {value ? "Me" : checkIn}
          </output>
        </>
      ) : (
        ""
      )}

      {checkOut !== undefined ? (
        <output
          ref={outputRef}
          style={{
            left: setBubble(checkOut, 1, max),
            borderColor: "var(--main-colour)",
            color: "var(--main-colour)",
          }}
          className={`${classes.bubble} border-2 text-[12px]  
          bg-default`}
        >
          {value ? "Me" : checkOut}
        </output>
      ) : (
        ""
      )}

      {value === undefined ? (
        ""
      ) : (
        <output
          ref={outputRef}
          style={{
            left: setBubble(value, 1, max),
            borderColor: "var(--main-colour)",
            color: "var(--main-colour20)",
          }}
          className={`${classes.bubble} border-2 text-[12px] bg-default`}
        >
          {Number.isNaN(value) ? 1 : value}
        </output>
      )}

      <HappyFace
        fill={withBackground ? "#FDF5E6" : "transparent"}
        className="absolute top-0 right-0 cursor-pointer"
      />
    </div>
  );
};

export default SliderDisplay;
