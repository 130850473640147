import { Modal } from "flowbite-react";
import { Participant } from "interface/ParticipantInterface";

import React, { useEffect, useState } from "react";
import { MdCancel } from "react-icons/md";
import classes from "./CheckOutFacilScreen.module.css";
import SliderDisplay from "components/SliderCustom/SliderDisplay";
import Button from "components/utility/Button";
import EmptySpace from "components/utility/EmptySpace";
import Organisation from "interface/OrganisationInterface";
import { useOrgORS } from "hooks/organisation/useOrgORS";
import CheckInCheckOutOrgModal from "pages/AllCircles/EndRatingAverage/CheckInCheckOutOrgModal";

import { DocumentData } from "firebase/firestore";

interface Props {
  participants: Participant[];
  self?: Participant;
  hasPermission: boolean;
  setModalState?: React.Dispatch<React.SetStateAction<boolean>>;
  organisation?: Organisation;
}

const CheckOutOrgFacilScreenDisplay = ({
  participants,
  self: ParticipantRecord,
  hasPermission,
  setModalState,
  organisation,
}: Props) => {
  const [isParticipantsModal, setIsParticipantsModal] = useState(false);
  const [participantModalType, setParticipantModalType] = useState<string>("");

  const [averageData, setAverageData] = useState<any>();
  const [ors, setOrs] = useState<DocumentData[]>();
  const { getORSByOrgID } = useOrgORS();

  const fetchORS = async () => {
    try {
      if (organisation) {
        const _ors = await getORSByOrgID(organisation?.id);
        if (_ors) {
          setOrs(_ors);
        } else {
          setOrs([]);
        }
      }
    } catch (error) {
      console.error(error);
    }
  };

  useEffect(() => {
    if (ParticipantRecord == null) return;
    fetchORS();
    // eslint-disable-next-line
  }, [ParticipantRecord]);

  const participantsModalOnClickHandler = (DragRatingKey) => {
    setIsParticipantsModal(true);
    setParticipantModalType(DragRatingKey);
    if (setModalState) {
      setModalState(true);
    }
  };

  const getFlaggedParticipant = (DragRatingKey: any) => {
    let count = 0;
    participants.forEach((p) => {
      if (
        p.orgCheckOut !== undefined &&
        p.orgCheckIn &&
        p.orgCheckIn[DragRatingKey] > p.orgCheckOut[DragRatingKey]
      ) {
        count++;
      }
      return count;
    });
    return count;
  };

  const calculateAverages = (participantRecords: Participant[]) => {
    const total = participantRecords.reduce(
      (accumulator, record) => {
        if (record.orgCheckIn) {
          for (const key in record.orgCheckIn) {
            if (Object.prototype.hasOwnProperty.call(record.orgCheckIn, key)) {
              const value = record.orgCheckIn[key];
              if (typeof value === "number" && value >= 0) {
                accumulator.orgCheckIn[key] =
                  (accumulator.orgCheckIn[key] || 0) + value;
              }
            }
          }
        }

        if (record.orgCheckOut) {
          for (const key in record.orgCheckOut) {
            if (Object.prototype.hasOwnProperty.call(record.orgCheckOut, key)) {
              const value = record.orgCheckOut[key];
              if (typeof value === "number" && value >= 0) {
                accumulator.orgCheckOut[key] =
                  (accumulator.orgCheckOut[key] || 0) + value;
              }
            }
          }
        }

        return accumulator;
      },
      {
        orgCheckIn: {},
        orgCheckOut: {},
      }
    );

    const orgCheckInCount = participantRecords.filter(
      (record) => record.orgCheckIn
    ).length;
    const orgCheckOutCount = participantRecords.filter(
      (record) => record.orgCheckOut
    ).length;

    const averages = {
      orgCheckIn: {},
      orgCheckOut: {},
    };

    for (const key in total.orgCheckIn) {
      if (Object.prototype.hasOwnProperty.call(total.orgCheckIn, key)) {
        averages.orgCheckIn[key] = Number(
          (total.orgCheckIn[key] / orgCheckInCount).toFixed(1)
        );
      }
    }

    for (const key in total.orgCheckOut) {
      if (Object.prototype.hasOwnProperty.call(total.orgCheckOut, key)) {
        averages.orgCheckOut[key] = Number(
          (total.orgCheckOut[key] / orgCheckOutCount).toFixed(1)
        );
      }
    }

    return averages;
  };

  useEffect(() => {
    if (participants.length === 0) return;
    setAverageData(calculateAverages(participants));
  }, [participants]);

  useEffect(() => {
    if (!ors) return;
  }, [ors]);

  const getSubTitle = (title: string) => {
    if (ors) {
      const _or = ors.find((question) => question.title === title);

      if (_or) {
        return _or.sub_title;
      } else {
        return null;
      }
    } else {
      return null;
    }
  };
  const orderedOrs = ors && ors.slice().sort((a, b) => a.order - b.order);
  return (
    <React.Fragment>
      <Modal show={isParticipantsModal} size="md" className="min-h-[100vh]">
        <Modal.Body>
          <div className="absolute right-0 top-0 p-2">
            <MdCancel
              size={30}
              className="cursor-pointer"
              onClick={() => {
                setIsParticipantsModal(false);
                if (setModalState) {
                  setModalState(false);
                }
              }}
              style={{
                color: "var(--main-colour)",
              }}
            />
          </div>
          <div>
            <CheckInCheckOutOrgModal
              participants={participants}
              keyTitle={participantModalType}
              organisation={organisation}
            />
          </div>
        </Modal.Body>
      </Modal>
      {orderedOrs &&
        orderedOrs.map((orsItem, index) => {
          const key = orsItem.title.replace(/ /g, "_");

          // Find the corresponding participant based on 'key'
          const participant = participants.find(
            (p) => p.orgCheckIn && p.orgCheckIn[key]
          );

          if (!participant) {
            // Participant not found for this 'key'
            return null;
          }

          return (
            <div
              key={index}
              className={`${classes.box} relative px-4 py-5 shadow-md mt-5 max-w-md rounded-xl`}
            >
              {hasPermission && organisation?.enableFlagRating ? (
                <svg width="40" height="40" className={classes.circle}>
                  <circle cx="20" cy="20" r="20" fill="var(--main-colour-20)" />
                  <text
                    x="50%"
                    y="55%"
                    dominantBaseline="middle"
                    textAnchor="middle"
                    fill={getFlaggedParticipant(key) > 0 ? "red" : ""}
                  >
                    {getFlaggedParticipant(key)}
                  </text>
                </svg>
              ) : (
                ""
              )}

              <h3 className="font-semibold text-default w-full text-center text-2xl">
                <span className="text-xl">{key.replace(/_/g, " ")}</span>
              </h3>
              <p className="text-default text-sm text-center">
                {getSubTitle(key.replace(/_/g, " "))}
              </p>
              <div className="py-4 w-full">
                <div className="flex justify-center items-center flex-col relative">
                  <p className="absolute top-4  text-default text-md">
                    Average Before:
                  </p>
                  {ParticipantRecord && ParticipantRecord?.orgCheckIn && (
                    <SliderDisplay
                      max={organisation?.maxRating ?? 10}
                      withBackground={true}
                      value={averageData?.orgCheckIn[key]}
                      checkIn={
                        ParticipantRecord?.orgCheckIn &&
                        Number(ParticipantRecord?.orgCheckIn[key])
                      }
                    />
                  )}
                </div>
                <div className="flex justify-center items-center flex-col relative mt-4">
                  <p className="absolute top-4   text-default text-md">
                    Average After:
                  </p>
                  {ParticipantRecord && ParticipantRecord?.orgCheckOut && (
                    <SliderDisplay
                      max={organisation?.maxRating ?? 10}
                      withBackground={true}
                      value={averageData?.orgCheckOut[key]}
                      checkOut={
                        ParticipantRecord?.orgCheckOut[key] &&
                        Number(ParticipantRecord?.orgCheckOut[key])
                      }
                    />
                  )}
                </div>
              </div>
              <EmptySpace height={"20px"} />
              {hasPermission ? (
                <Button
                  onClick={() => participantsModalOnClickHandler(key)}
                  text="View all"
                  className={classes["modal-button"]}
                />
              ) : (
                ""
              )}
            </div>
          );
        })}
    </React.Fragment>
  );
};

export default CheckOutOrgFacilScreenDisplay;
