import { LocalStorageKeys } from "App";
import { SmileyRatings } from "interface/FeedbackFormInterface";
import { useEffect, useState } from "react";
import { useAuthContext } from "./useAuthContext";

const initialPersonalSmileyState: SmileyRatings = {
  smiley1: {
    question: "Displayed Empathy",
    answer: 0,
  },
  smiley2: {
    question: "Provided others with a new perspective",
    answer: 0,
  },
  smiley3: {
    question: "Pointed out non-verbal/verbal communication",
    answer: 0,
  },
  smiley4: {
    question: "Showed appreciation and gave encouragement",
    answer: 0,
  },
  smiley5: {
    question: "Asked questions that evoked deep thinking",
    answer: 0,
  },
};

const initialGCSmileyState: SmileyRatings = {
  smiley1: {
    question: "Increase empathy towards others",
    answer: 0,
  },
  smiley2: {
    question: "Provide others with a new perspective",
    answer: 0,
  },
  smiley3: {
    question: "Improve observational skills",
    answer: 0,
  },
  smiley4: {
    question: "Increase appreciation and encourage towards others",
    answer: 0,
  },
  smiley5: {
    question: "Gain new insights",
    answer: 0,
  },
  smiley6: {
    question: "From 1 to 5, rate the overall session.",
    answer: 0,
  },
};

const getDefaultSmiley = (localStorageKey: string) => {
  switch (localStorageKey) {
    case LocalStorageKeys.REFLECTION.GC_SMILEY_RATINGS as string:
      return initialGCSmileyState;
    // break;
    case LocalStorageKeys.REFLECTION.PERSONAL_SMILEY_RATINGS as string:
      return initialPersonalSmileyState;
    // break;
    default:
      return initialGCSmileyState;
    // break;
  }
};

/**
 * Method that retrieves respective smiley rating stored in localStorage
 * @param localStorageKey - key value for localStorage
 * @param userStorageKey - key value for objects nested within the object retrieved by localStorageKey
 */
const getInitialAllValues = (
  userStorageKey: string,
  localStorageKey: string,
  participantId?: string
): SmileyRatings => {
  // localStorage.removeItem(userStorageKey + localStorageKey);
  const currAllValues = localStorage.getItem(userStorageKey + localStorageKey);
  if (currAllValues) {
    const allValues = JSON.parse(currAllValues);
    if (
      localStorageKey &&
      allValues &&
      (participantId ? allValues[participantId] : true)
    ) {
      return participantId ? allValues[participantId] : allValues;
    } else {
      return getDefaultSmiley(localStorageKey);
    }
  } else {
    return getDefaultSmiley(localStorageKey);
  }
};

/**
 * Hook that initialises the smiley state
 * @param localStorageKey: string - storage key to be passed
 * @param participantId?: string | undefined - id of the participant that is the current profile user
 */
const useRatingSmiley = (
  localStorageKey: string,
  participantId?: string | undefined
) => {
  const { profile } = useAuthContext();
  const userStoragekey = profile!.id + "_";
  const [allValues, setAllValues] = useState<SmileyRatings>(() =>
    getInitialAllValues(userStoragekey, localStorageKey, participantId)
  );
  const [smileyError, setSmileyError] = useState<boolean>(false);

  /**
   * Checks if any values in the smileys are untouched
   */
  const anyUntouched = () => {
    const isAllTouched = Object.keys(allValues).reduce((previous, key) => {
      return allValues[key].answer !== 0 && previous;
    }, true);

    return !isAllTouched;
  };

  useEffect(() => {
    setAllValues(
      getInitialAllValues(userStoragekey, localStorageKey, participantId)
    );
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [participantId]);

  useEffect(() => {
    localStorage.setItem(
      userStoragekey + localStorageKey,
      JSON.stringify(allValues)
    );
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [allValues]);

  return { allValues, setAllValues, anyUntouched, smileyError, setSmileyError };
};

export default useRatingSmiley;
