import { useEffect, useState } from "react";
import { useParams, Link } from "react-router-dom";
import { FaBackspace } from "react-icons/fa";

import Header from "components/Header/Header";
import Center from "components/utility/Center/Center";
import ContentWrapper from "components/utility/ContentWrapper";
import EmptySpace from "components/utility/EmptySpace";
import PageWrapper from "components/utility/PageWrapper";
import NGFooter from "components/Footers/NGFooter";
import TotalScore from "../RatingAverage/TotalScore";
import ParticipantSummary from "components/ParticipantSummary/ParticipantSummary";

import { useGrowthCircleContext } from "hooks/useGrowthCircleContext";
import { useAuthContext } from "hooks/useAuthContext";
import { useNestedCollection } from "hooks/useNestedCollection";
import { getOrgsWithPermission } from "models/organisation";
import { P_SEE_AVERAGE_RATING } from "models/permission";
import { Participant } from "interface/ParticipantInterface";
import Organisation from "interface/OrganisationInterface";
import CheckOutOrgFacilScreenDisplay from "components/CheckOutFacilScreen/CheckOutOrgFacilScreenDisplay";
import CheckOutFacilScreenDisplay from "components/CheckOutFacilScreen/CheckOutFacilScreenDisplay";

interface ParticipantCollection {
  documents: Participant[];
}

const ObserverPage = () => {
  const { id } = useParams();

  // Fetch participants
  const { documents: ParticipantRecords }: ParticipantCollection =
    useNestedCollection(["GrowthCircles", "Participants"], [id], null, null);

  const [countAllSubmitted, setCountAllSubmitted] = useState(0);
  const { growthCircleSession } = useGrowthCircleContext();
  const { profile } = useAuthContext();
  const [isParticipantModal, setIsParticipantModal] = useState(false);
  const [isRatingModal, setIsRatingModal] = useState(false);
  const [organisations, setOrganisations] = useState<Organisation[]>([]);
  const [selectedOrg, setSelectedOrg] = useState<Organisation | null>(null);

  // Fetch organizations based on permissions
  useEffect(() => {
    if (!profile) return;

    const fetchOrgs = async () => {
      const orgs = await getOrgsWithPermission(profile, P_SEE_AVERAGE_RATING);
      setOrganisations(orgs);

      // Automatically select the organization that matches the growth circle session's organization
      const matchedOrg = orgs.find(
        (org) => org.name === growthCircleSession?.organisation
      );
      setSelectedOrg(matchedOrg || null);
    };

    fetchOrgs();
  }, [profile, growthCircleSession]);

  // Count participants with checkOut records
  useEffect(() => {
    if (!ParticipantRecords || ParticipantRecords.length === 0) return;

    const countCheckedOut = ParticipantRecords.filter(
      (record) => record.checkOut !== null && record.checkOut !== undefined
    ).length;

    setCountAllSubmitted(countCheckedOut);
  }, [ParticipantRecords]);

  return (
    <PageWrapper>
      <Header hasModal={isParticipantModal || isRatingModal} />
      <ContentWrapper hasFiveStepper={true}>
        <div className="w-full relative">
          <Link
            className="px-8 py-2 absolute -top-2 left-2"
            to={`/all-circles/join-session`}
            style={{ color: `var(--main-colour)` }}
          >
            <FaBackspace size={35} />
          </Link>
          <h2 className="text-center text-2xl md:text-3xl font-semibold">
            Session Summary
          </h2>
        </div>

        <Center>
          <EmptySpace height="5px" />
          <ParticipantSummary participants={ParticipantRecords} />
          <EmptySpace height="5px" />

          {ParticipantRecords.length > 0 && (
            <div className="py-4 border-b border-main-colour w-2/3 mx-auto">
              <p className="text-sm font-semibold text-center">
                Check out how everyone’s feeling!
              </p>
              <p className="text-center py-2 font-semibold text-slate-600">
                {countAllSubmitted} out of {ParticipantRecords.length} submitted
                their checkouts
              </p>
            </div>
          )}

          <div className="w-full px-4 flex flex-col justify-center items-center">
            {selectedOrg &&
              selectedOrg.components.includes("intro-end-org-average") && (
                <CheckOutOrgFacilScreenDisplay
                  organisation={selectedOrg || undefined}
                  participants={ParticipantRecords}
                  hasPermission={true}
                  setModalState={setIsRatingModal}
                />
              )}
            {selectedOrg &&
              selectedOrg.components.includes("intro-end-average") && (
                <CheckOutFacilScreenDisplay
                  organisation={selectedOrg || undefined}
                  participants={ParticipantRecords}
                  hasPermission={true}
                  setModalState={setIsRatingModal}
                />
              )}
            {selectedOrg &&
              !selectedOrg.components.includes("intro-end-org-average") &&
              selectedOrg &&
              !selectedOrg.components.includes("intro-end-average") && (
                <p className="text-standard mt-5">
                  No available Check-In/Check-Out component for this
                  organisation.{" "}
                </p>
              )}

            {organisations.length > 0 && (
              <TotalScore
                participants={ParticipantRecords}
                setIsModal={setIsParticipantModal}
              />
            )}
          </div>

          <EmptySpace height="40px" />
          <NGFooter type={growthCircleSession?.type} />
        </Center>
      </ContentWrapper>
    </PageWrapper>
  );
};

export default ObserverPage;
