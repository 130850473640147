//TODO: move to firebase

export const ListSelectionData = {
  LetsAppreciate: [
    "My Effort",
    "My Attitude",
    "My Approach",
    "My Progress",
    "My Achievements",
    "My Connections",
    "My Family",
    "My Boss",
    "My Colleagues",
    "My Friends",
    "My Mentor",
    "My Coach",
    "My Team",
    "My Professor",
    "My NOC Experience",
    "Others",
  ],
  ChallengeMe: [
    "Ask for feedback",
    "Improve/create a process for greater efficiency and effectiveness",
    "Consider how you can add value to an existing team need.",
    "Talk to your boss and colleagues about their personal journeys",
    "Create a decision making framework based on your analysis of how different stakeholders make decisions, and the questions they tend to ask.",
    "Take classes to develop a skill set",
    "Join community events to understand how they sustain and engage a community.",
    "Connect with someone new each week, and invite them for coffee Ask about their views on the city's culture and development.",
    "Interview startup founders",
    "Conduct market research on a focus area or demographic group to find out their challenges 01 needs.",
    "Attend Startup or Professional conferences",
    "Consolidate a list of ideas and discuss how they would be relevant for the Singapore context",
    "Conduct a fund-raising event for a cause of your choice",
    "Find the local Singapore business association in your city and look for mentors who can share more about their experience working there",
    "Join a Hackathon and learn from how others approach it",
    "Join a business case challenge",
    "Create a list of things you have always wanted to do. Then do it.",
    "Start a podcast about everything you are learning.",
    "Find out What the City is known for. Immerse and learn more about it.",
    "Join a sandbox Get involved in skills-based volunteering",
    "Travel to a new area (ensure safety). People watch.",
    "Request to speak at an event to share about something you know",
    "Get connected with professors whose research are field of interest. Volunteer with them",
  ],
};
