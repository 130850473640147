import { BadgeType } from "interface/FeedbackFormInterface";
import Effort from "assets/ReflectionRating/Effort";
import { ReactComponent as CareAndSupport } from "assets/ReflectionRating/CareAndSupport.svg";
import { ReactComponent as Role } from "assets/ReflectionRating/Role.svg";

/**
 * Returns the Component based on the badge type as well as the colour, width and height provided
 * @param {string} colour - colour variable (based on App.css)
 * @param {BadgeType} badge - type of badge (Role/CareAndSupport/Effort)
 * @param {string} width? - width (can be in px/vw/vh...others not tested)
 * @param {string} height? - height (can be in px/vw/vh...others not tested)
 */
export const renderBadgeIcon = (
  colour: string,
  badge: BadgeType,
  width?: string,
  height?: string
): JSX.Element => {
  switch (badge) {
    case BadgeType.EFFORT:
      return (
        <Effort
          key={badge}
          sleeveFill={colour}
          middleStroke={colour}
          width={width}
          height={height}
        />
      );
    case BadgeType.CARE_AND_SUPPORT:
      return width && height ? (
        <CareAndSupport key={badge} width={width} height={height} />
      ) : (
        <CareAndSupport key={badge} />
      );
    // eslint-disable-next-line no-duplicate-case
    case BadgeType.EFFORT:
      return width && height ? (
        <Role key={badge} fill={colour} width={width} height={height} />
      ) : (
        <Role key={badge} fill={colour} />
      );
    default:
      return width && height ? (
        <Role key={badge} fill={colour} width={width} height={height} />
      ) : (
        <Role key={badge} fill={colour} />
      );
  }
};
