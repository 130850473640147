import { useEffect, useState, useRef } from "react";
import { projectFirestore } from "../firebase/config";
import firebase from "firebase/compat/app";
// import DocumentReference = firebase.firestore.DocumentReference;
// import CollectionReference = firebase.firestore.CollectionReference;
// import Query = firebase.firestore.Query;
// import WhereFilterOp = firebase.firestore.WhereFilterOp;
// import OrderByDirection = firebase.firestore.OrderByDirection;

// _query must be an array of arrays
export const useNestedCollection = (
  _collections: (string | undefined)[],
  _docs: (string | undefined)[],
  _query?:
    | [string, firebase.firestore.WhereFilterOp, string | undefined][]
    | null,
  _orderBy?: [string, firebase.firestore.OrderByDirection] | null
) => {
  const [documents, setDocuments] = useState<any[]>([]);
  const [error, setError] = useState<string | null>(null);

  // if we don't use a ref --> infinite loop in useEffect
  // _query is an array and is "different" on every function call
  let collections = useRef(_collections).current;
  const docs = useRef(_docs).current;
  const query = useRef(_query).current;
  const orderBy = useRef(_orderBy).current;

  useEffect(() => {
    if (collections.some((el) => !el) || docs.some((el) => !el)) return;
    let colRef = projectFirestore.collection(collections[0] as string);
    let docRef: firebase.firestore.DocumentReference;

    // traverse the nested collection
    for (let i = 0; i < docs.length; i++) {
      docRef = colRef.doc(docs[i]);
      colRef = docRef.collection(collections[i + 1] as string);
    }

    let queryRef: firebase.firestore.Query = colRef;

    // filter through each query
    if (query) {
      query.forEach((q) => {
        queryRef = queryRef.where(...q);
      });
    }

    // order by
    if (orderBy) {
      queryRef = queryRef.orderBy(...orderBy);
    }

    const unsubscribe = queryRef.onSnapshot(
      (snapshot) => {
        let results: any[] = [];
        snapshot.docs.forEach((doc) => {
          results.push({ ...doc.data(), id: doc.id });
        });
        // update state
        setDocuments(results);
        setError(null);
      },
      (error) => {
        console.log(error);
        setError("could not fetch the data");
      }
    );

    // unsubscribe on unmount
    return () => unsubscribe();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [collections, query, orderBy]);

  return { documents, error };
};
