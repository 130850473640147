import { IMAGE_DATA } from "components/utility/ImageSelector/ImageSelector";

const NGActivityIcons = (props: { id: number }) => {
  const { id } = props;

  const imageData = IMAGE_DATA.find((data) => data.id === id);

  return (
    <div className="flex justify-center items-center">
      {id !== null && imageData && imageData.image}
    </div>
  );
};

export default NGActivityIcons;
