import React from 'react';
import classes from './InnerContainer.module.css';

interface Props {
  children: React.ReactNode,
  tab: string,
  place: string,
}

// tab indicates which tab: "first", "second" or "third"
// place indicates top or bottom container: "top", "bottom"

const InnerContainer: React.FC<Props> = ({ children, tab, place }) => {
  return (
    <div className={`${classes["container"]} ${classes[tab]} ${classes[place]}`}>
      { children }
    </div>
  )
};

export default InnerContainer;
