import Header from "components/Header/Header";
import Steps from "components/utility/Steps";
import FiveStepHeader from "components/FiveStepHeader/FiveStepHeader";
import classes from "./ChooseRoles.module.css";

import CarouselRoles from "components/ChooseRoles/CarouselRoles";

import { useCarousel } from "hooks/useCarousel";
import React, { lazy, useEffect, useState, Suspense } from "react";
import { useAuthContext } from "hooks/useAuthContext";
import { useCollection } from "hooks/useCollection";
import EmptySpace from "components/utility/EmptySpace";
import BackAndNext from "components/utility/BackAndNext";

import ModalContent from "components/ChooseRoles/ModalContent";
import Center from "components/utility/Center/Center";
import useParticipants from "hooks/useParticipants";
import { useNavigate } from "react-router-dom";
import useUpdateProgress from "hooks/useUpdateProgress";
import { useGrowthCircleContext } from "hooks/useGrowthCircleContext";
import NoGCSessionWrapper from "pages/NoGCSession/NoGCSessionWrapper";
import ContentWrapper from "components/utility/ContentWrapper";
import Role from "interface/RoleInterface";

import {
  SETTING_INTENTIONS_ROUTE,
  VIEW_CHOOSE_ROLES_ROUTE,
} from "strings/Routes";
import PageWrapper from "components/utility/PageWrapper";
import { useSwipeable } from "react-swipeable";

import { Modal } from "flowbite-react";
import { MdCancel } from "react-icons/md";
import { ReactComponent as InfoButton } from "assets/InfoButtonWhite.svg";
import { InstructionModal } from "components/ChooseRoles/InstructionModal";
import { updateWindowLocationPath } from "models/profile";

const Carousel = lazy(() => import("components/ChooseRoles/Carousel"));
const Roles = lazy(() => import("components/ChooseRoles/Role/Roles"));
const ChooseRolesHelper = lazy(
  () => import("components/ChooseRolesHelper/ChooseRolesHelper")
);

interface SkillCollection {
  [key: string]: Role[];
}

// documents is in the format of [{activeListening: {}} ...]
// this method gets all the field names
const getGeneralSkillArray = (documents: SkillCollection[]): string[] => {
  return documents.map((skill) => {
    delete skill.createdAt; // need to remove all fields until only left with skill object
    delete skill.id;
    return Object.keys(skill)[0];
  });
};

const ChooseRoles = () => {
  const { documents } = useCollection("Skills", undefined, [
    "createdAt",
    "asc",
  ]);
  const { profile } = useAuthContext();
  const { growthCircleSession } = useGrowthCircleContext();
  const { updatedProgressObject } = useUpdateProgress(documents, profile);
  const [isHelperShow, setIsHelperShow] = useState(false);
  const [showInstructions, setShowInstructions] = useState(false);

  const [hasTrigger, setHasTrigger] = useState(false);

  const _setTrigger = () => {
    setHasTrigger(!hasTrigger);
  };
  //Props
  let navigate = useNavigate();
  const [currSelected, setCurrSelected] = useState<Role | null>(null);
  const { currentIndex, next, prev, changeIndex } = useCarousel(
    documents ? documents.length : 0
  );

  // skillContent contains information about each level
  const currSkill = documents[currentIndex];
  const skillContent =
    documents.length > 0 ? currSkill[Object.keys(currSkill)[0]] : [];

  // array of general skills in carousel
  const skillNameArray = getGeneralSkillArray(documents);

  // to get array of content for each carousel page
  useEffect(() => {
    if (!profile?.uid) {
      return;
    }
    updateWindowLocationPath(profile.uid);
  }, [profile?.uid]);

  const currentStep = 3;
  const stepsCount = 5;

  const _setVisible = () => {
    setIsHelperShow(true);
  };

  // Modal for not selecting any role
  const [isModalOpen, setIsModalOpen] = useState(false);

  useEffect(() => {
    if (isHelperShow || hasTrigger || isModalOpen) {
      document.body.style.overflowY = "hidden";
    } else {
      document.body.style.overflowY = "unset";
    }

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [isHelperShow, hasTrigger, isModalOpen]);

  // firebase
  const { updateRole, role } = useParticipants();

  // submit role next button
  const nextHandler = () => {
    if (!currSelected) return;
    updateRole(currSelected);

    navigate(VIEW_CHOOSE_ROLES_ROUTE);
  };

  // open modal if next button attempted to click
  const nextFailHandler = () => {
    setIsModalOpen(true);
  };

  // back button
  const backHandler = () => {
    navigate(SETTING_INTENTIONS_ROUTE);
  };

  // set state to role if already exist
  useEffect(() => {
    if (role) {
      setCurrSelected(role);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [role]);

  const swipeHandlers = useSwipeable({
    onSwipedLeft: next,
    onSwipedRight: prev,
  });

  const modalHandler = () => {
    setIsModalOpen(!isModalOpen);
  };

  const isHelperShowHandler = () => {
    setIsHelperShow(!isHelperShow);
  };

  const psb = document.getElementById("perfectScrollBar");
  psb?.addEventListener("scroll", (e) => {
    e.stopPropagation();
    e.preventDefault();
  });

  return (
    <NoGCSessionWrapper>
      {growthCircleSession && updatedProgressObject && (
        <>
          <InstructionModal
            isModalOpen={showInstructions}
            setIsModalOpen={setShowInstructions}
          />
          <React.Fragment>
            <Modal show={isModalOpen} size="md" className="min-h-screen">
              <Modal.Body className="relative mt-[20%] md:mt-[5%]  overflow-x-auto  rounded-lg">
                <div className="absolute right-0 top-0 p-2">
                  <MdCancel
                    size={30}
                    className="cursor-pointer"
                    onClick={modalHandler}
                    style={{
                      color: "var(--main-colour)",
                    }}
                  />
                </div>
                <div className="text-center w-full py-5" id="trigger-modal">
                  <ModalContent />
                </div>
              </Modal.Body>
            </Modal>
          </React.Fragment>

          <React.Fragment>
            <Modal show={isHelperShow} size="md" className="min-h-screen">
              <Modal.Body className="relative mt-[5%] md:mt-[3%] overflow-x-auto  rounded-lg">
                <div className="absolute right-0 top-0 p-2">
                  <MdCancel
                    size={30}
                    className="cursor-pointer"
                    onClick={isHelperShowHandler}
                    style={{
                      color: "var(--main-colour)",
                    }}
                  />
                </div>
                <div className="text-center w-full">
                  <Suspense fallback={<div>Loading...</div>}>
                    <ChooseRolesHelper setHasModal={setIsHelperShow} />
                  </Suspense>
                </div>
              </Modal.Body>
            </Modal>
          </React.Fragment>

          <PageWrapper>
            <Header hasModal={isHelperShow || hasTrigger || isModalOpen} />

            {/* {isHelperShow && <FiveStepDummy title={"Build A Skill"}/> }

            {!isHelperShow && } */}
            <FiveStepHeader
              hasLightning={true}
              hasGuide={false}
              hasHelper={true}
              setIsHelperVisible={_setVisible}
              setIsTriggerVisible={_setTrigger}
              hasModal={hasTrigger || isHelperShow || isModalOpen}
              currentStep={currentStep}
            />
            <ContentWrapper hasFiveStepper={true}>
              {/* Choose Roles Helper */}
              {/* {isHelperShow && <ChooseRolesHelper trigger={setIsHelperShow}/>} */}

              <Center>
                <EmptySpace height={"20px"} />
                <Steps count={stepsCount} currentStep={currentStep} />
                <div className="py-6 text-center w-[95vw]">
                  <div className="flex flex-row gap-4 justify-center items-center">
                    <h1 className="font-bold text-2xl">
                      Pick a role / Build a skill
                    </h1>
                    <InfoButton onClick={() => setShowInstructions(true)} />
                  </div>
                  <EmptySpace />
                  <h2 className="font-bold text-sm">
                    Swipe left or right, then choose a card to determine your
                    role.
                  </h2>
                  <h3 className="text-sm">
                    {`  A thicker ${
                      profile?.isFacil ? "red border" : "orange border"
                    } will appear around the card of the
                    role that you have clicked on.`}
                  </h3>
                </div>
                <EmptySpace height={"20px"} />
                <div
                  className={classes["choose-roles-content"]}
                  {...swipeHandlers}
                >
                  <Suspense fallback={<div>Loading...</div>}>
                    <Carousel
                      children={skillNameArray.map((role, index) => {
                        return (
                          <CarouselRoles
                            key={index}
                            name={role}
                            index={index}
                            currentIndex={currentIndex}
                            setCurrentIndex={changeIndex}
                          />
                        );
                      })}
                      next={next}
                      prev={prev}
                      currentIndex={currentIndex}
                      displayCount={"3"}
                      arrowOffset={"2%"}
                      width={"80%"}
                      arrowColor={"var(--main-colour)"}
                    />
                  </Suspense>
                  <Suspense fallback={<div>Loading...</div>}>
                    <Roles
                      currentIndex={currentIndex}
                      content={skillContent}
                      currSelected={currSelected}
                      setCurrSelected={setCurrSelected}
                    />
                  </Suspense>
                </div>
                <BackAndNext
                  nextAllowed={currSelected !== null}
                  nextCallback={nextHandler}
                  nextFailCallback={nextFailHandler}
                  backAllowed={true}
                  backCallback={backHandler}
                  backHidden={undefined}
                  isFixed={true}
                />
                <EmptySpace height={"40px"} />
              </Center>
            </ContentWrapper>
          </PageWrapper>
        </>
      )}
    </NoGCSessionWrapper>
  );
};

export default ChooseRoles;
