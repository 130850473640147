import { Modal } from "flowbite-react";
import {
  DRAG_RATING_KEYS,
  DragRatingKey,
  DragRatingKeys,
} from "hooks/useDragRating";
import { Participant } from "interface/ParticipantInterface";
import CheckInCheckOutModal from "pages/AllCircles/EndRatingAverage/CheckInCheckOutModal";
import React, { useEffect, useState } from "react";
import { MdCancel } from "react-icons/md";
import classes from "./CheckOutFacilScreen.module.css";
import SliderDisplay from "components/SliderCustom/SliderDisplay";
import Button from "components/utility/Button";
import EmptySpace from "components/utility/EmptySpace";
import Organisation from "interface/OrganisationInterface";
import { Role } from "models/organisationRole";
import useOrganisationRoleContext from "hooks/organisation/useOrganisationRoleContext";

interface Props {
  participants: Participant[];
  self?: Participant;
  hasPermission: boolean;
  setModalState?: React.Dispatch<React.SetStateAction<boolean>>;
  organisation?: Organisation;
}

interface ParticipantRecord {
  checkIn: {
    individually: number;
    interpersonally: number;
    overall: number;
    socially: number;
  };
  checkOut: {
    individually: number;
    interpersonally: number;
    overall: number;
    socially: number;
  };
}

export const DRAG_RATING_KEYS_ORG: DragRatingKeys = {
  INDIVIDUALLY: {
    KEY: "individually",
    TITLE: "Industry insights",
    SUB_TITLE: "",
  },
  INTERPERSONALLY: {
    KEY: "interpersonally",
    TITLE: "Job Roles",
    SUB_TITLE: "",
  },
  SOCIALLY: {
    KEY: "socially",
    TITLE: "Career Paths & Progression",
    SUB_TITLE: "",
  },
  OVERALL: {
    KEY: "overall",
    TITLE: "Skills Relevant To Industry",
    SUB_TITLE: "",
  },
};

const CheckOutFacilScreen = ({
  participants,
  self,
  hasPermission,
  setModalState,
  organisation,
}: Props) => {
  const [isParticipantsModal, setIsParticipantsModal] = useState(false);
  const [participantModalType, setParticipantModalType] = useState(
    DRAG_RATING_KEYS.INDIVIDUALLY
  );
  const { roles } = useOrganisationRoleContext();
  const [averageData, setAverageData] = useState<ParticipantRecord>();

  const participantsModalOnClickHandler = (DragRatingKey: DragRatingKey) => {
    setIsParticipantsModal(true);
    setParticipantModalType(DragRatingKey);
    if (setModalState) {
      setModalState(true);
    }
  };

  const getFlaggedParticipant = (DragRatingKey: DragRatingKey["KEY"]) => {
    let count = 0;
    participants.forEach((p) => {
      if (
        p.checkOut !== undefined &&
        p.checkIn[DragRatingKey] > p.checkOut[DragRatingKey]
      ) {
        count++;
      }
      return count;
    });
    return count;
  };

  const calculateAverages = (participantRecords: Participant[]) => {
    const total = participantRecords.reduce(
      (accumulator, record) => {
        if (record.checkIn.individually >= 0) {
          accumulator.checkIn.individually += record.checkIn.individually;
          accumulator.checkIn.interpersonally += record.checkIn.interpersonally;
          accumulator.checkIn.overall += record.checkIn.overall;
          accumulator.checkIn.socially += record.checkIn.socially;
        }

        if (record.checkOut.individually >= 0) {
          accumulator.checkOut.individually += record.checkOut.individually;
          accumulator.checkOut.interpersonally +=
            record.checkOut.interpersonally;
          accumulator.checkOut.overall += record.checkOut.overall;
          accumulator.checkOut.socially += record.checkOut.socially;
        }

        return accumulator;
      },
      {
        checkIn: {
          individually: 0,
          interpersonally: 0,
          overall: 0,
          socially: 0,
        },
        checkOut: {
          individually: 0,
          interpersonally: 0,
          overall: 0,
          socially: 0,
        },
      }
    );

    const checkInCount = participantRecords.filter(
      (record) => record.checkIn.individually >= 0
    ).length;
    const checkOutCount = participantRecords.filter(
      (record) => record.checkOut.individually >= 0
    ).length;

    const averages = {
      checkIn: {
        individually: Number(
          (total.checkIn.individually / checkInCount).toFixed(1)
        ),
        interpersonally: Number(
          (total.checkIn.interpersonally / checkInCount).toFixed(1)
        ),
        overall: Number((total.checkIn.overall / checkInCount).toFixed(1)),
        socially: Number((total.checkIn.socially / checkInCount).toFixed(1)),
      },
      checkOut: {
        individually: Number(
          (total.checkOut.individually / checkOutCount).toFixed(1)
        ),
        interpersonally: Number(
          (total.checkOut.interpersonally / checkOutCount).toFixed(1)
        ),
        overall: Number((total.checkOut.overall / checkOutCount).toFixed(1)),
        socially: Number((total.checkOut.socially / checkOutCount).toFixed(1)),
      },
    };
    return averages;
  };

  useEffect(() => {
    if (participants.length === 0) return;
    if (!organisation) return;
    const _newParticipants = filterParticipantsByRole(
      participants,
      organisation,
      roles
    );
    setAverageData(calculateAverages(_newParticipants));
  }, [participants, roles, organisation]);

  //TODO : This function will exclude a role based on Check-in and check-out settings from average calculation.
  const filterParticipantsByRole = (
    participants: Participant[],
    selectedOrganisation: Organisation,
    roles: Role[]
  ) => {
    // Map over the participants array
    return participants.filter((participant) => {
      // Get the participant's role from the selected organisation
      const participantRole = selectedOrganisation?.users[participant.userId];

      // Find the corresponding role in the roles array
      const role = roles
        .filter((p) => p.isEnable !== false)
        .find((r) => r.name === participantRole);

      // Check if the role is found and if exCludedToCalc is not true
      return role && !role.exCludedToCalc;
    });
  };

  return (
    <React.Fragment>
      <Modal show={isParticipantsModal} size="md" className="min-h-[100vh]">
        <Modal.Body>
          <div className="absolute right-0 top-0 p-2">
            <MdCancel
              size={30}
              className="cursor-pointer"
              onClick={() => {
                setIsParticipantsModal(false);
                if (setModalState) {
                  setModalState(false);
                }
              }}
              style={{
                color: "var(--main-colour)",
              }}
            />
          </div>
          <div>
            <CheckInCheckOutModal
              participants={participants}
              category={participantModalType}
            />
          </div>
        </Modal.Body>
      </Modal>
      {Object.entries(DRAG_RATING_KEYS).map((key, index) => (
        <div
          key={index}
          className={`${classes.box} relative px-4 py-5 shadow-md mt-5 max-w-md rounded-xl`}
        >
          {hasPermission ? (
            <svg width="40" height="40" className={classes.circle}>
              <circle cx="20" cy="20" r="20" fill="var(--main-colour-20)" />
              <text
                x="50%"
                y="55%"
                dominantBaseline="middle"
                textAnchor="middle"
                fill={getFlaggedParticipant(key[1].KEY) > 0 ? "red" : ""}
              >
                {getFlaggedParticipant(key[1].KEY)}
              </text>
            </svg>
          ) : (
            ""
          )}

          <h3 className="font-semibold text-default w-full text-center text-2xl">
            {organisation &&
            organisation.name === "NTU Career & Attachment Office" ? (
              <span className="text-xl">
                {DRAG_RATING_KEYS_ORG[key[1].TITLE.toUpperCase()].TITLE}
              </span>
            ) : (
              key[1].TITLE
            )}
          </h3>
          <p className="text-default text-sm text-center">
            {" "}
            {organisation &&
            organisation.name === "NTU Career & Attachment Office"
              ? ""
              : key[1].SUB_TITLE}
          </p>
          <div className="py-4 w-full">
            <div className="flex justify-center items-center flex-col relative">
              <p className="absolute top-4  text-default text-md">
                Average Before:
              </p>
              <SliderDisplay
                max={organisation?.maxRating ?? 10}
                withBackground={true}
                value={averageData?.checkIn[key[1].KEY]}
                checkIn={self?.checkIn[key[1].KEY]}
              />
            </div>
            <div className="flex justify-center items-center flex-col relative mt-4">
              <p className="absolute top-4   text-default text-md">
                Average After:
              </p>
              <SliderDisplay
                max={organisation?.maxRating ?? 10}
                withBackground={true}
                value={averageData?.checkOut[key[1].KEY]}
                checkOut={self?.checkOut[key[1].KEY]}
              />
            </div>
          </div>
          <EmptySpace height={"20px"} />
          {hasPermission ? (
            <Button
              onClick={() => participantsModalOnClickHandler(key[1])}
              text="View all"
              className={classes["modal-button"]}
            />
          ) : (
            ""
          )}
        </div>
      ))}
    </React.Fragment>
  );
};

export default CheckOutFacilScreen;
