import TemplateCard from "./TemplateCard";
import Organisation from "interface/OrganisationInterface";

interface TemplateSelectorProp {
  selectedItem: string | null;
  onSelect: Function;
  growthcircletypes: Organisation[];
}

const TemplateSelector = ({
  selectedItem,
  onSelect,
  growthcircletypes,
}: TemplateSelectorProp) => {
  return (
    <div className="w-full md:w-1/2 overflow-x-auto">
      <div>
        <p className="font-semibold my-4">Templates</p>
        <div className="flex gap-2" style={{ color: "#fff" }}>
          {growthcircletypes.map((data, index) => (
            <TemplateCard
              key={index}
              data={data}
              isSelected={selectedItem === data.name}
              onSelect={() => onSelect(data.name)}
            />
          ))}
        </div>
      </div>
    </div>
  );
};

export default TemplateSelector;
