import React from "react";
import { ReactComponent as ChatBubble } from "assets/GroundRules/ChatBubble.svg";
import { ReactComponent as Dice } from "assets/GroundRules/Dice.svg";
import { ReactComponent as Folder } from "assets/GroundRules/Folder.svg";
import { ReactComponent as Heart } from "assets/GroundRules/Heart.svg";
import { ReactComponent as Journey } from "assets/GroundRules/Journey.svg";
import { ReactComponent as LightBulb } from "assets/GroundRules/LightBulb.svg";
import { ReactComponent as Muscle } from "assets/GroundRules/Muscle.svg";

const ExpectIcons = (props) => {
  const { imageName } = props;
  // eslint-disable-next-line default-case
  switch (imageName) {
    case "Dice":
      return <Dice width={32} height={32} />;
    case "Folder":
      return <Folder width={32} height={32} />;
    case "Heart":
      return <Heart width={32} height={32} />;
    case "Journey":
      return <Journey width={32} height={32} />;
    case "LightBulb":
      return <LightBulb width={32} height={32} />;
    case "Muscle":
      return <Muscle width={32} height={32} />;
    case "ChatBubble":
      return <ChatBubble width={32} height={32} />;
  }
};

export default ExpectIcons;
