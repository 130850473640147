import LoadingWidget from "components/utility/LoadingWidget";
import PageWrapper from "components/utility/PageWrapper";
import { OrganisationContextProvider } from "context/OrganisationContext";
import useOrganisationContext from "hooks/organisation/useOrganisationContext";
import { useAuthContext } from "hooks/useAuthContext";
import { useFirestore } from "hooks/useFirestore";

import { useGrowthCirclesType } from "hooks/useGrowthCirlesType";
import { GrowthCircleSession } from "interface/GrowthCircleSessionInterface";
import Organisation from "interface/OrganisationInterface";
import { Profile } from "interface/ProfileInterface";
import {
  getGCSessionByHost,
  prepareGrowthCircleSession,
} from "models/growthCircleSession";
import { getOrgsWithPermission } from "models/organisation";
import { P_GENERATE_QR_CODE } from "models/permission";

import { useCallback, useEffect, useState } from "react";
import { useNavigate, useParams } from "react-router-dom";

import { updateGCSession } from "models/growthCircleSession";

const Staging = () => {
  const { getGrowthCircleTypeById } = useGrowthCirclesType();
  const { id } = useParams();
  const { profile } = useAuthContext();
  const { selectOrganisationByName } = useOrganisationContext();

  const [ownedGCSession, setOwnedGCSession] = useState<GrowthCircleSession>();

  const navigate = useNavigate();
  const { updateDocument } = useFirestore("users");
  useEffect(() => {
    if (!profile) return;
  }, [profile]);

  const myGCID = ownedGCSession?.id ?? profile?.facilGC ?? "";

  const getOrgsWithQRCodePermission = useCallback((profile: Profile) => {
    return getOrgsWithPermission(profile, P_GENERATE_QR_CODE);
  }, []);

  const fetchData = async () => {
    if (!profile) {
      return;
    }
    try {
      const orgs = await getOrgsWithQRCodePermission(profile);

      let session = ownedGCSession;

      // Check if user can create QR code and profile facilGC is not set or not equal to session ID
      if (
        (orgs.length !== 0 && profile.facilGC !== myGCID) ||
        (orgs.length !== 0 && profile.facilGC === "") ||
        (orgs.length !== 0 && profile.facilGC === undefined)
      ) {
        session = await prepareGrowthCircleSession(profile, orgs);
        setOwnedGCSession(session);
        updateDocument(profile.uid, { facilGC: myGCID });
      } else {
        // if the user already has a GrowthCircle Session no need to check again, just check if the session is expired
        const growthCircleSession = await getGCSessionByHost(profile.id);

        session = growthCircleSession;
        setOwnedGCSession(session);
      }

      // Return the session here if needed
      return session;
    } catch (error) {
      // Handle errors here
      console.error(error);
    }
  };

  const preparation = useCallback(
    async (id: string) => {
      const data = (await getGrowthCircleTypeById(id)) as Organisation;
      const session = await fetchData();
      if (data && session) {
        const updates = {
          organisation: data.name,
          type: data.name,
          subCategory: "",
        };

        selectOrganisationByName(data.name);
        await updateGCSession(session.id, updates);

        navigate(`/all-circles/welcome/${profile?.facilGC}`);
      }
    }, // eslint-disable-next-line
    [id, profile]
  );

  useEffect(() => {
    if (id) preparation(id);
    // eslint-disable-next-line
  }, [id, preparation]);

  return (
    <OrganisationContextProvider>
      <PageWrapper>
        <LoadingWidget />
      </PageWrapper>
    </OrganisationContextProvider>
  );
};

export default Staging;
