import FeedbackForm from "components/FeedBackForm/FeedbackForm";
import FeedbackFormTitle from "components/FeedBackForm/FeedbackFormTitle";
import QuestionGroup from "components/FeedBackForm/QuestionGroup";
import FiveStepHeader from "components/FiveStepHeader/FiveStepHeader";
import NGFooter from "components/Footers/NGFooter";
import Header from "components/Header/Header";
import BackAndNext from "components/utility/BackAndNext";
import Center from "components/utility/Center/Center";
import ContentWrapper from "components/utility/ContentWrapper";
import EmptySpace from "components/utility/EmptySpace";
import Steps from "components/utility/Steps";
import useNavigateFeedback from "hooks/feedback/useNavigateFeedback";
import { useAuthContext } from "hooks/useAuthContext";
import { useGrowthCircleContext } from "hooks/useGrowthCircleContext";
import useParticipantFeedback from "hooks/useParticipantFeedback";
import useParticipants from "hooks/useParticipants";
import { getFacilFeedbackQuestions } from "models/componentSettings/feedback/facilFeedback";
import { R_HOST } from "models/organisationRole";
import NoGCSessionWrapper from "pages/NoGCSession/NoGCSessionWrapper";
import { useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
import {
  COMPLETED_ROUTE,
  FEEDBACK_ROUTE_GROUP,
  FEEDBACK_ROUTE_SELF,
} from "strings/Routes";

const CheckOutFeedbackFacil = () => {
  const currentStep = 5;
  const stepsCount = 5;
  const { profile } = useAuthContext();
  const [isVisible, setIsVisible] = useState(false);
  const { growthCircleSession } = useGrowthCircleContext();
  const navigate = useNavigate();
  const handleNavigateFeedback = useNavigateFeedback();
  const { ParticipantRecord, sessionRole } = useParticipants();

  const facilFeedbackQuestions = getFacilFeedbackQuestions(
    growthCircleSession?.organisation ?? ""
  );
  const questionCategory = facilFeedbackQuestions.category;

  const category1Questions = facilFeedbackQuestions.subCategories[0].questions;

  const mainQuestions = facilFeedbackQuestions.subCategories[1].questions;

  const questionPrefixes =
    facilFeedbackQuestions.subCategories[1].questionPrefix ?? [];
  const questionPrefix =
    questionPrefixes[sessionRole?.name ?? ""] ?? questionPrefixes["default"];

  const otherFeedbackQuestion = facilFeedbackQuestions.subCategories[2].questions[0];

  const [otherFeedback, setOtherFeedback] = useParticipantFeedback(
    otherFeedbackQuestion.defaultResponse + '',
    questionCategory,
    otherFeedbackQuestion.questionName
  );

  useEffect(() => {
    if (!profile) return;
    if (!ParticipantRecord) return;
    if (ParticipantRecord[0]?.feedbackReflectionStatus === "submitted") {
      navigate(COMPLETED_ROUTE);
    }
    setIsVisible(false);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [isVisible, profile, ParticipantRecord]);
  const [hasTrigger, setHasTrigger] = useState(false);

  const _setTrigger = () => {
    setHasTrigger(!hasTrigger);
  };

  const nextHandler = () => {
    navigate(FEEDBACK_ROUTE_GROUP);
  };

  const backHandler = () => {
    navigate(FEEDBACK_ROUTE_SELF);
  };

  return (
    <NoGCSessionWrapper>
      <Header hasModal={isVisible || hasTrigger} />
      <FiveStepHeader
        hasModal={isVisible || hasTrigger}
        hasLightning={true}
        hasGuide={false}
        currentStep={currentStep}
        text="Reflection on Circle"
        setIsTriggerVisible={_setTrigger}
      />
      <ContentWrapper hasFiveStepper={true}>
        <Center minHeight="100vh">
          <EmptySpace height={"5px"} />
          <Steps count={stepsCount} currentStep={currentStep} />
          <EmptySpace height={"15px"} />
          <FeedbackForm activeTab={2} handleSwitchTabs={handleNavigateFeedback}>
            <FeedbackFormTitle>
              {facilFeedbackQuestions.title}
            </FeedbackFormTitle>
            <EmptySpace height={"10px"} />
            {sessionRole?.name && sessionRole.name !== R_HOST && (
              <QuestionGroup
                questionCategory={questionCategory}
                questions={category1Questions}
                sessionRole={sessionRole}
              />
            )}
            <QuestionGroup
              label={questionPrefix}
              questionCategory={questionCategory}
              questions={mainQuestions}
              sessionRole={sessionRole}
            />
            <QuestionGroup>
              <p className="text-slate-600 font-[700] px-4 text-left select-none">
                {otherFeedbackQuestion.question[sessionRole?.name ?? ""] ??
                  otherFeedbackQuestion.question.default}
              </p>
              <textarea
                defaultValue={otherFeedback}
                onChange={(event) => setOtherFeedback(event.target.value)}
                style={{ borderColor: "var(--main-colour)" }}
                className={`w-full border-2 rounded-lg bg-default shadow-lg  px-4 py-2 focus:ring-main-colour focus:border-main-colour `}
                rows={5}
              />
            </QuestionGroup>
          </FeedbackForm>
          <EmptySpace height={"50px"} />
          <BackAndNext
            nextAllowed={true}
            nextCallback={nextHandler}
            backAllowed={true}
            backCallback={backHandler}
            backHidden={undefined}
          />
          <NGFooter type={growthCircleSession?.type} />
        </Center>
      </ContentWrapper>
    </NoGCSessionWrapper>
  );
};

export default CheckOutFeedbackFacil;
