import FeedBackFormTabs, {
  FeedbackFormTabsProps,
} from "components/NG/FeedBackFormTabs";

import { ReactNode } from "react";

type Props = {
  children: ReactNode;
  organisation?: string;
  viewOnly?: boolean;
} & FeedbackFormTabsProps;

export default function FeedbackForm({
  children,
  organisation,
  viewOnly,
  ...feedbackFormTabsProps
}: Props) {
  return (
    <div className="bg-white w-full max-w-[370px] md:max-w-[460px] min-h-screen rounded-tl-3xl rounded-tr-3xl shadow-lg">
      <FeedBackFormTabs
        viewOnly={viewOnly}
        organisation={organisation}
        {...feedbackFormTabsProps}
      />
      <div className="w-full flex flex-col justify-center items-center text-center">
        {children}
      </div>
    </div>
  );
}
