import React, { useEffect, useState, lazy, Suspense } from "react";
import { ReactComponent as LightningSymbol } from "./../../assets/LightningSymbol.svg";
import { ReactComponent as QuestionMark } from "assets/QuestionCircleFill.svg";
import classes from "./FiveStepHeader.module.css";
import Modal from "components/utility/Modal";
import { Modal as FlowModal } from "flowbite-react";

import StepperModal from "components/utility/StepperModal/StepperModal";
import useStepperModal from "hooks/useStepperModal";
import useGetParticipants from "hooks/useGetParticipants";
import { useAuthContext } from "hooks/useAuthContext";

// import useMassCRUD from "hooks/useMassCRUD";
import useMount from "hooks/useMount";
import { MdCancel } from "react-icons/md";
import { getRoleByUserID } from "models/organisationRole";
import useOrganisationContext from "hooks/organisation/useOrganisationContext";

const Trigger = lazy(() => import("components/Trigger/TriggerContent"));

const ParticipantTriggeredModalContent = lazy(
  () => import("components/Trigger/ParticipantTriggeredModalContent")
);

interface ButtonProps {
  [key: string]: React.Dispatch<React.SetStateAction<boolean>>;
}

const StepGuideButton: React.FC<ButtonProps> = ({ setIsStepperModalOpen }) => {
  return (
    <button
      type="button"
      className={`${classes["clearButtonStyle"]} ${classes["guide"]}`}
      onClick={() => {
        setIsStepperModalOpen(true);
      }}
    >
      <QuestionMark />
    </button>
  );
};

const TriggerGuideButton: React.FC<ButtonProps> = ({
  setIsTriggerModalOpen,
}) => {
  return (
    <button
      type="button"
      className={`${classes["clearButtonStyle"]} ${classes["lightning-container"]}`}
      onClick={() => {
        setIsTriggerModalOpen((state) => !state);
      }}
    >
      <LightningSymbol fill={"var(--main-colour)"} />
    </button>
  );
};

interface Props {
  hasLightning: boolean;
  hasGuide: boolean;
  hasHelper?: boolean;
  hasModal?: boolean;
  setIsHelperVisible?: Function;
  setIsTriggerVisible?: Function;
  currentStep: number;
  showCurrentStep?: boolean;
  text?: string;
  subPage?: number;
  zIndex?: number;
  headerString?: string;
}

const ReflectionSubTitle = {
  1: "on Circle",
  2: "on Self",
  3: "on Self",
  4: "End of Reflection",
};

//Boolean attributes to include lightning symbol and question mark
const FiveStepHeader: React.FC<Props> = ({
  hasLightning,
  hasGuide,
  hasHelper,
  hasModal,
  setIsHelperVisible,
  setIsTriggerVisible,
  currentStep,
  text,
  showCurrentStep,
  subPage,
  zIndex,
  headerString,
}) => {
  /**
   * key-value constant mappings
   */
  const CONSTANTS = {
    TRIGGER_TITLE: "Trigger",
  };

  const {
    isStepperModalOpen,
    setIsStepperModalOpen,
    headerText,
    currentDetails,
  } = useStepperModal(currentStep);

  const [currentText, setCurrentText] = useState(headerText);
  const [isTriggerModalOpen, setIsTriggerModalOpen] = useState(false);
  const { triggeredParticipants, triggeredParticipantsForDisplay } =
    useGetParticipants();
  const { selectedOrganisation } = useOrganisationContext();

  const { profile } = useAuthContext();
  const [isFacil, setIsFacil] = useState(false);

  useEffect(() => {
    if (isTriggerModalOpen) setCurrentText(CONSTANTS.TRIGGER_TITLE);
    else setCurrentText(headerText);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [isTriggerModalOpen]);

  useEffect(() => {
    if (!text) return;
    setCurrentText(text);
  }, [text]);

  const toggleTriggerHandler = () => {
    setIsTriggerModalOpen(!isTriggerModalOpen);

    if (setIsTriggerVisible) {
      setIsTriggerVisible(!isTriggerModalOpen);
    }
    setIsParticipantTriggeredModalOpen(!isParticipantTriggeredModalOpen);
  };

  const [isParticipantTriggeredModalOpen, setIsParticipantTriggeredModalOpen] =
    useState(false);
  useEffect(() => {
    if (!selectedOrganisation) return;
    if (!profile) return;
    if (triggeredParticipants.length === 0) return;

    getRoleByUserID(profile?.id, selectedOrganisation).then((role) => {
      if (role && role.name === "facilitator") {
        setIsFacil(true);
        if (!isParticipantTriggeredModalOpen) {
          setIsParticipantTriggeredModalOpen(true);
        }
        if (setIsTriggerVisible) {
          setIsTriggerVisible(!isParticipantTriggeredModalOpen);
        }
      }
    });

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [profile?.isFacil, triggeredParticipants, profile, selectedOrganisation]);

  //const { massUpdate } = useMassCRUD();
  const isMount = useMount();
  useEffect(() => {
    if (isMount) return;
    //if (isParticipantTriggeredModalOpen === true) return;
    //TODO revisit logic how to record triggered history
    // massUpdate(`GrowthCircles/${profile?.growthCircle}/Participants`, {
    //   triggered: false,
    // });

    if (hasModal) {
      document.body.style.overflowY = "hidden";
    } else {
      document.body.style.overflowY = "unset";
    }
    //console.log(hasModal, isParticipantTriggeredModalOpen);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [isParticipantTriggeredModalOpen, hasModal]);

  const getCurrentText = () => {
    if (subPage) {
      switch (subPage) {
        case 1:
          return currentText + " " + ReflectionSubTitle[subPage];
          // eslint-disable-next-line no-unreachable
          break;
        case 2:
          return currentText + " " + ReflectionSubTitle[subPage];
          // eslint-disable-next-line no-unreachable
          break;
        case 3:
          return currentText + " " + ReflectionSubTitle[subPage];
          // eslint-disable-next-line no-unreachable
          break;
        case 4:
          return ReflectionSubTitle[4];
          // eslint-disable-next-line no-unreachable
          break;
        default:
          return currentText;
          // eslint-disable-next-line no-unreachable
          break;
      }
    } else {
      return currentText;
    }
  };

  const participantIsTriggeredHandler = () => {
    setIsParticipantTriggeredModalOpen(!isParticipantTriggeredModalOpen);
    if (setIsTriggerVisible) {
      setIsTriggerVisible(!isParticipantTriggeredModalOpen);
      console.log("this is called");
    }
  };

  //TODO: temporary condition
  const HeaderText =
    selectedOrganisation &&
    selectedOrganisation.name === "SGH Peer Circles" &&
    headerString === "Reflections"
      ? "Check-Out"
      : headerString;

  return (
    <div
      className={`${classes["five-step-header-container-dummy"]} ${
        hasModal ? "block -z-1000  -top-[8%]  right-0" : ""
      }`}
    >
      <div className={classes["five-step-header-container"]}>
        {/* participant triggered modal */}
        {isFacil && (
          <React.Fragment>
            <FlowModal
              show={isParticipantTriggeredModalOpen}
              size="md"
              className="min-h-screen"
            >
              <FlowModal.Body className="relative mt-[20%] md:mt-[5%]  overflow-x-auto  rounded-lg">
                <div className="absolute right-0 top-0 p-2">
                  <MdCancel
                    size={30}
                    className="cursor-pointer"
                    onClick={participantIsTriggeredHandler}
                    style={{
                      color: "var(--main-colour)",
                    }}
                  />
                </div>
                <div className="text-center w-full py-5" id="trigger-modal">
                  <Suspense fallback={<div>Loading...</div>}>
                    <ParticipantTriggeredModalContent
                      triggeredParticipants={triggeredParticipantsForDisplay}
                      setIsModalOpen={participantIsTriggeredHandler}
                    />
                  </Suspense>
                </div>
              </FlowModal.Body>
            </FlowModal>
          </React.Fragment>
        )}
        {/* trigger modal */}

        <React.Fragment>
          <FlowModal
            show={isTriggerModalOpen}
            size="md"
            className="min-h-screen"
          >
            <FlowModal.Body
              className="relative overflow-x-auto  rounded-lg"
              style={{ backgroundColor: "var(--main-colour)" }}
            >
              <div className="absolute right-0 top-0 p-2">
                <MdCancel
                  size={30}
                  className="cursor-pointer"
                  onClick={toggleTriggerHandler}
                  style={{
                    color: "white",
                  }}
                />
              </div>
              <div className="text-center w-full py-5">
                <Suspense fallback={<div>Loading...</div>}>
                  <Trigger />
                </Suspense>
              </div>
            </FlowModal.Body>
          </FlowModal>
        </React.Fragment>

        {/* stepper modal */}
        {showCurrentStep && (
          <Modal
            isModalOpen={isStepperModalOpen}
            setIsModalOpen={setIsStepperModalOpen}
            hasFadeIn={true}
            backgroundColor={"var(--main-colour-2)"}
            maxWidth={"fit-content"}
            minWidth={"fit-content"}
            maxHeight={"fit-content"}
            minHeight={"fit-content"}
            padding={"0px"}
            offOverlay={true}
            isScrollBlocked={true}
            clickOutsideToClose={true}
            top={"20%"}
          >
            <StepperModal
              currentStep={currentStep}
              currentTitle={headerText}
              currentDetails={currentDetails}
            />
          </Modal>
        )}

        <div id="five-step-header-modal" />

        {hasLightning && (
          <TriggerGuideButton setIsTriggerModalOpen={toggleTriggerHandler} />
        )}

        <strong key={currentText}>
          {HeaderText ? HeaderText : getCurrentText()}
        </strong>
        {hasGuide && (
          <StepGuideButton setIsStepperModalOpen={setIsStepperModalOpen} />
        )}

        {hasHelper && (
          <button
            type="button"
            className={`${classes["clearButtonStyle"]} ${classes["guide"]}`}
            onClick={() => {
              setIsHelperVisible!(true);
            }}
          >
            <QuestionMark />
          </button>
        )}
      </div>
    </div>
  );
};

FiveStepHeader.defaultProps = {
  showCurrentStep: true,
  zIndex: 1000,
  hasHelper: false,
};

export default FiveStepHeader;
