import { useEffect, useReducer, useState } from "react";
import { actionStructure, firestoreReducer } from "./useFirestore";
import { projectFirestore } from "../firebase/config";
import firebase from "firebase/compat/app";
import {
  FeedbackForm,
  isOfTypeBadgeCount,
} from "interface/FeedbackFormInterface";
// import { LocalStorageKeys } from "App";
import { useAuthContext } from "./useAuthContext";

let initialState = {
  document: null,
  isPending: false,
  error: null,
  success: false,
};

/**
 * Hook that consist of CRUD methods (not real-time) for the "FeedBackForm" document
 */
const useFeedbackForm = () => {
  const { profile } = useAuthContext();
  const [response, dispatch] = useReducer(firestoreReducer, initialState);
  const [isCancelled, setIsCancelled] = useState<boolean>(false);
  const ref = firebase.firestore().collection("FeedBackForm");

  /**
   * Based on the userId and growthCircleId of the current profile, check if the user has already submitted the end check-in (End-Rating)
   */
  const endCheckInSubmitted = (): Promise<boolean> => {
    const query = ref
      .where("userId", "==", profile!.id)
      .where("growthCircle", "==", profile!.growthCircle);

    return query
      .get()
      .then((querySnapShot) => {
        if (querySnapShot.empty) return false;

        const feedbackForm: FeedbackForm =
          querySnapShot.docs[0].data() as FeedbackForm;

        return feedbackForm.refPersonalDragValues !== undefined;
      })
      .catch((error) => {
        console.log("Error getting document: ", error);
        return false;
      });
  };

  /**
   * Based on the userId and growthCircleId of the current profile, check if the user has already submitted the feedback form (Reflection-Rating)
   */
  //TODO: FeedbackForm check if already submitted
  const feedbackSubmitted = (): Promise<boolean> => {
    const query = ref
      .where("userId", "==", profile!.id)
      .where("growthCircle", "==", profile!.growthCircle);

    return query
      .get()
      .then((querySnapShot) => {
        if (querySnapShot.empty) return false;

        const feedbackForm: FeedbackForm =
          querySnapShot.docs[0].data() as FeedbackForm;

        return (
          feedbackForm.refGcSmileyRatings !== undefined &&
          feedbackForm.refOpenEndedFields !== undefined &&
          feedbackForm.refPersonalSmileyRatings !== undefined
        );
      })
      .catch((error) => {
        console.log("Error getting document: ", error);
        return false;
      });
  };

  // only dispatch is not cancelled
  const dispatchIfNotCancelled = (action: actionStructure) => {
    // if (!isCancelled) {
    dispatch(action);
    // }
  };

  /**Set a document using a transaction
   * @param id - id to be set
   * @param doc - document to be set
   */
  const setFeedbackFormTransaction = async (
    id: string,
    doc: Partial<FeedbackForm>
  ) => {
    dispatch({ type: "IS_PENDING" });
    try {
      const docRef = ref.doc(id ? id : "");
      const createdAt = firebase.firestore.FieldValue.serverTimestamp();
      await projectFirestore.runTransaction(async (transaction) => {
        await transaction.get(docRef).then((document) => {
          //Set if document does not already exist
          if (!document.exists) {
            //console.log("Setting document");
            transaction.set(docRef, { ...doc, createdAt: createdAt });
          } else {
            //Update the document
            let newDoc = { ...doc };
            const keys = Object.keys(doc); //All keys of fields to update
            const data = document.data() as firebase.firestore.DocumentData; //Current data in the database
            keys.forEach((key, index) => {
              //If there already exists a current count for badge ratings for that user, increment it.
              const currentField = data[key];
              // console.log(!!currentField && isOfTypeBadgeCount(currentField));
              //console.log(key);
              if (!!currentField && isOfTypeBadgeCount(currentField)) {
                const updatedBadgeRatings = { ...doc[key] };
                //For each badge, updated the ratings accordingly
                Object.keys(updatedBadgeRatings).forEach((badgetype) => {
                  const currentCount: number | undefined = data[key][badgetype];
                  if (!!currentCount) {
                    updatedBadgeRatings[badgetype] =
                      updatedBadgeRatings[badgetype] + currentCount;
                  }
                });
                newDoc = {
                  ...newDoc,
                  [key]: updatedBadgeRatings,
                };
              }
            });
            console.info("Updating feedbackform");
            transaction.update(docRef, newDoc);
          }
          console.info("Successful feedbackform set");
          dispatchIfNotCancelled({
            type: "ADDED_DOCUMENT",
            payload: doc,
          });
        });
      });
    } catch (err) {
      let msg = "";
      if (err instanceof Error) msg = err.message;
      dispatchIfNotCancelled({ type: "ERROR", payload: msg });
      console.error("this was called error :", err);
      return null;
    }
  };

  /**
   * Updates a document using a transaction so that previous data in the field will not be lost, applicable to arrays
   * @param id - documentId
   * @param updates - object of fields to be updated
   * @param type - name of field to be updated (should be an array)
   *
   */
  const updateFeedbackFormTransaction = async (
    id: string,
    updates: { [key: string]: any }
  ) => {
    dispatch({ type: "IS_PENDING" });

    try {
      //Get docRef
      const docRef = ref.doc(id);

      await projectFirestore.runTransaction(async (transaction) => {
        //Code will get re-run multiple times if there are conflicts
        await transaction.get(docRef).then((doc) => {
          if (!doc.exists) {
            throw new Error("Document does not exist!");
          }

          //Do a manual deep merge with updates and docRef (to not overwrite arrays)
          //1. Loop through the keys of updates
          const keys = Object.keys(updates);
          keys.forEach((key, index) => {
            //1.1 if updates.key is an Array, make new value of updates new Set [...docRef.key, ...updates.key]
            const updateValue = updates[key];
            if (Array.isArray(updateValue)) {
              const docData = doc.data() as firebase.firestore.DocumentData;
              //Merges all unique ids
              updates[key] = Array.from(
                new Set([...docData[key], ...updateValue])
              );
              // console.log(updates[key]);
            }
          });

          transaction.update(docRef, updates);
          //console.log("Successful update transaction");
          dispatchIfNotCancelled({
            type: "UPDATED_DOCUMENT",
            payload: updates,
          });
        });
      });
    } catch (err) {
      let msg = "";
      if (err instanceof Error) msg = err.message;
      dispatchIfNotCancelled({ type: "ERROR", payload: msg });
      console.log("this was called error :", err);
      return null;
    }
  };

  useEffect(() => {
    return () => setIsCancelled(true);
  }, []);

  return {
    feedbackSubmitted,
    endCheckInSubmitted,
    updateFeedbackFormTransaction,
    setFeedbackFormTransaction,
    response,
    isCancelled,
  };
};

export default useFeedbackForm;
