import classes from "./WaitingAreaNext.module.css";
import { Participant } from "interface/ParticipantInterface";
import { useGCSession } from "../../hooks/useGCSession";
// import { DICE_ROUTE } from "strings/Routes";
import { useGrowthCircleContext } from "hooks/useGrowthCircleContext";
import useParticipants from "hooks/useParticipants";
import { checkCanAdvanceNextActivity } from "models/organisationRole";
import { BackAndNextProps } from "pages/AllCircles/GenericSessionPage/ComponentMapping";
import { useEffect } from "react";
import { resetDice } from "models/growthCircleSession";
import Sage from "assets/BeanSquad/sage.png";

interface Props {
  participants: Participant[];
  backAndNextHandler: BackAndNextProps;
}

const WaitingAreaNextComponent: React.FC<Props> = ({
  participants,
  backAndNextHandler,
}) => {
  const { facilAllowNextPage } = useGCSession();
  const { growthCircleSession } = useGrowthCircleContext();
  const { sessionRole } = useParticipants();

  const canAdvance = checkCanAdvanceNextActivity(sessionRole);
  const clickHandler = (e: React.MouseEvent<HTMLButtonElement, MouseEvent>) => {
    if (canAdvance) {
      facilAllowNextPage("dice");
    }
    // navigate(DICE_ROUTE);
    backAndNextHandler.nextCallback(e);
  };

  useEffect(() => {
    if (!growthCircleSession) return;
    if (!sessionRole) return;
    if (sessionRole?.name === "host") {
      if (participants.length > 0) {
        resetDice(growthCircleSession.id);
      }
    }

    // eslint-disable-next-line
  }, [sessionRole]);

  //console.log(canAdvance, growthCircleSession!.allowDice);
  return (
    <>
      <p className={classes["prompt"]}>
        {participants.length >= growthCircleSession!.number
          ? ""
          : "Waiting for others..."}
      </p>

      <div className={classes["avatar-helper"]}>
        {/* TODO: exclude facilitator */}
        {participants.length >= growthCircleSession!.number ||
        canAdvance ||
        growthCircleSession!.allowDice ? (
          <button
            className={classes["submitButton"]}
            onClick={(e: React.MouseEvent<HTMLButtonElement, MouseEvent>) =>
              clickHandler(e)
            }
            type="button"
          >
            NEXT
          </button>
        ) : (
          <img src={Sage} alt="Sage" />
        )}
      </div>
    </>
  );
};

export default WaitingAreaNextComponent;
