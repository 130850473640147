import { FC, useEffect, useState } from "react";
import { ComponentProps } from "../SelfAwarenessComponentMapping";
import BackAndNextFlat from "components/utility/BackAndNextFlat";
import GradingCard from "./GradingCard";
import { useSelfAwarenessContext } from "hooks/useSelfAwarenessContext";
import TitleBox from "../SubComponents/TitleBox";
import TextArea from "../SubComponents/TextArea";
import { MaterialMapping } from "./MaterialMapping";
import Lightbox, { ImagesListType } from "react-spring-lightbox";
import ConfirmModal from "../SubComponents/ConfirmModal";
import InfoModal from "../SubComponents/InfoModal";

const WorstRanking: FC<ComponentProps> = ({
  backAndNextHandler,
  setHasModal,
}) => {
  const [selectedItemGrade, setSelectedItemGrade] = useState<
    Record<string, number>
  >({});
  const [answer, setAnswer] = useState("");
  const { journal } = useSelfAwarenessContext();
  const [show, setShow] = useState<boolean>(false);
  const [showLightBox, setShowLightBox] = useState(false);
  const [activeIndex, setActiveIndex] = useState(0);
  const [showConfirm, setShowConfirm] = useState<boolean>(false);
  const images: ImagesListType = journal
    ? [
        {
          src: MaterialMapping[journal.selectionModalImageSource],
          loading: "lazy",
          alt: "Uploaded image",
        },
      ]
    : [];

  const handler = () => {
    setHasModal(!show);
    setShow(!show);
  };

  const gotoPrevious = () => {
    activeIndex > 0 && setActiveIndex(activeIndex - 1);
  };

  const gotoNext = () => {
    activeIndex + 1 < images.length && setActiveIndex(activeIndex + 1);
  };

  // Load data from local storage on component mount
  useEffect(() => {
    const storedSelectedItems = localStorage.getItem("SelfAwarenessJournal");

    if (storedSelectedItems) {
      const parsedJournal = JSON.parse(storedSelectedItems);
      setSelectedItemGrade(parsedJournal.worstGrading || []);
      setAnswer(parsedJournal.reflection || "");
    }
  }, []);

  const answerHandler = (value: string) => {
    setAnswer(value);
    const storedJournal = localStorage.getItem("SelfAwarenessJournal");
    const existingJournal = storedJournal ? JSON.parse(storedJournal) : {};

    // Update the existing journal with the new answer
    const updatedJournal = {
      ...existingJournal,
      reflection: value,
    };

    // Store the updated journal in local storage
    localStorage.setItem(
      "SelfAwarenessJournal",
      JSON.stringify(updatedJournal)
    );
  };

  const nextHandler = () => {
    setShowConfirm(!showConfirm);
    setHasModal(!showConfirm);
  };

  useEffect(() => {
    if (!journal) return;
  }, [journal]);

  return (
    <>
      {journal && (
        <>
          <ConfirmModal
            showConfirm={showConfirm}
            handler={nextHandler}
            backAndNextHandler={backAndNextHandler}
            setHasModal={setHasModal}
          />

          <InfoModal journal={journal} handler={handler} show={show} />
        </>
      )}
      <div className="py-8 px-4 w-full md:w-1/4">
        {journal && (
          <TitleBox
            title="What can you be prepared for? What is within your control or influence?"
            showInfo={true}
            journal={journal}
            handler={handler}
          />
        )}
        <div className="flex flex-col lg:flex-row gap-4 items-center justify-center my-4">
          {Object.keys(selectedItemGrade)
            .sort((a, b) => selectedItemGrade[b] - selectedItemGrade[a])
            .map((title, index) => (
              <GradingCard
                key={index}
                title={title}
                selectedItemGrade={selectedItemGrade}
              />
            ))}
        </div>
        {journal && (
          <TextArea
            maxLength={500}
            journal={journal}
            answerHandler={answerHandler}
            answer={answer}
          />
        )}
        <div className="my-8">
          <BackAndNextFlat
            {...backAndNextHandler}
            nextCallback={nextHandler}
            nextAllowed={answer.length > 0 ? true : false}
          />
        </div>
      </div>{" "}
      <Lightbox
        isOpen={showLightBox}
        onPrev={gotoPrevious}
        onNext={gotoNext}
        images={images}
        currentIndex={activeIndex}
        style={{ background: "grey" }}
        /* Handle closing */
        onClose={() => setShowLightBox(!showLightBox)}
        /* Use single or double click to zoom */
        // singleClickToZoom

        /* react-spring config for open/close animation */
        pageTransitionConfig={{
          from: { transform: "scale(0.75)", opacity: 0 },
          enter: { transform: "scale(1)", opacity: 1 },
          leave: { transform: "scale(0.75)", opacity: 0 },
          config: { mass: 1, tension: 320, friction: 32 },
        }}
      />
    </>
  );
};

export default WorstRanking;
