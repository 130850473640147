import classes from "./ChooseRoles.module.css";
import CarouselRoles from "components/ChooseRoles/CarouselRoles";
import { useCarousel } from "../../../hooks/useCarousel";
import React, { lazy, useEffect, useState, Suspense } from "react";
import { useAuthContext } from "../../../hooks/useAuthContext";
import { useCollection } from "../../../hooks/useCollection";
import EmptySpace from "../../../components/utility/EmptySpace";
import ModalContent from "../../../components/ChooseRoles/ModalContent";
import useParticipants from "../../../hooks/useParticipants";
import useUpdateProgress from "../../../hooks/useUpdateProgress";
import { useGrowthCircleContext } from "../../../hooks/useGrowthCircleContext";
import Role from "../../../interface/RoleInterface";
import { useSwipeable } from "react-swipeable";
import { Modal } from "flowbite-react";
import { MdCancel } from "react-icons/md";
import { ReactComponent as InfoButton } from "assets/InfoButtonWhite.svg";
import { InstructionModal } from "components/ChooseRoles/InstructionModal";
import { ComponentProps } from "../GenericSessionPage/ComponentMapping";
import BackAndNext from "components/utility/BackAndNext";
import { HiOutlineExclamationCircle } from "react-icons/hi";
import SimpleLoader from "components/Loaders/SimpleLoader";

const Carousel = lazy(() => import("components/ChooseRoles/Carousel"));
const Roles = lazy(() => import("components/ChooseRoles/Role/Roles"));

interface SkillCollection {
  [key: string]: Role[];
}

// documents is in the format of [{activeListening: {}} ...]
// this method gets all the field names
const getGeneralSkillArray = (documents: SkillCollection[]): string[] => {
  return documents.map((skill) => {
    delete skill.createdAt; // need to remove all fields until only left with skill object
    delete skill.id;
    return Object.keys(skill)[0];
  });
};

const ChooseRolesComponent = ({
  setHasModal,
  backAndNextHandler,
}: ComponentProps) => {
  const { documents } = useCollection("Skills", undefined, [
    "createdAt",
    "asc",
  ]);
  const { profile } = useAuthContext();
  const { growthCircleSession } = useGrowthCircleContext();
  const { updatedProgressObject } = useUpdateProgress(documents, profile);
  const [showInstructions, setShowInstructions] = useState(false);

  //Props
  const [currSelected, setCurrSelected] = useState<Role | null>(null);
  const { currentIndex, next, prev, changeIndex } = useCarousel(
    documents ? documents.length : 0
  );

  // skillContent contains information about each level
  const currSkill = documents[currentIndex];
  const skillContent =
    documents.length > 0 ? currSkill[Object.keys(currSkill)[0]] : [];

  // array of general skills in carousel
  const skillNameArray = getGeneralSkillArray(documents);

  // Modal for not selecting any role
  const [isModalOpen, setIsModalOpen] = useState(false);

  useEffect(() => {
    if (isModalOpen || showInstructions) {
      setHasModal(isModalOpen || showInstructions);
      document.body.style.overflowY = "hidden";
    } else {
      setHasModal(isModalOpen || showInstructions);
      document.body.style.overflowY = "unset";
    }

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [isModalOpen, showInstructions]);

  // firebase
  const { updateRole, role } = useParticipants();

  // submit role next button
  const submitHandler = () => {
    if (!currSelected) {
      return;
    }
    updateRole(currSelected);
  };

  // set state to role if already exist
  useEffect(() => {
    if (role) {
      setCurrSelected(role);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [role]);

  const swipeHandlers = useSwipeable({
    onSwipedLeft: next,
    onSwipedRight: prev,
  });

  const modalHandler = () => {
    setHasModal(!isModalOpen);
    setIsModalOpen(!isModalOpen);
  };

  const psb = document.getElementById("perfectScrollBar");
  psb?.addEventListener("scroll", (e) => {
    e.stopPropagation();
    e.preventDefault();
  });

  return (
    <React.Fragment>
      {growthCircleSession && updatedProgressObject && (
        <>
          <Suspense
            fallback={
              <div>
                <SimpleLoader />
              </div>
            }
          >
            <InstructionModal
              isModalOpen={showInstructions}
              setIsModalOpen={setShowInstructions}
            />
          </Suspense>
          <React.Fragment>
            <Modal show={isModalOpen} size="md" className="min-h-screen">
              <Modal.Body className="relative overflow-x-auto  rounded-lg">
                <HiOutlineExclamationCircle className="mx-auto mb-2 h-24 w-24 text-gray-400 dark:text-gray-200" />
                <div className="absolute right-0 top-0 p-2">
                  <MdCancel
                    size={30}
                    className="cursor-pointer"
                    onClick={modalHandler}
                    style={{
                      color: "var(--main-colour)",
                    }}
                  />
                </div>
                <div className="text-center w-full py-5" id="trigger-modal">
                  <ModalContent />
                </div>
              </Modal.Body>
            </Modal>
          </React.Fragment>

          <div className="py-6 text-center w-[95vw]">
            <div className="flex flex-row gap-4 justify-center items-center">
              <h1 className="font-bold text-2xl">
                Pick a role / Build a skill
              </h1>
              <InfoButton onClick={() => setShowInstructions(true)} />
            </div>
            <EmptySpace />
            <h2 className="font-bold text-sm">
              Swipe left or right, then choose a card to determine your role.
            </h2>
            <h3 className="text-sm">
              {`  A thicker ${
                profile?.isFacil ? "red border" : "orange border"
              } will appear around the card of the
                    role that you have clicked on.`}
            </h3>
          </div>
          <div className={classes["choose-roles-content"]} {...swipeHandlers}>
            <Suspense
              fallback={
                <div>
                  <SimpleLoader />
                </div>
              }
            >
              <Carousel
                children={skillNameArray.map((role, index) => {
                  return (
                    <CarouselRoles
                      key={index}
                      name={role}
                      index={index}
                      currentIndex={currentIndex}
                      setCurrentIndex={changeIndex}
                    />
                  );
                })}
                next={next}
                prev={prev}
                currentIndex={currentIndex}
                displayCount={"3"}
                arrowOffset={"2%"}
                width={"80%"}
                arrowColor={"var(--main-colour)"}
              />
            </Suspense>
            <Suspense
              fallback={
                <div>
                  <SimpleLoader />
                </div>
              }
            >
              <Roles
                currentIndex={currentIndex}
                content={skillContent}
                currSelected={currSelected}
                setCurrSelected={setCurrSelected}
              />
            </Suspense>
          </div>
          <BackAndNext
            {...backAndNextHandler}
            nextAllowed={currSelected && backAndNextHandler.nextAllowed}
            nextCallback={(e) => {
              submitHandler();
              backAndNextHandler.nextCallback(e);
            }}
            nextFailCallback={(e) => {
              setIsModalOpen(true);
              setHasModal(true);
            }}
            isFixed={true}
          />
        </>
      )}
    </React.Fragment>
  );
};

export default ChooseRolesComponent;
