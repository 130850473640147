import classes from "./RoleFrontBottom.module.css";
import EmptySpace from "components/utility/EmptySpace";
import Box from "components/utility/Box/Box";
// import PerfectScrollbar  from 'react-perfect-scrollbar'; 
import 'react-perfect-scrollbar/dist/css/styles.css';
import {
  STEPS_TITLE,
  EXAMPLE_TITLE,
  SAMPLE_QUESTIONS_TITLE,
} from "strings/ChooseRoles";

interface Props {
  stepsToTake: string[];
  example: string[];
  description: string;
  sampleQuestion: string[];
  questionPrompts: string;
}

/**
 * Renders the bottom half of the RoleFront component
 *
 * @param stepsToTake array of steps to take
 * @param example array of examples
 * @param description string description
 * @param sampleQuestion array of sample questions
 * @param questionPrompts string question prompts
 * @returns
 */
const RoleFrontBottom: React.FC<Props> = ({
  stepsToTake,
  example,
  description,
  sampleQuestion,
  questionPrompts,
}) => {

  return (
    // <PerfectScrollbar style={{ borderBottomLeftRadius: "9px", borderBottomRightRadius: "9px", overflowY: 'scroll'}} >
    <div className={classes["role-text"]}>
      <strong className={classes["steps-example"]}>{STEPS_TITLE}</strong>
      <ol>
        {stepsToTake.map((step, index) => {
          return (
            <li key={index}>
              <p>{step}</p>
            </li>
          );
        })}
      </ol>
      <EmptySpace height={"20px"} width={"100%"} />
      <strong className={classes["steps-example"]}>{EXAMPLE_TITLE}</strong>
      {example.map((example, index) => {
        return <p key={example}>{example}</p>;
      })}
      <EmptySpace height={"20px"} width={"100%"} />
      <Box padding={"0px"}>
        <div className={classes["clarify-header"]}>
          <strong>{questionPrompts}</strong>
        </div>
        <div className={classes["clarify-text"]}>
          <strong>{description}</strong>
          <EmptySpace height={"20px"} width={"100%"} />
          <strong className={classes["question-header"]}>
            {SAMPLE_QUESTIONS_TITLE}
          </strong>
          {sampleQuestion.map((question, index) => {
            return <p key={index}>{question}</p>;
          })}
        </div>
      </Box>
      <EmptySpace height="30px"/>
    </div>
    // </PerfectScrollbar>
  );
};

export default RoleFrontBottom;
