import { Modal } from "flowbite-react";
import { useEffect, useState } from "react";
import { AiFillCloseCircle, AiFillPlusCircle } from "react-icons/ai";
import classes from "pages/AllCircles/Admin/Admin.module.css";
import TextAreaMax from "components/Organisation/WelcomeSettings/components/TextAreaMax";
import usePromise from "hooks/utility/usePromise";
import { useOrgFeedbackQuestions } from "hooks/organisation/useOrgFeedbackQuestions";
import toast from "react-hot-toast";
import SimpleLoader from "components/Loaders/SimpleLoader";
import ChoiceItem from "./ChoiceItem";

type Props = {
  label: string;
  id: string | undefined;
  uid: string | undefined;
  choices: string[] | undefined;
  values: string;
  isDisabled?: boolean;
  editable: boolean | undefined;
  setValue: (questionName: string, value: string) => void;
  questionName: string;
};

const MultipleChoice = ({
  label,
  values,
  id,
  uid,
  choices,
  editable,
  setValue,
  questionName,
  isDisabled,
}: Props) => {
  const [choicesState, setChoicesState] = useState<{ [uid: string]: boolean }>(
    {}
  );

  const [show, setShow] = useState(false);
  const [choice, setChoice] = useState<string>("");
  const { isLoading, resolve } = usePromise();
  const { AddNewChoice } = useOrgFeedbackQuestions();

  const modalHandler = () => {
    setShow(!show);
  };

  const setChoiceHandler = (value: string) => {
    setChoice(value);
  };

  const submitHandler = async () => {
    resolve(() => AddNewChoice(id, uid, choice)).then(() => {
      toast.success("New Choice was added successfully!");
      modalHandler();
      setChoice("");
    });
  };

  // Function to handle checkbox state changes
  const handleCheckboxChange = (uid: string, checked: boolean) => {
    setChoicesState((prevState) => ({
      ...prevState,
      [uid]: checked,
    }));
  };

  // Function to get all checkbox values
  const getAllCheckboxValues = () => {
    return Object.keys(choicesState).filter((uid) => choicesState[uid]);
  };

  useEffect(() => {
    getAllCheckboxValues();

    //eslint-disable-next-line
  }, []);

  const getCheckedChoicesString = () => {
    const checkedChoices = Object.keys(choicesState).filter(
      (uid) => choicesState[uid]
    );
    return checkedChoices.join("|");
  };

  useEffect(() => {
    setValue(questionName, getCheckedChoicesString());
    // eslint-disable-next-line
  }, [choicesState]);

  return (
    <>
      <Modal show={show} size={"xl"}>
        <Modal.Body className="relative py-10">
          <>
            {!isLoading ? (
              <>
                {" "}
                <AiFillCloseCircle
                  size={30}
                  className="absolute top-2 right-2 cursor-pointer"
                  color="var(--icon-colour-0)"
                  onClick={modalHandler}
                />
                <div
                  className={`${classes["container"]} flex flex-col   w-full`}
                >
                  <p className="font-semibold text-standard">Add Choice</p>
                  <form onSubmit={submitHandler}>
                    <div className="w-full">
                      <TextAreaMax
                        value={choice}
                        valueHandler={setChoiceHandler}
                        maxLength={150}
                        row={3}
                        required={true}
                      />
                    </div>
                    <button
                      type="submit"
                      className={`${classes["button"]} w-1/4 ml-auto`}
                    >
                      Save
                    </button>
                  </form>
                </div>
              </>
            ) : (
              <SimpleLoader center={true} />
            )}
          </>
        </Modal.Body>
      </Modal>
      <div className="flex flex-col justify-center items-start">
        <p className="font-bold font-poppins text-standard">{label}</p>
        {editable && (
          <AiFillPlusCircle
            size={30}
            className="my-1 cursor-pointer mx-auto"
            color="var(--icon-colour-2)"
            onClick={modalHandler}
          />
        )}
        <div className="flex flex-col gap-4 items-start w-full my-4">
          {choices &&
            choices.length > 0 &&
            choices.map((choice, index) => (
              <ChoiceItem
                isDisabled={isDisabled}
                onChange={handleCheckboxChange}
                choice={choice}
                values={values}
                choices={choices}
                id={id}
                uid={uid}
                key={index}
                editable={editable}
              />
            ))}
        </div>
      </div>
    </>
  );
};

export default MultipleChoice;
