import React from "react";

interface Props {
  setTimerState: React.Dispatch<
    React.SetStateAction<"stop" | "start" | "reset">
  >;
}

const TimerButton: React.FC<Props> = ({ setTimerState }) => {
  const setStart = () => {
    setTimerState("start");
  };

  const setStop = () => {
    setTimerState("stop");
  };

  const setReset = () => {
    setTimerState("reset");
  };

  return (
    <div className="flex justify-between items-center w-full gap-2">
      <button
        type="button"
        onClick={setReset}
        className="bg-facil-colour px-8 py-3 rounded-full text-default shadow-md uppercase hover:bg-yellow-500 transition duration-1000"
      >
        <strong>Reset</strong>
      </button>

      <button
        type="button"
        onClick={setStart}
        className="bg-facil-colour px-8 py-3 rounded-full text-default shadow-md uppercase hover:bg-yellow-500 transition duration-1000"
      >
        <strong>Start</strong>
      </button>

      <button
        type="button"
        onClick={setStop}
        className="bg-facil-colour px-8 py-3 rounded-full text-default shadow-md uppercase hover:bg-yellow-500 transition duration-1000"
      >
        <strong>Stop</strong>
      </button>
    </div>
  );
};

export default TimerButton;
