import React from "react";
import { useDocument } from "hooks/useDocument";
import { useState, useEffect } from "react";
import { useNavigate } from "react-router-dom";

interface Props {
  children: React.ReactNode;
  growthCircleId: string | undefined;
}

/**
 * Checks if GrowthCircle Session if ongoing.
 *
 * If not ongoing, it will redirect to session-does-not-exist page
 * If ongoing, it will render the children
 *
 * @param children Elements to render if GC session is checked to be ongoing
 * @param growthCircleId Id of the GrowthCircle Session to check if ongoing
 * @returns Children Element if GC session is ongoing
 */
const GCNotOngoingWrapper: React.FC<Props> = ({ children, growthCircleId }) => {
  interface GrowthCircleSession {
    isOngoing: boolean;
  }

  interface GrowthCircleDocument {
    document: GrowthCircleSession;
    error: string | null;
  }
  const { document: growthCircleSession }: GrowthCircleDocument = useDocument(
    "GrowthCircles",
    growthCircleId
  );

  const navigate = useNavigate();
  const [checked, setChecked] = useState(false);
  const [sessionExist, setSessionExist] = useState(false);

  useEffect(() => {
    if (!growthCircleSession || checked) return;
    if (growthCircleSession.isOngoing) setSessionExist(true);
    setChecked(true);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [growthCircleSession]);

  useEffect(() => {
    if (!checked) return;
    if (sessionExist && checked) return;
    console.log(growthCircleSession);
    navigate("/session-does-not-exist");
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [sessionExist, checked]);

  if (!checked) return null;

  if (!sessionExist) {
    return null;
  } else {
    return <div>{children}</div>;
  }
};

export default GCNotOngoingWrapper;
