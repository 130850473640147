import { useContext } from "react";
import InnerContainer from "../Container/InnerContainer";
import ViewJournalContext from "../ViewJournalContext";
import classes from "./ReflectionQuestions.module.css";
import { REFLECTION_BOX_TITLE, NOTES_BOX_TITLE } from "strings/SharingPanel";
import useParticipants from "hooks/useParticipants";

const ViewReflection = () => {
  const tabNumber = "first";
  const place = "bottom";
  const { userJournal } = useContext(ViewJournalContext);
  const { decodeString } = useParticipants();

  return (
    <InnerContainer tab={tabNumber} place={place}>
      {userJournal.reflection && (
        <>
          <strong className={classes["title"]}>{REFLECTION_BOX_TITLE}</strong>
          <span className={classes["reflection-text-area"]}>
            {decodeString(userJournal.reflection)}
          </span>
        </>
      )}
      {userJournal.notes && (
        <>
          <strong className={classes["title"]}>{NOTES_BOX_TITLE}</strong>
          <span className={classes["reflection-text-area"]}>
            {decodeString(userJournal.notes)}
          </span>
        </>
      )}
    </InnerContainer>
  );
};

export default ViewReflection;
