import SmileyRating from "components/utility/smileyRating/SmileyRating";
import classes from "./Reflection.module.css";
import { DragRatings } from "hooks/useDragRating";
import { SmileyRatings } from "interface/FeedbackFormInterface";

type ReflectionRatingSmileyProps = {
  allValues: SmileyRatings;
  setAllValues: React.Dispatch<React.SetStateAction<SmileyRatings>>;
  prompt: string;
  allDragValues: DragRatings;
  showError: boolean;
};

/**
 * Page that displays a section of questions that the users can rate from 1 to 5
 */
export default function ReflectionRatingSmiley({
  allValues,
  setAllValues,
  prompt,
  allDragValues,
  showError,
}: ReflectionRatingSmileyProps) {
  const defaultPrompt =
    "To what extent has today’s GrowthCircle supported you?";

  const changeHandler = (e) => {
    // setAllValues({...allValues, [e.target.getAttribute("name")] : e.target.getAttribute("set")})
    const prev = { ...allValues };
    prev[e.target.getAttribute("data-name")].answer = parseInt(
      e.target.getAttribute("data-set")
    );
    setAllValues(prev);
  };

  return (
    <>
      <p className={classes.info}>{prompt ? prompt : defaultPrompt}</p>
      <div
        className={`${classes["reflection-sub-container"]} ${classes["white"]}`}
      >
        {/* <p className={classes.info}></p> */}
        {/* <div className={classes.line} /> */}

        {/* <SmileyRating question={allValues.question1} activeValue={allValues.answer1} name={'answer1'} onSelect={changeHandler} />
        <SmileyRating question={allValues.question2} activeValue={allValues.answer2} name={'answer2'} onSelect={changeHandler}/>
        <SmileyRating question={allValues.question3} activeValue={allValues.answer3} name={'answer3'} onSelect={changeHandler}/>
        <SmileyRating question={allValues.question4} activeValue={allValues.answer4} name={'answer4'} onSelect={changeHandler}/>
        <SmileyRating question={allValues.question5} activeValue={allValues.answer5} name={'answer5'} onSelect={changeHandler}/> */}
        {Object.keys(allValues).map((smiley, index) => {
          return (
            <SmileyRating
              key={smiley}
              question={allValues[smiley].question}
              activeValue={allValues[smiley].answer}
              name={smiley}
              onSelect={changeHandler}
              showError={showError}
            />
          );
        })}
      </div>
    </>
  );
}
