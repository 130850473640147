import { getProperNameComponent } from "pages/AllCircles/GenericSessionPage/ComponentMapping";
import { FaBan } from "react-icons/fa";

interface DisabledSectionProps {
  requiredPage?: string;
}

const DisabledSection = ({ requiredPage }: DisabledSectionProps) => {
  return (
    <div
      className="flex flex-col justify-center items-center h-96 w-full"
      style={{ color: "var(--text-colour)" }}
    >
      <p className="text-7xl my-4 flex items-center gap-4">
        <FaBan size={80} /> Disabled
      </p>
      <p>
        This component or page is currently disabled because it has not been
        added to the list of pages.
      </p>
      {requiredPage && (
        <p>
          To enable this page, kindly include{" "}
          <strong>"{`${getProperNameComponent(requiredPage)}`}"</strong> in the
          pages tab.
        </p>
      )}
    </div>
  );
};

export default DisabledSection;
