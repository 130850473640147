import FiveStepHeader from "components/FiveStepHeader/FiveStepHeader";
import NGFooter from "components/Footers/NGFooter";
import Header from "components/Header/Header";
import BackAndNext from "components/utility/BackAndNext";
import Center from "components/utility/Center/Center";
import ContentWrapper from "components/utility/ContentWrapper";
import EmptySpace from "components/utility/EmptySpace";
import Steps from "components/utility/Steps";
import { Modal } from "flowbite-react";
import { useGCSession } from "hooks/useGCSession";
import { useGrowthCircleContext } from "hooks/useGrowthCircleContext";
import useParticipants from "hooks/useParticipants";
import { checkCanAdvanceNextActivity } from "models/organisationRole";
import NoGCSessionWrapper from "pages/NoGCSession/NoGCSessionWrapper";
import React, { useState } from "react";
import { ReactComponent as CloseIcon } from "assets/Xbutton.svg";
import { useNavigate } from "react-router-dom";
import {
  CHOOSE_TOPIC_ROUTE,
  NG_ACTIVITY_ROUTE,
  VIEW_CHOOSE_ROLES_ROUTE,
} from "strings/Routes";
import SUSSActivityCards from "components/SUSS/ActivityCards";
import NGActivityCards from "components/NG/ActivityCards";
import Instruction from "components/utility/Instruction";

const INSTRUCTIONS_CHOOSE_ACTIVITY =
  "Select the activity for today's Growth Circle Session";

function checkIsMostVotedActivity(
  activityName: string,
  activities: { [key: string]: number }
) {
  const highestCount = Object.values(activities).reduce(
    (prev, curr) => (prev > curr ? prev : curr),
    0
  );
  return activities[activityName] === highestCount;
}

function checkHasTie(activities: [string, number][]) {
  const firstCount = activities[0] ? activities[0][1] : 0;
  const secondCount = activities[1] ? activities[1][1] : 0;
  return firstCount === secondCount;
}

interface Props {
  type: "SUSS" | "NG";
}

/**
 * Page to navigate to next.
 * @param type Type of choose activity page.
 * @returns ChooseActivityPage component.
 */
export default function ChooseActivityPage({ type }: Props) {
  const currentStep = 4;
  const stepsCount = 5;
  const [hasTrigger, setHasTrigger] = useState(false);
  const { facilAllowNextPage, updateSelectedActivity } = useGCSession();
  const { ParticipantRecord, ParticipantRecords, sessionRole } =
    useParticipants();
  const { growthCircleSession } = useGrowthCircleContext();
  const [isVisible, setIsVisible] = useState(false);
  const [refreshing, setRefreshing] = useState(false);
  const canAdvance = checkCanAdvanceNextActivity(sessionRole);
  const navigate = useNavigate();

  const nextPage = type === "SUSS" ? CHOOSE_TOPIC_ROUTE : NG_ACTIVITY_ROUTE;

  const ActivityCards = type === "SUSS" ? SUSSActivityCards : NGActivityCards;

  const _setTrigger = () => {
    setHasTrigger(!hasTrigger);
  };

  // count all activity inside ParticipantRecords Array and get the highest count
  const activityCount = ParticipantRecords.reduce<{ [key: string]: number }>(
    (acc, cur) => {
      if (cur.activity) {
        acc[cur.activity] = (acc[cur.activity] || 0) + 1;
      }
      return acc;
    },
    {}
  );

  const sortedActivities = Object.entries(activityCount).sort(
    ([, countA], [, countB]) => (countB as number) - (countA as number)
  );

  const highestCountActivity = sortedActivities[0]
    ? sortedActivities[0]
    : ["", 0];
  const participant = ParticipantRecord[0];
  const selectedActivity = participant?.activity;

  const backHandler = () => {
    navigate(VIEW_CHOOSE_ROLES_ROUTE);
  };

  const modalHandler = async () => {
    setIsVisible(!isVisible);
    if (!isVisible) {
      document.body.style.overflow = "unset";
    } else {
      document.body.style.overflow = "hidden";
    }
  };

  /**
   * If the user is a facilitator, and the highest voted activity is not the facilitator's activity, then show a
   * confirmation modal. Otherwise, allow the user to navigate to the next page
   */
  const nextHandler = () => {
    if (canAdvance) {
      if (
        selectedActivity &&
        checkIsMostVotedActivity(selectedActivity, activityCount) &&
        !checkHasTie(sortedActivities)
      ) {
        proceedNextActivity();
      } else {
        setIsVisible(true);
        document.body.style.overflow = "unset";
      }
    } else {
      navigate(nextPage);
    }
  };

  const proceedNextActivity = async () => {
    if (canAdvance && selectedActivity) {
      setRefreshing(true);
      await updateSelectedActivity(
        selectedActivity,
        activityCount[selectedActivity]
      );
      setRefreshing(false);
    }
    facilAllowNextPage("choose-activity");
    navigate(nextPage);
  };

  return (
    <NoGCSessionWrapper>
      <React.Fragment>
        <Modal show={isVisible} size="md" className="min-h-screen" popup={true}>
          <Modal.Body className="p-5 relative mt-[25%] md:mt-[5%]">
            <div className="absolute right-0 top-0 p-2">
              <CloseIcon className="cursor-pointer" onClick={modalHandler} />
            </div>
            <div className="text-center py-5 px-2">
              <h3 className="mb-5 py-4 text-lg font-normal text-gray-500 dark:text-gray-400">
                {checkHasTie(sortedActivities) ? (
                  <small className="block text-sm mt-5">
                    Since no activity got the most votes, should we keep going
                    with{" "}
                    <span className="font-semibold">{selectedActivity}</span> ?
                  </small>
                ) : (
                  <>
                    {`${highestCountActivity[1]} / ${ParticipantRecords.length}
                      of your group’s participants picked `}
                    <span className="font-semibold">
                      {highestCountActivity[0]}
                    </span>
                    .
                    <small className="block text-sm mt-5">
                      Are you sure you wish to pick{" "}
                      <span className="font-semibold">{selectedActivity}</span>{" "}
                      instead of{" "}
                      <span className="font-semibold">
                        {highestCountActivity[0]}
                      </span>
                    </small>
                  </>
                )}
              </h3>

              <div className="flex justify-center gap-4">
                <button
                  type="button"
                  disabled={refreshing}
                  className="shadow-md bg-facil-colour px-12 rounded-3xl text-white py-3 hover:bg-green-700 transition duration-1000"
                  onClick={proceedNextActivity}
                >
                  <span>{refreshing === false ? "Yes " : "Processing..."}</span>
                </button>
                <button
                  type="button"
                  disabled={refreshing}
                  className="shadow-md bg-facil-colour px-12 rounded-3xl py-3 text-white hover:bg-red-700 transition duration-1000"
                  onClick={modalHandler}
                >
                  No
                </button>
              </div>
            </div>
          </Modal.Body>
        </Modal>
      </React.Fragment>
      <div className="relative" style={{ minHeight: "100dvh" }}>
        <Header hasModal={isVisible || hasTrigger} />
        <FiveStepHeader
          hasModal={isVisible || hasTrigger}
          hasLightning={true}
          hasGuide={false}
          currentStep={currentStep}
          setIsTriggerVisible={_setTrigger}
          text="Choose & Share"
        />
        <ContentWrapper hasFiveStepper={true}>
          <Center minHeight="100vh">
            <EmptySpace height={"5px"} />
            <Steps count={stepsCount} currentStep={currentStep} />
            <div className="py-6 text-center flex flex-col items-center">
              <h3 className="font-bold text-xl w-72">
                <span className="block">Which activity would you</span>
                <span className="block">like to do?</span>
              </h3>
              <Instruction text={INSTRUCTIONS_CHOOSE_ACTIVITY} />
            </div>
            <div className="grid grid-cols-2 gap-3 w-80 h-96">
              <ActivityCards />
            </div>
            <EmptySpace height={"40px"} />
            <BackAndNext
              nextAllowed={
                (canAdvance && !!selectedActivity) ||
                (growthCircleSession?.allowChooseActivity && !!selectedActivity)
              }
              nextCallback={nextHandler}
              backAllowed={true}
              backCallback={backHandler}
              backHidden={undefined}
            />

            <EmptySpace height={"40px"} />
            <NGFooter type={growthCircleSession?.type} />
          </Center>
        </ContentWrapper>
      </div>
    </NoGCSessionWrapper>
  );
}
