import { createContext, useReducer, useEffect } from "react";
import { projectAuth, projectFirestore } from "../firebase/config";
import React from "react";
import { Profile } from "interface/ProfileInterface";
import { User, onAuthStateChanged } from "firebase/auth";

export const AuthContext = createContext<AuthContextStructure | null>(null);

interface AuthContextStructure {
  user: User | null;
  authIsReady: boolean;
  profile: Profile | null;
  dispatch: React.Dispatch<actionStructure>;
}

interface stateStructure {
  user: User | null;
  authIsReady: boolean;
  profile: Profile | null;
}

interface actionStructure {
  type: "LOGIN" | "LOGOUT" | "FORCE_LOGOUT" | "AUTH_IS_READY" | "UPDATE";
  payload: User | null;
  profile: Profile | null;
}

export const authReducer = (state: stateStructure, action: actionStructure) => {
  switch (action.type) {
    case "LOGIN":
      return { ...state, user: action.payload, profile: action.profile };
    case "LOGOUT":
      return { ...state, user: null, profile: null };
    case "FORCE_LOGOUT":
      return { authIsReady: true, user: null, profile: null };
    case "UPDATE":
      return {
        authIsReady: true,
        user: action.payload,
        profile: action.profile,
      };
    case "AUTH_IS_READY":
      return {
        user: action.payload,
        authIsReady: true,
        profile: action.profile,
      };
    default:
      return state;
  }
};

interface Props {
  children: React.ReactNode;
}

export const AuthContextProvider: React.FC<Props> = ({ children }) => {
  const [state, dispatch] = useReducer(authReducer, {
    user: null,
    authIsReady: false,
    profile: null,
  });

  useEffect(() => {
    const unsub = onAuthStateChanged(projectAuth, (user) => {
      if (user) {
        //get the profile
        const ref = projectFirestore.collection("users").doc(user.uid);

        ref.onSnapshot(
          (snapshot) => {
            if (snapshot.data()) {
              dispatch({
                type: "AUTH_IS_READY",
                payload: user,
                profile: { ...snapshot.data(), id: snapshot.id } as Profile,
              });
            } else {
              dispatch({
                type: "AUTH_IS_READY",
                payload: null,
                profile: null,
              });
            }
          },
          (err) => {
            console.log(err.message);
          }
        );
      } else {
        dispatch({ type: "AUTH_IS_READY", payload: user, profile: null });
      }

      unsub();
    });
  }, []);

  /* Logging the state of the AuthContext. */
  // console.log(state);

  return (
    <AuthContext.Provider value={{ ...state, dispatch: dispatch }}>
      {children}
    </AuthContext.Provider>
  );
};
