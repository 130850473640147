const currentDate = new Date();

// Get the current month as a number (0-indexed, where 0 is January and 11 is December)
const currentMonthNumber = currentDate.getMonth();

// Create an array of month names
const monthNames = [
  "Jan",
  "Feb",
  "Mar",
  "Apr",
  "May",
  "Jun",
  "Jul",
  "Aug",
  "Sep",
  "Oct",
  "Nov",
  "Dec",
];

export const currentMonthName = monthNames[currentMonthNumber];

const currentYear = currentDate.getFullYear();

export const currentYearFormat = currentYear.toString();
