import FiveStepHeader from "components/FiveStepHeader/FiveStepHeader";
import Header from "components/Header/Header";
import Timer from "components/SharingPanel/Timer/Timer";
import BackAndNext from "components/utility/BackAndNext";
import Center from "components/utility/Center/Center";
import ContentWrapper from "components/utility/ContentWrapper";
import EmptySpace from "components/utility/EmptySpace";
import Steps from "components/utility/Steps";
import { Modal } from "flowbite-react";
import { useAuthContext } from "hooks/useAuthContext";
import { useGCSession } from "hooks/useGCSession";
import { useGrowthCircleContext } from "hooks/useGrowthCircleContext";
import NoGCSessionWrapper from "pages/NoGCSession/NoGCSessionWrapper";
import React, { useEffect, useState, lazy, Suspense } from "react";
import { useNavigate } from "react-router-dom";
import { CHECK_IN_AFTER_ROUTE, NG_ACTIVITY_ROUTE } from "strings/Routes";
import { ReactComponent as CloseIcon } from "../../../assets/Xbutton.svg";
import NGFooter from "components/Footers/NGFooter";
import useParticipants from "hooks/useParticipants";
import { checkCanAdvanceNextActivity } from "models/organisationRole";

const NGReflectShareSkills = lazy(
  () => import("components/NG/NGReflectShareSkills")
);

const ActivityShareReflection = () => {
  const currentStep = 4;
  const stepsCount = 5;

  const [hasLightBox, setHasLightBox] = useState(false);
  const [isVisible, setIsVisible] = useState(false);
  const [hasTrigger, setHasTrigger] = useState(false);
  const _setTrigger = () => {
    setHasTrigger(!hasTrigger);
  };

  const setLightBoxHandler = () => {
    setHasLightBox(!hasLightBox);
  };

  const { profile } = useAuthContext();
  const { growthCircleSession } = useGrowthCircleContext();
  const { sessionRole } = useParticipants();
  const canAdvance = checkCanAdvanceNextActivity(sessionRole);
  const { facilAllowNextPage } = useGCSession();

  const navigate = useNavigate();

  useEffect(() => {
    if (!profile) return;
    if (!growthCircleSession) return;

    if (isVisible) {
      document.body.style.overflow = "hidden";
    } else {
      document.body.style.overflow = "unset";
    }
  }, [growthCircleSession, hasLightBox, profile, isVisible]);

  const backHandler = () => {
    navigate(NG_ACTIVITY_ROUTE);
  };

  const nextHandler = () => {
    setIsVisible(true);
    window.document.body.style.overflow = "hidden !important";
  };

  const confirmNext = () => {
    setIsVisible(false);
    window.document.body.style.overflow = "unset !important";

    if (canAdvance) {
      facilAllowNextPage("end-rating");
      navigate(CHECK_IN_AFTER_ROUTE);
    } else {
      navigate(CHECK_IN_AFTER_ROUTE);
    }
  };

  const modalHandler = () => {
    setIsVisible(!isVisible);
  };

  return (
    <NoGCSessionWrapper>
      <React.Fragment>
        <Modal show={isVisible} size="md" className="min-h-screen" popup={true}>
          <Modal.Body className="p-5 relative mt-[25%] md:mt-[5%]">
            <div className="absolute right-0 top-0 p-2">
              <CloseIcon className="cursor-pointer" onClick={modalHandler} />
            </div>
            <div className="text-center py-5 px-2">
              <h3 className="font-semibold mt-5 text-lg text-slate-700">
                Before Proceeding
              </h3>
              <EmptySpace height={"20px"} />
              <div className="text-slate-700 text-center">
                {profile?.facilGC && (
                  <p className="text-sm">
                    Have all the participants finished sharing?
                  </p>
                )}
                {!profile?.facilGC && (
                  <p className="text-sm">Are you finished sharing?</p>
                )}
                <EmptySpace height={"20px"} />
                <div className="flex justify-center gap-4 mt-5">
                  <button
                    type="button"
                    style={{ backgroundColor: "var(--main-colour)" }}
                    className={` shadow-md  px-12 rounded-3xl text-white py-3 hover:bg-green-700 transition duration-1000`}
                    onClick={confirmNext}
                  >
                    <span>{"Yes"}</span>
                  </button>
                  <button
                    type="button"
                    style={{ backgroundColor: "var(--main-colour)" }}
                    className={` shadow-md px-12 rounded-3xl text-white py-3 hover:bg-green-700 transition duration-1000`}
                    onClick={modalHandler}
                  >
                    No
                  </button>
                </div>
              </div>
            </div>
          </Modal.Body>
        </Modal>
      </React.Fragment>
      <div className="relative">
        <Header hasModal={isVisible || hasLightBox || hasTrigger} />
        <FiveStepHeader
          hasModal={isVisible || hasLightBox || hasTrigger}
          hasLightning={true}
          hasGuide={false}
          currentStep={currentStep}
          setIsTriggerVisible={_setTrigger}
          text="Choose & Share"
        />
        <ContentWrapper hasFiveStepper={true}>
          <Center>
            <EmptySpace height={"5px"} />
            <Steps count={stepsCount} currentStep={currentStep} />
            <EmptySpace height={"30px"} />
            <Suspense fallback={<div>Loading...</div>}>
              <NGReflectShareSkills hasLightBox={setLightBoxHandler} />
            </Suspense>
            <EmptySpace height={"40px"} />
            <BackAndNext
              backAllowed={true}
              backCallback={backHandler}
              nextAllowed={
                canAdvance || growthCircleSession?.allowEndRating || false
              }
              nextCallback={nextHandler}
              backHidden={undefined}
            />
            <EmptySpace height={"40px"} />
            {profile?.isFacil && (
              <>
                <Timer />
                <EmptySpace height={"50px"} />
              </>
            )}{" "}
            <EmptySpace height={"20px"} />
            <NGFooter type={growthCircleSession?.type} />
          </Center>
        </ContentWrapper>
      </div>
    </NoGCSessionWrapper>
  );
};

export default ActivityShareReflection;
