import Modal from "components/utility/Modal";
import ProfilePicture from "./ProfilePicture";
import Button from "components/utility/Button";
import { FormEvent, useState } from "react";
import useUsers from "hooks/useUsers";
import { Profile } from "interface/ProfileInterface";
import EmptySpace from "components/utility/EmptySpace";
import ConfirmationModal from "components/utility/Modals/ConfirmationModal";
import useParticipants from "hooks/useParticipants";
import { useAuthContext } from "hooks/useAuthContext";
import { checkHasPermission } from "models/organisationRole";
import { P_REMOVE_PROFILE_PICTURE } from "models/permission";

interface Props {
  profile?: Profile | null,
  isModalOpen: boolean;
  setIsModalOpen: React.Dispatch<React.SetStateAction<boolean>>;
  onReport?: () => void;
}

/**
 * Modal to report a user's profile photo.
 *
 * @param profile Profile of the user to be reported.
 * @param isModalOpen Whether the modal is open.
 * @param setIsModalOpen Setter function for the state of the modal.
 * @param onReport Method to run after reporting the photo.
 * @returns ReportProfilePictureModal component.
 */
export default function ReportProfilePictureModal(
  { profile, isModalOpen, setIsModalOpen, onReport=(()=>{}) }: Props
) {
  const { user } = useAuthContext();
  const { updateUserProfile } = useUsers();
  const { sessionRole } = useParticipants();
  const [isConfirmModalOpen, setIsConfirmModalOpen] = useState(false);

  function ignoreClick() {
    return;
  }

  function handleClick(e: FormEvent<HTMLButtonElement>) {
    setIsConfirmModalOpen(true);
  }

  function handleConfirm() {
    return new Promise<void>(resolve => {
      if (!profile?.uid) {
        resolve();
        return;
      }
      resolve(updateUserProfile(profile.uid, { photoURL: ''}));
    }).then(() => {
      onReport();
      setIsModalOpen(false);
      setIsConfirmModalOpen(false);
    });
  }

  return (
    <Modal
      isModalOpen={isModalOpen}
      setIsModalOpen={setIsModalOpen}
    >
      <div className="flex flex-col items-center">
        <ConfirmationModal
          isModalOpen={isConfirmModalOpen}
          setIsModalOpen={setIsConfirmModalOpen}
          confirmHandler={handleConfirm}
        >
          <div>
            Are you sure you want to remove this user's profile photo?
            <EmptySpace />
          </div>
        </ConfirmationModal>
        <ProfilePicture
          profile={profile}
          onClick={ignoreClick}
        />
        <EmptySpace />
        {profile?.displayName}
        <EmptySpace />
        {
          profile?.uid !== user?.uid
          && checkHasPermission(sessionRole, P_REMOVE_PROFILE_PICTURE)
          && <Button
            text="Remove photo"
            onClick={handleClick}
            className="text-main-colour border-main-colour hover:bg-main-colour"
          />
        }
      </div>
    </Modal>
  );
}