import FeedbackForm from "components/FeedBackForm/FeedbackForm";
import FeedbackFormTitle from "components/FeedBackForm/FeedbackFormTitle";
import FiveStepHeader from "components/FiveStepHeader/FiveStepHeader";
import NGFooter from "components/Footers/NGFooter";
import Header from "components/Header/Header";
import BackAndNext from "components/utility/BackAndNext";
import Center from "components/utility/Center/Center";
import ContentWrapper from "components/utility/ContentWrapper";
import EmptySpace from "components/utility/EmptySpace";
import InputField from "components/utility/Forms/InputField";
import Steps from "components/utility/Steps";
import { useGrowthCircleContext } from "hooks/useGrowthCircleContext";
import useParticipantFeedback from "hooks/useParticipantFeedback";
import useParticipants from "hooks/useParticipants";
import useUser from "hooks/useUser";
import NoGCSessionWrapper from "pages/NoGCSession/NoGCSessionWrapper";
import { useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
import { COMPLETED_ROUTE, FEEDBACK_ROUTE_FACIL } from "strings/Routes";
import { formatInputError } from "utility/inputValidation";
import QuestionGroup from "components/FeedBackForm/QuestionGroup";
import TextQuestion from "components/FeedBackForm/TextQuestion";
import { getSelfFeedbackQuestions } from "models/componentSettings/feedback/selfFeedback";
import useNavigateFeedback from "hooks/feedback/useNavigateFeedback";
import { Date } from "interface/ProfileInterface";

const CheckOutFeedbackSelf = () => {
  const currentStep = 5;
  const stepsCount = 5;
  const { profile, setProfile } = useUser();
  const [isVisible, setIsVisible] = useState(false);
  const navigate = useNavigate();
  const handleNavigateFeedback = useNavigateFeedback();
  const { ParticipantRecord, sessionRole } = useParticipants();
  const { growthCircleSession } = useGrowthCircleContext();
  const [errorMessage, setErrorMessage] = useState("");

  const selfFeedbackQuestions = getSelfFeedbackQuestions(
    growthCircleSession?.organisation ?? ""
  );

  const [profession, setProfession] = useParticipantFeedback(
    profile?.profession ?? "",
    "feedbackForSelf",
    "profession"
  );

  const [birthday, setBirthday] = useParticipantFeedback(
    (profile?.birthday as Date) ?? "",
    "feedbackForSelf",
    "birthday"
  ) as [Date, (birthday: Date) => void];

  useEffect(() => {
    if (!profile) {
      return;
    }
    setProfession(profile.profession ?? "");
    setBirthday(profile.birthday);
    if (!ParticipantRecord) return;
    if (ParticipantRecord[0]?.feedbackReflectionStatus === "submitted") {
      navigate(COMPLETED_ROUTE);
    }
    setIsVisible(false);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [isVisible, profile, ParticipantRecord]);
  const [hasTrigger, setHasTrigger] = useState(false);

  const _setTrigger = () => {
    setHasTrigger(!hasTrigger);
  };

  const nextHandler = async () => {
    if (profile) {
      setErrorMessage("");
      if (growthCircleSession?.organisation !== "NG") {
        navigate(FEEDBACK_ROUTE_FACIL);
      } else {
        setProfile({
          ...profile,
          profession,
          birthday,
        })
          .then(() => {
            navigate(FEEDBACK_ROUTE_FACIL);
          })
          .catch((err) => {
            setErrorMessage(formatInputError(err.message));
          });
      }
    }
  };

  return (
    <NoGCSessionWrapper>
      <Header hasModal={isVisible || hasTrigger} />
      <FiveStepHeader
        hasModal={isVisible || hasTrigger}
        hasLightning={true}
        hasGuide={false}
        currentStep={currentStep}
        text="Reflection on Circle"
        setIsTriggerVisible={_setTrigger}
      />
      <ContentWrapper hasFiveStepper={true}>
        <Center>
          <EmptySpace height={"5px"} />
          <Steps count={stepsCount} currentStep={currentStep} />
          <EmptySpace height={"15px"} />
          <FeedbackForm activeTab={1} handleSwitchTabs={handleNavigateFeedback}>
            <FeedbackFormTitle>{selfFeedbackQuestions.title}</FeedbackFormTitle>
            {selfFeedbackQuestions.subCategories.map((subCategory, index) => {
              const questionPrefixes = subCategory.questionPrefix ?? {
                default: "",
              };
              const questionPrefix =
                questionPrefixes[sessionRole?.name ?? ""] ??
                questionPrefixes.default;
              return (
                <QuestionGroup
                  key={index}
                  label={questionPrefix}
                  questionCategory={selfFeedbackQuestions.category}
                  questions={subCategory.questions}
                />
              );
            })}
            {growthCircleSession?.organisation === "NG" && (
              <QuestionGroup>
                <TextQuestion
                  label="My Profession"
                  placeholder="specify profession"
                  value={profession}
                  onChange={(event) => setProfession(event.target.value)}
                />
                <p className="text-slate-600 font-[700] px-4 text-left select-none py-4">
                  My Birthday
                </p>
                <InputField
                  type="date"
                  htmlFor="birthday"
                  onChange={(event) => setBirthday(event.target.value)}
                  value={birthday}
                  className={`w-full border-2 rounded-sm bg-default shadow-md`}
                />
                <EmptySpace />
              </QuestionGroup>
            )}
          </FeedbackForm>
          <div className="text-[#eb8181] p-4">{errorMessage}</div>
          <BackAndNext
            nextAllowed={
              growthCircleSession?.organisation !== "NG" ||
              !!(birthday && profession)
            }
            nextCallback={nextHandler}
            backAllowed={false}
            backHidden={undefined}
          />
          <NGFooter type={growthCircleSession?.type} />
        </Center>
      </ContentWrapper>
    </NoGCSessionWrapper>
  );
};

export default CheckOutFeedbackSelf;
