import classes from "./ChatUsers.module.css";
import ChatUser from "./ChatUser";
const ChatUsers = () => {
  interface ChatUsers {
    name: string;
    photoUrl: string;
    status: boolean;
  }

  const users: ChatUsers[] = [
    {
      name: "Joe",
      photoUrl:
        "https://avatars.dicebear.com/api/miniavs/joe.svg?background=%23f9ded2",
      status: true,
    },
    {
      name: "Jane",
      photoUrl:
        "https://avatars.dicebear.com/api/personas/Jane.svg?background=%23f9ded2",
      status: false,
    },
    {
      name: "Sam",
      photoUrl:
        "https://avatars.dicebear.com/api/personas/sam.svg?background=%23f9ded2",
      status: false,
    },
    {
      name: "Chris",
      photoUrl:
        "https://avatars.dicebear.com/api/personas/cris.svg?background=%23f9ded2",
      status: false,
    },
    {
      name: "Edward",
      photoUrl:
        "https://avatars.dicebear.com/api/personas/edward.svg?background=%23f9ded2",
      status: false,
    },
    {
      name: "Beth",
      photoUrl:
        "https://avatars.dicebear.com/api/personas/your-custom-seed.svg?background=%23f9ded2",
      status: false,
    },
    {
      name: "Steve",
      photoUrl:
        "https://avatars.dicebear.com/api/personas/steve.svg?background=%23f9ded2",
      status: false,
    },
  ];

  return (
    <div className={classes["chat-users-container"]}>
      {users &&
        users.map((user) => {
          return <ChatUser user={user} />;
        })}
    </div>
  );
};

export default ChatUsers;
