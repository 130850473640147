import usePermission, { PermissionHook } from "hooks/permissions/usePermission";
import { createContext, ReactNode } from "react";

export const PermissionContext = createContext<PermissionHook | null>(null);

interface Props {
  children: ReactNode;
}

export function PermissionContextProvider({ children }: Props) {
  const permissionHook = usePermission();

  return (
    <PermissionContext.Provider value={permissionHook}>
      {children}
    </PermissionContext.Provider>
  );
}
