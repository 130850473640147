import { ChangeEventHandler, FC, Key } from "react";
import classes from "./InputField.module.css";

interface PreValueProps {
  label: string;
  value: string;
}

interface Props {
  id?: string;
  onChange?: ChangeEventHandler<HTMLSelectElement>;
  defaultValue?: string | number | readonly string[];
  value?: string | number | readonly string[];
  options?: Key[];
  containerClassName?: string;
  inputClassName?: string;
  label?: string;
  required?: boolean;
  placeholder?: string;
  preValues?: PreValueProps;
}

/**
 * Select field with list of options.
 *
 * @param id Identifier for the select field.
 * @param onChange Change handler whenever an option is selected.
 * @param defaultValue Default value selected.
 * @param value Current value selected.
 * @param options List of option values.
 * @param containerClassName ClassName for select field.
 * @param inputClassName ClassName for option field.
 * @returns SelectField component.
 */
const SelectField: FC<Props> = ({
  id,
  onChange,
  defaultValue,
  value,
  options,
  containerClassName,
  inputClassName,
  required,
  placeholder,
}) => {
  return (
    <div
      className={
        containerClassName
          ? containerClassName
          : `${classes["input-placeholder"]}`
      }
    >
      <select
        id={id}
        onChange={onChange}
        placeholder={placeholder}
        defaultValue={defaultValue}
        value={value}
        className={inputClassName ? inputClassName : classes.input}
        required={required}
      >
        {placeholder && <option value={""}>{`${placeholder}`}</option>}
        {options?.map((option, index) => (
          <option key={index} value={option}>
            {option}
          </option>
        ))}
      </select>
    </div>
  );
};

export default SelectField;
