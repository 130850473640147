import FirstNameAvatar from "components/utility/FirstNameAvatar";
import { useAuthContext } from "hooks/useAuthContext";
import useGetParticipants from "hooks/useGetParticipants";
import { FeedbackForm } from "interface/FeedbackFormInterface";
import { useEffect } from "react";
import classes from "./FeedbackWaitingArea.module.css";

interface BeforeWaitingAreaProps {
  submittedFeedbackForms: FeedbackForm[] | undefined;
  nextHandler: () => void;
}

/**
 * Waiting Area component after the user has submitted the form
 * @param {FeedbackForm[]} submittedFeedbackForms - all feedbackforms submitted
 * @param {() => void} nextHandler - navigates to the next section of EndOfSession
 */
const BeforeWaitingArea = ({
  submittedFeedbackForms,
  nextHandler,
}: BeforeWaitingAreaProps) => {
  const { getParticipant, getParticipantColourIndex } = useGetParticipants();
  const { profile } = useAuthContext();

  useEffect(() => {
    console.info("Reached before waiting area");
  }, []);

  return (
    <>
      <h2 className={classes["waiting-text"]}>
        Waiting for others
        <span className={`${classes["waiting-text"]} ${classes["d1"]}`}>.</span>
        <span className={`${classes["waiting-text"]} ${classes["d2"]}`}>.</span>
        <span className={`${classes["waiting-text"]} ${classes["d3"]}`}>.</span>
      </h2>
      {/* participants */}

      <div className={classes["participants"]}>
        {submittedFeedbackForms?.map((feedbackForm, index) => {
          //TODO get back to displaying Triggers
          return (
            <div className={classes["avatar-container"]} key={index}>
              {profile?.isFacil && (
                <FirstNameAvatar
                  name={
                    feedbackForm.userId === profile!.id
                      ? "ME"
                      : feedbackForm.userName
                  }
                  participant={getParticipant(feedbackForm)}
                  participantNumber={getParticipantColourIndex(
                    feedbackForm.userId
                  )}
                />
              )}
              {!profile?.isFacil && (
                <FirstNameAvatar
                  name={
                    feedbackForm.userId === profile!.id
                      ? "ME"
                      : feedbackForm.userName
                  }
                  participant={getParticipant(feedbackForm)}
                  participantNumber={getParticipantColourIndex(
                    feedbackForm.userId
                  )}
                />
              )}
            </div>
          );
        })}
      </div>
    </>
  );
};

export default BeforeWaitingArea;
