import EmptySpace from "components/utility/EmptySpace";
import { Dropdown, Modal } from "flowbite-react";
import { DropdownItem } from "flowbite-react/lib/esm/components/Dropdown/DropdownItem";
import useOrganisationContext from "hooks/organisation/useOrganisationContext";
import usePromise from "hooks/utility/usePromise";
import {
  componentMapping,
  getProperNameComponent,
} from "pages/AllCircles/GenericSessionPage/ComponentMapping";
import { useEffect, useState } from "react";
import { toast } from "react-hot-toast";

import ComponentDetails from "./ComponentDetails";
import { FaSort } from "react-icons/fa";

import Organisation from "interface/OrganisationInterface";
import { UpdateData } from "firebase/firestore";
import classes from "pages/AllCircles/Admin/Admin.module.css";
import ComponentOrderCard from "./ComponentOrderCard";
import { setDefaultComponents } from "models/organisation";
import { FiRefreshCw } from "react-icons/fi";
import { timestamp } from "../../../firebase/config";
import { AiFillCloseCircle, AiFillInfoCircle } from "react-icons/ai";
import TemplateOverview from "../GrowthCirclesTypeSettings/TemplateOverview";
import RefreshOverview from "../GrowthCirclesTypeSettings/RefreshOverview";
import { useTour } from "@reactour/tour";

export default function ComponentSettings() {
  const { setIsOpen: setIsTourOpen } = useTour();
  const { selectedOrganisation, updateSelectedOrganisation } =
    useOrganisationContext();
  const { isLoading, resolve } = usePromise();
  const [isRefreshing, setIsRefreshing] = useState<boolean>(false);
  const [isSorting, setIsSorting] = useState<boolean>(false);
  const [isVisible, setIsVisible] = useState<boolean>(false);
  const [show, setShow] = useState<boolean>(false);
  const [saveAs, setSaveAs] = useState<boolean>(false);
  const [isMoving, setIsMoving] = useState(null); // Track the

  const isSortHandler = () => {
    setIsSorting(!isSorting);
  };

  const [componentArr, setComponentArr] = useState(
    selectedOrganisation!.components
  );

  const [info, setInfo] = useState("");

  useEffect(() => {
    setComponentArr(selectedOrganisation!.components);
    // eslint-disable-next-line
  }, [selectedOrganisation]);

  const handleSubmit = () => {
    var data: UpdateData<Organisation>;

    if (saveAs) {
      data = {
        components: componentArr,
        defaultComponents: componentArr,
        savedDefaultAt: timestamp.fromDate(new Date()),
      };
    } else {
      data = { components: componentArr };
    }
    resolve(() => updateSelectedOrganisation(data))
      .then(() => {
        toast.success("settings was successfully saved");
        setIsSorting(false);
      })
      .catch((error) => toast.error(`problem saving error : ${error}`));
  };

  const handleDelete = (component: string) => {
    setComponentArr((prev) => prev.filter((c) => c !== component));
  };

  const handleInfo = (component: string) => {
    setInfo(component);
  };

  function isStringAtEnd(array: string[], targetString: string) {
    if (array.length === 0) {
      return false; // If the array is empty, the target string cannot be at the end
    }

    const lastElement = array[array.length - 1];
    return lastElement === targetString;
  }

  const testHandler = () => {
    var target = "feedback-after";
    var target2 = "feedback-org-after";
    if (
      isStringAtEnd(componentArr, target) ||
      isStringAtEnd(componentArr, target2)
    ) {
      toast.success(`Component Test Success.`);
    } else {
      toast.error(
        `${target} or ${target2} must be at the end of the page flow please change.`
      );
    }
  };

  const handleMoveUp = (index) => {
    if (index > 0) {
      setMoving(index);
      setInfo(componentArr[index]);
      const updatedArr = [...componentArr];
      const temp = updatedArr[index];
      updatedArr[index] = updatedArr[index - 1];
      updatedArr[index - 1] = temp;
      setComponentArr(updatedArr);
    }
  };

  const handleMoveDown = (index) => {
    if (index < componentArr.length - 1) {
      setMoving(index);
      setInfo(componentArr[index]);
      const updatedArr = [...componentArr];
      const temp = updatedArr[index];
      updatedArr[index] = updatedArr[index + 1];
      updatedArr[index + 1] = temp;
      setComponentArr(updatedArr);
    }
  };
  const setMoving = (index) => {
    setIsMoving(index);
  };

  const handleMoveComplete = () => {
    setIsMoving(null);
  };

  /**
   * Pad all organisation roles with default components if they are missing.
   */
  const handleRefreshComponents = async () => {
    if (selectedOrganisation) {
      setIsRefreshing(true);
      setDefaultComponents(selectedOrganisation.id).then(() => {
        if (selectedOrganisation.defaultComponents) {
          setComponentArr(selectedOrganisation.defaultComponents);

          const data = { components: selectedOrganisation.defaultComponents };

          resolve(() => updateSelectedOrganisation(data))
            .then(() => {
              toast.success("Components was refreshed successfully.");
              setIsRefreshing(false);
              setShow(false);
            })
            .catch((error) => toast.error(`problem saving error : ${error}`));
        }
      });
    }
  };

  return (
    <>
      <Modal size={"7xl"} show={isVisible}>
        <Modal.Body className="relative min-h-[80vh] bg-default">
          {" "}
          <div>
            <AiFillCloseCircle
              onClick={() => setIsVisible(!isVisible)}
              className="absolute top-5 right-5 cursor-pointer"
              size={30}
              style={{ color: "var(--icon-colour-0)" }}
            />
            {selectedOrganisation && (
              <TemplateOverview
                showDefault={true}
                data={selectedOrganisation}
              />
            )}
          </div>
        </Modal.Body>
      </Modal>{" "}
      <Modal size={"7xl"} show={show}>
        <Modal.Body
          className={`${classes["container"]} relative min-h-[80vh] bg-default`}
        >
          <div className="w-full">
            {!isLoading && (
              <AiFillCloseCircle
                onClick={() => setShow(!show)}
                className="absolute top-5 right-5 cursor-pointer"
                size={30}
                style={{ color: "var(--icon-colour-0)" }}
              />
            )}
            {selectedOrganisation && (
              <RefreshOverview data={selectedOrganisation} />
            )}
            {selectedOrganisation &&
              selectedOrganisation.defaultComponents &&
              selectedOrganisation.defaultComponents?.length > 0 && (
                <div className="mb-8 text-center">
                  <button
                    className={`${classes.button}`}
                    onClick={handleRefreshComponents}
                  >
                    {isLoading ? "Please wait..." : "Confirm"}
                  </button>
                </div>
              )}
          </div>
        </Modal.Body>
      </Modal>
      <div className="mb-[100px]">
        <EmptySpace />
        <button
          className={`${classes["button"]}`}
          onClick={() => setIsTourOpen(true)}
        >
          Show Tutorial
        </button>
        <EmptySpace />
        {selectedOrganisation && (
          <div className="flex items-center gap-4">
            <p className="text-xl my-4 text-slate-600">
              Pages for <strong>{selectedOrganisation.name}</strong>
            </p>
          </div>
        )}

        <p className=" mb-8 justify-start items-center gap-2  text-lg">
          Pages within the GrowthCircles session are individual sections or
          elements that can be organized and saved according to your
          preferences. Please be aware that certain pages may rely on others, so
          it's essential to test their functionalities before finalizing your
          arrangements. Set as the default component by checking the box and
          saving. Each time you press the "Refresh Default Components" button,
          it will fetch and load the previously saved default components.
        </p>
        <p className="my-4">
          {" "}
          <strong>Refresh Default Components :</strong> This action restores the
          pages to their default components, reverting them to the saved default
          components that were previously stored.
        </p>
        <button
          component-settings-tour="refresh-default-button"
          className={`${classes["button"]} flex gap-2 justify-center items-center p-2 font-semibold`}
          onClick={() => setShow(!show)}
          disabled={isRefreshing}
        >
          <FiRefreshCw size={20} />{" "}
          {isRefreshing ? "Please wait..." : "Refresh default components"}
        </button>
        <EmptySpace />
        <hr />
        <EmptySpace />
        <div
          component-settings-tour="re-order-button"
          onClick={isSortHandler}
          className="cursor-pointer my-2 text-slate-600 flex gap-2 items-center w-1/4"
        >
          Re-Order
          <FaSort size={20} className="text-sky-700 cursor-pointer" />
        </div>
        <div className="flex w-full gap-5">
          <div className="w-full md:w-[600px]  ">
            {componentArr.map((component, index) => (
              <ComponentOrderCard
                handleMoveDown={handleMoveDown}
                handleMoveUp={handleMoveUp}
                isSorting={isSorting}
                key={index}
                component={component}
                index={index}
                handleDelete={() => handleDelete(component)}
                handleInfo={() => handleInfo(component)}
                info={info}
                isMoving={index === isMoving}
                onMoveComplete={handleMoveComplete}
              />
            ))}
          </div>

          <ComponentDetails info={info} />
        </div>
        <EmptySpace />
        <Dropdown
          component-settings-tour="add-page=dropdown"
          style={{ backgroundColor: "var(--icon-colour-0)" }}
          className={`p-4 `}
          label="Add page"
        >
          {Object.keys(componentMapping)
            .filter(
              (component) => componentMapping[component].type === "custom"
            )
            .map((component, index) => {
              if (componentArr.includes(component)) {
                return "";
              }
              return (
                <DropdownItem
                  className="my-2"
                  key={index}
                  onClick={() => {
                    setComponentArr((prev) => [...prev, component]);
                  }}
                >
                  {getProperNameComponent(component)}
                </DropdownItem>
              );
            })}
        </Dropdown>
        <EmptySpace />
        <div
          component-settings-tour="save-default-checkbox"
          style={{ color: "var(--text-colour)" }}
          className=" my-4 border p-5 border-gray-300"
        >
          <div className="cursor-pointer" onClick={() => setSaveAs(!saveAs)}>
            <input
              type="checkbox"
              checked={saveAs}
              onChange={() => setSaveAs(!saveAs)}
              className="mx-4"
              style={{
                height: "20px",
                width: "20px",
                borderRadius: "5px",
                backgroundColor: saveAs ? "var(--icon-colour-0)" : "",
              }}
            />
            Save as default component
          </div>
          {selectedOrganisation?.savedDefaultAt && (
            <p className="my-4 flex gap-2  items-center">
              The most recent default was saved on{" "}
              {selectedOrganisation.savedDefaultAt
                .toDate()
                .toLocaleString("en-US", {
                  year: "numeric",
                  month: "long",
                  day: "numeric",
                  hour: "numeric",
                  minute: "numeric",
                  second: "numeric",
                })}
              <AiFillInfoCircle
                onClick={() => setIsVisible(!isVisible)}
                className="cursor-pointer"
                size={30}
                style={{ color: "var(--icon-colour-0)" }}
              />
            </p>
          )}
        </div>
        {componentArr.length > 0 && (
          <div className="flex gap-4 w-1/2">
            <button
              component-settings-tour="save-pages-button"
              className={`${classes["button"]} w-[300px]`}
              onClick={handleSubmit}
              disabled={isLoading}
            >
              {isLoading ? "Saving..." : "Save"}
            </button>

            <button
              component-settings-tour="test-pages-button"
              className={`${classes["button"]} w-[300px]`}
              disabled={isLoading}
              onClick={testHandler}
            >
              {isLoading ? "Testing..." : "Test"}
            </button>
          </div>
        )}
      </div>
    </>
  );
}
