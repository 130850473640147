import Filter from './Filter.js';

var customFilter: {[key: string]: any} = new Filter(
  { 
    placeHolder: '🤬',
    englishList: ['knn', 'cb', 'ccb', 'fatfuck', 'lj', 'lanjiao', 'cheebai', 'babi'], 
    chineseList: [
        '笨蛋',
        '王八蛋', 
        '混蛋',
        '我靠',
        '我尻',
        '妈的',
        '他妈的',
        '傻屄',
        '肏',
        '肏你妈' 
    ]
  }
);


export { customFilter };


