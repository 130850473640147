import { useState } from "react";
import QRCode from "react-qr-code";
import { ToggleSwitch } from "flowbite-react";
import classes from "./QrCodeDisplay.module.css";
import toast from "react-hot-toast";

type QrCodeDisplayProps = {
  value: string;
  size: number;
};

const QrCodeDisplay = ({ value, size = 150 }: QrCodeDisplayProps) => {
  const [isDark, setIsDark] = useState(false);

  const copyClipboard = () => {
    navigator.clipboard.writeText(value);
    toast.success("successfully copied to clipboard");
  };

  return (
    <div
      className={`${isDark ? "bg-black" : "bg-white"} ${
        isDark ? classes["switch-container"] : ""
      } p-5 flex flex-col gap-4 justify-center items-center rounded-2xl`}
    >
      <QRCode
        bgColor={`${isDark ? "#000000" : "#FFFFFF"}`}
        fgColor={`${isDark ? "#FFFFFF" : "#000000"}`}
        value={value}
        size={size}
        onClick={copyClipboard}
      />
      <ToggleSwitch
        checked={isDark}
        label={"Dark Background"}
        color="white"
        style={{
          maxWidth: "200px",
          color: isDark ? "white" : "black",
        }}
        onChange={() => setIsDark(!isDark)}
      />
    </div>
  );
};

export default QrCodeDisplay;
