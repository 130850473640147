import { useEffect, useState, useRef } from "react";
import { projectFirestore } from "../firebase/config";
import firebase from "firebase/compat/app";

export const useCollection = (
  collection: string | undefined,
  _query?: [string, firebase.firestore.WhereFilterOp, string] | null,
  _orderBy?: [string, firebase.firestore.OrderByDirection | undefined] | null,
  _multipleQuery?: [
    string,
    firebase.firestore.WhereFilterOp,
    string | Array<string>
  ][],
  _limit?: number
) => {
  const [documents, setDocuments] = useState<any[]>([]);
  const [error, setError] = useState<string | null>(null);

  // if we don't use a ref --> infinite loop in useEffect
  // _query is an array and is "different" on every function call
  const query = useRef(_query).current as [
    string,
    firebase.firestore.WhereFilterOp,
    string
  ];
  const orderBy = useRef(_orderBy).current as [
    string,
    firebase.firestore.OrderByDirection | undefined
  ];
  const multipleQuery = useRef(_multipleQuery).current as [
    string,
    firebase.firestore.WhereFilterOp,
    string
  ][];
  const limit = useRef(_limit).current as number;

  useEffect(() => {
    if (!collection) return;

    let ref: firebase.firestore.Query<firebase.firestore.DocumentData> =
      projectFirestore.collection(collection);

    if (query) {
      ref = ref.where(...query);
    }
    if (multipleQuery) {
      multipleQuery.forEach((query) => {
        ref = ref.where(...query);
      });
    }
    if (orderBy) {
      ref = ref.orderBy(...orderBy);
    }
    if (limit) {
      ref = ref.limit(limit);
    }

    const unsubscribe = ref.onSnapshot(
      (snapshot) => {
        let results: any[] = [];
        snapshot.docs.forEach((doc) => {
          results.push({ ...doc.data(), id: doc.id });
        });

        // update state
        setDocuments(results);
        setError(null);
      },
      (error) => {
        console.log(collection, error);
        setError("could not fetch the data");
      }
    );

    // unsubscribe on unmount
    return () => unsubscribe();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [collection, query, orderBy]);

  return { documents, error };
};
