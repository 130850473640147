import FiveStepHeader from "components/FiveStepHeader/FiveStepHeader";
import Header from "components/Header/Header";
import Steps from "components/utility/Steps";
import classes from "./SettingsIntentions.module.css";
import { useAuthContext } from "hooks/useAuthContext";
import { useGrowthCircleContext } from "hooks/useGrowthCircleContext";
import { useGCSession } from "hooks/useGCSession";
import { useNavigate } from "react-router-dom";
import EmptySpace from "components/utility/EmptySpace";
import BackAndNext from "components/utility/BackAndNext";
import SubmitIntention from "components/SettingIntentions/SubmitIntention";
import OthersIntention from "components/SettingIntentions/OthersIntention";
import Center from "components/utility/Center/Center";
import NoGCSessionWrapper from "pages/NoGCSession/NoGCSessionWrapper";
import ContentWrapper from "components/utility/ContentWrapper";
import { CHOOSE_ROLES_ROUTE, RATING_AVERAGE_ROUTE } from "strings/Routes";
import { useEffect, useState } from "react";
import NGFooter from "components/Footers/NGFooter";
import useParticipants from "hooks/useParticipants";
import { checkCanAdvanceNextActivity } from "models/organisationRole";
import Instruction from "components/utility/Instruction";
import { updateWindowLocationPath } from "models/profile";

const INSTRUCTIONS_SET_INTENTIONS =
  "What would you like to take away from the session?";

export default function SettingIntentions() {
  const { profile } = useAuthContext();
  const { growthCircleSession } = useGrowthCircleContext();
  const { facilAllowNextPage } = useGCSession();
  let navigate = useNavigate();
  const currentStep = 2;
  const stepsCount = 5;
  const [hasTrigger, setHasTrigger] = useState(false);
  const { sessionRole } = useParticipants();
  const canAdvance = checkCanAdvanceNextActivity(sessionRole);

  const _setTrigger = () => {
    setHasTrigger(!hasTrigger);
  };

  useEffect(() => {
    if (!profile?.uid) {
      return;
    }
    updateWindowLocationPath(profile.uid);
  }, [profile?.uid]);

  const nextHandler = () => {
    if (canAdvance) {
      facilAllowNextPage("choose-roles");
    }
    navigate(CHOOSE_ROLES_ROUTE);
  };

  const backHandler = () => {
    navigate(RATING_AVERAGE_ROUTE);
  };

  return (
    <NoGCSessionWrapper>
      <div className="relative">
        <Header hasModal={hasTrigger} />
        <FiveStepHeader
          hasLightning={true}
          hasGuide={true}
          currentStep={currentStep}
          hasModal={hasTrigger}
          setIsTriggerVisible={_setTrigger}
        />

        <ContentWrapper hasFiveStepper={true}>
          <Center minHeight="100vh">
            <EmptySpace height={"1rem"} />
            <Steps count={stepsCount} currentStep={currentStep} />
            <EmptySpace height={"2rem"} />
            <div className={classes.container}>
              <div className={classes.description}>
                <p className="text-md">
                  If this session could gift you something,
                </p>
                <p className="text-md">what would it be?</p>
              </div>
              <Instruction text={INSTRUCTIONS_SET_INTENTIONS} />
            </div>
            <EmptySpace height={"2rem"} />
            <SubmitIntention profile={profile} />
            <EmptySpace height={"5px"} />
            <OthersIntention />
            <EmptySpace height={"30px"} />
            <BackAndNext
              nextAllowed={canAdvance || growthCircleSession?.allowChooseRoles}
              nextCallback={nextHandler}
              backAllowed={true}
              backCallback={backHandler}
              backHidden={undefined}
            />
            <EmptySpace height={"50px"} />
            <NGFooter type={growthCircleSession?.type} />
          </Center>
        </ContentWrapper>
      </div>
    </NoGCSessionWrapper>
  );
}
