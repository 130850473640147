import { inputClass } from "strings/InputClassStrings";
import { colours } from "../components/styles/colourMap";
import { ChangeEvent, useEffect, useState } from "react";

const OptionInput = ({
  index,
  pageType,
  placeholder,
  storage,
}: {
  index: number;
  pageType: string;
  placeholder?: string;
  storage: string;
}) => {
  const [answer, setAnswer] = useState<string>("");

  // Load data from local storage on component mount
  useEffect(() => {
    const storedJournal = localStorage.getItem("SelfAwarenessJournal");
    if (storedJournal) {
      const parsedJournal = JSON.parse(storedJournal);
      if (parsedJournal.Options) {
        setAnswer(parsedJournal.Options[index]);
      }
    }
    // eslint-disable-next-line
  }, []); // Empty dependency array means this effect runs once on mount

  const answerHandler = (value: string) => {
    setAnswer(value);
    const storedJournal = localStorage.getItem("SelfAwarenessJournal");
    const existingJournal = storedJournal ? JSON.parse(storedJournal) : {};
    const existingSteps = existingJournal[storage] || [];

    // Check if the index is within bounds
    if (index >= 0 && index < existingSteps.length) {
      const updatedSteps = existingSteps.map((step, i) =>
        i === index ? value : step
      );

      // Update the existing journal with the updated challenges array
      const updatedJournal = {
        ...existingJournal,
        [storage]: updatedSteps,
      };

      // Store the updated journal in local storage
      localStorage.setItem(
        "SelfAwarenessJournal",
        JSON.stringify(updatedJournal)
      );
    } else {
      // Add a new value if the index is out of bounds
      const updatedJournal = {
        ...existingJournal,
        [storage]: [...existingSteps, value],
      };

      // Store the updated journal in local storage
      localStorage.setItem(
        "SelfAwarenessJournal",
        JSON.stringify(updatedJournal)
      );
    }
  };

  return (
    <div className="flex gap-2 items-center w-full">
      <input
        onChange={(e: ChangeEvent<HTMLInputElement>) =>
          answerHandler(e.target.value)
        }
        value={answer}
        placeholder={`${placeholder ? placeholder : `Step ${index + 1}`}`}
        className={`${inputClass} border-4`}
        style={{ borderColor: colours[pageType]["border"] }}
      />
    </div>
  );
};

export default OptionInput;
