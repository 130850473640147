import classes from "./TopicResponse.module.css";
import OwnTopic from "./OwnTopic";
import OtherTopic from "./OtherTopics";
import Center from "components/utility/Center/Center";
import EmptySpace from "components/utility/EmptySpace";
import { PARTICIPANT_RESPONSE_TITLE } from "strings/ViewChooseTopic";

const TopicResponse = () => {
  return (
    <Center width={"100%"}>
      <OwnTopic />
      <EmptySpace height={"40px"} />
      <strong
        className={`${classes["participants-response"]}  py-2 px-4 w-full text-center`}
      >
        {PARTICIPANT_RESPONSE_TITLE}
      </strong>
      <EmptySpace height={"30px"} />
      <OtherTopic />
    </Center>
  );
};

export default TopicResponse;
