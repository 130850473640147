import { DocumentData } from "firebase/firestore";

import { FeedbackQuestions } from "models/componentSettings/feedback/utility/feedback";

import FeedbackSortCard from "./FeedbackSortCard";

interface FeedbackSortProps {
  questions: DocumentData[];
  setOrder: Function;
  order: string[];
}

const FeedbackSortComponent = ({
  questions,
  setOrder,
  order,
}: FeedbackSortProps) => {
  const feedbackQuestions: FeedbackQuestions[] = questions.map(
    (item: DocumentData) => item as FeedbackQuestions
  );

  return (
    <div className="flex gap-2 py-4">
      {feedbackQuestions &&
        feedbackQuestions.map((question, index) => (
          <FeedbackSortCard
            order={order}
            setOrder={setOrder}
            key={index}
            question={question}
          />
        ))}
    </div>
  );
};

export default FeedbackSortComponent;
