import React from "react";
import Checkbox from "./Checkbox";
import classes from "./JournalChecklistContainer.module.css";
import { JournalContent } from "components/EndOfSession/JournalContent";

type JournalChecklistContainerProps = {
  isSaveIntention: boolean;
  isSaveReflect: string[];
  isSaveReflectAll: boolean;
  isSaveShareAndSupport: string[];
  isSaveShareAndSupportAll: boolean;
  // isSaveSkillDescription,
  // isSaveSkillsDescriptionAll,
  handleSaveIntention: (e: React.FormEvent<HTMLInputElement>) => void;
  handleSaveReflect: (e: React.FormEvent<HTMLInputElement>) => void;
  handleSaveReflectAll: (e: React.FormEvent<HTMLInputElement>) => void;
  handleSaveShareAndSupport: (e: React.FormEvent<HTMLInputElement>) => void;
  handleSaveShareAndSupportAll: (e: React.FormEvent<HTMLInputElement>) => void;
  // handleSaveSkillDescription,
  // handleSaveSkillDescriptionAll,
  handleJournalSubmit: () => void;
  saveIntentionContent: JournalContent;
  reflectContent: JournalContent;
  // skillsDescriptionContent,
  shareAndSupportContent: JournalContent;
};

/**
 * Component that displays the journal checklist for EndOfSession
 * @param {boolean} isSaveIntention - boolean flag that binds to whether save intention is checked
 * @param {string[]} isSaveReflect - string array that stores the ids of the type of reflect being saved (Notes/Reflection)
 * @param {boolean} isSaveReflectAll - boolean flag that binds to whether or not to save all reflect sub-sections (Notes/Reflection)
 * @param {string[]} isSaveShareAndSupport - string array that stores the ids of the type of Share And Support (My/Other questions) to save
 * @param {boolean} isSaveShareAndSupportAll - boolean flag that binds to whether or not to save all share and support sub-sections (My/Other questions)
 * @param {(e: React.FormEvent<HTMLInputElement>) => void} handleSaveIntention - event method after user checks "Save Intention"
 * @param {(e: React.FormEvent<HTMLInputElement>) => void} handleSaveReflect - event method after user checks sub-sections of Reflection (Notes/Reflections)
 * @param {(e: React.FormEvent<HTMLInputElement>) => void} handleSaveReflectAll - event method after user checks "Reflect"
 * @param {(e: React.FormEvent<HTMLInputElement>) => void} handleSaveShareAndSupport - event method after user checks any sub-section of Share and Support (My Questions/Others Questions)
 * @param {() => void} handleJournalSubmit - method to handle when user clicks save
 * @param {JournalContent} saveIntentionContent - Markup of intention content
 * @param {JournalContent} reflectContent - Markup of reflection content
 * @param {JournalContent} shareAndSupportContent - Markup of shareandsupport content
 *
 */
const JournalChecklistContainer = ({
  isSaveIntention,
  isSaveReflect,
  isSaveReflectAll,
  isSaveShareAndSupport,
  isSaveShareAndSupportAll,
  // isSaveSkillDescription,
  // isSaveSkillsDescriptionAll,
  handleSaveIntention,
  handleSaveReflect,
  handleSaveReflectAll,
  handleSaveShareAndSupport,
  handleSaveShareAndSupportAll,
  // handleSaveSkillDescription,
  // handleSaveSkillDescriptionAll,
  handleJournalSubmit,
  saveIntentionContent,
  reflectContent,
  // skillsDescriptionContent,
  shareAndSupportContent,
}: JournalChecklistContainerProps) => {
  const strongStyle = {
    fontWeight: "bold",
  };

  const handleConfirmJournalSubmit = (e) => {
    e.preventDefault();
    handleJournalSubmit();
  };

  return (
    <form
      className={classes["journalFormContainer"]}
      onSubmit={handleConfirmJournalSubmit}
    >
      {/* Save Intention */}
      <Checkbox
        key={saveIntentionContent.id}
        id={saveIntentionContent.id}
        name={saveIntentionContent.name}
        text={saveIntentionContent.text}
        styleType={saveIntentionContent.style}
        handleClick={handleSaveIntention}
        isChecked={isSaveIntention}
      />

      <hr className={classes["divider"]} />

      <p>
        <strong style={strongStyle}>Save elements in "Choose and Share"</strong>
      </p>

      {/* Reflect Content */}
      <ul>
        <li>
          <Checkbox
            key={reflectContent.id}
            id={reflectContent.id}
            name={reflectContent.name}
            text={reflectContent.text}
            styleType={reflectContent.style}
            handleClick={handleSaveReflectAll}
            isChecked={isSaveReflectAll}
          />
        </li>
        <ul>
          {reflectContent.subContent!.map(
            ({ id, name, text, style }, index) => {
              return (
                <li key={id}>
                  <Checkbox
                    key={id}
                    id={id}
                    name={name}
                    text={text}
                    styleType={style}
                    handleClick={handleSaveReflect}
                    isChecked={isSaveReflect.includes(id)}
                  />
                </li>
              );
            }
          )}
        </ul>
      </ul>

      <hr className={classes["divider"]} />

      {/* Share And Support Content */}
      <ul>
        <li>
          <Checkbox
            key={shareAndSupportContent.id}
            id={shareAndSupportContent.id}
            name={shareAndSupportContent.name}
            text={shareAndSupportContent.text}
            styleType={shareAndSupportContent.style}
            handleClick={handleSaveShareAndSupportAll}
            isChecked={isSaveShareAndSupportAll}
          />
        </li>
        <ul>
          {shareAndSupportContent.subContent!.map(
            ({ id, name, text, style }, index) => {
              return (
                <li key={id}>
                  <Checkbox
                    key={id}
                    id={id}
                    name={name}
                    text={text}
                    styleType={style}
                    handleClick={handleSaveShareAndSupport}
                    isChecked={isSaveShareAndSupport.includes(id)}
                  />
                </li>
              );
            }
          )}
        </ul>
      </ul>
      {/* Skill Description Content */}
      {/* <ul>
        <li>
          <Checkbox
            key={skillsDescriptionContent.id}
            id={skillsDescriptionContent.id}
            name={skillsDescriptionContent.name}
            text={skillsDescriptionContent.text}
            style={skillsDescriptionContent.style}
            handleClick={handleSaveSkillDescriptionAll}
            isChecked={isSaveSkillsDescriptionAll}
          />
        </li>
        <ul>
          {skillsDescriptionContent.subContent.map(({ id, name, text, style }, index) => {
            return (
              <li key={id}>
                <Checkbox
                  key={id}
                  id={id}
                  name={name}
                  text={text}
                  style={style}
                  handleClick={handleSaveSkillDescription}
                  isChecked={isSaveSkillDescription.includes(id)}
                />
              </li>
            );
          })}
        </ul>
      </ul> */}

      <button className={classes["saveButton"]} type="submit">
        Save
      </button>
    </form>
  );
};

export default JournalChecklistContainer;
