import { useEffect } from "react";
import Bubble from "components/InfoPanel/CircleBubble";
import PageWrapper from "components/utility/PageWrapper";
import { useAuthContext } from "hooks/useAuthContext";
import { useGCSession } from "hooks/useGCSession";
import classes from "./WaitingArea.module.css";
import useGetParticipants from "hooks/useGetParticipants";
import EmptySpace from "components/utility/EmptySpace";
import NoGCSessionWrapper from "pages/NoGCSession/NoGCSessionWrapper";
import { useGrowthCircleContext } from "hooks/useGrowthCircleContext";
import ParticipantWaitingArea from "components/WaitingArea/ParticipantWaitingArea";
import WaitingAreaNext from "components/WaitingArea/WaitingAreaNext";
import { useFirestore } from "hooks/useFirestore";
import GrowthCollectiveLogo from "assets/NG/Logos/GrowthCollective.webp";
import NationalGalleryLogo from "assets/NG/Logos/NationalGallery.webp";
import SUSSLogo from "assets/SUSS/SUSS.png";
import NGFooter from "components/Footers/NGFooter";
import useParticipants from "hooks/useParticipants";
import { checkCanAdvanceNextActivity } from "models/organisationRole";
import useOrganisationContext from "hooks/organisation/useOrganisationContext";
import BuddyUp from "assets/NTU/BuddyUp.png";
import OrgBubble from "components/Bubble/OrgBubble";

const CustomWaitingArea = ["NG", "SUSS", "NTU Career & Attachment Office"];

export default function WaitingArea() {
  const { profile } = useAuthContext();
  const { participants } = useGetParticipants();
  const { createDices } = useGCSession();
  const { growthCircleSession } = useGrowthCircleContext();
  const { selectedOrganisation } = useOrganisationContext();
  const { updateDocument } = useFirestore("users");
  const { sessionRole } = useParticipants();

  const canAdvance = checkCanAdvanceNextActivity(sessionRole);

  useEffect(() => {
    if (
      !profile ||
      !canAdvance ||
      !growthCircleSession ||
      !selectedOrganisation
    )
      return;
    createDices(growthCircleSession);
    const data = { pageStep: window.location.pathname };
    updateDocument(profile.uid, data);

    if (profile.isFacil === true && profile.facilGC === "") {
      const data2 = { facilGC: growthCircleSession.id };
      updateDocument(profile.uid, data2);
    }

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [profile, growthCircleSession, canAdvance, selectedOrganisation]);

  if (!profile || !growthCircleSession) return null;

  return (
    <NoGCSessionWrapper>
      <div className="relative">
        <PageWrapper pageType={"waiting-area"}>
          <div className={classes["wrapper"]}>
            <div className={classes["container"]}>
              {/* TODO: Need to convert into organisation */}

              {selectedOrganisation &&
                !CustomWaitingArea.includes(selectedOrganisation.name) && (
                  <>
                    <OrgBubble />
                  </>
                )}

              {selectedOrganisation &&
                CustomWaitingArea.includes(selectedOrganisation.name) && (
                  <>
                    {" "}
                    {selectedOrganisation.name === "NG" && (
                      <Bubble>
                        <span className="text-xl font-semibold">
                          How To Art
                        </span>
                        <span className="text-xl font-semibold">
                          With Friends
                        </span>
                        <span className="text-xl font-semibold">
                          Growth Circles
                        </span>
                        <div className="flex gap-1 justify-center items-center">
                          <img src={GrowthCollectiveLogo} alt="" width={80} />
                          <img src={NationalGalleryLogo} alt="" width={130} />
                        </div>
                      </Bubble>
                    )}
                    {selectedOrganisation.name === "SUSS" && (
                      <Bubble>
                        <span className="text-xl font-semibold">PSY213</span>
                        <span className="text-xl font-semibold">
                          Growth Circles
                        </span>
                        <div className="flex gap-1 justify-center items-center">
                          <img src={GrowthCollectiveLogo} alt="" width={100} />
                          <img
                            className="rounded-lg"
                            src={SUSSLogo}
                            alt="SUSS"
                            width={100}
                          />
                        </div>
                      </Bubble>
                    )}
                    {selectedOrganisation.name ===
                      "NTU Career & Attachment Office" && (
                      <Bubble>
                        <div>
                          <img
                            src={BuddyUp}
                            alt="BuddyUp"
                            className="mb-2 h-44 w-44"
                          />
                        </div>
                        <h3 className="text-center text-3xl">
                          Growth Mentoring <br /> Circles
                        </h3>
                      </Bubble>
                    )}
                  </>
                )}

              {!selectedOrganisation && (
                <>
                  {" "}
                  {growthCircleSession.type === "NG" && (
                    <Bubble>
                      <span className="text-xl font-semibold">How To Art</span>
                      <span className="text-xl font-semibold">
                        With Friends
                      </span>
                      <span className="text-xl font-semibold">
                        Growth Circles
                      </span>
                      <div className="flex gap-1 justify-center items-center">
                        <img src={GrowthCollectiveLogo} alt="" width={80} />
                        <img src={NationalGalleryLogo} alt="" width={130} />
                      </div>
                    </Bubble>
                  )}
                  {growthCircleSession.type === "SUSS" && (
                    <Bubble>
                      <span className="text-xl font-semibold">PSY213</span>
                      <span className="text-xl font-semibold">
                        Growth Circles
                      </span>
                      <div className="flex gap-1 justify-center items-center">
                        <img src={GrowthCollectiveLogo} alt="" width={100} />
                        <img
                          className="rounded-lg"
                          src={SUSSLogo}
                          alt="SUSS"
                          width={100}
                        />
                      </div>
                    </Bubble>
                  )}
                </>
              )}

              <EmptySpace height={"30px"} />

              <ParticipantWaitingArea participants={participants} />

              <EmptySpace height={"30px"} />
              <WaitingAreaNext participants={participants} />
              <EmptySpace height={"80px"} />
            </div>
          </div>
        </PageWrapper>
        <EmptySpace height={"80px"} />
        <NGFooter type={growthCircleSession?.type} />
      </div>
    </NoGCSessionWrapper>
  );
}
