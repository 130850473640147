import { useCallback } from "react";
import {
  addGroup as _addGroup,
  deleteGroup,
  getGroupByGroupId,
  getGroupByGroupName,
  getGroups,
  inviteUser,
  updateGroupName,
} from "models/organisationGroup";
import { useListSelect } from "hooks/utility/useListSelect";
import { WithId } from "utility/model";
import { Group } from "interface/GroupInterface";

export interface OrganisationGroupHook {
  groups: WithId<Group>[];
  selectedGroup: WithId<Group> | null;
  selectFirstGroup: () => void;
  selectGroupByGroupName: (groupName: string, subGroupName: string) => void;
  addGroup: (groupName: string, subGroupName: string) => Promise<void>;
  inviteUserToSelectedGroup: (email: string) => Promise<void>;
  updateSelectedGroupName: (
    newGroupName: string,
    newSubGroupName: string
  ) => Promise<void>;
  deleteSelectedGroup: () => Promise<void>;
  getGroupByUserId: (userId: string) => Promise<WithId<Group> | undefined>;
}

/**
 * Keeps the state of a list of an organisation's groups and the currently selected group.
 *
 * @param organisationId Id of the organisation to manage.
 * @returns OrganisationGroupHook.
 */
export default function useOrganisationGroup(
  organisationId: string
): OrganisationGroupHook {
  const _fetchGroups = useCallback(async () => {
    if (!organisationId) {
      return [];
    }
    return getGroups(organisationId).then((groups) =>
      groups.sort((g1, g2) => g1.groupName.localeCompare(g2.groupName))
    );
  }, [organisationId]);

  const {
    items: groups,
    selectedItem: selectedGroup,
    selectItem,
    addItem,
    updateItem,
    deleteItem,
  } = useListSelect(
    _fetchGroups,
    (group1: WithId<Group>, group2: WithId<Group>) => group1.id === group2.id
  );

  const selectFirstGroup = useCallback(
    () => selectItem(() => true),
    [selectItem]
  );
  const selectGroupByGroupName = useCallback(
    (groupName: string, subGroupName: string) => {
      return selectItem(
        (g) => g.groupName === groupName && g.subGroupName === subGroupName
      );
    },
    [selectItem]
  );

  const addGroup = useCallback(
    async (groupName: string, subGroupName: string) => {
      await _addGroup(organisationId, {
        groupName,
        subGroupName,
        organisationId,
        invites: [],
        users: [],
        groupLeaders: [],
      });
      return addItem(
        await getGroupByGroupName(organisationId, groupName, subGroupName)
      );
    },
    [organisationId, addItem]
  );

  const inviteUserToSelectedGroup = useCallback(
    async (email: string) => {
      if (!selectedGroup?.id) {
        return;
      }
      await inviteUser(organisationId, selectedGroup, email);
      return updateItem(
        await getGroupByGroupId(organisationId, selectedGroup.id)
      );
    },
    [selectedGroup, organisationId, updateItem]
  );

  const updateSelectedGroupName = useCallback(
    async (newGroupName: string, newSubGroupName: string) => {
      if (!selectedGroup?.id) {
        return;
      }
      await updateGroupName(
        organisationId,
        selectedGroup.id,
        newGroupName,
        newSubGroupName
      );
      return updateItem(
        await getGroupByGroupId(organisationId, selectedGroup.id)
      );
    },
    [selectedGroup, organisationId, updateItem]
  );

  const getGroupByUserId = async (userId: string) => {
    const groups = await getGroups(organisationId);

    const userGroup = groups.find((group) => group.users.includes(userId));

    return userGroup;
  };

  const deleteSelectedGroup = useCallback(async () => {
    if (!selectedGroup?.id) {
      return;
    }
    await deleteGroup(organisationId, selectedGroup.id);
    deleteItem();
  }, [selectedGroup, organisationId, deleteItem]);

  return {
    groups,
    selectedGroup,
    selectGroupByGroupName,
    selectFirstGroup,
    addGroup,
    inviteUserToSelectedGroup,
    updateSelectedGroupName,
    deleteSelectedGroup,
    getGroupByUserId,
  };
}
