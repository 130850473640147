import SimpleLoader from "components/Loaders/SimpleLoader";
import AutoResizeTextArea from "components/utility/Inputs/AutoResizeTextArea";
import { Button, Modal } from "flowbite-react";
import { useOrganisationQuestion } from "hooks/useOrganisationQuestion";
import usePromise from "hooks/utility/usePromise";
import { QuestionInterface } from "interface/QuestionsInterface";
import { useState } from "react";
import toast from "react-hot-toast";
import { AiFillCloseCircle } from "react-icons/ai";
import { FaEdit, FaTrash } from "react-icons/fa";
import { HiOutlineExclamationCircle } from "react-icons/hi";

interface QuestionCardProps {
  question: QuestionInterface;
}

const QuestionCard = ({ question }: QuestionCardProps) => {
  const { DeleteQuestion, updateQuestion } = useOrganisationQuestion();
  const [isVisible, setIsVisible] = useState(false);
  const [editVisible, setEditVisible] = useState(false);
  const [questionEdit, setQuestionEdit] = useState<string>(question.question);
  const { isLoading, resolve } = usePromise();
  const { isLoading: isSaving, resolve: saving } = usePromise();

  const deleteHandler = () => {
    resolve(() => DeleteQuestion(question.id)).then(() => {
      toast.success("Question was deleted successfully");
    });
  };

  const saveHandler = () => {
    saving(() => updateQuestion(question.id, questionEdit)).then(() => {
      setEditVisible(!editVisible);
      toast.success("Question was updated successfully");
    });
  };

  return (
    <>
      <Modal
        show={isVisible}
        size="md"
        popup
        onClose={() => setIsVisible(!isVisible)}
      >
        <Modal.Header />
        <Modal.Body>
          <div className="text-center">
            <HiOutlineExclamationCircle className="mx-auto mb-4 h-14 w-14 text-gray-400 dark:text-gray-200" />
            <h3 className="mb-5 text-lg font-normal text-gray-500 dark:text-gray-400">
              Are you sure you want to delete question{" "}
              <strong>{question.question}</strong>?
            </h3>
            {!isLoading ? (
              <div className="flex justify-center items-center gap-4">
                <Button color="failure" onClick={deleteHandler}>
                  Yes, I'm sure
                </Button>
                <Button color="gray" onClick={() => setIsVisible(!isVisible)}>
                  No, cancel
                </Button>
              </div>
            ) : (
              <div className="flex justify-center items-center gap-4">
                <SimpleLoader />{" "}
              </div>
            )}
          </div>
        </Modal.Body>
      </Modal>

      <Modal
        show={editVisible}
        size="3xl"
        onClose={() => setEditVisible(!editVisible)}
      >
        <Modal.Body
          className="border-2 rounded-lg bg-default relative"
          style={{ borderColor: "var(--icon-colour-0)" }}
        >
          <AiFillCloseCircle
            size={30}
            className="absolute top-2 right-2 cursor-pointer"
            onClick={() => setEditVisible(!editVisible)}
            style={{ color: "var(--icon-colour-0)" }}
          />
          <div className="text-center flex flex-col gap-4 p-5">
            <h3 className="mb-5 text-lg font-normal text-gray-500 dark:text-gray-400">
              Edit the question and press save when done.
            </h3>
            <div className="p-2 shadow-lg bg-white rounded-lg">
              <AutoResizeTextArea
                className="w-full border-none"
                value={questionEdit}
                onChange={(value) => setQuestionEdit(value)}
              />
            </div>
            <button
              disabled={isSaving}
              onClick={saveHandler}
              className="bg-red-400 text-white py-4 text-lg uppercase rounded-full shadow-md w-1/3 ml-auto"
            >
              {isSaving ? "Saving..." : "Save"}
            </button>
          </div>
        </Modal.Body>
      </Modal>
      <div>
        <div className="p-2 py-6 border-b border-slate-400/60 flex justify-between">
          <p className="text-slate-800 break-words">{question.question}</p>
          <div className="flex flex-col items-center gap-2">
            <FaEdit
              onClick={() => setEditVisible(!editVisible)}
              size={22}
              className="text-blue-500 cursor-pointer "
            />
            <FaTrash
              onClick={() => setIsVisible(!isVisible)}
              size={22}
              className="text-red-500 cursor-pointer "
            />
          </div>
        </div>
      </div>
    </>
  );
};

export default QuestionCard;
