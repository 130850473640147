import { FC, useEffect, useState } from "react";
import { ComponentProps } from "../SelfAwarenessComponentMapping";

import { useSelfAwarenessContext } from "hooks/useSelfAwarenessContext";
import TitleBox from "../SubComponents/TitleBox";
import TextArea from "../SubComponents/TextArea";
import classes from "pages/AllCircles/Admin/Admin.module.css";

import OptionInput from "../SubComponents/OptionInput";
import BackAndNextFlat from "components/utility/BackAndNextFlat";
import toast from "react-hot-toast";

const DecisionComp: FC<ComponentProps> = ({
  backAndNextHandler,
  setHasModal,
}) => {
  const { journal } = useSelfAwarenessContext();
  const [answer, setAnswer] = useState("");
  // Load data from local storage on component mount
  useEffect(() => {
    const storedJournal = localStorage.getItem("SelfAwarenessJournal");
    if (storedJournal) {
      const parsedJournal = JSON.parse(storedJournal);
      setAnswer(parsedJournal.decisionAnswer || "");
    }
    // eslint-disable-next-line
  }, []); // Empty dependency array means this effect runs once on mount

  const answerHandler = (value: string) => {
    setAnswer(value);
    const storedJournal = localStorage.getItem("SelfAwarenessJournal");
    const existingJournal = storedJournal ? JSON.parse(storedJournal) : {};

    // Update the existing journal with the new answer
    const updatedJournal = {
      ...existingJournal,
      decisionAnswer: value,
    };

    // Store the updated journal in local storage
    localStorage.setItem(
      "SelfAwarenessJournal",
      JSON.stringify(updatedJournal)
    );
  };

  const nextHandler = (e: React.MouseEvent<HTMLButtonElement, MouseEvent>) => {
    const storedJournal = localStorage.getItem("SelfAwarenessJournal");
    const existingJournal = storedJournal ? JSON.parse(storedJournal) : {};
    if (
      existingJournal &&
      existingJournal.Options &&
      existingJournal.Options.every((item) => item.trim() !== "")
    ) {
      backAndNextHandler.nextCallback(e);
    } else {
      toast.error("Please fill-up all options");
    }
  };

  useEffect(() => {
    if (!journal) return;
  }, [journal]);
  return (
    <div className="py-8 px-4 w-full md:w-1/4">
      {journal && (
        <>
          <TitleBox
            journal={journal}
            title="What do you need to decide on?"
            showInfo={true}
            size="small"
            handler={() => {}}
          />
          <TextArea
            row={4}
            maxLength={500}
            answer={answer}
            journal={journal}
            answerHandler={answerHandler}
          />
          <TitleBox
            journal={journal}
            title="What do you need to decide on?"
            showInfo={true}
            size="small"
            handler={() => {}}
          />
          <div className={`${classes["container"]} flex flex-col w-full my-4`}>
            <OptionInput
              index={0}
              placeholder="Option 1"
              pageType={journal.pageType}
              storage="Options"
            />
            <OptionInput
              index={1}
              placeholder="Option 2"
              pageType={journal.pageType}
              storage="Options"
            />
            <OptionInput
              index={2}
              placeholder="Option 3"
              pageType={journal.pageType}
              storage="Options"
            />
          </div>
          <div className="my-4">
            <BackAndNextFlat
              {...backAndNextHandler}
              nextCallback={(e) => nextHandler(e)}
              nextAllowed={answer.length > 0 ? true : false}
            />
          </div>
        </>
      )}
    </div>
  );
};

export default DecisionComp;
