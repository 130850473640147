import classes from "components/ChooseTopics/TopicSelection.module.css";
import { useCarousel } from "hooks/useCarousel";
import React, { useState, useEffect } from "react";
import useParticipants from "hooks/useParticipants";
import { AiFillInfoCircle } from "react-icons/ai";
import { Modal } from "flowbite-react";
import { MdCancel } from "react-icons/md";
import { LEARNING_OUTCOMES } from "strings/LearningOutcomes";
import { ComponentProps } from "../GenericSessionPage/ComponentMapping";
import { useGrowthCircleContext } from "hooks/useGrowthCircleContext";
import Topics from "components/ChooseTopics/Topics";
import Carousel from "components/ChooseRoles/Carousel";
import EmptySpace from "components/utility/EmptySpace";
import BackAndNext from "components/utility/BackAndNext";
import { TOPIC_INFOS } from "strings/TopicInfos";
import useOrganisationContext from "hooks/organisation/useOrganisationContext";

import { useOrganisationTopics } from "hooks/useOrganisationTopics";
import { DocumentData } from "firebase/firestore";
import { Editor } from "react-draft-wysiwyg";
import { EditorState, convertFromRaw } from "draft-js";
import "react-draft-wysiwyg/dist/react-draft-wysiwyg.css";
import { useOrganisationActivityPage } from "hooks/useOrganisationActivityPage";
import { Activity } from "interface/ActivityInterface";

const isTopicCreated = (oldTopic: string, topics: DocumentData) => {
  let isCreated = true;
  topics.forEach((generalTopic) => {
    if (generalTopic) {
      const arrayOfTopics = generalTopic["general"];
      arrayOfTopics.forEach((topic) => {
        if (topic === oldTopic) {
          isCreated = false;
        }
      });
    }
  });
  return isCreated;
};

const ChooseTopicComponent = ({
  setHasModal,
  backAndNextHandler,
}: ComponentProps) => {
  const { growthCircleSession } = useGrowthCircleContext();
  const { selectedOrganisation } = useOrganisationContext();
  const { oldTopic, updateSelectedTopic } = useParticipants();
  const {
    activityTopics: orgTopics,
    getActivityTopics,
    getAllTopics,
  } = useOrganisationTopics();
  const [content, setContent] = useState("");

  const { activities } = useOrganisationActivityPage();
  const [focusActivity, setFocusActivity] = useState<Activity | null>(null);

  // retrieve topics from database

  const [editorState, setEditorState] = useState(() =>
    EditorState.createEmpty()
  );

  useEffect(() => {
    if (content)
      setEditorState(
        EditorState.createWithContent(convertFromRaw(JSON.parse(content)))
      );
  }, [content]);

  const { generalTopics } = useOrganisationTopics();
  const [topicNameState, setTopicNameState] = useState("");

  const [isVisible, setIsVisible] = useState(false);
  const [infoIsVisible, setInfoIsVisible] = useState(false);

  const customSort = (a: DocumentData, b: DocumentData) => {
    if (a.category === "General Topics") {
      return -1; // a should come before b
    } else if (b.category === "General Topics") {
      return 1; // b should come before a
    }
    return 0; // no change in order
  };

  const combinedArray = selectedOrganisation?.inheritGeneralTopics
    ? orgTopics.concat(generalTopics).sort(customSort)
    : orgTopics;

  // carousel
  const { currentIndex, next, prev } = useCarousel(combinedArray.length);

  // currently selected topic
  const [currSelected, setCurrSelected] = useState("");

  // created topic
  const [createdTopic, setCreatedTopic] = useState("");

  const topic = combinedArray[currentIndex];
  const topicContent = topic ? combinedArray : [];

  // fetch last chosen topic
  useEffect(() => {
    if (
      !selectedOrganisation ||
      !combinedArray ||
      !generalTopics ||
      !orgTopics ||
      !growthCircleSession?.Activity
    )
      return;

    // eslint-disable-next-line
  }, [
    oldTopic,
    growthCircleSession,
    selectedOrganisation,
    orgTopics,
    generalTopics,
    combinedArray,
  ]);

  useEffect(() => {
    if (oldTopic) {
      setCurrSelected(oldTopic);
    }

    if (isTopicCreated(oldTopic, topicContent)) {
      setCreatedTopic(oldTopic);
    }
    // eslint-disable-next-line
  }, [oldTopic]);

  useEffect(() => {
    if (!growthCircleSession) return;
    if (
      growthCircleSession?.Activity &&
      selectedOrganisation?.components.includes("choose-activity")
    ) {
      getActivityTopics(growthCircleSession?.Activity[0]);
    } else {
      getAllTopics();
    }

    // eslint-disable-next-line
  }, [growthCircleSession, selectedOrganisation]);

  // handle next
  const handleNext = (e: React.MouseEvent<HTMLButtonElement, MouseEvent>) => {
    updateSelectedTopic(currSelected);
    backAndNextHandler.nextCallback(e);
  };

  useEffect(() => {
    if (!growthCircleSession || !growthCircleSession.Activity) return; // Null check for growthCircleSession and its Activity property

    const activityTitle = growthCircleSession.Activity[0]; // Assuming Activity is an array
    if (!activityTitle) return; // Null check for activityTitle

    const activity = activities.find(
      (activity) => activity.title === activityTitle
    );
    if (activity) {
      setFocusActivity(activity);
    }
  }, [activities, growthCircleSession]);

  //SUSS
  const getSUSStopicDisplay = (text: string) => {
    var subText = text;
    switch (text) {
      case "Growth Circle 1 Topics":
        subText = "GC1";
        break;
      case "Growth Circle 2 Topics":
        subText = "GC2";
        break;
      case "Growth Circle 3 Topics":
        subText = "GC3";
        break;
      case "Growth Circle 4 Topics":
        subText = "GC4";
        break;
      default:
        subText = text;
        break;
    }
    return subText;
  };

  const modalHandler = () => {
    setIsVisible(!isVisible);
    if (setHasModal) setHasModal(!isVisible);
  };

  const topicModalHandler = (topicName: string) => {
    setTopicNameState(topicName);
    setInfoIsVisible(!infoIsVisible);
    if (setHasModal) setHasModal(!infoIsVisible);
  };

  return (
    <React.Fragment>
      <React.Fragment>
        <Modal
          show={isVisible}
          size="3xl"
          className="min-h-screen"
          popup={true}
        >
          <Modal.Body className="p-5 relative">
            <div className="absolute right-0 top-0 p-2">
              <MdCancel
                size={30}
                className="cursor-pointer"
                onClick={modalHandler}
                style={{ color: "var(--main-colour)" }}
              />
            </div>
            <div className="text-center py-5 px-2">
              <div className="text-slate-700 text-left">
                {growthCircleSession?.Activity !== undefined &&
                  orgTopics[currentIndex] && (
                    <ul>
                      {LEARNING_OUTCOMES.filter(
                        (learning) =>
                          learning.key === growthCircleSession?.Activity![0]
                      ).map((learning) => (
                        <li
                          key={learning.id}
                          dangerouslySetInnerHTML={{
                            __html: `${learning.learningItems}`,
                          }}
                        ></li>
                      ))}
                    </ul>
                  )}
                {editorState && (
                  <Editor
                    toolbar={{ options: [] }}
                    readOnly={true}
                    editorState={editorState}
                    toolbarClassName="border-none"
                    wrapperClassName="border-none"
                    editorClassName="p-2 select-none"
                    onEditorStateChange={() => {}}
                  />
                )}
              </div>
            </div>
          </Modal.Body>
        </Modal>
      </React.Fragment>

      <React.Fragment>
        <Modal
          show={infoIsVisible}
          size="3xl"
          className="min-h-screen"
          popup={true}
        >
          <Modal.Body className="p-5 relative">
            <div className="absolute right-0 top-0 p-2">
              <MdCancel
                size={30}
                className="cursor-pointer"
                onClick={() => topicModalHandler("")}
                style={{ color: "var(--main-colour)" }}
              />
            </div>
            <div className="text-center py-3 px-2">
              <div className="text-slate-700 text-left">
                {topicNameState !== "" && (
                  <ul>
                    {TOPIC_INFOS.filter(
                      (topic) => topic.key === topicNameState
                    ).map((topic) => (
                      <li
                        key={topic.id}
                        dangerouslySetInnerHTML={{
                          __html: `${topic.learningItems}`,
                        }}
                      ></li>
                    ))}

                    {TOPIC_INFOS.filter((topic) => topic.key === topicNameState)
                      .length === 0 &&
                      (editorState ? (
                        <Editor
                          toolbar={{ options: [] }}
                          readOnly={true}
                          editorState={editorState}
                          toolbarClassName="border-none"
                          wrapperClassName="border-none"
                          editorClassName="p-2 select-none"
                          onEditorStateChange={() => {}}
                        />
                      ) : (
                        "No information is set."
                      ))}
                  </ul>
                )}
              </div>
            </div>
          </Modal.Body>
        </Modal>
      </React.Fragment>

      {focusActivity && focusActivity.hasInfoModal && (
        <>
          <h1 className="text-3xl font-semibold  text-center mt-5">
            {focusActivity.title}
          </h1>

          <h2 className="text-xl font-semibold  text-center my-4">
            Choose a topic/Create your own
          </h2>
          <div className="relative w-full">
            <p className="text-md mx-auto w-[250px] text-center mt-5">
              Click on the ‘i’ icon to view more information.
            </p>
            <AiFillInfoCircle
              onClick={() => {
                modalHandler();
                if (
                  focusActivity.customInstructionModal &&
                  focusActivity.customInstructionModal !== ""
                ) {
                  setContent(focusActivity.customInstructionModal);
                }
              }}
              className="absolute top-2 right-12 cursor-pointer"
              size={30}
              style={{ color: "var(--main-colour)" }}
            />
          </div>
          <EmptySpace height={"10px"} />
        </>
      )}
      {selectedOrganisation?.sessionCustomTopic !== false && (
        <form className={`${classes["form"]} mt-5`}>
          <input
            className={classes["input"]}
            placeholder={"Create your own topic..."}
            defaultValue={createdTopic}
            onChange={(e) => {
              setCreatedTopic(e.target.value);
              setCurrSelected(e.target.value);
            }}
          ></input>
        </form>
      )}
      <EmptySpace height={"30px"} />
      <div className={`${classes["carousel-container"]} overflow-x-hidden`}>
        <Carousel
          customWrapper={true}
          children={combinedArray.map((topic) => {
            if (topic) {
              const topicName = topic.category;
              return (
                <div
                  key={topicName}
                  className={`text-default p-2 text-center w-full flex justify-center items-center ${
                    topic.hasInfo ? "cursor-help" : ""
                  } `}
                >
                  <p className="w-1/2 font-semibold">
                    {getSUSStopicDisplay(topicName)}
                  </p>
                  {topic.hasInfo && (
                    <AiFillInfoCircle
                      onClick={() => {
                        topicModalHandler(topicName);
                        setContent(topic.InfoContent);
                      }}
                      size={30}
                      className="cursor-pointer z-1000"
                    />
                  )}
                </div>
              );
            } else {
              return <></>;
            }
          })}
          next={next}
          prev={prev}
          currentIndex={currentIndex}
          displayCount={"1"}
          arrowOffset={"2%"}
          width={"50%"}
          arrowColor={"var(--main-colour-2)"}
        />
      </div>
      <EmptySpace height={"30px"} />
      <Topics
        content={topicContent[currentIndex]}
        currSelected={currSelected}
        setCurrSelected={setCurrSelected}
        height={"300px"}
      />
      <EmptySpace height={"30px"} />

      {createdTopic && (
        <Topics
          content={[createdTopic]}
          currSelected={currSelected}
          setCurrSelected={setCurrSelected}
        />
      )}
      <EmptySpace height={"30px"} />

      <BackAndNext
        {...backAndNextHandler}
        nextAllowed={!!currSelected && backAndNextHandler.nextAllowed}
        nextCallback={handleNext}
      />
      <EmptySpace height={"30px"} />
    </React.Fragment>
  );
};

export default ChooseTopicComponent;
