import { SelfAwarenessToolInterface } from "interface/SelfAwarenessToolInterface";
import { IoMdInformationCircle } from "react-icons/io";
import { colours } from "../components/styles/colourMap";

interface TitleBoxProps {
  journal?: SelfAwarenessToolInterface;
  handler: () => void;
  title: string;
  showInfo: boolean;
  size?: "small" | "large";
}

const TitleBox = ({
  journal,
  handler,
  title,
  showInfo,
  size,
}: TitleBoxProps) => {
  return (
    <>
      {journal && journal.pageType && (
        <div
          className={`relative ${
            size === "small" ? "p-2" : "p-5"
          } border-2 rounded-lg font-poppins font-semibold`}
          style={{
            backgroundColor: colours[journal.pageType]["background"],
            borderColor: colours[journal.pageType]["border"],
            color: "var(--text-colour)",
          }}
        >
          {journal && journal.hasSelectionInfo && showInfo && (
            <IoMdInformationCircle
              style={{ color: "var(--icon-colour-0)" }}
              className="absolute top-0 right-0 cursor-pointer "
              size={30}
              onClick={handler}
            />
          )}
          <div dangerouslySetInnerHTML={{ __html: title }}></div>
        </div>
      )}
    </>
  );
};

export default TitleBox;
