import Header from "components/Header/Header";
import ContentWrapper from "components/utility/ContentWrapper";
import { PinGenerator } from "components/utility/UtilityFunctions";
import { useEffect, useState } from "react";
import classes from "./Admin.module.css";
import { projectFirestore, timestamp } from "../../../firebase/config";
import { Toaster, toast } from "react-hot-toast";
import QRCode from "react-qr-code";

const Generator = () => {
  const [link, setLink] = useState("");
  const [code, setCode] = useState("");
  const [gcID, setID] = useState("");
  const [isLoading, setLoading] = useState(false);
  let ref = projectFirestore.collection("GrowthCircles");

  useEffect(() => {
    setLink(`https://growthcircles-webapp.com/all-circles/welcome/${gcID}`);
  }, [code, gcID]);
  const generateGC = async () => {
    setLoading(true);
    let inviteCode = PinGenerator(4);
    let data = {
      sessionId: "Quick GC",
      name: "generated via qr-generator",
      date: timestamp.fromDate(new Date()), //returns an single array
      mentor: "GrowthBeans", //returns an single array
      venue: [
        {
          id: "text",
          text: "Event",
          value: "Event",
          year: new Date().getFullYear(),
        },
      ], //returns an single array
      status: "Available", //returns an single array
      channel: [], //returns an single array
      //  "time": "12:00pm - 2:00pm", //Alternatively, storing them in datetime format with two keys timeStart and timeEnd would be better
      number: 6,
      slots: 5,
      timeEnd: "9:30pm",
      timeStart: "7:30pm",
      invite_code: inviteCode,
      isOngoing: true,
      type: "Growthbeans",
    };
    const createdAt = timestamp.fromDate(new Date());
    const addedDocument = await ref.add({ ...data, createdAt });
    setCode(inviteCode);
    setID(addedDocument.id);
    console.log(addedDocument.id);
    setLoading(false);

    toast.dismiss();
  };
  const submitHandler = async () => {
    if (gcID !== "") {
      toast.loading(
        (t) => (
          <div>
            You are about to regenerate a new QR code, ensure that you already
            shared the QR code before generating a new one do you want to
            proceed?
            <div className={classes.toastBtnGroup}>
              <button onClick={() => toast.dismiss(t.id)}>Cancel</button>
              <button onClick={generateGC}>Proceed</button>
            </div>
          </div>
        ),
        {
          style: {
            backgroundColor: "white",
            textAlign: "center",
          },
          icon: "",
        }
      );
    } else {
      generateGC();
    }
  };

  const joinHandler = () => {
    window.open(
      `https://growthcircles-webapp.com/all-circles/welcome/${gcID}`,
      "_blank"
    );
  };

  const copyClipboard = () => {
    navigator.clipboard.writeText(
      `https://growthcircles-webapp.com/all-circles/welcome/${gcID}`
    );
    toast.success("successfully copied to clipboard");
  };
  return (
    <>
      <Header />
      <ContentWrapper hasFiveStepper={true}>
        <Toaster
          position="top-center"
          containerStyle={{
            top: "15%",
          }}
        />
        <button
          disabled={isLoading}
          className={classes.btn}
          onClick={submitHandler}
        >
          {isLoading ? "Generating QR..." : "Generate QR Code"}
        </button>
        {gcID !== "" && !isLoading && (
          <p className={classes.gcQR}>
            {/* <img src={link} alt="QR CODE" /> */}
            <QRCode value={link} />
          </p>
        )}
        {isLoading && <p className={classes.loading}>Loading...</p>}
        {gcID === "" && !isLoading && (
          <p className={classes.generate}>
            Generate QR and PIN by clicking Generate QR
          </p>
        )}
        {gcID !== "" && (
          <>
            <span>PIN</span>
            <p className={classes.code}>{code}</p>
            <small>Copy the link below if QR scanner is not available</small>
            <p
              className={classes.link}
              onClick={copyClipboard}
            >{`https://growthcircles-webapp.com/all-circles/welcome/${gcID}`}</p>
            <button className={classes.btn} onClick={joinHandler}>
              Enter GC Session
            </button>
          </>
        )}
      </ContentWrapper>
    </>
  );
};

export default Generator;

// channel
// 0
// id "status_12"
// text "Eventbrite"
// value "{"index":2}"
// createdAt July 21, 2022 at 10:10:59 AM UTC+8
// date
// 0
// id "date"
// text "2022-05-25 19:30"
// value "{"date":"2022-05-25","time":"11:30:00"}"
// day 25
// invite_code "3495"
// isOngoing true
// mentor
// 0
// id "owner"
// text ""
// value null
// month 5
// name "Growth Circle 25 May 22"
// number 6
// person "Shaman"
// sessionId "Growth Circle 25 May 22"
// slots 5
// (number)
// status "Available"
// timeEnd "9:30pm"
// timeStart "7:30pm"
// type "Zoom"
// venue
// 0
// id "text"
// text "Zoom"
// value ""Zoom""
// year 2022
