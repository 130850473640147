import { Tooltip } from "flowbite-react";
import { AiFillQuestionCircle } from "react-icons/ai";

const ValueFinalCard = ({
  title,
  value,
  index,
}: {
  title: string;
  value: number;
  index: number;
}) => {
  return (
    <div
      className="py-4 px-2 text-white min-w-[150px] font-poppins text-center font-semibold rounded-lg"
      style={{ backgroundColor: "#EB8181" }}
    >
      <div className="flex gap-2 items-center justify-center">
        <p className="truncate max-w-[50px]">{title}</p>
        <Tooltip content={title} placement="top">
          <AiFillQuestionCircle className="cursor-pointer" size={20} />
        </Tooltip>
      </div>
      <p className="my-2 text-2xl">{value} / 10</p>
    </div>
  );
};

export default ValueFinalCard;
