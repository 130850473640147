const LoginWelcomeMessage = () => {
  return (
    <div className="text-main-colour flex flex-col w-full justify-center items-center">
      <span className={`text-3xl text-standard`}>Welcome to</span>
      {"\n"}
      <div className="flex text-xl font-semibold text-standard">
        <span className="italic px-1">Growth</span>
        <span>Circles</span>
      </div>
    </div>
  );
};

export default LoginWelcomeMessage;
