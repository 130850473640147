import { where } from "firebase/firestore";
import { getAllOrganisations } from "models/organisation";
import { getModelOperationsWithPath } from "utility/model";

const FIRESTORE_PATH_DASHBOARD_STATS = "DashboardStats";

// Organisation with special settings
export const NTU_CAREER_AND_ATTACHMENT_OFFICE =
  "NTU Career & Attachment Office";

type dashboardStatsDescriptionProps = {
  [key: string]: string;
};

export const D_LEVEL_OF_CLARITY = "levelOfClarity";
export const D_LEVEL_OF_CLARITY_CUSTOM = "levelOfClarityCustom";
export const D_TOP_5_TOPICS = "top5Topics";
export const D_NUMBER_OF_ACCOUNTS=
  "numberOfAccounts";
export const D_NUMBER_OF_TIMES_MENTORS_MET_MENTEES =
  "numberOfTimesMentorsMetMentees";
export const D_NUMBER_OF_TIMES_FACILITATORS_MET_PARTICIPANTS =
  "numberOfTimesFacilitatorsMetParticipants";
export const D_STEP_5_REFLECTION_QUANTITATIVE =
  "reflectionQuantitative";
export const D_STEP_5_REFLECTION_QUALITATIVE =
  "reflectionQualitative";
export const D_INTENTIONS_AND_TAKEWAYS = 
  "intentionsAndTakeways";

export const dashboardStatsName: dashboardStatsDescriptionProps = {
  [D_LEVEL_OF_CLARITY]:
    "Level of Clarity in 4 Areas: Pre and Post",
  [D_LEVEL_OF_CLARITY_CUSTOM]: "Level of Clarity in Other Areas: Pre and Post",
  [D_TOP_5_TOPICS]:
    "Top 5 Topics Discussed in Defined Period",
  [D_NUMBER_OF_ACCOUNTS]:
    "Number of Accounts",
  [D_NUMBER_OF_TIMES_MENTORS_MET_MENTEES]:
    "Number of Times Mentors Met Mentees",
  [D_NUMBER_OF_TIMES_FACILITATORS_MET_PARTICIPANTS]:
    "Number of Times Facilitators Met Participants",
  [D_STEP_5_REFLECTION_QUALITATIVE]:
    "Step 5 Reflection (Qualitative Feedback)",
  [D_INTENTIONS_AND_TAKEWAYS]:
    "Intentions and Takeways",
};

// Descriptions are currently the same as the names.
export const dashboardStatsDescriptions: dashboardStatsDescriptionProps = {
  [D_LEVEL_OF_CLARITY]:
    "Bar graph showing level of clarity in the 4 areas (individually, socially, interpersonally and overall) based on check in and check out.",
  [D_LEVEL_OF_CLARITY_CUSTOM]: "Bar graph showing level of clarity in other areas set by the organsation based on check in and check out.",
  [D_TOP_5_TOPICS]:
    "Top 5 most discussed topics and their count during Groth Circle sessions.",
  [D_NUMBER_OF_ACCOUNTS]:
    "Total number of Mentors & Mentees in the organisation",
  [D_NUMBER_OF_TIMES_MENTORS_MET_MENTEES]:
    "Bar graph showing each of the mentors and the number of times they met with their mentees.",
  [D_NUMBER_OF_TIMES_FACILITATORS_MET_PARTICIPANTS]:
    "Bar graph showing each of the facilitators and the number of times they met with participants.",
  [D_STEP_5_REFLECTION_QUALITATIVE]:
    "Shows all the written reflection done in step 5",
  [D_INTENTIONS_AND_TAKEWAYS]:
    "Shows the intentions and takeways of the participants",
};

export const dashboardStatsCustomNameGenerator = (name: string, arg: string) : string => {
  if (name === D_STEP_5_REFLECTION_QUANTITATIVE) {
    return `Step 5 Reflection (${arg})`;
  }
  return "";
}

export const dashboardStatsCustomDescriptionGenerator = (name: string, arg: string) : string => {
  if (name === D_STEP_5_REFLECTION_QUANTITATIVE) {
    return `Bar graph showing the quantitative feedback (average ratings) for Step 5 Reflection (${arg}).`;
  }
  return "";
}

export type StatisticsName = keyof typeof dashboardStatsDescriptions;

/**
 * Dashboard elements indicates the presence of a certain statistic
 * or data on the dashboard.
 */

type Statistic = {[p in StatisticsName]: boolean | string | number};

export type Statistics = { [p in StatisticsName]: Statistic };

export interface DashboardStats {
  organisationId: string;
  statistics: Statistics;
}

export const defaultDashboardStat = (id: string, name: string, description: string, isShown: boolean, order: number) => {
  return {
  "id": id,
  "name": name,
  "description": description,
  "isShown": isShown,
  "order": order
  }
}

export const defaultDashboardStats: Statistics = {
  [D_LEVEL_OF_CLARITY]: defaultDashboardStat(D_LEVEL_OF_CLARITY, dashboardStatsName[D_LEVEL_OF_CLARITY], dashboardStatsDescriptions[D_LEVEL_OF_CLARITY],true, 1),
  [D_LEVEL_OF_CLARITY_CUSTOM]: defaultDashboardStat(D_LEVEL_OF_CLARITY_CUSTOM, dashboardStatsName[D_LEVEL_OF_CLARITY_CUSTOM], dashboardStatsDescriptions[D_LEVEL_OF_CLARITY_CUSTOM], true, 2),
  [D_TOP_5_TOPICS]: defaultDashboardStat(D_TOP_5_TOPICS, dashboardStatsName[D_TOP_5_TOPICS], dashboardStatsDescriptions[D_TOP_5_TOPICS], true, 3),
  [D_NUMBER_OF_ACCOUNTS]: defaultDashboardStat(D_NUMBER_OF_ACCOUNTS, dashboardStatsName[D_NUMBER_OF_ACCOUNTS], dashboardStatsDescriptions[D_NUMBER_OF_ACCOUNTS], true, 4),
  [D_NUMBER_OF_TIMES_MENTORS_MET_MENTEES]: defaultDashboardStat(D_NUMBER_OF_TIMES_MENTORS_MET_MENTEES, dashboardStatsName[D_NUMBER_OF_TIMES_MENTORS_MET_MENTEES], dashboardStatsDescriptions[D_NUMBER_OF_TIMES_MENTORS_MET_MENTEES], true, 5),
  [D_NUMBER_OF_TIMES_FACILITATORS_MET_PARTICIPANTS]: defaultDashboardStat(D_NUMBER_OF_TIMES_FACILITATORS_MET_PARTICIPANTS, dashboardStatsName[D_NUMBER_OF_TIMES_FACILITATORS_MET_PARTICIPANTS], dashboardStatsDescriptions[D_NUMBER_OF_TIMES_FACILITATORS_MET_PARTICIPANTS], true, 6),
  [D_STEP_5_REFLECTION_QUALITATIVE]: defaultDashboardStat(D_STEP_5_REFLECTION_QUALITATIVE, dashboardStatsName[D_STEP_5_REFLECTION_QUALITATIVE], dashboardStatsDescriptions[D_STEP_5_REFLECTION_QUALITATIVE], true, 7),
  [D_INTENTIONS_AND_TAKEWAYS]: defaultDashboardStat(D_INTENTIONS_AND_TAKEWAYS, dashboardStatsName[D_INTENTIONS_AND_TAKEWAYS], dashboardStatsDescriptions[D_INTENTIONS_AND_TAKEWAYS], true, 8),
};

export const defaultDashboardStatsModel: DashboardStats = {
  organisationId: "",
  statistics: { ...defaultDashboardStats },
};

// --- DB Helper Functions ---

const ops = getModelOperationsWithPath(
  FIRESTORE_PATH_DASHBOARD_STATS,
  defaultDashboardStatsModel
);

const _getDashboardStatsWhere = ops.getModelWhere;

const _setDashboardStats = ops.setModel;

// --- End DB Helper functions ---

/**
 * Gets the dashboard statistics for a specific organisation.
 *
 * @param organisationId The organisation ID to get the dashboard statistics for.
 * @returns The dashboard statistics for the organisation.
 */
export function getDashboardStatsByOrganisationId(organisationId: string) {
  return _getDashboardStatsWhere(where("organisationId", "==", organisationId));
}

/**
 * Sets the dashboard statistics for a specific organisation if given,
 * otherwise sets the default dashboard statistics. Used for both adding
 * and updating.
 *
 * @param organisationId The organisation ID to set the dashboard statistics for.
 * @param dashboardStats The dashboard statistics to set.
 * @return A promise to completion of operation.
 */
export function setDashboardStats(
  organisationId: string,
  dashboardStats?: DashboardStats
) {
  if (!dashboardStats) {
    const dashboardStatsToSet = {
      ...defaultDashboardStatsModel,
      organisationId,
    };

    return _setDashboardStats(organisationId, dashboardStatsToSet);
  }

  return _setDashboardStats(organisationId, dashboardStats);
}

/**
 * Mass sets the default dashboard statistics for all organisations.
 */
export async function setDefaultDashboardStatsForAllOrganisations() {
  const organisations = await getAllOrganisations();

  for (const org of organisations) {
    setDashboardStats(org.id);
  }
}

// --- utility helper functions ---

export function checkHasStatistics(
  statistics: Statistics,
  stat: StatisticsName
) {
  return statistics[stat] ? statistics[stat]["isShown"] : false;
}

export function getStatisticTitle(
  statistics: Statistics,
  stat: StatisticsName
) : string {
  return statistics[stat]["name"].toString();
}

export function getStatisticTitleSplit(
  statistics: Statistics,
  stat: StatisticsName,
  ...splits: number[]
): string[] {
  const title = getStatisticTitle(statistics, stat);
  const sortedSplits = splits.filter(split => split > 0 && split < title.length).sort((a, b) => a - b);

  let previousIndex = 0;
  const result: string[] = [];

  for (const split of sortedSplits) {
    result.push(title.substring(previousIndex, split));
    previousIndex = split;
  }
  result.push(title.substring(previousIndex));

  return result;
}

export function getStatisticDescription(
  statistics: Statistics,
  stat: StatisticsName
) {
  return statistics[stat]["description"];
}

