import ProgressBar from "components/ChooseRoles/ProgressBar/ProgressBar";
import { renderBadgeIcon } from "components/ReflectionRating/Badges";
import { useAuthContext } from "hooks/useAuthContext";
import {
  BadgeStates,
  BadgeType,
  FeedbackForm,
} from "interface/FeedbackFormInterface";
import Role from "interface/RoleInterface";
import React, { Dispatch, useEffect } from "react";
import classes from "./FeedbackWaitingArea.module.css";
import RolesIconMap from "assets/Roles/RolesIconMap";
import { useGrowthCircleContext } from "hooks/useGrowthCircleContext";
import { useGCSession } from "hooks/useGCSession";
import { useFirestore } from "hooks/useFirestore";

interface AfterWaitingAreaProps {
  userRole: Role;
  userBadgeStates: BadgeStates | undefined;
  feedbackForms: FeedbackForm[];
  setShowNextHandler: Dispatch<React.SetStateAction<boolean>>;
}

/**
 * Component for the AfterWaitingArea (where the progress of the role, badges earned and level is shown)
 * @param {Role} userRole - role of the user
 * @param {BadgeStates | undefined} userBadgeStates - amount of badges earned for each type (Role, Care and Support and Effort)
 * @param {FeedbackForm[]} feedbackForms - all feedback forms collected from the GC
 * @param {Dispatch<React.SetStateAction<boolean>>} setShowNextHandler - navigate user to the next section of EndOfSession
 * }
 */
const AfterWaitingArea = ({
  userRole,
  userBadgeStates,
  feedbackForms,
  setShowNextHandler,
}: AfterWaitingAreaProps) => {
  const { profile } = useAuthContext();
  const { growthCircleSession } = useGrowthCircleContext();
  const { endGCSession } = useGCSession();
  const { progressPercentage, level } = profile!.progress[userRole.role];
  const { updateDocument } = useFirestore("users");
  //TODO : Check if what can be done to auto refresh page
  useEffect(() => {
    if (!profile) return;
    const data = { pageStep: window.location.pathname };
    updateDocument(profile.uid, data);
    console.info("Reached after waiting area");
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [profile]);
  useEffect(() => {
    if (
      growthCircleSession?.isOngoing ||
      (growthCircleSession && growthCircleSession.isOngoing === undefined)
    ) {
      endGCSession();
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [growthCircleSession, profile]);

  //Gets badges where it is >= half of the feedback form count (To add to progress)
  const goodBadges =
    userBadgeStates !== undefined
      ? Object.keys(userBadgeStates).filter(
          (badgeState) =>
            userBadgeStates &&
            userBadgeStates[badgeState] >= Math.floor(feedbackForms.length / 2)
        )
      : undefined;

  //Dynamically generates the text to display based on the number of good badges
  const completeText = goodBadges?.map((badgeState, index) => {
    let badgeString: string = badgeState.replace(/[A-Z]/g, " $&").trim();
    return (
      <React.Fragment key={badgeState}>
        {index === goodBadges.length - 1 && goodBadges.length > 1
          ? " and "
          : " "}
        <b key={index}>{badgeString}</b>
        {index === goodBadges.length - 1
          ? "!"
          : index === goodBadges.length - 2
          ? ""
          : ","}
      </React.Fragment>
    );
  });

  //Gets the Icon for the core_skills
  const CoreSkillIcon = RolesIconMap[userRole["core_skills"] as keyof object];

  return (
    <div className={classes["after-waiting-area"]}>
      {/* Badges won */}
      {goodBadges && goodBadges.length > 0 && (
        <>
          <div className={classes["good-badges"]}>
            {goodBadges.map((badgeState) => {
              return renderBadgeIcon(
                "var(--main-colour)",
                badgeState as BadgeType,
                "45px",
                "45px"
              );
            })}
          </div>
          <p className="py-4">Your peers value you for your {completeText}</p>
        </>
      )}

      {/* Progress */}
      <div className={classes["progress-bar"]}>
        <CoreSkillIcon fill={"var(--main-colour-2)"} />
        <div className={classes["role-and-progress-container"]}>
          <div className="mb-2">
            <p>{userRole.role}</p>
            <p>Level: {level}</p>
          </div>
          <ProgressBar
            finishAnimationHandler={() => setShowNextHandler(true)}
            hasAnimation={true}
            modalOnClick={false}
            progress={progressPercentage}
            borderTopRightRadius={"25px"}
            borderTopLeftRadius={"25px"}
            borderBottomRightRadius={"25px"}
            borderBottomLeftRadius={"25px"}
          />
        </div>
      </div>
    </div>
  );
};

export default AfterWaitingArea;
