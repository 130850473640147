import { ORSInterface } from "interface/ORSInterface";
import { Participant } from "interface/ParticipantInterface";
import React from "react";

interface ORSProps {
  ORSData: Participant;
  ORSLayout: ORSInterface[];
}

function ExportORSDisplay({ ORSData, ORSLayout }: ORSProps) {
  return (
    <>
      {ORSLayout &&
        ORSLayout.map((layoutItem, index) => {
          const formattedTitle = layoutItem.title.replace(/ /g, "_");
          return (
            <React.Fragment key={index}>
              <td className="px-6 py-2 border border-gray-500">
                {ORSData.orgCheckIn && ORSData.orgCheckIn[formattedTitle]}
              </td>
              <td className="px-6 py-2 border border-gray-500">
                {ORSData.orgCheckOut && ORSData.orgCheckOut[formattedTitle]}
              </td>
            </React.Fragment>
          );
        })}
    </>
  );
}

export default ExportORSDisplay;
