import { getProperNameComponent } from "pages/AllCircles/GenericSessionPage/ComponentMapping";
import { AiFillCloseCircle, AiFillInfoCircle } from "react-icons/ai";
import { MdOutlineArrowDropDown, MdOutlineArrowDropUp } from "react-icons/md";

interface OrderCardProps {
  handleInfo: (component: string) => void;
  component: string;
  info: string;
  index: number;
  handleDelete: (component: string) => void;
  isSorting: boolean;
  handleMoveUp: (index: number) => void;
  handleMoveDown: (index: number) => void;
  isMoving: boolean | null;
  onMoveComplete: () => void;
}
const ComponentOrderCard = ({
  handleInfo,
  component,
  info,
  index,
  handleDelete,
  isSorting,
  handleMoveDown,
  handleMoveUp,
  isMoving,
  onMoveComplete,
}: OrderCardProps) => {
  return (
    <div
      style={{
        borderColor: "var(--main-colour)",
        backgroundColor: isMoving
          ? "var(--main-colour)"
          : component === info
          ? "var(--main-colour)"
          : "white",
      }}
      className={`border-2 my-4 grid grid-cols-4 relative items-center w-full  ${
        isMoving
          ? "text-default"
          : component === info
          ? "text-default"
          : "bg-white"
      } rounded-xl shadow-sm`}
    >
      <h1 className="justify-self-start col-span-3 text-lg p-4">{`${
        index + 1
      }. ${getProperNameComponent(component)}`}</h1>
      {!isSorting && (
        <div className="flex gap-2 justify-self-end  px-4">
          <div
            onClick={() => {
              handleInfo(component);
            }}
            className="flex items-center justify-center w-10 justify-self-end text-lg"
          >
            <AiFillInfoCircle
              size={30}
              style={{
                color: component === info ? "white" : "var(--main-colour)",
              }}
              className=" cursor-pointer"
            />
          </div>
          <div
            onClick={() => {
              handleDelete(component);
            }}
            className="flex items-center justify-center w-10 justify-self-end text-lg"
          >
            <AiFillCloseCircle
              size={30}
              style={{
                color: component === info ? "white" : "var(--main-colour)",
              }}
              className=" cursor-pointer"
            />
          </div>
        </div>
      )}
      {isSorting && (
        <div className="flex justify-self-end">
          <MdOutlineArrowDropUp
            size={50}
            style={{
              color: component === info ? "white" : "var(--main-colour)",
            }}
            className={`cursor-pointer ${isMoving ? "text-white" : ""}`}
            onClick={() => {
              handleMoveUp(index);
              onMoveComplete(); // Call onMoveComplete after the move is initiated
            }}
          />
          <MdOutlineArrowDropDown
            size={50}
            style={{
              color: component === info ? "white" : "var(--main-colour)",
            }}
            className={`cursor-pointer ${isMoving ? "text-white" : ""}`}
            onClick={() => {
              handleMoveDown(index);
              onMoveComplete(); // Call onMoveComplete after the move is initiated
            }}
          />
        </div>
      )}
    </div>
  );
};

export default ComponentOrderCard;
