import { ReactNode } from "react";

interface DashboardCardProps {
  titles: string[];
  children: ReactNode;
  className?: string;
}

const DashboardCard = ({
  children,
  titles,
  className,
}: DashboardCardProps) => {
  return (
    <div className={`text-slate-700 flex flex-col flex-grow`}>
      <div className="text-xl font-semibold px-4">{titles.join("\n")}</div>
      <div
        className={`bg-white w-full md:min-h-[500px] md:min-w-[500px] rounded-xl my-4 flex-grow flex flex-col ${className}`}
      >
        {children}
      </div>
    </div>
  );
};

export default DashboardCard;
