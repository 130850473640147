import { useEmailAPI } from "hooks/useEmailAPI";
import { useFirestore } from "hooks/useFirestore";
import { useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
import { useAuthContext } from "../../hooks/useAuthContext";
import classes from "./ManageJournalContainer.module.css";

/**
 * Component after user submits the journal checklist
 */
const ManageJournalContainer = ({ participant }) => {
  const { profile } = useAuthContext();
  const navigate = useNavigate();
  const { sendEmail } = useEmailAPI();
  const { updateDocument } = useFirestore("users");
  const [isProfileLoaded, setIsProfileLoaded] = useState(false);

  useEffect(() => {
    if (profile && !isProfileLoaded) {
      setIsProfileLoaded(true);
    }
  }, [profile, isProfileLoaded]);

  useEffect(() => {
    if (!isProfileLoaded) return;

    if (!profile?.isEmailSent || profile?.isEmailSent === null) {
      sendEmail(profile!.email, profile!.displayName, participant.id);
      updateDocument(profile?.uid, { isEmailSent: true });
    }

    //TODO : must remove user from participants

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [profile, isProfileLoaded]);

  const viewHandler = async () => {
    //TODO: Link to email API before calling navigate
    if (!profile) return;
    await updateDocument(profile.uid, { pageStep: "" });
    navigate(`/all-circles/view-journal/${participant.id}`);
  };
  return (
    <>
      <p className={`${classes["prompt"]} py-4 leading-6`}>
        Thank you for your participation in the Growth Circles. You can view
        your journal by clicking the view button or clicking on the link that
        has been sent to your email.
      </p>
      <div className={classes["optionButtons"]}>
        <button
          onClick={viewHandler}
          className={`${classes["optionButton"]}`}
          type="button"
        >
          View
        </button>
        {/* <button
        className={classes['optionButton']}
        type='button'
        >
        Edit
      </button> */}
      </div>
    </>
  );
};

export default ManageJournalContainer;
