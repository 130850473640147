import FiveStepHeader from "components/FiveStepHeader/FiveStepHeader";
import Header from "components/Header/Header";
import { ReactComponent as TriggerImage } from "assets/Trigger.svg";
import { useState } from "react";
import classes from "./Trigger.module.css";
import PageWrapper from "components/utility/PageWrapper";
import TriggerModal from "./TriggerModal";

export default function Trigger() {
  const [showTriggerModal, setShowTriggerModal] = useState(false);

  const toggleInfoModal = () => {
    setShowTriggerModal((prev) => !prev);
  };

  const hideTermsHandler = () => {
    setShowTriggerModal(false);
  };
  return (
    <>
      <PageWrapper>
        {showTriggerModal && <TriggerModal onClick={hideTermsHandler} />}
        <div id="terms-modal" />
        <Header />

        <FiveStepHeader
          hasLightning={true}
          text={"Trigger"}
          toggleInfoModal={toggleInfoModal}
        />

        <div className={classes["content-container"]}>
          <div className={classes["sos"]} onClick={toggleInfoModal}>
            <h3>SOS</h3>
          </div>
          <div className={classes["trigger-container"]}>
            <TriggerImage />

            <div className={classes["resources-container"]}>
              <div className={classes["items"]}>
                <p>Lorem ipsum.</p>
                <p>Lorem ipsum.</p>
                <p>Lorem ipsum.</p>
                <p>Lorem ipsum.</p>
                <p>Lorem ipsum.</p>
                <p>Lorem ipsum.</p>
              </div>
              <p className={classes["description"]}>
                The “Resources” page provides...
              </p>
              <button className={classes["resourcesButton"]}>Resources</button>
            </div>
          </div>
        </div>
      </PageWrapper>
    </>
  );
}
