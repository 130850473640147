import { useSelfAwarenessContext } from "hooks/useSelfAwarenessContext";
import DropDownItem from "./DropDownItem";
import { useEffect } from "react";
import { SelectionData } from "../Data/SelectionData";
interface DropDownSelectorProps {
  selectedItems: string[];
  setSelectedItem: React.Dispatch<React.SetStateAction<string[]>>;
}
const DropDownSelector = ({
  selectedItems,
  setSelectedItem,
}: DropDownSelectorProps) => {
  const { journal } = useSelfAwarenessContext();

  useEffect(() => {
    if (!journal) return;
  }, [journal]);

  return (
    <div
      className="border-2 p-5 my-8 bg-white rounded-lg font-poppins"
      style={{
        borderColor: "#FFE871",
        color: "var(--text-colour)",
      }}
    >
      {journal &&
        journal.selectionMapData &&
        SelectionData[journal.selectionMapData].map((item, index) => (
          <DropDownItem
            selectedItems={selectedItems}
            setSelectedItem={setSelectedItem}
            subItem={item.subItems}
            title={item.title}
            key={index}
          />
        ))}
    </div>
  );
};

export default DropDownSelector;
