import { FeedbackForm, isFeedbackForm } from "interface/FeedbackFormInterface";
import { useCallback, useEffect, useState } from "react";
import { useAuthContext } from "./useAuthContext";
import { useCollection } from "./useCollection";
import useGetParticipants from "./useGetParticipants";

interface useGetFeedbackFormProps {
  growthCircleId?: string;
}

/**
 * Gets feedbackforms based on profile's growthCircleId in real-time
 * @param growthCircleId?: string - growthCircle id to query from
 */
const useGetFeedbackForm = ({ growthCircleId }: useGetFeedbackFormProps) => {
  const { profile } = useAuthContext();
  const growthCircle = growthCircleId ? growthCircleId : profile!.growthCircle;
  const { documents } = useCollection("FeedBackForm", [
    "growthCircle",
    "==",
    growthCircle,
  ]);
  const { participants } = useGetParticipants();

  const [feedbackForms, setFeedbackForms] = useState<FeedbackForm[] | null>(
    null
  );
  const [userFeedbackForm, setUserFeedbackForm] = useState<FeedbackForm | null>(
    null
  );

  /**
   * Sets feedbackForms and userFeedbackForm when they are successfully queried from the database
   */
  useEffect(() => {
    //console.log(documents);
    if (documents.length > 0) {
      const feedbackForms: FeedbackForm[] = documents.filter((doc) =>
        isFeedbackForm(doc)
      );
      setFeedbackForms(feedbackForms);

      const userFeedbackForm: FeedbackForm | undefined = documents.find(
        (doc) => isFeedbackForm(doc) && doc.userId === profile!.id
      );
      if (userFeedbackForm !== undefined) setUserFeedbackForm(userFeedbackForm);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [documents]);

  /**
   * Helper method to check if feedback form is submitted based on the three different forms to fill up
   * @param feedback: FeedbackForm - feedback form to check
   */
  const feedbackSubmitted = (feedback: FeedbackForm): boolean => {
    return (
      feedback.refPersonalSmileyRatings !== undefined &&
      feedback.refOpenEndedFields !== undefined &&
      feedback.refGcSmileyRatings !== undefined
    );
  };

  /**
   * Checks if all feedbackforms from the real-time database are all filled up and returns true if they are
   */
  const allFeedbackFormsSubmitted = (): boolean => {
    const filledFeedbackForms: FeedbackForm[] | undefined =
      submittedFeedbackForms();
    return (
      filledFeedbackForms !== undefined &&
      filledFeedbackForms.length === participants.length
    );
  };

  /**
   * Helper method to filter through current feedback forms and return the ones that are submitted as output
   */
  const submittedFeedbackForms = (): FeedbackForm[] | undefined => {
    return feedbackForms?.filter((feedback) => feedbackSubmitted(feedback));
  };

  /**
   * Callback hook to reduce repeated computation upon re-render (if feedbackform does not change in database)
   */
  // eslint-disable-next-line react-hooks/exhaustive-deps
  const allFeedbackSubmittedCallback = useCallback(allFeedbackFormsSubmitted, [
    feedbackForms,
  ]);

  /**
   * Callback hook to reduce repeated computation upon re-render (if feedbackform does not change in database)
   */
  const submittedFeedbackFormsCallBack = useCallback(submittedFeedbackForms, [
    feedbackForms,
  ]);

  return {
    userFeedbackForm,
    feedbackForms,
    allFeedbackSubmittedCallback,
    submittedFeedbackFormsCallBack,
  };
};

export default useGetFeedbackForm;
