import BackAndNext from "components/utility/BackAndNext";
import EmptySpace from "components/utility/EmptySpace";
import useParticipants from "hooks/useParticipants";
import { useEffect, useState } from "react";
import TotalScore from "../RatingAverage/TotalScore";
import { checkHasPermission } from "models/organisationRole";
import { P_SEE_AVERAGE_RATING } from "models/permission";
import CheckOutFacilScreen from "components/CheckOutFacilScreen/CheckOutFacilScreen";
import { Participant } from "interface/ParticipantInterface";
import { ComponentProps } from "../GenericSessionPage/ComponentMapping";
import React from "react";
import useOrganisationContext from "hooks/organisation/useOrganisationContext";
import { useAuthContext } from "hooks/useAuthContext";
import useOrganisationRoleContext from "hooks/organisation/useOrganisationRoleContext";

const CheckInCheckOutAverageComponent = ({
  backAndNextHandler,
  setHasModal,
}: ComponentProps) => {
  const { ParticipantRecords, ParticipantRecord } = useParticipants();
  const [countAllSubmitted, setCountAllSubmitted] = useState(0);
  const [isParticipantsModal, setIsParticipantsModal] = useState(false);
  const [isParticipantModal, setIsParticipantModal] = useState(false);
  const [self, setSelf] = useState<Participant | undefined>(undefined);
  const { selectedOrganisation } = useOrganisationContext();
  const { profile } = useAuthContext();
  const { roles } = useOrganisationRoleContext();
  const [hasPermission, setHasPermission] = useState(false);
  useEffect(() => {
    if (isParticipantModal || isParticipantsModal) {
      setHasModal(isParticipantModal || isParticipantsModal);
      document.body.style.overflowY = "hidden";
    } else {
      setHasModal(false);
      document.body.style.overflowY = "unset";
    }
  }, [isParticipantModal, isParticipantsModal, setHasModal]);

  // Function to count participants with checkOut records
  const countParticipantsCheckedOut = (
    participantRecords: Participant[]
  ): number => {
    return participantRecords.filter(
      (record) =>
        record.checkOut.individually >= 0 && record.checkOut !== undefined
    ).length;
  };

  useEffect(() => {
    if (ParticipantRecord == null) return;
    setSelf(ParticipantRecord[0]);
  }, [ParticipantRecord]);

  useEffect(() => {
    if (ParticipantRecords.length === 0) return;
    const numberOfCheckedOutParticipants =
      countParticipantsCheckedOut(ParticipantRecords);
    setCountAllSubmitted(numberOfCheckedOutParticipants);
  }, [ParticipantRecords]);

  useEffect(() => {
    if (!selectedOrganisation) return;
  }, [selectedOrganisation]);

  useEffect(() => {
    if (!profile) return;

    const _role = roles.filter(
      (role) => role.name === selectedOrganisation?.users[profile.uid]
    );

    setHasPermission(checkHasPermission(_role[0], P_SEE_AVERAGE_RATING));
    // eslint-disable-next-line
  }, [profile, roles]);

  useEffect(() => {
    // eslint-disable-next-line
  }, [hasPermission]);

  return (
    <React.Fragment>
      <EmptySpace height="5px" />
      {ParticipantRecords.length > 0 && (
        <div className="py-4 border-b border-main-colour w-2/3 mx-auto">
          <p className="text-sm font-semibold text-center">
            {selectedOrganisation?.name === "NTU Career & Attachment Office"
              ? "Check out how everyone’s progress!"
              : "Check out how everyone’s feeling!"}
          </p>
          <p className="text-center py-2 font-semibold   text-slate-600">
            {countAllSubmitted} out of {ParticipantRecords.length} submitted
          </p>
        </div>
      )}
      {self && self.userId && (
        <CheckOutFacilScreen
          organisation={selectedOrganisation || undefined}
          participants={ParticipantRecords}
          self={self}
          hasPermission={hasPermission}
          setModalState={setIsParticipantsModal}
        />
      )}

      {self && self.userId && hasPermission ? (
        <TotalScore
          organisation={selectedOrganisation || undefined}
          participants={ParticipantRecords}
          setIsModal={setIsParticipantModal}
        />
      ) : (
        ""
      )}
      <EmptySpace height={"40px"} />
      <BackAndNext {...backAndNextHandler} />
    </React.Fragment>
  );
};

export default CheckInCheckOutAverageComponent;
