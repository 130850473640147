import {
  BarChart,
  CartesianGrid,
  XAxis,
  YAxis,
  Tooltip,
  Bar,
  Cell,
  ResponsiveContainer,
} from "recharts";

interface BarChartComponentProps {
  data: Array<any>;
}

const VerticalBarChartComponent = ({ data }: BarChartComponentProps) => {
  return (
    <div>
      <ResponsiveContainer minWidth={500} width="95%" height={360}>
        <BarChart
          layout="vertical"
          data={data}
          margin={{
            top: 30,
            right: 15,
            bottom: 20,
            left: 80,
          }}
        >
          <CartesianGrid stroke="#f5f5f5" />
          <XAxis axisLine={{ stroke: "#EB8181" }} type="number" />
          <YAxis
            axisLine={{ stroke: "#EB8181" }}
            dataKey="name"
            type="category"
          />
          <Tooltip />

          <Bar dataKey="value" stackId="a">
            {data.map((entry, index) => (
              <Cell key={`cell-${index}`} fill={entry.color1} />
            ))}
          </Bar>
        </BarChart>
      </ResponsiveContainer>
    </div>
  );
};

export default VerticalBarChartComponent;
