import { useCallback, useState } from "react";
import { projectFirestore } from "../firebase/config";
import { getDocs, updateDoc } from "firebase/firestore";
import firebase from "firebase/compat/app";

const UpdateDisplayNameLowerCase = () => {
  const onClickHandler = useCallback(() => {
    setStatus("Updating");
    let ref = projectFirestore.collection("users");
    let query: firebase.firestore.Query<firebase.firestore.DocumentData> = ref;
    getDocs(query).then((snapshot) => {
      snapshot.docs.map((doc) => {
        if (doc.get("displayName") === undefined) {
          return updateDoc(doc.ref, {
            displayName: "",
            displayNameLowerCase: "",
          });
        }
        return updateDoc(doc.ref, {
          displayNameLowerCase: doc.get("displayName").toLowerCase(),
        });
      });
    });
    setStatus("Update done");
  }, []);
  const [status, setStatus] = useState("Ready");
  return (
    <div className="flex flex-col items-center justify-center h-screen gap-4">
      <h1>Status: {status}</h1>
      {status === "Ready" && (
        <button
          className="bg-white border-r shadow-md p-2 rounded-md"
          onClick={onClickHandler}
        >
          Update fields
        </button>
      )}
    </div>
  );
};

export default UpdateDisplayNameLowerCase;
