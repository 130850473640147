import { Link } from "react-router-dom";
import { useEffect, useState } from "react";

import Logo from "../utility/Logo";
import { ReactComponent as HamburgerMenu } from "../../assets/HamburgerMenu.svg";
import SidebarModal from "../Sidebar/SidebarModal";
import Cookies from "js-cookie";
import classes from "./Header.module.css";
import adminClasses from "pages/AllCircles/Admin/Admin.module.css";
import useGetParticipants from "hooks/useGetParticipants";
import { useAuthContext } from "hooks/useAuthContext";
import { useLogout } from "hooks/useLogout";
import { FaSignOutAlt } from "react-icons/fa";
import { Modal } from "flowbite-react";

interface HeaderProps {
  hasModal: boolean;
  hasSidebar?: boolean;
  title?: string;
}

const Header = ({ hasModal, hasSidebar = true, title }: HeaderProps) => {
  const [sidebarActive, setSidebarActive] = useState(false);
  const [haveModal, setHasModal] = useState(false);
  const [show, setShow] = useState(false);
  const [message, setMessage] = useState("");
  const { triggeredParticipants } = useGetParticipants();
  const { profile } = useAuthContext();
  const { logout } = useLogout();
  const hamburgerMenuHandler = () => {
    setSidebarActive((prev) => !prev);
  };

  useEffect(() => {
    if (!profile?.isFacil) return;
    if (triggeredParticipants.length === 0) {
      setHasModal(false);
    } else {
      setHasModal(true);
    }

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [profile?.isFacil, triggeredParticipants]);

  useEffect(() => {
    if (sidebarActive) {
      document.body.style.overflow = "hidden";
    } else {
      document.body.style.overflow = "unset";
    }
  });

  const callBack = () => {
    if (!haveModal) {
      setHasModal(true);
    } else {
      setHasModal(false);
    }
  };

  useEffect(() => {
    const userAgent = navigator.userAgent.toLowerCase();
    const isSamsungBrowser =
      userAgent.includes("samsung") || userAgent.includes("samsungbrowser");
    const isChrome =
      userAgent.includes("chrome") &&
      !userAgent.includes("edg") &&
      !isSamsungBrowser; // Updated line
    const isFirefox = userAgent.includes("firefox");

    // Function to get the browser name from the userAgent
    function getBrowserName() {
      if (isSamsungBrowser) return "Samsung Internet";
      if (isChrome) return "Google Chrome";
      if (isFirefox) return "Firefox";
      if (userAgent.includes("safari") && !userAgent.includes("chrome"))
        return "Safari";
      return "Unknown Browser";
    }

    const browserName = getBrowserName();

    // Check for the cookie
    const hasAcceptedModal = Cookies.get("compatibilityAccept");

    // Show the modal if the user is on Samsung Internet and the modal hasn't been accepted
    if (isSamsungBrowser && !isChrome && !isFirefox && !hasAcceptedModal) {
      const _message =
        `(${browserName}) may not display this application correctly.` +
        "\n" +
        "Please consider using a standards-compliant browser such as Firefox or Google Chrome.";
      setMessage(_message);
      setShow(true);
      setHasModal(true);
      console.error(_message); // For debugging to see if the full message is output
    }

    // eslint-disable-next-line
  }, []);

  const compatibilityAcceptanceHandler = () => {
    setShow(false);
    setHasModal(false);
    // Set the cookie to expire in 1 hour
    Cookies.set("compatibilityAccept", "true", { expires: 1 / 24 }); // 1 hour = 1/24 of a day
  };

  return (
    <>
      <Modal show={show} size={"6xl"}>
        <Modal.Body>
          <div
            className={`${adminClasses.container} "text-standard flex flex-col justify-center items-center`}
          >
            <p>{message}</p>
            <button
              className={`${adminClasses.button} mt-4 min-w-[140px]`}
              onClick={compatibilityAcceptanceHandler}
            >
              Ok
            </button>
          </div>
        </Modal.Body>
      </Modal>
      <div
        id="header"
        className={`${classes.header}`}
        style={{
          zIndex: hasModal || hasModal || haveModal ? 1 : 100000000000,
        }}
      >
        {hasSidebar && (
          <>
            <SidebarModal
              sidebar={sidebarActive}
              callBack={callBack}
              backdropClick={hamburgerMenuHandler}
            />
            <div className={classes.icon}>
              <HamburgerMenu
                onClick={hamburgerMenuHandler}
                viewBox="0 0 25 22"
                stroke={"var(--main-colour)"}
              />
            </div>
            <Link to="/" style={{ textDecoration: "none" }}>
              <Logo text={title} />
            </Link>
            <span />
          </>
        )}
        {!hasSidebar && (
          <div className="w-full text-center flex justify-center items-center relative">
            <Link to="/" style={{ textDecoration: "none" }}>
              <Logo />
            </Link>
            <span
              onClick={logout}
              style={{ color: "var(--main-colour)" }}
              className="absolute right-0 md:right-[5%] cursor-pointer  py-2 pl-3 pr-4 flex gap-2 rounded  md:p-0"
            >
              <FaSignOutAlt size={20} />{" "}
              <span className="hidden md:block">Logout</span>
            </span>
          </div>
        )}
      </div>
    </>
  );
};

Header.defaultProps = {
  hasModal: false,
};

export default Header;
