import React, { useCallback, useEffect, useState } from "react";
import classes from "./ParticipantWaitingArea.module.css";
import { Participant } from "interface/ParticipantInterface";
import { useAuthContext } from "../../hooks/useAuthContext";
import FirstNameAvatar from "components/utility/FirstNameAvatar";
import useGetParticipants from "hooks/useGetParticipants";
import { FaPlusCircle, FaTimesCircle } from "react-icons/fa";
import { Modal } from "flowbite-react";
import useParticipants from "hooks/useParticipants";
import { coHostRole, R_CO_HOST, R_HOST } from "models/organisationRole";
import { Role } from "models/organisationRole";
import { P_ASSIGN_CO_HOST, P_BECOME_CO_HOST } from "models/permission";
import { useGrowthCircleContext } from "hooks/useGrowthCircleContext";
import buttonClass from "pages/AllCircles/Admin/Admin.module.css";
import usePromise from "hooks/utility/usePromise";
import { useNavigate } from "react-router";
import useOrganisationContext from "hooks/organisation/useOrganisationContext";
import useGroup from "hooks/organisation/useGroup";
import SessionOwnerComponent from "./SessionOwnerComponent";
import { Toaster } from "react-hot-toast";
import { useGCSession } from "hooks/useGCSession";

function checkCanAssignCoHost(role: Role | null | undefined) {
  const permissions = role?.permissions;
  return permissions ? permissions[P_ASSIGN_CO_HOST] === true : false;
}

interface Props {
  participants: Participant[];
}

const ParticipantWaitingArea: React.FC<Props> = ({ participants }) => {
  const { getParticipantColourIndex } = useGetParticipants();
  const { profile } = useAuthContext();
  const {
    sessionRole,
    updateSessionRole,
    getSessionRole,
    syncGCInstance,
    leaveSession,
    ParticipantRecord,
    alignParticipantLocations,
  } = useParticipants();
  const [isVisible, setVisible] = useState(false);
  const { growthCircleSession } = useGrowthCircleContext();
  const [showTrainee, setShowTrainee] = useState(false);
  const { isLoading, resolve } = usePromise();
  const navigate = useNavigate();

  const { selectedOrganisation } = useOrganisationContext();
  const { getSubUser } = useGroup();
  const [showExpired, setShowExpired] = useState(false);
  const { resetActivity } = useGCSession();

  //TODO : temporary captures participants with different GC instance
  useEffect(() => {
    checkIfHostIsATrainee(participants);

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [participants, growthCircleSession]);

  useEffect(() => {
    SynSessionId();
    resetActivityByOwner();

    // eslint-disable-next-line
  }, []);

  const SynSessionId = async () => {
    if (!growthCircleSession) return;
    if (!ParticipantRecord[0]) return;

    if (
      ParticipantRecord[0].sessionInstance !==
      growthCircleSession.sessionInstance
    ) {
      syncGCInstance(
        ParticipantRecord[0].id,
        growthCircleSession.sessionInstance
      );
    }
  };

  const resetActivityByOwner = async () => {
    try {
      if (growthCircleSession?.facilOwner === profile?.uid) {
        if (growthCircleSession?.uid)
          await resetActivity(growthCircleSession?.uid);
      }
    } catch (error) {
      console.error(error);
    }
  };

  const alignParticipantLocationsToFacil = async () => {
    try {
      if (
        growthCircleSession?.location &&
        growthCircleSession?.location !== ""
      ) {
        await alignParticipantLocations(growthCircleSession?.location);
      }
    } catch (error) {
      console.error(error);
    }
  };

  const checkIfExpired = () => {
    // Assuming you have a Firestore Timestamp
    if (
      selectedOrganisation &&
      selectedOrganisation.dateExpire &&
      selectedOrganisation.subscriptionType === "Subscription"
    ) {
      const dateString = selectedOrganisation.dateExpire;
      // console.log(selectedOrganisation);
      const expirationDate = new Date(dateString);

      // Get the current date
      const currentDate = new Date();

      // Compare the dates
      if (currentDate >= expirationDate) {
        setShowExpired(true);
      } else {
        setShowExpired(false);
      }
    }
  };

  const checkSubGroupUser = async () => {
    if (profile) {
      const data = await getSubUser(profile?.uid);
      if (data) {
        const groupDateString = data.groupDoc.dateExpire;
        const subgroupDateString = data.subgroupDoc?.dateExpire;

        if (
          data.groupDoc.subscriptionType === "Subscription" &&
          groupDateString
        ) {
          const groupExpirationDate = new Date(groupDateString);
          const currentDate = new Date();

          // Check if the group expiration date is reached
          if (currentDate >= groupExpirationDate) {
            setShowExpired(true);
            return;
          }
        }

        // Check if there is a subgroup and if its expiration date is reached
        if (
          data.subgroupDoc?.subscriptionType === "Subscription" &&
          subgroupDateString
        ) {
          const subgroupExpirationDate = new Date(subgroupDateString);
          const currentDate = new Date();

          if (currentDate >= subgroupExpirationDate) {
            setShowExpired(true);
          } else {
            setShowExpired(false);
          }
        }
      }
    }
  };

  useEffect(() => {
    checkIfExpired();
    checkSubGroupUser();
    // eslint-disable-next-line
  }, [selectedOrganisation]);

  useEffect(() => {
    alignParticipantLocationsToFacil();
    // eslint-disable-next-line
  }, [participants]);

  const checkIfHostIsATrainee = (participants: Participant[]) => {
    if (participants && sessionRole) {
      participants
        .filter((participant) => participant.sessionRole === "host")
        .map((participant) => {
          if (participant.userId === profile?.uid) {
            return false;
          } else {
            if (!participant.isTrainee) {
              setShowTrainee(false);
            } else if (
              participant.isTrainee &&
              sessionRole?.name === "trainee"
            ) {
              setShowTrainee(false);
            } else {
              setShowTrainee(true);
            }
            return true;
          }
        });
    }
  };

  const checkCanBecomeCoHost = useCallback(
    (participant: Participant | undefined) => {
      const permissions = participant
        ? getSessionRole(participant)?.permissions
        : null;
      return permissions ? permissions[P_BECOME_CO_HOST] === true : false;
    },
    [getSessionRole]
  );

  const checkIsHostOrCoHost = useCallback(
    (participant: Participant | undefined) => {
      if (!participant) {
        return false;
      }
      const roleName = participant.sessionRole;
      return roleName === R_HOST || roleName === R_CO_HOST;
    },
    []
  );

  const modalHandler = () => {
    setVisible(!isVisible);
  };

  const addCoHostHandler = async (id: string) => {
    await updateSessionRole(id, coHostRole);
    setVisible(!isVisible);
  };

  const removeCoHostHandler = async (id: string) => {
    await updateSessionRole(id, null);
  };

  const leaveSessionHandler = async () => {
    const id = participants.find(
      (participant) => participant.userId === profile?.uid
    )?.userId;

    if (id && growthCircleSession) {
      resolve(() => leaveSession(id, growthCircleSession?.id)).then(() => {
        navigate(`/`);
      });
    } else {
      console.error("User ID not found in participants list");
      // Handle the case where the user ID is not found in the participants list
    }
  };

  if (!profile) return null;

  return (
    <>
      <Toaster toastOptions={{ position: "bottom-center" }} />
      <Modal show={showTrainee}>
        <div
          className={`${buttonClass["container"]} p-5 flex-col justify-center items-center`}
          style={{ color: "var(--text-colour)" }}
        >
          <p className="text-lg text-center">
            This session is reserved solely for trainees. If you wish to
            participate in a session for non-trainees, please reach out to a
            Growth facilitator for assistance.
          </p>

          <button
            disabled={isLoading}
            onClick={leaveSessionHandler}
            className={`${buttonClass["button"]} mt-8`}
          >
            {isLoading ? "Please wait..." : "OK"}
          </button>
        </div>
      </Modal>
      <Modal show={showExpired}>
        <div
          className={`${buttonClass["container"]} p-5 flex-col justify-center items-center`}
          style={{ color: "var(--text-colour)" }}
        >
          <p className="text-lg text-center">
            This GrowthCircle or Group/Sub-Group subscription is already
            expired, please reach out to a Growth facilitator for assistance.
          </p>

          <button
            disabled={isLoading}
            onClick={leaveSessionHandler}
            className={`${buttonClass["button"]} mt-8`}
          >
            {isLoading ? "Please wait..." : "OK"}
          </button>
        </div>
      </Modal>
      <React.Fragment>
        <Modal
          show={isVisible}
          size="md"
          popup={true}
          className="min-h-screen"
          onClose={modalHandler}
        >
          <Modal.Header />
          <Modal.Body>
            <div className="text-center">
              <h3
                className="mb-5 text-lg font-normal"
                style={{ color: "var(--main-colour)" }}
              >
                Select a user from the list to be a co-host.
              </h3>

              <div className="flex flex-col justify-start gap-4">
                {participants
                  .filter((parti) => checkCanBecomeCoHost(parti))
                  .map((participant) => (
                    <div
                      key={participant.userId}
                      className="flex w-full py-1 gap-2 items-center"
                    >
                      <FirstNameAvatar
                        name={participant.userName}
                        participant={participant}
                        styleTriggered={true}
                        participantNumber={getParticipantColourIndex(
                          participant.userId
                        )}
                        onClick={() => addCoHostHandler(participant.id)}
                      />{" "}
                      <p className={classes["username"]}>
                        {participant.userName.split(" ")[0]}
                      </p>
                    </div>
                  ))}
              </div>
            </div>
          </Modal.Body>
        </Modal>
      </React.Fragment>
      {growthCircleSession && (
        <SessionOwnerComponent
          profile={profile}
          growthCircleSession={growthCircleSession}
        />
      )}
      <div
        className={`${classes["users-container"]} flex flex-col  items-start mx-auto min-h-[350px] max-h-[600px] overflow-y-scroll`}
      >
        <div className="relative w-full">
          <h3
            className="py-2 font-semibold text-left"
            style={{ color: "var(--main-colour)" }}
          >
            Host / Co-Host
          </h3>
          <div className="flex gap-4">
            {participants
              .filter((parti) => checkIsHostOrCoHost(parti))
              .map((participant) => {
                return (
                  <div
                    key={participant.userId}
                    className={
                      "flex flex-col  justify-center items-center text-center relative overflow-hidden text-ellipsis"
                    }
                  >
                    {checkCanAssignCoHost(sessionRole) &&
                      participant.userId !== profile.uid &&
                      participant.sessionRole !== "host" && (
                        <div
                          className="absolute top-0 right-0 cursor-pointer "
                          onClick={() => removeCoHostHandler(participant.id)}
                        >
                          <FaTimesCircle color="red" size={20} />
                        </div>
                      )}
                    <FirstNameAvatar
                      name={participant.userName}
                      participant={participant}
                      styleTriggered={true}
                      participantNumber={getParticipantColourIndex(
                        participant.userId
                      )}
                    />{" "}
                    <p
                      className={`${classes["username"]} py-2 text-center w-full`}
                    >
                      {participant.userName.split(" ")[0]}
                    </p>
                  </div>
                );
              })}
            {checkCanAssignCoHost(sessionRole) &&
              participants.some(
                (parti) =>
                  checkCanBecomeCoHost(parti) && !checkIsHostOrCoHost(parti)
              ) && (
                <div className="mt-1">
                  <button type="button" onClick={modalHandler}>
                    <FaPlusCircle
                      size={30}
                      style={{ color: "var(--main-colour)" }}
                    />
                  </button>
                </div>
              )}
          </div>
          <h3
            className="text-left py-4 font-semibold"
            style={{ color: "var(--main-colour)" }}
          >
            Participants
          </h3>
          <div className="flex flex-wrap gap-4">
            {participants
              .filter((parti) => !checkIsHostOrCoHost(parti))
              .map((participant) => {
                return (
                  <div
                    className={
                      "flex flex-col items-center  overflow-hidden text-ellipsis"
                    }
                    key={participant.userId}
                  >
                    <FirstNameAvatar
                      name={participant.userName}
                      participant={participant}
                      styleTriggered={true}
                      participantNumber={getParticipantColourIndex(
                        participant.userId
                      )}
                    />

                    <p
                      className={`${classes["username"]} w-12 py-2 text-center  overflow-hidden text-ellipsis`}
                    >
                      {participant.userName.split(" ")[0]}
                    </p>
                  </div>
                );
              })}
          </div>
        </div>
      </div>
    </>
  );
};

export default ParticipantWaitingArea;

