import classes from "./ChatUsers.module.css";

const ChatUser = ({ user }) => {
  return (
    <div className={classes["user-container"]}>
      <img src={`${user.photoUrl}`} alt="" />
      <div className={classes["name-container"]}>
        {" "}
        <p>{user.name}</p>
        <span>
          <button className={user.status ? classes["online"] : ""}></button>{" "}
          online
        </span>
      </div>
    </div>
  );
};

export default ChatUser;
