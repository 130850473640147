import ProfilePicture from "components/ProfilePicture/ProfilePicture";
import { Profile } from "interface/ProfileInterface";
import { createAvatar } from "@dicebear/core";
import { initials } from "@dicebear/collection";
import useUser from "hooks/useUser";
import { useEffect, useState } from "react";
import Organisation from "interface/OrganisationInterface";
import { BsGoogle } from "react-icons/bs";
import { MdMail } from "react-icons/md";

type UserInfoModalProps = {
  user: Profile;
};

interface dateDisplay {
  seconds: number;
  nanoseconds: number;
}

const UserModalAdminInfo = ({ user }: UserInfoModalProps) => {
  const { getAllOrganisationByUser } = useUser();
  const [organisations, setOrganisations] = useState<Organisation[]>([]);

  const avatar = createAvatar(initials, {
    seed: user.displayName || user.email,
    backgroundColor: ["EB8181"],
  });

  const svg = avatar.toString();

  const displayRegisteredDate = (registeredDate: dateDisplay) => {
    // Convert Firestore Timestamp to JavaScript Date
    const date = new Date(
      registeredDate.seconds * 1000 + registeredDate.nanoseconds / 1000000
    );

    // Format the date to a string
    const formattedDate: string = date.toLocaleDateString("en-US", {
      timeZone: "Asia/Manila",
      month: "long",
      day: "numeric",
      year: "numeric",
    });

    return formattedDate;
  };

  const getAllOrg = async () => {
    getAllOrganisationByUser(user.uid)
      .then((orgs) => {
        setOrganisations(orgs);
      })
      .catch((error) => {
        console.error("Error getting organisations:", error);
      });
  };

  useEffect(() => {
    getAllOrg();
    // eslint-disable-next-line
  }, [user]);

  return (
    <div className="flex gap-4 p-5 min-h-[80vh]">
      <div className="flex flex-col items-center pb-10">
        {user.photoURL ? (
          <div className="mb-3">
            <ProfilePicture size={100} profile={user} onClick={(e) => e} />
          </div>
        ) : (
          <img
            className="w-24 h-24 mb-3 rounded-full shadow-lg"
            src={`data:image/svg+xml;utf8,${encodeURIComponent(svg)}`}
            alt={user.displayName || user.email}
          />
        )}
        <h5 className="w-36 text-center overflow-hidden text-ellipsis mb-1 text-xl font-medium text-gray-900 dark:text-white ">
          {user.displayName || user.email || user.firstname}
        </h5>
        <span className="text-sm text-gray-500">{user.email}</span>
        <p className="text-sm text-center my-2 text-gray-500">{user.uid}</p>
        {user.access && (
          <p className="text-sm text-center  uppercase bg-green-800 text-green-100 px-4 py-1 rounded-full my-2">
            {user.access === "admin" ? "Super Admin" : ""}
          </p>
        )}
        <p className="text-sm text-center my-2 text-gray-500">
          Member since
          <span className="block">
            {displayRegisteredDate(user.last_active as unknown as dateDisplay)}
          </span>
        </p>
        <div className="text-sm text-center my-2 text-gray-500">
          Login Method
          <div className="my-2 mx-auto flex justify-center items-center">
            {user.accountType.toLocaleLowerCase() === "google" ? (
              <BsGoogle size={32} />
            ) : (
              <MdMail size={32} />
            )}
          </div>
        </div>
      </div>
      <div className="text-standard">
        {organisations.length > 0 && (
          <div className=" relative overflow-x-auto border">
            <table className="w-full text-sm text-left rtl:text-right  ">
              <thead className="text-xs text-gray-700 uppercase bg-gray-50">
                <tr>
                  <th scope="col" className="px-6 py-3">
                    Organisation
                  </th>
                  <th scope="col" className="px-6 py-3">
                    Role
                  </th>
                </tr>
              </thead>
              <tbody>
                {organisations
                  .slice()
                  .sort((a, b) => a.name.localeCompare(b.name))
                  .map((org, index) => (
                    <tr className="bg-white border-b   " key={index}>
                      <td className="px-6 py-2">{org.name}</td>
                      <td className="px-6 py-2">{org.users[user.uid]}</td>
                    </tr>
                  ))}
              </tbody>
            </table>
          </div>
        )}
        {organisations.length === 0 && <p>No organisations found.</p>}
      </div>
    </div>
  );
};

export default UserModalAdminInfo;
