import {
  ChangeEvent,
  FC,
  FormEvent,
  MouseEvent,
  useEffect,
  useState,
} from "react";
import { useNavigate } from "react-router-dom";

import ProfileHeader from "./ProfileHeader";
import ProfilePictureSelector from "./ProfilePictureSelector";
import Header from "components/Header/Header";
import EmptySpace from "components/utility/EmptySpace";
import PageWrapper from "components/utility/PageWrapper";
import InputField from "components/utility/Forms/InputField";
import Button from "components/utility/Button";

import classes from "./ProfileSettings.module.css";
import useUser from "hooks/useUser";
import { Gender, Profile } from "interface/ProfileInterface";
import BackAndNext from "components/utility/BackAndNext";
import Modal from "components/utility/Modal";
import useInputHandler from "hooks/form/useInputHandler";
import usePromise from "hooks/utility/usePromise";
import { checkIsUserOf } from "models/organisation";
import useOrganisationContext from "hooks/organisation/useOrganisationContext";
import { OrganisationDropdownItem } from "interface/OrganisationInterface";

/**
 * Converts null and undefined into empty string.
 *
 * @param str String to convert.
 * @returns converted string.
 */
function toString<T>(str: T | undefined | null): T | "" {
  return str ? str : "";
}

const genders: Gender[] = ["Female", "Male", "Others"];

/**
 * A page to update profile information.
 * Allows update of display name, first name, last name, email,
 * gender, birthday. Contains a link to a page to rest password.
 *
 * @returns ProfileSettings page.
 */
const ProfileSettings: FC<{}> = () => {
  const navigate = useNavigate();
  const { user, profile, setProfile, formatAuthError } = useUser();
  const { value: formFirstName, onChange: onFormFirstNameChange } =
    useInputHandler(profile?.firstname ?? "");
  const { value: formLastName, onChange: onFormLastNameChange } =
    useInputHandler(profile?.lastname ?? "");
  const { value: formDisplayName, onChange: onFormDisplayNameChange } =
    useInputHandler(profile?.displayName ?? "");
  const { value: formEmail, onChange: onFormEmailChange } = useInputHandler(
    profile?.email ?? ""
  );

  const { value: formPersonalID, onChange: onFormPersonalIDChange } =
    useInputHandler(profile?.personalID ?? "");
  const [formGender, setFormGender] = useState<Gender>(
    toString(profile?.gender)
  );
  const [formBirthday, setFormBirthday] = useState(toString(profile?.birthday));
  const { resolve, isLoading } = usePromise();
  const [responseMessage, setResponseMessage] = useState("");
  const [isModalOpen, setIsModalOpen] = useState(false);
  const [isSUSS, setIsSUSS] = useState(false);

  const { selectedOrganisation } = useOrganisationContext();

  const [selectedValues, setSelectedValues] = useState<{
    [label: string]: string;
  }>({});
  const [textareaValues, setTextareaValues] = useState<{
    [label: string]: string;
  }>({});

  const handleDropdownChange = (label: string, value: string) => {
    const formattedLabel = label.replace(/ /g, "_");
    // console.log(`Selected ${label}: ${value}`);

    setSelectedValues({
      ...selectedValues,
      [formattedLabel]: value,
    });

    // If "Others" is selected in the dropdown, clear the textarea value
    if (value === "Others") {
      setTextareaValues({
        ...textareaValues,
        [formattedLabel]: "",
      });
    }
  };

  const handleTextareaChange = (label: string, value: string) => {
    const formattedLabel = label.replace(/ /g, "_");
    // console.log(`Textarea for ${label}: ${value}`);

    setTextareaValues({
      ...textareaValues,
      [formattedLabel]: value,
    });
  };

  const OrgID = selectedOrganisation?.id;

  // Initialize an empty object to store the collected data with a dynamic key
  const collectedDataObject: {
    [key: string]: { label: string; value: string; otherValues?: string }[];
  } = {
    [OrgID || "default"]: [], // Use OrgID as the key, or a default key if OrgID is falsy
  };

  // Collect the data and populate the object
  Object.keys(selectedValues).forEach((label) => {
    const originalLabel = label.replace(/_/g, " "); // Revert back to original label format

    collectedDataObject[OrgID || "default"].push({
      label: originalLabel,
      value: selectedValues[label],
      otherValues: textareaValues[label] ?? "",
    });
  });

  const getCurrentYear = () => {
    const currentDate = new Date();
    return currentDate.getFullYear();
  };

  const minYear = getCurrentYear() - 13;

  const getFirst4Digits = (inputString: string): string => {
    return inputString.slice(0, 4);
  };

  function submitHandler(
    e: MouseEvent<HTMLButtonElement> | FormEvent<HTMLFormElement>
  ) {
    e.preventDefault();
    if (user && profile) {
      const newProfile: Profile = {
        ...profile,
        displayName: formDisplayName,
        displayNameLowerCase: formDisplayName.toLowerCase(),
        firstname: formFirstName,
        lastname: formLastName,
        email: formEmail,
        gender: formGender,
        birthday: formBirthday,

        OrganisationFields: {
          ...profile.OrganisationFields, // Spread the existing data
          ...collectedDataObject, // Update with the new data
        },
      };

      if (formPersonalID) {
        newProfile.personalID = formPersonalID;
      }
      resolve(() => {
        return setProfile(newProfile)
          .then(() => {
            setResponseMessage("Changes saved.");
          })
          .catch((err) => {
            setResponseMessage(formatAuthError(err));
          })
          .finally(() => {
            setIsModalOpen(true);
          });
      });
    }
  }

  //check if isSUSS
  const checkIfSUSS = async () => {
    if (user?.uid) {
      const _isSUSS = await checkIsUserOf(user?.uid, "SUSS");

      if (_isSUSS) {
        setIsSUSS(true);
      } else {
        setIsSUSS(false);
      }
    }
  };

  useEffect(() => {
    checkIfSUSS();

    if (profile && profile.birthday !== "" && profile.birthday !== undefined) {
      const result = getFirst4Digits(profile?.birthday) as `${number}` | "";
      setFormBirthday(toString(result));
    }

    // eslint-disable-next-line
  }, [user?.uid]);

  function handleBack(e: MouseEvent<HTMLButtonElement>) {
    e.preventDefault();
    navigate("/");
  }

  function handleUpdatePassword(e: MouseEvent<HTMLButtonElement>) {
    e.preventDefault();
    navigate("update-password");
  }

  function handleUpdateGender(
    e: ChangeEvent<HTMLSelectElement> & { target: { value: Gender } }
  ) {
    setFormGender(e.target.value);
  }

  function handleUpdateBirthday(
    e: ChangeEvent<HTMLInputElement> & { target: { value: number } }
  ) {
    setFormBirthday(e.target.value);
  }

  useEffect(() => {
    if (!profile || !selectedOrganisation) return;

    if (
      profile?.OrganisationFields &&
      selectedOrganisation.id &&
      profile?.OrganisationFields[selectedOrganisation.id]
    ) {
      // Create copies of the existing state objects
      const updatedSelectedValues = { ...selectedValues };
      const updatedTextareaValues = { ...textareaValues };
      // console.log(profile?.OrganisationFields[selectedOrganisation.id]);
      if (profile?.OrganisationFields[selectedOrganisation.id]) {
        profile?.OrganisationFields[selectedOrganisation.id].forEach((data) => {
          const formattedLabel = data.label.replace(/ /g, "_");

          // Update the selected value
          updatedSelectedValues[formattedLabel] = data.value;

          // If "Others" is selected in the dropdown, update the textarea value
          if (data.value === "Others") {
            const textareaLabel = `${formattedLabel}_textarea`;
            updatedTextareaValues[textareaLabel] = data.otherValues;
          }
        });

        // Update the state with the updated objects
        setSelectedValues(updatedSelectedValues);
        setTextareaValues(updatedTextareaValues);
      }

      // console.log(selectedValues, textareaValues);
    } else {
      if (selectedOrganisation.uniqueFields) {
        const updatedSelectedValues = { ...selectedValues };
        const updatedTextareaValues = { ...textareaValues };
        selectedOrganisation.uniqueFields.forEach((data) => {
          const formattedLabel = data.label.replace(/ /g, "_");

          // Update the selected value
          updatedSelectedValues[formattedLabel] = data.options[0];
          setSelectedValues(updatedSelectedValues);
          setTextareaValues(updatedTextareaValues);
        });
      }
      // console.log(selectedValues, textareaValues);
    }

    // eslint-disable-next-line
  }, [profile, selectedOrganisation]);

  return (
    <PageWrapper>
      <Header />
      <Modal
        isModalOpen={isModalOpen}
        setIsModalOpen={setIsModalOpen}
        onClose={() => setIsModalOpen(false)}
      >
        <div className={classes.modal}>
          <div>
            <b>{responseMessage}</b>
          </div>
          <EmptySpace />
          <div>
            <Button text="Okay" onClick={() => setIsModalOpen(false)} />
          </div>
        </div>
      </Modal>
      <ProfileHeader />
      <EmptySpace />
      <ProfilePictureSelector />
      <form className={`${classes.form} mb-[100px]`} onSubmit={submitHandler}>
        <div className={classes.inputs}>
          <InputField
            text="First Name"
            isRequired={false}
            type={"text"}
            onChange={onFormFirstNameChange}
            htmlFor={"first-name"}
            label={"First Name"}
            value={formFirstName}
          />
          <InputField
            text="Last Name"
            isRequired={false}
            type={"text"}
            onChange={onFormLastNameChange}
            htmlFor={"last-name"}
            label={"Last Name"}
            value={formLastName}
          />
          <InputField
            text="Username"
            isRequired={true}
            type={"text"}
            onChange={onFormDisplayNameChange}
            htmlFor={"Username"}
            label={"Username"}
            value={formDisplayName}
          />
          {(profile?.accountType === "email_and_password" ||
            profile?.accountType === "guest") && (
            <>
              <InputField
                text="Email"
                isRequired={true}
                type={"email"}
                onChange={onFormEmailChange}
                value={formEmail}
                htmlFor={"email"}
                label={"Email"}
              />
              <EmptySpace />
              <Button
                className={classes["button"]}
                text={"Change Password"}
                onClick={handleUpdatePassword}
              />
            </>
          )}
          {isSUSS && (
            <>
              <InputField
                type="text"
                text="personal id"
                required={true}
                onChange={onFormPersonalIDChange}
                htmlFor={"personal-id"}
                label={"SUSS Personal Identification No."}
                value={formPersonalID}
              />
            </>
          )}

          {selectedOrganisation &&
            selectedOrganisation.id &&
            selectedOrganisation?.hasUniqueFields && (
              <>
                {selectedOrganisation.uniqueFields &&
                  selectedOrganisation.uniqueFields.map(
                    (item: OrganisationDropdownItem, index) =>
                      item.field_type === "dropdown" ? (
                        <div key={index}>
                          <div className="py-2">
                            <InputField
                              type="select"
                              isRequired={true}
                              onChange={(e) => {
                                handleDropdownChange(
                                  item.label,
                                  e.target.value
                                );
                              }}
                              defaultValue={
                                selectedOrganisation.id &&
                                profile?.OrganisationFields &&
                                profile?.OrganisationFields[
                                  selectedOrganisation.id
                                ] &&
                                item.label
                                  ? profile.OrganisationFields[
                                      selectedOrganisation.id
                                    ]
                                      .filter(
                                        (data) =>
                                          data.label ===
                                          item.label.replace(/_/g, " ")
                                      )
                                      .map((data) => data.value)
                                      .find(Boolean) ?? ""
                                  : ""
                              }
                              options={[...item.options]}
                              htmlFor={item.label}
                              label={item.label}
                            />
                          </div>

                          {profile?.OrganisationFields &&
                            profile?.OrganisationFields[
                              selectedOrganisation.id
                            ] &&
                            profile?.OrganisationFields[
                              selectedOrganisation.id
                            ].map((data) => {
                              if (data.label === item.label && item.options) {
                                const value = data.otherValues || ""; // Set a default value if needed

                                // Check if the selected value is "Others" in the options
                                const isOtherSelected =
                                  Object.keys(selectedValues).length === 0
                                    ? item.options.includes(data.value) &&
                                      data.value === "Others"
                                    : selectedValues[
                                        item.label.replace(/ /g, "_")
                                      ] === "Others";
                                if (isOtherSelected) {
                                  return (
                                    <div key={data.label}>
                                      <textarea
                                        className="w-full"
                                        required
                                        defaultValue={value}
                                        onChange={(e) =>
                                          handleTextareaChange(
                                            item.label,
                                            e.target.value
                                          )
                                        }
                                        placeholder={`Others ${item.label}`}
                                      />
                                    </div>
                                  );
                                }
                              }
                              return null; // Return null for non-matching items
                            })}
                        </div>
                      ) : null
                  )}
              </>
            )}
          <InputField
            type="select"
            name="gender"
            htmlFor="gender"
            label="Gender"
            onChange={handleUpdateGender}
            defaultValue={profile?.gender ? profile.gender : "Others"}
            options={genders}
          />
          <InputField
            label="Birth Year"
            type="year"
            minYear={minYear}
            onChange={handleUpdateBirthday}
            value={formBirthday}
          />
        </div>
        <EmptySpace />
        <BackAndNext
          backCallback={handleBack}
          nextCallback={submitHandler}
          backAllowed={!isLoading}
          nextAllowed={!isLoading}
          customNextText="Submit"
          useAnimation={false}
        />
      </form>
      <EmptySpace />
    </PageWrapper>
  );
};

export default ProfileSettings;
