import { getFeedbackByCategory, updateFeedback } from "localStorage/feedback";
import { useState } from "react";

export default function useFeedback(
  category: string,
  defaultValues: { [questionName: string]: string }
) {
  const initialValues: { [questionName: string]: string } = {
    ...defaultValues,
    ...getFeedbackByCategory(category),
  };
  const [values, setValues] = useState(initialValues);

  const setValue = (questionName: string, value: string) => {
    setValues((values) => ({
      ...values,
      [questionName]: value,
    }));
    updateFeedback(category, { [questionName]: value });
  };

  return { values, setValue };
}
