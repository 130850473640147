const AlertModal = (props) => {
  const placeholderStyle = {
    position: "absolute",
    right: "-16px",
    top: "40px",
    zIndex: "3",
    textAlign: "left",
  };

  const boxStyle = {
    width: "242px",
    height: "auto", // change to auto
    background: "#FFD9D9",
    color: "#000",
    borderRadius: "20px",
    position: "relative",
    fontSize: "0.7em",
    display: "flex",
    alignItems: "center",
    padding: "10px",
  };

  const caretStyle = {
    position: "absolute",
    borderLeft: "9px solid transparent",
    borderRight: "9px solid transparent",
    borderBottom: "15px solid #FFD9D9",
    right: "20px",
    top: "-15px",
    zIndex: "3",
  };

  return (
    <div style={placeholderStyle}>
      <div style={boxStyle}>
        {props.text}
        <div style={caretStyle} />
      </div>
    </div>
  );
};

export default AlertModal;
