import useOrganisationGroup, { OrganisationGroupHook } from "hooks/organisation/useOrganisationGroup";
import { createContext, ReactNode } from "react";

export const OrganisationGroupContext = createContext<OrganisationGroupHook | null>(null);

interface Props {
  organisationId: string
  children: ReactNode
}

export function OrganisationGroupContextProvider({ organisationId, children }: Props) {
  const organisationGroupHook = useOrganisationGroup(organisationId);

  return (
    <OrganisationGroupContext.Provider value={organisationGroupHook}>
      {children}
    </OrganisationGroupContext.Provider>
  );
};
