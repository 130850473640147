import { useState } from "react";
import { AiFillExclamationCircle } from "react-icons/ai";
import ReadTermsModal from "./ReadTermsModal";
import ReadPrivacyModal from "./ReadPrivacyModal";

interface NoteToTermsProps {
  showExceptionText?: boolean;
  type?: "image" | "pdf";
}

const NoteToTerms: React.FC<NoteToTermsProps> = ({
  showExceptionText = false,
  type = "image",
}) => {
  const [showTerms, setShowTerms] = useState(false);
  const [showConduct, setShowConduct] = useState(false);
  const [showPrivacy, setShowPrivacy] = useState(false);

  const showTermsHandler = () => {
    setShowTerms(true);
  };

  const showConductHandler = () => {
    setShowConduct(true);
  };

  const showPrivacyHandler = () => {
    setShowPrivacy(true);
  };

  const hideTermsHandler = () => {
    setShowTerms(false);
    setShowConduct(false);
  };

  const hidePrivacyHandler = () => {
    setShowPrivacy(false);
  };

  return (
    <div>
      <ReadTermsModal
        canClose={true}
        show={showTerms || showConduct}
        onClick={hideTermsHandler}
        section={showConduct ? "Conduct" : "Terms"}
      />

      <ReadPrivacyModal
        canClose={true}
        show={showPrivacy}
        onClick={hidePrivacyHandler}
        section={"Privacy"}
      />

      <p className="mt-2 text-red-500">
        <span className="font-bold">Note :</span> Please refrain from uploading
        any {type === "image" ? "pictures" : "pdf/files"} that do not belong to
        you {showExceptionText && "(unless authorisation is given)"} and/or
        contain harmful materials. For further details on what you may not
        upload, please refer to our&nbsp;
        <span
          className="cursor-pointer inline-flex items-center"
          style={{ textDecoration: "underline" }}
          onClick={showTermsHandler}
        >
          Terms & Use
          <AiFillExclamationCircle className="mx-1" size={20} />
        </span>
        ,&nbsp;
        <span
          className="cursor-pointer inline-flex items-center"
          style={{ textDecoration: "underline" }}
          onClick={showConductHandler}
        >
          Online Conduct Policy
          <AiFillExclamationCircle className="mx-1" size={20} />
        </span>
        and our&nbsp;
        <span
          className="cursor-pointer inline-flex items-center"
          style={{ textDecoration: "underline" }}
          onClick={showPrivacyHandler}
        >
          Privacy Policy
          <AiFillExclamationCircle className="mx-1" size={20} />
        </span>
      </p>
    </div>
  );
};

export default NoteToTerms;
