import { DocumentData } from "firebase/firestore";
import OrganisationTopics from "interface/OrganisationTopicsInterface";
import { useEffect } from "react";
import TopicCategoryCard from "./TopicCategoryCard";
import { FaPlusCircle } from "react-icons/fa";

interface TopicCategoryProps {
  topics: DocumentData | undefined;
}

const TopicCategoryCards = ({ topics }: TopicCategoryProps) => {
  useEffect(() => {
    if (!topics) return;
  }, [topics]);

  return (
    <div className="w-full">
      <p className="py-4 flex items-center gap-2 text-sky-700">
        Listed below are the categories. Add topics to each category by pressing
        the
        <FaPlusCircle size={20} className="text-green-500" /> button
      </p>
      <div className="grid grid-cols-1 xl:grid-cols-4 gap-4 w-full">
        {topics &&
          topics.length > 0 &&
          topics.map((topics: OrganisationTopics, ind: number) => (
            <TopicCategoryCard topics={topics} key={ind} />
          ))}
      </div>
    </div>
  );
};

export default TopicCategoryCards;
