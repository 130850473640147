import blue from "assets/GrowthCirclesType/blue.svg";
import yellow from "assets/GrowthCirclesType/yellow.svg";
import pink from "assets/GrowthCirclesType/pink.svg";
import Organisation from "interface/OrganisationInterface";
import { Link } from "react-router-dom";
import { AiFillCloseCircle, AiFillInfoCircle } from "react-icons/ai";
import { useState } from "react";
import { Modal } from "flowbite-react";
import GrowthCirclesTypeAdminCardModal from "./GrowthCirclesTypeAdminCardModal";

interface GrowthCirclesTypeAdminCardProps {
  data: Organisation;
  hasModal?: boolean;
  setModal?: Function;
  start?: boolean;
}
const GrowthCirclesTypeAdminCard = ({
  data,
  setModal,
  start,
}: GrowthCirclesTypeAdminCardProps) => {
  const bgImages = [blue, yellow, pink, pink, yellow];
  const randomImageIndex = Math.floor(Math.random() * bgImages.length);
  const [show, setShow] = useState(false);
  return (
    <>
      <Modal show={show} size="xl">
        <Modal.Body
          className="border-2 rounded-lg relative"
          style={{ borderColor: "var(--icon-colour-0)", color: "#565454" }}
        >
          <AiFillCloseCircle
            size={30}
            className="absolute top-2 right-2 cursor-pointer z-1000"
            onClick={() => {
              if (setModal) {
                setModal();
              }
              setShow(!show);
            }}
            style={{ color: "var(--icon-colour-0)" }}
          />
          <GrowthCirclesTypeAdminCardModal
            index={randomImageIndex}
            data={data}
            start={start}
          />
        </Modal.Body>
      </Modal>
      <div
        className="relative border-2   min-w-[160px] min-h-[280px] bg-white rounded-[30px] p-5 shadow-md"
        style={{ borderColor: "var(--icon-colour-0)", color: "#565454" }}
      >
        <p
          className="bg-default w-1/2 p-2 font-bold text-xl text-center rounded-3xl will-change-auto"
          style={{ color: "#EB8181" }}
        >
          {data.tags}
        </p>
        <AiFillInfoCircle
          onClick={() => {
            if (setModal) {
              setModal();
            }
            setShow(!show);
          }}
          style={{ color: "var(--icon-colour-0)" }}
          size={30}
          className="absolute top-5 right-5 cursor-pointer"
        />
        <div className="my-5 p-1 relative rounded-lg text-center min-h-[200px] flex items-center justify-center">
          <img
            className="w-full "
            src={bgImages[randomImageIndex]}
            alt={`${bgImages[randomImageIndex]}`}
          />
          <p className="text-3xl w-2/3 break-words absolute">{data.name}</p>
        </div>
        <div className="my-4 mb-28  mt-10 text-2xl break-words">
          <p className="text-center break-words">{data.shortDescription}</p>
        </div>
        <div className="p-5 w-full absolute bottom-0 left-0">
          {!start && (
            <Link to={`/all-circles/admin/growthcircles-type/${data.id}`}>
              <div
                style={{ backgroundColor: "#eb8181" }}
                className="px-4 w-full text-3xl text-center py-4 rounded-full text-default font-semibold"
              >
                View
              </div>
            </Link>
          )}
          {start && (
            <Link to={`/all-circles/staging/${data.id}`}>
              <div
                style={{ backgroundColor: "#eb8181" }}
                className="px-4 w-full text-3xl text-center py-4 rounded-full text-default font-semibold"
              >
                Start
              </div>
            </Link>
          )}
        </div>
      </div>
    </>
  );
};

export default GrowthCirclesTypeAdminCard;
