import React from "react";

interface Props {
  fill: string;
  height?: string;
  width?: string;
}

const EmotionalAgility: React.FC<Props> = ({ fill, height, width }) => {
  return (
    <svg
      width={width ? width : "50"}
      height={height ? height : "50"}
      viewBox="0 0 50 50"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M25 50C11.1925 50 0 38.8075 0 25C0 11.1925 11.1925 0 25 0C38.8075 0 50 11.1925 50 25C50 38.8075 38.8075 50 25 50ZM25 45C30.3043 45 35.3914 42.8929 39.1421 39.1421C42.8929 35.3914 45 30.3043 45 25C45 19.6957 42.8929 14.6086 39.1421 10.8579C35.3914 7.10714 30.3043 5 25 5C19.6957 5 14.6086 7.10714 10.8579 10.8579C7.10714 14.6086 5 19.6957 5 25C5 30.3043 7.10714 35.3914 10.8579 39.1421C14.6086 42.8929 19.6957 45 25 45ZM15 27.5H35C35 30.1522 33.9464 32.6957 32.0711 34.5711C30.1957 36.4464 27.6522 37.5 25 37.5C22.3478 37.5 19.8043 36.4464 17.9289 34.5711C16.0536 32.6957 15 30.1522 15 27.5ZM15 22.5C14.0054 22.5 13.0516 22.1049 12.3483 21.4017C11.6451 20.6984 11.25 19.7446 11.25 18.75C11.25 17.7554 11.6451 16.8016 12.3483 16.0983C13.0516 15.3951 14.0054 15 15 15C15.9946 15 16.9484 15.3951 17.6517 16.0983C18.3549 16.8016 18.75 17.7554 18.75 18.75C18.75 19.7446 18.3549 20.6984 17.6517 21.4017C16.9484 22.1049 15.9946 22.5 15 22.5ZM35 22.5C34.0054 22.5 33.0516 22.1049 32.3484 21.4017C31.6451 20.6984 31.25 19.7446 31.25 18.75C31.25 17.7554 31.6451 16.8016 32.3484 16.0983C33.0516 15.3951 34.0054 15 35 15C35.9946 15 36.9484 15.3951 37.6516 16.0983C38.3549 16.8016 38.75 17.7554 38.75 18.75C38.75 19.7446 38.3549 20.6984 37.6516 21.4017C36.9484 22.1049 35.9946 22.5 35 22.5Z"
        fill={fill}
      />
    </svg>
  );
};

export default EmotionalAgility;
