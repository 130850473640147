import classes from "./PageWrapper.module.css";

interface Props {
  children: React.ReactNode;
  pageType?: "welcome" | "waiting-area" | "groundRules";
  forSticky?: boolean;
  className?: string;
}

const PageWrapper: React.FC<Props> = ({
  children,
  pageType,
  forSticky,
  className,
}) => {
  const classNames = `${className} ${classes["root"]} ${
    pageType ? classes[pageType] : ""
  } ${forSticky ? classes["forSticky"] : ""}`;

  return (
    <div className={`${classNames}`}>
      <div id="backdrop-root" />
      {pageType === "groundRules" && <div className={classes["grass"]} />}
      {children}
    </div>
  );
};

export default PageWrapper;
