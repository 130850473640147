import { ChangeEventHandler } from "react";

interface Props {
  onChange: ChangeEventHandler<HTMLInputElement>;
  id?: string;
  checked?: boolean;
}

/**
 * Input field in the form of a checkbox.
 *
 * @param onChange Change handler for the input field.
 * @param id Id of the input component.
 * @returns The CheckboxField component
 */
export default function CheckboxField({
  onChange,
  id,
  checked = false,
}: Props) {
  return (
    <div>
      <input
        style={{ height: "20px", width: "20px" }}
        onChange={onChange}
        type="checkbox"
        id={id}
        checked={checked}
      />
    </div>
  );
}
