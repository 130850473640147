import { useEffect, useState } from "react";

import { getOrganisations } from "models/organisation";
import { useAuthContext } from "./useAuthContext";
import { projectFirestore } from "../firebase/config";

export const useVideo = () => {
  const [videos, setVideos] = useState<Array<Object>>([]);
  const { profile } = useAuthContext();
  const [permissions, setPermissions] = useState<string[]>([]);
  useEffect(() => {
    if (!profile) return;
  }, [profile, videos]);

  const getVideosByOrg = async (organisations) => {
    if (!organisations) return;
    let documents: Object[] = [];
    // eslint-disable-next-line
    await organisations.map((org) => {
      //Get user permissions
      if (profile) {
        permissions.push(org.users[profile?.uid]);

        setPermissions(permissions);
      }

      if (permissions.length > 0) {
        const filteredPermissions = permissions.filter(
          (permission) => permission !== undefined
        );
        const uniqueArray = [...new Set(filteredPermissions)];

        if (uniqueArray.length > 0) {
          projectFirestore
            .collection("Videos")
            .where("organisationName", "==", org.name)
            .where("selectedRoles", "array-contains-any", uniqueArray)
            .get()
            .then(function (doc) {
              doc.docs.map((document) => documents.push(document.data()));
            });
        }
      }
    });
    // eslint-disable-next-line
    return setVideos(documents);
  };

  const getOrganisationVideos = async () => {
    if (profile) {
      getOrganisations(profile).then((organisations) => {
        getVideosByOrg(organisations);
      });
    }
  };

  return { getOrganisationVideos, videos };
};
