import { useCallback, useEffect, useMemo, useState } from "react";
import { useAuthContext } from "./useAuthContext";
import { useFirestore } from "./useFirestore";
import { useNestedCollection } from "./useNestedCollection";
import Role from "interface/RoleInterface";
import { Role as OrganisationRole } from "models/organisationRole";
import { Participant, CheckInCheckOut } from "interface/ParticipantInterface";
import { projectFirestore } from "../firebase/config";
import firebase from "firebase/compat/app";
import "firebase/firestore";
import useOrganisationContext from "./organisation/useOrganisationContext";
import { getRoleByName, getRoleByUserID } from "models/organisationRole";
import useOrganisationRoleContext from "./organisation/useOrganisationRoleContext";
import {
  getParticipantById,
  saveParticipantFeedback,
} from "models/participant";
import { savePastParticipants } from "models/pastParticipant";
import {
  getFeedback,
  removeFeedback,
  updateFeedback,
} from "localStorage/feedback";
import { FEEDBACK_CATEGORY_GROUP } from "models/componentSettings/feedback/groupFeedback";
import { getBadges } from "localStorage/badge";

interface participantCollection {
  documents: Participant[];
}

const useParticipants = () => {
  const { profile } = useAuthContext();
  const { selectedOrganisation } = useOrganisationContext();
  const { roles, selectedRole } = useOrganisationRoleContext();
  const profileId = profile?.id ?? "";
  const growthCirclesId = profile?.growthCircle ? profile.growthCircle : "";
  const { documents: ParticipantRecord }: participantCollection =
    useNestedCollection(
      ["GrowthCircles", "Participants"],
      [growthCirclesId],
      [["userId", "==", profileId]],
      null
    );
  const { documents: ParticipantRecords }: participantCollection =
    useNestedCollection(
      ["GrowthCircles", "Participants"],
      [growthCirclesId],
      [],
      null
    );
  const { updateDocument } = useFirestore(
    null,
    ["GrowthCircles", "Participants"],
    [growthCirclesId]
  );

  const [participantId, setParticipantId] = useState<string>("");
  const [oldReflection, setOldReflection] = useState<string>("");
  const [oldNotes, setOldNotes] = useState<string>("");
  const [oldDice, setOldDice] = useState<number | null>(null);
  const [intentions, SetIntentions] = useState<string>("");
  const [role, setRole] = useState<Role | null>(null);
  const [oldTopic, setOldTopic] = useState<string>("");
  const [oldPath, setOldPath] = useState<string>("");
  //TODO : add general question and personal question
  const [personalReflectionQuestion, setPersonalReflectionQuestion] = useState<
    string[]
  >([]);
  const [generalReflectionQuestion, setGeneralReflectionQuestion] = useState<
    string[]
  >([]);
  const [compulsoryReflectionQuestion, setCompulsoryReflectionQuestion] =
    useState<string[]>([]);
  const [firstFetch, setFirstFetch] = useState(true);
  const [topicGeneralCat, setTopicGeneralCat] = useState<string>("");

  const _participant = ParticipantRecord[0];
  const sessionRole = useMemo(() => {
    const _documentSessionRole = _participant?.sessionRole;
    return _documentSessionRole
      ? getRoleByName(roles, _documentSessionRole)
      : selectedRole;
  }, [_participant, selectedRole, roles]);

  useEffect(() => {
    if (ParticipantRecord.length > 0 && firstFetch) {
      setOldReflection(decodeString(ParticipantRecord[0]["reflection"]));
      setOldNotes(decodeString(ParticipantRecord[0]["notes"]));
      setRole(ParticipantRecord[0]["role"] ?? null);
      setOldDice(ParticipantRecord[0]["diceIndex"]);
      setOldTopic(ParticipantRecord[0]["topic"]);
      setOldPath(ParticipantRecord[0]["path"]);

      setParticipantId(ParticipantRecord[0].id);
      setFirstFetch(false);
    }

    if (oldTopic) {
      let topicGen = projectFirestore
        .collection("Topics")
        .where("topics", "array-contains", oldTopic);

      topicGen.get().then((querySnapshot) => {
        querySnapshot.forEach((doc) => {
          setTopicGeneralCat(doc.data().general);
        });
      });
    }

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [ParticipantRecord, firstFetch, oldTopic]);

  useEffect(() => {
    if (ParticipantRecord.length === 0) return;
    setGeneralReflectionQuestion(ParticipantRecord[0]["reflectionQuestion"]); // this is the general reflection question
    setPersonalReflectionQuestion(
      ParticipantRecord[0]["personalReflectionQuestion"]
    );
    setCompulsoryReflectionQuestion(
      ParticipantRecord[0]["compulsoryReflectionQuestion"]
    );
    SetIntentions(ParticipantRecord[0]["intentions"]);
  }, [ParticipantRecord]);

  /**
   * Helper method to encode strings
   * @param str - string to be encoded
   */
  const encodeString = (str: string): string => {
    if (str === undefined) return "";
    //Split string by linebreak
    const strSplitByLineBreak = str.split(/\r?\n/);

    //Encoded by {str.length}#
    return strSplitByLineBreak.map((str) => `${str.length}#${str}`).join("");
  };

  /**
   * Helper method to decode strings
   * @param str - string to be decoded
   */
  const decodeString = (str: string): string => {
    if (str === undefined) return "";
    let res = "";
    let i = 0;

    while (i < str.length) {
      let j = i;
      while (str[j] !== "#" && j < str.length) {
        ++j;
      }

      const len: number = Number(str.slice(i, j));
      // console.log(isNaN(len));
      if (isNaN(len)) return str;
      res += str.slice(++j, j + len) + "\n";
      i = j + len;
    }

    // console.log(res);

    return res;
  };

  /**
   * Updates CheckIn on user's participant document
   * @param checkIn - the data you want to update
   */
  const updateGCSessionCheckIn = async (checkIn: CheckInCheckOut) => {
    const updatedCheckIn = {
      ...ParticipantRecord[0],
      checkIn: checkIn,
    };

    await updateDocument(participantId, updatedCheckIn);
  };

  const updateSessionORScheckInValues = async (orgCheckIn: any) => {
    const updatedCheckIn = {
      ...ParticipantRecord[0],
      orgCheckIn,
    };

    await updateDocument(participantId, updatedCheckIn);
  };

  const updateSessionORScheckOutValues = async (orgCheckOut: any) => {
    const updatedCheckIn = {
      ...ParticipantRecord[0],
      orgCheckOut,
    };

    await updateDocument(participantId, updatedCheckIn);
  };

  /**
   * Updates intentions on user's participant document
   * @param entry - the text of the intention
   */
  const addIntentions = async (entry: string) => {
    const updatedIntentions = {
      ...ParticipantRecord[0],
      intentions: entry,
    };

    await updateDocument(participantId, updatedIntentions);
  };

  const addIntentionsRandomize = async (
    entry: string,
    intentionQuestion?: string
  ) => {
    const updatedIntentions = {
      ...ParticipantRecord[0],
      intentionsRandom: entry,
      intentionQuestion: intentionQuestion || null,
    };

    await updateDocument(participantId, updatedIntentions);
  };

  const addReflection = async (reflection: string) => {
    // if reflection already exist, update instead
    //Encode string
    const encodedReflection: string = encodeString(reflection);
    // console.log(encodedReflection);
    const updatedReflection = {
      ...ParticipantRecord[0],
      reflection: encodedReflection,
    };
    await updateDocument(participantId, updatedReflection);
  };

  const addNotes = async (notes: string) => {
    // if reflection already exist, update instead
    //Encode string
    const encodedNotes = encodeString(notes);
    // console.log(encodedNotes);
    const updatedNotes = {
      ...ParticipantRecord[0],
      notes: encodedNotes,
    };
    await updateDocument(participantId, updatedNotes);
  };

  const updateRole = async (role: Role) => {
    const updatedRole = {
      ...ParticipantRecord[0],
      role: role,
    };
    await updateDocument(participantId, updatedRole);
  };

  const updateSelectedDice = async (index: number) => {
    const updatedDice = {
      ...ParticipantRecord[0],
      diceIndex: index,
    };
    await updateDocument(participantId, updatedDice);
  };

  const updateSelectedTopic = async (topic: string) => {
    const updatedTopic = {
      ...ParticipantRecord[0],
      topic: topic,
      reflectionQuestion: [],
      personalReflectionQuestion: [],
    };
    await updateDocument(participantId, updatedTopic);
  };

  const updateSelectedActivity = async (activity: string) => {
    const updatedActivity = {
      ...ParticipantRecord[0],
      activity: activity,
    };

    await updateDocument(participantId, updatedActivity);
  };

  const updateSelectedPath = async (path: string) => {
    const updatedPath = {
      ...ParticipantRecord[0],
      path: path,
      reflectionQuestion: [],
      personalReflectionQuestion: [],
      compulsoryReflectionQuestion: [],
    };
    await updateDocument(participantId, updatedPath);
  };

  const updatePersonalReflectionQuestion = async (question: Object[]) => {
    const updatedQuestion = {
      ...ParticipantRecord[0],
      personalReflectionQuestion: question,
    };

    await updateDocument(participantId, updatedQuestion);
  };

  const updateGeneralReflectionQuestion = async (question: Object[]) => {
    const updatedQuestion = {
      ...ParticipantRecord[0],
      reflectionQuestion: question,
    };

    await updateDocument(participantId, updatedQuestion);
  };

  const updateCompulsoryReflectionQuestion = async (question: Object[]) => {
    const updatedQuestion = {
      ...ParticipantRecord[0],
      compulsoryReflectionQuestion: question,
    };

    await updateDocument(participantId, updatedQuestion);
  };
  // addReflection: adds / update reflection entry
  // oldReflection: retrieve past reflection if exist

  const updateReflectionRatingCompleted = async () => {
    const ratingCompleted = {
      ...ParticipantRecord[0],
      reflectionRatingCompleted: true,
    };
    await updateDocument(participantId, ratingCompleted);
  };

  const updateTriggeredStatus = async (
    isTriggered: boolean,
    isImmediate: boolean
  ) => {
    await updateDocument(participantId, {
      triggered: isTriggered,
      isImmediate: isImmediate,
    });
  };

  const syncGCInstance = async (partID: string, gcID: string) => {
    const data = {
      sessionInstance: gcID,
    };
    await updateDocument(partID, data);
  };

  const updateActivityTitle = async (title: string) => {
    await updateDocument(participantId, {
      activityTitle: title,
    });
    const queryRef = projectFirestore
      .collection("GrowthCircles")
      .doc(growthCirclesId)
      .collection("Participants")
      .where("userId", "==", profileId);
    queryRef.get().then((querySnapshot) => {
      querySnapshot.forEach((doc) => {
        // Get the DocumentReference for each matching document
        const documentRef = doc.ref;

        // Update the document using the DocumentReference
        documentRef.update({
          ngPersonalRandomQuestion: firebase.firestore.FieldValue.delete(),
          ngTopicRandomQuestion: firebase.firestore.FieldValue.delete(),
        });
      });
    });
  };

  const leaveSession = async (userId: string, gcID: string) => {
    const queryRef = projectFirestore
      .collection("GrowthCircles")
      .doc(gcID)
      .collection("Participants")
      .where("userId", "==", userId);

    queryRef
      .get()
      .then((querySnapshot) => {
        querySnapshot.forEach((doc) => {
          doc.ref
            .delete()
            .then(() => {
              console.log("Document successfully deleted!");
            })
            .catch((error) => {
              console.error("Error deleting document: ", error);
            });
        });
      })
      .catch((error) => {
        console.error("Error getting documents: ", error);
      });
  };

  const updateUsersImageUrls = async (imageUrls: string[]) => {
    await updateDocument(participantId, {
      imageUrls: imageUrls,
    });
  };

  const updateNGTopicRandomQuestion = async (question: string) => {
    await updateDocument(participantId, {
      ngTopicRandomQuestion: question,
    });
  };

  const updateRandomPersonalQuestion = async (
    question: string,
    activity: string,
    question1: string,
    question2: string
  ) => {
    await updateDocument(participantId, {
      ngPersonalRandomQuestion: question,
      facilSelectedActivity: activity,
      reflectionQuestion1: question1,
      reflectionQuestion2: question2,
    });
  };

  const updateFeedbackReflection = async (reflection: any) => {
    // await updateDocument(participantId, reflection);
    console.log(JSON.stringify(reflection));
    localStorage.setItem("feedBackFormData", JSON.stringify(reflection));
  };

  const updateParticiPantBadgeRating = async (id, rating) => {
    const docRef = projectFirestore
      .collection("GrowthCircles")
      .doc(growthCirclesId)
      .collection("Participants")
      .doc(id);

    await projectFirestore.runTransaction(async (transaction) => {
      const doc = await transaction.get(docRef);
      const badgeData =
        doc?.data()?.feedbackReflection?.feedbackForGroup?.Badges || [];
      const userId = profile?.id;
      const userBadgeDataIndex = badgeData.findIndex(
        (item) => item.fromUserID === userId
      );

      if (userBadgeDataIndex !== -1) {
        // If the user badge data already exists, update the values
        badgeData[userBadgeDataIndex] = {
          ...badgeData[userBadgeDataIndex],
          ...rating,
        };
      } else {
        // If the user badge data doesn't exist, create a new object and push it into the badge data array
        const newUserBadgeData = {
          fromUserID: userId,
          ...rating,
        };
        badgeData.push(newUserBadgeData);
      }

      const data = {
        feedbackReflection: {
          ...doc.data()?.feedbackReflection,
          feedbackForGroup: {
            ...doc.data()?.feedbackReflection?.feedbackForGroup,
            Badges: badgeData,
          },
        },
      };

      transaction.update(docRef, data);
    });
  };

  const updateLevelProgress = async (ParticipantRecord: Participant) => {
    if (
      profile?.progress &&
      ParticipantRecord.role &&
      ParticipantRecord.role.role in profile.progress
    ) {
      const { level, numOfTimesPlayed, progressPercentage } =
        profile.progress[ParticipantRecord.role.role];

      let newProgressPercentage: number;
      // sum all the badges values inside the feedbackReflection.feedbackForGroup.Badges array of Objects
      //  Roles +  CareAndSupport  Effort
      const badges =
        ParticipantRecord.feedbackReflection?.feedbackForGroup.Badges;
      let CareAndSupport = 0;
      let Role = 0;
      let Effort = 0;

      if (badges !== undefined) {
        const sumOfBadges = badges.reduce((acc, curr) => {
          for (const key in curr) {
            if (key in acc) {
              acc[key] += curr[key];
            } else {
              acc[key] = curr[key];
            }
          }
          return acc;
        }, {});

        CareAndSupport = sumOfBadges.CareAndSupport || 0;
        Role = sumOfBadges.Role || 0;
        Effort = sumOfBadges.Effort || 0;
      }

      // Add 40 to progressPercentage and add the sum of all the badges values
      newProgressPercentage =
        progressPercentage + 40 + CareAndSupport + Role + Effort;

      // check if newProgressPercentage is greater or equal than 100
      if (newProgressPercentage >= 100) {
        // if yes, then add 1 to level and get the remainder of newProgressPercentage / 100
        const newLevel = level + 1;
        const newNumOfTimesPlayed = numOfTimesPlayed + 1;
        newProgressPercentage = newProgressPercentage % 100;
        // update the progressPercentage
        const newProgress = {
          ...profile.progress,
          [ParticipantRecord.role.role]: {
            level: newLevel,
            numOfTimesPlayed: newNumOfTimesPlayed,
            progressPercentage: newProgressPercentage,
          },
        };
        if (
          ParticipantRecord.levelUpdated === undefined ||
          !ParticipantRecord.levelUpdated
        ) {
          const docRef = projectFirestore.collection("users").doc(profile?.uid);

          await projectFirestore.runTransaction(async (transaction) => {
            const doc = await transaction.get(docRef);
            const currentData = doc.data();

            const data = {
              ...currentData,
              progress: newProgress,
            };

            transaction.update(docRef, data);
          });
          // console.log(ParticipantRecord.id);
          await updateDocument(ParticipantRecord.id, {
            levelUpdated: true,
          });
          //after submitting the feedback, clear the local storage
          localStorage.removeItem("BadgeData");
        }
      } else {
        // if no, then update the progressPercentage
        const newProgress = {
          ...profile.progress,
          [ParticipantRecord.role.role]: {
            level: level,
            numOfTimesPlayed: numOfTimesPlayed + 1,
            progressPercentage: newProgressPercentage,
          },
        };

        if (
          ParticipantRecord.levelUpdated === undefined ||
          !ParticipantRecord.levelUpdated
        ) {
          const docRef = projectFirestore.collection("users").doc(profile?.uid);

          await projectFirestore.runTransaction(async (transaction) => {
            const doc = await transaction.get(docRef);
            const currentData = doc.data();

            const data = {
              ...currentData,
              progress: newProgress,
            };

            transaction.update(docRef, data);
          });
          // console.log(ParticipantRecord.id);
          await updateDocument(ParticipantRecord.id, {
            levelUpdated: true,
          });
          //after submitting the feedback, clear the local storage
          localStorage.removeItem("BadgeData");
        }
      }
    } else {
      console.debug("Key not found.");
    }
  };

  const StoreBadgeData = async () => {
    const Badges = getBadges();
    // Check if Badges exist
    if (Badges.length === 0) {
      console.warn("No badge data found.");
      return;
    }

    // Loop through the badges and update the database based on the badge data ToUserID
    for (let i = 0; i < Badges.length; i++) {
      const badge = Badges[i];
      const { ToUserID, FromUserID, Effort, CareAndSupport, Role } = badge;

      const docRef = projectFirestore
        .collection("GrowthCircles")
        .doc(growthCirclesId)
        .collection("Participants")
        .doc(ToUserID);

      // Check if the ToUserID exists
      const userDoc = await docRef.get();
      if (!userDoc.exists) {
        // If the user does not exist, skip the iteration and continue with the next user
        console.warn(`User with ID ${ToUserID} does not exist.`);
        continue;
      }

      await projectFirestore.runTransaction(async (transaction) => {
        const doc = await transaction.get(docRef);
        const existingBadges =
          doc?.data()?.feedbackReflection?.feedbackForGroup?.Badges || [];

        const userBadgeDataIndex = existingBadges.findIndex(
          (item) => item.FromUserID === FromUserID && item.ToUserID === ToUserID
        );

        if (userBadgeDataIndex !== -1) {
          // If the user badge data already exists, update the values
          existingBadges[userBadgeDataIndex] = {
            ...existingBadges[userBadgeDataIndex],
            Effort,
            CareAndSupport,
            Role,
          };
        } else {
          // If the user badge data doesn't exist, create a new object and push it into the badge data array
          const newUserBadgeData = {
            FromUserID,
            ToUserID,
            Effort,
            CareAndSupport,
            Role,
          };
          existingBadges.push(newUserBadgeData);
        }

        const updatedData = {
          feedbackReflection: {
            ...doc.data()?.feedbackReflection,
            feedbackForGroup: {
              ...doc.data()?.feedbackReflection?.feedbackForGroup,
              Badges: existingBadges,
            },
          },
        };

        transaction.update(docRef, updatedData);
      });
    }
  };

  const updateFeedbackReflectionStatus = async () => {
    updateFeedback(FEEDBACK_CATEGORY_GROUP, { Badges: getBadges() });
    const data = getFeedback();

    if (!data) {
      return;
    }
    const { feedbackReflection } = data;

    // Merge the feedbackReflection objects
    const updatedFeedbackReflection = {
      ...ParticipantRecord[0].feedbackReflection,
      ...feedbackReflection,
    };
    await saveParticipantFeedback(
      growthCirclesId,
      participantId,
      updatedFeedbackReflection
    );
    await StoreBadgeData();
    const participant = await getParticipantById(
      growthCirclesId,
      participantId
    );
    if (selectedOrganisation && participant) {
      savePastParticipants(selectedOrganisation.id, [participant]);
    }

    //after submitting the feedback, clear the local storage together with the checkOut data
    removeFeedback();
    localStorage.removeItem("checkOut");
  };

  const saveCheckOut = async (data: object) => {
    await updateDocument(participantId, { checkOut: data });
  };

  const updateTriggerRead = async (participantId: string) => {
    await updateDocument(participantId, { triggerReadByFacil: true });
  };

  /**
   * Updates the session role of the participant document.
   *
   * @param participantId Id of the participant (Not userID).
   * @param role Updated role of the participant.
   */
  const updateSessionRole = useCallback(
    async (participantId: string, role: OrganisationRole | null) => {
      await updateDocument(participantId, {
        sessionRole: role ? role.name : "",
      });
    },
    [updateDocument]
  );

  /**
   * Retrives the session role of a participant.
   * Defaults to organisation role of the current organisation context if participant
   * has no session role.
   *
   * @param participant Participant to retrive role of.
   * @returns The session role if exists, organisation role otherwise.
   */
  const getSessionRole = useCallback(
    (participant: Participant) => {
      if (participant.sessionRole) {
        return getRoleByName(roles, participant.sessionRole);
      }
      if (!selectedOrganisation) {
        return null;
      }
      return getRoleByUserID(participant.userId, selectedOrganisation, roles);
    },
    [roles, selectedOrganisation]
  );

  const alignParticipantLocations = async (location: string) => {
    try {
      // Fetch all participants
      const participantsSnapshot = await projectFirestore
        .collection("GrowthCircles")
        .doc(growthCirclesId)
        .collection("Participants")
        .get();

      const batch = projectFirestore.batch();

      participantsSnapshot.forEach((doc) => {
        const participant = doc.data();

        // Check if the participant's location doesn't match the supplied location
        if (participant.location !== location) {
          const docRef = projectFirestore
            .collection("GrowthCircles")
            .doc(growthCirclesId)
            .collection("Participants")
            .doc(doc.id);

          // Add the update operation to the batch
          batch.update(docRef, { location });
        }
      });

      // Commit the batch update
      await batch.commit();
      console.log("Participant locations updated successfully");
    } catch (error) {
      console.error("Error updating participant locations:", error);
    }
  };

  return {
    addReflection,
    addNotes,
    decodeString,
    oldReflection,
    oldNotes,
    oldDice,
    oldTopic,
    oldPath,
    personalReflectionQuestion,
    generalReflectionQuestion,
    compulsoryReflectionQuestion,
    intentions,
    role,
    topicGeneralCat,
    updateRole,
    updateGCSessionCheckIn,
    addIntentions,
    updateSelectedDice,
    updateSelectedTopic,
    updateSelectedPath,
    updatePersonalReflectionQuestion,
    updateGeneralReflectionQuestion,
    updateCompulsoryReflectionQuestion,
    updateReflectionRatingCompleted,
    updateTriggeredStatus,
    updateSelectedActivity,
    ParticipantRecords,
    ParticipantRecord,
    sessionRole,
    updateActivityTitle,
    updateUsersImageUrls,
    updateNGTopicRandomQuestion,
    updateRandomPersonalQuestion,
    updateFeedbackReflection,
    updateParticiPantBadgeRating,
    updateFeedbackReflectionStatus,
    updateLevelProgress,
    saveCheckOut,
    updateTriggerRead,
    updateSessionRole,
    getSessionRole,
    syncGCInstance,
    updateSessionORScheckInValues,
    updateSessionORScheckOutValues,
    addIntentionsRandomize,
    leaveSession,
    alignParticipantLocations,
  };
};

export default useParticipants;
