import { GrowthCircleSession } from "interface/GrowthCircleSessionInterface";
import { useCollection } from "./useCollection";
import { useAuthContext } from "./useAuthContext";

const useTopics = (growthCircle: GrowthCircleSession | undefined) => {
  var type: string[] = [];
  const { profile } = useAuthContext();
  if (growthCircle?.type !== "TAFEP") {
    switch (growthCircle?.subCategory) {
      case "GC SU1":
        type = ["Growth Circle 1 Topics"];
        break;
      case "GC SU2":
        type = ["Growth Circle 2 Topics"];
        break;
      case "GC SU3":
        type = ["Growth Circle 3 Topics"];
        break;
      case "GC SU4":
        type = ["Growth Circle 4 Topics"];
        break;
      default:
        type = ["General Topics"];
    }
  } else {
    if (profile?.activity === "Grievance Handling") {
      type = [
        "Types of Grievance Issues",
        "Grievance Handling Procedures & Challenges",
      ];
    } else if (profile?.activity === "Flexible Work Arrangement") {
      type = ["Flexible Work Arrangement"];
    } else {
      type = ["General Topics"];
    }
  }

  const { documents } = useCollection(
    "Topics",
    undefined,
    ["order", "asc"],
    [["general", "in", type]]
  );

  return { topics: documents };
};

export default useTopics;
