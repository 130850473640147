import { useEffect, useState } from "react";
import Header from "components/Header/Header";
import PageWrapper from "components/utility/PageWrapper";
import classes from "pages/AllCircles/Admin/Admin.module.css";
import { useAuthContext } from "hooks/useAuthContext";
import { useSelfAwareness } from "hooks/useSelfAwareness";
import usePromise from "hooks/utility/usePromise";
import toast, { Toaster } from "react-hot-toast";

const Iframe = ({ iframe }: { iframe: string | TrustedHTML }) => {
  return <div dangerouslySetInnerHTML={{ __html: iframe }} />;
};

const JourneyWithSage = () => {
  const [isCompleted, setIsCompleted] = useState("false");
  const { isLoading, resolve } = usePromise();
  const [progress, setProgress] = useState("");
  const [progress2, setProgress2] = useState("");
  const { profile } = useAuthContext();
  const { saveProgress, getProgress } = useSelfAwareness();

  const submitHandler = async () => {
    if (profile) {
      const data = await resolve(() =>
        getProgress(profile.uid, "Journey With Sage")
      );
      if (data?.status === "completed") {
        toast.success("Progress was updated successfully!");
      } else {
        if (progress === "100.00%" || progress === "90.91%") {
          setIsCompleted("true");
          let _progress = progress === "90.91%" ? "100%" : progress;

          resolve(() =>
            saveProgress(profile, _progress, "Journey With Sage", "completed")
          ).then(() => {
            toast.success("Progress was updated successfully!");
          });
        } else {
          setIsCompleted("false");

          resolve(() =>
            saveProgress(profile, progress, "Journey With Sage", "incomplete")
          ).then(() => {
            toast.success("Progress was updated successfully!");
          });
        }
      }
    }
  };

  const submitInHouseHandler = async () => {
    if (profile) {
      if (progress2 === "Exit") {
        resolve(() =>
          saveProgress(profile, "100.00%", "Settling In-Housing", "completed")
        ).then(() => {
          toast.success("Progress was updated successfully!");
        });
      } else {
        resolve(() =>
          saveProgress(profile, "0%", "Settling In-Housing", "incomplete")
        ).then(() => {
          toast.success("Progress was updated successfully!");
        });
      }
    }
  };

  const iframe =
    '<iframe id="focus-sink" height="850px" style="width: 100%;" scrolling="no" title="fx." src="https://journey-with-sage.web.app" frameborder="no" allowtransparency="true" allowfullscreen="true" />';

  const iframe2 =
    '<iframe height="850px" style="width: 100%;" scrolling="no" title="fx." src="https://journey-with-sage-housing.web.app" frameborder="no" allowtransparency="true" allowfullscreen="true" />';

  // Add event listener to capture messages sent via postMessage
  useEffect(() => {
    const handleMessage = (event: MessageEvent) => {
      // Verify that the message is coming from a trusted source
      if (event.origin === "https://journey-with-sage.web.app") {
        // Access the data sent from the iframe
        const data: { name: string; progress: string } = event.data;

        //convert data percentage
        let percentageString: string = data.progress;
        let numberValue: number = parseFloat(percentageString.replace("%", ""));

        // Do something with the received data
        if (numberValue > 9 && data.name !== "" && data.name.length < 60) {
          setProgress(data.progress);
        }
      } else {
        // Ignore messages from untrusted origins
        console.warn("Received message from untrusted origin:", event.origin);
      }

      // Verify that the message is coming from a trusted source
      if (event.origin === "https://journey-with-sage-housing.web.app") {
        // Access the data sent from the iframe
        const data: { progress: string } = event.data;

        // Do something with the received data
        //console.log(data);

        setProgress2(data.progress);
      } else {
        // Ignore messages from untrusted origins
        console.warn("Received message from untrusted origin:", event.origin);
      }
    };

    window.addEventListener("message", handleMessage);

    // Clean up function to remove event listener
    return () => {
      window.removeEventListener("message", handleMessage);
    };
    // eslint-disable-next-line
  }, []);

  const fetchProgress = async () => {
    if (profile) {
      const data = await getProgress(profile.uid, "Journey With Sage");
      if (data?.status === "completed") {
        setIsCompleted("true");
      }
    }
  };

  useEffect(() => {
    if (!profile) return;
    fetchProgress();
    // eslint-disable-next-line
  }, [profile]);

  return (
    <PageWrapper>
      <Header />
      <Toaster position={"bottom-center"} toastOptions={{ duration: 2000 }} />
      <div className={`${classes["container"]} my-4 mt-[30%] md:mt-[8%]`}>
        <button
          disabled={isLoading}
          onClick={submitHandler}
          className={`${classes["button"]}`}
        >
          {isLoading ? "Saving..." : "Save Progress"}
        </button>
      </div>
      <div className="w-full max-w-7xl mb-[100px]">
        <Iframe iframe={iframe} />
      </div>

      {isCompleted === "true" && (
        <>
          <div className={`${classes["container"]} my-4 `}>
            <button
              disabled={isLoading}
              onClick={submitInHouseHandler}
              className={`${classes["button"]}`}
            >
              {isLoading ? "Saving..." : "Save Progress"}
            </button>
          </div>

          <div className="w-full max-w-7xl mb-[100px]">
            <Iframe iframe={iframe2} />
          </div>
        </>
      )}
    </PageWrapper>
  );
};

export default JourneyWithSage;
