import { useAuthContext } from "hooks/useAuthContext";
import { useEffect, useState } from "react";
import classes from "./NGFooter.module.css";
import GrowthCollectiveLogo from "assets/NG/Logos/GrowthCollective.webp";
import GBLogo from "assets/gblogo.png";
import { GrowthCircleSession } from "interface/GrowthCircleSessionInterface";
import useOrganisationContext from "hooks/organisation/useOrganisationContext";
import {
  getDownloadURL,
  listAll,
  ref,
  StorageReference,
} from "firebase/storage";
import { projectStorage } from "../../firebase/config";

interface NGFooterProps {
  type?: string;
  GrowthCircle?: GrowthCircleSession;
}

const NGFooter = ({ type, GrowthCircle }: NGFooterProps) => {
  const { profile } = useAuthContext();
  const { selectedOrganisation } = useOrganisationContext();
  const [files, setFiles] = useState<string[]>([]);

  const fetchImages = async () => {
    try {
      setFiles([]);
      let storageRef: StorageReference;

      if (selectedOrganisation?.isFooterMain) {
        storageRef = ref(
          projectStorage,
          `organisations/logo/${selectedOrganisation?.id}`
        );
      } else {
        storageRef = ref(
          projectStorage,
          `organisations/logo/footer/${selectedOrganisation?.id}`
        );
      }
      const result = await listAll(storageRef);

      const urlPromises = result.items.map((imageRef) =>
        getDownloadURL(imageRef)
      );

      const urls = await Promise.all(urlPromises);

      if (urls.length > 0) {
        setFiles(urls); // Update the state with the new URLs
      } else {
        setFiles([]);
      }
    } catch (error) {
      console.error(error);
    }

    // eslint-disable-next-line
  };

  useEffect(() => {
    if (!profile || !selectedOrganisation) return;
    fetchImages();

    // eslint-disable-next-line
  }, [selectedOrganisation]);

  return (
    <div
      className={`fixed bottom-0 bg-default w-full border-t px-4 flex justify-between items-center z-50`}
      style={{ borderColor: "var(--main-colour)" }}
    >
      <div className="flex gap-2 justify-start items-center w-full">
        {selectedOrganisation?.footerSettings === "logo-with-collectives" && (
          <div className="py-2">
            <img
              src={GrowthCollectiveLogo}
              alt="GrowthCollective"
              width={75}
              height={20}
            />
          </div>
        )}
        {files.length > 0 ? (
          <div className="overflow-hidden py-2">
            <img
              src={files[0] ?? GBLogo}
              alt={selectedOrganisation?.name}
              className="object-contain max-h-[50px] w-[130px]"
            />
          </div>
        ) : (
          <div className="py-2">
            <img
              src={GrowthCollectiveLogo}
              alt="GrowthCollective"
              width={75}
              height={20}
            />
          </div>
        )}
      </div>
      <p className={`${classes.powered} text-sm md:text-lg`}>
        Powered by Growthbeans
      </p>
    </div>
  );
};

export default NGFooter;
