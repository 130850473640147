import FeedbackFormTitle from "components/FeedBackForm/FeedbackFormTitle";
import FeedbackOrgForm from "components/FeedBackForm/FeedbackOrgForm";
import QuestionViewGroup from "components/Organisation/FeedbackSettings/QuestionViewGroup";
import BackAndNext from "components/utility/BackAndNext";
import EmptySpace from "components/utility/EmptySpace";
import { Modal } from "flowbite-react";
import { useOrgFeedbackQuestions } from "hooks/organisation/useOrgFeedbackQuestions";
import { useGrowthCircleContext } from "hooks/useGrowthCircleContext";
import useParticipants from "hooks/useParticipants";
import usePromise from "hooks/utility/usePromise";
import { FeedbackLayout } from "interface/FeedbackLayoutInterface";
import { saveJournal } from "models/journal";
import { checkCanSkipSaveJournal } from "models/organisationRole";
import { ReactComponent as CloseIcon } from "assets/Xbutton.svg";
import { useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
import { COMPLETED_ROUTE, FINISHED_ROUTE } from "strings/Routes";
import { Toaster } from "react-hot-toast";
import { ComponentProps } from "pages/AllCircles/GenericSessionPage/ComponentMapping";

const FeedbackOrgAfterComponent = ({
  backAndNextHandler,
  setHasModal,
}: ComponentProps) => {
  const { feedbackLayout } = useOrgFeedbackQuestions();
  const [activeTab, setActiveTab] = useState(1);
  const [isVisible, setIsVisible] = useState(false);
  const { isLoading, resolve } = usePromise();
  const {
    updateFeedbackReflectionStatus,
    ParticipantRecord,
    updateLevelProgress,
    sessionRole,
  } = useParticipants();
  const navigate = useNavigate();
  const { growthCircleSession } = useGrowthCircleContext();

  const canSkipSaveJournal = checkCanSkipSaveJournal(sessionRole);

  const nextHandler = () => {
    if (feedbackLayout.length === activeTab) {
      modalHandler();
    } else {
      setActiveTab(activeTab + 1);
    }
  };

  const modalHandler = () => {
    setIsVisible(!isVisible);
    setHasModal(!isVisible);
  };

  const handleConfirm = async () => {
    resolve(() => updateFeedbackReflectionStatus()).then(async () => {
      setIsVisible(false);

      if (canSkipSaveJournal) {
        navigate(COMPLETED_ROUTE);
      } else {
        if (growthCircleSession) {
          await saveJournal(growthCircleSession, ParticipantRecord[0]);
        }
        await updateLevelProgress(ParticipantRecord[0]);
        console.debug("updateLevel called");
        navigate(FINISHED_ROUTE);
      }
    });
  };

  const nextHandlerSubmitted = async () => {
    if (canSkipSaveJournal) {
      navigate(COMPLETED_ROUTE);
    } else {
      navigate(FINISHED_ROUTE);
    }
  };

  useEffect(() => {
    if (!feedbackLayout || !ParticipantRecord) return;
    // eslint-disable-next-line
  }, [feedbackLayout]);

  return (
    <>
      <Toaster position={"bottom-center"} />
      <EmptySpace height={"25px"} />

      <Modal show={isVisible} size="md" className="min-h-screen " popup={true}>
        <Modal.Body className="p-5 relative mt-[5%] md:mt-[5%] ">
          {!isLoading && (
            <div className="absolute right-0 -top-2 p-2">
              <CloseIcon className="cursor-pointer" onClick={modalHandler} />
            </div>
          )}
          <div className="text-center py-5 px-2">
            <h3 className="mb-5 py-4 text-lg font-semibold  text-gray-500 dark:text-gray-400">
              {!isLoading && (
                <>
                  Do you want to submit?{" "}
                  <span className="block"> You can only do this once.</span>
                </>
              )}
              {isLoading && <>Submitting Please wait..</>}
            </h3>

            <div className="flex justify-center gap-4">
              <button
                type="button"
                disabled={isLoading}
                style={
                  !isLoading
                    ? { backgroundColor: "var(--main-colour)" }
                    : { backgroundColor: "grey" }
                }
                className={`shadow-md text-white  px-12 rounded-3xl  transition duration-1000 py-4`}
                onClick={handleConfirm}
              >
                <span>{isLoading ? "Submitting..." : "Yes"}</span>
              </button>
              {!isLoading && (
                <button
                  type="button"
                  style={{ backgroundColor: "var(--main-colour)" }}
                  className={`shadow-md  px-12 rounded-3xl py-3 text-white hover:bg-red-700 transition duration-1000`}
                  onClick={modalHandler}
                >
                  No
                </button>
              )}
            </div>
          </div>
        </Modal.Body>
      </Modal>

      {ParticipantRecord[0]?.feedbackReflectionStatus !== "submitted" &&
        feedbackLayout &&
        (feedbackLayout as FeedbackLayout[]) &&
        feedbackLayout.map((questions, index) => (
          <div
            key={index}
            className="w-full flex flex-col justify-center items-center"
          >
            {activeTab === index + 1 && (
              <>
                <FeedbackOrgForm
                  numTab={feedbackLayout.length}
                  activeTab={activeTab}
                  handleSwitchTabs={(n) => setActiveTab(n)}
                >
                  <FeedbackFormTitle>{questions?.title}</FeedbackFormTitle>
                  <EmptySpace height={"15px"} />
                  <QuestionViewGroup
                    setHasModal={setHasModal}
                    sectionId={questions?.id}
                    questionCategory={questions?.title}
                    questions={questions?.subCategories}
                  />
                </FeedbackOrgForm>
                <EmptySpace />
                <BackAndNext
                  {...backAndNextHandler}
                  nextAllowed={true}
                  nextCallback={nextHandler}
                />
              </>
            )}
          </div>
        ))}

      {!isLoading &&
        ParticipantRecord[0]?.feedbackReflectionStatus === "submitted" && (
          <>
            <EmptySpace height={"15px"} />
            <div className="w-full flex flex-col justify-center  items-center">
              <h1 className="mt-5 font-semibold">
                It appears that you have already submitted your feedback form.
                Please proceed to the next step.
              </h1>
              <button
                style={{ backgroundColor: "var(--main-colour)" }}
                className="border-4 border-[var(--main-colour)]   text-white rounded-3xl mt-5 shadow-md px-8 py-4 hover:bg-green-600 hover:border-green-400 transition duration-1000"
                onClick={nextHandlerSubmitted}
              >
                Proceed to next session
              </button>
            </div>
            <EmptySpace height={"50px"} />
          </>
        )}
    </>
  );
};

export default FeedbackOrgAfterComponent;
