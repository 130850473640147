import { SelfAwarenessToolInterface } from "interface/SelfAwarenessToolInterface";
import TitleBox from "./TitleBox";
import TextArea from "./TextArea";
import BackAndNextFlat from "components/utility/BackAndNextFlat";
import { BackAndNextProps } from "../SelfAwarenessComponentMapping";
import { useEffect, useState } from "react";

interface QuestionCompItemProps {
  journal: SelfAwarenessToolInterface;
  backAndNextHandler: BackAndNextProps;
  handler: () => void;
  nextHandler: (e: React.MouseEvent<HTMLButtonElement, MouseEvent>) => void;
  question: string;
  backOptionHandler: () => void;
  option: string;
}
const ValueRiskQuestion = ({
  backAndNextHandler,
  journal,
  handler,
  question,
  nextHandler,
  backOptionHandler,
  option,
}: QuestionCompItemProps) => {
  const [answer, setAnswer] = useState("");

  const answerHandler = (value: string) => {
    setAnswer(value);
    const storedJournal = localStorage.getItem("SelfAwarenessJournal");
    const existingJournal = storedJournal ? JSON.parse(storedJournal) : {};

    // Ensure each option has its own object in criteriaGrading
    const updatedTopSelectionItem = {
      ...existingJournal.valueRiskQuestions,
      [option]: {
        ...(existingJournal.valueRiskQuestions?.[option] || {}),
        [question]: value,
      },
    };

    const updatedJournal = {
      ...existingJournal,
      valueRiskQuestions: updatedTopSelectionItem,
      // other properties of SelfAwarenessJournal...
    };

    localStorage.setItem(
      "SelfAwarenessJournal",
      JSON.stringify(updatedJournal)
    );
  };

  useEffect(() => {
    const storedJournal = localStorage.getItem("SelfAwarenessJournal");
    const existingJournal = storedJournal ? JSON.parse(storedJournal) : {};
    const initialValue =
      existingJournal.valueRiskQuestions?.[option]?.[question];
    if (initialValue) {
      setAnswer(initialValue);
    } else {
      setAnswer("");
    }

    // eslint-disable-next-line
  }, [question]);

  return (
    <>
      {journal && question && (
        <>
          <TitleBox
            title={`Value-Risk-Resources-Need (VRRN)`}
            handler={handler}
            showInfo={true}
            journal={journal}
            size="small"
          />
          <p
            className="font-poppins p-2 my-4 font-semibold"
            style={{ color: "var(--text-colour)" }}
          >
            {question}
          </p>
          <TextArea
            maxLength={500}
            journal={journal}
            answer={answer}
            answerHandler={answerHandler}
          />

          <div className="mt-5">
            <BackAndNextFlat
              {...backAndNextHandler}
              backCallback={backOptionHandler}
              nextCallback={(e) => nextHandler(e)}
              nextAllowed={answer.length > 0 ? true : false}
            />
          </div>
        </>
      )}
    </>
  );
};

export default ValueRiskQuestion;
