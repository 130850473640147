import { BadgeName, getBadges, toggleBadge } from "localStorage/badge";
import { useCallback, useState } from "react";

export default function useBadges() {
  const [badges, setBadges] = useState(getBadges());

  const toggleBadgeWrapped = useCallback((
    fromUserID: string,
    toUserID: string,
    badgeName: BadgeName
  ) => {
    const newBadges = toggleBadge(fromUserID, toUserID, badgeName);
    setBadges(newBadges);
  }, []);

  return {
    badges,
    toggleBadge: toggleBadgeWrapped
  };
}
