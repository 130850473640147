import { useReducer, useState } from "react";
import { useAuthContext } from "./useAuthContext";
import { actionStructure, firestoreReducer } from "./useFirestore";
import firebase from "firebase/compat/app";
import { GCJournalNotFoundError } from "errors/GCJournalNotFoundError";
import { GCJournal } from "interface/GCJournal";
import { GCJournalDuplicateError } from "errors/GCJournalDuplicateError";
import { projectFirestore } from "../firebase/config";

let initialState = {
  document: null,
  isPending: false,
  error: null,
  success: false,
};

const useGCJournal = () => {
  const { profile } = useAuthContext();
  const [response, dispatch] = useReducer(firestoreReducer, initialState);

  const [isCancelled] = useState<boolean>(false);
  //const { updateDocument } = useFirestore("GCJournals");
  const ref = firebase.firestore().collection("GCJournals");

  // only dispatch is not cancelled
  const dispatchIfNotCancelled = (action: actionStructure) => {
    // if (!isCancelled) {
    dispatch(action);
    // }
  };

  /**
   * Based on the growthCircle id, retrieve the user's journal
   * @param growthCircleId: string
   */
  const retrieveGCJournalByGrowthCircleId = async (growthCircleId: string) => {
    dispatch({ type: "IS_PENDING" });

    const query = ref
      .where("userId", "==", profile!.id)
      .where("growthCircle", "==", growthCircleId);

    return query.get().then((querySnapShot) => {
      if (querySnapShot.empty) {
        const errorMsg: string =
          "Journal with id " + growthCircleId + "is not associated with you";
        dispatchIfNotCancelled({
          type: "ERROR",
          payload: errorMsg,
        });

        throw new GCJournalNotFoundError(errorMsg);
      }

      if (querySnapShot.size > 1) {
        const errorMsg: string =
          "Multiple journals were found from growthcircle id: " +
          growthCircleId;
        dispatchIfNotCancelled({
          type: "ERROR",
          payload: errorMsg,
        });

        throw new GCJournalDuplicateError(errorMsg);
      }

      const userGCJournal: GCJournal =
        querySnapShot.docs[0].data() as GCJournal;
      dispatchIfNotCancelled({
        type: "RETRIEVED_DOCUMENT",
        payload: userGCJournal,
      });
      return userGCJournal;
    });
  };

  const massUpdateJournals = async (uid: string, new_uid: string) => {
    const ref = projectFirestore
      .collection("GCJournals")
      .where("userId", "==", uid);
    ref.get().then(function (querySnapshot) {
      if (querySnapshot.docs.length > 0) {
        querySnapshot.docs.forEach((doc) => {
          const newData = { ...doc.data(), userId: new_uid };
          // Update the document with the new userId
          projectFirestore.collection("GCJournals").doc(doc.id).update(newData);
        });
      } else {
        console.info("No Journals to sync");
      }
    });
  };

  return {
    retrieveGCJournalByGrowthCircleId,
    response,
    isCancelled,
    massUpdateJournals,
  };
};

export default useGCJournal;
