import SimpleLoader from "components/Loaders/SimpleLoader";
import { QuestionType } from "components/Organisation/QuestionSettings/QuestionSettings";
import InputField from "components/utility/Forms/InputField";
import { Modal } from "flowbite-react";
import useOrganisationContext from "hooks/organisation/useOrganisationContext";
import { useGrowthCirclesType } from "hooks/useGrowthCirlesType";
import { useOrganisationQuestion } from "hooks/useOrganisationQuestion";
import { useOrganisationTopics } from "hooks/useOrganisationTopics";
import usePromise from "hooks/utility/usePromise";
import Organisation from "interface/OrganisationInterface";
import OrganisationTopics from "interface/OrganisationTopicsInterface";
import { QuestionInterface } from "interface/QuestionsInterface";
import classes from "pages/AllCircles/Admin/Admin.module.css";
import { useCallback, useState } from "react";
import toast from "react-hot-toast";
import { AiFillCloseCircle } from "react-icons/ai";

interface Props {
  topics: OrganisationTopics[];
}

/**
 * Modal to copy questions from one organisation to another
 * 
 * @param props - the component props
 * @param props.topics - the topics (categories) of the organisation
 * @returns the copy question modal component
 */
const CopyQuestionModal = ({ topics }: Props) => {
  const { getTopicsById } = useOrganisationTopics();
  const { organisations, selectedOrganisation, updateSelectedOrganisation } =
    useOrganisationContext();
  const { getGrowthCircleTypeByName } = useGrowthCirclesType();
  const {
    getAllQuestionByOrgId,
    getAllQuestionByOrgName,
    processCopyTopicsAndQuestions,
  } = useOrganisationQuestion();
  const { isLoading: isLoadingCopyQuestions, resolve: resolveLoadCopy } =
    usePromise();
  const [visible, setVisible] = useState(false);
  const [selectedQuestion, setSelectedQuestion] = useState("");
  const [selectedCopy, setSelectedCopy] = useState("");
  const [isCopying, setCopying] = useState(false);
  const [copyingOrg, setCopyingOrg] = useState<Organisation | null>();
  const [topicData, setTopicData] = useState<OrganisationTopics[]>(
    [] as OrganisationTopics[]
  );
  const [specificQuestionsToCopy, setSpecificQuestionsToCopy] = useState<
    QuestionInterface[]
  >([]);
  const [randomQuestionsToCopy, setRandomQuestionsToCopy] = useState<
    QuestionInterface[]
  >([]);

  const orgOptions = [
    "",
    ...organisations
      .map((org) => org.name)
      .filter((name) => name !== selectedOrganisation?.name),
  ];

  // questions are similar if they have the same question text
  // regardless of the case
  const filterSimilarQuestions = useCallback(
    (
      existingQuestions: QuestionInterface[],
      newQuestions: QuestionInterface[]
    ) => {
      return newQuestions.filter((question) => {
        for (const existingQuestion of existingQuestions) {
          if (
            question.question.toLocaleLowerCase() ===
            existingQuestion.question.toLocaleLowerCase()
          ) {
            return false;
          }
        }

        return true;
      });
    },
    []
  );

  // refresh the category of the questions
  const refreshCategories = useCallback(
    (questions: QuestionInterface[]) => {
      const newQuestions = questions.map((question) => {
        // if the question is a category question, ignore
        if (!question.topic) {
          return question;
        }

        for (const category of topics) {
          if (
            category.general
              .map((topic) => topic.toLocaleLowerCase())
              .includes(question.topic?.toLocaleLowerCase())
          ) {
            return {
              ...question,
              topicCategoryId: category.id,
              category: category.category,
            };
          }
        }

        return question;
      });

      return newQuestions;
    },
    [topics]
  );

  const copySelectionChangeHandler = useCallback(
    async (selectedCopy: string) => {
      if (selectedCopy !== "" && selectedOrganisation) {
        try {
          const org = await getGrowthCircleTypeByName(selectedCopy);
          const { name } = org?.data as Organisation;
          if (org?.data) {
            setCopyingOrg(org?.data as Organisation);
            let newTopics: OrganisationTopics[] = [];
            const newQuestions = await getAllQuestionByOrgName(name);
            const existingQuestions = await getAllQuestionByOrgId(
              selectedOrganisation.id
            );
            const specificQuestions: QuestionInterface[] = [];
            const randomQuestions: QuestionInterface[] = [];

            // filter question based on selected organisation question type
            for (const question of newQuestions) {
              if (question.topicCategoryId === "") {
                randomQuestions.push(question as QuestionInterface);
              } else {
                specificQuestions.push(question as QuestionInterface);
              }
            }

            // filter similar questions to prevent duplicates
            const randomQuestionsToCopy = filterSimilarQuestions(
              existingQuestions as QuestionInterface[],
              randomQuestions as QuestionInterface[]
            );
            const filteredSpecificQuestions = filterSimilarQuestions(
              existingQuestions as QuestionInterface[],
              specificQuestions as QuestionInterface[]
            );

            // replace the category of the questions with similar topics
            let specificQuestionsToCopy = refreshCategories(
              filteredSpecificQuestions
            );

            if (specificQuestionsToCopy.length > 0) {
              // fetch the categories and topics that will be copied
              newTopics = (await getTopicsById(
                specificQuestionsToCopy[0].organisationId,
                specificQuestionsToCopy.map(
                  (question) => question.topicCategoryId
                )
              )) as OrganisationTopics[];

              setSelectedQuestion(QuestionType.SPECIFIC);
            } else if (randomQuestionsToCopy.length > 0) {
              setSelectedQuestion(QuestionType.RANDOM);
            }

            const topicNames: (string | null)[] = specificQuestionsToCopy.map(
              (question) => question.topic
            );

            const currentTopicNames: string[] = topics.flatMap(
              (topic) => topic.general
            );

            // filter out all current topics from the categories to copy
            // also filter topics not related to the questions to copy
            newTopics = newTopics.flatMap((category) => {
              const filteredTopicGeneral = category.general.filter(
                (topicName) =>
                  topicNames.includes(topicName) &&
                  !currentTopicNames.includes(topicName)
              );

              // include only categories with topics to copy
              if (filteredTopicGeneral.length > 0) {
                return [
                  {
                    ...category,
                    general: filteredTopicGeneral,
                  },
                ];
              }

              // include categories with category questions
              if (
                specificQuestionsToCopy.some(
                  (question) => question.topicCategoryId === category.id
                )
              ) {
                return [
                  {
                    ...category,
                    general: filteredTopicGeneral,
                  },
                ];
              }

              return [];
            });

            specificQuestionsToCopy = specificQuestionsToCopy.map(
              (question) => {
                const questionCategory = newTopics.find(
                  (topic) => topic.id === question.topicCategoryId
                );

                if (!questionCategory) {
                  return question;
                }

                const existingCategory = topics.find(
                  (topic) =>
                    topic.category.toLocaleLowerCase() ===
                    questionCategory.category.toLocaleLowerCase()
                );

                if (!existingCategory) {
                  return question;
                }

                return {
                  ...question,
                  topicCategoryId: existingCategory.id,
                };
              }
            );

            setTopicData(newTopics);
            setSpecificQuestionsToCopy(specificQuestionsToCopy);
            setRandomQuestionsToCopy(randomQuestionsToCopy);
          }
        } catch (error) {
          console.error(error);
        }
      }
    },
    [
      topics,
      refreshCategories,
      filterSimilarQuestions,
      getGrowthCircleTypeByName,
      getTopicsById,
      //selectedCopy,
      selectedOrganisation,
      getAllQuestionByOrgId,
      getAllQuestionByOrgName,
    ]
  );

  const handleCloseModal = useCallback(() => {
    setSelectedCopy("");
    setVisible(false);
    setCopyingOrg(null);
    setSelectedQuestion("");
    setSpecificQuestionsToCopy([]);
    setRandomQuestionsToCopy([]);
  }, [
    setVisible,
    setSelectedCopy,
    setCopyingOrg,
    setSelectedQuestion,
    setSpecificQuestionsToCopy,
    setRandomQuestionsToCopy,
  ]);

  const copyingSubmitHandler = useCallback(async () => {
    setCopying(true);

    await processCopyTopicsAndQuestions(
      topicData,
      topics,
      selectedQuestion === QuestionType.SPECIFIC
        ? specificQuestionsToCopy
        : randomQuestionsToCopy,
      selectedOrganisation?.id,
      selectedQuestion
    ).then(() => {
      updateSelectedOrganisation({
        questionsType: selectedQuestion,
      }).then(() => {
        toast.success(
          `Questions ${
            selectedQuestion === QuestionType.SPECIFIC ? "and Topics " : ""
          }were copied successfully`
        );
        setCopying(false);
        handleCloseModal();
      });
    });
  }, [
    processCopyTopicsAndQuestions,
    topics,
    topicData,
    selectedOrganisation,
    selectedQuestion,
    specificQuestionsToCopy,
    randomQuestionsToCopy,
    updateSelectedOrganisation,
    handleCloseModal,
  ]);

  return (
    <>
      <Modal show={visible} size="3xl">
        <Modal.Body
          className="border-2 rounded-lg bg-default relative"
          style={{ borderColor: "var(--icon-colour-0)" }}
        >
          {!isCopying && (
            <AiFillCloseCircle
              size={30}
              className="absolute top-2 right-2 cursor-pointer"
              onClick={handleCloseModal}
              style={{ color: "var(--icon-colour-0)" }}
            />
          )}
          <div className="text-center">
            <p className="py-4 text-slate-600 text-lg">
              To duplicate a set of reflection questions, choose an organization
              or growth circle type from the dropdown menu, and then click the
              "Copy" button.{" "}
              <span className="font-semibold">
                If you opt for specific question selection, the associated
                topics will be automatically created in this organization or
                growth circle type. This will take place for missing topics
                only.
              </span>
            </p>
            <div className="flex flex-col gap-2 justify-center items-center">
              <div style={{ color: "var(--text-colour)" }} className="my-4">
                Organisation / Growth Circle Type:
                <InputField
                  name={"selected organisation"}
                  type="select"
                  htmlFor={"organisation"}
                  onChange={(e: React.ChangeEvent<HTMLSelectElement>) => {
                    setSelectedCopy(e.target.value);
                    resolveLoadCopy(() =>
                      copySelectionChangeHandler(e.target.value)
                    );
                  }}
                  options={orgOptions}
                  value={selectedCopy}
                />
              </div>
              <div className="flex items-start justify-start">
                {copyingOrg &&
                  (isLoadingCopyQuestions ? (
                    <SimpleLoader />
                  ) : (
                    <div className="flex gap-2 items-start justify-start flex-col">
                      {selectedOrganisation &&
                        copyingOrg &&
                        specificQuestionsToCopy.length !== 0 && (
                          <label className="cursor-pointer">
                            <input
                              type="radio"
                              value={QuestionType.SPECIFIC}
                              checked={
                                selectedQuestion === QuestionType.SPECIFIC
                              }
                              onChange={(e) =>
                                setSelectedQuestion(e.target.value)
                              }
                              className="mx-4"
                              style={{
                                height: "20px",
                                width: "20px",
                                backgroundColor:
                                  selectedQuestion === QuestionType.SPECIFIC
                                    ? "var(--icon-colour-0)"
                                    : "",
                              }}
                            />
                            Copy Specific Questions (Total{" "}
                            {specificQuestionsToCopy.length} questions,{" "}
                            {topicData.flatMap((topic) => topic.general).length}{" "}
                            topics,{" "}
                            {
                              topicData.filter(
                                (topic) =>
                                  !topics
                                    .map((currentTopic) =>
                                      currentTopic.category.toLocaleLowerCase()
                                    )
                                    .includes(
                                      topic.category.toLocaleLowerCase()
                                    )
                              ).length
                            }{" "}
                            categories)
                          </label>
                        )}
                      {selectedOrganisation &&
                        copyingOrg &&
                        randomQuestionsToCopy.length !== 0 && (
                          <label className="cursor-pointer">
                            <input
                              type="radio"
                              value={QuestionType.RANDOM}
                              checked={selectedQuestion === QuestionType.RANDOM}
                              onChange={(e) =>
                                setSelectedQuestion(e.target.value)
                              }
                              className="mx-4"
                              style={{
                                height: "20px",
                                width: "20px",
                                backgroundColor:
                                  selectedQuestion === QuestionType.RANDOM
                                    ? "var(--icon-colour-0)"
                                    : "",
                              }}
                            />
                            Copy Randomize Personal and General questions for
                            any topic <br />
                            (Total {randomQuestionsToCopy.length} questions)
                          </label>
                        )}
                      {selectedOrganisation &&
                        copyingOrg &&
                        specificQuestionsToCopy.length === 0 &&
                        randomQuestionsToCopy.length === 0 && (
                          <p className="color-red">No questions to copy.</p>
                        )}
                    </div>
                  ))}
              </div>
              {selectedOrganisation &&
                (specificQuestionsToCopy.length > 0 ||
                  randomQuestionsToCopy.length > 0) &&
                (selectedQuestion === QuestionType.SPECIFIC ||
                  selectedQuestion === QuestionType.RANDOM) && (
                  <button
                    disabled={isCopying}
                    onClick={copyingSubmitHandler}
                    className="bg-red-400 text-white py-4 text-lg uppercase rounded-full w-1/3 my-4"
                  >
                    {isCopying ? "Copying..." : "Copy"}
                  </button>
                )}
            </div>
          </div>
        </Modal.Body>
      </Modal>
      <div className="my-4">
        <button
          reflection-questions-settings-tour="copy-button"
          onClick={() => setVisible(true)}
          className={`${classes["button"]}`}
        >
          Copy
        </button>
      </div>
    </>
  );
};

export default CopyQuestionModal;
