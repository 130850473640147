import { FeedbackQuestion, FeedbackQuestions } from "./utility/feedback";

const FEEDBACK_CATEGORY_FACIL = "feedbackForFacil";

const facilNameQuestion: FeedbackQuestion = {
  questionName: "facilName",
  type: "text",
  question: { default: "Please write your Growth Facilitator's Name" },
  defaultResponse: "",
};

const createdSafeSpaceQuestion: FeedbackQuestion = {
  questionName: "createdSafeSpace",
  type: "smileyRating",
  question: { default: "Create a space that made us feel safe to share" },
  defaultResponse: 3,
};

const communicatedWellQuestion: FeedbackQuestion = {
  questionName: "communicatedWell",
  type: "smileyRating",
  question: { default: "Communicate well with the group" },
  defaultResponse: 3,
};

const engagedGroupQuestion: FeedbackQuestion = {
  questionName: "engagedTheGroupEffectively",
  type: "smileyRating",
  question: { default: "Engage the group effectively" },
  defaultResponse: 3,
};

const providedInsightsQuestion: FeedbackQuestion = {
  questionName: "provideUsefulInsights",
  type: "smileyRating",
  question: { default: "Provide useful insights" },
  defaultResponse: 3,
};

const facilitatedLearningQuestion: FeedbackQuestion = {
  questionName: "facilitateLearningThoughtReflection",
  type: "smileyRating",
  question: { default: "Facilitate learning through reflections" },
  defaultResponse: 3,
};

const hasKnowledgeAndSkillsQuestion: FeedbackQuestion = {
  questionName: "haveNecessaryKnowledgeAndSkillsForTheRole",
  type: "smileyRating",
  question: { default: "Have the necessary knowledge and skills for the role" },
  defaultResponse: 3,
};

const empoweredUsQuestion: FeedbackQuestion = {
  questionName: "empowerUsToSupportOneAnother",
  type: "smileyRating",
  question: { default: "Empower us to support one another" },
  defaultResponse: 3,
};

const embracedPerspectivesQuestion: FeedbackQuestion = {
  questionName: "inviteAndEmbraceAllPerspectives",
  type: "smileyRating",
  question: { default: "Invite and embrace all perspectives" },
  defaultResponse: 3,
};

const otherFeedbackQuestion: FeedbackQuestion = {
  questionName: "otherFeedback",
  type: "textArea",
  question: {
    default: "Kindly share with us why you gave the above ratings",
    host: "Kindly share with us why you gave the above ratings, and share any other feedback or appreciation for your facilitator",
  },
  defaultResponse: "",
};

const defaultFacilFeedbackQuestions: FeedbackQuestions = {
  title: "For Facilitator",
  category: FEEDBACK_CATEGORY_FACIL,
  subCategories: [
    {
      questions: [facilNameQuestion],
    },
    {
      questionPrefix: {
        default: "The facilitator was able to:",
        host: "To what extent was I able to:",
      },
      questions: [
        createdSafeSpaceQuestion,
        communicatedWellQuestion,
        engagedGroupQuestion,
        providedInsightsQuestion,
        facilitatedLearningQuestion,
        hasKnowledgeAndSkillsQuestion,
        empoweredUsQuestion,
        embracedPerspectivesQuestion,
      ],
    },
    {
      questions: [otherFeedbackQuestion],
    },
  ],
};

const sussFacilFeedbackSettings: FeedbackQuestions = {
  title: "For peers",
  category: FEEDBACK_CATEGORY_FACIL,
  subCategories: [
    {
      questions: [],
    },
    {
      questionPrefix: {
        default: "To what extent were my peers able to:",
      },
      questions: [
        createdSafeSpaceQuestion,
        communicatedWellQuestion,
        engagedGroupQuestion,
        providedInsightsQuestion,
        facilitatedLearningQuestion,
        {
          ...hasKnowledgeAndSkillsQuestion,
          question: { default: "Demonstrate their roles effectively" },
        },
        empoweredUsQuestion,
        embracedPerspectivesQuestion,
      ],
    },
    {
      questions: [otherFeedbackQuestion],
    },
  ],
};

// TODO move these to firestore and use modelOperations to add, get, update and delete.
export function getFacilFeedbackQuestions(
  organisationName: string
): FeedbackQuestions {
  if (organisationName === "SUSS") {
    return sussFacilFeedbackSettings;
  }
  return defaultFacilFeedbackQuestions;
}
