import { ReactComponent as Effort } from "../../assets/ReflectionRating/Effort.svg";
import { ReactComponent as CareAndSupport } from "../../assets/ReflectionRating/CareAndSupport.svg";
import { ReactComponent as RoleIcon } from "../../assets/ReflectionRating/RoleIcon.svg";
import styles from "./FeedbackRating.module.css";
import { useAuthContext } from "hooks/useAuthContext";
import { Participant } from "interface/ParticipantInterface";
import { Badge, BadgeName } from "localStorage/badge";
import { useCallback } from "react";

type Props = {
  participant: Participant
  rating: Badge | undefined
  toggleRating: (
    fromUserId: string,
    toUserId: string,
    badgeName: BadgeName
  ) => void
}

const FeedbackRatingIcons = ( {
  participant,
  rating,
  toggleRating,
}: Props ) => {
  const { user } = useAuthContext();

  const onRatingChange = useCallback((badgeName: BadgeName) => {
    if (!user?.uid) {
      return;
    }
    toggleRating(user?.uid, participant.id, badgeName);
  }, [user?.uid, participant.id, toggleRating])

  const iconStyles = {
    display: "flex",
    justifyContent: "center",
    alignItems: "center",
    height: "60px",
    width: "60px",
    margin: "0 auto",
    borderRadius: "50%",
    cursor: "pointer",
  };

  return (
    <tr>
      {participant && (
        <>
          <td width={100}>
            <span className="font-bold block break-underscore whitespace-pre-wrap">
              {participant.userId === user?.uid
                ? "Myself"
                : participant.userName}{" "}
              :{" "}
            </span>{" "}
            <span
              style={{ color: "var(--main-colour)" }}
              className={`text-[12px] block font-semibold`}
            >
              {participant.role && participant.role.role}
            </span>
          </td>
          <td width={100} align={"center"} className="text-center py-4 px-2">
            <div
              className={`relative  `}
              onClick={() => onRatingChange("Effort")}
              data-value="Effort"
              style={iconStyles}
            >
              {rating && rating["Effort"] > 0 && (
                <div
                  style={{
                    backgroundColor: "var(--main-colour)",
                    opacity: "0.4",
                  }}
                  className={`${styles.animatedBackground} shadow-md `}
                ></div>
              )}
              <Effort width={50} height={35} className={`z-50`} />
            </div>
          </td>
          <td width={100} className="text-center py-4 px-2 ">
            <div className="border-x border-slate-700 px-3">
              <div
                className="relative"
                onClick={() => onRatingChange("CareAndSupport")}
                data-value="CareAndSupport"
                style={iconStyles}
              >
                {rating && rating["CareAndSupport"] > 0 && (
                  <div
                    style={{
                      backgroundColor: "var(--main-colour)",
                      opacity: "0.4",
                    }}
                    className={`${styles.animatedBackground} shadow-md  `}
                  ></div>
                )}
                <CareAndSupport width={50} height={35} className={"z-50"} />
              </div>
            </div>
          </td>
          <td width={100} className="text-center py-4 px-2">
            <div
              className="relative"
              onClick={() => onRatingChange("Role")}
              data-value="Role"
              style={iconStyles}
            >
              {rating && rating["Role"] > 0 && (
                <div
                  style={{
                    backgroundColor: "var(--main-colour)",
                    opacity: "0.4",
                  }}
                  className={`${styles.animatedBackground} shadow-md `}
                ></div>
              )}
              <RoleIcon width={50} height={35} className={"z-50"} />
            </div>
          </td>
        </>
      )}
    </tr>
  );
};

export default FeedbackRatingIcons;
