import FiveStepHeader from "components/FiveStepHeader/FiveStepHeader";
import Header from "components/Header/Header";
import Center from "components/utility/Center/Center";
import ContentWrapper from "components/utility/ContentWrapper";
import EmptySpace from "components/utility/EmptySpace";
import FirstNameAvatar from "components/utility/FirstNameAvatar";
import PoweredByGB from "components/utility/PoweredByGB";
import { Modal } from "flowbite-react";
import { useAuthContext } from "hooks/useAuthContext";
import useGetParticipants from "hooks/useGetParticipants";
import useParticipants from "hooks/useParticipants";
import NoGCSessionWrapper from "pages/NoGCSession/NoGCSessionWrapper";
import React, { useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
import classes from "./WaitingAreaFinal.module.css";
import { ReactComponent as CloseIcon } from "../../../assets/Xbutton.svg";
import { COMPLETED_ROUTE } from "strings/Routes";
import { useGCSession } from "hooks/useGCSession";
import { useGrowthCircleContext } from "hooks/useGrowthCircleContext";

const WaitingAreaFinal = () => {
  const currentStep = 5;

  const { profile } = useAuthContext();
  const { ParticipantRecords, ParticipantRecord, updateLevelProgress } =
    useParticipants();
  const { facilAllowNextPage } = useGCSession();
  const { getParticipantColourIndex } = useGetParticipants();
  const [everyoneSubmitted, setEveryoneSubmitted] = useState(false);
  const navigate = useNavigate();
  const [isVisible, setIsVisible] = useState(false);
  const { growthCircleSession } = useGrowthCircleContext();
  const [isSubmitting, setIsSubmitting] = useState(false);

  useEffect(() => {
    if (!profile || !ParticipantRecords) return;

    if (isVisible) {
      document.body.style.overflow = "hidden";
    } else {
      document.body.style.overflow = "unset";
    }

    const checkIfEveryoneSubmittedFeedback = () => {
      const allParticipants = ParticipantRecords.filter(
        (participant) => participant.feedbackReflectionStatus === "submitted"
      );

      if (allParticipants.length !== 0 && ParticipantRecords.length !== 0) {
        if (allParticipants.length === ParticipantRecords.length) {
          // console.log("everyone submitted");
          setEveryoneSubmitted(true);
        }
      }
    };
    checkIfEveryoneSubmittedFeedback();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [profile, ParticipantRecords, isVisible, growthCircleSession]);

  useEffect(() => {
    if (ParticipantRecord.length === 0) return;
    if (!ParticipantRecord[0] === undefined) return;
    // console.log(everyoneSubmitted);
    if (everyoneSubmitted && ParticipantRecord[0] !== undefined) {
      console.log("everyone submitted");
      setTimeout(() => {
        updateLevelProgress(ParticipantRecord[0]);
        console.log("updateLevel called");
      }, 1000);
      setTimeout(() => {
        console.log("navigate called");
        navigate(COMPLETED_ROUTE);
      }, 3000);
    }

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [ParticipantRecord, everyoneSubmitted]);

  const switchHandler = () => {
    setIsVisible(true);
    // console.log("Proceed Early");
  };

  const handleConfirm = () => {
    setIsVisible(false);
    if (profile?.isFacil) {
      facilAllowNextPage("allow-skip-feedback");
      navigate(COMPLETED_ROUTE);
    }
  };

  const nextHandler = async () => {
    setIsSubmitting(true);
    setIsVisible(false);
    if (profile?.isFacil) {
      await updateLevelProgress(ParticipantRecord[0]);
      navigate(COMPLETED_ROUTE);
    } else {
      await updateLevelProgress(ParticipantRecord[0]);
      navigate(COMPLETED_ROUTE);
    }
  };

  const modalHandler = () => {
    setIsVisible(!isVisible);
  };

  return (
    <NoGCSessionWrapper>
      <React.Fragment>
        <Modal show={isVisible} size="md" className="min-h-screen" popup={true}>
          <Modal.Body className="p-5 relative mt-[25%] md:mt-[5%]">
            <div className="absolute right-0 top-0 p-2">
              <CloseIcon className="cursor-pointer" onClick={modalHandler} />
            </div>
            <div className="text-center py-5 px-2">
              <h3 className="mb-5 py-4 text-lg font-semibold  text-gray-500 dark:text-gray-400">
                Would you like to proceed early?{" "}
                <span className="block text-sm font-normal mt-4">
                  {" "}
                  Please be aware that advancing early may lead to inaccurate
                  role progress outcomes.
                </span>
              </h3>

              <div className="flex justify-center gap-4">
                <button
                  type="button"
                  className={`${
                    profile?.isFacil ? "bg-facil-colour" : "bg-main-colour"
                  } shadow-md  px-12 rounded-3xl text-white py-3 hover:bg-green-700 transition duration-1000`}
                  onClick={handleConfirm}
                >
                  <span> Yes </span>
                </button>
                <button
                  type="button"
                  className={`${
                    profile?.isFacil ? "bg-facil-colour" : "bg-main-colour"
                  } shadow-md bg-facil-colour px-12 rounded-3xl py-3 text-white hover:bg-red-700 transition duration-1000`}
                  onClick={modalHandler}
                >
                  No
                </button>
              </div>
            </div>
          </Modal.Body>
        </Modal>
      </React.Fragment>
      <Header hasModal={isVisible} />
      <FiveStepHeader
        hasModal={isVisible}
        hasLightning={true}
        hasGuide={false}
        currentStep={currentStep}
        text="Completed"
      />
      <ContentWrapper hasFiveStepper={true}>
        <Center minHeight="100vh">
          <EmptySpace height={"50px"} />
          <>
            <h2 className={classes["waiting-text"]}>
              Waiting for others
              <span className={`${classes["waiting-text"]} ${classes["d1"]}`}>
                .
              </span>
              <span className={`${classes["waiting-text"]} ${classes["d2"]}`}>
                .
              </span>
              <span className={`${classes["waiting-text"]} ${classes["d3"]}`}>
                .
              </span>
            </h2>
            {/* participants */}

            <div className={classes["participants"]}>
              {ParticipantRecords?.map((feedbackForm, index) => {
                //TODO get back to displaying Triggers
                return (
                  <div className={classes["avatar-container"]} key={index}>
                    {profile?.isFacil && (
                      <FirstNameAvatar
                        name={
                          feedbackForm.userId === profile!.id
                            ? "ME"
                            : feedbackForm.userName
                        }
                        participant={feedbackForm}
                        participantNumber={getParticipantColourIndex(
                          feedbackForm.userId
                        )}
                      />
                    )}
                    {!profile?.isFacil && (
                      <FirstNameAvatar
                        name={
                          feedbackForm.userId === profile!.id
                            ? "ME"
                            : feedbackForm.userName
                        }
                        participant={feedbackForm}
                        participantNumber={getParticipantColourIndex(
                          feedbackForm.userId
                        )}
                      />
                    )}
                  </div>
                );
              })}
            </div>
            <EmptySpace height={"50px"} />
            {profile!.isFacil && !everyoneSubmitted && (
              <button
                type="button"
                className={`bg-facil-colour shadow-lg border-4 border-yellow-400 font-semibold py-4 px-6 text-white rounded-3xl hover:bg-green-500 hover:border-green-600 transition duration-1000`}
                onClick={switchHandler}
              >
                Proceed Early
              </button>
            )}
            {!profile!.isFacil &&
              growthCircleSession?.allowSkipFeedBackForm && (
                <button
                  type="button"
                  className={`bg-main-colour shadow-lg border-4 border-red-500 font-semibold py-3 px-8 text-white rounded-3xl hover:bg-green-500 hover:border-green-600 transition duration-1000`}
                  onClick={nextHandler}
                >
                  {isSubmitting ? "Submitting..." : "Next"}
                </button>
              )}
          </>
          <PoweredByGB />
        </Center>
      </ContentWrapper>
    </NoGCSessionWrapper>
  );
};

export default WaitingAreaFinal;
