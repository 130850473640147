import { useEffect } from "react";
import classes from "./StepperModal.module.css";

interface Props {
  currentStep: number;
  currentTitle: string;
  currentDetails: Detail[];
}

interface Detail {
  icon: React.ReactElement;
  description: string;
}

const StepperModal: React.FC<Props> = ({
  currentStep,
  currentTitle,
  currentDetails,
}) => {
  useEffect(() => {
    // console.log(currentStep);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return (
    <div className={classes.modalStyle}>
      <div className={classes.stepContainer}>{currentStep}</div>
      <div className={classes.contentStyle}>
        <div className={classes.contentTitle}>{currentTitle}</div>
        {currentDetails.map((detail, i) => {
          return (
            <div key={i} className={classes.contentDetails}>
              <div className={classes.iconContainer}>{detail.icon}</div>
              <span className={classes.detailDescription}>
                {detail.description}
              </span>
            </div>
          );
        })}
      </div>
    </div>
  );
};

export default StepperModal;
