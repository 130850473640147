/* eslint-disable react-hooks/exhaustive-deps */
import { useCallback } from "react";

/**
 * Adds debounce https://levelup.gitconnected.com/debounce-in-javascript-improve-your-applications-performance-5b01855e086
 * to a method of choice
 * @param func: () => any - method of choice
 * @param delay: number - number of miliseconds you wish to delay (default is 400)
 */
const useDebounce = (func: () => any, delay: number) => {
  //default value of 400
  const debounceFunction = (func, delay = 400) => {
    let timer;
    return (...args) => {
      let self = this;
      clearTimeout(timer);
      timer = setTimeout(() => {
        func.apply(self, args);
      }, delay);
    };
  };
  const debounce = useCallback(debounceFunction(func, delay), []);
  return [debounce];
};

export default useDebounce;
