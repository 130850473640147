interface Props {
  arrowColor?: string;
}

const RightArrow: React.FC<Props> = ({ arrowColor }) => {
  return (
    <svg
      width="20"
      height="34"
      viewBox="0 0 10 14"
      fill="current"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M9.71398 6.11986L1.04614 0.119046C0.946111 0.0498323 0.829052 0.00930119 0.707639 0.0018425C0.586226 -0.00561619 0.465085 0.0202818 0.357335 0.0767326C0.249585 0.133183 0.15933 0.218036 0.0963449 0.322101C0.0333595 0.426166 4.19617e-05 0.545478 9.53674e-07 0.66712L9.53674e-07 12.6687C-8.01086e-05 12.7904 0.0331488 12.9098 0.0960855 13.014C0.159022 13.1182 0.249268 13.2031 0.357039 13.2596C0.464811 13.3162 0.586 13.3421 0.707472 13.3347C0.828943 13.3273 0.946064 13.2867 1.04614 13.2175L9.71398 7.21667C9.80228 7.15504 9.8744 7.073 9.9242 6.97752C9.974 6.88204 10 6.77595 10 6.66826C10 6.56058 9.974 6.45449 9.9242 6.35901C9.8744 6.26353 9.80228 6.18149 9.71398 6.11986Z"
        fill={arrowColor}
      />
    </svg>
  );
};

export default RightArrow;
