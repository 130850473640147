import "./SpecialCharactersPopup.css"
import PropTypes from 'prop-types';
import { IoIosCloseCircle } from "react-icons/io";

const SpecialCharactersPopup = ({ trigger, setTrigger, children }) => {
  if (!trigger) {
    return null;
  }

  return (
    <div className="popup">
      <div className="popup-inner">
        <IoIosCloseCircle 
        className="close-btn" 
        onClick={() => setTrigger(false)} 
        size={20} />
        {children}
      </div>
    </div>
  );
};

SpecialCharactersPopup.propTypes = {
  trigger: PropTypes.bool.isRequired,
  setTrigger: PropTypes.func.isRequired,
};

export default SpecialCharactersPopup;