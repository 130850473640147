import { ReactComponent as Effort } from "assets/ReflectionRating/Effort.svg";
import { ReactComponent as CareAndSupport } from "assets/ReflectionRating/CareAndSupport.svg";
import { ReactComponent as RoleIcon } from "assets/ReflectionRating/RoleIcon.svg";
import FeedbackRatingIcons from "components/NG/FeedbackRatingIcons";
import { Participant } from "interface/ParticipantInterface";
import { Badge, BadgeName } from "localStorage/badge";

type Props = {
  participants: Participant[]
  badges: Badge[]
  toggleBadge: (
    fromUserID: string,
    toUserID: string,
    badgeName: BadgeName
  ) => void
}

export default function RatingIconQuestion({
  participants,
  badges,
  toggleBadge,
}: Props) {

  return (
    <div>
      <p className="text-slate-600 font-[700] px-4 text-center select-none">
        Let’s put a check on the emojis that you think your group
        members deserve.
      </p>
      <div className="flex flex-col justify-center items-start mx-auto p-3">
        <div className="flex justify-center items-start gap-2 py-2">
          <Effort />{" "}
          <p
            style={{ color: "var(--main-colour)" }}
            className={` text-md text-left`}
          >
            Appreciate their <strong>Efforts</strong>
          </p>
        </div>
        <div className="flex justify-center items-start gap-2 py-2">
          <CareAndSupport />{" "}
          <p
            style={{ color: "var(--main-colour)" }}
            className={` text-md text-left`}
          >
            Appreciate their <strong>Care and Support</strong>
          </p>
        </div>
        <div className="flex justify-center items-start gap-2 py-2">
          <RoleIcon />{" "}
          <p
            style={{ color: "var(--main-colour)" }}
            className={`  text-md text-left`}
          >
            Appreciate the <strong>Role they played</strong>
          </p>
        </div>
      </div>
      <div className="w-full flex justify-center items-center">
        <table>
          <thead>
            <tr>
              <th></th>
              <th>Efforts</th>
              <th>Care & Support</th>
              <th>Role</th>
            </tr>
          </thead>
          <tbody>
            {
              participants.map((participant, index) => (
                <FeedbackRatingIcons
                  key={index}
                  participant={participant}
                  rating={badges.find(b => b.ToUserID === participant.id)}
                  toggleRating={toggleBadge}
                />
              ))}
          </tbody>
        </table>
      </div>
    </div>
  );
}
