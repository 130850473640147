import Organisation from "interface/OrganisationInterface";
import { getProperNameComponent } from "pages/AllCircles/GenericSessionPage/ComponentMapping";
import classes from "pages/AllCircles/Admin/Admin.module.css";

const RefreshOverview = ({ data }: { data: Organisation }) => {
  // Function to check if a component exists in the default components
  const isDefaultComponent = (component: string, index: number) => {
    return (
      data.defaultComponents && data.defaultComponents[index] === component
    );
  };

  return (
    <div className={`${classes["container"]} flex justify-center`}>
      <div
        className="flex-col flex gap-5 justify-center"
        style={{ color: "var(--text-colour)" }}
      >
        <p className="font-semibold px-10">
          Before proceeding with the reset to default layouts, please observe
          the distinctions between your current components and their stored
          default states. Each component is highlighted for clarity, with the
          background color indicating any differences in the component's index
          or arrangement.
        </p>
        <div className="flex gap-4 p-5 w-full min-h-[80vh] justify-center">
          <div className="w-full">
            <p className="text-2xl">Current Component Set-up</p>
            {data &&
              data.components.map((component, index) => (
                <div
                  key={index}
                  style={{ borderColor: "var(--main-colour)" }}
                  className={`border-2 my-4 grid grid-cols-4 relative items-center cursor-pointer w-full p-4 rounded-xl shadow-sm ${
                    !isDefaultComponent(component, index)
                      ? "bg-main-colour text-white"
                      : "bg-white"
                  }`}
                >
                  <h1 className="justify-self-start col-span-3 text-lg">
                    {`${index + 1}. ${getProperNameComponent(component)}`}
                  </h1>
                </div>
              ))}
          </div>

          <div className="w-full">
            <p className="text-2xl">Stored Default Component Set-up</p>
            {data &&
              data.defaultComponents &&
              data.defaultComponents.map((component, index) => (
                <div
                  key={index}
                  style={{ borderColor: "var(--main-colour)" }}
                  className={`border-2 my-4 grid grid-cols-4 relative items-center cursor-pointer w-full p-4 rounded-xl shadow-sm ${
                    !data.components.includes(component)
                      ? "bg-main-colour text-white"
                      : "bg-white"
                  }`}
                >
                  <h1 className="justify-self-start col-span-3 text-lg">
                    {`${index + 1}. ${getProperNameComponent(component)}`}
                  </h1>
                </div>
              ))}
            {data && !data.defaultComponents && (
              <div className="min-h-[40vh] flex flex-col justify-center items-center">
                <p className="justify-self-start font-semibold col-span-3 text-xl">
                  No available default components found.
                </p>
                <p className="text-center">
                  Simply exit the modal and save the current component layout by
                  checking the "Save as default component" checkbox.
                </p>
              </div>
            )}
          </div>
        </div>
      </div>
    </div>
  );
};

export default RefreshOverview;
