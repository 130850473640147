import classes from "./OwnRole.module.css";
import RolesIconMap from "assets/Roles/RolesIconMap";
import useParticipants from "hooks/useParticipants";
import Box from "components/utility/Box/Box";
import EmptySpace from "components/utility/EmptySpace";
import { OWN_ROLE_TITLE } from "strings/ViewChooseRoles";

const OwnRole = () => {
  const { role } = useParticipants();

  const OptionalIcon = () => {
    if (!role) return null;
    const CoreSkillIcon = RolesIconMap[role.core_skills];
    return (
      <Box
        height={"75px"}
        width={"75px"}
        border={"3px solid var(--main-colour)"}
      >
        <CoreSkillIcon fill={"var(--main-colour)"} />
      </Box>
    );
  };

  return (
    <Box width={"90%"} backgroundColor={"var(--secondary-colour-2-60)"}>
      <strong className={classes["title"]}>{OWN_ROLE_TITLE}</strong>
      <EmptySpace height={"10px"} />
      {OptionalIcon()}
      <EmptySpace height={"10px"} />
      <p className={classes["your-role"]}>{role?.role}</p>
    </Box>
  );
};

export default OwnRole;
