import { Dropdown } from "flowbite-react";
import { useEffect, useState } from "react";

interface Props {
  currSelected: string;
  setCurrSelected: (item: string) => any;
  listOfOptions: string[];
  disabled?: boolean;
}

const DropDown = ({
  currSelected,
  setCurrSelected,
  listOfOptions,
  disabled,
}: Props) => {
  const [curr, setCurr] = useState(currSelected);

  useEffect(() => {
    setCurr(currSelected);
  }, [currSelected]);

  return (
    <Dropdown
      style={{ backgroundColor: "#eb8181" }}
      className="px-1"
      label={curr}
      size="sm"
      disabled={disabled}
    >
      {listOfOptions.map((option, index) => (
        <Dropdown.Item
          key={index}
          className="my-1"
          onClick={() => {
            if (!disabled) {
              setCurrSelected(option);
              setCurr(option);
            }
          }}
        >
          {option}
        </Dropdown.Item>
      ))}
    </Dropdown>
  );
};

export default DropDown;
