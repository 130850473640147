import { useNestedCollection } from "./useNestedCollection";
import { useEffect, useState } from "react";
import { useAuthContext } from "./useAuthContext";
import {
  PartialParticipant,
  Participant,
} from "interface/ParticipantInterface";
import { DRAG_RATING_KEYS } from "./useDragRating";

const getDiceSelection = (participants: Participant[]) => {
  const array = new Array(9).fill(0);
  for (let i = 0; i < participants.length; i++) {
    const selectedDice = participants[i].diceIndex;
    array[selectedDice]++;
  }
  return array;
};

const getParticipantNames = (participants: Participant[]) => {
  return participants.map((participant) => {
    return participant.userName;
  });
};

const getTriggeredParticipants = (participants: Participant[]) => {
  return participants
    .filter((participant) => {
      const isTriggered = participant.triggered;
      const notReadByFacil =
        participant.triggerReadByFacil === false ||
        participant.triggerReadByFacil === undefined;
      return isTriggered && notReadByFacil;
    })
    .map((participant) => participant.userName);
};

const getTriggeredParticipantsForDisplay = (participants: Participant[]) => {
  return participants
    .filter((participant) => {
      const isTriggered = participant.triggered;

      return isTriggered;
    })
    .map((participant) => participant.userName);
};

const useGetParticipants = () => {
  const { profile } = useAuthContext();
  // const profileId = profile?.id ? profile.id : "";
  const growthCirclesId = profile?.growthCircle ? profile.growthCircle : "";
  const { documents } = useNestedCollection(
    ["GrowthCircles", "Participants"],
    [growthCirclesId],
    null,
    null
  );
  const [participants, setParticipants] = useState<Participant[]>([]);
  const [diceSelection, setDiceSelection] = useState<number[]>(
    new Array(9).fill(0)
  );
  const [participantNames, setParticipantsNames] = useState<string[]>([]);
  const [triggeredParticipants, setTriggeredParticipants] = useState<string[]>(
    []
  );

  const [triggeredParticipantsForDisplay, setTriggeredParticipantsForDisplay] =
    useState<string[]>([]);

  useEffect(() => {
    if (documents.length === 0) return;
    setParticipants(documents);
    setDiceSelection(getDiceSelection(documents));
    setParticipantsNames(getParticipantNames(documents));
    setTriggeredParticipants(getTriggeredParticipants(documents));
    setTriggeredParticipantsForDisplay(
      getTriggeredParticipantsForDisplay(documents)
    );
  }, [documents]);

  /**
   * Returns a 2d array of participants with the same rating for a checkIn key (have to be 2d because of the way it is rendered in JSX)
   * @param key - type of rating (individually, interpersonally, socially, overall)
   * @param participants - participants array
   */
  const getGroupedParticipantsByCheckInKey = (
    key: string,
    participants: Participant[]
  ) => {
    // console.log(participants);
    //Set otherValues to contain the other handles needed for the bar
    let result: Participant[][] = [];
    let groupArray: Participant[] = [];

    const participantsClone: Participant[] = [...participants]
      .filter((participant) => participant.checkIn)
      .sort(
        (participant1, participant2) =>
          participant1.checkIn[DRAG_RATING_KEYS[key].KEY] -
          participant2.checkIn[DRAG_RATING_KEYS[key].KEY]
      );

    for (let i = 0; i < participantsClone.length; i++) {
      const currCheckIn =
        participantsClone[i].checkIn[DRAG_RATING_KEYS[key].KEY];
      const prevCheckIn =
        participantsClone[i - 1]?.checkIn[DRAG_RATING_KEYS[key].KEY];
      if (currCheckIn !== prevCheckIn) {
        groupArray = [];
        result.push(groupArray);
      }
      groupArray.push(participantsClone[i]);
    }

    return result;
  };

  /**
   * Returns a 2d array of participants grouped by ORS rating key dynamically
   * @param participants - participants array
   */
  const getGroupedParticipantsByORSRatingKey = (participants) => {
    let result: Participant[][] = [];
    let groupArray: Participant[] = [];

    const participantsClone = [...participants].filter(
      (participant) => participant.orgCheckIn
    );

    if (!profile?.isFacil) {
      // Get participant that is the user
      const participantProfile: Participant | undefined =
        participantsClone.find(
          (participant) => participant.userId === profile?.id
        );

      groupArray = [];
      if (participantProfile) {
        groupArray.push(participantProfile);
        result.push(groupArray);
      }
    } else {
      // Get a unique list of rating keys dynamically
      const uniqueRatingKeys = [
        ...new Set(
          participantsClone.flatMap((participant) =>
            Object.keys(participant.orgCheckIn)
          )
        ),
      ];

      for (let i = 0; i < participantsClone.length; i++) {
        const participant = participantsClone[i];
        const currCheckIn = participant.orgCheckIn;

        if (i === 0) {
          groupArray = [];
          result.push(groupArray);
          groupArray.push(participant);
        } else {
          let isSameGroup = true;
          for (const key of uniqueRatingKeys) {
            if (currCheckIn[key] !== participantsClone[i - 1].orgCheckIn[key]) {
              isSameGroup = false;
              break;
            }
          }
          if (isSameGroup) {
            groupArray.push(participant);
          } else {
            groupArray = [];
            result.push(groupArray);
            groupArray.push(participant);
          }
        }
      }
    }

    return result;
  };

  /**
   * Accepts an userId (and potentially group of participants) to get colour
   * @param userId: string - userId of current profile
   * @param participants?: Participant[] - group of participants to get the colour
   */
  const getParticipantColourIndex = (
    userId: string,
    participants?: Participant[]
  ) => {
    //Based on documents sorted by createdAt, retrieve the participant's id
    const participantsUsed = participants ? participants : [...documents];
    const sortedParticipantsByDate: Participant[] = participantsUsed.sort(
      (participant1, participant2) => {
        //Colours arranged by joined date
        // return participant1.createdAt.toDate().getTime() - participant2.createdAt.toDate().getTime()
        return participant1.userId.localeCompare(participant2.userId);
      }
    );
    // console.log(userId);

    //return the index it belongs to
    const idx: number = sortedParticipantsByDate.findIndex((participant) => {
      return participant.userId === userId;
    });
    // console.log(idx);
    return idx;
  };

  /**
   * Get a participant of the current session by a ShareMessage or FeedbackForm.
   *
   * @param messageOrForm ShareMessage object or FeedbackForm object.
   * @returns Participant if found, PartialParticipant otherwise.
   */
  const getParticipant = (messageOrForm: {
    userId: string;
    userName: string;
  }): Participant | PartialParticipant => {
    const p = participants.find((p) => p.userId === messageOrForm.userId);
    return p
      ? p
      : {
          userId: messageOrForm.userId,
          userName: messageOrForm.userName,
        };
  };

  return {
    participants,
    participantNames,
    triggeredParticipants,
    triggeredParticipantsForDisplay,
    diceSelection,
    getGroupedParticipantsByCheckInKey,
    getGroupedParticipantsByORSRatingKey,
    getParticipantColourIndex,
    getParticipant,
  };
};

export default useGetParticipants;
