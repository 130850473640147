import { ShareMessage } from "interface/MessageInterface";
import { Participant } from "interface/ParticipantInterface";
import { createContext } from "react";

export interface ViewJournalState {
  activated: boolean[];
  setFirstActivated: () => void;
  setSecondActivated: () => void;
  setThirdActivated: () => void;
  userJournal: Participant;
  messages: ShareMessage[];
  participants: Participant[];
}

const ViewJournalContext = createContext<ViewJournalState>(
  {} as ViewJournalState
);

export default ViewJournalContext;
