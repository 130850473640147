import { FC, useEffect, useState } from "react";
import TitleBox from "../SubComponents/TitleBox";
import { ComponentProps } from "../SelfAwarenessComponentMapping";
import { useSelfAwarenessContext } from "hooks/useSelfAwarenessContext";
import BackAndNextFlat from "components/utility/BackAndNextFlat";
import AutoResizeTextArea from "components/utility/Inputs/AutoResizeTextArea";
import { Modal } from "flowbite-react";
import { AiFillCloseCircle } from "react-icons/ai";
import classes from "pages/AllCircles/Admin/Admin.module.css";
import ConfirmModal from "../SubComponents/ConfirmModal";

const ListSelectorReflection: FC<ComponentProps> = ({
  backAndNextHandler,
  setHasModal,
}) => {
  const [show, setShow] = useState<boolean>(false);
  const [showConfirm, setShowConfirm] = useState<boolean>(false);
  const { journal } = useSelfAwarenessContext();
  const [reflection, setReflection] = useState<string>("");
  const handler = () => {
    setShow(!show);
    setHasModal(!show);
  };

  const [selectedItems, setSelectedItems] = useState<string[]>([]);

  // Load data from local storage on component mount
  useEffect(() => {
    const storedJournal = localStorage.getItem("SelfAwarenessJournal");
    const existingJournal = storedJournal ? JSON.parse(storedJournal) : {};
    if (existingJournal && existingJournal.listSelected) {
      setSelectedItems(existingJournal.listSelected);
    }
    // eslint-disable-next-line
  }, []);

  useEffect(() => {
    if (!journal) return;
  }, [journal]);

  const answerHandler = (value: string) => {
    setReflection(value);
    const storedJournal = localStorage.getItem("SelfAwarenessJournal");
    const existingJournal = storedJournal ? JSON.parse(storedJournal) : {};

    // Update the existing journal with the new answer
    const updatedJournal = {
      ...existingJournal,
      reflection: value,
    };

    // Store the updated journal in local storage
    localStorage.setItem(
      "SelfAwarenessJournal",
      JSON.stringify(updatedJournal)
    );
  };

  const nextHandler = () => {
    setShowConfirm(!showConfirm);
    setHasModal(!showConfirm);
  };

  return (
    <>
      <ConfirmModal
        showConfirm={showConfirm}
        setHasModal={setHasModal}
        backAndNextHandler={backAndNextHandler}
        handler={nextHandler}
      />
      <Modal show={show} popup={true} size={"sm"}>
        <div
          className={`${classes["container"]} p-5 relative flex flex-col`}
          style={{ color: "var(--text-colour)" }}
        >
          <AiFillCloseCircle
            style={{ color: "var(--icon-colour-0)" }}
            size={30}
            className="absolute top-2 right-2 cursor-pointer"
            onClick={handler}
          />
          <p className="text-center font-semibold">
            What you are grateful for:
          </p>
          <div
            className="max-h-[300px] overflow-y-auto my-5 border-2 rounded-xl"
            style={{ borderColor: "#FFE871" }}
          >
            {selectedItems.map((item, index) => (
              <div key={index} className="px-5 select-none">
                <div
                  style={{ borderBottomColor: "var(--icon-colour-0)" }}
                  className={`border-b-2 p-2 flex gap-2 items-center`}
                >
                  {item}
                </div>
              </div>
            ))}
          </div>
          <button
            onClick={handler}
            className={`${classes["button"]} w-1/2 self-center`}
          >
            Okay
          </button>
        </div>
      </Modal>{" "}
      <div className="py-8 px-4 w-full md:w-1/4">
        {journal && (
          <TitleBox
            journal={journal}
            handler={handler}
            showInfo={true}
            title={`Share more. What are you particularly thankful about?`}
          />
        )}
        <div
          className="border-2 p-5 my-8 bg-white rounded-lg font-poppins  "
          style={{
            borderColor: "#FFE871",
            color: "var(--text-colour)",
          }}
        >
          <AutoResizeTextArea
            row={10}
            className="border-none w-full rounded-lg placeholder:text-gray-300"
            onChange={(value) => answerHandler(value)}
            value={reflection}
            placeholder="Write your answer here. (Max length 500)"
            maxLength={500}
          />
        </div>
        <div className="my-4">
          <BackAndNextFlat
            {...backAndNextHandler}
            nextCallback={nextHandler}
            nextAllowed={reflection.length > 0 ? true : false}
          />
        </div>
      </div>
    </>
  );
};

export default ListSelectorReflection;
