import { FeedbackQuestion } from "models/componentSettings/feedback/utility/feedback";
import FeedbackQuestionSortCard from "./FeedbackQuestionSortCard";

interface FeedbackSortProps {
  questions: FeedbackQuestion[];
  setOrder: Function;
  order: string[];
}

const FeedbackQuestionModalSorting = ({
  questions,
  setOrder,
  order,
}: FeedbackSortProps) => {
  return (
    <div className="flex flex-wrap gap-2 py-4">
      {questions &&
        questions.map((question, index) => (
          <FeedbackQuestionSortCard
            order={order}
            setOrder={setOrder}
            key={index}
            question={question}
          />
        ))}
    </div>
  );
};

export default FeedbackQuestionModalSorting;
