import BackAndNext from "components/utility/BackAndNext";
import EmptySpace from "components/utility/EmptySpace";
import { useAuthContext } from "hooks/useAuthContext";
import React, { useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
import { COMPLETED_ROUTE, FINISHED_ROUTE } from "strings/Routes";
import useParticipants from "hooks/useParticipants";
import useParticipantFeedback from "hooks/useParticipantFeedback";
import { Modal } from "flowbite-react";
import { ReactComponent as CloseIcon } from "assets/Xbutton.svg";
import { useEmailAPI } from "hooks/useEmailAPI";
import { useGrowthCircleContext } from "hooks/useGrowthCircleContext";
import {
  checkCanAdvanceNextActivity,
  checkCanSkipSaveJournal,
} from "models/organisationRole";
import FeedbackForm from "components/FeedBackForm/FeedbackForm";
import FeedbackFormTitle from "components/FeedBackForm/FeedbackFormTitle";
import QuestionGroup from "components/FeedBackForm/QuestionGroup";
import { getGroupFeedbackQuestions } from "models/componentSettings/feedback/groupFeedback";
import TextAreaQuestion from "components/FeedBackForm/TextAreaQuestion";
import RatingIconQuestion from "components/FeedBackForm/RatingIconQuestion";
import useBadges from "hooks/feedback/useBadges";
import { FeedbackProps } from "./FeedbackAfterComponent";
import { saveJournal } from "models/journal";
import SpecialThanks from "components/SpecialThanks/SpecialThanks";

const FeedbackGroupComponent = ({
  backAndNextHandler,
  setHasModal,
  setTabNumber,
  tabNumber,
}: FeedbackProps) => {
  const { profile } = useAuthContext();
  const [isVisible, setIsVisible] = useState(false);
  const [isSubmitting, setIsSubmitting] = useState(false);
  const { sendMailWantToBeFacil } = useEmailAPI();
  const { growthCircleSession } = useGrowthCircleContext();
  const { sessionRole } = useParticipants();
  const { badges, toggleBadge } = useBadges();
  //console.log("journal");
  const navigate = useNavigate();
  const {
    ParticipantRecords,
    ParticipantRecord,
    updateFeedbackReflectionStatus,
    updateLevelProgress,
  } = useParticipants();

  const groupFeedbackQuestions = getGroupFeedbackQuestions(
    growthCircleSession?.organisation ?? ""
  );
  const questionCategory = groupFeedbackQuestions.category;

  const appreaciationQuestion =
    groupFeedbackQuestions.subCategories[2].questions[0];
  const [whatElseToAppreciate, setWhatElseToAppreciate] =
    useParticipantFeedback(
      appreaciationQuestion.defaultResponse + "",
      questionCategory,
      appreaciationQuestion.questionName
    );

  const becomeFacilQuestion =
    groupFeedbackQuestions.subCategories[3].questions[0];
  const [selectedValue, setSelectedValue] = useParticipantFeedback(
    becomeFacilQuestion.defaultResponse + "",
    questionCategory,
    becomeFacilQuestion.questionName
  );

  const canSkipSaveJournal = checkCanSkipSaveJournal(sessionRole);

  const handleRadioChange = (event) => {
    setSelectedValue(event.target.value);
  };

  useEffect(() => {
    if (!ParticipantRecords) return;
    if (!profile) return;

    if (isVisible) {
      document.body.style.overflow = "hidden";
    } else {
      document.body.style.overflow = "unset";
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [isVisible, profile, ParticipantRecords]);

  useEffect(() => {
    if (!ParticipantRecord) return;
  }, [ParticipantRecord]);

  const nextHandler = () => {
    setIsVisible(true);
    setHasModal(true);
  };

  const nextHandlerSubmitted = async () => {
    if (canSkipSaveJournal) {
      navigate(COMPLETED_ROUTE);
    } else {
      navigate(FINISHED_ROUTE);
    }
  };
  //console.log("feedback");
  const handleConfirm = async () => {
    setIsSubmitting(true);
    // if (checkCanAdvanceNextActivity(sessionRole)) {
    //   facilAllowNextPage("reflection-rating");
    // }
    if (!checkCanAdvanceNextActivity(sessionRole) && selectedValue === "yes") {
      sendMailWantToBeFacil(profile?.email!, profile?.displayName!);
    }
    await updateFeedbackReflectionStatus();
    setIsSubmitting(false);
    setIsVisible(false);
    setHasModal(false);

    // // console.log("confirm click", sessionRole);
    if (canSkipSaveJournal) {
      navigate(COMPLETED_ROUTE);
    } else {
      if (growthCircleSession) {
        await saveJournal(growthCircleSession, ParticipantRecord[0]);
      }
      await updateLevelProgress(ParticipantRecord[0]);
      console.debug("updateLevel called");
      navigate(FINISHED_ROUTE);
    }
  };

  const backHandler = () => {
    if (tabNumber === 2) {
      setTabNumber(1);
    } else {
      setTabNumber(2);
    }
  };

  const sortedParticipantRecords = [...ParticipantRecords].sort((a, b) => {
    if (a.userId === profile?.uid) return 1;
    if (b.userId === profile?.uid) return -1;
    return 0;
  });

  const modalHandler = () => {
    setIsVisible(!isVisible);
    setHasModal(!isVisible);
  };

  return (
    <React.Fragment>
      <React.Fragment>
        <Modal
          show={isVisible}
          size="md"
          className="min-h-screen "
          popup={true}
        >
          <Modal.Body className="p-5 relative mt-[5%] md:mt-[5%] ">
            {!isSubmitting && (
              <div className="absolute right-0 top-0 p-2">
                <CloseIcon className="cursor-pointer" onClick={modalHandler} />
              </div>
            )}
            <div className="text-center py-5 px-2">
              <h3 className="mb-5 py-4 text-lg font-semibold  text-gray-500 dark:text-gray-400">
                {!isSubmitting && (
                  <>
                    Do you want to submit?{" "}
                    <span className="block"> You can only do this once.</span>
                  </>
                )}
                {isSubmitting && <>Submitting Please wait..</>}
              </h3>

              <div className="flex justify-center gap-4">
                <button
                  type="button"
                  disabled={isSubmitting}
                  style={
                    !isSubmitting
                      ? { backgroundColor: "var(--main-colour)" }
                      : { backgroundColor: "grey" }
                  }
                  className={`shadow-md text-white  px-12 rounded-3xl  transition duration-1000 py-4`}
                  onClick={handleConfirm}
                >
                  <span>{isSubmitting ? "Submitting..." : "Yes"}</span>
                </button>
                {!isSubmitting && (
                  <button
                    type="button"
                    style={{ backgroundColor: "var(--main-colour)" }}
                    className={`shadow-md  px-12 rounded-3xl py-3 text-white hover:bg-red-700 transition duration-1000`}
                    onClick={modalHandler}
                  >
                    No
                  </button>
                )}
              </div>
            </div>
          </Modal.Body>
        </Modal>
      </React.Fragment>

      {ParticipantRecord[0]?.feedbackReflectionStatus !== "submitted" && (
        <React.Fragment>
          <EmptySpace height={"15px"} />
          <FeedbackForm activeTab={3} handleSwitchTabs={(n) => setTabNumber(n)}>
            <FeedbackFormTitle>For the Group</FeedbackFormTitle>
            <QuestionGroup
              questionCategory={questionCategory}
              questions={groupFeedbackQuestions.subCategories[0].questions}
              sessionRole={sessionRole}
            />
            <QuestionGroup>
              <RatingIconQuestion
                participants={sortedParticipantRecords}
                badges={badges}
                toggleBadge={toggleBadge}
              />
            </QuestionGroup>
            <QuestionGroup>
              <TextAreaQuestion
                label={
                  appreaciationQuestion.question[sessionRole?.name ?? ""] ??
                  appreaciationQuestion.question.default
                }
                value={whatElseToAppreciate}
                onChange={(event) =>
                  setWhatElseToAppreciate(event.target.value)
                }
              />
            </QuestionGroup>
            {!checkCanAdvanceNextActivity(sessionRole) && (
              <QuestionGroup>
                <p className="text-slate-600 font-[700] px-4 text-left select-none">
                  {becomeFacilQuestion.question[sessionRole?.name ?? ""] ??
                    becomeFacilQuestion.question.default}
                </p>
                <div>
                  {selectedValue !== undefined && (
                    <div className="flex justify-start items-center gap-4 py-4 px-4">
                      <input
                        type="radio"
                        name="facil"
                        id="yes"
                        value="yes"
                        checked={selectedValue === "yes"}
                        onChange={handleRadioChange}
                        style={
                          selectedValue === "yes"
                            ? { backgroundColor: "var(--main-colour)" }
                            : {}
                        }
                        className={` appearance-none  ring-transparent bg-transparent h-4 w-4 checked:background-none shadow-md`}
                      />
                      <label htmlFor="yes">Yes</label>
                      <input
                        type="radio"
                        name="facil"
                        id="no"
                        value="no"
                        checked={selectedValue === "no"}
                        onChange={handleRadioChange}
                        style={
                          selectedValue === "no"
                            ? { backgroundColor: "var(--main-colour)" }
                            : {}
                        }
                        className={` appearance-none  ring-transparent bg-transparent h-4 w-4 checked:background-none shadow-md`}
                      />

                      <label htmlFor="no">No</label>
                    </div>
                  )}
                </div>
              </QuestionGroup>
            )}
            <SpecialThanks
              setHasModal={setHasModal}
              participants={ParticipantRecords}
            />
          </FeedbackForm>
          <EmptySpace height={"50px"} />
          <BackAndNext
            {...backAndNextHandler}
            nextAllowed={
              whatElseToAppreciate !== "" &&
              selectedValue !== "" &&
              backAndNextHandler.nextAllowed
            }
            nextCallback={nextHandler}
            backCallback={backHandler}
          />
        </React.Fragment>
      )}

      {ParticipantRecord[0]?.feedbackReflectionStatus === "submitted" && (
        <React.Fragment>
          <EmptySpace height={"15px"} />
          <div className="w-full flex flex-col justify-center  items-center">
            <h1 className="mt-5 font-semibold">
              It appears that you have already submitted your feedback form.
              Please proceed to the next step.
            </h1>
            <button
              style={{ backgroundColor: "var(--main-colour)" }}
              className="border-4 border-[var(--main-colour)]   text-white rounded-3xl mt-5 shadow-md px-8 py-4 hover:bg-green-600 hover:border-green-400 transition duration-1000"
              onClick={nextHandlerSubmitted}
            >
              Proceed to next session
            </button>
          </div>
          <EmptySpace height={"50px"} />
        </React.Fragment>
      )}
    </React.Fragment>
  );
};

export default FeedbackGroupComponent;
