import { FC, useEffect, useState } from "react";
import { ComponentProps } from "../SelfAwarenessComponentMapping";
import { useSelfAwarenessContext } from "hooks/useSelfAwarenessContext";
import { useSelfAwareness } from "hooks/useSelfAwareness";
import { GradingQuestionInterface } from "interface/GradingQuestionInterface";
import SimpleLoader from "components/Loaders/SimpleLoader";
import BackAndNextFlat from "components/utility/BackAndNextFlat";
import TitleBox from "../SubComponents/TitleBox";
import InfoModal from "../SubComponents/InfoModal";
import TextAreaReflection from "../SubComponents/TextAreaReflection";
import toast from "react-hot-toast";

const GradingItemReflection: FC<ComponentProps> = ({
  backAndNextHandler,
  setHasModal,
}) => {
  const { journal } = useSelfAwarenessContext();
  const [show, setShow] = useState<boolean>(false);
  const { getGradingQuestions } = useSelfAwareness();
  const [questions, setQuestions] = useState<GradingQuestionInterface[]>([]);
  const [gradingItem, setGradingItems] = useState<Object>({});

  const handler = () => {
    setShow(!show);
    setHasModal(!show);
  };

  const setGradingQuestion = async () => {
    if (journal) {
      const _questions = (await getGradingQuestions(
        journal.id || ""
      )) as GradingQuestionInterface[];
      setQuestions(_questions);
    }
  };

  useEffect(() => {
    const storedJournal = localStorage.getItem("SelfAwarenessJournal");
    const existingJournal = storedJournal ? JSON.parse(storedJournal) : {};

    if (existingJournal.gradingItems) {
      setGradingItems(existingJournal.gradingItems);
    }
  }, []);

  const nextHandler = (e: React.MouseEvent<HTMLButtonElement, MouseEvent>) => {
    const storedJournal = localStorage.getItem("SelfAwarenessJournal");
    const existingJournal = storedJournal ? JSON.parse(storedJournal) : {};
    const gradingItemsKeys = Object.keys(existingJournal.questionList || {});

    // Check if all grading items are filled
    const allGradingItemsFilled = gradingItemsKeys.every((key) => {
      const item = existingJournal.questionList[key];
      return item.trim() !== "";
    });

    if (
      existingJournal &&
      existingJournal?.questionList &&
      allGradingItemsFilled
    ) {
      backAndNextHandler.nextCallback(e);
    } else {
      toast.error("Please answer all questions");
    }
  };

  useEffect(() => {
    if (!journal) return;
    setGradingQuestion();

    // eslint-disable-next-line
  }, [journal]);
  return (
    <div className="py-8 px-4 w-full md:w-1/4">
      {journal && <InfoModal handler={handler} show={show} journal={journal} />}
      {journal && (
        <>
          <TitleBox
            title={`What factors contributed to your ratings?`}
            showInfo={true}
            journal={journal}
            handler={handler}
          />

          {journal &&
            questions.length > 0 &&
            questions.map((question, index) => (
              <div key={index} className="mt-4">
                <p
                  className="font-semibold"
                  style={{ color: "var(--text-colour)" }}
                >
                  {question.category}: {gradingItem[question.category]}
                </p>
                <TextAreaReflection
                  question={question}
                  row={5}
                  journal={journal}
                  maxLength={500}
                />
              </div>
            ))}

          {questions && questions.length === 0 && (
            <div className="min-h-[200px] flex justify-center items-center mx-auto">
              <SimpleLoader />
            </div>
          )}

          <div className="mt-5">
            <BackAndNextFlat
              {...backAndNextHandler}
              nextCallback={(e) => nextHandler(e)}
            />
          </div>
        </>
      )}
    </div>
  );
};

export default GradingItemReflection;
