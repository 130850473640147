import classes from './ProgressBar.module.css'

export default function ProgressBar({value}) {
  return (
    <div className={classes['progress-bar-container']}>
       <div className={classes['progress-bar']} style={{width: value+'%'}}>
            <span>{value}%</span> 
       </div>
    </div>
  )
}
