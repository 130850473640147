import React from "react";

interface Props {
  setIsCompressed: (b: boolean) => any;
}

const ExpandButton: React.FC<Props> = ({ setIsCompressed }) => {
  const onClickExpand = (event: React.MouseEvent<SVGSVGElement>) => {
    event.stopPropagation();
    setIsCompressed(false);
  };

  return (
    <svg
      width="40"
      height="40"
      viewBox="0 0 40 40"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      onClick={onClickExpand}
    >
      <rect width="40" height="40" rx="10" fill="#EB8181" />
      <path
        d="M12 12L16.4444 16.4M12 12V16M12 12H16M12 27.9556L16.4444 23.5556M28 27.9556L23.6 23.5556M27.9556 12L23.5556 16.4M24 12H28V16M28 24V28H24M16 28H12V24"
        stroke="#FDF5E6"
        strokeWidth="2"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
    </svg>
  );
};

export default ExpandButton;
