/* eslint-disable react/jsx-no-target-blank */
import classes from "./ResourceRoot.module.css";
import useOrganisationContext from "hooks/organisation/useOrganisationContext";
import { useEffect } from "react";
import { useResourcesOrganisation } from "hooks/useResourcesOrganisation";
import PageWrapper from "components/utility/PageWrapper";
import Header from "components/Header/Header";
import EmptySpace from "components/utility/EmptySpace";

/**
 * Page that shows the ground rules for users when they first start a GC session
 */
const ResourceLandingPage = () => {
  const { selectedOrganisation } = useOrganisationContext();
  const { resources } = useResourcesOrganisation();

  useEffect(() => {
    // eslint-disable-next-line
  }, [selectedOrganisation]);

  return (
    <PageWrapper pageType={"groundRules"}>
      <Header />

      <EmptySpace height="50px" />
      <div
        className={`max-w-md ${classes["list-container"]} w-full mt-20 py-5 mb-[200px]`}
      >
        {resources.length > 0 && (
          <>
            <div className="font-semibold py-4">Organisation Resources</div>
            <ul>
              {resources.map((res, ind) => (
                <a key={ind} href={res.url} target="_blank">
                  <li className="break-words">{res.title}</li>
                </a>
              ))}
            </ul>
          </>
        )}
        <ul>
          <div className="font-semibold py-4">General Resources</div>
          <a
            href="https://www.imh.com.sg/CHAT/Pages/default.aspx"
            target="_blank"
          >
            <li>www.imh.com.sg</li>
          </a>
          <a href="https://mindline.sg/" target="_blank">
            <li>mindline.sg</li>
          </a>
          <a
            href="https://www.healthhub.sg/programmes/186/MindSG/Discover"
            target="_blank"
          >
            <li>www.healthhub.sg</li>
          </a>
          <a href="https://aic.buzz/Top5_MH" target="_blank">
            <li>aic.buzz</li>{" "}
          </a>
          <a href="https://aic.buzz/MHListing" target="_blank">
            <li>aic.buzz/MHListing</li>{" "}
          </a>
          <a href="https://aic.buzz/mindmatters-directory" target="_blank">
            <li>aic.buzz/mindmatters-directory</li>
          </a>
        </ul>
      </div>
    </PageWrapper>
  );
};

// https://mindline.sg/
// https://www.healthhub.sg/programmes/186/MindSG/Discover
// https://aic.buzz/Top5_MH
// https://aic.buzz/MHListing
// aic.buzz/mindmatters-directory

export default ResourceLandingPage;
