import { DocumentData } from "firebase/firestore";
import { ORSInterface } from "interface/ORSInterface";
import ORSSortCard from "./ORSSortCard";

interface ORSsortProps {
  questions: DocumentData[];
  setOrder: Function;
  order: string[];
}

const ORSSortComponent = ({ questions, setOrder, order }: ORSsortProps) => {
  const orsQuestions: ORSInterface[] = questions.map(
    (item: DocumentData) => item as ORSInterface
  );

  return (
    <div className="flex flex-wrap gap-2 py-4">
      {orsQuestions &&
        orsQuestions.map((question, index) => (
          <ORSSortCard
            order={order}
            setOrder={setOrder}
            key={index}
            question={question}
          />
        ))}
    </div>
  );
};

export default ORSSortComponent;
