import { useState } from "react";
import { stepperContent } from "components/utility/UtilityList";


// use: const {isStepperModalOpen, setIsStepperModalOpen, headerText, currentDetails} = useStepperModal(currentStep);
const useStepperModal = (currentStep: number) => {
    let headerText = stepperContent[currentStep].title;
    const currentDetails = stepperContent[currentStep].details;
  
    const [isStepperModalOpen, setIsStepperModalOpen] = useState(false);

    return {isStepperModalOpen, setIsStepperModalOpen, headerText, currentDetails};
}

export default useStepperModal;
