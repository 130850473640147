import { FC, useEffect, useState } from "react";
import { ComponentProps } from "../SelfAwarenessComponentMapping";

import { useSelfAwarenessContext } from "hooks/useSelfAwarenessContext";
import TitleBox from "../SubComponents/TitleBox";

import BackAndNextFlat from "components/utility/BackAndNextFlat";
import DecisionSelector from "../SubComponents/DecisionSelector";
import DecisionCriteria from "../SubSetsComponents/DecisionCriteria";
import ValueRisk from "../SubSetsComponents/ValueRisk";

const DecisionSelection: FC<ComponentProps> = ({
  backAndNextHandler,
  setHasModal,
}) => {
  const [selectedItem, setSelectedItem] = useState<string | null>(null);

  const [localComponent, setLocalComponent] = useState<string>(
    "DecisionSelection" || "DecisionCriteria" || "ValueRisk"
  );
  const { journal } = useSelfAwarenessContext();

  // Load data from local storage on component mount
  useEffect(() => {
    const storedJournal = localStorage.getItem("SelfAwarenessJournal");
    if (storedJournal) {
      const parsedJournal = JSON.parse(storedJournal);
      if (parsedJournal && parsedJournal.decision) {
        setSelectedItem(parsedJournal.decision);
      }
    }
    // eslint-disable-next-line
  }, []); // Empty dependency array means this effect runs once on mount

  const nextHandler = (e: React.MouseEvent<HTMLButtonElement, MouseEvent>) => {
    setLocalComponent("ValueRisk");
    const storedJournal = localStorage.getItem("SelfAwarenessJournal");
    if (storedJournal) {
      const parsedJournal = JSON.parse(storedJournal);
      const parser = new DOMParser();

      if (parsedJournal.decision) {
        const parsedDocument = parser.parseFromString(
          parsedJournal.decision,
          "text/html"
        );
        const parsedTitle = parsedDocument.body.textContent || "";

        if (parsedTitle === "The Decision Criteria") {
          setLocalComponent("DecisionCriteria");
        } else if (parsedTitle === "Value - Risk - Resources - Need (VRRN)") {
          setLocalComponent("ValueRisk");
        } else {
          setLocalComponent("DecisionSelection");
        }
      }
    }
  };

  const handleCardSelect = (title: string) => {
    setSelectedItem((prevSelectedItem) =>
      prevSelectedItem === title ? null : title
    );

    const storedJournal = localStorage.getItem("SelfAwarenessJournal");
    const existingJournal = storedJournal ? JSON.parse(storedJournal) : {};

    // Update the existing journal with the new answer
    const updatedJournal = {
      ...existingJournal,
      decision: title,
    };

    // Store the updated journal in local storage
    localStorage.setItem(
      "SelfAwarenessJournal",
      JSON.stringify(updatedJournal)
    );
  };

  useEffect(() => {
    if (!journal) return;
  }, [journal]);
  return (
    <div className="py-8 px-4 w-full md:w-1/4">
      {journal && (
        <>
          {localComponent === "DecisionSelection" && (
            <>
              <TitleBox
                journal={journal}
                title="What do you need to decide on?"
                showInfo={true}
                size="small"
                handler={() => {}}
              />
              <DecisionSelector
                journal={journal}
                selectedItem={selectedItem}
                handleCardSelect={handleCardSelect}
              />
              <div className="my-4">
                <BackAndNextFlat
                  {...backAndNextHandler}
                  nextCallback={(e) => nextHandler(e)}
                  nextAllowed={
                    selectedItem && selectedItem?.length > 0 ? true : false
                  }
                />
              </div>
            </>
          )}

          {localComponent === "DecisionCriteria" && (
            <>
              <DecisionCriteria
                backAndNextHandler={backAndNextHandler}
                journal={journal}
                setLocalComponent={setLocalComponent}
                setHasModal={setHasModal}
              />
            </>
          )}

          {localComponent === "ValueRisk" && (
            <>
              <ValueRisk
                backAndNextHandler={backAndNextHandler}
                journal={journal}
                setLocalComponent={setLocalComponent}
                setHasModal={setHasModal}
              />
            </>
          )}
        </>
      )}
    </div>
  );
};

export default DecisionSelection;
