import { FC, useEffect, useState } from "react";
import TitleBox from "../SubComponents/TitleBox";

import { useSelfAwarenessContext } from "hooks/useSelfAwarenessContext";
import BackAndNextFlat from "components/utility/BackAndNextFlat";
import { FaPlusCircle } from "react-icons/fa";
import classes from "pages/AllCircles/Admin/Admin.module.css";
import SimpleLoader from "components/Loaders/SimpleLoader";
import { ComponentProps } from "../SelfAwarenessComponentMapping";

import InfoModal from "../SubComponents/InfoModal";
import btnClasses from "components/utility/BackAndNext.module.css";
import BreakDownInput from "../SubComponents/BreakDownInput";
import toast from "react-hot-toast";

const DissectKeyBreakdown: FC<ComponentProps> = ({
  backAndNextHandler,
  setHasModal,
}) => {
  const [show, setShow] = useState<boolean>(false);
  const { journal } = useSelfAwarenessContext();

  const handler = () => {
    setShow(!show);
    setHasModal(!show);
  };

  const [dissectKeys, setDissectKeys] = useState<string[]>([]);
  const [isLoading, setLoading] = useState<boolean>(false);
  const [activeIndex, setActiveIndex] = useState<number>(0);
  const [breakDowns, setBreakDowns] = useState<string[]>([]);

  const [focusKey, setFocusKey] = useState<string>("");

  useEffect(() => {
    const storedJournal = localStorage.getItem("SelfAwarenessJournal");
    const existingJournal = storedJournal ? JSON.parse(storedJournal) : {};

    if (existingJournal.dissectKeys) {
      setDissectKeys(existingJournal.dissectKeys);
    }

    if (existingJournal.dissectKeyValues) {
      setFocusKey(existingJournal.dissectKeys[activeIndex]);
      const _focus = existingJournal.dissectKeys[activeIndex];

      setBreakDowns(existingJournal.dissectKeyValues[_focus] || []);
    } else {
      setFocusKey(existingJournal.dissectKeys[activeIndex]);
    }

    // eslint-disable-next-line
  }, []);

  useEffect(() => {
    const storedJournal = localStorage.getItem("SelfAwarenessJournal");
    const existingJournal = storedJournal ? JSON.parse(storedJournal) : {};

    const _focus = existingJournal.dissectKeys[activeIndex];
    if (existingJournal.dissectKeyValues) {
      setBreakDowns(existingJournal.dissectKeyValues[_focus] || []);
      setFocusKey(existingJournal.dissectKeys[activeIndex]);
    }
  }, [activeIndex]);

  const addStepsHandler = () => {
    // Assuming challenges is an array in your state
    const newSteps = ""; // Replace with your actual default data
    setBreakDowns((prevSteps) => [...prevSteps, newSteps]);

    const storedJournal = localStorage.getItem("SelfAwarenessJournal");
    const existingJournal = storedJournal ? JSON.parse(storedJournal) : {};

    // Ensure dissectKeyValues is initialized as an empty object
    existingJournal.dissectKeyValues = existingJournal.dissectKeyValues || {};

    const existingSteps = existingJournal.dissectKeyValues[focusKey] || [];

    // Update the local storage with the modified challenges array
    const updatedJournal = {
      ...existingJournal,
      dissectKeyValues: {
        ...existingJournal.dissectKeyValues,
        [focusKey]: [...existingSteps, newSteps], // Add the new challenge
      },
    };
    localStorage.setItem(
      "SelfAwarenessJournal",
      JSON.stringify(updatedJournal)
    );
  };

  const removeHandler = (index: number) => {
    const storedJournal = localStorage.getItem("SelfAwarenessJournal");
    const existingJournal = storedJournal ? JSON.parse(storedJournal) : {};
    const existingSteps = existingJournal.dissectKeyValues[focusKey] || [];
    const updatedSteps = existingSteps.filter((_, i) => i !== index);

    // Update the local storage with the modified steps array
    existingJournal.dissectKeyValues[focusKey] = updatedSteps;
    localStorage.setItem(
      "SelfAwarenessJournal",
      JSON.stringify(existingJournal)
    );

    setBreakDowns(updatedSteps); // Update the state
    refreshValues();
  };

  const refreshValues = () => {
    setLoading(true);

    const storedJournal = localStorage.getItem("SelfAwarenessJournal");
    const existingJournal = storedJournal ? JSON.parse(storedJournal) : {};

    if (
      existingJournal &&
      existingJournal.dissectKeyValues &&
      existingJournal.dissectKeyValues[focusKey]
    ) {
      setBreakDowns(existingJournal.dissectKeyValues[focusKey]);
    }

    setTimeout(() => {
      setLoading(false);
    }, 1000);
  };

  const checkIfAllClear = () => {
    const storedJournal = localStorage.getItem("SelfAwarenessJournal");
    const existingJournal = storedJournal ? JSON.parse(storedJournal) : {};

    if (
      existingJournal.dissectKeyValues[focusKey].every(
        (item) => item.trim() !== ""
      )
    ) {
      return true;
    } else {
      toast.error("Please fill-up all fields.");
      return false;
    }
  };

  const nexAllowHandler = (
    e: React.MouseEvent<HTMLButtonElement, MouseEvent>
  ) => {
    const status = checkIfAllClear();
    if (status) {
      setActiveIndex((previousIndex) => {
        const newIndex = previousIndex + 1;
        if (newIndex < dissectKeys.length) {
          return newIndex;
        } else {
          checkIfAllClear();
          backAndNextHandler.nextCallback(e);
          return previousIndex; // Keep the current index if it exceeds the array length
        }
      });
      refreshValues();
    } else {
      return false;
    }
  };

  const backCallback = (e: React.MouseEvent<HTMLButtonElement, MouseEvent>) => {
    setActiveIndex((previousIndex) => {
      const newIndex = previousIndex - 1;
      if (newIndex >= 0) {
        return newIndex;
      } else {
        backAndNextHandler.backCallback(e);
        return previousIndex; // Keep the current index if it goes below 0
      }
    });
    refreshValues();
  };

  useEffect(() => {}, [isLoading]);

  useEffect(() => {
    if (!journal) return;
  }, [journal]);

  return (
    <div className="py-8 px-4 w-full md:w-1/4">
      {journal && <InfoModal journal={journal} show={show} handler={handler} />}
      {journal && (
        <>
          <TitleBox
            journal={journal}
            handler={handler}
            showInfo={true}
            title={`<p style="text-decoration: underline"><strong>${focusKey}</strong></p> <p>Why does this happen?</p> <p>What are the obstacles here?</p>`}
          />

          <div
            className={`p-5 flex flex-col gap-2 mt-8 font-poppins ${classes["container"]}`}
          >
            {!isLoading &&
              breakDowns &&
              breakDowns.map((step, index) => (
                <BreakDownInput
                  storage={`${focusKey}`}
                  placeholder={`eg. Cause ${index + 1}`}
                  key={index}
                  removeHandler={removeHandler}
                  index={index}
                  pageType={journal.pageType}
                  step={step}
                />
              ))}

            {isLoading && (
              <div className="flex justify-center items-center">
                <SimpleLoader />
              </div>
            )}
          </div>
          <div className="flex justify-center items-center mb-8">
            <FaPlusCircle
              className={`cursor-pointer ${btnClasses["animation"]}`}
              onClick={addStepsHandler}
              size={30}
              style={{ color: "var(--icon-colour-0)" }}
            />
          </div>
        </>
      )}
      <div className="my-4">
        <BackAndNextFlat
          {...backAndNextHandler}
          nextAllowed={breakDowns.length > 0 ? true : false}
          nextCallback={(e) => nexAllowHandler(e)}
          backCallback={(e) => backCallback(e)}
        />
      </div>
    </div>
  );
};

export default DissectKeyBreakdown;
