import { FC, useEffect, useState } from "react";
import { ComponentProps } from "../SelfAwarenessComponentMapping";
import BackAndNextFlat from "components/utility/BackAndNextFlat";
import GradingCard from "./GradingCard";
import TextArea from "../SubComponents/TextArea";
import { useSelfAwarenessContext } from "hooks/useSelfAwarenessContext";

const GradingRanking: FC<ComponentProps> = ({ backAndNextHandler }) => {
  const [selectedItemGrade, setSelectedItemGrade] = useState<
    Record<string, number>
  >({});
  const [answer, setAnswer] = useState("");
  const { journal } = useSelfAwarenessContext();
  // Load data from local storage on component mount
  useEffect(() => {
    const storedSelectedItems = localStorage.getItem("SelfAwarenessJournal");

    if (storedSelectedItems) {
      const parsedJournal = JSON.parse(storedSelectedItems);
      setSelectedItemGrade(parsedJournal.topSelectionGrading || []);
      setAnswer(parsedJournal.reflection || "");
    }
  }, []);

  const answerHandler = (value: string) => {
    setAnswer(value);
    const storedJournal = localStorage.getItem("SelfAwarenessJournal");
    const existingJournal = storedJournal ? JSON.parse(storedJournal) : {};

    // Update the existing journal with the new answer
    const updatedJournal = {
      ...existingJournal,
      reflection: value,
    };

    // Store the updated journal in local storage
    localStorage.setItem(
      "SelfAwarenessJournal",
      JSON.stringify(updatedJournal)
    );
  };

  useEffect(() => {
    if (!journal) return;
  }, [journal]);

  return (
    <div className="py-8 px-4 w-full md:w-1/4">
      <div
        className="relative p-5 border-2 rounded-lg font-poppins font-semibold"
        style={{
          backgroundColor: "#FFF4B9",
          borderColor: "#FFE871",
          color: "var(--text-colour)",
        }}
      >
        <p>
          Lean in to your emotions. What is it telling you about what is
          important to you?
        </p>
      </div>
      <div className="flex flex-col lg:flex-row  gap-4 items-center justify-center my-4">
        {Object.keys(selectedItemGrade)
          .sort((a, b) => selectedItemGrade[b] - selectedItemGrade[a])
          .map((title, index) => (
            <GradingCard
              key={index}
              title={title}
              selectedItemGrade={selectedItemGrade}
            />
          ))}
      </div>

      {journal && (
        <TextArea
          maxLength={500}
          answer={answer}
          journal={journal}
          answerHandler={answerHandler}
        />
      )}

      <div className="my-8">
        <BackAndNextFlat
          {...backAndNextHandler}
          nextAllowed={answer.length > 0}
        />
      </div>
    </div>
  );
};

export default GradingRanking;
