import FeedbackFormTitle from "components/FeedBackForm/FeedbackFormTitle";
import BackAndNext from "components/utility/BackAndNext";
import EmptySpace from "components/utility/EmptySpace";
import { useGrowthCircleContext } from "hooks/useGrowthCircleContext";
import useParticipantFeedback from "hooks/useParticipantFeedback";
import useParticipants from "hooks/useParticipants";
import useUser from "hooks/useUser";
import { useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
import { COMPLETED_ROUTE } from "strings/Routes";
import { MINIMUM_AGE, formatInputError } from "utility/inputValidation";
import QuestionGroup from "components/FeedBackForm/QuestionGroup";
import TextQuestion from "components/FeedBackForm/TextQuestion";
import { getSelfFeedbackQuestions } from "models/componentSettings/feedback/selfFeedback";
import { Date as ProfileDate } from "interface/ProfileInterface";
import React from "react";
import { FeedbackProps } from "./FeedbackAfterComponent";
import FeedbackForm from "components/FeedBackForm/FeedbackForm";

const FeedbackSelfComponent = ({
  backAndNextHandler,
  setTabNumber,
}: FeedbackProps) => {
  const { profile, setProfile } = useUser();
  const navigate = useNavigate();
  const { ParticipantRecord, sessionRole } = useParticipants();
  const { growthCircleSession } = useGrowthCircleContext();
  const [errorMessage, setErrorMessage] = useState("");

  const getCurrentYear = () => {
    const currentDate = new Date();
    return currentDate.getFullYear();
  };

  const minYear = getCurrentYear() - MINIMUM_AGE;

  const selfFeedbackQuestions = getSelfFeedbackQuestions(
    growthCircleSession?.organisation ?? ""
  );

  const [profession, setProfession] = useParticipantFeedback(
    profile?.profession ?? "",
    "feedbackForSelf",
    "profession"
  );

  const [birthday, setBirthday] = useParticipantFeedback(
    profile?.birthday ?? "",
    "feedbackForSelf",
    "birthday"
  ) as [ProfileDate, (birthday: ProfileDate) => void];

  const getFirst4Digits = (inputString: string): string => {
    return inputString.slice(0, 4);
  };

  useEffect(() => {
    if (!profile) {
      return;
    }
    setProfession(profile.profession ?? "");
    setBirthday(profile.birthday);
    if (!ParticipantRecord) return;
    if (ParticipantRecord[0]?.feedbackReflectionStatus === "submitted") {
      navigate(COMPLETED_ROUTE);
    }

    if (profile && profile.birthday !== "" && profile.birthday !== undefined) {
      const result = getFirst4Digits(profile?.birthday) as ProfileDate;
      setBirthday(result);
    }

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [profile, ParticipantRecord]);

  const nextHandler = async () => {
    if (profile) {
      setErrorMessage("");
      if (growthCircleSession?.organisation !== "NG") {
        setTabNumber(2);
      } else {
        setProfile({
          ...profile,
          profession,
          birthday,
        })
          .then(() => {
            setTabNumber(2);
          })
          .catch((err) => {
            setErrorMessage(formatInputError(err.message));
          });
      }
    }
  };

  const BirthDayHandler = async (birthday: ProfileDate) => {
    if (birthday.length < 4) {
      setErrorMessage("invalid year");
    } else if (birthday.length > 4) {
      setErrorMessage("invalid year");
    } else {
      if (parseInt(birthday) >= minYear) {
        setErrorMessage(
          `Invalid birthdate, you must be at least ${MINIMUM_AGE} years of age`
        );
      } else {
        setErrorMessage("");
        setBirthday(birthday);
      }
    }
  };

  return (
    <React.Fragment>
      <FeedbackForm activeTab={1} handleSwitchTabs={(n) => setTabNumber(n)}>
        <EmptySpace height={"15px"} />
        <FeedbackFormTitle>{selfFeedbackQuestions.title}</FeedbackFormTitle>
        {selfFeedbackQuestions.subCategories.map((subCategory, index) => {
          const questionPrefixes = subCategory.questionPrefix ?? {
            default: "",
          };
          const questionPrefix =
            questionPrefixes[sessionRole?.name ?? ""] ??
            questionPrefixes.default;
          return (
            <QuestionGroup
              key={index}
              label={questionPrefix}
              questionCategory={selfFeedbackQuestions.category}
              questions={subCategory.questions}
            />
          );
        })}
        {growthCircleSession?.organisation === "NG" && (
          <QuestionGroup>
            <TextQuestion
              label="My Profession"
              placeholder="Specify profession"
              value={profession}
              onChange={(event) => setProfession(event.target.value)}
            />
            <p className="text-slate-600 font-[700] px-4 text-left select-none py-4">
              My Birth Year
            </p>
            <input
              className="w-full border-2 bg-default"
              style={{ borderColor: "var(--main-colour)" }}
              onChange={(event) =>
                BirthDayHandler(event.target.value as ProfileDate)
              }
              defaultValue={birthday}
              min="1900"
              max={minYear}
              type="number"
            />
            <EmptySpace />
          </QuestionGroup>
        )}
        <div className="text-[#eb8181] p-4">{errorMessage}</div>
      </FeedbackForm>
      <EmptySpace />
      <BackAndNext
        {...backAndNextHandler}
        nextAllowed={
          growthCircleSession?.organisation !== "NG" ||
          !!(birthday && profession)
        }
        nextCallback={nextHandler}
      />
    </React.Fragment>
  );
};

export default FeedbackSelfComponent;
