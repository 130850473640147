import { Link } from "react-router-dom";
// import classNamees from "./GcCard.module.css";
import { RiPlantFill } from "react-icons/ri";
export default function GcCard({ gc }) {
  return (
    <Link to={`/all-circles/admin/growth-circles/${gc.id}`}>
      <div className="w-full max-w-sm hover:shadow-lg transition duration-1000 bg-white border border-gray-200 rounded-lg shadow dark:bg-gray-800 dark:border-gray-700">
        <div className="flex justify-end px-4 pt-4"></div>
        <div className="flex flex-col items-center pb-10">
          <div className="shadow-md border-2 border-slate-300 p-4 rounded-full">
            <RiPlantFill size={90} color="seagreen" />
          </div>

          <h5 className="mb-1 mt-2 text-center text-md font-medium text-gray-900 dark:text-white">
            {gc.name}
          </h5>
          <p className="flex gap-2 text-sm justify-center items-center">
            <span className="text-2xl text-gray-500 dark:text-gray-400">
              {gc.invite_code}
            </span>
          </p>

          <div className="flex mt-4 space-x-3 md:mt-6">
            <div
              className={`${
                gc.isOngoing
                  ? "bg-green-600 border-green-300"
                  : "bg-gray-600 border-gray-300"
              } inline-flex items-center px-4 py-2 text-sm font-medium text-center border  rounded-lg hover:bg-green-900 focus:ring-4 focus:outline-none focus:ring-green-200  text-white`}
            >
              {gc.isOngoing ? "On-going" : "Finished"}
            </div>
          </div>
        </div>
      </div>
    </Link>
  );
}
