import { useEffect, useRef } from "react";

interface TermsSection {
  title: string;
  content: React.ReactNode;
}

const termsSections: TermsSection[] = [
  {
    title: "Terms & Conditions",
    content: (
      <>
        <p className="text-start py-2">
          The Terms of Use stated herein constitute a legal agreement between you and 
          Savoir Asia Consulting Pte. Ltd. (UEN. 201410884W), a company incorporated under 
          the laws of the Republic of Singapore (“<span className="font-bold">Growthbeans</span>”, 
          “<span className="font-bold">we</span>”, or “<span className="font-bold">us</span>”).
        </p>
        <p className="text-start py-2">
          These terms of use (the “<span className="font-bold">Terms of Use</span>”) and 
          Growthbeans’s Policies (as defined below, together with the Terms of Use and Growthbeans’s 
          Policies, the “<span className="font-bold">Agreement</span>”) govern the 
          use of this WebApp (as defined below). Please read these carefully. By using the WebApp, 
          you agree that you have read, understood, accepted, and agree with the terms outlined in 
          this Agreement, <span className="underline font-bold">especially 
            including <a href="#clause-6" className="italic">Clause 6</a> of these Terms of Use herein which 
            sets out our disclaimers</span>. If you do not agree to any of the 
          terms, please immediately cease and refrain from using the WebApp and/or our Services (as defined below).
        </p>
        <p className="text-start py-2">
          Where applicable, these Terms shall be subject to country-specific provisions as set out herein.
        </p>
        <p className="text-start py-2">
          Users who are minors should obtain consent from their parent(s) or legal guardian(s), who by 
          accepting these Terms shall agree to take responsibility for their actions and any fees charged 
          concerning their use of the WebApp and/or purchase of Products (as defined below). If you are 
          considered a minor, you represent that you have your parent or guardian’s permission to use the 
          Service. If you are a parent or guardian of a minor, by allowing your child to use the WebApp, 
          you are subject to the terms of this Terms of use and responsible for your child’s activity. 
        </p>
        <p className="text-start py-2">
          We refer to Participants (as defined below), Facilitators (as defined below), any person who 
          may have access to any portion of our WebApp, and other users of our Services collectively as 
          “<span className="font-bold">Users</span>” or “<span className="font-bold">you</span>”.
        </p>
        <ul className="text-start py-4">
          <li className="text-2xl font-bold py-4">
            1. INTERPRETATION
          </li>
        </ul>
        <p className="text-start py-2">
          Unless the subject or context otherwise requires, the following words and expressions shall 
          have the following meanings: -
        </p>
        <table className="border w-full">
          <thead>
            <tr>
              <th className="border border-gray-300 text-start font-bold px-2 py-2">
                “Account”
              </th>
              <td className="border border-gray-300 text-start px-2">
                means an account created on the WebApp;
              </td>
            </tr>
            <tr>
              <th className="border border-gray-300 text-start font-bold px-2 py-2">
              “Agreement”
              </th>
              <td className="border border-gray-300 text-start px-2">
                has the meaning ascribed to it in the Recital;
              </td>
            </tr>
            <tr>
              <th className="border border-gray-300 text-start font-bold px-2 py-2">
              “Background Checks”
              </th>
              <td className="border border-gray-300 text-start px-2">
                has the meaning ascribed to it in <a href="#clause-2.7" className="italic">Clause 2.7</a>;
              </td>
            </tr>
            <tr>
              <th className="border border-gray-300 text-start font-bold px-2 py-2">
              “Confidential Information”
              </th>
              <td className="border border-gray-300 text-start px-2">
                means all information that would reasonably be deemed confidential 
                (however recorded or preserved) including the terms of this Agreement, 
                any data (irrespective of whether in human or machine-readable form), 
                advice, recommendations, ideas, innovations, inventions, proposals, improvements, 
                enhancements, modifications, methods, know-how, technical materials, technical 
                conceptions, technological developments, designs, chemical compositions, or 
                configurations, systems, processes, machines, equipment, techniques, software, 
                strategic plans, marketing plans, business plans, works of authorship or invention, 
                and/or any other physical matter in any way containing, representing or embodying 
                any of the foregoing; and any information whether verbally, in written or other forms, 
                that is proprietary, confidential, or constitutes a trade secret, which may be disclosed 
                or made available, directly or indirectly to the receiving party by the disclosing party;
              </td>
            </tr>
            <tr>
              <th className="border border-gray-300 text-start font-bold px-2 py-2">
              “Content”
              </th>
              <td className="border border-gray-300 text-start px-2">
                means any information, opinions, messages, images, graphics, photographs, audio and/or videos;
              </td>
            </tr>
            <tr>
              <th className="border border-gray-300 text-start font-bold px-2 py-2">
              “CRTPA”
              </th>
              <td className="border border-gray-300 text-start px-2">
                means the Contracts (Rights of Third Parties) Act (Chapter 53B) of Singapore;
              </td>
            </tr>
            <tr>
              <th className="border border-gray-300 text-start font-bold px-2 py-2">
              “Facilitation and Training Sessions”
              </th>
              <td className="border border-gray-300 text-start px-2">
                has the meaning ascribed to it in <a href="#clause-1.1.2" className="italic">Clause 1.1.2</a>;
              </td>
            </tr>
            <tr>
              <th className="border border-gray-300 text-start font-bold px-2 py-2">
                “Force Majeure Event”
              </th>
              <td className="border border-gray-300 text-start px-2">
                means an event, or a series of related events, that is outside the 
                reasonable control of Growthbeans (including failures of the internet 
                or any public telecommunications network, hacker attacks, denial of 
                service attacks, virus or other malicious software attacks or infections, 
                power failures, industrial disputes affecting any third party, changes to 
                the law, disasters, explosions, fires, floods, riots, terrorist attacks, 
                disease outbreaks and wars), further examples of which have been 
                included in <a href="#clause-10" className="italic">Clause 10</a>;
              </td>
            </tr>
            <tr>
              <th className="border border-gray-300 text-start font-bold px-2 py-2">
              “Group”
              </th>
              <td className="border border-gray-300 text-start px-2">
                means in relation to a company, that company and any subsidiary and/or holding 
                company from time to time of that company, and each and any subsidiary from 
                time to time of a holding company of that company; “holding company” and 
                “subsidiary” should have the same meanings as they are respectively defined 
                in Sections 5 and 6 of the Companies Act (Cap. 50);
              </td>
            </tr>
            <tr>
              <th className="border border-gray-300 text-start font-bold px-2 py-2">
              “Growth Circle Sessions”
              </th>
              <td className="border border-gray-300 text-start px-2">
                has the meaning ascribed to it in <a href="#clause-1.1.1" className="italic">Clause 1.1.1</a>;
              </td>
            </tr>
            <tr>
              <th className="border border-gray-300 text-start font-bold px-2 py-2">
              “Growthhub Events”
              </th>
              <td className="border border-gray-300 text-start px-2">
                has the meaning ascribed to it in <a href="#clause-1.1.7" className="italic">Clause 1.1.7</a>;
              </td>
            </tr>
            <tr>
              <th className="border border-gray-300 text-start font-bold px-2 py-2">
              “Growthhub Profiles”
              </th>
              <td className="border border-gray-300 text-start px-2">
                has the meaning ascribed to it in <a href="#clause-1.1.9" className="italic">Clause 1.1.9</a>;
              </td>
            </tr>
            <tr>
              <th className="border border-gray-300 text-start font-bold px-2 py-2">
              “Intellectual Property”
              </th>
              <td className="border border-gray-300 text-start px-2">
                means any intellectual property including trademarks, trade names, service marks, 
                service mark registrations, service names, patents, patent rights, copyrights, 
                inventions, licenses, approvals, governmental authorisations and/or trade secrets;
              </td>
            </tr>
            <tr>
              <th className="border border-gray-300 text-start font-bold px-2 py-2">
              “Journals”
              </th>
              <td className="border border-gray-300 text-start px-2">
                has the meaning ascribed to it in Clause <a href="#clause-1.1.4" className="italic">1.1.4</a>;
              </td>
            </tr>
            <tr>
              <th className="border border-gray-300 text-start font-bold px-2 py-2">
              “Member”
              </th>
              <td className="border border-gray-300 text-start px-2">
                has the meaning ascribed to it in <a href="#clause-2.2" className="italic">Clause 2.2</a>;
              </td>
            </tr>
            <tr>
              <th className="border border-gray-300 text-start font-bold px-2 py-2">
              “Membership”
              </th>
              <td className="border border-gray-300 text-start px-2">
                has the meaning ascribed to it in <a href="#clause-2.5" className="italic">Clause 2.5</a>;
              </td>
            </tr>
            <tr>
              <th className="border border-gray-300 text-start font-bold px-2 py-2">
              “Party”
              </th>
              <td className="border border-gray-300 text-start px-2">
                means any party to this Agreement;
              </td>
            </tr>
            <tr>
              <th className="border border-gray-300 text-start font-bold px-2 py-2">
              “Personal Data”
              </th>
              <td className="border border-gray-300 text-start px-2">
                means any data and/ or information, whether true or not, about an individual who 
                can be identified from such data and/ or information; or any data and other 
                information with respect to that individual to which Growthbeans has or is likely to have access;
              </td>
            </tr>
            <tr>
              <th className="border border-gray-300 text-start font-bold px-2 py-2">
              “PDPA”
              </th>
              <td className="border border-gray-300 text-start px-2">
                means the Personal Data Protection Act of Singapore 2012;
              </td>
            </tr>
            <tr>
              <th className="border border-gray-300 text-start font-bold px-2 py-2">
              “WebApp”
              </th>
              <td className="border border-gray-300 text-start px-2">
                means the WebApp managed by Growthbeans to provide the Services including (i) Growthbeans’s 
                websites and domain including https://growthcircles-webapp.com, and subdomains; (ii) the 
                application and database software for the Services; (iii) the system and server software used 
                to provide the Services; and (iv) the computer hardware on which that application, database, 
                system and server software is installed;
              </td>
            </tr>
            <tr>
              <th className="border border-gray-300 text-start font-bold px-2 py-2">
              “Policies”
              </th>
              <td className="border border-gray-300 text-start px-2">
                means, collectively, policies issued by Growthbeans that may be quoted by these Terms of Use, 
                including Growthbeans’ <span className="font-bold">Online Conduct Policy, and 
                Privacy Policy</span>;
              </td>
            </tr>
            <tr>
              <th className="border border-gray-300 text-start font-bold px-2 py-2">
              “Post”
              </th>
              <td className="border border-gray-300 text-start px-2">
                has the meaning ascribed to it in <a href="#clause-3.1" className="italic">Clause 3.1</a>;
              </td>
            </tr>
            <tr>
              <th className="border border-gray-300 text-start font-bold px-2 py-2">
              “Privacy Policy”
              </th>
              <td className="border border-gray-300 text-start px-2">
                means the privacy policy of Growthbeans which is available at “[<span className="bg-yellow-200">●</span>]” as may 
                be amended by Growthbeans from time to time;
              </td>
            </tr>
            <tr>
              <th className="border border-gray-300 text-start font-bold px-2 py-2">
              “Proceeding”
              </th>
              <td className="border border-gray-300 text-start px-2">
                has the meaning ascribed to it in <a href="#clause-15.2" className="italic">Clause 15.2</a>;
              </td>
            </tr>
            <tr>
              <th className="border border-gray-300 text-start font-bold px-2 py-2">
              “Self-Discovery Tools”
              </th>
              <td className="border border-gray-300 text-start px-2">
                has the meaning ascribed to it in <a href="#clause-1.1.5" className="italic">Clause 1.1.5</a>;
              </td>
            </tr>
            <tr>
              <th className="border border-gray-300 text-start font-bold px-2 py-2">
              “Services”
              </th>
              <td className="border border-gray-300 text-start px-2">
                has the meaning ascribed to it in <a href="#clause-1.1" className="italic">Clause 1.1</a>;
              </td>
            </tr>
            <tr>
              <th className="border border-gray-300 text-start font-bold px-2 py-2">
              “Terms of Use”
              </th>
              <td className="border border-gray-300 text-start px-2">
                means this Terms of Use as may be amended by Growthbeans from time to time;
              </td>
            </tr>
            <tr>
              <th className="border border-gray-300 text-start font-bold px-2 py-2">
              “Users”
              </th>
              <td className="border border-gray-300 text-start px-2">
                has the meaning ascribed to it in the recital.
              </td>
            </tr>
          </thead>
        </table>
        <ul className="text-start py-2 pl-6">
          <li className="py-2 list-inside list-disc">
            Except to the extent that the context otherwise requires, any reference to “<span className="font-bold">this 
            Agreement</span>” includes these Terms of Use and Growthbeans’s Policies, as from time to 
            time amended, modified or supplemented and any document which is supplemental hereto 
            or which is expressed to be collateral herewith or which is entered into pursuant to 
            or in accordance with the terms hereof.
          </li>
          <li className="py-2 list-inside list-disc">
            Reference to statutory provisions shall be construed as references to those provisions 
            as respectively amended or re-enacted or as their application is modified by other 
            provisions (whether before or after the date hereof) from time to time and shall include 
            any provisions of which they are re-enactments (whether with or without modification).
          </li>
          <li className="py-2 list-inside list-disc">
            The headings are inserted for convenience only and shall not affect the construction of this Agreement.
          </li>
          <li className="py-2 list-inside list-disc">
            Words importing the singular shall include the plural and vice versa, and “person” shall 
            include an individual, corporation, association, company, membership, firm, trustee, 
            trust, executor,  administrator or other legal personal representatives,  unincorporated 
            association, joint venture (whether incorporated or unincorporated), syndicate or other 
            business enterprises, any governmental, administrative or regulatory authority or agency 
            (notwithstanding that “person” may be sometimes used in this Agreement in conjunction with 
            some of such words), and their respective successors, legal personal representatives and 
            assigns, as the case may be, and pronouns shall have a similarly extended meaning.
          </li>
          <li className="py-2 list-inside list-disc">
            In the event of any inconsistency between the Terms of Use and Growthbeans’s Policies, 
            the terms and provisions last executed shall prevail.
          </li>
          <li className="py-2 list-inside list-disc">
            The words “including” and “includes” are not exclusive, so should be read as if followed 
            by the words “without limitation”.
          </li>
          <li className="py-2 list-inside list-disc">
            References herein to “Clauses” are to clauses in this Agreement.
          </li>
        </ul>
        <ul className="text-start py-2">
          <li className="text-2xl font-bold py-4" id="clause-1">
            1. WHAT WE DO
          </li>
          <ul className="text-start">
          <li className="py-2" id="clause-1.1">
            1.1.	Growthbeans offers a multitude of services which provide tools for Users to make 
            connections more meaningfully, deepen self-awareness, and establish communities. In particular, 
            the WebApp provides:-
          </li>
            <ul className="list-inside px-4 py-2">
              <li className="my-4" id="clause-1.1.1">
                {" "}
                1.1.1.	a methodology and tools for the facilitation of an in-person or virtual session run 
                by Growthbeans, its vendors, its clients, or its partners which enable open sharing between 
                its participants, and/ or other desired outcomes(“<span className="font-bold">Growth Circle Sessions</span>”);
              </li>
              <li className="my-4" id="clause-1.1.2">
                {" "}
                1.1.2.	a methodology and tools for the facilitation of in-person or virtual sessions, 
                seminars, training, educational and/or learning modules whether run by Growthbeans, its 
                clients, its partners, or its vendors (together, the “<span className="font-bold">Facilitation and Training 
                  Sessions</span>”);
              </li>
              <li className="my-4" id="clause-1.1.3">
                {" "}
                1.1.3.	a management tool to manage the deployment of Facilitators to Growth Circle Sessions, or other Events;
              </li>
              <li className="my-4" id="clause-1.1.4">
                {" "}
                1.1.4.	tools for Users to record their personal reflections whether for their own use or for 
                sharing with their organisations as part of a program (“<span className="font-bold">Journals</span>”); 
              </li>
              <li className="my-4" id="clause-1.1.5">
                {" "}
                1.1.5.	self-discovery tools for Users to facilitate their thoughts and feelings with the aim 
                for them to gain greater self-awareness (“<span className="font-bold">Self-Discovery Tools</span>”);
              </li>
              <li className="my-4" id="clause-1.1.6">
                {" "}
                1.1.6.	listing of public and non-public resources associated with but are not limited to well-being and mental health;
              </li>
              <li className="my-4" id="clause-1.1.7">
                {" "}
                1.1.7.	an events platform that helps organisers of Growth Circle Sessions (including 
                Growthbeans or other Users) or other similar sessions to create, post, and manage events, and 
                share facilitation opportunities with other Users (“<span className="font-bold">Growthhub Events</span>”) and 
                for other Users to sign up for such Growthhub Events; and
              </li>
              <li className="my-4" id="clause-1.1.8">
                {" "}
                1.1.8.	a platform for Facilitators to represent their personal information including 
                their names, relevant work experiences, certifications, and work preferences, to other 
                Users (“<span className="font-bold">Facilitator Profiles</span>”)(collectively, the “<span className="font-bold">Services</span>”).
              </li>
            </ul>
          </ul>
          <li className="text-2xl font-bold py-4" id="clause-2">
            2.	WEBAPP MEMBERSHIP
          </li>
          <ul className="text-start">
          <li className="py-2" id="clause-2.1">
            2.1.	To use the WebApp, you agree to abide by the terms set out in this Agreement.
          </li>
          <li className="py-2" id="clause-2.2">
            2.2.	Under the WebApp, you may interact with other Users in different capacities. 
            You may interact as a (i) Participant; and/or (ii) Facilitator (together with Participants, 
            the “<span className="font-bold">Members</span>”).
          </li>
          <li className="py-2" id="clause-2.3">
            2.3.	<span className="font-bold">Registering for Membership:</span> To register as a Member, 
            you must create a password that is unique to your Account. Your email will be your unique 
            identifier and you will be required to use this unique identifier to gain access to the WebApp as a Member. In 
            the alternative, you may use Google sign-through services to create a unique identifier.
          </li>
          <li className="py-2" id="clause-2.4">
            2.4.	We shall have rights to (i) deny you access to the WebApp; (ii) request that you 
            change your unique identifier and password; and (iii) assign you a password, should your 
            unique identifier or password be deemed, at our sole discretion, inappropriate or in 
            breach of the Agreement. You are responsible for ensuring that your username, password, 
            and security information used for this WebApp are kept confidential.
          </li>
          <li className="py-2" id="clause-2.5">
            2.5.	Upon your successful registration and/or verification, you will be a Member of 
            the WebApp (“<span className="font-bold">Membership</span>”). 
          </li>
          <li className="py-2" id="clause-2.6">
            2.6.	Under the WebApp, you may interact with other Users in different capacities. 
            You may interact as a Participant, or a Facilitator.
          </li>
          <li className="py-2" id="clause-2.7">
            2.7.	For the avoidance of doubt, Growthbeans shall have the right but not the obligation 
            to conduct checks and reasonable investigations. If Growthbeans, in its sole discretion, is 
            not satisfied with the results of these checks and/or investigations for any reason whatsoever, 
            we may terminate your Account and/or access to the WebApp.  In our taking such actions, you 
            agree that we shall not be liable to you for claims, damages, losses, expenses, costs, or 
            liabilities whatsoever (including any loss of profits, business interruption, or loss of information) 
            resulting or arising directly or indirectly from the termination of your Account and/or access 
            to the WebApp, even if we have been advised of the possibility of such damages in advance.
          </li>
          <li className="py-2" id="clause-2.8">
            2.8.	You agree that you will not disclose whether deliberately, negligently, or otherwise, 
            the credentials of your Account to any third party or allow any third party to use your Account, 
            gain access to the WebApp, and/or use our Services through your Account.
          </li>
          </ul>
          <li className="text-2xl font-bold py-4" id="clause-3">
            3.	CONDUCT ON THE WEBAPP
          </li>
          <ul className="text-start">
          <li className="py-2" id="clause-3.1">
            3.1.	During your time on the WebApp, unless otherwise restricted, you may upload, distribute, 
            publish, display, and transfer (“<span className="font-bold">Post</span>”) Content to other Users. This may be feedback given 
            during a Growth Circles Session, your messages with another User, or other interactions you may 
            have on the WebApp with any other User. You agree that you bear sole responsibility for the Content 
            you Post on the WebApp. By using our WebApp, you agree to abide by 
            our <span className="underline font-bold">Online Conduct Policy</span>. 
            Further, you agree to utilise the Services only in the manner which we have intended.
          </li>
          <li className="py-2" id="clause-3.2">
            3.2.	Where we believe, suspect, or are of the opinion that any Content contains errors, is 
            inaccurate, contains omissions, or otherwise violates this Agreement, Growthbeans has the right 
            (though not the obligation) to investigate and take appropriate action. These actions include (i) 
            removing Content that does not conform to this Agreement; (ii) suspending or terminating the Account 
            of Users who breach this Agreement; and/or (iii) monitoring, editing, and/or deleting any Content on 
            the WebApp to conform to the requirements under this Agreement. 
          </li>
          <li className="py-2" id="clause-3.3">
            3.3.	In our taking such actions, you agree that we shall not be liable to you for claims, damages, 
            losses, expenses, costs, or liabilities whatsoever (including any direct or indirect damages for 
            loss of profits, business interruption, or loss of information) resulting or arising directly or 
            indirectly from our actions, which may limit your use of or inability to use the WebApp or any websites 
            linked to it, or from your reliance on the information and material on the WebApp, even if we have been 
            advised of the possibility of such damages in advance.
          </li>
          </ul>
          <li className="text-2xl font-bold py-4" id="clause-4">
            4.	IN-PERSON EVENTS CONDUCTED BY GROWTHBEANS
          </li>
          <p>Should you attend a Growth Circles Session or any Event organised by Growthbeans, you agree to the following:-</p>
          <ul className="text-start">
          <li className="py-2" id="clause-4.1">
            4.1.	During your utilisation of the WebApp or attendance at any Facilitation and Training Session, 
            you agree that you shall not cause any disturbance, engage in disruptive behaviour, or do anything 
            that affects the safety or comfort of another participant, facilitator, and/or any other third party 
            including bullying, physical violence, psychological harm, and/or any discriminatory behaviour based 
            on age, disability, race, ethnicity, gender, religion, political, or institutional affiliation. 
          </li>
          <li className="py-2" id="clause-4.2">
            4.2.	You shall maintain the condition of the venue and the property on-site at any venue where the 
            Growth Circles Session or Event is organised. You shall make good and pay for damages caused by act 
            or omission by yourself. 
          </li>
          <li className="py-2" id="clause-4.3">
            4.3.	In the event that we are penalised in any manner due to your failure to observe any of the 
            aforesaid, you shall indemnify us against all damages incurred (whether direct or indirect, including 
            costs on an indemnity basis).
          </li>
          <li className="py-2" id="clause-4.4">
            4.4.	You are solely responsible for your personal belongings. During the Growth Circles Session or Event, 
            you must care of your personal belongings and ensure that your valuables are secured at all times. We 
            shall under no circumstance, be held liable for any loss(es) or damage(s) to your personal belongings.
          </li>
          </ul>
          <li className="text-2xl font-bold py-4" id="clause-5">
            5.	INTELLECTUAL PROPERTY RIGHTS & KNOW-HOW
          </li>
          <ul className="text-start">
          <li className="py-2" id="clause-5.1">
            5.1.	Intellectual Property including but not limited to trademarks, trade names, and logos, 
            and copyrights owned by both Growthbeans and third parties are used and displayed on this WebApp. 
            Save where provided for in this Agreement or in any other written agreement between you and Growthbeans, 
            nothing on this WebApp shall be construed as granting, by implication, estoppel or otherwise, any 
            licence or right to use any of Growthbeans’s or any third party’s trademarks. You agree not to use, 
            reproduce, link, republish, copy, display, broadcast, hyperlink, transfer, transmit, distribute, store, 
            or reuse any Intellectual Property contained on the WebApp.
          </li>
          <li className="py-2" id="clause-5.2">
            5.2.	Further, Growthbeans’ methodologies, know-how, frameworks, and facilitation tools are shared 
            within the WebApp. You acknowledge that use of these outside the context of Growthbeans’ Growth Circle 
            Sessions and/or the WebApp may deprive Growthbeans of an opportunity, and also the opportunities to 
            utilise its methodologies, know-how, frameworks, and facilitation tools to further develop its business, 
            client-base, and operations.  Save where provided for in this Agreement or in any other written agreement 
            between you and Growthbeans, nothing on this WebApp shall be construed as granting, by implication, 
            estoppel or otherwise, any licence or right to use Growthbeans’ methodologies, know-how, frameworks, 
            and facilitation tools. 
          </li>
          <li className="py-2" id="clause-5.3">
            5.3.	The Content available on the WebApp may from time to time be provided by third parties. We may 
            not have control over such Content. Growthbeans shall not be held responsible for any such Content 
            provided by third parties which are published, featured, displayed or otherwise used or contained on the WebApp.
          </li>
          <li className="py-2" id="clause-5.4">
            5.4.	You agree that by posting, transmitting, transferring, distributing, or uploading any Content 
            onto the WebApp, you warrant that you have all the necessary rights (including intellectual property rights) 
            to post, transmit, transfer, distribute, or upload such Content. 
          </li>
          <li className="py-2" id="clause-5.5">
            5.5.	You further agree that by posting, transmitting, transferring, distributing, or uploading 
            information and/or Content onto the WebApp, you hereby grant to Growthbeans an irrevocable, worldwide, 
            royalty-free, fully-paid, non-exclusive perpetual license to use, copy, perform, display, and distribute 
            such information and/or Content; to modify such Content; and to display such Content and modifications; and 
            that Growthbeans has the right to prepare derivative works of, or incorporate into other works, such information 
            and/or Content. You may end this license for specific Content by deleting such Content from the WebApp, or 
            generally by closing your account, except (a) to the extent you shared it with others as part of the Services 
            and they copied, or stored it; and (b) for the reasonable time it takes to remove from backup and other systems.  
          </li>
          <li className="py-2" id="clause-5.6">
            5.6.	This <a href="#clause-5" className="italic">Clause 5</a> shall survive termination of this Agreement. 
          </li>
          </ul>
          <li className="text-2xl font-bold py-4" id="clause-6">
            6.	DISCLAIMERS
          </li>
          <p className="font-bold py-2">
            THE SERVICE AND THE WEBAPP AS WELL AS THE RELATED CONTENT ARE PROVIDED TO YOU STRICTLY ON AN “AS IS” 
            BASIS. ALL CONDITIONS, REPRESENTATIONS AND WARRANTIES, INCLUDING ANY IMPLIED WARRANTY OF MERCHANTABILITY, 
            FITNESS FOR A PARTICULAR PURPOSE, OR NON-INFRINGEMENT OF THIRD-PARTY RIGHTS, ARE HEREBY EXCLUDED TO THE 
            MAXIMUM EXTENT PERMISSIBLE BY LAW. YOU AGREE THAT YOU SHALL BEAR ALL RISK ARISING OUT OF YOUR USE OF THE 
            SERVICE AND SHALL HAVE NO RECOURSE TO US IN RESPECT OF THE SAME.
          </p>
          <p className="font-bold py-2">
            If you have statutory rights or warranties we cannot disclaim, the duration of any such statutorily 
            required rights or warranties, will be limited to the maximum extent permitted by law.
          </p>
          <ul className="text-start">
          <li className="py-2" id="clause-6.1">
            6.1.	<span className="font-bold">No Advice.</span> The information, materials, and resources contained in this WebApp or shared during 
            Growthbeans’ Growth Circle Sessions, and Facilitation and Training Sessions are not intended to be 
            treated as professional advice or advice in whatever nature and should not be relied upon as such. In 
            particular, Growth Circle Sessions are not meant to be a replacement for professional assistance which 
            is inclusive but not limited to (i) professional counselling, (ii) therapy, (iii) psychotherapy; and/or 
            (iv) psychiatric medical assistance. Nothing in the WebApp or in the Growthbeans’ Growth Circle Sessions 
            are intended to establish a doctor-patient, and/or therapist-patient relationship or to replace the services 
            of a trained therapist, doctor, or health professional. Please consult a medical or trained professional if 
            you require such assistance.
          </li>
          <li className="py-2" id="clause-6.2">
            6.2.	<span className="font-bold">Interactions with other Users:</span> We are not responsible for the actions of other Users of the WebApp 
            and your interactions with other Users whether on the WebApp, during in-person Growth Circle Sessions, 
            or outside the use of the WebApp. We shall not be taken to have the obligation to regulate interactions 
            between you and other Users and shall not be liable for the actions of any User of the WebApp. This may 
            include but may not be limited to any actions, comments, reactions, or interactions other Users may have with 
            you that may include bullying, taunting, cause of discomfort, physical violence, and/or inappropriate behaviours. 
          </li>
          <li className="py-2" id="clause-6.3">
            6.3.	<span className="font-bold">No Warranty on Outcomes:</span> In the course of utilising our Services, you may come across information and 
            statements made by Growthbeans which are aspirational outcomes to its self-assessment tools, Growth Circle 
            Sessions, and/or other Services. Growthbeans does not intend to imply any warranty or assured outcomes from 
            the use of these words, and/or your use of the Services. 
          </li>
          <li className="py-2" id="clause-6.4">
            6.4.	<span className="font-bold">No Warranty on Satisfaction:</span> We do not warrant nor guarantee that the Service and/or WebApp will meet 
            your requirements, expectations, and personal standards of satisfaction. 
          </li>
          <li className="py-2" id="clause-6.5">
            6.5.	<span className="font-bold">No Obligation to Store Information:</span> We do not warrant or represent to store any Content which you 
            have produced, linked, published, displayed, broadcasted, transferred, transmitted, or distributed on the 
            WebApp. It is your responsibility to ensure that all Content which you upload is available to you and is not 
            lost should there be any removal of such Content from the WebApp.  
          </li>
          <li className="py-2" id="clause-6.6">
            6.6.	<span className="font-bold">No Warranty of Uninterrupted Access:</span> Users should be aware that transactions over the internet may 
            be subject to interruption, transmission blackout, delayed transmission due to internet traffic or incorrect 
            data transmission due to the public nature of the internet. Growthbeans does not represent or warrant that 
            access to the WebApp will be uninterrupted and that there will be no delays, errors, failures, or loss of any 
            information transmitted to and from this WebApp. We do not warrant the reliability, timeliness, quality, 
            suitability, availability, accuracy, or completeness of the Service or the WebApp.
          </li>
          <li className="py-2" id="clause-6.7">
            6.7.	<span className="font-bold">Third-Party Links: Unless otherwise specified,</span> information and materials provided in other websites/ 
            web applications to which a hyperlink is offered on the WebApp do not represent Growthbeans’s standpoint 
            and Growthbeans shall not be taken as endorsing, recommending, approving, guaranteeing or introducing any 
            service/ product referred to in such other websites/ web applications. Further, Growthbeans is not responsible 
            and shall not be liable for the content available on any hyperlinked websites/ web applications and access to 
            and use of which is at the User’s own risk.
          </li>
          <li className="py-2" id="clause-6.8">
            6.8.	<span className="font-bold">No Warranty on Accuracy, Adequacy or Completeness:</span> Without limiting the generality of the foregoing, 
            Growthbeans does not represent or warrant the accuracy, adequacy or completeness of the information and 
            materials contained in this WebApp, Growth Circle Sessions, or in its Facilitation and Training Sessions. 
            We expressly disclaim any liability for any errors or omissions in such information and materials. 
          </li>
          <li className="py-2" id="clause-6.9">
            6.9.	<span className="font-bold">No Warranty on Listed Events:</span> Through our Services, we may connect Members with other Members who are 
            attendees at events which they list (“Member Events”). You agree that (1) we are not responsible for the 
            conduct of any of the Members or other attendees at such Member Events; (2) we do not endorse any particular 
            event listed on our Services; (3) we do not review and/or vet any of these Member Events; and (4) that you 
            will adhere to these terms and conditions that apply to such Member Events. You agree that some events may 
            carry inherent risks and by participating in those events, you choose to assume those risks voluntarily. For 
            example, some events may carry risk of illness, bodily injury, disability, or death, and you freely assume 
            those risks by choosing to participate in those events.
          </li>
          <li className="py-2" id="clause-6.10">
            6.10.	<span className="font-bold">No Warranty on Security of WebApp:</span> We do not warrant that the WebApp will be secure, uninterrupted, 
            free of errors or other harmful components, or operate in combination with any other hardware, software, 
            system, or data.
          </li>
          <li className="py-2" id="clause-6.11">
            6.11.	<span className="font-bold">General Disclaimer of Information and Materials:</span> The information and materials contained in the 
            WebApp are provided on an “as is” and an “as available” basis without any express or implied warranties of 
            any kind including warranties of merchantability, non-infringement of intellectual property rights, fitness 
            for a particular purpose, or absence of computer viruses. Any files downloaded or opened from this WebApp 
            are done so at the user’s own risk.
          </li>
          <li className="py-2" id="clause-6.12">
            6.12.	<span className="font-bold">Information and Materials Provided by Other Users:</span>  Content provided on the WebApp which has been 
            produced, linked, published, displayed, broadcasted, transferred, transmitted, or distributed by Users of the 
            WebApp does not represent Growthbeans’s opinions, views, positions, and/or standpoints. Growthbeans shall not 
            be taken as endorsing, recommending, approving, guaranteeing or introducing such information or material and 
            its contents. Growthbeans does not represent or warrant the accuracy, adequacy, authenticity, or completeness 
            of such information and materials and shall not be liable for any errors or omissions in such information and 
            materials.
          </li>
          <li className="py-2" id="clause-6.13">
            6.13.	<span className="font-bold">No Warranty on the Accuracy of Content.</span> During your use of the Services, you may encounter Content that 
            might be against our Online Conduct Policy. Such Content may be inaccurate, incomplete, delayed, misleading, 
            illegal, offensive, or harmful. We do not normally review content provided by our Members. You agree that we 
            are not responsible for Content shared by other Users. We are not able to prevent such a misuse of our Services. 
            You agree that we are not responsible for any such misuse.
          </li>
          </ul>
          <li className="text-2xl font-bold py-4" id="clause-7">
            7.	LIMITATION OF LIABILITY
          </li>
          <ul className="text-start">
          <li className="py-2" id="clause-7.1">
            7.1.	Nothing in this Agreement shall limit or exclude liability for death or personal injury caused by 
            negligence; fraud, deceit, or fraudulent misrepresentation; or any other liability that cannot be excluded by law.
          </li>
          <li className="py-2" id="clause-7.2">
            7.2.	Subject to <a href="#clause-7.1" className="italic">Clause 7.1</a>, Growthbeans shall not be liable to any User or third party, in any circumstances, 
            for any and all losses, damages, claims, causes of action, and/or injury, including any (i) loss of profit, data, 
            business, goodwill, chance, or use of the WebApp; (ii) interruption of business; and/or (iii) direct and/or 
            indirect, special, incidental or consequential loss arising out of your use, access, or reliance, or in 
            connection with this Agreement, whether arising out of a breach of contract, tort, breach of statutory duty, 
            restitution, or otherwise, even if Growthbeans had been advised of the possibility of such potential losses, 
            damages, claims, causes of action, and/or injury.
          </li>
          <li className="py-2" id="clause-7.3">
            7.3.	These include losses, damages, claims, causes of action, and/or injury arising directly or indirectly 
            out of, or in any way connected with (i) your use, access, or reliance on the Service and/or the WebApp; 
            (ii) your use of any features of the WebApp; (iii) your inability to use the Service and/or the WebApp for 
            any reason whatsoever including the performance of the WebApp or Services; (iv) any delay in operation or 
            transmission, communication failure, internet access difficulties, or malfunction of equipment or software; 
            (v)  your reliance on the completeness, accuracy, or existence of any Growthhub Event, Growthhub Event 
            description, or User; and/or (vi) your interactions and dealings with any Users or third parties introduced 
            to you by the Service or the WebApp.  
          </li>
          <li className="py-2" id="clause-7.4">
            7.4.	Notwithstanding any provision in this Agreement, to the fullest extent allowed by law, the maximum 
            aggregate liability to any User arising out of or in connection with this Agreement, whether arising out 
            of a breach of contract, tort, breach of statutory duty, restitution, or otherwise, for any single incident 
            or series of related incidents shall be limited to $50. 
          </li>
          <li className="py-2" id="clause-7.5">
            7.5.	This <a href="#clause-7" className="italic">Clause 7</a> shall survive termination of this Agreement.  
          </li>
          </ul>
          <li className="text-2xl font-bold py-4" id="clause-8">
            8.	CONFIDENTIALITY
          </li>
          <ul className="text-start">
          <li className="py-2" id="clause-8.1">
            8.1.	Other than for the purposes set out in this Agreement, you shall not, without the prior 
            written consent of Growthbeans, make use of (i) any information obtained directly or indirectly from 
            Growthbeans; or (ii) compiled or generated by you in relation to or derived from such information.
          </li>
          <li className="py-2" id="clause-8.2">
            8.2.	You shall not publish or release, nor shall you allow the publication or release of, any news 
            item, article, publication, advertisement, speech, or any other information or material pertaining to 
            any part of the obligations to be performed under this Agreement in any media without the prior written 
            consent of Growthbeans. 
          </li>
          <li className="py-2" id="clause-8.3">
            8.3.	This <a href="#clause-8" className="italic">Clause 8</a> shall survive termination of this Agreement.  
          </li>
          </ul>
          <li className="text-2xl font-bold py-4" id="clause-9">
            9.	TERMINATION AND SUSPENSION 
          </li>
          <ul className="text-start">
          <li className="py-2" id="clause-9.1">
            9.1.	<span className="font-bold">Suspension for Maintenance:</span> You agree that we may without notice (i) modify, suspend or 
            terminate the operation of or access to the WebApp, or any portion of the WebApp (including access 
            to your Account and/or the availability of any products or services), for any reason; (ii) modify or 
            change any applicable policies or terms; and (iii) interrupt the operation of the WebApp or any 
            portion of the WebApp (including access to your Account and/or the availability of any products or 
            services), as necessary to perform routine or non-routine maintenance, error correction, or other 
            changes. We shall not be required to compensate you for any modification, suspension, or termination.
          </li>
          <li className="py-2" id="clause-9.2">
            9.2.	<span className="font-bold">Termination and Suspension by Us:</span> Except to the extent you have agreed in this Agreement or 
            any separate agreement between you and Growthbeans, Growthbeans shall have the right to terminate 
            or suspend your use of the WebApp without prior notice and at any time if we deem that you have 
            failed to comply with any of the provisions of this Agreement. Growthbeans may effect such 
            termination or suspension for any matter of concern including the following:- 
            <ul className="list-inside px-4 py-2">
              <li className="my-4" id="clause-9.2.1">
                {" "}
                9.2.1.	we believe that you have breached and/or violated any requirement, term, representation, 
                or warranty set in these Terms of Use or Growthbeans’s Policies;
              </li>
              <li className="my-4" id="clause-9.2.2">
                {" "}
                9.2.2.	we are of the opinion that you have misused or abused our Services, or use the Services 
                in a way not intended or permitted by us;
              </li>
              <li className="my-4" id="clause-9.2.3">
                {" "}
                9.2.3.	allowing you access to the WebApp or our Services may violate any applicable laws, rules, 
                and regulations, or may expose Growthbeans or other Users to legal liability; and
              </li>
              <li className="my-4" id="clause-9.2.4">
                {" "}
                9.2.4.	where there are concerns that you are involved in or related to any unlawful activity.
              </li>
            </ul>
          </li>
          <li className="py-2" id="clause-9.3">
            9.3.	<span className="font-bold">Termination and Suspension by You:</span> Except to the extent you have agreed otherwise in a 
            separate written agreement between you and Growthbeans, you may terminate or suspend your Membership 
            by sending us an email at <a href="mailto:growthbeans@savoir-asia.com"><span className="underline font-bold">growthbeans@savoir-asia.com</span></a>.  
          </li>
          <li className="py-2" id="clause-9.4">
            9.4.	<span className="font-bold">Effect of Suspension or Termination of Membership:</span> The commencement of the suspension or 
            termination of your Membership will be decided upon at the sole discretion of Growthbeans. From 
            the time your Membership with the WebApp has been suspended, you will not be able to gain access 
            to the WebApp. You agree that we will not be liable to you for any direct or indirect losses (including 
            loss of profit, business, or opportunity), damages, or costs arising from such suspension.   
          </li>
          <li className="py-2" id="clause-9.5">
            9.5.	<span className="font-bold">Effect of Termination:</span> The commencement of the termination of your Membership will be decided 
            upon at the sole discretion of Growthbeans. From the time your Membership with the WebApp has been 
            terminated, you will not be able to gain access to the WebApp. You agree that Growthbeans will not be 
            liable to you for any direct or indirect losses (including loss of profit, business, or opportunity), 
            damages, or costs arising from such termination. Should you wish to appeal such termination, you must 
            send us an email at <a href="mailto:growthbeans@savoir-asia.com"><span className="underline font-bold">growthbeans@savoir-asia.com</span></a> and 
            we may at our sole discretion, reinstate your Account.
          </li>
          <li className="py-2" id="clause-9.6">
            9.6.	<span className="font-bold">Termination of this Agreement:</span> This Agreement shall be terminated at any time after the termination 
            of your Membership at the election of Growthbeans, or automatically after 60 days from the termination 
            of your Membership. Parties may agree in writing to extend the period. Should you wish to reinstate your 
            Membership, you must lodge an appeal to do so by sending your appeal via email 
            to <a href="mailto:growthbeans@savoir-asia.com"><span className="underline font-bold">growthbeans@savoir-asia.com</span></a> 
            within 7 days of the termination of your Membership. In the event your appeal is successful, this Agreement 
            shall on the date which you have received a notification of your successful appeal continue to operate as if 
            your Membership was not terminated.  
          </li>
          </ul>
          <li className="text-2xl font-bold py-4" id="clause-10">
            10.	FORCE MAJEURE
          </li>
          <ul className="text-start">
          <li className="py-2" id="clause-10.1">
            10.1.	In the event of a Force Majeure Event, Growthbeans’s contractual obligations (under this Agreement) 
            affected by such an event shall be suspended during the period of delay caused by the Force Majeure Event 
            and Growthbeans shall not be, in such an event, in breach of this Agreement and has the right to elect to 
            terminate this Agreement. An Event of Force Majeure includes but is not limited to the following:-
            <ul className="list-inside px-4 py-2">
              <li className="my-4" id="clause-10.1.1">
                {" "}
                10.1.1.	acts of god (such as, but not limited to, fires, explosions, earthquakes, drought, tidal waves, 
                floods, sonic booms or other natural disasters);
              </li>
              <li className="my-4" id="clause-10.1.2">
                {" "}
                10.1.2.	war, hostilities (whether war be declared or not), invasion, armed conflict, an act of foreign 
                enemies, mobilisation, requisition or embargo within Singapore or the region of Singapore;
              </li>
              <li className="my-4" id="clause-10.1.3">
                {" "}
                10.1.3.	pandemics and/or severe outbreaks of diseases;
              </li>
              <li className="my-4" id="clause-10.1.4">
                {" "}
                10.1.4.	rebellion, revolution, insurrection, military or usurped power, civil war, riots or terrorist acts;
              </li>
              <li className="my-4" id="clause-10.1.5">
                {" "}
                10.1.5.	destruction of property including the collapse of building, flooding or fire;
              </li>
              <li className="my-4" id="clause-10.1.6">
                {" "}
                10.1.6.	contamination by radio-activity from any nuclear fuel, or any nuclear waste from the combustion of 
                nuclear fuel, radio-active toxic explosive, or other hazardous properties of any explosive nuclear assembly 
                or nuclear component of such assembly;
              </li>
              <li className="my-4" id="clause-10.1.7">
                {" "}
                10.1.7.	interruptions or failure of utility services including electric power, internet service, gas, or water;
              </li>
              <li className="my-4" id="clause-10.1.8">
                {" "}
                10.1.8.	strikes or other industrial action or blockade;
              </li>
              <li className="my-4" id="clause-10.1.9">
                {" "}
                10.1.9.	any change in regulations or laws or an act of government; and
              </li>
              <li className="my-4" id="clause-10.1.10">
                {" "}
                10.1.10.	the unexpected failure of technical infrastructure, machinery and/or computers.
              </li>
            </ul>
          </li>
          <li className="py-2" id="clause-10.2">
            10.2.	Neither Party shall be considered in breach of this Agreement to the extent that the performance of 
            their respective obligations is reasonably preventable by a Force Majeure Event from the time of that Force 
            Majeure Event.
          </li>
          <li className="py-2" id="clause-10.3">
            10.3.	In the event of a Force Majeure Event, Growthbeans shall have the right to terminate or suspend your 
            use of the WebApp. If and to the extent that Growthbeans is prevented from executing the Services by the 
            Force Majeure Event, while Growthbeans is prevented, Growthbeans shall be relieved of its obligations to 
            provide the Services but shall endeavour to continue to perform its obligations under this Agreement as 
            reasonably practicable.  
          </li>
          </ul>
          <li className="text-2xl font-bold py-4" id="clause-11">
            11.	INDEMNITIES AND LIABILITIES
          </li>
          <p className="py-2">
            You irrevocably undertake to keep Growthbeans, Growthbeans’s respective servants, agents, directors, 
            officers, affiliates, members of Growthbeans’s Group, employees, advisers or representatives, past, 
            present or future, fully and effectively indemnified against and hold harmless with regard to any and 
            all losses (whether direct, indirect, or consequential), costs, damages (whether in tort, contract, or 
            otherwise), claims, demands, actions, proceedings, liabilities, and expenses whatsoever (including all 
            legal costs and/or fees on an indemnity basis) which Growthbeans, Growthbeans’s respective servants, 
            agents, directors, partners, officers, affiliates, members of Growthbeans’s group, employees, advisers 
            or representatives, past, present or future, may at any time sustain, incur or suffer as a result of or 
            in connection with or arising from any breach of, or failure to comply with, or breach of any representation 
            or warranty of any of the terms under this Agreement. 
          </p>
          <li className="text-2xl font-bold py-4" id="clause-12">
            12.	RELEASE
          </li>
          <p className="py-2">
            You agree to release us (collectively with our Affiliates, and each of our and their respective officers, 
            directors, agents, co-branders, licensors, vendors, other partners, independent contractors, and employees, 
            the “Released Parties”) from all damages (whether direct, indirect, incidental, consequential, or otherwise), 
            losses, liabilities, costs, and expenses of every kind and nature, known and unknown, arising out of a dispute 
            between you and a third party (including other Users) in connection with your use of the Service, any event 
            listed through the Services, any actions by third parties, your Content, and/or your Intellectual Property. 
          </p>
          <li className="text-2xl font-bold py-4" id="clause-13">
            13.	RELATION OF THIS AGREEMENT WITH OTHER AGREEMENTS
          </li>
          <p className="py-2">
            This Agreement shall be read together with any other agreements that you agree to be bound by in the 
            course of your use of the Services and WebApp. Where there is any conflict between any of these agreements 
            and these terms, the other agreement which you have entered shall take precedence.  
          </p>
          <li className="text-2xl font-bold py-4" id="clause-14">
            14.	RIGHT TO AMEND TERMS
          </li>
          <p className="py-2">
            You agree that we shall have the right to supplement, vary, or amend this Agreement from time to time. 
            You agree that you shall have received notice of any supplements, variations, and/or amendments where 
            Growthbeans uploads any of such supplements, variations and/or amendments on its WebApp. You agree that 
            you shall have the obligation to review such supplements, variations and/or amendments and agree to be 
            bound to such supplements, variations and/or amendments. In the event you do not agree to the supplements, 
            variations and/or amendments, you must cease and refrain from any use of the WebApp and/or our Services 
            and, where applicable, send us a notice to terminate your Membership with the WebApp 
            per <a href="#clause-9" className="italic">Clause 9</a> (Termination and Suspension of Membership). 
          </p>
          <li className="text-2xl font-bold py-4" id="clause-15">
            15.	JURISDICTION, CHOICE OF LAW
          </li>
          <ul className="text-start">
          <li className="py-2" id="clause-15.1">
            15.1.	<span className="font-bold">Governing Law:</span> This Agreement and all its subsequent variations shall be governed, construed, 
            and interpreted in accordance with the laws of Singapore for every purpose.
          </li>
          <li className="py-2" id="clause-15.2">
            15.2.	<span className="font-bold">Jurisdiction:</span> In relation to any legal action or proceedings arising out of or in connection 
            with this Agreement (“<span className="font-bold">Proceedings</span>”), each of the Parties irrevocably submits to the exclusive 
            jurisdiction of the Singapore courts and waives any objection to Proceedings in such courts on the 
            grounds of venue or on the grounds that Proceedings have been brought in an inappropriate forum.
          </li>
          </ul>
          <li className="text-2xl font-bold py-4" id="clause-16">
            16.	ASSIGNMENT, NOVATION AND DELEGATIONS
          </li>
          <ul className="text-start">
          <li className="py-2" id="clause-16.1">
            16.1.	You agree that your Account and Membership with the WebApp are personal to you. As such, you 
            agree that all your rights and obligations under this Agreement and the Terms of Use may not be 
            assigned, novated, transferred, or delegated to any third party unless provided for in this Agreement 
            or with prior written consent from Growthbeans.
          </li>
          <li className="py-2" id="clause-16.2">
            16.2.	You agree that we have the right to assign, novate, and/or transfer any or all our obligations 
            and rights under this Agreement to any person without reference to you.
          </li>
          </ul>
          <li className="text-2xl font-bold py-4" id="clause-17">
            17.	RIGHTS OF THIRD PARTIES
          </li>
          <ul className="text-start">
          <li className="py-2" id="clause-17.1">
            17.1.	Except otherwise provided for in this Agreement, CRTPA shall not apply to this Agreement and any 
            person who is not a Party to this Agreement shall have no right whatsoever under the CRTPA to enforce 
            this Agreement or any of its terms.
          </li>
          <li className="py-2" id="clause-17.2">
            17.2.	Growthbeans’s servants, agents, directors, partners, officers, affiliates, employees, advisers or 
            representatives, past, present or future, and all companies which are part of Growthbeans’s Group shall 
            have the right to enforce this Agreement as if they were Growthbeans and a party to this Agreement.
          </li>
          </ul>
          <li className="text-2xl font-bold py-4" id="clause-18">
            18.	PERSONAL DATA
          </li>
          <ul className="text-start">
          <li className="py-2" id="clause-18.1">
            18.1.	As part of our obligations to protect your Personal Data, we have set out the processes and procedures 
            we will undertake in providing our Services to you. These have been set out in our Privacy Policy and you 
            may refer to the Privacy Policy with respect to how we will treat your Personal Data. By using the WebApp, 
            you agree to the Privacy Policy.
          </li>
          <li className="py-2" id="clause-18.2">
            18.2.	You agree that we may from time to time use your Personal Data to contact you via email, telephone, 
            SMS, regular mail, or by other means, to update you, provide you with information, and seek clarification 
            of any query we or you may have.
          </li>
          <li className="py-2" id="clause-18.3">
            18.3.	Where you have access to or receive Personal Data from Growthbeans:-
            <ul className="list-inside px-4 py-2">
              <li className="my-4" id="clause-18.3.1">
                {" "}
                18.3.1.	you represent and warrant to us that you shall at all times comply with the requirements 
                of the PDPA, concerning your collection (if applicable), use, processing, disclosure, protection, 
                retention, and any other handling of such Personal Data, and you undertake to continue to comply 
                with the aforesaid requirements of the PDPA in respect of such Personal Data; and
              </li>
              <li className="my-4" id="clause-18.3.2">
                {" "}
                18.3.2.	you shall not transfer any such Personal Data outside Singapore without express written 
                consent from Growthbeans.
              </li>
            </ul>
          </li>
          <li className="py-2" id="clause-18.4">
            18.4.	To the extent that you disclose Personal Data to us, you undertake to us that, by the time of such disclosure:-
            <ul className="list-inside px-4 py-2">
              <li className="my-4" id="clause-18.4.1">
                {" "}
                18.4.1.	you will have obtained all the necessary consents from the relevant individuals to whom the Personal 
                Data relates, for the disclosure of their Personal Data for our collection, use, and/or disclosure; and
              </li>
              <li className="my-4" id="clause-18.4.2">
                {" "}
                18.4.2.	such consents have not been withdrawn.
              </li>
            </ul>
          </li>
          <li className="text-2xl font-bold py-4" id="clause-19">
            19.	MISCELLANEOUS
          </li>
          <ul className="text-start">
          <li className="py-2" id="clause-19.1">
            19.1.	<span className="font-bold">Severability:</span> If any part, term or provision of this Agreement is held to be illegal, in 
            conflict with any law or otherwise invalid, the remaining portion or portions shall be considered 
            severable and not be affected by such determination, and the rights and obligations of the parties 
            shall be construed and enforced as if this Agreement did not contain the particular part, term, or 
            provisions held to be illegal or invalid.
          </li>
          <li className="py-2" id="clause-19.2">
            19.2.	<span className="font-bold">Entire Agreement:</span> This Agreement embodies the entire understanding of the Parties hereto with 
            respect to the subject matter of this Agreement and there are no promises, terms, conditions or 
            obligations, oral or written, express or implied (whether by law or otherwise) other than those 
            contained herein. 
          </li>
          <li className="py-2" id="clause-19.3">
            19.3.	<span className="font-bold">Remedies and Waivers:</span> No failure or delay on the part of any Party to this Agreement to exercise 
            any right or remedy under this Agreement will operate as a waiver thereof, nor will any single or 
            partial exercise of any right or remedy preclude any other or further exercise thereof or the exercise 
            of any other right or remedy. The rights provided in this Agreement are cumulative and not exclusive of 
            any rights or remedies provided by law.  
          </li>
          <li className="py-2" id="clause-19.4">
            19.4.	<span className="font-bold">No Membership, Agency or Fiduciary Relationship:</span> Nothing in this Agreement shall be construed as 
            creating a partnership, principal-agent relationship, joint venture or fiduciary relationship between 
            you and Growthbeans, any of Growthbeans’s servants, agents, directors, partners, officers, affiliates, 
            employees, advisers or representatives, past, present or future, and all companies which are part of 
            Growthbeans’s Group.  
          </li>
          <li className="py-2" id="clause-19.5">
            19.5.	<span className="font-bold">Further Assurance:</span> The Parties shall do and execute or procure to be done and executed all such 
            further acts, deeds, things and documents as the other Parties may reasonably require to fulfil the 
            provisions of and to give to each Party the full benefit of this Agreement.  
          </li>
          <li className="py-2" id="clause-19.6">
            19.6.	<span className="font-bold">Survival:</span> Clauses 
            <a href="#clause-5" className="italic"> 5</a> (Intellectual Property Rights), 
            <a href="#clause-7" className="italic"> 7</a> (Limitation of Liability), 
            <a href="#clause-8" className="italic"> 8</a> (Confidentiality), 
            <a href="#clause-11" className="italic"> 11</a> (Indemnities and Liabilities), 
            <a href="#clause-15" className="italic"> 15</a> (Jurisdiction, Choice of Law), 
            <a href="#clause-17" className="italic"> 17</a> (Rights of Third Parties), 
            <a href="#clause-18" className="italic"> 18</a> (Personal Data), and 
            <a href="#clause-19" className="italic"> 19</a> (Miscellaneous) shall survive the termination of this Agreement.  
          </li>
          </ul>
          </ul>
        </ul>
      </>
    )
  }, {
    title: "ONLINE CONDUCT POLICY",
    content: (
      <>
        <div className="py-4">
          <p className="text-start">
            We wish to provide the best experience for all our Users. In doing so, we will require you not to share, 
            post or upload anything that may be deemed offensive, unsafe, or may cause legal liability to Growthbeans, 
            yourself, or other Users. Accordingly, you agree that you will not post any Content that:
          </p>
          <ul className="text-start py-2 pl-6">
          <li className="py-2 list-inside list-disc">
            is false, untruthful, misleading, fraudulent, and/or inaccurate;
          </li>
          <li className="py-2 list-inside list-disc">
            threatens or promotes sexual violence or exploitation;
          </li>
          <li className="py-2 list-inside list-disc">
            is obscene or contains sexually explicit material;
          </li>
          <li className="py-2 list-inside list-disc">
            involves violence and graphic images that are of public interest or concern, such as human rights abuses, 
            acts of cruelty to animals, or acts of terrorism;
          </li>
          <li className="py-2 list-inside list-disc">
            promotes or encourages self-injury or suicide;
          </li>
          <li className="py-2 list-inside list-disc">
            promotes or encourages others to carry out, and/or threatens others with illegal activity including drug 
            trafficking, theft, vandalism, physical harm, assault, destruction of property, cyber-crime, cruelty to 
            animals, crimes resulting in financial damage, direct threats to public safety, and/or breach of financial 
            regulations, or other requirements;
          </li>
          <li className="py-2 list-inside list-disc">
            is offensive which includes content that directly attacks, discriminates, and/or, demeans individuals, or 
            a group based on their race, ethnicity, age, national origin, religious affiliation, sexual orientation, 
            sex, gender, and /or disabilities;
          </li>
          <li className="py-2 list-inside list-disc">
            is defamatory, libellous, threatening, and/or harassing, including Content that identifies, shames, or 
            degrades individuals; shows physical bullying; or targets individuals;
          </li>
          <li className="py-2 list-inside list-disc">
            involves or promotes the operation of an illegal business, and/or the operation of an unlicensed business 
            that requires licensing;
          </li>
          <li className="py-2 list-inside list-disc">
            involves or promotes the sale of illegal products or products that you or a third party are not lawfully 
            allowed to sell including stolen items, counterfeit items, banned materials, restricted items, and/or narcotics;
          </li>
          <li className="py-2 list-inside list-disc">
            reveals the Personal Data of any individual including telephone numbers, street addresses, or names of 
            any individual;
          </li>
          <li className="py-2 list-inside list-disc">
            infringes the rights of a third party (including intellectual property rights);
          </li>
          <li className="py-2 list-inside list-disc">
            is not lawfully posted, transmitted, transferred, distributed, or uploaded under any applicable law; and
          </li>
          <li className="py-2 list-inside list-disc">
            promotes and/or encourages others to breach their obligations to Growthbeans and/or third parties;
          </li>
          <li className="py-2 list-inside list-disc">
            causes or requires Growthbeans to violate any law, regulation, or Growthbeans’s obligations to any third party; or
          </li>
          <li className="py-2 list-inside list-disc">
            violates this Agreement and any applicable laws and regulations.
          </li>
          </ul>
          <p className="text-start">
            Further, you will be required to keep the WebApp safe and free from harm for all Users and Growthbeans. By 
            using the WebApp, you agree that you shall:-
          </p>
          <ul className="text-start py-2 pl-6">
          <li className="py-2 list-inside list-disc">
            not upload anything that may compromise the WebApp including any malicious code, data or set of instructions 
            that intentionally or unintentionally cause harm or subverts the intended function of the WebApp, including 
            viruses, trojan horses, worms, time bombs, cancelbots, easter eggs, or other computing programming routines 
            that may damage, modify, delete, detrimentally interfere with, surreptitiously intercept, access without 
            authority or expropriate any system, data or personal information;
          </li>
          <li className="py-2 list-inside list-disc">
            not hack into, interfere with, disrupt, disable, over-burden or otherwise impair the proper working of the 
            WebApp, including without limitation to denial-of-service attacks, spoof attacks, session hacking, sniffing, 
            tampering, or reprogramming;
          </li>
          <li className="py-2 list-inside list-disc">
            not alter, disassemble, decompile, reverse engineer, or attempt to discover the source code of any part of the WebApp;
          </li>
          <li className="py-2 list-inside list-disc">
            not use software that reads data on the WebApp and/or the Services automatically;
          </li>
          <li className="py-2 list-inside list-disc">
            not use the Account of another User at any time for any purpose, whether with or without his/her permission;
          </li>
          <li className="py-2 list-inside list-disc">
            not do anything that would appear to, or would create a liability for Growthbeans or cause Growthbeans to lose 
            (in whole or in part) the services of our internet service providers or other suppliers;
          </li>
          <li className="py-2 list-inside list-disc">
            not use any information obtained from the WebApp to harass, abuse, or harm another person;
          </li>
          <li className="py-2 list-inside list-disc">
            not impersonate another person when posting content;
          </li>
          <li className="py-2 list-inside list-disc">
            not post harmful URLs to external websites or any form of HTML or programming;
          </li>
          <li className="py-2 list-inside list-disc">
            not harass, threaten, stalk, or abuse any User; 
          </li>
          <li className="py-2 list-inside list-disc">
            not engage in any conduct that restricts or inhibits any User from using or enjoying the WebApp;
          </li>
          <li className="py-2 list-inside list-disc">
            not use the WebApp or its functions for any unlawful purpose;
          </li>
          <li className="py-2 list-inside list-disc">
            comply with all applicable laws and regulations in your use of the WebApp; and
          </li>
          <li className="py-2 list-inside list-disc">
            comply with any further notices, supplements, variations and/or amendments to this Online Conduct Policy.
          </li>
          </ul> 
          <p className="text-start">Last updated: 24 October 2025</p>
        </div>
      </>
    )
  }
]

interface TermsContentProps {
  startIndex: number; 
}

const TermsContent: React.FC<TermsContentProps> = ({ startIndex }) => {

  useEffect(() => {
    sectionRefs.current[startIndex]?.scrollIntoView({ behavior: 'smooth' });
  }, [startIndex]);

  const sectionRefs = useRef<(HTMLDivElement | null)[]>([]); // Specify the type for refs

  return (
    <div style={{ color: "var(--text-colour)" }}>
      {termsSections.map((section, index) => (
          <div key={index} ref={el => sectionRefs.current[index] = el}>
            <br />
            <h1 className="text-3xl font-bold">{section.title}</h1>
            {section.content}
          </div>
        ))}      
    </div>
  );
};

export default TermsContent;
