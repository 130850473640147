import { Tabs } from "flowbite-react";
import classes from "./AdminTab.module.css";
import { Dispatch, SetStateAction } from "react";

interface Props {
  children: React.ReactNode;
  setTab?: Dispatch<SetStateAction<number>>;
}

export default function AdminTab({ children, setTab }: Props) {
  return (
    <Tabs.Group
      onActiveTabChange={(tab) => {
        if (setTab) {
          setTab(tab);
        }
      }}
      className={classes.tab}
    >
      {children}
    </Tabs.Group>
  );
}
