import FirstNameAvatar, {
  getIconMainColour,
} from "components/utility/FirstNameAvatar";
import useGetParticipants from "hooks/useGetParticipants";
import { Participant } from "interface/ParticipantInterface";
import { SpecialThanksInterface } from "interface/SpecialThanksInterface";

const SpecialThanksItem = ({
  participant,
  message,
}: {
  participant: Participant;
  message: SpecialThanksInterface;
}) => {
  const { getParticipantColourIndex } = useGetParticipants();
  return (
    <div
      style={{ borderColor: "var(--main-colour)" }}
      className="border-2 rounded-lg flex justify-start flex-col bg-white px-4 py-2 m-2"
    >
      <div className="flex gap-2 justify-start items-center">
        {participant && (
          <>
            <FirstNameAvatar
              name={participant.userName}
              participant={participant}
              styleTriggered={true}
              participantNumber={getParticipantColourIndex(participant.userId)}
            />
            <p
              className="font-bold w-36 text-ellipsis overflow-hidden"
              style={{
                color: getIconMainColour(
                  getParticipantColourIndex(participant.userId)
                ),
              }}
            >
              {participant.userName}
            </p>
          </>
        )}
      </div>
      <p
        className="my-4 flex  text-left font-poppins first-letter:uppercase"
        style={{ fontSize: "14px", fontWeight: "500", lineHeight: "15px" }}
      >
        {message.message}
      </p>
    </div>
  );
};

export default SpecialThanksItem;
