import React from "react";
import classes from "./Pathways.module.css";
import { PATHWAYS } from "strings/ChoosePathway";

interface Props {
  currSelected: string;
  setCurrSelected: React.Dispatch<React.SetStateAction<string>>;
}

const Pathways: React.FC<Props> = ({ currSelected, setCurrSelected }) => {
  const choosePathway = (path: string) => {
    setCurrSelected(path);
  };

  return (
    <div className={classes["pathway-container"]}>
      {PATHWAYS.map((path, index) => {
        return (
          <div
            key={index}
            className={`${classes["path-container"]} ${
              currSelected === path.value && classes["path-selected"]
            }`}
            onClick={() => choosePathway(path.value)}
          >
            <strong>{path.label}</strong>
          </div>
        );
      })}
    </div>
  );
};

export default Pathways;
