import FeedBackStepper from "components/FeedBackForm/FeedBackStepper";
import FiveStepHeader from "components/FiveStepHeader/FiveStepHeader";
import Header from "components/Header/Header";
import PageWrapper from "components/utility/PageWrapper";
import Steps from "components/utility/Steps";
import { useEffect, useRef, useState } from "react";
import classes from "./Reflection.module.css";
import ReflectionFeedBackForm from "./ReflectionFeedBackForm";
import ReflectionRateOtherUsers from "./ReflectionRateOtherUsers";
import ReflectionRatingSmiley from "./ReflectionRatingSmiley";
import InfoModal from "./InfoModal";
import { LocalStorageKeys } from "App";
import { useNavigate } from "react-router-dom";
import ConfirmModal from "components/utility/ConfirmModal";
import useGetParticipants from "hooks/useGetParticipants";
import ContentWrapper from "components/utility/ContentWrapper";
import { useDragRating } from "hooks/useDragRating";
import useRatingSmiley from "hooks/useRatingSmiley";
import useReflectionRatings from "hooks/useReflectionRating.ts";
// import { useAuthContext } from "../../../hooks/useAuthContext";
import { END_OF_SESSION_ROUTE } from "../../../strings/Routes";
import NoGCSessionWrapper from "../../NoGCSession/NoGCSessionWrapper";
import RedirectWrapper from "../../../components/utility/RedirectWrapper";
import LoadingWidget from "../../../components/utility/LoadingWidget";
import { useAuthContext } from "hooks/useAuthContext";
import { useFirestore } from "hooks/useFirestore";

/**
 * Page that includes multi-subpages for users to fill in their feedback at the end of each GrowthCircle session
 */
export default function ReflectionMainRating() {
  const { profile } = useAuthContext();
  const submitModalId = "submit-modal";
  const [showSubmitModal, setShowSubmitModal] = useState(false);
  const [showHelper, setShowHelper] = useState(false);
  // const userStoragekey = profile!.id + "_";
  const { updateDocument } = useFirestore("users");
  const {
    dragValues: gcDragValues,
    // setDragValues: setGcDragValues,
    // anyUntouched,
    // dragError: gcDragError,
    // setDragError: setGcDragError,
    // updatesFeedbackFormWithDragRating,
  } = useDragRating(LocalStorageKeys.REFLECTION.DRAG_RATINGS);
  const {
    allValues: allGcSmileyValues,
    setAllValues: setAllGcSmileyValues,
    anyUntouched: anyGcSmileyUntouched,
    smileyError: gcSmileyError,
    setSmileyError: setGcSmileyError,
  } = useRatingSmiley(LocalStorageKeys.REFLECTION.GC_SMILEY_RATINGS); //For Section 2 of Feedback
  const {
    allValues: allPersonalSmileyValues,
    setAllValues: setAllPersonalSmileyValues,
    anyUntouched: anyPersonalSmileyUntouched,
    smileyError: personalSmileyError,
    setSmileyError: setPersonalSmileyError,
  } = useRatingSmiley(LocalStorageKeys.REFLECTION.PERSONAL_SMILEY_RATINGS); //For Section 4 of Feedback
  // const [handleSubmitFeedbackForm, clearReflectionFields] = useFeedbackForm();
  const { handleSubmitFeedbackForm, clearReflectionFields } =
    useReflectionRatings();
  const [pageNumber, setPageNumber] = useState(1); //stepper max 5
  const [showLoadingWidget, setShowLoadingWidget] = useState(false);
  const currentStep = 5;
  const stepsCount = 5;
  const topRef = useRef<HTMLDivElement | null>(null);
  let navigate = useNavigate();

  const { participants } = useGetParticipants();

  useEffect(() => {
    if (!profile) return;
    const data = { pageStep: window.location.pathname };
    updateDocument(profile.uid, data);
    if (topRef.current) {
      // console.log("running");
      topRef.current.scrollIntoView();
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [pageNumber, profile]);

  const validatePage = () => {
    //Switch statement to handle validation for each page
    switch (pageNumber) {
      // case 1:
      //   //Logic to validate 1
      //   const pageOneError = anyUntouched();
      //   if (pageOneError) {
      //     setGcDragError(true);
      //   }
      //   setGcSmileyError(false);
      //   return pageOneError;
      case 1:
        //Logic to validate 2
        const pageTwoError = anyGcSmileyUntouched();
        if (pageTwoError) {
          setGcSmileyError(true);
        }
        setPersonalSmileyError(false);
        return pageTwoError;
      case 2:
        //Logic to validate 3
        const pageThreeError = anyPersonalSmileyUntouched();
        if (pageThreeError) {
          setPersonalSmileyError(true);
        }
        return pageThreeError;
      case 3:
        //Logic to validate 4
        return false;
      case 4:
        //Logic to validate 5
        return false;
      default:
        return true;
    }
  };

  const nextHandler = () => {
    //TODO: this generates blank page
    // !! Uncomment after finish testing ReflectionRatingAverage
    if (!validatePage() && pageNumber !== 4) {
      setPageNumber(pageNumber + 1);
    }
    if (pageNumber === 4) {
      setShowSubmitModal(true);
    }
    // setPageNumber(5);
  };

  const prevHandler = () => {
    setPageNumber(pageNumber - 1);
  };
  //TODO : this execute the done button
  const doneHandler = () => {
    setShowSubmitModal(false);
    //Submit feedback
    setShowLoadingWidget(true);
    handleSubmitFeedbackForm().then((res) => {
      clearReflectionFields();
      navigate(END_OF_SESSION_ROUTE);
    });
  };

  //After confirming modal, handle submit
  const submitHandler = (e) => {
    e.preventDefault();
    nextHandler();
  };

  // const helperHandler = () => {
  //   setShowHelper(true);
  // };

  const dismissHandler = () => {
    setShowHelper(false);
  };

  // const changeGcValuesHandler = (e) => {
  //   setGcDragValues(e);
  // };

  return (
    <NoGCSessionWrapper>
      <PageWrapper forSticky={true}>
        <div id="terms-modal" />
        <Header />
        <FiveStepHeader
          hasLightning={true}
          hasGuide={true}
          currentStep={currentStep}
          subPage={pageNumber}
        />
        {showHelper && <InfoModal dismissHandler={dismissHandler} />}
        <ContentWrapper hasFiveStepper={true}>
          <RedirectWrapper>
            {document && !showLoadingWidget && (
              <form
                className={classes["reflection-form"]}
                onSubmit={submitHandler}
              >
                <div className={classes["reflection-container"]}>
                  <div ref={topRef} className={classes["steps-container"]}>
                    <Steps count={stepsCount} currentStep={currentStep} />
                  </div>
                  {pageNumber !== 6 && (
                    <div className={classes.description}>
                      {/* {pageNumber === 6 && 'These are how others have rated you.'} */}
                      Take some time to answer these questions.
                    </div>
                  )}
                  {pageNumber !== 6 && (
                    <FeedBackStepper
                      currentStep={pageNumber}
                      numberOfPage={4}
                    />
                  )}
                  {/* {pageNumber === 1 && (
                  <ReflectionDragRating
                    allDragValues={gcDragValues}
                    valueHandler={changeGcValuesHandler}
                    dragError={gcDragError}
                  />
                )} */}
                  {pageNumber === 1 && (
                    <ReflectionRatingSmiley
                      prompt={
                        "To what extent has today’s GrowthCircle supported you?"
                      }
                      allDragValues={gcDragValues}
                      allValues={allGcSmileyValues}
                      setAllValues={setAllGcSmileyValues}
                      showError={gcSmileyError}
                    />
                  )}
                  {pageNumber === 2 && (
                    <ReflectionRatingSmiley
                      prompt={"How do you rate yourself today in these areas?"}
                      allDragValues={gcDragValues}
                      allValues={allPersonalSmileyValues}
                      setAllValues={setAllPersonalSmileyValues}
                      showError={personalSmileyError}
                    />
                  )}
                  {pageNumber === 3 && (
                    <ReflectionFeedBackForm
                      feedBackDone={() => setShowSubmitModal(true)}
                    />
                  )}
                  {pageNumber === 4 && (
                    <ReflectionRateOtherUsers participants={participants} />
                  )}
                  {/* {pageNumber === 6 && <ReflectionRatingAverage participants={participants}/>} */}
                  <br />
                  <div className={classes["button-next-container"]}>
                    {pageNumber !== 6 && (
                      <div className={classes["bottom-nav"]}>
                        {pageNumber !== 1 && (
                          <button type="button" onClick={prevHandler}>
                            BACK
                          </button>
                        )}
                        {
                          pageNumber !== 4 && (
                            // (pageNumber !== 3 || showOthersRatings !== null) && (
                            <button type="submit">NEXT</button>
                          )
                          // )
                        }
                        {pageNumber === 4 && (
                          <button
                            type="submit"
                            className={classes["submit-button"]}
                          >
                            Done
                          </button>
                        )}
                      </div>
                    )}
                    {/* Submit Button */}
                  </div>
                </div>
              </form>
            )}
            {showLoadingWidget && <LoadingWidget />}
          </RedirectWrapper>
        </ContentWrapper>
        {showSubmitModal && (
          <ConfirmModal
            hasTitle={true}
            hasMessage={true}
            hasBackDrop={true}
            modalId={submitModalId}
            onClick={() => setShowSubmitModal(false)}
            onAccept={() => doneHandler()}
            title={"Do you want to submit?"}
            message={`You can only do this once.`}
          />
        )}
        <div id={submitModalId} />
      </PageWrapper>
    </NoGCSessionWrapper>
  );
}
