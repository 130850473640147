import Role from "interface/RoleInterface";

import PageWrapper from "components/utility/PageWrapper";
import { useSwipeable } from "react-swipeable";
import { useCollection } from "hooks/useCollection";
import { useAuthContext } from "hooks/useAuthContext";
import { useGrowthCircleContext } from "hooks/useGrowthCircleContext";
import useUpdateProgress from "hooks/useUpdateProgress";
import { lazy, useEffect, useState, Suspense } from "react";
import { useCarousel } from "hooks/useCarousel";
import Header from "components/Header/Header";
import ContentWrapper from "components/utility/ContentWrapper";
import EmptySpace from "components/utility/EmptySpace";
import classes from "./ChooseRoles.module.css";
import RequestVerifyEmailModal from "components/Journal/RequestVerifyEmailModal";
import { Link } from "react-router-dom";
import { ImHome } from "react-icons/im";

const Carousel = lazy(() => import("components/ChooseRoles/Carousel"));
const CarouselRoles = lazy(
  () => import("components/ChooseRoles/CarouselRoles")
);
const Roles = lazy(() => import("components/ChooseRoles/Role/Roles"));

interface SkillCollection {
  [key: string]: Role[];
}

// documents is in the format of [{activeListening: {}} ...]
// this method gets all the field names
const getGeneralSkillArray = (documents: SkillCollection[]): string[] => {
  return documents.map((skill) => {
    delete skill.createdAt; // need to remove all fields until only left with skill object
    delete skill.id;
    return Object.keys(skill)[0];
  });
};

const MyRoles = () => {
  const { documents } = useCollection("Skills", undefined, [
    "createdAt",
    "asc",
  ]);

  const { profile } = useAuthContext();
  const { growthCircleSession } = useGrowthCircleContext();
  const { updatedProgressObject } = useUpdateProgress(documents, profile);
  //Props

  const [currSelected] = useState<Role | null>(null);
  const { currentIndex, next, prev, changeIndex } = useCarousel(
    documents ? documents.length : 0
  );

  // skillContent contains information about each level
  const [skillContent, setSkillContent] = useState<Role[]>([]);

  // array of general skills in carousel
  const [skillNameArray, setSkillNameArray] = useState<string[]>([]);

  // to get array of content for each carousel page
  useEffect(() => {
    if (documents && documents.length > 0) {
      // get
      setSkillNameArray(getGeneralSkillArray(documents));

      const currSkill = documents[currentIndex];
      const currContent = currSkill[Object.keys(currSkill)[0]];

      setSkillContent(currContent);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [
    currentIndex,
    documents,
    profile,
    updatedProgressObject,
    growthCircleSession,
  ]);

  const swipeHandlers = useSwipeable({
    onSwiped: () => console.log("swiping"),
    onSwipedLeft: next,
    onSwipedRight: prev,
  });

  return (
    <>
      {updatedProgressObject && (
        <>
          <PageWrapper pageType={"groundRules"}>
            <Header hasModal={!profile?.isVerified} />

            <RequestVerifyEmailModal show={!profile?.isVerified} />
            <ContentWrapper hasFiveStepper={true}>
              <Link
                to="/"
                className="flex justify-center w-1/2 mx-auto text-center py-4"
              >
                <ImHome size={30} />
              </Link>
              <div
                className={classes["choose-roles-content"]}
                {...swipeHandlers}
              >
                {" "}
                <Suspense fallback={<div>Loading...</div>}>
                  <Carousel
                    children={skillNameArray.map((role, index) => {
                      return (
                        <Suspense key={index} fallback={<div>Loading...</div>}>
                          <CarouselRoles
                            name={role}
                            index={index}
                            currentIndex={currentIndex}
                            setCurrentIndex={changeIndex}
                          />
                        </Suspense>
                      );
                    })}
                    next={next}
                    prev={prev}
                    currentIndex={currentIndex}
                    displayCount={"3"}
                    arrowOffset={"2%"}
                    width={"80%"}
                    arrowColor={"var(--main-colour)"}
                  />
                </Suspense>
                <EmptySpace height={"20px"} />
                {/* <div className={classes['roles-container']}> */}
                <Suspense fallback={<div>Loading...</div>}>
                  <Roles
                    currentIndex={currentIndex}
                    content={skillContent}
                    currSelected={currSelected}
                    setCurrSelected={() => {
                      return false;
                    }}
                  />
                </Suspense>
                {/* </div> */}
              </div>

              <EmptySpace height={"40px"} />
            </ContentWrapper>
          </PageWrapper>
        </>
      )}
    </>
  );
};

export default MyRoles;
