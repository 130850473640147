import { Participant } from "interface/ParticipantInterface";
import classes from "./TotalScore.module.css";
import { FC } from "react";
import TotalScoreRow from "./TotalScoreRow";
import Organisation from "interface/OrganisationInterface";

interface Props {
  participants: Participant[];
  setIsModal: (b) => any;
  organisation?: Organisation;
}

const TotalScore: FC<Props> = ({ participants, setIsModal, organisation }) => {
  let redArr: JSX.Element[] = [];
  let notRedArr: JSX.Element[] = [];

  participants.forEach((p, index) => {
    let beforeTotal = 0;
    let afterTotal = 0;
    if (p.checkIn) {
      beforeTotal =
        p.checkIn.individually +
        p.checkIn.interpersonally +
        p.checkIn.socially +
        p.checkIn.overall;
    }
    if (p.checkOut) {
      afterTotal =
        p.checkOut.individually +
        p.checkOut.interpersonally +
        p.checkOut.socially +
        p.checkOut.overall;
    }

    const maxRating = organisation?.maxRating ? organisation.maxRating * 4 : 40;
    const flagRatingPercentage = organisation?.flagRatingPercentage ? organisation.flagRatingPercentage : 60;
    const flagValue = (flagRatingPercentage / 100) * maxRating;

    if (beforeTotal < flagValue || (afterTotal < flagValue && Math.sign(afterTotal) !== -1)) {
      redArr.push(
        <TotalScoreRow
          key={index}
          name={p.userName}
          beforeTotal={beforeTotal < 0 ? undefined : beforeTotal}
          afterTotal={afterTotal < 0 ? undefined : afterTotal}
          participant={p}
          setIsModal={setIsModal}
          organisation={organisation}
        />
      );
      return;
    }
    notRedArr.push(
      <TotalScoreRow
        key={index}
        name={p.userName}
        beforeTotal={beforeTotal !== Infinity ? beforeTotal : undefined}
        afterTotal={afterTotal !== Infinity ? afterTotal : undefined}
        participant={p}
        setIsModal={setIsModal}
        organisation={organisation}
      />
    );
  });

  return (
    <div className={`${classes["container"]} shadow-md`}>
      <h1
        className={`sticky font-bold text-lg justify-self-center text-default mb-4`}
      >
        For facilitator info only
      </h1>
      {redArr.length > 0 && organisation?.enableFlagRating && (
        <h3 className={classes["red-note"]}>
          Take special note of those
          <strong className="whitespace-pre"> above </strong>
          the dotted line
        </h3>
      )}

      <div className={`${classes["header"]} text-default`}>
        <div></div>
        <h1>Name</h1>
        <h1 className="justify-self-center">Before</h1>
        <h1 className="justify-self-center">After</h1>
      </div>
      <div className={classes["row-container"]}>
        {redArr.map((p) => p)}
        {redArr.length > 0 && organisation?.enableFlagRating && (
          <div className="border-dashed border-yellow-900 border"></div>
        )}
        {notRedArr.map((p) => p)}
      </div>
    </div>
  );
};

export default TotalScore;
