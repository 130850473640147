import { SelfAwarenessToolInterface } from "interface/SelfAwarenessToolInterface";
import TitleBox from "../SubComponents/TitleBox";
import { BackAndNextProps } from "../SelfAwarenessComponentMapping";
import { useState } from "react";
import WeightageComp from "../SubComponents/WeightageComp";
import CriteriaGrading from "../SubComponents/CriteriaGrading";
import CriteriaFinalGrade from "../SubComponents/CriteriaFinalGrade";
import ConfirmModal from "../SubComponents/ConfirmModal";

interface DecisionCriteriaProps {
  journal: SelfAwarenessToolInterface;
  backAndNextHandler: BackAndNextProps;
  setLocalComponent: React.Dispatch<React.SetStateAction<string>>;
  setHasModal: (b: boolean) => any;
}
const DecisionCriteria = ({
  journal,
  backAndNextHandler,
  setLocalComponent,
  setHasModal,
}: DecisionCriteriaProps) => {
  const [compIndex, setCompIndex] = useState<number>(0);
  const [showConfirm, setShowConfirm] = useState<boolean>(false);

  const handler = () => {
    setShowConfirm(!showConfirm);
    setHasModal(!showConfirm);
  };

  const componentMap = [WeightageComp, CriteriaGrading, CriteriaFinalGrade];

  const backHandler = () => {
    if (compIndex > 0) {
      setCompIndex((prevIndex) => prevIndex - 1);
    } else {
      setLocalComponent("DecisionSelection");
    }
  };

  const nextHandler = (e: React.MouseEvent<HTMLButtonElement, MouseEvent>) => {
    if (compIndex < componentMap.length - 1) {
      setCompIndex((prevIndex) => prevIndex + 1);
    } else {
      handler();
    }
  };

  const CurrentComponent = componentMap[compIndex];

  return (
    <>
      {journal && (
        <ConfirmModal
          setHasModal={setHasModal}
          backAndNextHandler={backAndNextHandler}
          handler={handler}
          showConfirm={showConfirm}
        />
      )}
      <TitleBox
        journal={journal}
        title="The Decision Criteria"
        showInfo={true}
        size="small"
        handler={() => {}}
      />
      <CurrentComponent
        backHandler={backHandler}
        nextHandler={(e) => nextHandler(e)}
        backAndNextHandler={backAndNextHandler}
        journal={journal}
      />
    </>
  );
};

export default DecisionCriteria;
