import { useEffect, useState } from "react";
import React from "react";
import {
  BackAndNextProps,
  ComponentProps,
} from "../GenericSessionPage/ComponentMapping";
import FeedbackSelfComponent from "./FeedbackSelfComponent";
import EmptySpace from "components/utility/EmptySpace";
import FeedbackFacilComponent from "./FeedbackFacilComponent";
import FeedbackGroupComponent from "./FeedbackGroupComponent";
import useOrganisationContext from "hooks/organisation/useOrganisationContext";
import { Toaster } from "react-hot-toast";

export interface FeedbackProps {
  backAndNextHandler: BackAndNextProps;
  setTabNumber: React.Dispatch<React.SetStateAction<number>>;
  setHasModal: (b: boolean) => any;
  tabNumber?: number;
}

const FeedbackAfterComponent = ({ setHasModal }: ComponentProps) => {
  const [tabNumber, setTabNumber] = useState(1);
  const { selectedOrganisation } = useOrganisationContext();
  const [components, setComponent] = useState<any>();

  useEffect(() => {
    if (!selectedOrganisation) return;

    if (selectedOrganisation.name === "NTU Career & Attachment Office") {
      setComponent([FeedbackSelfComponent, FeedbackGroupComponent]);
    } else {
      setComponent([
        FeedbackSelfComponent,
        FeedbackFacilComponent,
        FeedbackGroupComponent,
      ]);
    }
    // eslint-disable-next-line
  }, [selectedOrganisation]);

  const FeedbackAfterComponent = components && components[tabNumber - 1];

  useEffect(() => {
    window.scrollTo({ top: -1, left: -1, behavior: "smooth" });
    // eslint-disable-next-line
  }, []);

  return (
    <React.Fragment>
      <EmptySpace height={"25px"} />
      <Toaster toastOptions={{ duration: 3000 }} position={"bottom-center"} />
      {components && (
        <FeedbackAfterComponent
          setTabNumber={setTabNumber}
          tabNumber={tabNumber}
          backAndNextHandler={{
            nextAllowed: true,
            backAllowed: tabNumber > 1,
            nextCallback: (e) => {},
            backCallback: (e) => {},
          }}
          setHasModal={setHasModal}
        />
      )}
    </React.Fragment>
  );
};

export default FeedbackAfterComponent;
