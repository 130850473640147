import { useParams } from "react-router-dom";
import { ChangeEvent, FormEvent, useEffect, useRef, useState } from "react";

import PageWrapper from "components/utility/PageWrapper";

import Checkbox from "assets/Checkbox";
import TickedCheckbox from "assets/TickedCheckbox";
import WelcomeMessage from "components/Login/WelcomeMessage";
import InputField from "components/utility/Forms/InputField";
import Button from "components/utility/Button";
import TermsModal from "components/Login/TermsModal";
import classes from "./Signup.module.css";
import ReCAPTCHA from "react-google-recaptcha";
import { checkPasswordError } from "../../utility/inputValidation";

//import hook and useState
import { useSignup } from "../../hooks/useSignup";
import toast, { Toaster } from "react-hot-toast";
import {
  getOrgById,
  joinOrganisation,
  joinUserFieldOrganisation,
} from "models/organisation";
import { getUserByEmail, updateUser } from "models/profile";
import { facilitatorRole, participantRole } from "models/organisationRole";
import useGroup from "hooks/organisation/useGroup";
import Organisation, {
  OrganisationDropdownItem,
} from "interface/OrganisationInterface";
import PasswordChecker from "./PasswordChecker";
import { createAndAddOneOrgUser } from "utility/orgUsersHelpers";

const SignupInvite = () => {
  const { group, id } = useParams();
  const [email, setEmail] = useState("");
  const [password, setPassword] = useState("");
  const [confirm_password, setConfirmPassword] = useState("");
  const [displayName, setDisplayName] = useState("");
  const { usernameError, emailError, passwordError, signup, isPending, error } =
    useSignup();
  const [termsAccepted, setTermsAccepted] = useState(false);
  const [showTerms, setShowTerms] = useState(false);
  const recaptchaRef = useRef();
  const [verified, setVerified] = useState(false);
  const [isStrong, setIsStrong] = useState(false);

  const {
    getSubGroups,
    getGroupOrgIDAndByGroupName,
    joinOrganitionsIdAndGroupId,
  } = useGroup();
  const [subGroups, setSubGroups] = useState<Array<string>>([]);
  const [organisation, setOrganisation] = useState("");
  const [organisationObject, setOrganisationObject] = useState<Organisation>();

  const [tutorialGroup, setTutorialGroup] = useState(group ?? "");
  const [personalID, setPersonalID] = useState("");

  const [selectedValues, setSelectedValues] = useState<{
    [label: string]: string;
  }>({});

  const [textareaValues, setTextareaValues] = useState<{
    [label: string]: string;
  }>({});

  const handleDropdownChange = (label: string, value: string) => {
    const formattedLabel = label.replace(/ /g, "_");

    setSelectedValues({
      ...selectedValues,
      [formattedLabel]: value,
    });

    // If "Others" is selected in the dropdown, clear the textarea value
    if (value === "Others") {
      setTextareaValues({
        ...textareaValues,
        [formattedLabel]: "",
      });
    }
  };

  const handleTextareaChange = (label: string, value: string) => {
    const formattedLabel = label.replace(/ /g, "_");

    setTextareaValues({
      ...textareaValues,
      [formattedLabel]: value,
    });
  };

  const OrgID = organisationObject?.id;

  // Initialize an empty object to store the collected data with a dynamic key
  const collectedDataObject: {
    [key: string]: { label: string; value: string; otherValues?: string }[];
  } = {
    [OrgID || "default"]: [], // Use OrgID as the key, or a default key if OrgID is falsy
  };

  // Collect the data and populate the object
  Object.keys(selectedValues).forEach((label) => {
    const originalLabel = label.replace(/_/g, " "); // Revert back to original label format

    collectedDataObject[OrgID || "default"].push({
      label: originalLabel,
      value: selectedValues[label],
      otherValues: textareaValues[label] ?? "",
    });
  });

  const _getSubGroups = async (group) => {
    const data = await getSubGroups(group);
    const subGroupNames = data.map((item) => item.subGroupName);
    subGroupNames.sort();
    setSubGroups(subGroupNames);
  };

  const handleChange = (e: string) => {
    const newPassword: string = e;
    setPassword(newPassword);
    // Set the state based on the criteria
    setIsStrong(checkPasswordError(newPassword));

    // Hide terms if there is password is not strong (especially if the password set was initially strong)
    if (!checkPasswordError(newPassword)) {
      setShowTerms(false);
      setTermsAccepted(false);
    }
  };

  const setConfirmPasswordHandler = (e: string) => {
    setConfirmPassword(e);
    handleChange(password);
    // Check if the password matches the confirmation
    const matches = password === e;
    setIsStrong(matches); // Update isStrong based on matches

    // Hide terms if there is no match
    if (!matches) {
      setShowTerms(false);
      setTermsAccepted(false);
    }
  };

  useEffect(() => {
    if (!group || !id) return;
    _getSubGroups(group);

    getOrgById(id).then((doc) => {
      if (doc) {
        setOrganisation(doc.name);
      }
    });

    // eslint-disable-next-line
  }, [group, id, organisation]);

  useEffect(() => {
    if (!id) return;
    getOrgById(id).then((doc) => {
      if (doc) {
        if (!organisationObject) setOrganisationObject(doc);
      }
    });

    // eslint-disable-next-line
  }, [group, id, organisationObject]);

  const checkboxHandler = () => {
    if (termsAccepted === true) {
      setTermsAccepted(false);
    } else {
      setTermsAccepted(true);
    }
  };

  const buttonStyle = termsAccepted
    ? {
        fontSize: "1em",
        fontWeight: "600",
      }
    : {
        fontSize: "1em",
        fontWeight: "600",
        borderColor: "#C4C4C4",
        color: "#C4C4C4",
      };

  const showTermsHandler = () => {
    setShowTerms(true);
    console.log(showTerms);
  };

  const hideTermsHandler = () => {
    setShowTerms(false);
  };

  //registerUser
  const submitHandler = async (e: FormEvent) => {
    e.preventDefault();
    if (password === confirm_password) {
      const { res } = await signup(
        email,
        password,
        displayName,
        {
          personalID,
          tutorialGroup,
        },
        collectedDataObject
      );

      if (res !== null) {
        const profile = await getUserByEmail(email).then((profile) => {
          return profile;
        });
        if (id && profile) {
          const role = profile.isFacil
            ? facilitatorRole.name
            : participantRole.name;
          getOrgById(id).then((org) => {
            if (org) {
              joinOrganisation(org, profile?.uid, role);
              createAndAddOneOrgUser(org, profile, role);
              //TODO: temporary function
              joinUserFieldOrganisation(org, profile?.uid, role);
              // add invitation
              updateUser(profile.uid, { organisationInvite: org.name });
            }
          });
          const subGroupID = await getGroupOrgIDAndByGroupName(
            id,
            tutorialGroup
          );
          if (subGroupID.length > 0) {
            await joinOrganitionsIdAndGroupId(profile?.id, id, subGroupID[0]);
          }
        }
      }
    } else {
      toast.error("Passwords do not match");
    }
  };

  const handleRecaptcha = (response) => {
    verifyRecaptcha(response);
  };
  const verifyRecaptcha = async (response: string) => {
    const url =
      "https://us-central1-nobeans-110a4.cloudfunctions.net/verifyRecaptcha";
    const params = new URLSearchParams();

    params.append("response", response);

    try {
      const response_1 = await fetch(url, {
        method: "POST",
        body: params,
        headers: { "Content-Type": "application/x-www-form-urlencoded" },
      });
      const data = await response_1.json();
      // console.log(data);
      if (data.success) {
        setVerified(true);
      } else {
        setVerified(false);
        toast.error("failed captcha please try again");
      }
    } catch (error) {
      console.error(error);
    }
  };

  return (
    <PageWrapper>
      <Toaster />

      {showTerms && (
        <TermsModal
          show={showTerms}
          onClick={hideTermsHandler}
          onAccept={checkboxHandler}
        />
      )}
      <div id="terms-modal" />
      <div className={`${classes.header} my-4`}>
        <WelcomeMessage />
      </div>
      <form className={classes.form} onSubmit={submitHandler}>
        <div className={classes.inputs}>
          <InputField
            text="Username"
            isRequired={true}
            type={"text"}
            Invalid={usernameError}
            onChange={(e) => setDisplayName(e.target.value)}
            value={displayName}
          />
          <InputField
            text="Email"
            isRequired={true}
            type={"email"}
            Invalid={emailError}
            onChange={(e) => setEmail(e.target.value)}
            value={email}
          />

          {/* { //TODO : Change this logic to be a feature} */}
          {id && organisation === "SUSS" && (
            <>
              <div className="py-2">
                {" "}
                <InputField
                  isRequired={true}
                  text="Personal Identification"
                  type={"text"}
                  onChange={(e) => setPersonalID(e.target.value)}
                  value={personalID}
                />
              </div>
              <div className="py-2">
                <InputField
                  type="select"
                  isRequired={true}
                  Invalid={passwordError}
                  onChange={(e) => setTutorialGroup(e.target.value)}
                  options={[...subGroups]}
                  placeholder="Select Group"
                />
              </div>
            </>
          )}

          <InputField
            text="Password"
            isRequired={true}
            oncopy="return false"
            onpaste="return false"
            Invalid={passwordError}
            type={"password"}
            onChange={(e: ChangeEvent<HTMLInputElement>) =>
              handleChange(e.target.value)
            }
            value={password}
          />
          <InputField
            text="Confirm Password"
            isRequired={true}
            oncopy="return false"
            onpaste="return false"
            Invalid={passwordError}
            type={"password"}
            onChange={(e: ChangeEvent<HTMLInputElement>) =>
              setConfirmPasswordHandler(e.target.value)
            }
            value={confirm_password}
          />
        </div>

        <PasswordChecker
          password={password}
          confirm_password={confirm_password}
        />

        {id && organisationObject?.hasUniqueFields && (
          <>
            {organisationObject.uniqueFields &&
              organisationObject.uniqueFields.map(
                (item: OrganisationDropdownItem, index) =>
                  item.field_type === "dropdown" ? (
                    <div key={index}>
                      <div className="py-2">
                        <InputField
                          type="select"
                          isRequired={true}
                          onChange={(e) =>
                            handleDropdownChange(item.label, e.target.value)
                          }
                          options={[...item.options]}
                          placeholder={item.label}
                        />
                      </div>

                      {selectedValues[item.label.replace(/ /g, "_")] ===
                        "Others" && (
                        <div>
                          <textarea
                            className="w-full"
                            required
                            value={
                              textareaValues[item.label.replace(/ /g, "_")] ||
                              ""
                            }
                            onChange={(e) =>
                              handleTextareaChange(item.label, e.target.value)
                            }
                            placeholder={`Others ${item.label}`}
                          />
                        </div>
                      )}
                    </div>
                  ) : null
              )}
          </>
        )}
        {isStrong &&
          password === confirm_password &&
          displayName !== "" &&
          email !== "" && (
            <div className={"flex flex-col justify-center items-center my-8"}>
              <span className={`${classes["policy-notif"]} mb-8`}>
                <div className={`${classes.checkbox}`}>
                  {termsAccepted ? (
                    <TickedCheckbox onClick={checkboxHandler} />
                  ) : (
                    <Checkbox onClick={checkboxHandler} />
                  )}
                </div>
                <span className="w-full text-center">
                  You agree to the terms and conditions in the {""}
                  <span className={classes.policy} onClick={showTermsHandler}>
                    privacy notice.
                  </span>
                </span>
              </span>

              <ReCAPTCHA
                ref={recaptchaRef}
                sitekey="6Ld0jxInAAAAACy3275Ql2jd_Bb5YDqZWrSnaBdx"
                onChange={handleRecaptcha}
                size="normal"
                theme="light"
              />

              {verified && termsAccepted && (
                <Button
                  text={isPending ? "Loading..." : "Create Account"}
                  isDisabled={!termsAccepted || isPending}
                  style={buttonStyle}
                  type="submit"
                />
              )}
              {error}
            </div>
          )}
      </form>
    </PageWrapper>
  );
};

export default SignupInvite;
