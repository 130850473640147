import DashboardStatsList from "components/Organisation/DashboardSettings/DashboardStatsList";
import EmptySpace from "components/utility/EmptySpace";
import useOrganisationContext from "hooks/organisation/useOrganisationContext";
import { Statistics } from "models/dashboardStats";
import classes from "pages/AllCircles/Admin/Admin.module.css";
import { Dispatch, SetStateAction } from "react";
import { Toaster } from "react-hot-toast";

interface Props {
  setDisplayDashboardSettings: Dispatch<SetStateAction<boolean>>;
  setStatistics: Dispatch<SetStateAction<Statistics>>;
  statistics: Statistics;
}

/**
 * Component to select which dashboard stats to display for an organisation.
 *
 * @param {object} props
 * @param {Dispatch<SetStateAction<boolean>>} props.setDisplayDashboardSettings set if dashboard settings is displayed.
 * @param {Dispatch<SetStateAction<Statistics>>} props.setStatistics set the statistics to display.
 * @return DashboardSettings component.
 */
export default function DashboardSettings({
  setDisplayDashboardSettings,
  setStatistics,
  statistics,
}: Props) {
  const { selectedOrganisation } = useOrganisationContext();

  return (
    <div className="mb-[100px]">
      <Toaster toastOptions={{ duration: 5000 }} />
      <button
        className={`${classes["button"]}`}
        onClick={() => setDisplayDashboardSettings(false)}
      >
        Back to Dashboard
      </button>
      {selectedOrganisation && (
        <div className="flex items-center gap-2">
          <p className="text-xl my-4 text-slate-600">
            Dashboard settings for <strong>{selectedOrganisation.name}</strong>
          </p>
        </div>
      )}
      <p className="mb-3 justify-start items-center gap-2">
        Choose which statistics to display on the dashboard from the provided
        list.{" "}
      </p>
      <EmptySpace />
      {selectedOrganisation && (
        <DashboardStatsList
          organisation={selectedOrganisation}
          setCurrentStatistics={setStatistics}
          currentStatistics={statistics}
        />
      )}
    </div>
  );
}
