export const colours = {
  Forest: {
    background: "#FFF4B9",
    border: "#FFE871",
  },
  WaterFalls: {
    background: "#FFF4B9",
    border: "#FFE871",
  },
  ForestSnow: {
    background: "#FFF4B9",
    border: "#FFE871",
  },
  ForestSnowBlue: {
    background: "#CEF3FF",
    border: "#9AE4FB",
  },
  ForestBlue: {
    background: "#CEF3FF",
    border: "#9AE4FB",
  },
  WaterFallsBlue: {
    background: "#CEF3FF",
    border: "#9AE4FB",
  },
  ForestPurple: {
    background: "#EFE8FF",
    border: "#B096E4",
  },
  ForestSnowPurple: {
    background: "#EFE8FF",
    border: "#B096E4",
  },
  ForestRed: {
    background: "#FFD1D1",
    border: "#F69A9A",
  },
  ForestSnowRed: {
    background: "#FFD1D1",
    border: "#F69A9A",
  },
  WaterFallsGreen: {
    background: "#E0FFD6",
    border: "#D1F3C5",
  },
};
