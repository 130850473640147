import { Profile } from "interface/ProfileInterface";
import { useEffect, useState } from "react";
import { projectAuth, projectFirestore } from "../firebase/config";
import { useAuthContext } from "./useAuthContext";
import { useNavigate } from "react-router-dom";

export const useLogout = () => {
  const [isCancelled, setIsCancelled] = useState<boolean>(false);
  const [error, setError] = useState<string | null>(null);
  const [isPending, setIsPending] = useState<boolean>(false);
  const { dispatch, user, profile } = useAuthContext();

  const navigate = useNavigate();
  const logout = async () => {
    setError(null);
    setIsPending(true);

    try {
      //update online status
      //console.log(user);
      if (user) {
        const { uid } = profile as Profile;
        await projectFirestore
          .collection("users")
          .doc(uid)
          .update({ online: false, pageStep: null, growthCircle: null });
      }

      // sign the user out
      await projectAuth.signOut();
      console.info("logout");
      // dispatch logout action
      dispatch({ type: "FORCE_LOGOUT", payload: null, profile: null });

      // update state
      // if (!isCancelled) {
      setIsPending(false);
      setError(null);
      //TODO: temporary fix to clear context
      localStorage.setItem("GrowthCirclesThemeIsFacil", "false");

      navigate("/login");
      window.location.reload();
      // }
    } catch (err) {
      // if (!isCancelled) {
      let msg = "";
      if (err instanceof Error) msg = err.message;
      setError(msg);
      setIsPending(false);
      console.log(msg);
      // }
    }
  };

  useEffect(() => {
    return () => setIsCancelled(true);
  }, []);

  return { logout, error, isPending, isCancelled };
};
