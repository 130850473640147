import React, { useRef, CSSProperties } from "react";

interface AutoResizeTextAreaProps {
  onChange: (value: string) => void;
  onFocus?: () => void;
  onBlur?: () => void;
  placeholder?: string;
  className?: string;
  defaultValue?: string;
  isRequired?: boolean;
  value?: string;
  maxLength?: number;
  row?: number;
  style?: CSSProperties;
  readonly?: boolean;
}

const AutoResizeTextArea: React.FC<AutoResizeTextAreaProps> = ({
  onChange,
  onFocus,
  onBlur,
  placeholder,
  className,
  defaultValue,
  isRequired,
  value,
  maxLength,
  row,
  style,
  readonly,
}) => {
  const textRef = useRef<HTMLTextAreaElement>(null);

  const adjustTextAreaHeight = () => {
    if (textRef.current) {
      textRef.current.style.height = "auto";
      textRef.current.style.height = `${textRef.current.scrollHeight}px`;
    }
  };

  const onBlurHandler = (e: React.FocusEvent<HTMLTextAreaElement>) => {
    adjustTextAreaHeight(); // Adjust height before onBlur
    if (onBlur) {
      onBlur(); // Pass the event to the onBlur prop
    }
  };

  const onChangeHandler = (e: React.ChangeEvent<HTMLTextAreaElement>) => {
    adjustTextAreaHeight(); // Adjust height on change
    onChange(e.target.value); // Pass the value to onChange prop
  };

  return (
    <div>
      <textarea
        readOnly={readonly}
        style={style}
        rows={row}
        required={isRequired}
        ref={textRef}
        maxLength={maxLength}
        onChange={onChangeHandler}
        onFocus={onFocus} // Reset height when focused
        onBlur={onBlurHandler} // Reset height when blurred
        className={`text-area focus:ring-0 ${className}`}
        placeholder={placeholder}
        value={value}
      />
    </div>
  );
};

export default AutoResizeTextArea;
