import React from "react";

interface Props {
  fill: string;
  height?: string;
  width?: string;
}

const PerspectiveTaking: React.FC<Props> = ({ fill, height, width }) => {
  return (
    <svg
      width={width ? width : "62"}
      height={height ? height : "37"}
      viewBox="0 0 62 37"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M21.5833 6.9375C21.5833 8.77744 20.8524 10.542 19.5514 11.8431C18.2504 13.1441 16.4858 13.875 14.6458 13.875C13.7347 13.8752 12.8325 13.6959 11.9907 13.3473C11.1489 12.9988 10.3841 12.4878 9.73983 11.8435C9.09558 11.1993 8.58458 10.4344 8.23601 9.59261C7.88744 8.75082 7.70813 7.8486 7.70833 6.9375C7.70833 3.10492 10.8132 0 14.6458 0C18.4784 0 21.5833 3.10492 21.5833 6.9375ZM0 25.18C0 19.7133 9.75875 16.9583 14.6458 16.9583C19.5329 16.9583 29.2917 19.7133 29.2917 25.18V37H0V25.18ZM53.9583 6.9375C53.9583 8.77744 53.2274 10.542 51.9264 11.8431C50.6254 13.1441 48.8608 13.875 47.0208 13.875C46.1097 13.8752 45.2075 13.6959 44.3657 13.3473C43.5239 12.9988 42.7591 12.4878 42.1148 11.8435C41.4706 11.1993 40.9596 10.4344 40.611 9.59261C40.2624 8.75082 40.0831 7.8486 40.0833 6.9375C40.0833 3.10492 43.1882 0 47.0208 0C50.8534 0 53.9583 3.10492 53.9583 6.9375ZM35.4583 4.625C35.4583 5.85163 34.9711 7.02801 34.1037 7.89537C33.2363 8.76272 32.06 9.25 30.8333 9.25C29.6067 9.25 28.4303 8.76272 27.563 7.89537C26.6956 7.02801 26.2083 5.85163 26.2083 4.625C26.2083 3.39837 26.6956 2.22199 27.563 1.35463C28.4303 0.487275 29.6067 0 30.8333 0C32.06 0 33.2363 0.487275 34.1037 1.35463C34.9711 2.22199 35.4583 3.39837 35.4583 4.625ZM32.375 25.18C32.375 19.7133 42.1337 16.9583 47.0208 16.9583C51.9079 16.9583 61.6667 19.7133 61.6667 25.18V37H32.375V25.18Z"
        fill={fill}
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M38.1571 15.7019C37.7471 15.853 37.3401 16.0133 36.9423 16.1844C35.1386 16.9552 33.3148 17.9974 31.898 19.3911C31.5187 19.7657 31.158 20.1742 30.8342 20.6198C30.5136 20.182 30.1578 19.7711 29.7705 19.3911C28.3537 17.9974 26.5314 16.9552 24.7246 16.1844C24.3239 16.0132 23.9188 15.8523 23.5098 15.7019C25.8577 14.4855 28.8763 13.875 30.8342 13.875C32.7921 13.875 35.8107 14.4855 38.1571 15.7019Z"
        fill={fill}
      />
    </svg>
  );
};

export default PerspectiveTaking;
