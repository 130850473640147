import classes from "./ContentWrapper.module.css";

interface Props {
  children: React.ReactNode;
  hasFiveStepper?: boolean;
  style?: { [key: string]: string };
}

const ContentWrapper: React.FC<Props> = ({
  children,
  hasFiveStepper,
  style,
}) => {
  return (
    <div
      className={
        hasFiveStepper
          ? classes["has-stepper-container"]
          : classes["content-container"]
      }
      style={style}
    >
      {children}
    </div>
  );
};

export default ContentWrapper;
