import React from "react";
import classes from "./RoleFront.module.css";
import RoleFrontTop from "./RoleFrontTop";
import RoleFrontBottom from "./RoleFrontBottom";
import RoleInterface from "../../../../interface/RoleInterface";
import ExpandButton from "assets/Roles/ExpandButton";
import ContractButton from "assets/Roles/ContractButton";


interface Props {
  setIsFlipped: React.Dispatch<React.SetStateAction<boolean>>;
  currSelected?: RoleInterface | null;
  role: string;
  stepsToTake: string[];
  example: string[];
  description: string;
  sampleQuestion: string[];
  questionPrompts: string;
  coreSkill: string;
  isCompressed: boolean;
  setIsCompressed: (b: boolean) => any;
}

const RoleFront: React.FC<Props> = ({
  setIsFlipped,
  currSelected,
  role,
  stepsToTake,
  example,
  description,
  sampleQuestion,
  questionPrompts,
  coreSkill,
  isCompressed,
  setIsCompressed,
}) => {
  return (
    <div id="container">
    <div
      id="role-container"
      className={`${classes["role-container"]} ${
        currSelected && currSelected.role === role && classes["role-selected"]
      }
        ${isCompressed && classes["role-container-compressed"]}`}
    >
      <RoleFrontTop
        setIsFlipped={setIsFlipped}
        role={role}
        coreSkill={coreSkill}
      />
       
      <RoleFrontBottom
        stepsToTake={stepsToTake}
        example={example}
        description={description}
        sampleQuestion={sampleQuestion}
        questionPrompts={questionPrompts}
      />
      
      <div className={isCompressed ? classes["compressed"] : classes["expand"]}>
        {isCompressed 
        ? <ExpandButton setIsCompressed={setIsCompressed}/> 
        : <ContractButton setIsCompressed={setIsCompressed}/>}
      </div>
    </div>
    </div>
  );
};

export default RoleFront;
