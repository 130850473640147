import Header from "components/Header/Header";
import PageWrapper from "components/utility/PageWrapper";
import { useEffect, useRef, useState } from "react";
import { useGrowthCircleContext } from "hooks/useGrowthCircleContext";
import { getOrgByName } from "models/organisation";
import FiveStepHeader from "components/FiveStepHeader/FiveStepHeader";
import { Modal } from "flowbite-react";
import { MdCancel } from "react-icons/md";
import { useNavigate } from "react-router-dom";
import { checkCanAdvanceNextActivity } from "models/organisationRole";
import useParticipants from "hooks/useParticipants";
import { useGCSession } from "hooks/useGCSession";
import Steps from "components/utility/Steps";
import Center from "components/utility/Center/Center";
import EmptySpace from "components/utility/EmptySpace";
import ContentWrapper from "components/utility/ContentWrapper";
import { useAuthContext } from "hooks/useAuthContext";
import { updateUser } from "models/profile";
import { Component, componentMapping } from "./ComponentMapping";
import NGFooter from "components/Footers/NGFooter";
import React from "react";
import { useGrowthCircles } from "hooks/useGrowthCircles";
import { GrowthCircleSession } from "interface/GrowthCircleSessionInterface";

/**
 * GenericSessionPage allows for componentisation pages within a Growthcircle session,
 * allowing for more control within organisation.
 */
const GenericSessionPage = () => {
  const { growthCircleSession } = useGrowthCircleContext();
  const { updateFacilPageNumber } = useGCSession();
  const { getGCDetailsById } = useGrowthCircles();
  const { sessionRole } = useParticipants();
  const { profile, dispatch, user } = useAuthContext();
  const [step, setStep] = useState(1);
  const [hasModal, setHasModal] = useState(false);
  const [isHelper, setIsHelper] = useState(false);
  const canAdvance = checkCanAdvanceNextActivity(sessionRole);
  const [components, setComponents] = useState<string[]>([]);
  const firstRender = useRef(true);
  const [CurrComponent, setCurrComponent] = useState<Component>();
  const [isGC, setIsGC] = useState(false);

  const navigate = useNavigate();

  useEffect(() => {
    if (!CurrComponent) {
      return;
    }
    window.scrollTo({ top: -1, left: -1, behavior: "smooth" });
    // eslint-disable-next-line
  }, [CurrComponent]);

  const helperOnClickHandler = (b: boolean) => {
    setHasModal(b);
    setIsHelper(b);
  };

  const helperOnCloseHandler = () => {
    setHasModal(false);
    setIsHelper(false);
  };

  /**
   * Handler of the next button.
   * If there is a next handler within the component, run the handler first before changing page.
   */
  const handleNext = () => {
    if (!profile) {
      return;
    }
    let newStep = step + 1;
    if (step + 1 > components.length) {
      newStep = step;
    }
    if (
      canAdvance &&
      growthCircleSession &&
      step + 1 > growthCircleSession.facilPageNumber
    ) {
      updateFacilPageNumber(newStep);
    }

    setStep(newStep);
    updateUser(profile.uid, { pageNumber: newStep });
    setCurrComponent(componentMapping[components[step]]);
  };

  const handleBack = () => {
    if (step <= 1 || !profile) {
      return;
    }
    setStep((step) => step - 1);
    updateUser(profile.uid, { pageNumber: step - 1 });
    setCurrComponent(componentMapping[components[step - 2]]);
    navigate("/growthcircle");
  };

  useEffect(() => {
    if (growthCircleSession) {
      if (firstRender.current && growthCircleSession && profile) {
        firstRender.current = false;
        getOrgByName(growthCircleSession.organisation).then((org) => {
          if (org) {
            const defaultComponents = [
              "welcome",
              "enter-code",
              "waiting-area",
              "ground-rules",
            ];
            const newComponents = defaultComponents.concat(org.components);
            setComponents(newComponents);

            if (profile.pageNumber === undefined) {
              setStep(1);
              updateUser(profile.uid, { pageNumber: 1 });
              setCurrComponent(componentMapping[newComponents[0]]);
              return;
            }

            if (profile.pageNumber > growthCircleSession.facilPageNumber) {
              setStep(growthCircleSession.facilPageNumber);
              updateUser(profile.uid, {
                pageNumber: growthCircleSession.facilPageNumber,
              });
              setCurrComponent(
                componentMapping[
                  newComponents[growthCircleSession.facilPageNumber - 1]
                ]
              );
              return;
            } else {
              setStep(profile.pageNumber);
              setCurrComponent(
                componentMapping[newComponents[profile.pageNumber - 1]]
              );
              return;
            }
          }
        });
      }
    } else {
      if (!isGC) failSafe();
    }

    // eslint-disable-next-line
  }, [growthCircleSession, updateFacilPageNumber, firstRender, profile]);

  const failSafe = async () => {
    const id = localStorage.getItem("preGC");
    if (id && profile) {
      const growthCircleSession = (await getGCDetailsById(
        id
      )) as GrowthCircleSession;
      if (growthCircleSession) {
        setIsGC(!isGC);
        firstRender.current = false;
        dispatch({
          type: "UPDATE",
          payload: user,
          profile: { ...profile, growthCircle: growthCircleSession.uid },
        });
        getOrgByName(growthCircleSession.organisation).then((org) => {
          if (org) {
            const defaultComponents = [
              "welcome",
              "enter-code",
              "waiting-area",
              "ground-rules",
            ];
            const newComponents = defaultComponents.concat(org.components);
            setComponents(newComponents);

            if (profile.pageNumber === undefined) {
              setStep(1);
              updateUser(profile.uid, {
                pageNumber: 1,
                growthCircle: growthCircleSession.uid,
              });
              setCurrComponent(componentMapping[newComponents[0]]);
              return;
            }

            if (profile.pageNumber > growthCircleSession.facilPageNumber) {
              setStep(growthCircleSession.facilPageNumber);
              updateUser(profile.uid, {
                pageNumber: growthCircleSession.facilPageNumber,
                growthCircle: growthCircleSession.uid,
              });
              setCurrComponent(
                componentMapping[
                  newComponents[growthCircleSession.facilPageNumber - 1]
                ]
              );
              return;
            } else {
              setStep(profile.pageNumber);
              setCurrComponent(
                componentMapping[newComponents[profile.pageNumber - 1]]
              );
              return;
            }
          }
        });
      }
    }
  };

  /**
   * Call handleBack function when user uses back functionality of browser.
   */
  window.onpopstate = () => {
    handleBack();
  };

  return (
    <React.Fragment>
      {CurrComponent && (
        <>
          <Modal
            show={isHelper}
            size="md"
            className="min-h-screen"
            popup={true}
          >
            <Modal.Body className="relative mt-[15%] md:mt-[5%] rounded-3xl overflow-x-auto">
              <div className="absolute right-0 top-0 p-2">
                <MdCancel
                  size={30}
                  className="cursor-pointer"
                  onClick={helperOnCloseHandler}
                  style={{
                    color: "var(--main-colour )",
                  }}
                />
              </div>
              <div className="text-center w-full py-5">
                {CurrComponent.Helper ? (
                  <CurrComponent.Helper setHasModal={helperOnClickHandler} />
                ) : (
                  ""
                )}
              </div>
            </Modal.Body>
          </Modal>

          {CurrComponent.type === "custom" && (
            <>
              <PageWrapper>
                <Header hasModal={hasModal} />
                <FiveStepHeader
                  hasLightning={true}
                  hasGuide={true}
                  hasHelper={CurrComponent.Helper ? true : false}
                  currentStep={CurrComponent.pageStep}
                  setIsHelperVisible={helperOnClickHandler}
                  setIsTriggerVisible={setHasModal}
                  hasModal={hasModal}
                  headerString={CurrComponent.headerText}
                />
                <ContentWrapper hasFiveStepper={true}>
                  <Center className="min-h-screen">
                    <EmptySpace />
                    <Steps count={5} currentStep={CurrComponent.pageStep} />

                    <CurrComponent.Main
                      setHasModal={setHasModal}
                      backAndNextHandler={{
                        backAllowed: step > 1,
                        nextAllowed:
                          step - 1 < components.length &&
                          (canAdvance ||
                            (growthCircleSession &&
                              step < growthCircleSession?.facilPageNumber) ||
                            step === components.length),
                        nextCallback: handleNext,
                        backCallback: handleBack,
                      }}
                    />
                    <NGFooter type={growthCircleSession?.type} />
                  </Center>
                </ContentWrapper>
              </PageWrapper>
            </>
          )}
          {CurrComponent.type === "default" && (
            <>
              <PageWrapper pageType="welcome">
                <ContentWrapper hasFiveStepper={true}>
                  <Center className="min-h-screen">
                    <EmptySpace />

                    <CurrComponent.Main
                      setHasModal={setHasModal}
                      backAndNextHandler={{
                        backAllowed: step > 1,
                        nextAllowed:
                          step - 1 < components.length &&
                          (canAdvance ||
                            (growthCircleSession &&
                              step < growthCircleSession?.facilPageNumber) ||
                            step === components.length),
                        nextCallback: handleNext,
                        backCallback: handleBack,
                      }}
                    />
                  </Center>
                </ContentWrapper>
              </PageWrapper>
            </>
          )}
          {CurrComponent.type === "special" && (
            <>
              <EmptySpace />

              <CurrComponent.Main
                setHasModal={setHasModal}
                backAndNextHandler={{
                  backAllowed: step > 1,
                  nextAllowed:
                    step - 1 < components.length &&
                    (canAdvance ||
                      (growthCircleSession &&
                        step < growthCircleSession?.facilPageNumber) ||
                      step === components.length),
                  nextCallback: handleNext,
                  backCallback: handleBack,
                }}
              />
            </>
          )}
        </>
      )}
    </React.Fragment>
  );
};

export default GenericSessionPage;
