import { FaTrashAlt } from "react-icons/fa";
import { inputClass } from "strings/InputClassStrings";
import { colours } from "../components/styles/colourMap";
import { ChangeEvent, useEffect, useState } from "react";

const ChallengeInput = ({
  index,
  pageType,
  removeHandler,
  challenge,
}: {
  index: number;
  pageType: string;
  challenge: string;
  removeHandler: (index: number) => void;
}) => {
  const [answer, setAnswer] = useState<string>(challenge);
  const answerHandler = (value: string) => {
    setAnswer(value);
    const storedJournal = localStorage.getItem("SelfAwarenessJournal");
    const existingJournal = storedJournal ? JSON.parse(storedJournal) : {};
    const existingChallenges = existingJournal.challenges || [];

    // Check if the index is within bounds
    if (index >= 0 && index < existingChallenges.length) {
      // Update the existing challenges array with the new value at the specified index
      const updatedChallenges = existingChallenges.map((challenge, i) =>
        i === index ? value : challenge
      );

      // Update the existing journal with the updated challenges array
      const updatedJournal = {
        ...existingJournal,
        challenges: updatedChallenges,
      };

      // Store the updated journal in local storage
      localStorage.setItem(
        "SelfAwarenessJournal",
        JSON.stringify(updatedJournal)
      );
    } else {
      // Add a new value if the index is out of bounds
      const updatedJournal = {
        ...existingJournal,
        challenges: [...existingChallenges, value],
      };

      // Store the updated journal in local storage
      localStorage.setItem(
        "SelfAwarenessJournal",
        JSON.stringify(updatedJournal)
      );
    }
  };

  useEffect(() => {}, [challenge]);

  return (
    <div className="flex gap-2 items-center">
      <input
        onChange={(e: ChangeEvent<HTMLInputElement>) =>
          answerHandler(e.target.value)
        }
        value={answer}
        placeholder="Challenge"
        className={`${inputClass} border-2`}
        style={{ borderColor: colours[pageType]["border"] }}
      />
      <FaTrashAlt
        className="cursor-pointer"
        onClick={() => removeHandler(index)}
        size={20}
        style={{ color: "var(--icon-colour-0)" }}
      />
    </div>
  );
};

export default ChallengeInput;
