import { FC, useEffect, useState } from "react";
import { ComponentProps } from "../SelfAwarenessComponentMapping";
import BackAndNextFlat from "components/utility/BackAndNextFlat";
import GradingCard from "./GradingCard";
import { useSelfAwarenessContext } from "hooks/useSelfAwarenessContext";
import TextArea from "../SubComponents/TextArea";
import ConfirmModal from "../SubComponents/ConfirmModal";

const GradingRankingNeeds: FC<ComponentProps> = ({
  backAndNextHandler,
  setHasModal,
}) => {
  const [selectedItemGrade, setSelectedItemGrade] = useState<
    Record<string, number>
  >({});
  const [answer, setAnswer] = useState("");
  const [show, setShow] = useState<boolean>(false);
  const { journal } = useSelfAwarenessContext();

  // Load data from local storage on component mount
  useEffect(() => {
    const storedSelectedItems = localStorage.getItem("SelfAwarenessJournal");

    if (storedSelectedItems) {
      const parsedJournal = JSON.parse(storedSelectedItems);
      setSelectedItemGrade(parsedJournal.topSelectionGrading || []);
      setAnswer(parsedJournal.needs || "");
    }
  }, []);

  const answerHandler = (value: string) => {
    setAnswer(value);
    const storedJournal = localStorage.getItem("SelfAwarenessJournal");
    const existingJournal = storedJournal ? JSON.parse(storedJournal) : {};

    // Update the existing journal with the new answer
    const updatedJournal = {
      ...existingJournal,
      needs: value,
    };

    // Store the updated journal in local storage
    localStorage.setItem(
      "SelfAwarenessJournal",
      JSON.stringify(updatedJournal)
    );
  };

  const handler = () => {
    setShow(!show);
    setHasModal(!show);
  };

  useEffect(() => {
    if (!journal) return;
  }, [journal]);

  return (
    <>
      <ConfirmModal
        backAndNextHandler={backAndNextHandler}
        setHasModal={setHasModal}
        showConfirm={show}
        handler={handler}
      />
      <div className="py-8 px-4 w-full md:w-1/4">
        <div
          className="relative p-5 border-2 rounded-lg font-poppins font-semibold"
          style={{
            backgroundColor: "#FFF4B9",
            borderColor: "#FFE871",
            color: "var(--text-colour)",
          }}
        >
          <p>What do you really feel like doing now? What can help you?</p>
        </div>
        <div className="flex flex-col lg:flex-row gap-4 items-center justify-center my-4">
          {Object.keys(selectedItemGrade)
            .sort((a, b) => selectedItemGrade[b] - selectedItemGrade[a])
            .map((title, index) => (
              <GradingCard
                key={index}
                title={title}
                selectedItemGrade={selectedItemGrade}
              />
            ))}
        </div>
        {journal && (
          <TextArea
            maxLength={500}
            answer={answer}
            journal={journal}
            answerHandler={answerHandler}
          />
        )}

        <div className="my-8">
          <BackAndNextFlat
            {...backAndNextHandler}
            nextCallback={() => {
              setShow(!show);
              setHasModal(!show);
            }}
            nextAllowed={answer.length > 0 ? true : false}
          />
        </div>
      </div>
    </>
  );
};

export default GradingRankingNeeds;
