import TimerDisplay from "./TimerDisplay";
import TimerButton from "./TimerButton";
import EmptySpace from "components/utility/EmptySpace";
import { useState } from "react";

const Timer = () => {
  const [timerState, setTimerState] = useState<"start" | "reset" | "stop">(
    "stop"
  );
  return (
    <div className="bg-white shadow-lg p-5 rounded-3xl">
      {" "}
      <TimerDisplay timerState={timerState} setTimerState={setTimerState} />
      <EmptySpace />
      <TimerButton setTimerState={setTimerState} />
    </div>
  );
};

export default Timer;
