import { QueryConstraint } from "firebase/firestore";
import { timestamp } from "../firebase/config";
import getModelOperations from "utility/model";
import PublicQuestion from "interface/PublicQuestionInterface";

const FIRESTORE_PATH_PUBLIC_QUESTIONS = "PublicQuestions";

export const defaultPublicQuestion: PublicQuestion = {
  id: "",
  createdAt: timestamp.fromDate(new Date()),
  organisationFromId: "",
  question: "",
};

// --- DB Helper Functions ---

const ops = getModelOperations(defaultPublicQuestion);

function _setPublicQuestion(
  publicQuestionId: string,
  publicQuestion: PublicQuestion
) {
  const path = `${FIRESTORE_PATH_PUBLIC_QUESTIONS}/${publicQuestionId}`;
  return ops.setModel(path, publicQuestion);
}

function _deletePublicQuestion(publicQuestionId: string) {
  const path = `${FIRESTORE_PATH_PUBLIC_QUESTIONS}/${publicQuestionId}`;
  return ops.deleteModel(path);
}

async function _getPublicQuestions(...queryConstraints: QueryConstraint[]) {
  const path = `${FIRESTORE_PATH_PUBLIC_QUESTIONS}`;
  const result = await ops.getModels(path, ...queryConstraints);
  return result;
}

// --- End DB Helper Functions ---

export function addPublicQuestion(newPublicQuestion: PublicQuestion) {
  return _setPublicQuestion(newPublicQuestion.id, newPublicQuestion);
}

export const getPublicQuestions = _getPublicQuestions;

export const deletePublicQuestion = _deletePublicQuestion;
