import useOrganisationGroup from "hooks/organisation/useOrganisationGroup";
import { Group } from "interface/GroupInterface";
import { useState, useEffect } from "react";

const AsyncGroup = ({ userId }) => {
  const [group, setGroup] = useState<Group | undefined>();
  const { getGroupByUserId } = useOrganisationGroup("35FWaZ85asr89ngceLJ2");

  useEffect(() => {
    getGroupByUserId(userId).then((group) => {
      return setGroup(group);
    });
    // eslint-disable-next-line
  }, [userId]);

  if (group !== null) {
    if (group) {
      return <span>{group.subGroupName}</span>;
    } else {
      return <span>No group found</span>;
    }
  }

  // You can return a loading indicator here if needed
  return null;
};

export default AsyncGroup;
