import { Modal } from "flowbite-react";
import { FeedbackLayout } from "interface/FeedbackLayoutInterface";
import { useEffect, useState } from "react";
import { AiFillCloseCircle } from "react-icons/ai";
import { FaSortAmountDown } from "react-icons/fa";
import classes from "pages/AllCircles/Admin/Admin.module.css";
import usePromise from "hooks/utility/usePromise";
import FeedbackQuestionModalSorting from "./FeedbackQuestionModalSorting";
import { useOrgFeedbackQuestions } from "hooks/organisation/useOrgFeedbackQuestions";
import toast from "react-hot-toast";

interface FeedbackQuestionSortingProps {
  questions: FeedbackLayout;
}
const FeedbackQuestionSorting = ({
  questions,
}: FeedbackQuestionSortingProps) => {
  const [isSorting, setIsSorting] = useState(false);
  const { isLoading, resolve } = usePromise();
  const { saveReOrdering } = useOrgFeedbackQuestions();
  const [selectedIds, setSelectedIds] = useState<string[]>([]);

  const isSortHandler = () => {
    setSelectedIds([]);
    if (!questions.subCategories) return;
    const initialSelectedIds = questions.subCategories.map(
      (sections) => sections.uid!
    );
    setSelectedIds(initialSelectedIds);

    setIsSorting(!isSorting);
  };

  const saveHandler = async () => {
    if (!questions.subCategories) return;
    const reorderedQuestions = [...questions.subCategories].sort(
      (a, b) => selectedIds.indexOf(a.uid!) - selectedIds.indexOf(b.uid!)
    );
    const newObj: FeedbackLayout = {
      ...questions,
      subCategories: reorderedQuestions,
    };

    resolve(() => saveReOrdering(questions.id, newObj)).then(() => {
      toast.success("New order saved successfully!");
      setIsSorting(!isSorting);
    });
  };

  useEffect(() => {
    if (!questions.subCategories) return;
    const initialSelectedIds = questions.subCategories.map(
      (sections) => sections.uid!
    );

    setSelectedIds(initialSelectedIds);
    // eslint-disable-next-line
  }, []);

  useEffect(() => {
    // eslint-disable-next-line
  }, [selectedIds]);

  return (
    <>
      <Modal show={isSorting} size="3xl" popup onClose={isSortHandler}>
        <Modal.Body
          className={`relative ${classes["container"]} flex justify-center`}
        >
          {!isLoading && (
            <AiFillCloseCircle
              size={30}
              color={`var(--icon-colour-0)`}
              className="absolute top-2 right-2 cursor-pointer"
              onClick={() => setIsSorting(!isSorting)}
            />
          )}
          <div className="text-center flex justify-center flex-col items-center mt-8">
            {questions &&
              questions.subCategories !== undefined &&
              questions.subCategories.length > 0 && (
                <FeedbackQuestionModalSorting
                  order={selectedIds}
                  setOrder={setSelectedIds}
                  questions={questions.subCategories || []}
                />
              )}
            {selectedIds.length === questions.subCategories.length && (
              <button
                disabled={isLoading}
                className={`${classes["button"]} my-5`}
                onClick={saveHandler}
              >
                {isLoading ? "Saving..." : " Save Order"}
              </button>
            )}
          </div>
        </Modal.Body>
      </Modal>
      <div className="relative w-full">
        <FaSortAmountDown
          className="absolute top-5 left-5 cursor-pointer"
          onClick={() => {
            setIsSorting(!isSorting);
          }}
          size={30}
          color={`var(--main-colour)`}
        />
      </div>
    </>
  );
};

export default FeedbackQuestionSorting;
