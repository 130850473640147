import BackAndNext from "components/utility/BackAndNext";
import EmptySpace from "components/utility/EmptySpace";
import React, { useEffect, useState, lazy, Suspense } from "react";
import { Modal as FlowBiteModal } from "flowbite-react";
import { AiFillCloseCircle } from "react-icons/ai";
import useParticipants from "hooks/useParticipants";
import { checkCanUseTimer } from "models/organisationRole";
import { ComponentProps } from "../GenericSessionPage/ComponentMapping";

const ReflectShareSkills = lazy(
  () => import("components/SharingPanel/ReflectShareSkills")
);

const Timer = lazy(() => import("components/SharingPanel/Timer/Timer"));

const SharingPanelComponent = ({
  backAndNextHandler,
  setHasModal,
}: ComponentProps) => {
  const { sessionRole } = useParticipants();
  const [showConfirmNext, setShowConfirmNext] = useState(false);

  useEffect(() => {
    if (showConfirmNext) {
      document.body.style.overflow = "hidden";
    } else {
      document.body.style.overflow = "unset";
    }
  }, [showConfirmNext]);

  const nextHandler = () => {
    setHasModal(true);
    setShowConfirmNext(true);
  };

  const confirmHandler = (
    e: React.MouseEvent<HTMLButtonElement, MouseEvent>
  ) => {
    setShowConfirmNext(false);
    setHasModal(false);
    backAndNextHandler.nextCallback(e);
  };

  const modalHandler = () => {
    setHasModal(!showConfirmNext);
    setShowConfirmNext(!showConfirmNext);
  };

  return (
    <React.Fragment>
      <React.Fragment>
        <FlowBiteModal
          show={showConfirmNext}
          size="md"
          className="min-h-screen"
          popup={true}
        >
          <FlowBiteModal.Body className="p-5 relative mt-[5%]">
            <div className="absolute right-0 top-0 p-2">
              <AiFillCloseCircle
                size={30}
                color={`var(--main-colour)`}
                className="cursor-pointer"
                onClick={modalHandler}
              />
            </div>
            <div className="text-center py-5 px-2">
              <h3 className="font-semibold mt-5 text-lg text-slate-700">
                Before Proceeding
              </h3>
              <EmptySpace height={"20px"} />
              <div className="text-slate-700 text-center">
                {sessionRole?.name === "host" && (
                  <p className="text-sm">
                    Have all the participants finished sharing?
                  </p>
                )}
                {sessionRole?.name !== "host" && (
                  <p className="text-sm">Are you finished sharing?</p>
                )}
                <EmptySpace height={"20px"} />
                <div className="flex justify-center gap-4 mt-5">
                  <button
                    type="button"
                    style={{ backgroundColor: "var(--main-colour)" }}
                    className={`shadow-md  px-12 rounded-3xl text-white py-3 hover:bg-green-700 transition duration-1000`}
                    onClick={confirmHandler}
                  >
                    <span>{"Yes"}</span>
                  </button>
                  <button
                    type="button"
                    style={{ backgroundColor: "var(--main-colour)" }}
                    className={`shadow-md px-12 rounded-3xl text-white py-3 hover:bg-green-700 transition duration-1000`}
                    onClick={modalHandler}
                  >
                    No
                  </button>
                </div>
              </div>
            </div>
          </FlowBiteModal.Body>
        </FlowBiteModal>
      </React.Fragment>

      <EmptySpace height={"30px"} />
      <Suspense fallback={<div>Loading...</div>}>
        <ReflectShareSkills setHasModal={setHasModal} />
      </Suspense>
      <EmptySpace height={"40px"} />
      <BackAndNext {...backAndNextHandler} nextCallback={nextHandler} />
      <EmptySpace height={"40px"} />
      {checkCanUseTimer(sessionRole) && (
        <>
          <Suspense fallback={<div>Loading...</div>}>
            <Timer />
          </Suspense>
          <EmptySpace height={"50px"} />
        </>
      )}
    </React.Fragment>
  );
};

export default SharingPanelComponent;
