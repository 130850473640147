import SimpleLoader from "components/Loaders/SimpleLoader";
import { DocumentData } from "firebase/firestore";
import QuestionDataCard from "./QuestionDataCard";

interface IntentionsAndTakewaysProps {
  intentions: String[] | undefined;
  takeawaysEntries: String[] | undefined;
  actionStepsEntries: String[] | undefined;
  participantHistory: DocumentData[];
  isLoading: boolean;
}

const IntentionsAndTakeways = ({
  intentions,
  takeawaysEntries,
  actionStepsEntries,
  participantHistory,
  isLoading,
}: IntentionsAndTakewaysProps) => {
  if (isLoading) {
    return <SimpleLoader center={true} />;
  }

  const showIntentions =
    intentions && intentions?.length > 0 && participantHistory.length > 0;
  const showTakeaways =
    takeawaysEntries &&
    takeawaysEntries?.length > 0 &&
    participantHistory.length > 0;
  const showActionSteps =
    actionStepsEntries &&
    actionStepsEntries?.length > 0 &&
    participantHistory.length > 0;

  return (
    <>
      {(showIntentions || showTakeaways || showActionSteps) && (
        <div className="m-7 flex flex-col gap-7">
          {showIntentions && (
            <QuestionDataCard
              entries={intentions}
              title={"What do you want to takeaway from the mentoring session?"}
            />
          )}
          {showTakeaways && (
            <QuestionDataCard
              entries={takeawaysEntries}
              title={"What is your key takeaway from the mentoring session?"}
            />
          )}
          {showActionSteps && (
            <QuestionDataCard
              entries={actionStepsEntries}
              title={"What is one action you will be taking next?"}
            />
          )}
        </div>
      )}
      {!showIntentions && !showTakeaways && !showActionSteps && (
        <div className="flex justify-center items-center flex-grow">
          <h3 className="text-xl font-semibold">No data available.</h3>
        </div>
      )}
    </>
  );
};

export default IntentionsAndTakeways;
