import { ReactComponent as ExclaimationMark } from "assets/ExclamationMark.svg";
import classes from "./SliderContainer.module.css";
import Slider from "./Slider";

const SliderContainer = (props) => {
  //values,setValues,icon to be parsed to slider
  //name and description are the information about the slider
  const { values, setValues, name, description, icon, showError } = props;

  return (
    <div className={classes.box}>
      <div className={classes.header}>{name}</div>
      <div className={classes["header-description"]}>{description}</div>
      <Slider values={values} setValues={setValues} icon={icon} />
      {showError && (
        <div className={classes["errorMessage"]}>
          <ExclaimationMark />
          <span>Please indicate your rating for this section.</span>
        </div>
      )}
    </div>
  );
};

export default SliderContainer;
