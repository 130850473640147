import { ChangeEventHandler, FC, useRef } from "react";
import { ReactComponent as UploadImageIcon } from "assets/NG/upload-image.svg";
import classes from "./InputField.module.css";

interface Props {
  onChange: ChangeEventHandler<HTMLInputElement>;
  accept?: string;
  customIcon?: JSX.Element;
}

/**
 * File selector to upload files.
 *
 * @param onChange Function to handle chosen file.
 * @param accept File types to accept.
 * @param customIcon Custom Icon to trigger file picker.
 *    Icon must not stop click event propagation.
 * @returns FileField component.
 */
const FileField: FC<Props> = ({ onChange, accept, customIcon = <UploadImageIcon /> }) => {
  const inputRef = useRef<HTMLInputElement>(null);
  
  return (
    <div
      onClick={() => inputRef.current?.click()}
      className={classes.file}
    >
      { customIcon }
      <input
        type="file"
        accept={accept}
        onChange={onChange}
        className="hidden"
        ref={inputRef}
      />
    </div>
    
  );
}

export default FileField;