import { Tabs } from "flowbite-react";
import { ReactNode } from "react";

interface Props {
  title: string;
  children?: ReactNode;
  disabled?: boolean;
}

export default function TabItem({ title, children, disabled }: Props) {
  return (
    <Tabs.Item title={title} disabled={disabled}>
      {!disabled && children}
    </Tabs.Item>
  );
}
