import Header from "components/Header/Header";
import Steps from "components/utility/Steps";
import FiveStepHeader from "components/FiveStepHeader/FiveStepHeader";
import BackAndNext from "components/utility/BackAndNext";
import { useNavigate } from "react-router-dom";
import EmptySpace from "components/utility/EmptySpace";
import { useAuthContext } from "hooks/useAuthContext";
import { useGCSession } from "hooks/useGCSession";
import { useGrowthCircleContext } from "hooks/useGrowthCircleContext";
import Center from "components/utility/Center/Center";
import NoGCSessionWrapper from "pages/NoGCSession/NoGCSessionWrapper";
import ContentWrapper from "components/utility/ContentWrapper";
import { CHECK_IN_AFTER_ROUTE, CHOOSE_PATHWAY_ROUTE } from "strings/Routes"; //

import React, { useEffect, useState, lazy, Suspense } from "react";

import { Modal as FlowBiteModal } from "flowbite-react";

// import ConfirmNext from "components/SharingPanel/ConfirmNext/ConfirmNext";
import { useFirestore } from "hooks/useFirestore";
import { AiFillCloseCircle } from "react-icons/ai";
import NGFooter from "components/Footers/NGFooter";
import useParticipants from "hooks/useParticipants";
import {
  checkCanAdvanceNextActivity,
  checkCanUseTimer,
} from "models/organisationRole";

const SharingPanelHelper = lazy(
  () => import("components/SharingPanelHelper/SharingPanelHelper")
);

const ReflectShareSkills = lazy(
  () => import("components/SharingPanel/ReflectShareSkills")
);

const Timer = lazy(() => import("components/SharingPanel/Timer/Timer"));

const SharingPanel = () => {
  const { profile } = useAuthContext();
  const { growthCircleSession } = useGrowthCircleContext();
  const { sessionRole } = useParticipants();
  const [isHelperShow, setHelperShow] = useState(false);
  const [showConfirmNext, setShowConfirmNext] = useState(false);
  const { updateDocument } = useFirestore("users");
  const [hasTrigger, setHasTrigger] = useState(false);
  const canAdvance = checkCanAdvanceNextActivity(sessionRole);

  const _setTrigger = () => {
    setHasTrigger(!hasTrigger);
  };

  useEffect(() => {
    if (!profile?.uid) {
      return;
    }
    const data = { pageStep: window.location.pathname };
    updateDocument(profile?.uid, data);
  }, [profile?.uid, updateDocument]);

  useEffect(() => {
    if (isHelperShow || showConfirmNext) {
      document.body.style.overflow = "hidden";
    } else {
      document.body.style.overflow = "unset";
    }
  }, [isHelperShow, showConfirmNext]);

  const currentStep = 4;
  const stepsCount = 5;

  let navigate = useNavigate();

  const _setVisible = () => {
    setHelperShow(true);
  };

  const { facilAllowNextPage } = useGCSession();
  const nextHandler = () => {
    setShowConfirmNext(true);
  };

  const confirmHandler = () => {
    setShowConfirmNext(false);
    if (canAdvance) {
      facilAllowNextPage("end-rating");
    }
    navigate(CHECK_IN_AFTER_ROUTE);
  };

  const backHandler = () => {
    navigate(CHOOSE_PATHWAY_ROUTE);
  };

  const modalHandler = () => {
    setShowConfirmNext(!showConfirmNext);
  };

  const setHelperShowHandler = () => {
    setHelperShow(!isHelperShow);
  };

  return (
    <NoGCSessionWrapper>
      <div className="relative">
        <Header hasModal={isHelperShow || showConfirmNext || hasTrigger} />
        <FiveStepHeader
          hasModal={isHelperShow || showConfirmNext || hasTrigger}
          hasLightning={true}
          hasGuide={false}
          hasHelper={true}
          setIsHelperVisible={_setVisible}
          setIsTriggerVisible={_setTrigger}
          currentStep={currentStep}
        />

        <ContentWrapper hasFiveStepper={true}>
          {/* <SharingPanelHelper trigger={setHelperShow}/> */}

          <React.Fragment>
            <FlowBiteModal
              show={showConfirmNext}
              size="md"
              className="min-h-screen"
              popup={true}
            >
              <FlowBiteModal.Body className="p-5 relative">
                <div className="absolute right-0 top-0 p-2">
                  <AiFillCloseCircle
                    size={30}
                    color={`var(--main-colour)`}
                    className="cursor-pointer"
                    onClick={modalHandler}
                  />
                </div>
                <div className="text-center py-5 px-2">
                  <h3 className="font-semibold mt-5 text-lg text-slate-700">
                    Before Proceeding
                  </h3>
                  <EmptySpace height={"20px"} />
                  <div className="text-slate-700 text-center">
                    {profile?.facilGC && (
                      <p className="text-sm">
                        Have all the participants finished sharing?
                      </p>
                    )}
                    {!profile?.facilGC && (
                      <p className="text-sm">Are you finished sharing?</p>
                    )}
                    <EmptySpace height={"20px"} />
                    <div className="flex justify-center gap-4 mt-5">
                      <button
                        type="button"
                        style={{ backgroundColor: "var(--main-colour)" }}
                        className={` shadow-md  px-12 rounded-3xl text-white py-3`}
                        onClick={confirmHandler}
                      >
                        <span>{"Yes"}</span>
                      </button>
                      <button
                        type="button"
                        style={{ backgroundColor: "var(--main-colour)" }}
                        className={`$ shadow-md px-12 rounded-3xl text-white py-3`}
                        onClick={modalHandler}
                      >
                        No
                      </button>
                    </div>
                  </div>
                </div>
              </FlowBiteModal.Body>
            </FlowBiteModal>
          </React.Fragment>

          <React.Fragment>
            <FlowBiteModal
              show={isHelperShow}
              size="md"
              className="min-h-screen"
              popup={true}
            >
              <FlowBiteModal.Body className="p-5 relative mt-[15%] md:mt-[3%]">
                <div className="absolute right-0 top-0 p-2">
                  <AiFillCloseCircle
                    size={30}
                    color={`var(--main-colour)`}
                    className="cursor-pointer"
                    onClick={setHelperShowHandler}
                  />
                </div>
                <div className="text-center py-5 px-2">
                  <Suspense fallback={<div>Loading...</div>}>
                    <SharingPanelHelper setHasModal={setHelperShow} />
                  </Suspense>
                </div>
              </FlowBiteModal.Body>
            </FlowBiteModal>
          </React.Fragment>

          <Center>
            <EmptySpace height={"1rem"} />
            <Steps count={stepsCount} currentStep={currentStep} />
            <EmptySpace height={"30px"} />
            <Suspense fallback={<div>Loading...</div>}>
              <ReflectShareSkills />
            </Suspense>
            <EmptySpace height={"40px"} />
            <BackAndNext
              backAllowed={true}
              backCallback={backHandler}
              nextAllowed={
                canAdvance || growthCircleSession?.allowEndRating || false
              }
              nextCallback={nextHandler}
              backHidden={undefined}
            />
            <EmptySpace height={"40px"} />
            {checkCanUseTimer(sessionRole) && (
              <>
                <Suspense fallback={<div>Loading...</div>}>
                  <Timer />
                </Suspense>
                <EmptySpace height={"50px"} />
              </>
            )}
          </Center>
        </ContentWrapper>
        <NGFooter type={growthCircleSession?.type} />
      </div>
    </NoGCSessionWrapper>
  );
};

export default SharingPanel;
