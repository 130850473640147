import { Group } from "interface/GroupInterface";
import { ChangeEvent, useCallback, useState } from "react";
import { WithId } from "utility/model";

export default function useGroupSelectHandler(
  groups: WithId<Group>[],
  selectGroupByGroupName: (groupName: string, subGroupName: string) => void
) {
  const [groupName, setGroupName] = useState("");

  const groupNames = Array.from(new Set(groups.map((g) => g.groupName)));
  const subGroupNames = Array.from(
    new Set(
      groups.filter((g) => g.groupName === groupName).map((g) => g.subGroupName)
    )
  );

  const handleGroupChange = useCallback(
    (e: ChangeEvent<HTMLSelectElement>) => {
      const newGroupName = e.target.value;
      setGroupName(newGroupName);
      selectGroupByGroupName(
        newGroupName,
        groups.find((g) => g.groupName === newGroupName)?.subGroupName ?? ""
      );
    },
    [groups, selectGroupByGroupName]
  );

  const handleSubGroupChange = useCallback(
    (e: ChangeEvent<HTMLSelectElement>) => {
      selectGroupByGroupName(groupName, e.target.value);
    },
    [groupName, selectGroupByGroupName]
  );

  return {
    groupNames,
    subGroupNames,
    handleGroupChange,
    handleSubGroupChange,
  };
}
