import firebase from "firebase/compat/app";
import { CheckInCheckOut } from "./ParticipantInterface";

interface Smiley {
  question: string;
  answer: number;
}

interface OpenQuestionAnswer {
  question: string;
  answer: string;
}

export interface SmileyRatings {
  [questionNumber: string]: Smiley;
}

export interface OpenEndedFields {
  [fieldKey: string]: OpenQuestionAnswer;
}

export enum BadgeType {
  EFFORT = "Effort",
  CARE_AND_SUPPORT = "CareAndSupport",
  ROLE = "Role",
}

export type BadgeStates = {
  [badgeType in BadgeType]: number;
};

/**
 * Checks object for whether it is equal to BadgeCount
 * @param x: any
 */
export const isOfTypeBadgeCount = (x: any): x is BadgeStates => {
  return (
    !!x[BadgeType.EFFORT] &&
    !!x[BadgeType.CARE_AND_SUPPORT] &&
    !!x[BadgeType.ROLE]
  );
};

export const isFeedbackForm = (x: any): x is FeedbackForm => {
  const isFeedback: boolean =
    x.id !== undefined &&
    x.createdAt !== undefined &&
    ((x.refGcSmileyRatings !== undefined &&
      x.refPersonalSmileyRatings !== undefined &&
      x.refOpenEndedFields !== undefined) ||
      x.refBadgeRatings !== undefined);
  // if (!isFeedback) {
  //   throw new IsNotFeedBackFormError(JSON.stringify(x));
  // }
  if (!isFeedback) {
    console.log(x);
  }
  return isFeedback;
};

export interface FeedbackForm {
  id: string;
  createdAt: firebase.firestore.Timestamp;
  userId: string;
  userName: string;
  growthCircle: string;
  refPersonalDragValues?: CheckInCheckOut;
  refGcSmileyRatings?: SmileyRatings;
  refPersonalSmileyRatings?: SmileyRatings;
  refOpenEndedFields?: SmileyRatings;
  refBadgeRatings?: BadgeStates;
  savedToProgress?: boolean;
}
