import Header from "components/Header/Header";
import SimpleLoader from "components/Loaders/SimpleLoader";

import {
  SelfAwarenessComponentMapping,
  Component,
} from "components/SelfAwareness/SelfAwarenessComponentMapping";
import { SelfAwarenessImageMap } from "components/SelfAwareness/SelfAwarenessImageMap";
import ConfirmExitModal from "components/SelfAwareness/SubComponents/ConfirmExitModal";
import { SelfAwarenessContextProvider } from "context/SelfAwarenessContext";
import { useAuthContext } from "hooks/useAuthContext";

import { useSelfAwareness } from "hooks/useSelfAwareness";

import { SelfAwarenessToolInterface } from "interface/SelfAwarenessToolInterface";

import { Suspense, lazy, useEffect, useState } from "react";
import { Toaster } from "react-hot-toast";
import { MdArrowLeft } from "react-icons/md";
import { useParams } from "react-router-dom";

const PageWrapperSelfAwareness = lazy(
  () => import("components/SelfAwareness/PageWrapperSelfAwareness")
);

const SelfDiscoverySinglePage = () => {
  const { id } = useParams();
  const { getSelfAwarenessTool } = useSelfAwareness();
  const [tool, setTool] = useState<SelfAwarenessToolInterface>();
  const [hasModal, setHasModal] = useState(false);
  const [currentIndex, setCurrentIndex] = useState<number>(0);
  const [CurrComponent, setCurrComponent] = useState<Component>();
  const [components, setComponents] = useState<string[]>([]);
  const { profile } = useAuthContext();
  const [showExit, setShowExit] = useState(false);

  const getData = async () => {
    try {
      if (id) {
        const data = await getSelfAwarenessTool(id);

        if (data) {
          setTool(data);
          setComponents(data.components);
          setCurrComponent(SelfAwarenessComponentMapping[data.components[0]]);
        } else {
          // Handle error or set a default value
          console.log("data is undefined");
        }
      }
    } catch (error) {
      console.error(error);
    }
  };

  const helperOnClickHandler = (b: boolean) => {
    setHasModal(b);
  };

  const getComponent = async () => {
    setCurrComponent(SelfAwarenessComponentMapping[components[currentIndex]]);
  };

  const handleNext = () => {
    setCurrentIndex((prevIndex) => prevIndex + 1);
    setCurrComponent(
      SelfAwarenessComponentMapping[components[currentIndex + 1]]
    );
  };

  const handleBack = () => {
    if (currentIndex > 0) {
      setCurrentIndex((prevIndex) => prevIndex - 1);
      setCurrComponent(
        SelfAwarenessComponentMapping[components[currentIndex - 1]]
      );
    }
  };

  useEffect(() => {
    localStorage.removeItem("SelfAwarenessJournal");
  }, []);

  useEffect(() => {
    if (!id || !profile) return;
    getData();
    getComponent();

    const storedJournal = localStorage.getItem("SelfAwarenessJournal");
    const existingJournal = storedJournal ? JSON.parse(storedJournal) : {};

    // Update the existing journal with the new answer
    const updatedJournal = {
      ...existingJournal,
      journalId: id,
      userId: profile.uid,
    };

    // Store the updated journal in local storage
    localStorage.setItem(
      "SelfAwarenessJournal",
      JSON.stringify(updatedJournal)
    );

    // eslint-disable-next-line
  }, [id, profile]);

  const handler = () => {
    setShowExit(!showExit);
    setHasModal(!showExit);
  };

  return (
    <>
      <ConfirmExitModal
        handler={handler}
        showExit={showExit}
        setHasModal={setHasModal}
      />
      <Header title="Self-Discovery" hasModal={hasModal} />
      <Toaster toastOptions={{ duration: 3000 }} position="bottom-center" />
      {id && (
        <SelfAwarenessContextProvider id={id}>
          <Suspense fallback="loading...">
            <PageWrapperSelfAwareness>
              {tool ? (
                <>
                  <div
                    className={`z-1 w-full mt-[20%] md:mt-[5%] flex flex-col justify-between items-center `}
                  >
                    <div
                      style={{ color: "#EB8181" }}
                      className="flex w-full md:w-3/12 items-center text-2xl font-semibold justify-center text-center my-4"
                    >
                      <p className="text-center">"{tool?.title}" Journal</p>
                    </div>
                    <div className="w-full md:w-3/12 relative flex justify-center items-center">
                      <MdArrowLeft
                        style={{ color: "#EB8181" }}
                        size={80}
                        onClick={handler}
                        className="absolute top-5 left-7 cursor-pointer self-start"
                      />

                      <img
                        width={"150px"}
                        height={"150px"}
                        src={SelfAwarenessImageMap[tool.source]}
                        alt={tool?.alt}
                        className="rounded-lg"
                      />
                    </div>
                  </div>

                  <div className="mb-[250px] relative z-10 w-full flex justify-center">
                    {CurrComponent && (
                      <CurrComponent.Main
                        setHasModal={helperOnClickHandler}
                        backAndNextHandler={{
                          backAllowed: currentIndex > 0,
                          nextAllowed: currentIndex < components.length - 1,
                          nextCallback: handleNext,
                          backCallback: handleBack,
                        }}
                      />
                    )}
                    {!CurrComponent && (
                      <div
                        className="flex min-h-[300px] my-4 justify-center items-center font-semibold font-poppins"
                        style={{ color: "var(--text-colour)" }}
                      >
                        <p>No Components are set</p>
                      </div>
                    )}
                  </div>
                </>
              ) : (
                <SimpleLoader />
              )}
            </PageWrapperSelfAwareness>
          </Suspense>
        </SelfAwarenessContextProvider>
      )}
    </>
  );
};

export default SelfDiscoverySinglePage;
