import { projectFirestore } from "../firebase/config";

type Fields = {
  [key: string]: any;
};

/**
 *
 * Contains CUD methods on the database
 */
const useMassCRUD = () => {
  /**
   * Mass updates documents within a collection by adding new fields using Firestore's batch write
   * projectFirestore = db (in documentation)
   * @param path - path of the collection (collectionId/docId/subcollectionId/docId2/...)
   * @param fields - object of key-value fields to be updated
   */
  const massUpdate = async (path: string, fields: Fields) => {
    //Firestore's collection method takes in a path(collectionId/docId/subCollectionId/...)
    const collectionRef = projectFirestore.collection(path);

    collectionRef.get().then(
      //snapShot is the array of documents within this collection
      async (snapShot) => {
        const batch = projectFirestore.batch();

        snapShot.forEach((doc) => {
          const docRef = doc.ref;

          //Update with the fields provided
          batch.update(docRef, fields);
        });

        //Commit the batch
        await batch.commit();
      }
    );
  };

  const updateGCFacilOwner = async (uid: string, new_uid: string) => {
    const ref = projectFirestore
      .collection("GrowthCircles")
      .where("facilOwner", "==", uid);
    ref.get().then(function (querySnapshot) {
      if (querySnapshot.docs.length > 0) {
        querySnapshot.docs.forEach((doc) => {
          const newData = { ...doc.data(), facilOwner: new_uid };
          // Update the document with the new userId
          projectFirestore
            .collection("GrowthCircles")
            .doc(doc.id)
            .update(newData);
        });
      } else {
        console.info("No GrowthCircles to sync");
      }
    });
  };

  return {
    massUpdate,
    updateGCFacilOwner,
  };
};

export default useMassCRUD;
