export const HOME_PAGE = "/";
export const GROUND_RULES_ROUTE = "/all-circles/ground-rules";
export const INFO_PANEL_ROUTE = "/all-circles/info-panel/id";
export const ENTER_CODE_ROUTE = "/all-circles/enter-code";
export const WAITING_AREA_ROUTE = "/all-circles/waiting-area";
export const DICE_ROUTE = "/all-circles/dice";
export const INTRO_RATING_ROUTE = "/all-circles/intro-rating";
export const RATING_AVERAGE_ROUTE = "/all-circles/rating-average";
export const SETTING_INTENTIONS_ROUTE = "/all-circles/setting-intentions";
export const CHOOSE_ROLES_ROUTE = "/all-circles/choose-roles";
export const VIEW_CHOOSE_ROLES_ROUTE = "/all-circles/view-choose-roles";
export const CHOOSE_TOPIC_ROUTE = "/all-circles/choose-topic";
export const VIEW_CHOOSE_TOPIC_ROUTE = "/all-circles/view-choose-topic";
export const CHOOSE_PATHWAY_ROUTE = "/all-circles/choose-pathway";
export const SHARING_PANEL_ROUTE = "/all-circles/sharing-panel";
export const QUESTION_FOR_TOPICS_ROUTE = "/all-circles/questions-for-topics";
export const END_RATING_ROUTE = "/all-circles/end-rating";
export const END_RATING_AVERAGE_ROUTE = "/all-circles/end-rating-average";
export const REFLECTION_RATING_ROUTE = "/all-circles/reflection-rating";
export const FEEDBACK_FORM_ROUTE = "/all-circles/feedback-form";
export const END_OF_SESSION_ROUTE = "/all-circles/end-of-session";

// routes for NG
export const NG_CHOOSE_ACTIVITY = "/all-circles/choose-activity";
export const NG_ACTIVITY_ROUTE = "/all-circles/activity";
export const NG_REFLECTION_ACTIVITY_ROUTE =
  "/all-circles/activity-share-reflection";

// routes for SUSS
export const SUSS_CHOOSE_ACTIVITY = "/all-circles/choose-activity-suss";

// new routes global
export const FEEDBACK_ROUTE_SELF = "/all-circles/check-out-feedback-for-self";
export const FEEDBACK_ROUTE_FACIL = "/all-circles/check-out-feedback-for-facil";
export const FEEDBACK_ROUTE_GROUP = "/all-circles/check-out-feedback-for-group";
export const WAITING_AREA_FINAL = "/all-circles/waiting-area-final";
export const COMPLETED_ROUTE = "/all-circles/completed";
export const FINISHED_ROUTE = "/all-circles/finished";
export const CHECK_IN_AFTER_ROUTE = "/all-circles/check-in-after";
export const CHECK_IN_CHECK_OUT_AVERAGE =
  "/all-circles/check-in-check-out-average";
