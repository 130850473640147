import SimpleLoader from "components/Loaders/SimpleLoader";
import { useEffect, useState } from "react";
import {
  Bar,
  BarChart,
  CartesianGrid,
  Cell,
  ResponsiveContainer,
  Tooltip,
  XAxis,
  YAxis,
} from "recharts";

interface Props {
  isLoading: boolean;
  averages: Record<number, Record<string, number>>;
}

/**
 * Organisation stack bar component for level of clarity in 4 areas.
 * (individually, socially, interpersonally, overall)
 *
 * @param {object} props The props of the component.
 * @param {boolean} props.isLoading The loading state of the component.
 * @param {Record<number, Record<string, number>>} props.averages The averages of the feedback.
 * @returns {JSX.Element} The organisation stack bar component.
 */
const QuantitativeFeedbackCard = ({ isLoading, averages }: Props) => {
  const [processedAverages, setProcessedAverages] = useState<
    Array<Array<string | Array<Array<string>>>>
  >([]);
  const [category, setCategory] = useState<number>(0);
  const [page, setPage] = useState<number>(0);
  const [data, setData] = useState<Record<string, string>[]>([]);

  const numBars = 4; // Number of bars in a graph
  const colors = ["#53A9DA", "#AF91F0", "#F2A838", "#7FD33F"];

  const isObjectEmpty = (obj: Object) => {
    return Object.keys(obj).length === 0;
  };

  const camelCaseToWords = (camelCaseString: string): string => {
    // Use a regular expression to match camelCase pattern
    const result = camelCaseString
      .replace(/([a-z])([A-Z])/g, "$1 $2") // Insert space before uppercase letters
      .replace(/^./, (str) => str.toUpperCase()); // Capitalize the first letter

    return result;
  };

  const showPrevious = () => {
    if (page <= 0 && category <= 0) return false;
    return true;
  };

  const showNext = () => {
    if (
      page >= Math.ceil(processedAverages[category][1].length / numBars) - 1 &&
      category >= processedAverages.length - 1
    )
      return false;
    return true;
  };

  const goPrevious = () => {
    if (page > 0) {
      setPage(page - 1);
    } else if (category > 0) {
      setCategory(category - 1);
      setPage(
        Math.ceil(processedAverages[category - 1][1].length / numBars) - 1
      );
    }
  };

  const goNext = () => {
    if (page < Math.ceil(processedAverages[category][1].length / numBars) - 1) {
      setPage(page + 1);
    } else if (category < processedAverages.length - 1) {
      setCategory(category + 1);
      setPage(0);
    }
  };

  const tickFormatter = (value: string) => {
    const maxLength = 12; // Maximum number of characters per line
    const maxLines = 2; // Maximum number of lines
    const lines: string[] = [];
    const words = value.match(/[A-Za-z0-9]+|[ \-_]+/g) || [];

    let currentLine = "";

    for (let index = 0; index < words.length; index++) {
      let word = words[index];
      if (lines.length === maxLines) break;
      if (currentLine === "" && word.trim() === "") continue; // Skip leading spaces

      if (lines.length < maxLines - 1) {
        // Not last line
        while (word.length > maxLength) {
          const length = maxLength - currentLine.length;
          currentLine += word.substring(0, length);
          word = word.substring(length);
          lines.push(currentLine.trim());
          currentLine = "";
          if (lines.length === maxLines) break;
        }

        if (currentLine.length + word.length > maxLength) {
          lines.push(currentLine.trim());
          currentLine = "";
        }
        currentLine += word;
        if (index === words.length - 1) {
          lines.push(currentLine.trim());
        }
      } else {
        // Last line
        if (index < words.length - 1) {
          // Not last word
          if (currentLine.length + word.length + 3 <= maxLength) {
            // Room for "..."
            currentLine += word;
          } else {
            currentLine += "...";
            lines.push(currentLine.trim());
            break;
          }
        } else {
          // Last word
          if (currentLine.length + word.length <= maxLength) {
            currentLine += word;
          } else {
            const length = maxLength - currentLine.length;
            currentLine += word.substring(0, length) + "...";
          }
          lines.push(currentLine.trim());
          break;
        }
      }
    }
    return lines.join("\n");
  };

  useEffect(() => {
    var result: Array<Array<string | Array<Array<string>>>> = [];
    for (var category in averages) {
      var items: Array<Array<string>> = [];
      for (var item in averages[category]) {
        items.push([item, averages[category][item].toString()]);
      }
      items.sort((a, b) => a[0].localeCompare(b[0]));
      result.push([category, items]);
    }
    result.sort((a, b) => b[0].toString().localeCompare(a[0].toString()));
    setProcessedAverages(result);
  }, [averages]);

  useEffect(() => {
    if (!processedAverages.length) {
      setData([]);
      return;
    }
    const startIndex = page * numBars;
    const entries = processedAverages[category][1];

    var result: Record<string, string>[] = [];

    for (var i = 0; i < numBars; i++) {
      const entry = entries[startIndex + i];
      if (!(entry ?? "")) break;
      const element: Record<string, string> = {
        name: camelCaseToWords(entry[0].toString()),
        value: entry[1].toString(),
        colour: colors[i],
      };
      result[i] = element;
    }

    setData(result);
    // eslint-disable-next-line
  }, [category, page, processedAverages]);

  useEffect(() => {
    setPage(0);
  }, [category]);

  if (isLoading) {
    return <SimpleLoader center={true} />;
  }

  return (
    <>
      {data.length > 0 && !isObjectEmpty(averages) && (
        <div className="flex flex-col mt-5 mr-5">
          <div className="flex gap-1 justify-end">
            {showPrevious() ? (
              <button
                onClick={goPrevious}
                className="flex items-center !py-1 !px-4"
              >
                <span className="!text-4xl">&lsaquo;</span>&nbsp;
                <span className="mt-1 !text-md">Prev</span>
              </button>
            ) : (
              <button className="invisible flex items-center !py-1 !px-4">
                <span className="!text-4xl">&lsaquo;</span>&nbsp;
                <span className="mt-1 !text-md">Prev</span>
              </button>
            )}
            {showNext() ? (
              <button
                className="flex items-center !py-1 !px-4"
                onClick={goNext}
              >
                <span className="mt-1 !text-md">Next</span>&nbsp;
                <span className="!text-4xl">&rsaquo;</span>
              </button>
            ) : (
              <button className="invisible flex items-center !py-1 !px-4">
                <span className="mt-1 !text-md">Next</span>&nbsp;
                <span className="!text-4xl">&rsaquo;</span>
              </button>
            )}
          </div>
          <ResponsiveContainer
            minWidth={500}
            width="95%"
            height={360}
            className="block"
          >
            <BarChart
              barGap={-28.5}
              layout="vertical"
              data={data}
              margin={{
                top: 30,
                right: 15,
                bottom: 20,
                left: 80,
              }}
            >
              <CartesianGrid stroke="#f5f5f5" />

              <XAxis
                ticks={[
                  ...Array(parseInt(processedAverages[category][0].toString())),
                ].map((_, i) => i + 1)}
                axisLine={{ stroke: "#EB8181" }}
                type="number"
                xAxisId={0}
                domain={[1, "dataMax"]}
              />

              <YAxis
                axisLine={{ stroke: "#EB8181" }}
                dataKey="name"
                type="category"
                tickFormatter={tickFormatter}
              />
              <Tooltip />
              <Bar maxBarSize={45} dataKey="value" radius={[0, 8, 8, 0]}>
                {data.map((entry, index) => (
                  <Cell key={`cell-${index}`} fill={entry.colour} />
                ))}
              </Bar>
            </BarChart>
          </ResponsiveContainer>
        </div>
      )}
      {isObjectEmpty(averages) && (
        <div className="flex justify-center items-center h-64">
          <h3 className="text-xl font-semibold">No data available.</h3>
        </div>
      )}
    </>
  );
};

export default QuantitativeFeedbackCard;
