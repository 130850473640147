import { ReactComponent as ItsARealMood } from "assets/NG/Activity/Its_a_real_Mood.svg";
import { ReactComponent as ArtworkMixtape } from "assets/NG/Activity/artwork_mixtape.svg";
import { ReactComponent as WhatsGoingOn } from "assets/NG/Activity/whats_going_on.svg";
import { ReactComponent as OnHope } from "assets/NG/Activity/on_hope.svg";
import { ReactComponent as GrowthCollectiveLogo } from "assets/NG/Logos/GrowthCollective-01.svg";
import { useCallback, useEffect, useRef, useState } from "react";
import { Activity } from "interface/ActivityInterface";
import {
  deleteObject,
  getDownloadURL,
  getStorage,
  listAll,
  ref,
  uploadBytesResumable,
} from "firebase/storage";
import { projectStorage } from "../../../firebase/config";
import { isValidImageFile } from "utility/imageValidation";
import { toast } from "react-hot-toast";
import useOrganisation from "hooks/organisation/useOrganisation";
import Organisation from "interface/OrganisationInterface";
import SimpleLoader from "components/Loaders/SimpleLoader";
import { FaTimesCircle } from "react-icons/fa";
import { Button, Modal } from "flowbite-react";
import { HiOutlineExclamationCircle } from "react-icons/hi";
import usePromise from "hooks/utility/usePromise";
import { FiRefreshCcw } from "react-icons/fi";
import { useOrganisationActivityPage } from "hooks/useOrganisationActivityPage";

import classes from "../../../pages/AllCircles/Admin/Admin.module.css";

export const IMAGE_DATA = [
  {
    id: 0,
    key: "Default",
    image: <GrowthCollectiveLogo width={100} height={100} />,
  },
  {
    id: 1,
    key: "ItsARealMood",
    image: <ItsARealMood width={100} height={100} />,
  },
  {
    id: 2,
    key: "ArtworkMixtape",
    image: <ArtworkMixtape width={100} height={100} />,
  },
  {
    id: 3,
    key: "WhatsGoingOn",
    image: <WhatsGoingOn width={100} height={100} />,
  },
  {
    id: 4,
    key: "OnHope",
    image: <OnHope width={100} height={100} />,
  },
];

interface ImageSelectorProps {
  organisation: Organisation;
  activity: Activity;
  SelectedImageIndex: number;
  handleImageCheckboxChange: Function;
  handleImageUrlChange: Function;
  selectedImageUrl: string;
}

const ImageSelector = ({
  organisation,
  activity,
  SelectedImageIndex,
  handleImageCheckboxChange,
  handleImageUrlChange,
  selectedImageUrl,
}: ImageSelectorProps) => {
  const percentRef = useRef(0);
  const inputFileRef = useRef<HTMLInputElement>(null);
  const [isUploading, setIsUploading] = useState(false);
  const [imageURLs, setImageURLs] = useState<string[]>([]);
  const [imageURLsData, setImageURLsData] = useState<string[]>([]);
  const [activeIndex, setActiveIndex] = useState<number>(0);
  const { selectedOrganisation } = useOrganisation();
  const [loading, setLoading] = useState(true);
  const [files, setFiles] = useState<string[]>([]);
  const [isVisible, setIsVisible] = useState(false);
  const { isLoading, resolve } = usePromise();
  const [imageText, setImageText] = useState("");
  const { updateActivitiesWithImageText } = useOrganisationActivityPage();

  const fetchImages = useCallback(async () => {
    try {
      setFiles([]);
      const storageRef = ref(
        projectStorage,
        `organisations/activity_image/${organisation?.id}`
      );
      const result = await listAll(storageRef);

      const urlPromises = result.items.map((imageRef) =>
        getDownloadURL(imageRef)
      );

      const urls = await Promise.all(urlPromises);
      setFiles(urls); // Update the state with the new URLs
      setLoading(false); // Set loading to false after updating
    } catch (error) {
      console.log(error);
    }
    // eslint-disable-next-line
  }, [selectedOrganisation]);

  useEffect(() => {
    fetchImages();
    // eslint-disable-next-line
  }, [organisation?.id, loading]);

  useEffect(() => {
    if (!selectedOrganisation) return;
    console.log(activeIndex, imageURLsData, isUploading, imageURLs);
    // eslint-disable-next-line
  }, [
    SelectedImageIndex,
    activity,
    selectedOrganisation,
    imageURLsData,
    files,
    loading,
  ]);

  const handleFileUpload = useCallback(
    async (e: React.ChangeEvent<HTMLInputElement>) => {
      if (e.target.files && e.target.files.length > 0) {
        setLoading(true);
        try {
          const file = e.target.files[0];
          if (file) {
            const allowedExtensions = ["jpg", "jpeg", "png", "gif"];
            const fileExtension = file.name!.split(".").pop()!.toLowerCase();
            if (allowedExtensions.includes(fileExtension)) {
              // Check the size of the image
              const image = new Image();
              image.src = URL.createObjectURL(file);

              await new Promise<void>((resolve, reject) => {
                image.onload = () => {
                  if (image.width <= 500 && image.height <= 500) {
                    resolve(); // Resolve without passing any value
                  } else {
                    toast.error(
                      "Image dimensions must be 500x500 pixels or smaller."
                    );
                  }
                };
                image.onerror = () =>
                  reject(new Error("Failed to load image."));
              });

              setIsUploading(true);
              const reader = new FileReader();

              reader.onload = (event) => {
                const dataURL = event.target?.result as string;
                setImageURLs((prevURLs) => [...prevURLs, dataURL]);
                setActiveIndex(imageURLs.length);
              };

              reader.readAsDataURL(file);

              //store in fire storage
              let currentDate = new Date();
              let currentTime = currentDate.toLocaleTimeString("en-US", {
                hour12: false,
              });

              let filename = `growthcircles_${currentDate
                .toJSON()
                .slice(0, 10)}_${currentTime}`;

              let extension = file.name.split(".").pop();
              const storageRef = ref(
                projectStorage,
                `/organisations/activity_image/${organisation.id}/${filename}.${extension}`
              );

              // Set the metadata to include the `contentDisposition` property with the value "inline"
              const metadata = {
                contentType: file.type,
                contentDisposition: "inline",
              };

              // Pass the `metadata` object as the second argument to the `uploadBytesResumable` function
              const uploadTask = uploadBytesResumable(
                storageRef,
                file,
                metadata
              );

              uploadTask.on(
                "state_changed",
                (snapshot) => {
                  const percent = Math.round(
                    (snapshot.bytesTransferred / snapshot.totalBytes) * 100
                  );
                  percentRef.current = percent;
                  console.debug(percent);
                },
                (err) => console.error(err),
                async () => {
                  // Get the download URL of the resized image
                  await getDownloadURL(uploadTask.snapshot.ref).then(
                    async (url) => {
                      if (isValidImageFile(url)) {
                        let newStr = url.replace(
                          /\.(jpe?g|png|gif|bmp)/i,
                          "_500x500.webp"
                        );

                        await new Promise<void>((resolve) => {
                          setImageURLsData((prevURLs) => {
                            const updatedURLs = [...prevURLs, newStr];
                            resolve();
                            return updatedURLs;
                          });
                        }).then(() => {
                          // make inputFileRef empty
                          inputFileRef.current!.value = "";

                          setIsUploading(false);

                          toast.success("Upload success!");
                          setLoading(false);
                          fetchImages();
                        });
                      } else {
                        toast.error("Unsupported file extension.");
                        setIsUploading(false);
                        setLoading(false);
                        fetchImages();
                      }
                    }
                  );
                }
              );
            } else {
              toast.error("Please upload a valid image file");
            }
          }
        } catch (error) {
          alert(error);
        }
      }
    },
    // eslint-disable-next-line
    [selectedOrganisation]
  );

  const setDeleteHandler = (img: string) => {
    setImageText(img);
    setIsVisible(!isVisible);
  };

  const deleteHandler = () => {
    const storage = getStorage();

    // Create a reference to the file to delete
    const desertRef = ref(storage, imageText);

    // Delete the file
    resolve(() => deleteObject(desertRef)).then(() => {
      setIsVisible(!isVisible);
      if (activity.selectedImageURL)
        updateActivitiesWithImageText(activity.selectedImageURL);
      toast.success("image deleted successfully!");
      fetchImages();
    });
  };

  const changeImageURLHandler = (img: string) => {
    handleImageCheckboxChange(-1);
    handleImageUrlChange(img);
  };

  const changeImageCheckboxHandler = (index: number) => {
    handleImageCheckboxChange(index);
    handleImageUrlChange("");
  };

  useEffect(() => {
    // eslint-disable-next-line
  }, [activity]);

  return (
    <>
      <Modal
        show={isVisible}
        size="sm"
        popup
        onClose={() => setIsVisible(!isVisible)}
      >
        <Modal.Header />
        <Modal.Body>
          <div className="text-center">
            <HiOutlineExclamationCircle className="mx-auto mb-4 h-14 w-14 text-gray-400 dark:text-gray-200" />
            <h3 className="mb-5 text-lg font-normal text-gray-500 dark:text-gray-400">
              Are you sure you want to delete this image? each activity that
              selected this image will be lost.
            </h3>
            {!isLoading ? (
              <div className="flex justify-center items-center gap-4">
                <Button color="failure" onClick={deleteHandler}>
                  Yes, I'm sure
                </Button>
                <Button color="gray" onClick={() => setIsVisible(!isVisible)}>
                  No, cancel
                </Button>
              </div>
            ) : (
              <div className="flex justify-center items-center gap-4">
                <SimpleLoader />{" "}
              </div>
            )}
          </div>
        </Modal.Body>
      </Modal>
      <div className="flex flex-wrap my-4 px-4 py-8 gap-4 border border-slate-500/25 rounded-sm w-full">
        {activity.hasImage &&
          IMAGE_DATA.map((imageData) => (
            <label key={imageData.id}>
              <input
                type="checkbox"
                className="hidden"
                checked={activity.selectedImageIndex === imageData.id}
                onChange={() => changeImageCheckboxHandler(imageData.id)}
              />
              <div
                className={
                  SelectedImageIndex === imageData.id
                    ? "border-2 border-blue-700"
                    : ""
                }
              >
                {imageData.image}
              </div>
            </label>
          ))}

        {loading ? (
          <SimpleLoader />
        ) : (
          files.map((image, index) => (
            <label key={index}>
              <input
                type="checkbox"
                className="hidden"
                checked={activity.selectedImageURL === image}
                onChange={() => changeImageURLHandler(image)}
              />
              <div
                className={`relative p-2 ${
                  selectedImageUrl === image ? "border-2 border-blue-700" : ""
                }`}
              >
                <img src={image} alt={`fetching...`} width={120} height={120} />

                <FaTimesCircle
                  onClick={() => setDeleteHandler(image)}
                  size={20}
                  className="cursor-pointer absolute top-0 right-0 text-red-500"
                />
              </div>
            </label>
          ))
        )}
      </div>

      {files.length > 0 && (
        <div className={`${classes["container"]} flex flex-col`}>
          <p className="flex text-start self-start py-2 text-sky-800">
            If new uploaded image may take a while to upload press refresh
            button before selecting new images.
          </p>
          <button
            className={`${classes["button"]} px-8 py-2 flex gap-2 self-start items-center`}
            onClick={fetchImages}
          >
            {" "}
            <FiRefreshCcw size={20} />
            Refresh
          </button>
        </div>
      )}
      <div className="py-8 flex flex-col self-start">
        {isUploading && (
          <div className="text-sky-500 py-8 flex self-start">
            {percentRef.current}% uploaded
          </div>
        )}
        <input
          className="border border-gray-200 rounded-full"
          accept="image/jpeg, image/png, image/gif"
          onChange={handleFileUpload}
          ref={inputFileRef}
          type="file"
        />
      </div>
    </>
  );
};

export default ImageSelector;
