import firebase from "firebase/compat/app";
import "firebase/compat/firestore";
import "firebase/compat/auth";
import "firebase/compat/storage";
import "firebase/compat/performance";

import {
  FacebookAuthProvider,
  getAuth,
  signInAnonymously,
  signInWithPopup,
  updateProfile,
  GoogleAuthProvider,
  signInWithCustomToken,
  sendPasswordResetEmail,
  onAuthStateChanged,
} from "firebase/auth";

const mode: string = "live";
var env: string = "";
var firebaseConfig = {};

if (mode === "live") {
  //live nobeans-110a4
  env = "nobeans-110a4";
  firebaseConfig = {
    apiKey: "AIzaSyAoX4WinpfNAy1xdpngIcGQVNIyi7vBSow",
    authDomain: "nobeans-110a4.firebaseapp.com",
    projectId: "nobeans-110a4",
    storageBucket: "nobeans-110a4.appspot.com",
    messagingSenderId: 650620228496,
    appId: "1:650620228496:web:3e2480c0ee248805909915",
    measurementId: "G-CF9R95QGXS",
  };
} else {
  //testing gc-testing-ebf45
  env = "gc-testing-ebf45";
  firebaseConfig = {
    apiKey: "AIzaSyCQGB5If7YLbHe1-r6shUZR7--6XzoFC_g",
    authDomain: "gc-testing-ebf45.firebaseapp.com",
    projectId: "gc-testing-ebf45",
    storageBucket: "gc-testing-ebf45.appspot.com",
    messagingSenderId: 483099142417,
    appId: "1:483099142417:web:58697e11f3209ee80304b0",
    measurementId: "G-7FQGHLRG6K",
  };
}

const ENV = env;

//init firebase
firebase.initializeApp(firebaseConfig);
const perf = firebase.performance();

//init services
const projectFirestore = firebase.firestore();
const projectAuth = firebase.auth();
const projectStorage = firebase.storage();
const projectAuthFacebook = new FacebookAuthProvider();
const projectAuthGoogle = new GoogleAuthProvider();

//timestamp
const timestamp = firebase.firestore.Timestamp;
const serverTimestamp = new Date();

export {
  projectFirestore,
  projectAuth,
  projectStorage,
  serverTimestamp,
  timestamp,
  firebase,
  projectAuthFacebook,
  projectAuthGoogle,
  perf,
  signInAnonymously,
  getAuth,
  signInWithPopup,
  updateProfile,
  signInWithCustomToken,
  sendPasswordResetEmail,
  onAuthStateChanged,
  ENV,
};
