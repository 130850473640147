import { createPortal } from "react-dom";
import Backdrop from "../../../components/utility/Backdrop";

import classes from "./Trigger.module.css";

const ModalOverlay = (props) => {
  // const buttonStyle = {
  //   border: "5px solid #EB8181",
  //   fontWeight: "700",
  //   fontSize: "1.1em",
  //   minHeight: "40px",
  //   width: "203px",
  // };

  return (
    <div className={classes.modal}>
      {/* props.cancelHandler */}

      <div className={classes["message-box"]}>
        <span className={classes["close-button"]} onClick={props.cancelHandler}>
          ✖
        </span>
        <h5>You are about to call the phone</h5>
        <h5>number 1800 221 4444.</h5>
        <p>Do you want to proceed?</p>

        <div className={classes["button-container"]}>
          <button
            className={classes["selection-button"]}
            onClick={() => window.open("tel:18002214444")}
          >
            Yes
          </button>
          <button
            className={classes["selection-button"]}
            onClick={props.cancelHandler}
          >
            No
          </button>
        </div>
      </div>
    </div>
  );
};

const TriggerModal = (props) => {
  return (
    <>
      {/* Modals to be ported over to respective placing in DOM  */}
      <Backdrop onClick={props.onClick} />
      {createPortal(
        <ModalOverlay
          cancelHandler={props.onClick}
          onAccept={props.onAccept}
          title={props.title}
          document={props.document}
          averages={props.averages}
          // subtitle={props.title}
          // body={props.body}
        />,
        document.getElementById("terms-modal")
      )}
    </>
  );
};

export default TriggerModal;
