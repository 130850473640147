import { SelfAwarenessToolInterface } from "interface/SelfAwarenessToolInterface";
import { useEffect, useState } from "react";
import { BackAndNextProps } from "../SelfAwarenessComponentMapping";
import CriteriaItems from "./CriteriaItems";

const CriteriaGrading = ({
  journal,
  backAndNextHandler,
  nextHandler,
  backHandler,
}: {
  journal: SelfAwarenessToolInterface;
  backAndNextHandler: BackAndNextProps;
  nextHandler: (e: React.MouseEvent<HTMLButtonElement, MouseEvent>) => void;
  backHandler: () => void;
}) => {
  const [options, setOptions] = useState<string[]>([]);

  // Load data from local storage on component mount
  useEffect(() => {
    const storedJournal = localStorage.getItem("SelfAwarenessJournal");

    if (storedJournal) {
      const parsedJournal = JSON.parse(storedJournal);

      if (parsedJournal && parsedJournal.Options) {
        // Set the Options from parsedJournal if it exists
        setOptions(parsedJournal.Options);
      }
    }
    // eslint-disable-next-line
  }, []); // Empty dependency array means this effect runs once on mount

  return (
    <div className={` flex flex-col`}>
      <p className="my-4 font-poppins" style={{ color: "var(--text-colour)" }}>
        Rate the extent to which each option meets the decision criteria.
      </p>

      <CriteriaItems
        backHandler={backHandler}
        backAndNextHandler={backAndNextHandler}
        options={options}
        nextAllowedHandler={(e) => nextHandler(e)}
      />
    </div>
  );
};

export default CriteriaGrading;
