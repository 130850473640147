import Bubble from "components/InfoPanel/CircleBubble";
import SimpleLoader from "components/Loaders/SimpleLoader";
import useOrganisationContext from "hooks/organisation/useOrganisationContext";
import usePromise from "hooks/utility/usePromise";
import { useEffect, useState } from "react";

const OrgBubble = () => {
  const { selectedOrganisation, fetchOrgImages } = useOrganisationContext();
  const [files, setFiles] = useState<string[]>([]);
  const { isLoading, resolve } = usePromise();

  useEffect(() => {
    const fetchImages = async () => {
      const img = await resolve(() => fetchOrgImages("logo"));
      if (img) setFiles(img);
    };

    if (selectedOrganisation) {
      fetchImages();
    }
    // eslint-disable-next-line
  }, [selectedOrganisation, fetchOrgImages]);
  return (
    <>
      {files.length > 0 && selectedOrganisation?.showTagLineLogo ? (
        <Bubble>
          <div className="w-44">
            {!isLoading ? (
              <img
                src={files[0]}
                alt={selectedOrganisation?.name}
                className="h-full w-full object-contain"
              />
            ) : (
              <div className="my-4 flex justify-center items-center">
                <SimpleLoader />
              </div>
            )}
          </div>

          {selectedOrganisation?.tagLine !== "" ? (
            <h3
              style={{ color: "var(--main-colour)" }}
              className="text-3xl text-center break-words w-[300px]"
            >
              {selectedOrganisation?.tagLine}
            </h3>
          ) : (
            <h3 className="text-3xl text-center break-words w-[300px]">
              Growth <br /> <em>Circles</em>
            </h3>
          )}
        </Bubble>
      ) : (
        <Bubble>
          <h5
            className="text-xl font-semibold"
            style={{ color: "var(--main-colour)" }}
          >
            Welcome to
          </h5>
          {selectedOrganisation?.tagLine &&
          selectedOrganisation?.tagLine !== "" ? (
            <h5
              className="text-3xl text-center"
              style={{ color: "var(--main-colour)" }}
            >
              {selectedOrganisation?.tagLine}
            </h5>
          ) : (
            <h5 className="text-5xl" style={{ color: "var(--main-colour)" }}>
              Growth <br /> <em>Circles</em>
            </h5>
          )}
        </Bubble>
      )}
    </>
  );
};

export default OrgBubble;
