import { useCallback } from "react";
import { OnStoreChange, Unsubscribe, useClientSyncExternalStore } from "./utility/useClientSyncExternalStore";

type Subscriber<T> = (id: string, onStoreChange: OnStoreChange<T>) => Unsubscribe

/**
 * Subscribes to an external store that requires an id.
 *
 * @param id Id of the store.
 * @param subscribe Subscribe function of the store.
 * @returns 
 */
export default function useSubscribeModel<T>(id: string, subscribe: Subscriber<T>) {

  const subscribeWrapped = useCallback((
    onStoreChange: OnStoreChange<T>
  ) => {
    if (!id) {
      return () => {};
    }
    return subscribe(id, onStoreChange);
  }, [id, subscribe])
  
  return useClientSyncExternalStore(subscribeWrapped);
}
