import getLocalStorageOperations from "utility/localStorage";

const LOCALSTORAGE_KEY_BADGE = "BadgeData";

export type BadgeName = 'Effort' | 'CareAndSupport' | 'Role'

export type Badge = {
  FromUserID: string
  ToUserID: string // TODO: rename this for clarity: This refers to participant id and not user id
  Effort: number
  CareAndSupport: number
  Role: number
}

type Badges = {
  Badges: Badge[]
}

function padMissingData(
  rawData: {}
): Badges {
  return {
    Badges: [],
    ...rawData
  }
}

// --- Helper Functions ---

const ops = getLocalStorageOperations(LOCALSTORAGE_KEY_BADGE, padMissingData)

function _getBadges() {
  return ops.getItem()?.Badges ?? [];
}

function _setBadges(badges: Badge[]) {
  return ops.setItem({ Badges: badges });
}

// --- End Helper functions ---

export const getBadges = _getBadges;

export function toggleBadge(
  fromUserID: string,
  toUserID: string,
  badgeName: BadgeName
) {
  const oldBadges = _getBadges();
  let newBadges: Badge[];
  const oldBadge = oldBadges.find(b => b.FromUserID === fromUserID && b.ToUserID === toUserID);
  if (oldBadge) {
    newBadges = oldBadges.map(b => {
      if (b !== oldBadge) {
        return b;
      }
      return {
        ...b,
        [badgeName]: (b[badgeName] + 1) % 2
      };
    });
  } else {
    newBadges = [
      ...oldBadges,
      {
        FromUserID: fromUserID,
        ToUserID: toUserID,
        Effort: 0,
        CareAndSupport: 0,
        Role: 0,
        [badgeName]: 1
      }
    ];
  }
  _setBadges(newBadges);
  return newBadges;
}

export const removeBadges = ops.removeItem;
