import { useEffect, useState } from "react";
import classes from "./Completed.module.css";
import CompletedContainer from "./CompletedContainer";
import useJournalChecklist from "hooks/useJournalChecklist";
import ConfirmModal from "components/utility/ConfirmModal";
import ManageJournalContainer from "./ManageJournalContainer";
import { Participant } from "interface/ParticipantInterface";
import JournalChecklistContainer from "components/EndOfSession/JournalChecklistContainer";
import { useFirestore } from "hooks/useFirestore";
import { useAuthContext } from "hooks/useAuthContext";

type CompletedProps = {
  setJournalSubmitted: () => void;
  participant: Participant;
};

/**
 * Component that handles the Journal portion (Checklist and Manage) of EndOfSession
 * @param {() => void} setJournalSubmitted - method to set journal as submitted
 * @param {Participant} participant - participant document of current profile user
 */
const Completed = ({
  setJournalSubmitted,
  participant, //participant info passed down as props from EndOfSession
}: CompletedProps) => {
  const { profile } = useAuthContext();
  const [contentType, setContentType] = useState<string>("JournalChecklist");
  const { updateDocument } = useFirestore("users");
  const modalId = "confirm-modal";

  useEffect(() => {
    if (!profile) return;
    const data = { pageStep: "" };
    updateDocument(profile.uid, data);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [profile]);

  const handleNext = () => {
    switch (contentType) {
      case "JournalChecklist":
        setContentType("ManageJournal");
        break;
      case "ManageJournal":
        break;
    }
  };

  const [showTerms, setShowTerms] = useState(false);

  const hideTermsHandler = () => {
    setShowTerms(false);
  };

  const showTermsHandler = () => {
    setShowTerms(true);
  };

  //TODO: last step save journal
  const confirmHandler = () => {
    //Handle submitting of journal
    //console.log(participant);
    handleJournalSubmit(participant).then((res) => {
      setJournalSubmitted();
      handleNext();
      setShowTerms(false);
    });
    //Navigation to next component
  };
  const {
    isSaveIntention,
    isSaveReflect,
    isSaveReflectAll,
    isSaveShareAndSupport,
    isSaveShareAndSupportAll,
    // isSaveSkillDescription,
    // isSaveSkillsDescriptionAll,
    handleSaveIntention,
    handleSaveReflect,
    handleSaveReflectAll,
    handleSaveShareAndSupport,
    handleSaveShareAndSupportAll,
    // handleSaveSkillDescription,
    // handleSaveSkillDescriptionAll,
    handleJournalSubmit,
    saveIntentionContent,
    reflectContent,
    shareAndSupportContent,
  } = useJournalChecklist();

  const completedContentType = {
    JournalChecklist: {
      id: 1,
      header: "Save to your Journal?",
      content: (
        <JournalChecklistContainer
          isSaveIntention={isSaveIntention}
          isSaveReflect={isSaveReflect}
          isSaveReflectAll={isSaveReflectAll}
          isSaveShareAndSupport={isSaveShareAndSupport}
          isSaveShareAndSupportAll={isSaveShareAndSupportAll}
          // isSaveSkillDescription={isSaveSkillDescription}
          // isSaveSkillsDescriptionAll={isSaveSkillsDescriptionAll}
          handleSaveIntention={handleSaveIntention}
          handleSaveReflect={handleSaveReflect}
          handleSaveReflectAll={handleSaveReflectAll}
          handleSaveShareAndSupport={handleSaveShareAndSupport}
          handleSaveShareAndSupportAll={handleSaveShareAndSupportAll}
          // handleSaveSkillDescription={handleSaveSkillDescription}
          // handleSaveSkillDescriptionAll={handleSaveSkillDescriptionAll}
          handleJournalSubmit={showTermsHandler}
          saveIntentionContent={saveIntentionContent}
          reflectContent={reflectContent}
          // skillsDescriptionContent={skillsDescriptionContent}
          shareAndSupportContent={shareAndSupportContent}
        />
      ),
    },
    ManageJournal: {
      id: 2,
      header: "Successfully Saved",
      content: <ManageJournalContainer participant={participant} />,
    },
  };

  return (
    <>
      {showTerms && (
        <ConfirmModal
          hasTitle={true}
          hasMessage={true}
          hasBackDrop={true}
          onClick={hideTermsHandler}
          onAccept={confirmHandler}
          modalId={modalId}
          message={"Do you want to save?"}
        />
      )}
      <div id={modalId} />
      <h2 className={classes["completedTitle"]}>Well done!</h2>
      <p className={classes["completedP"]}>
        Like a{" "}
        <strong>
          <em>Circle</em>
        </strong>
        , you're welcome for another round!
      </p>

      <div className={classes["contentContainer"]}>
        <CompletedContainer
          header={completedContentType[contentType].header}
          id={completedContentType[contentType].id}
        >
          {completedContentType[contentType].content}
        </CompletedContainer>
        {/* <CompletedContainer header={"Save to your journal?"}>
      </CompletedContainer> */}
      </div>
    </>
  );
};

export default Completed;
