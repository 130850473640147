import SimpleLoader from "components/Loaders/SimpleLoader";
import AutoResizeTextArea from "components/utility/Inputs/AutoResizeTextArea";
import { DocumentData } from "firebase/firestore";
import useOrganisationContext from "hooks/organisation/useOrganisationContext";
import { useOrganisationIntentions } from "hooks/useOrganisationIntentions";
import usePromise from "hooks/utility/usePromise";
import { Intention } from "interface/IntentionInterface";

import { useTour } from "@reactour/tour";
import EmptySpace from "components/utility/EmptySpace";
import classes from "pages/AllCircles/Admin/Admin.module.css";
import { FormEvent, useEffect, useState } from "react";
import toast from "react-hot-toast";
import IntentionCard from "./IntentionCard";

interface IntentionSettingsProps {
  intentions: DocumentData;
}

const IntentionSettings = ({ intentions }: IntentionSettingsProps) => {
  const { setIsOpen: setIsTourOpen } = useTour();
  const { selectedOrganisation, updateSelectedOrganisation } =
    useOrganisationContext();
  const [updating, setIsUpdating] = useState(false);
  const [checkboxState, setCheckboxState] = useState({
    randomizeIntentions: false,
  });
  const { updateOrganisationIntentionSettings, AddNewIntentionQuestion } =
    useOrganisationIntentions();
  const [question, setQuestion] = useState("");
  const { isLoading, resolve } = usePromise();

  useEffect(() => {
    if (!selectedOrganisation) return;
  }, [selectedOrganisation]);

  const handleCheckboxChange = async (checkboxName: string) => {
    setIsUpdating(true);
    const updatedCheckboxState = {
      ...checkboxState,
      [checkboxName]: !checkboxState[checkboxName],
    };

    setCheckboxState(updatedCheckboxState);
    updateOrganisationIntentionSettings(updatedCheckboxState).then(() => {
      updateSelectedOrganisation({ name: selectedOrganisation?.name }).then(
        () => {
          setIsUpdating(false);
          toast.success("Settings was updated successfully.");
        }
      );
    });
  };

  const AddNewQuestionHandler = async (e: FormEvent) => {
    e.preventDefault();

    resolve(() => AddNewIntentionQuestion(question))
      .then(() => {
        setQuestion("");
        toast.success("New question was added successfully");
      })
      .catch((error) => {
        toast.error(error);
      });
  };

  return (
    <div className="mb-[100px]">
      {selectedOrganisation && (
        <>
          <EmptySpace />
          <button
            className={`${classes["button"]}`}
            onClick={() => setIsTourOpen(true)}
          >
            Show Tutorial
          </button>
          <EmptySpace />
          <div className="flex items-center gap-4">
            <p className="text-xl my-4 text-slate-600">
              Intention's Questions for {/* Outcome Rating Score (ORS) for */}
              <strong>{selectedOrganisation.name}</strong>
            </p>
          </div>
          <p className=" mb-8 justify-start items-center gap-2 text-slate-500 text-lg">
            Toggle the checkbox below to determine whether the GrowthCircle
            session will incorporate randomized intention-based questions. To
            include new questions, enter your question in the provided text area
            and then click the 'Add New Question' button.
          </p>
          {!updating ? (
            <div
              intention-settings-tour="randomize-questions"
              className="flex flex-col items-start gap-2  text-2xl"
            >
              <label className="cursor-pointer">
                <input
                  type="checkbox"
                  checked={selectedOrganisation.randomizeIntentions ?? false}
                  onChange={() => handleCheckboxChange("randomizeIntentions")}
                  className="mx-4"
                  style={{
                    height: "20px",
                    width: "20px",
                    borderRadius: "3px",
                    backgroundColor: selectedOrganisation.randomizeIntentions
                      ? "var(--icon-colour-0)"
                      : "",
                  }}
                />
                Use Randomize Intention's Questions
              </label>
            </div>
          ) : (
            <SimpleLoader />
          )}
          <form
            intention-settings-tour="add-question"
            onSubmit={AddNewQuestionHandler}
          >
            <div className="py-4 bg-white border border-gray-300 w-1/2 p-2 rounded-md mt-5">
              <AutoResizeTextArea
                isRequired={true}
                placeholder="Specify question here"
                className="border-none w-full text-slate-500 placeholder:text-gray-300"
                value={question}
                onChange={(value) => setQuestion(value)}
              />
            </div>

            <button disabled={isLoading} type="submit" className="my-4">
              {isLoading ? "Saving..." : "Add New Question"}
            </button>
          </form>
          <p className="my-4 font-semibold">
            Total of {intentions.length} questions{" "}
          </p>
          {intentions && (
            <div className="bg-white mt-5">
              {intentions.map((intention: Intention, index: number) => (
                <IntentionCard key={index} intention={intention} />
              ))}
            </div>
          )}
        </>
      )}
    </div>
  );
};

export default IntentionSettings;
