// TODO: move to firebase

export const SelectionData = {
  HelpINeedSelection: [
    {
      title: "Connection",
      subItems: [
        "Acceptance",
        "Affection",
        "Belonging",
        "Cooperation",
        "Communication",
        "Closeness",
        "Community",
        "Companionship",
        "Compassion",
        "Consideration",
        "Consistency",
        "Empathy",
        "Inclusion",
        "Intimacy",
        "Love",
        "Mutuality",
        "Nurturing",
        "Respect/Self-respect",
        "Safety",
        "Security",
        "Stability",
        "Support",
        "To know and be known",
        "To see and be seen",
        "To understand and be",
        "Understood",
        "Trust",
        "Warmth",
      ],
    },
    {
      title: "Physical Well-being",
      subItems: [
        "Air",
        "Food",
        "Movement/Exercise",
        "Rest/Sleep",
        "Safety",
        "Shelter",
        "Touch",
        "Water",
      ],
    },
    { title: "Honesty", subItems: ["Authenticity", "Integrity", "Presence"] },
    { title: "Play", subItems: ["Joy", "Humor"] },
    {
      title: "Peace",
      subItems: [
        "Beauty",
        "Communion",
        "Ease",
        "Equality",
        "Harmony",
        "Inspiration",
        "Order",
      ],
    },
    {
      title: "Autonomy",
      subItems: ["Freedom", "Independence", "Space", "Spontaneity"],
    },
    {
      title: "Meaning",
      subItems: [
        "Awareness",
        "Celebration of life",
        "Challenge",
        "Clarity",
        "Competence",
        "Consciousness",
        "Contribution",
        "Creativity",
        "Discovery",
        "Efficacy",
        "Effectiveness",
        "Growth",
        "Hope",
        "Learning",
        "Mourning",
        "Participation",
        "Purpose",
        "Self-expression",
        "Stimulation",
        "To matter",
        "Understanding",
      ],
    },
  ],
  ImFeel: [
    {
      title: "Love",
      subItems: [
        "Grateful",
        "Sentimental",
        "Affectionate",
        "Romantic",
        "Enchanted",
        "Thankful",
        "Appreciative",
        "Nostalgic",
        "Tender",
        "Compassionate",
        "Warmhearted",
        "Enamored",
        "Passionate",
        "Rapturous",
        "Enthralled",
      ],
    },
    {
      title: "Joy",
      subItems: [
        "Euphoric",
        "Excited",
        "Optimistic",
        "Proud",
        "Cheerful",
        "Happy",
        "Content",
        "Peaceful",
        "Jubilant",
        "Elated",
        "Zealous",
        "Enthusiastic",
        "Hopeful",
        "Eager",
        "Illustrious",
        "Triumphant",
        "Playful",
        "Amused",
        "Delighted",
        "Jovial",
        "Pleased",
        "Satisfied",
        "Serene",
        "Tranquil",
      ],
    },
    {
      title: "Surprise",
      subItems: [
        "Moved",
        "Overcome",
        "Amazed",
        "Confused",
        "Stunned",
        "Touched",
        "Simulated",
        "Astounded",
        "Speechless",
        "Awe-struck",
        "Astonished",
        "Perplexed",
        "Disillusioned",
        "Bewildered",
        "Shocked",
      ],
    },
    {
      title: "Sadness",
      subItems: [
        "Hurt",
        "Unhappy",
        "Disappointed",
        "Shameful",
        "Lonely",
        "Gloomy",
        "Agonized",
        "Disturbed",
        "Miserable",
        "Disheartened",
        "Dismayed",
        "Displeased",
        "Regretful",
        "Guilty",
        "Isolated",
        "Hopeless",
        "Depressed",
      ],
    },
    {
      title: "Anger",
      subItems: [
        "Enraged",
        "Exasperated",
        "Irritable",
        "Jealous",
        "Disgusted",
        "Hateful",
        "Hostile",
        "Agitated",
        "Frustrated",
        "Annoyed",
        "Aggravated",
        "Resentful",
        "Envious",
        "Contemptuous",
        "Revolted",
      ],
    },
    {
      title: "Fear",
      subItems: [
        "Scared",
        "Terrified",
        "Insecure",
        "Nervous",
        "Horrified",
        "Frighted",
        "Helpless",
        "Panicked",
        "Hysterical",
        "Inferior",
        "Inadequate",
        "Worried",
        "Anxious",
        "Mortified",
        "Dreadful",
      ],
    },
  ],
};
