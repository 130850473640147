import { getModelOperationsWithPath, WithId } from "../utility/model";
import {
  arrayRemove,
  arrayUnion,
  deleteField,
  DocumentData,
  DocumentReference,
  FieldPath,
  orderBy,
  SetOptions,
  UpdateData,
  where,
  WithFieldValue,
} from "firebase/firestore";
import {
  getRoleByUserID,
  participantRole,
  setDefaultRoles,
} from "./organisationRole";
import { Profile } from "interface/ProfileInterface";
import { getUserByEmail } from "./profile";
import { hasKey } from "utility/typePredicates";
import {
  defaultComponents,
  isValidComponent,
} from "pages/AllCircles/GenericSessionPage/ComponentMapping";
import { acceptInvitation } from "./organisationGroup";
import Organisation, {
  defaultDataConsentContent,
  defaultOrganisationModel,
} from "interface/OrganisationInterface";
import { timestamp } from "../firebase/config";
import {
  createAndAddOneOrgUser,
  createAndAddOneOrgUserIfNotExists,
} from "utility/orgUsersHelpers";
import { setDashboardStats } from "models/dashboardStats";
import { getOrgUserById } from "hooks/organisation/useOrganisation";
import firebase from "firebase/compat/app";

export const FIRESTORE_PATH_ORGANISATIONS = "organisations";

//const ERR_ORG_NOT_FOUND = 'Organisation does not exist';
export const ERR_ORG_ALREADY_EXIST =
  "Organisation with the same name already exists";
//const ERR_FAILED_TO_ADD_ORG = 'Failed to add new organisation. Please try again later';
//const ERR_ROLE_ALREADY_EXIST = 'Role with the same name already exists';

export function isOrganisation(obj: unknown): obj is Organisation {
  const properties: (keyof Organisation)[] = ["name", "id", "users"];
  for (let prop of properties) {
    if (!hasKey(obj, prop)) {
      return false;
    }
  }
  return true;
}

// --- Helper Functions ---

const ops = getModelOperationsWithPath(
  FIRESTORE_PATH_ORGANISATIONS,
  defaultOrganisationModel
);

//const _setOrganisation: ((organisationId: string, newData: WithFieldValue<Organisation>) => Promise<void>) = ops.setModel;

const _getOrganisation: (
  organisationId: string
) => Promise<Organisation | undefined> = ops.getModel;

const _addOrganisation: (
  newOrganisation: WithFieldValue<Organisation>
) => Promise<DocumentReference<DocumentData> | undefined> = ops.addModel;

const _getOrganisations = ops.getModels;

const _getOrganisationWhere = ops.getModelWhere;

const _updateOrganisation: (
  organisationId: string,
  organisationUpdates: UpdateData<Organisation>
) => Promise<void> = ops.updateModel;

const _setOrganisation: (
  organisationId: string,
  newOrganisation: WithFieldValue<Organisation>,
  setOptions: SetOptions
) => Promise<void> = ops.setModel;

// --- End Helper functions ---

export const getOrgById = _getOrganisation;

export const getOrgUniqueId = _getOrganisation;

export function getOrgByName(
  organisationName: string
): Promise<WithId<Organisation> | undefined>;
export function getOrgByName(
  organisationName: string,
  organisations: Organisation[]
): Organisation | undefined;
export function getOrgByName(
  organisationName: string,
  organisations?: Organisation[]
) {
  return organisations
    ? organisations.find((org) => org.name === organisationName)
    : _getOrganisationWhere(where("name", "==", organisationName));
}

export async function getOrganisations(profile: Profile) {
  if (profile.access === "admin") {
    return _getOrganisations();
  }

  // Get organisations with `users: { user.id: role }`
  //TODO: need to align with new orgusers logic
  return _getOrganisations(orderBy(new FieldPath("users", profile.uid)));
}

export function getAllOrganisations() {
  return _getOrganisations();
}

export function checkIsUserOf(
  userId: string,
  organisation: Organisation
): Promise<boolean>;
export function checkIsUserOf(
  userId: string,
  organisationName: string
): Promise<boolean>;
export async function checkIsUserOf(
  userId: string,
  organisationOrName: Organisation | string
): Promise<boolean> {
  if (typeof organisationOrName !== "string") {
    const userFound = await getOrgUserById(organisationOrName.id, userId);
    return userFound !== null && userFound !== undefined; // Explicitly check for both null and undefined
  }

  // If organisationOrName is a string (organisation name), handle async logic
  const organisation = await getOrgByName(organisationOrName);
  if (organisation) {
    const user = await getOrgUserById(organisation.id, userId);

    return user !== null && user !== undefined; // Return false if user is null or undefined
  } else {
    return false; // Return false if organisation is not found
  }
}
export async function addOrganisation(
  organisationName: string,
  addedBy: string
) {
  const existingOrg = await getOrgByName(organisationName);
  if (existingOrg) {
    throw new Error(ERR_ORG_ALREADY_EXIST);
  }
  const newOrganisation: Organisation = {
    id: "",
    name: organisationName,
    users: {},
    invites: {},
    components: defaultComponents,
    inheritGeneralTopics: true,
    addedBy,
    createdAt: timestamp.fromDate(new Date()),
    enableFlagRating: true,
    flagRatingPercentage: 60,
    setMaxRating: false,
    maxRating: 10,
    enableCustomEndGrowthCircles: false,
    enableCustomDataConsent: false,
    customDataConsent: defaultDataConsentContent,
    dataConsentValidityPeriod: 1
  };
  const docRef = await _addOrganisation(newOrganisation);
  const orgId = docRef?.id;
  if (!orgId) {
    return;
  }

  setDashboardStats(orgId);
  return setDefaultRoles(orgId);
}

export const updateOrganisation = _updateOrganisation;

export async function joinOrganisation(
  organisation: Organisation,
  userId: string,
  role: string
) {
  const _check = await checkIsUserOf(userId, organisation);
  if (_check) {
    return;
  }
  const updates = {};
  updates[`users.${userId}`] = role;

  return updateOrganisation(organisation.id, updates);
}

//TODO: remove this to omit organisation fields
export async function joinUserFieldOrganisation(
  organisation: Organisation,
  userId: string,
  role: string
) {
  const updates = {};
  updates[`users.${userId}`] = role;

  return updateOrganisation(organisation.id, updates);
}

export async function removeUserFromOrganisation(
  organisationId: string,
  userId: string
) {
  // Create an update object with a null value for the userId key
  const updates = {};
  updates[`users.${userId}`] = firebase.firestore.FieldValue.delete();

  return updateOrganisation(organisationId, updates);
}

export async function inviteUser(
  organisation: Organisation,
  email: string,
  role: string
) {
  return getUserByEmail(email).then((profile) => {
    if (profile?.uid) {
      // Add to orgusers collection
      createAndAddOneOrgUser(organisation, profile, role);
      joinUserFieldOrganisation(organisation, profile.uid, role);
      return joinOrganisation(organisation, profile.uid, role);
    } else {
      // using setDoc allows emails to be used as field keys without it being interpreted as dot notation
      return _setOrganisation(
        organisation.id,
        { invites: { [email]: role } } as Organisation,
        { merge: true }
      );
    }
  });
}

export function unInviteUser(organisation: Organisation, email: string) {
  // using setDoc allows emails to be used as field keys without it being interpreted as dot notation
  return _setOrganisation(
    organisation.id,
    { invites: { [email]: deleteField() } } as WithFieldValue<Organisation>,
    { merge: true }
  );
}

export async function acceptInvitations(user: Profile) {
  const email = user.email;
  if (!email) {
    return;
  }
  const orgs = await _getOrganisations(
    orderBy(new FieldPath("invites", email))
  );
  return orgs.forEach((org) => {
    const role = org.invites[email];
    return Promise.all([
      joinOrganisation(org, user.uid, role ?? participantRole.name),
      // add to orgusers collection if not there
      createAndAddOneOrgUserIfNotExists(
        org,
        user,
        role ?? participantRole.name
      ),
      unInviteUser(org, email),
      acceptInvitation(user, org.id),
    ]);
  });
}

export const setUserRole = async (
  organisation: Organisation,
  userId: string,
  role: string
) => {
  await _setOrganisation(
    organisation.id,
    { users: { [userId]: role } } as Organisation,
    { merge: true }
  );
  const updates = { ...organisation.users };
  updates[userId] = role;

  return updateOrganisation(organisation.id, { users: updates });
};

// TODO: Replace all usage of this function with the one from permission context.
/**
 * Retrieves organisations that a user has a certain permission for.
 *
 * @param profile Profile of the user.
 * @param permission Permission required to filter organisations by.
 * @return All organisations that the user can generate QR code for.
 */
export function getOrgsWithPermission(profile: Profile, permission: string) {
  return getOrganisations(profile).then(async (orgs) => {
    const filteredOrgs: Organisation[] = [];
    await Promise.all(
      orgs
        .filter((org) => !org.isAType)
        .map(async (org) => {
          const role = await getRoleByUserID(profile.uid, org);
          if (
            profile.access === "admin" ||
            (role && role.permissions[permission] === true)
          ) {
            return filteredOrgs.push(org);
          }
        })
    );
    return filteredOrgs;
  });
}

/**
 * Add newComponent to components array or organisation.
 * @param organisationId
 * @param newComponent
 * @returns
 */
export async function addComponent(
  organisationId: string,
  newComponent: string
) {
  const validComponent = isValidComponent(newComponent);
  if (!validComponent) {
    throw new Error("Component does not exist.");
  }
  return ops.updateModel(organisationId, {
    components: arrayUnion(newComponent),
  });
}

/**
 * Remove a component from organisation's components array.
 * @param organisationId
 * @param componentToDelete
 * @returns
 */
export async function removeComponent(
  organisationId: string,
  componentToDelete: string
) {
  return ops
    .updateModel(organisationId, {
      components: arrayRemove(componentToDelete),
    })
    .catch((error) => console.log(error));
}

/**
 * Set component array of organisation.
 * @param organisationId
 * @param components
 * @returns
 */
export async function setComponent(
  organisationId: string,
  components: string[]
) {
  const validComponents =
    components.filter((c) => !isValidComponent(c)).length === 0;
  if (!validComponents) {
    throw new Error("A component does not exist.");
  }
  return ops.updateModel(organisationId, { components: components });
}

export const setDefaultComponents = async (organisationId: string) => {
  getOrgById(organisationId).then((org) => {
    if (org?.defaultComponents && org?.defaultComponents?.length > 0) {
      return ops.updateModel(organisationId, {
        components: arrayUnion(...org?.defaultComponents),
      });
    } else {
      return ops.updateModel(organisationId, {
        components: arrayUnion(...defaultComponents),
      });
    }
  });
};
