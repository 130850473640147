import FirstNameAvatar from "components/utility/FirstNameAvatar";
import { Tooltip } from "flowbite-react";
import useGetParticipants from "hooks/useGetParticipants";
import { Participant } from "interface/ParticipantInterface";
import { FaChalkboardTeacher } from "react-icons/fa";
interface ParticiPant {
  participant: Participant;
}

const ParticipantSummaryCard = ({ participant }: ParticiPant) => {
  const { getParticipantColourIndex } = useGetParticipants();
  return (
    <div
      className={`relative flex flex-col gap-2 bg-default w-full py-6 rounded-md shadow-md px-4 items-center justify-center`}
    >
      {participant.sessionRole !== undefined &&
        participant.sessionRole !== null &&
        participant.sessionRole !== "" && (
          <div className="absolute top-2 right-2">
            {" "}
            <Tooltip className="capitalize" content={participant.sessionRole}>
              <FaChalkboardTeacher
                size={20}
                style={{ color: "var(--main-colour)" }}
              />{" "}
            </Tooltip>
          </div>
        )}
      <FirstNameAvatar
        size={80}
        name={participant.userName}
        participant={participant}
        styleTriggered={true}
        participantNumber={getParticipantColourIndex(participant.userId)}
      />
      <div className="my-2 w-full text-center">
        <h2 className="my-2 text-xl tex-center font-semibold capitalize w-full">
          {participant.userName}
        </h2>
        <table className="text-left">
          <tbody>
            {participant.personalId && (
              <tr>
                <th className="pr-8 flex justify-start">Personal ID</th>
                <td>{participant.personalId}</td>
              </tr>
            )}
            {participant.tutorialGroup && (
              <tr>
                <th className="pr-8 flex justify-start">Tutorial Group</th>
                <td>{participant.tutorialGroup}</td>
              </tr>
            )}
            <tr>
              <th className="pr-8 flex justify-start">Intentions</th>
              <td>{participant.intentions}</td>
            </tr>
            {participant.topic && (
              <tr>
                <th className="pr-8 flex justify-start">Topic</th>
                <td>{participant.topic}</td>
              </tr>
            )}
            {participant.path && (
              <tr>
                <th className="pr-8 flex justify-start">Pathway</th>
                <td>{participant.path}</td>
              </tr>
            )}
            <tr>
              <th className="pr-8 flex justify-start">Role</th>
              <td>{participant.role?.role}</td>
            </tr>
          </tbody>
        </table>
      </div>
    </div>
  );
};

export default ParticipantSummaryCard;
