import classes from "./ProgressBar.module.css";
import Modal from "components/utility/Modal";
import React, { useEffect, useState } from "react";
import ProgressBarContent from "./ProgressBarContent";

interface Props {
  progress: number,
  borderTopLeftRadius?: string,
  borderTopRightRadius?: string,
  borderBottomLeftRadius?: string,
  borderBottomRightRadius?: string,
  borderTop?: string,
  borderLeft?: string,
  borderRight?: string,
  borderBottom?: string,
  height?: string,
  modalOnClick?: boolean,
  hasAnimation?:boolean,
  finishAnimationHandler?: () => any
}

const ProgressBar: React.FC<Props> = ({
  progress,
  borderTopLeftRadius,
  borderTopRightRadius,
  borderBottomLeftRadius,
  borderBottomRightRadius,
  borderTop,
  borderLeft,
  borderRight,
  borderBottom,
  height,
  modalOnClick,
  hasAnimation,
  finishAnimationHandler,
}) => {

  const containerStyles = {
    borderTopLeftRadius: borderTopLeftRadius,
    borderTopRightRadius: borderTopRightRadius,
    borderBottomLeftRadius: borderBottomLeftRadius,
    borderBottomRightRadius: borderBottomRightRadius,
    height: height,
    borderTop: borderTop,
    borderLeft: borderLeft,
    borderRight: borderRight,
    borderBottom: borderBottom,
  };

  const [isModalOpen, setIsModalOpen] = useState<boolean>(false);
  const [widthValue, setWidthValue] = useState<number>(progress);

  useEffect(() => {
    let timerId;
    if (widthValue !== progress) {
      timerId = setTimeout(() => setWidthValue((widthValue + 1) % 100), 50);
    } 
    return () => clearTimeout(timerId);
  }, [progress, widthValue])

  const animatedProgressStyle = {
    width: `${widthValue}%`
  }

  //Inline for the progress
  const progressStyle = {
    width: `${progress}%`,
  };
  

  return (
    <>
      {modalOnClick && <Modal
        isModalOpen={isModalOpen}
        setIsModalOpen={setIsModalOpen}
        isScrollBlocked={true}
        clickOutsideToClose={true}
        maxHeight={"70%"}
        maxWidth={"400px"}
      >
        <ProgressBarContent />
      </Modal>}
      <div
        className={classes["containerStyles"]}
        style={containerStyles}
        onClick={(e: React.MouseEvent<HTMLDivElement>) => {
          e.stopPropagation();
          setIsModalOpen(true);
        }}
      >
        <div className={classes["progressStyles"]} style={hasAnimation ? animatedProgressStyle : progressStyle}></div>
        <span className={classes["labelStyles"]}>
          <strong
            className={classes["progressPercentage"]}
          >{`${widthValue}%`}</strong>
        </span>
      </div>
    </>
  );
};

ProgressBar.defaultProps = {
  modalOnClick: false
}

export default ProgressBar;
