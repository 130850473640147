import useGetFeedbackForm from "hooks/useGetFeedbackForm";
import { useEffect, useState } from "react";
import classes from "./FeedbackWaitingArea.module.css";
import useGetParticipants from "hooks/useGetParticipants";
import { useAuthContext } from "hooks/useAuthContext";
import BeforeWaitingArea from "./BeforeWaitingArea";
import AfterWaitingArea from "./AfterWaitingArea";
import useUsers from "hooks/useUsers";
import useFeedbackForm from "hooks/useFeedbackForm";
import ConfirmModal from "components/utility/ConfirmModal";
import { useGrowthCircleContext } from "hooks/useGrowthCircleContext";
import { useFirestore } from "hooks/useFirestore";

interface FeedbackWaitingAreaProps {
  parentNextHandler: () => void;
}

const MODAL_ID = {
  FIRST_WARNING: "first-warning",
  LAST_WARNING: "last-warning",
};

/**
 * Component for the waiting area and progress loading
 * @param {() => void}parentNextHandler - handler for the next button
 */
const MainWaitingArea = ({ parentNextHandler }: FeedbackWaitingAreaProps) => {
  const { profile } = useAuthContext();
  const {
    userFeedbackForm,
    feedbackForms,
    allFeedbackSubmittedCallback,
    submittedFeedbackFormsCallBack,
  } = useGetFeedbackForm({});
  const { updateFeedbackFormTransaction } = useFeedbackForm();
  const { participants } = useGetParticipants();
  const { updateRoleProgress } = useUsers();

  //States
  const [showAfterContent, setShowAfterContent] = useState<boolean | null>(
    null
  );
  const [showFirstModal, setShowFirstModal] = useState<boolean>(false);
  const [showSecondModal, setShowSecondModal] = useState<boolean>(false);
  const [showNextContent, setShowNextContent] = useState<boolean>(false);
  const { growthCircleSession } = useGrowthCircleContext();

  const { updateDocument } = useFirestore("GrowthCircles");
  //Test output
  useEffect(() => {
    // console.log(allFeedbackSubmittedCallback());
    // console.log(userFeedbackForm);
    // console.log(showNextContent);
  }, []);

  useEffect(() => {
    //If all users have submitted their feedback form OR facil decides to proceed early
    const shouldUpdateRole = allFeedbackSubmittedCallback() || showAfterContent;

    //If the current user's feedback form has yet to update the role progress, update it. (To prevent multiple updates)
    if (
      feedbackForms &&
      shouldUpdateRole &&
      userFeedbackForm !== null &&
      !userFeedbackForm.savedToProgress
    ) {
      //Set feedbackForm to be updated
      updateFeedbackFormTransaction(userFeedbackForm.id, {
        savedToProgress: true,
      })
        .then((saved) => setShowAfterContent(true))
        .then((contentShown) =>
          updateRoleProgress(
            userFeedbackForm,
            participants,
            feedbackForms.length
          ).then((res) => setTimeout(() => setShowNextContent(true), 800))
        );
    }

    //if for some reason the user refreshes the page it will automatically move the user to the progress if the form has been saved
    if (userFeedbackForm?.savedToProgress) {
      setShowAfterContent(true);
      setShowNextContent(true);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [feedbackForms, showAfterContent, growthCircleSession]);

  //Method to open up modal to prompt facil if facil does indeed want to proceed early
  const switchHandler = () => {
    if (!allFeedbackSubmittedCallback()) {
      //Open modal
      setShowFirstModal(true);
    } else {
      setShowAfterContent(true);
    }
  };

  //Moves user to the next section of EndOfSession
  const nextHandler = () => {
    //Calculate score
    parentNextHandler();
  };

  //Method to prompt second modal upon accepting the first
  const firstModalNextHandler = () => {
    //1. Hide first modal
    setShowFirstModal(false);

    //2. Show second modal
    setShowSecondModal(true);
  };

  //Method to show content after accepting second modal
  const secondModalNextHandler = async () => {
    //.Hide second modal
    setShowSecondModal(false);

    //.Show After content
    setShowAfterContent(true);

    //. Update allowEndOfSession to True
    setShowNextContent(true);
    updateDocument(growthCircleSession!.id, { allowSkipFeedBackForm: true });
  };

  useEffect(() => {
    console.info("Reached main waiting area");
  }, []);

  const userRole = participants.find((p) => p.userId === profile!.id)!.role;

  return (
    <div className={classes["waiting-container"]}>
      {/* Before all Submitted */}
      {(!showAfterContent && (
        <BeforeWaitingArea
          nextHandler={nextHandler}
          submittedFeedbackForms={submittedFeedbackFormsCallBack()}
        />
      )) ||
        (feedbackForms !== null && userRole && (
          <AfterWaitingArea
            userRole={userRole}
            userBadgeStates={userFeedbackForm?.refBadgeRatings}
            feedbackForms={feedbackForms}
            setShowNextHandler={setShowNextContent}
          />
        ))}
      {profile!.isFacil && !showAfterContent && (
        <button type="button" onClick={switchHandler}>
          Proceed Early
        </button>
      )}
      {/* TODO: this will allow users to click Ok when facil decided to proceed early */}
      {(profile!.isFacil || userFeedbackForm?.savedToProgress) &&
        showNextContent && (
          <button type="button" onClick={nextHandler}>
            Okay
          </button>
        )}
      {!profile!.isFacil &&
        growthCircleSession?.allowSkipFeedBackForm &&
        !showNextContent && (
          <button type="button" onClick={switchHandler}>
            Okay
          </button>
        )}
      {showFirstModal && (
        <ConfirmModal
          hasMessage={true}
          hasTitle={true}
          hasBackDrop={true}
          modalId={MODAL_ID.FIRST_WARNING}
          title={"Are you sure?"}
          message={
            "Not everyone has submitted their feedback yet. This will affect everyone's role progress"
          }
          onClick={() => setShowFirstModal(false)}
          onAccept={() => firstModalNextHandler()}
        />
      )}
      {showSecondModal && (
        <ConfirmModal
          hasTitle={true}
          hasMessage={true}
          hasBackDrop={true}
          modalId={MODAL_ID.LAST_WARNING}
          title={"Are you REALLY sure?"}
          message={"Participant's role progress may not be truly reflected."}
          onClick={() => setShowSecondModal(false)}
          onAccept={() => secondModalNextHandler()}
        />
      )}
      <div id={MODAL_ID.FIRST_WARNING}></div>
      <div id={MODAL_ID.LAST_WARNING}></div>
    </div>
  );
};

export default MainWaitingArea;
