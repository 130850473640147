import { useGrowthCircleContext } from "hooks/useGrowthCircleContext";
import React, { useEffect } from "react";
import classes from "./Logo.module.css";
import useOrganisationContext from "hooks/organisation/useOrganisationContext";

import { useAuthContext } from "hooks/useAuthContext";

interface Style {
  style?: { [key: string]: any };
  text?: string;
}

//TODO : must be included on organisation property
const CustomLogos = ["NG", "SUSS", "NTU Career & Attachment Office"];

const NormalLogo: React.FC<Style> = ({ text }) => {
  if (!text) {
    return (
      <>
        <span className="italic px-1">Growth</span>
        <span>Circles</span>
      </>
    );
  }

  const replacedText = text.replace(
    /growthcircles/gi,
    '<span class="italic px-1">Growth</span><span>Circles</span>'
  );

  return <span dangerouslySetInnerHTML={{ __html: replacedText }} />;
};

const NgLogo: React.FC = () => (
  <div className="text-center">
    <span className="block leading-tight text-lg">How To Art With Friends</span>
    <span className="block leading-tight text-lg">
      <span className="italic">Growth</span> Circles
    </span>
  </div>
);

const SUSSLogo: React.FC = () => (
  <div className="text-center">
    <span className="leading-tight text-3lg">PSY213</span>
    <span className="block leading-tight text-lg">
      <span className="italic">Growth</span> Circles
    </span>
  </div>
);

const NTULogo: React.FC = () => (
  <div className="text-center">
    <span className="block leading-tight text-2xl">
      BuddyUP! Growth Mentoring Circles
    </span>
  </div>
);

const Logo: React.FC<Style> = ({ style, text }) => {
  const { growthCircleSession } = useGrowthCircleContext();
  const { selectedOrganisation } = useOrganisationContext();
  const { profile } = useAuthContext();

  useEffect(() => {
    if (!growthCircleSession || !selectedOrganisation) return;
  }, [growthCircleSession, selectedOrganisation, profile]);

  return (
    <>
      {!growthCircleSession && (
        <h1 className={classes.logo} style={style}>
          <NormalLogo text={text} />
        </h1>
      )}

      {growthCircleSession &&
        CustomLogos.includes(growthCircleSession.type) && (
          <>
            {growthCircleSession.type === "NG" && (
              <div
                className={`${classes.logo} flex gap-4 justify-center items-center`}
                style={style}
              >
                <NgLogo />
              </div>
            )}

            {growthCircleSession.type === "SUSS" && (
              <div
                className={`${classes.logo} flex gap-4 justify-center items-center`}
                style={style}
              >
                <SUSSLogo />
              </div>
            )}

            {growthCircleSession.type === "NTU Career & Attachment Office" && (
              <div
                className={`${classes.logo} flex gap-4 justify-center items-center`}
                style={style}
              >
                <NTULogo />
              </div>
            )}
          </>
        )}

      {growthCircleSession &&
        !CustomLogos.includes(growthCircleSession.type) && (
          <h1 className={classes.logo} style={style}>
            {selectedOrganisation?.hasCustomHeaderText && (
              <NormalLogo text={selectedOrganisation.customHeaderText} />
            )}
            {!selectedOrganisation?.hasCustomHeaderText && (
              <NormalLogo text={text} />
            )}
          </h1>
        )}
    </>
  );
};

export default Logo;
