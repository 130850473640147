import EmotionWheel from "assets/SelfAwareness/materials/EmotionWheel.png";
import IcanDoIt from "assets/SelfAwareness/materials/IcanDoIt.png";
import CircleOfControl from "assets/SelfAwareness/materials/CircleOfControl.png";
import CQChart from "assets/SelfAwareness/materials/CQChart.png";
import SelfAppraisal from "assets/SelfAwareness/materials/SelfAppraisal.png";
import WhatIsTheIssue from "assets/SelfAwareness/materials/WhatsTheIssue.png";
import EmbraceDifference from "assets/SelfAwareness/materials/EmbraceDifference.png";
export const MaterialMapping = {
  EmotionWheel,
  IcanDoIt,
  CircleOfControl,
  CQChart,
  SelfAppraisal,
  WhatIsTheIssue,
  EmbraceDifference,
};
