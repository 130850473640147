import { useCallback, useEffect, useReducer } from "react";

export const NO_SELECTION = -1;

/**
 * Finds the index of the first item that satisfies the predicate.
 *
 * @param items List of items to check.
 * @param checkShouldSelect Predicate to check for item.
 * @returns index of first item that satisfies predicate, NO_SELECTION otherwise.
 */
function findIndex<T>(items: T[], checkShouldSelect: (item: T) => boolean) {
  const newItem = items.find(checkShouldSelect);
  return newItem ? items.indexOf(newItem) : NO_SELECTION;
}

export type ListSelectState<T> = {
  items: T[];
  selected: number;
};

export type ListSelectAction<T> =
  | {
      type: "FETCH";
      payload: T[];
    }
  | {
      type: "SELECT";
      payload: (item: T) => boolean;
    }
  | {
      type: "UNSELECT";
    }
  | {
      type: "ADD";
      payload: T;
    }
  | {
      type: "UPDATE";
      payload: T;
    }
  | {
      type: "UPDATE_SPECIFIC";
      payload: { predicate: (item: T) => boolean; item: T };
    }
  | {
      type: "DELETE";
    }
  | {
      type: "SET_LIST";
      payload: T[];
    };;

function _createReducer<T>(isEqual: (item: T, item2: T) => boolean) {
  return (
    state: ListSelectState<T>,
    action: ListSelectAction<T>
  ): ListSelectState<T> => {
    const oldItem = state.items[state.selected];
    switch (action.type) {
      case "FETCH":
        if (!oldItem) {
          return {
            selected: NO_SELECTION,
            items: action.payload,
          };
        }
        return {
          selected: findIndex(action.payload, (item) => isEqual(item, oldItem)),
          items: action.payload,
        };
      case "SELECT":
        return {
          selected: findIndex(state.items, action.payload),
          items: state.items,
        };
      case "UNSELECT":
        return {
          selected: NO_SELECTION,
          items: state.items,
        };
      case "ADD":
        return {
          selected: state.items.length,
          items: [...state.items, action.payload],
        };
      case "UPDATE":
        if (!oldItem) {
          return state;
        }
        const newList = [...state.items];
        newList[state.selected] = action.payload;
        return {
          selected: state.selected,
          items: newList,
        };
      case "UPDATE_SPECIFIC":
        const { predicate, item } = action.payload;
        const index = findIndex(state.items, predicate);
        const updatedList = [...state.items];
        updatedList[index] = item;
        return {
          selected: state.selected,
          items: updatedList,
        };
      case "DELETE":
        if (!oldItem) {
          return state;
        }
        return {
          selected: state.items.length >= 2 ? 0 : NO_SELECTION,
          items: [
            ...state.items.slice(0, state.selected),
            ...state.items.slice(state.selected + 1),
          ],
        };
      case "SET_LIST":
        return {
          selected: NO_SELECTION,
          items: action.payload,
        };
      default:
        return state;
    }
  };
}

/**
 * Manages the state of a list of asynchronously fetched items and track the currently selected item.
 * Can be used with dropdown menus to select item.
 *
 * @param isEqual Function to compare if 2 items are the same item.
 * @returns ListSelectReducer hook.
 */
export function useListSelect<T>(
  fetchItems: () => Promise<T[]>,
  isEqual: (t1: T, t2: T) => boolean
) {
  const defaultState: ListSelectState<T> = {
    selected: NO_SELECTION,
    items: [],
  };

  const [state, dispatch] = useReducer(
    _createReducer<T>(isEqual),
    defaultState
  );

  const { items, selected } = state;

  /**
   * Selects the first item that satisifes a predicate.
   *
   * @param checkShouldSelect Checks if an item should be selected.
   */
  const selectItem = useCallback((checkShouldSelect: (item: T) => boolean) => {
    dispatch({
      type: "SELECT",
      payload: checkShouldSelect,
    });
  }, []);

  const unselectItem = useCallback(() => {
    dispatch({
      type: "UNSELECT",
    });
  }, []);

  /**
   * Adds an item to the list.
   *
   * @param newItem New item to be added.
   */
  const addItem = useCallback((newItem: T | undefined) => {
    if (newItem) {
      dispatch({
        type: "ADD",
        payload: newItem,
      });
    }
  }, []);

  /**
   * Updates the currently selected item.
   *
   * @param updatedItem Updated item.
   */
  const updateItem = useCallback((updatedItem: T | undefined) => {
    if (updatedItem) {
      dispatch({
        type: "UPDATE",
        payload: updatedItem,
      });
    }
  }, []);

  /**
   * Updates the first item that satisifes the predicate.
   *
   * @param checkShouldUpdate Checks if the item should be updated.
   * @param updatedItem Updated item.
   */
  const updateSpecificItem = useCallback(
    (checkShouldUpdate: (item: T) => boolean, updatedItem: T | undefined) => {
      if (updatedItem) {
        dispatch({
          type: "UPDATE_SPECIFIC",
          payload: { predicate: checkShouldUpdate, item: updatedItem },
        });
      }
    },
    []
  );

  /**
   * Deletes the selected item from the list.
   */
  const deleteItem = useCallback(() => {
    dispatch({
      type: "DELETE",
    });
  }, []);

  /**
   * Sets the entire list of items.
   *
   * @param newList New list of items.
   */
  const setList = useCallback((newList: T[]) => {
  dispatch({
    type: "SET_LIST",
    payload: newList,
  });
  }, []);

  const selectedItem = selected !== NO_SELECTION ? items[selected] : null;

  const wrappedFetchItems = useCallback(() => {
    return fetchItems().then((items) => {
      dispatch({
        type: "FETCH",
        payload: items,
      });
      return items;
    });
  }, [fetchItems]);

  useEffect(() => {
    wrappedFetchItems();
  }, [wrappedFetchItems]);

  return {
    items,
    selectedItem,
    selectItem,
    unselectItem,
    addItem,
    updateItem,
    updateSpecificItem,
    deleteItem,
    setList,
    fetchItems: wrappedFetchItems,
  };
}
