import {
  PartialParticipant,
  Participant,
} from "interface/ParticipantInterface";
import { useGCSession } from "hooks/useGCSession";
import ProfilePicture from "components/ProfilePicture/ProfilePicture";
import { ReactComponent as ExclamationMark } from "assets/ExclamationMark.svg";
import classes from "./FirstNameAvatar.module.css";
import { MouseEventHandler, useCallback, useEffect, useState } from "react";
import { Profile } from "interface/ProfileInterface";
import { getUserById } from "models/profile";

function getColourIndex(participantNumber: number) {
  if (participantNumber < 0) {
    return 0;
  }
  const totalColors = 6; // Total number of colors available
  return participantNumber % totalColors;
}
export function getIconMainColour(participantNumber: number) {
  return `var(--icon-colour-${getColourIndex(participantNumber)})`;
}

export function getIconBackgroundColour(participantNumber: number) {
  return `var(--icon-colour-${getColourIndex(participantNumber)}-background)`;
}

interface Props {
  name: string;
  participantNumber: number;
  participant: Participant | PartialParticipant;
  onClick?: MouseEventHandler<HTMLDivElement>;
  styleTriggered?: boolean;
  customStyle?: string;
  size?: number;
}

const FirstNameAvatar: React.FC<Props> = ({
  name,
  participantNumber,
  participant,
  onClick,
  styleTriggered = false,
  customStyle,
  size = 40,
}) => {
  const { getFirstLetters } = useGCSession();
  const [participantProfile, setParticipantProfile] = useState<Profile | null>(
    null
  );

  const firstLettersName = getFirstLetters(name).slice(0, 2);

  const style = {
    border: `2px solid ${getIconMainColour(participantNumber)}`,
    backgroundColor: `${getIconBackgroundColour(participantNumber)}`,
    color: `${getIconMainColour(participantNumber)}`,
  };

  const shouldTrigger =
    styleTriggered && "triggered" in participant && participant.triggered;

  const fetchProfile = useCallback(() => {
    if (!participant.userId) {
      return;
    }
    getUserById(participant.userId).then((profile) => {
      setParticipantProfile(profile ?? null);
    });
  }, [participant.userId]);

  useEffect(() => {
    fetchProfile();
  }, [fetchProfile]);

  return (
    <div className={`${shouldTrigger ? classes.triggered : ""}`}>
      {shouldTrigger ? <ExclamationMark width={90} /> : ""}
      {participantProfile?.photoURL ? (
        <ProfilePicture
          size={size}
          style={style}
          profile={participantProfile}
          onClick={onClick}
          onReport={fetchProfile}
        />
      ) : (
        <div
          onClick={onClick}
          className={`${classes["avatar-container"]} ${
            customStyle ? classes["avatar-container-" + customStyle] : ""
          } `}
          style={{ ...style, height: `${size}px`, width: `${size}px` }}
        >
          <strong className="uppercase" style={{ fontSize: `${size + 70}%` }}>
            {firstLettersName}
          </strong>
        </div>
      )}
    </div>
  );
};

export default FirstNameAvatar;
