import React from "react";

interface EffortProps {
  sleeveFill?: string;
  middleStroke?: string;
  width?: string;
  height?: string
}

const Effort: React.FC<EffortProps> = ({
  sleeveFill,
  middleStroke,
  width,
  height
}) => {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width={width ? width : "26"}
      height={height ? height : "24"}
      fill="none"
      viewBox="0 0 26 24"
    >
      <path
        fill="url(#paint0_linear_813_3260)"
        d="M3.975 16.535c-.01-.007-.019-.015-.028-.02a.228.228 0 01-.11-.156.28.28 0 01.043-.197c.44-.209 3.679-1.754 4.479-2.48.206-.188.305-.662.489-1.627l.136-.704c.048-.229.173-.654.304-1.103.18-.612.366-1.244.428-1.617.52-3.142.952-7.037.956-7.074.07-.677.218-1.366.768-1.366.648 0 1.101.408 1.101.994l.04 14.984c0 1.073-.407 1.229-.88 1.409-.265.1-.565.216-.777.499-.434.58-.899 1.35-1.348 2.095-.192.316-.387.642-.578.948l-5.023-4.585z"
      ></path>
      <path
        fill="#E6B77E"
        d="M11.443.38c.546 0 .912.322.912.803l.04 14.983c0 .942-.302 1.058-.759 1.232-.288.11-.616.235-.86.563-.44.588-.907 1.361-1.358 2.112-.152.25-.305.506-.457.753l-4.854-4.43a.441.441 0 00-.057-.043c-.017-.01-.02-.019-.02-.028a.039.039 0 010-.021c.625-.298 3.674-1.765 4.46-2.48.253-.23.352-.696.549-1.731.041-.218.087-.455.136-.702.046-.222.169-.642.3-1.086.182-.618.37-1.257.432-1.642.52-3.147.952-7.046.958-7.087.087-.84.26-1.197.578-1.197m0-.379c-.753 0-.891.923-.956 1.534 0 0-.43 3.89-.953 7.064-.107.643-.62 2.17-.73 2.716-.234 1.145-.353 2.03-.57 2.227-.828.753-4.476 2.47-4.476 2.47-.178.225-.138.522.091.663l5.19 4.736c.663-1.043 1.386-2.351 2.038-3.223.521-.698 1.695-.19 1.695-2.021l-.04-14.983c0-.652-.489-1.183-1.29-1.183z"
      ></path>
      <path
        fill={sleeveFill ? sleeveFill : "#F9BB62"}
        d="M9.573 24L0 23.894l.106-7.36 6.018-2.703a.499.499 0 01.687.332c.18.704.599 1.951 1.516 3.203a17.404 17.404 0 002.378 2.608.997.997 0 01.23 1.186l-2.306 1.772L9.573 24z"
      ></path>
      <path
        // stroke="#E6B77E"
        stroke={middleStroke ? middleStroke : "#E6B77E"}
        strokeLinecap="round"
        strokeLinejoin="round"
        strokeMiterlimit="10"
        strokeWidth="2"
        d="M10.526 2.64s.156-.98 1.047-.98c.89 0 .952.98.952.98m-2.281 2.464s.195-1.071 1.14-1.071c.943 0 1.16 1.071 1.16 1.071"
      ></path>
      <path
        fill="#424242"
        d="M6.282 14.385c.19.71.635 2.017 1.587 3.316a17.979 17.979 0 002.457 2.696.434.434 0 01.134.404l-2.165 1.58a.57.57 0 00-.106.819l.178.218L.58 23.33l.093-6.425 5.609-2.52m.047-.597a.484.484 0 00-.203.043L.108 16.533l-.106 7.36L9.575 24l-.946-1.16 2.306-1.68a.994.994 0 00-.23-1.186 17.419 17.419 0 01-2.378-2.609 9.457 9.457 0 01-1.516-3.202.496.496 0 00-.482-.375z"
        opacity="0.2"
      ></path>
      <path
        fill="url(#paint1_linear_813_3260)"
        d="M16.289 21.118c-.19-.305-.387-.631-.579-.948-.45-.745-.914-1.517-1.348-2.095-.212-.283-.512-.398-.777-.499-.472-.18-.88-.335-.88-1.409l.04-14.982c0-.586.453-.994 1.102-.994.55 0 .695.69.767 1.366.004.04.436 3.934.956 7.076.06.373.248 1.006.428 1.617.133.45.258.874.304 1.103.051.249.096.486.138.706.184.965.283 1.437.49 1.625.798.726 4.038 2.271 4.478 2.48.04.062.055.13.043.195a.224.224 0 01-.11.155.26.26 0 00-.028.021l-5.024 4.583z"
      ></path>
      <path
        fill="#E6B77E"
        d="M13.845.38c.319 0 .491.358.58 1.196.004.04.439 3.938.958 7.085.065.385.252 1.024.432 1.642.131.446.255.865.3 1.086.051.249.095.484.137.702.197 1.035.295 1.502.547 1.731.787.717 3.836 2.182 4.462 2.48a.038.038 0 010 .02c-.002.009-.006.018-.021.03a.317.317 0 00-.057.043l-4.854 4.43c-.152-.247-.305-.503-.457-.754-.451-.748-.92-1.524-1.357-2.112-.245-.326-.573-.451-.861-.561-.457-.174-.758-.29-.758-1.23l.04-14.985c-.003-.481.365-.804.91-.804m0-.379c-.8 0-1.292.53-1.292 1.183l-.04 14.983c0 1.831 1.174 1.323 1.696 2.02.652.873 1.374 2.181 2.038 3.224l5.19-4.734c.229-.14.27-.438.09-.664 0 0-3.648-1.716-4.476-2.469-.216-.197-.336-1.082-.57-2.227-.11-.546-.623-2.073-.73-2.716-.523-3.174-.953-7.064-.953-7.064C14.74.923 14.6 0 13.845 0z"
      ></path>
      <path
        fill={sleeveFill ? sleeveFill : "#F9BB62"}
        d="M15.713 24l9.573-.106-.106-7.36-6.018-2.703a.499.499 0 00-.686.332 9.49 9.49 0 01-1.517 3.203 17.404 17.404 0 01-2.378 2.608.997.997 0 00-.23 1.186l2.306 1.772L15.713 24z"
      ></path>
      <path
        stroke={middleStroke ? middleStroke : "#E6B77E"}
        strokeLinecap="round"
        strokeLinejoin="round"
        strokeMiterlimit="10"
        strokeWidth="2"
        d="M14.76 2.64s-.155-.98-1.046-.98-.952.98-.952.98m2.281 2.464s-.195-1.071-1.14-1.071c-.946 0-1.16 1.071-1.16 1.071"
      ></path>
      <path
        fill="#424242"
        d="M19.006 14.385l5.61 2.518.094 6.425-7.787.088.178-.218a.565.565 0 00-.106-.82L14.829 20.8a.43.43 0 01.135-.404 17.977 17.977 0 002.457-2.696 10.156 10.156 0 001.585-3.314m-.047-.597a.498.498 0 00-.483.375 9.49 9.49 0 01-1.517 3.202 17.407 17.407 0 01-2.378 2.61.997.997 0 00-.23 1.184l2.306 1.68-.946 1.16 9.573-.106-.106-7.36-6.016-2.702a.484.484 0 00-.203-.043z"
        opacity="0.2"
      ></path>
      <defs>
        <linearGradient
          id="paint0_linear_813_3260"
          x1="8.208"
          x2="8.208"
          y1="21.118"
          y2="0.189"
          gradientUnits="userSpaceOnUse"
        >
          <stop stopColor="#EDC391"></stop>
          <stop offset="1" stopColor="#F9DDBD"></stop>
        </linearGradient>
        <linearGradient
          id="paint1_linear_813_3260"
          x1="17.086"
          x2="17.086"
          y1="21.118"
          y2="0.189"
          gradientUnits="userSpaceOnUse"
        >
          <stop stopColor="#EDC391"></stop>
          <stop offset="1" stopColor="#F9DDBD"></stop>
        </linearGradient>
      </defs>
    </svg>
  );
}

export default Effort;