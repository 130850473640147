import SliderDisplay from "components/SliderCustom/SliderDisplay";
import FirstNameAvatar from "components/utility/FirstNameAvatar";
import { Participant } from "interface/ParticipantInterface";
import { FC } from "react";
import { ReactComponent as ExclamationMark } from "assets/ExclamationMark.svg";
import Organisation from "interface/OrganisationInterface";
import { useOrgORS } from "hooks/organisation/useOrgORS";

interface Props {
  participant: Participant;
  organisation?: Organisation;
}

const TotalOrgScoreModal: FC<Props> = ({ participant, organisation }) => {
  const { ors } = useOrgORS();
  const ratingKeys = ors.map((orsItem) => orsItem.title);

  return (
    <div className="flex flex-col items-center text-[color:var(--main-colour)] font-bold pb-1 mb-[80px]">
      <h1 className={`underline underline-offset-8 decoration-2 mb-2 text-xl`}>
        For facilitator info only
      </h1>
      {/* ... other code ... */}
      <div className="relative flex items-center gap-1 top-3">
        <FirstNameAvatar
          name={participant.userName}
          participant={participant}
          styleTriggered={true}
          participantNumber={0}
        />
        <h1 className="">{participant.userName}</h1>
      </div>
      {ratingKeys.map((key, index) => {
        const orgCheckOutValue =
          participant.orgCheckOut?.[key.replace(/ /g, "_")];
        const orgCheckInValue =
          participant.orgCheckIn?.[key.replace(/ /g, "_")];

        return (
          <div
            key={index}
            className="relative flex flex-col items-center w-full"
          >
            <div className={`relative flex items-center -bottom-9 `}>
              {Number(orgCheckOutValue) >= 0 &&
              Number(orgCheckOutValue) < Number(orgCheckInValue) &&
              organisation?.enableFlagRating ? (
                <ExclamationMark />
              ) : (
                ""
              )}
              <h1
                className={`mb-8 ${
                  Number(orgCheckOutValue) >= 0 &&
                  Number(orgCheckOutValue) < Number(orgCheckInValue) &&
                  organisation?.enableFlagRating
                    ? `text-red-400`
                    : ``
                }`}
              >
                {key.replace(/_/g, " ")}
              </h1>
            </div>
            <SliderDisplay
              max={organisation?.maxRating ?? 10}
              withBackground={false}
              value={undefined}
              checkIn={
                Number(orgCheckInValue) >= 0
                  ? Number(orgCheckInValue)
                  : undefined
              }
              checkOut={
                Number(orgCheckOutValue) >= 0
                  ? Number(orgCheckOutValue)
                  : undefined
              }
            />
          </div>
        );
      })}
    </div>
  );
};

export default TotalOrgScoreModal;
