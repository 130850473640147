import React from "react";
import classes from "./CircleBubble.module.css";
import { ReactComponent as BigBubble } from "../../assets/BigBubble.svg";
import { ReactComponent as Bubble2 } from "../../assets/Bubble2.svg";
import { ReactComponent as Bubble3 } from "../../assets/Bubble3.svg";
import { ReactComponent as Bubble4 } from "../../assets/Bubble4.svg";
type CircleBubbleProps = {
  children: React.ReactNode;
};

/**
 * Bubble with animating smaller bubbles
 * @param {React.ReactNode} children - content to be placed inside bubble
 */
const CircleBubble = ({ children }: CircleBubbleProps) => {
  return (
    <div className={classes["circle"]}>
      <div className={classes["children"]}>{children}</div>
      {/* <span className={classes["text"]}>
        I'm <strong>{name}.</strong>
      </span>
      <div className={classes["avatar"]}>
        <Avatar width="100%" height="100%" />
      </div> */}
      <div className={classes["bigBubble"] + " " + classes["movingBubble"]}>
        <BigBubble width="100%" height="100%" />
      </div>
      <div className={classes["bigBubble"]}>
        <BigBubble width="80%" height="80%" />
      </div>
      <div className={classes["bubble2"]}>
        <Bubble2 width="100%" height="100%" />
      </div>
      <div className={classes["bubble3"]}>
        <Bubble3 width="20%" height="100%" />
      </div>
      <div className={classes["bubble4"]}>
        <Bubble4 width="60%" height="60%" />
      </div>
      <div className={classes["bubble5"]}>
        <Bubble3 transform="scale(-1,1)" width="100%" height="100%" />
      </div>
      <div className={classes["bubble6"]}>
        <BigBubble transform="rotate(145)" width="100%" height="100%" />
      </div>
      <div className={classes["bubble7"] + " " + classes["movingBubble2"]}>
        <Bubble3 transform="rotate(100)" width="100%" height="100%" />
      </div>
      <div className={classes["bubble7"]}>
        <Bubble3 width="120%" height="120%" />
      </div>
      <div className={classes["bubble8"]}>
        <BigBubble
          transform="scale(-1,1) rotate(-10)"
          width="100%"
          height="100%"
        />
      </div>
      <div className={classes["bubble9"] + " " + classes["movingBubble"]}>
        <Bubble2 width="100%" height="100%" />
      </div>

      <div className={classes["bubble9"]}>
        <Bubble2 width="100%" height="100%" />
      </div>
    </div>
  );
};

export default CircleBubble;
