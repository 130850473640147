import React from "react";

interface Props {
  height?: string,
  width?: string,
}

const EmptySpace: React.FC<Props> = ({ height, width }) => {
  const style = {
    minHeight: height ? height : "20px",
    minWidth: width ? width: "100%",
  };
  return (
    <div
      style={style}
    ></div>
  );
};

export default EmptySpace;
