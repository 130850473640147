import { Participant } from "interface/ParticipantInterface";
import { projectFirestore } from "../../../../firebase/config";
import { useEffect, useRef, useState } from "react";
import { DownloadTableExcel } from "react-export-table-to-excel";

import AsyncGroup from "./AsyncGroup";
import { TEST_ORGANISATIONS } from "utility/growthCircles";

const ExportDataCustomORS = () => {
  const tableRef = useRef(null);
  const [datas, setDatas] = useState<Participant[]>([]);

  const fetchDataAndSortByCreatedAt = async () => {
    const ref = projectFirestore
      .collection("organisations")
      .doc("3YnRMzok7KUfDQoGsV1x")
      .collection("participantHistory")
      .where("growthCircleId", "not-in", TEST_ORGANISATIONS)

      .get();

    ref.then((snapshots) => {
      let result: Participant[] = [];
      snapshots.docs.map((doc) => {
        console.log(doc.data());
        return result.push(doc.data() as Participant);
      });
      setDatas(result);
    });
  };

  useEffect(() => {
    fetchDataAndSortByCreatedAt();
    // eslint-disable-next-line
  }, []);

  const defaultValue = 3;

  return (
    <div>
      <div className="px-4">
        <DownloadTableExcel
          filename={`GrowthCircles-data-${new Date()}`}
          sheet="users"
          currentTableRef={tableRef.current}
        >
          <button className="bg-green-500 hover:bg-green-600 px-4 py-2 rounded-md text-white">
            Export Excel
          </button>
        </DownloadTableExcel>
      </div>
      <table border={1} ref={tableRef}>
        <thead>
          <tr>
            <th scope="col" className="px-6 py-3 border border-gray-500">
              Date
            </th>
            <th scope="col" className="px-6 py-3 border border-gray-500">
              Time
            </th>
            <th scope="col" className="px-6 py-3 border border-gray-500">
              Email
            </th>
            <th scope="col" className="px-6 py-3 border border-gray-500">
              Name
            </th>
            <th scope="col" className="px-6 py-3 border border-gray-500">
              Intentions
            </th>
            <th scope="col" className="px-6 py-3 border border-gray-500">
              Core Role
            </th>
            <th scope="col" className="px-6 py-3 border border-gray-500">
              Role
            </th>
            <th scope="col" className="px-6 py-3 border border-gray-500">
              Role Description
            </th>
            <th scope="col" className="px-6 py-3 border border-gray-500">
              Topic
            </th>
            <th scope="col" className="px-6 py-3 border border-gray-500">
              Path
            </th>
            <th scope="col" className="px-6 py-3 border border-gray-500">
              Tutorial Group
            </th>
            <th scope="col" className="px-6 py-3">
              CheckIn Industry insights
            </th>
            <th scope="col" className="px-6 py-3 border border-gray-500">
              CheckIn - Job roles
            </th>
            <th scope="col" className="px-6 py-3 border border-gray-500">
              CheckIn - Career Paths & Progression
            </th>
            <th scope="col" className="px-6 py-3 border border-gray-500">
              CheckIn - Skills Relevant To Industry
            </th>
            <th scope="col" className="px-6 py-3 border border-gray-500">
              CheckOut - Industry insights
            </th>
            <th scope="col" className="px-6 py-3 border border-gray-500">
              CheckOut - Job roles
            </th>
            <th scope="col" className="px-6 py-3 border border-gray-500">
              CheckOut - Career Paths & Progression
            </th>
            <th scope="col" className="px-6 py-3 border border-gray-500">
              CheckOut - Skills Relevant To Industry
            </th>
            <th scope="col" className="px-6 py-3 border border-gray-500">
              For Self - On a scale of 1-10, how satisfied are you with today’s
              BuddyUP! Growth Mentoring Circle?
            </th>
            <th scope="col" className="px-6 py-3 border border-gray-500">
              For Self - What takeaways and insights do you have from today’s
              BuddyUP! Growth Mentoring Circle?
            </th>
            <th scope="col" className="px-6 py-3 border border-gray-500">
              For Self - What is one action step you will be taking?
            </th>

            <th scope="col" className="px-6 py-3 border border-gray-500">
              For Group - What went well in today's session? What else do you
              appreciate? What do you think contributed to that?
            </th>
          </tr>
        </thead>
        <tbody>
          {datas &&
            datas.map((data) => (
              <tr key={data.id}>
                <td className="px-6 py-2 border border-gray-500">
                  {data.createdAt.toDate().toLocaleDateString("en-US", {
                    timeZone: "Asia/Manila",
                    month: "long",
                    day: "numeric",
                    year: "numeric",
                  })}
                </td>
                <td className="px-6 py-2 border border-gray-500">
                  {data.createdAt.toDate().toLocaleTimeString("en-US", {
                    timeZone: "Asia/Manila",
                  })}
                </td>
                <td className="px-6 py-2 border border-gray-500">
                  {" "}
                  {data.email}
                </td>
                <td className="px-6 py-2 border border-gray-500">
                  {data.userName}
                </td>
                <td className="px-6 py-2 border border-gray-500">
                  {data.intentions}
                </td>
                <td className="px-6 py-4 border border-gray-500">
                  {" "}
                  {data.role ? data.role.core_skills : ""}
                </td>
                <td className="px-6 py-4 border border-gray-500">
                  {" "}
                  {data.role ? data.role.role : ""}
                </td>
                <td className="px-6 py-4 border border-gray-500">
                  {" "}
                  {data.role ? data.role.description : ""}
                </td>
                <td className="px-6 py-4 border border-gray-500">
                  {" "}
                  {data.activity !== "" ? data.activity : data.topic}{" "}
                  {data.topic}
                </td>
                <td className="px-6 py-4 border border-gray-500">
                  {" "}
                  {data.activityTitle !== ""
                    ? data.activityTitle
                    : data.path}{" "}
                  {data.path}
                </td>
                <td className="px-6 py-4 border border-gray-500">
                  {" "}
                  {data.userId && <AsyncGroup userId={data.userId} />}
                </td>
                <td className="px-6 py-4 border border-gray-500">
                  {data.checkIn ? data.checkIn.individually : ""}
                </td>
                <td className="px-6 py-4 border border-gray-500">
                  {data.checkIn ? data.checkIn.interpersonally : ""}
                </td>
                <td className="px-6 py-4 border border-gray-500">
                  {data.checkIn ? data.checkIn.socially : ""}
                </td>
                <td className="px-6 py-4 border border-gray-500">
                  {data.checkIn ? data.checkIn.overall : ""}
                </td>
                <td className="px-6 py-4 border border-gray-500">
                  {data.checkOut ? data.checkOut.individually : ""}
                </td>
                <td className="px-6 py-4 border border-gray-500">
                  {data.checkOut ? data.checkOut.interpersonally : ""}
                </td>
                <td className="px-6 py-4 border border-gray-500">
                  {data.checkOut ? data.checkOut.socially : ""}
                </td>
                <td className="px-6 py-4 border border-gray-500">
                  {data.checkOut ? data.checkOut.overall : ""}
                </td>
                <td className="px-6 py-4 border border-gray-500">
                  {data.feedbackReflection !== undefined &&
                  data.feedbackReflection.feedbackForSelf
                    ? data.feedbackReflection.feedbackForSelf.satisfactory
                    : 5}
                </td>
                <td className="px-6 py-4 border border-gray-500">
                  {data.feedbackReflection !== undefined &&
                  data.feedbackReflection.feedbackForSelf
                    ? data.feedbackReflection.feedbackForSelf.takeaways
                    : ""}
                </td>
                <td className="px-6 py-4 border border-gray-500">
                  {data.feedbackReflection !== undefined &&
                  data.feedbackReflection.feedbackForSelf !== undefined
                    ? data.feedbackReflection.feedbackForSelf
                        .whatActionStepQuestion
                    : defaultValue}
                </td>

                <td className="px-6 py-4 border border-gray-500">
                  {data.feedbackReflection !== undefined &&
                  data.feedbackReflection.feedbackForGroup
                    ? data.feedbackReflection.feedbackForGroup
                        .whatElseToAppreciete
                    : ""}
                </td>
              </tr>
            ))}
        </tbody>
      </table>
    </div>
  );
};

export default ExportDataCustomORS;
