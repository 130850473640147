import SimpleLoader from "components/Loaders/SimpleLoader";
import { Button, Modal } from "flowbite-react";
import usePromise from "hooks/utility/usePromise";
import { useState } from "react";
import { toast } from "react-hot-toast";
import { FaTimes } from "react-icons/fa";
import { HiOutlineExclamationCircle } from "react-icons/hi";

interface TopicItemProps {
  topic: string;
  categoryId: string;
  deleteFunc: Function;
}

const TopicItem = ({ topic, categoryId, deleteFunc }: TopicItemProps) => {
  const [deleteShow, setDeleteShow] = useState(false);
  const { isLoading, resolve } = usePromise();
  const deleteHandler = async (topic: string, categoryId: string) => {
    resolve(() => deleteFunc(topic, categoryId)).then(() => {
      toast.success("Topic deleted successfully");
      setDeleteShow(!deleteShow);
    });
  };
  return (
    <>
      {" "}
      <Modal
        show={deleteShow}
        size="lg"
        popup
        onClose={() => setDeleteShow(!deleteShow)}
      >
        <Modal.Header />
        <Modal.Body>
          <div className="text-center flex justify-center flex-col items-center">
            {!isLoading && (
              <HiOutlineExclamationCircle
                size={82}
                className="mx-auto mb-4  text-gray-400 dark:text-gray-200"
              />
            )}
            <h3 className="mb-5 text-lg font-normal text-gray-500 dark:text-gray-400">
              Are you certain about your decision to delete the "
              <strong>{topic}</strong>" topic? Please be aware that this action
              could have repercussions on any questions associated with this
              topic, as they will also be removed.
            </h3>
            {isLoading ? (
              <SimpleLoader />
            ) : (
              <div className="flex justify-center gap-4">
                <Button
                  color="failure"
                  onClick={() => deleteHandler(topic, categoryId)}
                >
                  Yes, I'm sure
                </Button>
                <Button color="gray" onClick={() => setDeleteShow(!deleteShow)}>
                  No, cancel
                </Button>
              </div>
            )}
          </div>
        </Modal.Body>
      </Modal>
      <div className="text-center flex justify-center items-center gap-2 px-4 py-2 rounded-full break-all bg-main-colour text-default">
        {topic}

        <FaTimes
          onClick={() => setDeleteShow(!deleteShow)}
          size={20}
          className="cursor-pointer"
        />
      </div>
    </>
  );
};

export default TopicItem;
