import { PermissionContext } from "context/PermissionContext";
import { useContext } from "react";

const ERR_NO_PROVIDER =
  "usePermissionContext must be used inside a PermissionContextProvider";

/**
 * Provides the context for the user's permission and permission of the selected organisation.
 *
 * @returns PermissionContext.
 */
export default function usePermissionContext() {
  const context = useContext(PermissionContext);

  if (!context) {
    throw Error(ERR_NO_PROVIDER);
  }

  return context;
}
