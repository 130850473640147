import { ORSInterface } from "interface/ORSInterface";
import { useState } from "react";

interface ORSSortCardProps {
  question: ORSInterface;
  setOrder: Function;
  order: string[];
}

const ORSSortCard = ({ question, setOrder, order }: ORSSortCardProps) => {
  const [selected, setIsSelected] = useState(true);

  const selectedHandler = () => {
    setIsSelected(!selected);
    setOrder((prevSelectedIds) => {
      if (!selected) {
        return [...prevSelectedIds, question.id];
      } else {
        return prevSelectedIds.filter((id: string) => id !== question.id);
      }
    });
  };

  return (
    <div
      onClick={selectedHandler}
      className={`relative bg-white flex justify-center items-center min-w-[150px]  min-h-[150px] shadow-md text-slate-700  cursor-pointer ${
        selected ? "border-4 border-green-700 " : "border border-slate-400/70"
      }`}
    >
      {question.title}

      {question.id && selected && (
        <p className="absolute bottom-2 right-2 text-md font-semibold rounded-full text-default bg-green-700 w-10 h-10 flex items-center justify-center text-center">
          {selected ? order.indexOf(question.id) + 1 : ""}
        </p>
      )}
    </div>
  );
};

export default ORSSortCard;
