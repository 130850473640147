import classes from "./WelcomeMessage.module.css";
import Logo from "../utility/Logo";

const WelcomeMessage = (props) => {
  return (
    <div className={classes["welcome-message"]}>
      <span className={classes.welcome}>Welcome to</span>
      {"\n"}
      <Logo />
    </div>
  );
};

export default WelcomeMessage;
