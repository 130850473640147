import { FaCheckCircle, FaTimes } from "react-icons/fa";
import { IoMdInformationCircle } from "react-icons/io";
import SpecialCharactersPopup from "./SpecialCharactersPopup";
import { useState } from "react";
import { passwordHasSpecialCharacters } from 'utility/inputValidation'

interface PasswordCheckerProps {
  password: string;
  confirm_password: string;
  theme?: "light" | "dark";
}


const PasswordChecker = ({
  password,
  confirm_password,
  theme,
}: PasswordCheckerProps) => {
  const [showPopup, setShowPopup] = useState(false);
  
  return (
    <div>
      <p
        className={`flex justify-start items-center gap-4 ${
          theme === "dark" ? "text-default" : "text-slate-500"
        }`}
      >
        {password.length >= 8 ? (
          <FaCheckCircle className={`text-green-500`} />
        ) : (
          <FaTimes className="text-red-500" />
        )}
        At least 8 Characters
      </p>
      <p
        className={`flex justify-start items-center gap-4 ${
          theme === "dark" ? "text-default" : "text-slate-500"
        }`}
      >
        {/[A-Z]/.test(password) ? (
          <FaCheckCircle className={`text-green-500`} />
        ) : (
          <FaTimes className="text-red-500" />
        )}
        With an uppercase
      </p>
      <p
        className={`flex justify-start items-center gap-4 ${
          theme === "dark" ? "text-default" : "text-slate-500"
        }`}
      >
        {/[a-z]/.test(password) ? (
          <FaCheckCircle className={`text-green-500`} />
        ) : (
          <FaTimes className="text-red-500" />
        )}
        With a lowercase
      </p>
      <p
        className={`flex justify-start items-center gap-4 ${
          theme === "dark" ? "text-default" : "text-slate-500"
        }`}
      >
        {/* eslint-disable-next-line */}
        {passwordHasSpecialCharacters(password) ? (
          <FaCheckCircle className="text-green-500" />
        ) : (
          <FaTimes className="text-red-500" />
        )}
        With a special character   
        <IoMdInformationCircle 
          className="info-icon" 
          onClick={() => setShowPopup(true)}
          style={{ cursor: 'pointer', marginLeft:'-10'}}
          size={20}
        />
      </p>
      
      <SpecialCharactersPopup trigger={showPopup} setTrigger={setShowPopup}>
        <h2>Details</h2>
        <p>Accepted: _@!#$%^&*+=~</p>
        <p>Not accepted: /(){}[]:;&lt;&gt;,.?\-</p>
      </SpecialCharactersPopup>
      
    
      <p
        className={`flex justify-start items-center gap-4 ${
          theme === "dark" ? "text-default" : "text-slate-500"
        }`}
      >
        {/\d/.test(password) ? (
          <FaCheckCircle className={`text-green-500`} />
        ) : (
          <FaTimes className="text-red-500" />
        )}
        Includes a digit
      </p>
      <p
        className={`flex justify-start items-center gap-4 ${
          theme === "dark" ? "text-default" : "text-slate-500"
        }`}
      >
        {password !== "" && password === confirm_password ? (
          <FaCheckCircle className={`text-green-500`} />
        ) : (
          <FaTimes className="text-red-500" />
        )}
        Confirm password match
      </p>
    </div>
  );
};

export default PasswordChecker;
