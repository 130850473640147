import { useState, useEffect } from "react";
import { LocalStorageKeys } from "../App";
import { useAuthContext } from "./useAuthContext";
import useFeedbackForm from "./useFeedbackForm";

export type DragRatingKey = {
  KEY: string;
  TITLE: string;
  SUB_TITLE: string;
};

export type DragRatingKeys = {
  [key: string]: DragRatingKey;
};

/**
 * Areas for drag ratings (used as keys in most components that uses slider/drag)
 */
export const DRAG_RATING_KEYS: DragRatingKeys = {
  INDIVIDUALLY: {
    KEY: "individually",
    TITLE: "Individually",
    SUB_TITLE: "Personal well-being",
  },
  INTERPERSONALLY: {
    KEY: "interpersonally",
    TITLE: "Interpersonally",
    SUB_TITLE: "Family, Close relationships",
  },
  SOCIALLY: {
    KEY: "socially",
    TITLE: "Socially",
    SUB_TITLE: "Work, school, friendships",
  },
  OVERALL: {
    KEY: "overall",
    TITLE: "Overall",
    SUB_TITLE: "General sense of well-being",
  },
};

type DragRating = {
  value: number;
  isTouched: boolean;
};

export type DragRatings = {
  [key: string]: DragRating;
};

/**
 * Method to retrieve dragValues for Reflection from localStorage
 * @param localStoragekey: string - string to retrieve current drag state (if any)
 *
 */
const getInitialDragState = (localStorageKey: string): DragRatings => {
  // localStorage.removeItem(localStorageKey);
  const currDragValues: string | null = localStorage.getItem(localStorageKey);
  if (currDragValues) {
    const dragValues = JSON.parse(currDragValues);
    return dragValues;
  } else {
    const defaultDragValues = {} as DragRatings;
    defaultDragValues[DRAG_RATING_KEYS.INDIVIDUALLY.KEY] = {
      value: 1,
      isTouched: false,
    };
    defaultDragValues[DRAG_RATING_KEYS.INTERPERSONALLY.KEY] = {
      value: 1,
      isTouched: false,
    };
    defaultDragValues[DRAG_RATING_KEYS.SOCIALLY.KEY] = {
      value: 1,
      isTouched: false,
    };
    defaultDragValues[DRAG_RATING_KEYS.OVERALL.KEY] = {
      value: 1,
      isTouched: false,
    };
    // console.log(defaultDragValues);

    return defaultDragValues;
  }
};

/**
 * Hook to use drag rating component
 * @param localStorageKey: string - drag rating key
 */
export const useDragRating = (localStorageKey: string) => {
  const { profile } = useAuthContext();
  const userStorageKey = profile!.id + "_" + localStorageKey;
  const [dragValues, setDragValues] = useState<DragRatings>(() =>
    getInitialDragState(userStorageKey)
  );
  const [dragError, setDragError] = useState(false);
  const { setFeedbackFormTransaction } = useFeedbackForm();

  /**
   * Store dragRatings data into localStorage
   */
  const storeDragRatings = () => {
    localStorage.setItem(userStorageKey, JSON.stringify(dragValues));
  };

  /**
   * Removes current drag ratings stored in localStorage
   */
  const removeDragRatingsFromStorage = () => {
    localStorage.removeItem(userStorageKey);
  };

  /**
   * Updates FeedBackForm document in database with check-ins after the session
   */
  const updatesFeedbackFormWithDragRating = async () => {
    const newUserFeedback = {
      growthCircle: profile!.growthCircle,
      userId: profile!.id,
      userName: profile!.displayName,
    };

    const uniqueId = profile!.id + profile!.growthCircle;

    if (!!dragValues) {
      const dragRatings = {};
      //Converting array to number
      Object.keys(dragValues).forEach((dragRatingKey) => {
        const ratingValue = dragValues[dragRatingKey].value[0];
        dragRatings[dragRatingKey] = ratingValue;
      });
      newUserFeedback[LocalStorageKeys.REFLECTION.DRAG_RATINGS] = dragRatings;
    }

    await setFeedbackFormTransaction(uniqueId, newUserFeedback);
    // removeDragRatingsFromStorage();
  };

  /**
   * Returns true if there exists a slider that has not been touched by the user
   */
  const anyUntouched = (): boolean => {
    const isAllTouched = Object.keys(dragValues).reduce((previous, key) => {
      return dragValues[key].isTouched && previous;
    }, true);

    return !isAllTouched;
  };

  /**
   * Sets attribute to touched
   * @param key - key to to set to touched
   */
  const setKeyToTouched = (key) => {
    dragValues[key].isTouched = true;
    //console.debug(dragValues);
  };

  //Stores values in localStorage
  useEffect(() => {
    storeDragRatings();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [dragValues]);

  return {
    dragValues,
    setDragValues,
    anyUntouched,
    dragError,
    setDragError,
    setKeyToTouched,
    removeDragRatingsFromStorage,
    updatesFeedbackFormWithDragRating,
  };
};
