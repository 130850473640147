import { QueryConstraint, where, WithFieldValue } from "firebase/firestore";
import { Participant } from "interface/ParticipantInterface";
import getModelOperations, { WithId } from "utility/model";
import { FIRESTORE_PATH_ORGANISATIONS } from "./organisation";
import { defaultParticipantModel } from "./participant";

const FIRESTORE_SUBPATH_PARTICIPANT_HISTORY = 'participantHistory';

// --- Helper Functions ---

const ops = getModelOperations(
  defaultParticipantModel
);

function _setPastParticipant(
  organisationId: string,
  participantId: string,
  newParticipant: WithFieldValue<Participant>
) {
  const path = `${FIRESTORE_PATH_ORGANISATIONS}/${organisationId}/${FIRESTORE_SUBPATH_PARTICIPANT_HISTORY}/${participantId}`;
  return ops.setModel(path, newParticipant);
}

function _getPastParticipants(
  organisationId: string,
  ...queryConstraints: QueryConstraint[]
) {
  const path = `${FIRESTORE_PATH_ORGANISATIONS}/${organisationId}/${FIRESTORE_SUBPATH_PARTICIPANT_HISTORY}`;
  return ops.getModels(path, ...queryConstraints);
}

// --- End Helper functions ---

export function savePastParticipants(
  organisationId: string,
  participants: WithId<WithFieldValue<Participant>>[]
) {
  return Promise.all(participants.map(
    p => _setPastParticipant(organisationId, p.id, p)
  ))
}

export async function getPastParticipantsBySession(
  organisationId: string,
  sessionInstance: string
) {
  return _getPastParticipants(
    organisationId,
    where('sessionInstance', '==', sessionInstance)
  );
}
