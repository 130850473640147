import AutoResizeTextArea from "components/utility/Inputs/AutoResizeTextArea";
import useOrganisationContext from "hooks/organisation/useOrganisationContext";
import usePromise from "hooks/utility/usePromise";
import { FormEvent, useEffect, useState } from "react";
import {
  FaCheckCircle,
  FaPlusCircle,
  FaShapes,
  FaTimesCircle,
} from "react-icons/fa";
import classes from "../../../pages/AllCircles/Admin/Admin.module.css";
import { FaPlus } from "react-icons/fa6";
import Select from "react-select";
import makeAnimated from "react-select/animated";
import TemplateSelector from "./TemplateSelector";
import { useGrowthCirclesType } from "hooks/useGrowthCirlesType";
import toast from "react-hot-toast";
import { useAuthContext } from "hooks/useAuthContext";
import EmptySpace from "components/utility/EmptySpace";
import TextInputHelpers from "components/InputHelpers/TextInputHelpers";

interface DataStructure {
  name: string;
}

const AddNewType = () => {
  const { selectedOrganisation } = useOrganisationContext();
  const { profile } = useAuthContext();
  const {
    AddNewGrowthCircleType,
    AddNewGrowthCircleTypeByTemplate,
    getDataByLabelObject,
    getValuesByLabelObjectsArray,
    getValuesByLabelObjectsArrayOnArraySet,
    growthcircletypes,
  } = useGrowthCirclesType();
  const [title, setTitle] = useState("");
  const [remarks, setRemarks] = useState("");
  const [shortDescription, setShortDescription] = useState("");
  const [objectives, setObjective] = useState<string[]>([]);
  const { isLoading, resolve } = usePromise();
  const [whoForThis, setWhoForThis] = useState<string[]>([]);
  const [tags, setTags] = useState(null);
  const [subTags, setSubTags] = useState(null);
  const [targetAudience, setTargetAudience] = useState();
  const [selected, setSelected] = useState("new" || "template");
  const [errorTitle, setErrorTitle] = useState(false);
  const [subTagSelection, setSubTagSelection] = useState<
    { value: string; label: string }[]
  >([]);

  const [selectedItem, setSelectedItem] = useState<string | null>(null);
  const handleCardSelect = (title: string) => {
    setSelectedItem((prevSelectedItem) =>
      prevSelectedItem === title ? null : title
    );
  };

  const [tempObject, setTempObject] = useState("");

  const animatedComponents = makeAnimated();

  const whoIsData = [
    { name: "Individuals" },
    { name: "HR" },
    { name: "Leaders" },
    { name: "Wellness Ambassadors" },
    { name: "Mentors" },
    { name: "Mentees" },
    { name: "Student" },
    { name: "Teacher" },
    { name: "Employee" },
  ];

  const tagData = [
    { value: "Wellbeing", label: "Wellbeing" },
    { value: "Career & Purpose", label: "Career & Purpose" },
    { value: "People & Teams", label: "People & Teams" },
    { value: "Personal Development", label: "Personal Development" },
  ];

  const TargetAudienceData = [
    { value: "Schools", label: "Schools" },
    { value: "Corporate", label: "Corporate" },
    { value: "Community Centre", label: "Community Centre" },
  ];

  const getForThis = (whoOptions: DataStructure[]) => {
    return whoOptions.map((data) => ({ value: data.name, label: data.name }));
  };

  const getFormattedData = (datas: Array<Object>) => {
    return datas.map((data) => ({ value: data, label: data }));
  };

  const formattedSelectedRoles = getFormattedData(whoForThis);

  const handleChange = (selectedOptions) => {
    setWhoForThis(selectedOptions);
  };

  const handleTagChange = (selectedOptions) => {
    const updatedTags = tagData.filter((tag) => {
      // Check if the tag does not match the selected option
      return (
        tag.value !== selectedOptions.value &&
        tag.label !== selectedOptions.label
      );
    });
    setSubTagSelection(updatedTags);
    setTags(selectedOptions);
  };

  const handleSubTagChange = (selectedOptions) => {
    setSubTags(selectedOptions);
  };

  const handleTargetAudience = (selectedOptions) => {
    setTargetAudience(selectedOptions);
  };

  useEffect(() => {
    if (!selectedOrganisation || !profile) return;
  }, [selectedOrganisation, selected, profile]);

  const submitHandler = (e: FormEvent) => {
    e.preventDefault();
    const _tagData = getDataByLabelObject(tags, tagData);
    const _subTagData = getValuesByLabelObjectsArray(subTags, tagData);
    const _forThis = getValuesByLabelObjectsArrayOnArraySet(
      whoForThis,
      whoIsData
    );
    const _targetAudience = getValuesByLabelObjectsArray(
      targetAudience,
      TargetAudienceData
    );

    if (selected === "template" && selectedItem === null) {
      toast.error("Please select a template");
      return false;
    }

    if (errorTitle) {
      toast.error("Please specify a unique growthcircle name.");
      return false;
    }

    if (objectives.length > 0) {
      const data = {
        title,
        remarks,
        shortDescription,
        objectives,
        whoForThis: _forThis,
        tags: _tagData,
        subTags: _subTagData,
        targetAudience: _targetAudience,
        addedBy: profile?.uid,
      };

      if (selected === "template" && selectedItem !== "" && profile) {
        resolve(() =>
          AddNewGrowthCircleTypeByTemplate(data, selectedItem, profile)
        ).then(() => {
          toast.success("New GrowthCircles type was added successfully");
          setTitle("");
          setRemarks("");
          setShortDescription("");
          setObjective([]);
          setWhoForThis([]);
          setTags(null);
          setTargetAudience(undefined);
          setTempObject("");
        });
      } else {
        resolve(() => AddNewGrowthCircleType(data)).then(() => {
          toast.success("New GrowthCircles type was added successfully");
          setTitle("");
          setRemarks("");
          setShortDescription("");
          setObjective([]);
          setWhoForThis([]);
          setTags(null);
          setTargetAudience(undefined);
          setTempObject("");
        });
      }
    } else {
      toast.error("Please add objectives");
    }
  };

  const addObjective = () => {
    if (tempObject !== "") {
      setObjective([...objectives, tempObject]);
      setTempObject("");
    } else {
      toast.error("Please add an objective first");
    }
  };

  const removeObjective = (indexToRemove) => {
    const updatedObjectives = objectives.filter(
      (_, index) => index !== indexToRemove
    );

    setObjective(updatedObjectives);
  };

  const setTitleHandler = (title: string) => {
    // Convert the title to lowercase (or uppercase) for case-insensitive comparison
    const lowercaseTitle = title.toLowerCase();

    // Find the object in growthcircletypes based on the lowercase title
    const foundObject = growthcircletypes.find(
      (obj) => obj.name.toLowerCase() === lowercaseTitle
    );

    // Do something with the foundObject (e.g., update state or perform any other logic)
    if (foundObject) {
      setErrorTitle(true);
      setTitle(title);
    } else {
      // Set the title
      setTitle(title);
      setErrorTitle(false);
    }
  };

  return (
    <div className=" animate-fade">
      <form
        onSubmit={submitHandler}
        className="my-8 flex flex-col items-start gap-2"
        style={{ color: "var(--text-colour)" }}
      >
        <div className="flex ">
          <div className="cursor-pointer " onClick={() => setSelected("new")}>
            <p
              className="w-1/2 font-semibold"
              style={{ color: "var(--text-colour)" }}
            >
              Create New Growthcircles
            </p>
            <div
              className={`w-[150px] h-[150px] bg-red-400 flex justify-center items-center rounded-lg ${
                selected === "new" ? "border-[10px]" : ""
              }`}
              style={{
                borderColor: selected === "new" ? "#F9BB62" : "",
              }}
            >
              <FaPlus size={80} className="text-default" />
            </div>
          </div>
          <div
            className="cursor-pointer"
            onClick={() => setSelected("template")}
          >
            <p
              className="font-semibold "
              style={{ color: "var(--text-colour)" }}
            >
              Create New from <br />
              template
            </p>
            <div
              className={`w-[150px] h-[150px] bg-red-400 flex justify-center items-center rounded-lg ${
                selected === "template" ? "border-[10px]" : ""
              }`}
              style={{
                borderColor: selected === "template" ? "#F9BB62" : "",
              }}
            >
              <FaShapes size={80} className="text-default" />
            </div>
          </div>
        </div>
        {selected === "template" && (
          <TemplateSelector
            growthcircletypes={growthcircletypes}
            selectedItem={selectedItem}
            onSelect={handleCardSelect}
          />
        )}

        <div className="mt-2 w-full md:w-1/2">
          <TextInputHelpers text="Growthcirle Name" />
          <div className="flex gap-2 items-start">
            <div className="w-full">
              <input
                type="text"
                placeholder="Specify Type"
                value={title}
                onChange={(e: React.ChangeEvent<HTMLInputElement>) =>
                  setTitleHandler(e.target.value)
                }
                className={
                  "w-full !text-lg focus:border-gray-400 !px-8 !py-6 rounded-full placeholder:text-sm placeholder:text-slate-300"
                }
                required={true}
              />
              {errorTitle && title !== "" && (
                <p className="text-red-400">
                  The name already exists please try another unique name
                </p>
              )}
            </div>
            {!errorTitle && title !== "" && (
              <FaCheckCircle
                style={{ color: "var(--icon-colour-2)" }}
                size={35}
                className="mt-2"
              />
            )}
            {errorTitle && title !== "" && (
              <FaTimesCircle
                style={{ color: "var(--icon-colour-4)" }}
                size={35}
                className="mt-2"
              />
            )}
          </div>
        </div>

        <div className="mt-2 w-full md:w-1/2">
          {" "}
          <p className="text-md my-2 font-semibold">
            Description of Growthcircle
          </p>{" "}
          <div className="py-4 bg-white border border-gray-300 p-2 rounded-md">
            <AutoResizeTextArea
              isRequired={true}
              placeholder="Specify Short Description / Summary"
              className="border-none w-full text-slate-500 placeholder:text-slate-300"
              value={shortDescription}
              onChange={(value) => setShortDescription(value)}
            />
          </div>
        </div>

        <div className="mt-2 w-full md:w-1/2">
          <p className="text-md my-2 font-semibold">
            Objectives ( fill-up the text input and press the plus icon)
          </p>
          <div className="flex justify-between gap-2 items-center">
            <input
              type="text"
              value={tempObject}
              className={
                "w-full !text-lg focus:border-gray-400 !px-8 !py-6 rounded-full placeholder:text-sm placeholder:text-slate-300 focus:border-none"
              }
              placeholder="Specify objective..."
              onChange={(e) => setTempObject(e.target.value)}
            />
            <FaPlusCircle
              onClick={addObjective}
              style={{ color: "var(--icon-colour-2)" }}
              className="cursor-pointer"
              size={35}
            />
          </div>
          <div className="mt-2 px-5 text-xl">
            {objectives.map((obj, index) => (
              <div key={index} className="flex items-center">
                <p>{obj}</p>{" "}
                <FaTimesCircle
                  onClick={() => removeObjective(index)}
                  style={{
                    color: "var(--icon-colour-0)",
                    marginLeft: "0.5rem",
                    cursor: "pointer",
                  }}
                  size={20}
                />
              </div>
            ))}
          </div>
        </div>

        <div className="flex flex-col w-full md:w-1/2">
          <TextInputHelpers text="Who is this for?" />
          <Select
            components={animatedComponents}
            closeMenuOnSelect={false}
            isMulti
            options={getForThis(whoIsData)}
            defaultValue={formattedSelectedRoles}
            onChange={handleChange}
            required
          />
        </div>
        <div className="flex flex-col w-full md:w-1/2">
          <p className="text-md my-2 font-semibold">Tag</p>
          <Select
            components={animatedComponents}
            defaultValue={tags}
            options={tagData}
            onChange={handleTagChange}
            required
          />
        </div>
        <div className="flex flex-col w-full md:w-1/2">
          <p className="text-md my-2 font-semibold">Sub Tags</p>
          <Select
            components={animatedComponents}
            defaultValue={subTags}
            isMulti
            options={subTagSelection}
            onChange={handleSubTagChange}
            required
          />
        </div>
        <EmptySpace />
        <hr className="border-t w-full border-gray-400" />
        <div className="mt-2 w-full md:w-1/2">
          {" "}
          <p className="text-md my-2 font-semibold">
            Side Remarks (internal reference)
          </p>{" "}
          <div className="py-4 bg-white border border-gray-300  p-2 rounded-md ">
            <AutoResizeTextArea
              isRequired={true}
              placeholder="Side Remarks"
              className="border-none w-full text-slate-500 placeholder:text-slate-300"
              value={remarks}
              onChange={(value) => setRemarks(value)}
            />
          </div>
        </div>
        <div className="flex flex-col w-full md:w-1/2">
          <p className="text-md my-2 font-semibold">
            Target Audience(s) (internal reference)
          </p>
          <Select
            components={animatedComponents}
            closeMenuOnSelect={false}
            isMulti
            defaultValue={targetAudience}
            options={TargetAudienceData}
            onChange={handleTargetAudience}
            required
          />
        </div>
        <button
          className={`my-4 ${classes["button"]}`}
          disabled={isLoading}
          type="submit"
        >
          {isLoading ? "Submitting..." : "Submit"}
        </button>

        <hr className="border-t w-full border-gray-400" />
      </form>
    </div>
  );
};

export default AddNewType;
