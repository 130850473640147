import useOrganisationContext from "hooks/organisation/useOrganisationContext";
import { HierarchicalNames, HierarchicalState } from "hooks/useDashboard";
import { Dispatch, SetStateAction, useEffect, useState } from "react";
import DatePicker from "react-datepicker";
import "react-datepicker/dist/react-datepicker.css";
import { AiFillCloseCircle } from "react-icons/ai";
import { BsFilterCircleFill } from "react-icons/bs";
import classes from "../Admin.module.css";
import DashboardFilterInputs from "./DashboardFilterInputs";

interface Props {
  options: string[];
  startDate?: Date;
  setStartDate?: Dispatch<SetStateAction<Date>>;
  endDate?: Date;
  setEndDate?: Dispatch<SetStateAction<Date>>;
  profiles?: boolean[];
  setProfiles?: Dispatch<SetStateAction<Array<boolean>>>;
  allProfiles?: string[];
  groups?: HierarchicalState[];
  setGroups?: Dispatch<SetStateAction<Array<HierarchicalState>>>;
  allGroups?: HierarchicalNames[];
}

/**
 * Filter header component for the dashboard
 *
 * @param {object} props The props of the component
 * @param {string[]} props.options The options for the filter
 * @param {Date} props.startDate The start date of the filter
 * @param {Dispatch<SetStateAction<Date>>} props.setStartDate The set start date function
 * @param {Date} props.endDate The end date of the filter
 * @param {Dispatch<SetStateAction<Date>>} props.setEndDate The set end date function
 * @returns {JSX.Element} The filter header component
 */
const FilterHeader = ({
  options,
  startDate,
  setStartDate,
  endDate,
  setEndDate,
  profiles,
  setProfiles,
  allProfiles,
  groups,
  setGroups,
  allGroups,
}: Props) => {
  const { selectedOrganisation, selectOrganisationByName } =
    useOrganisationContext();

  const [showFilter, setShowFilter] = useState(false);

  useEffect(() => {
    if (!selectedOrganisation) return;
  }, [selectedOrganisation]);

  const onChangeHandler = (value: string) => {
    selectOrganisationByName(value);
  };

  const onProfileChangeHandler = (e: React.ChangeEvent<HTMLInputElement>, index: number) => {
    const { checked } = e.target;
    setProfiles?.((prev) => {
      const newSelection = [...prev];
      newSelection[index] = checked;
      return newSelection;
    });
  };

  const onGroupChangeHandler = (
    e: React.ChangeEvent<HTMLInputElement>,
    index: number,
    subIndex: number
  ) => {
    const { checked } = e.target;
    if  (subIndex === -1) { // group
      setGroups?.((prev) => {
        const newSelection = [...prev];
        newSelection[index].state = checked;

        // If checked is true, set all sub-groups to true. Else, set all sub-groups to false.
        if (checked) {
          newSelection[index].subGroupStates.fill(true);
        } else {
          newSelection[index].subGroupStates.fill(false);
        }

        return newSelection;
      });
    } else { // sub group
      setGroups?.((prev) => {
        const newSelection = [...prev];
        newSelection[index].subGroupStates[subIndex] = checked;

        // If all sub-groups are false, set parent group to false
        const allSubGroupsFalse = newSelection[index].subGroupStates.some(state => state === false);
        if (allSubGroupsFalse) {
          newSelection[index].state = false;
        }

        // If all sub-groups are true, set parent group to true
        const allSubGroupsTrue = newSelection[index].subGroupStates.every(state => state === true);
        if (allSubGroupsTrue) {
          newSelection[index].state = true;
        }

        return newSelection;
      });
    }
  };

  return (
    <div className="text-slate-600 w-full flex gap-4">
      <div className="flex mt-1 relative">
        <BsFilterCircleFill
          size={30}
          className="cursor-pointer"
          onClick={() => setShowFilter(!showFilter)}
          style={{ color: "var(--icon-colour-0)" }}
        />
        <div
          className={`bg-white w-[450px] min-h-[343px] shadow-md absolute top-5 left-8 border-2 rounded-xl p-5 z-1000 ${
            showFilter ? "" : "hidden"
          }`}
          style={{ borderColor: "var(--icon-colour-0)" }}
        >
          <AiFillCloseCircle
            size={25}
            className="absolute top-2 right-2 cursor-pointer"
            style={{ color: "var(--icon-colour-0)" }}
            onClick={() => setShowFilter(!showFilter)}
          />

          <DashboardFilterInputs
            type="select"
            options={options}
            onChange={onChangeHandler}
            label="Organisation"
            initialSelected={selectedOrganisation?.name}
          />
          <DashboardFilterInputs
            type="checkbox"
            options={allProfiles}
            onChange={onProfileChangeHandler}
            label="Profile Type"
            initialMultipleSelected={profiles}
          />
          <DashboardFilterInputs
            type="checkbox"
            optionsHierarchical={allGroups}
            onChange={onGroupChangeHandler}
            label="Group"
            initialMultipleSelectedHierarchical={groups}
          />
          <DatePicker
            className="bg-default font-semibold text-main-colour px-2 w-auto rounded-full"
            wrapperClassName="w-auto mt-6 flex items-center justify-between"
            dateFormat="dd/MM/yyyy"
            maxDate={endDate ?? new Date()}
            selected={startDate ?? new Date()}
            onChange={setStartDate ? (date: Date | null) => {
              const defaultStartDate = new Date(new Date().getFullYear(), new Date().getMonth(), 1); 
              setStartDate(date ?? defaultStartDate);
            } : () => {}}
          />
          <p className="w-auto font-semibold text-main-colour text-lg text-center">
            To
          </p>
          <DatePicker
            className="bg-default font-semibold text-main-colour px-2 w-auto rounded-full"
            wrapperClassName="w-auto flex items-center justify-between"
            dateFormat="dd/MM/yyyy"
            minDate={startDate ?? new Date()}
            selected={endDate ?? new Date()}
            onChange={setEndDate ? (date: Date | null) => {
              const defaultEndDate = new Date((new Date(Date.UTC(new Date().getFullYear(), new Date().getMonth() + 1, 1))).getTime() - 82800000); 
              setEndDate(date ? new Date(date) : defaultEndDate);
            } : () => {}}
          />
          <div className="my-4 mx-auto flex justify-center">
            <button
              type="button"
              onClick={() => setShowFilter(!showFilter)}
              className={`${classes["button"]}`}
            >
              Close
            </button>
          </div>
        </div>
      </div>
      <div>
        <div className="flex gap-8 text-xl">
          <p>
            <strong>Organisation :</strong> {selectedOrganisation?.name}
          </p>
          <p>
            <strong>Profile Type :</strong>{" "}
            <span className="text-nowrap">
            {(() => {
              const countTrue = profiles?.filter(
                (profile) => profile === true
              ).length;

              if (countTrue === profiles?.length) {
                return "All";
              } else if (countTrue === 0) {
                return "None";
              } else if (countTrue === 1) {
                const index = profiles?.indexOf(true);
                return allProfiles && index ? allProfiles[index] : "Unknown";
              } else {
                return "Multiple";
              }
            })()}
            </span>
          </p>
          <p>
            <strong>Group :</strong>{" "}
            <span className="text-nowrap">
            {(() => {
              const countTrue = groups?.filter(
                (group) => group.state === true
              ).length;

              const subGroups = groups?.filter(
                (group) =>
                  group.subGroupStates?.filter((subGroup) => subGroup === true)
                    .length > 0
              );

              const countSubGroups: number =
                subGroups?.length ?? 0;

              if (countTrue === groups?.length) {
                return "All";
              } else if (countTrue === 0) {
                if (countSubGroups > 1) {
                  return "Multiple";
                } else if (countSubGroups === 1) {
                  const group = groups?.find(
                    (group) =>
                      group.subGroupStates?.filter(
                        (subGroup) => subGroup === true
                      ).length > 0
                  );
                  if (!group) return "Unknown";
                  const index = groups?.indexOf(group);
                  return allGroups && index !== undefined ? allGroups[index].name : "Unknown";
                }
                return "None";
              } else if (countTrue === 1) {
                const group = groups?.find((group) => group.state === true);
                if (!group) return "Unknown";
                const index = groups?.indexOf(group);
                return allGroups && index !== undefined ? allGroups[index].name : "Unknown";
              } else {
                return "Multiple";
              }
            })()}
            </span>
          </p>
          <p>
            <strong>Defined Period :</strong>{" "}
            {startDate ? startDate.toDateString() : new Date().toDateString()}{" "}
            to {endDate ? endDate.toDateString() : new Date().toDateString()}
          </p>
        </div>

        <p className="py-2 text-lg">
          Click on the filter icon on the left to filter data by period, type of
          participant and group.
        </p>
      </div>
    </div>
  );
};

export default FilterHeader;
