import { isObject } from "./typePredicates";

interface LocalStorageOperations<T> {
  setItem: (item: T) => void
  getItem: () => T | null
  updateItem: (itemUpdates: Partial<T>) => void
  removeItem: () => void
}

export default function getLocalStorageOperations<T extends {}>(
  key: string,
  padMissingData: ((rawData: {}) => T)
): LocalStorageOperations<T> {

  function setItem(item: T) {
    return localStorage.setItem(key, JSON.stringify(item));
  }

  /**
   * Retrieves padded localstorage data if exists, null otherwise.
   * Pads missing local storage data with default values.
   *
   * @returns Padded data.
   */
  function getItem() {
    const rawData = localStorage.getItem(key);
    const data = rawData ? JSON.parse(rawData) : null;
    if (!isObject(data)) {
      return null;
    }
    return padMissingData(data);
  }

  function updateItem(itemUpdates: Partial<T>) {
    const item = getItem();
    setItem(padMissingData({
      ...item,
      ...itemUpdates
    }));
  }

  function removeItem() {
    localStorage.removeItem(key);
  }

  return {
    setItem,
    getItem,
    updateItem,
    removeItem
  }
}
