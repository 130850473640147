import { SelfAwarenessToolInterface } from "interface/SelfAwarenessToolInterface";

import { BackAndNextProps } from "../SelfAwarenessComponentMapping";
import { useEffect, useState } from "react";
import ValueRiskQuestion from "../SubComponents/ValueRiskQuestion";
import ValueRiskGrading from "../SubComponents/ValueRiskGrading";

interface ValueRiskProps {
  journal: SelfAwarenessToolInterface;
  backAndNextHandler: BackAndNextProps;
  setHasModal: (b: boolean) => any;
  option: string;
  nexHandler: (e: React.MouseEvent<HTMLButtonElement, MouseEvent>) => void;
  backHandler: () => void;
}
const ValueRiskComps = ({
  journal,
  backAndNextHandler,
  setHasModal,
  option,
  nexHandler,
  backHandler,
}: ValueRiskProps) => {
  const [compIndex, setCompIndex] = useState<number>(0);

  const componentMap = [
    ValueRiskQuestion,
    ValueRiskQuestion,
    ValueRiskQuestion,
    ValueRiskQuestion,
    ValueRiskGrading,
  ];

  const backOptionHandler = () => {
    if (compIndex > 0) {
      setCompIndex((prevIndex) => prevIndex - 1);
    } else {
      backHandler();
    }
  };

  const nextOptionHandler = (
    e: React.MouseEvent<HTMLButtonElement, MouseEvent>
  ) => {
    if (compIndex < componentMap.length - 1) {
      setCompIndex((prevIndex) => prevIndex + 1);
    } else {
      nexHandler(e);
    }
  };

  const CurrentComponent = componentMap[compIndex];

  const questionList = [
    `What do you value most about ${option}?`,
    `What is the potential risk of taking ${option}?`,
    `What is the impact of ${option} on your resources?`,
    `What will be the potential regret of not taking ${option}?`,
    `To what extent does ${option} meet your need?`,
  ];

  useEffect(() => {
    setCompIndex(0);
  }, [option]);

  return (
    <>
      <CurrentComponent
        option={option}
        nextHandler={(e) => nextOptionHandler(e)}
        journal={journal}
        handler={() => {}}
        backAndNextHandler={backAndNextHandler}
        question={questionList[compIndex]}
        backOptionHandler={backOptionHandler}
      />
    </>
  );
};

export default ValueRiskComps;
