import { createContext, useEffect } from "react";
import { useAuthContext } from "hooks/useAuthContext";
import { GrowthCircleSession } from "interface/GrowthCircleSessionInterface";
import useSubscribeModel from "hooks/useSubscribeModel";
import { subscribeGCSession } from "models/growthCircleSession";
import { OrganisationContextProvider } from "./OrganisationContext";
import { OrganisationRoleContextProvider } from "./OrganisationRoleContext";
import useOrganisationContext from "hooks/organisation/useOrganisationContext";
import { useGrowthCircleContext } from "hooks/useGrowthCircleContext";
import useOrganisationRoleContext from "hooks/organisation/useOrganisationRoleContext";
import { getRoleByUserID } from "models/organisationRole";
import { ThemeContextProvider } from "./ThemeContext";
import { PermissionContextProvider } from "./PermissionContext";

interface Props {
  children: React.ReactNode;
}

/**
 * Wrapper around the growth circle pages to provide context on organisation and organistion role.
 * @param children Children node.
 * @returns TempChildrenWrapper.
 */
function TempChildrenWrapper({ children }: Props) {
  const { user, profile } = useAuthContext();

  const { growthCircleSession } = useGrowthCircleContext();
  const { organisations, selectedOrganisation, selectOrganisationByName } =
    useOrganisationContext();
  const { roles, selectRoleByName } = useOrganisationRoleContext();

  useEffect(() => {
    if (!profile) return;
    const orgName = growthCircleSession?.organisation;

    if (organisations.length === 0) {
      return;
    }
    // Retrieve the last organisation from the profile invitation or from localStorage
    const lastOrg = profile.organisationInvite;

    // Find the organisation in the organisations array by matching the organisation name
    const result = organisations.find((org) => org.name === orgName);

    // Check if the organisation name exists and a matching organisation was found
    if (orgName && result) {
      // If a match is found, select the organisation by name
      selectOrganisationByName(orgName);
    } else {
      // If no match is found and there's a previously stored organisation (lastOrg), select it
      if (lastOrg) {
        selectOrganisationByName(lastOrg);
      }
    }

    // eslint-disable-next-line
  }, [organisations, growthCircleSession?.organisation, selectedOrganisation]);

  useEffect(() => {
    if (!user?.uid || !selectedOrganisation) {
      return;
    }

    const role = getRoleByUserID(user.uid, selectedOrganisation, roles);

    if (!role) {
      return;
    }
    selectRoleByName(role.name);
  }, [roles, selectRoleByName, selectedOrganisation, user?.uid]);

  return <>{children}</>;
}

/**
 * Wrapper around OrganisationRoleContextProvider to read
 * context of current selected organisation.
 *
 * @param children Children node.
 * @returns TempWrappedRoleContext component.
 */
function TempWrappedRoleContext({ children }: Props) {
  const { selectedOrganisation } = useOrganisationContext();

  return (
    <OrganisationRoleContextProvider
      organisationId={selectedOrganisation?.id ?? ""}
    >
      <ThemeContextProvider>{children}</ThemeContextProvider>
    </OrganisationRoleContextProvider>
  );
}

interface growthCircleStructure {
  growthCircleSession: GrowthCircleSession | undefined;
}

export const GrowthCircleContext = createContext<growthCircleStructure>({
  growthCircleSession: undefined,
});

/**
 * Provides the context of the current growthcircle session, organisation and role.
 *
 * @param children Children node.
 * @returns
 */
export const GrowthCircleContextProvider: React.FC<Props> = ({ children }) => {
  const { profile } = useAuthContext();

  const growthCircleSession = useSubscribeModel(
    profile?.growthCircle ?? "",
    subscribeGCSession
  );
  return (
    <GrowthCircleContext.Provider value={{ growthCircleSession }}>
      <OrganisationContextProvider>
        <PermissionContextProvider>
          <TempWrappedRoleContext>
            <TempChildrenWrapper>{children}</TempChildrenWrapper>
          </TempWrappedRoleContext>
        </PermissionContextProvider>
      </OrganisationContextProvider>
    </GrowthCircleContext.Provider>
  );
};
