import EmptySpace from "components/utility/EmptySpace";
import classes from "./SearchBar.module.css";

// interface Props {
//   setSearchWord: React.Dispatch<React.SetStateAction<String>>;
// }

const SearchBar = ({ setSearchWord }) => {
  return (
    <div className={classes["search-bar-container"]}>
      <strong className={classes["title"]}>Resources</strong>
      <EmptySpace height={"10px"} />
      <form className={classes["search-bar-form"]}>
        <input
          className={classes["search-bar-input"]}
          placeholder={"Search"}
          onChange={(e) => {
            setSearchWord(e.target.value);
          }}
        ></input>
      </form>
    </div>
  );
};

export default SearchBar;
