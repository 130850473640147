import { getModelOperationsWithPath } from "../utility/model";
import { GCJournal } from "interface/GCJournal";
import {
  DocumentData,
  DocumentReference,
  limit,
  orderBy,
  UpdateData,
  where,
  WithFieldValue,
} from "firebase/firestore";
import firebase from "firebase/compat/app";
import { GrowthCircleSession } from "interface/GrowthCircleSessionInterface";
import { Participant } from "interface/ParticipantInterface";
import { defaultOrganisation } from "interface/OrganisationInterface";

const FIRESTORE_PATH_JOURNALS = "GCJournals";

export const defaultJournalModel: GCJournal = {
  category: "",
  createdAt: firebase.firestore.Timestamp.now(),
  organisation: defaultOrganisation.name,
  sessionInstance: "",
  growthCircle: "",
  participant: "",
  intentions: "",
  myQuestions: true,
  notes: "",
  othersQuestions: true,
  reflection: "",
  userId: "",
  path: "",
  role: null,
  topic: "",
  reflectionQuestion: [],
  personalReflectionQuestion: [],
  compulsoryReflectionQuestion: [],
  reflectionQuestion1: "",
  reflectionQuestion2: "",
  imageUrls: [],
};

// --- Helper Functions ---

const ops = getModelOperationsWithPath(
  FIRESTORE_PATH_JOURNALS,
  defaultJournalModel
);

const _addJournal: (
  newJournal: WithFieldValue<GCJournal>
) => Promise<DocumentReference<DocumentData> | undefined> = ops.addModel;

/*
  setModel: (docId: string, newData: WithFieldValue<T>, setOptions?: SetOptions) => Promise<void>,
  getModel: (docId: string) => Promise<T | undefined>,
*/

const _getJournals = ops.getModels;

const _getJournalWhere = ops.getModelWhere;

const _updateJournal: (
  journalId: string,
  JournalUpdates: UpdateData<GCJournal>
) => Promise<void> = ops.updateModel;
/*
  deleteModel: (docId: string) => Promise<void>,
  subscribeModel: (docId: string, onStoreChange: OnStoreChange<WithId<T> | undefined>) => Unsubscribe,
  subscribeModels: (
    onStoreChange: OnStoreChange<WithId<T>[]>,
    ...queryConstraints: QueryConstraint[]
  ) => Unsubscribe
*/

// --- End Helper functions ---

/**
 * Get the first journal with the given participant id.
 * Participant Id is stored as the field 'growthCircle'
 *
 * @param participantId Id of the participant.
 * @returns The Journal if exists, undefined otherwise.
 */
export function getJournalByParticipantId(participantId: string) {
  return _getJournalWhere(where("growthCircle", "==", participantId));
}

export function getJournalsByUserId(userId: string, organisation?: string) {
  const conditions = [where("userId", "==", userId)];
  if (organisation) {
    conditions.push(where("organisation", "==", organisation));
  }

  return _getJournals(...conditions, orderBy("createdAt", "desc"), limit(15));
}

/**
 * Updates the feedback given to a particular Journal
 * @param journalId Id of the journal.
 * @param feedback Updated feedback.
 */
export function updateJournalFeedback(journalId: string, feedback: string) {
  return _updateJournal(journalId, { feedback });
}

interface SaveJournalOptions {
  saveIntention: boolean;
  reflection: boolean;
  notes: boolean;
  myQuestions: boolean;
  othersQuestions: boolean;
  savePicture: boolean;
}

export async function saveJournal(
  growthCircleSession: GrowthCircleSession,
  participant: Participant,
  options?: SaveJournalOptions
) {
  if (!options) {
    options = {
      saveIntention: true,
      reflection: true,
      notes: true,
      myQuestions: true,
      othersQuestions: true,
      savePicture: true,
    };
  }
  const journal: GCJournal = {
    ...defaultJournalModel,
    createdAt: firebase.firestore.Timestamp.now(),
    userId: participant.userId,
    category: growthCircleSession?.type,
    organisation: growthCircleSession?.organisation ?? defaultOrganisation.name,
    sessionInstance: growthCircleSession?.sessionInstance ?? "",
    growthCircle: participant.id,
    participant: participant.id,
    role: participant.role ?? null,
    topic: participant.activityTitle ?? participant.topic ?? "",
    path: participant.activity ?? participant.path ?? "",

    myQuestions: options.myQuestions,
    othersQuestions: options.othersQuestions,
    compulsoryReflectionQuestion:
      participant.compulsoryReflectionQuestion ?? [],
    reflectionQuestion:
      participant.ngTopicRandomQuestion ?? participant.reflectionQuestion,
    personalReflectionQuestion:
      participant.ngPersonalRandomQuestion ??
      participant.personalReflectionQuestion ??
      "",
    reflectionQuestion1: participant.reflectionQuestion1 || "",
    reflectionQuestion2: participant.reflectionQuestion2 || "",
  };

  if (options.saveIntention) {
    journal.intentions = participant.intentions ?? "";
  }
  if (options.reflection) {
    journal.reflection = participant.reflection || "";
  }
  if (options.notes) {
    journal.notes = participant.notes || "";
  }
  if (options.savePicture) {
    journal.imageUrls = participant.imageUrls ?? [];
  }

  const existingJournal = await _getJournalWhere(
    where("userId", "==", journal.userId),
    where("growthCircle", "==", journal.growthCircle)
  );
  if (existingJournal) {
    return _updateJournal(existingJournal.id, journal);
  } else {
    return _addJournal(journal);
  }
}
