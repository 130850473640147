import { useEffect, useState } from "react";
import { projectFirestore } from "../firebase/config";
import {
  collection,
  query,
  where,
  limit,
  startAfter,
  getDocs,
  QueryDocumentSnapshot,
  DocumentData,
  orderBy,
} from "firebase/firestore";

export const useGrowthCircles = (type?: string) => {
  const [growthCircles, setGrowthCircles] = useState<
    QueryDocumentSnapshot<DocumentData>[]
  >([]);
  const [lastVisible, setLastVisible] =
    useState<QueryDocumentSnapshot<DocumentData> | null>(null);
  const [hasMore, setHasMore] = useState<boolean>(true);
  useEffect(() => {
    const fetchGrowthCircles = async () => {
      const q = projectFirestore
        .collection("GrowthCircles")
        .where("type", "==", type)
        .where("lastSession", "!=", "")
        .orderBy("lastSession", "desc")
        .limit(8);

      const querySnapshot = await getDocs(q);

      const docSnapshots =
        querySnapshot.docs as QueryDocumentSnapshot<DocumentData>[];
      setGrowthCircles(docSnapshots);

      if (docSnapshots.length > 0) {
        setLastVisible(docSnapshots[docSnapshots.length - 1]);
        setHasMore(true);
      } else {
        setLastVisible(null);
        setHasMore(false);
      }
    };
    if (type) fetchGrowthCircles();
  }, [type]);

  const getGCDetailsById = async (id: string) => {
    try {
      const querySnapshot = await projectFirestore
        .collection("GrowthCircles")
        .where("uid", "==", id)
        .get();

      if (!querySnapshot.empty) {
        // If the query has results, return the first document found
        return querySnapshot.docs[0].data();
      } else {
        // If no matching documents found, return null or throw an error
        return null; // or throw new Error("No matching document found");
      }
    } catch (error) {
      console.error("Error fetching GC details by ID:", error);
      throw error;
    }
  };

  const loadMoreGrowthCircles = async () => {
    if (lastVisible && type) {
      const next = query(
        collection(projectFirestore, "GrowthCircles"),
        where("type", "==", type),
        where("lastSession", "!=", ""),
        orderBy("lastSession", "desc"),
        startAfter(lastVisible),
        limit(8)
      );

      const querySnapshot = await getDocs(next);
      const newDocSnapshots =
        querySnapshot.docs as QueryDocumentSnapshot<DocumentData>[];
      setGrowthCircles((prevGrowthCircles) => [
        ...prevGrowthCircles,
        ...newDocSnapshots,
      ]);

      if (newDocSnapshots.length > 0) {
        setLastVisible(newDocSnapshots[newDocSnapshots.length - 1]);
        setHasMore(true);
      } else {
        setHasMore(false);
      }
    }
  };

  const getAllGc = async (type: string) => {
    try {
      const snapshot = await projectFirestore
        .collection("GrowthCircles")
        .where("type", "==", type)
        .where("lastSession", "!=", "")
        .orderBy("lastSession", "desc")
        .get();

      const growthCircles = snapshot.docs.map((doc) => ({
        ...doc.data(),
      }));

      return growthCircles;
    } catch (error) {
      console.error("Error fetching Growth Circles:", error);
      throw error; // Optionally re-throw the error to handle it upstream
    }
  };

  return {
    growthCircles,
    loadMoreGrowthCircles,
    hasMore,
    getGCDetailsById,
    getAllGc,
  };
};
