import { useContext } from "react";
import { OrganisationRoleContext } from "context/OrganisationRoleContext";

const ERR_NO_PROVIDER = "useOrganisationRoleContext must be used inside an OrganisationRoleContextProvider";

/**
 * Provides the context for the roles of the organisation and the currently selected role.
 *
 * @returns OrganisationRoleContext.
 */
export default function useOrganisationRoleContext() {

  const context = useContext(OrganisationRoleContext);

  if (!context) {
    throw Error(ERR_NO_PROVIDER);
  }

  return context;
}
