import { useEffect, useState } from "react";
import SliderGrading from "./SliderGrading";

const WorstItem = ({ item }) => {
  const [value, setValue] = useState(5);

  useEffect(() => {
    const storedJournal = localStorage.getItem("SelfAwarenessJournal");
    const existingJournal = storedJournal ? JSON.parse(storedJournal) : {};

    // Update or add the grade for the current item
    const updatedTopSelectionItem = {
      ...existingJournal.worstGrading,
      [item]: value,
    };

    // Update existingJournal with the latest worstItem
    const updatedJournal = {
      ...existingJournal,
      worstGrading: updatedTopSelectionItem,
      // other properties of SelfAwarenessJournal...
    };

    // Save the updated SelfAwarenessJournal to local storage
    localStorage.setItem(
      "SelfAwarenessJournal",
      JSON.stringify(updatedJournal)
    );
  }, [value, item]);

  return (
    <div
      className="p-5 w-full"
      style={{
        color: "var(--text-colour)",
      }}
    >
      <p className="font-semibold">{item}</p>
      <SliderGrading withBackground={true} value={value} setValue={setValue} />
    </div>
  );
};

export default WorstItem;
