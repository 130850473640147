import Header from "components/Header/Header";
import SimpleLoader from "components/Loaders/SimpleLoader";

import ContentWrapper from "components/utility/ContentWrapper";
import PageWrapper from "components/utility/PageWrapper";
import { useSpecialThanks } from "hooks/useSpecialThanks";
import { Suspense, lazy } from "react";

const SpecialThanksList = lazy(
  () => import("components/SpecialThanks/SpecialThanksList")
);

const MyGrowth = () => {
  const { mySpecialThanks } = useSpecialThanks();

  return (
    <PageWrapper>
      {" "}
      <Header />
      <ContentWrapper hasFiveStepper={true}>
        <div className="w-full mb-[100px] flex flex-col justify-center items-center">
          <p
            className="text-3xl font-semibold"
            style={{ color: "var(--main-colour)" }}
          >
            My Growth
          </p>
          <p>Overall data collected over all circles attended</p>

          <div className="bg-white my-4 w-full rounded-lg">
            <div
              style={{ borderColor: "var(--main-colour)" }}
              className="px-5 py-2 font-semibold border-b-2"
            >
              <p>Special Thanks</p>
            </div>

            <Suspense fallback={<SimpleLoader />}>
              <SpecialThanksList mySpecialThanks={mySpecialThanks} />
            </Suspense>
          </div>
        </div>
      </ContentWrapper>
    </PageWrapper>
  );
};

export default MyGrowth;
