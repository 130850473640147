import React from "react";

import classes from "./OtherTopics.module.css";

import Box from "components/utility/Box/Box";
import EmptySpace from "components/utility/EmptySpace";

import useGetParticipants from "hooks/useGetParticipants";
import { useAuthContext } from "hooks/useAuthContext";

import { OTHERS_TOPIC_TITLE } from "strings/ViewChooseTopic";
import { IoMdArrowDroprightCircle } from "react-icons/io";

interface OtherTopicProps {
  name: string;
  topic: string;
}

const OtherTopic: React.FC<OtherTopicProps> = ({ name, topic }) => {
  return (
    <div className="mt-2 flex gap-1 justify-start items-center w-full px-4">
      <p className={"w-28 overflow-hidden text-ellipsis"}>{name}</p>
      <IoMdArrowDroprightCircle
        size={30}
        color={"var(--main-colour)"}
        fill={"var(--main-colour)"}
      />
      <p className={""}>{topic}</p>
    </div>
  );
};

const OtherTopics = () => {
  const { participants } = useGetParticipants();
  const { profile } = useAuthContext();

  if (!participants || !profile) return null;
  return (
    <Box width={"90%"} backgroundColor={"var(--secondary-colour-2-60)"}>
      <EmptySpace height={"5px"} />
      <Box
        backgroundColor={"var(--main-colour)"}
        width={"90%"}
        padding={"3px"}
        borderRadius={"20px"}
      >
        <strong className={classes["selected-topics-title"]}>
          {OTHERS_TOPIC_TITLE}
        </strong>
      </Box>
      <EmptySpace height={"10x"} />
      <div className="flex justify-start items-start flex-col">
        {participants.map((p) => {
          const userId = p.userId;
          const profileId = profile.uid;
          if (userId === profileId) return null;
          return <OtherTopic key={userId} name={p.userName} topic={p.topic} />;
        })}
      </div>
    </Box>
  );
};

export default OtherTopics;
