import React from "react";
import classes from "./RoleFrontTop.module.css";
import RolesIconMap from "assets/Roles/RolesIconMap";
import { useAuthContext } from "hooks/useAuthContext";
import EmptySpace from "components/utility/EmptySpace";
import { ReactComponent as InfoButton } from "assets/InfoButton.svg";
import { LEVEL_LABEL, PLAYED_LABEL } from "strings/ChooseRoles";
import ProgressBarCard from "components/ChooseRoles/ProgressBar/ProgressBarCard";

interface Props {
  setIsFlipped: React.Dispatch<React.SetStateAction<boolean>>;
  role: string;
  coreSkill: string;
}

interface CoreSkillIconProps {
  fill: string;
  width?: string;
  height?: string;
}

/**
 * Renders the top half of RoleFront
 *
 * @param setIsFlipped state setter to flip the card
 * @param role role name
 * @param coreSkill core skill needed to get the icon
 * @returns
 */
const RoleFrontTop: React.FC<Props> = ({ setIsFlipped, role, coreSkill }) => {
  const onClickInfo = (e: React.MouseEvent<HTMLButtonElement>) => {
    e.stopPropagation();
    setIsFlipped(true);
  };

  const CoreSkillIcon: React.FC<CoreSkillIconProps> =
    RolesIconMap[coreSkill as keyof object];

  const { profile } = useAuthContext();

  if (!profile) return null;

  const { progressPercentage, level, numOfTimesPlayed } =
    profile.progress[role];

  return (
    <div className={classes["role-header"]}>
      <div className={classes["progress-bar-container"]}>
        <ProgressBarCard progress={progressPercentage} />
      </div>
      <div className={classes["role-header-inner"]}>
        <div
          className={`${classes["role-icon"]} flex justify-center items-center`}
        >
          <CoreSkillIcon fill={"var(--main-colour)"} />
          <EmptySpace height={"0px"} />
        </div>
        <div className={classes["role-info"]}>
          <div className={classes["role-name"]}>
            <strong>{role}</strong>
            <button onClick={onClickInfo}>
              <InfoButton fill={"var(--main-colour)"} />
            </button>
          </div>
          <div className={classes["role-stats"]}>
            <strong className={classes["level"]}>
              {LEVEL_LABEL}
              {level}
            </strong>
            <strong className={classes["played"]}>
              {PLAYED_LABEL}
              {numOfTimesPlayed}
            </strong>
          </div>
        </div>
      </div>
    </div>
  );
};

export default RoleFrontTop;
