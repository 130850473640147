export type JournalContent = {
  id: string;
  name: string;
  text: string;
  style: string;
  subContent?: JournalContent[];
};

export const saveIntentionContent: JournalContent = {
  id: "intentions",
  name: "saveIntention",
  text: "Save Intention",
  style: "bold",
};

export const reflectContent: JournalContent = {
  id: "reflectAll",
  name: "reflectAll",
  text: "Reflect",
  style: "semibold",
  subContent: [
    {
      id: "reflection",
      name: "reflection",
      text: "Reflection",
      style: "medium",
    },
    {
      id: "notes",
      name: "notes",
      text: "Notes",
      style: "medium",
    },
  ],
};

export const shareAndSupportContent: JournalContent = {
  id: "shareAndSupportAll",
  name: "shareAndSupportAll",
  text: "Share and Support",
  style: "semibold",
  subContent: [
    {
      id: "myQuestions",
      name: "myQuestions",
      text: "My Questions",
      style: "medium",
    },
    {
      id: "othersQuestions",
      name: "othersQuestions",
      text: "Other's Questions",
      style: "medium",
    },
  ],
};

export const skillsDescriptionContent: JournalContent = {
  id: "skillsDescriptionAll",
  name: "skillsDescriptionAll",
  text: "Skills Description",
  style: "semibold",
  subContent: [
    {
      id: "myRole",
      name: "myRole",
      text: "My Role",
      style: "medium",
    },
    {
      id: "othersRoles",
      name: "othersRoles",
      text: "Other's Roles",
      style: "medium",
    },
  ],
};

// const JournalContent = [saveIntentionContent, reflectContent, shareAndSupportContent, skillsDescriptionContent];

// export default JournalContent;
