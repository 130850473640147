export const standardReflectionQuestionsTourSteps = [
  {
    selector: '[reflection-questions-settings-tour="copy-button"]',
    content: `You can copy reflection questions from another organization or growth circle`,
  },
  {
    selector: '[reflection-questions-settings-tour="question-type"]',
    content: `Choose whether the question type should be tailored to specific topics or a mixture of personal and general questions across all topics`,
  },
  {
    selector: '[reflection-questions-settings-tour="advance-options"]',
    content: `There are additional options to select based on the selected question type`,
  },
];

export const specificReflectionQuestionsTourSteps = [
  ...standardReflectionQuestionsTourSteps,
  {
    selector: '[reflection-questions-settings-tour="add-question"]',
    content: `You can add questions to the selected topic/category`,
  },
  {
    selector:
      '[reflection-questions-settings-tour="category-question-checkbox"]',
    content: `Use this checkbox to add question to a category`,
  },
  {
    selector: '[reflection-questions-settings-tour="domain-questions"]',
    content: `You can add question that you have created to domains or copy questions from a domain`,
  },
];

export const randomReflectionQuestionsTourSteps = [
  ...standardReflectionQuestionsTourSteps,
  {
    selector: '[reflection-questions-settings-tour="add-question"]',
    content: `You can add questions to the selected topic/category`,
  },
  {
    selector: '[reflection-questions-settings-tour="domain-questions"]',
    content: `You can add question that you have created to domains or copy questions from a domain`,
  },
];
