import React from "react";
import classes from "./CarouselRoles.module.css";

interface Props {
  name: string;
  index: number;
  currentIndex: number;
  setCurrentIndex: (index: number) => void;
}

const CarouselRoles: React.FC<Props> = ({
  name,
  index,
  currentIndex,
  setCurrentIndex,
}) => {
  return (
    <div className={classes["content"]}>
      {/* <div
        className={`${classes["role-card"]} ${
          index === currentIndex ? classes["border"] : classes["sideBorder"]
        }`}
      >
        <strong className={classes["name"]}>{name.toUpperCase()}</strong>
      </div> */}
      <button
        style={{ backgroundColor: "var(--main-colour)" }}
        className={` ${
          index === currentIndex ? classes["border"] : classes["sideBorder"]
        }`}
        type="button"
        onClick={() => setCurrentIndex(index)}
      >
        <strong className={classes["name"]}>{name.toUpperCase()}</strong>
      </button>
    </div>
  );
};

export default CarouselRoles;
