import { FC, MouseEventHandler } from "react";
import { RxEyeOpen, RxEyeClosed } from "react-icons/rx";
import classes from "./InputField.module.css";

interface Props {
  showPassword : boolean;
  visibilityToggleHandler: MouseEventHandler<HTMLButtonElement>;
}

const openEye = (
  <div className="h-6">
    <RxEyeOpen size={24} fill="gray" />
  </div>
); 

const closedEye = (
  <div className="h-6">
    <RxEyeClosed size={24} fill="gray" />
  </div>
); 

const PasswordVisibilityToggle: FC<Props> = ({ showPassword, visibilityToggleHandler }) => {
  return (
    <button
      type="button"
      className={classes.password}
      onClick={visibilityToggleHandler}
    >
      { showPassword ? openEye : closedEye }
    </button>
  )
};

export default PasswordVisibilityToggle;