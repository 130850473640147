import classes from "./OwnTopic.module.css";
import useParticipants from "hooks/useParticipants";
import Box from "components/utility/Box/Box";
import EmptySpace from "components/utility/EmptySpace";
import { OWN_TOPIC_TITLE } from "strings/ViewChooseTopic";

const OwnTopic = () => {
  const { oldTopic } = useParticipants();

  return (
    <Box width={"90%"} backgroundColor={"var(--secondary-colour-2-60)"}>
      <strong className={classes["title"]}>{OWN_TOPIC_TITLE}</strong>
      <EmptySpace height={"20px"} />
      <Box backgroundColor={"var(--secondary-colour-2)"} padding={"10px 20px"}>
        <strong className={classes["topic"]}>{oldTopic}</strong>
      </Box>
      <EmptySpace height={"10px"} />
    </Box>
  );
};

export default OwnTopic;
