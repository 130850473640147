import { useAuthContext } from "hooks/useAuthContext";
import { useEffect } from "react";
import classes from "./NGFooter.module.css";
import GrowthCollectiveLogo from "assets/NG/Logos/GrowthCollective.webp";

function StandardFooter() {
  const { profile } = useAuthContext();

  useEffect(() => {
    if (!profile) return;
  }, [profile]);

  return (
    <div
      className={`fixed bottom-0 bg-default w-full border-t px-4 flex justify-between items-center ${
        profile?.isFacil ? "border-facil-colour" : "border-main-colour"
      } z-50`}
    >
      <div className="flex gap-1 justify-start items-center w-full">
        <img src={GrowthCollectiveLogo} alt="" width={75} height={20} />
      </div>
      <p className={`${classes.powered} py-4 w-full `}>
        Powered by Growthbeans
      </p>
    </div>
  );
}

export default StandardFooter;
