import { ResourceInterface } from "interface/ResourceInterface";
import { projectFirestore, timestamp } from "../firebase/config";
import useOrganisationContext from "./organisation/useOrganisationContext";
import { useCallback, useEffect, useState } from "react";

export const useResourcesOrganisation = () => {
  const { selectedOrganisation } = useOrganisationContext();
  const [resources, setResources] = useState<ResourceInterface[]>([]);

  const AddResources = useCallback(
    async (data: object) => {
      try {
        const record = {
          createdAt: timestamp.fromDate(new Date()),
          organisationId: selectedOrganisation?.id,
          ...data,
        };
        const ref = await projectFirestore
          .collection("organisations")
          .doc(selectedOrganisation?.id)
          .collection("resources")
          .add(record);
        await ref.update({ id: ref.id });
      } catch (error) {
        console.error(error);
      }
    },
    [selectedOrganisation]
  );

  const DeleteResource = useCallback(
    async (resourceId: string) => {
      try {
        await projectFirestore
          .collection("organisations")
          .doc(selectedOrganisation?.id)
          .collection("resources")
          .doc(resourceId)
          .delete();
      } catch (error) {
        console.error(error);
      }
    },
    [selectedOrganisation]
  );

  const GetOrganisationResources = () => {
    const updatedResources: ResourceInterface[] = [];
    let topicUnsubscribe: (() => void) | undefined;

    try {
      const resourcesQuery = projectFirestore
        .collection("organisations")
        .doc(selectedOrganisation?.id)
        .collection("resources")
        .orderBy("createdAt", "desc");

      topicUnsubscribe = resourcesQuery.onSnapshot((resourcesSnapshot) => {
        updatedResources.length = 0; // Clear the array
        resourcesSnapshot.forEach((resourceSnapshot) => {
          updatedResources.push(resourceSnapshot.data() as ResourceInterface);
        });

        setResources(updatedResources);

        // Set the state or do whatever you need with the topics
        // setTopicData(updatedTopics);
      });
    } catch (error) {
      console.error(error);
    }

    return () => {
      if (topicUnsubscribe) {
        topicUnsubscribe();
      }
    };
    // eslint-disable-next-line
  };

  useEffect(() => {
    GetOrganisationResources();
    // eslint-disable-next-line
  }, [selectedOrganisation]);

  const GetOrganisationResourcesById = () => (orgId: string) => {
    const updatedResources: ResourceInterface[] = [];
    let topicUnsubscribe: (() => void) | undefined;

    try {
      const resourcesQuery = projectFirestore
        .collection("organisations")
        .doc(orgId)
        .collection("resources");

      topicUnsubscribe = resourcesQuery.onSnapshot((resourcesSnapshot) => {
        updatedResources.length = 0; // Clear the array
        resourcesSnapshot.forEach((resourceSnapshot) => {
          updatedResources.push(resourceSnapshot.data() as ResourceInterface);
        });

        setResources(updatedResources);
      });
    } catch (error) {
      console.error(error);
    }

    return () => {
      if (topicUnsubscribe) {
        topicUnsubscribe();
      }
    };
  };

  return {
    AddResources,
    DeleteResource,
    GetOrganisationResourcesById,
    GetOrganisationResources,
    resources,
  };
};
