import blue from "assets/GrowthCirclesType/blue.svg";
import yellow from "assets/GrowthCirclesType/yellow.svg";
import pink from "assets/GrowthCirclesType/pink.svg";
import Organisation from "interface/OrganisationInterface";
import { isArray } from "lodash";
import { Link } from "react-router-dom";

interface GrowthCirclesTypeAdminCardProps {
  data: Organisation;
  index: number;
  start?: boolean;
}
const GrowthCirclesTypeAdminCardModal = ({
  data,
  index,
  start,
}: GrowthCirclesTypeAdminCardProps) => {
  const bgImages = [blue, yellow, pink, pink, yellow];

  return (
    <div className="relative  min-w-[160px] min-h-[280px] bg-white  p-5">
      <p
        className="bg-default w-full md:w-1/2 p-2 font-bold text-xl text-center rounded-3xl will-change-auto"
        style={{ color: "#EB8181" }}
      >
        {data.tags}
      </p>
      <div className="my-5 p-1 relative rounded-lg text-center min-h-[200px] flex items-center justify-center">
        <img
          className="w-full "
          src={bgImages[index]}
          alt={`${bgImages[index]}`}
        />
        <p className="text-3xl w-2/3 break-words absolute">{data.name}</p>
      </div>
      <div className="my-4 mb-28  mt-10 text-2xl break-words  flex items-start justify-start flex-col">
        <div className="my-4">
          <p>
            <span
              className="text-2xl font-semibold"
              style={{ color: "#EB8181" }}
            >
              SUMMARY:
            </span>{" "}
            {data.shortDescription}
          </p>
        </div>
        <div className="my-4">
          <p className="text-2xl font-semibold" style={{ color: "#EB8181" }}>
            OBJECTIVES:
          </p>
          <small>Use this for discussions on</small>
          <ul className="list-disc px-8">
            {data.objectives &&
              isArray(data.objectives) &&
              data.objectives.map((objective, index) => (
                <li key={index}>{objective}</li>
              ))}
          </ul>
        </div>
        <div className="my-4">
          <p className="text-2xl font-semibold" style={{ color: "#EB8181" }}>
            WHO IS THIS FOR:
          </p>{" "}
          <p>{data.whoForThis && data.whoForThis.join(", ")}</p>
        </div>
      </div>
      <div className="p-5 w-full absolute bottom-0 left-0">
        {!start && (
          <Link to={`/all-circles/admin/growthcircles-type/${data.id}`}>
            <div
              style={{ backgroundColor: "#eb8181" }}
              className="px-4 w-full text-3xl text-center py-4 rounded-full text-default font-semibold"
            >
              View
            </div>
          </Link>
        )}
        {start && (
          <Link to={`/all-circles/staging/${data.id}`}>
            <div
              style={{ backgroundColor: "#eb8181" }}
              className="px-4 w-full text-3xl text-center py-4 rounded-full text-default font-semibold"
            >
              Start
            </div>
          </Link>
        )}
      </div>
    </div>
  );
};

export default GrowthCirclesTypeAdminCardModal;
