import EmptySpace from "components/utility/EmptySpace";
import React from "react";

const ModalContent: React.FC<{}> = () => {
  const title = "Cannot Proceed!";
  const content =
    "Kindly choose a role to proceed to the following page or await the facilitator/host to proceed to the next stage.";

  return (
    <div>
      <strong className={`text-slate-400 text-2xl`}>{title}</strong>
      <EmptySpace height={"20px"} />
      <p className={"text-slate-600"}>{content}</p>
    </div>
  );
};

export default ModalContent;
