import classes from "./RoleResponse.module.css";
import OwnRole from "./OwnRole";
import OtherRoles from "./OtherRoles";
import Center from "components/utility/Center/Center";
import EmptySpace from "components/utility/EmptySpace";
import { OTHERS_ROLES_TITLE } from "strings/ViewChooseRoles";
import { useEffect, useState } from "react";
import useGetParticipants from "hooks/useGetParticipants";

const RoleResponse = () => {
  const { participants } = useGetParticipants();
  const [totalRoles, setTotalRoles] = useState(0);
  useEffect(() => {
    var total = 0;
    participants.forEach((element) => {
      if (element.role) {
        total++;
      }
    });
    setTotalRoles(total);
  }, [participants]);

  return (
    <Center width={"100%"}>
      <OwnRole />
      <EmptySpace />
      {participants && (
        <strong className={classes["participants-response"]}>
          {OTHERS_ROLES_TITLE} ({totalRoles} / {participants.length})
        </strong>
      )}

      <EmptySpace height={"30px"} />
      <OtherRoles />
    </Center>
  );
};

export default RoleResponse;
