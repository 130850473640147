import React, { MouseEvent } from "react";
import classes from "./RectangularBubble.module.css";
import { ReactComponent as Glitter } from "assets/Glitter.svg";
import { useRef } from "react";
import { ReactComponent as BottomArrow } from "assets/GroundRules/BottomArrow.svg";
import EmptySpace from "./EmptySpace";

interface Props {
  style?: string;
  id: string;
  title?: string;
  subtitle?: string;
  children: React.ReactNode;
  buttonText?: string;
  doNotShowButton?: boolean;
  buttonMethod: (
    event: MouseEvent<HTMLButtonElement, globalThis.MouseEvent>
  ) => void;
}
/**
 * Speech bubble used together with Laref
 * @param {string} style - modifier to current style (altBubbleContainer)
 * @param {string} id - id of bubble
 * @param {string} title? - title of bubble
 * @param {string} subtitle? - subtitle of bubble
 * @param {React.ReactNode} children - contents of bubble
 * @param {string} buttonText? - text of button
 * @param {boolean} doNotShowButton? - boolean flag to indicate whether to show the button
 * @param {() => any} buttonMethod - event handler when user clicks the button
 */
const RectangularBubble: React.FC<Props> = ({
  style, // "altBubbleContainer"
  id,
  title,
  subtitle,
  children, //An array of contents
  buttonText, //Text of the Button
  doNotShowButton,
  buttonMethod,
}) => {
  const listInnerReference = useRef<HTMLDivElement | null>(null);

  //Method to detect if user has reached the bottom of the scroll
  const onScroll = () => {
    handleScrollBottom();
  };

  const handleScrollBottom = () => {
    if (listInnerReference.current) {
      const { scrollTop, scrollHeight, clientHeight } =
        listInnerReference.current;

      if (scrollTop + clientHeight === scrollHeight) {
        //Logic when user scrolled to the bottom
      }
    }
  };

  const containerClasses = `${classes["bubbleContainer"]} ${
    style ? classes[style] : ""
  }`;

  return (
    <div className={classes["relativeContainer"]}>
      <div className={containerClasses}>
        {title && <h1 key={title}>{title}</h1>}
        {subtitle && <h1>{subtitle}</h1>}
        <EmptySpace height="4px" />
        <div
          key={id}
          ref={listInnerReference}
          onScroll={onScroll}
          className={`${classes["content"]}`}
        >
          {children}
        </div>

        {buttonText && !doNotShowButton && (
          <button key={buttonText} type="button" onClick={buttonMethod}>
            {style === undefined && (
              <div className={classes["glitter"]}>
                <Glitter />
              </div>
            )}
            {buttonText}
          </button>
        )}

        <div className={classes["bottomArrow"]}>
          <BottomArrow width={"100%"} height={"100%"} />
        </div>
      </div>
    </div>
  );
};

export default RectangularBubble;
