import classes from "./SmileyRating.module.css";
import { ReactComponent as ExclaimationMark } from "assets/ExclamationMark.svg";

import { useThemeContext } from "hooks/useThemeContext";

type SmileyRatingProps = {
  question?: string;
  activeValue: number;
  name: string;
  onSelect: (e: any) => void;
  showError: boolean;
  otherClass?: string;
};

export default function SmileyRating({
  question,
  activeValue,
  name,
  onSelect,
  showError,
  otherClass,
}: SmileyRatingProps) {
  const { isFacil } = useThemeContext();

  const smileyInputClasses = `${classes["smiley-input"]} ${otherClass} ${
    showError && activeValue === 0 ? classes["smiley-input--error"] : ""
  }`;

  return (
    <div className={classes["smiley-container"]}>
      <p>{question}</p>
      <div className={`${classes["smiley-input-container"]}`}>
        <p className={classes["rating-number"]}>1</p>
        <div className={smileyInputClasses}>
          <div
            className={`${
              classes[activeValue === 1 ? "circle-active" : "circle"]
            } ${
              isFacil
                ? classes[
                    activeValue === 1 ? "circle-active--facil" : "circle--facil"
                  ]
                : ""
            }`}
            data-name={name}
            data-set={1}
            onClick={onSelect}
          ></div>
          <div
            className={`${
              classes[activeValue === 2 ? "circle-active" : "circle"]
            } ${
              isFacil
                ? classes[
                    activeValue === 2 ? "circle-active--facil" : "circle--facil"
                  ]
                : ""
            }`}
            data-name={name}
            data-set={2}
            onClick={onSelect}
          ></div>
          <div
            className={`${
              classes[activeValue === 3 ? "circle-active" : "circle"]
            } ${
              isFacil
                ? classes[
                    activeValue === 3 ? "circle-active--facil" : "circle--facil"
                  ]
                : ""
            }`}
            data-name={name}
            data-set={3}
            onClick={onSelect}
          ></div>
          <div
            className={`${
              classes[activeValue === 4 ? "circle-active" : "circle"]
            } ${
              isFacil
                ? classes[
                    activeValue === 4 ? "circle-active--facil" : "circle--facil"
                  ]
                : ""
            }`}
            data-name={name}
            data-set={4}
            onClick={onSelect}
          ></div>
          <div
            className={`${
              classes[activeValue === 5 ? "circle-active" : "circle"]
            } ${
              isFacil
                ? classes[
                    activeValue === 5 ? "circle-active--facil" : "circle--facil"
                  ]
                : ""
            }`}
            data-name={name}
            data-set={5}
            onClick={onSelect}
          ></div>
        </div>
        <p className={classes["rating-number"]}>5</p>
      </div>
      {/* Error Message */}
      {showError && activeValue === 0 && (
        <div className={classes["smiley-error-message"]}>
          <ExclaimationMark />
          <span>Please choose your rating for this section.</span>
        </div>
      )}
    </div>
  );
}
