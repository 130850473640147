import useOrganisation, {
  OrganisationHook,
} from "hooks/organisation/useOrganisation";
import Organisation from "interface/OrganisationInterface";
import { createContext, ReactNode } from "react";
import { WithId } from "utility/model";

export const OrganisationContext = createContext<OrganisationHook | null>(null);

interface Props {
  fetchOrganisations?: () => Promise<WithId<Organisation>[]>;
  children: ReactNode;
}

export function OrganisationContextProvider({
  fetchOrganisations,
  children,
}: Props) {
  const organisationHook = useOrganisation(fetchOrganisations);

  return (
    <OrganisationContext.Provider value={organisationHook}>
      {children}
    </OrganisationContext.Provider>
  );
}
