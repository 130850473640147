import React from "react";
import classes from "./Steps.module.css";

interface CircleProps {
  number: number;
  color: string;
}

const Circle: React.FC<CircleProps> = ({ number, color }) => {
  const circleStyle = {
    backgroundColor: color,
    width: "1.8rem",
    height: "1.8rem",
  };
  return (
    <button className={`${classes["circle"]}`} style={circleStyle}>
      <strong>{number}</strong>
    </button>
  );
};

//Steps for GrowthCircles Session

interface StepsProps {
  count: number;
  currentStep: number;
  style?: React.CSSProperties;
}

const Steps: React.FC<StepsProps> = ({ count, currentStep, style }) => {
  let circles: JSX.Element[] = [];

  for (var i = 1; i < count + 1; i++) {
    const color =
      i < currentStep ? "#F9BB62" : i > currentStep ? "#C4C4C4" : "#B0CD00";
    circles.push(<Circle key={i} number={i} color={color} />);
  }

  return (
    <div style={style} className={`${classes["steps"]} md:text-md text-sm`}>
      {circles}
    </div>
  );
};

export default Steps;
