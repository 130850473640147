import SimpleLoader from "components/Loaders/SimpleLoader";
import InputField from "components/utility/Forms/InputField";
import AutoResizeTextArea from "components/utility/Inputs/AutoResizeTextArea";
import { DocumentData } from "firebase/firestore";
import { Button, Modal } from "flowbite-react";
import { useOrgORS } from "hooks/organisation/useOrgORS";
import usePromise from "hooks/utility/usePromise";
import { FormEvent, useEffect, useState } from "react";
import toast from "react-hot-toast";
import { FaEdit, FaTrash } from "react-icons/fa";
import { HiOutlineExclamationCircle } from "react-icons/hi";

interface ORSProps {
  ors: DocumentData;
}

const ORSItem = ({ ors }: ORSProps) => {
  const [deleteShow, setDeleteShow] = useState<boolean>(false);
  const [editShow, setEditShow] = useState<boolean>(false);
  const { isLoading, resolve } = usePromise();
  const [title, setTitle] = useState<string>(ors.title);
  const [subTitle, setSubTitle] = useState<string>(ors.sub_title);
  const { DeleteORS, UpdateORS } = useOrgORS();
  const deleteHandler = () => {
    resolve(() => DeleteORS(ors.id)).then(() => {
      toast.success("ORS was deleted successfully");
      setDeleteShow(!deleteShow);
    });
  };

  useEffect(() => {
    if (!ors) return;
  }, [ors]);

  const submitHandler = (e: FormEvent) => {
    e.preventDefault();
    resolve(() => UpdateORS(ors.id, title, subTitle)).then(() => {
      toast.success("ORS data was updated successfully");
      setEditShow(!editShow);
    });
  };

  return (
    <>
      <Modal
        show={deleteShow}
        size="lg"
        popup
        onClose={() => setDeleteShow(!deleteShow)}
      >
        <Modal.Header />
        <Modal.Body>
          <div className="text-center flex justify-center flex-col items-center">
            {!isLoading && (
              <HiOutlineExclamationCircle
                size={82}
                className="mx-auto mb-4  text-gray-400 dark:text-gray-200"
              />
            )}
            <h3 className="mb-5 text-lg font-normal text-gray-500 dark:text-gray-400">
              Are you certain about your decision to delete the "
              <strong>{ors.title}</strong>" ORS?
            </h3>
            {isLoading ? (
              <SimpleLoader />
            ) : (
              <div className="flex justify-center gap-4">
                <Button color="failure" onClick={deleteHandler}>
                  Yes, I'm sure
                </Button>
                <Button color="gray" onClick={() => setDeleteShow(!deleteShow)}>
                  No, cancel
                </Button>
              </div>
            )}
          </div>
        </Modal.Body>
      </Modal>{" "}
      <Modal
        show={editShow}
        size="lg"
        popup
        onClose={() => setEditShow(!editShow)}
      >
        <Modal.Header className="bg-default" />
        <Modal.Body className="bg-default">
          <div className="text-center flex justify-center flex-col items-center">
            <h3 className="mb-5 text-lg font-normal text-gray-500 dark:text-gray-400">
              Edit information and click "Update ORS"
            </h3>
            <form
              onSubmit={submitHandler}
              className="my-8 flex flex-col items-center gap-2"
            >
              <InputField
                text="ORS title"
                value={title}
                onChange={(e: React.ChangeEvent<HTMLInputElement>) =>
                  setTitle(e.target.value)
                }
                isRequired={true}
              />
              <div className="p-2 bg-white shadow-md rounded-md">
                <AutoResizeTextArea
                  className="border-none"
                  placeholder="Specify sub-title"
                  value={subTitle}
                  onChange={(value) => setSubTitle(value)}
                />
              </div>
              <button
                className="my-4 bg-sky-700 text-sky-100 px-8 py-3"
                disabled={isLoading}
                type="submit"
              >
                {isLoading ? "Submitting..." : "Update ORS"}
              </button>
            </form>
          </div>
        </Modal.Body>
      </Modal>{" "}
      {!isLoading && (
        <div className="bg-white shadow-md p-4 relative">
          <div className="absolute flex gap-2 right-2 top-2">
            <FaTrash
              size={20}
              className="text-red-500 cursor-pointer"
              onClick={() => setDeleteShow(!deleteShow)}
            />
            <FaEdit
              size={20}
              className="text-sky-600 cursor-pointer"
              onClick={() => setEditShow(!editShow)}
            />
          </div>
          <p className="text-xl font-semibold text-slate-600">{ors.title}</p>
          <p className="py-2 text-slate-600">{ors.sub_title}</p>
        </div>
      )}
    </>
  );
};

export default ORSItem;
