import { FC, useEffect, useState } from "react";
import { ComponentProps } from "../SelfAwarenessComponentMapping";
import { useSelfAwarenessContext } from "hooks/useSelfAwarenessContext";
import TitleBox from "../SubComponents/TitleBox";
import PathItemSelector from "../SubComponents/PathItemSelector";
import BackAndNextFlat from "components/utility/BackAndNextFlat";
import StepsSelection from "./StepsSelection";
import WorstSelection from "./WorstSelection";
import { Modal } from "flowbite-react";
import { AiFillCloseCircle } from "react-icons/ai";
import classes from "pages/AllCircles/Admin/Admin.module.css";
import { useSelfAwareness } from "hooks/useSelfAwareness";
import usePromise from "hooks/utility/usePromise";
import SimpleLoader from "components/Loaders/SimpleLoader";
import { QuotationList } from "./Quotation";
import { useNavigate } from "react-router-dom";
import toast from "react-hot-toast";

const PathSelector: FC<ComponentProps> = ({
  backAndNextHandler,
  setHasModal,
}) => {
  const { journal } = useSelfAwarenessContext();
  const [randomQuotation, setRandomQuotation] = useState<string>("");
  const [selectedItem, setSelectedItem] = useState<string | null>(null);
  const [showConfirm, setShowConfirm] = useState<boolean>(false);
  const [localComponent, setLocalComponent] = useState<string>(
    "PathItemSelector" ||
      "StepsSelection" ||
      "WorstSelection" ||
      "RandomQuotation"
  );
  const navigate = useNavigate();
  const { isLoading, resolve } = usePromise();
  const { saveJournalEntry } = useSelfAwareness();
  const handleCardSelect = (title: string) => {
    setSelectedItem((prevSelectedItem) =>
      prevSelectedItem === title ? null : title
    );

    const storedJournal = localStorage.getItem("SelfAwarenessJournal");
    const existingJournal = storedJournal ? JSON.parse(storedJournal) : {};

    // Update the existing journal with the new answer
    const updatedJournal = {
      ...existingJournal,
      path: title,
    };

    // Store the updated journal in local storage
    localStorage.setItem(
      "SelfAwarenessJournal",
      JSON.stringify(updatedJournal)
    );
  };

  const nextCallbackHandler = () => {
    if (selectedItem === "Let us get going!") {
      setLocalComponent("StepsSelection");
    } else {
      setLocalComponent("WorstSelection");
    }
  };

  const backHandler = () => {
    setLocalComponent("PathItemSelector");
  };

  const confirmNext = (e: React.MouseEvent<HTMLButtonElement, MouseEvent>) => {
    if (journal) {
      resolve(() =>
        saveJournalEntry(journal.pageType, journal.title || "")
      ).then(() => {
        setShowConfirm(!showConfirm);
        setHasModal(!showConfirm);
        setLocalComponent("RandomQuotation");
      });
    }
  };

  const nextHandler = () => {
    const storedJournal = localStorage.getItem("SelfAwarenessJournal");
    const existingJournal = storedJournal ? JSON.parse(storedJournal) : {};
    if (existingJournal.steps.every((item) => item.trim() !== "")) {
      setShowConfirm(!showConfirm);
      setHasModal(!showConfirm);
    } else {
      toast.error("Please fill-up all fields.");
      return false;
    }
  };

  const nextHandlerEnd = () => {
    navigate(`/self-awareness`);
  };

  // Load data from local storage on component mount
  useEffect(() => {
    const storedJournal = localStorage.getItem("SelfAwarenessJournal");
    if (storedJournal) {
      const parsedJournal = JSON.parse(storedJournal);
      setSelectedItem(parsedJournal.path || "");
    }
    // eslint-disable-next-line
  }, []); // Empty dependency array means this effect runs once on mount

  useEffect(() => {
    if (journal) return;
  }, [journal]);

  useEffect(() => {
    const getRandomQuotation = () => {
      const randomIndex = Math.floor(Math.random() * QuotationList.length);
      return QuotationList[randomIndex];
    };

    // Set a random quotation on component mount
    setRandomQuotation(getRandomQuotation());
    setHasModal(true);
    // eslint-disable-next-line
  }, []);

  return (
    <div className="py-8 px-4 w-full md:w-1/4">
      {journal && (
        <>
          {/* !!!We use this instead of the Confirm Modal because of nested components */}
          <Modal show={showConfirm} popup={true} size={"sm"}>
            <div
              className={`${classes["container"]} p-5 relative flex flex-col`}
              style={{ color: "var(--text-colour)" }}
            >
              {!isLoading ? (
                <>
                  <AiFillCloseCircle
                    style={{ color: "var(--icon-colour-0)" }}
                    size={30}
                    className="absolute top-2 right-2 cursor-pointer"
                    onClick={nextHandler}
                  />
                  <p className="my-8 text-center">
                    Have you completed your journal? Pressing the "OK" button
                    will submit and save the journal.
                  </p>
                  <div className="flex gap-4 justify-center items-center">
                    <button
                      onClick={confirmNext}
                      className={`${classes["button"]}  `}
                    >
                      Ok
                    </button>
                    <button
                      onClick={nextHandler}
                      className={`${classes["button"]} `}
                    >
                      Cancel
                    </button>
                  </div>{" "}
                </>
              ) : (
                <div className="min-h-[100px] flex justify-center items-center">
                  <SimpleLoader />
                </div>
              )}
            </div>
          </Modal>
          {localComponent === "RandomQuotation" && (
            <>
              <div
                className="my-8 font-poppins text-2xl h-[250px]"
                style={{ color: "var(--text-colour)" }}
              >
                <p className="w-full text-center">{randomQuotation}</p>
              </div>{" "}
              <div className="mt-5">
                <BackAndNextFlat
                  {...backAndNextHandler}
                  nextAllowed={true}
                  backAllowed={false}
                  nextCallback={nextHandlerEnd}
                />
              </div>
            </>
          )}
          {localComponent === "PathItemSelector" && (
            <>
              <TitleBox
                title={journal.pathSelectorTitle || ""}
                journal={journal}
                showInfo={false}
                handler={() => {}}
              />
              <PathItemSelector
                selectedItem={selectedItem}
                journal={journal}
                handleCardSelect={handleCardSelect}
              />
              <div className="my-8">
                <BackAndNextFlat
                  {...backAndNextHandler}
                  nextAllowed={selectedItem ? true : false}
                  nextCallback={nextCallbackHandler}
                />
              </div>
            </>
          )}
          {localComponent === "StepsSelection" && (
            <StepsSelection
              backAndNextHandler={{
                backAllowed: true,
                nextAllowed: true,
                nextCallback: nextHandler,
                backCallback: backHandler,
              }}
              setHasModal={setHasModal}
            />
          )}
          {localComponent === "WorstSelection" && (
            <WorstSelection
              backAndNextHandler={{
                backAllowed: true,
                nextAllowed: true,
                nextCallback: (e) => backAndNextHandler.nextCallback(e),
                backCallback: backHandler,
              }}
              setHasModal={setHasModal}
            />
          )}
        </>
      )}
    </div>
  );
};

export default PathSelector;
