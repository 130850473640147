export const SUPPORTED_IMAGE_EXTENSIONS = ["jpg", "jpeg", "png", "gif", "bmp"];

export function isValidImageFile(fileName: string) {
  const match = fileName.match(/\.([a-zA-Z0-9]+)(?:[?#]|$)/);
  const extension = match ? match[1].toLowerCase() : "";
  return extension ? SUPPORTED_IMAGE_EXTENSIONS.includes(extension) : false;
}

export const SUPPORTED_PDF_EXTENSIONS = ["pdf"];

export function isValidPdfFile(fileName: string) {
  const match = fileName.match(/\.([a-zA-Z0-9]+)(?:[?#]|$)/);
  const extension = match ? match[1].toLowerCase() : "";
  return extension ? SUPPORTED_PDF_EXTENSIONS.includes(extension) : false;
}
