import { SelfAwarenessToolInterface } from "interface/SelfAwarenessToolInterface";
import PathCard from "./PathCard";

const PathItemSelector = ({
  journal,
  handleCardSelect,
  selectedItem,
}: {
  journal: SelfAwarenessToolInterface;
  handleCardSelect: (title: string) => void;
  selectedItem: string | null;
}) => {
  return (
    <div className="flex gap-4 justify-center items-center my-8">
      <PathCard
        handleCardSelect={handleCardSelect}
        pageType={journal.pageType}
        content="Let us get going!"
        selectedItem={selectedItem}
      />
      <PathCard
        handleCardSelect={handleCardSelect}
        pageType={journal.pageType}
        content={`<p>Obstacles, fears,</p> <p>concerns?</p>`}
        selectedItem={selectedItem}
      />
    </div>
  );
};

export default PathItemSelector;
