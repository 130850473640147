import { Modal } from "flowbite-react";
import { AiFillCloseCircle } from "react-icons/ai";
import classes from "pages/AllCircles/Admin/Admin.module.css";
import { SelfAwarenessToolInterface } from "interface/SelfAwarenessToolInterface";
import { MaterialMapping } from "../components/MaterialMapping";
import Lightbox, { ImagesListType } from "react-spring-lightbox";
import { Suspense, lazy, useState } from "react";
const InfoModalContent = lazy(() => import("./InfoModalContent"));

interface InfoModalProps {
  show: boolean;
  journal: SelfAwarenessToolInterface;

  handler: () => void;
}

const InfoModal = ({
  handler,
  journal,

  show,
}: InfoModalProps) => {
  const [activeIndex, setActiveIndex] = useState(0);
  const [showLightBox, setShowLightBox] = useState(false);
  const images: ImagesListType = journal
    ? [
        {
          src: MaterialMapping[journal.selectionModalImageSource],
          loading: "lazy",
          alt: "Uploaded image",
        },
      ]
    : [];

  const gotoPrevious = () => {
    activeIndex > 0 && setActiveIndex(activeIndex - 1);
  };

  const gotoNext = () => {
    activeIndex + 1 < images.length && setActiveIndex(activeIndex + 1);
  };

  return (
    <>
      <Modal show={show} popup={true} size={"sm"}>
        <div
          className={`p-5 relative font-poppins ${classes["container"]} flex flex-col`}
          style={{ color: "var(--text-colour)" }}
        >
          <AiFillCloseCircle
            style={{ color: "var(--icon-colour-0)" }}
            size={30}
            className="absolute top-2 right-2 cursor-pointer"
            onClick={handler}
          />
          <p className="my-8">{journal?.selectionModalContent}</p>
          {journal.selectionModalWithImage === true && (
            <Suspense fallback="loading...">
              <InfoModalContent
                showLightBox={showLightBox}
                setShowLightBox={setShowLightBox}
                journal={journal}
              />
            </Suspense>
          )}
          <button
            onClick={handler}
            className={`${classes["button"]} w-1/2 self-center`}
          >
            Okay
          </button>
        </div>
      </Modal>
      <Lightbox
        isOpen={showLightBox}
        onPrev={gotoPrevious}
        onNext={gotoNext}
        images={images}
        currentIndex={activeIndex}
        style={{ background: "grey" }}
        /* Handle closing */
        onClose={() => setShowLightBox(!showLightBox)}
        /* Use single or double click to zoom */
        // singleClickToZoom

        /* react-spring config for open/close animation */
        pageTransitionConfig={{
          from: { transform: "scale(0.75)", opacity: 0 },
          enter: { transform: "scale(1)", opacity: 1 },
          leave: { transform: "scale(0.75)", opacity: 0 },
          config: { mass: 1, tension: 320, friction: 32 },
        }}
      />
    </>
  );
};

export default InfoModal;
