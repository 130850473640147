import useFeedback from "hooks/feedback/useFeedback";
import { FeedbackQuestion } from "models/componentSettings/feedback/utility/feedback";
import { ReactNode } from "react";
import TextAreaQuestion from "./TextAreaQuestion";
import SmileyRatingQuestion from "./SmileyRatingQuestion";
import SliderQuestion from "./SliderQuestion";
import TextQuestion from "./TextQuestion";
import { Role } from "models/organisationRole";

export type SmileyRatingQuestionProps = {
  questionCategory?: string;
  questions?: FeedbackQuestion[];
  sessionRole?: Role | null;
  children?: ReactNode;
  label?: string;
};

export default function QuestionGroup({
  children,
  label = "",
  questionCategory = "",
  questions = [],
  sessionRole,
}: SmileyRatingQuestionProps) {
  const defaultResponses = {};
  questions.forEach(
    (question) =>
      (defaultResponses[question.questionName] = question.defaultResponse)
  );
  const { values, setValue } = useFeedback(questionCategory, defaultResponses);

  return (
    <div className="border-b-2 w-full max-w-[290px] md:max-w-[360px] py-4">
      {label && (
        <p
          style={{ color: "var(--main-colour)" }}
          className={` py-3 text-lg font-semibold text-left`}
        >
          {label}
        </p>
      )}
      {questions.map((questionDetails) => {
        const commonProps = {
          label:
            questionDetails.question[sessionRole?.name ?? ""] ??
            questionDetails.question.default,
          key: questionDetails.questionName,
        };
        switch (questionDetails.type) {
          case "text":
            return (
              <TextQuestion
                {...commonProps}
                value={values[questionDetails.questionName]}
                onChange={(event) =>
                  setValue(questionDetails.questionName, event.target.value)
                }
              />
            );
          case "textArea":
            return (
              <TextAreaQuestion
                {...commonProps}
                value={values[questionDetails.questionName]}
                onChange={(event) =>
                  setValue(questionDetails.questionName, event.target.value)
                }
              />
            );
          case "slider":
            return (
              <SliderQuestion
                {...commonProps}
                value={Number(values[questionDetails.questionName])}
                setValue={(value) =>
                  setValue(questionDetails.questionName, value + "")
                }
              />
            );
          case "smileyRating":
            return (
              <SmileyRatingQuestion
                {...commonProps}
                value={Number(values[questionDetails.questionName])}
                name={questionDetails.questionName}
                onChange={(event) =>
                  setValue(
                    questionDetails.questionName,
                    event.target.getAttribute("data-set") ?? ""
                  )
                }
              />
            );
          default:
            return <></>;
        }
      })}
      {children}
    </div>
  );
}
