import { FC, useEffect, useState } from "react";
import TitleBox from "../SubComponents/TitleBox";
import { ComponentProps } from "../SelfAwarenessComponentMapping";
import { useSelfAwarenessContext } from "hooks/useSelfAwarenessContext";
import BackAndNextFlat from "components/utility/BackAndNextFlat";
import { colours } from "./styles/colourMap";
import InfoModal from "../SubComponents/InfoModal";

const TopKeyList: FC<ComponentProps> = ({
  backAndNextHandler,
  setHasModal,
}) => {
  const [show, setShow] = useState<boolean>(false);
  const { journal } = useSelfAwarenessContext();
  const handler = () => {
    setShow(!show);
    setHasModal(!show);
  };

  const [selectedItems, setSelectedItems] = useState<string[]>([]);

  // Load data from local storage on component mount
  useEffect(() => {
    const storedJournal = localStorage.getItem("SelfAwarenessJournal");
    const existingJournal = storedJournal ? JSON.parse(storedJournal) : {};
    if (existingJournal && existingJournal.dissectKeys) {
      setSelectedItems(existingJournal.dissectKeys);
    }
    // eslint-disable-next-line
  }, []);

  const nextHandler = (e: React.MouseEvent<HTMLButtonElement, MouseEvent>) => {
    backAndNextHandler.nextCallback(e);
  };

  useEffect(() => {
    if (!journal) return;
  }, [journal]);

  return (
    <div className="py-8 px-4 w-full md:w-1/4">
      {journal && <InfoModal handler={handler} show={show} journal={journal} />}
      {journal && (
        <>
          <TitleBox
            journal={journal}
            handler={handler}
            showInfo={true}
            title={journal.topKeysTitle || ""}
          />

          <div className="p-5 my-4 font-poppins">
            <div className="max-h-[300px] overflow-y-auto">
              {selectedItems.map((item, index) => (
                <div key={index} className="px-5 select-none">
                  <div
                    style={{
                      borderColor: colours[journal.pageType]["border"],
                      color: "var(--text-colour)",
                    }}
                    className={`border-2 p-2 flex w-full gap-2 items-center  rounded-full my-2 bg-white px-4`}
                  >
                    {item}
                  </div>
                </div>
              ))}
            </div>
          </div>
        </>
      )}
      <div className="my-4">
        <BackAndNextFlat
          {...backAndNextHandler}
          nextFailCallback={(e) => nextHandler(e)}
        />
      </div>
    </div>
  );
};

export default TopKeyList;
