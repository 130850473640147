import Button from "components/utility/Button";
import EmptySpace from "components/utility/EmptySpace";
import Modal from "components/utility/Modal";
import { useNavigate } from "react-router-dom";

const MSG_RESET_PASSWORD
  = 'For security reasons, we require all users to use stronger passwords. Please change your password.';

/**
 * A modal that prompts users to reset their password.
 *
 * @param isModalOpen Whether the modal is open.
 * @param setIsModalOpen Setter function for the state of the modal.
 */
export default function ChangePasswordPrompt({isModalOpen, setIsModalOpen}) {
  const navigate = useNavigate();

  return (
    <Modal
      isModalOpen={isModalOpen}
      setIsModalOpen={setIsModalOpen}
    >
      <div className="flex flex-col">
        <div className="text-center">
          { MSG_RESET_PASSWORD }
        </div>
        <EmptySpace />
        <Button
          text='Reset Password'
          onClick={() => navigate('my-account/update-password')}
          className="self-center"
        />
      </div>
    </Modal>
  );
}
