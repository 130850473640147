import classes from "./Admin.module.css";
import { useEffect, useState } from "react";

// import { projectFirestore } from "../../../firebase/config.js";
import { ReactComponent as GreenPlusIcon } from "../../../assets/GreenPlusIcon.svg";
import { Link } from "react-router-dom";
import GrowthCirclesList from "components/Admin/GrowthCircles/GrowthCirclesList";
import SidebarAdmin from "components/Admin/SidebarAdmin";
import NavAdmin from "components/Admin/NavAdmin";

export default function AdminGrowthCircles() {
  const [showOption, setShowOption] = useState(false);
  const [showOptionState, setShowOptionState] = useState(false);
  const [limit, setLimit] = useState(50);
  const [isOngoing, setIsOngoing] = useState(true);
  const limitHandler = (value: number) => {
    setLimit(value);
  };

  const setIsOngoingHandler = (value) => {
    setIsOngoing(value);
    setShowOptionState(!showOptionState);
  };

  useEffect(() => {
    // console.log(limit);
  }, [limit]);

  return (
    <div className="min-h-screen h-full">
      <NavAdmin />
      <div className="min-h-screen h-full flex flex-col md:flex-row">
        <SidebarAdmin />
        <div className="p-4 w-1/8">
          <div className="w-full flex justify-between px-2 py-4 border-b-2 border-gray-800/[0.2] mb-2">
            <h4 className="text-2xl text-gray-600 font-bold">GrowthCircles </h4>
            <div>
              <Link
                to="/all-circles/admin/qr-generator/"
                className={`${classes["add_new"]} flex gap-2`}
              >
                Add New <GreenPlusIcon />
              </Link>
            </div>
          </div>
          <div className={classes["data-filter-display"]}>
            <div className="flex justify-center items-center gap-2">
              <p>Showing</p>
              <div
                className="relative bg-white w-20 px-2 py-2 text-center border-2 border-gray-200/[0.6]"
                onClick={() => {
                  setShowOption(!showOption);
                }}
              >
                {limit}
                {showOption && (
                  <div className="transition duration-1000 bg-white absolute top-15  border border-gray-100 shadow-md w-40">
                    <ul className="flex flex-col gap-2">
                      <li
                        className="hover:bg-gray-400 px-4 py-2"
                        onClick={() => limitHandler(50)}
                      >
                        50
                      </li>
                      <li
                        className="hover:bg-gray-400 px-4 py-2"
                        onClick={() => limitHandler(100)}
                      >
                        100
                      </li>
                      <li
                        className="hover:bg-gray-400 px-4 py-2"
                        onClick={() => limitHandler(200)}
                      >
                        200
                      </li>
                      <li
                        className="hover:bg-gray-400 px-4 py-2"
                        onClick={() => limitHandler(500)}
                      >
                        500
                      </li>
                      <li
                        className="hover:bg-gray-400 px-4 py-2"
                        onClick={() => limitHandler(1000)}
                      >
                        1000
                      </li>
                      <li
                        className="hover:bg-gray-400 px-4 py-2"
                        onClick={() => limitHandler(10000)}
                      >
                        All
                      </li>
                    </ul>
                  </div>
                )}
              </div>
              <p>of data.</p>
            </div>
            <div className={classes["data-filter-state"]}>
              <p onClick={() => setShowOptionState(!showOptionState)}>
                Status :{" "}
                <strong>{isOngoing === true ? "Active" : "Finished"}</strong>
              </p>
              {showOptionState && (
                <div className={classes["data-filter-state-options"]}>
                  <ul>
                    <li onClick={() => setIsOngoingHandler(true)}>Active</li>
                    <li onClick={() => setIsOngoingHandler(false)}>Finished</li>
                  </ul>
                </div>
              )}
            </div>
          </div>
          <GrowthCirclesList limit={limit} isOngoing={isOngoing} />
        </div>
      </div>
    </div>
  );
}
