import { where } from "firebase/firestore";
import { getModelOperationsWithPath, WithId } from "utility/model";
import firebase from "firebase/compat/app";
import { ShareMessage } from "interface/MessageInterface";

const FIRESTORE_PATH_SHARE_MESSAGE = 'ShareAndSupport';

const defaultShareMessageModel: ShareMessage = {
  createdAt: firebase.firestore.Timestamp.now(),
  userName: '',
  userId: '',
  growthCircle: '',
  entry: '',
  role: '',
  id: '',
  coreSkill: '',
}

// --- Helper Functions ---

const ops = getModelOperationsWithPath(
  FIRESTORE_PATH_SHARE_MESSAGE,
  defaultShareMessageModel
);

const _getShareMessages = ops.getModels;

// --- End Helper functions ---

export function getShareMessagesByRecipientPID(
  recipientPID: string
): Promise<WithId<ShareMessage>[]>;
export function getShareMessagesByRecipientPID(
  recipientPID: string,
  userId: string,
  shouldGetMyQuestions: boolean,
  shouldGetOthersQuestion: boolean
): Promise<WithId<ShareMessage>[]>;
export function getShareMessagesByRecipientPID(
  recipientPID: string,
  userId?: string,
  shouldGetMyQuestions?: boolean,
  shouldGetOthersQuestion?: boolean
) {
  const conditions = [where("recipientPID", "==", recipientPID)];
  if (userId) {
    if (!shouldGetMyQuestions) {
      conditions.push(where('userId', '!=', userId))
    }
    if (!shouldGetOthersQuestion) {
      conditions.push(where('userId', '==', userId))
    }
  }
  return _getShareMessages(...conditions);
}
