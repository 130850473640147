import { useCallback } from "react";
import useFeedback from "./feedback/useFeedback";

type ParticipantFeedbackHook = [string, (newValue: string) => void]

const useParticipantFeedback = (
  initialValue: string,
  category: string,
  questionName: string
): ParticipantFeedbackHook => {
  const { values, setValue } = useFeedback(
    category,
    { [questionName]: initialValue }
  );

  const setValueWrapped = useCallback((newValue: string) => (
    setValue(questionName, newValue)
  ), [questionName, setValue])

  return [values[questionName], setValueWrapped];
};

export default useParticipantFeedback;
