import { FC, useEffect, useState } from "react";
import { ComponentProps } from "../SelfAwarenessComponentMapping";
import BackAndNextFlat from "components/utility/BackAndNextFlat";
import { useSelfAwarenessContext } from "hooks/useSelfAwarenessContext";
import TextArea from "../SubComponents/TextArea";
import TitleBox from "../SubComponents/TitleBox";
import InfoModal from "../SubComponents/InfoModal";

const QuestionWithSub: FC<ComponentProps> = ({
  backAndNextHandler,
  setHasModal,
}) => {
  const [reflection, setReflection] = useState("");
  const { journal } = useSelfAwarenessContext();
  const [question, setQuestion] = useState("");
  const [path, setPath] = useState("");
  const [show, setShow] = useState(false);

  const handler = () => {
    setShow(!show);
    setHasModal(!show);
  };

  // Load data from local storage on component mount
  useEffect(() => {
    const storedJournal = localStorage.getItem("SelfAwarenessJournal");
    if (storedJournal) {
      const parsedJournal = JSON.parse(storedJournal);
      setReflection(parsedJournal.reflection || "");
      setQuestion(parsedJournal.question || "");
      setPath(parsedJournal.path || "");
    }
    // eslint-disable-next-line
  }, []); // Empty dependency array means this effect runs once on mount

  const answerHandler = (value: string) => {
    setReflection(value);
    const storedJournal = localStorage.getItem("SelfAwarenessJournal");
    const existingJournal = storedJournal ? JSON.parse(storedJournal) : {};

    // Update the existing journal with the new answer
    const updatedJournal = {
      ...existingJournal,
      reflection: value,
    };

    // Store the updated journal in local storage
    localStorage.setItem(
      "SelfAwarenessJournal",
      JSON.stringify(updatedJournal)
    );
  };

  useEffect(() => {
    if (!journal) return;
  }, [journal]);

  return (
    <div className="py-8 px-4 w-full md:w-1/4">
      {journal && <InfoModal handler={handler} show={show} journal={journal} />}
      {journal && (
        <>
          <TitleBox
            title={path || ""}
            showInfo={false}
            journal={journal}
            handler={handler}
          />
          <div
            className="mt-5 font-poppins font-bold"
            style={{ color: "var(--text-colour)" }}
          >
            <p>{question}</p>
          </div>
          <TextArea
            maxLength={500}
            answer={reflection}
            journal={journal}
            answerHandler={answerHandler}
          />

          <div className="mt-5">
            <BackAndNextFlat
              {...backAndNextHandler}
              nextAllowed={reflection.length > 0 ? true : false}
            />
          </div>
        </>
      )}
    </div>
  );
};

export default QuestionWithSub;
