import { ChangeEventHandler, FC } from "react";
import classes from "./InputField.module.css";

interface Props {
  id?: string;
  onChange?: ChangeEventHandler<HTMLInputElement>;
  value?: string | number | readonly string[];
  className?: string;
  required?: boolean;
}

const DateField: FC<Props> = ({
  id,
  onChange,
  value,
  className = "",
  required = false,
}) => {
  return (
    <div className={className ? className : classes["input-placeholder"]}>
      <input
        type="date"
        id={id}
        onChange={onChange}
        value={value}
        className={classes.input}
        required={required}
      />
    </div>
  );
};

export default DateField;
