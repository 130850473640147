import useOrganisationContext from "hooks/organisation/useOrganisationContext";
import { useAuthContext } from "hooks/useAuthContext";
import { useEffect } from "react";

const TestPage = () => {
  const { user, profile } = useAuthContext();
  const { selectedOrganisation } = useOrganisationContext();

  useEffect(() => {
    console.log(user, profile, selectedOrganisation);
    // eslint-disable-next-line
  }, [profile, user]);

  return (
    <div className="h-[100vh] flex justify-center items-center">
      {profile?.displayName} {selectedOrganisation?.name}
    </div>
  );
};

export default TestPage;
