import classes from "./Admin.module.css";
import { useNavigate, useParams } from "react-router-dom";
import { useDocument } from "../../../hooks/useDocument";
import { FormEvent, useEffect, useState } from "react";
import { useFirestore } from "../../../hooks/useFirestore";
import { PinGenerator } from "../../../components/utility/UtilityFunctions";
import { useNestedCollection } from "hooks/useNestedCollection";
import QRCode from "react-qr-code";
import ParticipantSingle from "./ParticipantSingle";

import { projectFirestore } from "../../../firebase/config";
import SidebarAdmin from "components/Admin/SidebarAdmin";
import NavAdmin from "components/Admin/NavAdmin";

import "firebase/firestore";
import { useGCSession } from "hooks/useGCSession";
import { Participant } from "interface/ParticipantInterface";

export default function ShowSingleGC() {
  const { id } = useParams();
  const { document } = useDocument("GrowthCircles", id);
  const [title, setTitle] = useState("");
  const [inviteCode, setInviteCode] = useState("");
  const [maxParticipants, setMaxParticipants] = useState<number>(5);
  const { updateDocument, response } = useFirestore("GrowthCircles");
  const [participanInfo, setParticipantInfo] = useState(Object);
  const [checkOut, setCheckOut] = useState(Object);
  const navigate = useNavigate();
  const { documents } = useNestedCollection(
    ["GrowthCircles", "Participants"],
    [id],
    null,
    null
  );
  const { refreshGCSession } = useGCSession();
  const callbackHandler = (participant : Participant) => {
    setParticipantInfo(participant);
  };

  const getCheckOut = (id : string) => {
    let obj = checkOut.find((o : Participant) => o.userId === id);
    // console.log(obj);
    return obj;
  };

  useEffect(() => {
    if (document) {
      setInviteCode(document.invite_code ?? "");
      setMaxParticipants(document.number ?? 5);
      setTitle(document.name);
      // eslint-disable-next-line @typescript-eslint/no-array-constructor
    }

    const ref = projectFirestore.collection("FeedBackForm");
    const queryRef = ref.where("growthCircle", "==", id);
    queryRef
      .get()
      .then(function (doc) {
        if (doc.docs.length > 0) {
          //get document from users
          let documents: Object[] = [];
          doc.docs.map((_gc) => {
            documents.push({
              ..._gc.data().refPersonalDragValues,
              id: _gc.id,
              userId: _gc.data().userId,
              userName: _gc.data().userName,
            });
            return setCheckOut(documents);
          });
        } else {
          try {
            // add display name to user
          } catch (err) {
            console.log("Error getting documents: ", err);
          }
        }
      })
      .catch(function (err) {
        console.log("Error getting documents: ", err);
      });
    // console.log(checkOut);
    // console.log(participanInfo);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [document, documents, participanInfo]);

  const submitHandler = (e : FormEvent) => {
    e.preventDefault();
    const data = {
      invite_code: inviteCode,
      number: maxParticipants,
      name: title,
      id: id,
    };
    console.log(response);
    return updateDocument(id, data);
  };

  const randomHandler = () => {
    setInviteCode(PinGenerator(4));
  };

  const handleBackUp = () => {
    refreshGCSession(id ?? '');
  };

  const handleDelete = () => {
    const ref = projectFirestore.collection("GrowthCircles").doc(id);
    console.log(ref);
    ref.delete().then(() => {
      navigate("/all-circles/admin/growth-circles");
    });
  };

  return (
    <div>
      <NavAdmin />
      <div className={`${classes.container} min-h-screen`}>
        <SidebarAdmin />
        <div className={classes.content}>
          {document && (
            <>
              <div className={classes.main}>
                <div className={classes.title}>
                  <h4>{document.name}</h4>
                </div>

                <div className={classes.container}>
                  <form method="POST" onSubmit={submitHandler}>
                    <label>
                      GrowthCircle Session Name
                      <div className={classes.pair}>
                        <input
                          type="text"
                          onChange={(e) => setTitle(e.target.value)}
                          value={document.name ? document.name : title}
                        />
                      </div>
                    </label>

                    <label>
                      Max Participant
                      <input
                        type="number"
                        min="5"
                        max="8"
                        onChange={(e) =>
                          setMaxParticipants(parseInt(e.target.value))
                        }
                        value={
                          maxParticipants > 0
                            ? maxParticipants
                            : document.number
                        }
                      />
                    </label>
                    <label>
                      Invite Code
                      <div className={classes.pair}>
                        <input
                          type="text"
                          onChange={(e) => setInviteCode(e.target.value)}
                          value={
                            inviteCode.length > 0
                              ? inviteCode
                              : document.invite_code
                          }
                        />
                        <button type="button" onClick={randomHandler}>
                          Generate
                        </button>
                      </div>
                    </label>
                    <label>GC Link</label>
                    <p className={classes.gcLink}>
                      <a
                        href={
                          `https://growthcircles-webapp.com/all-circles/welcome/` +
                          id
                        }
                        target="_new"
                      >
                        https://growthcircles-webapp.com/all-circles/welcome/
                        {id}
                      </a>
                    </p>

                    {/* <ParticipantWaitingArea participants={documents} /> */}

                    <p className={classes.gcQR}>
                      <QRCode
                        size={200}
                        value={`https://growthcircles-webapp.com/all-circles/welcome/${id}`}
                      />
                    </p>

                    <button type="submit">Update</button>
                    {documents.length === 0 && (
                      <button
                        onClick={handleDelete}
                        className="ml-2"
                        type="button"
                      >
                        Delete
                      </button>
                    )}

                    <button
                      type="button"
                      className="ml-2"
                      onClick={() => handleBackUp()}
                    >
                      Create Backup
                    </button>
                  </form>
                </div>
              </div>
            </>
          )}
          <div className={classes["info-section"]}>
            {documents && (
              <>
                <h3>Participants</h3>
                <small>Click the row to see more info.</small>
                <table>
                  <thead>
                    <tr>
                      <th>Name</th>
                      <th>Email</th>
                      <th>Access</th>
                      <th>Date</th>
                    </tr>
                  </thead>
                  <tbody>
                    {documents.map((participant) => (
                      <ParticipantSingle
                        key={participant.id}
                        participant={participant}
                        growthCircleID={id}
                        Callback={callbackHandler}
                      />
                    ))}
                  </tbody>
                </table>
              </>
            )}
            {Object.keys(participanInfo).length !== 0 && (
              <div className={classes["participant-info-container"]}>
                {participanInfo && (
                  <>
                    <h3>Participants Information</h3>
                    <table>
                      <thead>
                        <tr>
                          <th>Topic</th>
                          <td>{participanInfo.topic}</td>
                        </tr>
                        <tr>
                          <th>Intensions</th>
                          <td>{participanInfo.intentions}</td>
                        </tr>
                        <tr>
                          <th>Path</th>
                          <td>{participanInfo.path}</td>
                        </tr>
                        <tr>
                          <th>Role</th>
                          <td>{participanInfo.role.core_skills} </td>
                        </tr>
                      </thead>
                    </table>
                    <h3>Participants CheckIn</h3>

                    <table>
                      {checkOut && (
                        <>
                          <thead>
                            <tr>
                              <th></th>
                              <th align="center">Check In</th>
                              <th align="center">Check Out</th>
                            </tr>
                          </thead>
                          <tbody>
                            <tr>
                              <td>Interpersonally</td>
                              <td align="center">
                                {participanInfo.checkIn.interpersonally}
                              </td>
                              <td align="center">
                                {
                                  getCheckOut(participanInfo.userId)
                                    .interpersonally
                                }
                              </td>
                            </tr>

                            <tr>
                              <td>Individually</td>
                              <td align="center">
                                {participanInfo.checkIn.individually}
                              </td>
                              <td align="center">
                                {
                                  getCheckOut(participanInfo.userId)
                                    .individually
                                }
                              </td>
                            </tr>
                            <tr>
                              <td>Socially</td>
                              <td align="center">
                                {participanInfo.checkIn.socially}
                              </td>
                              <td align="center">
                                {getCheckOut(participanInfo.userId).socially}
                              </td>
                            </tr>
                            <tr>
                              <td>Overall</td>
                              <td align="center">
                                {participanInfo.checkIn.overall}{" "}
                              </td>
                              <td align="center">
                                {getCheckOut(participanInfo.userId).overall}
                              </td>
                            </tr>
                          </tbody>
                        </>
                      )}
                    </table>
                  </>
                )}
              </div>
            )}
          </div>
        </div>
      </div>
    </div>
  );
}
