import { ReactComponent as Heart } from "../../assets/DiceStepperModalIcons/Heart.svg";
import { ReactComponent as Arm } from "../../assets/DiceStepperModalIcons/Arm.svg";
import { ReactComponent as Journey } from "../../assets/DiceStepperModalIcons/Journey.svg";
import { ReactComponent as Lightbulb } from "../../assets/DiceStepperModalIcons/Lightbulb.svg";
import { ReactComponent as Sharing } from "../../assets/DiceStepperModalIcons/Sharing.svg";
import { ReactComponent as Topic } from "../../assets/DiceStepperModalIcons/Topic.svg";
import { ReactComponent as Dices } from "../../assets/DiceStepperModalIcons/Dices.svg";

interface Detail {
  icon: React.ReactElement,
  description: string,
}

interface Stepper {
  title: string,
  details: Detail[]
}

const stepperContent: Stepper[] = [
  {
    title: "",
    details: []
  },
  {
    title: "Let's Connect",
    details: [{ icon: <Dices />, description: "What connects to us" }],
  },
  {
    title: "Check-In",
    details: [
      { icon: <Heart />, description: "How are you feeling?" },
      { icon: <Heart />, description: "Setting Intentions" },
    ],
  },
  {
    title: "Build A Skill",
    details: [{ icon: <Arm />, description: "Choosing Roles" }],
  },
  {
    title: "Choose And Share",
    details: [
      { icon: <Topic />, description: "Choosing Topic" },
      { icon: <Journey />, description: "Choosing Journey" },
      { icon: <Sharing />, description: "Sharing" },
    ],
  },
  {
    title: "Reflection",
    details: [{ icon: <Lightbulb />, description: "Reflection" }],
  },
  {
    title: "Completed",
    details:[]

  },
  {
    title: "GrowthCircles Journal",
    details: []
  },

];

export { stepperContent };
