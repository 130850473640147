import { ChangeEvent, FC, FormEvent, MouseEvent, useState, useEffect } from 'react'
import { useNavigate } from 'react-router-dom'

import ProfileHeader from "./ProfileHeader"
import Header from "components/Header/Header"
import EmptySpace from "components/utility/EmptySpace"
import PageWrapper from "components/utility/PageWrapper"
import InputField from "components/utility/Forms/InputField";
import BackAndNext from 'components/utility/BackAndNext'
import PasswordConfirmModal from './PasswordConfirmModal'
import classes from "./ProfileSettings.module.css";
import { useAuthContext } from 'hooks/useAuthContext';
import PasswordChecker from 'pages/Login/PasswordChecker';
import { checkPasswordError } from 'utility/inputValidation';

/**
 * Form for logged in user to update password.
 * Comes with confirmation message modal.
 *
 * @returns UpdatePassword component.
 */
const UpdatePassword: FC<{}> = () => {
  const { profile } = useAuthContext();
  const navigate = useNavigate();
  const [formCurrentPassword, setFormCurrentPassword] = useState('');
  const [formNewPassword, setFormNewPassword] = useState('');
  const [formConfirmPassword, setFormConfirmPassword] = useState('');
  const [isModalOpen, setIsModalOpen] = useState(false);
  const [isStrong, setIsStrong] = useState(false);
  const [nextAllowed, setNextAllowed] = useState(false);

  const handleChange = (e: string) => {
    const newPassword: string = e;
    setFormNewPassword(newPassword);

    // Set the state based on the criteria
    setIsStrong(checkPasswordError(newPassword));
  };

  const setConfirmPasswordHandler = (e: string) => {
    setFormConfirmPassword(e);
    handleChange(formNewPassword);
    // Check if the password matches the confirmation
    const matches = formNewPassword === e;
    setIsStrong(matches); // Update isStrong based on matches
  };

  const handleBack = (e: MouseEvent<HTMLButtonElement>) => {
    e.preventDefault();
    navigate('/my-account');
  }

  const submitHandler = (
    e: FormEvent<HTMLFormElement> | MouseEvent<HTMLButtonElement>
  ) => {
    e.preventDefault();
    if (formNewPassword === formConfirmPassword) {
      setIsModalOpen(true);
    }
  }

  let errorMessage = '';
  if (profile?.accountType === 'google') {
    errorMessage = `To access your account, please log in using your google account instead of Email and Password.`;
  } else if (profile?.accountType === 'facebook') {
    errorMessage = `To access your account, please log in using your facebook account instead of Email and Password.`;
  }   
  
  useEffect(() => {
    if (isStrong && formNewPassword === formConfirmPassword) {
      setNextAllowed(true);
    } else {
      setNextAllowed(false);
    }
  }, [isStrong, formNewPassword, formConfirmPassword]);

  return (
    (profile?.accountType !== 'email_and_password' && profile?.accountType !== 'guest')
    ? (
      <PageWrapper>
        <Header />
          <EmptySpace height={"8vh"} />
          <ProfileHeader/>
          <div className={classes['center-text']}>
            { errorMessage }
          </div>              
      </PageWrapper>
    ) 
    : (
      <PageWrapper>
        <Header />
        <EmptySpace height={"8vh"} />
        <ProfileHeader/>
        <PasswordConfirmModal
          isModalOpen={isModalOpen}
          setIsModalOpen={setIsModalOpen}
          currentPassword={formCurrentPassword}
          newPassword={formNewPassword}
        />
        <form className={classes.form} onSubmit={submitHandler}>
          <div className={classes.inputs}>
            <InputField
              text="Current Password"
              isRequired={true}
              type={"password"}
              onChange={(e: ChangeEvent<HTMLInputElement>) => 
                setFormCurrentPassword(e.target.value)
              }
              value={formCurrentPassword}
              htmlFor={"currentpassword"}
              label={"Current Password"}
            />
            <InputField
              text="New Password"
              isRequired={true}
              type={"password"}
              onChange={(e: ChangeEvent<HTMLInputElement>) =>
                handleChange(e.target.value)
              }
              value={formNewPassword}
            />

            <InputField
              text="Confirm Password"
              isRequired={true}
              type={"password"}
              onChange={(e: ChangeEvent<HTMLInputElement>) =>
                setConfirmPasswordHandler(e.target.value)
              }
              value={formConfirmPassword}
            />
          </div>
          <PasswordChecker
              password={formNewPassword}
              confirm_password={formConfirmPassword}
          />

        <BackAndNext
          backCallback={handleBack}
          nextCallback={submitHandler}
          backAllowed={true}
          nextAllowed={nextAllowed}
          customNextText="Done"
          useAnimation={false}
        />
          
          <EmptySpace/>
        </form>
      </PageWrapper>
    )

  )
}

export default UpdatePassword
