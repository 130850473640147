import React, { useEffect } from "react";
import classes from "./TimerDisplay.module.css";
import { useState } from "react";
import EmptySpace from "components/utility/EmptySpace";
import audio from "assets/Audio/timer.mp3";

interface Props {
  timerState: string;
  setTimerState: React.Dispatch<
    React.SetStateAction<"stop" | "start" | "reset">
  >;
}

const TimerDisplay: React.FC<Props> = ({ timerState, setTimerState }) => {
  const minuteUnit = "min";
  const secondUnit = "sec";

  const [minuteValue, setMinuteValue] = useState(3);

  const [secondValue, setSecondValue] = useState(0);

  const onMinuteChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    if (timerState === "start") return;

    setMinuteValue(parseInt(e.target.value));
  };

  const onSecondChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    if (timerState === "start") return;

    setSecondValue(parseInt(e.target.value));
  };

  let countDownInterval: NodeJS.Timeout;
  let sound = new Audio(audio);
  useEffect(() => {
    if (timerState === "reset") {
      setMinuteValue(3);
      setSecondValue(0);
      setTimerState("stop");
      if (!sound.paused) sound.pause();
    } else if (timerState === "start") {
      if (secondValue > 59) {
        setSecondValue(59);
      }
      if (minuteValue > 13) {
        setMinuteValue(13);
      }
      // eslint-disable-next-line react-hooks/exhaustive-deps
      countDownInterval = setInterval(countDownTimer, 1000);
    } else if (timerState === "stop") {
      clearInterval(countDownInterval);
      if (!sound.paused) sound.pause();
    }
    return () => clearInterval(countDownInterval);
  }, [timerState, minuteValue, secondValue]);

  const countDownTimer = () => {
    if (secondValue > 0) {
      setSecondValue((s) => s - 1);
    } else if (secondValue === 0 && minuteValue > 0) {
      setSecondValue((s) => 59);
      setMinuteValue((m) => m - 1);
    } else if (secondValue === 0 && minuteValue === 0) {
      setTimerState("stop");
      sound.play();
      // enable vibration support
      if ("vibrate" in navigator) {
        // vibration API supported
        navigator.vibrate(3000);
        console.log("supported");
      } else {
        console.log("vibrate not supported");
      }
    }
  };

  return (
    <div className={classes["timer-display"]}>
      <input
        type={"number"}
        className={`w-full min-w-[50px] max-w-[100px] border-0 text-3xl`}
        onChange={onMinuteChange}
        value={minuteValue}
        max={13}
        min={1}
      />
      <p className={classes["timer-units"]}>{minuteUnit}</p>
      <EmptySpace width={"8px"} />
      <p className={classes["colon"]}>:</p>
      <EmptySpace width={"8px"} />
      <input
        type={"number"}
        className={`w-full min-w-[50px] max-w-[100px] border-0 text-3xl`}
        onChange={onSecondChange}
        value={secondValue}
        min={0}
        max={59}
        maxLength={2}
      />
      <p className={classes["timer-units"]}>{secondUnit}</p>
    </div>
  );
};

export default TimerDisplay;
