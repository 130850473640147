import SliderDisplay from "components/SliderCustom/SliderDisplay";
import FirstNameAvatar from "components/utility/FirstNameAvatar";
import { Participant } from "interface/ParticipantInterface";
import { FC } from "react";
import { DRAG_RATING_KEYS, DragRatingKeys } from "hooks/useDragRating";
import { ReactComponent as ExclamationMark } from "assets/ExclamationMark.svg";
import Organisation from "interface/OrganisationInterface";

interface Props {
  participant: Participant;
  organisation?: Organisation;
}

export const DRAG_RATING_KEYS_ORG: DragRatingKeys = {
  INDIVIDUALLY: {
    KEY: "individually",
    TITLE: "Industry insights",
    SUB_TITLE: "",
  },
  INTERPERSONALLY: {
    KEY: "interpersonally",
    TITLE: "Job Roles",
    SUB_TITLE: "",
  },
  SOCIALLY: {
    KEY: "socially",
    TITLE: "Career Paths and Progression",
    SUB_TITLE: "",
  },
  OVERALL: {
    KEY: "overall",
    TITLE: "Skills Relevant To Industry",
    SUB_TITLE: "",
  },
};

const TotalScoreModal: FC<Props> = ({ participant, organisation }) => {
  return (
    <div className="flex flex-col items-center text-[color:var(--main-colour)] font-bold pb-1 mb-[80px]">
      <h1 className={`underline underline-offset-8 decoration-2 mb-2 text-xl`}>
        For facilitator info only
      </h1>
      <div className="flex items-center text-gray-700 font-normal gap-2 mb-2">
        <div className="flex items-center gap-2">
          <svg width="10" height="10" className="relative -mr-1">
            <circle cx="5" cy="5" r="5" fill="lightgrey" />
          </svg>
          <div>Before</div>
        </div>
        <div className="flex items-center gap-2">
          <svg width="10" height="10" className="relative -mr-1">
            <circle cx="5" cy="5" r="5" fill="#FAD297" />
          </svg>
          <div>After</div>
        </div>
        <div className="flex items-center">
          <ExclamationMark />
          <p>After {"<"} Before</p>
        </div>
      </div>
      <div className="justify-center w-36 relative flex items-center gap-2 top-3">
        <FirstNameAvatar
          name={participant.userName}
          participant={participant}
          styleTriggered={true}
          participantNumber={0}
        />
        <h1 className="overflow-hidden text-ellipsis">
          {participant.userName}
        </h1>
      </div>
      {participant.checkIn &&
        Object.keys(DRAG_RATING_KEYS).map((key, index) => (
          <div
            key={index}
            className="relative flex flex-col items-center w-full"
          >
            <div className={`relative flex items-center -bottom-9 uppercase`}>
              {participant.checkOut[DRAG_RATING_KEYS[key].KEY] >= 0 &&
              participant.checkOut[DRAG_RATING_KEYS[key].KEY] <
                participant.checkIn[DRAG_RATING_KEYS[key].KEY] ? (
                <ExclamationMark />
              ) : (
                ""
              )}
              <h1
                className={` ${
                  participant.checkOut[DRAG_RATING_KEYS[key].KEY] >= 0 &&
                  participant.checkOut[DRAG_RATING_KEYS[key].KEY] <
                    participant.checkIn[DRAG_RATING_KEYS[key].KEY]
                    ? `text-red-400`
                    : ``
                }`}
              >
                {organisation &&
                organisation.name === "NTU Career & Attachment Office" ? (
                  <small>{DRAG_RATING_KEYS_ORG[key].TITLE}</small>
                ) : (
                  key
                )}
              </h1>
            </div>
            <SliderDisplay
              withBackground={false}
              value={undefined}
              checkIn={
                participant.checkIn[DRAG_RATING_KEYS[key].KEY] >= 0
                  ? participant.checkIn[DRAG_RATING_KEYS[key].KEY]
                  : undefined
              }
              checkOut={
                participant.checkOut[DRAG_RATING_KEYS[key].KEY] >= 0
                  ? participant.checkOut[DRAG_RATING_KEYS[key].KEY]
                  : undefined
              }
            />
          </div>
        ))}
    </div>
  );
};

export default TotalScoreModal;
