import { useEffect, useState } from "react";
import TitleBox from "../SubComponents/TitleBox";

import { useSelfAwarenessContext } from "hooks/useSelfAwarenessContext";
import BackAndNextFlat from "components/utility/BackAndNextFlat";
import { FaPlusCircle } from "react-icons/fa";
import classes from "pages/AllCircles/Admin/Admin.module.css";
import SimpleLoader from "components/Loaders/SimpleLoader";
import { BackAndNextProps } from "../SelfAwarenessComponentMapping";
import StepInput from "../SubComponents/StepInput";

const StepsSelection = ({
  backAndNextHandler,
  setHasModal,
}: {
  backAndNextHandler: BackAndNextProps;
  setHasModal: (show: boolean) => void;
}) => {
  const [show, setShow] = useState<boolean>(false);
  const { journal } = useSelfAwarenessContext();

  const handler = () => {
    setShow(!show);
    setHasModal(!show);
  };

  const [steps, setSteps] = useState<string[]>([]);
  const [isLoading, setLoading] = useState<boolean>(false);

  // Load data from local storage on component mount
  useEffect(() => {
    const storedJournal = localStorage.getItem("SelfAwarenessJournal");
    const existingJournal = storedJournal ? JSON.parse(storedJournal) : {};
    if (existingJournal && existingJournal.steps) {
      setSteps(existingJournal.steps);
    }
    // eslint-disable-next-line
  }, []);

  const addStepsHandler = () => {
    // Assuming challenges is an array in your state
    const newSteps = ""; // Replace with your actual default data
    setSteps((prevSteps) => [...prevSteps, newSteps]);

    const storedJournal = localStorage.getItem("SelfAwarenessJournal");
    const existingJournal = storedJournal ? JSON.parse(storedJournal) : {};
    const existingSteps = existingJournal.steps || [];

    // Update the local storage with the modified challenges array
    const updatedJournal = {
      ...existingJournal,
      steps: [...existingSteps, newSteps], // Add the new challenge
    };
    localStorage.setItem(
      "SelfAwarenessJournal",
      JSON.stringify(updatedJournal)
    );
  };

  const removeHandler = (index: number) => {
    const storedJournal = localStorage.getItem("SelfAwarenessJournal");
    const existingJournal = storedJournal ? JSON.parse(storedJournal) : {};
    const existingSteps = existingJournal.steps || [];
    const updatedSteps = existingSteps.filter((_, i) => i !== index); // Remove the challenge with the specified value

    // Update the local storage with the modified challenges array directly
    existingJournal.steps = updatedSteps;
    localStorage.setItem(
      "SelfAwarenessJournal",
      JSON.stringify(existingJournal)
    );
    setSteps(updatedSteps); // Update the state
    refreshValues();
  };

  const refreshValues = () => {
    setLoading(true);
    const storedJournal = localStorage.getItem("SelfAwarenessJournal");
    const existingJournal = storedJournal ? JSON.parse(storedJournal) : {};
    if (existingJournal && existingJournal.steps) {
      setSteps(existingJournal.steps);
    }
    setTimeout(() => {
      setLoading(false);
    }, 1000);
  };

  useEffect(() => {}, [isLoading]);

  // Load data from local storage on component mount
  useEffect(() => {
    const storedJournal = localStorage.getItem("SelfAwarenessJournal");
    if (storedJournal) {
      const existingJournal = storedJournal ? JSON.parse(storedJournal) : {};

      // Update the existing journal with the new answer
      const updatedJournal = {
        ...existingJournal,
        worstGrading: {},
        worsts: [],
      };

      // Store the updated journal in local storage
      localStorage.setItem(
        "SelfAwarenessJournal",
        JSON.stringify(updatedJournal)
      );
    }
    // eslint-disable-next-line
  }, []); // Empty dependency array means this effect runs once on mount

  useEffect(() => {
    if (!journal) return;
  }, [journal]);

  return (
    <>
      {journal && (
        <>
          <TitleBox
            journal={journal}
            handler={handler}
            showInfo={false}
            title={`What are all the things that you would need to achieve the goal?`}
          />

          <div
            className={`p-5 flex flex-col gap-2 mt-8 font-poppins ${classes["container"]}`}
          >
            {!isLoading &&
              steps.map((step, index) => (
                <StepInput
                  storage="steps"
                  key={index}
                  removeHandler={removeHandler}
                  index={index}
                  pageType={journal.pageType}
                  step={step}
                />
              ))}

            {isLoading && (
              <div className="flex justify-center items-center">
                <SimpleLoader />
              </div>
            )}
          </div>
          <div className="flex justify-center items-center mb-8">
            <FaPlusCircle
              className="cursor-pointer"
              onClick={addStepsHandler}
              size={30}
              style={{ color: "var(--icon-colour-0)" }}
            />
          </div>
        </>
      )}
      <div className="my-4">
        <BackAndNextFlat
          {...backAndNextHandler}
          nextAllowed={steps.length > 0 ? true : false}
        />
      </div>
    </>
  );
};

export default StepsSelection;
