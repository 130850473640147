import FiveStepHeader from "components/FiveStepHeader/FiveStepHeader";
import Header from "components/Header/Header";
import Slider from "components/Slider/Slider";
import ContentWrapper from "components/utility/ContentWrapper";
import PageWrapper from "components/utility/PageWrapper";
import Steps from "components/utility/Steps";
import { useAuthContext } from "hooks/useAuthContext";
import { useGrowthCircleContext } from "hooks/useGrowthCircleContext";
import { DRAG_RATING_KEYS } from "hooks/useDragRating";
import { useGCSession } from "hooks/useGCSession";
import useGetParticipants from "hooks/useGetParticipants";
import { useEffect, useState } from "react";
import NoGCSessionWrapper from "../../NoGCSession/NoGCSessionWrapper";
import classes from "./RatingAverage.module.css";
import UserBarHandle from "components/RatingAverage/UserBarHandle";
import BackAndNext from "components/utility/BackAndNext";
import { useNavigate } from "react-router-dom";
import { SETTING_INTENTIONS_ROUTE } from "strings/Routes";
import { CheckInCheckOut } from "interface/ParticipantInterface";
import { useFirestore } from "hooks/useFirestore";
import EmptySpace from "components/utility/EmptySpace";
import NGFooter from "components/Footers/NGFooter";
import useParticipants from "hooks/useParticipants";
import TotalScore from "./TotalScore";
import {
  checkCanAdvanceNextActivity,
  checkHasPermission,
} from "models/organisationRole";
import Instruction from "components/utility/Instruction";
import { P_SEE_AVERAGE_RATING } from "models/permission";
import useOrganisationContext from "hooks/organisation/useOrganisationContext";

const INSTRUCTIONS_RATING_AVERAGE =
  "Embrace the different emotional states and wellbeing of everyone";

/**
 * Page that shows the average of all participants after user has rated their initial checkIns
 */
const RatingAverage = () => {
  const PAGE_CONSTANTS = {
    FIVE_STEPPER_TEXT: "Check-in",
    PAGE_TITLE: "Check out how everyone's feeling!",
    STEPS_COUNT: 5,
    STEPS_CURRENT: 2,
  };
  const { updateDocument } = useFirestore("users");
  const { selectedOrganisation } = useOrganisationContext();
  const { profile } = useAuthContext();
  const { growthCircleSession } = useGrowthCircleContext();
  const { participants, getGroupedParticipantsByCheckInKey } =
    useGetParticipants();
  const {
    calculateAverageCheckIn,
    facilAllowNextPage,
    checkAllRatingAveragesNotNan,
  } = useGCSession();
  const { sessionRole } = useParticipants();
  const [averages, setAverages] = useState<CheckInCheckOut | null | undefined>(
    null
  );
  const [showContent, setShowContent] = useState(false);
  const [currentCheckInType, setCurrentCheckInType] = useState<string | null>(
    ""
  );
  const [hasTrigger, setHasTrigger] = useState(false);
  const [isTotalModal, setIsTotalModal] = useState(false);
  let navigate = useNavigate();
  const canAdvance = checkCanAdvanceNextActivity(sessionRole);

  const _setTrigger = () => {
    setHasTrigger(!hasTrigger);
  };
  const getOtherValues = (key) => {
    const groupedParticipants = getGroupedParticipantsByCheckInKey(
      key,
      participants
    );

    return groupedParticipants.map((participants, index) => {
      //Map into a component depending on the length
      return (
        <UserBarHandle
          max={selectedOrganisation?.maxRating ?? 10}
          key={index}
          checkInType={DRAG_RATING_KEYS[key].KEY + index}
          currentCheckInType={currentCheckInType}
          participants={participants}
          values={[participants[0].checkIn[DRAG_RATING_KEYS[key].KEY]]}
          setCurrentCheckInType={setCurrentCheckInType}
        />
      );
    });
  };

  const submitHandler = () => {
    if (canAdvance) {
      facilAllowNextPage("setting-intentions");
    }
    navigate(SETTING_INTENTIONS_ROUTE);
  };

  const countAllSubmitted = () => {
    let count = 0;
    participants.map((p) => (p.checkIn ? count++ : 0));
    return count;
  };

  useEffect(() => {
    if (!profile) return;
    if (!!participants && participants.length > 0) {
      setAverages(calculateAverageCheckIn(participants));
    }
    const data = { pageStep: window.location.pathname };
    updateDocument(profile.uid, data);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [participants, profile, selectedOrganisation]);

  useEffect(() => {
    //Check if all checkIn keys are true
    if (averages) {
      setShowContent(checkAllRatingAveragesNotNan(averages));
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [averages]);

  return (
    <NoGCSessionWrapper>
      <div className="relative">
        <PageWrapper>
          <Header hasModal={hasTrigger || isTotalModal} />
          <FiveStepHeader
            currentStep={2}
            hasLightning={true}
            hasGuide={true}
            text={PAGE_CONSTANTS.FIVE_STEPPER_TEXT}
            setIsTriggerVisible={_setTrigger}
            hasModal={hasTrigger || isTotalModal}
          />
          <ContentWrapper hasFiveStepper={true}>
            <div className={classes["rating-average-container"]}>
              {/* Steps */}
              <Steps
                count={PAGE_CONSTANTS.STEPS_COUNT}
                currentStep={PAGE_CONSTANTS.STEPS_CURRENT}
              />
              {/* title */}
              <div className={classes["title"]}>
                <h1 className="text-sm font-semibold text-center">
                  {PAGE_CONSTANTS.PAGE_TITLE}
                </h1>
                <h2 className="text-center py-2 font-semibold text-slate-600">
                  {countAllSubmitted()} out of {participants.length} submitted
                </h2>
                <hr />
              </div>
              <Instruction text={INSTRUCTIONS_RATING_AVERAGE} />
              {/* Content */}
              {Object.keys(DRAG_RATING_KEYS).map((key, index) => {
                return (
                  showContent && (
                    <div key={key} className={classes["rating-row"]}>
                      {/* textual content */}
                      <div className={classes["textual-content"]}>
                        <h2>{DRAG_RATING_KEYS[key].TITLE}</h2>
                        <h3>{DRAG_RATING_KEYS[key].SUB_TITLE}</h3>
                      </div>
                      {/* slider */}
                      <Slider
                        max={selectedOrganisation?.maxRating ?? 10}
                        values={[
                          averages![DRAG_RATING_KEYS[key].KEY].toFixed(1),
                        ]}
                        setValues={[0]}
                        icon={false}
                        isDisabled={true}
                        // eslint-disable-next-line react/style-prop-object
                        style={"average"}
                        otherValues={getOtherValues(key)}
                      />
                    </div>
                  )
                );
              })}
            </div>
            <EmptySpace height="2rem" />
            {checkHasPermission(sessionRole, P_SEE_AVERAGE_RATING) ? (
              <TotalScore
                participants={participants}
                setIsModal={setIsTotalModal}
              />
            ) : (
              ""
            )}

            <div className={classes["navigation"]}>
              <BackAndNext
                nextAllowed={
                  canAdvance || growthCircleSession?.allowSettingIntentions
                }
                nextCallback={submitHandler}
                backAllowed={false}
                backHidden={true}
              />
            </div>
          </ContentWrapper>
          <EmptySpace height="50px" />
        </PageWrapper>
        <NGFooter type={growthCircleSession?.type} />
      </div>
    </NoGCSessionWrapper>
  );
};

export default RatingAverage;
